import React, { useEffect, useState } from "react";
import {
  $DatePicker,
  $Button,
  $Popconfirm,
  $Tooltip,
} from "us.common/components";
import { BoxIcons, IconTypes, LoadingOutlined } from "us.icons";
import { useTranslation } from "react-i18next";
import { IExecution } from "../Interfaces";
import moment from "moment";
import { FollowUpAction } from "us.collection.followup/constants";

/**
 * @description Execution part of Activity Execution bar
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2912026690/Follow-up+Activity+Execution+UI+Implementation
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 08/03/2022
 */
const Execution: React.FC<IExecution> = (props) => {
  const { t } = useTranslation();

  const [visibleConfirm, setVisibleConfirm] = useState<boolean>(false);

  const {
    currentDateFormat,
    isEnableParameter,
    isdisable,
    isExecuting,
    onClose,
    onDateSelect,
    setFieldValue,
    date,
    name,
    onExecute,
    values,
    isValidToExecute,
    isSingleFollowUp,
    tabStartedIndex,
    isActivityValid,
  } = props;

  const { activity, executingDateTime, executingDateTimeWithParameter } =
    values;

  useEffect(() => {
    if (isActivityValid && !isEnableParameter) {
      window.setTimeout(function () {
        document.querySelector(`[name=executingDateTime]` as any)?.focus();
      }, 300);
    }
  }, [isEnableParameter, activity]);

  /**
   * @function
   * @desciption set confirmation appearing
   * @returns {boolean}
   */
  const isValidData = (): boolean => {
    try {
      return isValidToExecute
        ? isValidToExecute(
            activity,
            isEnableParameter
              ? executingDateTimeWithParameter
              : executingDateTime
          )
        : false;
    } catch (error) {
      return false;
    }
  };

  /**
   * @function
   * @description activity and action exection
   * @param {string} action action name
   */
  const onExecution = (action: string) => {
    setFieldValue("action", action);
    onExecute(values);
    setVisibleConfirm(false);
  };

  return (
    <>
      {!isSingleFollowUp && (
        <$Tooltip
          placement="topLeft"
          title={t(
            "US.COLLECTION.COMMON:ACTIVITYEXECUTION.SCHEDULE_DATE_AND_TIME"
          )}
        >
          <$DatePicker
            placeholder={`${currentDateFormat} HH:MM`}
            name={name}
            showTime={{ format: "HH:mm" }}
            format={`${currentDateFormat} HH:mm`}
            allowClear={true}
            value={date}
            onOk={(date: any) => onDateSelect(date, setFieldValue)}
            style={{ width: 190 }}
            disabledDate={(d: any) => !d || d.isBefore(moment().startOf("day"))}
            disabled={isdisable}
            tabIndex={tabStartedIndex}
          />
        </$Tooltip>
      )}
      <$Popconfirm
        placement="topRight"
        id="popConfirmId"
        title={t(
          "US.COLLECTION.COMMON:ACTIVITYEXECUTION.DO_YOU_WANT_TO_REMOVE_RESULTS_FROM_THE_FOLLOWUP_AFTER_SUCCESSFUL_EXECUTION"
        )}
        onConfirm={() => onExecution(FollowUpAction.REMOVE)}
        onCancel={() => onExecution(FollowUpAction.DONE)}
        okText={t("US.COLLECTION.COMMON:COMMON.YES")}
        cancelText={t("US.COLLECTION.COMMON:COMMON.NO")}
        onVisibleChange={() => visibleConfirm && setVisibleConfirm(false)}
        visible={visibleConfirm}
        okButtonProps={{
          tabIndex: tabStartedIndex + 2,
          autoFocus: true,
        }}
        cancelButtonProps={{
          tabIndex: tabStartedIndex + 3,
        }}
      >
        <$Button
          type={isEnableParameter ? "primary" : "default"}
          style={{ width: isEnableParameter ? "90px" : "50px" }}
          className="ml-1"
          icon={
            <>
              {isExecuting && <LoadingOutlined style={{ fontSize: 18 }} />}
              {!isExecuting && (
                <BoxIcons type={IconTypes.BOX_ICON} name="run-activity" />
              )}
            </>
          }
          onClick={() => setVisibleConfirm(isValidData())}
          disabled={isdisable}
          tabIndex={tabStartedIndex + 1}
        >
          {isEnableParameter && (
            <span className="ml-1">
              {t("US.COLLECTION.COMMON:ACTIVITYEXECUTION.RUN")}
            </span>
          )}
        </$Button>
      </$Popconfirm>
      {isEnableParameter && (
        <$Button
          className="ml-2"
          onClick={onClose}
          tabIndex={tabStartedIndex + 4}
        >
          {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
        </$Button>
      )}
    </>
  );
};

export default Execution;
