import { SearchFields } from "../../Constants";

/**
 * @description - Search value substring
 * @param {string} searchValue - Search value
 * @returns {string} - ":" added text
 */
export const getSearchValue = (searchValue: string): string => {
  //
  try {
    return searchValue?.substring(searchValue?.indexOf(":") + 1)?.trim();
  } catch (e) {
    return "";
  }
};

/**
 * @description - Get search value added option list
 * @param searchOption - Option list
 * @param searchValue - search text
 * @returns {Array} - Option list
 */
export const getSearchOptionList = (
  searchOption: Array<any>,
  searchValue: string
) => {
  try {
    const list = searchOption?.map((x: any) => {
      return {
        label: `${x.label}${getSearchValue(searchValue)}`,
        value: `${x.value}${getSearchValue(searchValue)}`,
      };
    });
    return list;
  } catch (error) {
    return [];
  }
};

/**
 * @description - Get search field type
 * @param field - Search field
 * @returns field type
 */
export const getFieldType = (field: string): string => {
  try{
    let fieldType = "";
    switch (field) {
      case SearchFields.DEBTOR_NAME:
        fieldType = SearchFields.NAME;
        break;
      case SearchFields.DEBTOR_NUMBER:
        fieldType = SearchFields.NUMBER;
        break;
      case SearchFields.OTHER_PARTY_NAME:
        fieldType = SearchFields.OTHER_PARTY_NAME;
        break;
      case SearchFields.OTHER_PARTY_NUMBER:
        fieldType = SearchFields.OTHER_PARTY_NUMBER;
        break;
      default:
        break;
    }
    return fieldType;
  }
  catch(error){
    return "";
  }
}
