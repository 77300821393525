import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import './DebtorBasicInfo.scss';
import { ConnectedProps, connect } from 'react-redux';
import Common from 'us.common';
import { useTranslation } from 'react-i18next';
import {
	BoxIcons,
	IconTypes,
	SwapOutlined,
	EnvironmentFilled,
	PhoneFilled,
	DownOutlined,
	UpOutlined,
} from 'us.icons';
import {
	CaseList,
	CreditInformation,
} from 'us.collection.debtor/components/DebtorBasicInfo/Components';
import {
	getAddressByDebtorDetails,
	getPhoneNumbersByDebtorDetails,
	isHistorySaved,
} from 'us.collection.debtor/components/DebtorBasicInfo/Functions';
import { IDebtorBasicInfo } from 'us.collection.debtor/components/Interfaces';
import EditOtherParty from 'us.collection.case/components/OtherParty/Edit/Home';
import _ from 'lodash';
import * as Actions from 'us.collection.case/actions';
import { IOtherPartySummery } from 'us.collection.case/reducers/Dashboard/Interfaces';
import { appInsights } from 'us.helper';
import * as action_ from 'us.collection.debtor/actions';
import { $SelectDebtor } from 'us.common/components/SelectDebtor';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { RootState } from 'us.helper/types';
import { SaveSearchHistory } from 'us.common/repository';

const { $Button, $Typography, $Tooltip, $Skeleton, $Drawer, $List, $Radio } =
	Common.Components;
const { Paragraph } = $Typography;
const { OtherPartyAction } = Actions;
const { otherParty, otherParties } = OtherPartyAction;

const DebtorBasicInfo: React.FC<IDebtorBasicInfo & PropsFromRedux> = (
	props
) => {
	const { t } = useTranslation();
	const [otherPartyList, setOtherPartyList] = useState<
		IOtherPartySummery[] | undefined
	>([]);
	const [caseListVisible, setCaseListVisible] = useState<boolean>(false);
	const [creditInformationVisible, setCreditInformationVisible] =
		useState<boolean>(false);
	const [selectedOtherParty, setSelectedOtherParty] = useState({});

	const {
		metaData,
		debtorWidgetData,
		addDrawer,
		debtorPermission,
		debtorDrawer,
		searchHistory,
		manageAddDrawer,
		onMaximize,
		openDebtorDrawer,
		saveDebtor,
		saveSearchHistory,
	} = props;

	const { otherParties, debtorDetails, isBadDebtor } =
		debtorWidgetData.data ?? {};
	const {
		address1,
		address2,
		address3,
		zipCode,
		zipName,
		phoneNumbers,
		idNo,
		entRoleId,
		debtorType,
	} = debtorDetails ?? {};
	const { cid, caseId, caseNo } = metaData.data ?? {};

	useEffect(() => {
		if (
			!metaData.isLoading &&
			debtorWidgetData &&
			caseId &&
			!isHistorySaved(caseNo, entRoleId, searchHistory) &&
			debtorDetails
		) {
			// save search data
			const payload = SaveSearchHistory.call({
				...debtorDetails,
				...metaData.data,
			});
			saveSearchHistory && saveSearchHistory(payload);
		}
	}, [debtorWidgetData]);

	useEffect(() => {
		if (otherParties) {
			const firstOtherParty = _.orderBy(
				otherParties &&
					otherParties.filter(
						(item: any) => item.activeStatus
					),
				['regDate'],
				['desc']
			)[0];
			if (typeof firstOtherParty !== 'undefined') {
				setOtherPartyList([firstOtherParty]);
			}
		}
	}, [otherParties]);

	const onLoadingMore = () => {
		try {
			setOtherPartyList(
				_.orderBy(
					otherParties?.filter(
						(item: any) => item.activeStatus
					),
					['regDate'],
					['desc']
				)
			);
		} catch (error) {
			appInsights.trackException(
				`Dashboard Debtor OtherParty loading more Exeception - ${error}`
			);
		}
	};

	const onCollapse = () => {
		try {
			setOtherPartyList([
				_.orderBy(
					otherParties?.filter(
						(item: any) => item.activeStatus
					),
					['regDate'],
					['desc']
				)[0],
			]);
		} catch (error) {
			appInsights.trackException(
				`Dashboard Debtor OtherParty Collapse Exeception - ${error}`
			);
		}
	};
	const loadMore = otherParties && (
		<div className='load-more' onClick={onLoadingMore}>
			<DownOutlined />
		</div>
	);

	const minimize = otherParties && (
		<div className='load-more' onClick={onCollapse}>
			<UpOutlined />
		</div>
	);

	const onOtherParty = (item: any) => {
		manageAddDrawer &&
			manageAddDrawer({ title: '', visible: true });
		setSelectedOtherParty(item);
	};

	const OtherPartyClose = () => {
		manageAddDrawer &&
			manageAddDrawer({ title: '', visible: false });
	};

	const handleChangeDebtorDrawer = (visible: boolean) => {
		openDebtorDrawer &&
			openDebtorDrawer({
				title: visible
					? t(
							'US.COLLECTION.DEBTOR:BASICINFO.SELECT_DEBTOR'
					  )
					: '',
				visible,
			});
	};

	const changeDebtorDetails = (
		selectedDebtor: any,
		isAddDebtor?: boolean
	) => {
		const metaDataObject = {
			id: caseNo,
			type: 'caseno',
		};
		if (isAddDebtor) {
			saveDebtor &&
				saveDebtor({
					newDebtorEntRoleId: -1,
					newCustId: selectedDebtor.creditorReference,
					caseId,
					metaDataObject,
					isAddDebtor,
				});
		} else {
			saveDebtor &&
				saveDebtor({
					newDebtorEntRoleId:
						selectedDebtor.debtorEntRoleId,
					newCustId: selectedDebtor.custId,
					caseId,
					metaDataObject,
					isAddDebtor,
				});
		}
	};

	const debtorInfoRadioButtons = [
		{
			value: 'creditInformation',
			component: t(
				'US.COLLECTION.DEBTOR:BASICINFO.CREDIT_INFORMATION'
			),
			dataTestId: 'dashboard-debtor-caseInformation',
		},
		{
			value: 'caseList',
			component: (
				<>
					{t(
						'US.COLLECTION.DEBTOR:BASICINFO.CASE_LIST'
					)}
					<strong>
						{` ${
							(_.find(
								debtorDetails?.tags,
								{
									tag: 'ActiveCaseCount',
								}
							) ?? {})['value']
						}`}
					</strong>
				</>
			),
			dataTestId: 'dashboard-debtor-caseList',
		},
	];

	return (
		<Formik initialValues={{}} onSubmit={() => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...rest
			}: any) => (
				<React.Fragment>
					<div className='widget widget-debtor-info'>
						<div
							className='widget-header widget-link-header d-flex align-items-center px-0'
							onClick={onMaximize}
							data-testid='dashboard-debtor'>
							<span className='widget-title'>
								{t(
									'US.COLLECTION.DEBTOR:BASICINFO.DEBTOR'
								)}
							</span>
						</div>
						<$Skeleton
							loading={
								debtorWidgetData.isLoading ||
								metaData.isLoading
							}
							active
							paragraph={{ rows: 2 }}>
							<div className='widget-body px-0'>
								<div className='d-flex align-items-center mb-2'>
									<BoxIcons
										type={
											IconTypes.BOX_ICON
										}
										name='debtor'
										className={
											isBadDebtor?.isBadDebtor
												? 'baddebtor-icon'
												: 'debtor-icon'
										}
									/>
									<div className='ml-2'>
										<strong className='d-block'>
											{
												cid
											}
											<$Tooltip
												placement='right'
												title={t(
													'US.COLLECTION.DEBTOR:BASICINFO.CHANGE'
												)}>
												<$Button
													className='ml-2'
													size='small'
													onClick={() =>
														handleChangeDebtorDrawer(
															true
														)
													}
													data-testid='dashboard-debtor-changeDebtor'
													disabled={
														metaData.data &&
														isSubCaseTransferredToCase(
															metaData.data
														)
													}>
													<SwapOutlined />
												</$Button>
											</$Tooltip>
										</strong>
										<strong className='d-block'>
											{`${debtorDetails?.firstName} ${debtorDetails?.lastName}`}
										</strong>
									</div>
								</div>
								<div className='mb-2'>
									<$Radio
										name='debtorInfoRadio'
										radioButton='true'
										options={
											debtorPermission &&
											debtorPermission[
												'creditInformation'
											]
												?.isEnabled
												? debtorInfoRadioButtons
												: _.filter(
														debtorInfoRadioButtons,
														(
															component: any
														) =>
															component.value !=
															'creditInformation'
												  )
										}
										optionValue='value'
										optionContent={(
											item: any
										) =>
											item.component
										}
										onChange={(
											e: any
										) => {
											e
												.target
												.value ===
												'creditInformation' &&
												setCreditInformationVisible(
													true
												);
											e
												.target
												.value ===
												'caseList' &&
												setCaseListVisible(
													true
												);
										}}
										className='wdi-radio-group'
									/>
								</div>
								{(address1 ||
									address2 ||
									address3 ||
									zipCode ||
									zipName) && (
									<div className='d-flex wdi-item'>
										<span className='bui-label'>
											<EnvironmentFilled />
										</span>
										<Paragraph
											ellipsis={{
												rows: 3,
											}}>
											{getAddressByDebtorDetails(
												debtorDetails
											)}
										</Paragraph>
									</div>
								)}
								{phoneNumbers &&
									phoneNumbers.length >
										0 && (
										<$Tooltip
											placement='topLeft'
											title={`${t(
												'US.COLLECTION.CASE:SALARY.PHONENO'
											)}`}>
											<div className='d-flex wdi-item'>
												<span className='bui-label'>
													<PhoneFilled />
												</span>
												<Paragraph
													ellipsis={{
														rows: 2,
													}}>
													{getPhoneNumbersByDebtorDetails(
														debtorDetails
													)}
												</Paragraph>
											</div>
										</$Tooltip>
									)}

								{idNo && (
									<$Tooltip
										placement='topLeft'
										title={`${t(
											'US.COLLECTION.CASE:OTHERPARTY.PERSONNO'
										)}`}>
										<div className='d-flex wdi-item'>
											<span className='bui-label'>
												<BoxIcons
													type={
														IconTypes.BOX_ICON
													}
													name='personNumber'
												/>
											</span>
											<Paragraph
												ellipsis>
												{
													idNo
												}
											</Paragraph>
										</div>
									</$Tooltip>
								)}

								{/* otherParties */}
								{otherPartyList &&
									otherPartyList.length >
										0 && (
										<div className='mb-3'>
											<$List
												itemLayout='horizontal'
												loadMore={
													otherParties &&
													otherParties.filter(
														(
															item: any
														) =>
															item.activeStatus
													)
														?.length >
														1
														? otherPartyList?.length ===
														  1
															? loadMore
															: minimize
														: null
												}
												dataSource={
													otherPartyList
												}
												renderItem={(
													item: any
												) => {
													const {
														entRoleId,
														roleType,
														name,
													} =
														item;
													return (
														<$List.Item
															key={
																entRoleId
															}
															className='other-party-item'
															onClick={() =>
																onOtherParty(
																	item
																)
															}>
															<$List.Item.Meta
																className='align-items-center'
																avatar={
																	<BoxIcons
																		type={
																			IconTypes.BOX_ICON
																		}
																		name='other-party'
																		className='other-party-icon'
																	/>
																}
																title={`${entRoleId} - ${name}`}
																description={
																	roleType
																}
															/>
														</$List.Item>
													);
												}}
											/>
										</div>
									)}
								<div>
									<$SelectDebtor
										name='selectedDebtor'
										onDrawerClose={() =>
											handleChangeDebtorDrawer(
												false
											)
										}
										onSelect={(
											selectedDebtor: object
										) =>
											changeDebtorDetails(
												selectedDebtor
											)
										}
										title={
											debtorDrawer.title
										}
										visible={
											debtorDrawer.visible
										}
										addNewDebtor={(
											debtorDetails: any
										) =>
											changeDebtorDetails(
												debtorDetails,
												true
											)
										}
									/>
								</div>
							</div>
						</$Skeleton>
					</div>

					<$Drawer
						title={t(
							'US.COLLECTION.DEBTOR:BASICINFO.DEBTOR_CASE_LIST'
						)}
						width={'90%'}
						visible={caseListVisible}
						onClose={() => {
							rest.setFieldValue(
								'debtorInfoRadio',
								undefined
							);
							setCaseListVisible(
								false
							);
						}}
						className='case-list-drawer'>
						{
							<CaseList
								{...props}
								debtorType={
									debtorType
								}
								onClose={() => {
									rest.setFieldValue(
										'debtorInfoRadio',
										undefined
									);
									setCaseListVisible(
										false
									);
								}}
							/>
						}
					</$Drawer>

					<$Drawer
						title={t(
							'US.COLLECTION.DEBTOR:BASICINFO.CREDIT_INFORMATION'
						)}
						width={1300}
						visible={
							creditInformationVisible
						}
						onClose={() => {
							rest.setFieldValue(
								'debtorInfoRadio',
								undefined
							);
							setCreditInformationVisible(
								false
							);
						}}>
						{
							<CreditInformation
								entRoleId={
									metaData
										.data
										?.debtorEntNumber
								}
								{...props}
							/>
						}
					</$Drawer>

					<$Drawer
						title={t(
							'US.COLLECTION.CASE:OTHERPARTY.EDIT_OTHER_PARTY'
						)}
						width={'auto'}
						className='other-party-edit'
						visible={addDrawer?.visible}
						onClose={OtherPartyClose}
						destroyOnClose>
						<EditOtherParty
							selectedOtherParty={
								selectedOtherParty
							}
							{...props}
						/>
					</$Drawer>
				</React.Fragment>
			)}
		</Formik>
	);
};

const { debtor } = action_.changeDebtorActions;

const mapState = (state: RootState) => {
	const { dashboard, otherParty, changeDebtor, mainSearch, domainView } =
		state;
	const { addDrawer, otherParties } = otherParty;
	const { debtorWidgetData } = dashboard;
	const { debtorDrawer } = changeDebtor;
	const { history } = mainSearch;
	const { metaData } = domainView;
	return {
		metaData,
		debtorWidgetData,
		addDrawer,
		otherParties,
		debtorDrawer,
		searchHistory: history.data,
	};
};

const { history } = Common.Actions.MainSearchActions;
const mapDispatch = {
	manageAddDrawer: otherParty.openDrawer,
	getOtherParies: otherParties.get,
	openDebtorDrawer: debtor.openDrawer,
	saveDebtor: debtor.save,
	saveSearchHistory: history.save,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DebtorBasicInfo);
