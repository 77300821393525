import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getDebtorAddressInfo } from 'us.collection.debtor/actions';
import { useTranslation } from 'react-i18next';
import Common from 'us.common';
import { debtor } from 'us.collection.debtor/actions';
import { IMetaData } from 'us.collection/interfaces';
import moment from 'moment';
import { IDataModel } from 'us.collection.case/reducers/Dashboard/Interfaces';
import { IOnFilter, IOnSort } from 'us.common/components';
import { RootState } from 'us.helper/types';

const { $Tag, $Table, $Skeleton, $DateLabel, $TableTree } = Common.Components;

interface IAddressHistory {
	getDebtorAddressInfo: () => void;
	onClose(): void;
	isFetching: boolean;
	currentCurrency: string;
	currentLanguage: string;
	getDebtorAddress: any;
	debtorAddressInfo: any;
	metaData: IDataModel<IMetaData>;
	isFetchingAddressInfo: boolean;
	selectedOtherPartyDetail: any;
	currentDateFormat: string;
}

const AddressHistory: React.FC<IAddressHistory> = (props) => {
	const { t } = useTranslation();
	const {
		currentLanguage,
		getDebtorAddress,
		debtorAddressInfo,
		isFetchingAddressInfo,
		currentDateFormat,
		selectedOtherPartyDetail,
	} = props;
	const [sortedInfo, setSortedInfo] = useState<any>({});

	const handleTableChange = (
		pagination: any,
		filters: any,
		sorter: any
	) => {
		setSortedInfo(sorter);
	};

	useEffect(() => {
		getDebtorAddress(selectedOtherPartyDetail?.entRoleId);
	}, []);

	const columns: any = [
		{
			title: t(
				'US.COLLECTION.DEBTOR:ADDRESSHISTORY.ROWSTATE'
			),
			dataIndex: 'rowState',
			key: 'rowState',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			customRenderChild: (text: any, record: any) => {
				return record.rowState === 'Active' ? (
					<$Tag
						className='tag-status-active'
						style={{
							minWidth: '60px',
							textAlign: 'center',
						}}>
						{text}
					</$Tag>
				) : (
					<$Tag
						className='tag-status-deactive'
						style={{
							minWidth: '60px',
							textAlign: 'center',
						}}>
						{text}
					</$Tag>
				);
			},
		},
		{
			title: t('US.COLLECTION.DEBTOR:ADDRESSHISTORY.SOURCE'),
			dataIndex: 'addrsource',
			key: 'addrsource',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			customRenderChild: (text: any, record: any) => {
				return <span>{text}</span>;
			},
		},
		{
			title: t(
				'US.COLLECTION.DEBTOR:ADDRESSHISTORY.ADDRESS1'
			),
			dataIndex: 'address1',
			key: 'address1',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			customRenderChild: (text: any, record: any) => {
				return <span>{text}</span>;
			},
		},
		{
			title: t(
				'US.COLLECTION.DEBTOR:ADDRESSHISTORY.ADDRESS2'
			),
			dataIndex: 'address2',
			key: 'address2',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			customRenderChild: (text: any, record: any) => {
				return <span>{text}</span>;
			},
		},
		{
			title: t(
				'US.COLLECTION.DEBTOR:ADDRESSHISTORY.ADDRESS3'
			),
			dataIndex: 'address3',
			key: 'address3',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			customRenderChild: (text: any, record: any) => {
				return <span>{text}</span>;
			},
		},
		{
			title: t('US.COLLECTION.DEBTOR:GENERAL.POSTALAREA'),
			dataIndex: 'zipName',
			key: 'zipName',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			customRenderChild: (text: any, record: any) => {
				return <span>{text}</span>;
			},
		},
		{
			title: t(
				'US.COLLECTION.DEBTOR:ADDRESSHISTORY.MUNICIPALITY'
			),
			dataIndex: 'municipality',
			key: 'municipality',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			customRenderChild: (text: any, record: any) => {
				return <span>{text}</span>;
			},
		},
		{
			title: t('US.COLLECTION.DEBTOR:GENERAL.COUNTRY'),
			dataIndex: 'countryName',
			key: 'countryName',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
		{
			title: t('US.COLLECTION.DEBTOR:GENERAL.MODIFIEDDATE'),
			dataIndex: 'modifiedDate',
			key: 'modifiedDate',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (text: any, record: any) => {
				return (
					<span>
						{moment(
							record?.modifiedDate
						).isValid() &&
							moment
								.utc(
									record?.modifiedDate
								)
								.isAfter(
									moment.utc(
										'0001-01-01'
									)
								) && (
								<$DateLabel
									value={
										record?.modifiedDate
									}
								/>
							)}
					</span>
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.DEBTOR:ADDRESSHISTORY.MODIFIEDUSER'
			),
			dataIndex: 'modifiedUser',
			key: 'modifiedUser',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			customRenderChild: (text: any, record: any) => {
				return <span>{text}</span>;
			},
		},
		{
			title: t('US.COLLECTION.DEBTOR:GENERAL.TELEPHONE'),
			dataIndex: 'telMobileNo',
			key: 'telMobileNo',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			customRenderChild: (text: any, record: any) => {
				return <span>{text}</span>;
			},
		},
		{
			title: t('US.COLLECTION.DEBTOR:ADDRESSHISTORY.SMS'),
			dataIndex: 'sms',
			key: 'sms',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			customRenderChild: (text: any, record: any) => {
				return <span>{text}</span>;
			},
		},
		{
			title: t('US.COLLECTION.DEBTOR:ADDRESSHISTORY.EMAIL'),
			dataIndex: 'email',
			key: 'email',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			customRenderChild: (text: any, record: any) => {
				return <span>{text}</span>;
			},
		},
	];

	const handleSort: IOnSort = (sortData, dataSource) => {
		return sortData(dataSource);
	};

	const handleFilter: IOnFilter = (searchData, dataSource) => {
		return searchData(dataSource);
	};

	return (
		<$Skeleton
			loading={isFetchingAddressInfo}
			active
			paragraph={{ rows: 2 }}>
			<$TableTree
				onChange={handleTableChange}
				rowKey='id'
				data={debtorAddressInfo}
				size='small'
				className='mt-3 header-custom-tag'
				onSort={handleSort}
				onFilter={handleFilter}
				filterOnType={true}
				resetOnSourceChange={true}
				bordered
				pagination={{ defaultPageSize: 15 }}
				scroll={{ x: 1200 }}
				columns={columns}
			/>
		</$Skeleton>
	);
};

const mapStateToProps = (state: RootState) => {
	const { debtor, common, entity, debtorDetail, domainView } = state;
	const { currentCurrency, currentLanguage, currentDateFormat } = common;
	const { metaData } = domainView;
	const { address } = debtorDetail;
	const { isFetching } = entity;
	return {
		currentCurrency,
		currentLanguage,
		isFetching,
		debtorAddressInfo: address.data,
		isFetchingAddressInfo: address.isFetching,
		currentDateFormat,
		metaData,
	};
};

const mapDispatchToProps = {
	getDebtorAddressInfo,
	getDebtorAddress: debtor.address.getByDebtorNo,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressHistory);
