import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	BoxIcons,
	CloseCircleOutlined,
	DeleteOutlined,
	IconTypes,
} from 'us.icons';
import { ArTransactionAction } from 'us.collection.transactions/constants';
import { IItemMenu } from './interface';

const ItemMenu: React.FC<IItemMenu> = ({ actions, onCallAction }) => {
	const { t } = useTranslation();

	return (
		<div className='table-more-content'>
			{actions.includes(
				ArTransactionAction.TRANSACTION_MAPPING
			) && (
				<div
					className='d-flex flex-row more-item'
					onClick={() =>
						onCallAction(
							ArTransactionAction.TRANSACTION_MAPPING
						)
					}>
					<div className='p-1'>
						<BoxIcons
							type={
								IconTypes.BOX_ICON
							}
							name='mapping'
						/>
					</div>
					<div className='p-1'>
						{t(
							'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TRANSACTION_MAPPING'
						)}
					</div>
				</div>
			)}
			{actions.includes(ArTransactionAction.CASE_MAPPING) && (
				<div
					className='d-flex flex-row more-item'
					onClick={() =>
						onCallAction(
							ArTransactionAction.CASE_MAPPING
						)
					}>
					<div className='p-1'>
						<BoxIcons
							type={
								IconTypes.BOX_ICON
							}
							name='case-map'
						/>
					</div>
					<div className='p-1'>
						{t(
							'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.CASE_MAPPING'
						)}
					</div>
				</div>
			)}
			{actions.includes(ArTransactionAction.CANCELATION) && (
				<div
					className='d-flex flex-row more-item'
					onClick={() =>
						onCallAction(
							ArTransactionAction.CANCELATION
						)
					}>
					<div className='p-1'>
						<CloseCircleOutlined />
					</div>
					<div className='p-1'>
						{t(
							'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.CANCELLATION'
						)}
					</div>
				</div>
			)}
			{actions.includes(ArTransactionAction.DELETE) && (
				<div
					className='d-flex mb-2 flex-row more-item'
					onClick={() =>
						onCallAction(
							ArTransactionAction.DELETE
						)
					}>
					<div className='p-1'>
						<DeleteOutlined />
					</div>
					<div className='p-1'>
						{t('US.COMMON:COMMON.DELETE')}
					</div>
				</div>
			)}
		</div>
	);
};

export default ItemMenu;
