import { URLType } from "us.collection/constants/DomainView";
import { appInsights } from "us.helper";

/**
 * To check if the route is correct based on the url type
 * @param caseNo Case number
 * @param typeParam The url type
 * @returns
 */
export const isCorrectRoute = (caseNo: string | undefined, typeParam: string): boolean => {
  try {
    return caseNo ||
      [URLType.AR, URLType.CREDITOR, URLType.CREDITOR_GROUP, URLType.BUREAU].findIndex(
        (urlType) => urlType === typeParam
      ) >= 0
      ? true
      : false;
  } catch (error) {
    appInsights.trackException(`Error in BMD list View IsCorrectRoute function. - ${error}`);
    return false;
  }
};
