export enum Types {
	SUBCASE = 'S',
	CASE = 'C',
	AR = 'A',
	CREDITOR = 'CRE',
	TRANSACTIONS = 'P',
	PAYMENT = 'P',
	BUREAU = 'BU',
	DEFAULT = 'ALL',
}

export enum EntityTypes {
	SUBCASE = 'SubCase',
	CASE = 'Case',
	AR = 'AR',
	CREDITOR = 'Creditor',
	TRANSACTIONS = 'Payment',
	PAYMENT = 'Payment',
	BUREAU = 'Bureau',
	DEFAULT = '',
}

export enum Url {
	SUBCASE = 'sub-case',
	CASE = 'case',
	AR = 'ar',
	CREDITOR = 'creditor',
	TRANSACTIONS = 'payment',
	PAYMENT = 'payment',
	BUREAU = 'bureau',
	DEFAULT = '',
}
