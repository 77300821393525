import React from "react";
import {
  IFollowUpFilters,
  IFollowUpResult,
  ILastAction,
  followupColumnDropdownData,
} from "us.collection.followup/interfaces";
import { EntityType } from "us.collection/constants";
import {
  FollowUpEntityType,
  FollowUpEntityTypeInterface,
  AllFollowUpEntityTypeInterface,
  FollowUpEntityTypeBadge,
  FollowUpEntityTypeBadgeInterface,
  CaseState,
  DateFormats,
  ColumnDataType,
  ColumnDataTypeInterface,
  UrlModule,
  UrlModuleInterface,
  FollowUpUniqueCol,
  FollowUpUniqueColInterface,
  LastAction,
  FollowUpAction,
  ReduxAction,
} from "us.collection.followup/constants";
import { getColorForWorkFlowState } from "us.collection/functions/WorkFlowState";
import {
  MoreButton,
  NavigationColumn,
} from "us.collection.followup/components/FollowUps/Components/FollowUpResult/Components";
import { ActionHistoryButton } from "us.collection.followup/components/FollowUps/Components/FollowUpHistory/Components";
import {
  $Popover,
  $Avatar,
  $Tooltip,
  $Tag,
  $AmountLabel,
  $DateLabel,
} from "us.common/components";
import {
  InfoCircleFilled,
  CheckCircleFilled,
  CloseCircleFilled,
  ClockCircleFilled,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  FolderOpenFilled,
  EyeFilled,
} from "us.icons";
import moment from "moment";
import _ from "lodash";
import { IInitialState } from "us.collection.followup/reducers/FollowUps/Interfaces";
import * as Azure from "us.helper/azure";
import {
  filterFollowUpByLastAction,
  isViewed,
} from "us.collection.followup/components/FollowUps/Components/FollowUpResult/Functions";
import { entity } from "us.common/actions";

/**
 * @description - Get column dataType for FollowUp result table
 * @param data - column value
 * @returns  dataType as a string
 */
const getDataType = (data: any): ColumnDataTypeInterface => {
  try {
    if (moment(data, DateFormats.REQ, true).isValid()) {
      return ColumnDataType.DATE;
    } else if (/^\d*\.\d*$/.test(data)) {
      return ColumnDataType.AMOUNT;
    } else {
      return ColumnDataType.STRING;
    }
  } catch (error) {
    return ColumnDataType.STRING;
  }
};

/**
 * @description - Get EntityType Badge for the column
 * @param entityType - entityType string
 * @param record - A single followUp result data row
 * @returns  Abbreviation for entityType as a string
 */
const getEntityTypeBadge = (
  entityType: string,
  record: any
): FollowUpEntityTypeBadgeInterface => {
  switch (entityType) {
    case FollowUpEntityType.CASE:
      return record?.Casetype == EntityType.CASE
        ? FollowUpEntityTypeBadge.CASE
        : FollowUpEntityTypeBadge.INVOICE;
    case FollowUpEntityType.INVOICE:
      return FollowUpEntityTypeBadge.INVOICE;
    case FollowUpEntityType.DEBTOR:
      return FollowUpEntityTypeBadge.DEBTOR;
    case FollowUpEntityType.CREDITOR:
      return FollowUpEntityTypeBadge.CREDITOR;
    case FollowUpEntityType.PAYMENT:
      return FollowUpEntityTypeBadge.PAYMENT;
    default:
      return "";
  }
};

/**
 * @description - Get matching url for the entityType
 * @param entityType - entityType string
 * @returns  url module
 */
export const getUrlForEntity = (entityType: string): UrlModuleInterface => {
  switch (entityType) {
    case FollowUpEntityType.CASE:
    case FollowUpEntityType.INVOICE:
      return UrlModule.CASE;
    case FollowUpEntityType.DEBTOR:
      return UrlModule.AR;
    case FollowUpEntityType.CREDITOR:
      return UrlModule.CREDITOR;
    case FollowUpEntityType.PAYMENT:
      return UrlModule.PAYMENT;
    default:
      return "";
  }
};

/**
 * * @remarks
 * This method is a part of the filterFollowUpResult function.
 * @description - Get followUpEntityType from the field "CaseType" field in a row data for the filtering data function
 * @param caseType - caseType string
 * @returns  Case of Invoice string
 */
const matchCaseTypeToFollowUpEntityType = (
  caseType: string
): FollowUpEntityTypeInterface => {
  switch (caseType) {
    case EntityType.CASE:
      return FollowUpEntityType.CASE;
    case EntityType.SUBCASE:
      return FollowUpEntityType.INVOICE;
    default:
      return "";
  }
};

/**
 * * @remarks
 * This method is a part of the filterFollowUpResult function.
 * @description - Get caseStateArray from the field of "caseStates" in filter state for the filtering data function
 * @param filters - filters object in redux state
 * @returns  Array of clicked caseState as string of array
 */
const getCaseStateFilterArr = (filters: IFollowUpFilters): Array<string> => {
  try {
    const caseStateArr = [];
    const { caseStates } = filters;
    caseStates.open && caseStateArr.push(CaseState.OPEN);
    caseStates.sentence && caseStateArr.push(CaseState.SENTENCE_RECEIVED);
    caseStates.nextDueDate && caseStateArr.push(CaseState.NEXT_DUE_DATE);
    caseStates.court && caseStateArr.push(CaseState.SEND_TO_COURT);
    caseStates.close && caseStateArr.push(CaseState.CLOSED);

    return caseStateArr;
  } catch (error) {
    return [];
  }
};

/**
 * * @remarks
 * This method is a part of the reArrangeColumns function again which is used in generateFollowUpResultColumns main function.
 * @description - Move specific element of the array to another index position
 * @param array - array
 * @param initialIndex - index of element which is wanted to move in current array
 * @param finalIndex - index which is wanted to placed in final array
 * @returns  final array which is moved according to arguments provided.
 */
const moveElementInArr = (
  array: Array<any>,
  initialIndex: number,
  finalIndex: number
): Array<any> => {
  try {
    array.splice(finalIndex, 0, array.splice(initialIndex, 1)[0]);
    return array;
  } catch (error) {
    return array;
  }
};

/**
 * * @remarks
 * This method is to get find linked column with respect to the entityType.
 * @description - Get find linked column with respect to the entityType
 * @param entityType - entityType
 * @returns  final array of possible columns to linked.
 */
const getLinkedColumnsForEntity = (
  entityType: AllFollowUpEntityTypeInterface
): Array<string> => {
  switch (entityType) {
    case FollowUpEntityType.CASE:
    case FollowUpEntityType.INVOICE:
      return [FollowUpUniqueCol.EX_CASE_NO];
    case FollowUpEntityType.CREDITOR:
      return [FollowUpUniqueCol.CREDITOR_NO];
    case FollowUpEntityType.DEBTOR:
      return [FollowUpUniqueCol.AR_NO];
    case FollowUpEntityType.PAYMENT:
      return [FollowUpUniqueCol.PAYMENT_ID, FollowUpUniqueCol.PAYMENT_NO];
    default:
      return [];
  }
};

/**
 * * @remarks
 * This method is a part of the generateFollowUpResultColumns main function.
 * @description - Re-arrange columns in table as the requirement
 * @param columns - array of columns in the table
 * @param entityType - entityType
 * @returns  final array of columns correctly placed
 */
const reArrangeColumns = (
  columns: Array<any>,
  entityType: AllFollowUpEntityTypeInterface,
  isHistory?: boolean
): any => {
  try {
    let newColumns = [...columns];
    const linkedColIndex = newColumns.findIndex((col: any) =>
      getLinkedColumnsForEntity(entityType).includes(col.dataIndex)
    );
    if (linkedColIndex > -1) {
      newColumns = moveElementInArr(
        newColumns,
        linkedColIndex,
        isHistory ? 1 : 3
      );
    }
    const workFlowStateIndex = newColumns.findIndex(
      (col: any) => FollowUpUniqueCol.WORKFLOW_STATE == col.dataIndex
    );
    if (workFlowStateIndex > -1) {
      newColumns = moveElementInArr(
        newColumns,
        workFlowStateIndex,
        isHistory ? 2 : 4
      );
    }
    const caseHandlerIndex = newColumns.findIndex(
      (col: any) => FollowUpUniqueCol.CASE_HANDLER == col.dataIndex
    );
    if (caseHandlerIndex > -1) {
      newColumns = moveElementInArr(
        newColumns,
        caseHandlerIndex,
        newColumns.length - 1
      );
    }
    return newColumns;
  } catch (error) {
    return columns;
  }
};

/**
 * * @remarks
 * This method is a part of the generateFollowUpResultColumns main function.
 * @description - set column width according to the data index
 * @param dataIndex - column name
 * @returns  width of the column
 */
const setColWidthByDataIndex = (dataIndex: string): number => {
  let colLength: number = 150;
  try {
    switch (dataIndex.toLowerCase()) {
      case FollowUpUniqueCol.COUNTRY_ID:
        colLength = 120;
        break;
      case FollowUpUniqueCol.REASON:
      case FollowUpUniqueCol.COMMENT:
      case FollowUpUniqueCol.NOTE:
        colLength = 300;
        break;
      case FollowUpUniqueCol.BALANCE:
      case FollowUpUniqueCol.AMOUNT:
        colLength = 130;
        break;
      default:
        colLength = 150;
        break;
    }
    if (dataIndex.length > 12) {
      colLength = dataIndex.length * 9;
    }
    return colLength;
  } catch (error) {
    return 150;
  }
};

/**
 * @description - get claim icon
 * @returns html item for the related claim
 */
const getClaimIcon = (
  entityType: string,
  viewedList: (string | number)[],
  record: any
) => {
  switch (entityType) {
    case FollowUpEntityType.CASE:
    case FollowUpEntityType.INVOICE:
     return isViewed(viewedList, record.Excaseno) ? (
        <EyeFilled />
      ) : (
        getEntityTypeBadge(entityType, record)
      );
    case FollowUpEntityType.DEBTOR:
     return isViewed(viewedList, record.Followupid) ? (
        <EyeFilled />
      ) : (
        getEntityTypeBadge(entityType, record)
      );
    case FollowUpEntityType.CREDITOR:
     return isViewed(viewedList, record.Creditorno) ? (
        <EyeFilled />
      ) : (
        getEntityTypeBadge(entityType, record)
      );
    default:
     return isViewed(viewedList, record.Paymentid) ? (
        <EyeFilled />
      ) : (
        getEntityTypeBadge(entityType, record)
      );
  }
};

/**
 * @description - Generate followUp result columns of the UI table which is stored in redux state - A main function.
 * @param data - API result for a particular fallowUp
 * @returns  final array of Table columns
 */
export const generateFollowUpResultColumns = (
  data: IFollowUpResult,
  followupHeaders: Array<string>,
  viewedList: Array<string | number>,
  isHistory?: boolean,
  followUpId?: number
): any => {
  const { followupData, entityType } = data;
  let columns: any = [];
  const historyColumns = [
    {
      key: FollowUpUniqueCol.MORE,
      title: "",
      dataIndex: FollowUpUniqueCol.MORE,
      width: "46px",
      fixed: "left",
      customRenderChild: (text: any, record: any) => {
        return (
          <ActionHistoryButton
            record={record}
            entityType={entityType}
            followUpId={followUpId}
          />
        );
      },
    },
  ];
  const resultColumns: any = [
    {
      key: FollowUpUniqueCol.MORE,
      title: "",
      dataIndex: FollowUpUniqueCol.MORE,
      width: "42px",
      fixed: "left",
      customRenderChild: (text: any, record: any) => {
        return <MoreButton record={record} entityType={entityType} />;
      },
    },
    {
      key: FollowUpUniqueCol.INFO,
      title: "",
      dataIndex: FollowUpUniqueCol.INFO,
      width: "36px",
      fixed: "left",
      align: "center",
      customRenderChild: (text: any, record: any) => {
        return (
          <$Popover
            placement="top"
            content={"Information"}
            destroyTooltipOnHide
          >
            <InfoCircleFilled className="followup-info" />
          </$Popover>
        );
      },
    },
    {
      key: FollowUpUniqueCol.TYPE,
      title: "",
      dataIndex: FollowUpUniqueCol.TYPE,
      width: "40px",
      fixed: "left",
      customRenderChild: (text: any, record: any) => {
        return (
          <$Avatar shape="square" size="small">
            {getClaimIcon(entityType, viewedList, record)}
          </$Avatar>
        );
      },
    },
  ];
  const lastColumn = {
    key: FollowUpUniqueCol.FINAL_COL,
    title: "",
    dataIndex: FollowUpUniqueCol.FINAL_COL,
  };
  if (isHistory) {
    columns = historyColumns;
  } else {
    columns = resultColumns;
  }
  try {
    followupHeaders.map((dataIndex: string | any) => {
      const dataType = getDataType(followupData[0][`${dataIndex}`]);
      if (getLinkedColumnsForEntity(entityType).includes(dataIndex)) {
        columns.push({
          key: dataIndex,
          title:
            dataIndex == FollowUpUniqueCol.EX_CASE_NO
              ? FollowUpUniqueCol.CASE_NO
              : dataIndex,
          dataIndex: dataIndex,
          className: "text-nowrap",
          width: 120,
          fixed: "left",
          ellipsis: true,
          customSorter: (a: any, b: any) => a - b,
          customFilter: true,
          customRenderChild: (text: any, record: any) => {
            return (
              <NavigationColumn
                entityType={entityType}
                record={record}
                dataIndex={dataIndex}
                isHistory={isHistory}
                followupId={followUpId}
                caseNo={record?.Excaseno}
              />
            );
          },
        });
      } else if (dataIndex == FollowUpUniqueCol.WORKFLOW_STATE) {
        columns.push({
          key: dataIndex,
          title: dataIndex,
          dataIndex,
          className: "text-nowrap",
          width: 150,
          ellipsis: true,
          customSorter: (a: any, b: any) => a.localeCompare(b),
          customFilter: true,
          customRenderChild: (text: any, record: any) => {
            return (
              record[`${dataIndex}`] && (
                <$Tooltip title={record[`${dataIndex}`]}>
                  <$Tag
                    className={`state-tag tag-status-${getColorForWorkFlowState(
                      record[`${dataIndex}`]
                    )}`}
                  >
                    {record[`${dataIndex}`]}
                  </$Tag>
                </$Tooltip>
              )
            );
          },
        });
      } else if (dataIndex == FollowUpUniqueCol.LAST_ACTION_NAME) {
        columns.push({
          key: dataIndex,
          title: dataIndex,
          dataIndex,
          className: "text-nowrap",
          width: 200,
          ellipsis: true,
          customSorter: (a: any, b: any) => a.localeCompare(b),
          customFilter: true,
          customRenderChild: (text: any, record: any) => {
            return (
              record[`${dataIndex}`] && (
                <$Popover
                  placement="topLeft"
                  content={
                    <div>
                      <div>
                        <strong>{record[`${dataIndex}`]}</strong>
                      </div>
                      <div>
                        <$DateLabel
                          value={record[FollowUpUniqueCol.LAST_ACTION_DATE]}
                        />
                        {` by ${record[FollowUpUniqueCol.LAST_ACTION_USER]}`}
                      </div>
                    </div>
                  }
                  destroyTooltipOnHide
                >
                  {record.hasOwnProperty(
                    FollowUpUniqueCol.LAST_ACTION_STATUS
                  ) &&
                    getLastActionStatusIcon(
                      record[FollowUpUniqueCol.LAST_ACTION_STATUS],
                      record[FollowUpUniqueCol.LAST_ACTION_NAME]
                    )}
                  <span>{record[`${dataIndex}`]}</span>
                </$Popover>
              )
            );
          },
        });
      } else if (
        dataIndex != FollowUpUniqueCol.FOLLOWUP_ID &&
        dataIndex != FollowUpUniqueCol.FOLLOWUP_CASE_STATE &&
        dataIndex != FollowUpUniqueCol.CASE_TYPE
      ) {
        columns.push({
          key: dataIndex,
          title: dataIndex == FollowUpUniqueCol.CASE_NO ? "Caseid" : dataIndex,
          dataIndex,
          align: dataType == ColumnDataType.AMOUNT ? "right" : "",
          className:
            dataType == ColumnDataType.AMOUNT
              ? "right-has-sort right-has-filter text-nowrap"
              : "text-nowrap",
          width: setColWidthByDataIndex(dataIndex),
          ellipsis: true,
          customSorter: (a: any, b: any) =>
            dataType == ColumnDataType.AMOUNT ? a - b : a.localeCompare(b),
          customFilter: dataType == ColumnDataType.STRING ? true : dataType,
          customRenderChild: (text: any, record: any) => {
            const cellDataType = getDataType(record[`${dataIndex}`]);
            return (
              <>
                {cellDataType == ColumnDataType.STRING && (
                  <$Tooltip title={record[`${dataIndex}`]}>
                    {record[`${dataIndex}`]}
                  </$Tooltip>
                )}
                {cellDataType == ColumnDataType.AMOUNT && (
                  <$AmountLabel value={Number(record[`${dataIndex}`])} />
                )}
                {cellDataType == ColumnDataType.DATE && (
                  <$DateLabel value={record[`${dataIndex}`]} />
                )}
              </>
            );
          },
        });
      }
    });
    const newColumns = reArrangeColumns(columns, entityType, isHistory);
    return [...newColumns, lastColumn];
  } catch (error) {
    return columns;
  }
};

/**
 * @description - Get rowKey value for the FollowUp result UI table
 * @param entityType - followUpEntity string
 * @returns  unique key for the respective entityType
 */
export const getUniqueFieldName = (
  entityType: string
): FollowUpUniqueColInterface => {
  switch (entityType) {
    case FollowUpEntityType.CASE:
    case FollowUpEntityType.INVOICE:
      return FollowUpUniqueCol.CASE_NO;
    case FollowUpEntityType.DEBTOR:
      return FollowUpUniqueCol.AR_NO;
    case FollowUpEntityType.CREDITOR:
      return FollowUpUniqueCol.CREDITOR_NO;
    case FollowUpEntityType.PAYMENT:
      return FollowUpUniqueCol.PAYMENT_ID;
    default:
      return "";
  }
};

/**
 * @description - Filter followUp result according to the filters we applied
 * @param filters - filters object
 * @param data - API followUp data
 * @returns  Filtered fallowUpData
 */
export const filterFollowUpResult = (
  filters: IFollowUpFilters,
  data: IFollowUpResult
): Array<any> => {
  try {
    const currentUser: any = (
      window._ENV.REACT_APP_AZURE_AD_SETUP
        ? new Azure.ADAuth()
        : new Azure.B2CAuth()
    ).currentUser();
    const caseStateArr = getCaseStateFilterArr(filters);
    const { followupData, entityType } = data;
    const {
      searchText,
      entityType: entityTypeInFilter,
      userOwnedClaims,
      actionStatus,
    } = filters;
    const filteredData = filterFollowUpByLastAction(followupData, actionStatus);
    return filteredData.filter(
      (row: any) =>
        ([FollowUpEntityType.CASE, FollowUpEntityType.INVOICE].includes(
          entityType
        )
          ? caseStateArr.includes(row.Followupcasestate)
          : true) &&
        (entityType == FollowUpEntityType.CASE
          ? matchCaseTypeToFollowUpEntityType(row.Casetype) ==
            entityTypeInFilter
          : entityType == entityTypeInFilter) &&
        Object.values(row).find((field: any) =>
          field.toString().toLowerCase().includes(searchText.toLowerCase())
        ) &&
        (userOwnedClaims &&
        [FollowUpEntityType.CASE, FollowUpEntityType.INVOICE].includes(
          entityType
        )
          ? row.Casehandler == currentUser?.name
          : true)
    );
  } catch (error) {
    return data.followupData;
  }
};

/**
 * * @remarks
 * This method is to get find EntityId with respect to the entityType.
 * @description - Get find EntityId with respect to the entityType
 * @param entityType - entityType
 * @returns  entityId
 */
export const getEntityIdForEntity = (
  entityType: AllFollowUpEntityTypeInterface,
  record: any
): number => {
  switch (entityType) {
    case FollowUpEntityType.CASE:
    case FollowUpEntityType.INVOICE:
      if (
        Object.keys(record).some(
          (field: string) => field == FollowUpUniqueCol.CASE_NO
        )
      ) {
        return record[`${FollowUpUniqueCol.CASE_NO}`];
      } else {
        return 0;
      }
    case FollowUpEntityType.CREDITOR:
      if (
        Object.keys(record).some(
          (field: string) => field == FollowUpUniqueCol.CREDITOR_NO
        )
      ) {
        return record[`${FollowUpUniqueCol.CREDITOR_NO}`];
      } else {
        return 0;
      }
    case FollowUpEntityType.DEBTOR:
      if (
        Object.keys(record).some(
          (field: string) => field == FollowUpUniqueCol.AR_NO
        )
      ) {
        return record[`${FollowUpUniqueCol.AR_NO}`];
      } else {
        return 0;
      }
    case FollowUpEntityType.PAYMENT:
      if (
        Object.keys(record).some(
          (field: string) => field == FollowUpUniqueCol.PAYMENT_ID
        )
      ) {
        return record[`${FollowUpUniqueCol.PAYMENT_ID}`];
      } else if (
        Object.keys(record).some(
          (field: string) => field == FollowUpUniqueCol.PAYMENT_NO
        )
      ) {
        return record[`${FollowUpUniqueCol.PAYMENT_NO}`];
      } else {
        return 0;
      }
    default:
      return 0;
  }
};

/**
 * @function
 * @description set last action status icon
 * @param {string} status last action status
 * @param {string} actionName last action name
 * @returns {JSX.Element} icon
 */
export const getLastActionStatusIcon = (
  status: string,
  actionName: string
): JSX.Element => {
  try {
    switch (status) {
      case LastAction.EXECUTED:
        return <CheckCircleFilled className="mr-1 text-success" />;
      case LastAction.FAILED:
      case LastAction.PENDING_FAILED:
        return <CloseCircleFilled className="mr-1 text-error" />;
      case LastAction.OPEN:
        return <FolderOpenFilled className="mr-1" />;
      case LastAction.PENDING:
        return <ClockCircleFilled className="mr-1 text-warning" />;
      case LastAction.VIEW:
        return <EyeFilled className="mr-1" />;
      default:
        return getNonActivityStatusIcon(actionName);
    }
  } catch (error) {
    return getNonActivityStatusIcon(actionName);
  }
};

/**
 * @function
 * @description set icon according to action name
 * @param {string} actionName last action name
 * @returns {JSX.Element} icon
 */
const getNonActivityStatusIcon = (actionName: string): JSX.Element => {
  try {
    switch (actionName.toUpperCase()) {
      case FollowUpAction.REMOVE:
        return <CloseCircleOutlined className="mr-1 text-error" />;
      case FollowUpAction.DONE:
        return <CheckCircleOutlined className="mr-1 text-success" />;
      case FollowUpAction.POSTPONE:
        return <ClockCircleOutlined className="mr-1 text-warning" />;
      default:
        return <InfoCircleOutlined className="mr-1" />;
    }
  } catch (error) {
    return <InfoCircleOutlined className="mr-1" />;
  }
};

/**
 * @function
 * @description set last updated actions to followup result table
 * @param {Array<any>} followupData followup results
 * @param {Array<ILastAction>} lastActions last updated actions
 * @returns
 */
export const setUpdatedLastActions = (
  followupData: Array<any>,
  lastActions: Array<ILastAction>
): Array<any> => {
  try {
    const newFollowData: Array<any> = [];

    followupData.map((data: any) => {
      const { isMatching, lastAction } = getMatchingRecord(
        data?.Followupid,
        lastActions
      );
      const { isRemoveAfterExecution } = lastAction ?? {
        isRemoveAfterExecution: false,
      };

      if (isMatching) {
        !isRemoveAfterExecution &&
          lastAction &&
          newFollowData.push(mapProperties(data, lastAction));
      } else {
        newFollowData.push(data);
      }
    });

    return newFollowData;
  } catch (error) {
    return followupData;
  }
};

/**
 * @function
 * @description get last action which is relevant to followup result table
 * @param {string | number} followupId followup result table - record followup id
 * @param {Array<ILastAction>} lastActions - updated last actions
 * @returns { isMatching: boolean; lastAction?: ILastAction }
 */
const getMatchingRecord = (
  followupId: string | number,
  lastActions: Array<ILastAction>
): { isMatching: boolean; lastAction?: ILastAction } => {
  try {
    const lastAction = lastActions.find(
      (lastAction: ILastAction) => lastAction.followupdataId == followupId
    );

    return { isMatching: !_.isEmpty(lastAction), lastAction };
  } catch (error) {
    return { isMatching: false };
  }
};

/**
 * @function
 * @description map last action object properties
 * @param followupData followup result table - record
 * @param {ILastAction} lastAction
 * @returns
 */
const mapProperties = (followupData: any, lastAction: ILastAction) => {
  const {
    followupActionsStatus,
    lastActionDate,
    lastActionName,
    lastActionUser,
  } = lastAction;
  try {
    return {
      ...followupData,
      Lastactionname: lastActionName,
      Lastactionuser: lastActionUser,
      Lastactionstatus: followupActionsStatus,
      Lastactiondate: lastActionDate,
    };
  } catch (error) {
    return followupData;
  }
};

/**
 * @function
 * @description set Last action to followup result table immediately, after activity execution
 * @param {any} actionData - followup actions
 * @param {string} lastActionUser current user
 * @returns {Array<ILastAction>}
 */
export const setPendingFollowups = (
  actionData: any,
  lastActionUser: string
): Array<ILastAction> => {
  const { followupdataIds, actionName } = actionData;
  try {
    return followupdataIds.map((followupdataId: string) => {
      return {
        followupdataId,
        followupActionsStatus: LastAction.PENDING,
        isRemoveAfterExecution: false,
        lastActionName: actionName,
        lastActionDate: moment().format(DateFormats.REQ),
        lastActionUser,
      };
    });
  } catch (error) {
    return [];
  }
};

/**
 * @function
 * @description get re-import progressing state
 * @param {number} followupId followup id
 * @param {ReduxAction} action which action is called
 * @param {IInitialState} state redux state
 * @returns  { isFollowupReImporting: boolean; isBulkFollowupReImporting: boolean }
 */
export const getReImportProgressingState = (
  followupId: number,
  action: ReduxAction,
  state: IInitialState
): { isFollowupReImporting: boolean; isBulkFollowupReImporting: boolean; reimportingFollowupId: number } => {
  try {
    const { isBulkFollowupReImporting, isFollowupReImporting } = state;
    if (action == ReduxAction.START) {
      if (followupId == -1) {
        return { isFollowupReImporting, isBulkFollowupReImporting: true, reimportingFollowupId: 0 };
      } else {
        return { isFollowupReImporting: true, isBulkFollowupReImporting, reimportingFollowupId: followupId };
      }
    } else if (action == ReduxAction.SUCCESS) {
      if (followupId == -1) {
        return { isFollowupReImporting, isBulkFollowupReImporting: false, reimportingFollowupId: 0 };
      } else {
        return { isFollowupReImporting: false, isBulkFollowupReImporting, reimportingFollowupId: 0 };
      }
    } else {
      if (followupId == -1) {
        return { isFollowupReImporting, isBulkFollowupReImporting: false, reimportingFollowupId: 0 };
      } else {
        return { isFollowupReImporting: false, isBulkFollowupReImporting, reimportingFollowupId: 0 };
      }
    }
  } catch (error) {
    return { isFollowupReImporting: false, isBulkFollowupReImporting: false, reimportingFollowupId: 0 };
  }
};

/**
 * @description - set select column drop down data
 * @param {Array<string>} columns - column names
 * @returns  modified column object
 */
export const setTableColumnFilter = (
  columns: Array<string>
): { [x: string]: boolean }[] => {
  try {
    if (columns.length > 0) {
      return columns.map((name) => ({ [name]: true }));
    } else return [];
  } catch (error) {
    return [];
  }
};

/**
 * @description - filter column array by a given filter
 * @param {Array<string>} columns - column names
 * @param {string} filter - filter criteria
 * @returns  array without the given filter
 */
export const getFilteredColumnList = (
  columns: Array<string>,
  filter: string
): Array<string> => {
  try {
    if (columns.length > 0) {
      return columns.filter((item) => item != filter);
    } else return [];
  } catch (error) {
    return [];
  }
};

/**
 * @description - set select column drop down data
 * @param {Array<string>} columns - column names
 * @param {string} filter - filter criteria
 * @returns  modified column object
 */
export const getFilteredColumnListWithStates = (
  columns: Array<{ [x: string]: boolean }>,
  filter: string
): { [x: string]: boolean }[] => {
  try {
    if (columns.length > 0) {
      return columns.filter((item) => !item.hasOwnProperty(filter));
    } else return [];
  } catch (error) {
    return [];
  }
};
