const services = {
  development: {
    catalogservice:{
      tenants:"v1"
    },
    entityservice: {
      entity: "v1",
      creditorGroups: "v1",
      postalCodes: "v1",
      "creditorGroups.save": "v1",
      "creditorGroups.update": "v1",
      "creditorGroups.delete": "v1",
      "creditorGroups.withCreditors": "v1",
      "entity.creditors": "v1",
      "entities.otherParties": "v1",
      "entities.otherParty.:entityId": "v1",
      "entities.otherParty": "v1",
      "entities.:entityRoleId": "v1",
      "entities.postalCode": "v1",
      "entities.municipalities": "v1",
      "debtor.adressinfo": "v1",
      "debtor.history": "v1",
      "debtor.debtorInfo": "v1",
      entities: "v1",
      "entities.contactperson": "v1",
      "entities.linkedcreditors": "v1",
      "entities.otherpartyRoleType": "v1",
      "entities.otherparty": "v1",
      "entities.applicablecourts": "v1",
      notehistory: "v1",
    },
    arservice: {
      ars: "v1",
      "ars.otherparty": "v1",
      "otherparty.otherpartyRoleTypes": "v1",
      "otherparty.otherpartyRoleType": "v1",
      "ars.interestData": "v1",
      "ars.case.interest": "v1",
      "atg.search": "v1",
      "atg.download": "v1",
      "objectionsCOP.save": "v1",
      "objectionsDO.save": "v1",
      "objection.profile": "v1",
      "ars.cancelObjection": "v1",
      "interestoccurrences.get": "v1",
      "interestoccurrences.save": "v1",
      "ars.caseInformation": "v1",
      "caseinformationdate.save": "v1",
      "interestoccurrences.delete": "v1",
      "ars.workflowStates": "v1",
      "ars.cancelObjection": "v1",
      "interestoccurrences.get": "v1",
      "interestoccurrences.save": "v1",
      "ars.caseInformation": "v1",
      "caseinformationdate.save": "v1",
      "interestoccurrences.delete": "v1",
      "ars.workflowStates": "v1",
      "casehandlers.get": "v1",
      "casehandlers.patch": "v1",
      "courthandlers.get": "v1",
      "courthandlers.patch": "v1",
      "cases.kids": "v1",
      "cases.closereasons": "v1",
      "cases.updatecase": "v1",
      "cases.list": "v1",
      "cases.merge": "v1",
      "cases.create": "v1",
      "cases.updateDueDate": "v1",
      "cases.split": "v1",
      "ars.changedebtor": "v1",
    },
    uscservice: {
      note: "v1",
      "domainMessage.note": "v1",
      "domainMessage.template": "v1",
      "domainMessage.templateText": "v1",
      "domainMessage.basicInfo": "v1",
      "domainMessage.send": "v1",
      "usc.domainmessage": "v1",
      "usc.domainmessageText": "v1",
      "usc.domainmessage.basicdata": "v1",
      document: "v1",
      templatelist: "v1",
      templatecontent: "v1",
      messagetemplate: "v1",
      messages: "v1",
    },
    paymentagreementservice: {
      paymentAgreement: "v1",
      "paymentAgreement.delete": "v1",
      "paymentAgreement.amount": "v1",
      "paymentAgreement.detail": "v1",
      "paymentAgreement.interest": "v1",
    },
    bmdservice: {
      bmds: "v1",
      creditorbmd: "v1",
      bmdkeyvalue: "v1",
      "bmd.list": "v1",
      "bmd.dataTypes": "v1",
      "bmd.dataTypeValues": "v1",
      "bmdProperty.add": "v1",
      "bmdProperty.change": "v1",
      "bmdPropertyDetails.get": "v1",
      "bmdPropertyHistoryDetails.delete": "v1",
      "bmdcategories.add": "v1",
      "bmdgroups.add": "v1",
      "bmdgroups.update": "v1",
      "changeCount.get": "v1",
      "dataTypes.add": "v1",
      "dataTypes.edit": "v1",
      "dataTypeValueRetrievingSP.get": "v1",
    },
    systemservice: {
      CreditorDetails: "v1",
      entityhistorydetails: "v1",
    },
    paymentService: {
      payments: "v1",
      "payments.apportionments": "v1",
    },
    searchservice: {
      entitysearch: "v1",
      "search.invoice": "v1",
      "search.case": "v1",
      "search.creditor": "v1",
      "search.debtor": "v1",
      "search.history": "v1",
    },
    extendeddataservice: {
      "extendeddata.extendedfields": "v1",
    },
    documentservice: {
      "printed.documents": "v1",
      document: "v1",
      documentCategories: "v1",
      "uploaded.documents": "v1",
      printcancelstatus: "v1"
    },
    activityservice: {
      "activities.settings": "v1",
      activities: "v1",
      activityhistory: "v1",
      pendingactivities: "v1",
      activitiesforfollowup: "v1",
      bulkactivity: "v1",
      "activity.reexecute": "v1"
    },
    transactionservice: {
      "creditNote.add": "v1",
      delete: "v1",
      transactiontypes: "v1",
    },
    noteservice: {
      note: "v1",
      "noteservice.note": "v1",
    },
    partpaymentservice: {
      "addPartPayment.get": "v1",
      "addPartPayment.save": "v1",
      "addPartPayment.delete": "v1",
      "addPartPayment.edit": "v1",
      "addPartPayment.getPayments": "v1",
    },
    assetservice: {
      assets: "v1",
    },
    workflowservice: {
      "workflows.execute": "v1",
      "workflows.workflowgroups": "v1",
      "workflows.savestates": "v1",
      "workflows.updatestates": "v1",
      "workflows.deletestates": "v1",
      "workflows.changestates": "v1",
      "workflows.savestatecategories": "v1",
      "workflows.updatestatecategories": "v1",
      "workflows.deletestatecategories": "v1",
      "workflows.dataadapters": "v1",
      "workflows.getevents": "v1",
      "workflows.saveevents": "v1",
      "workflows.updateevents": "v1",
      "workflows.errorscount": "v1",
      "workflows.errorlist": "v1",
      "workflows.reexecuteworkflow": "v1",
    },
    metadataservice: {
      domiainview: "v1",
    },
    sentenceservice: {
      "sentnece.summery": "v1",
      "sentence.amounts": "v1",
      "sentence.delete": "v1",
      "sentence.details": "v1",
      "sentence.add": "v1",
      "sentence.edit": "v1",
    },
    userservice: {
      "users.authorizationprofile": "v1",
      "users.accessauthoritycomments": "v1",
      directory: "v1",
      users: "v1",
    },
    followupservice: {
      manualfollowupdataset: "v1",
      manualfollowupcategory: "v1",
      manualfollowupreason: "v1",
      followupdataset: "v1",
      handlemanualfollowupitem: "v1",
    },
  },
  qa: {
    catalogservice:{
      tenants:"v1"
    },
    entityservice: {
      entity: "v1",
      creditorGroups: "v1",
      postalCodes: "v1",
      "creditorGroups.save": "v1",
      "creditorGroups.update": "v1",
      "creditorGroups.delete": "v1",
      "creditorGroups.withCreditors": "v1",
      "entity.creditors": "v1",
      "entities.otherParties": "v1",
      "entities.otherParty.:entityId": "v1",
      "entities.otherParty": "v1",
      "debtor.adressinfo": "v1",
      "debtor.history": "v1",
      "debtor.debtorInfo": "v1",
      "entities.:entityRoleId": "v1",
      "entities.postalCode": "v1",
      "entities.municipalities": "v1",
      entities: "v1",
      "entities.contactperson": "v1",
      "entities.linkedcreditors": "v1",
      "entities.otherpartyRoleType": "v1",
      "entities.otherparty": "v1",
      "entities.applicablecourts": "v1",
      notehistory: "v1",
    },
    arservice: {
      ars: "v1",
      "ars.otherparty": "v1",
      "otherparty.otherpartyRoleTypes": "v1",
      "otherparty.otherpartyRoleType": "v1",
      "ars.interestData": "v1",
      "ars.case.interest": "v1",
      "atg.search": "v1",
      "atg.download": "v1",
      "objectionsCOP.save": "v1",
      "objectionsDO.save": "v1",
      "objection.profile": "v1",
      "ars.cancelObjection": "v1",
      "interestoccurrences.get": "v1",
      "interestoccurrences.save": "v1",
      "ars.caseInformation": "v1",
      "caseinformationdate.save": "v1",
      "interestoccurrences.delete": "v1",
      "ars.workflowStates": "v1",
      "ars.cancelObjection": "v1",
      "interestoccurrences.get": "v1",
      "interestoccurrences.save": "v1",
      "ars.caseInformation": "v1",
      "caseinformationdate.save": "v1",
      "interestoccurrences.delete": "v1",
      "ars.workflowStates": "v1",
      "casehandlers.get": "v1",
      "casehandlers.patch": "v1",
      "courthandlers.get": "v1",
      "courthandlers.patch": "v1",
      "cases.kids": "v1",
      "cases.closereasons": "v1",
      "cases.updatecase": "v1",
      "cases.list": "v1",
      "cases.merge": "v1",
      "cases.create": "v1",
      "cases.updateDueDate": "v1",
      "cases.split": "v1",
      "ars.changedebtor": "v1",
    },
    uscservice: {
      note: "v1",
      "domainMessage.note": "v1",
      "domainMessage.template": "v1",
      "domainMessage.templateText": "v1",
      "domainMessage.basicInfo": "v1",
      "domainMessage.send": "v1",
      "usc.domainmessage": "v1",
      "usc.domainmessageText": "v1",
      "usc.domainmessage.basicdata": "v1",
      document: "v1",
      templatelist: "v1",
      templatecontent: "v1",
      messagetemplate: "v1",
      messages: "v1",
    },
    paymentagreementservice: {
      paymentAgreement: "v1",
      "paymentAgreement.delete": "v1",
      "paymentAgreement.amount": "v1",
      "paymentAgreement.detail": "v1",
      "paymentAgreement.interest": "v1",
    },
    bmdservice: {
      bmds: "v1",
      creditorbmd: "v1",
      bmdkeyvalue: "v1",
      "bmd.list": "v1",
      "bmd.dataTypes": "v1",
      "bmd.dataTypeValues": "v1",
      "bmdProperty.add": "v1",
      "bmdProperty.change": "v1",
      "bmdPropertyDetails.get": "v1",
      "bmdPropertyHistoryDetails.delete": "v1",
      "bmdcategories.add": "v1",
      "bmdgroups.add": "v1",
      "bmdgroups.update": "v1",
      "changeCount.get": "v1",
      "dataTypes.add": "v1",
      "dataTypes.edit": "v1",
      "dataTypeValueRetrievingSP.get": "v1",
    },
    systemservice: {
      CreditorDetails: "v1",
      entityhistorydetails: "v1",
    },
    paymentService: {
      payments: "v1",
      "payments.apportionments": "v1",
    },
    searchservice: {
      entitysearch: "v1",
      "search.invoice": "v1",
      "search.case": "v1",
      "search.creditor": "v1",
      "search.debtor": "v1",
      "search.history": "v1",
    },
    documentservice: {
      "printed.documents": "v1",
      document: "v1",
      documentCategories: "v1",
      "uploaded.documents": "v1",
      printcancelstatus: "v1"
    },
    activityservice: {
      "activities.settings": "v1",
      activities: "v1",
      activityhistory: "v1",
      pendingactivities: "v1",
      activitiesforfollowup: "v1",
      bulkactivity: "v1",
      "activity.reexecute": "v1"
    },
    transactionservice: {
      "creditNote.add": "v1",
      delete: "v1",
      transactiontypes: "v1",
    },
    noteservice: {
      note: "v1",
      "noteservice.note": "v1",
    },
    partpaymentservice: {
      "addPartPayment.get": "v1",
      "addPartPayment.save": "v1",
      "addPartPayment.delete": "v1",
      "addPartPayment.edit": "v1",
      "addPartPayment.getPayments": "v1",
    },
    assetservice: {
      assets: "v1",
    },
    workflowservice: {
      "workflows.execute": "v1",
      "workflows.workflowgroups": "v1",
      "workflows.savestates": "v1",
      "workflows.updatestates": "v1",
      "workflows.deletestates": "v1",
      "workflows.changestates": "v1",
      "workflows.savestatecategories": "v1",
      "workflows.updatestatecategories": "v1",
      "workflows.deletestatecategories": "v1",
      "workflows.dataadapters": "v1",
      "workflows.getevents": "v1",
      "workflows.saveevents": "v1",
      "workflows.updateevents": "v1",
      "workflows.errorscount": "v1",
      "workflows.errorlist": "v1",
      "workflows.reexecuteworkflow": "v1",
    },
    metadataservice: {
      domiainview: "v1",
    },
    sentenceservice: {
      "sentnece.summery": "v1",
      "sentence.amounts": "v1",
      "sentence.delete": "v1",
      "sentence.details": "v1",
      "sentence.add": "v1",
      "sentence.edit": "v1",
    },
    userservice: {
      "users.authorizationprofile": "v1",
      "users.accessauthoritycomments": "v1",
      directory: "v1",
      users: "v1",
    },
    followupservice: {
      manualfollowupdataset: "v1",
      manualfollowupcategory: "v1",
      manualfollowupreason: "v1",
      followupdataset: "v1",
      handlemanualfollowupitem: "v1",
    },
  },
  uat: {
    catalogservice:{
      tenants:"v1"
    },
    entityservice: {
      entity: "v1",
      creditorGroups: "v1",
      postalCodes: "v1",
      "creditorGroups.save": "v1",
      "creditorGroups.update": "v1",
      "creditorGroups.delete": "v1",
      "creditorGroups.withCreditors": "v1",
      "entity.creditors": "v1",
      "entities.otherParties": "v1",
      "entities.otherParty.:entityId": "v1",
      "entities.otherParty": "v1",
      "debtor.adressinfo": "v1",
      "debtor.history": "v1",
      "debtor.debtorInfo": "v1",
      "entities.:entityRoleId": "v1",
      "entities.postalCode": "v1",
      "entities.municipalities": "v1",
      entities: "v1",
      "entities.contactperson": "v1",
      "entities.linkedcreditors": "v1",
      "entities.otherpartyRoleType": "v1",
      "entities.otherparty": "v1",
      "entities.applicablecourts": "v1",
      notehistory: "v1",
    },
    arservice: {
      ars: "v1",
      "ars.otherparty": "v1",
      "otherparty.otherpartyRoleTypes": "v1",
      "otherparty.otherpartyRoleType": "v1",
      "ars.interestData": "v1",
      "ars.case.interest": "v1",
      "atg.search": "v1",
      "atg.download": "v1",
      "objectionsCOP.save": "v1",
      "objectionsDO.save": "v1",
      "objection.profile": "v1",
      "ars.cancelObjection": "v1",
      "interestoccurrences.get": "v1",
      "interestoccurrences.save": "v1",
      "ars.caseInformation": "v1",
      "caseinformationdate.save": "v1",
      "interestoccurrences.delete": "v1",
      "ars.workflowStates": "v1",
      "ars.cancelObjection": "v1",
      "interestoccurrences.get": "v1",
      "interestoccurrences.save": "v1",
      "ars.caseInformation": "v1",
      "caseinformationdate.save": "v1",
      "interestoccurrences.delete": "v1",
      "ars.workflowStates": "v1",
      "casehandlers.get": "v1",
      "casehandlers.patch": "v1",
      "courthandlers.get": "v1",
      "courthandlers.patch": "v1",
      "cases.kids": "v1",
      "cases.closereasons": "v1",
      "cases.updatecase": "v1",
      "cases.list": "v1",
      "cases.merge": "v1",
      "cases.create": "v1",
      "cases.updateDueDate": "v1",
      "cases.split": "v1",
      "ars.changedebtor": "v1",
    },
    uscservice: {
      note: "v1",
      "domainMessage.note": "v1",
      "domainMessage.template": "v1",
      "domainMessage.templateText": "v1",
      "domainMessage.basicInfo": "v1",
      "domainMessage.send": "v1",
      "usc.domainmessage": "v1",
      "usc.domainmessageText": "v1",
      "usc.domainmessage.basicdata": "v1",
      document: "v1",
      templatelist: "v1",
      templatecontent: "v1",
      messagetemplate: "v1",
      messages: "v1",
    },
    paymentagreementservice: {
      paymentAgreement: "v1",
      "paymentAgreement.delete": "v1",
      "paymentAgreement.amount": "v1",
      "paymentAgreement.detail": "v1",
      "paymentAgreement.interest": "v1",
      "bmdPropertyHistoryDetails.delete": "v1",
    },
    bmdservice: {
      bmds: "v1",
      creditorbmd: "v1",
      bmdkeyvalue: "v1",
      "bmd.list": "v1",
      "bmd.dataTypes": "v1",
      "bmd.dataTypeValues": "v1",
      "bmdProperty.add": "v1",
      "bmdProperty.change": "v1",
      "bmdPropertyDetails.get": "v1",
      "bmdcategories.add": "v1",
      "bmdgroups.add": "v1",
      "bmdgroups.update": "v1",
      "changeCount.get": "v1",
      "dataTypes.add": "v1",
      "dataTypes.edit": "v1",
      "dataTypeValueRetrievingSP.get": "v1",
    },
    systemservice: {
      CreditorDetails: "v1",
      entityhistorydetails: "v1",
    },
    paymentService: {
      payments: "v1",
      "payments.apportionments": "v1",
    },
    searchservice: {
      entitysearch: "v1",
      "search.invoice": "v1",
      "search.case": "v1",
      "search.creditor": "v1",
      "search.debtor": "v1",
      "search.history": "v1",
    },
    extendeddataservice: {
      "extendeddata.extendedfields": "v1",
    },
    documentservice: {
      "printed.documents": "v1",
      document: "v1",
      documentCategories: "v1",
      "uploaded.documents": "v1",
      printcancelstatus: "v1"
    },
    activityservice: {
      "activities.settings": "v1",
      activities: "v1",
      activityhistory: "v1",
      pendingactivities: "v1",
      activitiesforfollowup: "v1",
      bulkactivity: "v1",
      "activity.reexecute": "v1"
    },
    transactionservice: {
      "creditNote.add": "v1",
      delete: "v1",
      transactiontypes: "v1",
    },
    noteservice: {
      note: "v1",
      "noteservice.note": "v1",
    },
    partpaymentservice: {
      "addPartPayment.get": "v1",
      "addPartPayment.save": "v1",
      "addPartPayment.delete": "v1",
      "addPartPayment.edit": "v1",
      "addPartPayment.getPayments": "v1",
    },
    assetservice: {
      assets: "v1",
    },
    workflowservice: {
      "workflows.execute": "v1",
      "workflows.workflowgroups": "v1",
      "workflows.savestates": "v1",
      "workflows.updatestates": "v1",
      "workflows.deletestates": "v1",
      "workflows.changestates": "v1",
      "workflows.savestatecategories": "v1",
      "workflows.updatestatecategories": "v1",
      "workflows.deletestatecategories": "v1",
      "workflows.dataadapters": "v1",
      "workflows.getevents": "v1",
      "workflows.saveevents": "v1",
      "workflows.updateevents": "v1",
      "workflows.errorscount": "v1",
      "workflows.errorlist": "v1",
      "workflows.reexecuteworkflow": "v1",
    },
    metadataservice: {
      domiainview: "v1",
    },
    sentenceservice: {
      "sentnece.summery": "v1",
      "sentence.amounts": "v1",
      "sentence.delete": "v1",
      "sentence.details": "v1",
      "sentence.add": "v1",
      "sentence.edit": "v1",
    },
    userservice: {
      "users.authorizationprofile": "v1",
      "users.accessauthoritycomments": "v1",
      directory: "v1",
      users: "v1",
    },
    followupservice: {
      manualfollowupdataset: "v1",
      manualfollowupcategory: "v1",
      manualfollowupreason: "v1",
      followupdataset: "v1",
      handlemanualfollowupitem: "v1",
    },
  },
  pp: {
    catalogservice:{
      tenants:"v1"
    },
    entityservice: {
      entity: "v1",
      creditorGroups: "v1",
      postalCodes: "v1",
      "creditorGroups.save": "v1",
      "creditorGroups.update": "v1",
      "creditorGroups.delete": "v1",
      "creditorGroups.withCreditors": "v1",
      "entity.creditors": "v1",
      "entities.otherParties": "v1",
      "entities.otherParty.:entityId": "v1",
      "entities.otherParty": "v1",
      "debtor.adressinfo": "v1",
      "debtor.history": "v1",
      "debtor.debtorInfo": "v1",
      "entities.:entityRoleId": "v1",
      "entities.postalCode": "v1",
      "entities.municipalities": "v1",
      entities: "v1",
      "entities.contactperson": "v1",
      "entities.linkedcreditors": "v1",
      "entities.otherpartyRoleType": "v1",
      "entities.otherparty": "v1",
      "entities.applicablecourts": "v1",
      notehistory: "v1",
    },
    arservice: {
      ars: "v1",
      "ars.otherparty": "v1",
      "otherparty.otherpartyRoleTypes": "v1",
      "otherparty.otherpartyRoleType": "v1",
      "ars.interestData": "v1",
      "ars.case.interest": "v1",
      "atg.search": "v1",
      "atg.download": "v1",
      "objectionsCOP.save": "v1",
      "objectionsDO.save": "v1",
      "objection.profile": "v1",
      "ars.cancelObjection": "v1",
      "interestoccurrences.get": "v1",
      "interestoccurrences.save": "v1",
      "ars.caseInformation": "v1",
      "caseinformationdate.save": "v1",
      "interestoccurrences.delete": "v1",
      "ars.workflowStates": "v1",
      "ars.cancelObjection": "v1",
      "interestoccurrences.get": "v1",
      "interestoccurrences.save": "v1",
      "ars.caseInformation": "v1",
      "caseinformationdate.save": "v1",
      "interestoccurrences.delete": "v1",
      "ars.workflowStates": "v1",
      "casehandlers.get": "v1",
      "casehandlers.patch": "v1",
      "courthandlers.get": "v1",
      "courthandlers.patch": "v1",
      "cases.kids": "v1",
      "cases.closereasons": "v1",
      "cases.updatecase": "v1",
      "cases.list": "v1",
      "cases.merge": "v1",
      "cases.create": "v1",
      "cases.updateDueDate": "v1",
      "cases.split": "v1",
      "ars.changedebtor": "v1",
    },
    uscservice: {
      note: "v1",
      "domainMessage.note": "v1",
      "domainMessage.template": "v1",
      "domainMessage.templateText": "v1",
      "domainMessage.basicInfo": "v1",
      "domainMessage.send": "v1",
      "usc.domainmessage": "v1",
      "usc.domainmessageText": "v1",
      "usc.domainmessage.basicdata": "v1",
      document: "v1",
      templatelist: "v1",
      templatecontent: "v1",
      messagetemplate: "v1",
      messages: "v1",
    },
    paymentagreementservice: {
      paymentAgreement: "v1",
      "paymentAgreement.delete": "v1",
      "paymentAgreement.amount": "v1",
      "paymentAgreement.detail": "v1",
      "paymentAgreement.interest": "v1",
    },
    bmdservice: {
      bmds: "v1",
      creditorbmd: "v1",
      bmdkeyvalue: "v1",
      "bmd.list": "v1",
      "bmd.dataTypes": "v1",
      "bmd.dataTypeValues": "v1",
      "bmdProperty.add": "v1",
      "bmdProperty.change": "v1",
      "bmdPropertyDetails.get": "v1",
      "bmdPropertyHistoryDetails.delete": "v1",
      "bmdcategories.add": "v1",
      "bmdgroups.add": "v1",
      "bmdgroups.update": "v1",
      "changeCount.get": "v1",
      "dataTypes.add": "v1",
      "dataTypes.edit": "v1",
      "dataTypeValueRetrievingSP.get": "v1",
    },
    systemservice: {
      CreditorDetails: "v1",
      entityhistorydetails: "v1",
    },
    paymentService: {
      payments: "v1",
      "payments.apportionments": "v1",
    },
    searchservice: {
      entitysearch: "v1",
      "search.invoice": "v1",
      "search.case": "v1",
      "search.creditor": "v1",
      "search.debtor": "v1",
      "search.history": "v1",
    },
    extendeddataservice: {
      "extendeddata.extendedfields": "v1",
    },
    documentservice: {
      "printed.documents": "v1",
      document: "v1",
      documentCategories: "v1",
      "uploaded.documents": "v1",
      printcancelstatus: "v1"
    },
    activityservice: {
      "activities.settings": "v1",
      activities: "v1",
      activityhistory: "v1",
      pendingactivities: "v1",
      activitiesforfollowup: "v1",
      bulkactivity: "v1",
      "activity.reexecute": "v1"
    },
    transactionservice: {
      "creditNote.add": "v1",
      delete: "v1",
      transactiontypes: "v1",
    },
    noteservice: {
      note: "v1",
      "noteservice.note": "v1",
    },
    partpaymentservice: {
      "addPartPayment.get": "v1",
      "addPartPayment.save": "v1",
      "addPartPayment.delete": "v1",
      "addPartPayment.edit": "v1",
      "addPartPayment.getPayments": "v1",
    },
    assetservice: {
      assets: "v1",
    },
    workflowservice: {
      "workflows.execute": "v1",
      "workflows.workflowgroups": "v1",
      "workflows.savestates": "v1",
      "workflows.updatestates": "v1",
      "workflows.deletestates": "v1",
      "workflows.changestates": "v1",
      "workflows.savestatecategories": "v1",
      "workflows.updatestatecategories": "v1",
      "workflows.deletestatecategories": "v1",
      "workflows.dataadapters": "v1",
      "workflows.getevents": "v1",
      "workflows.saveevents": "v1",
      "workflows.updateevents": "v1",
      "workflows.errorscount": "v1",
      "workflows.errorlist": "v1",
      "workflows.reexecuteworkflow": "v1",
    },
    metadataservice: {
      domiainview: "v1",
    },
    sentenceservice: {
      "sentnece.summery": "v1",
      "sentence.amounts": "v1",
      "sentence.delete": "v1",
      "sentence.details": "v1",
      "sentence.add": "v1",
      "sentence.edit": "v1",
    },
    userservice: {
      "users.authorizationprofile": "v1",
      "users.accessauthoritycomments": "v1",
      directory: "v1",
      users: "v1",
    },
    followupservice: {
      manualfollowupdataset: "v1",
      manualfollowupcategory: "v1",
      manualfollowupreason: "v1",
      followupdataset: "v1",
      handlemanualfollowupitem: "v1",
    },
  },
  production: {
    catalogservice:{
      tenants:"v1"
    },
    entityservice: {
      entity: "v1",
      creditorGroups: "v1",
      postalCodes: "v1",
      "creditorGroups.save": "v1",
      "creditorGroups.update": "v1",
      "creditorGroups.delete": "v1",
      "creditorGroups.withCreditors": "v1",
      "entity.creditors": "v1",
      "entities.otherParties": "v1",
      "entities.otherParty.:entityId": "v1",
      "entities.otherParty": "v1",
      "debtor.adressinfo": "v1",
      "debtor.history": "v1",
      "debtor.debtorInfo": "v1",
      "entities.:entityRoleId": "v1",
      "entities.postalCode": "v1",
      "entities.municipalities": "v1",
      entities: "v1",
      "entities.contactperson": "v1",
      "entities.linkedcreditors": "v1",
      "entities.otherpartyRoleType": "v1",
      "entities.otherparty": "v1",
      "entities.applicablecourts": "v1",
      notehistory: "v1",
    },
    arservice: {
      ars: "v1",
      "ars.otherparty": "v1",
      "otherparty.otherpartyRoleTypes": "v1",
      "otherparty.otherpartyRoleType": "v1",
      "ars.interestData": "v1",
      "ars.case.interest": "v1",
      "atg.search": "v1",
      "atg.download": "v1",
      "objectionsCOP.save": "v1",
      "objectionsDO.save": "v1",
      "objection.profile": "v1",
      "ars.cancelObjection": "v1",
      "interestoccurrences.get": "v1",
      "interestoccurrences.save": "v1",
      "ars.caseInformation": "v1",
      "caseinformationdate.save": "v1",
      "interestoccurrences.delete": "v1",
      "ars.workflowStates": "v1",
      "ars.cancelObjection": "v1",
      "interestoccurrences.get": "v1",
      "interestoccurrences.save": "v1",
      "ars.caseInformation": "v1",
      "caseinformationdate.save": "v1",
      "interestoccurrences.delete": "v1",
      "ars.workflowStates": "v1",
      "casehandlers.get": "v1",
      "casehandlers.patch": "v1",
      "courthandlers.get": "v1",
      "courthandlers.patch": "v1",
      "cases.kids": "v1",
      "cases.closereasons": "v1",
      "cases.updatecase": "v1",
      "cases.list": "v1",
      "cases.merge": "v1",
      "cases.create": "v1",
      "cases.updateDueDate": "v1",
      "cases.split": "v1",
      "ars.changedebtor": "v1",
    },
    uscservice: {
      note: "v1",
      "domainMessage.note": "v1",
      "domainMessage.template": "v1",
      "domainMessage.templateText": "v1",
      "domainMessage.basicInfo": "v1",
      "domainMessage.send": "v1",
      "usc.domainmessage": "v1",
      "usc.domainmessageText": "v1",
      "usc.domainmessage.basicdata": "v1",
      document: "v1",
      templatelist: "v1",
      templatecontent: "v1",
      messagetemplate: "v1",
      messages: "v1",
    },
    paymentagreementservice: {
      paymentAgreement: "v1",
      "paymentAgreement.delete": "v1",
      "paymentAgreement.amount": "v1",
      "paymentAgreement.detail": "v1",
      "paymentAgreement.interest": "v1",
    },
    bmdservice: {
      bmds: "v1",
      creditorbmd: "v1",
      bmdkeyvalue: "v1",
      "bmd.list": "v1",
      "bmd.dataTypes": "v1",
      "bmd.dataTypeValues": "v1",
      "bmdProperty.add": "v1",
      "bmdProperty.change": "v1",
      "bmdPropertyDetails.get": "v1",
      "bmdPropertyHistoryDetails.delete": "v1",
      "bmdcategories.add": "v1",
      "bmdgroups.add": "v1",
      "bmdgroups.update": "v1",
      "changeCount.get": "v1",
      "dataTypes.add": "v1",
      "dataTypes.edit": "v1",
      "dataTypeValueRetrievingSP.get": "v1",
    },
    systemservice: {
      CreditorDetails: "v1",
      entityhistorydetails: "v1",
    },
    paymentService: {
      payments: "v1",
      "payments.apportionments": "v1",
    },
    searchservice: {
      entitysearch: "v1",
      "search.invoice": "v1",
      "search.case": "v1",
      "search.creditor": "v1",
      "search.debtor": "v1",
      "search.history": "v1",
    },
    extendeddataservice: {
      "extendeddata.extendedfields": "v1",
    },
    documentservice: {
      "printed.documents": "v1",
      document: "v1",
      documentCategories: "v1",
      "uploaded.documents": "v1",
      printcancelstatus: "v1"
    },
    activityservice: {
      "activities.settings": "v1",
      activities: "v1",
      activityhistory: "v1",
      pendingactivities: "v1",
      activitiesforfollowup: "v1",
      bulkactivity: "v1",
      "activity.reexecute": "v1"
    },
    transactionservice: {
      "creditNote.add": "v1",
      delete: "v1",
      transactiontypes: "v1",
    },
    noteservice: {
      note: "v1",
      "noteservice.note": "v1",
    },
    partpaymentservice: {
      "addPartPayment.get": "v1",
      "addPartPayment.save": "v1",
      "addPartPayment.delete": "v1",
      "addPartPayment.edit": "v1",
      "addPartPayment.getPayments": "v1",
    },
    assetservice: {
      assets: "v1",
    },
    workflowservice: {
      "workflows.execute": "v1",
      "workflows.workflowgroups": "v1",
      "workflows.savestates": "v1",
      "workflows.updatestates": "v1",
      "workflows.deletestates": "v1",
      "workflows.changestates": "v1",
      "workflows.savestatecategories": "v1",
      "workflows.updatestatecategories": "v1",
      "workflows.deletestatecategories": "v1",
      "workflows.dataadapters": "v1",
      "workflows.getevents": "v1",
      "workflows.saveevents": "v1",
      "workflows.updateevents": "v1",
      "workflows.errorscount": "v1",
      "workflows.errorlist": "v1",
      "workflows.reexecuteworkflow": "v1",
    },
    metadataservice: {
      domiainview: "v1",
    },
    sentenceservice: {
      "sentnece.summery": "v1",
      "sentence.amounts": "v1",
      "sentence.delete": "v1",
      "sentence.details": "v1",
      "sentence.add": "v1",
      "sentence.edit": "v1",
    },
    userservice: {
      "users.authorizationprofile": "v1",
      "users.accessauthoritycomments": "v1",
      directory: "v1",
      users: "v1",
    },
    followupservice: {
      manualfollowupdataset: "v1",
      manualfollowupcategory: "v1",
      manualfollowupreason: "v1",
      followupdataset: "v1",
      handlemanualfollowupitem: "v1",
    },
  },
};

export default () => services[process.env.NODE_ENV];
