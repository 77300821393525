import {  IDebtorHistory} from 'us.collection.debtor/Interfaces'
import moment from 'moment';

export const debtorHistory: IDebtorHistory[] = [{
    id: 0,
    debtorNo: 0,
    fullName: "",
    birthDate:moment('0001-01-01T00:00:00'),
    personNo: '',
    title: "First Name",
    orginalRegDate: moment('0001-01-01T00:00:00'),
    regDate: moment('0001-01-01T00:00:00'),
    createdUser: "",
    modifiedUser: ""
}]