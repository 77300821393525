import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    email: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ENTER_THE_EMAIL"
    ).email(
      "US.COLLECTION.VALIDATIONS:REQUIRED.SHOULD_BE_VALID_EMAIL"
    ).nullable(),
    creditorName: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ENTER_THE_CREDITOR_NAME"
    ),
    creditorNumber: Yup.number().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ENTER_THE_CREDITOR_NUMBER"
    ),
    account: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ENTER_A_ACCOUNT_NUMBER"
    ),
    postalCode: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ENTER_A_VALID_POSTAL_CODE"
    ),
    productNo: Yup.number().max(999999999,'US.COLLECTION.VALIDATIONS:REQUIRED.INVALID_PRODUCT_NUMBER'
    ),
    direct: Yup.string() 
      .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD') 
      .matches(/^\d{4,15}$/g, 'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS') 
      .nullable(),
    mobile: Yup.string() 
      .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD') 
      .matches(/^\d{4,15}$/g, 'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS') 
      .nullable(),
    office: Yup.string() 
      .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD') 
      .matches(/^\d{4,15}$/g, 'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS') 
      .nullable(),
    im1: Yup.string() 
      .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD') 
      .matches(/^\d{4,15}$/g, 'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS') 
      .nullable(),
    im2: Yup.string() 
      .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD') 
      .matches(/^\d{4,15}$/g, 'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS') 
      .nullable(),
    im3: Yup.string() 
      .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD') 
      .matches(/^\d{4,15}$/g, 'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS') 
      .nullable(),
  });
};
