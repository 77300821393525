import * as Yup from "yup";

interface IAddArNote extends Yup.MixedSchema {
  noteText: string;
}
const AddArNoteValidation = Yup.object<IAddArNote>().shape({
  noteText: Yup.string()
    .test(
      "comment",
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD",
      (val: any) => {
        let value = typeof val == "undefined" ? "" : val;
        return value.toString().trim().length > 0;
      }
    )
    .required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
    ),
});

export default AddArNoteValidation;
