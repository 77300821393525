import moment from 'moment';
import { IAddressHistory } from 'us.collection.debtor/Interfaces'

export const addressDetail: IAddressHistory[] = [{
    debtorNo: "",
    address1: "",
    address2: "",
    address3: "",
    zipCode: '',
    zipName: "",
    country: "",
    telMobileNo: "",
    telWork: "",
    telHome: "",
    email: "",
    fax: "",
    msn: "",
    skype: "",
    sms: "",
    municipality: "",
    regDateTime: moment('0001-01-01T00:00:00'),
    modifiedUser: "",
    rowState: "",
    modifiedDate: moment('0001-01-01T00:00:00'),
    addrSource: ""
}]