import { ChangeDebtor } from "us.collection.debtor/constants/Actions";
import { initialState } from "./State";
const {
  CHANGE_DEBTOR_SUCCESS,
  CHANGE_DEBTOR_DRAWER,
} = ChangeDebtor;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case CHANGE_DEBTOR_SUCCESS:
      return Object.assign({}, state, {
        debtorDrawer: { title: "", visible: false}
      });
    case CHANGE_DEBTOR_DRAWER:
      return Object.assign({}, state, {
        debtorDrawer: payload?.data
      });
    default:
      return state;
  }
};
