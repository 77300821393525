import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Common from 'us.common';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Actions from 'us.collection.routines-and-activities/actions';
import {
  dateFormats,
  DocumentType,
} from "us.collection.routines-and-activities/constants";
import { HistoryItem } from "./Components";
import { ILog } from "./Interfaces";
import { DOCUMENT_DRAWER_DEFAULT, LogHistoryOptions } from "./Constants";
import { DocumentViewer } from "../DocumentViewer";
import { AttachmentViewer } from "../AttachmentViewer";
import {
  changeExtension,
  getDrawerWidthByDocumentType,
  filterHistoryTypesByBMDValue
} from "us.collection.routines-and-activities/functions";
import { DocumentDrawer } from "us.collection.routines-and-activities/interfaces";
import { Note } from "us.collection.routines-and-activities/components/Note";
import { RegisteredUserType, ActivityStatusForPayload, ActivityTypesForPayload } from 'us.collection/constants';
import { RootState } from "us.helper/types";
import { BarsOutlined, BoxIcons, IconTypes } from "us.icons";
import './Log.scss';

const {
  $Row,
  $Col,
  $Input,
  $DatePicker,
  $Select,
  $Empty,
  $Radio,
  $Skeleton,
  $Divider,
  $Drawer,
  $Tooltip,
} = Common.Components;

const Log: React.FC<ILog & PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const {
	authorizationProfile,
    currentDateFormat,
    summaryData,
    note,
    attachement,
    filterValue,
    updateActivityLog,
    metaData,
    getLogHistory,
    filter,
    search,
    onMaximize,
    reset,
  } = props;

  const { caseId, entityType } = metaData.data ?? {};
  const { enableCreditorPortalNotes } = authorizationProfile ?? {};

  const [drawerInfo, setDrawerInfo] = useState<DocumentDrawer>(
    DOCUMENT_DRAWER_DEFAULT
  );

  useEffect(() => {
    reset && reset({});
    getHistory(caseId);
  }, [metaData, updateActivityLog]);

  useEffect(() => {
    if (Array.isArray(attachement.data) && attachement.data.length > 0) {
      setDrawerInfo({
        ...drawerInfo,
        title: getDocumentName(),
      });
    }
  }, [attachement]);

  /**
   *
   *@param {number} caseId
   */
  const getHistory = useCallback(
    (caseId: number) => {
      if (caseId) {
        getLogHistory &&
          getLogHistory({
            entityId: caseId,
            entityType: entityType,
            activityType: ActivityTypesForPayload.MESSAGE,
			activityStatus: ActivityStatusForPayload.SUCCESS,
			registeredUserType: RegisteredUserType.ALL,
          });
      }
    },
    [caseId, entityType]
  );

  const closeDrawer = () => {
    setDrawerInfo(DOCUMENT_DRAWER_DEFAULT);
  };

  const searchValue = (values: any) => {
    search &&
      search({
        name: values.hasOwnProperty("search") ? values?.search : "",
        date: values.hasOwnProperty("searchDate")
          ? values?.searchDate?.format(dateFormats.req)
          : "",
        type: values.hasOwnProperty("type") ? values?.type : "",
      });
  };

  // get document name
  const getDocumentName = (): string => {
    if (drawerInfo.isXmlPdf) {
      return changeExtension(
        attachement.data[0].documentName,
        DocumentType.PDF
      );
    } else {
      return attachement.data[0].documentName;
    }
  };

  // handle on press history item
  const onViewHistoryItem = (data: any) => {
    const { type } = data ?? {};
    setDrawerInfo({
      documentType: type,
      title:
        type === DocumentType.TEXT
          ? "US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.EDIT_NOTE"
          : "",
      isVisible: true,
      data,
    });
  };

  // handle history item icon click event
  const onHandleHistoryIconPress = (
    documentType: any,
    data: any,
    isXmlPdf?: boolean
  ) => {
    setDrawerInfo({
      title: "",
      isVisible: true,
      data,
      documentType,
      isXmlPdf,
    });
  };

  const documentTypeOptions = [
    {
      label: t("US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.EMAIL"),
      value: DocumentType.EMAIL,
    },
    {
      label: t("US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.PDF"),
      value: DocumentType.PDF,
    },
    {
      label: t("US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.SMS"),
      value: DocumentType.SMS,
    },
    {
      label: t("US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.TEXT"),
      value: DocumentType.TEXT,
    },
    ,
    {
      label: t("US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.XML"),
      value: DocumentType.XML,
    },
  ];

  const historyTypeOptions = [
    {
      value: LogHistoryOptions.ALL,
      label: (
        <$Tooltip title={t("US.COLLECTION.CASE:CASELOG.ALL")} placement="top">
          <div>
            <BarsOutlined />
          </div>
        </$Tooltip>
      ),
    },
    {
      value: LogHistoryOptions.CASE_LOGS,
      label: (
        <$Tooltip
          title={t("US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.NOTES")}
          placement="top"
        >
          <div>
            <BoxIcons type={IconTypes.BOX_ICON} name="case-note" />
          </div>
        </$Tooltip>
      ),
    },
    {
      value: LogHistoryOptions.CP_NOTES,
      label: (
        <$Tooltip
          title={t("US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.CREDITOR_NOTE")}
          placement="top"
        >
          <div>
            <BoxIcons type={IconTypes.BOX_ICON} name="creditor-notes" />
          </div>
        </$Tooltip>
      ),
    },
    {
      value: LogHistoryOptions.ACTIVITIES,
      label: (
        <$Tooltip
          title={t(
            "US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.MESSAGE_ACTIVITIES"
          )}
          placement="top"
        >
          <div>
            <BoxIcons type={IconTypes.BOX_ICON} name="message-activity" />
          </div>
        </$Tooltip>
      ),
    },
  ];

  return (
    <Formik enableReinitialize initialValues={{}} onSubmit={() => {}}>
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...rest
      }: any) => (
        <div className="widget case-logs">
          <div className="pt-2 pb-2">
            <div
              className="widget-header widget-link-header d-flex align-items-center px-0"
              onClick={onMaximize}
              data-testid="dashboard-history-widget"
            >
              <span className="widget-title">
                {t("US.COLLECTION.CASE:CASELOG.HISTORY")}
              </span>
            </div>
          </div>
          <$Skeleton
            loading={note?.isLoading || metaData.isLoading}
            active
            paragraph={{ rows: 4 }}
          >
            <div className="widget-body px-0">
              <div>
                <$Row gutter={8} align="top" className="mb-2">
                  <$Col className="gutter-row" span={9}>
                    <$Input
                      size="small"
                      placeholder={t("US.COLLECTION.COMMON:COMMON.SEARCH")}
                      name="search"
                      onChange={(e: any) => {
                        rest.setFieldValue("search", e.target.value);
                        searchValue({
                          ...values,
                          search: e.target.value,
                        });
                      }}
                      className="w-100"
                      allowClear={true}
                      data-testid="dashboard-history-search"
                    />
                  </$Col>
                  <$Col className="gutter-row" span={9}>
                    <$DatePicker
                      name="searchDate"
                      size="small"
                      allowClear
                      format={currentDateFormat}
                      placeholder={t(
                        "US.COLLECTION.ROUTINESANDACTIVITIES:RUNACTIVITY.SELECTDATE"
                      )}
                      value={values.searchDate}
                      onChange={(e: any) => {
                        rest.setFieldValue("searchDate", e);
                        searchValue({
                          ...values,
                          searchDate: e,
                        });
                      }}
                      data-testid="dashboard-history-searchDate"
                    />
                  </$Col>
                  <$Col className="gutter-row" span={6}>
                    <$Select
                      allOption={true}
                      optionText="label"
                      optionValue="value"
                      defaultValue="all"
                      className="mb-1"
                      style={{
                        width: "100%",
                        marginTop: "4px",
                      }}
                      size="small"
                      name="type"
                      options={documentTypeOptions}
                      placeholder={t(
                        "US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.DOC_TYPE"
                      )}
                      onChange={(e: any) => {
                        searchValue({
                          ...values,
                          type: e,
                        });
                      }}
                      onSearchBy={["label"]}
                      data-testid="dashboard-history-type"
                    />
                  </$Col>
                </$Row>
                <$Radio
                  name="filter"
                  optionText="label"
                  optionValue="value"
                  size="small"
                  defaultValue={LogHistoryOptions.ALL}
                  value={filterValue}
                  optionType="button"
                  buttonStyle="default"
                  radioButton="true"
                  optionContent={(item: any) => item.label}
                  className="d-flex mb-3 cl-type-filter"
                  optionStyle="flex-fill text-center"
                  onChange={(e: any) => {
                    filter &&
                      filter({
                        filterValue: e.target.value,
                      });
                  }}
                  options={filterHistoryTypesByBMDValue(
					historyTypeOptions,
					enableCreditorPortalNotes
				  )}
                  data-testid="dashboard-history-radioFilter"
                />
              </div>

              {summaryData.data.length <= 0 && (
                <>
                  <$Divider className="mt-0 mb-4" />
                  <$Empty
                    className="my-0"
                    description={`${t("US.COMMON:COMMON.NO_DATA")}`}
                    image={$Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                </>
              )}
              {summaryData.data.length > 0 && (
                <div className="cl-list">
                  {summaryData.data.map((item: any) => (
                    <HistoryItem
                      key={item.id}
                      item={item}
                      onPress={() => {
                        onViewHistoryItem(item);
                      }}
                      onPressIcon={(type: any, isXmlPdf?: boolean) => {
                        onHandleHistoryIconPress(type, item, isXmlPdf);
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          </$Skeleton>

          <$Drawer
            title={
              drawerInfo.documentType == DocumentType.TEXT
                ? t(drawerInfo.title)
                : drawerInfo.title
            }
            width={getDrawerWidthByDocumentType(drawerInfo.documentType)}
            visible={drawerInfo.isVisible}
            onClose={closeDrawer}
            destroyOnClose
            placement="left"
            className={
              drawerInfo.documentType === DocumentType.ATTACHEMENT
                ? "history-attach-viwer"
                : "history-doc-viwer"
            }
          >
            {![DocumentType.TEXT, DocumentType.ATTACHEMENT, DocumentType.UNKNOWN].includes(
              drawerInfo.documentType
            ) && (
              <DocumentViewer
                isXmlPdf={drawerInfo.isXmlPdf}
                documentType={drawerInfo.documentType}
                data={drawerInfo.data}
                onClose={closeDrawer}
              />
            )}
            {drawerInfo.documentType === DocumentType.TEXT && (
              <Fragment>
                <Note {...props} close={closeDrawer} data={drawerInfo.data} />
              </Fragment>
            )}
            {drawerInfo.documentType === DocumentType.ATTACHEMENT && (
              <AttachmentViewer
                communicationJobId={drawerInfo.data?.communicationJobId}
                onClose={closeDrawer}
              />
            )}
          </$Drawer>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  const { common, domainView, logHistory, messageAndNotes, AuthorizationProfile } = state;
  const { currentLanguage, currentDateFormat } = common;
  const { authorizationProfile } = AuthorizationProfile
  const { metaData } = domainView;
  const { summaryData, note, activity, attachement, noteDrawer, filterValue } =
    logHistory;
  const { updateActivityLog } = messageAndNotes;

  return {
	authorizationProfile,
    currentLanguage,
    currentDateFormat,
    summaryData,
    note,
    activity,
    attachement,
    noteDrawer,
    updateActivityLog,
    metaData,
    filterValue,
  };
};

const { historyAction } = Actions;
const { logHistory, attachment } = historyAction;

const mapDispatchToProps = {
  getLogHistory: logHistory.get,
  filter: logHistory.setFilter,
  search: logHistory.setSearch,
  reset: logHistory.reset,
  getAttachment: attachment.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Log);
