import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { $TextArea, $Button, $Popconfirm, $Form } from 'us.common/components';
import { IRootState } from 'us.collection/interfaces';
import { ARNoteAction } from 'us.collection/actions';
import { ArNote } from 'us.collection/repository';
import { AddArNoteValidation } from '../../Validation';
import { IAddNote } from '../../Intefaces';
import { $Switch } from 'us.common/components';
import { isEditUserNote } from '../../Functions';
import { EntityType, RegisteredUserType } from 'us.collection/constants';
import * as Azure from 'us.helper/azure';
import * as Actions from 'us.collection.creditor/actions';

const { addArNote } = ARNoteAction;

const { creditor } = Actions;
const { creditors } = creditor;

const AddNote: React.FC<IAddNote> = (props) => {
	const { t } = useTranslation();

	const {
		openDrawer,
		noteDrawer,
		editNoteContent,
		saveArNote,
		entityId,
		entityType,
		isNoteSaving,
		creditors,
		getCreditors,
		metaData,
	} = props;

	let isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
	let auth: any;
	if (isAzureAD) {
		auth = new Azure.ADAuth();
	} else {
		auth = new Azure.B2CAuth();
	}

	const azureName: any = auth.currentUser();

	const { creditorId } = metaData.data ?? {};

	useEffect(() => {
		if (
			!creditors.data.length &&
			creditorId &&
			creditorId > 0 &&
			entityType === EntityType.CREDITOR
		) {
			getCreditors && getCreditors({ creditorId });
		}
	}, [creditorId]);

	/**
	 * Close drawer
	 */
	const onClose = () => {
		openDrawer &&
			openDrawer({
				title: '',
				visible: false,
				isEdit: false,
			});
	};

	/**
	 * Save note
	 * @param values Form values
	 */
	const saveNote = (values: any) => {
		const noteId = noteDrawer?.isEdit
			? editNoteContent?.noteId
			: -1;
		const requestBody = ArNote.call({
			...values,
			entityId,
			entityType,
			noteId,
			creditorId,
		});
		saveArNote && saveArNote(requestBody);
	};

	return (
		<Formik
			initialValues={{
				noteText: noteDrawer?.isEdit
					? editNoteContent?.noteText
					: '',
				isCopiedToChildCreditors: noteDrawer?.isEdit
					? editNoteContent?.isCopiedToChildCreditors
					: false,
			}}
			enableReinitialize
			onSubmit={saveNote}
			validationSchema={AddArNoteValidation}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...restProps
			}: any) => (
				<>
					<$Form layout='vertical'>
						<div
							className='mb-2'
							data-testid='add-note-label'>
							<$TextArea
								name='noteText'
								label={t(
									'US.COLLECTION.CASE:NOTES.NOTE'
								)}
								autoSize={{
									minRows: 15,
									maxRows: 15,
								}}
								maxLength={800}
								disabled={
									(noteDrawer?.isEdit &&
										(editNoteContent.registeredUserType ===
											RegisteredUserType.SYSTEM ||
											!isEditUserNote(
												editNoteContent,
												azureName?.unique_name
											))) ||
									isNoteSaving
								}
								data-testid='new-note-text'
							/>
						</div>
						{entityType ===
							EntityType.CREDITOR &&
							creditors.data.length >
								0 && (
								<div>
									<div className='mt-1 d-flex flex-1 align-items-top'>
										<div className='mr-3'>
											<$Switch
												name='isCopiedToChildCreditors'
												checked={
													values?.isCopiedToChildCreditors
												}
											/>
										</div>
										<div>
											{t(
												'US.COLLECTION.CASE:NOTES.COPY_TO_CHILD_CREDITOR(S)'
											)}
										</div>
									</div>
								</div>
							)}
					</$Form>

					<div className='drawer-footer-fixed align-content-center justify-content-end'>
						<div>
							{noteDrawer?.isEdit &&
								isEditUserNote(
									editNoteContent,
									azureName?.unique_name
								) && (
									<$Button
										className='ml-3 mr-2'
										type='primary'
										onClick={
											handleSubmit
										}
										disabled={
											(noteDrawer?.isEdit &&
												editNoteContent?.noteText ===
													values.noteText &&
												editNoteContent?.isCopiedToChildCreditors ===
													values.isCopiedToChildCreditors) ||
											isNoteSaving
										}
										data-testid='edit-note-button'>
										{t(
											'US.COLLECTION.COMMON:COMMON.UPDATE'
										)}
									</$Button>
								)}
							{!noteDrawer?.isEdit && (
								<$Button
									className='ml-3 mr-2'
									type='primary'
									onClick={
										handleSubmit
									}
									data-testid='save-new-note-button'
									disabled={
										isNoteSaving
									}>
									{t(
										'US.COLLECTION.COMMON:COMMON.SAVE'
									)}
								</$Button>
							)}
							{(!noteDrawer?.isEdit ||
								(noteDrawer?.isEdit &&
									isEditUserNote(
										editNoteContent,
										azureName?.unique_name
									))) && (
								<$Popconfirm
									title={t(
										'US.COLLECTION.CASE:NOTES.YOUR_EXISTING_DATA_WILL_BE_LOST_DO_YOU_WISH_TO_CONTINUE'
									)}
									okText={t(
										'US.COLLECTION.COMMON:COMMON.YES'
									)}
									cancelText={t(
										'US.COLLECTION.COMMON:COMMON.NO'
									)}
									onConfirm={
										onClose
									}
									placement='rightTop'>
									<$Button>
										{t(
											'US.COLLECTION.COMMON:COMMON.CANCEL'
										)}
									</$Button>
								</$Popconfirm>
							)}
							{noteDrawer?.isEdit &&
								(editNoteContent.registeredUserType ===
									RegisteredUserType.SYSTEM ||
									!isEditUserNote(
										editNoteContent,
										azureName?.unique_name
									)) && (
									<$Button
										onClick={
											onClose
										}
										data-testid='system-note-cancel-button'>
										{t(
											'US.COLLECTION.COMMON:COMMON.CANCEL'
										)}
									</$Button>
								)}
						</div>
					</div>
				</>
			)}
		</Formik>
	);
};
const mapStateToProps = (state: IRootState) => {
	const { ArNotes, creditor, domainView } = state;
	const { noteDrawer, isNoteSaving } = ArNotes;
	const { creditors } = creditor;
	const { metaData } = domainView;
	return {
		noteDrawer,
		isNoteSaving,
		creditors,
		metaData,
	};
};

const mapDispatchToProps = {
	openDrawer: addArNote.openDrawer,
	saveArNote: addArNote.save,
	getCreditors: creditors.get,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNote);
