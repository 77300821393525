import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCaseAssets, OtherPartyAction } from '../../actions';
import { AssetsAction } from 'us.collection.case/actions';
import AddAssets from './Component/AddAssets/AddAssets';
import { useTranslation } from 'react-i18next';
import {
  PlusOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
} from 'us.icons';
import Common from 'us.common';
import { CaseType } from 'us.helper/types/enums';
import { Formik } from 'formik';
import { IRootState } from 'us.collection/interfaces';
import { IDataModel } from 'us.collection.case/reducers/Dashboard/Interfaces';
import { IMetaData } from 'us.collection/interfaces';
import { IOnFilter, IOnSort } from 'us.common/components';
import { EntityType } from 'us.collection.case/constants';

const {
  $Button,
  $Popconfirm,
  $PageHeader,
  $Divider,
  $Affix,
  $Skeleton,
  $Popover,
  $Drawer,
  $Message,
  $TableTree,
  $AmountLabel,
  $DateLabel,
  $Tooltip,
} = Common.Components;

interface IAssets {
  isEnforcement?: boolean;
  addAssetType?: string;
  onMinimize(): void;
  addEnforcementAsset?(): void;
  isSubCase: CaseType;
}

interface IBase extends IAssets {
  getCaseAssets: () => void;
  caseAssets: any;
  currentLanguage: string;
  currentDateFormat: string;
  currentCurrency: string;
  getAssetList: any;
  entity: any;
  data: any;
  changeDrawerInfo: any;
  drawerInfo: any;
  getAssets: any;
  isFetching: boolean;
  deleteAssets: any;
  resetRealEstateAssets: any;
  arDetails: any;
  deleteParty: any;
  resetOtherPartyInfo: any;
  metaData: IDataModel<IMetaData>;
}

export interface ISortOrder {
  columnKey: string;
  order: any;
}

export const invalidDate = '0001-01-01T00:00:00';

const { otherParty } = OtherPartyAction;
const { AddRealEstateAssets, DeleteAssets, RealEstateAssets } = AssetsAction;

const Assets: React.FC<IBase> = (props) => {
  const { t } = useTranslation(['US.COLLECTION.CASE'], {
    useSuspense: true,
  });
  const [popVisible, setPopVisible] = useState<number>(-1);
  const [editedAssetType, setAssetType] = useState('Real Estate');

  const {
    data,
    changeDrawerInfo,
    drawerInfo,
    getAssets,
    isFetching,
    deleteAssets,
    resetRealEstateAssets,
    arDetails,
    deleteParty,
    resetOtherPartyInfo,
    metaData,
  } = props;

  const { debtorEntNumber } = metaData.data ?? {};

  useEffect(() => {
    if (debtorEntNumber || arDetails?.cid) {
      getAssets({
        entityId: debtorEntNumber ?? arDetails?.cid,
        entityType: EntityType.DEBTOR,
      });
    }
  }, [debtorEntNumber, arDetails?.cid]);

  // delete Assets details
  const handleDelete = (record: any) => {
    if (record.isEnforced) {
      $Message.warning(
        t(
          'US.COLLECTION.CASE:ASSETS.THIS_ASSET_IS_LINKED_TO_ENFORCEMENT_CAN_NOT_DELETE_THE_ASSET'
        )
      );
    } else {
      record.assetType === 'Employer'
        ? deleteParty({
            entityId: debtorEntNumber,
            entityType: 'Debtor',
            entRoleId: record.assetId,
            calledFromAssetEntNo: debtorEntNumber,
          })
        : deleteAssets({
            assetId: record.assetId,
            assetType: record.assetType,
            entityId: debtorEntNumber ?? arDetails?.cid,
            entityType: EntityType.DEBTOR,
          });
    }
  };

  // Edit Assets details
  const handleEditAsset = (record: any) => {
    changeDrawerInfo({
      title: t('US.COLLECTION.CASE:ASSETS.EDIT_ASSET'),
      visible: true,
      isEdit: true,
      assetId: record.assetId,
    });
    setAssetType(record.assetType);
    resetRealEstateAssets();
  };

  const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
    columnKey: '',
    order: '',
  });

  // Popover actions in table
  const content = (record: any) => (
    <div className="table-more-content">
      <div
        className="d-flex flex-row more-item"
        onClick={() => {
          setPopVisible(-1);
          handleEditAsset(record);
        }}
      >
        <div className="p-1">
          <EditOutlined />
        </div>
        <div className="p-1">{t('US.COLLECTION.COMMON:COMMON.EDIT')}</div>
      </div>
      <$Popconfirm
        title={t('US.COLLECTION.CASE:ASSETS.DO_YOU_WANT_TO_DELETE_THIS_ASSET?')}
        onConfirm={() => {
          setPopVisible(-1);
          handleDelete(record);
        }}
        onCancel={() => setPopVisible(-1)}
      >
        <div className="d-flex mb-2 flex-row more-item text-error">
          <div className="p-1">
            <DeleteOutlined />
          </div>
          <div className="p-1">{t('US.COLLECTION.COMMON:COMMON.DELETE')}</div>
        </div>
      </$Popconfirm>
    </div>
  );

  const columns: any = [
    {
      title: '',
      key: 'more',
      dataIndex: 'more',
      width: '50px',
      customRenderChild: (text: any, record: any) => {
        return (
          <$Popover
            placement="right"
            content={content(record)}
            destroyTooltipOnHide
            trigger="click"
            visible={record.key == popVisible}
            onVisibleChange={(visible: boolean) => {
              setPopVisible(visible ? record.key : -1);
            }}
          >
            <$Button icon={<MoreOutlined />} size="small" />
          </$Popover>
        );
      },
    },
    {
      title: t('US.COLLECTION.CASE:ASSETS.TYPE'),
      dataIndex: 'assetType',
      key: 'assetType',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
    {
      title: t('US.COLLECTION.CASE:ASSETS.DESCRIPTION'),
      key: 'description',
      dataIndex: 'description',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
    {
      title: t('US.COLLECTION.CASE:ASSETS.ESTIMATED_VALUE'),
      dataIndex: 'estimatedValue',
      key: 'estimatedValue',
      align: 'right',
      className: 'right-has-sort right-has-filter text-nowrap',
      customSorter: (a: any, b: any) => a - b,
      customFilter: 'amount',
      customRenderChild: (text: any, record: any) => {
        return <$AmountLabel value={record.estimatedValue} />;
      },
    },
    {
      title: t('US.COLLECTION.CASE:ASSETS.VALUATION'),
      dataIndex: 'valuationAmount',
      key: 'valuationAmount',
      align: 'right',
      className: 'right-has-sort right-has-filter text-nowrap',
      customSorter: (a: any, b: any) => a - b,
      customFilter: 'amount',
      customRenderChild: (text: any, record: any) => {
        return <$AmountLabel value={record.valuationAmount} />;
      },
    },
    {
      title: t('US.COLLECTION.CASE:ASSETS.VALUATION_DATE'),
      dataIndex: 'valuationDate',
      key: 'valuationDate',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: 'date',
      customRenderChild: (text: any, record: any) => {
        return (
          record.valuationDate && <$DateLabel value={record.valuationDate} />
        );
      },
    },
    {
      title: t('US.COLLECTION.CASE:ASSETS.CREATED_ON'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: 'date',
      customRenderChild: (text: any, record: any) => {
        const { createdDate } = record;
        return (
          <>
            {createdDate && (
              <span>
                <$DateLabel value={createdDate} />
              </span>
            )}
            {record.createdUser && (
              <small className="bui-label d-block">
                {t('US.COLLECTION.CASE:ASSETS.BY')}
                {' - '}
                {record.createdUser}
              </small>
            )}
          </>
        );
      },
    },
    {
      title: t('US.COLLECTION.CASE:ASSETS.MAP_STATUS'),
      dataIndex: 'status',
      key: 'status',
      align: 'left',
      className: 'right-has-sort right-has-filter text-nowrap',
    },
  ];
  //   const assetsData = data;
  /**
   * back button funciionality
   */
  const minimize = () => {
    props.onMinimize();
  };

  /**
   * opening the drawer for the new asset addtion.
   */
  const addNewButtonHandler = (drawerType: string, drwerData?: any) => {
    if (props.addEnforcementAsset) {
      props.addEnforcementAsset();
    }
    changeDrawerInfo({
      title: t('US.COLLECTION.CASE:ASSETS.NEWASSET'),
      visible: true,
      isEdit: false,
    });
    setAssetType('Real Estate');
    resetRealEstateAssets();
  };

  /**
   * closing the drawer
   */
  const onClose = () => {
    resetRealEstateAssets();

    changeDrawerInfo({
      title: t(''),
      visible: false,
      isEdit: false,
    });
    resetOtherPartyInfo({});
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter);
  };

  const handleSort: IOnSort = (sortData, dataSource) => {
    return sortData(dataSource);
  };

  const handleFilter: IOnFilter = (searchData, dataSource) => {
    return searchData(dataSource);
  };

  const refreshButtonHandler = () => {
    if (debtorEntNumber || arDetails?.cid) {
      getAssets({
        entityId: debtorEntNumber ?? arDetails?.cid,
        entityType: EntityType.DEBTOR,
      });
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={(values: any, actions: any) => {}}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        ...rest
      }: any) => (
        <>
          <div className="space-content">
            <$Affix offsetTop={80}>
              <div className="page-header header-border pr-0">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <$PageHeader
                      className="px-0"
                      title={t('US.COLLECTION.CASE:ASSETS.ASSETS')}
                      onBack={minimize}
                    />
                    <$Divider className="bui-devider" type="vertical" />
                    <$Skeleton
                      loading={false}
                      active
                      paragraph={{
                        rows: 0,
                      }}
                    >
                      <$Button
                        type="dashed"
                        size="small"
                        onClick={() => addNewButtonHandler('ADD')}
                        icon={<PlusOutlined />}
                      >
                        {t('US.COLLECTION.CASE:ASSETS.NEW_ASSET')}
                      </$Button>
                    </$Skeleton>
                  </div>
                  <div>
                    <$Tooltip title={t('US.COLLECTION.COMMON:COMMON.REFRESH')}>
                      <$Button
                        type="dashed"
                        size="small"
                        onClick={() => refreshButtonHandler()}
                        icon={<ReloadOutlined />}
                      />
                    </$Tooltip>
                  </div>
                </div>
              </div>
            </$Affix>

            <$Skeleton loading={isFetching} active paragraph={{ rows: 2 }}>
              <$TableTree
                onChange={handleTableChange}
                rowKey="assetId"
                data={data}
                size="small"
                className="mt-3 header-custom-tag"
                onSort={handleSort}
                onFilter={handleFilter}
                filterOnType={true}
                resetOnSourceChange={true}
                bordered
                pagination={{
                  defaultPageSize: 15,
                }}
                scroll={{
                  x: 1200,
                }}
                columns={columns}
                onRow={(record: any, rowIndex: any) => {
                  return {
                    onDoubleClick: (event: any) => {
                      handleEditAsset(record);
                    },
                  };
                }}
              />
            </$Skeleton>
          </div>
          <$Drawer //Add Asserts
            title={drawerInfo?.title}
            width={900}
            visible={drawerInfo?.visible}
            onClose={() => onClose()}
            destroyOnClose
          >
            <AddAssets
              enforcementEmployeeBreadcrumb={false}
              enforcementPostalBreadcrumb={false}
              addAssetType="Real Estate"
              assetType={editedAssetType}
              showOnly={false}
            />
          </$Drawer>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common, domainView, entityDetails, assets, transaction } = state;
  const { currentLanguage, currentDateFormat, currentCurrency } = common;
  const { data, drawerInfo, isFetching } = assets;
  const { entity } = entityDetails;
  const { metaData } = domainView;
  const { arDetails } = transaction;

  return {
    caseAssets: state.case.caseAssets,
    currentLanguage,
    currentDateFormat,
    currentCurrency,
    entity,
    data,
    metaData,
    drawerInfo,
    isFetching,
    arDetails,
  };
};

const mapDispatchToProps = {
  getCaseAssets,
  getAssetList: AssetsAction.Assets.get,
  changeDrawerInfo: AddRealEstateAssets.openDrawer,
  getAssets: AssetsAction.Assets.get,
  deleteAssets: DeleteAssets.delete,
  resetRealEstateAssets: RealEstateAssets.reset,
  deleteParty: otherParty.delete,
  resetOtherPartyInfo: otherParty.reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(Assets);
