import React from "react";
import {
  DeleteOutlined,
  BoxIcons,
  IconTypes,
  PushpinOutlined,
  PushpinFilled,
} from "us.icons";
import Common from "us.common";
import { useTranslation } from "react-i18next";
import { IMessageTemplate } from "../../Interface";
import { isSubCaseTransferredToCase } from "us.common/functions";

const { $Button, $Tooltip, $Popconfirm } = Common.Components;

const MessageTemplate: React.FC<IMessageTemplate> = (props) => {
  const { t } = useTranslation();
  const {
    messageTemplate,
    onDelete,
    onPreviewEditDrawer,
    metaData,
    onPinUnpinTemplate,
  } = props;
  const { templateName, plugin, isPin } = messageTemplate;

  return (
    <div
      className="msg-template-item"
      onDoubleClick={() => {
        onPreviewEditDrawer && onPreviewEditDrawer(true, messageTemplate);
      }}
    >
      <div className="msg-template-item-content">
        <div className="msg-template-column">
          <div className="msg-template-icon">
            <BoxIcons type={IconTypes.BOX_ICON} name={plugin.toLowerCase()} />
          </div>
          <div className="msg-template-name" title={templateName}>
            {templateName}
          </div>

          <div className="msg-template-options">
            <$Tooltip
              placement="top"
              title={t("US.COLLECTION.COMMON:COMMON.EDIT")}
            >
              <$Button
                type="link"
                size="small"
                onClick={() => {
                  onPreviewEditDrawer &&
                    onPreviewEditDrawer(false, messageTemplate);
                }}
                disabled={metaData && isSubCaseTransferredToCase(metaData)}
              >
                <BoxIcons type={IconTypes.BOX_ICON} name="edit" />
              </$Button>
            </$Tooltip>
            <$Popconfirm
              title={t("US.COLLECTION.CASE:MASSAGETEMPLATES.DELETECONFIRM")}
              onConfirm={onDelete}
              okText={t("US.COLLECTION.COMMON:COMMON.YES")}
              cancelText={t("US.COLLECTION.COMMON:COMMON.NO")}
            >
              <$Tooltip
                placement="top"
                title={t("US.COLLECTION.COMMON:COMMON.DELETE")}
              >
                <$Button
                  type="link"
                  size="small"
                  danger
                  disabled={metaData && isSubCaseTransferredToCase(metaData)}
                >
                  <DeleteOutlined />
                </$Button>
              </$Tooltip>
            </$Popconfirm>
            <$Popconfirm
              title={
                isPin
                  ? t(
                      "US.COLLECTION.CASE:MASSAGETEMPLATES.ARE_YOU_SURE_YOU_WANT_TO_UNPIN?"
                    )
                  : t(
                      "US.COLLECTION.CASE:MASSAGETEMPLATES.ARE_YOU_SURE_YOU_WANT_TO_PIN?"
                    )
              }
              onConfirm={onPinUnpinTemplate}
              okText={t("US.COLLECTION.COMMON:COMMON.YES")}
              cancelText={t("US.COLLECTION.COMMON:COMMON.NO")}
            >
              <$Tooltip
                placement="top"
                title={
                  isPin
                    ? t("US.COLLECTION.COMMON:UNPIN")
                    : t("US.COLLECTION.COMMON:PIN")
                }
              >
                <$Button
                  type="link"
                  size="small"
                  disabled={metaData && isSubCaseTransferredToCase(metaData)}
                >
                  {isPin ? <PushpinFilled /> : <PushpinOutlined />}
                </$Button>
              </$Tooltip>
            </$Popconfirm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageTemplate;
