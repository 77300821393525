import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';

import Common from 'us.common';
import ExcelExporter from 'us.collection/components/ExcelGenerator';
import * as Actions from 'us.collection.transactions/actions';
import { IExcleColumn } from 'us.collection/components/ExcelGenerator/Interface';
import { INavigationData } from 'us.collection/interfaces';
import {
	filter,
	IArTransactions,
} from 'us.collection.transactions/components/ArTransactions/Interfaces';
import {
	initialFilter,
	excelColumn,
	FilterTypes,
	TableTypes,
} from 'us.collection.transactions/components/ArTransactions/Constants';
import { filterTransactions } from 'us.collection.transactions/functions';
import {
	MoreOutlined,
	AppstoreOutlined,
	UnorderedListOutlined,
	ReloadOutlined,
} from 'us.icons';
import { TransactionDetails } from 'us.collection.transactions/components';
import './Home.scss';
import { IOnFilter, IOnSort } from 'us.common/components';
import { appInsights } from 'us.helper';
import { getColorForWorkFlowState } from 'us.collection/functions';
import { ItemMenu, TransactionMapping } from './Components';
import { URLType } from 'us.collection/constants';
import {
	getArTransactionActions,
	getDrawerSettingsByAction,
} from './Functions';
import {
	ArTransactionAction,
	defaultArTransactionDrawerSettings,
} from 'us.collection.transactions/constants';
import { CaseMapping } from './Components/CaseMapping';
import { invalidDate } from '../Transactions/Constants';
import { RootState } from 'us.helper/types';

const {
	$Button,
	$Radio,
	$Tag,
	$TableTree,
	$Drawer,
	$Skeleton,
	$Popover,
	$Select,
	$Tooltip,
	$Affix,
	$PageHeader,
	$Divider,
	$AmountLabel,
	$DateLabel,
	$Switch,
} = Common.Components;

const ArTransactions: React.FC<IArTransactions & PropsFromRedux> = (props) => {
	const { t } = useTranslation();
	const [tableView, setTableView] = useState<string>(TableTypes.GROUP);
	const [filters, setFilters] = useState<filter>(initialFilter);
	const [popVisible, setPopVisible] = useState<number>(-1);
	const [expandedRows, setExpandedRows] = useState<Array<number>>([]);

	const { state: locationState, pathname } = useLocation();
	const state = locationState as INavigationData;

	const {
		currentDateFormat,
		currentLanguage,
		currentCurrency,
		getTransactions,
		transactions,
		isFetching,
		selectTransaction,
		resetSelectedTransaction,
		caseType,
		drawerSettings,
		history,
		updateDrawerSettings,
	} = props;

	const routeDetails: any = matchPath(pathname, {
		path: '/ar/:arNo/transactions',
		exact: false,
		strict: false,
	});

	const caseDetail = {
		caseId: routeDetails?.params?.arNo,
		caseType: FilterTypes.ARType,
	};

	useEffect(() => {
		if (pathname && caseDetail.caseId) {
			getTransactionsData();
		}
	}, [pathname]);

	useEffect(() => {
		handleExpandedRows(0, false, true);
	}, [transactions]);

	const getTransactionsData = () => {
		getTransactions(FilterTypes.AR, caseDetail.caseId);
	};

	const handleSortGroup: IOnSort = (sortData, dataSource) => {
		try {
			return sortData(
				dataSource.map((i: any) => {
					return {
						...i,
						children: sortData(i.children),
					};
				})
			);
		} catch (error) {
			appInsights.trackException(
				`AR Transaction sorting Exception - ${error}`
			);
			return dataSource;
		}
	};

	const handleFilterGroup: IOnFilter = (searchData, dataSource) => {
		let result: any[] = [];
		try {
			dataSource.flatMap((i: any) => {
				searchData(i?.children)?.length != 0 &&
					result.push({
						...i,
						children:
							searchData(i?.children)
								?.length == 0
								? []
								: searchData(
										i?.children
								  ),
					});
			});
		} catch (error) {
			appInsights.trackException(
				`AR Transaction filtering Exception - ${error}`
			);
		}
		return result;
	};

	/**
	 * This function opens a drawer and updates the drawer settings.
	 * @param {any} record - the record that was clicked on
	 * @param {ArTransactionAction} action - ArTransactionAction - this is the action that was clicked on
	 * the table
	 */
	const openDrawer = (record: any, action: ArTransactionAction) => {
		selectTransaction(record);
		// get drawer settings by action
		const drawerSettings = getDrawerSettingsByAction(
			record,
			action,
			caseDetail
		);
		updateDrawerSettings(drawerSettings);
	};

	/**
	 * When the drawer is closed, reset the drawer settings to the default settings and reset the selected
	 * transaction to an empty object.
	 */
	const closeDrawer = () => {
		updateDrawerSettings(defaultArTransactionDrawerSettings);
		resetSelectedTransaction({});
	};

	/**
	 * A function that is called when a user clicks on an action button in the table.
	 * @param {ArTransactionAction} action - ArTransactionAction, record: any
	 * @param {any} record - the row data
	 */
	const onCallItemAction = (action: ArTransactionAction, record: any) => {
		// close popup menu
		setPopVisible(-1);
		// handle actions
		openDrawer(record, action);
	};

	/**
	 * Set Workflow state and date
	 * @param workFlowState
	 * @param nextDueDate
	 * @returns if nextDudate is null return workFlowStatw - {string},
	 *  otherwise return workflow with date
	 */
	const setWFStateTag = (
		workFlowState: string,
		nextDueDate: string
	): string | JSX.Element => {
		try {
			if (workFlowState && nextDueDate) {
				return (
					<>
						{workFlowState} -{' '}
						<$DateLabel
							value={nextDueDate}
						/>
					</>
				);
			} else {
				return workFlowState;
			}
		} catch (error) {
			appInsights.trackException(
				`ARTransaction setWFStateTag Exception - ${error}`
			);
			return workFlowState;
		}
	};

	const firstCommonColumns = (tableType: string) => [
		{
			title: t('US.COLLECTION.TRANSACTIONS:ARHOME.REG_DATE'),
			dataIndex: 'regDate',
			key: 'regDate',
			className: 'text-nowrap',
			width: tableType == TableTypes.GROUP ? 120 : 150,
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (_text: any, record: any) =>
				record.regDate && (
					<$DateLabel value={record.regDate} />
				),
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:ARHOME.TRANS_TYPE'
			),
			dataIndex: 'type',
			key: 'type',
			className: 'text-nowrap',
			width: tableType == TableTypes.GROUP ? 130 : 150,
			ellipsis: true,
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: true,
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REF_NO'
			),
			dataIndex: 'invoiceNumber',
			key: 'invoiceNumber',
			className: 'text-nowrap',
			width: tableType == TableTypes.GROUP ? 130 : 150,
			ellipsis: true,
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
		},
		{
			title: t('US.COLLECTION.TRANSACTIONS:ARHOME.KID'),
			dataIndex: 'kid',
			key: 'kid',
			className: 'text-nowrap',
			width: 170,
			ellipsis: true,
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
		},
	];

	const secondCommonColumns = (tableType: string) => [
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:ARHOME.VOUCHER_NO'
			),
			dataIndex: 'voucherID',
			key: 'voucherID',
			width: tableType == TableTypes.GROUP ? 130 : 150,
			className: 'text-nowrap',
			ellipsis: true,
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
			customRenderChild: (_text: any, record: any) => {
				return (
					<span>
						{record.voucherID > 0
							? record.voucherID
							: ''}
					</span>
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:ARHOME.VOUCHER_DATE'
			),
			dataIndex: 'voucherDate',
			key: 'voucherDate',
			width: 150,
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (_text: any, record: any) =>
				record.voucherDate && (
					<$DateLabel
						value={record.voucherDate}
					/>
				),
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:ARHOME.PAYMENT_ID'
			),
			dataIndex: 'paymentID',
			key: 'paymentID',
			width: tableType == TableTypes.GROUP ? 130 : 150,
			className: 'text-nowrap',
			ellipsis: true,
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
			customRenderChild: (_text: any, record: any) => {
				return (
					<Link
						to={{
							pathname: `/payment/${record.paymentID}/distribution`,
							state: {
								originate: 'payment',
								accessTabs: [
									'payment',
								],
								refreshCount: 0,
								currentTab: 'payment',
							},
						}}>
						{record.paymentID > 0
							? record.paymentID
							: ''}
					</Link>
				);
			},
		},
		{
			title: () => <div>{t('US.COMMON:COMMON.AMOUNT')}</div>,
			dataIndex: 'amount',
			key: 'amount',
			width: tableType == TableTypes.GROUP ? 130 : 120,
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderParent: (
				_text: any,
				record: any,
				index: number
			) => {
				return {
					children: tableType ==
						TableTypes.GROUP && (
						<div
							className={
								'header-custom-amount'
							}>
							<$AmountLabel
								value={
									record.amount
								}
							/>
						</div>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
			customRenderChild: (_text: any, record: any) => {
				return (
					<>
						{tableType ==
							TableTypes.GROUP && (
							<$AmountLabel
								value={
									record.amount
								}
							/>
						)}
						{tableType ==
							TableTypes.LIST && (
							<$AmountLabel
								value={
									record.amount
								}
							/>
						)}
					</>
				);
			},
		},
		{
			title: () => (
				<div>
					{t(
						'US.COLLECTION.TRANSACTIONS:ARHOME.INTEREST'
					)}
				</div>
			),
			dataIndex: 'interestAmount',
			key: 'interestAmount',
			width: tableType == TableTypes.GROUP ? 130 : 120,
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderParent: (
				_text: any,
				record: any,
				index: number
			) => {
				return {
					children: tableType ==
						TableTypes.GROUP && (
						<div
							className={
								'header-custom-amount'
							}>
							<$AmountLabel
								value={
									record.interestAmount
								}
							/>
						</div>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
			customRenderChild: (_text: any, record: any) => {
				return (
					<>
						{tableType ==
							TableTypes.GROUP && (
							<$AmountLabel
								value={
									record.interestAmount
								}
							/>
						)}
						{tableType ==
							TableTypes.LIST && (
							<$AmountLabel
								value={
									record.interestAmount
								}
							/>
						)}
					</>
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.INTEREST_RATE'
			),
			dataIndex: 'interestRate',
			key: 'interestRate',
			className: 'right-has-sort right-has-filter text-nowrap',
			width: 130,
			align: 'right',
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
			customRenderParent: (
				_text: any,
				record: any,
				index: number
			) => {
				return {
					children: tableType ==
						TableTypes.GROUP && <div></div>,
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
			customRenderChild: (_text: any, record: any) => {
				const { interestRate } = record;
				return (
					<>
						{interestRate > 0 && (
							<div>
								{interestRate}%
							</div>
						)}
					</>
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.INTEREST_FROM_DATE'
			),
			dataIndex: 'interestFromDate',
			key: 'interestFromDate',
			className: 'right-has-sort right-has-filter text-nowrap',
			width: 170,
			align: 'right',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderParent: (
				_text: any,
				record: any,
				index: number
			) => {
				return {
					children: tableType ==
						TableTypes.GROUP && <div></div>,
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
			customRenderChild: (_text: any, record: any) => {
				const { interestFromDate } = record;
				return (
					<>
						{interestFromDate &&
							interestFromDate !=
								invalidDate && (
								<$DateLabel
									value={
										interestFromDate
									}
								/>
							)}
					</>
				);
			},
		},
		{
			title: () => (
				<div>
					{t(
						'US.COLLECTION.TRANSACTIONS:ARHOME.TOTAL_AMOUNT'
					)}
				</div>
			),
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			width: 150,
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderParent: (
				_text: any,
				record: any,
				index: number
			) => {
				return {
					children: tableType ==
						TableTypes.GROUP && (
						<div
							className={
								'header-custom-amount'
							}>
							<$AmountLabel
								value={
									record.totalAmount
								}
							/>
						</div>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
			customRenderChild: (_text: any, record: any) => {
				return (
					<>
						{tableType ==
							TableTypes.GROUP && (
							<$AmountLabel
								value={
									record.totalAmount
								}
							/>
						)}
						{tableType ==
							TableTypes.LIST && (
							<$AmountLabel
								value={
									record.totalAmount
								}
							/>
						)}
					</>
				);
			},
		},
		{
			title: () => <div>{t('US.COMMON:COMMON.PAID')}</div>,
			dataIndex: 'paid',
			key: 'paid',
			width: tableType == TableTypes.GROUP ? 130 : 120,
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderParent: (
				_text: any,
				record: any,
				index: number
			) => {
				return {
					children: tableType ==
						TableTypes.GROUP && (
						<div
							className={
								'header-custom-amount'
							}>
							<$AmountLabel
								value={
									record.paid
								}
							/>
						</div>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
			customRenderChild: (_text: any, record: any) => {
				return (
					<>
						{tableType ==
							TableTypes.GROUP && (
							<$AmountLabel
								value={
									record.paid
								}
							/>
						)}
						{tableType ==
							TableTypes.LIST && (
							<$AmountLabel
								value={
									record.paid
								}
							/>
						)}
					</>
				);
			},
		},
		{
			title: () => <div>{t('US.COMMON:COMMON.BALANCE')}</div>,
			dataIndex: 'balance',
			key: 'balance',
			width: tableType == TableTypes.GROUP ? 130 : 120,
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderParent: (
				_text: any,
				record: any,
				index: number
			) => {
				return {
					children: tableType ==
						TableTypes.GROUP && (
						<div
							className={
								'header-custom-amount'
							}>
							<$AmountLabel
								value={
									record.balance
								}
							/>
						</div>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
			customRenderChild: (_text: any, record: any) => {
				return (
					<>
						{tableType ==
							TableTypes.GROUP && (
							<$AmountLabel
								value={
									record.balance
								}
							/>
						)}
						{tableType ==
							TableTypes.LIST && (
							<$AmountLabel
								value={
									record.balance
								}
							/>
						)}
					</>
				);
			},
		},
		{
			title: t('US.COMMON:COMMON.DESCRIPTION'),
			dataIndex: 'description',
			key: 'description',
			ellipsis: true,
			width: tableType == TableTypes.GROUP && 250,
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: true,
			customRenderParent: (
				_text: any,
				record: any,
				index: number
			) => {
				return {
					children: record.description,
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
			customRenderChild: (
				_text: any,
				record: any,
				index: number
			) => {
				return {
					children: record.description,
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
	];

	const groupColumns: any = [
		{
			title: t('US.COLLECTION.TRANSACTIONS:ARHOME.CASE_NO'),
			dataIndex: 'caseNo',
			key: 'caseNo',
			className: 'text-nowrap case-no-td',
			width: 100,
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: true,
			customRenderParent: (
				_text: any,
				record: any,
				index: number
			) => {
				const {
					caseNo,
					caseId,
					workFlowState,
					nextDueDate,
				} = record;
				return {
					children: (
						<>
							{caseNo && (
								<div className='case-no d-flex align-items-center'>
									{caseNo >
										0 && (
										<>
											<span className='mr-2'>
												{t(
													'US.COLLECTION.TRANSACTIONS:ARHOME.CASE_NO'
												)}{' '}
												:{' '}
												<Link
													to={{
														pathname: `/case/${caseNo}`,
														state: {
															...state,
															currentTab:
																caseId !==
																'-1'
																	? URLType.CASE
																	: URLType.SUBCASE,
														},
													}}>
													{caseNo >
													0
														? caseNo
														: ''}
												</Link>
											</span>

											{workFlowState && (
												<$Tag
													className={`tag-status-${getColorForWorkFlowState(
														workFlowState
													)}`}>
													{setWFStateTag(
														workFlowState,
														nextDueDate
													)}
												</$Tag>
											)}
										</>
									)}
									{!(
										caseNo >
										0
									) && (
										<>
											{t(
												'US.COLLECTION.TRANSACTIONS:ARHOME.OTHER_TRANSACTION'
											)}
										</>
									)}
								</div>
							)}
						</>
					),
					key: index,
					props: {
						colSpan: 9,
					},
				};
			},
			customRenderChild: (
				_text: any,
				_record: any,
				index: number
			) => {
				return {
					children: <></>,
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: '',
			key: 'more',
			dataIndex: 'more',
			width: '40px',
			className: 'group-row-action',
			customRenderChild: (_text: any, record: any) => {
				const actions = getArTransactionActions(record);
				return (
					actions.length > 0 && (
						<$Popover
							trigger='click'
							placement='rightTop'
							content={
								<ItemMenu
									actions={
										actions
									}
									onCallAction={(
										action: ArTransactionAction
									) =>
										onCallItemAction(
											action,
											record
										)
									}
								/>
							}
							destroyTooltipOnHide
							visible={
								record.id ==
								popVisible
							}
							onVisibleChange={(
								visible: boolean
							) =>
								setPopVisible(
									visible
										? record.id
										: -1
								)
							}>
							<$Button
								id='btnItemMenu'
								icon={
									<MoreOutlined />
								}
								size='small'
							/>
						</$Popover>
					)
				);
			},
		},
		...firstCommonColumns(TableTypes.GROUP),
		...secondCommonColumns(TableTypes.GROUP),
	];

	const listColumns: any = [
		{
			title: '',
			key: 'more',
			dataIndex: 'more',
			width: '50px',
			customRenderChild: (_text: any, record: any) => {
				const actions = getArTransactionActions(record);
				return (
					<$Popover
						trigger='click'
						placement='rightTop'
						content={
							<ItemMenu
								actions={
									actions
								}
								onCallAction={(
									action: ArTransactionAction
								) =>
									onCallItemAction(
										action,
										record
									)
								}
							/>
						}
						destroyTooltipOnHide
						visible={
							record.id == popVisible
						}
						onVisibleChange={(
							visible: boolean
						) =>
							setPopVisible(
								visible
									? record.id
									: -1
							)
						}>
						<$Button
							icon={<MoreOutlined />}
							size='small'
						/>
					</$Popover>
				);
			},
		},
		...firstCommonColumns(TableTypes.LIST),
		{
			title: t('US.COLLECTION.TRANSACTIONS:ARHOME.CASE_NO'),
			dataIndex: 'caseNo',
			key: 'caseNo',
			className: 'text-nowrap case-no-td',
			width: 130,
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: true,
			customRenderChild: (_text: any, record: any) => {
				const { workFlowState, nextDueDate, caseNo } =
					record;
				return (
					<>
						{
							<$Tooltip
								placement='top'
								trigger={
									workFlowState ||
									nextDueDate
										? 'hover'
										: ''
								}
								title={
									<div>
										{workFlowState &&
											workFlowState}
										{workFlowState &&
										nextDueDate
											? ' - '
											: ''}
										{nextDueDate && (
											<$DateLabel
												value={
													nextDueDate
												}
											/>
										)}
									</div>
								}>
								<Link
									to={{
										pathname: `/case/${caseNo}`,
										state: {
											...state,
											currentTab: URLType.CASE,
										},
									}}>
									<$Tag
										className={`mt-1 text-truncate case-no tag-status-${getColorForWorkFlowState(
											workFlowState
										)}`}>
										{
											caseNo
										}
									</$Tag>
								</Link>
							</$Tooltip>
						}
					</>
				);
			},
		},
		...secondCommonColumns(TableTypes.LIST),
	];

	const minimize = () => {
		history.push(`/ar/${caseDetail.caseId}`, state);
	};

	const handleExpandedRows = (
		rowKey: number,
		isExpand: boolean,
		isAll?: boolean
	) => {
		const data = filterTransactions(
			transactions,
			tableView,
			filters,
			currentDateFormat,
			currentLanguage,
			currentCurrency
		);
		if (isAll) {
			setExpandedRows(
				data?.map((item: any) => {
					return item.id;
				})
			);
		} else if (typeof isAll != 'undefined' && !isAll) {
			setExpandedRows([]);
		} else {
			if (isExpand) {
				setExpandedRows([...expandedRows, rowKey]);
			} else {
				setExpandedRows(
					expandedRows.filter(
						(expandedRow: number) =>
							expandedRow != rowKey
					)
				);
			}
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{ expandRows: true }}
			onSubmit={() => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				...rest
			}: any) => (
				<>
					{/* Component AR */}
					<div className='space-content'>
						<$Affix offsetTop={80}>
							<div className='page-header header-border mr-0'>
								<div className='d-flex flex-row align-items-center justify-content-between'>
									<div className='d-flex align-items-center'>
										<$PageHeader
											className='px-0'
											title={t(
												'US.COLLECTION.TRANSACTIONS:ARHOME.AR_TRANSACTIONS'
											)}
											onBack={
												minimize
											}
										/>
										<$Divider
											className='bui-devider'
											type='vertical'
										/>
										<$Radio
											name='tableView'
											value={
												tableView
											}
											size='small'
											options={[
												{
													label: '',
													value: TableTypes.GROUP,
													icon: (
														<AppstoreOutlined />
													),
												},
												{
													label: '',
													value: TableTypes.LIST,
													icon: (
														<UnorderedListOutlined />
													),
												},
											]}
											radioButton='true'
											optionValue='value'
											optionContent={(
												item: any
											) =>
												item.icon
											}
											onChange={(
												e: any
											) => {
												setTableView(
													e
														.target
														.value
												);
												setFilters(
													initialFilter
												);
											}}
										/>
									</div>
									<div className='d-flex align-items-center ml-5'>
										{tableView ==
											TableTypes.GROUP && (
											<div className='d-flex align-items-center mr-4'>
												<div
													className='mr-2'
													style={{
														lineHeight: '1',
													}}>
													<$Switch
														name='expandRows'
														checked={
															values.expandRows
														}
														onChange={(
															e: any
														) => {
															handleExpandedRows(
																0,
																false,
																e
															);
															rest.setFieldValue(
																'expandRows',
																e
															);
														}}
													/>
												</div>
												<div>
													{t(
														'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.EXPAND_ALL'
													)}
												</div>
											</div>
										)}
										<$Select
											name='TRANS_TYPE'
											placeholder={t(
												'US.COLLECTION.TRANSACTIONS:ARHOME.TRANS_TYPE'
											)}
											size='small'
											allOption={
												false
											}
											value={
												filters.type
											}
											style={{
												width: 200,
											}}
											options={[
												{
													label: t(
														'US.COMMON:COMMON.ALL'
													),
													value: 'All',
												},
												...transactions
													.map(
														(
															transaction: any
														) =>
															transaction.transactionGroupName
													)
													.filter(
														(
															value: any,
															index: any,
															self:
																| string
																| any[]
														) =>
															self.indexOf(
																value
															) ===
															index
													)
													.map(
														(
															item: any
														) => {
															return {
																label: item,
																value: item,
															};
														}
													),
											]}
											optionValue='value'
											optionText='label'
											onSearchBy={[
												'value',
											]}
											onChange={(
												value: any
											) =>
												setFilters(
													{
														...filters,
														type: value,
													}
												)
											}
										/>
										<$Divider
											className='bui-devider'
											type='vertical'
										/>
										<ExcelExporter
											dataSet={filterTransactions(
												transactions,
												TableTypes.LIST,
												filters,
												currentDateFormat,
												currentLanguage,
												currentCurrency
											).map(
												(
													tran: any
												) => {
													Object.keys(
														tran
													).forEach(
														(
															key: string
														) => {
															if (
																tran[
																	key
																] ==
																null
															) {
																tran[
																	key
																] =
																	'';
															}
														}
													);
													return tran;
												}
											)}
											columns={excelColumn.map(
												(
													col: IExcleColumn
												) => {
													return {
														...col,
														title: t(
															col.title
														),
													};
												}
											)}
											name={`Transactions_${
												pathname?.split(
													'/'
												)[2]
											}`}
											format={{
												currentDateFormat,
												currentCurrency,
												currentLanguage,
											}}
										/>

										<$Tooltip
											title={t(
												'US.COLLECTION.COMMON:COMMON.REFRESH'
											)}>
											<$Button
												type='dashed'
												size='small'
												onClick={() =>
													getTransactionsData()
												}
												icon={
													<ReloadOutlined />
												}
												className='ml-3'
											/>
										</$Tooltip>
									</div>
								</div>
							</div>
						</$Affix>

						{tableView ==
							TableTypes.GROUP && (
							<$Skeleton
								loading={
									isFetching
								}
								active
								paragraph={{
									rows: 2,
								}}>
								<$TableTree
									rowKey='id'
									data={filterTransactions(
										transactions,
										tableView,
										filters,
										currentDateFormat,
										currentLanguage,
										currentCurrency
									)}
									onSort={
										handleSortGroup
									}
									onFilter={
										handleFilterGroup
									}
									filterOnType={
										true
									}
									size='small'
									className='mt-3 ar-table-group'
									bordered
									pagination={{
										defaultPageSize: 15,
									}}
									scroll={{
										x: 1200,
										y: 'calc(100vh - 280px)',
									}}
									columns={
										groupColumns
									}
									defaultExpandAllRows={
										true
									}
									onRow={(
										record: any
									) => {
										return {
											onDoubleClick:
												() => {
													record.arItemNo >
														0 &&
														openDrawer(
															record,
															ArTransactionAction.CANCELATION
														);
												},
										};
									}}
									expandedRowKeys={
										expandedRows
									}
									onExpand={(
										isExpand: boolean,
										record: any
									) =>
										handleExpandedRows(
											record.id,
											isExpand
										)
									}
									firstColSkipFilterProps={
										-1
									}
								/>
							</$Skeleton>
						)}

						{tableView ==
							TableTypes.LIST && (
							<$Skeleton
								loading={
									isFetching
								}
								active
								paragraph={{
									rows: 2,
								}}>
								<$TableTree
									rowKey='id'
									data={filterTransactions(
										transactions,
										tableView,
										filters,
										currentDateFormat,
										currentLanguage,
										currentCurrency
									)}
									onSort={(
										sortData,
										dataSource
									) =>
										sortData(
											dataSource
										)
									}
									onFilter={(
										searchData,
										dataSource
									) =>
										searchData(
											dataSource
										)
									}
									filterOnType={
										true
									}
									size='small'
									className='mt-3 ar-table-list'
									bordered
									pagination={{
										defaultPageSize: 100,
									}}
									scroll={{
										x: 1200,
										y: 'calc(100vh - 280px)',
									}}
									columns={
										listColumns
									}
									onRow={(
										record: any
									) => {
										return {
											onDoubleClick:
												() => {
													openDrawer(
														record,
														ArTransactionAction.CANCELATION
													);
												},
										};
									}}
								/>
							</$Skeleton>
						)}

						<$Drawer
							title={
								drawerSettings.title
							}
							width={
								drawerSettings.width
							}
							onClose={closeDrawer}
							visible={
								drawerSettings.visible
							}
							destroyOnClose>
							{/* Transaction details view for delete, cancel transaction */}
							{[
								ArTransactionAction.CANCELATION,
								ArTransactionAction.DELETE,
							].includes(
								drawerSettings.type
							) && (
								<TransactionDetails
									isDeleteTransaction={
										drawerSettings.isDeleteTransaction
									}
									isNewTransaction={
										drawerSettings.isNewTransaction
									}
									filters={
										drawerSettings.filters
									}
									caseDetail={
										caseDetail
									}
									onClose={
										closeDrawer
									}
								/>
							)}
							{/* Transaction mapping view */}
							{drawerSettings.type ===
								ArTransactionAction.TRANSACTION_MAPPING && (
								<TransactionMapping
									onClose={
										closeDrawer
									}
									caseType={
										caseType
									}
								/>
							)}
							{/* Case Mapping view for positive amounts mapping */}
							{drawerSettings.type ===
								ArTransactionAction.CASE_MAPPING && (
								<CaseMapping
									onClose={
										closeDrawer
									}
									caseType={
										caseType
									}
								/>
							)}
						</$Drawer>
					</div>
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, transaction } = state;
	const { currentCurrency, currentLanguage, currentDateFormat } = common;
	const {
		isFetching,
		transactionDrawer,
		mappingTransactionDrawer,
		editInterestDrawer,
		transactions,
		mapping,
		drawerSettings,
	} = transaction;
	const { updatedTransactions } = mapping;

	return {
		currentCurrency,
		currentLanguage,
		currentDateFormat,
		transactions,
		mappingTransactionDrawer,
		editInterestDrawer,
		transactionDrawer,
		isFetching,
		updatedTransactions,
		drawerSettings,
	};
};

const { transactions } = Actions;
const {
	getAll,
	select,
	resetInfo,
	manageDrawer,
	manageMappingDrawer,
	manageInterestDrawer,
	updateDrawerSettings,
} = transactions;

const mapDispatchToProps = {
	getTransactions: getAll,
	selectTransaction: select,
	manageTransactionnDrawer: manageDrawer,
	manageMappingTransactionDrawer: manageMappingDrawer,
	manageEditInterestDrawer: manageInterestDrawer,
	resetSelectedTransaction: resetInfo,
	updateDrawerSettings,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ArTransactions);
