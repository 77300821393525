import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { INavigationData } from 'us.collection/interfaces';
import { Header } from 'us.collection.creditor/layouts/CreditorView/Header';
import {
	RouteComponentProps,
	matchPath,
	withRouter,
	useHistory,
} from 'react-router-dom';
import { BoxIcons, IconTypes } from 'us.icons';
import { useTranslation } from 'react-i18next';
import Common from 'us.common';
import { ConfigurationContext } from 'us.common/ConfigurationContext/ConfigurationProvider';
import { getSelectedMenuKeyByModule } from 'us.collection/functions';

const { $Layout, $Menu } = Common.Components;

const { Content, Sider } = $Layout;

interface IHomeProps extends RouteComponentProps {}

const Home: React.FC<IHomeProps> = (props) => {
	const { t } = useTranslation(['US.COLLECTION.LAYOUT']);
	const BODY_CLASS = 'change-body-color-dom';
	const context = useContext(ConfigurationContext);

	const historyData = useHistory();
	const { location } = historyData;
	const state = location.state as INavigationData;

	const [creditorPermission, setCreditorPermission] = useState<any>();

	const { params }: any = matchPath(props.location.pathname, {
		path: '/:type/:Id?/:module?',
		exact: false,
		strict: false,
	});

	useEffect(() => {
		setCreditorPermission(context.componentPermission['creditor']);
	}, [context]);

	useEffect(() => {
		document.body.classList.add(BODY_CLASS);
		return () => {
			document.body.classList.remove(BODY_CLASS);
		};
	}, []);

	const navigate = (url: string): void => {
		window.scrollTo(0, 0);
		const { params }: any = matchPath(props.location.pathname, {
			path: '/creditor/:Id/:module?',
			exact: false,
			strict: false,
		});
		let id = params['Id'];
		props.history.push({
			...location,
			pathname: `/creditor/${id}/${url}`,
		});
	};

	const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
	const onCollapse = (collapsed: boolean): void => {
		setIsCollapsed(collapsed);
	};
	const menuFieldsAvalibility = (menuItem: string): boolean => {
		return creditorPermission?.routes[menuItem]?.isEnabled;
	};

	return (
		<$Layout style={{ minHeight: '100vh' }}>
			<Header {...props}></Header>
			<$Layout>
				<Content className='m-0'>
					<main>
						{props.children}
						{/* <CreditorDashboard /> */}
					</main>
				</Content>
				<Sider
					collapsible
					collapsed={isCollapsed}
					onCollapse={(e) => onCollapse(e)}
					reverseArrow={true}>
					<$Menu
						theme='dark'
						mode='inline'
						selectedKeys={getSelectedMenuKeyByModule(
							params['module']
						)}>
						<$Menu.Item
							onClick={() =>
								navigate(
									'creditor-cases'
								)
							}
							key='2'>
							<BoxIcons
								type={
									IconTypes.BOX_ICON
								}
								name='creditor-cases'
							/>
							<span>
								{t(
									'US.COLLECTION.LAYOUT:CASE.CREDITOR_CASES'
								)}
							</span>
						</$Menu.Item>
						{menuFieldsAvalibility(
							'caseDocuments'
						) && (
							<$Menu.Item
								onClick={() =>
									navigate(
										'documents'
									)
								}
								key='4'
								data-testid='creditor-layout-caseDocuments'>
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='documents'
								/>
								<span>
									{t(
										'US.COLLECTION.LAYOUT:CASE.CREDITOR_DOCUMENTS'
									)}
								</span>
							</$Menu.Item>
						)}
						{menuFieldsAvalibility(
							'routinesAndActivities'
						) && (
							<$Menu.Item
								onClick={() =>
									navigate(
										'history'
									)
								}
								key='16'
								data-testid='creditor-layout-routinesAndActivities'>
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='activity-history'
								/>
								<span>
									{' '}
									{t(
										'US.COLLECTION.LAYOUT:CASE.HISTORY'
									)}
								</span>
							</$Menu.Item>
						)}
						{menuFieldsAvalibility(
							'otherParty'
						) && (
							<$Menu.Item
								onClick={() =>
									navigate(
										'other-party'
									)
								}
								key='14'
								data-testid='creditor-layout-otherParty'>
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='other-party'
								/>
								<span>
									{t(
										'US.COLLECTION.LAYOUT:CASE.OTHERPARTY'
									)}
								</span>
							</$Menu.Item>
						)}
						{menuFieldsAvalibility(
							'notes'
						) && (
							<$Menu.Item
								onClick={() =>
									navigate(
										'notes'
									)
								}
								key='15'
								data-testid='creditor-layout-notes'>
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='note'
								/>
								<span>
									{t(
										'US.COLLECTION.LAYOUT:CASE.NOTES'
									)}
								</span>
							</$Menu.Item>
						)}
						{menuFieldsAvalibility(
							'bmd'
						) && (
							<$Menu.Item
								key='17'
								onClick={() =>
									navigate(
										'bmd'
									)
								}
								data-testid='creditor-layout-bmd'>
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='bmd'
								/>
								<span>
									{t(
										'US.COLLECTION.LAYOUT:CASE.BMD'
									)}
								</span>
							</$Menu.Item>
						)}
						<$Menu.Item
							key='18'
							onClick={() =>
								navigate(
									'creditor-information'
								)
							}>
							<BoxIcons
								type={
									IconTypes.BOX_ICON
								}
								name='creditor-information'
							/>
							<span>
								{t(
									'US.COLLECTION.LAYOUT:CREDITOR.CREDITOR_INFORMATION'
								)}
							</span>
						</$Menu.Item>
						{/* <$Menu.Item
							key='19'
							onClick={() =>
								navigate(
									'workflows'
								)
							}>
							<BoxIcons
								type={
									IconTypes.BOX_ICON
								}
								name='bureau-workflows'
							/>
							<span>
								{t(
									'US.COLLECTION.LAYOUT:CREDITOR.WORKFLOWS'
								)}
							</span>
						</$Menu.Item> */}
						{/* <$Menu.Item
							key='20'
							onClick={() =>
								navigate(
									'workflows-troubleshoot'
								)
							}>
							<BoxIcons
								type={
									IconTypes.BOX_ICON
								}
								name='workflows-troubleshoot'
							/>
							<span>
								{t(
									'US.COLLECTION.LAYOUT:BUREAU.WORKFLOWS_TROUBLESHOOT'
								)}
							</span>
						</$Menu.Item> */}
					</$Menu>
				</Sider>
			</$Layout>
		</$Layout>
	);
};

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = {};

export default withRouter(
	connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(Home)
);
