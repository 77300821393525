import { IDocumentOfTitleAssets } from "./interfaces";
import moment from "moment";

export function SaveDocumentOfTitleAssets(this: IDocumentOfTitleAssets) {
  return {
    entityId: this.entityId,
    entityType: this.entityType,
    estimatedValue: this.estimatedValue,
    valuationAmount: this.valuationAmount,
    valuationDate:
      this.valuationDate && moment(this.valuationDate).isValid()
        ? moment(this.valuationDate).format("YYYY-MM-DD")
        : null,
    SNR: this.snr,
    nameOfHousing: this.nameOfHousing,
    ninForHousingCompany: this.ninForHousingCompany,
    description: this.description,
    ownedPart: this.ownedPart,
    postalCode: this.postalCode,
    municipalityCode: this.municipalityCode,
    comment: this.comment,
  };
}
