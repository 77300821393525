import { CaseState } from 'us.collection.transactions/constants';
import { ICase, ISubCase } from 'us.collection.transactions/interfaces';
import { appInsights } from 'us.helper';
import { CaseType } from 'us.helper/types/enums';

/**
 * @function
 * @description - To get the data arranged for the manage case grid
 * @param {ICase[]} caseList - list of all the cases
 * @param {ICase[]} selectedCases - list of cases selected in the Home view
 * @return {*}  {ISubCase[]} - filtered list of sub cases
 */
export const getDataArranged = (caseList: ICase[]): ISubCase[] => {
  try {
    return caseList
      ?.filter(
        (rowCase) =>
          rowCase.caseState === CaseState.OPEN &&
          rowCase.caseType === CaseType.C &&
          rowCase.isMergeEnabled
      )
      .map(({ ...item }: any) => {
        delete item.children;
        return item;
      });
  } catch (error) {
    appInsights.trackException(
      `Error in add case and subcases drawerStatusHandler function. - ${error}`
    );
    return [];
  }
};
