import { IExistingPartPayment } from 'us.collection.partpayments/reducers/AddPartPayment/Interfaces'

export const ExistingPartpayment: IExistingPartPayment = {
    totalAmount: 0,
    firstInstallmentDate: "",
    lastInstallmentDate: "",
    caseCost: 0,
    firstInstallmentAmount: 0,
    lastInstallmentAmount: 0,
    createdUser: "",
    createdDate: "",
    schemaList: []
}