import { IBMDList } from "us.collection/interfaces/BMD";

/**
 * To prepare data with the key value in all the array elements
 * @param data the api response of BMDList
 * @returns arranged bmd list with key values
 */
export const GetData = (data: IBMDList): IBMDList => {
  try {
    return {
      ...data,
      categories: [
        ...data.categories.map((category) => ({
          ...category,
          key: category.id,
          bmdGroups: [
            ...category.bmdGroups.map((bmdGroup) => ({
              ...bmdGroup,
              key: bmdGroup.id,
              propertyList: bmdGroup.propertyList.map((property) => ({
                ...property,
                key: property.id,
              })),
            })),
          ],
        })),
      ],
    };
  } catch (e) {
    return data
  }
};
