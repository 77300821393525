import { IActivity } from "us.collection.followup/interfaces";
import { appInsights } from "us.helper";

/**
 * @function
 * @description filter activity according to followup entity type
 * @param {Array<IActivity>} data activity data
 * @param {string} filter filter value
 * @returns filter activities
 */
export const filterActivityByFollowUpEntityType = (
    data: Array<IActivity>,
    filter: string | undefined
): Array<IActivity> => {
    try {
        return data.filter((activity: IActivity) => activity.entitytype === filter);
    } catch (error) {
        appInsights.trackException(
            `FollowUp-ActivityExection getOptionItem Exception - ${error}`
        );
        return [];
    }
};

/**
 * @function
 * @description get activities for activity table
 * @param {Array<IActivity>} data activity data
 * @param {string} filter filter value
 * @returns {Array<IActivityTableData>} formatted data for activity table
 */
export const getActivityTableData = (
    data: Array<IActivity>,
    filter: string | undefined
): Array<IActivity> => {
    try {
        return filter ? filterActivityByFollowUpEntityType(data, filter) : data

    } catch (error) {
        appInsights.trackException(
            `FollowUp-ActivityExection getActivityTableData Exception - ${error}`
        );
        return [];
    }
};

/**
 * @function
 * @description check activity is valid or not
 * @param {Array<IActivity> }activities activity list
 * @param {string} activityName select activity
 * @returns true - activity is valid, otherwise false
 */
export const isValidActivity = (
    activities: Array<IActivity>,
    activityName: string
): boolean => {
    try {
        return activities.some((activity: IActivity) => activity.displayName === activityName)
    } catch (error) {
        return false;
    }
};
