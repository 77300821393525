export enum AddPartPayment {
    GET_EXISTING_PART_PAYMENT_START = "GET_EXISTING_PART_PAYMENT_START",
    GET_EXISTING_PART_PAYMENT_SUCCESS = "GET_EXISTING_PART_PAYMENT_SUCCESS",
    GET_EXISTING_PART_PAYMENT_FAIL = "GET_EXISTING_PART_PAYMENT_FAIL",

    CALCULATE_PART_PAYMENT_START = "CALCULATE_PART_PAYMENT_START",
    CALCULATE_PART_PAYMENT_SUCCESS = "CALCULATE_PART_PAYMENT_SUCCESS",
    CALCULATE_PART_PAYMENT_FAIL = "CALCULATE_PART_PAYMENT_FAIL",

    GET_PART_PAYMENT_BMDS_START = "GET_PART_PAYMENT_BMDS_START",
    GET_PART_PAYMENT_BMDS_SUCCESS = "GET_PART_PAYMENT_BMDS_SUCCESS",
    GET_PART_PAYMENT_BMDS_FAIL = "GET_PART_PAYMENT_BMDS_FAIL",

    ADD_PAYMENT_AGREEMENT_DRAWER = "ADD_PAYMENT_AGREEMENT_DRAWER",

    ADD_PAYMENT_AGREEMENT_START = "ADD_PAYMENT_AGREEMENT_START",
    ADD_PAYMENT_AGREEMENT_SUCCESS = "ADD_PAYMENT_AGREEMENT_SUCCESS",
    ADD_PAYMENT_AGREEMENT_FAIL = "ADD_PAYMENT_AGREEMENT_FAIL",

    DELETE_PART_PAYMENT_START = "DELETE_PART_PAYMENT_START",
    DELETE_PART_PAYMENT_SUCCESS = "DELETE_PART_PAYMENT_SUCCESS",
    DELETE_PART_PAYMENT_FAIL = "DELETE_PART_PAYMENT_FAIL",

    RESET_ADD_PART_PAYMENT = "RESET_ADD_PART_PAYMENT",
    RESET_ADD_PART_PAYMENT_CHANGE_COST = "RESET_ADD_PART_PAYMENT_CHANGE_COST",

    GET_PAYMENT_BY_INSTALLMENT_START = "GET_PAYMENT_BY_INSTALLMENT_START",
    GET_PAYMENT_BY_INSTALLMENT_SUCCESS = "GET_PAYMENT_BY_INSTALLMENT_SUCCESS",
    GET_PAYMENT_BY_INSTALLMENT_FAIL = "GET_PAYMENT_BY_INSTALLMENT_FAIL",

    EDIT_PART_PAYMENT_START = "EDIT_PART_PAYMENT_START",
    EDIT_PART_PAYMENT_SUCCESS = "EDIT_PART_PAYMENT_SUCCESS",
    EDIT_PART_PAYMENT_FAIL = "EDIT_PART_PAYMENT_FAIL",

}

