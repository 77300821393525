import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';
import * as API from 'us.collection.transactions/services';
import * as Actions from 'us.collection.transactions/actions';
import {
	Transaction,
	TransactionType,
	InterestApplication,
} from 'us.collection.transactions/constants/Actions';
import { CaseType } from 'us.helper/types/enums';
import { $Message, $MessageBox } from 'us.common/components';
import _ from 'lodash';
import { appInsights, i18n } from 'us.helper';
import {
	isMappingSuccess,
	prepareTransactionsForMapping,
} from 'us.collection.transactions/functions';

const { transactions, transactionTypes, interestApplication } = Actions;

const TransactionSagas = {
	/**
	 * Get transactions on us.box.case
	 * @param action
	 */
	getAll: function* (action: any) {
		const params = action?.payload ?? {};
		switch (params.entityType) {
			case CaseType.C:
				params.entityType = 'Case';
				break;
			case CaseType.S:
				params.entityType = 'SubCase';
				break;
			default:
				params.entityType = 'AR';
				break;
		}

		try {
			const data = yield call(API.getTransactions, params);
			if (data?.isError) {
				yield put(transactions.getAllFail(data?.errorMessage));
			} else {
				yield put(transactions.getAllSuccess(data));
			}
		} catch (error) {
			yield put(transactions.getAllFail(error));
			appInsights.trackException(
				`Get All Transaction Saga Exception - ${error}`
			);
		}
	},
	save: function* (action: any) {
		const params = action?.payload.data ?? {};
		const filters = action?.payload.filters ?? {};

		let entityType = 'Case';
		switch (filters?.caseType) {
			case 'S':
				entityType = 'SubCase';
				break;
			case 'C':
				entityType = 'Case';
				break;
			case 'A':
				entityType = 'AR';
				break;
			default:
				entityType = 'Case';
				break;
		}
		try {
			const data = yield call(API.saveTransactions, {
				boxtransactionList: params,
			});
			yield put(transactions.success(data));
			yield put(
				transactions.manageDrawer({
					title: '',
					visible: false,
					isNewTransaction: false,
					isDeleteTransaction: false,
				})
			);
			const responseTran = data?.processStepResultList?.find(
				(step: any) => step?.stepName == 'Get Transaction Profile'
			)?.transaction;
			const paymentId = responseTran?.paymentId;
			const arItemId = responseTran?.arItemId;

			if (paymentId == 0 && arItemId == 0) {
				$MessageBox(
					'error',
					`US.COLLECTION.TRANSACTIONS:MESSAGES.SAVING_FAILED`,
					'',
					''
				);
			} else if (data.resultStatus) {
				$MessageBox(
					'success',
					`US.COLLECTION.TRANSACTIONS:MESSAGES.SUCCESSFULLY_ADDED`,
					'',
					''
				);
			} else {
				$MessageBox(
					'error',
					`US.COLLECTION.TRANSACTIONS:MESSAGES.SAVING_FAILED`,
					'',
					''
				);
			}
			const transactionsList = yield call(API.getTransactions, {
				entityType: entityType,
				entityId: filters.caseId,
			});
			if (transactionsList.isError) {
				yield put(transactions.getAllFail(data?.errorMessage));
			} else {
				yield put(transactions.getAllSuccess(transactionsList));
			}
		} catch (error) {
			yield put(transactions.fail(new Error(error)));
			appInsights.trackException(`Save Transaction Saga Exception - ${error}`);
		}
	},
	addCreditNote: function* (action: any) {
		const params = action?.payload.data ?? {};
		const filters = action?.payload.filters ?? {};

		let entityType = 'Case';
		switch (filters?.caseType) {
			case 'S':
				entityType = 'SubCase';
				break;
			case 'C':
				entityType = 'Case';
				break;
			case 'A':
				entityType = 'AR';
				break;
			default:
				entityType = 'Case';
				break;
		}

		try {
			const data = yield call(API.addCreditNote, params);
			yield put(transactions.addCreditNoteSuccess(data));
			if (data) {
				$MessageBox(
					'success',
					`US.COLLECTION.TRANSACTIONS:MESSAGES.SUCCESSFULLY_CANCELLED`,
					'',
					''
				);
			} else {
				$MessageBox(
					'error',
					`US.COLLECTION.TRANSACTIONS:MESSAGES.CANCELLATION_FAILED`,
					'',
					''
				);
			}
			yield put(
				transactions.manageDrawer({
					title: '',
					visible: false,
					isNewTransaction: false,
					isDeleteTransaction: false,
				})
			);
			const transactionsList = yield call(API.getTransactions, {
				entityType: entityType,
				entityId: filters.caseId,
			});
			if (transactionsList.isError) {
				yield put(transactions.getAllFail(data?.errorMessage));
			} else {
				yield put(transactions.getAllSuccess(transactionsList));
			}
		} catch (error) {
			yield put(transactions.addCreditNoteFail(error));
			appInsights.trackException(`Add Credit Note Saga Exception - ${error}`);
		}
	},
	/**
	 * Get Transaction type
	 * @param action
	 */
	getTransactionTypes: function* (action: any) {
		const params = action.payload;
		try {
			const data = yield call(API.getTransactionType, params);
			if (_.isEmpty(data) || data?.isError) {
				yield put(transactionTypes.getAllFail(data));
			} else {
				yield put(transactionTypes.getAllSuccess(data));
			}
		} catch (error) {
			yield put(transactionTypes.getAllFail(error));
			appInsights.trackException(
				`Get Transaction Types Saga Exception - ${error}`
			);
		}
	},

	getMappingTransactionsByArItemNo: function* (action: any) {
		const params = action?.payload.data;
		try {
			const data = yield call(API.getMappingTransactionsByArItemNo, params);
			if (data.message || data.errors || data.isError || !Array.isArray(data)) {
				yield put(transactions.getMappingFail(data));
			} else {
				yield put(transactions.getMappingSuccess(data));
			}
		} catch (error) {
			yield put(transactions.getMappingFail(error));
			appInsights.trackException(
				`Get Mapping Transactions Sagas Exception - ${error}`
			);
		}
	},
	delete: function* (action: any) {
		const params = action?.payload?.data ?? {};
		const filters = action?.payload?.filters ?? {};

		let entityType = 'Case';
		switch (filters?.caseType) {
			case 'S':
				entityType = 'SubCase';
				break;
			case 'C':
				entityType = 'Case';
				break;
			case 'A':
				entityType = 'AR';
				break;
			default:
				entityType = 'Case';
				break;
		}

		try {
			const data = yield call(
				API.deleteTransactions,
				params.arItemNo,
				params.comment
			);
			yield put(transactions.deleteSuccess(data));
			yield put(
				transactions.manageDrawer({
					title: '',
					visible: false,
					isNewTransaction: false,
					isDeleteTransaction: false,
				})
			);
			$MessageBox(
				'success',
				`US.COLLECTION.TRANSACTIONS:MESSAGES.SUCCESSFULLY_DELETED`,
				'',
				''
			);
			const transactionsList = yield call(API.getTransactions, {
				entityType: entityType,
				entityId: filters.caseId,
			});
			if (transactionsList.isError) {
				yield put(transactions.getAllFail(data?.errorMessage));
			} else {
				yield put(transactions.getAllSuccess(transactionsList));
			}
		} catch (error) {
			$MessageBox(
				'error',
				`US.COLLECTION.TRANSACTIONS:MESSAGES.ERROR_IN_DELETION`,
				'',
				''
			);
			appInsights.trackException(
				`Delete Transaction Saga Exception - ${error}`
			);
		}
	},
	getArDetails: function* (action: any) {
		const params = action?.payload?.data;
		const withUpdate = action?.payload?.withUpdate;
		try {
			const data = yield call(API.getArDetails, params, withUpdate);
			if (data.message || data.errors || data.isError) {
				yield put(transactions.getArDetailsFail(data));
			} else {
				yield put(transactions.getArDetailsSuccess(data));
			}
		} catch (error) {
			yield put(transactions.getArDetailsFail(error));
			appInsights.trackException(`Get AR Details Saga Exception - ${error}`);
		}
	},
	getPopOverItems: function* (action: any) {
		const params = action?.payload?.data ?? {};
		try {
			// const data = yield call(API.getPopOverItems, params);
			if (Array.isArray([])) {
				yield put(transactions.getPopOverItemsSuccess([]));
			} else {
				yield put(transactions.getPopOverItemsFail('Error'));
			}
		} catch (error) {
			yield put(transactions.getPopOverItemsFail(error));
			appInsights.trackException(`Get popOver items Saga Exception - ${error}`);
		}
	},
	getPopOverValues: function* (action: any) {
		const params = action?.payload?.data ?? {};
		try {
			const { data, status } = yield call(API.getPopOverValues, params);

			if (Array.isArray(data) && status == 200) {
				yield put(
					transactions.getPopOverValuesSuccess({
						properties: data,
						activityCode: params.activityCode,
					})
				);
			} else {
				yield put(transactions.getPopOverValuesFail('Error'));
			}
		} catch (error) {
			yield put(transactions.getPopOverValuesFail(error));
			appInsights.trackException(
				`Get popOver values Saga Exception - ${error}`
			);
		}
	},
	saveDynamicPopOver: function* (action: any): any {
		const params = action?.payload?.data ?? {};
		try {
			const data = yield call(API.saveCourtFeeInvoice, params);
			if (data.hasOwnProperty('statusCode') && data.statusCode >= 0) {
				$MessageBox(
					data.statusCode == 1 ? 'success' : 'error',
					`US.COLLECTION.TRANSACTIONS:MESSAGES.${data?.statusMessage}`,
					'',
					''
				);
				yield put(transactions.saveDynamicPopOverSuccess(data));
			} else {
				$MessageBox(
					'error',
					`US.COLLECTION.TRANSACTIONS:MESSAGES.ERROR_IN_DYNAMIC_ACTIVITY`,
					'',
					''
				);
				yield put(transactions.saveDynamicPopOverFail('error'));
			}
		} catch (error) {
			$MessageBox(
				'error',
				`US.COLLECTION.TRANSACTIONS:MESSAGES.ERROR_IN_DYNAMIC_ACTIVITY`,
				'',
				''
			);
			yield put(transactions.saveDynamicPopOverFail(error));
			appInsights.trackException(
				`Save dynamic activity Saga Exception - ${error}`
			);
		}
	},
	mapItem: function* (action: any) {
		const params = action?.payload?.data ?? {};
		try {
			const { data, status } = yield call(API.mapItemByItem, params);

			if (Array.isArray(data) && status == 200) {
				yield put(transactions.mapItemSuccess(data));
			} else {
				yield put(transactions.mapItemFail('Error'));
			}
		} catch (error) {
			yield put(transactions.mapItemFail(error));
			appInsights.trackException(
				`Get popOver values Saga Exception - ${error}`
			);
		}
	},
	getMappingTransactions: function* (action: any) {
		const params = action?.payload?.data ?? {};
		try {
			const { data, status } = yield call(API.getMappingTransactions, params);

			if (Array.isArray(data) && status === 200) {
				yield put(
					transactions.getMappingTransactionsSuccess(
						prepareTransactionsForMapping(data)
					)
				);
			} else {
				yield put(transactions.getMappingTransactionsFail(data));
			}
		} catch (error) {
			yield put(transactions.getMappingTransactionsFail(error));
			appInsights.trackException(
				`Get Mapping Transactions Saga Exception - ${error}`
			);
		}
	},
	updateMappingTransactions: function* (action: any) {
		const params = action?.payload?.data ?? {};
		try {
			$Message.loading(
				i18n.t('US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TRANSACTION_UPDATING'),
				0
			);
			const { data, transactionsData } = yield call(
				API.updateMappingTransactions,
				params
			);
			$Message.destroy();
			if (isMappingSuccess(data)) {
				$MessageBox(
					'success',
					'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TRANSACTION_UPDATE_SUCCESS',
					'',
					''
				);
				yield put(transactions.updateMappingTransactionsSuccess(data));
				if (Array.isArray(transactionsData)) {
					yield put(transactions.getAllSuccess(transactionsData));
				}
			} else {
				$MessageBox(
					'error',
					'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TRANSACTION_UPDATE_FAILED',
					'',
					''
				);
				yield put(transactions.updateMappingTransactionsFail(data));
			}
		} catch (error) {
			$Message.destroy();
			$MessageBox(
				'error',
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TRANSACTION_UPDATE_FAILED',
				'',
				''
			);
			yield put(transactions.updateMappingTransactionsFail(error));
			appInsights.trackException(
				`Update Mapping Transactions Saga Exception - ${error}`
			);
		}
	},
	getMappingCases: function* (action: any) {
		const params = action?.payload?.data ?? {};
		try {
			const { data, status } = yield call(API.getMappingCases, params);

			if (Array.isArray(data) && status === 200) {
				yield put(
					transactions.getMappingCasesSuccess(
						prepareTransactionsForMapping(data)
					)
				);
			} else {
				yield put(transactions.getMappingCasesFail(data));
			}
		} catch (error) {
			yield put(transactions.getMappingCasesFail(error));
			appInsights.trackException(
				`Get Mapping Transactions Saga Exception - ${error}`
			);
		}
	},
	getInterestBMD: function* (action: any) {
		const params = action?.payload?.data ?? {};
		try {
			const { data, status } = yield call(API.getInterestBMD, params);

			if (Array.isArray(data) && status === 200) {
				yield put(transactions.getInterestBMDSuccess(data));
			} else {
				yield put(transactions.getInterestBMDFail(data));
			}
		} catch (error) {
			yield put(transactions.getInterestBMDFail(error));
			appInsights.trackException(
				`Get Mapping Transactions Saga Exception - ${error}`
			);
		}
	},
	getStandardInterestRateByDate: function* (action: any) {
		const params = action?.payload?.data ?? {};
		try {
			const { data, status } = yield call(
				API.getStandardInterestRateByDate,
				params
			);
			if (data && status === 200) {
				yield put(interestApplication.standardInterestRate.success(data));
			} else {
				yield put(interestApplication.standardInterestRate.fail(data));
			}
		} catch (error) {
			yield put(interestApplication.standardInterestRate.fail(error as any));
			appInsights.trackException(
				`Get Standard Interest Rate By Date Saga Exception - ${error}`
			);
		}
	},
	getCalculatedInterestRate: function* (action: any) {
		const params = action?.payload?.data ?? {};
		try {
			const { data, status } = yield call(
				API.getCalculatedInterestRate,
				params
			);

			if (data && status === 200) {
				yield put(interestApplication.calculatedInterest.success(data));
			} else {
				if (status === 204) {
					$MessageBox(
						'warning',
						'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.INTEREST_PREDICTION_NOT_AVAILABLE_FOR_FUTURE_DATES',
						'',
						''
					);
				}
				yield put(interestApplication.calculatedInterest.fail(data));
			}
		} catch (error) {
			yield put(interestApplication.calculatedInterest.fail(error as any));
			appInsights.trackException(
				`Get Calculated Interest Saga Exception - ${error}`
			);
		}
	},
	updateTransactionInterestRate: function* (action: any) {
		const { paramsUpdateTransaction, paramsGetTransaction } =
			action?.payload?.data ?? {};
		try {
			const { data, status } = yield call(
				API.updateTransactionInterestRate,
				paramsUpdateTransaction
			);

			if (data && data.isSuccess && status === 200) {
				$MessageBox(
					'success',
					'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TRANSACTION_UPDATE_SUCCESS',
					'',
					''
				);
				yield put(interestApplication.transactionInterest.success(data));
				switch (paramsGetTransaction.entityType) {
					case CaseType.C:
						paramsGetTransaction.entityType = 'Case';
						break;
					case CaseType.S:
						paramsGetTransaction.entityType = 'SubCase';
						break;
					default:
						paramsGetTransaction.entityType = 'AR';
						break;
				}
				const updatedTransactionsData = yield call(
					API.getTransactions,
					paramsGetTransaction
				);
				if (!updatedTransactionsData?.isError) {
					yield put(transactions.getAllSuccess(updatedTransactionsData));
				}
			} else {
				$MessageBox(
					'error',
					'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TRANSACTION_UPDATE_FAILED',
					'',
					''
				);
				yield put(interestApplication.transactionInterest.fail(data));
			}
		} catch (error) {
			$MessageBox(
				'error',
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TRANSACTION_UPDATE_FAILED',
				'',
				''
			);
			yield put(interestApplication.transactionInterest.fail(error as any));
			appInsights.trackException(
				`Update Transaction Interest Rates Saga Exception - ${error}`
			);
		}
	},
};

export default [
	takeLatest(
		Transaction.GET_TRANSACTIONS_BY_ENTITY_NUMBER_AND_ENTITY_TYPE_STRAT,
		TransactionSagas.getAll
	),
	takeLatest(
		TransactionType.GET_TRANSACTION_TYPES,
		TransactionSagas.getTransactionTypes
	),
	takeLatest(
		Transaction.GET_MAPPING_TRANSACTIONS_BY_TRANSACTIONID_STRAT,
		TransactionSagas.getMappingTransactionsByArItemNo
	),
	takeLatest(Transaction.SAVE_TRANSACTIONS, TransactionSagas.save),
	takeLatest(Transaction.DELETE_TRANSACTION, TransactionSagas.delete),
	takeLatest(Transaction.ADD_CREDIT_NOTE, TransactionSagas.addCreditNote),
	takeLatest(Transaction.GET_AR_DETAILS, TransactionSagas.getArDetails),
	takeLatest(Transaction.GET_POP_OVER_ITEMS, TransactionSagas.getPopOverItems),
	takeLatest(
		Transaction.SAVE_DYNAMIC_POP_OVER,
		TransactionSagas.saveDynamicPopOver
	),
	takeLatest(
		Transaction.GET_POP_OVER_FORM_VALUES,
		TransactionSagas.getPopOverValues
	),
	takeLatest(Transaction.MAP_ITEM_BY_ITEM, TransactionSagas.mapItem),
	takeLatest(
		Transaction.GET_MAPPING_TRANSACTIONS,
		TransactionSagas.getMappingTransactions
	),
	takeLatest(
		Transaction.UPDATE_MAPPING_TRANSACTIONS,
		TransactionSagas.updateMappingTransactions
	),
	takeLatest(Transaction.GET_MAPPING_CASES, TransactionSagas.getMappingCases),
	takeLatest(
		Transaction.GET_INTEREST_BMD_VALUE,
		TransactionSagas.getInterestBMD
	),
	takeLatest(
		InterestApplication.GET_STANDARD_INTEREST_RATE_BY_DATE,
		TransactionSagas.getStandardInterestRateByDate
	),
	takeLatest(
		InterestApplication.GET_CALCULATED_INTEREST,
		TransactionSagas.getCalculatedInterestRate
	),
	takeLatest(
		InterestApplication.UPDATE_TRANSACTION_INTEREST_RATE,
		TransactionSagas.updateTransactionInterestRate
	),
];
