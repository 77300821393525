import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect, useSelector } from 'react-redux';
import { ObsoletedateAction } from 'us.collection/actions';
import {
	$Button,
	$DateLabel,
	$DatePicker,
	$Popover,
	$Skeleton,
	$Spin,
	$Tooltip,
} from 'us.common/components';
import {
	ExclamationCircleFilled,
	LoadingOutlined,
	EditOutlined,
} from 'us.icons';
import { IObsoleteDateState } from 'us.collection/layouts/CaseView/Interfaces';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { RootState } from 'us.helper/types';

const ObsoleteDate: React.FC<PropsFromRedux> = (props) => {
	const { isLoading } = useSelector(
		(state: RootState) => state.obsoleteDate
	);

	const { t } = useTranslation([
		'US.COLLECTION.LAYOUT',
		'US.COLLECTION.COMMON',
		'US.COLLECTION.ROUTINESANDACTIVITIES',
	]);

	const { workflow, metaData, caseViewData, updateObsoleteDate } = props;

	const { obsoluteDate } = metaData.data ?? {};
	const { caseId } = caseViewData.data ?? {};

	const [
		{ pickedDate, isCalenderOpen, isConfirmPopupOpen },
		setObsoleteDateState,
	] = useState<IObsoleteDateState>({
		pickedDate: moment(),
		isCalenderOpen: false,
		isConfirmPopupOpen: false,
	});

	const handleUpdateObsoleteDate = (date: Moment) => {
		updateObsoleteDate &&
			updateObsoleteDate({
				caseId,
				dateType: 'ObsoleteDate',
				selectedDate: date.toISOString(),
				metaData: {
					...metaData.data,
					obsoluteDate: date.toISOString(),
				},
			});
	};

	return (
		<>
			<span className='bui-label mr-2'>
				{t('US.COLLECTION.LAYOUT:CASE.OBSOLETEDATE')}
			</span>
			{workflow.isLoading && (
				<$Skeleton.Input
					style={{ width: 200 }}
					active={true}
					size={'small'}
				/>
			)}
			{!workflow.isLoading && (
				<strong className='obsolete-date'>
					<$Tooltip
						placement='leftTop'
						title={t(
							'US.COLLECTION.LAYOUT:CASE.PENDING'
						)}>
						{obsoluteDate &&
							moment(
								obsoluteDate,
								'YYYY-MM-DD'
							).isValid() &&
							moment(
								obsoluteDate,
								'YYYY-MM-DD'
							).diff(moment()) && (
								<span className='cas-prp-date status-pending' />
							)}
					</$Tooltip>

					<$Popover
						placement='bottomLeft'
						content={
							<div
								style={{
									maxWidth: '300px',
								}}>
								<div className='ant-popover-message'>
									<ExclamationCircleFilled />
									<div className='ant-popover-message-title'>
										<div
											style={{
												paddingBottom:
													'0.3rem',
											}}>
											{t(
												'US.COLLECTION.LAYOUT:CASE.UPDATE_OBSOLETE_CONFORM'
											)}
										</div>
									</div>
								</div>
								<div className='ant-popover-buttons'>
									<$Button
										size='small'
										onClick={() =>
											setObsoleteDateState(
												(
													preState
												) => ({
													...preState,
													isConfirmPopupOpen:
														false,
												})
											)
										}>
										{t(
											'US.COLLECTION.COMMON:COMMON.NO'
										)}
									</$Button>
									<$Button
										size='small'
										type='primary'
										onClick={() => {
											setObsoleteDateState(
												(
													preState
												) => ({
													...preState,
													isConfirmPopupOpen:
														false,
												})
											);
											handleUpdateObsoleteDate(
												pickedDate
											);
										}}>
										{t(
											'US.COLLECTION.COMMON:COMMON.YES'
										)}
									</$Button>
								</div>
							</div>
						}
						trigger='click'
						visible={isConfirmPopupOpen}>
						<$DateLabel
							value={obsoluteDate}
						/>
						<$DatePicker
							className='obsolete-date-pick'
							name='yearPicker'
							open={isCalenderOpen}
							onSelect={(
								date: Moment
							) =>
								setObsoleteDateState(
									{
										pickedDate: date,
										isConfirmPopupOpen:
											true,
										isCalenderOpen:
											false,
									}
								)
							}
							disabledDate={(
								current: Moment
							) =>
								current &&
								current.isBefore(
									moment(),
									'day'
								)
							}
							onOpenChange={(
								visbile: boolean
							) =>
								setObsoleteDateState(
									(
										preState
									) => ({
										...preState,
										isCalenderOpen:
											false,
									})
								)
							}
						/>
					</$Popover>
					<$Tooltip
						placement='rightTop'
						title={t(
							'US.COLLECTION.COMMON:COMMON.EDIT'
						)}>
						<$Button
							size='small'
							type='text'
							className='px-1'
							onClick={() =>
								setObsoleteDateState(
									(
										preState
									) => ({
										...preState,
										isCalenderOpen:
											true,
									})
								)
							}
							disabled={
								metaData &&
								isSubCaseTransferredToCase(
									metaData
								)
							}>
							{isLoading && (
								<$Spin
									indicator={
										<LoadingOutlined
											style={{
												fontSize: 16,
											}}
											spin
										/>
									}
								/>
							)}
							{!isLoading && (
								<EditOutlined />
							)}
						</$Button>
					</$Tooltip>
				</strong>
			)}
		</>
	);
};

const mapState = (state: RootState) => {
	const { domainView } = state;
	const { metaData, workflow } = domainView;
	return {
		metaData,
		workflow,
		caseViewData: domainView.case,
	};
};

const { AddObsoletedate } = ObsoletedateAction;
const mapDispatch = {
	updateObsoleteDate: AddObsoletedate.update,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ObsoleteDate);
