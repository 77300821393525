import { IEnforcement, IEnforcementItem } from "us.collection.case/interfaces";
import {
  ISalaryDeductionPlan as ISalaryDeduction,
  ISalaryDeductionSchema,
} from "us.collection.case/interfaces";
import {
  SalaryDeductionSchedules,
  AssetTypes,
} from "us.collection.case/constants";
import { DateFormats } from "us.collection.case/constants";
import _ from "lodash";
import moment from "moment";
import { ICase } from "us.collection.case/reducers/Dashboard/Interfaces";
import CommonConstans from "us.common/constants";
import { appInsights } from "us.helper";
import { IAccountSummary } from "us.common/components/CaseBalanceUpdate/Interfaces";

const { languageCodes } = CommonConstans;
/**
 * Combine enforcement types
 * @param enforcements
 * @returns concat string of enforcement types
 */
export const setEnforcementTypes = (
  enforcements: IEnforcementItem[]
): string => {
  try {
    let enforcementTypes: Array<string> = [];
    enforcements?.map((enforcement: IEnforcementItem) => {
      enforcementTypes.push(enforcement.enforcementType);
    });
    return enforcementTypes.join();
  } catch (error) {
    appInsights.trackException(
      `Error in all enforcement types string concat. - ${error}`
    );
    return "";
  }
};

/**
 * Get summation of faceValue or valuation
 * @param enforcements
 * @param type {string} - can be facevalue or valuation
 * @returns {number} sum of faceValue or valuation
 */
export const setupTotalAmount = (
  enforcements: IEnforcementItem[],
  type: string
): number => {
  try {
    return enforcements
      ?.map((enforcement: any) => enforcement[`${type}`])
      ?.reduce((acc: any, curr: any) => acc + curr, 0);
  } catch (error) {
    appInsights.trackException(
      `Error in calculate ${type} in enforcedItems. - ${error}`
    );
    return 0;
  }
};

/**
 * Create datasource for table tree compenent
 * @param data
 * @returns datasource objects array
 */
export const setupEnforcementData = (data: Array<any>) => {
  let enforcementData: Array<any> = [];
  try {
    Object.values(
      _(data)
        .groupBy((item) => item.referenceId)
        .sortBy((group) => data.indexOf(group[0]))
        .value()
    ).map((item: any, index: number) => {
      if (item.length > 1) {
        enforcementData.push({
          key: index,
          referenceId: item[0]?.referenceId,
          enforcementId: "",
          registrationDate: "",
          enforcementTypes: "",
          totalValuation: "",
          totalFaceValue: "",
          comment: "",
          lastModifiedDate: "",
          children: item?.map((o: any, index: number) => {
            return {
              key: o?.referenceId + index,
              enforcementId: o?.enforcementId,
              registrationDate: o?.registrationDate,
              enforcementTypes: setEnforcementTypes(o.enforcedItems),
              totalValuation: setupTotalAmount(o.enforcedItems, "valuation"),
              totalFaceValue: setupTotalAmount(o.enforcedItems, "faceValue"),
              comment: o?.comment,
              lastModifiedUser: o?.lastModifiedUser,
              lastModifiedDate: o?.lastModifiedDate,
            };
          }),
        });
      } else if (item.length === 1) {
        enforcementData.push({
          key: index,
          referenceId: item[0]?.referenceId,
          enforcementId: "",
          registrationDate: "",
          enforcementTypes: "",
          totalValuation: "",
          totalFaceValue: "",
          comment: "",
          lastModifiedDate: "",
          children: [
            {
              key: item[0]?.referenceId,
              enforcementId: item[0]?.enforcementId,
              registrationDate: item[0]?.registrationDate,
              enforcementTypes: setEnforcementTypes(item[0]?.enforcedItems),
              totalValuation: setupTotalAmount(
                item[0]?.enforcedItems,
                "valuation"
              ),
              totalFaceValue: setupTotalAmount(
                item[0]?.enforcedItems,
                "faceValue"
              ),
              comment: item[0]?.comment,
              lastModifiedDate: item[0]?.lastModifiedDate,
              lastModifiedUser: item[0]?.lastModifiedUser,
            },
          ],
        });
      }
    });
    return enforcementData;
  } catch (error) {
    appInsights.trackException(
      `Error in setup Enforcements list data - ${error}`
    );
    return enforcementData;
  }
};

/**
 * filter Assets to select component of the asset field
 * @param {Array<any>} assets all the assets registered to particular case
 * @param {*} selectedAsset selected single asset
 * @param {Array<any>} selectedAssets currently selected array of assets
 * @return {Array<any>} filtered assets list
 */
export const getAssetsOption = (
  assets: Array<any>,
  selectedAsset: any,
  selectedAssets: Array<any>
): Array<any> => {
  const noAsset = {
    key: `-1-`,
    assetId: -1,
    entityId: -1,
    entityType: "DEB",
    assetType: "No Asset",
    displayName: "No Asset",
    faceValue: 0,
    estimatedValue: 0,
    valuationAmount: 0,
    createdDate: "",
    createdUser: "",
    description: "",
    isEnforced: false,
  };

  try {
    // check there is any employer asset already selected.
    const employerAsset = _.find(selectedAssets, {
      assetType: AssetTypes.EMPLOYER,
    });

    // if there is already selected enforced items available.
    if (selectedAssets.length > 1) {
      // when add new button click - enforcementItemId=-1 and list assets except employer asset if already selected.
      if (selectedAsset.enforcementItemId == -1) {
        return _.filter(
          assets.map((asset: any, index: number) => {
            return {
              ...asset,
              displayName: `${asset?.assetType} - ${asset?.description}`,
            };
          }),
          (asset: any) =>
            employerAsset && selectedAsset.assetType != AssetTypes.EMPLOYER
              ? asset.assetType != AssetTypes.EMPLOYER
              : asset
        );
      }
      // when after select the asset / edit enforcement view for the already registered enforced items.
      else {
        // if an employer asset selected.
        if (selectedAsset.assetType == AssetTypes.EMPLOYER) {
          return assets.map((asset: any, index: number) => {
            return {
              ...asset,
              displayName: `${asset?.assetType} - ${asset?.description}`,
            };
          });
        }
        // if selected asset is not an employer asset.
        else {
          return _.filter(
            assets.map((asset: any, index: number) => {
              return {
                ...asset,
                displayName: `${asset?.assetType} - ${asset?.description}`,
              };
            }),
            (asset: any) =>
              employerAsset ? asset.assetType != AssetTypes.EMPLOYER : asset
          );
        }
      }
    }
    // initial new enforcement UI
    return [
      noAsset,
      ...assets.map((asset: any, index: number) => {
        return {
          ...asset,
          displayName: `${asset?.assetType} - ${asset?.description}`,
        };
      }),
    ];
  } catch (error) {
    appInsights.trackException(
      `Error in list the asset options in Add Enforcement View. - ${error}`
    );
    return [noAsset];
  }
};

/**
 * filter enforcement type options to enforcement type dropdown
 * @param {Array<any>} enforcementTypes all the enforcement Types irrespective to the asset
 * @param {*} selectedAsset selected single asset
 * @param {Array<any>} selectedAssets currently selected assets
 * @return {Array<any>}  filter enforcement type
 */
export const getEnforcementTypeOption = (
  enforcementTypes: Array<any>,
  selectedAsset: any,
  selectedAssets: Array<any>
): Array<any> => {
  try {
    // check there is Debt Certificate enforcement type already selected.
    const debtCertificateAsset = _.find(selectedAssets, {
      enforcementType: "Debt Certificate",
    });

    // if selected enforcement type is Debt Certificate
    if (selectedAsset.enforcementType == "Debt Certificate") {
      return _.filter(
        enforcementTypes,
        (enforcementType: any) =>
          enforcementType.assetType ==
          (selectedAsset?.assetType != "No Asset"
            ? selectedAsset?.assetType
            : "")
      );
    }
    // if selected enforcement type is other than Debt Certificate
    else {
      return _.filter(
        enforcementTypes,
        (enforcementType: any) =>
          enforcementType.assetType ==
            (selectedAsset?.assetType != "No Asset"
              ? selectedAsset?.assetType
              : "") &&
          (debtCertificateAsset
            ? enforcementType.enforcementTypeName != "Debt Certificate"
            : enforcementType)
      );
    }
  } catch (error) {
    appInsights.trackException(
      `Error in list the enforcement  options in Add Enforcement View. - ${error}`
    );
    return enforcementTypes;
  }
};

/**
 * dueDate changes for amount column changes
 * @param {moment.Moment} dueDate
 * @param {SalaryDeductionSchedules} schedule schedules such as Weekly, Biweekly, Monthly
 * @return {string}  due date
 */
const setDueDateForAmountChange = (
  dueDate: moment.Moment,
  schedule: SalaryDeductionSchedules
): string => {
  switch (schedule) {
    case SalaryDeductionSchedules.MONTHLY:
      return moment(dueDate).add(1, "months").format(DateFormats.REQ);
    case SalaryDeductionSchedules.WEEKLY:
      return moment(dueDate).add(7, "days").format(DateFormats.REQ);
    case SalaryDeductionSchedules.BIWEEKLY:
      return moment(dueDate).add(14, "days").format(DateFormats.REQ);
  }
};

/**
 * dueDate changes for due date column changes
 * @param {moment.Moment} dueDate
 * @param {SalaryDeductionSchedules} schedule
 * @param {number} step
 * @return {string} due date
 */
const setDueDateForDueChange = (
  dueDate: moment.Moment,
  schedule: SalaryDeductionSchedules,
  step: number
): string => {
  switch (schedule) {
    case SalaryDeductionSchedules.MONTHLY:
      return moment(dueDate)
        .add(1 * step, "months")
        .format(DateFormats.REQ);
    case SalaryDeductionSchedules.WEEKLY:
      return moment(dueDate)
        .add(7 * step, "days")
        .format(DateFormats.REQ);
    case SalaryDeductionSchedules.BIWEEKLY:
      return moment(dueDate)
        .add(14 * step, "days")
        .format(DateFormats.REQ);
  }
};

/**
 * salary deduction schema changes for due date column changes
 * @param {string} installmentDueDate
 * @param {ISalaryDeduction} values salary deduction schema
 * @param {number} installmentIndex changed salary deduction schema index
 * @return {*}
 */
export const calculateSchemaForDueDate = (
  installmentDueDate: string,
  values: ISalaryDeduction,
  installmentIndex: number
): Array<any> => {
  const deductionSchedule: SalaryDeductionSchedules = values.deductionSchedule;
  const prevSalaryDeductionSchema = values.salaryDeductionSchema;
  const finalSalaryDeductionSchema: any[] = [];
  try {
    prevSalaryDeductionSchema.map(
      (
        installment: ISalaryDeductionSchema,
        index: number,
        array: Array<ISalaryDeductionSchema>
      ) => {
        const newInstallment = {
          ...installment,
          dueDate:
            installmentIndex <= index
              ? setDueDateForDueChange(
                  moment(installmentDueDate),
                  deductionSchedule,
                  index - installmentIndex
                )
              : moment(installment.dueDate).format(DateFormats.REQ),
        };
        finalSalaryDeductionSchema.push(newInstallment);
        return newInstallment;
      }
    );
    return finalSalaryDeductionSchema;
  } catch (error) {
    appInsights.trackException(
      `Error in calculate salary schema in Add Enforcement view by dueDate change. - ${error}`
    );
    return finalSalaryDeductionSchema;
  }
};

/**
 * salary deduction schema changes for amount column changes
 * @param {ISalaryDeduction} values
 * @return {Array<any>}  {Array<any>}
 */
export const calculateSchemaForAmount = (
  values: ISalaryDeduction,
  clickedSeqId: number
): Array<any> => {
  let totalBalance = values.faceValue;
  const deductionAmount = values.deductionAmount;
  const deductionSchedule: SalaryDeductionSchedules = values.deductionSchedule;
  const prevSalaryDeductionSchema = values.salaryDeductionSchema;
  const finalSalaryDeductionSchema: any[] = [];
  try {
    prevSalaryDeductionSchema.map(
      (
        installment: ISalaryDeductionSchema,
        index: number,
        array: Array<ISalaryDeductionSchema>
      ) => {
        const newInstallment = {
          ...installment,
          balance:
            totalBalance > Number(installment.termAmount)
              ? totalBalance - Number(installment.termAmount)
              : 0,
          termAmount:
            totalBalance > Number(installment.termAmount)
              ? Number(installment.termAmount)
              : totalBalance,
          outstandingAmount:
            totalBalance > Number(installment.termAmount)
              ? Number(installment.termAmount)
              : totalBalance,
        };
        if (
          (newInstallment.termAmount > 0 && newInstallment.balance >= 0) ||
          (newInstallment.termAmount == 0 && newInstallment.balance > 0)
        ) {
          if (clickedSeqId == prevSalaryDeductionSchema.length) {
            finalSalaryDeductionSchema.push(newInstallment);
            totalBalance =
              totalBalance > Number(installment.termAmount)
                ? totalBalance - Number(installment.termAmount)
                : 0;
          } else if (index < prevSalaryDeductionSchema.length - 1) {
            finalSalaryDeductionSchema.push(newInstallment);
            totalBalance =
              totalBalance > Number(installment.termAmount)
                ? totalBalance - Number(installment.termAmount)
                : 0;
          }
        }
        return newInstallment;
      }
    );
    while (totalBalance > 0) {
      finalSalaryDeductionSchema.push({
        sequenceId: finalSalaryDeductionSchema.length + 1,
        dueDate: setDueDateForAmountChange(
          moment(
            finalSalaryDeductionSchema[finalSalaryDeductionSchema.length - 1]
              .dueDate
          ),
          deductionSchedule
        ),
        termAmount:
          totalBalance > deductionAmount ? deductionAmount : totalBalance,
        balance:
          totalBalance > deductionAmount ? totalBalance - deductionAmount : 0,
        outstandingAmount:
          totalBalance > deductionAmount ? deductionAmount : totalBalance,
        isTermAmountEditable: false,
        isDueDateEditable: true,
      });
      totalBalance =
        totalBalance > deductionAmount ? totalBalance - deductionAmount : 0;
    }
    return finalSalaryDeductionSchema;
  } catch (error) {
    appInsights.trackException(
      `Error in calculate salary schema in Add Enforcement view by amount change. - ${error}`
    );
    return finalSalaryDeductionSchema;
  }
};

/**
 * Set Enforcement displayName according to language
 * @param {ICase} caseDetail
 * @param {string} currentLanguage
 * @returns {string} enforcementDisplayName
 */
export const setEnforcementDisplayName = (
  currentLanguage: string,
  caseDetail: ICase | undefined
): string => {
  try {
    const { enforcementTypesDisplayName = "", enforcementTypes = "" } =
      caseDetail ?? {};
    const { nbNO, enGB } = languageCodes;
    switch (currentLanguage) {
      case nbNO:
        return enforcementTypes;
      case enGB:
        return enforcementTypesDisplayName;
      default:
        return enforcementTypesDisplayName;
    }
  } catch (error) {
    return "";
  }
};

/**
 * @function
 * @description replace case balance by account summary details
 * @param {IAccountSummary} AccountSummaryDetails
 * @returns {Object}
 */
export const replaceCaseBalanceByAccountSummary = ({
  mainAmount,
  caseCost,
  collectionFee,
  courtFee,
  otherCost,
  mainAmountInterest,
  interestOfCost,
}: IAccountSummary): Object => {
  try {
    return {
      mainAmount,
      caseCost,
      collectionFee,
      courtFee,
      otherCost,
      interestOfMainAmount: mainAmountInterest,
      interestOfCosts: interestOfCost,
    };
  } catch (error) {
    appInsights.trackException(
      `Error in replace case balance by account summary. - ${error}`
    );
    return {};
  }
};
