import { IAPIAction } from "us.collection/interfaces";
import ActionConsts from 'us.collection.routines-and-activities/constants'
import { IHistory } from 'us.collection.routines-and-activities/interfaces'

const { ACTIONS } = ActionConsts
const { LogHistory } = ACTIONS

export const historyAction: Readonly<IHistory & IAPIAction> = {
    logHistory: {
        get: (data) => ({
            type: LogHistory.GET_LOG_HISTORY_STRAT,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: LogHistory.GET_LOG_HISTORY_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: LogHistory.GET_LOG_HISTORY_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        setFilter: (data) => ({
            type: LogHistory.LOGS_FILTER_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        setSearch: (data) => ({
            type: LogHistory.LOGS_SEARCH_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        reset: (data) => ({
            type: LogHistory.RESET_FILTER_AND_SEARCH,
            payload: {
                data,
                isLoading: true
            }
        })
    },
    attachment: {
        get: (data) => ({
            type: LogHistory.GET_ATTACHMENT_DATA_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: LogHistory.GET_ATTACHMENT_DATA_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: LogHistory.GET_ATTACHMENT_DATA_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        reset: (data) => ({
            type: LogHistory.RESET_ATTACHMENT_DATA,
            payload: {
              data,
              isLoading: true,
            },
          }),
    },
    noteStatus: {
        set: (data) => ({
            type: LogHistory.NOTE_NOTIFIED_STATUS_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: LogHistory.NOTE_NOTIFIED_STATUS_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: LogHistory.NOTE_NOTIFIED_STATUS_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
    // activities: {
    //     setFilter: (data) => ({
    //         type: LogHistory.ACTIVITIES_FILTER_START,
    //         payload: {
    //             data,
    //             isLoading: true
    //         }
    //     }),
    //     set: (data) => ({
    //         type: LogHistory.SET_USER_NAME_CASE_NUMBER,
    //         payload: {
    //             data,
    //             isLoading: true
    //         }
    //     }),
    // },
    delete: {
        delete: (data) => ({
            type: LogHistory.DELETE_ACTIVITY_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: LogHistory.DELETE_ACTIVITY_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: LogHistory.DELETE_ACTIVITY_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
    note: {
        save: (data) => ({
            type: LogHistory.UPDATE_NOTE_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: LogHistory.UPDATE_NOTE_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: LogHistory.UPDATE_NOTE_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        openDrawer: (data) => ({
            type: LogHistory.EDIT_NOTE_DRAWER,
            payload: {
                data,
                isLoading: true
            }
        }),
    },
    deleteNote: {
        delete: (data) => ({
            type: LogHistory.DELETE_NOTE_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: LogHistory.DELETE_NOTE_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: LogHistory.DELETE_NOTE_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
    print: {
        cancel: (data) => ({
            type: LogHistory.PRINT_CANCEL_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: LogHistory.PRINT_CANCEL_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: LogHistory.PRINT_CANCEL_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
}