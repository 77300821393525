import _ from 'lodash';
import { MappingTransaction } from 'us.collection.transactions/components/ArTransactions/Interfaces';
import { MappingItem } from 'us.collection.transactions/interfaces';

/**
 * @description If the itemTypeId of the mappingTransaction is in the reMapEnabledItemTypeIds array, return true,
 * otherwise return false.
 * @param {MappingTransaction} mappingTransaction - MappingTransaction
 * @returns A boolean value.
 */
export const canReMap = (
	mappingTransaction: MappingTransaction,
	availableBalanceToMap: number
): boolean => {
	const reMapEnabledItemTypeIds = [
		5, 11, 16, 27, 33, 38, 47, 56, 67, 111, 206, 208, 300, 303, 305,
		306, 400, 401, 501, 502, 503, 504, 508, 509, 522, 523, 531, 532,
		533, 601, 602, 603, 604, 605,
	];
	try {
		const { isMappedTransaction, itemTypeId, isParent, balance } =
			mappingTransaction;
		return (
			!isMappedTransaction &&
			!isParent &&
			!(availableBalanceToMap < 0 && balance === 0)
		);
	} catch (error) {
		return false;
	}
};

/**
 * @description Calculate the total mapped amount for the newly mapped transactions
 * @param {MappingTransaction[]} mappingTransactions - MappingTransaction[]
 * @returns The total amount of the mapped transactions.
 */
export const getTotalMappedAmount = (
	mappingTransactions: MappingTransaction[],
	mappingAmount: number
): number => {
	try {
		if (mappingAmount < 0) {
			// get only newly mapped transactions
			const mappedTransactions = mappingTransactions.filter(
				({ isMappedTransaction }: MappingTransaction) =>
					!isMappedTransaction
			);
			// calculate total newly mapped amount
			const total = _.sumBy(
				_.cloneDeep(mappedTransactions),
				function ({
					balance,
					newBalance,
				}: MappingTransaction) {
					const difference = Number(
						newBalance - balance
					).toFixed(2);
					return parseFloat(difference);
				}
			);

			return total;
		} else {
			const mappedTransactions = mappingTransactions.filter(
				({
					isMappedTransaction,
					isMapped,
				}: MappingTransaction) =>
					!isMappedTransaction && isMapped
			);
			return mappedTransactions.length > 0
				? mappingAmount
				: 0;
		}
	} catch (error) {
		return 0;
	}
};

/**
 * @description Map child transactions item by item according to the selected keys.
 * @param {MappingTransaction} childTransactions - MappingTransaction,
 * @param {React.Key[]} selectedRowKeys - Selected transactions keys for mapping
 * @param {number} mappingAmount - Available amount to map;
 * @returns The updated child transactions list after mapping.
 */
const mapChildTransactions = (
	childTransactions: MappingTransaction[],
	selectedRowKeys: React.Key[],
	mappingAmount: number
): MappingTransaction[] => {
	try {
		const totalMappedAmount = getTotalMappedAmount(
			_.cloneDeep(childTransactions),
			mappingAmount
		);

		// calculate remaining amount to be mapped
		const remainingMappingAmount: number = parseFloat(
			Number(mappingAmount - totalMappedAmount).toFixed(2)
		);

		const updatedChildTransactions: MappingTransaction[] = [];
		childTransactions.map(
			(transaction: MappingTransaction, _index: number) => {
				const { balance, transactionId, isMapped } =
					transaction;

				// check whether the item should updated or not
				const shouldUpdateNewBalance =
					selectedRowKeys.includes(transactionId);

				if (shouldUpdateNewBalance) {
					// selected item to map
					// need to update the new balance with mapped amount
					if (isMapped) {
						updatedChildTransactions.push(
							transaction
						);
					} else {
						let newBalance: number = 0;

						if (
							remainingMappingAmount <
								0 &&
							balance <
								Math.abs(
									remainingMappingAmount
								)
						) {
							// minus amount mapping
							// mapped to full balance
							newBalance = 0;
						} else {
							// map to remaining mapping balance
							newBalance = parseFloat(
								Number(
									balance +
										remainingMappingAmount
								).toFixed(2)
							);
						}
						updatedChildTransactions.push(
							Object.assign(
								transaction,
								{
									isMapped: true,
									newBalance,
								}
							)
						);
					}
				} else {
					if (isMapped) {
						// if recently mapped but not includes in the keys
						// unmapped recently mapped transaction
						updatedChildTransactions.push(
							Object.assign(
								transaction,
								{
									isMapped: false,
									newBalance: 0,
								}
							)
						);
					} else {
						// no need to update the new balance
						updatedChildTransactions.push(
							transaction
						);
					}
				}
			}
		);
		return updatedChildTransactions;
	} catch (error) {
		return childTransactions;
	}
};

/**
 * @description It takes a parentTransaction object and a mappedAmount number and returns a new parentTransaction
 * object with the newBalance property updated and the isMapped property set to true.
 * @param {MappingTransaction} parentTransaction - MappingTransaction,
 * @param {number} mappedAmount - mapped amount to child transactions;
 * @returns The parentTransaction object is being returned.
 */
const updateParentMapTransaction = (
	parentTransaction: MappingTransaction,
	mappedAmount: number
): MappingTransaction => {
	try {
		const newBalance = parseFloat(
			Number(
				parentTransaction.balance - mappedAmount
			).toFixed(2)
		);

		return {
			...parentTransaction,
			newBalance,
			isMapped: true,
		};
	} catch (error) {
		return parentTransaction;
	}
};

/**
 * It takes a list of transactions and a list of selected row keys, and returns a new list of
 * transactions with updated new balance.
 * @param {MappingTransaction[]} transactions - MappingTransaction[]
 * @param {React.Key[]} selectedRowKeys - React.Key[]
 * @returns An array of objects.
 */
export const mappingTransactionsWithNewBalance = (
	transactions: MappingTransaction[],
	selectedRowKeys: React.Key[],
	mappingAmount: number
): MappingTransaction[] => {
	try {
		if (mappingAmount < 0) {
			// handle negative amount mapping
			const updatedTransactions: MappingTransaction[] = [];

			// handle child transactions mapping
			const childTransactions = transactions.filter(
				({ isMappedTransaction }: MappingTransaction) =>
					!isMappedTransaction
			);
			// map child transactions and get updated list
			const updatedChildTransactions = mapChildTransactions(
				_.cloneDeep(childTransactions),
				selectedRowKeys,
				mappingAmount
			);

			// update the list with mapped transactions
			updatedTransactions.push(...updatedChildTransactions);

			// handle parent transaction mapping
			const parentTransactionIndex =
				getParentTransactionIndex(transactions);

			if (parentTransactionIndex > -1) {
				// extract the parent transaction
				const parentTransaction =
					_.cloneDeep(transactions)[
						parentTransactionIndex
					];
				// update parent transaction balance or reset
				if (selectedRowKeys.length > 0) {
					const totalMappedAmount =
						getTotalMappedAmount(
							updatedChildTransactions,
							mappingAmount
						);
					const updatedParentTransactions =
						updateParentMapTransaction(
							parentTransaction,
							totalMappedAmount
						);
					updatedTransactions.splice(
						parentTransactionIndex,
						0,
						updatedParentTransactions
					);
				} else {
					updatedTransactions.splice(
						parentTransactionIndex,
						0,
						parentTransaction
					);
				}
			}
			return updatedTransactions;
		} else {
			// handle positive amount mapping
			return transactions.map(
				(
					transaction: MappingTransaction,
					_index: number
				) => {
					return {
						...transaction,
						isMapped: true,
					};
				}
			);
		}
	} catch (error) {
		return transactions;
	}
};

/**
 * @description Generate mapping items to map AR transactions
 * @param {MappingTransaction[]} transactions - MappingTransaction[]
 * @returns {MappingItem[]} An array of `MappingItem`.
 */
export const getItemsToMap = (
	transactions: MappingTransaction[],
	selectedRowKeys: React.Key[],
	mappingAmount: number
): MappingItem[] => {
	try {
		const mappings: MappingItem[] = [];
		// get selected transactions
		transactions.map(
			({
				transactionId,
				newBalance,
				caseId,
				balance,
				isMapped,
			}: MappingTransaction) => {
				if (
					selectedRowKeys.includes(
						transactionId
					) &&
					isMapped
				) {
					const mappedAmount =
						mappingAmount < 0
							? parseFloat(
									Number(
										newBalance -
											balance
									).toFixed(
										2
									)
							  )
							: mappingAmount;
					mappings.push({
						transactionId,
						mappedAmount,
						comment: '',
						caseId,
					});
				}
			}
		);
		// generate array of objects
		return mappings;
	} catch (error) {
		return [];
	}
};

/**
 * @description It takes an array of objects and groups them by a property called caseNo
 * @param {MappingTransaction[]} transactions - MappingTransaction[]
 * @returns An array of objects.
 */
export const groupByCaseNo = (transactions: MappingTransaction[]): any[] => {
	try {
		const updatedTransactions: any[] = [];
		const groupedTransactions = Object.entries(
			_.groupBy(transactions, 'caseNo')
		);
		groupedTransactions.forEach(([caseNo, children], _index) => {
			updatedTransactions.push({
				id: caseNo,
				isParent: true,
				caseNo,
				children,
				caseId: children[0].caseId,
			});
		});
		return updatedTransactions;
	} catch (error) {
		return [];
	}
};

/**
 * It returns the index of the first mapped transaction in an array of transactions
 * @param {MappingTransaction[]} mappingTransactions - MappingTransaction[]
 * @returns The index of the first mapped transaction in the array.
 */
const getParentTransactionIndex = (
	mappingTransactions: MappingTransaction[]
): number => {
	try {
		return mappingTransactions.findIndex(
			(transaction: MappingTransaction) =>
				transaction.isMappedTransaction
		);
	} catch (error) {
		return -1;
	}
};

/**
 * To re map must include a parent transaction and at least one child related to the parent
 * @param {any} values - form values
 * @returns A boolean value.
 */
export const ableToReMap = (values: any, mappingAmount: number): boolean => {
	try {
		const { transactions, selectedRowKeys } = values ?? {};
		if (selectedRowKeys.length > 0 && transactions.length > 0) {
			// has selected item(s)
			const selectedItems = transactions.filter(
				({
					transactionId,
					isMapped,
				}: MappingTransaction) => {
					return (
						selectedRowKeys.includes(
							transactionId
						) && isMapped
					);
				}
			);
			const parentTransactionIndex =
				getParentTransactionIndex(transactions);
			return (
				parentTransactionIndex > -1 &&
				selectedItems.length > 0 &&
				isFullyMapped(transactions, mappingAmount)
			);
		} else {
			// no any item selected or no mapping transactions
			return false;
		}
	} catch (error) {
		return false;
	}
};

/**
 * It returns true if the total amount of the transactions mapped to the mapping amount is equal to the
 * mapping amount
 * @param {MappingTransaction[]} transactions - MappingTransaction[]
 * @param {number} mappingAmount - number
 * @returns A function that takes two parameters and returns a boolean.
 */
export const isFullyMapped = (
	transactions: MappingTransaction[],
	mappingAmount: number
): boolean => {
	try {
		const totalMapped = getTotalMappedAmount(
			transactions,
			mappingAmount
		);
		return Math.abs(mappingAmount - totalMapped) === 0;
	} catch (error) {
		return false;
	}
};
