import { EntityType } from "us.collection/components/BMD/Constants";
import { DrawerTypes } from "us.collection/constants";
import { hasOverRides } from "./HasOverRides";
import { IDrawerStatus } from "us.collection/interfaces";

/**
 * @function
 * @description - To decide if the confirmation popup on warning is required
 * @param {EntityType} entityType - the route type/ tab type
 * @param {IDrawerStatus} drawerStatus - state the purpose of the drawer opening
 * @return {*}  {boolean} - the decision if the popup is required
 */
export const isPopUpDissabled = (entityType: EntityType, drawerStatus: IDrawerStatus): boolean => {
  return (
    drawerStatus.drawerType === DrawerTypes.ADD_PROPERTY ||
    !(
      drawerStatus.drawerType === DrawerTypes.EDIT_BMD &&
      hasOverRides(entityType, drawerStatus)
    )
  );
};
