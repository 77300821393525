import moment from "moment";
import { ISalaryDeductionPlan } from "us.collection.case/interfaces";
import { SalaryDeductionSchedules } from "us.collection.case/constants";

export const salaryDeductionPlan : ISalaryDeductionPlan = {
    caseId: "",
    enforcementId: -1,
    fromDate: moment(),  /* Start date for the Salary Deduction Plan */
    deductionSchedule: SalaryDeductionSchedules.MONTHLY, /* Possible values = {Weekly,BiWeekly,Monthly} */
    deductionAmount: 0,
    faceValue: 0, 
    isGiroTemplate: false,
    nextInstallmentDate: moment(),
    nextTermAmount: "0",
    saveToDB: false, /* false- salary deduction schema is calculated without saving to DB. true - given salary deduction schema is saved to db*/
    salaryDeductionSchema: []
}