import { DrawerType } from "us.collection.transactions/constants";
import { IReturnAction } from "us.common/interfaces";
import { appInsights } from "us.helper";

/**
 * @function
 * @description - Handle the drawer behaviour
 * @param {DrawerType} type - Opened Drawer type
 * @param {boolean} isOpen - If the drawer is open
 * @param {((<T>(data: object | T[]) => IReturnAction<T>) | undefined)} openDrawer
 * @author Darshana Waasala <waasalajb@unicorn-solutions.com>
 */
export const drawerStatusHandler = (
  type: DrawerType,
  isOpen: boolean,
  openDrawer: (<T>(data: object | T[]) => IReturnAction<T>) | undefined
): void => {
  try {
    let title: string;
    switch (type) {
      case DrawerType.CREATE_CASES:
        title = "US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.CREATE_NEW_CASE";
        break;
      case DrawerType.MERGE_CASES:
        title = "US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.MERGE_CASES";
        break;
      default:
        title = "";
        break;
    }
    openDrawer &&
      openDrawer({
        title,
        type,
        isOpen,
      });
  } catch (error) {
    appInsights.trackException(
      `Error in add case and subcases drawerStatusHandler function. - ${error}`
    );
  }
};
