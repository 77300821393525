import moment from "moment";
import { IEnforcement, IEnforcementItem } from "us.collection.case/interfaces";

const enforcementItem: IEnforcementItem = {
  key: `-1-`,
  enforcementItemId: -1,
  enforcementType: "",
  assetId: -1,
  assetType: "No Asset",
  faceValue: 0,
  valuation: 0,
};

export const enforcement: IEnforcement = {
  enforcementId: -1,
  referenceId: "",
  journalId: "",
  registrationDate: moment(),
  executedDate: moment(),
  obsoleteDate: moment().add(10, 'years'),
  comment: "",
  lastModificedDate: moment(),
  lastModifiedUser: "",
  mainAmount: 0,
  courtFee: 0,
  collectionFee: 0,
  interestOfMainAmount: 0,
  interestOfCosts: 0,
  enforcedItems: [enforcementItem],
  mainAmountFromDate: null,
  mainAmountToDate: null,
  mainAmountRate: 0,
  caseCost: 0,
  caseCostFromDate: null,
  caseCostToDate: null,
  caseCostRate: 0,
  collectionFeeFromDate: null,
  collectionFeeToDate: null,
  collectionFeeRate: 0,
  courtFeeFromDate: null,
  courtFeeToDate: null,
  courtFeeRate: 0,
  otherCost: 0,
  otherCostFromDate: null,
  otherCostToDate: null,
  otherCostRate: 0,
  interestOfMainAmountFromDate: null,
  interestOfMainAmountToDate: null,
  interestOfMainAmountRate: 0,
  interestOfCostsFromDate: null,
  interestOfCostsToDate: null,
  interestOfCostsRate: 0,
  isUpdateCaseBalance: false
};
