import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { $Button } from 'us.common/components';
import { LeftOutlined, RightOutlined } from 'us.icons';
import { followupFooterActions, domainViewAction } from 'us.collection/actions';
import { parse, stringify } from 'query-string';
import {
	getDisplayName,
	getEntityDataFromURL,
	getEntityType,
	getNextEntity,
} from './Function';
import { IFollowupFooter } from './Interface';
import { Formik } from 'formik';
import './FollowupFooter.scss';
import { followUps } from 'us.collection.followup/actions';
import { URLType } from 'us.collection/constants';
import _ from 'lodash';
import { RootState } from 'us.helper/types';

/**
 * @description Followup Footer
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2961571869/Follow-up+Navigation+UI+Design
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 20/04/2022
 */
const FollowupFooter: React.FC<PropsFromRedux & IFollowupFooter> = (props) => {
	const { t } = useTranslation();
	const history = useHistory();
	const {
		list,
		followUpNavigation,
		result,
		previousResult,
		tableDataWithTableFilters,
		filters,
		authorizationProfile,
		getMetaData,
		updateNavigationDetails,
		setPreviousResult,
		getCaseId,
	} = props;

	const { data } = list;
	const { location } = history;
	const { pathname } = location;
	const previousSearchParams = parse(location.search);
	const queryFollowupId = previousSearchParams['id'];
	const { viewedItems, initialEntityId, hit, entityIndex } =
		followUpNavigation ?? {};
	const { dataSource } = result ?? {};
	const { data: tableData } = tableDataWithTableFilters ?? {};
	const entityTypeInTheFilter = filters?.data?.entityType;

	const { entityId: paramsEntityId } = useMemo(() => {
		return getEntityDataFromURL(pathname);
	}, [pathname]);

	/**
	 * @function
	 * @description Navigation to next result
	 */
	const nextFollowupNavigation = () => {
		try {
			const { entityType, metaDataType } = getEntityType(
				entityTypeInTheFilter
			);
			const nextResultId = getNextEntity(
				entityIndex,
				tableData,
				entityTypeInTheFilter
			);
			let items: any[] = [...viewedItems];
			if (entityType.toLowerCase() == URLType.AR) {
				const debtorFollowUpId =
					Array.isArray(dataSource) &&
					dataSource.find(
						(claim: any) =>
							claim.Arno ==
							nextResultId
					)?.Followupid;
				items.push(debtorFollowUpId);
			} else {
				items.push(nextResultId);
			}
			updateNavigationDetails &&
				updateNavigationDetails({
					...followUpNavigation,
					viewedItems: items,
					entityIndex:
						entityIndex ==
						tableData.length - 1
							? 0
							: entityIndex + 1,
					hit: hit + 1,
				});
			setPreviousResult &&
				setPreviousResult([
					...previousResult.previousResultIds,
					paramsEntityId,
				]);
			const newSearchParams = stringify({
				...previousSearchParams,
				id: queryFollowupId,
			});
			if (
				entityTypeInTheFilter.toLowerCase() ===
				URLType.PAYMENT
			) {
				history.push({
					pathname: `/${entityType}/${nextResultId}/distribution`,
					search: newSearchParams,
					state: {
						currentTab: URLType.PAYMENT,
						originate: URLType.PAYMENT,
						accessTabs: [],
					},
				});
			} else {
				history.push({
					pathname: `/${entityType}/${nextResultId}`,
					search: newSearchParams,
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	/**
	 * @function
	 * @description Navigation to previous result
	 */
	const previousFollowupNavigation = () => {
		const { entityType, metaDataType } = getEntityType(
			entityTypeInTheFilter
		);
		const previousItemsListLength =
			previousResult.previousResultIds.length;
		const previousResultId =
			previousItemsListLength > 0
				? previousResult.previousResultIds[
						previousItemsListLength - 1
				  ]
				: -1;
		try {
			2;
			updateNavigationDetails &&
				updateNavigationDetails({
					...followUpNavigation,
					entityIndex:
						entityIndex == 0
							? tableData.length - 1
							: entityIndex - 1,
					hit: hit - 1,
				});
			const newSearchParams = stringify({
				...previousSearchParams,
				id: queryFollowupId,
			});
			if (
				entityTypeInTheFilter.toLowerCase() ===
				URLType.PAYMENT
			) {
				history.push({
					pathname: `/${entityType}/${previousResultId}/distribution`,
					search: newSearchParams,
					state: {
						currentTab: URLType.PAYMENT,
						originate: URLType.PAYMENT,
						accessTabs: [],
					},
				});
			} else {
				history.push({
					pathname: `/${entityType}/${previousResultId}`,
					search: newSearchParams,
				});
			}
			setPreviousResult &&
				setPreviousResult(
					previousResult.previousResultIds.slice(
						0,
						previousItemsListLength - 1
					)
				);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Formik
			initialValues={{ search: '' }}
			onSubmit={() => {}}
			enableReinitialize>
			{({ values, ...restProps }: any) => (
				<>
					<div className='dom-folowup-quick-nav d-flex align-items-center justify-content-center'>
						<$Button
							size='small'
							type='dashed'
							onClick={
								previousFollowupNavigation
							}
							data-testid='footer-back-navigation'
							disabled={
								previousResult
									.previousResultIds
									.length <=
								0
							}>
							<LeftOutlined />{' '}
							{t(
								'US.COMMON:FOLLOWUP_FOOTER.PREV_RESULT'
							)}
						</$Button>
						<div className='dom-folowup-quick-nav-text'>
							<small className='d-block bui-label'>
								{t(
									'US.COMMON:FOLLOWUP_FOOTER.FOLLOWUP'
								)}
							</small>
							<strong>
								{getDisplayName(
									data,
									queryFollowupId as string
								)}
							</strong>
						</div>
						<$Button
							size='small'
							type='dashed'
							onClick={
								nextFollowupNavigation
							}
							disabled={
								(initialEntityId ==
									getNextEntity(
										entityIndex,
										tableData,
										entityTypeInTheFilter
									) &&
									hit >
										1 &&
									hit ==
										tableData?.length) ||
								getNextEntity(
									entityIndex,
									tableData,
									entityTypeInTheFilter
								) == 0 ||
								hit ==
									tableData?.length ||
								(hit == 1 &&
									tableData.length ==
										1)
							}
							data-testid='footer-next-navigation'>
							{t(
								'US.COMMON:FOLLOWUP_FOOTER.NEXT_RESULT'
							)}
							<RightOutlined />
						</$Button>
					</div>
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { followupFooter, followUps, AuthorizationProfile } = state;
	const {
		followUpNavigation,
		list,
		result,
		tableDataWithTableFilters,
		filters,
	} = followUps;
	const { previousResult } = followupFooter;
	return {
		list,
		followUpNavigation,
		result,
		previousResult,
		tableDataWithTableFilters,
		filters,
		authorizationProfile: AuthorizationProfile.authorizationProfile,
	};
};

const { metaData, caseId } = domainViewAction;
const { followups, previousResult } = followupFooterActions;
const { followUpNavigation } = followUps;

const mapDispatchToProps = {
	setPreviousResult: previousResult.set,
	getFollowups: followups.get,
	getMetaData: metaData.get,
	updateNavigationDetails: followUpNavigation.set,
	getCaseId: caseId.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FollowupFooter);
