import React, { useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { CheckOutlined } from 'us.icons';
import Common from 'us.common';
import { connect } from 'react-redux';
import { enforcements } from 'us.collection.case/actions';
import { ISalaryDeductionPlan } from 'us.collection.case/components/Enforcements/Add/Components/SalaryDeductionPlan/Interfaces';
import {
	calculateSchemaForDueDate,
	calculateSchemaForAmount,
} from 'us.collection.case/functions';
import {
	SalaryDeductionSchedules,
	DateFormats,
} from 'us.collection.case/constants';
import SalaryDeductionPlanSchema from 'us.collection.case/components/Enforcements/Add/Components/SalaryDeductionPlan/Validations';
import { RootState } from 'us.helper/types';

const {
	$PageHeader,
	$Divider,
	$Button,
	$Breadcrumb,
	$Row,
	$Table,
	$Col,
	$Form,
	$DatePicker,
	$Switch,
	$InputAmount,
	$Select,
	$Popconfirm,
	$AmountLabel,
} = Common.Components;

const { salaryDeductionPlan } = enforcements;
const { save, set, resetDeductionSchema } = salaryDeductionPlan;

const SalaryDeductionPlan: React.FC<ISalaryDeductionPlan> = (props) => {
	const { t } = useTranslation();

	const {
		currentDateFormat,
		currentLanguage,
		currentCurrency,
		backToNewEnforcement,
		salaryDeductionPlan,
		selectedSalaryAsset,
		addSalaryDeductionPlan,
		setSalaryDeductionPlan,
		resetSalaryDeductionSchema,
		drawer,
		metaData,
		updateEmployerAsset,
	} = props;

	const [isPastDateConfirmed, setIsPastDateConfirmed] =
		useState<boolean>(false);
	const { caseId } = metaData.data ?? {};

	const column: any = (rest: any, values: any) => {
		const columns = [
			{
				title: t(
					'US.COLLECTION.CASE:ENFORCEMENTS.SEQUENCE_NO'
				),
				dataIndex: 'sequenceId',
				key: 'sequenceId',
				className: 'text-nowrap',
			},
			{
				title: t(
					'US.COLLECTION.COMMON:COMMON.DUE_DATE'
				),
				dataIndex: 'dueDate',
				key: 'dueDate',
				className: 'text-nowrap',
				render: (
					text: any,
					record: any,
					index: number
				) => {
					return (
						<div>
							<$DatePicker
								size='small'
								name={`salaryDeductionSchema[${
									record.sequenceId -
									1
								}]['dueDate']`}
								className='w-100'
								value={
									record.dueDate
								}
								format={
									currentDateFormat
								}
								disabled={
									!record.isDueDateEditable ||
									moment(
										record.dueDate
									).isBefore(
										moment().startOf(
											'day'
										)
									)
								}
								onChange={(
									date: moment.Moment
								) =>
									rest.setFieldValue(
										'salaryDeductionSchema',
										calculateSchemaForDueDate(
											date.format(
												DateFormats.REQ
											),
											values,
											record.sequenceId -
												1
										)
									)
								}
								disabledDate={(
									current: moment.Moment
								) => {
									if (
										record.sequenceId -
											1 ==
										0
									) {
										return (
											current &&
											current <
												moment(
													values.fromDate
												)
										);
									} else {
										return (
											current &&
											current <=
												moment(
													values
														.salaryDeductionSchema[
														record.sequenceId -
															2
													][
														'dueDate'
													]
												)
										);
									}
								}}
							/>
						</div>
					);
				},
			},
			{
				title: t('US.COLLECTION.COMMON:COMMON.AMOUNT'),
				dataIndex: 'termAmount',
				key: 'termAmount',
				align: 'right',
				className: 'text-nowrap',
				render: (
					text: any,
					record: any,
					index: number
				) => {
					return (
						<div className='d-flex'>
							<div className='flex-grow-1'>
								<$InputAmount
									min={0}
									placeholder='0,00'
									className='bui-number-input'
									size='small'
									value={
										record.termAmount >
										0
											? record.termAmount
											: 0
									}
									name={`salaryDeductionSchema[${
										record.sequenceId -
										1
									}]['termAmount']`}
									currentLanguage={
										currentLanguage
									}
									currentCurrency={
										currentCurrency
									}
									disabled={moment(
										record.dueDate
									).isBefore(
										moment().startOf(
											'day'
										)
									)}
									onChange={(
										val: number
									) => {
										values.salaryDeductionSchema.splice(
											record.sequenceId -
												1,
											1,
											{
												...values
													.salaryDeductionSchema[
													record.sequenceId -
														1
												],
												termAmount: val,
											}
										);
										rest.setFieldValue(
											'salaryDeductionSchema',
											values.salaryDeductionSchema
										);
										rest.setFieldValue(
											'inCalcIndex',
											record.sequenceId -
												1
										);
									}}
								/>
							</div>
							<$Button
								type={
									values.inCalcIndex ==
									record.sequenceId -
										1
										? 'primary'
										: 'default'
								}
								size='small'
								icon={
									<CheckOutlined />
								}
								disabled={
									!rest.isValid
								}
								onClick={() => {
									rest.setFieldValue(
										'salaryDeductionSchema',
										calculateSchemaForAmount(
											values,
											record.sequenceId
										)
									);
									rest.setFieldValue(
										'inCalcIndex',
										-1
									);
								}}
								style={{
									marginTop: 4,
								}}
							/>
						</div>
					);
				},
			},
			{
				title: t('US.COLLECTION.COMMON:COMMON.BALANCE'),
				dataIndex: 'balance',
				key: 'balance',
				align: 'right',
				className: 'text-nowrap',
				render: (
					text: any,
					record: any,
					index: number
				) => (
					<$AmountLabel
						value={Number(record.balance)}
					/>
				),
			},
			{
				title: t(
					'US.COLLECTION.CASE:ENFORCEMENTS.OUTSTANDING_AMOUNT'
				),
				dataIndex: 'outstandingAmount',
				key: 'outstandingAmount',
				align: 'right',
				className: 'text-nowrap',
				render: (
					text: any,
					record: any,
					index: number
				) => (
					<$AmountLabel
						value={Number(
							record.outstandingAmount
						)}
					/>
				),
			},
		];
		return columns;
	};

	return (
		<Formik
			initialValues={{
				...salaryDeductionPlan.data,
				faceValue: selectedSalaryAsset.faceValue,
				inCalcIndex: -1,
			}}
			validationSchema={SalaryDeductionPlanSchema}
			enableReinitialize
			onSubmit={(values: any, actions: any) => {
				setSalaryDeductionPlan({ ...values, caseId });
				backToNewEnforcement();
			}}
			validateOnMount
			validateOnBlur>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...rest
			}: any) => (
				<>
					<div className='d-flex'>
						<div className='flex-grow-1'>
							<$Breadcrumb className='mb-2'>
								<$Breadcrumb.Item>
									<a
										onClick={() =>
											backToNewEnforcement()
										}>
										{t(
											'US.COLLECTION.CASE:ENFORCEMENTS.NEWENFORCEMENT'
										)}
									</a>
								</$Breadcrumb.Item>
								<$Breadcrumb.Item>
									{t(
										'US.COLLECTION.CASE:ENFORCEMENTS.CREATE_SALARY_DEDUCTION_PLAN'
									)}
								</$Breadcrumb.Item>
							</$Breadcrumb>
							<$PageHeader
								className='p-0 mb-3'
								onBack={() =>
									backToNewEnforcement()
								}
								title={t(
									'US.COLLECTION.CASE:ENFORCEMENTS.SALARY_DEDUCTION_PLAN'
								)}
							/>
						</div>
					</div>
					<$Form layout='vertical'>
						<$Row
							gutter={16}
							className='mb-2'>
							<$Col span={6}>
								<$Popconfirm
									title={t(
										'US.COLLECTION.CASE:ENFORCEMENTS.FROM_DATE_IS_ORDER_THAN_THE_CURRENT_DATE'
									)}
									placement='topLeft'
									visible={
										drawer.isNewEnforcement &&
										moment(
											values?.fromDate
										).format(
											DateFormats.REQ
										) <
											moment().format(
												DateFormats.REQ
											) &&
										!isPastDateConfirmed
									}
									onConfirm={() =>
										setIsPastDateConfirmed(
											true
										)
									}
									onCancel={() =>
										rest.setFieldValue(
											'fromDate',
											moment()
										)
									}
									okText={t(
										'US.COMMON:COMMON.YES'
									)}
									cancelText={t(
										'US.COMMON:COMMON.NO'
									)}>
									<$DatePicker
										required
										label={t(
											'US.COLLECTION.COMMON:COMMON.FROM_DATE'
										)}
										size='small'
										name='fromDate'
										className='w-100'
										format={
											currentDateFormat
										}
										placeholder={t(
											'US.COLLECTION.CASE:ENFORCEMENTS.SELECT_DATE'
										)}
										value={
											values?.fromDate
										}
										disabled={
											values
												.salaryDeductionSchema
												.length >
											0
										}
										tabIndex={
											1
										}
										autoFocus={
											true
										}
										data-testid='datepicker-from-date'
									/>
								</$Popconfirm>
							</$Col>
							<$Col span={6}>
								<$InputAmount
									label={t(
										'US.COLLECTION.CASE:ENFORCEMENTS.FACE_VALUE'
									)}
									required
									min={0}
									placeholder='0,00'
									className='w-100'
									size='small'
									value={
										values.faceValue >
										0
											? values.faceValue
											: 0
									}
									name='faceValue'
									currentLanguage={
										currentLanguage
									}
									currentCurrency={
										currentCurrency
									}
									disabled={
										values
											.salaryDeductionSchema
											.length >
										0
									}
									tabIndex={
										4
									}
									dataTestid='input-face-value'
								/>
							</$Col>
							<$Col span={6}>
								<$InputAmount
									label={t(
										'US.COLLECTION.CASE:ENFORCEMENTS.DEDUCTION_AMOUNT'
									)}
									required
									min={0}
									placeholder='0,00'
									className='w-100'
									size='small'
									value={
										values.deductionAmount
									}
									name='deductionAmount'
									currentLanguage={
										currentLanguage
									}
									currentCurrency={
										currentCurrency
									}
									disabled={
										values
											.salaryDeductionSchema
											.length >
										0
									}
									tabIndex={
										2
									}
									dataTestid='input-deduction-amount'
								/>
							</$Col>
						</$Row>
						<$Row
							gutter={16}
							className='my-3'>
							<$Col span={6}>
								<$Select
									name='deductionSchedule'
									formitem={{
										label: t(
											'US.COLLECTION.CASE:ENFORCEMENTS.DEDUCT_HOW_OFTEN'
										),
									}}
									value={
										values.deductionSchedule
									}
									size='small'
									options={[
										{
											value: SalaryDeductionSchedules.WEEKLY,
											label: t(
												'US.COLLECTION.CASE:ENFORCEMENTS.WEEKLY'
											),
										},
										{
											value: SalaryDeductionSchedules.BIWEEKLY,
											label: t(
												'US.COLLECTION.CASE:ENFORCEMENTS.EVERY_TWO_WEEK'
											),
										},
										{
											value: SalaryDeductionSchedules.MONTHLY,
											label: t(
												'US.COLLECTION.CASE:ENFORCEMENTS.MONTHLY'
											),
										},
									]}
									optionText='label'
									optionValue='value'
									allOption={
										false
									}
									tabIndex={
										3
									}
									required
									disabled={
										values
											.salaryDeductionSchema
											.length >
										0
									}
									onSearchBy={[
										'label',
										'value',
									]}
									dataTestid='select-deduction-schedule'
								/>
							</$Col>
							<$Col span={6}>
								<div className='d-flex align-items-center mt-4'>
									<div className='mr-3'>
										<$Switch
											name='isGiroTemplate'
											tabIndex={
												5
											}
											checked={
												values.isGiroTemplate
											}
										/>
									</div>
									<div className='flex-fill'>
										{t(
											'US.COLLECTION.CASE:ENFORCEMENTS.GIRO_TEMPLATE'
										)}
									</div>
								</div>
							</$Col>
						</$Row>
						<$Divider
							orientation='left'
							className='my-4'>
							<$Button
								type='default'
								size='small'
								className='mr-2'
								disabled={
									values.deductionAmount <=
										0 ||
									values.deductionAmount >
										values.faceValue ||
									values
										.salaryDeductionSchema
										.length >
										0
								}
								onClick={() => {
									updateEmployerAsset(
										values
									);
									addSalaryDeductionPlan(
										{
											...values,
											caseId,
										}
									);
								}}
								tabIndex={6}
								data-testid='btn-calculate'>
								{t(
									'US.COLLECTION.CASE:ENFORCEMENTS.CALCULATE'
								)}
							</$Button>
							<$Button
								type='default'
								danger
								size='small'
								tabIndex={7}
								onClick={() =>
									resetSalaryDeductionSchema(
										{}
									)
								}
								data-testid='btn-reset-list'
								disabled={
									values
										.salaryDeductionSchema
										.length ==
										0 ||
									(!drawer.isNewEnforcement &&
										selectedSalaryAsset.enforcementItemId !=
											-1)
								}>
								{t(
									'US.COLLECTION.CASE:ENFORCEMENTS.RESET_LIST'
								)}
							</$Button>
						</$Divider>
						<div>
							<$Table
								rowKey='sequenceId'
								columns={column(
									rest,
									values
								)}
								dataSource={
									values.salaryDeductionSchema
								}
								className='mt-3'
								bordered
								loading={
									salaryDeductionPlan.isFetching
								}
								data-testid='table-salary-scheme'
							/>
						</div>
					</$Form>

					<div className='drawer-footer-fixed align-content-center justify-content-end'>
						<div>
							<$Button
								className='ml-3 mr-2'
								type='primary'
								disabled={
									!rest.isValid ||
									values
										.salaryDeductionSchema
										.length ==
										0 ||
									_.sumBy(
										values.salaryDeductionSchema,
										(
											term: any
										) =>
											Number(
												term.termAmount
											)
									) !=
										values.faceValue
								}
								onClick={(
									e: any
								) =>
									handleSubmit(
										e
									)
								}
								data-testid='btn-save'>
								{t(
									'US.COLLECTION.COMMON:COMMON.SAVE'
								)}
							</$Button>
							<$Button
								onClick={() =>
									backToNewEnforcement()
								}>
								{t(
									'US.COLLECTION.COMMON:COMMON.CANCEL'
								)}
							</$Button>
						</div>
					</div>
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, enforcement, domainView } = state;
	const { currentDateFormat, currentLanguage, currentCurrency } = common;
	const { salaryDeductionPlan, drawer } = enforcement;
	const { metaData } = domainView;

	return {
		currentDateFormat,
		currentLanguage,
		currentCurrency,
		salaryDeductionPlan,
		drawer,
		metaData,
	};
};

const mapDispatchToProps = {
	addSalaryDeductionPlan: save,
	setSalaryDeductionPlan: set,
	resetSalaryDeductionSchema: resetDeductionSchema,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SalaryDeductionPlan);
