import { manualFollowUpActions } from 'us.collection.case/actions';
import {
	CaseType,
	FollowUpEntityType,
	EntityFollowUpPanel,
	ManualFollowUp,
	ItemAction,
} from 'us.collection.case/constants';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as API from 'us.collection.case/services';
import { $MessageBox } from 'us.common/components';
import { followUps } from 'us.collection/actions';
import * as Actions from 'us.collection.followup/actions';
import { EntityFollowUpTabs } from 'us.collection/constants';
import _ from 'lodash';

const {
	GET_MANUAL_FOLLOW_UP_REASONS_START,
	SAVE_MANUAL_FOLLOW_UP_START,
	GET_MANUAL_FOLLOW_UPS_START,
	SAVE_MANUAL_FOLLOW_UP_REASONS_START,
	DONE_OR_REMOVE_MANUAL_FOLLOW_UP_START,
	INIT_MANUAL_FOLLOWUP,
	GET_FOLLOW_UP_SUMMARY,
} = ManualFollowUp;

const { entity } = followUps;
const {
	categories,
	users,
	reasons,
	reason,
	manualFollowUp,
	manualFollowUps,
	followUpsSummary,
} = manualFollowUpActions;
const { followUps: entityFollowUps } = Actions;

const manualFollowUpSagas = {
	/**
	 * @description get all manualFollowUps
	 */
	manualFollowUps: {
		get: function* (action: any): any {
			const params = action.payload.data;
			try {
				const data = yield call(
					API.followupService.manualFollowUps.get,
					params
				);
				if (Array.isArray(data)) {
					yield put(
						manualFollowUps.success(data)
					);
				} else {
					yield put(manualFollowUps.fail(data));
				}
			} catch (error) {
				yield put(manualFollowUps.fail(error as any));
			}
		},
	},
	/**
	 * @description get reasons for add manualFollowUp
	 */
	reasons: {
		get: function* (action: any): any {
			const params = action.payload.data;
			try {
				const data = yield call(
					API.followupService.reasons.get,
					params
				);
				if (Array.isArray(data)) {
					yield put(reasons.success(data));
				} else {
					yield put(reasons.fail([]));
				}
			} catch (error) {
				yield put(reasons.fail(error as any));
			}
		},
	},
	/**
	 * @namespace reason save call
	 */
	reason: {
		save: function* (action: any): any {
			const params = action.payload.data;
			const { reasonName } = params;
			try {
				const data = yield call(
					API.followupService.reason.save,
					params
				);
				if (
					data.hasOwnProperty(
						'manualfollowupreasonId'
					) &&
					!data?.isError
				) {
					const { manualfollowupreasonId } = data;
					yield put(
						reason.success({
							reasonId: manualfollowupreasonId,
							reasonName,
						})
					);
				} else {
					yield put(reason.fail(data));
				}
			} catch (error) {
				yield put(reason.fail(error as any));
			}
		},
	},
	/**
	 * @namespace maualFollowup service call
	 */
	manualFollowUp: {
		init: function* (action: any): any {
			try {
				const categorydata = yield call(
					API.followupService.categories.get
				);
				const usersData = yield call(
					API.followupService.users.get
				);
				if (Array.isArray(categorydata)) {
					yield put(
						categories.success(categorydata)
					);
				} else {
					yield put(categories.fail([]));
				}
				if (Array.isArray(usersData)) {
					yield put(users.success(usersData));
				} else {
					yield put(users.fail([]));
				}
			} catch (error) {
				yield put(categories.fail([]));
				yield put(users.fail([]));
			}
		},
		save: function* (action: any): any {
			const params = action.payload.data;
			const { entityId, entityType } = params;
			try {
				const data = yield call(
					API.followupService.manualFollowUp.save,
					_.omit(params, 'entityType')
				);
				if (
					data.hasOwnProperty('followupDataId') &&
					!data?.isError
				) {
					yield put(manualFollowUp.success(data));
					$MessageBox(
						'success',
						`US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.SUCCESSFULLY_ADDED`,
						'',
						''
					);
					if (followUpsSummary.get) {
						yield put(
							followUpsSummary.get({
								caseId: entityId,
							})
						);
					}
					if (manualFollowUps.get) {
						yield put(
							manualFollowUps.get({
								caseId: entityId,
								caseType:
									entityType ===
									FollowUpEntityType.CASE
										? CaseType.CASE
										: CaseType.SUBCASE,
							})
						);
					}
					if (entity.manageDrawer) {
						yield put(
							entity.manageDrawer({
								visible: true,
								selectedTab:
									EntityFollowUpTabs.FOLLOW_UP,
								panel: EntityFollowUpPanel.DUE,
								manualFollowUp:
									{
										isNewManualFollowUp:
											false,
										panel: [
											EntityFollowUpPanel.DUE,
											EntityFollowUpPanel.UPCOMING,
										],
									},
							})
						);
					}
				} else {
					yield put(manualFollowUp.fail(data));
					$MessageBox(
						'error',
						`US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.SAVING_FAILED`,
						'',
						''
					);
				}
			} catch (error) {
				yield put(manualFollowUp.fail(error as any));
				$MessageBox(
					'error',
					`US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.SAVING_FAILED`,
					'',
					''
				);
			}
		},
		doneOrRemove: function* (action: any): any {
			const params = action?.payload?.data;
			const entity = action?.payload?.entity;
			try {
				const data = yield call(
					API.followupService.manualFollowUp
						.doneOrRemove,
					params
				);
				if (
					data.hasOwnProperty('isError') &&
					!data?.isError
				) {
					if (
						manualFollowUp.doneOrRemoveSuccess
					) {
						yield put(
							manualFollowUp.doneOrRemoveSuccess(
								data
							)
						);
						if (followUpsSummary.get) {
							yield put(
								followUpsSummary.get(
									{
										caseId: entity.entityId,
									}
								)
							);
						}
						if (manualFollowUps.get) {
							yield put(
								manualFollowUps.get(
									{
										caseId: entity.entityId,
										caseType:
											entity.entityType ===
											'Case'
												? CaseType.CASE
												: CaseType.SUBCASE,
									}
								)
							);
						}
						if (
							entityFollowUps
								.activeFollowUps
								.get
						) {
							yield put(
								entityFollowUps.activeFollowUps.get(
									entity
								)
							);
						}
					}
					$MessageBox(
						'success',
						`US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.${
							params?.action ===
							ItemAction.DONE.toLowerCase()
								? 'MARKED_AS_DONE'
								: 'SUCCESSFULLY_REMOVED'
						}`,
						'',
						''
					);
				} else {
					if (manualFollowUp.doneOrRemoveFail) {
						yield put(
							manualFollowUp.doneOrRemoveFail(
								data
							)
						);
					}
					$MessageBox(
						'error',
						`US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.${
							params?.action ===
							ItemAction.DONE.toLowerCase()
								? 'MARKING_DONE_FAILED'
								: 'REMOVAL_FAILED'
						}`,
						'',
						''
					);
				}
			} catch (error) {
				if (manualFollowUp.doneOrRemoveFail) {
					yield put(
						manualFollowUp.doneOrRemoveFail(
							error as any
						)
					);
				}
				$MessageBox(
					'error',
					`US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.${
						params?.action ===
						ItemAction.DONE.toLowerCase()
							? 'MARKING_DONE_FAILED'
							: 'REMOVAL_FAILED'
					}`,
					'',
					''
				);
			}
		},
	},
	/**
	 * @description get summary of the all followups
	 */
	followUpsSummary: {
		get: function* (action: any): any {
			const params = action.payload.data;
			try {
				const { data, status } = yield call(
					API.followupService.followUpsSummary
						.get,
					params
				);
				if (status == 200) {
					yield put(
						followUpsSummary.success(data)
					);
				} else {
					yield put(followUpsSummary.fail(data));
				}
			} catch (error) {
				yield put(followUpsSummary.fail(error as any));
			}
		},
	},
};
export default [
	takeLatest(
		INIT_MANUAL_FOLLOWUP,
		manualFollowUpSagas.manualFollowUp.init
	),
	takeLatest(
		GET_MANUAL_FOLLOW_UPS_START,
		manualFollowUpSagas.manualFollowUps.get
	),
	takeLatest(
		GET_MANUAL_FOLLOW_UP_REASONS_START,
		manualFollowUpSagas.reasons.get
	),
	takeLatest(
		SAVE_MANUAL_FOLLOW_UP_REASONS_START,
		manualFollowUpSagas.reason.save
	),
	takeLatest(
		SAVE_MANUAL_FOLLOW_UP_START,
		manualFollowUpSagas.manualFollowUp.save
	),
	takeLatest(
		DONE_OR_REMOVE_MANUAL_FOLLOW_UP_START,
		manualFollowUpSagas.manualFollowUp.doneOrRemove
	),
	takeLatest(
		GET_FOLLOW_UP_SUMMARY,
		manualFollowUpSagas.followUpsSummary.get
	),
];
