import {
  httpCollection,
  versionedHttpCollection,
} from "us.helper/http/collection";
import ServiceConfig from "service.config.js";

export default {
  documents: {
    get: <P extends { entityType: string; entityId: string }>(
      params: P
    ): Promise<{}> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "documentservice",
            "document/documentdetails",
             params,
            ServiceConfig()[`documentservice`]["document"]
          );
          resolve({data, status});
        } catch (error) {
          reject(error);
        }
      });
    },
    upload: <
      D extends Array<any>,
      P extends {
        entityType: string;
        entityId: string;
        noteText: string;
        addToHistory: boolean;
        documentType: string;
        showInCreditorPortal:boolean;
      }
    >(
      fileList: D,
      params: P
    ): Promise<{}> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          let formData = new FormData();
          formData.append("entityId", params.entityId);
          formData.append("entityType", params.entityType);
          formData.append("noteText", params.noteText);
          formData.append("addToHistory", params.addToHistory.toString());
          formData.append("documentType", params.documentType);
          formData.append("showInCreditorPortal", params.showInCreditorPortal.toString());

          fileList.map((file: any, index: number) => {
            formData.append(`file${index}`, file, file.name);
          });

          const { data } = await versionedHttpCollection.uploadFile(
            "documentservice",
            "document",
            formData,
            ServiceConfig()[`documentservice`]["document"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  document: {
    get: <P extends { lookupId: number; system: string; lookupType: string }>(
      params: P
    ): Promise<{}> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.get(
            "documentservice",
            "document",
            params,
            ServiceConfig()[`documentservice`]["document"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    delete: <P extends { documentId: number }>(params: P): Promise<{}> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.patch(
            "documentservice",
            "document",
            { documentId: params?.documentId },
            ServiceConfig()[`documentservice`]["document"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  categories: {
    get: <P extends {}>(params: P): Promise<{}> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.get(
            "documentservice",
            "documents/documentCategories",
            params,
            ServiceConfig()[`documentservice`]["documentCategories"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      })
    }
  },
  rules: {
    get: <P extends { entityId: number; type: string; propertyList: Array<string> }>(
      params: P
    ): Promise<{}> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.get(
            "bmdservice",
            "bmds",
            {...params, propertyList: params.propertyList.toString()},
            ServiceConfig()[`bmdservice`]["bmds"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  }
};
