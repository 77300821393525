import { CourtSentenceInitialState } from "./State/InitialState";
import Constants from "us.collection.case/constants";
import {
  ICourtSentenceInitialState,
  ISentenceModel,
  ISentenceSummery,
} from "us.collection.case/interfaces";
import { getSentenceObject, getSentenceObjectClone } from "./Functions";

const { ACTIONS } = Constants;
const { CourtSentence } = ACTIONS;

const {
  GET_SENTENCE_SUMMERY_START,
  GET_SENTENCE_SUMMERY_SUCCESS,
  GET_SENTENCE_SUMMERY_FAIL,
  DELETE_SENTENCE_START,
  DELETE_SENTENCE_SUCCESS,
  DELETE_SENTENCE_FAIL,
  CHANGE_DRAWER_STATUS,
  GET_SENTENCE_START,
  GET_SENTENCE_SUCCESS,
  GET_SENTENCE_FAIL,
  GET_SENTENCE_CASE_AMOUNTS_START,
  GET_SENTENCE_CASE_AMOUNTS_SUCCESS,
  GET_SENTENCE_CASE_AMOUNTS_FAIL,
  SAVE_SENTENCE_START,
  SAVE_SENTENCE_SUCCESS,
  SAVE_SENTENCE_FAIL,
  UPDATE_SENTENCE_START,
  UPDATE_SENTENCE_SUCCESS,
  UPDATE_SENTENCE_FAIL,
} = CourtSentence;

export default (state: ICourtSentenceInitialState = CourtSentenceInitialState, action: any) => {
  switch (action.type) {
    case GET_SENTENCE_SUMMERY_START:
      return getSentenceObjectClone(state, "sentenceSummery", [], true, false);

    case GET_SENTENCE_SUMMERY_SUCCESS:
      return getSentenceObjectClone<ISentenceModel<ISentenceSummery[]>>(
        state,
        "sentenceSummery",
        action.payload.data ?? [],
        false,
        false
      );

    case GET_SENTENCE_SUMMERY_FAIL:
      return getSentenceObjectClone(state, "sentenceSummery", [], false, true);

    case DELETE_SENTENCE_START:
      return getSentenceObjectClone(state, "sentenceDeleteAtempt", null, true, false);

    case DELETE_SENTENCE_SUCCESS:
      const { allSentences = [], sentenceIdToDelete, deletedAck } = action.payload.data;
      const filteredSentences = (allSentences as ISentenceSummery[]).flatMap((sentence) =>
        sentence.id === sentenceIdToDelete ? [] : sentence
      );
      return Object.assign(
        {},
        state,
        getSentenceObject("sentenceSummery", filteredSentences, false, false),
        getSentenceObject("sentenceDeleteAtempt", deletedAck, false, false)
      );

    case DELETE_SENTENCE_FAIL:
      return getSentenceObjectClone(state, "sentenceDeleteAtempt", null, false, true);

    case CHANGE_DRAWER_STATUS:
      return getSentenceObjectClone(state, "drawerStatus", action.payload.data);

    case GET_SENTENCE_START:
      return getSentenceObjectClone(state, "sentenceDetails", null, true, false);
    case GET_SENTENCE_SUCCESS:
      return getSentenceObjectClone(state, "sentenceDetails", action.payload.data, false, false);
    case GET_SENTENCE_FAIL:
      return getSentenceObjectClone(state, "sentenceDetails", null, false, false);

    case GET_SENTENCE_CASE_AMOUNTS_START:
      return getSentenceObjectClone(state, "caseAmountDetails", null, true, false);
    case GET_SENTENCE_CASE_AMOUNTS_SUCCESS:
      return getSentenceObjectClone(state, "caseAmountDetails", action.payload.data, false, false);
    case GET_SENTENCE_CASE_AMOUNTS_FAIL:
      return getSentenceObjectClone(state, "caseAmountDetails", null, false, false);

    case SAVE_SENTENCE_START:
      return getSentenceObjectClone(state, "sentenceSaveAtempt", null, true, false);
    case SAVE_SENTENCE_SUCCESS:
      return getSentenceObjectClone(state, "sentenceSaveAtempt", action.payload.data, false, false);
    case SAVE_SENTENCE_FAIL:
      return getSentenceObjectClone(state, "sentenceSaveAtempt", null, false, false);
    case UPDATE_SENTENCE_START:
      return getSentenceObjectClone(state, "sentenceEditAtempt", null, true, false);
    case UPDATE_SENTENCE_SUCCESS:
      return getSentenceObjectClone(state, "sentenceEditAtempt", action.payload.data, false, false);
    case UPDATE_SENTENCE_FAIL:
      return getSentenceObjectClone(state, "sentenceEditAtempt", null, false, false);
    default:
      return state;
  }
};
