import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';
import moment from 'moment';
import Common from 'us.common';
import * as Actions from 'us.collection.case/actions';
import { Input } from 'antd';
import { ValidationSchema } from 'us.collection.case/components/Validations';
import {
  IAddOtherParty,
  ISortOrder,
  ICountryValue,
} from 'us.collection.case/components/Interfaces/OtherParty/interfaces';
import Highlighter from 'react-highlight-words';
import {
  PlusOutlined,
  SearchOutlined,
  FilterOutlined,
  ExclamationCircleFilled,
  DeleteOutlined,
  MinusOutlined,
} from 'us.icons';
import { INavigationData, IRootState } from 'us.collection/interfaces';
import { validatePersonNo } from 'us.collection.case/functions';
import { europeanCountry } from 'us.common/constants';
import PostalArea from 'us.common/containers/PostalArea/PostalArea';
import { EntitySearch } from 'us.collection.case/components/OtherParty/Search';
import {
  initialData,
  MainTab,
} from 'us.collection.case/components/OtherParty/Add/Constants';
import _ from 'lodash';
import { OtherPartyTypes } from 'us.collection.case/components/OtherParty/Constants';
import { IOnFilter, IOnSort } from 'us.common/components';
import { useHistory, useLocation } from 'react-router-dom';
import { Option } from 'antd/lib/mentions';

const {
  $Form,
  $Input,
  $Button,
  $DatePicker,
  $Popconfirm,
  $SelectGroup,
  $Row,
  $Col,
  $Divider,
  $Select,
  $Drawer,
  $Popover,
  $Skeleton,
  $Table,
  $Collapse,
  $TableTree,
} = Common.Components;

const { OtherPartyAction } = Actions;
const {
  otherParty,
  roleType,
  roleTypes,
  deleteRoleType,
  postalCode,
  minicipality,
} = OtherPartyAction;

const AddOtherParty: React.FC<IAddOtherParty> = (props) => {
  const { t } = useTranslation();
  const { state: locationState, pathname } = useLocation();
  const {
    currentDateFormat,
    save,
    onClose,
    metaData,
    getRoleType,
    roleType,
    saveRole,
    drawerName,
    deleteRoleTypes,
    postalcode,
    municipility,
    searchPostalcode,
    searchMunicipility,
    resetPostalDetails,
    arDetails,
    isArDetailsFetching,
    isCreditorManagement = false,
    newEntityData,
  } = props;
  const state = locationState as INavigationData;
  const { creditorId } = state ?? {};
  const { entityType, debtorId, caseId } = metaData.data ?? {}; //creditorId

  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [pnType, setPNType] = useState(OtherPartyTypes.PERSON);
  const [popConfirmVisible, SetPopConfirmVisible] = useState(false);
  const [PostalAreaVisible, setPostalAreaVisible] = useState(false);
  const [isExistingPostalCode, setIsExistingPostalCode] = useState(true);
  const [isExistingMunicipalityCode, setIsExistingMunicipalityCode] =
    useState(true);
  const [otherPartyInfo, setOtherPartyInfo] = useState<any>(initialData);
  const [country, setCountry] = useState<ICountryValue>({
    preValue: '',
    newValue: '',
  });
  // Manage Role Type
  const [ManageRoleTypeaVisible, setManageRoleTypeVisible] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
    columnKey: '',
    order: '',
  });
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    setOtherPartyInfo({
      ...otherPartyInfo,
      isCreditorManagement,
      otherpartyRoleType: isCreditorManagement
        ? t('US.COLLECTION.COMMON:COMMON.CONTACT_PERSON')
        : null,
    });
    getRoleType({
      otherPartyType: OtherPartyTypes.PERSON,
      currentTab: getURLTypeAndId().caseType,
    });
  }, []);

  useEffect(() => {
    if (postalcode && postalcode?.isResponse) {
      setTimeout(() => {
        if (resetPostalDetails) {
          resetPostalDetails({});
        }
      }, 50);
      if (postalcode?.data?.length > 0) {
        const { countyCode, countyName, postalPlace } = postalcode?.data[0];
        setOtherPartyInfo({
          ...otherPartyInfo,
          zipName: postalPlace,
          municipalityName: countyName,
          municipalityCode: countyCode,
        });
        setIsExistingPostalCode(true);
        setIsExistingMunicipalityCode(true);
      } else {
        setOtherPartyInfo({
          ...otherPartyInfo,
          zipName: '',
          municipalityName: '',
          municipalityCode: '',
        });
        setIsExistingPostalCode(false);
      }
    }
  }, [postalcode]);

  useEffect(() => {
    if (municipility && municipility.isResponse) {
      setTimeout(() => {
        if (resetPostalDetails) {
          resetPostalDetails({});
        }
      }, 50);
      if (municipility?.data?.length > 0) {
        const { municipalityName, municipalityCode } = municipility?.data[0];
        setOtherPartyInfo({
          ...otherPartyInfo,
          municipalityName: municipalityName,
          municipalityCode: municipalityCode,
        });
        setIsExistingMunicipalityCode(true);
      } else {
        setOtherPartyInfo({
          ...otherPartyInfo,
          municipalityName: '',
        });
        setIsExistingMunicipalityCode(false);
      }
    }
  }, [municipility]);

  useEffect(() => {
    if (roleType.data.length && otherPartyInfo.otherpartyRoleType) {
      const isExist = _.find(roleType.data, function (o) {
        return o.value === otherPartyInfo.otherpartyRoleType;
      });
      !isExist &&
        setOtherPartyInfo({
          ...otherPartyInfo,
          otherpartyRoleType: null,
        });
    }
  }, [roleType]);

  const getURLTypeAndId = () => {
    const { params }: any = matchPath(pathname, {
      path: '/:type/:Id?/:module?',
      exact: false,
      strict: false,
    });
    let id = params['Id'];
    const caseType = params['type'];
    let type;
    let debtorEntRoleId;
    switch (caseType) {
      case 'case':
        if (entityType === 'S') {
          type = MainTab.SUBCASE;
        } else {
          type = MainTab.CASE;
        }
        debtorEntRoleId = debtorId;
        id = caseId;
        break;
      case 'ar':
        type = MainTab.AR;
        debtorEntRoleId = arDetails.debtorId;
        break;
      case 'creditor':
        type = MainTab.CREDITOR;
        id = creditorId;
        break;
      case 'bureau':
      case 'creditor-group':
        type = MainTab.CREDITOR;
        id = newEntityData?.entRoleId;
        break;
      default:
        type = 'S';
        break;
    }
    return {
      caseType,
      type,
      id,
      debtorEntRoleId,
    };
  };

  const onchangePNType = (value: any, restProps: any) => {
    setPNType(value);
    restProps.setFieldValue('otherpartyRoleType', '');
    getRoleType({
      otherPartyType: value,
      currentTab: getURLTypeAndId().caseType,
    });
  };

  const handleBirthday = (values: any, rest: any) => {
    const personNo = values.target.value;
    const data = validatePersonNo(personNo.replaceAll(/\D/g, ''));
    if (data.isValid) {
      formatPersonNo(personNo, rest);
      rest.setFieldValue('birthDate', moment(data.birthDate));
    }
  };
  const handleSubmit = (data: any, actions: any) => {
    actions.setSubmitting(true);
    const role: { label: string; value: number }[] = roleType.data?.filter(
      (item: any) => item.value === data?.otherpartyRoleType
    );
    const bDate =
      data.birthDate.length === 0
        ? data.birthDate
        : moment(data.birthDate).format('YYYY-MM-DD');
    if (data?.otherpartyType === OtherPartyTypes.COMPANY) {
      data.organizationNo = data?.nin.trim().replace(/ /g, '');
    } else {
      data.personNo = data?.nin.trim().replace(/ /g, '');
    }
    const { id, type, debtorEntRoleId } = getURLTypeAndId();
    save({
      action: 'add',
      entityId: id,
      ...data,
      birthDate: bDate,
      countryId: data?.country,
      otherpartyRoleType: isCreditorManagement
        ? t('US.COLLECTION.COMMON:COMMON.CONTACT_PERSON')
        : role[0]?.label,
      otherpartyBelongstype: type,
      isAddressKnown: data?.zipCode?.length > 0 ? true : false,
      debtorEntRoleId,
    });
  };

  const openSearchDrawer = () => {
    setDrawerVisible(true);
  };
  const onCloseSearchDrawer = () => {
    setDrawerVisible(false);
  };

  const handleAddRoleType = (values: any, rest: any) => {
    if (values?.newOtherPartyRoleType.trim().length > 0) {
      saveRole({
        otherPartyRoleType: values?.newOtherPartyRoleType,
        otherPartyType: values?.otherpartyType,
      });
      rest.setFieldValue('newOtherPartyRoleType', '');
      // setAddRoleType("");
    }
  };

  const onChangeCountry = (data: any, rest: any, values: any) => {
    setCountry({
      preValue: country?.newValue,
      newValue: data,
    });
    rest.setFieldValue('country', data);
    if (values?.zipCode.length > 0) {
      SetPopConfirmVisible(true);
    }
  };

  const handlePostalDoubleClick = (
    selectedPostalAreaDetail: any,
    rest: any,
    values: any
  ) => {
    setPostalAreaVisible(false);
    rest.setFieldValue(
      'municipalityCode',
      selectedPostalAreaDetail?.MunicipalityCode
    );
    rest.setFieldValue(
      'municipalityName',
      selectedPostalAreaDetail?.MunicipalityName
    );
    rest.setFieldValue('zipCode', selectedPostalAreaDetail?.PostalCode);
    rest.setFieldValue('zipName', selectedPostalAreaDetail?.City);
  };

  const searchZipDetail = (zipcode: string, values: any, rest: any) => {
    if (zipcode?.length > 0) {
      setOtherPartyInfo(values);
      if (searchPostalcode) {
        searchPostalcode({
          countryCode: values?.country?.length === 0 ? 'NO' : values?.country,
          searchText: zipcode,
        });
      }
    } else {
      rest.setFieldValue('zipName', '');
      rest.setFieldValue('municipalityName', '');
      rest.setFieldValue('municipalityCode', '');
    }
  };
  const searchMunicipilityDetail = (
    countyCode: string,
    values: any,
    rest: any
  ) => {
    if (countyCode?.length > 0) {
      setOtherPartyInfo(values);
      if (searchMunicipility) {
        searchMunicipility({
          countryCode: values?.country?.length === 0 ? 'NO' : values?.country,
          searchText: countyCode,
        });
      }
    } else {
      rest.setFieldValue('municipalityName', '');
    }
  };

  const getColumnSearchProps = (dataIndex: string, title: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <$Input
          name="columnSearch"
          placeholder={`${t('US.COLLECTION.COMMON:COMMON.SEARCH')} ${title}`}
          value={selectedKeys[0]}
          onChange={(e: any) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{
            width: 188,
            marginBottom: 8,
            display: 'block',
          }}
        />

        <$Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {t('US.COLLECTION.COMMON:COMMON.SEARCH')}
        </$Button>
        <$Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          {t('US.COLLECTION.COMMON:COMMON.RESET')}
        </$Button>
      </div>
    ),
    filterIcon: (filtered: string) => (
      <FilterOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value: string, record: any) => {
      return record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    render: (text: string) => (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ?? ''}
      />
    ),
  });
  const handleSearch = (selectedKeys: any, confirm: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };
  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
  };
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter);
  };

  const handlePersonNo = (data: any, rest: any, values: any) => {
    if (moment(data).isValid()) {
      rest.setFieldValue('birthDate', moment(data));
    } else {
      rest.setFieldValue('birthDate', '');
    }
  };

  const countryPopOverConfirmYes = (rest: any, values: any) => {
    SetPopConfirmVisible(false);
    rest.setFieldValue('municipalityCode', '');
    rest.setFieldValue('municipalityName', '');
    rest.setFieldValue('zipCode', '');
    rest.setFieldValue('zipName', '');
  };

  const countryPopOverConfirmNo = (rest: any) => {
    rest.setFieldValue('country', country?.preValue);
    SetPopConfirmVisible(false);
  };

  const changeView = () => {
    setPostalAreaVisible(true);
  };
  const postalAreaClose = () => {
    setPostalAreaVisible(false);
  };
  const formatOrganizationNo = (data: any, rest: any) => {
    const value = data.target.value;
    if (value?.length === 9) {
      const parts = value.replace(/\s/g, '').match(/.{1,3}/g);
      rest.setFieldValue('nin', parts.join(' '));
    }
  };

  const formatPersonNo = (value: any, rest: any) => {
    if (value?.length === 6 || (value?.length >= 9 && value?.length <= 11)) {
      const parts = value.replace(/\s/g, '').match(/.{1,6}/g);
      rest.setFieldValue('nin', parts.join(' '));
    }
  };

  const manageRoleType = (values: any) => {
    setManageRoleTypeVisible(true);
    setOtherPartyInfo(values);
  };
  const closeRoleType = () => {
    setManageRoleTypeVisible(false);
  };

  const setSearchData = (data: any) => {
    const {
      entRoleId,
      emailAddress,
      birthDate,
      firstName,
      lastName,
      address1,
      address2,
      address3,
      contacts,
      reference,
      zipCode,
      zipName,
      municipalityCode,
      municipalityName,
      countryCode,
      nIN,
    } = data;
    onCloseSearchDrawer();
    const mapData = {
      entRoleId,
      otherpartyType: OtherPartyTypes.PERSON,
      emailAddress,
      personNo: '',
      organizationNo: '',
      otherpartyRoleType: null,
      birthDate: moment(birthDate),
      firstName,
      lastName,
      address1,
      address2,
      address3,
      telephone: contacts?.hasOwnProperty('Mobile')
        ? contacts?.Mobile?.trim()
        : contacts?.hasOwnProperty('sms')
        ? contacts?.sms?.trim()
        : '',
      reference,
      gender: '',
      zipCode,
      zipName,
      municipalityCode,
      municipalityName,
      country: countryCode,
      countryId: '',
      smsNo: '',
      isDefault: true,
      isAddressKnown: false,
      nin: nIN,
    };
    setOtherPartyInfo(mapData);
  };
  const deleteRoleType = (data: any) => {
    deleteRoleTypes({ roleTypeId: data?.value, type: pnType });
  };
  const roleTypeColumns: any = [
    {
      title: '',
      key: 'more',
      dataIndex: 'more',
      width: '50px',
      customRenderChild: (text: any, record: any, index: number) => {
        return (
          <$Popconfirm
            title={t('US.COLLECTION.CASE:OTHERPARTY.DELETECONFIRM')}
            onConfirm={() => {
              deleteRoleType(record);
            }}
            okText={t('US.COLLECTION.COMMON:COMMON.YES')}
            cancelText={t('US.COLLECTION.COMMON:COMMON.NO')}
          >
            <$Button icon={<DeleteOutlined />} size="small" danger />
          </$Popconfirm>
        );
      },
    },
    {
      title: t('US.COLLECTION.CASE:OTHERPARTY.ROLE_NAME'),
      dataIndex: 'label',
      key: 'label',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
  ];

  const handleSort: IOnSort = (sortData, dataSource) => {
    return sortData(dataSource);
  };

  const handleFilter: IOnFilter = (searchData, dataSource) => {
    return searchData(dataSource);
  };

  const checkRoleTypeExistence = (
    roleTypes: Array<{ label: string; value: number }>,
    newRole: string
  ) => {
    try {
      return roleTypes.some(
        ({ label }) => label.toLowerCase() == newRole.toLowerCase()
      );
    } catch (error) {
      return true;
    }
  };

  return (
    <Formik
      initialValues={otherPartyInfo}
      enableReinitialize
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      validateOnBlur
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <$Skeleton loading={isArDetailsFetching} active paragraph={{ rows: 2 }}>
          <$Form onSubmit={handleSubmit} className="">
            <$Collapse
              bordered={false}
              defaultActiveKey={['1', '2']}
              expandIcon={({ isActive }) =>
                isActive ? <MinusOutlined /> : <PlusOutlined />
              }
              className=""
            >
              {/* Basic information */}
              <$Collapse.Panel
                header={t('US.COLLECTION.CASE:OTHERPARTY.BASIC_INFORMATIONS')}
                key="1"
                data-testid="otherparty-new-basicInformation"
              >
                <div className="pl-1">
                  <$Row gutter={16} className="mb-1">
                    <$Col span={8}>
                      <$Select
                        formitem={{
                          label: t('US.COLLECTION.CASE:OTHERPARTY.TYPE'),
                        }}
                        name="otherpartyType"
                        allOption={false}
                        size="small"
                        onSelect={(e: any) => onchangePNType(e, restProps)}
                        required
                        value={values.otherpartyType}
                        style={{
                          width: '100%',
                        }}
                        options={[
                          {
                            label: t('US.COLLECTION.CASE:OTHERPARTY.PERSON'),
                            value: OtherPartyTypes.PERSON,
                          },
                          {
                            label: t('US.COLLECTION.CASE:OTHERPARTY.COMPANY'),
                            value: OtherPartyTypes.COMPANY,
                          },
                        ]}
                        data-testid="otherparty-new-basicInformation"
                        onSearchBy={['label', 'value']}
                      />
                    </$Col>
                  </$Row>

                  <$Row gutter={16} className="mb-1">
                    <$Col span={12}>
                      <a
                        onClick={openSearchDrawer}
                        style={{
                          display: 'block',
                          marginBottom: '2px',
                        }}
                        data-testid="otherparty-new-selectOtherParty"
                      >
                        <strong>
                          {t('US.COLLECTION.CASE:OTHERPARTY.FIRSTNAME')}
                        </strong>
                      </a>
                      <$Input
                        name="firstName"
                        size="small"
                        data-testid="otherparty-new-firstName"
                      />
                    </$Col>
                    <$Col span={12}>
                      <$Input
                        name="lastName"
                        label={t('US.COLLECTION.CASE:OTHERPARTY.LASTNAME')}
                        size="small"
                        required
                        data-testid="otherparty-new-lastName"
                      />
                    </$Col>
                  </$Row>

                  <$Row gutter={16} className="mb-1">
                    <$Col span={8}>
                      <div
                        style={{
                          marginBottom: '6px',
                        }}
                      >
                        <a
                          onClick={() => manageRoleType(values)}
                          style={{
                            display: 'block',
                            marginBottom: '2px',
                          }}
                          data-testid="otherparty-new-roleTypeView"
                        >
                          <span className="required">*</span>
                          <strong>
                            {t('US.COLLECTION.CASE:OTHERPARTY.ROLETYPE')}
                          </strong>
                        </a>
                      </div>
                      <$Select
                        required={!isCreditorManagement}
                        style={{
                          width: '100%',
                        }}
                        placeholder=""
                        className="mr-0"
                        options={roleType.data}
                        onSearchBy={['label', 'value']}
                        allOption={false}
                        name="otherpartyRoleType"
                        dropdownRender={(menu: any) => (
                          <div>
                            {menu}
                            <$Divider
                              style={{
                                margin: '4px 0',
                              }}
                            />
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                padding: 8,
                              }}
                            >
                              <$Input
                                name="newOtherPartyRoleType"
                                style={{
                                  flex: 'auto',
                                }}
                                onChange={(e: any) => {
                                  restProps.setTouched({
                                    ...restProps.touched,
                                    newOtherPartyRoleType: true,
                                  });
                                  restProps.setFieldValue(
                                    'newOtherPartyRoleType',
                                    e.target.value
                                  );
                                  restProps.setFieldValue(
                                    'isRoleTypeExist',
                                    checkRoleTypeExistence(
                                      roleType.data,
                                      e.target.value
                                    )
                                  );
                                }}
                                dataTestid="otherparty-new-addRoleType"
                              />
                              <$Button
                                type="default"
                                size="small"
                                className="mr-2"
                                icon={<PlusOutlined />}
                                onClick={() =>
                                  handleAddRoleType(values, restProps)
                                }
                                disabled={values?.isRoleTypeExist}
                              >
                                {t('US.COLLECTION.CASE:OTHERPARTY.ADD')}
                              </$Button>
                              {/* <a
                                style={{
                                  flex: "none",
                                  padding: "8px",
                                  display: "block",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleAddRoleType(values, restProps)
                                }
                              >
                                <PlusOutlined />
                                {t("US.COLLECTION.CASE:OTHERPARTY.ADD")}
                              </a> */}
                            </div>
                          </div>
                        )}
                        data-testid="otherparty-new-otherpartyRoleType"
                        disabled={isCreditorManagement}
                        value={
                          isCreditorManagement
                            ? t('US.COLLECTION.COMMON:COMMON.CONTACT_PERSON')
                            : values.otherpartyRoleType
                        }
                      />
                    </$Col>
                    <$Col span={pnType === OtherPartyTypes.COMPANY ? 16 : 8}>
                      <$Input
                        name="nin"
                        onBlur={
                          pnType === OtherPartyTypes.PERSON
                            ? (value: any) => handleBirthday(value, restProps)
                            : (value: any) =>
                                formatOrganizationNo(value, restProps)
                        }
                        label={t(
                          'US.COLLECTION.CASE:OTHERPARTY.NATIONAL_IDENTITY_NUMBER'
                        )}
                        size="small"
                        data-testid="otherparty-new-nin"
                      />
                    </$Col>

                    {pnType === OtherPartyTypes.PERSON && (
                      <$Col span={8}>
                        <$DatePicker
                          name="birthDate"
                          allowClear={true}
                          label={t('US.COLLECTION.CASE:OTHERPARTY.BIRTHDATE')}
                          onChange={(e: any) =>
                            handlePersonNo(e, restProps, values)
                          }
                          defaultPickerValue={moment('2000/01/01')}
                          format={currentDateFormat}
                          placeholder={t(
                            'US.COLLECTION.CASE:OTHERPARTY.SELECT_DATE'
                          )}
                          value={
                            moment(values.birthDate).isValid()
                              ? moment(values.birthDate)
                              : null
                          }
                          style={{
                            width: '100%',
                          }}
                          size="small"
                          data-testid="otherparty-new-birthDate"
                          disabledDate={(date: any) =>
                            !date || date > moment().startOf('day')
                          }
                        />
                      </$Col>
                    )}
                  </$Row>

                  <$Row gutter={16} className="mb-1">
                    <$Col span={8}>
                      <$Input
                        name="telephone"
                        label={t('US.COLLECTION.CASE:OTHERPARTY.TELEPHONE')}
                        size="small"
                        data-testid="otherparty-new-telephone"
                      />
                    </$Col>
                    <$Col span={8}>
                      <$Input
                        name="smsNo"
                        label={t('US.COLLECTION.DEBTOR:GENERAL.SMS')}
                        size="small"
                        data-testid="otherparty-new-smsNo"
                      />
                    </$Col>
                    <$Col span={8}>
                      <$Input
                        name="emailAddress"
                        label={t('US.COLLECTION.CASE:OTHERPARTY.EMAIL')}
                        size="small"
                        data-testid="otherparty-new-emailAddress"
                      />
                    </$Col>
                  </$Row>
                  <$Row gutter={16} className="mb-4">
                    <$Col span={8}>
                      <$Input
                        name="reference"
                        label={t('US.COLLECTION.CASE:OTHERPARTY.REFERENCE')}
                        size="small"
                        data-testid="otherparty-new-reference"
                        maxLength={20}
                      />
                    </$Col>
                  </$Row>
                </div>
              </$Collapse.Panel>

              {/* Address information */}
              <$Collapse.Panel
                header={t('US.COLLECTION.CASE:OTHERPARTY.ADDRESS_INFORMATION')}
                key="2"
                data-testid="otherparty-new-addressInformation"
              >
                <div className="pl-1">
                  <$Row gutter={16} className="mb-1">
                    <$Col span={8}>
                      <$Popover
                        placement="rightTop"
                        content={
                          <div
                            style={{
                              maxWidth: '300px',
                            }}
                          >
                            <div className="ant-popover-message">
                              <ExclamationCircleFilled />
                              <div className="ant-popover-message-title">
                                <div
                                  style={{
                                    paddingBottom: '0.3rem',
                                  }}
                                >
                                  {t(
                                    'US.COLLECTION.CASE:OTHERPARTY.POSTAL_CODE_WARNING'
                                  )}
                                </div>
                                <div
                                  style={{
                                    paddingBottom: '0.5rem',
                                  }}
                                >
                                  <strong>
                                    {t(
                                      'US.COLLECTION.CASE:OTHERPARTY.CONTINUE_CONFIRM'
                                    )}
                                  </strong>
                                </div>
                              </div>
                            </div>
                            <div className="ant-popover-buttons">
                              <$Button
                                size="small"
                                onClick={() =>
                                  countryPopOverConfirmNo(restProps)
                                }
                                data-testid="otherparty-new-countryPopOverConfirmNo"
                              >
                                {t('US.COMMON:COMMON.NO')}
                              </$Button>
                              <$Button
                                size="small"
                                type="primary"
                                onClick={() =>
                                  countryPopOverConfirmYes(restProps, values)
                                }
                                data-testid="otherparty-new-countryPopOverConfirmYes"
                              >
                                {t('US.COMMON:COMMON.YES')}
                              </$Button>
                            </div>
                          </div>
                        }
                        trigger="click"
                        visible={popConfirmVisible}
                      ></$Popover>
                      <$SelectGroup
                        className="country-select"
                        required
                        options={_.orderBy(
                          europeanCountry,
                          ['name', 'label'],
                          ['asc', 'asc']
                        )}
                        formitem={{
                          label: t('US.COLLECTION.CASE:OTHERPARTY.COUNTRY'),
                        }}
                        style={{
                          width: '100%',
                        }}
                        value={values.country}
                        name="country"
                        optionFilterProp="children"
                        onChange={(data: any) =>
                          onChangeCountry(data, restProps, values)
                        }
                        filterOption={(input: any, option: any) =>
                          option.children
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        data-testid="otherparty-new-country"
                      />
                    </$Col>
                  </$Row>

                  <$Row gutter={16} className="mb-1">
                    <$Col span={8}>
                      <$Input
                        name="address1"
                        label={t('US.COLLECTION.CASE:OTHERPARTY.POSTADDRESS1')}
                        size="small"
                        data-testid="otherparty-new-address1"
                      />
                    </$Col>
                    <$Col span={8}>
                      <$Input
                        name="address2"
                        label={t('US.COLLECTION.CASE:OTHERPARTY.POSTADDRESS2')}
                        size="small"
                        data-testid="otherparty-new-address2"
                      />
                    </$Col>
                    <$Col span={8}>
                      <$Input
                        name="address3"
                        label={t('US.COLLECTION.CASE:OTHERPARTY.POSTADDRESS3')}
                        size="small"
                        data-testid="otherparty-new-address3"
                      />
                    </$Col>
                  </$Row>

                  <$Row gutter={16} className="mb-1">
                    <$Col span={6}>
                      <a
                        className="d-block"
                        style={{
                          paddingBottom: '0.13rem',
                        }}
                        onClick={() => changeView()}
                        data-testid="otherparty-new-postalcode"
                      >
                        <strong>
                          {t('US.COLLECTION.COMMON:ENTITYSELECTION.POSTALCODE')}
                        </strong>
                      </a>
                      <$Input
                        name="zipCode"
                        size="small"
                        onBlur={(data: any) =>
                          searchZipDetail(
                            data?.target?.value,
                            values,
                            restProps
                          )
                        }
                      />
                    </$Col>
                    <$Col span={6}>
                      <$Skeleton
                        loading={postalcode?.isLoading}
                        active
                        paragraph={{
                          rows: 0,
                        }}
                      >
                        <$Input
                          name="zipName"
                          label={t(
                            'US.COLLECTION.COMMON:ENTITYSELECTION.POSTALAREA'
                          )}
                          size="small"
                          disabled={isExistingPostalCode}
                        />
                      </$Skeleton>
                    </$Col>
                    <$Col span={6}>
                      <$Skeleton
                        loading={postalcode?.isLoading}
                        active
                        paragraph={{
                          rows: 0,
                        }}
                      >
                        <$Input
                          name="municipalityCode"
                          label={t(
                            'US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYCODE'
                          )}
                          size="small"
                          onBlur={(data: any) =>
                            searchMunicipilityDetail(
                              data?.target?.value,
                              values,
                              restProps
                            )
                          }
                          disabled={isExistingPostalCode}
                        />
                      </$Skeleton>
                    </$Col>
                    <$Col span={6}>
                      <$Skeleton
                        loading={
                          postalcode?.isLoading || municipility?.isLoading
                        }
                        active
                        paragraph={{
                          rows: 0,
                        }}
                      >
                        <$Input
                          name="municipalityName"
                          label={t(
                            'US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYNAME'
                          )}
                          size="small"
                          disabled={isExistingMunicipalityCode}
                        />
                      </$Skeleton>
                    </$Col>
                  </$Row>
                </div>
              </$Collapse.Panel>
            </$Collapse>
          </$Form>
          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Button
                className="mr-2"
                disabled={isSubmitting}
                onClick={handleSubmit}
                type="primary"
                data-testid="otherparty-new-save"
              >
                {t('US.COLLECTION.COMMON:COMMON.SAVE')}
              </$Button>
              {restProps.dirty && (
                <$Popconfirm
                  title={t('US.COLLECTION.CASE:OTHERPARTY.CANCEL_ERROR')}
                  placement="topLeft"
                  onConfirm={() => onClose()}
                  okText={t('US.COLLECTION.COMMON:COMMON.YES')}
                  cancelText={t('US.COLLECTION.COMMON:COMMON.NO')}
                >
                  <$Button data-testid="otherparty-new-cancel">
                    {t('US.COLLECTION.COMMON:COMMON.CANCEL')}
                  </$Button>
                </$Popconfirm>
              )}
              {!restProps.dirty && (
                <$Button
                  onClick={() => onClose()}
                  data-testid="otherparty-new-cancel"
                >
                  {t('US.COLLECTION.COMMON:COMMON.CANCEL')}
                </$Button>
              )}
            </div>
          </div>

          {/* Search for other party ---------------------------------------------------------------------------------*/}
          <$Drawer
            width={900}
            visible={drawerVisible}
            onClose={onCloseSearchDrawer}
            title={t('US.COLLECTION.CASE:OTHERPARTY.SEARCH')}
            destroyOnClose
          >
            <EntitySearch setData={setSearchData} />
          </$Drawer>
          <$Drawer
            title={drawerName}
            width={800}
            visible={PostalAreaVisible}
            onClose={postalAreaClose}
            destroyOnClose
          >
            <PostalArea
              postalAreaClose={() => postalAreaClose()}
              drawerContentChangeClick={(e: any) =>
                handlePostalDoubleClick(e?.updatedInfo, restProps, values)
              }
              countryCode={values.country}
              {...props}
            />
          </$Drawer>
          {/* Manage role type ---------------------------------------------------------------------------------*/}
          <$Drawer
            title={t('US.COLLECTION.CASE:OTHERPARTY.MANAGE_ROLE_TYPES')}
            width={500}
            visible={ManageRoleTypeaVisible}
            onClose={closeRoleType}
            destroyOnClose
          >
            <$Skeleton loading={false} active paragraph={{ rows: 2 }}>
              {/* <$Table
                rowKey="value"
                onChange={handleTableChange}
                columns={roleTypeColumns}
                dataSource={roleType.data}
                size="small"
                className="table-striped"
                bordered
                pagination={{ defaultPageSize: 20, hideOnSinglePage: true }}
              /> */}

              <$TableTree
                onChange={handleTableChange}
                rowKey="value"
                data={roleType.data}
                size="small"
                className="mt-3"
                onSort={handleSort}
                onFilter={handleFilter}
                filterOnType={true}
                resetOnSourceChange={true}
                bordered
                pagination={{
                  defaultPageSize: 15,
                }}
                scroll={{
                  x: 400,
                }}
                columns={roleTypeColumns}
              />
            </$Skeleton>
          </$Drawer>
        </$Skeleton>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const {
    common,
    domainView,
    otherParty,
    entityDetails,
    transaction,
    manageCreditors,
  } = state;
  const { currentDateFormat } = common;
  const { metaData } = domainView;
  const { roleType, postalcode, municipility } = otherParty;
  const { drawerName } = entityDetails;
  const { arDetails, isArDetailsFetching } = transaction;
  const { nextCreditorNo, newEntityData } = manageCreditors;

  return {
    currentDateFormat,
    metaData,
    roleType,
    drawerName,
    postalcode,
    municipility,
    arDetails,
    isArDetailsFetching,
    nextCreditorNo,
    newEntityData,
  };
};

const mapDispatchToProps = {
  save: otherParty.save,
  getRoleType: roleTypes.get,
  saveRole: roleType.save,
  deleteRoleTypes: deleteRoleType.delete,
  searchPostalcode: postalCode.search,
  searchMunicipility: minicipality.search,
  resetPostalDetails: postalCode.reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOtherParty);
