import moment from "moment";

/**
 * To check given field has value in the Object 
 * @param data Object
 * @param value Field Name
 * @returns Boolean value
 */
export const checkValue = (data: any, value: any) => {
    return data?.hasOwnProperty(value) && data[value]?.toString().trim().length > 0
}

/**
 * concat address1,address2, and address3
 * @param data Whole object
 * @returns address
 */
export const combineAddress = (data: any) => {
    let address: string = '';
    if (data?.address1 && data?.address1.length > 0) {
        address = `${data.address1},`
    }
    if (data?.address2 && data?.address2.length > 0) {
        address = address.concat(` ${data.address2},`)
    }

    if (data?.address3 && data?.address3.length > 0) {
        address = address.concat(` ${data.address3}`)
    } else {
        address = address.slice(0, -1)
    }
    return address;
}

/**
 * Create Contact Object using phoneNumbers array that is given by entity detail response
 * @param data phoneNumbers array
 * @returns contact Object
 */
export const setContactNOs = (data: any[]) => {
    let nums: any = {}
    data?.map((item: any) => {
        nums[item.type] = item.number
    })
    return nums;
}

/**
 * Create extendFields Object using tags array that is given by entity detail response
 * @param data tags array
 * @returns extendFields Object
 */
export const setTags = (data: any[]) => {
    let tags: any = {}
    data?.map((item: any) => {
        tags[item.tag] = item.value
    })
    return tags;
}

/**
 * concat two values and filter undefined
 * @param value1 param 01
 * @param value2 param 02
 * @returns string
 */
export const combineTwoFields = (value1: any, value2: any) => {
    let result: string = '';
    if (value1 && value1?.toString().length > 0) {
        if (value2 && value2.toString().length > 0) {
            result = `${value1} ${value2}`
        } else {
            result = value1
        }
    } else if (value2) {
        result = value2;
    }
    return result;
}

export const setBMDValues = (data: any[]) => {
    let bmds: any = {}
    data?.map((item: any) => {
        bmds[item.propertyName] = item.propertyValue
    })
    return bmds;
}

export const setLastCase = (data: any) => {

    if (data?.hasOwnProperty('lastCase') && data?.lastCase?.length > 0) {
        let lastCaseNo = data?.lastCase.split('-')[0];
        let lastCaseDate = moment('2021.05.16', 'YYYY.MM.DD').isValid() ? moment(data?.lastCase.split('-')[1].trim(), 'YYYY.MM.DD').format('YYYY-MM-DD') : ''
        return {
            lastCaseNo,
            lastCaseDate
        }
    } else {
        return {}
    }
}