import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RoutineAndActivity } from "us.collection.routines-and-activities/components";
import { ToggleClass } from "us.helper";
import { connect } from "react-redux";

interface ICnt_Sentences extends RouteComponentProps {
  caseNo: number;
}

const Cnt_Sentences: React.FC<ICnt_Sentences> = (props) => {
  ToggleClass("change-body-color-dom");

  const onMinimize = (url: string) => {
    props.history.push(url);
  };

  return (
    <RoutineAndActivity onMinimize={onMinimize} {...props}></RoutineAndActivity>
  );
};

export default withRouter(Cnt_Sentences);
