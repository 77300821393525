import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import {
	PopOverMenuTypes,
	TransactionType,
} from 'us.collection.transactions/components/Transactions/Constants';
import * as Actions from 'us.collection.transactions/actions';
import {
	CloseCircleOutlined,
	BoxIcons,
	IconTypes,
	CopyOutlined,
	DeleteOutlined,
} from 'us.icons';
import { $Skeleton, $DynamicPopOver } from 'us.common/components';
import {
	IFormValues,
	IMetaData,
} from 'us.common/components/DynamicPopOver/Interface';
import { handleEndPointLocals } from 'us.common/functions';
import './PopOverContent.scss';
import { IPopOverContent } from 'us.collection.transactions/components/Transactions/Interfaces';
import { DynamicPopOverPayload } from './Repository';

const { transactions } = Actions;
const {
	getAll,
	getPopOverItems,
	getPopOverValues,
	manageDynamicPopOver,
	saveDynamicPopOver,
} = transactions;

/**
 * @description - Component for transaction table popOver menu content.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2919530635/Court+Fee+Handling+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 10/03/2022
 * @param transaction - List of transactions.
 * @param isPopOverLoading - Wether the menu is loading.
 * @param isPopOverSaving - Wether the dynamic popOver form submitting.
 * @param visibleDynamicPopOver - Currently visible popOver id.
 * @param popOverMenuItems - Array of menu items.
 * @param onClickMenuItem - OnClick action for each menu item.
 * @param editTransactionHandler -open edit transaction drawer.
 * @param handleTransactionMapping -open transaction mapping drawer.
 */
const PopOverContent: React.FC<IPopOverContent & PropsFromRedux> = (props) => {
	const { t } = useTranslation();

	const {
		metaData,
		transaction,
		isPopOverLoading,
		isPopOverSaving,
		isDynamicPopOverLoading,
		visibleDynamicPopOver,
		popOverMenuItems,
		onClickMenuItem,
		editTransactionHandler,
		handleTransactionMapping,
		getPopOverItems,
		getPopOverValues,
		manageDynamicPopOver,
		saveDynamicPopOver,
		getTransactions,
		editInterestHandler,
	} = props;

	const {
		isCancelable,
		type,
		arItemNo,
		transactionGroupId,
		balance,
		isInterestApplicable,
		interestAmount,
	} = transaction ?? {};

	const { entityType, caseId } = metaData.data ?? {};

	useEffect(() => {
		getPopOverItems({ arItemNo });
	}, []);

	/**
	 * @description - Check whether that the transaction has an amount to be apply an interest
	 */
	const hasBalanceToInterest = (): boolean => {
		try {
			return balance - interestAmount > 0;
		} catch (error) {
			return false;
		}
	};

	const renderMenuItems = (menuItem: IMetaData) => {
		const { activityCode, title, iconName } = menuItem ?? {};
		switch (activityCode) {
			case PopOverMenuTypes.EDITINTEREST:
				return (
					<>
						{isInterestApplicable &&
							hasBalanceToInterest() && (
								<div
									className='d-flex flex-row more-item'
									data-testid='edit-interest-popover-content'
									onClick={() => {
										onClickMenuItem(
											-1
										);
										editInterestHandler(
											transaction
										);
									}}>
									<div className='p-1'>
										<BoxIcons
											type={
												IconTypes.BOX_ICON
											}
											name='edit-interest'
										/>
									</div>
									<div className='p-1'>
										{t(
											'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.EDIT_INTEREST'
										)}
									</div>
								</div>
							)}
					</>
				);
			case PopOverMenuTypes.CANCELLATION:
				return (
					<>
						{!isCancelable &&
							type !=
								TransactionType.DB && (
								<div
									className='d-flex flex-row more-item'
									onClick={() => {
										onClickMenuItem(
											-1
										);
										editTransactionHandler(
											transaction
										);
									}}>
									<div className='p-1'>
										<CloseCircleOutlined />
									</div>
									<div className='p-1'>
										{t(
											'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.CANCELLATION'
										)}
									</div>
								</div>
							)}
					</>
				);
			case PopOverMenuTypes.MAPPING:
				return (
					<>
						<div
							className='d-flex flex-row more-item'
							onClick={() => {
								onClickMenuItem(
									-1
								);
								handleTransactionMapping(
									transaction
								);
							}}>
							<div className='p-1'>
								<CopyOutlined />
							</div>
							<div className='p-1'>
								{t(
									'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.MAPPING'
								)}
							</div>
						</div>
					</>
				);
			case PopOverMenuTypes.DELETE:
				return (
					<>
						{(isCancelable ||
							type ==
								TransactionType.DB) && (
							<div
								className='d-flex flex-row more-item text-error'
								onClick={() => {
									onClickMenuItem(
										-1
									);
									editTransactionHandler(
										transaction
									);
								}}>
								<div className='p-1'>
									<DeleteOutlined />
								</div>
								<div className='p-1'>
									{t(
										'US.COMMON:COMMON.DELETE'
									)}
								</div>
							</div>
						)}
					</>
				);
			default:
				return (
					<>
						{transactionGroupId === 5 &&
							balance >= 0 && (
								<$DynamicPopOver
									visible={
										arItemNo ==
										visibleDynamicPopOver
									}
									onVisibleChange={(
										visible: boolean
									) => {
										if (
											visible
										) {
											manageDynamicPopOver(
												arItemNo
											);
											getPopOverValues &&
												getPopOverValues(
													{
														activityCode,
														transactionId:
															arItemNo,
													}
												);
										} else {
											manageDynamicPopOver(
												-1
											);
										}
									}}
									metaData={
										menuItem
									}
									isSaving={
										isPopOverSaving
									}
									loading={
										isDynamicPopOverLoading
									}
									onClose={() =>
										manageDynamicPopOver(
											-1
										)
									}
									onConfirm={(
										values: IFormValues
									) => {
										const payload =
											DynamicPopOverPayload.call(
												{
													...values,
													arItemNo,
												}
											);
										saveDynamicPopOver &&
											saveDynamicPopOver(
												payload
											);
									}}
									onComplete={() =>
										getTransactions &&
										getTransactions(
											entityType,
											caseId
										)
									}>
									<div className='d-flex flex-row more-item'>
										<div className='p-1'>
											<BoxIcons
												type={
													IconTypes.BOX_ICON
												}
												name={
													iconName
														? iconName
														: 'invoice'
												}
											/>
										</div>
										<div className='p-1'>
											{handleEndPointLocals(
												`US.COLLECTION.TRANSACTIONS:TRANSACTIONS.${title}`
											)}
										</div>
									</div>
								</$DynamicPopOver>
							)}
					</>
				);
		}
	};

	return (
		<>
			<div
				className='table-more-content table-more-content-transactions'
				style={{ minWidth: 140 }}>
				<$Skeleton
					loading={isPopOverLoading}
					active
					paragraph={{ rows: 2 }}>
					{popOverMenuItems.map(
						(
							menuItem: IMetaData,
							index: number
						) => (
							<div key={index}>
								{renderMenuItems(
									menuItem
								)}
							</div>
						)
					)}
				</$Skeleton>
			</div>
		</>
	);
};

const mapStateToProps = (state: any) => {
	const { popOverMenu } = state.transaction;
	const { metaData } = state.domainView;
	const {
		items,
		isLoading,
		isValuesLoading,
		isPopOverSaving,
		visibleDynamicPopOver,
	} = popOverMenu;
	return {
		popOverMenuItems: items,
		isPopOverLoading: isLoading,
		isDynamicPopOverLoading: isValuesLoading,
		metaData,
		isPopOverSaving,
		visibleDynamicPopOver,
	};
};

const mapDispatchToProps = {
	getTransactions: getAll,
	getPopOverItems,
	getPopOverValues,
	manageDynamicPopOver,
	saveDynamicPopOver,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PopOverContent);
