
const initialState = {
    debtorInfo: [],
    contactCategories: [],
    newContactCategorySuccessMsg: '',
    debtorAddressInfo: [],
    debtorEntHistory: [],
    error: null,
}


export default (state: any = initialState, action: any) => {
    let newState = state;

    switch (action.type) {

        case 'GET_DEBTOR_INFO_SUCCESS':
            newState = {
                ...state,
                debtorInfo: action.payload,
                error: null
            }

            break;

        case 'GET_DEBTOR_CONTACT_CATEGORY_SUCCESS':
            newState = {
                ...state,
                contactCategories: action.payload,
                error: null
            }
            break;

        case 'ADD_DEBTOR_CONTACT_CATEGROY_SUCCESS':
            newState = {
                ...state,
                contactCategories:[],
                newContactCategorySuccessMsg: action.payload,
                error: null
            }
            break;

        case 'GET_DEBTOR_ADDRESS_INFO_SUCCESS':
            newState = {
                ...state,
                debtorAddressInfo: action.payload,
                error: null
            }
            break;

        case 'GET_DEBTOR_ENT_HISTORY_SUCCESS':
            newState = {
                ...state,
                debtorEntHistory: action.payload,
                error: null
            }
            break;

        default:
            break;
    }
    return newState;

}