import React, { useEffect, useState, memo } from 'react';
import {
	BoxIcons,
	IconTypes,
	DeleteOutlined,
	FolderOpenFilled,
	PlusOutlined,
	ReloadOutlined,
} from 'us.icons';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router';
import Common from 'us.common';
import { Formik, FieldArray } from 'formik';
import './CaseDocument.scss';
import moment from 'moment';
import { connect } from 'react-redux';
import { caseDocuments } from 'us.collection.documents/actions';
import { INavigationData, IMetaData } from 'us.collection/interfaces';
import { ICaseDocument } from 'us.collection.documents/interfaces';
import {
	filterCaseDocuments,
	getBoxIconByFileFormat,
	getLookUpTypeByCaseType,
} from 'us.collection.documents/functions';
import { IntlDate } from 'us.common/functions';
import {
	CaseDocumentContent,
	UploadCaseDocuments,
} from 'us.collection.documents/components/CaseDocuments';
import _ from 'lodash';
import { IDataModel } from 'us.collection.case/reducers/Dashboard/Interfaces';
import { RouteComponentProps } from 'react-router-dom';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { EntityTypeShortForm } from 'us.common/constants/EntityDetail';
import { RootState } from 'us.helper/types';

const {
	$Button,
	$AutoComplete,
	$Drawer,
	$PageHeader,
	$Divider,
	$Affix,
	$Empty,
	$Tooltip,
	$DatePicker,
	$Skeleton,
	$Popconfirm,
	$Upload,
} = Common.Components;

type filter = {
	uploadedDate: string;
	documentName: string;
};

interface ICaseDocuments extends RouteComponentProps {
	currentDateFormat: any;
	currentLanguage: any;
	getCaseDocuments: any;
	getDocumentContent: any;
	getFileUploadRules: any;
	deleteDocument: any;
	caseDocuments?: any;
	rules?: any;
	manageDocumentPreviewDrawer?: any;
	manageDocumentUploadDrawer?: any;
	previewDrawer: any;
	uploadDrawer: any;
	metaData: IDataModel<IMetaData>;
}

const CaseDocuments: React.FC<ICaseDocuments> = memo((props) => {
	const { t } = useTranslation(
		['US.COLLECTION.DOCUMENTS', 'US.COLLECTION.COMMON'],
		{
			useSuspense: true,
		}
	);

	const {
		currentDateFormat,
		currentLanguage,
		getCaseDocuments,
		getDocumentContent,
		getFileUploadRules,
		deleteDocument,
		caseDocuments,
		rules,
		manageDocumentPreviewDrawer,
		manageDocumentUploadDrawer,
		previewDrawer,
		uploadDrawer,
		metaData,
		history,
		location,
	} = props;

	const state = location.state as INavigationData;
	const { creditorId } = state ?? {};
	const { entityType, caseId, caseNo, pid } = metaData.data ?? {};
	const initialFilter: filter = {
		uploadedDate: '',
		documentName: '',
	};

	const [filters, setFilters] = useState<filter>(initialFilter);
	const [caseDocs, setCaseDocs] = useState<{
		[key: string]: { [key: string]: Array<ICaseDocument> };
	}>({});
	const [selectedDoc, setSelectedDoc] = useState<string>('');
	const [fileList, setFileList] = useState<Array<any>>([]);

	const [uploadRules, setuploadRules] = useState<{
		formats: string;
		maxCount: number;
		maxSize: number;
	}>({ formats: '', maxCount: 10, maxSize: 5 });

	useEffect(() => {
		if (entityType) {
			getCaseDocuments({
				entityType: getLookUpTypeByCaseType(
					getEntityTypeAndCaseId().caseType,
					entityType
				),
				entityId: getEntityTypeAndCaseId().id,
			});
		}
		getFileUploadRules({
			entityId: -1,
			type: 'BU',
			propertyList: [
				'MaxFileUploadSize(MB)',
				'MaxFileUploadCount',
				'AllowedFileTypes',
			],
		});
	}, [entityType]);

	useEffect(() => {
		const filteredDocs = filterCaseDocuments(
			caseDocuments.data,
			filters,
			currentDateFormat
		);
		setCaseDocs(filteredDocs);
		setSelectedDoc(
			filteredDocs[`${selectedDoc}`]
				? selectedDoc
				: _.keys(filteredDocs)[0]
		);
	}, [caseDocuments, filters]);

	useEffect(() => {
		const allowedFileTypes = _.find(rules?.data, {
			propertyName: 'AllowedFileTypes',
		})
			?.propertyValue?.split(',')
			.map((val: string) => `.${val.trim()}`)
			.toString();
		const maxFileUploadCount = Number(
			_.find(rules?.data, {
				propertyName: 'MaxFileUploadCount',
			})?.propertyValue
		);
		const maxFileUploadSize = Number(
			_.find(rules?.data, {
				propertyName: 'MaxFileUploadSize(MB)',
			})?.propertyValue
		);

		setuploadRules({
			formats: allowedFileTypes,
			maxCount: maxFileUploadCount,
			maxSize: maxFileUploadSize,
		});
	}, [rules]);

	const minimize = () => {
		const { caseType, typeId } = getEntityTypeAndCaseId();
		history.push({
			...location,
			pathname: `/${caseType}/${typeId}`,
		});
	};
	const getEntityTypeAndCaseId = () => {
		const { params }: any = matchPath(location.pathname, {
			path: '/:type/:Id?/:module?',
			exact: false,
			strict: false,
		});
		let id = params['Id'];
		let caseType = params['type'];
		let typeId = params['Id'];
		switch (caseType) {
			case 'case':
				id = caseId;
				typeId = caseNo;
				break;
			case 'creditor':
				id = creditorId;
				typeId = pid;
				break;
		}
		return {
			caseType,
			id,
			typeId,
		};
	};
	const previewHandler = (caseDocument: ICaseDocument) => {
		if (entityType) {
			getDocumentContent({
				lookupId: caseDocument.documentId,
				system: 'EntityDocument',
				lookupType: getLookUpTypeByCaseType(
					getEntityTypeAndCaseId().caseType,
					entityType === EntityTypeShortForm.CASE
						? caseDocument.entityType
						: entityType
				),
			});
		}
		manageDocumentPreviewDrawer({
			title: caseDocument.documentName,
			visible: true,
		});
	};

	const deleteHandler = (caseDocument: ICaseDocument) => {
		const { params }: any = matchPath(location.pathname, {
			path: '/:type/:id?/:module?',
			exact: false,
			strict: false,
		});
		const entityType = params['type'];
		const entityId = params['id'];

		deleteDocument({
			documentId: caseDocument.documentId,
			entityType,
			entityId,
		});
	};

	const onPreviewDrawerClose = () => {
		manageDocumentPreviewDrawer({
			title: '',
			visible: false,
		});
	};

	const onUploadDrawerClose = () => {
		manageDocumentUploadDrawer({
			title: '',
			visible: false,
		});
	};

	const uploadProps = {
		onRemove: (file: any) => {
			const index = fileList.indexOf(file);
			setFileList(fileList.splice(index, 1));
		},
		beforeUpload: (file: any, list: any) => {
			const newList = [...list];
			newList.forEach((item: any) => {
				const isLtMax =
					item.size / 1024 / 1024 >
					uploadRules.maxSize;
				if (isLtMax) {
					item.status = 'error';
					item.response = 'File size exceeded.';
				}
			});
			setFileList(newList);
			return false;
		},
		accept: uploadRules.formats,
		fileList,
	};

	const refreshButtonHandler = () => {
		if (entityType) {
			getCaseDocuments({
				entityType: getLookUpTypeByCaseType(
					getEntityTypeAndCaseId().caseType,
					entityType
				),
				entityId: getEntityTypeAndCaseId().id,
			});
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{}}
			onSubmit={(values: any, actions: any) => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				...rest
			}: any) => (
				<>
					<div className='case-documents'>
						<$Affix offsetTop={80}>
							<div className='page-header header-border pr-0'>
								<div className='d-flex flex-row align-items-center justify-content-between'>
									<div className='d-flex align-items-center'>
										<$PageHeader
											className='px-0'
											title={t(
												'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.CASE_DOCUMENTS'
											)}
											onBack={
												minimize
											}
										/>
										<$Divider
											className='bui-devider'
											type='vertical'
										/>
										{caseDocuments?.isFetching ? (
											<$Skeleton.Input
												style={{
													width: 176,
												}}
												active={
													true
												}
												size={
													'small'
												}
											/>
										) : (
											<$Upload
												{...uploadProps}
												showUploadList={
													false
												}
												onChange={(
													e: any
												) => {
													manageDocumentUploadDrawer(
														{
															title: t(
																'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.ADD_NEW_DOCUMENT'
															),
															visible: true,
														}
													);
												}}
												maxCount={
													2
												}
												multiple>
												<$Button
													icon={
														<PlusOutlined />
													}
													disabled={
														metaData.data &&
														isSubCaseTransferredToCase(
															metaData.data
														)
													}>
													{' '}
													{t(
														'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.ADD_NEW_DOCUMENT'
													)}
												</$Button>
											</$Upload>
										)}
										{caseDocuments?.isFetching ? (
											<$Skeleton.Input
												style={{
													width: 320,
													marginLeft: 16,
												}}
												active={
													true
												}
												size={
													'small'
												}
											/>
										) : (
											<$Tooltip
												placement='topLeft'
												title={
													t(
														'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.ONLY_ALLOWED'
													) +
													` ${
														_.find(
															rules?.data,
															{
																propertyName:
																	'AllowedFileTypes',
															}
														)
															?.propertyValue
															? _.find(
																	rules?.data,
																	{
																		propertyName:
																			'AllowedFileTypes',
																	}
															  )
																	?.propertyValue
															: ''
													} ` +
													`(${t(
														'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.MAXIMUM_UPLOAD_SIZE'
													)} ${
														uploadRules.maxSize
															? uploadRules.maxSize
															: ''
													}MB)`
												}>
												<span className='pl-3 only-allowed'>
													{t(
														'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.ONLY_ALLOWED'
													) +
														` ${
															_.find(
																rules?.data,
																{
																	propertyName:
																		'AllowedFileTypes',
																}
															)
																?.propertyValue
																? _.find(
																		rules?.data,
																		{
																			propertyName:
																				'AllowedFileTypes',
																		}
																  )
																		?.propertyValue
																: ''
														} ` +
														`(${t(
															'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.MAXIMUM_UPLOAD_SIZE'
														)} ${
															uploadRules.maxSize
																? uploadRules.maxSize
																: ''
														}MB)`}
												</span>
											</$Tooltip>
										)}
									</div>
									<div className='d-flex align-items-center mr-3 ml-5'>
										<$AutoComplete
											size='small'
											name='documentName'
											style={{
												width: 200,
											}}
											className='mr-2'
											placeholder={t(
												'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.NAME'
											)}
											options={_.flatMapDeep(
												_.values(
													caseDocs
												).map(
													(
														objByCategory: any
													) =>
														_.uniqBy(
															_.flatMapDeep(
																objByCategory
															),
															'documentName'
														).map(
															(
																doc: any
															) => {
																return {
																	label: doc.documentName,
																	value: doc.documentName,
																};
															}
														)
												)
											)}
											onChange={(
												val: any
											) =>
												setFilters(
													{
														...filters,
														documentName:
															val,
													}
												)
											}
										/>
										<$DatePicker
											name='uploadedDate'
											placeholder={t(
												'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.FILTER_BY_DATE'
											)}
											value={
												values?.uploadedDate
											}
											defaultPickerValue={moment()}
											allowClear={
												true
											}
											format={
												currentDateFormat
											}
											style={{
												width: 200,
											}}
											size='small'
											onChange={(
												date: moment.Moment,
												dateString: string
											) => {
												(moment(
													date
												).isValid() ||
													date ==
														null) &&
													rest.setFieldValue(
														'uploadedDate',
														date
													);
												setSelectedDoc(
													date
														? date.format(
																'YYYY-MM-DD'
														  )
														: _.keys(
																caseDocs
														  )[0]
												);
											}}
										/>

										<$Tooltip
											title={t(
												'US.COLLECTION.COMMON:COMMON.REFRESH'
											)}>
											<$Button
												type='dashed'
												size='small'
												onClick={() =>
													refreshButtonHandler()
												}
												icon={
													<ReloadOutlined />
												}
												className='ml-3'
											/>
										</$Tooltip>
									</div>
								</div>
							</div>
						</$Affix>

						<div className=''>
							<div className='select-attchment'>
								<div className='d-flex select-attchment-body'>
									<div className='folders'>
										<p>
											<strong>
												{t(
													'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.DOCUMENT_FOLDER'
												)}
											</strong>
										</p>
										<FieldArray
											name='uploadedDateMenu'
											render={(
												arrayHelpers: any
											) => (
												<>
													{_.keys(
														caseDocs
													).map(
														(
															date: any,
															index: number
														) => {
															return (
																<div
																	key={
																		index
																	}
																	className={`d-flex align-items-start folder-item ${
																		selectedDoc ==
																		date
																			? `folder-item-selected`
																			: ''
																	}`}
																	onClick={() => {
																		setSelectedDoc(
																			date
																		);
																		rest.setFieldValue(
																			'uploadedDate',
																			''
																		);
																	}}>
																	<span>
																		<FolderOpenFilled />
																	</span>
																	<span className='ml-2'>
																		{IntlDate(
																			date,
																			currentLanguage,
																			currentDateFormat
																		)}
																	</span>
																</div>
															);
														}
													)}
												</>
											)}
										/>
									</div>
									<div className='flex-grow-1 documents'>
										<FieldArray
											name='groups'
											render={(
												arrayHelpers: any
											) => (
												<>
													{caseDocs[
														`${selectedDoc}`
													] &&
														Object.entries(
															caseDocs[
																`${selectedDoc}`
															]
														)?.map(
															(
																[
																	documentCategory,
																	docArray,
																],
																index
															) => {
																return (
																	<div
																		key={
																			index
																		}
																		className='mb-3'>
																		<$Divider orientation='left'>
																			{documentCategory
																				? documentCategory
																				: t(
																						'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.OTHER_UPLOADS'
																				  )}
																		</$Divider>
																		<div className='documents-wrap'>
																			{/* Item */}
																			<FieldArray
																				name='selectedDocs'
																				render={(
																					arrayHelpers2: any
																				) => (
																					<>
																						{docArray &&
																							docArray.map(
																								(
																									doc: ICaseDocument,
																									index: number
																								) => {
																									return (
																										<$Skeleton
																											key={
																												index
																											}
																											loading={
																												caseDocuments.isFetching
																											}>
																											<div
																												key={
																													index
																												}
																												className='msg-template-item msg-template-item-selected'
																												onDoubleClick={() =>
																													previewHandler(
																														doc
																													)
																												}>
																												<div className='msg-template-item-content'>
																													<div className='msg-temp-column'>
																														<div className='msg-temp-icon'>
																															<BoxIcons
																																type={
																																	IconTypes.BOX_ICON
																																}
																																name={getBoxIconByFileFormat(
																																	doc.documentType
																																)}
																															/>
																														</div>
																														<div className='msg-temp-name'>
																															<span
																																style={{
																																	wordBreak: 'break-all',
																																}}>
																																{
																																	doc.documentName.split(
																																		'.'
																																	)[0]
																																}
																															</span>
																															<span>
																																.
																																{
																																	doc.documentName.split(
																																		'.'
																																	)[1]
																																}
																															</span>
																														</div>
																													</div>
																													<div className='case-doc-options'>
																														<$Tooltip
																															placement='top'
																															title={t(
																																'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.DELETE'
																															)}>
																															<$Popconfirm
																																title={t(
																																	'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.SURE_TO_DELETE'
																																)}
																																onConfirm={() =>
																																	deleteHandler(
																																		doc
																																	)
																																}
																																okText={t(
																																	'US.COLLECTION.COMMON:COMMON.YES'
																																)}
																																cancelText={t(
																																	'US.COLLECTION.COMMON:COMMON.NO'
																																)}
																																disabled={
																																	metaData.data &&
																																	isSubCaseTransferredToCase(
																																		metaData.data
																																	)
																																}>
																																<$Button
																																	type='link'
																																	size='small'
																																	danger
																																	disabled={
																																		metaData.data &&
																																		isSubCaseTransferredToCase(
																																			metaData.data
																																		)
																																	}>
																																	<DeleteOutlined />
																																</$Button>
																															</$Popconfirm>
																														</$Tooltip>
																													</div>
																												</div>
																											</div>
																										</$Skeleton>
																									);
																								}
																							)}
																					</>
																				)}
																			/>
																		</div>
																	</div>
																);
															}
														)}
													{!caseDocs[
														`${selectedDoc}`
													] && (
														<>
															<div className='d-flex justify-content-center'>
																<div className='d-flex mt-4'>
																	<$Empty
																		image={
																			$Empty.PRESENTED_IMAGE_SIMPLE
																		}
																		description={
																			<span>
																				{t(
																					'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.THEREARENOUPLOADEDDOCUMENTSHERE'
																				)}
																			</span>
																		}>
																		<p>
																			{`${t(
																				'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.YOU_MAY_ONLY_UPLOAD'
																			)} ${
																				_.find(
																					rules?.data,
																					{
																						propertyName:
																							'AllowedFileTypes',
																					}
																				)
																					?.propertyValue
																					? _.find(
																							rules?.data,
																							{
																								propertyName:
																									'AllowedFileTypes',
																							}
																					  )
																							?.propertyValue
																					: ''
																			}. ${t(
																				'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.ENSURE_FILE_FORMATS'
																			)}`}
																		</p>
																	</$Empty>
																</div>
															</div>
														</>
													)}
												</>
											)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<$Drawer
						title={previewDrawer.title}
						width='75%'
						placement='right'
						onClose={onPreviewDrawerClose}
						className='case-doc-viwer'
						visible={previewDrawer.visible}
						destroyOnClose>
						<CaseDocumentContent
							onClose={
								onPreviewDrawerClose
							}
						/>
					</$Drawer>
					<$Drawer
						title={uploadDrawer.title}
						width={600}
						visible={uploadDrawer.visible}
						onClose={onUploadDrawerClose}
						className='upload-case-doc'
						destroyOnClose>
						<UploadCaseDocuments
							onClose={
								onUploadDrawerClose
							}
							fileList={fileList}
							onRemoveFile={
								setFileList
							}
							uploadRules={
								uploadRules
							}
						/>
					</$Drawer>
				</>
			)}
		</Formik>
	);
});

const mapStateToProps = (state: RootState) => {
	const { common, documents, domainView } = state;
	const { currentDateFormat, currentLanguage } = common;
	const { caseDocuments, rules, previewDrawer, uploadDrawer } = documents;
	const { metaData } = domainView;
	return {
		currentDateFormat,
		currentLanguage,
		caseDocuments,
		rules,
		previewDrawer,
		uploadDrawer,
		metaData,
	};
};

const mapDispatchToProps = {
	getCaseDocuments: caseDocuments.documents.get,
	getDocumentContent: caseDocuments.document.get,
	deleteDocument: caseDocuments.document.delete,
	getFileUploadRules: caseDocuments.rules.get,
	manageDocumentPreviewDrawer: caseDocuments.document.managePreviewDrawer,
	manageDocumentUploadDrawer: caseDocuments.document.manageUploadDrawer,
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseDocuments);
