import React, { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { Formik } from 'formik';
import Common from 'us.common';
import { Link, matchPath } from 'react-router-dom';
import { INavigationData } from 'us.collection/interfaces';
import { IOnFilter, IOnSort } from 'us.common/components/antd/TableTree';
import { useHistory } from 'react-router-dom';
import { PlusOutlined } from 'us.icons';
import { ManageCreditorInformation } from 'us.collection.creditor/components/ManageCreditors';
import './Style.scss';
import { RootState } from 'us.helper/types';

const { $PageHeader, $Affix, $Skeleton, $TableTree, $Button, $Drawer } =
	Common.Components;

interface ISelectedCreditorsHome {
	isCreditorManagement?: boolean;
	drawerContentChangeClick?: any;
}

export interface ISortOrder {
	columnKey: string;
	order: any;
}

const SelectedCreditorsHome: React.FC<ISelectedCreditorsHome & PropsFromRedux> =
	memo((props) => {
		const { t } = useTranslation();
		const { push, location } = useHistory();
		const state = location.state as INavigationData;

		const { params }: any = matchPath(window.location.pathname, {
			path: '/:type/:id?/:module?',
			exact: false,
			strict: false,
		});

		const {
			selectedCreditors,
			metaData,
			isCreditorManagement,
			drawerContentChangeClick,
			creditorGroupData,
		} = props;

		const { creditorGroupId } = metaData.data ?? {};

		const initialDrawerInfo = {
			title: '',
			visibility: '',
		};
		const [drawerInfo, setDrawerInfo] =
			useState<any>(initialDrawerInfo);

		const columns: any = [
			{
				title: t(
					'US.COLLECTION.CREDITOR:SELECTEDCREDITOR.CREDITOR_NUMBER'
				),
				dataIndex: 'id',
				key: 'id',
				className: 'text-nowrap',
				customSorter: (a: any, b: any) => a - b,
				customFilter: true,
				customRenderChild: (text: any, record: any) => {
					return (
						<div>
							{!isCreditorManagement && (
								<Link
									to={{
										...location,
										pathname: `/creditor/${record?.pid}`,
										state: {
											...state,
											currentTab: 'creditor',
											creditorId: record?.creditorId,
										},
									}}>
									{text >
									0
										? text
										: ''}
								</Link>
							)}
							{isCreditorManagement &&
							text > 0
								? text
								: ''}
						</div>
					);
				},
			},
			{
				title: t(
					'US.COLLECTION.CREDITOR:SELECTEDCREDITOR.CREDITOR_NAME'
				),
				dataIndex: 'creditorName',
				key: 'creditorName',
				className: 'text-nowrap',
				customSorter: (a: any, b: any) =>
					a.localeCompare(b),
				customFilter: true,
			},
			{
				title: t(
					'US.COLLECTION.CREDITOR:SELECTEDCREDITOR.NIN'
				),
				dataIndex: 'nin',
				key: 'nin',
				className: 'text-nowrap',
				customSorter: (a: any, b: any) => a - b,
				customFilter: true,
			},
			{
				title: t(
					'US.COLLECTION.CREDITOR:SELECTEDCREDITOR.ADDRESS'
				),
				dataIndex: 'address',
				key: 'address',
				className: 'text-nowrap',
				customSorter: (a: any, b: any) =>
					a.localeCompare(b),
				customFilter: true,
			},
		];

		const drawerClose = () => {
			setDrawerInfo(initialDrawerInfo);
		};

		const onMinimize = () => {
			push({
				...location,
				pathname: `/creditor-group/${creditorGroupId}`,
			});
		};

		const handleSort: IOnSort = (sortData, dataSource) => {
			return sortData(dataSource);
		};

		const handleFilter: IOnFilter = (searchData, dataSource) => {
			return searchData(dataSource);
		};

		return (
			<Formik
				enableReinitialize
				initialValues={{}}
				onSubmit={() => {}}>
				{() => (
					<>
						<div className='space-content selected-creditor'>
							{!isCreditorManagement && (
								<$Affix
									offsetTop={
										80
									}>
									<div className='page-header header-border'>
										<div className='d-flex flex-row align-items-center justify-content-between'>
											<div className='d-flex align-items-center'>
												{params[
													'module'
												] ===
													'selected-creditors' && (
													<$PageHeader
														className='px-0'
														title={t(
															'US.COLLECTION.CREDITOR:SELECTEDCREDITOR.SELECTED_CREDITOR'
														)}
														onBack={
															onMinimize
														}
													/>
												)}
												{!params[
													'module'
												] && (
													<$PageHeader
														className='px-0'
														title={t(
															'US.COLLECTION.CREDITOR:SELECTEDCREDITOR.SELECTED_CREDITOR'
														)}
													/>
												)}
												<$Button
													type='default'
													size='small'
													className='mr-2'
													icon={
														<PlusOutlined />
													}
													data-testid='add-new-creditor'
													onClick={() =>
														setDrawerInfo(
															{
																title: t(
																	'US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.NEW_CREDITOR'
																),
																visibility: true,
															}
														)
													}>
													{t(
														'US.COLLECTION.CREDITOR:SELECTEDCREDITOR.NEW_CREDITOR'
													)}
												</$Button>
											</div>
										</div>
									</div>
								</$Affix>
							)}

							<$Skeleton
								loading={
									selectedCreditors?.isFetching
								}
								active
								paragraph={{
									rows: 2,
								}}>
								<$TableTree
									rowKey={(
										record: any
									) =>
										record.creditorId
									}
									data={
										selectedCreditors?.data
									}
									size='small'
									className='mt-3'
									onSort={
										handleSort
									}
									onFilter={
										handleFilter
									}
									filterOnType={
										true
									}
									resetOnSourceChange={
										true
									}
									bordered
									pagination={{
										defaultPageSize: 15,
									}}
									scroll={{
										x: 1200,
									}}
									columns={
										columns
									}
									firstColSkipFilterProps={
										-1
									}
									onRow={(
										record: any
									) => {
										return {
											onDoubleClick:
												() => {
													if (
														isCreditorManagement
													) {
														drawerContentChangeClick(
															{
																record,
															}
														);
													}
												},
										};
									}}
								/>
							</$Skeleton>
						</div>
						<$Drawer
							title={drawerInfo.title}
							width={'80%'}
							visible={
								drawerInfo.visibility
							}
							onClose={drawerClose}
							destroyOnClose>
							<ManageCreditorInformation
								isDrawer={true}
								onClose={
									drawerClose
								}
								creditorGroupData={
									creditorGroupData
								}
								isGroupDisabled={
									true
								}
							/>
						</$Drawer>
					</>
				)}
			</Formik>
		);
	});

const mapStateToProps = (state: RootState) => {
	const { common, creditorGroup, domainView } = state;
	const { currentCurrency, currentLanguage, currentDateFormat } = common;
	const { selectedCreditors, creditorGroupDetails } = creditorGroup;
	const { metaData } = domainView;

	return {
		currentCurrency,
		currentLanguage,
		currentDateFormat,
		selectedCreditors,
		metaData,
		creditorGroupData: creditorGroupDetails?.data,
	};
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SelectedCreditorsHome);
