import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FieldArray } from "formik";
import Common from "us.common";
import Constants from "us.common/constants";
import { IntlDate, IntlCurrency } from "us.common/functions";
import { IBisnode, IBetaDetaljer } from "us.collection.debtor/Interfaces";
import { IRootState } from "us.collection/interfaces";
import { PlusOutlined, MinusOutlined } from "us.icons";
import moment from "moment";
import { IOnFilter, IOnSort } from "us.common/components/antd/TableTree";

const { $Collapse, $Skeleton, $TableTree, $DateLabel, $AmountLabel } = Common.Components;

interface ISortOrder {
  columnKey: string;
  order: any;
}

interface IBisnodeProvider {
  currentDateFormat: string;
  currentCurrency: string;
  currentLanguage: string;
  debtorCreditInfo: IBisnode;
  isFetching: boolean;
}

const BisnodeProvider: React.FC<IBisnodeProvider> = (props) => {
  const { t } = useTranslation(
    ["US.COLLECTION.DEBTOR", "US.COLLECTION.COMMON"],
    { useSuspense: true }
  );
  const {
    debtorCreditInfo,
    isFetching,
    currentDateFormat,
    currentCurrency,
    currentLanguage,
  } = props;

  const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
    columnKey: "",
    order: "",
  });

  // Equation Table
  const equationColumns: any = [
    {
      title: "SkatteAr",
      dataIndex: "SkatteAr",
      key: "SkatteAr",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: "Formue",
      dataIndex: "Formue",
      key: "Formue",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: "EndringFormue",
      dataIndex: "EndringFormue",
      key: "EndringFormue",
      align: "right",
      className: "right-has-sort right-has-filter text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: "amount",
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <$AmountLabel value={Number(record?.EndringFormue)} />,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: "Inntekt",
      dataIndex: "Inntekt",
      key: "Inntekt",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: "EndringInntekt",
      dataIndex: "EndringInntekt",
      key: "EndringInntekt",
      align: "right",
      className: "right-has-sort right-has-filter text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: "amount",
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <$AmountLabel value={Number(record?.EndringInntekt)} />,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: "Skatt",
      dataIndex: "Skatt",
      key: "Skatt",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: "SkatteKlasse",
      dataIndex: "SkatteKlasse",
      key: "SkatteKlasse",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: "Kommunenr",
      dataIndex: "Kommunenr",
      key: "Kommunenr",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: "KommuneNavn",
      dataIndex: "KommuneNavn",
      key: "KommuneNavn",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: "BruttoInntekt",
      dataIndex: "BruttoInntekt",
      key: "BruttoInntekt",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: "Gjeldsgrad1",
      dataIndex: "Gjeldsgrad1",
      key: "Gjeldsgrad1",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: "Gjeldsgrad2",
      dataIndex: "Gjeldsgrad2",
      key: "Gjeldsgrad2",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
  ];

  // Messages Table
  const messagesColumns: any = [
    {
      title: "MeldingsKode",
      dataIndex: "MeldingsKode",
      key: "MeldingsKode",
      width: 200,
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: "MeldingsTekst",
      dataIndex: "MeldingsTekst",
      key: "MeldingsTekst",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
  ];

  // BetaDetaljer Table
  const betaDetaljerColumns: any = [
    {
      title: "RegistrertDato",
      dataIndex: "RegistrertDato",
      key: "RegistrertDato",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: "date",
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <$DateLabel value={record?.RegistrertDato} />,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: "BetaGruppeKode",
      dataIndex: "BetaGruppeKode",
      key: "BetaGruppeKode",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: "BetaGruppeTekst",
      dataIndex: "BetaGruppeTekst",
      key: "BetaGruppeTekst",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: "BetaType",
      dataIndex: "BetaType",
      key: "BetaType",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: "BetaTekst",
      dataIndex: "BetaTekst",
      key: "BetaTekst",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: "BetaBelop",
      dataIndex: "BetaBelop",
      key: "BetaBelop",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: "KildeKode",
      dataIndex: "KildeKode",
      key: "KildeKode",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: "KildeTekst",
      dataIndex: "KildeTekst",
      key: "KildeTekst",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: "KildeReferansenr",
      dataIndex: "KildeReferansenr",
      key: "KildeReferansenr",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: "Kreditor",
      dataIndex: "Kreditor",
      key: "Kreditor",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
  ];

  // Sort table column
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter);
  };

  const handleSort: IOnSort = (sortData, dataSource) => {
    return sortData(dataSource);
  };

  const handleFilter: IOnFilter = (searchData, dataSource) => {
    return searchData(dataSource);
  };

  return (
    <>
      <$Collapse
        bordered={false}
        defaultActiveKey={["1", "2", "3", "4", "5", "6"]}
        expandIcon={({ isActive }) =>
          isActive ? <MinusOutlined /> : <PlusOutlined />
        }
        className="bui-collapse"
      >
        <$Collapse.Panel
          header="Identifikasjon"
          key={
            debtorCreditInfo?.washResult?.Washdata?.Identifikasjon?.Internref ==
            ""
              ? "-1"
              : "1"
          }
          className="bui-collapse-panel"
          collapsible={
            debtorCreditInfo?.washResult?.Washdata?.Identifikasjon?.Internref ==
            ""
              ? "disabled"
              : undefined
          }
        >
          <div className="d-flex flex-wrap px-2">
            {debtorCreditInfo?.washResult?.Washdata?.Identifikasjon
              ?.Internref && (
              <div className="info-item">
                <label>Internref :</label>
                <span>
                  {
                    debtorCreditInfo?.washResult?.Washdata?.Identifikasjon
                      ?.Internref
                  }
                </span>
              </div>
            )}
          </div>
        </$Collapse.Panel>
        <$Collapse.Panel
          header="NavnAdresse"
          key="2"
          className="bui-collapse-panel"
        >
          <div className="d-flex flex-wrap px-2">
            {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse?.Status && (
              <div className="info-item">
                <label>Status :</label>
                <span>
                  {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse?.Status}
                </span>
              </div>
            )}
            {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse
              ?.StatusDato && (
              <div className="info-item">
                <label>StatusDato :</label>
                <span>
                  {IntlDate(
                    moment(
                      debtorCreditInfo?.washResult?.Washdata?.NavnAdresse
                        ?.StatusDato
                    ).format(),
                    currentLanguage,
                    Constants.dateParams.DATE
                  )}
                </span>
              </div>
            )}
            {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse
              ?.Fodselsdato && (
              <div className="info-item">
                <label>Fodselsdato :</label>
                <span>
                  {IntlDate(
                    moment(
                      debtorCreditInfo?.washResult?.Washdata?.NavnAdresse
                        ?.Fodselsdato
                    ).format(),
                    currentLanguage,
                    Constants.dateParams.DATE
                  )}
                </span>
              </div>
            )}
            {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse?.Navn && (
              <div className="info-item">
                <label>Navn :</label>
                <span>
                  {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse?.Navn}
                </span>
              </div>
            )}
            {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse?.Adresse && (
              <div className="info-item">
                <label>Adresse :</label>
                <span>
                  {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse?.Adresse}
                </span>
              </div>
            )}
            {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse?.Postnr && (
              <div className="info-item">
                <label>Postnr :</label>
                <span>
                  {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse?.Postnr}
                </span>
              </div>
            )}
            {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse?.Poststed && (
              <div className="info-item">
                <label>Poststed :</label>
                <span>
                  {
                    debtorCreditInfo?.washResult?.Washdata?.NavnAdresse
                      ?.Poststed
                  }
                </span>
              </div>
            )}
            {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse?.Kommune && (
              <div className="info-item">
                <label>Kommune :</label>
                <span>
                  {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse?.Kommune}
                </span>
              </div>
            )}
            {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse?.Fylke && (
              <div className="info-item">
                <label>Fylke :</label>
                <span>
                  {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse?.Fylke}
                </span>
              </div>
            )}
            {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse?.Alder && (
              <div className="info-item">
                <label>Alder :</label>
                <span>
                  {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse?.Alder}
                </span>
              </div>
            )}
            {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse?.Kjonn && (
              <div className="info-item">
                <label>Kjonn :</label>
                <span>
                  {debtorCreditInfo?.washResult?.Washdata?.NavnAdresse?.Kjonn}
                </span>
              </div>
            )}
          </div>
        </$Collapse.Panel>
        <$Collapse.Panel
          header="BetaDetaljer"
          key={
            typeof debtorCreditInfo?.washResult?.Washdata?.BetaDetaljer ==
              "undefined" ||
            debtorCreditInfo?.washResult?.Washdata?.BetaDetaljer?.length == 0
              ? "-3"
              : "3"
          }
          className="bui-collapse-panel"
          collapsible={
            typeof debtorCreditInfo?.washResult?.Washdata?.BetaDetaljer ==
              "undefined" ||
            debtorCreditInfo?.washResult?.Washdata?.BetaDetaljer?.length == 0
              ? "disabled"
              : undefined
          }
        >
          <div>
            <$Skeleton loading={isFetching} active paragraph={{ rows: 2 }}>
              <$TableTree
                rowKey="RegistrertDato"
                data={
                  Array.isArray(
                    debtorCreditInfo?.washResult?.Washdata?.BetaDetaljer
                  )
                    ? debtorCreditInfo?.washResult?.Washdata?.BetaDetaljer
                    : [debtorCreditInfo?.washResult?.Washdata?.BetaDetaljer]
                }
                columns={betaDetaljerColumns}
                size="small"
                className=""
                onSort={handleSort}
                onFilter={handleFilter}
                filterOnType={true}
                resetOnSourceChange={true}
                bordered
                pagination={false}
                scroll={{ x: 1200 }}
                firstColSkipFilterProps={-1}
              />
            </$Skeleton>
          </div>
        </$Collapse.Panel>
        <$Collapse.Panel
          header="TidligereNavnAdresse"
          key={
            typeof debtorCreditInfo?.washResult?.Washdata
              ?.TidligereNavnAdresse == "undefined" ||
            Object.keys(
              debtorCreditInfo?.washResult?.Washdata?.TidligereNavnAdresse
            ).length === 0
              ? "-4"
              : "4"
          }
          className="bui-collapse-panel"
          collapsible={
            typeof debtorCreditInfo?.washResult?.Washdata
              ?.TidligereNavnAdresse == "undefined" ||
            Object.keys(
              debtorCreditInfo?.washResult?.Washdata?.TidligereNavnAdresse
            ).length === 0
              ? "disabled"
              : undefined
          }
        >
          <div className="d-flex flex-wrap px-2">
            {debtorCreditInfo?.washResult?.Washdata?.TidligereNavnAdresse
              ?.TidligereNavnAdresse && (
              <div className="info-item">
                <label>TidligereNavnAdresse :</label>
                <span>
                  {
                    debtorCreditInfo?.washResult?.Washdata?.TidligereNavnAdresse
                      ?.TidligereNavnAdresse
                  }
                </span>
              </div>
            )}
            {debtorCreditInfo?.washResult?.Washdata?.TidligereNavnAdresse
              ?.EndringsDato && (
              <div className="info-item">
                <label>EndringsDato :</label>
                <span>
                  {IntlDate(
                    moment(
                      debtorCreditInfo?.washResult?.Washdata
                        ?.TidligereNavnAdresse?.EndringsDato
                    ).format(),
                    currentLanguage,
                    Constants.dateParams.DATE
                  )}
                </span>
              </div>
            )}
            {debtorCreditInfo?.washResult?.Washdata?.TidligereNavnAdresse
              ?.EndringsType && (
              <div className="info-item">
                <label>EndringsType :</label>
                <span>
                  {
                    debtorCreditInfo?.washResult?.Washdata?.TidligereNavnAdresse
                      ?.EndringsType
                  }
                </span>
              </div>
            )}
            {debtorCreditInfo?.washResult?.Washdata?.TidligereNavnAdresse
              ?.TidligerePostAdresse && (
              <div className="info-item">
                <label>TidligerePostAdresse :</label>
                <span>
                  {
                    debtorCreditInfo?.washResult?.Washdata?.TidligereNavnAdresse
                      ?.TidligerePostAdresse
                  }
                </span>
              </div>
            )}
          </div>
        </$Collapse.Panel>
        <$Collapse.Panel
          header="Ligning"
          key={
            typeof debtorCreditInfo?.washResult?.Washdata?.Ligning ==
              "undefined" ||
            debtorCreditInfo?.washResult?.Washdata?.Ligning?.length == 0
              ? "-5"
              : "5"
          }
          className="bui-collapse-panel"
          collapsible={
            typeof debtorCreditInfo?.washResult?.Washdata?.Ligning ==
              "undefined" ||
            debtorCreditInfo?.washResult?.Washdata?.Ligning?.length == 0
              ? "disabled"
              : undefined
          }
        >
          <div>
            <$Skeleton loading={isFetching} active paragraph={{ rows: 2 }}>
              <$TableTree
                rowKey="SkatteAr"
                data={debtorCreditInfo?.washResult?.Washdata?.Ligning}
                columns={equationColumns}
                size="small"
                className=""
                onSort={handleSort}
                onFilter={handleFilter}
                filterOnType={true}
                resetOnSourceChange={true}
                bordered
                pagination={false}
                scroll={{ x: 1200 }}
                firstColSkipFilterProps={-1}
              />
            </$Skeleton>
          </div>
        </$Collapse.Panel>
        <$Collapse.Panel
          header="Meldinger"
          key={
            typeof debtorCreditInfo?.washResult?.Washdata?.Meldinger ==
              "undefined" ||
            debtorCreditInfo?.washResult?.Washdata?.Meldinger?.length == 0
              ? "-6"
              : "6"
          }
          className="bui-collapse-panel"
          collapsible={
            typeof debtorCreditInfo?.washResult?.Washdata?.Meldinger ==
              "undefined" ||
            debtorCreditInfo?.washResult?.Washdata?.Meldinger?.length == 0
              ? "disabled"
              : undefined
          }
        >
          <div>
            <$Skeleton loading={isFetching} active paragraph={{ rows: 2 }}>
              <$TableTree
                rowKey="MeldingsKode"
                data={
                  Array.isArray(
                    debtorCreditInfo?.washResult?.Washdata?.Meldinger
                  )
                    ? debtorCreditInfo?.washResult?.Washdata?.Meldinger
                    : [debtorCreditInfo?.washResult?.Washdata?.Meldinger]
                }
                columns={messagesColumns}
                size="small"
                className=""
                onSort={handleSort}
                onFilter={handleFilter}
                filterOnType={true}
                resetOnSourceChange={true}
                bordered
                pagination={false}
                scroll={{ x: 1200 }}
                firstColSkipFilterProps={-1}
              />
            </$Skeleton>
          </div>
        </$Collapse.Panel>
      </$Collapse>
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common } = state;
  return {
    currentDateFormat: common.currentDateFormat,
    currentCurrency: common.currentCurrency,
    currentLanguage: common.currentLanguage,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BisnodeProvider);
