import { Formik } from 'formik';
import React, { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Common from 'us.common';
import { CaseType } from 'us.helper/types/enums';
import { IMapping } from './Interface';
import * as Actions from 'us.collection.transactions/actions';
import { getSum } from 'us.collection.transactions/functions';
import { IRootState } from 'us.collection/interfaces';
import { invalidDate } from 'us.collection.transactions/components/Transactions/Constants';
import './Mapping.scss';
const {
	$Button,
	$Divider,
	$Typography,
	$Row,
	$Col,
	$TableTree,
	$DateLabel,
	$Tooltip,
	$AmountLabel,
} = Common.Components;

const { Text } = $Typography;

export interface ISortOrder {
	columnKey: string;
	order: any;
}

const Mapping: React.FC<IMapping> = memo((props) => {
	const { t } = useTranslation(['US.COLLECTION.TRANSACTIONS']);
	const {
		onClose,
		transactionDetails,
		caseType,
		mappingTransactions,
		getMappingTransactionsByArItemNo,
	} = props;

	useEffect(() => {
		getMappingTransactionsByArItemNo(transactionDetails);
	}, [getMappingTransactionsByArItemNo]);

	const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
		columnKey: '',
		order: '',
	});

	const columns: any = [
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REF_NO'
			),
			dataIndex: 'invoiceNumber',
			key: 'invoiceNumber',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
			customRenderChild: (text: any, record: any) => {
				const { invoiceNumber, kid } = record;
				return (
					<div>
						<div>{invoiceNumber}</div>
						<div className='mt-n1'>
							{kid && (
								<small>
									{t(
										'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.KID'
									)}{' '}
									- {kid}
								</small>
							)}
						</div>
					</div>
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TYPE'
			),
			dataIndex: 'type',
			key: 'type',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.VOUCHERNO'
			),
			dataIndex: 'voucherID',
			key: 'voucherID',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
			customRenderChild: (text: any, record: any) => {
				const { voucherID } = record;
				return (
					<div>
						{voucherID > 0 ? voucherID : ''}
					</div>
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.VOUCHERDATE'
			),
			dataIndex: 'voucherDate',
			key: 'voucherDate',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (text: any, record: any) => {
				const { voucherDate } = record;
				return (
					voucherDate != invalidDate && (
						<$DateLabel
							value={voucherDate}
						/>
					)
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.DUEDATE'
			),
			dataIndex: 'dueDate',
			key: 'dueDate',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (text: any, record: any) => {
				const { dueDate } = record;
				return (
					dueDate != invalidDate && (
						<$DateLabel value={dueDate} />
					)
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REGDATE'
			),
			dataIndex: 'regDate',
			key: 'regDate',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (text: any, record: any) => {
				const { regDate } = record;
				return (
					regDate != invalidDate && (
						<$DateLabel value={regDate} />
					)
				);
			},
		},
		{
			title: () => (
				<>
					<div>
						{t(
							'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.AMOUNT'
						)}
					</div>
					<div className='header-custom-amount'>
						<$AmountLabel
							value={getSum(
								mappingTransactions,
								'amount'
							)}
						/>
					</div>
				</>
			),
			dataIndex: 'amount',
			key: 'amount',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return <$AmountLabel value={record.amount} />;
			},
		},
		{
			title: () => (
				<>
					<div>
						{t(
							'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.BALANCE'
						)}
					</div>
					<div className='header-custom-amount'>
						<$AmountLabel
							value={getSum(
								mappingTransactions,
								'balance'
							)}
						/>
					</div>
				</>
			),
			dataIndex: 'balance',
			key: 'balance',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return <$AmountLabel value={record.balance} />;
			},
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.CREATEDBY'
			),
			dataIndex: 'createdBy',
			key: 'createdBy',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.DESCRIPTION'
			),
			dataIndex: 'description',
			key: 'description',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
	];

	const handleTableChange = (
		pagination: any,
		filters: any,
		sorter: any
	) => {
		setSortedInfo(sorter);
	};
	const handleSubmit = (
		data: any,
		{
			setErrors,
			setStatus,
			resetForm,
			isSubmitting,
			isValidating,
			setSubmitting,
		}: any
	) => {};

	return (
		<Formik
			enableReinitialize
			initialValues={{}}
			onSubmit={handleSubmit}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...rest
			}: any) => (
				<div className='transaction-mapping'>
					<div className='tm-summery'>
						<$Row
							gutter={16}
							className='tm-summery-item'>
							<$Col span={4}>
								<div className='tm-summery-item-inner'>
									<div className='tm-summery-item-value'>
										<div>
											{transactionDetails?.invoiceNumber && (
												<$Tooltip
													placement='topLeft'
													title={
														transactionDetails?.invoiceNumber
													}>
													<span>
														{transactionDetails?.invoiceNumber &&
															transactionDetails?.invoiceNumber}
													</span>
												</$Tooltip>
											)}
											{!transactionDetails?.invoiceNumber && (
												<span>
													---
												</span>
											)}
										</div>
										{caseType ===
											CaseType.S && (
											<Text
												type='secondary'
												className='mb-0 mt-0'>
												<$Tooltip
													placement='topLeft'
													title={
														transactionDetails?.kid
													}>
													<span>
														<small>
															KID
															-{' '}
															{
																transactionDetails?.kid
															}
														</small>
													</span>
												</$Tooltip>
											</Text>
										)}
									</div>
									<div className='tm-summery-item-title'>
										{t(
											'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REF_NO'
										)}
									</div>
								</div>
							</$Col>

							<$Col span={4}>
								<div className='tm-summery-item-inner'>
									<div className='tm-summery-item-value'>
										{transactionDetails?.type && (
											<$Tooltip
												placement='topLeft'
												title={
													transactionDetails?.type
												}>
												<span>
													{
														transactionDetails?.type
													}
												</span>
											</$Tooltip>
										)}
										{!transactionDetails?.type && (
											<span>
												---
											</span>
										)}
									</div>
									<div className='tm-summery-item-title'>
										{t(
											'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TYPE'
										)}
									</div>
								</div>
							</$Col>

							<$Col span={4}>
								<div className='tm-summery-item-inner'>
									<div className='tm-summery-item-value'>
										{transactionDetails?.voucherID >
											0 && (
											<$Tooltip
												placement='topLeft'
												title={
													transactionDetails?.voucherID
												}>
												<span>
													{
														transactionDetails?.voucherID
													}
												</span>
											</$Tooltip>
										)}
										{transactionDetails?.voucherID ===
											0 && (
											<span>
												---
											</span>
										)}
									</div>
									<div className='tm-summery-item-title'>
										{t(
											'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.VOUCHERNO'
										)}
									</div>
								</div>
							</$Col>

							<$Col span={3}>
								<div className='tm-summery-item-inner'>
									<div className='tm-summery-item-value'>
										{transactionDetails?.voucherDate && (
											<$DateLabel
												value={
													transactionDetails?.voucherDate
												}
											/>
										)}
										{!transactionDetails?.voucherDate && (
											<span>
												---
											</span>
										)}
									</div>
									<div className='tm-summery-item-title'>
										{t(
											'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.VOUCHERDATE'
										)}
									</div>
								</div>
							</$Col>

							<$Col span={3}>
								<div className='tm-summery-item-inner'>
									<div className='tm-summery-item-value'>
										{transactionDetails?.dueDate && (
											<$DateLabel
												value={
													transactionDetails?.dueDate
												}
											/>
										)}
										{!transactionDetails?.dueDate && (
											<span>
												---
											</span>
										)}
									</div>
									<div className='tm-summery-item-title'>
										{t(
											'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.DUEDATE'
										)}
									</div>
								</div>
							</$Col>

							<$Col span={3}>
								<div className='tm-summery-item-inner'>
									<div className='tm-summery-item-value'>
										{transactionDetails?.regDate && (
											<$DateLabel
												value={
													transactionDetails?.regDate
												}
											/>
										)}
										{!transactionDetails?.regDate && (
											<span>
												---
											</span>
										)}
									</div>
									<div className='tm-summery-item-title'>
										{t(
											'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REGDATE'
										)}
									</div>
								</div>
							</$Col>

							<$Col span={3}>
								<div className='tm-summery-item-inner'>
									<div className='tm-summery-item-value'>
										{transactionDetails?.paymentID >
											0 && (
											<$Tooltip
												placement='topLeft'
												title={
													transactionDetails?.paymentID
												}>
												<span>
													{
														transactionDetails?.paymentID
													}
												</span>
											</$Tooltip>
										)}
										{transactionDetails?.paymentID <
											0 && (
											<span>
												---
											</span>
										)}
									</div>
									<div className='tm-summery-item-title'>
										{t(
											'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.PAYMENTID'
										)}
									</div>
								</div>
							</$Col>
						</$Row>

						<$Row
							gutter={16}
							className='tm-summery-item mt-3'>
							<$Col span={4}>
								<div className='tm-summery-item-inner'>
									<div className='tm-summery-item-value'>
										{transactionDetails?.paid ||
											(transactionDetails?.amount && (
												<>
													{transactionDetails?.isPayment && (
														<$AmountLabel
															value={
																transactionDetails?.paid
															}
														/>
													)}
													{!transactionDetails?.isPayment && (
														<$AmountLabel
															value={
																transactionDetails?.amount
															}
														/>
													)}
												</>
											))}
										{!transactionDetails?.paid &&
											!transactionDetails?.amount && (
												<span>
													---
												</span>
											)}
									</div>
									<div className='tm-summery-item-title'>
										{t(
											'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.AMOUNT'
										)}
									</div>
								</div>
							</$Col>

							<$Col span={4}>
								<div className='tm-summery-item-inner'>
									<div className='tm-summery-item-value'>
										{transactionDetails?.balance && (
											<$AmountLabel
												value={
													transactionDetails?.balance
												}
											/>
										)}
										{!transactionDetails?.balance && (
											<span>
												---
											</span>
										)}
									</div>
									<div className='tm-summery-item-title'>
										{t(
											'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.BALANCE'
										)}
									</div>
								</div>
							</$Col>

							<$Col span={4}>
								<div className='tm-summery-item-inner'>
									<div className='tm-summery-item-value'>
										{transactionDetails?.createdBy && (
											<$Tooltip
												placement='topLeft'
												title={
													transactionDetails?.createdBy
												}>
												<span>
													{
														transactionDetails?.createdBy
													}
												</span>
											</$Tooltip>
										)}
										{!transactionDetails?.createdBy && (
											<span>
												---
											</span>
										)}
									</div>
									<div className='tm-summery-item-title'>
										{t(
											'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.CREATEDBY'
										)}
									</div>
								</div>
							</$Col>

							<$Col span={12}>
								<div className='tm-summery-item-inner'>
									<div className='tm-summery-item-value'>
										{transactionDetails?.description && (
											<$Tooltip
												placement='topLeft'
												title={
													transactionDetails?.description
												}>
												<span>
													{
														transactionDetails?.description
													}
												</span>
											</$Tooltip>
										)}
										{!transactionDetails?.description && (
											<span>
												---
											</span>
										)}
									</div>
									<div className='tm-summery-item-title'>
										{t(
											'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.DESCRIPTION'
										)}
									</div>
								</div>
							</$Col>
						</$Row>
					</div>

					<$Divider />

					<div className='mt-4 d-flex align-items-center'>
						<h3>
							{t(
								'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.MAPPING_DETAILS'
							)}
						</h3>
					</div>
					<div>
						<$TableTree
							onChange={
								handleTableChange
							}
							rowKey='arItemNo'
							data={
								mappingTransactions
							}
							size='small'
							className='mt-3 header-custom-tag'
							onSort={(
								sortData,
								dataSource
							) =>
								sortData(
									dataSource
								)
							}
							onFilter={(
								searchData,
								dataSource
							) =>
								searchData(
									dataSource
								)
							}
							filterOnType={true}
							resetOnSourceChange={
								true
							}
							bordered
							pagination={{
								defaultPageSize: 15,
							}}
							scroll={{ x: 1200 }}
							columns={columns}
							firstColSkipFilterProps={
								-1
							}
						/>
					</div>
					<div className='drawer-footer-fixed align-content-center'>
						<div className='ml-auto'>
							<$Button
								onClick={() =>
									onClose()
								}>
								{t(
									'US.COLLECTION.COMMON:COMMON.CANCEL'
								)}
							</$Button>
						</div>
					</div>
				</div>
			)}
		</Formik>
	);
});

const mapStateToProps = (state: IRootState) => {
	const { common, transaction } = state;
	const { currentDateFormat, currentCurrency, currentLanguage } = common;
	const { mappingTransactions, transactionDetails, isFetching } =
		transaction;
	return {
		isFetching,
		currentCurrency,
		currentLanguage,
		currentDateFormat,
		transactionDetails,
		mappingTransactions,
	};
};

const mapDispatchToProps = {
	getMappingTransactionsByArItemNo: Actions.transactions.getMapping,
};

export default connect(mapStateToProps, mapDispatchToProps)(Mapping);
