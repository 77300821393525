import _ from 'lodash';
import { MappingCase } from 'us.collection.transactions/components/ArTransactions/Interfaces';
import { MappingItem } from 'us.collection.transactions/interfaces';
import { CaseType } from 'us.helper/types/enums';

/**
 * @description It takes an array of objects and groups them by a property called caseNo
 * @param {MappingCase[]} cases - MappingTransaction[]
 * @returns An array of objects.
 */
export const groupByParentCaseId = (cases: MappingCase[]): any[] => {
	try {
		const updatedCases: any[] = [];
		const groupedCases = Object.entries(
			_.groupBy(cases, 'parentCaseId')
		);

		groupedCases.forEach(([parentCaseId, children], _index) => {
			const childCases: MappingCase[] = [];
			children.map((childCase: MappingCase, _index) => {
				if (childCase.caseId !== Number(parentCaseId)) {
					childCases.push({
						...childCase,
						id: `${childCase.caseId}`,
					});
				}
			});
			const parentCase = cases.find(
				({ caseId }: MappingCase) =>
					caseId === Number(parentCaseId)
			);
			updatedCases.push({
				...parentCase,
				id: parentCaseId,
				isParent: true,
				children: childCases,
			});
		});
		return updatedCases;
	} catch (error) {
		return [];
	}
};

/**
 * @description Calculate the total mapped amount for the newly mapped cases
 * @param {MappingCase[]} mappingCases - MappingCase[]
 * @returns The total amount of the mapped cases.
 */
export const getTotalMappedAmount = (mappingCases: MappingCase[]): number => {
	try {
		// get only newly mapped transactions
		const mappedTransactions = mappingCases.filter(
			({ isMapped }: MappingCase) => isMapped
		);
		// calculate total newly mapped amount
		const total = _.sumBy(
			_.cloneDeep(mappedTransactions),
			'mappedAmount'
		);
		return total;
	} catch (error) {
		return 0;
	}
};

/**
 * It takes a list of transactions and a list of selected row keys, and returns a new list of
 * transactions with updated new balance.
 * @param {MappingCase[]} cases - MappingCase[]
 * @param {React.Key[]} selectedRowKeys - React.Key[]
 * @returns An array of objects.
 */
export const mappingCasesWithNewBalance = (
	cases: MappingCase[],
	selectedRowKeys: React.Key[],
	mappingAmount: number
): MappingCase[] => {
	try {
		// handle positive amount mapping
		return cases.map((mappingCase: MappingCase, _index: number) => {
			const { caseId, isMapped, balance } = mappingCase;
			const isItemSelected = selectedRowKeys.includes(
				`${caseId}`
			);
			if (isMapped || !isItemSelected) {
				return mappingCase;
			} else {
				const newBalance = parseFloat(
					Number(balance + mappingAmount).toFixed(
						2
					)
				);
				return {
					...mappingCase,
					isMapped: isItemSelected,
					mappedAmount: mappingAmount,
					newBalance,
				};
			}
		});
	} catch (error) {
		return cases;
	}
};

/**
 * It takes a string and returns a string.
 * @param {string} caseType - string
 * @returns A string.
 */
export const getCaseTypeString = (caseType: string): string => {
	try {
		if (caseType && caseType.toUpperCase() === CaseType.C) {
			return 'US.COLLECTION.COMMON:COMMON.CASE';
		}
		if (caseType && caseType.toUpperCase() === CaseType.S) {
			return 'US.COLLECTION.COMMON:COMMON.INVOICE';
		}
		return '';
	} catch (error) {
		return '';
	}
};

/**
 * To re map must include a parent transaction and at least one child related to the parent
 * @param {any} values - form values
 * @returns A boolean value.
 */
export const ableToReMap = (values: any): boolean => {
	try {
		const { transactions, selectedRowKeys } = values ?? {};
		if (selectedRowKeys.length > 0 && transactions.length > 0) {
			// has selected item(s)
			const selectedItems = transactions.filter(
				({ caseId, isMapped }: MappingCase) => {
					return (
						selectedRowKeys.includes(
							`${caseId}`
						) && isMapped
					);
				}
			);
			return selectedItems.length > 0;
		} else {
			// no any item selected or no mapping transactions
			return false;
		}
	} catch (error) {
		return false;
	}
};

/**
 * @description Generate mapping items to map AR transactions
 * @param {MappingCase[]} transactions - MappingTransaction[]
 * @returns {MappingItem[]} An array of `MappingItem`.
 */
export const getItemsToMap = (
	transactions: MappingCase[],
	selectedRowKeys: React.Key[]
): MappingItem[] => {
	try {
		const mappings: MappingItem[] = [];
		// get selected transactions
		transactions.map(
			({ caseId, mappedAmount, isMapped }: MappingCase) => {
				if (
					selectedRowKeys.includes(`${caseId}`) &&
					isMapped
				) {
					mappings.push({
						transactionId: undefined,
						mappedAmount: mappedAmount ?? 0,
						comment: '',
						caseId,
					});
				}
			}
		);
		// generate array of objects
		return mappings;
	} catch (error) {
		return [];
	}
};

/**
 * Check whether the case or sub-case row selection enable and can select for remapping
 * @param {MappingCase[]} mappingCases - List of cases
 * @param {MappingCase} mappingCase - Row record
 * @param {number} availableBalanceToMap - Mapping amount
 * @returns A boolean value.
 */
export const isEnableRowSelection = (
	mappingCases: MappingCase[],
	mappingCase: MappingCase,
	availableBalanceToMap: number,
	subCaseMappingOnly: boolean
): boolean => {
	try {
		const { isParent, isMapped } = mappingCase;
		const totalMapped = getTotalMappedAmount(mappingCases);
		if (isParent && subCaseMappingOnly) {
			return false;
		} else if (totalMapped === availableBalanceToMap && !isMapped) {
			return false;
		} else {
			return true;
		}
	} catch (error) {
		return false;
	}
};
