import { otherParty } from 'us.collection.case/constants/Actions';
import { initialState } from './State';
import _ from 'lodash'
import { combineAddress, combineTwoFields, setContactNOs, filterRoleType } from 'us.collection.case/functions'

export default (state = initialState, { payload, type }: any) => {
    switch (type) {
        case otherParty.GET_OTHER_PARTY_DATA_STRAT:
            return Object.assign({}, state, {
                otherParties: {
                    data: state.otherParties.data,
                    isFetching: true,
                },
            });
        case otherParty.GET_OTHER_PARTY_DATA_SUCCESS:
            return Object.assign({}, state, {
                otherParties: {
                    data: _.isEmpty(payload?.data) ? [] : payload?.data?.map((o: any, index: number) => { return { ...o, key: index, activeStatusString: o.activeStatus ? 'ACTIVE' : 'INACTIVE' } }),
                    isFetching: false,
                },
            });
        case otherParty.GET_OTHER_PARTY_DATA_FAIL:
            return Object.assign({}, state, {
                otherParties: {
                    data: [],
                    isFetching: false,
                },
            });
        case otherParty.GET_OTHER_PARTY_BY_ID_STRAT:
            return Object.assign({}, state, {
                otherParty: {
                    data: state.otherParty.data,
                    isFetching: true,
                },
            });
        case otherParty.GET_OTHER_PARTY_BY_ID_RESET:
            return Object.assign({}, state, {
                otherParty: {
                    data: {},
                    isFetching: false,
                },
            });
        case otherParty.GET_OTHER_PARTY_BY_ID_SUCCESS:
            return Object.assign({}, state, {
                otherParty: {
                    data: payload?.data,
                    isFetching: false,
                },
            });
        case otherParty.GET_OTHER_PARTY_DATA_FAIL:
            return Object.assign({}, state, {
                otherParty: {
                    data: {},
                    isFetching: false,
                },
            });
        case otherParty.ADD_OTHER_PARTY_DRAWER:
            return Object.assign({}, state, {
                addDrawer: payload?.data
            });
        case otherParty.GET_OTHER_PARTY_ROLE_TYPE_STRAT:
            return Object.assign({}, state, {
                currentTab: payload?.data.currentTab
            });
        case otherParty.GET_OTHER_PARTY_ROLE_TYPE_SUCCESS:
            return Object.assign({}, state, {
                roleType: {
                    data: filterRoleType(state.currentTab, payload?.data).map((item: any) => ({ label: item.roleName, value: item.roleId }))
                }
            });
        case otherParty.GET_OTHER_PARTY_ROLE_TYPE_FAIL:
            return Object.assign({}, state, {
                roleType: {
                    data: []
                }
            });
        case otherParty.SAVE_OTHER_PARTY_ROLE_TYPE_SUCCESS:
            return Object.assign({}, state, {
                roleType: {
                    data: filterRoleType(state.currentTab, payload?.data).map((item: any) => ({ label: item.roleName, value: item.roleId }))
                }
            });
        case otherParty.SEARCH_OTHER_PARTY_DATA_STRAT:
            return Object.assign({}, state, {
                searchData: {
                    data: [],
                    isFetching: true,
                }
            });
        case otherParty.SEARCH_OTHER_PARTY_DATA_SUCCESS:
            return Object.assign({}, state, {
                searchData: {
                    data: payload.data.map((item: any, index: number) => {
                        return {
                            key: index,
                            ...item,
                            name: combineTwoFields(item?.firstName, item?.lastName),
                            address: combineAddress(item),
                            contacts: setContactNOs(item.phoneNumbers)
                        }
                    }),
                    isFetching: false,
                }
            });
        case otherParty.SEARCH_OTHER_PARTY_DATA_FAIL:
            return Object.assign({}, state, {
                searchData: {
                    data: [],
                    isFetching: false,
                }
            });
        case otherParty.CLEAR_OTHER_PARTY_SEARCH_DATA:
            return Object.assign({}, state, {
                searchData: {
                    data: [],
                    isFetching: false,
                }
            });
        case otherParty.DELETE_OTHER_PARTY_ROLE_TYPE_FAIL:
            return Object.assign({}, state, {
                roleType: {
                    data: []
                }
            });
        case otherParty.SEARCH_POSTALCODE_START:
            return Object.assign({}, state, {
                postalcode: {
                    data: [],
                    isLoading: true,
                    isResponse: false
                },
            });
        case otherParty.SEARCH_POSTALCODE_SUCCESS:
            return Object.assign({}, state, {
                postalcode: {
                    data: payload?.data,
                    isLoading: false,
                    isResponse: true
                },
            });
        case otherParty.SEARCH_POSTALCODE_FAIL:
            return Object.assign({}, state, {
                postalcode: {
                    data: [],
                    isLoading: false,
                    isResponse: true
                },
            });

        case otherParty.SEARCH_MUNICIPALITY_START:
            return Object.assign({}, state, {
                municipility: {
                    data: [],
                    isLoading: true,
                    isResponse: false
                },
            });
        case otherParty.SEARCH_MUNICIPALITY_SUCCESS:
            return Object.assign({}, state, {
                municipility: {
                    data: payload?.data,
                    isLoading: false,
                    isResponse: true
                },
            });
        case otherParty.SEARCH_MUNICIPALITY_FAIL:
            return Object.assign({}, state, {
                municipility: {
                    data: [],
                    isLoading: false,
                    isResponse: true
                },
            });
        case otherParty.CLEAR_POSTAL_DETAILS:
            return Object.assign({}, state, {
                postalcode: {
                    data: [],
                    isLoading: false,
                    isResponse: false
                },
                municipility: {
                    data: [],
                    isLoading: false,
                    isResponse: false
                },
        });
        case otherParty.GET_POSTAL_DETAIL:
            return Object.assign({}, state, {
                otherParty: {
                    data: {
                        ...payload?.data?.values,
                        municipalityCode: "",
                        municipalityName: "",
                        zipName: "" 
                    },
                    isFetching: false,
                },
            });
        case otherParty.GET_POSTAL_DETAIL_SUCCESS:
            const { postalCode, postalPlace, countyCode, countyName } = payload?.data && payload?.data[0]
            return Object.assign({}, state, {
                otherParty: {
                    data: {
                        ...state.otherParty.data,
                        municipalityCode: countyCode ?? "",
                        municipalityName: countyName ?? "",
                        zipName: postalPlace ?? ""
                    },
                    isFetching: false,
                },
        });
        case otherParty.GET_POSTAL_DETAIL_FAIL:
            return Object.assign({}, state, {
                otherParty: {
                    data: {...state.otherParty.data},
                    isFetching: false,
                },
        });
        case otherParty.RESET_POSTAL_DETAIL:
            return Object.assign({}, state, {
                otherParty: {
                    data: {},
                    isFetching: false,
                },
        });
        default:
            return state;
    }
}