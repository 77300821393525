import { IInitialState } from "us.collection.case/reducers/MassageTemplates/Interfaces";

export const messageTemplateInitial = {
  id: 0,
  application: "",
  templateCategory: "",
  templateName: "",
  plugin: "",
  isPin: false,
};

export const initialState: IInitialState = {
  messageTemplates: {
    data: [],
    isLoading: false,
  },
  previewEditDrawerData: {
    title: "",
    visible: false,
    isPreview: false,
    messageTemplate: messageTemplateInitial,
  },
  template: {
    communicationJobId: 0,
    content: [],
    isLoading: false,
  },
  emailDrawerData: {
    title: "",
    visible: false,
  },
  generateMessageData: {
    communicationJobId: 0,
    generatedMessgePath: "",
    isLoading: false,
  },
  email: {
    isLoading: false,
  },
  tempalateUpdatedContent: [],
};
