import React from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {$Input, $Button, $TextArea, $Form} from "us.common/components";
import { BMDCategory } from "./AddBMDCategory";
import { BMDActions } from "us.collection/actions";
import { IRootState } from "us.collection/interfaces";
import { connect } from "react-redux";
import { AddBMDGroupValidation } from "./Validation";
import { AddBMDGroup, UpdateBMDGroup } from "us.collection/repository";
import { IAddBMDGroup } from "./Interfaces";
import { $MessageBox } from "us.common/components";
import { isCategoryDuplicate } from "../../Functions";

/**
 * @description - The component used in add and edit bmd group drawer
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2910748725/Add+BMD+Group+-+UI+Implementation
 * @param {PropsFromRedux} props
 * @return {JSX.Element}
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 22/03/2022
 */
const BMDGroup: React.FC<IAddBMDGroup> = (props) => {
  const { t } = useTranslation();

  const {
    openGroupDrawer,
    bmdGroupInitial,
    save,
    bmdGroup,
    bmdGroupDrawer,
    editGroupContent,
    update,
    bmdListData,
  } = props;

  const { isLoading } = bmdGroup ?? {};
  const { isEdit } = bmdGroupDrawer ?? {};

  /**
   * @function
   * @description Close add/edit bmd group drawer
   * @returns void
   */
  const closeDrawer = () => {
    openGroupDrawer &&
      openGroupDrawer({
        title: "",
        isVisible: false,
        isEdit: false,
      });
  };

  /**
   * @function
   * @description Add bmd group
   * @param values Add/Edit form values
   * @returns void
   */
  const saveBMDGroup = (values: any) => {
    if (
      isCategoryDuplicate(bmdListData.categories, values)
    ) {
      $MessageBox(
        "error",
        "US.COLLECTION.CASE:BMD.BMD_GROUP_NAME_CAN_NOT_BE_DUPLICATE",
        "",
        ""
      );
    } else {
      if (isEdit) {
        update && update(UpdateBMDGroup.call(values));
      } else {
        save && save(AddBMDGroup.call(values));
      }
    }
  };

  return (
    <Formik
      initialValues={isEdit ? editGroupContent : bmdGroupInitial}
      validationSchema={AddBMDGroupValidation}
      enableReinitialize
      onSubmit={saveBMDGroup}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <$Form layout="vertical">
            <div data-testid="category">
              <BMDCategory restPropsValues={values} disabled={isEdit} />
            </div>
            <div data-testid="group-name">
              <$Input
                name="name"
                label={t("US.COLLECTION.CASE:BMD.SUB_CATEGORY")}
                size="small"
                required
                disabled={isLoading}
              />
            </div>
            <div data-testid="group-description">
              <$TextArea
                autoSize={{ minRows: 4, maxRows: 6 }}
                label={t("US.COLLECTION.CASE:BMD.DESCRIPTION")}
                name="description"
                maxLength={200}
                disabled={isLoading}
              />
            </div>
          </$Form>
          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Button
                data-testid="save-button"
                className="ml-3 mr-2"
                type="primary"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isEdit
                  ? t("US.COLLECTION.COMMON:COMMON.UPDATE")
                  : t("US.COLLECTION.COMMON:COMMON.SAVE")}
              </$Button>
              <$Button onClick={closeDrawer} data-testid="cancel-button">
                {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
              </$Button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

const { bmdGroup } = BMDActions;

const mapStateToProps = (state: IRootState) => {
  const { bmd } = state;
  const { categoryList, bmdGroupInitial, bmdGroup, bmdGroupDrawer, bmdList } =
    bmd;
  return {
    categoryList,
    bmdGroupInitial,
    bmdGroup,
    bmdGroupDrawer,
    bmdListData: bmdList.data,
  };
};

const mapDispatchToProps = {
  openGroupDrawer: bmdGroup.openDrawer,
  save: bmdGroup.save,
  update: bmdGroup.update,
};

export default connect(mapStateToProps, mapDispatchToProps)(BMDGroup);
