import { IBMDCategory } from "us.collection/interfaces";

/**
 * @function
 * @description Check Group name duplication with same category
 * @param categories BMD category list
 * @param values Form values
 * @returns Duplicate or not
 */
export const isCategoryDuplicate = (
  categories: IBMDCategory[],
  values: any
): boolean => {
  try {
    if (
      categories
        .find((category) => category.id == values.categoryId)
        ?.bmdGroups.find((group) => group.name == values.name.trim())
    ) {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
};