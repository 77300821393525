import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Affix, PageHeader, Radio } from 'antd';
import { BasicInfo, AddressInfo, ContactInfo } from '../../components';
import OtherInfo from './OtherInfo';
import { RadioChangeEvent } from 'antd/lib/radio';
import './Infomation.scss';
import { IntlDate } from 'us.common/functions';
import { useTranslation } from 'react-i18next';


interface ICreditorInfomation {
    onMinimize : () => void;
    currentLanguage:string;
}

interface IBase extends ICreditorInfomation{
    
    //getCreditorGeneralInfo: any;
    creditorLevelDetail: any;
}

class CurrentView {
    generalInfo: any;
    otherInfo: any;
    address: any;
    contactInfo: any;
    level: string;
    show: string;
    constructor() {
        this.level = '';
        this.show = '';
    }
}

class ViewData {
    level1: CurrentView;
    level2: CurrentView;
    level3: CurrentView;
    constructor() {
        this.level1 = new CurrentView();
        this.level2 = new CurrentView();
        this.level3 = new CurrentView();
    }
}


const CreditorInfomation: React.FC<IBase> = (props) => {

    const { t } = useTranslation(['US.COLLECTION.CREDITOR'], { useSuspense:true });
    const statisticStylle = { fontSize: '0.75em', textAlign: 'left', textDecoration: 'none' };

    const {
       // getCreditorGeneralInfo,
        creditorLevelDetail,
        // resetCreditorLevelDetail
        currentLanguage
    } = props;

    const gridStyle = {
        width: '90%',
        textAlign: 'left',
        margin: '0.5em',
        backgroundColor: 'white',
        padding: '5px',
        fontSize: '0.75em'
    };


    const [viewData, setViewData] = useState<ViewData>(new ViewData());

    const viewNames = {
        CreditorInfo: 'CreditorInfo',
        OtherInfo: 'OtherInfo',
        Address: 'Address',
        CntInfo: 'CntInfo'
    }

    const levelNames = {
        level1: 'level1',
        level2: 'level2',
        level3: 'level3'
    }

    const [currentView, setCurrentView] = useState<CurrentView>(new CurrentView());

    useEffect(() => {
        if (!creditorLevelDetail || Object.entries(creditorLevelDetail).length === 0) {
           // getCreditorGeneralInfo(['/1', '/2', '/3']);
        }
        else {

            if (currentView.level === '') { 
                setCurrentView({ ...creditorLevelDetail.level1, show: viewNames.CreditorInfo, level: levelNames.level1 });
                setViewData({ ...creditorLevelDetail });
            }
        }
        return () => {
            
        };
    }, [creditorLevelDetail, currentView, levelNames.level1, viewData, viewNames.CreditorInfo]);

    const getViewDataCurrentView = (levelArg: string): CurrentView => {
        switch (levelArg) {
            case levelNames.level1:
                return viewData.level1;
            case levelNames.level2:
                return viewData.level2;
            case levelNames.level3:
                return viewData.level3;
            default:
                return new CurrentView();
        }
    }

    const onInfoTabChange = (e: RadioChangeEvent) => {

        let level: any = currentView.level;
        switch (e.target.value) {
            case 'Address':
                if (currentView.address ? currentView.address.id > 0 : false) {
                    setCurrentView({ ...currentView, show: viewNames.Address });
                } else {
                    if (getViewDataCurrentView(level).address ? (getViewDataCurrentView(level).address[0].id > 0) : false) {
                        setCurrentView({ ...currentView, address: getViewDataCurrentView(level).address, show: viewNames.Address });
                    } else if (creditorLevelDetail) {
                        let arrangedAddresses = creditorLevelDetail[level].address.addresses.map((item: any) => { return { ...item, key: item.id } });
                        setCurrentView({ ...currentView, address: arrangedAddresses, show: viewNames.Address });
                        setViewData({ ...viewData, [level]: { ...getViewDataCurrentView(level), address: arrangedAddresses } });
                    }
                }

                break;

            case viewNames.CntInfo:
                if (currentView.contactInfo ? currentView.contactInfo.id > 0 : false) {
                    setCurrentView({ ...currentView, show: viewNames.CntInfo });
                }
                else {
                    if (getViewDataCurrentView(level).contactInfo ? (getViewDataCurrentView(level).contactInfo[0].id > 0) : false) {
                        setCurrentView({ ...currentView, contactInfo: getViewDataCurrentView(level).contactInfo, show: viewNames.CntInfo });
                    } else if (creditorLevelDetail) {
                        let arrangedContacts = creditorLevelDetail[level].contactInfo.contacts.map((item: any) => { return { ...item, key: item.id } });
                        setCurrentView({ ...currentView, contactInfo: arrangedContacts, show: viewNames.CntInfo });
                        setViewData({ ...viewData, [level]: { ...getViewDataCurrentView(level), contactInfo: arrangedContacts } });
                    }
                }
                break;

            default:
                setCurrentView({ ...currentView, show: e.target.value });
                break;
        }
    }

    const onLevelChange = (levelValue: string) => {
        switch (levelValue) {
            case levelNames.level1:

                setCurrentView({ ...viewData.level1, show: viewNames.CreditorInfo, level: levelNames.level1 })
                break;

            case levelNames.level2:
                if (viewData.level2.generalInfo ? (viewData.level2.generalInfo.id > 0) : false) {
                    setCurrentView({
                        ...viewData.level2,
                        generalInfo: viewData.level2.generalInfo,
                        show: viewNames.CreditorInfo,
                        level: levelNames.level2
                    });
                }
                else if (currentView?.generalInfo.BelongsTo) {

                    setCurrentView({
                        generalInfo: creditorLevelDetail[levelValue].generalInfo, show: viewNames.CreditorInfo, level: levelNames.level2
                        , otherInfo: undefined,
                        address: undefined,
                        contactInfo: undefined
                    });
                    setViewData({ ...viewData, level2: { ...viewData.level2, generalInfo: creditorLevelDetail[levelValue].generalInfo } });
                }
                break;

            case levelNames.level3:
                if (viewData.level3.generalInfo ? (viewData.level3.generalInfo.id > 0) : false) {
                    setCurrentView({
                        ...viewData.level3,
                        generalInfo: viewData.level3.generalInfo, show: viewNames.CreditorInfo, level: levelNames.level3
                    });
                }
                else if (viewData.level2.generalInfo ? viewData.level2.generalInfo.BelongsTo : false) {
                    setCurrentView({ generalInfo: creditorLevelDetail[levelValue].generalInfo, show: viewNames.CreditorInfo, level: levelNames.level3 
                        , otherInfo: undefined,
                        address: undefined,
                        contactInfo: undefined});
                    setViewData({ ...viewData, level3: { ...viewData.level3,generalInfo:creditorLevelDetail[levelValue].generalInfo } });
                }
                break;

            default:
                break;
        }
    }

    const minimize = () => {
        props.onMinimize();
    }


    return (
        <>
            <Affix offsetTop={80}>
                <div className="page-header">
                    <div className="d-flex align-items-center">
                        <PageHeader className="px-0 py-3 mr-n3" onBack={minimize} title={t('US.COLLECTION.CREDITOR:INFO.CREDITORINFORMATION')} />
                    </div>
                </div>
            </Affix>
            <div className="level-nav-container">
                <div className={currentView?.level === levelNames.level1 ? 'level-nav selected' : 'level-nav'} onClick={() => onLevelChange(levelNames.level1)}>
                    <div className="level-nav-item-icon">1</div>
                    <div className="level-nav-item-content">{t('US.COLLECTION.CREDITOR:INFO.LEVEL')}</div>
                    <div className="level-nav-item-divider"></div>
                </div>
                <div className={currentView?.level === levelNames.level2 ? 'level-nav selected' : 'level-nav'} onClick={() => onLevelChange(levelNames.level2)}>
                    <div className="level-nav-item-icon" >2</div>
                    <div className="level-nav-item-content">{t('US.COLLECTION.CREDITOR:INFO.LEVEL')}</div>
                    <div className="level-nav-item-divider"></div>
                </div>
                <div className={currentView?.level === levelNames.level3 ? 'level-nav selected' : 'level-nav'} onClick={() => onLevelChange(levelNames.level3)}>
                    <div className="level-nav-item-icon">3</div>
                    <div className="level-nav-item-content">{t('US.COLLECTION.CREDITOR:INFO.LEVEL')}</div>
                </div>
            </div>

            <Radio.Group onChange={(e) => onInfoTabChange(e)} defaultValue={currentView ? currentView.show : 'CreditorInfo'} buttonStyle="solid">
                <Radio.Button checked={currentView ? currentView.show === 'CreditorInfo' : true} value="CreditorInfo">{t('US.COLLECTION.CREDITOR:INFO.CREDITORINFORMATION')}</Radio.Button>
                <Radio.Button checked={currentView ? currentView.show === 'OtherInfo' : false} value="OtherInfo">{t('US.COLLECTION.CREDITOR:INFO.OTHERINFORMATION')}</Radio.Button>
                <Radio.Button checked={currentView ? currentView.show === 'Address' : false} value="Address">{t('US.COLLECTION.CREDITOR:INFO.ADDRESS')}</Radio.Button>
                <Radio.Button checked={currentView ? currentView.show === 'CntInfo' : false} value="CntInfo">{t('US.COLLECTION.CREDITOR:INFO.CONTACTINFO')}</Radio.Button>
            </Radio.Group>

            {
                currentView ?
                    (function () {
                        switch (currentView.show) {
                            case viewNames.CreditorInfo:
                                return (
                                    <BasicInfo gridStyle={gridStyle} statisticStylle={statisticStylle} CreditorGeneralInfo={currentView.generalInfo} />
                                );

                            case viewNames.OtherInfo:
                                return (
                                    <OtherInfo gridStyle={gridStyle} statisticStylle={statisticStylle} CreditorGeneralInfo={currentView.generalInfo} />
                                )

                            case viewNames.Address:
                                return (
                                    <AddressInfo data={currentView.address.addresses?.map((address: any) => { return { ...address,RegDateTime:IntlDate( address.RegDateTime,currentLanguage), key: address.id } })} />
                                );

                            case viewNames.CntInfo:
                                return (
                                    <ContactInfo data={currentView.contactInfo.contacts?.map((contact: any) => { return { ...contact,BirthDate:IntlDate(contact.BirthDate,currentLanguage), key: contact.id } })} />
                                );

                            default:
                                return (<p>noting matched</p>)
                        }
                    }())
                    :

                    null
            }

        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
        creditorLevelDetail: state.creditor.creditorLevelDetail,
        currentLanguage: state.common.currentLanguage
    };
};

const mapDispatchToProps = {
   // getCreditorGeneralInfo,
    // resetCreditorLevelDetail
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreditorInfomation);