import { AddPartPayment } from 'us.collection.partpayments/constants/Actions'
import { initialState } from 'us.collection.partpayments/reducers/AddPartPayment/State'
import { getPartPaymentInitial } from './Functions';

const {
    GET_EXISTING_PART_PAYMENT_START,
    GET_EXISTING_PART_PAYMENT_SUCCESS,
    GET_EXISTING_PART_PAYMENT_FAIL,
    CALCULATE_PART_PAYMENT_START,
    CALCULATE_PART_PAYMENT_SUCCESS,
    CALCULATE_PART_PAYMENT_FAIL,
    GET_PART_PAYMENT_BMDS_START,
    GET_PART_PAYMENT_BMDS_SUCCESS,
    GET_PART_PAYMENT_BMDS_FAIL,
    ADD_PAYMENT_AGREEMENT_DRAWER,
    ADD_PAYMENT_AGREEMENT_START,
    ADD_PAYMENT_AGREEMENT_SUCCESS,
    ADD_PAYMENT_AGREEMENT_FAIL,
    DELETE_PART_PAYMENT_START,
    DELETE_PART_PAYMENT_SUCCESS,
    DELETE_PART_PAYMENT_FAIL,
    RESET_ADD_PART_PAYMENT,
    RESET_ADD_PART_PAYMENT_CHANGE_COST,
    GET_PAYMENT_BY_INSTALLMENT_START,
    GET_PAYMENT_BY_INSTALLMENT_SUCCESS,
    GET_PAYMENT_BY_INSTALLMENT_FAIL,
    EDIT_PART_PAYMENT_START,
    EDIT_PART_PAYMENT_SUCCESS,
    EDIT_PART_PAYMENT_FAIL
} = AddPartPayment

export default (state: any = initialState, { payload, type }: any) => {
    switch (type) {
        case GET_EXISTING_PART_PAYMENT_START:
          return Object.assign({}, state, {
            isFetching: true,
        });
        case GET_EXISTING_PART_PAYMENT_SUCCESS:
          return Object.assign({}, state, {
            ExistingPartPaymentDetails : payload?.data,
            PartPaymentDetailsDuplicate: payload?.data,
            isFetching: false,
        });
        case GET_EXISTING_PART_PAYMENT_FAIL:
          return Object.assign({}, state, {
            isFetching: false,
          });

        case CALCULATE_PART_PAYMENT_START:
          return Object.assign({}, state, {
            AddPartPaymentInitial: payload?.data?.initialValues,
            isFetching: !(payload?.data?.calculateType === "calculateInstalment"),
        });
        case CALCULATE_PART_PAYMENT_SUCCESS:
          const partPaymentInit = getPartPaymentInitial({...state.AddPartPaymentInitial}, payload?.data);
          return Object.assign({}, state, {
            ExistingPartPaymentDetails : payload?.data?.calculateType == "calculateInstalment" ? {} : payload?.data?.plan,
            PartPaymentDetailsDuplicate : payload?.data?.plan?.isSaved ? payload?.data?.plan : {...state.PartPaymentDetailsDuplicate},
            AddPartPaymentInitial:   partPaymentInit,
            isFetching: false,
        });
        case CALCULATE_PART_PAYMENT_FAIL:
          return Object.assign({}, state, {
            isFetching: false,
          });

        case GET_PART_PAYMENT_BMDS_START:
          return Object.assign({}, state, {
            isFetching: true,
        });
        case GET_PART_PAYMENT_BMDS_SUCCESS:
          let remainbalanceBMD = 0;
          let instalmentAmountBMD = 0;
          let noofinstallmentBMD = 0;
          let minInstallmentsCostBMD = 0;
          let minRemainingBalanceForCost = 0;
          let forwadbmd = 0;
          payload?.data?.map((item: any) =>{
            if(item.displayKeyName == "Minimum"){
              remainbalanceBMD = item.value;
              instalmentAmountBMD = item.value;
            }
            if(item.displayKeyName == "MaxNoOfInstallment"){
              noofinstallmentBMD = item.value;
            }
            if(item.displayKeyName == "MinInstallmentsForCost"){
              minInstallmentsCostBMD = item.value;
            }
            if(item.displayKeyName == "MinRemainingBalanceForCost"){
              minRemainingBalanceForCost = item.value;
            }
            if(item.displayKeyName == "ForwardCharge"){
              forwadbmd = item.value;
            }
          })
          return Object.assign({}, state, {
            AddPartPaymentInitial: {...state.AddPartPaymentInitial, 
                                    BMDs: payload?.data, 
                                    remainBalanceBMD: remainbalanceBMD, 
                                    noOfInstallmentBMD: noofinstallmentBMD,
                                    installmentAmountBMD: instalmentAmountBMD,
                                    minInstallmentsForCostBMD: Number(minInstallmentsCostBMD),
                                    minRemainingBalanceForCost,
                                    forwardChargeBMD: forwadbmd, 
                                    forwardCharge: forwadbmd
                                  },
            partPaymentBMDs : payload?.data,
            isFetching: false,
        });
        case GET_PART_PAYMENT_BMDS_FAIL:
          return Object.assign({}, state, {
            isFetching: false,
          });

        case ADD_PAYMENT_AGREEMENT_DRAWER:
          return Object.assign({}, state, {
            addPaymentAgreementDrawer: payload?.data,
            isFetching: false,
        });

        case ADD_PAYMENT_AGREEMENT_START:
          return Object.assign({}, state, {
            isFetching: true,
        });
        case ADD_PAYMENT_AGREEMENT_SUCCESS:
          return Object.assign({}, state, {
            isFetching: false,
        });
        case ADD_PAYMENT_AGREEMENT_FAIL:
          return Object.assign({}, state, {
            isFetching: false,
          });

        case DELETE_PART_PAYMENT_START:
          return Object.assign({}, state, {
            isFetching: true,
        });
        case DELETE_PART_PAYMENT_SUCCESS:
          return Object.assign({}, state, {
            ExistingPartPaymentDetails: !payload?.data?.isError ? initialState.ExistingPartPaymentDetails : {...state.ExistingPartPaymentDetails} ,
            PartPaymentDetailsDuplicate : !payload?.data?.isError ? initialState.ExistingPartPaymentDetails : {...state.PartPaymentDetailsDuplicate},
            AddPartPaymentInitial: {...state.AddPartPaymentInitial,
                                      noOfInstallment:  0  ,
                                      noOfInstallmentDuplicate:  0 ,
                                      installmentAmount: 0.00 ,
                                      installmentAmountDuplicate: 0.00 
                                  },
            isFetching: false,
        });
        case DELETE_PART_PAYMENT_FAIL:
          return Object.assign({}, state, {
            isFetching: false,
          });

        case RESET_ADD_PART_PAYMENT:
          return Object.assign({}, state, {
            AddPartPaymentInitial: payload?.data == "" ? initialState.AddPartPaymentInitial : {...state.AddPartPaymentInitial,
                                                                                                  remainingBalance: 0.00,
                                                                                                  noOfInstallment: 0,
                                                                                                  noOfInstallmentDuplicate: 0,
                                                                                                  installmentAmount: 0.00,
                                                                                                  installmentAmountDuplicate: 0.00,
                                                                                                  partPaymentDate: "",
                                                                                                  firstInstallmentDate: "",
                                                                                                  forwardChargOrCosts: 'forwardCharge',
                                                                                                  comunicationMethod: "SMS"
                                                                                              },
            ExistingPartPaymentDetails: payload?.data == "" ? {...state.ExistingPartPaymentDetails} : {...state.PartPaymentDetailsDuplicate},
            isFetching: false,
          });
        case RESET_ADD_PART_PAYMENT_CHANGE_COST:
          return Object.assign({}, state, {
            ExistingPartPaymentDetails: {...state.ExistingPartPaymentDetails, schemaList: []},
            AddPartPaymentInitial: payload?.data,
            isFetching: false,
          });

        case GET_PAYMENT_BY_INSTALLMENT_START:
          return Object.assign({}, state, {
            isFetching: false,
        });
        case GET_PAYMENT_BY_INSTALLMENT_SUCCESS:
          const tempScemaList = state.ExistingPartPaymentDetails?.schemaList?.map((item: any) =>{
            if(item.installmentId == payload?.data?.installmentId){
              return {
                ...item,
                paymentsDetails : payload?.data?.payments
              }
            }
            return item
          })
          return Object.assign({}, state, {
            ExistingPartPaymentDetails: {...state.ExistingPartPaymentDetails, schemaList: tempScemaList},
            isFetching: false,
          });
        case GET_PAYMENT_BY_INSTALLMENT_FAIL:
          return Object.assign({}, state, {
            isFetching: false,
          });

        case EDIT_PART_PAYMENT_START:
          return Object.assign({}, state, {
            ExistingPartPaymentDetails: {...state.ExistingPartPaymentDetails, firstInstallmentDate: payload?.data?.dueDate},
            isFetching: true,
        });
        case EDIT_PART_PAYMENT_SUCCESS:
          return Object.assign({}, state, {
            isFetching: false,
        });
        case EDIT_PART_PAYMENT_FAIL:
          return Object.assign({}, state, {
            isFetching: false,
          });

        default:
            return state;
    }
}