import { ICase, ISubCase } from "us.collection.transactions/interfaces";
import { appInsights } from "us.helper";

/**
 * @function
 * @description - To get the case list flattened
 * @param {ICase[]} caseList - case list collection
 * @return {*}  {ISubCase[]} - a flattened list of case -> list of sub cases
 */
export const getDataSetToExport = (caseList: ICase[]): ISubCase[] => {
  try {
    return caseList.flatMap((item) => {
      if (item.children?.length) {
        return [{ ...item, children: null, caseNo: item.caseNumber }, ...item.children];
      } else {
        return [item];
      }
    });
  } catch (error) {
    appInsights.trackException(
      `Error in case and subcases getDataSetToExport funciton. - ${error}`
    );
    return [];
  }
};
