import { initialState } from 'us.collection.case/reducers/Dashboard/State';
import {
	AccountSummeryWidget,
	DebtorWidget,
	CaseInfoWidget,
	Interest,
} from 'us.collection.case/constants/Actions';
import { DomainView } from 'us.collection/constants';
import {
	IDataModel,
	IAccountSummeryWidget,
	IDebtorWidgetInfo,
	ICaseInformation,
} from './Interfaces';

const { GET_CASE_ID } = DomainView;

const {
	GET_SUMMARY_INTEREST_START,
	GET_SUMMARY_INTEREST_SUCCESS,
	GET_SUMMARY_INTEREST_FAIL,
} = Interest;

const {
	GET_ACCOUNT_SUMMERY_WIDGET_DATA,
	GET_ACCOUNT_SUMMERY_WIDGET_DATA_FAIL,
	GET_ACCOUNT_SUMMERY_WIDGET_DATA_SUCCESS,
} = AccountSummeryWidget;

const {
	GET_DEBTOR_WIDGET_DATA,
	GET_DEBTOR_WIDGET_DATA_FAIL,
	GET_DEBTOR_WIDGET_DATA_SUCCESS,
} = DebtorWidget;

const {
	GET_CASE_INFO_WIDGET_DATA,
	GET_CASE_INFO_WIDGET_DATA_FAIL,
	GET_CASE_INFO_WIDGET_DATA_SUCCESS,
} = CaseInfoWidget;

enum stateProperties {
	accountSummeryWidgetData = 'accountSummeryWidgetData',
	debtorWidgetData = 'debtorWidgetData',
	caseInfoWidgetData = 'caseInfoWidgetData',
}

export default (state: any = initialState, action: any) => {
	const getDataModel = <T>(
		data: T | undefined,
		isLoading: boolean,
		isError: boolean,
		propertyName: stateProperties
	) => {
		return Object.assign({}, state, {
			[propertyName]: <IDataModel<T>>{
				isLoading: isLoading,
				data: data,
				isError: isError,
			},
		});
	};

	switch (action.type) {
		case GET_CASE_ID:
			return Object.assign({}, state, {
				[stateProperties.accountSummeryWidgetData]: {
					isLoading: true,
					data: undefined,
					isError: false,
				},
				[stateProperties.debtorWidgetData]: {
					isLoading: true,
					data: undefined,
					isError: false,
				},
				[stateProperties.caseInfoWidgetData]: {
					isLoading: true,
					data: undefined,
					isError: false,
				},
			});
		/**
		 * for account summery
		 */
		case GET_ACCOUNT_SUMMERY_WIDGET_DATA:
			return getDataModel(
				undefined,
				true,
				false,
				stateProperties.accountSummeryWidgetData
			);
		case GET_ACCOUNT_SUMMERY_WIDGET_DATA_SUCCESS:
			return getDataModel<IAccountSummeryWidget>(
				action.payload.data,
				false,
				false,
				stateProperties.accountSummeryWidgetData
			);
		case GET_ACCOUNT_SUMMERY_WIDGET_DATA_FAIL:
			return getDataModel(
				undefined,
				false,
				true,
				stateProperties.accountSummeryWidgetData
			);

		/**
		 * for interest calculates
		 */
		case GET_SUMMARY_INTEREST_START:
			return Object.assign({}, state, {
				interestCalculating: true,
			});
		case GET_SUMMARY_INTEREST_SUCCESS:
			return Object.assign({}, state, {
				interestCalculating: false,
			});
		case GET_SUMMARY_INTEREST_FAIL:
			return Object.assign({}, state, {
				interestCalculating: false,
			});
		/**
		 * for debtor widget
		 */
		case GET_DEBTOR_WIDGET_DATA:
			return getDataModel(
				undefined,
				true,
				false,
				stateProperties.debtorWidgetData
			);
		case GET_DEBTOR_WIDGET_DATA_SUCCESS:
			return getDataModel<IDebtorWidgetInfo>(
				action.payload.data,
				false,
				false,
				stateProperties.debtorWidgetData
			);
		case GET_DEBTOR_WIDGET_DATA_FAIL:
			return getDataModel(
				undefined,
				false,
				true,
				stateProperties.debtorWidgetData
			);

		/**
		 * for case info
		 */
		case GET_CASE_INFO_WIDGET_DATA:
			return getDataModel(
				undefined,
				true,
				false,
				stateProperties.caseInfoWidgetData
			);
		case GET_CASE_INFO_WIDGET_DATA_SUCCESS:
			return getDataModel<ICaseInformation>(
				action.payload.data,
				false,
				false,
				stateProperties.caseInfoWidgetData
			);
		case GET_CASE_INFO_WIDGET_DATA_FAIL:
			return getDataModel(
				undefined,
				false,
				true,
				stateProperties.caseInfoWidgetData
			);

		default:
			return state;
	}
};
