import { appInsights } from "us.helper";
import { CaseType } from "us.helper/types/enums";
import { ICaseSubCasesFrom } from "../Interfaces";

/**
 * @function
 * @description - Check for the desition to dissable the Create-case button
 * @param {ICaseSubCasesFrom} values - form values
 * @return {*}  {boolean} - boolean decision if the create case button is dissabled
 */
export const isCreateCaseButtonDisabled = (values: ICaseSubCasesFrom): boolean => {
  try {
    return (
      values.selectedCases.filter(
        (caseItem) => caseItem.parentId === 0 && caseItem.caseType == CaseType.S && caseItem.isCreateEnabled
      ).length == 0 || values.selectedCases?.filter((x) => x.parentId > 0).length > 0
    );
  } catch (error) {
    appInsights.trackException(
      `Cases and Subcases Home isCreateCaseButtonDisabled error - ${error}`
    );
    return true;
  }
};
