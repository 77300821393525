import { template, values } from 'lodash';
import * as Yup from 'yup';
//import { IClaimOfPayment } from 'us.collection.case/reducers'

const numberReg = /^[0-9 ?\b]+$/;

export interface IClaimOfPayment extends Yup.MixedSchema {
	caseId: number;
	paidTo: string;
	paidDate: any;
	amountPaid: string;
	payerName: string;
	paymentMethod: string;
	cardNumber: number;
	paidFromAccount: number;
	paidToAccount: number;
	kidNumber: string;
	communicationMethod: string;
	smsInitial: string;
	smsNumber: string;
	caseNote: string;
	emailInitial: string;
	emailAddress: string;
	fileUpload: any;
	invoicetable: any;
	isSelected: boolean;
}

export default () => {
	return Yup.object<IClaimOfPayment>().shape({
		paidTo: Yup.string().required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
		),
		paidDate: Yup.string().required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
		),
		amountPaid: Yup.string()
			.required(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
			)
			.test(
				'amountPaid',
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD',
				(val: any) => {
					let value = typeof val == undefined ? '' : val;
					return (
						/^-?\d{1,3}(,\d{3})*(\.\d{2})?$/.test(value) ||
						/^-?\d*\.?\d*$/.test(value)
					);
				}
			)
			.test(
				'amountPaid',
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ENTER_A_VALID_VALUE_INTO_THE_FIELD',
				(val: any) => {
					let value = typeof val == undefined ? '' : val;
					return +value != 0;
				}
			),
		paymentMethod: Yup.string().required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
		),
		cardNumber: Yup.number()
			.lessThan(
				10000,
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.moreThan(
				999,
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.when('paymentMethod', {
				is: (paymentMethod) => paymentMethod == '2',
				then: Yup.number()
					.required(
						'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
					)
					.typeError(
						'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
					),
			}),
		paidFromAccount: Yup.number()
			.lessThan(
				100000000000,
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
			)
			.typeError(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.nullable(),
		paidToAccount: Yup.number()
			.lessThan(
				100000000000,
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
			)
			.typeError(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.nullable(),
		kidNumber: Yup.string()
			.matches(
				numberReg,
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.max(
				25,
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			),
		smsInitial: Yup.string(),
		emailInitial: Yup.string(),
		emailAddress: Yup.string()
			.email(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
			)
			.when(['emailInitial'], {
				is: (emailInitial) => emailInitial != undefined,
				then: Yup.string()
					.required(
						'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
					)
					.email(
						'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
					),
			}),
		smsNumber: Yup.string()
			.typeError(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.matches(/^\d{4,15}$/g, 'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS') 
		    .nullable()
			.when(['smsInitial'], {
				is: (smsInitial) => smsInitial != undefined,
				then: Yup.string()
					.required(
						'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
					)
					.typeError(
						'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
					)
					.matches(/^\d{4,15}$/g, 'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS') 
		    		.nullable(),
			}),
		communicationMethod: Yup.string().required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_SELECT_YOUR_COMMUNICATION_METHOD'
		),
	});
};
