import React from 'react';
import { Route } from 'react-router-dom';
import { DomainView, CaseView } from '../layouts';
import { CreditorGroup, CreditorView } from 'us.collection.creditor/layouts';
import {
	TransactionLayout,
	ArLayout,
} from 'us.collection.transactions/layouts';
import Case from 'us.collection.case';
import Creditor from 'us.collection.creditor';
import Debtor from 'us.collection.debtor';
import Partpayments from 'us.collection.partpayments';
import Transactions from 'us.collection.transactions';
import RoutinesAndActivities from 'us.collection.routines-and-activities';
import Documents from 'us.collection.documents';
import Admin from 'us.collection.admin';
import { CaseType } from 'us.helper/types/enums';
import { asyncRoutes } from 'us.collection/functions';

import {
	MainSearch,
	UserRoleSettings,
	UserSettings,
	Home,
	FollowUps,
} from './asyncRoutes';
import { BureauView } from 'us.collection.admin/layouts';

export default (caseNo: number) => {
	const MainViewModules = [Admin];
	const CaseViewModules = [
		Case,
		Partpayments,
		RoutinesAndActivities,
		Transactions,
		Documents,
		Debtor,
	];
	const TransactionViewModels = [Transactions];
	const CreditorModules = [Creditor];
	return [
		<Route
			exact
			key='search'
			path={'/search'}
			render={(props) => <MainSearch {...props} />}
		/>,
		<Route
			exact
			key='/user-role-settings'
			path={'/user-role-settings'}
			render={() => <UserRoleSettings />}
		/>,
		<Route
			exact
			key='/user-settings'
			path={'/user-settings'}
			render={() => <UserSettings />}
		/>,
		<Route
			exact
			key='/follow-up'
			path={'/follow-up/:followUpId?'}
			render={() => <FollowUps />}
		/>,
		<Route exact key='/' path={'/'} render={() => <Home />} />,
		<DomainView isFollowup='false' key={'DomainView'}>
			<Route
				key='creditor-routes'
				path='/creditor/:creditorId(\d+)'
				render={({ match: { url, params } }) => (
					<CreditorView.Layout>
						{CreditorModules.map(
							({ Routes }, index) => (
								<React.Fragment
									key={
										url +
										index
									}>
									{asyncRoutes(
										url,
										params.creditorId,
										CaseType.C,
										Routes
											.Containers
											.creditorContainers
									)}
								</React.Fragment>
							)
						)}
					</CreditorView.Layout>
				)}
			/>
			<Route
				key='creditor-group-routes'
				path='/creditor-group/:creditorGroupId(\d+)'
				render={({ match: { url } }) => (
					<CreditorGroup.Layout>
						{CreditorModules.map(
							({ Routes }, index) => (
								<React.Fragment
									key={
										url +
										index
									}>
									{asyncRoutes(
										url,
										caseNo,
										CaseType.C,
										Routes
											.Containers
											.creditorGroupContainers
									)}
								</React.Fragment>
							)
						)}
					</CreditorGroup.Layout>
				)}
			/>
			<Route
				key='payment-routes'
				path='/payment/:payment'
				render={({ match: { url } }) => (
					<TransactionLayout>
						{TransactionViewModels.map(
							({ Routes }, index) => (
								<React.Fragment
									key={
										url +
										index
									}>
									{asyncRoutes(
										url,
										caseNo,
										CaseType.C,
										Routes.TransactionContainers
									)}
								</React.Fragment>
							)
						)}
					</TransactionLayout>
				)}
			/>
			<Route
				key='ar-routes'
				path='/ar/:arNumber(\d+)'
				render={({ match: { url, params } }) => (
					<ArLayout>
						{TransactionViewModels.map(
							({ Routes }, index) => (
								<React.Fragment
									key={
										url +
										index
									}>
									{asyncRoutes(
										url,
										params.arNumber,
										CaseType.C,
										Routes.ArContainers
									)}
								</React.Fragment>
							)
						)}
					</ArLayout>
				)}
			/>
			<Route
				key='case-routes'
				path='/case/:exCaseNo(\d+)'
				render={({ match: { url } }) => (
					<CaseView.Layout>
						{CaseViewModules.map(
							({ Routes }, index) => (
								<React.Fragment
									key={
										url +
										index
									}>
									{asyncRoutes(
										url,
										caseNo,
										CaseType.C,
										Routes.Containers
									)}
								</React.Fragment>
							)
						)}
					</CaseView.Layout>
				)}
			/>
			<Route
				key='bureau-routes'
				path='/bureau'
				render={({ match: { url } }) => (
					<BureauView.Layout>
						{MainViewModules.map(
							({ Routes }, index) => (
								<React.Fragment
									key={
										url +
										index
									}>
									{asyncRoutes(
										url,
										caseNo,
										CaseType.C,
										Routes.BureauContainers
									)}
								</React.Fragment>
							)
						)}
					</BureauView.Layout>
				)}
			/>
		</DomainView>,
	];
};
