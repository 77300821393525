import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from "service.config.js";
import { ICalculatePartPayment } from 'us.collection.partpayments/reducers/AddPartPayment/Interfaces'

export default {
  PartPayment: {
    get:<P> ( params: P ): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "partpaymentservice",
            "partpayment",
            params,
            ServiceConfig()[`partpaymentservice`]["addPartPayment.get"]
          );
          if (status === 200 || status === 204 || status === 404) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },

    save: async (PartPaymentInfo: Partial<ICalculatePartPayment>): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.post(
            "partpaymentservice",
            'calculatepartpayment',
            PartPaymentInfo,
            ServiceConfig()[`partpaymentservice`]["addPartPayment.save"]
          );
          if (status === 200) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },

    delete: <P> (params: P): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.delete(
            "partpaymentservice",
            "partpayment",
            "",
            params,
            ServiceConfig()[`partpaymentservice`]["addPartPayment.delete"]
          );
          if (status === 200 || status === 404) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },

    edit: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.post(
            "partpaymentservice",
            'installment',
            params,
            ServiceConfig()[`partpaymentservice`]["addPartPayment.edit"]
          );
            resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },

  },

  partPaymentBMD: {
    get:<P> ( params: P ): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.post(
            "bmdservice",
            "bmdkeyvalue",
            params,
            ServiceConfig()[`bmdservice`]["bmdkeyvalue"]
          );
          if (status === 200 || status === 404) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },

  paymentAgreement: {
    save: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.post(
            "paymentagreementservice",
            'PaymentAgreement',
            params,
            ServiceConfig()[`paymentagreementservice`]["paymentAgreement"]
          );
            resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },

  paymentsByInstallment: {
    get:<P> ( params: P ): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "partpaymentservice",
            "partpayment/payment",
            params,
            ServiceConfig()[`partpaymentservice`]["addPartPayment.getPayments"]
          );
          if (status === 200 || status === 404) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },


};
