import { IAPIAction } from "us.collection/interfaces";
import { ICaseDocumentsAction } from "us.collection.documents/interfaces";
import { CaseDocuments } from "us.collection.documents/constants/Actions";

export const caseDocuments: Readonly<ICaseDocumentsAction & IAPIAction> = {
  documents: {
    get: (data) => ({
      type: CaseDocuments.GET_CASE_DOCUMENTS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CaseDocuments.GET_CASE_DOCUMENTS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CaseDocuments.GET_CASE_DOCUMENTS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    upload: (fileList, data) => ({
      type: CaseDocuments.UPLOAD_DOCUMENTS,
      payload: {
        fileList,
        data,
        isLoading: true,
      },
    }),
    uploadSuccess: (data) => ({
      type: CaseDocuments.UPLOAD_DOCUMENTS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    uploadFail: (error) => ({
      type: CaseDocuments.UPLOAD_DOCUMENTS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  document: {
    get: (data) => ({
      type: CaseDocuments.GET_CASE_DOCUMENT_CONTENT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CaseDocuments.GET_CASE_DOCUMENT_CONTENT_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CaseDocuments.GET_CASE_DOCUMENT_CONTENT_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    delete: (data) => ({
      type: CaseDocuments.DELETE_CASE_DOCUMENT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    deleteSuccess: (data) => ({
      type: CaseDocuments.DELETE_CASE_DOCUMENT_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    deleteFail: (error) => ({
      type: CaseDocuments.DELETE_CASE_DOCUMENT_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    managePreviewDrawer: (data) => ({
      type: CaseDocuments.MANAGE_PREVIEW_DRAWER,
      payload: {
        data,
      },
    }),
    manageUploadDrawer: (data) => ({
      type: CaseDocuments.MANAGE_UPLOAD_DRAWER,
      payload: {
        data,
      },
    }),
  },
  categories: {
    get: (data) => ({
      type: CaseDocuments.GET_DOCUMENT_CATEGORIES,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CaseDocuments.GET_DOCUMENT_CATEGORIES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CaseDocuments.GET_DOCUMENT_CATEGORIES_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  rules: {
    get: (data) => ({
      type: CaseDocuments.GET_UPLOAD_FILE_RULES,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CaseDocuments.GET_UPLOAD_FILE_RULES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CaseDocuments.GET_UPLOAD_FILE_RULES_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  }
};
