import React from "react";
import { IAsyncRoute } from "us.collection/interfaces";

export const Containers: Array<IAsyncRoute> = [
  {
    Container: React.lazy(() => import("../containers/Assets")),
    Route: "assets",
  },
  {
    Container: React.lazy(() => import("../containers/Agreements")),
    Route: "agreements",
  },
  {
    Container: React.lazy(() => import("../containers/MessageTemplates")),
    Route: "message-templates",
  },
  {
    Container: React.lazy(() => import("../containers/Notes")),
    Route: "notes",
  },
  {
    Container: React.lazy(() => import("../containers/OtherParty")),
    Route: "other-party",
  },
  {
    Container: React.lazy(() => import("../containers/CourtSentence")),
    Route: "sentences",
  },
  {
    Container: React.lazy(() => import("../containers/Interest")),
    Route: "interest",
  },
  {
    Container: React.lazy(() => import("../containers/Guarantor")),
    Route: "gurantor",
  },
  {
    Container: React.lazy(() => import("../containers/ExtendedField")),
    Route: "extended-fields",
  },
  {
    Container: React.lazy(() => import("../containers/Enforcements")),
    Route: "enforcements",
  },
  {
    Container: React.lazy(() => import("../containers/CaseInformation")),
    Route: "case-information",
  },
  {
    Container: React.lazy(() => import("../containers/DetailView")),
    Route: "",
  },
  {
    Container: React.lazy(() => import("us.collection/containers/BMD")),
    Route: "bmd",
  },
];
