import { ARNote } from "us.collection/constants";
import { initialState } from "./State";

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ARNote.GET_AR_NOTE_HISTORY_START:
      return Object.assign({}, state, {
        summery: { data: [], isLoading: true },
      });

    case ARNote.GET_AR_NOTE_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        summery: { data: action.payload.data, isLoading: false },
      });
    case ARNote.GET_AR_NOTE_HISTORY_FAIL:
      return Object.assign({}, state, {
        summery: { ...state.summery, isLoading: false },
      });
    case ARNote.ADD_AR_NOTE_DRAWER:
      return Object.assign({}, state, {
        noteDrawer: action.payload.data,
      });
    case ARNote.ADD_AR_NOTE_START:
      return Object.assign({}, state, {
        isNoteSaving: true
      });
    case ARNote.ADD_AR_NOTE_SUCCESS:
      return Object.assign({}, state, {
        noteDrawer: { visible: false, title: "", isEdit: false },
        isNoteSaving: false
      });
    case ARNote.ADD_AR_NOTE_FAIL:
      return Object.assign({}, state, {
        isNoteSaving: false
      });
    case ARNote.DELETE_AR_NOTE_START:
      return Object.assign({}, state, {});
    case ARNote.DELETE_AR_NOTE_SUCCESS:
      return Object.assign({}, state, {});
    case ARNote.DELETE_AR_NOTE_FAIL:
      return Object.assign({}, state, {});
    default:
      return state;
  }
};
