import React, {
	useState,
	useEffect,
	useContext,
	useCallback,
	useMemo,
} from 'react';
import { withRouter, matchPath, RouteComponentProps } from 'react-router-dom';
import { ConnectedProps, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BoxIcons, IconTypes } from 'us.icons';
import * as Actions from 'us.collection.case/actions';
import { $MessageBox, LottieAnimation } from 'us.common/components';
import Common from 'us.common';
import { ConfigurationContext } from 'us.common/ConfigurationContext/ConfigurationProvider';
import { CaseType } from 'us.helper/types/enums';
import './Layout.scss';
import { CloseStates } from 'us.collection/constants';
import { LogoAnimation } from 'us.common/components/animations';
import { getSelectedMenuKeyByModule } from 'us.collection/functions';
import { RootState } from 'us.helper/types';
import { Header, GDPRComment } from './Components';

const { $Layout, $Menu } = Common.Components;
const { Content, Sider } = $Layout;

const { interestAction, setCurrentCaseTypeAndTransactionTypeAction } = Actions;

const CaseLayout: React.FC<RouteComponentProps & PropsFromRedux> = (props) => {
	const { t } = useTranslation();
	const context = useContext(ConfigurationContext);
	const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

	const params: { type: string; id: string; module: string } =
		useMemo(() => {
			const { params }: any = matchPath(
				props.location.pathname,
				{
					path: '/:type/:id?/:module?',
					exact: false,
					strict: false,
				}
			);
			return {
				type: params['type'] ?? '',
				id: params['id'] ?? '',
				module: params['module'] ?? '',
			};
		}, [props.location.pathname]);

	const BODY_CLASS = 'change-body-color-dom';

	const {
		metaData,
		accountSummeryWidgetData,
		location,
		history,
		showGDPRCommentBox,
		showDashboard,
		workflow,
		caseViewData,
		setFilter,
		setCurrentCaseTypeAndTransactionTypeAction,
	} = props;

	const { entityType, caseNo } = metaData.data ?? {};
	const { workflowDescription = '' } = workflow.data ?? {};

	useEffect(() => {
		document.body.classList.add(BODY_CLASS);
		return () => {
			document.body.classList.remove(BODY_CLASS);
		};
	}, []);

	const navigate = (url: string): void => {
		if (caseNo) {
			window.scrollTo(0, 0);
			history.push({
				...location,
				pathname: `/case/${caseNo}/${url}`,
			});
			if (url === 'interest' && setFilter) {
				setFilter({ type: 'all' });
			}
			if (
				url === 'transactions' &&
				setCurrentCaseTypeAndTransactionTypeAction
			) {
				setCurrentCaseTypeAndTransactionTypeAction(
					entityType,
					'All'
				);
			}
		}
	};

	const handlePartPayment = () => {
		if (
			accountSummeryWidgetData.data?.paymentAgreementBasic
				?.total
		) {
			$MessageBox(
				'warning',
				'US.COLLECTION.LAYOUT:CASE.PAYMENT_AGREEMENT_EXISTS_FOR_THIS_CASE',
				'',
				''
			);
		} else {
			navigate('part-payments');
		}
	};

	const onCollapse = (collapsed: boolean): void => {
		setIsCollapsed(collapsed);
	};

	// check whether menu item is available or not
	const isMenuFieldAvailable = useCallback(
		(menuItem: string) => {
			return context.componentPermission['case']?.routes[
				menuItem
			]?.isEnabled;
		},
		[context?.componentPermission['case']]
	);

	return (
		<>
			{showDashboard && !caseViewData.isLoading && (
				<$Layout
					style={{
						minHeight: 'calc(100vh - 128px)',
					}}>
					<Header />
					<$Layout>
						<Content className='mt-0'>
							<main>
								{props.children}
							</main>
						</Content>
						<Sider
							collapsible
							collapsed={isCollapsed}
							onCollapse={(e) =>
								onCollapse(e)
							}
							data-testid='dashboard-layout-collapsible'
							reverseArrow={true}>
							<$Menu
								theme='dark'
								mode='inline'
								selectedKeys={getSelectedMenuKeyByModule(
									params.module
								)}>
								{isMenuFieldAvailable(
									'agreements'
								) && (
									<$Menu.Item
										onClick={() =>
											navigate(
												'agreements'
											)
										}
										key='1'
										data-testid='dashboard-layout-agreements'>
										<BoxIcons
											type={
												IconTypes.BOX_ICON
											}
											name='agreements'
										/>
										<span>
											{t(
												'US.COLLECTION.LAYOUT:CASE.AGREEMENTS'
											)}
										</span>
									</$Menu.Item>
								)}
								{entityType ===
									CaseType.C &&
									isMenuFieldAvailable(
										'assets'
									) && (
										<$Menu.Item
											onClick={() =>
												navigate(
													'assets'
												)
											}
											key='2'
											data-testid='dashboard-layout-assets'>
											<BoxIcons
												type={
													IconTypes.BOX_ICON
												}
												name='assets'
											/>
											<span>
												{t(
													'US.COLLECTION.LAYOUT:CASE.ASSETS'
												)}
											</span>
										</$Menu.Item>
									)}
								{isMenuFieldAvailable(
									'caseDocuments'
								) && (
									<$Menu.Item
										onClick={() =>
											navigate(
												'documents'
											)
										}
										key='4'
										data-testid='dashboard-layout-caseDocuments'>
										<BoxIcons
											type={
												IconTypes.BOX_ICON
											}
											name='documents'
										/>
										<span>
											{t(
												`US.COLLECTION.LAYOUT:CASE.${
													entityType ===
													CaseType.C
														? 'CASE'
														: 'SUBCASE'
												}_DOCUMENTS`
											)}
										</span>
									</$Menu.Item>
								)}
								{isMenuFieldAvailable(
									'caseInterest'
								) && (
									<$Menu.Item
										onClick={() =>
											navigate(
												'interest'
											)
										}
										key='5'
										data-testid='dashboard-layout-caseInterest'>
										<BoxIcons
											type={
												IconTypes.BOX_ICON
											}
											name='interest'
										/>
										<span>
											{t(
												`US.COLLECTION.LAYOUT:CASE.${
													entityType ===
													CaseType.C
														? 'CASE'
														: ''
												}INTEREST`
											)}
										</span>
									</$Menu.Item>
								)}
								{entityType ===
									CaseType.C &&
									isMenuFieldAvailable(
										'caseInformation'
									) && (
										<$Menu.Item
											onClick={() =>
												navigate(
													'case-information'
												)
											}
											key='6'
											data-testid='dashboard-layout-caseInformation'>
											<BoxIcons
												type={
													IconTypes.BOX_ICON
												}
												name='case-information'
											/>
											<span>
												{t(
													'US.COLLECTION.LAYOUT:CASE.CASE_INFORMATION'
												)}
											</span>
										</$Menu.Item>
									)}
								{isMenuFieldAvailable(
									'debtor'
								) && (
									<$Menu.Item
										onClick={() =>
											navigate(
												'debtor-information'
											)
										}
										key='8'
										data-testid='dashboard-layout-debtor'>
										<BoxIcons
											type={
												IconTypes.BOX_ICON
											}
											name='debtor-information'
										/>
										<span>
											{t(
												'US.COLLECTION.LAYOUT:CASE.DEBTORINFORMATION'
											)}
										</span>
									</$Menu.Item>
								)}
								{entityType ===
									CaseType.C &&
									isMenuFieldAvailable(
										'enforcement'
									) &&
									!CloseStates.includes(
										workflowDescription
									) && (
										<$Menu.Item
											onClick={() =>
												navigate(
													'enforcements'
												)
											}
											key='9'
											data-testid='dashboard-layout-enforcement'>
											<BoxIcons
												type={
													IconTypes.BOX_ICON
												}
												name='enforcement'
											/>
											<span>
												{t(
													'US.COLLECTION.LAYOUT:CASE.ENFORCEMENTS'
												)}
											</span>
										</$Menu.Item>
									)}

								{isMenuFieldAvailable(
									'extendedField'
								) && (
									<$Menu.Item
										onClick={() =>
											navigate(
												'extended-fields'
											)
										}
										key='10'
										data-testid='dashboard-layout-extendedField'>
										<BoxIcons
											type={
												IconTypes.BOX_ICON
											}
											name='extended-fields'
										/>
										<span>
											{t(
												'US.COLLECTION.LAYOUT:CASE.EXTENDEDFIELD'
											)}
										</span>
									</$Menu.Item>
								)}
								{isMenuFieldAvailable(
									'routinesAndActivities'
								) && (
									<$Menu.Item
										onClick={() =>
											navigate(
												'history'
											)
										}
										key='16'
										data-testid='dashboard-layout-routinesAndActivities'>
										<BoxIcons
											type={
												IconTypes.BOX_ICON
											}
											name='activity-history'
										/>
										<span>
											{t(
												'US.COLLECTION.LAYOUT:CASE.HISTORY'
											)}
										</span>
									</$Menu.Item>
								)}
								{isMenuFieldAvailable(
									'messageTemplates'
								) && (
									<$Menu.Item
										onClick={() =>
											navigate(
												'message-templates'
											)
										}
										key='12'
										data-testid='dashboard-layout-messageTemplates'>
										<BoxIcons
											type={
												IconTypes.BOX_ICON
											}
											name='template'
										/>
										<span>
											{t(
												'US.COLLECTION.LAYOUT:CASE.MESSAGETEMPLATES'
											)}
										</span>
									</$Menu.Item>
								)}
								{isMenuFieldAvailable(
									'otherParty'
								) && (
									<$Menu.Item
										onClick={() =>
											navigate(
												'other-party'
											)
										}
										key='14'
										data-testid='dashboard-layout-otherParty'>
										<BoxIcons
											type={
												IconTypes.BOX_ICON
											}
											name='other-party'
										/>
										<span>
											{t(
												'US.COLLECTION.LAYOUT:CASE.OTHERPARTY'
											)}
										</span>
									</$Menu.Item>
								)}
								{entityType ===
									CaseType.C &&
									isMenuFieldAvailable(
										'partPayments'
									) && (
										<$Menu.Item
											onClick={() =>
												handlePartPayment()
											}
											key='15'
											data-testid='dashboard-layout-partPayments'>
											<BoxIcons
												type={
													IconTypes.BOX_ICON
												}
												name='part-payment'
											/>
											<span>
												{t(
													'US.COLLECTION.LAYOUT:CASE.PARTPAYMENT'
												)}
											</span>
										</$Menu.Item>
									)}

								{entityType ===
									CaseType.C &&
									isMenuFieldAvailable(
										'sentences'
									) &&
									!CloseStates.includes(
										workflowDescription
									) && (
										<$Menu.Item
											onClick={() =>
												navigate(
													'sentences'
												)
											}
											key='17'
											data-testid='dashboard-layout-sentences'>
											<BoxIcons
												type={
													IconTypes.BOX_ICON
												}
												name='sentence'
											/>
											<span>
												{t(
													'US.COLLECTION.LAYOUT:CASE.SENTENCES'
												)}
											</span>
										</$Menu.Item>
									)}
								{isMenuFieldAvailable(
									'transactions'
								) && (
									<$Menu.Item
										onClick={() =>
											navigate(
												'transactions'
											)
										}
										key='18'
										data-testid='dashboard-layout-transactions'>
										<BoxIcons
											type={
												IconTypes.BOX_ICON
											}
											name='transaction'
										/>
										<span>
											{t(
												'US.COLLECTION.LAYOUT:CASE.TRANSACTIONS'
											)}
										</span>
									</$Menu.Item>
								)}
								{isMenuFieldAvailable(
									'bmd'
								) && (
									<$Menu.Item
										onClick={() =>
											navigate(
												'bmd'
											)
										}
										key='19'
										data-testid='dashboard-layout-bmd'>
										<BoxIcons
											type={
												IconTypes.BOX_ICON
											}
											name='bmd'
										/>
										<span>
											{t(
												'US.COLLECTION.LAYOUT:CASE.BMD'
											)}
										</span>
									</$Menu.Item>
								)}
							</$Menu>
						</Sider>
					</$Layout>
				</$Layout>
			)}
			{showGDPRCommentBox && <GDPRComment />}
			{!showDashboard &&
				!showGDPRCommentBox &&
				caseViewData.isLoading && (
					<div className='gdpr-inline-box-loading'>
						<LottieAnimation
							lotti={LogoAnimation}
							height={60}
							width={230}
						/>
					</div>
				)}
		</>
	);
};

const mapStateToProps = (state: RootState) => {
	const { dashboard, domainView } = state;
	const { accountSummeryWidgetData } = dashboard;
	const { metaData, showGDPRCommentBox, showDashboard, workflow } =
		domainView;
	return {
		metaData,
		accountSummeryWidgetData,
		showGDPRCommentBox,
		showDashboard,
		workflow,
		caseViewData: domainView.case,
	};
};

const mapDispatchToProps = {
	setFilter: interestAction.interest.setFilter,
	setCurrentCaseTypeAndTransactionTypeAction:
		setCurrentCaseTypeAndTransactionTypeAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(CaseLayout));
