import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Common from 'us.common';
import Highlighter from 'react-highlight-words';
import { Link, withRouter } from 'react-router-dom';
import { IViewPayments } from 'us.collection.partpayments/components/AddPartPayment/IViewPayments';

const { $Skeleton, $Table, $Input, $Button } = Common.Components;

import { FilterOutlined, SearchOutlined } from 'us.icons';
import { $AmountLabel, $DateLabel } from 'us.common/components';
import { RootState } from 'us.helper/types';

export interface ISortOrder {
	columnKey: string;
	order: any;
}

const ViewPayments: React.FC<IViewPayments> = (props) => {
	const { t } = useTranslation();

	const { paymentDetails } = props;
	const [searchText, setSearchText] = useState<string>('');
	const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
		columnKey: '',
		order: '',
	});

	const formatObject = (data: any) => {
		return {
			...data,
		};
	};

	const getColumnSearchProps = (dataIndex: string, title: string) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}: any) => (
			<div style={{ padding: 8 }}>
				<$Input
					name='columnSearch'
					placeholder={`${t(
						'US.COLLECTION.COMMON:COMMON.SEARCH'
					)} ${title}`}
					value={selectedKeys[0]}
					onChange={(e: any) =>
						setSelectedKeys(
							e.target.value
								? [
										e
											.target
											.value,
								  ]
								: []
						)
					}
					onPressEnter={() =>
						handleSearch(
							selectedKeys,
							confirm
						)
					}
					style={{
						width: 188,
						marginBottom: 8,
						display: 'block',
					}}
				/>

				<$Button
					type='primary'
					onClick={() =>
						handleSearch(
							selectedKeys,
							confirm
						)
					}
					icon={<SearchOutlined />}
					size='small'
					style={{ width: 90, marginRight: 8 }}>
					{t(
						'US.COLLECTION.COMMON:COMMON.SEARCH'
					)}
				</$Button>
				<$Button
					onClick={() =>
						handleReset(clearFilters)
					}
					size='small'
					style={{ width: 90 }}>
					{t('US.COLLECTION.COMMON:COMMON.RESET')}
				</$Button>
			</div>
		),
		filterIcon: (filtered: string) => (
			<FilterOutlined
				style={{
					color: filtered ? '#1890ff' : undefined,
				}}
			/>
		),
		onFilter: (value: string, record: any) => {
			let formatRecord = formatObject(record);
			return formatRecord[dataIndex]
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase());
		},
		render: (text: string) => (
			<Highlighter
				highlightStyle={{
					backgroundColor: '#ffc069',
					padding: 0,
				}}
				searchWords={[searchText]}
				autoEscape
				textToHighlight={text ? text.toString() : ''}
			/>
		),
	});

	const handleSearch = (selectedKeys: any, confirm: any) => {
		confirm();
		setSearchText(selectedKeys[0]);
	};

	const handleReset = (clearFilters: any) => {
		clearFilters();
		setSearchText('');
	};

	const handleTableChange = (
		pagination: any,
		filters: any,
		sorter: any
	) => {
		setSortedInfo(sorter);
	};

	const columns: any = [
		{
			title: t(
				'US.COLLECTION.PARTPAYMENTS:VIEW_PAYMENTS.PAYMENT_ID'
			),
			dataIndex: 'paymentId',
			key: 'paymentId',
			width: 200,
			className: 'text-nowrap',
			sorter: (a: any, b: any) => a.paymentId - b.paymentId,
			sortOrder: sortedInfo
				? sortedInfo.columnKey === 'paymentId' &&
				  sortedInfo.order
				: null,
			...getColumnSearchProps(
				'paymentId',
				t(
					'US.COLLECTION.PARTPAYMENTS:VIEW_PAYMENTS.PAYMENT_ID'
				)
			),
			render: (text: any, record: any) => {
				return (
					<Link
						to={{
							pathname: `/payment/${record?.paymentId}/distribution`,
							state: {
								originate: 'payment',
								accessTabs: [
									'payment',
								],
								refreshCount: 0,
								currentTab: 'payment',
							},
						}}
						replace>
						{record?.paymentId}
					</Link>
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.PARTPAYMENTS:VIEW_PAYMENTS.PAYMENT_AMOUNT'
			),
			dataIndex: 'paymentAmount',
			key: 'paymentAmount',
			width: 200,
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			sorter: (a: any, b: any) =>
				a.paymentAmount - b.paymentAmount,
			sortOrder: sortedInfo
				? sortedInfo.columnKey === 'paymentAmount' &&
				  sortedInfo.order
				: null,
			...getColumnSearchProps(
				'paymentAmount',
				t(
					'US.COLLECTION.PARTPAYMENTS:VIEW_PAYMENTS.PAYMENT_AMOUNT'
				)
			),
			render: (text: any, record: any) => {
				return (
					<$AmountLabel
						value={record?.paymentAmount}
					/>
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.PARTPAYMENTS:VIEW_PAYMENTS.PAYMENT_REG_DATE'
			),
			dataIndex: 'paymentDate',
			key: 'paymentDate',
			width: 200,
			className: 'text-nowrap',
			sorter: (a: any, b: any) =>
				a.paymentDate - b.paymentDate,
			sortOrder: sortedInfo
				? sortedInfo.columnKey === 'paymentDate' &&
				  sortedInfo.order
				: null,
			...getColumnSearchProps(
				'paymentDate',
				t(
					'US.COLLECTION.PARTPAYMENTS:VIEW_PAYMENTS.PAYMENT_REG_DATE'
				)
			),
			render: (text: any, record: any) => {
				return (
					<$DateLabel
						value={record?.paymentDate}
					/>
				);
			},
		},
	];

	return (
		<Formik
			enableReinitialize
			initialValues={{}}
			validateOnChange
			validateOnBlur
			validateOnMount
			onSubmit={() => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
			}: any) => (
				<div className='view-payments'>
					<div className='view-payments-inner'>
						<$Skeleton
							loading={
								!paymentDetails
							}
							active
							paragraph={{ rows: 2 }}>
							<$Table
								dataSource={
									paymentDetails
								}
								columns={
									columns
								}
								rowKey='paymentId'
								size='small'
								className='view-payments-data'
								bordered
								pagination={
									false
								}
								onChange={
									handleTableChange
								}
							/>
						</$Skeleton>
					</div>
				</div>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common } = state;
	const { currentCurrency, currentLanguage, currentDateFormat } = common;
	return {
		currentLanguage,
		currentCurrency,
		currentDateFormat,
	};
};

const mapDispatchToProps = {};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ViewPayments)
);
