import { IDeleteBMDPropertyHistoryDetailsObject } from "../Interfaces";

/**
 * @function
 * @description - to prepare the object for deleting the bmd property
 * @param {*} this - the input params
 * @return {*}  {IDeleteBMDPropertyHistoryDetailsObject} - type object required for saga to handle delete operation
 */
export function DeleteBMDPropertyHistoryDetailsObject(
  this: any
): IDeleteBMDPropertyHistoryDetailsObject {
  return {
    deletePrameters: {
      propertyValueId: this.id,
      entityType: this.entityType,
      entityId: parseInt(this.entityId) <= 0 ? 1 : parseInt(this.entityId),
    },
    bmdPropertyDetails: {
      propertyId: this.propertyId,
      keyName: this.keyName,
      dataTypeId: this.dataTypeId,
      dataTypeName: this.dataTypeName,
      description: this.description,
      displayName: this.displayName,
      cgVisibility: this.cgVisibility,
      crVisibility: this.crVisibility,
      arVisibility: this.arVisibility,
      caVisibility: this.caVisibility,
      changedHistory: this.changedHistory,
    },
  };
}
