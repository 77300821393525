import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { INavigationData } from 'us.collection/interfaces';
import Common from 'us.common';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ICreditors } from 'us.collection.creditor/components/Dashboard/Interfaces';
import * as Actions from 'us.collection.creditor/actions';
import './Creditors.scss';
import { RootState } from 'us.helper/types';

const { $List, $Skeleton, $Empty } = Common.Components;

const { creditor } = Actions;
const { general, creditors, otherContact, creditorBMDs } = creditor;

const Home: React.FC<ICreditors> = (props) => {
	const { t } = useTranslation(['US.COLLECTION.CREDITOR'], {
		useSuspense: true,
	});
	const { creditors, getCreditors, metaData, location } = props;

	const state = location.state as INavigationData;
	const { refreshCount = 0, currentTab, creditorId } = state ?? {};
	const { isLoading } = metaData;

	useEffect(() => {
		if (
			state &&
			currentTab === 'creditor' &&
			!creditors.isLoading
		) {
			if (creditorId && getCreditors) {
				getCreditors({ creditorId });
			}
		}
	}, [creditorId, refreshCount]);

	return (
		<div className='widget creditor-creditors-list'>
			<div className='widget-header'>
				<strong className='d-block mb-2'>
					{t(
						'US.COLLECTION.CREDITOR:CREDITORS.LINK_CREDITORS'
					)}
				</strong>
			</div>
			<div className='widget-body'>
				<$Skeleton
					loading={
						creditors.isLoading || isLoading
					}
					active
					paragraph={{ rows: 4 }}>
					{creditors?.data?.length == 0 && (
						<$Empty
							className='mb-4'
							description={`${t(
								'US.COLLECTION.CREDITOR:SETTINGS.NO_DATA'
							)}`}
							image={
								$Empty.PRESENTED_IMAGE_SIMPLE
							}
						/>
					)}
					{creditors?.data?.length > 0 && (
						<$List
							itemLayout='horizontal'
							dataSource={
								creditors?.data
							}
							renderItem={(
								item: any
							) => (
								<$List.Item>
									<$List.Item.Meta
										className='align-items-center'
										avatar={
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='19.309'
												height='22.888'
												viewBox='0 0 19.309 22.888'>
												<g
													id='businessman'
													transform='translate(-37.758 0)'>
													<g
														id='Group_1193'
														data-name='Group 1193'
														transform='translate(37.758 0)'>
														<path
															id='Path_1322'
															data-name='Path 1322'
															d='M134.783,9.7a4.84,4.84,0,0,0,2.976,3.687,3.261,3.261,0,0,0,2.185,0A4.93,4.93,0,0,0,142.929,9.7c.227-.019.526-.336.848-1.479.441-1.559-.028-1.791-.426-1.753a4.132,4.132,0,0,0,.171-.64c.673-4.042-1.317-4.18-1.317-4.18A3.07,3.07,0,0,0,141,.539a4.049,4.049,0,0,0-2.459-.521,3.672,3.672,0,0,0-.981.185h0a4.31,4.31,0,0,0-1.08.554,5.664,5.664,0,0,0-1.109.919,4.5,4.5,0,0,0-1.2,2.1,4.568,4.568,0,0,0,.009,2.057h0a4,4,0,0,0,.171.64c-.4-.038-.867.194-.426,1.753C134.257,9.368,134.555,9.685,134.783,9.7Z'
															transform='translate(-129.203 0)'
															fill='#70b3ff'
														/>
														<path
															id='Path_1323'
															data-name='Path 1323'
															d='M55.228,276.474a15.029,15.029,0,0,1-4.388-2L49.3,279.331l-.289.915,0-.014-.251.777-.81-2.3c1.99-2.777-.4-2.749-.535-2.744s-2.526-.033-.535,2.744l-.81,2.3-.251-.777,0,.014-.289-.915-1.54-4.857a15.029,15.029,0,0,1-4.388,2c-1.806.46-1.891,2.545-1.82,3.573,0,0,.1,1.4.209,2.014a18.521,18.521,0,0,0,9.43,2.294,18.465,18.465,0,0,0,9.43-2.294c.1-.616.209-2.014.209-2.014C57.119,279.019,57.033,276.934,55.228,276.474Z'
															transform='translate(-37.758 -261.467)'
															fill='#70b3ff'
														/>
													</g>
												</g>
											</svg>
										}
										title={
											<Link
												to={{
													...location,
													pathname: `/creditor/${item?.creditorInkassoId}`,
													state: {
														...state,
														currentTab: 'creditor',
														creditorId: item?.creditorId,
													},
												}}>{`${item?.creditorInkassoId} - ${item?.name}`}</Link>
										}
									/>
								</$List.Item>
							)}
						/>
					)}
				</$Skeleton>
			</div>
		</div>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, creditor, domainView } = state;
	const { currentLanguage, currentDateFormat } = common;
	const { creditors } = creditor;
	const { metaData } = domainView;
	return {
		creditors,
		metaData,
		currentLanguage,
		currentDateFormat,
	};
};

const mapDispatchToProps = {
	getCreditors: creditors.get,
	getGeneral: general.get,
	getOtherContact: otherContact.get,
	getCreditorBMDs: creditorBMDs.get,
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
