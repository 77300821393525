import React from 'react';
import { useTranslation } from 'react-i18next';
import { InterestType } from 'us.collection.transactions/constants';
import { $Tag } from 'us.common/components';

type InterestTypeTagProps = {
	rate: number;
	interestType: string;
};

const InterestTypeTag: React.FC<InterestTypeTagProps> = ({
	rate,
	interestType,
}) => {
	const { t } = useTranslation();

	return (
		<div className='d-flex align-items-center justify-content-between'>
			{interestType == InterestType.STANDARD_INTEREST && (
				<$Tag className='tag-interest-type-standard'>
					{t('US.COLLECTION.TRANSACTIONS:TRANSACTIONS.STANDARD')}
				</$Tag>
			)}
			{interestType == InterestType.FIXED_INTEREST && (
				<$Tag className='tag-interest-type-fixed'>
					{t('US.COLLECTION.TRANSACTIONS:TRANSACTIONS.FIXED_INTEREST')}
				</$Tag>
			)}
			{interestType == InterestType.NO_INTEREST && (
				<$Tag className='tag-interest-type-none'>
					{t('US.COLLECTION.TRANSACTIONS:TRANSACTIONS.NO_INTEREST')}
				</$Tag>
			)}
			{interestType == InterestType.FIXED_INTEREST && <span>{rate}%</span>}
		</div>
	);
};

export default InterestTypeTag;
