import React from "react";
import { EntityType } from "us.collection/components/BMD/Constants";
import { IBMDChangeHistory, IBMDPropertyDetails, IReturnAction } from "us.collection/interfaces";
import { $Button, $DateTimeLabel, $Popover, $Tooltip } from "us.common/components";
import { appInsights } from "us.helper";
import { MoreOutlined } from "us.icons";
import { TableAction } from "../../TableAction";
import { ITableCustomComponents } from "../Interfaces";
import { getActions } from "./GetActions";
import { tableClickEventHandler } from "./";

/**
 * @function
 * @description - Get table custom values
 * @param {(keyof IBMDChangeHistory | "more")} dataIndex - column indexes
 * @param {*} setValues - formik update field function
 * @param {((<T>(data: object | T[]) => IReturnAction<T>) | undefined)} bmdPropertyHistory - react action to delete bmd property
 * @param {({
 *     entityId: string | undefined;
 *     entityType: EntityType;
 *   })} routeDetails - routing details for the AddBMD component
 * @param {IBMDPropertyDetails} bmdPropertyDetails - BMD property details from the API
 * @return {*}  {(ITableCustomComponents<IBMDChangeHistory & { more: "more" }>)} - custom table rendering values
 */
export const getTableRenderItems = (
  dataIndex: keyof IBMDChangeHistory | "more",
  setValues: any,
  bmdPropertyHistory: (<T>(data: object | T[]) => IReturnAction<T>) | undefined,
  routeDetails: {
    entityId: string | undefined;
    entityType: EntityType;
  },
  bmdPropertyDetails: IBMDPropertyDetails
): ITableCustomComponents<IBMDChangeHistory & { more: "more" }> => {
  try {
    switch (dataIndex) {
      case "more":
        return {
          customRenderChild: (text: any, record: IBMDChangeHistory, index: number) => {
            return (
              <>
                {!record.isEffective && (
                  <$Popover
                    placement="rightTop"
                    content={
                      <TableAction
                        clickEvent={(e) =>
                          tableClickEventHandler(
                            e,
                            record,
                            setValues,
                            bmdPropertyHistory,
                            routeDetails,
                            bmdPropertyDetails
                          )
                        }
                        actions={getActions(record)}
                      />
                    }
                    destroyTooltipOnHide
                    trigger="hover"
                  >
                    <$Button icon={<MoreOutlined />} size="small" />
                  </$Popover>
                )}
              </>
            );
          },
        };

      case "dateTime":
        return {
          customRenderChild: (text: any, record: IBMDChangeHistory, index: number) => {
            return <$DateTimeLabel value={text} />;
          },
          customSorter: (a: any, b: any) => a?.localeCompare(b),
        };

      case "value":
      case "user":
      case "comment":
        return {
          customRenderChild: (text: any, record: IBMDChangeHistory, index: number) => {
            return (
              <$Tooltip placement="topLeft" title={text} key={text}>
                {text}
              </$Tooltip>
            );
          },
          customSorter: (a: any, b: any) => a?.localeCompare(b),
        };

      default:
        return {
          customSorter: (a: any, b: any) => a - b,
          customRenderChild: (text: any, record: IBMDChangeHistory, index: number) => <></>,
        };
    }
  } catch (error) {
    appInsights.trackException(`Error in add bmd getTableRenderItems function. - ${error}`);
    return {
      customSorter: (a: any, b: any) => a - b,
      customRenderChild: (text: any, record: IBMDChangeHistory, index: number) => <></>,
    };
  }
};
