import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { Formik } from 'formik';
import { Input } from 'antd';
import moment from 'moment';

import Common from 'us.common';
import { PlusOutlined, SaveOutlined } from 'us.icons';
import '../../ContentV2.scss';
import { $Divider } from 'us.common/components';
import { MessageAndNoteAction } from 'us.collection.case/actions';
import { INote } from 'us.collection.case/components/Interfaces/NotesAndMessage';
import { SendDomainNote } from 'us.collection.case/repository';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { appInsights } from 'us.helper/AppInsights';
import { NotesValidationSchema } from '../../Validations';
import { RootState } from 'us.helper/types';

const {
	$Form,
	$Select,
	$Button,
	$DatePicker,
	$Switch,
	$TextArea,
	$Typography,
	$Row,
	$Col,
} = Common.Components;

const { Paragraph } = $Typography;

const { note } = MessageAndNoteAction;

const Note: React.FC<INote & PropsFromRedux> = (props) => {
	const { t } = useTranslation();

	const { metaData, setNoteView, currentDateFormat, save } = props;

	const defaultCommunicationMethods = [
		{
			label: t(
				'US.COLLECTION.CASE:NOTESANDMESSAGES.TELEPHONE'
			),
			value: 'telephone',
		},
		{
			label: t('US.COLLECTION.CASE:NOTESANDMESSAGES.EMAIL'),
			value: 'email',
		},
		{
			label: t('US.COLLECTION.CASE:NOTESANDMESSAGES.NOTE'),
			value: 'note',
		},
	];

	const notesAndMessageInitial = {
		dueDate: null,
		internal: false,
		copyAsDebtorNotes: false,
		communicationMethod: 'note',
		caseNote: '',
	};

	const [addCommunicationMethod, setAddCommunicationMethod] = useState({
		addMethod: '',
		options: [] as any,
	});

	const handleAddCommunicationMethod = () => {
		try {
			const regex = /^(?!\s)[A-Za-z0-9\s]+$/;
			if (
				addCommunicationMethod.addMethod !== '' &&
				regex.test(addCommunicationMethod.addMethod)
			) {
				setAddCommunicationMethod({
					addMethod: '',
					options: [
						...addCommunicationMethod.options,
						{
							label: addCommunicationMethod.addMethod,
							value: addCommunicationMethod.addMethod.toLowerCase(),
						},
					],
				});
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard NotesAndMessage-Note handleAddComunicationMethod Exeception - ${error}`
			);
		}
	};

	const handleSubmit = (data: any, { resetForm }: any) => {
		try {
			if (metaData.data) {
				const queryObject = SendDomainNote.call(data, {
					...metaData.data,
				});

				save && save(queryObject);

				resetForm(notesAndMessageInitial);

				setNoteView('Note');
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard NotesAndMessage-Note Submit Exeception - ${error}`
			);
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={notesAndMessageInitial}
			validationSchema={NotesValidationSchema}
			onSubmit={handleSubmit}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...rest
			}: any) => (
				<div className='py-3'>
					<$Form>
						<$Row gutter={16}>
							<$Col
								span={10}
								xxl={{
									span: 10,
								}}
								xl={{
									span: 12,
								}}>
								<div
									style={{
										marginTop: 5,
									}}
									data-testid='manual-due-date-label'>
									{t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.MANUALDUEDATE'
									)}
								</div>
							</$Col>
							<$Col
								span={14}
								xxl={{
									span: 14,
								}}
								xl={{
									span: 12,
								}}>
								<$DatePicker
									formitem
									formitemlabel={t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.MANUALDUEDATE'
									)}
									className='w-100'
									name='dueDate'
									size='small'
									placeholder={
										currentDateFormat
									}
									format={
										currentDateFormat
									}
									allowClear
									value={
										values.dueDate
									}
									defaultPickerValue={
										values.dueDate
									}
									disabledDate={(
										day: any
									) =>
										day.isBefore(
											moment().startOf(
												'day'
											)
										)
									}
									disabled={
										metaData.data &&
										isSubCaseTransferredToCase(
											metaData.data
										)
									}
									data-testid='manual-duedate'
								/>
							</$Col>
						</$Row>

						<$Row gutter={16}>
							<$Col
								span={10}
								xxl={{
									span: 10,
								}}
								xl={{
									span: 12,
								}}>
								<div
									style={{
										marginTop: 5,
									}}
									data-testid='originated-in'>
									{t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.ORIGINATEDIN'
									)}
								</div>
							</$Col>
							<$Col
								span={14}
								xxl={{
									span: 14,
								}}
								xl={{
									span: 12,
								}}
								data-testid='select-communication-method'>
								<$Select
									placeholder=''
									className='w-100'
									data-testid='select-communication-method-options'
									showSearch={
										false
									}
									options={[
										...defaultCommunicationMethods,
										...addCommunicationMethod.options,
									]}
									allOption={
										false
									}
									optionValue='value'
									optionText='label'
									defaultValue={
										values.communicationMethod
									}
									formitem={{}}
									name='communicationMethod'
									disabled={
										metaData.data &&
										isSubCaseTransferredToCase(
											metaData.data
										)
									}
									dropdownMatchSelectWidth={
										false
									}
									dropdownRender={(
										menu: any
									) => (
										<div>
											{
												menu
											}
											<$Divider
												style={{
													margin: '4px 0',
												}}
											/>
											<div
												style={{
													display: 'flex',
													flexWrap: 'nowrap',
													padding: 8,
												}}
												data-testid='new-communication-method'>
												<Input
													size='small'
													style={{
														flex: 'auto',
														minWidth: 120,
													}}
													maxLength={
														25
													}
													value={
														addCommunicationMethod.addMethod
													}
													onChange={(
														e: any
													) =>
														setAddCommunicationMethod(
															{
																...addCommunicationMethod,
																addMethod: e
																	.target
																	.value,
															}
														)
													}
												/>
												<$Button
													type='link'
													onClick={() =>
														handleAddCommunicationMethod()
													}
													icon={
														<PlusOutlined />
													}>
													{t(
														'US.COLLECTION.CASE:NOTESANDMESSAGES.ADD'
													)}
												</$Button>
											</div>
										</div>
									)}
								/>
							</$Col>
						</$Row>

						<$Row
							gutter={16}
							className='mt-0'>
							<$Col
								span={10}
								xxl={{
									span: 10,
								}}
								xl={{
									span: 24,
								}}>
								<div
									className='d-flex align-items-center mt-2'
									data-testid='is-internal'>
									<$Switch
										size='small'
										name='internal'
										checked={
											values.internal
										}
										defaultChecked={
											values.internal
										}
										disabled={
											metaData.data &&
											isSubCaseTransferredToCase(
												metaData.data
											)
										}
									/>
									<Paragraph
										ellipsis
										className='mb-0 ml-2'>
										{t(
											'US.COLLECTION.CASE:NOTESANDMESSAGES.INTERNAL'
										)}
									</Paragraph>
								</div>
							</$Col>
							<$Col
								span={14}
								xxl={{
									span: 14,
								}}
								xl={{
									span: 24,
								}}>
								<div
									className='d-flex align-items-center mt-2'
									data-testid='debtor-notes'>
									<$Switch
										checked={
											values.copyAsDebtorNotes
										}
										size='small'
										name='copyAsDebtorNotes'
										defaultChecked={
											values.copyAsDebtorNotes
										}
										disabled={
											metaData.data &&
											isSubCaseTransferredToCase(
												metaData.data
											)
										}
									/>
									<Paragraph
										ellipsis
										className='mb-0 ml-2'>
										{t(
											'US.COLLECTION.CASE:NOTESANDMESSAGES.COPYASDEBTORNOTES'
										)}
									</Paragraph>
								</div>
							</$Col>
						</$Row>

						<$TextArea
							data-testid='case-note'
							name='caseNote'
							className='mt-3 note-message'
							placeholder={t(
								'US.COLLECTION.CASE:NOTESANDMESSAGES.TYPEANOTEHERE'
							)}
							defaultValue={
								values.caseNote
							}
							disabled={
								metaData.data &&
								isSubCaseTransferredToCase(
									metaData.data
								)
							}
						/>

						<div className='d-flex align-items-end flex-column mt-3'>
							<$Button
								type='default'
								htmlType='submit'
								onClick={
									handleSubmit
								}
								disabled={
									metaData.data &&
									isSubCaseTransferredToCase(
										metaData.data
									)
								}
								icon={
									<SaveOutlined />
								}
								data-testid='save-btn'>
								{t(
									'US.COLLECTION.COMMON:COMMON.SAVE'
								)}
							</$Button>
						</div>
					</$Form>
				</div>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, domainView } = state;
	const { currentDateFormat } = common;
	const { metaData } = domainView;

	return {
		currentDateFormat,
		metaData,
	};
};

const mapDispatchToProps = {
	save: note.save,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Note);
