import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from "service.config.js";
import {
  IExecuteReq,
  IGetTemplateReq,
  ISendEmailReq,
} from "us.collection.case/components/MessageTemplates/Interface";
import axios from "axios";
import { IMessageTemplatePatchData } from "us.collection.case/interfaces";
const htmldata = {
  communicationjobid: 1,
  templateContent: [
    `<html><head><meta http-equiv="X-UA-Compatible" content="IE=edge"></head><body style="height:11.69in;width:8.5in;overflow:hidden;"><div id="divUSCPageContent" style="position:absolute; left: 0px; top: 0px; right:0px; bottom:0px; height: 100%; width: 100%;background-position: 0px 0px;background-repeat:no-repeat;"><div style="position:absolute; left: 79px; top: 54px;overflow:visible;width:auto;height:auto "><table cellspacing="0" cellpadding="0" border="0">
<tbody style="border-width:0px">
    <tr>
        <td colspan="3" style="font-size: 7px; font-family: 'Source Sans Pro', sans-serif; color: #666;;width:96mm;vertical-align:top;height:11mm" align="left">
        <p style="vertical-align:top"><span style="font-family:Arial">Returadresse: Debia Finans AS, Nugegoda, Sri Lanka, 0000 Nugegoda</span></p>
        </td>
    </tr>
</tbody>
</table>
<table border="0" cellpadding="0" cellspacing="0" width="330">
<tr>
    <td>
    <p style="line-height:100%"><span style=";font-family:Arial;font-size:12px">POSTBOKS 96</span><br /><span style=";font-family:Arial;font-size:12px"> </span><br /><span style=";font-family:Arial;font-size:12px">6001       ÅLESUND</span></p>
    </td>
</tr>
<tr>
    <td>
    <span style="color:#000000"> </span></td>
</tr>
</table>
  </div><div style="position:absolute; left: 434px; top: 80px;overflow:visible;width:auto;height:auto "><table style="font-size: 13px; font-family: 'Source Sans Pro', sans-serif" cellspacing="0" cellpadding="0" border="0" width="300">
<tbody>
    <tr>
        <td align="right"> <img src="http://uatcloud.unicornbox.com/USLogo.png" alt="" style="margin-bottom: 8px;" width="210" height="39" /></td>
    </tr>
    <tr>
        <td style="color: #555555; font-size: 13px; font-family: 'Source Sans Pro', sans-serif" align="right"> Nugegoda, Sri Lanka</td>
    </tr>
    <tr>
        <td style="color: #555555; font-size: 13px; font-family: 'Source Sans Pro', sans-serif" align="right">0000 (Foretaksregisteret)</td>
    </tr>
    <tr>
        <td style="color: #555555;  text-decoration: none; font-size: 13px; font-family: 'Source Sans Pro', sans-serif" align="right">
        <p style="text-align:right">E-post: devbox@unicornbox.com</p>
        </td>
    </tr>
    <tr>
        <td style="color: #555555;  text-decoration: none; font-size: 13px; font-family: 'Source Sans Pro', sans-serif" align="right">
        <p style="text-align:right">Telefon: +94 2 123456</p>
        </td>
    </tr>
    <tr>
        <td style="color: #555555;  text-decoration: none; font-size: 13px; font-family: 'Source Sans Pro', sans-serif" align="right">
        <p style="text-align:right">Dato : 07.12.2021</p>
        </td>
    </tr>
</tbody>
</table>
 </div><div style="position:absolute; left: 433px; top: 509px;overflow:visible;width:auto;height:auto "><table style="color: #555555; font-size: 12px; font-family: 'Source Sans Pro', sans-serif;" cellspacing="0" cellpadding="0" border="0" width="300">
<tbody>
    <tr>
        <td><span lang="EN-GB" style='line-height: 107%; font-family: "Calibri",sans-serif; mso-ascii-theme-font: minor-latin; mso-fareast-font-family: Calibri; mso-fareast-theme-font: minor-latin; mso-hansi-theme-font: minor-latin; mso-bidi-font-family: "Times New Roman"; mso-bidi-theme-font: minor-bidi; mso-ansi-language: EN-GB; mso-fareast-language: EN-US; mso-bidi-language: AR-SA;font-size:13px'>Hovedstol</span></td>
        <td align="right" width="100"><span style="font-size:13px">1 500,00</span></td>
    </tr>
    <tr>
        <td><span style="font-size:13px">Forsinkelsesrente</span></td>
        <td align="right" width="100"><span style="font-size:13px">0,00</span></td>
    </tr>
    <tr>
        <td><span style="font-size:13px">Inkassosalær</span></td>
        <td align="right" width="100"><span style="font-size:13px">125,00</span></td>
    </tr>
    <tr>
        <td><span style="font-size:13px">Rettsgebyr</span></td>
        <td align="right" width="100"><span style="font-size:13px">0,00</span></td>
    </tr>
    <tr>
        <td><p><span style="font-size:13px">Tilleggsalær</span></p></td>
        <td align="right" width="100"><span style="font-size:13px">0,00</span></td>
    </tr>
    <tr>
        <td><span style="font-size:13px">- Innbetalt</span></td>
        <td align="right" width="100"><span style="font-size:13px">0,00</span></td>
    </tr>
    <tr>
        <td colspan="2" height="10"></td>
    </tr>
    <tr>
        <td style="font-weight: 600; color: #000; font-size: 12px;"><span style="font-size:13px">Beløp å betale</span></td>
        <td style="font-weight: 600; color: #000; font-size: 12px;" align="right"><span style="font-size:13px">1 625,00</span></td>
    </tr>
</tbody>
</table>
 </div><div style="position:absolute; left: 433px; top: 326px;overflow:visible;width:auto;height:auto "><table style="color: #000000; background-color: #fff9c4; border: 1px solid #ffd54f;  font-size: 12px;" width="300" cellspacing="0" cellpadding="0" border="0" align="center">
<tbody>
    <tr>
        <td height="5"> </td>
    </tr>
    <tr>
        <td valign="top">
        <table style="color: #000; font-size: 14px; font-family: 'Source Sans Pro', sans-serif;" width="300" cellspacing="0" cellpadding="0" border="0">
            <tbody>
                <tr>
                    <td width="10"> </td>
                    <td style="font-weight: 400; color: #000;" width="100"><span style="font-size:13px">Sak </span></td>
                    <td style="font-weight: 400; color: #000;" align="right" width="20"><span style="font-size:13px">:</span></td>
                    <td style="font-size:18px; font-weight: 600; color: #000;" align="right" width="180"><span style="font-size:13px">2795</span></td>
                    <td width="10"><span style="font-size:13px"> </span></td>
                </tr>
                <tr>
                    <td width="10"></td>
                    <td style="font-weight: 400; color: #000;" width="100"><span style="font-size:13px">Kreditor</span></td>
                    <td style="font-weight: 400; color: #000;" align="right" width="20"><span style="font-size:13px">:</span></td>
                    <td style="font-weight: 600; color: #000;" align="right" width="180"><span style="font-size:13px">Arpico</span></td>
                    <td width="10"><span style="font-size:13px"> </span></td>
                </tr>
                <tr>
                    <td width="10"></td>
                    <td style="font-weight: 400; color: #000;" width="100"><span style="font-size:13px">Forfall</span></td>
                    <td style="font-weight: 400; color: #000;" align="right" width="20"><span style="font-size:13px">:</span></td>
                    <td style="font-weight: 600; color: #000;" align="right" width="180"><span style="font-size:13px">21.12.2021</span></td>
                    <td width="10"><span style="font-size:13px"> </span></td>
                </tr>
                <tr>
                    <td width="10"><span style="font-size:13px"> </span></td>
                    <td style="font-weight: 400; color: #000;"><span style="font-size:13px">Konto nr.</span></td>
                    <td style="font-weight: 400; color: #000;" align="right"><span style="font-size:13px">:</span></td>
                    <td style="font-weight: 600; color: #000;" align="right" width="180"><span style="font-size:13px">0000 00 000</span></td>
                    <td width="10"></td>
                </tr>
                <tr>
                    <td width="10"></td>
                    <td style="font-weight: 400; color: #000;" width="100"><span style="font-size:13px">KID</span></td>
                    <td style="font-weight: 400; color: #000;" align="right" width="20"><span style="font-size:13px">:</span></td>
                    <td style="font-weight: 600; color: #000;" align="right" width="180"><span style="font-size:13px">110000279561382</span></td>
                    <td width="10"></td>
                </tr>
                <tr>
                    <td width="10"></td>
                    <td style="font-weight: 400; color: #000;" width="100"><span style="font-size:13px">Beløp å betale</span></td>
                    <td style="font-weight: 400; color: #000;" align="right" width="20"><span style="font-size:13px">:</span></td>
                    <td style="font-size:16px; font-weight: 600; color: #000;" align="right" width="180"><span style="font-size:13px">1 625,00</span></td>
                    <td width="10"><span style="font-size:13px"> </span></td>
                </tr>
<tr>
<td width="10"><span style="font-size:13px"> </span></td>
<td style="font-weight: 400; color: #000;" width="100"><span style="font-size:13px">Saksbehandler </span></td>
<td style="font-weight: 400; color: #000;" align="right" width="20"><span style="font-size:13px"> </span></td>
<td style="font-size:16px; font-weight: 600; color: #000;" align="right" width="180"><span style="font-size:13px"> Debia Demo User</span></td>
<td width="10"> </td>
</tr>
            </tbody>
        </table>
        </td>
    </tr>
</tbody>
</table> </div><div style="position:absolute; left: 76px; top: 328px;overflow:visible;width:auto;height:auto "><table style="width:350px">
<tr>
    <td style="font-family: 'Source Sans Pro', sans-serif; font-size: 14px; color: #555;">
    <p style="text-align:left">
    <span style="font-family:'Source Sans Pro', sans-serif;font-size:14px"><strong><span style="font-size:13px">Saksnr : 2795 </span><br /><span style="font-size:13px">Kreditors navn : Arpico </span></strong></span></p>
    <p style="text-align:left">
    <span style="font-family:'Source Sans Pro', sans-serif;font-size:14px"><strong><br /></strong></span></p>
    <p style="text-align:left"> Viser til begjæring sendt .<br />Vi har den (Date for last payment) mottatt betaling på kr. (Amount for last payment) i saken.<br />Ber om at betalingen tas hensyn til i videre beregning, men at saken fortsetter som tiltenkt. </p>
    <p style="text-align:left">
    <span style="font-family:'Source Sans Pro', sans-serif;font-size:13px">
    </span><span style="font-family:'Source Sans Pro', sans-serif;font-size:13px">
    </span><br />
    <span style="font-size:13px">
    </span><span style="font-family:'Source Sans Pro', sans-serif;font-size:13px">
    </span><br />
    <span style="font-size:13px">
    </span><span style="font-family:'Source Sans Pro', sans-serif;font-size:13px">
    </span><span style="font-size:13px">
    </span>
    <p style="text-align:left"><span style="font-family:'Source Sans Pro', sans-serif;font-size:13px">Med hilsen<br />
    UAT DEBIA AS<br />
    ved saksbehandler </span><br /><span style="font-family:'Source Sans Pro', sans-serif;font-size:13px"><br />
    Debia Demo User <br />
    arne.karlsson@debia.no</span><span style="font-family:'Source Sans Pro', sans-serif;font-size:13px"> </span></p>
    </p>
    </td>
</tr>
</table>
 </div></div></div>`,
  ],
};

const html = {
  communicationjobid: 2,
  templateContent: [
    ` <html><head><meta http-equiv="X-UA-Compatible" content="IE=edge"></head><body style="height:11.69in;width:8.5in;overflow:hidden;"><div id="divUSCPageContent" style="position:absolute; left: 0px; top: 0px; right:0px; bottom:0px; height: 100%; width: 100%;background-position: 0px 0px;background-repeat:no-repeat;"><div style="position:absolute; left: 79px; top: 54px;overflow:visible;width:auto;height:auto "><table cellspacing="0" cellpadding="0" border="0">
<tbody style="border-width:0px">
    <tr>
        <td colspan="3" style="font-size: 7px; font-family: 'Source Sans Pro', sans-serif; color: #666;;width:96mm;vertical-align:top;height:11mm" align="left">
        <p style="vertical-align:top"><span style="font-family:Arial">Returadresse: Debia Finans AS, Nugegoda, Sri Lanka, 0000 Nugegoda</span></p>
        </td>
    </tr>
</tbody>
</table>
<table border="0" cellpadding="0" cellspacing="0" width="330">
<tr>
    <td>
    <p style="line-height:100%"><span style=";font-family:Arial;font-size:12px">POSTBOKS 96</span><br /><span style=";font-family:Arial;font-size:12px"> </span><br /><span style=";font-family:Arial;font-size:12px">6001       ÅLESUND</span></p>
    </td>
</tr>
<tr>
    <td>
    <span style="color:#000000"> </span></td>
</tr>
</table>
  </div><div style="position:absolute; left: 434px; top: 80px;overflow:visible;width:auto;height:auto "><table style="font-size: 13px; font-family: 'Source Sans Pro', sans-serif" cellspacing="0" cellpadding="0" border="0" width="300">
<tbody>
    <tr>
        <td align="right"> <img src="http://uatcloud.unicornbox.com/USLogo.png" alt="" style="margin-bottom: 8px;" width="210" height="39" /></td>
    </tr>
    <tr>
        <td style="color: #555555; font-size: 13px; font-family: 'Source Sans Pro', sans-serif" align="right"> Nugegoda, Sri Lanka</td>
    </tr>
    <tr>
        <td style="color: #555555; font-size: 13px; font-family: 'Source Sans Pro', sans-serif" align="right">0000 (Foretaksregisteret)</td>
    </tr>
    <tr>
        <td style="color: #555555;  text-decoration: none; font-size: 13px; font-family: 'Source Sans Pro', sans-serif" align="right">
        <p style="text-align:right">E-post: devbox@unicornbox.com</p>
        </td>
    </tr>
    <tr>
        <td style="color: #555555;  text-decoration: none; font-size: 13px; font-family: 'Source Sans Pro', sans-serif" align="right">
        <p style="text-align:right">Telefon: +94 2 123456</p>
        </td>
    </tr>
    <tr>
        <td style="color: #555555;  text-decoration: none; font-size: 13px; font-family: 'Source Sans Pro', sans-serif" align="right">
        <p style="text-align:right">Dato : 07.12.2021</p>
        </td>
    </tr>
</tbody>
</table>
 </div><div style="position:absolute; left: 433px; top: 509px;overflow:visible;width:auto;height:auto "><table style="color: #555555; font-size: 12px; font-family: 'Source Sans Pro', sans-serif;" cellspacing="0" cellpadding="0" border="0" width="300">
<tbody>
    <tr>
        <td><span lang="EN-GB" style='line-height: 107%; font-family: "Calibri",sans-serif; mso-ascii-theme-font: minor-latin; mso-fareast-font-family: Calibri; mso-fareast-theme-font: minor-latin; mso-hansi-theme-font: minor-latin; mso-bidi-font-family: "Times New Roman"; mso-bidi-theme-font: minor-bidi; mso-ansi-language: EN-GB; mso-fareast-language: EN-US; mso-bidi-language: AR-SA;font-size:13px'>Hovedstol</span></td>
        <td align="right" width="100"><span style="font-size:13px">1 500,00</span></td>
    </tr>
    <tr>
        <td><span style="font-size:13px">Forsinkelsesrente</span></td>
        <td align="right" width="100"><span style="font-size:13px">0,00</span></td>
    </tr>
    <tr>
        <td><span style="font-size:13px">Inkassosalær</span></td>
        <td align="right" width="100"><span style="font-size:13px">125,00</span></td>
    </tr>
    <tr>
        <td><span style="font-size:13px">Rettsgebyr</span></td>
        <td align="right" width="100"><span style="font-size:13px">0,00</span></td>
    </tr>
    <tr>
        <td><p><span style="font-size:13px">Tilleggsalær</span></p></td>
        <td align="right" width="100"><span style="font-size:13px">0,00</span></td>
    </tr>
    <tr>
        <td><span style="font-size:13px">- Innbetalt</span></td>
        <td align="right" width="100"><span style="font-size:13px">0,00</span></td>
    </tr>
    <tr>
        <td colspan="2" height="10"></td>
    </tr>
    <tr>
        <td style="font-weight: 600; color: #000; font-size: 12px;"><span style="font-size:13px">Beløp å betale</span></td>
        <td style="font-weight: 600; color: #000; font-size: 12px;" align="right"><span style="font-size:13px">1 625,00</span></td>
    </tr>
</tbody>
</table>
 </div><div style="position:absolute; left: 433px; top: 326px;overflow:visible;width:auto;height:auto "><table style="color: #000000; background-color: #fff9c4; border: 1px solid #ffd54f;  font-size: 12px;" width="300" cellspacing="0" cellpadding="0" border="0" align="center">
<tbody>
    <tr>
        <td height="5"> </td>
    </tr>
    <tr>
        <td valign="top">
        <table style="color: #000; font-size: 14px; font-family: 'Source Sans Pro', sans-serif;" width="300" cellspacing="0" cellpadding="0" border="0">
            <tbody>
                <tr>
                    <td width="10"> </td>
                    <td style="font-weight: 400; color: #000;" width="100"><span style="font-size:13px">Sak </span></td>
                    <td style="font-weight: 400; color: #000;" align="right" width="20"><span style="font-size:13px">:</span></td>
                    <td style="font-size:18px; font-weight: 600; color: #000;" align="right" width="180"><span style="font-size:13px">2795</span></td>
                    <td width="10"><span style="font-size:13px"> </span></td>
                </tr>
                <tr>
                    <td width="10"></td>
                    <td style="font-weight: 400; color: #000;" width="100"><span style="font-size:13px">Kreditor</span></td>
                    <td style="font-weight: 400; color: #000;" align="right" width="20"><span style="font-size:13px">:</span></td>
                    <td style="font-weight: 600; color: #000;" align="right" width="180"><span style="font-size:13px">Arpico</span></td>
                    <td width="10"><span style="font-size:13px"> </span></td>
                </tr>
                <tr>
                    <td width="10"></td>
                    <td style="font-weight: 400; color: #000;" width="100"><span style="font-size:13px">Forfall</span></td>
                    <td style="font-weight: 400; color: #000;" align="right" width="20"><span style="font-size:13px">:</span></td>
                    <td style="font-weight: 600; color: #000;" align="right" width="180"><span style="font-size:13px">21.12.2021</span></td>
                    <td width="10"><span style="font-size:13px"> </span></td>
                </tr>
                <tr>
                    <td width="10"><span style="font-size:13px"> </span></td>
                    <td style="font-weight: 400; color: #000;"><span style="font-size:13px">Konto nr.</span></td>
                    <td style="font-weight: 400; color: #000;" align="right"><span style="font-size:13px">:</span></td>
                    <td style="font-weight: 600; color: #000;" align="right" width="180"><span style="font-size:13px">0000 00 000</span></td>
                    <td width="10"></td>
                </tr>
                <tr>
                    <td width="10"></td>
                    <td style="font-weight: 400; color: #000;" width="100"><span style="font-size:13px">KID</span></td>
                    <td style="font-weight: 400; color: #000;" align="right" width="20"><span style="font-size:13px">:</span></td>
                    <td style="font-weight: 600; color: #000;" align="right" width="180"><span style="font-size:13px">110000279561382</span></td>
                    <td width="10"></td>
                </tr>
                <tr>
                    <td width="10"></td>
                    <td style="font-weight: 400; color: #000;" width="100"><span style="font-size:13px">Beløp å betale</span></td>
                    <td style="font-weight: 400; color: #000;" align="right" width="20"><span style="font-size:13px">:</span></td>
                    <td style="font-size:16px; font-weight: 600; color: #000;" align="right" width="180"><span style="font-size:13px">1 625,00</span></td>
                    <td width="10"><span style="font-size:13px"> </span></td>
                </tr>
<tr>
<td width="10"><span style="font-size:13px"> </span></td>
<td style="font-weight: 400; color: #000;" width="100"><span style="font-size:13px">Saksbehandler </span></td>
<td style="font-weight: 400; color: #000;" align="right" width="20"><span style="font-size:13px"> </span></td>
<td style="font-size:16px; font-weight: 600; color: #000;" align="right" width="180"><span style="font-size:13px"> Debia Demo User</span></td>
<td width="10"> </td>
</tr>
            </tbody>
        </table>
        </td>
    </tr>
</tbody>
</table> </div><div style="position:absolute; left: 76px; top: 328px;overflow:visible;width:auto;height:auto "><table style="width:350px">
<tr>
    <td style="font-family: 'Source Sans Pro', sans-serif; font-size: 14px; color: #555;">
    <p style="text-align:left">
    <span style="font-family:'Source Sans Pro', sans-serif;font-size:14px"><strong><span style="font-size:13px">Saksnr : 2795 </span><br /><span style="font-size:13px">Kreditors navn : Arpico </span></strong></span></p>
    <p style="text-align:left">
    <span style="font-family:'Source Sans Pro', sans-serif;font-size:14px"><strong><br /></strong></span></p>
    <p style="text-align:left"> Viser til begjæring sendt .<br />Vi har den (Date for last payment) mottatt betaling på kr. (Amount for last payment) i saken.<br />Ber om at betalingen tas hensyn til i videre beregning, men at saken fortsetter som tiltenkt. </p>
    <p style="text-align:left">
    <span style="font-family:'Source Sans Pro', sans-serif;font-size:13px">
    </span><span style="font-family:'Source Sans Pro', sans-serif;font-size:13px">
    </span><br />
    <span style="font-size:13px">
    </span><span style="font-family:'Source Sans Pro', sans-serif;font-size:13px">
    </span><br />
    <span style="font-size:13px">
    </span><span style="font-family:'Source Sans Pro', sans-serif;font-size:13px">
    </span><span style="font-size:13px">
    </span>
    <p style="text-align:left"><span style="font-family:'Source Sans Pro', sans-serif;font-size:13px">Med hilsen<br />
    UAT DEBIA AS<br />
    ved saksbehandler </span><br /><span style="font-family:'Source Sans Pro', sans-serif;font-size:13px"><br />
    Debia Demo User <br />
    arne.karlsson@debia.no</span><span style="font-family:'Source Sans Pro', sans-serif;font-size:13px"> </span></p>
    </p>
    </td>
</tr>
</table>
 </div></div></div>`,
    `<head><meta http-equiv="X-UA-Compatible" content="IE=edge"></head><body style="height:11.69in;width:8.5in;overflow:hidden;"><div id="divUSCPageContent" style="position:absolute; left: 0px; top: 0px; right:0px; bottom:0px; height: 100%; width: 100%;background-position: 0px 0px;background-repeat:no-repeat;"><div style="position:absolute; left: 61px; top: 45px;overflow:visible;width:auto;height:auto "><table cellspacing='2' cellpadding='2' style='width:665px' border="0">
<tr>
    <td>
    <table cellspacing='2' cellpadding='2' style='width:665px'>
        <tr>
            <td>
            <table style="border:1px solid #d6d6d6;" cellspacing="0" cellpadding="0" border="0" width="656">
                <tbody>
                    <tr>
                        <td colspan="3" height="10"> </td>
                    </tr>
                    <tr>
                        <td colspan="3" valign="top">
                        <table border="0" cellspacing="0" cellpadding="0" width="640" style="color: #555555; font-size: 12px; font-family: 'Source Sans Pro', sans-serif;" align="center">
                            <tbody>
                                <tr>
                                    <td width="240" style="border-bottom: 1px solid #d6d6d6; font-weight: 600;">Fakturanr/delkrav:</td>
                                    <td width="200" style="border-bottom: 1px solid #d6d6d6; font-weight: 600;" align="left">Forfall:</td>
                                    <td width="200" style="border-bottom: 1px solid #d6d6d6; font-weight: 600;" align="right">Beløp</td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                    <div id='RepeatTable-CaseInvoiceDetails2'>
                                    <table width="640" border="0" style="color: #555555; font-size: 12px; font-family: 'Source Sans Pro', sans-serif;" align="center">
                                        <tbody>
                                            <tr>
                                                <td style="border-bottom: 1px solid #d6d6d6;" width="240">9966GG</td>
                                                <td style="border-bottom: 1px solid #d6d6d6;" width="200">10.12.2021</td>
                                                <td style="border-bottom: 1px solid #d6d6d6;" width="200" align="right">1 500,00</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" valign="top" align="center">
                        <table border="0" cellspacing="0" cellpadding="0" width="640" style="color: #555555; font-size: 12px; font-family: 'Source Sans Pro', sans-serif;" align="center">
                            <tbody>
                                <tr style="font-weight: 400; color: #000000;">
                                    <td width="240" height="30">Sum hovedstol:</td>
                                    <td width="200" height="30"> </td>
                                    <td width="200" height="30" align="right">1 500,00</td>
                                </tr>
                            </tbody>
                        </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            </td>
            <td valign="top">
            </td>
        </tr>
    </table>
    </td>
</tr>
<tr>
    <td>
    <br />
    <table cellspacing='2' cellpadding='2' style='width:665px'>
        <tr>
            <td>
            
            </td>
        </tr>
        <tr>
            <td>
            <table width="656" border="0" cellpadding="0" cellspacing="0" style="color: #555555; font-size: 12px; font-family: 'Source Sans Pro', sans-serif;" align="left">
                <tr style="color: #000000; font-size: 12px">
                    <td style="border-bottom: 1px solid #d6d6d6;" width="570">Total</td>
                    <td style="border-bottom: 1px solid #d6d6d6;" width="100" align="right"> 0,00</td>
                </tr>
            </table>
            </td>
        </tr>
    </table>
    </td>
</tr>
</table></div></div></div>`,
  ],
};

const executeRes = {
  communicationJobId: 1234,
  generatedMessgePath: "\\uatunicornboxBOXSystemFileUSCFiles\file1.pdf",
};

export default {
  getAll: <
    P extends {
      entityType: string;
    }
  >(
    params: P
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await versionedHttpCollection.get(
          "uscservice",
          `templatelist/${params.entityType}`,
          {},
          ServiceConfig()[`uscservice`]["templatelist"]
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  delete: <P extends { templateId: string | number }>(
    params: P
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await versionedHttpCollection.delete(
          "uscservice",
          "templatelist",
          params.templateId,
          {},
          ServiceConfig()[`uscservice`]["templatelist"]
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getById: (params: IGetTemplateReq): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await versionedHttpCollection.post(
          "uscservice",
          "templatecontent",
          { ...params },
          ServiceConfig()[`uscservice`]["templatecontent"]
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  excuteActivity: (params: IExecuteReq): Promise<any> => {
    const {
      communicationJobId,
      entityId,
      entityType,
      templateName,
      templateCategory,
      PluginName,
      HtmlContent,
    } = params;
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await versionedHttpCollection.post(
          "uscservice",
          "messagetemplate",
          {
            communicationJobId,
            entityId,
            entityType,
            templateName,
            templateCategory,
            PluginName,
            HtmlContent,
          },
          ServiceConfig()[`uscservice`]["messagetemplate"]
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  sendEmail: (params: ISendEmailReq): Promise<any> => {
    const {
      entityId,
      entityType,
      communicationJobId,
      mesageContent,
      emailOrTelNo,
      isPaymentInfoSMS,
      isGiroDocument,
      dueDate,
      senderType,
      messgeType,
      attachmentPaths,
      documentType,
      subject,
    } = params;
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await versionedHttpCollection.post(
          "uscservice",
          "messages",
          {
            entityId,
            entityType,
            communicationJobId,
            mesageContent,
            emailOrTelNo,
            isPaymentInfoSMS,
            isGiroDocument,
            dueDate,
            senderType,
            messgeType,
            attachmentPaths,
            documentType,
            subject,
          },
          ServiceConfig()[`uscservice`]["messages"]
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  pinUnpinMessageTemplate: (
    params: IMessageTemplatePatchData | any
  ): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        let templateLists;
        const { data } = await versionedHttpCollection.patch(
          "uscservice",
          `templatelist/${params.templateId}`,
          params.pinStatusObject,
          ServiceConfig()[`uscservice`]["templatelist"]
        );

        if (!data.isError) {
          templateLists = await versionedHttpCollection.get(
            "uscservice",
            `templatelist/${params.entityType}`,
            {},
            ServiceConfig()[`uscservice`]["templatelist"]
          );
        }

        resolve({ data, templateLists });
      } catch (error) {
        reject(error);
      }
    });
  },
};
