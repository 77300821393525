import { IMessageTemplateItem } from "us.collection.case/interfaces/MessageTemplate/IMessageTemplate";
import { IFilter } from "us.collection.case/components/MessageTemplates/Interface";
import _ from 'lodash'
import { appInsights } from "us.helper";
import { LevelType, EntityType } from 'us.collection/constants';

/**
 * filter by document type and name
 * @param data array of message template objects
 * @param filter filter values
 * @returns fillered data array
 */
export const filterMessageTemplates = (data: Array<IMessageTemplateItem>, filter: IFilter): Array<IMessageTemplateItem> => {
    const { type, name } = filter
    try {
        if (type === "all") {
            return _.orderBy(data.filter(
                ({ templateName }: IMessageTemplateItem) => {
                    return templateName
                        .toLowerCase()
                        .includes(name.toLowerCase())
                }
            ), ["templateName"], ["asc"]);
        } else {
            return _.orderBy(data.filter(
                ({ templateName, plugin }: IMessageTemplateItem) => {
                    return plugin.toLowerCase() === type &&
                        templateName
                            .toLowerCase()
                            .includes(name.toLowerCase())
                }
            ), ["templateName"], ["asc"]);
        }
    } catch (error) {
        appInsights.trackException(`MessageTemplate FIlter Exeception - ${error}`)
        return [];
    }
};

/**
 * Get entity type for active domain view tab
 * @param entityType current tab entity type
 * @returns entity type for message template payload
 */
export const getEntityTypeForPayload = (entityType: string | undefined): string => {
  try {
    switch (entityType) {
      case EntityType.CASE:
        return LevelType.CASE;
      case EntityType.SUBCASE:
        return LevelType.SUBCASE;
      case EntityType.AR:
        return LevelType.AR;
      default:
        return "";
    }
  } catch (error) {
    return "";
  }
};