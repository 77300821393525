import React, { useState } from "react";
import { $Button, $Popover } from "us.common/components";
import { MoreOutlined } from "us.icons";
import { GridMoreColumnContent } from "./Components";
import { IGridMoreColumn } from "./Interfaces";

export const GridMoreColumn: React.FC<IGridMoreColumn> = (props) => {
  const { index, record } = props;
  const [popVisible, setPopVisibile] = useState<number>(-1);
  return (
    <$Popover
      placement="right"
      content={<GridMoreColumnContent record={record} setPopVisibile={setPopVisibile} />}
      destroyTooltipOnHide
      trigger="click"
      visible={index == popVisible}
      onVisibleChange={(visbile: boolean) => setPopVisibile(visbile ? index : -1)}
    >
      <$Button icon={<MoreOutlined />} size="small" />
    </$Popover>
  );
};
