import { IEntity, PhoneNumber } from 'us.common/interfaces';
import moment from 'moment';
import _ from 'lodash';
import { appInsights } from 'us.helper';
import { initPhoneNumberFields } from '../Constants';
import { PhoneNumberTypes } from 'us.common/constants';

export const formInitialValue = (entityDetails: IEntity) => {
	try {
		return {
			...entityDetails,
			...getPhoneNoFields(
				entityDetails.phoneNumbers as Array<PhoneNumber>
			),
			personNo:
				entityDetails.debtorType === 'P'
					? entityDetails.idNo.trim() === ''
						? ''
						: formatPersonNo(
								entityDetails.idNo
						  )
					: '',
			companyNo:
				entityDetails.debtorType === 'N'
					? formatOrganizationNo(
							entityDetails.idNo
					  )
					: '',
			countryCode: entityDetails.countryCode ?? 'NO',
			addrUnknown:
				entityDetails.debtorType === 'P'
					? entityDetails.zipCode?.length !== 0
						? false
						: true
					: entityDetails.zipCode?.length !== 0 &&
					  entityDetails.zipName?.length !== 0
					? false
					: true,
			postalAreaVisible: false,
			popConfirmVisible: false,
			isExistingPostalCode:
				entityDetails.isExistingPostalCode ?? true,
			isExistingMunicipalityCode:
				entityDetails.isExistingMunicipalityCode ??
				true,
		};
	} catch (error) {
		appInsights.trackException(
			`Error in form initial values in Debtor Information. - ${error}`
		);
		return { ...entityDetails };
	}
};
/**
 * Person No format with space
 * @param value
 * @returns
 */
const formatPersonNo = (value: any) => {
	try {
		if (!value) {
			return value;
		}
		value = value.replace(/ /g, '');
		if (
			value.length === 6 ||
			(value.length >= 9 && value.length <= 11)
		) {
			const parts = value.match(/.{1,6}/g);
			return parts.join(' ');
		} else {
			return value;
		}
	} catch (error) {
		appInsights.trackException(
			`Error in function of Person No format with space in Debtor Information. - ${error}`
		);
		return value;
	}
};
/**
 * Organization number format
 * @param value
 * @returns
 */
const formatOrganizationNo = (value: any) => {
	try {
		if (!value) {
			return '';
		}
		value = value.replace(/ /g, '');
		if (value.length === 9) {
			const parts = value.match(/.{1,3}/g);
			return parts.join(' ');
		} else {
			return value;
		}
	} catch (error) {
		appInsights.trackException(
			`Error in function of Organization number format in Debtor Information. - ${error}`
		);
		return value;
	}
};
/**
 * Validate person number
 * @param personNumber person number
 * @returns {Object}
 */
export const validatePersonNo = (
	personNumber: number | string
): {
	isValid: boolean;
	birthDate: string;
} => {
	let response = {
		isValid: false,
		birthDate: '',
	};
	try {
		let personNo = personNumber.toString();
		let validLength = validatePersonNoLength(personNo);

		if (validLength.isVaild) {
			let bDate = generateBirthDate(personNo);
			if (validLength.numberLength >= 9) {
				response = {
					isValid:
						Boolean(+bDate?.date) &&
						bDate?.date.getDate() ==
							bDate.day &&
						bDate.date.getMonth() + 1 ==
							bDate.month,
					birthDate: bDate.date.toString(),
				};
			} else if (validLength.numberLength === 6) {
				response = {
					isValid:
						Boolean(+bDate?.date) &&
						bDate?.date.getDate() ==
							bDate.day &&
						bDate.date.getMonth() + 1 ==
							bDate.month,
					birthDate: bDate.date.toString(),
				};
			}
		}
		if (
			moment().endOf('day') < moment(response.birthDate) &&
			response.isValid
		) {
			return {
				isValid: false,
				birthDate: '',
			};
		}
		return response;
	} catch (error) {
		appInsights.trackException(
			`Error in function of Validate person number in Debtor Information. - ${error}`
		);
		return response;
	}
};

/**
 * Generate birthdate accoding to the person number
 * @param personNo
 * @returns
 */
const generateBirthDate = (personNo: string) => {
	let day = parseInt(personNo.substring(0, 2));
	let month = parseInt(personNo.substring(2, 4));
	let year = personNo.substring(4, 6);
	let date: Date = new Date(moment().format());
	let yearCode: number = 0;

	try {
		if (personNo.length >= 9) {
			let centryCode = personNo.substring(6, 9);
			if (
				parseInt(centryCode) >= 0 &&
				parseInt(centryCode) <= 499
			) {
				yearCode = parseInt(`19${year}`);
			} else if (
				parseInt(centryCode) >= 500 &&
				parseInt(centryCode) <= 999
			) {
				yearCode = parseInt(`20${year}`);
			}
			date = new Date(yearCode, +month - 1, day);
		} else if (personNo.length === 6) {
			let currentYear = new Date()
				.getFullYear()
				.toString()
				.substring(0, 2);
			let assumeYear = parseInt(currentYear + year);
			if (assumeYear > new Date().getFullYear()) {
				assumeYear = assumeYear - 100;
			}
			date = new Date(assumeYear, +month - 1, day);
		}
		return {
			date,
			month,
			day,
		};
	} catch (error) {
		appInsights.trackException(
			`Error in function of generateBirthDate in Debtor Information. - ${error}`
		);
		return {
			date,
			month,
			day,
		};
	}
};

/**
 * person number length validation
 * @param personNo person number
 * @returns
 */
const validatePersonNoLength = (
	personNo: string
): {
	isVaild: boolean;
	numberLength: number;
} => {
	let response = {
		isVaild: false,
		numberLength: 0,
	};
	if (personNo.length > 11) {
		response = {
			isVaild: false,
			numberLength: personNo.length,
		};
	} else if (personNo.length === 11) {
		response = {
			isVaild: true,
			numberLength: personNo.length,
		};
	} else if (personNo.length >= 9) {
		response = {
			isVaild: true,
			numberLength: personNo.length,
		};
	} else if (personNo.length === 6) {
		response = {
			isVaild: true,
			numberLength: personNo.length,
		};
	} else {
		response = {
			isVaild: false,
			numberLength: personNo.length,
		};
	}
	return response;
};

/**
 * @function
 * @description get telephone number fields for the form
 * @param {Array<PhoneNumber>} phoneNumbers phone numbers list
 * @returns {initPhoneNumberFields} telephone fields
 */
const getPhoneNoFields = (
	phoneNumbers: Array<PhoneNumber>
): typeof initPhoneNumberFields => {
	try {
		let phoneNumberFields = { ...initPhoneNumberFields };

		phoneNumbers?.map(({ type, number }: PhoneNumber) => {
			if (Object.values(PhoneNumberTypes).includes(type)) {
				phoneNumberFields[type] = number;
			}
		});

		return phoneNumberFields;
	} catch (error) {
		return initPhoneNumberFields;
	}
};
