import { StatusHistory } from "us.collection/reducers/Workflow/Interfaces"

/**
 * @description Get default key for selected tab
 * @param statusList - History status list
 * @returns - Selected key
 */
export const getDefaultTabKey = (statusList: Array<StatusHistory>): Array<string> => {
  try {
    if (statusList.length > 0) {
      return [statusList[0]?.stackId?.toString()];
    }
    return ["-1"];
  } catch {
    return ["-1"];
  }
};
