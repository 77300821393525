import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import { connect } from 'react-redux';

import { BoxIcons, IconTypes, LoadingOutlined } from 'us.icons';
import './Style.scss';
import Common from 'us.common';
import {
	ClaimOfPayment,
	DebtorObjection,
	WaitingForCreditorResponse,
	AddBureauLevelObjection,
	CancelObjection,
} from 'us.collection.case/components/Detail/ObjectionAndAgreement/Components';
import { ObjectionAndAgreementsAction } from 'us.collection.case/actions';
import { IObjection } from 'us.collection.case/components/Interfaces/Objection';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { RootState } from 'us.helper/types';
import { EntityTypes } from 'us.collection.case/constants';
import { ObjectionAndAgreementDrawerType } from 'us.collection.case/constants/Actions';

const { $Tooltip, $Button, $Drawer, $MessageBox, $Row, $Col } =
	Common.Components;

const ObjectionAndAgreement: React.FC<IObjection> = (props) => {
	const { t } = useTranslation();
	const [
		waitingForCreditorResponseVisible,
		setwaitingForCreditorResponseVisible,
	] = useState<boolean>(false);
	const [
		AddBureauLevelObjectionVisible,
		setAddBureauLevelObjectionVisible,
	] = useState<boolean>(false);

	const {
		metaData,
		drawer,
		accountSummeryWidgetData,
		history,
		claimOfPayment,
		debtorObjection,
		updateDrawer,
	} = props;

	const { location } = history;

	const { caseNo, isObjected } = metaData.data ?? {};

	const handleDrawer = (
		visible: boolean,
		type: ObjectionAndAgreementDrawerType,
		title: string
	) => {
		updateDrawer && updateDrawer({ type, visible, title });
	};

	const waitingForCreditorResponseClose = () => {
		setwaitingForCreditorResponseVisible(false);
	};

	const AddBureauLevelObjectionClose = () => {
		setAddBureauLevelObjectionVisible(false);
	};

	const navigate = (url: string): void => {
		window.scrollTo(0, 0);
		history.push({
			...location,
			pathname: `/case/${caseNo}/${url}`,
		});
	};

	const handlePartPayment = () => {
		if (
			accountSummeryWidgetData.data?.paymentAgreementBasic
				?.total
		) {
			$MessageBox(
				'warning',
				'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.PAYMENT_AGREEMENT_EXISTS_FOR_THIS_CASE',
				'',
				''
			);
		} else {
			navigate('part-payments');
		}
	};

	const handlePaymentAgreement = () => {
		if (
			accountSummeryWidgetData.data?.partPaymentBasic
				?.totalInstallments
		) {
			$MessageBox(
				'warning',
				'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.PART_PAYMENT_EXISTS_FOR_THIS_CASE',
				'',
				''
			);
		} else {
			navigate('agreements');
		}
	};

	return (
		<Formik
			initialValues={{
				searchVal: '',
			}}
			onSubmit={() => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...restProps
			}: any) => (
				<>
					<$Row gutter={16}>
						<$Col
							xxl={{ span: 12 }}
							xl={{ span: 10 }}>
							<div className='case-action-list'>
								<div className='case-action-list-header'>
									<span>
										{t(
											'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.OBJECTION'
										)}
									</span>
								</div>
								<div className='d-flex flex-wrap case-action-list-body case-action-objections'>
									<$Tooltip
										placement='top'
										title={t(
											'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.CLAIM_OF_PAYMENT'
										)}>
										<$Button
											type='default'
											disabled={
												isObjected ||
												claimOfPayment?.isLoading ||
												debtorObjection?.isLoading ||
												(metaData.data &&
													isSubCaseTransferredToCase(
														metaData.data
													))
											}
											onClick={() =>
												handleDrawer(
													true,
													ObjectionAndAgreementDrawerType.CLAIM_OF_PAYMENT,
													'US.COLLECTION.CASE:OBJECTION_AND_AGREEMENT.CLAIM_OF_PAYMENT'
												)
											}>
											{!claimOfPayment?.isLoading && (
												<BoxIcons
													type={
														IconTypes.BOX_ICON
													}
													name='claimOfPayment'
												/>
											)}
											{claimOfPayment?.isLoading && (
												<LoadingOutlined
													spin
												/>
											)}
										</$Button>
									</$Tooltip>

									<$Tooltip
										placement='top'
										title={t(
											'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.DEBTOR_OBJECTION'
										)}>
										<$Button
											type='default'
											disabled={
												isObjected ||
												claimOfPayment?.isLoading ||
												debtorObjection?.isLoading ||
												(metaData.data &&
													isSubCaseTransferredToCase(
														metaData.data
													))
											}
											onClick={() =>
												handleDrawer(
													true,
													ObjectionAndAgreementDrawerType.DEBTOR_OBJECTION,
													'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.DEBTOR_OBJECTION'
												)
											}>
											{!debtorObjection?.isLoading && (
												<BoxIcons
													type={
														IconTypes.BOX_ICON
													}
													name='debtorObjection'
												/>
											)}
											{debtorObjection?.isLoading && (
												<LoadingOutlined
													spin
												/>
											)}
										</$Button>
									</$Tooltip>
									<CancelObjection />
								</div>
							</div>
						</$Col>
						<$Col
							xxl={{ span: 12 }}
							xl={{ span: 14 }}>
							<div className='case-action-list'>
								<div className='case-action-list-header'>
									<span>
										{t(
											'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.PAYMENT_AGREEMENT'
										)}
									</span>
								</div>
								<div className='d-flex flex-wrap case-action-list-body case-action-pay-agreement'>
									<$Tooltip
										placement='top'
										title={t(
											'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.DEBTOR_AGREED_TO_PAY'
										)}>
										<$Button
											type='default'
											data-testid='dashboard-payment-agreement'
											disabled={
												metaData.data &&
												isSubCaseTransferredToCase(
													metaData.data
												)
											}
											onClick={
												handlePaymentAgreement
											}>
											<BoxIcons
												type={
													IconTypes.BOX_ICON
												}
												name='debtorAgreedToPay'
											/>
										</$Button>
									</$Tooltip>

									{accountSummeryWidgetData
										.data
										?.case
										?.caseType ==
										EntityTypes.CASE && (
										<$Tooltip
											placement='top'
											title={t(
												'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.PART_PAYMENT'
											)}>
											<$Button
												type='default'
												onClick={
													handlePartPayment
												}>
												<BoxIcons
													type={
														IconTypes.BOX_ICON
													}
													name='downPayment'
												/>
											</$Button>
										</$Tooltip>
									)}
								</div>
							</div>
						</$Col>
					</$Row>

					<$Drawer
						title={t(drawer.title)}
						width={800}
						visible={drawer.visible}
						onClose={() =>
							handleDrawer(
								false,
								ObjectionAndAgreementDrawerType.NONE,
								''
							)
						}
						destroyOnClose>
						{drawer.type ==
							ObjectionAndAgreementDrawerType.CLAIM_OF_PAYMENT && (
							<ClaimOfPayment
								onClose={() =>
									handleDrawer(
										false,
										ObjectionAndAgreementDrawerType.NONE,
										''
									)
								}
							/>
						)}
						{drawer.type ==
							ObjectionAndAgreementDrawerType.DEBTOR_OBJECTION && (
							<DebtorObjection
								onClose={() =>
									handleDrawer(
										false,
										ObjectionAndAgreementDrawerType.NONE,
										''
									)
								}
							/>
						)}
					</$Drawer>

					<$Drawer
						title={t(
							'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.WAITING_FOR_CREDITOR_RESPONSE'
						)}
						width={600}
						visible={
							waitingForCreditorResponseVisible
						}
						onClose={
							waitingForCreditorResponseClose
						}>
						<WaitingForCreditorResponse
							onClose={
								waitingForCreditorResponseClose
							}
							{...props}
						/>
					</$Drawer>

					<$Drawer
						title={t(
							'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.ADD_BUREAU_LEVEL_OBJECTION'
						)}
						width={600}
						visible={
							AddBureauLevelObjectionVisible
						}
						onClose={
							AddBureauLevelObjectionClose
						}>
						<AddBureauLevelObjection
							onClose={
								AddBureauLevelObjectionClose
							}
							{...props}
						/>
					</$Drawer>
				</>
			)}
		</Formik>
	);
};
const mapStateToProps = (state: RootState) => {
	const {
		case: caseValue,
		dashboard,
		objectionAndAgreements,
		domainView,
	} = state;
	const { caseNo, caseType } = caseValue;
	const { accountSummeryWidgetData } = dashboard;
	const { drawer, debtorObjection, claimOfPayment } =
		objectionAndAgreements;
	const { metaData } = domainView;
	return {
		caseNo,
		caseType,
		metaData,
		accountSummeryWidgetData,
		claimOfPayment,
		debtorObjection,
		drawer,
	};
};

const { debtorObjection } = ObjectionAndAgreementsAction;

const mapDispatchToProps = {
	updateDrawer: debtorObjection.openDrawer,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ObjectionAndAgreement)
);
