import { validatePersonNo } from 'us.collection.case/functions';
import * as Yup from 'yup';
import {
	organizationNoValidate,
	personNoValidation,
} from 'us.collection/functions';
import { DEFAULT_COUNTRY_CODE } from 'us.common/constants';

interface IEmployer extends Yup.MixedSchema {
	otherPartyType: string;
	lastName: string;
	otherpartyRoleType: string;
	country: string;
	zipCode: string;
	emailAddress: string;
	telephoneNo: number;
}

const EmployerSchema = Yup.object<IEmployer>().shape({
	otherPartyType: Yup.string().required(
		'US.COLLECTION.VALIDATIONS:REQUIRED.TYPE_IS_REQUIRED'
	),
	lastName: Yup.string().required(
		'US.COLLECTION.VALIDATIONS:REQUIRED.LAST_NAME_IS_REQUIRED'
	),
	zipCode: Yup.string()
		.required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.POSTAL_CODE_IS_REQUIRED'
		)
		.when('country', {
			is: (countryCode) =>
				countryCode === DEFAULT_COUNTRY_CODE,
			then: Yup.string().matches(
				/^\d+$/,
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			),
		}),
	otherpartyRoleType: Yup.string().required(
		'US.COLLECTION.VALIDATIONS:REQUIRED.ROLE_TYPE_IS_REQUIRED'
	),
	country: Yup.string().required(
		'US.COLLECTION.VALIDATIONS:REQUIRED.COUNTRY_IS_REQUIRED'
	),
	nin: Yup.string().when('otherPartyType', {
		is: (val) => val === 'N',
		then: Yup.string()
			.typeError(
				'US.COLLECTION.VALIDATIONS:REQUIRED.FIELD_VALUE_IS_IN_INCORRECT_FORMAT'
			)
			.test(
				'organizationNo',
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
				(val: any) => {
					val =
						typeof val == 'undefined'
							? ''
							: val.toString();
					return organizationNoValidate(val);
				}
			),
		otherwise: Yup.string()
			.typeError(
				'US.COLLECTION.VALIDATIONS:REQUIRED.FIELD_VALUE_IS_IN_INCORRECT_FORMAT'
			)
			.test(
				'personNo',
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
				(value: any) => {
					let isValidDate = true;
					let val = value
						?.toString()
						.replaceAll(/\D/g, '');
					if (val) {
						if (isNaN(val)) {
							isValidDate = false;
						} else {
							isValidDate =
								validatePersonNo(
									val
								).isValid;
						}
					} else {
						val = '';
					}
					return (
						isValidDate ||
						val?.toString() === ''
					);
				}
			)
			.test(
				'personNo',
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
				(val: any) => {
					val =
						typeof val == 'undefined'
							? ''
							: val.toString();
					return personNoValidation(val);
				}
			),
	}),
	emailAddress: Yup.string().email(
		'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
	),
	telephoneNo: Yup.string()
		.typeError(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
		)
		.matches(
			/^\d{4,15}$/g,
			'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS'
		)
		.nullable(),
});

export default EmployerSchema;
