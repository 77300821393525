import React, { useState, useEffect } from "react";
import { RealEstate } from "../RealEstate";
import { PersonalEffects } from "../PersonalEffects";
import { DocumentOfTitle } from "../DocumentOfTitle";
import { Vehicle } from "../Vehicle";
import { Financial } from "../Financial";
import { AddAssetViewType } from "../types";
import { AssetTypes } from "us.collection.case/constants";
import { useTranslation } from "react-i18next";
import Common from "us.common";
const { $Radio } = Common.Components;
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { IReturnAction, IRootState } from "us.collection/interfaces";
import { Employer } from "../Employer";
import { AssetsAction, OtherPartyAction } from "us.collection.case/actions";

interface IAddAssets {
  isRadioButtons?: boolean;
  addAssetType: string;
  isPreviousBreadcrumbEnable?(): any;
  isPreviousBreadcrumbDisable?(): any;
  handleAddEnforcement?(): any;
  onSearchAsset?(): any;
  enforcementEmployeeBreadcrumb: boolean;
  enforcementPostalBreadcrumb: boolean;
  assetType: string;
  showOnly: boolean;
  drawerInfo: any;
  ResetPostalDetails: (<T>(data: object | T[]) => IReturnAction<T>) | undefined;
  ResetEmployerDetails: (<T>(data: object | T[]) => IReturnAction<T>) | undefined;
}

const ValidationSchema = Yup.object({});

const AddAssets: React.FC<IAddAssets> = (props) => {
  const { t } = useTranslation();

  const { showOnly, ResetPostalDetails, ResetEmployerDetails } = props;

  const [postalCodeDetail, setPostalCodeDetail] = useState<AddAssetViewType>({
    realstatePostalCodeData: undefined,
    personalEffectsPostalCodeData: undefined,
    salaryPostalCodeData: undefined,
    otherPostalCodeData: undefined,

    realStateMunipalCodeData: undefined,
    personalEffectsMunicipalCodeData: undefined,
    salaryMunicipalCodeData: undefined,
    otherMunicipalCodeData: undefined,

    documentOfTitleMunicipalCodeData: undefined,
  });

  const [assetView, setAssetView] = useState<string>("");

  useEffect(() => {
    setAssetView(props.assetType);
  }, []);

  const GetOptions = () => {
    const options = [
      {
        label: t("US.COLLECTION.CASE:ADDASSETS.REALESTATE"),
        value: "REAL ESTATE"
      },
      {
        label: t("US.COLLECTION.CASE:ADDASSETS.PERSONALEFFECTS"),
        value: "PERSONAL EFFECT",
      },
      {
        label: t("US.COLLECTION.CASE:ADDASSETS.EMPLOYER"),
        value: "EMPLOYER",
      },
      {
        label: t("US.COLLECTION.CASE:ADDASSETS.DOCUMENTOFTITLE"),
        value: "DOCUMENT OF TITLE",
      },
      {
        label: t("US.COLLECTION.CASE:ADDASSETS.VEHICLE"),
        value: "VEHICLE",
      },
      {
        label: t("US.COLLECTION.CASE:ADDASSETS.FINANCIAL"),
        value: "FINANCIAL",
      },
    ];

    return options.map((option) => {
      if (props.drawerInfo.isEdit) {
        if (option.value === props.assetType.toUpperCase()) {
          return { ...option, disabled: false };
        } else {
          return { ...option, disabled: true };
        }
      } else {
        return option;
      }
    });
  };

  const handleAssetChange = (assetName : string) =>{
    if(assetName.toUpperCase() == AssetTypes.EMPLOYER.toUpperCase()){
      ResetEmployerDetails && ResetEmployerDetails({})
    }
    else{
      ResetPostalDetails && ResetPostalDetails({})
    }
    setAssetView(assetName);
  }

  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      validationSchema={ValidationSchema}
      onSubmit={()=>{}}
      validateOnChange
      validateOnBlur
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <$Radio
            //size="small"
            name="AssetsType"
            radioButton="true"
            optionValue="value"
            optionContent={(item: any, index: number) => item.label}
            onChange={(e: any) =>  handleAssetChange(e.target.value)}
            className="d-flex mb-3"
            optionStyle="flex-fill text-center"
            value={assetView.toUpperCase()}
            options={GetOptions()}
            optionType="button"
            buttonStyle="default"
          />
          {assetView.toUpperCase() == AssetTypes.REAL_ESTATE.toUpperCase() && (
            <RealEstate
              postalCodeDetails={postalCodeDetail.realstatePostalCodeData}
              isEditing={false}
              asset={undefined}
              showOnly={showOnly}
            />
          )}
          {assetView.toUpperCase() == AssetTypes.PERSONAL_EFFECT.toUpperCase() && (
            <PersonalEffects
              isEditing={false}
              postalCodeDetails={postalCodeDetail.personalEffectsPostalCodeData}
              showOnly={showOnly}
            />
          )}
          {assetView.toUpperCase() == AssetTypes.EMPLOYER.toUpperCase() && (
            <Employer showOnly={showOnly}/>
          )}
          {assetView.toUpperCase() == AssetTypes.DOCUMENT_OF_TITLE.toUpperCase() && (
            <DocumentOfTitle
              isEditing={false}
              postalCodeDetails={postalCodeDetail.salaryPostalCodeData}
              showOnly={showOnly}
            />
          )}
          {assetView.toUpperCase() == AssetTypes.VEHICLE.toUpperCase() && <Vehicle isEditing={false} showOnly={showOnly} />}
          {assetView.toUpperCase() == AssetTypes.FINANCIAL.toUpperCase() && (
            <Financial
              isEditing={false}
              postalCodeDetails={postalCodeDetail.otherPostalCodeData}
              showOnly={showOnly}
            />
          )}
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { assets } = state;
  const { drawerInfo } = assets;
  return {
    drawerInfo
  };
};

const mapDispatchToProps = {
  ResetPostalDetails: AssetsAction.postalDetails.reset,
  ResetEmployerDetails: OtherPartyAction.postalDetails.reset
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAssets);
