import _ from "lodash";
import { getAssetsOption } from "us.collection.case/functions";
import { IEnforcement } from "us.collection.case/interfaces";
import { appInsights } from "us.helper";

/**
 * @function
 * @description update enforced item values by selected asset detail
 * @param {any} record asset details
 * @param {number} index index of enforced item
 * @param {any} enforcedItems enforced item's details
 * @returns {Array<any>}
 */
export const onSelectAsset = (
  record: any,
  index: number,
  enforcedItems: any
): Array<any> => {
  try {
    enforcedItems[index] = {
      ...enforcedItems[index],
      ...record,
      ...{
        enforcementType: "",
        enforcementTypeId: "",
        assetTypeId: "",
      },
    };
    return enforcedItems;
  } catch (error) {
    appInsights.trackException(
      `Error in select asset in Add Enforcement view. - ${error}`
    );
    return enforcedItems;
  }
};

/**
 * @function
 * @description update enforced item values by selected asset detail in dropdown
 * @param {any} enforcedItems enforced item details
 * @param {number} index index of the selected enforced item
 * @param {Array<any>} assets array of asset
 * @param {any} selectedAsset selected asset detail
 * @param {string} key unique key
 * @returns {Array<any>}
 */
export const selectAssetOnDropDown = (
  enforcedItems: any,
  index: number,
  assets: Array<any>,
  selectedAsset: any,
  key: string
): Array<any> => {
  try {
    enforcedItems[index] = {
      ...enforcedItems[index],
      ..._.find(getAssetsOption(assets, selectedAsset, enforcedItems), {
        key,
      }),
      ...{
        enforcementType: "",
        enforcementTypeId: "",
        assetTypeId: "",
      },
    };
    return enforcedItems;
  } catch (error) {
    appInsights.trackException(
      `Error in select asset in dropdown in Add Enforcement view. - ${error}`
    );
    return enforcedItems;
  }
};

/**
 * @function
 * @description update enforced item values by selected enforcement type in dropdown
 * @param {any} enforcedItems enforced item details
 * @param {number} index index of the selected enforced item
 * @param {Array<any>} enforcementTypes enforcement types list
 * @param {string} enforcementTypeId selected enforcement type id
 * @returns {Array<any>}
 */
export const selectEnforcementTypeOnDropDown = (
  enforcedItems: any,
  index: number,
  enforcementTypes: Array<any>,
  enforcementTypeId: string
): Array<any> => {
  try {
    enforcedItems[index] = {
      ...enforcedItems[index],
      ..._.find(enforcementTypes, {
        enforcementTypeId,
      }),
      enforcementType: _.find(enforcementTypes, {
        enforcementTypeId,
      })["enforcementTypeName"],
    };
    return enforcedItems;
  } catch (error) {
    appInsights.trackException(
      `Error in select enforcement type in dropdown in Add Enforcement view. - ${error}`
    );
    return enforcedItems;
  }
};

/**
 * @function
 * @description extract case balance values from enforcement detail
 * @param {IEnforcement} enforcement selected enforcement detail
 * @returns
 */
export const extractCaseBalancesFromEnforcement = (
  enforcement: IEnforcement
) => {
  try {
    const {
      enforcementId,
      referenceId,
      journalId,
      registrationDate,
      executedDate,
      obsoleteDate,
      comment,
      lastModificedDate,
      lastModifiedUser,
      enforcedItems,
      ...caseBalances
    } = enforcement;
    return caseBalances;
  } catch (error) {
    return {};
  }
};
