
import { CREDITOR_CASES_INITIAL_VALUES } from "us.collection.creditor/constants";
import { IInitialState } from "us.collection.creditor/reducers/CreditorCases/Interfaces";

export const initialState: IInitialState = {
    caseList: {
        data : [],
        isLoading: true
    },
}

