import moment from "moment";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  DocumentType,
  IconType,
} from "us.collection.routines-and-activities/constants";
import { NoteType, NoteSource } from "us.collection/constants";
import {
  getIconByActivityType,
  getStyleTermByExecutedStatus,
  getIconForUploadDocument
} from "us.collection.routines-and-activities/functions";
import Common from "us.common";
import { $Tag } from "us.common/components";
import { getUSCVersionName } from "us.common/functions";
import { BoxIcons, IconTypes, IconNames } from "us.icons";

const { $Tooltip, $Popover, $Button, $DateTimeLabel } = Common.Components;

type HistoryItemProps = {
  item: any;
  onPress: () => void;
  onPressIcon: (type: any, isXmlPdf?: boolean) => void;
};

const HistoryItem: React.FC<HistoryItemProps> = ({
  item,
  onPress,
  onPressIcon,
}) => {
  const {
    channel,
    name,
    date,
    user,
    isSuccess,
    executedStatus,
    type,
    noteType,
    source,
    activityType,
    isPrinted,
	notified,
    isAttachmentAvailable,
    version,
    isUploadDocument,
    isWorkflowHistory,
  } = item;
  const { t } = useTranslation();
  let styleTerm: string = isSuccess
    ? getStyleTermByExecutedStatus(executedStatus)
    : IconType.ERROR;
  return (
    <Fragment>
      {channel && channel.length > 0 && (
        <div className="cl-item case-log">
          <div className="cl-actions">
          
              <$Button
                onClick={onPress}
                type="link"
                size="small"
                data-testid="dashboard-history-channel"
                className="row-icon row-icon-delivered"
              >
                     <BoxIcons type={IconTypes.BOX_ICON} name="global" />
              </$Button>
          
          </div>
          <div className="cl-details">
            <span className="cl-title">
              <a onClick={onPress}>{name}</a>
            </span>
            <span className="cl-desc">
              {moment.utc(date).isValid() &&
                moment.utc(date).isAfter(moment.utc("0001-01-01")) && (
                  <$DateTimeLabel value={date} />
                )}
              {user && ` | ${user}`}
            </span>
          </div>
        </div>
      )}
      {type === DocumentType.TEXT && noteType !== NoteType.CP_NOTE && !isUploadDocument && (
        <div className={`cl-item case-log ${!notified ? " unread-note" : ""}`}>
          <div className="cl-actions">
            <$Popover
              overlayClassName="history-note-preview"
              placement="right"
              title={t("US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.NOTE")}
              content={name}
              trigger="hover"
            >
              <$Button
                type="link"
                size="small"
                className="row-icon row-icon-note"
              >
                <BoxIcons type={IconTypes.BOX_ICON} name="case-note" />
              </$Button>
            </$Popover>
          </div>
          <div className="cl-details">
            <span className="cl-title">
              <a onClick={onPress} data-testid="dashboard-history-noteOpen">
                {name}
              </a>
            </span>
            <span className="cl-desc">
              {moment.utc(date).isValid() &&
                moment.utc(date).isAfter(moment.utc("0001-01-01")) && (
                  <$DateTimeLabel value={date} />
                )}
              {user && ` | ${user}`}
            </span>
          </div>
        </div>
      )}
      {isUploadDocument && (
        <>
          <div className="cl-item case-log">
            <div className="cl-actions">
              <$Tooltip placement="top" title={type && type.toUpperCase()}>
                <$Button
                  onClick={() => onPressIcon(DocumentType.PDF)}
                  type="link"
                  size="small"
                  className={`row-icon row-icon-${styleTerm}`}
                >
                  <BoxIcons
                    type={IconTypes.BOX_ICON}
                    name={getIconForUploadDocument(type)}
                  />
                </$Button>
              </$Tooltip>
              <div className="cl-details">
                <span className="cl-title">
                  <a onClick={onPress}>{name}</a>
                </span>
                <span className="cl-desc">
                  {moment.utc(date).isValid() &&
                    moment.utc(date).isAfter(moment.utc('0001-01-01')) && (
                      <$DateTimeLabel value={date} />
                    )}
                  | {user}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
      {type === DocumentType.TEXT && noteType === NoteType.CP_NOTE && (
        <div className={`cl-item case-log ${!notified && source != NoteSource.US_BOX ? " unread-note" : ""}`}>
          <div className="cl-actions">
            <$Popover
              overlayClassName="history-cp-note-preview"
              placement="right"
              title={
                <div className="d-flex align-items-center justify-content-between">
                  <span className="mr-4">
                    {t(
                      "US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.CREDITOR_NOTE"
                    )}
                  </span>
                  <$Tag
                    className={`tag-sr ${
                      source == NoteSource.US_BOX
                        ? "tag-sr-sent"
                        : "tag-sr-received"
                    }`}
                  >
                    <BoxIcons
                      type={IconTypes.BOX_ICON}
                      name={source == NoteSource.US_BOX ? IconNames.SENT : IconNames.RECEIVED}
                      className="mr-1"
                    />
                    {`${
                      source == NoteSource.US_BOX
                        ? t("US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.SENT")
                        : t(
                            "US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.RECEIVED"
                          )
                    }`}
                  </$Tag>
                </div>
              }
              content={name}
              trigger="hover"
            >
              <$Button
                type="link"
                size="small"
                className="row-icon row-icon-cp-note"
              >
                <BoxIcons type={IconTypes.BOX_ICON} name="creditor-notes" />
              </$Button>
            </$Popover>
          </div>
          <div className="cl-details">
            <span className="cl-title">
              <$Tooltip
                placement="top"
                title={`${
                  source == NoteSource.US_BOX
                    ? t("US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.SENT")
                    : t("US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.RECEIVED")
                }`}
              >
                <span
                  className={`cl-icon-sr ${
                    source == NoteSource.US_BOX
                      ? "cl-icon-sr-sent"
                      : "cl-icon-sr-received"
                  }`}
                >
                  <BoxIcons
                    type={IconTypes.BOX_ICON}
                    name={source == NoteSource.US_BOX ? IconNames.SENT : IconNames.RECEIVED}
                  />
                </span>
              </$Tooltip>
              <a onClick={onPress} data-testid="dashboard-history-noteOpen">
                {name}
              </a>
            </span>
            <span className="cl-desc">
              {moment.utc(date).isValid() &&
                moment.utc(date).isAfter(moment.utc("0001-01-01")) && (
                  <$DateTimeLabel value={date} />
                )}
              {user && ` | ${user}`}
            </span>
          </div>
        </div>
      )}
      {!channel && type !== DocumentType.TEXT && !isUploadDocument && !isWorkflowHistory && (
        <div className="cl-item case-log">
          <div className="cl-actions">
            {/* Print Button */}
            {isPrinted && (
              <$Tooltip
                placement="top"
                title={t("US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.PRINT")}
              >
                <$Button
                  type="link"
                  size="small"
                  className="row-icon row-icon-print"
                >
                  <BoxIcons type={IconTypes.BOX_ICON} name="print" />
                </$Button>
              </$Tooltip>
            )}

            {/* Attachment Button */}
            {isAttachmentAvailable && (
              <$Tooltip
                placement="top"
                title={t(
                  "US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.ATTACHMENTS"
                )}
              >
                <$Button
                  type="link"
                  onClick={() => onPressIcon(DocumentType.ATTACHEMENT)}
                  size="small"
                  data-testid="dashboard-history-attachment"
                  className="row-icon row-icon-attachment"
                >
                  <BoxIcons type={IconTypes.BOX_ICON} name="attachment" />
                </$Button>
              </$Tooltip>
            )}

            {type === DocumentType.EMAIL && (
              <$Tooltip
                placement="top"
                title={t("US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.EMAIL")}
                overlayClassName="dh-act-btn"
              >
                <$Button
                  onClick={() => onPressIcon(DocumentType.EMAIL)}
                  type="link"
                  size="small"
                  data-testid="dashboard-history-attachmentOpen"
                  className={`row-icon row-icon-${styleTerm}`}
                >
                  <BoxIcons
                    type={IconTypes.BOX_ICON}
                    name={DocumentType.EMAIL}
                  />
                </$Button>
              </$Tooltip>
            )}

            {type === DocumentType.PDF && (
              <$Tooltip
                placement="top"
                title={t("US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.PDF")}
                overlayClassName="dh-act-btn"
              >
                <$Button
                  onClick={() => onPressIcon(DocumentType.PDF)}
                  type="link"
                  size="small"
                  data-testid="dashboard-history-attachmentOpen"
                  className={`row-icon row-icon-${styleTerm}`}
                >
                  <BoxIcons type={IconTypes.BOX_ICON} name={DocumentType.PDF} />
                </$Button>
              </$Tooltip>
            )}

            {/* XML type document and generate xml and pdf icons */}
            {type === DocumentType.XML && (
              <Fragment>
                {/* <$Tooltip
                  placement="top"
                  title={t("US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.PDF")}
                  overlayClassName="dh-act-btn"
                >
                  <$Button
                    onClick={() => onPressIcon(DocumentType.PDF, true)}
                    type="link"
                    size="small"
                    data-testid="dashboard-history-attachmentOpen"
                    className={`row-icon row-icon-${styleTerm}`}
                  >
                    <BoxIcons
                      type={IconTypes.BOX_ICON}
                      name={DocumentType.PDF}
                    />
                  </$Button>
                </$Tooltip> */}
                <$Tooltip
                  placement="top"
                  title={type ?? ""}
                  overlayClassName="dh-act-btn"
                >
                  <$Button
                    onClick={() => onPressIcon(DocumentType.XML)}
                    type="link"
                    size="small"
                    data-testid="dashboard-history-attachmentOpen"
                    className={`row-icon row-icon-${styleTerm}`}
                  >
                    <BoxIcons
                      type={IconTypes.BOX_ICON}
                      name={getIconByActivityType(activityType, type)}
                    />
                  </$Button>
                </$Tooltip>
              </Fragment>
            )}
             {type !== DocumentType.EMAIL &&
                type !== DocumentType.PDF &&
                type !== DocumentType.XML && (
                  <$Tooltip
                    placement="top"
                    title={type && type.toUpperCase()}
                    overlayClassName="dh-act-btn"
                  >
                    <$Button
                      onClick={() => onPressIcon(type)}
                      type="link"
                      size="small"
                      className={`row-icon row-icon-${styleTerm}`}
                    >
                      <BoxIcons
                        type={IconTypes.BOX_ICON}
                        name={getIconByActivityType(activityType, type)}
                      />
                    </$Button>
                  </$Tooltip>
                )}
          </div>
          <div className="cl-details">
            <span className="cl-title">
              <a onClick={onPress}>{name}</a>
            </span>
            <span className="cl-desc">
              {moment.utc(date).isValid() &&
                moment.utc(date).isAfter(moment.utc("0001-01-01")) && (
                  <$DateTimeLabel value={date} />
                )}
              {user && ` | ${user}`}
            </span>
          </div>
          {version && (
            <$Tag
              className={`ml-2 tag-version-${version}`}
            >{`${getUSCVersionName(version)}`}</$Tag>
          )}
        </div>
      )}
     
    </Fragment>
  );
};

export default HistoryItem;
