import { IAddNewCreditor } from "us.collection.creditor/components/ManageCreditors/Interfaces";

export function SaveNewCreditor(this: any): IAddNewCreditor {
  const {
    entRoleId,
    entId,
    creditorNumber,
    creditorName,
    groupNo,
    groupName,
    belongsToId,
    nin,
    account,
    accountNo,
    productNo,
    caseHandler,
    agreement,
    address1,
    address2,
    address3,
    country,
    postalCode,
    postalPlace,
    email,
    direct,
    mobile,
    office,
    fax,
    im1,
    im2,
    im3,
    entRoleIdsOfContactPersons,
    AllowMoveToBMD,
    GetBelongsToSettings
  } = this;
  
  return {
    entRoleId,
    entId,
    entityType: "CRE",
    belongstocredno: belongsToId,
    prductNo: productNo != '' ? Number(productNo) : 0,
    firstName: creditorName.indexOf(' ') != -1 ? creditorName.trim().substring(0, creditorName.indexOf(' ')) : '',
    lastName: creditorName.indexOf(' ') != -1 ? creditorName.trim().substring(creditorName.indexOf(' ') + 1) : creditorName,
    idNo: nin,
    birthDate: "",
    pid: creditorNumber,
    accountNo: account.replace(/ /g, ""),
    remitaccount: accountNo.replace(/ /g, ""),
    gender: "",
    address1,
    address2,
    address3,
    zipCode: postalCode,
    zipName: postalPlace,
    municipalityCode: "",
    countryCode: country,
    email: email,
    preferredCommunicationMethod: "",
    bankAccountNo: account.replace(/ /g, ""),
    debtorType: "",
    tags: [
      { tag: "GroupName", value: groupName },
      { tag: "ProductNo", value: Number(productNo) },
      { tag: "skype", value: im2 },
      { tag: "msn", value: im3 },
      { tag: "parentCreditorNo", value: belongsToId },
      { tag: "creditorGroupId", value: groupNo },
      { tag: "remitAccountNo", value: accountNo.replace(/ /g, "") },
    ],
    phoneNumbers: [
      { type: "mobile", number: mobile },
      { type: "work", number: office },
      { type: "home", number: direct },
      { type: "sms", number: im1 },
      { type: "fax", number: fax },
    ],
    contactInfo: entRoleIdsOfContactPersons,
    caseHandler: caseHandler,
    agreement: agreement,
    OtherInfo: null,
    AllowMoveToBMD,
    GetBelongsToSettings
  };
}
