export const initialState = {
    postalDetails: {},
    belongsToCreditor: {},
    isValidBelongsToCreditor: true,
    nextCreditorNo: '',
    nextCreditorNoLoading: false,
    users: [],
    newEntityData: {},
    isAddingCreditor: false,
    isUpdatingCreditor: false,
    isCreditorNoValid: true,
    isValidPostalCodes: false
  };
  