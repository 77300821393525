import { put, call, takeLatest } from "redux-saga/effects";
import * as API from "us.collection.case/services";
import * as Actions from "us.collection.case/actions";
import {
  ExtendedField
} from "us.collection.case/constants/Actions";

const { extendedField } = Actions;

const ExtendedFieldSagas = {
  /**
   * Get extended field on us.collection.case
   * @param action
   */
  get: function* (action: any) {
    const params = action?.payload?.data ?? {};
    try {
      const {data, status} = yield call(API.extendedField.extendedFields.get, params);
      if (data?.errors || data?.statusCode) {
        yield put(extendedField.extendedFields.fail(data?.errors[0]?.error));
      } 
      else if(status == 204){
        yield put(extendedField.extendedFields.fail({}));
      }
      else {
        yield put(extendedField.extendedFields.success(data));
      }
    } catch (error) {
      yield put(extendedField.extendedFields.fail(error as any));
      console.error(error);
    }
  },
  
};

export default [
  takeLatest(
    ExtendedField.GET_EXTENDED_FIELD,
    ExtendedFieldSagas.get
  ),
];
