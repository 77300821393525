import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import {
	$Select,
	$Skeleton,
	$Tooltip,
	$Button,
	$Notification,
	$Empty,
	$Input,
} from 'us.common/components';
import {
	FollowUpPinStatus,
	IFollowUpItem,
} from 'us.collection.followup/interfaces';
import { IFollowUpList } from 'us.collection.followup/components/FollowUps/Components/FollowUpList/Interfaces';
import { followUps } from 'us.collection.followup/actions';
import { FollowUpItem } from 'us.collection.followup/components/FollowUps/Components/FollowUpList/Components';
import {
	ImportOutlined,
	LoadingOutlined,
	DownOutlined,
	UpOutlined,
} from 'us.icons';
import { useSignalRTrigger } from 'us.common/SignalRProvider';
import { NotificationTriggerType } from 'us.common/constants/Component/Notification';
import { searchFollowup } from './Functions';
import { PinType } from './Constants';
import { useFormikContext } from 'formik';
import { RootState } from 'us.helper/types';

const {
	categories,
	list,
	result,
	reImport,
	followUpNavigation,
	categoryFilters,
	unpinnedItems,
} = followUps;

const FollowUpList: React.FC<PropsFromRedux & IFollowUpList> = (props) => {
	const {
		categories,
		list,
		history,
		isBulkFollowupReImporting,
		followUpNavigation,
		pinStatus,
		reImportReset,
		reImportFollowUp,
		getFollowUpCategories,
		getFollowUpList,
		updateNavigationDetails,
		setCategoryFilters,
		setUnpinnedExpanded,
	} = props;

	const { t } = useTranslation();
	const { values } = useFormikContext<any>();
	const { followUpId } = useParams<{ followUpId?: string }>();
	const [selectedFollowUp, setSelectedFollowUp] = useState<number>(-1);
	const { setFieldValue } = useFormikContext<any>();
	const { setTrigger } = useSignalRTrigger();

	const { followUpCategory } = values;
	const { data: categoryOptions, categorySearchText } = categories ?? {};
	const { isFetching, data } = list ?? {};

	const { pinnedFollowup, unpinnedFollowup } = useMemo(() => {
		return searchFollowup(data, categorySearchText);
	}, [data, categorySearchText]);

	const { isBack } = followUpNavigation ?? {};
	const { isExpanded: isUnpinExpanded } = pinStatus ?? {};

	const triggers = [
		{
			name: NotificationTriggerType.ON_FOLLOWUP_REIMPORT,
			callBack: () => {
				$Notification.success({
					message: t(
						`US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.FOLLOWUPS_REIMPORTED_SUCCESSFULLY`
					),
					key: '1',
					placement: 'topRight',
					duration: 10,
				});
				reImportReset && reImportReset({});
			},
		},
	];

	useEffect(() => {
		if (!isBack) {
			getFollowUpCategories && getFollowUpCategories({});
			setTrigger(triggers);
		}
		const targetElement = document.querySelector(
			`a[href="/follow-up/${followUpId}"]`
		);
		if (targetElement) {
			targetElement.scrollIntoView({ behavior: 'smooth' });
		}
	}, []);

	useEffect(() => {
		if (followUpId) {
			setSelectedFollowUp(Number(followUpId));
		}
	}, [followUpId]);

	useEffect(() => {
		if (followUpCategory != undefined && !isBack) {
			getFollowUpList &&
				getFollowUpList({
					categoryId: followUpCategory,
					pinStatus: FollowUpPinStatus.ALL,
				});
		}
	}, [followUpCategory]);

	return (
		<div className='fl-sidebar'>
			<div className='fl-sidebar-header'>
				<div className='d-flex align-items-top'>
					<div className='flex-grow-1'>
						<$Select
							name='followUpCategory'
							size='small'
							options={
								categoryOptions
							}
							value={followUpCategory}
							allOption={false}
							className='w-100'
							optionText='categoryName'
							optionValue='categoryID'
							onSearchBy={[
								'categoryName',
								'categoryID',
							]}
							dataTestid='followUpCategoryTestId'
							onSelect={(
								categoryId: number
							) => {
								isBack &&
									updateNavigationDetails &&
									updateNavigationDetails(
										{
											isBack: false,
											viewedItems:
												[],
										}
									);
								history.push(
									'/follow-up'
								);
								setSelectedFollowUp(
									-1
								);
							}}
						/>
						<div className='pt-1'>
							<$Input
								size='small'
								name='followupSearch'
								className='w-100'
								allowClear={
									true
								}
								placeholder={t(
									'US.COMMON:COMMON.SEARCH'
								)}
								onChange={(
									inputParam: any
								) => {
									if (
										isBack
									)
										updateNavigationDetails &&
											updateNavigationDetails(
												{
													isBack: false,
													viewedItems:
														[],
												}
											);
									setCategoryFilters &&
										setCategoryFilters(
											inputParam
												.target
												?.value
										);
									setFieldValue(
										'followupSearch',
										inputParam
											.target
											?.value
									);
								}}
							/>
						</div>
					</div>
					<div>
						<$Tooltip
							title={t(
								'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.RE_IMPORT_ALL_FOLLOWUPS'
							)}>
							<$Button
								type='dashed'
								size='small'
								icon={
									isBulkFollowupReImporting ? (
										<LoadingOutlined
											style={{
												fontSize: 14,
											}}
											spin
										/>
									) : (
										<ImportOutlined />
									)
								}
								onClick={() =>
									reImportFollowUp &&
									reImportFollowUp(
										{
											followUpId: -1,
										}
									)
								}
								className='ml-3'
								disabled={
									isBulkFollowupReImporting
								}
							/>
						</$Tooltip>
					</div>
				</div>
			</div>
			<$Skeleton
				className='p-3'
				loading={
					isFetching ||
					categories?.isFetching ||
					!followUpCategory
				}
				active
				paragraph={{ rows: 4 }}>
				<>
					{[
						...pinnedFollowup,
						...unpinnedFollowup,
					].length == 0 &&
						!isFetching && (
							<$Empty
								description={`${t(
									'US.COLLECTION.COMMON:COMMON.NO_DATA'
								)}`}
								image={
									$Empty.PRESENTED_IMAGE_SIMPLE
								}
							/>
						)}
					<div
						className={`fl-sidebar-body
							${isUnpinExpanded && 'unpin-expanded'}
							${pinnedFollowup.length == 0 && 'flss-no-pinned'}
						`}>
						{pinnedFollowup.length != 0 && (
							<div className='fl-sidebar-section flss-pinned'>
								<div className='fl-cat-list'>
									{pinnedFollowup?.map(
										(
											followUpItem: IFollowUpItem,
											index: number
										) => {
											const {
												followupId,
											} =
												followUpItem;
											return (
												<FollowUpItem
													followUpItem={
														followUpItem
													}
													key={
														index
													}
													onSelect={() => {
														isBack &&
															updateNavigationDetails &&
															updateNavigationDetails(
																{
																	isBack: false,
																	viewedItems:
																		[],
																}
															);
														setSelectedFollowUp(
															followupId
														);
													}}
													isSelected={
														followupId ==
														selectedFollowUp
													}
													type={
														PinType.PINNED
													}
												/>
											);
										}
									)}
								</div>
							</div>
						)}
						{unpinnedFollowup.length !=
							0 && (
							<>
								{pinnedFollowup.length !=
									0 &&
									unpinnedFollowup.length >=
										1 && (
										<div
											className='d-flex align-items-center justify-content-between unpinned-title'
											onClick={() =>
												setUnpinnedExpanded &&
												setUnpinnedExpanded(
													{}
												)
											}>
											<strong className='mb-0'>
												{t(
													'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.UNPINNED_FOLLOWUPS'
												)}
											</strong>
											{isUnpinExpanded ? (
												<DownOutlined />
											) : (
												<UpOutlined />
											)}
										</div>
									)}
								{((isUnpinExpanded &&
									pinnedFollowup.length !=
										0) ||
									pinnedFollowup.length ==
										0) && (
									<div
										className={`fl-sidebar-section flss-unpinned`}>
										<div className='fl-cat-list '>
											{unpinnedFollowup?.map(
												(
													followUpItem: IFollowUpItem,
													index: number
												) => {
													const {
														followupId,
													} =
														followUpItem;
													return (
														<FollowUpItem
															followUpItem={
																followUpItem
															}
															key={
																index
															}
															onSelect={() => {
																isBack &&
																	updateNavigationDetails &&
																	updateNavigationDetails(
																		{
																			isBack: false,
																			viewedItems:
																				[],
																		}
																	);
																setSelectedFollowUp(
																	followupId
																);
															}}
															isSelected={
																followupId ==
																selectedFollowUp
															}
															type={
																PinType.UNPINNED
															}
														/>
													);
												}
											)}
										</div>
									</div>
								)}
							</>
						)}
					</div>
				</>
			</$Skeleton>
		</div>
	);
};

const mapStateToProps = (state: RootState) => {
	const { followUps } = state;
	const {
		categories,
		list,
		isBulkFollowupReImporting,
		followUpNavigation,
		pinStatus,
	} = followUps;
	return {
		categories,
		list,
		isBulkFollowupReImporting,
		followUpNavigation,
		pinStatus,
	};
};

const mapDispatchToProps = {
	getFollowUpCategories: categories.get,
	getFollowUpList: list.get,
	getFollowUpResult: result.get,
	reImportFollowUp: reImport.execute,
	reImportReset: reImport.reset,
	updateNavigationDetails: followUpNavigation.set,
	setUnpinnedExpanded: unpinnedItems.set,
	setCategoryFilters: categoryFilters.set,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FollowUpList);
