import { Formik, FormikErrors, FormikHelpers, FormikState } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IBMDDataType,
  IBMDDataTypeValueRetrievingSP,
  IRootState,
} from 'us.collection/interfaces';
import {
  $Button,
  $Form,
  $Input,
  $Select,
  $Skeleton,
  $TextArea,
  $AsyncInput,
} from 'us.common/components';
import { AddEditDataTypeFormSchema } from './Validation';
import { BMDActions } from 'us.collection/actions';
import { connect, ConnectedProps } from 'react-redux';
import { CurrentView } from '../../../../Constants';
import {
  AddDataTypeRequestObject,
  EditDataTypeRequestObject,
} from './Repository';
import { IAddEditDataTypeForm } from './Interfaces';
import { getInitialValues, isDataTypeNameDuplicating } from './Functions';

export const AddEditDataTypeForm: React.FC<
  IAddEditDataTypeForm & PropsFromRedux
> = (props) => {
  const {
    currentView,
    selectedBMDDataType,
    drawerStatus,
    getBMDDataTypeValueRetrievingSP,
    updateBMDDataType,
    addBMDDataType,
    changeDrawerStatus,
    valueRetrievingSPs,
    valueRetrievingSPsLoading,
    addUpdateDataType,
    bmdDataTypes,
  } = props;
  const { t } = useTranslation();
  const [isDuplicity, setDuplicity] = useState<boolean>(false);

  useEffect(() => {
    getBMDDataTypeValueRetrievingSP && getBMDDataTypeValueRetrievingSP({});
  }, []);

  /**
   * @function
   * @description Form submission for Add edit bmd data type form
   * @param {IAddBMDProperty} values - form values
   * @param {FormikHelpers<IAddBMDProperty>} actions - formik actions
   */
  const handleSubmit = (
    values: IBMDDataType,
    actions: FormikHelpers<IBMDDataType>
  ): void => {
    actions.validateForm();
    if (currentView === CurrentView.Add) {
      const params = AddDataTypeRequestObject.call({
        ...values,
      });
      addBMDDataType && addBMDDataType(params);
    } else if (currentView === CurrentView.Edit) {
      const params = EditDataTypeRequestObject.call({
        ...values,
      });
      updateBMDDataType && updateBMDDataType(params);
    }
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={getInitialValues(selectedBMDDataType)}
      enableReinitialize
      validateOnBlur
      validateOnChange
      validateOnMount
      validationSchema={AddEditDataTypeFormSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        setValues,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        resetForm,
        ...restProps
      }: {
        values: IBMDDataType;
        errors: { [field: string]: string };
        setValues: any;
        handleSubmit: any;
        isSubmitting: boolean;
        setFieldValue: (
          field: string,
          value: any,
          shouldValidate?: boolean
        ) => void;
        resetForm: (nextState?: Partial<FormikState<IBMDDataType>>) => void;
        validateForm: (
          values?: IBMDDataType
        ) => Promise<FormikErrors<IBMDDataType>>;
        setTouched: (
          fields: { [field: string]: boolean },
          shouldValidate?: boolean
        ) => void;
        isValidating: boolean;
        isValid: boolean;
        touched: { [field: string]: boolean };
      }) => (
        <>
          <div className="mt-3">
            <$Form>
              <div>
                <$AsyncInput
                  name="name"
                  label={t('US.COLLECTION.CASE:BMD.NAME')}
                  size="small"
                  required
                  onBlur={(event: any) => {
                    setDuplicity(
                      isDataTypeNameDuplicating(
                        event.target.value,
                        bmdDataTypes.data,
                        selectedBMDDataType
                      )
                    );
                  }}
                  isValid={!isDuplicity}
                  asyncError={t(
                    'US.COLLECTION.CASE:BMD.THIS_NAME_IS_ALREADY_TAKEN'
                  )}
                />
              </div>

              <div className="mb-4">
                <$TextArea
                  autoSize={{ minRows: 4, maxRows: 6 }}
                  label={t('US.COLLECTION.CASE:BMD.DESCRIPTION')}
                  name="description"
                  className="w-100"
                />
              </div>
              <$Skeleton
                loading={valueRetrievingSPsLoading}
                active
                paragraph={{ rows: 2 }}
              >
                <div className="mb-3">
                  <label className="required d-block">
                    {t('US.COLLECTION.CASE:BMD.VALUE_RETRIEVING_SP_NAME')}
                  </label>
                  <$Select
                    name="storedProcedure"
                    placeholder="Select the value retrieving SP name"
                    size="small"
                    options={valueRetrievingSPs?.map(
                      ({ name }: IBMDDataTypeValueRetrievingSP) => ({
                        key: name,
                        label: name,
                        value: name,
                      })
                    )}
                    allOption={false}
                    autoFocus={true}
                    onSearchBy={['label']}
                    onSelect={(value) =>
                      setFieldValue('storedProcedure', value, true)
                    }
                    className="w-100"
                    required
                    value={values.storedProcedure}
                    formitem={{}}
                  />
                </div>
              </$Skeleton>
            </$Form>
          </div>
          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Button
                className="ml-3 mr-2"
                type="primary"
                onClick={handleSubmit}
                loading={addUpdateDataType.isLoading}
                data-testid="update-data-type"
                disabled={!restProps.isValid || isDuplicity}
              >
                {currentView === CurrentView.Edit &&
                  t('US.COLLECTION.COMMON:COMMON.UPDATE')}
                {currentView === CurrentView.Add &&
                  t('US.COLLECTION.COMMON:COMMON.SAVE')}
              </$Button>
              <$Button
                onClick={() => {
                  changeDrawerStatus &&
                    changeDrawerStatus({ ...drawerStatus, isVisible: false });
                }}
              >
                {t('US.COLLECTION.COMMON:COMMON.CANCEL')}
              </$Button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

const { bmdDataTypeValueRetrievingSP, bmdDataTypes, bmdDataType } = BMDActions;

const mapStateToProps = (state: IRootState) => {
  const { bmd } = state;
  const { valueRetrievingSPs, drawerStatus, addUpdateDataType, bmdDataTypes } =
    bmd;
  const { data, isLoading } = valueRetrievingSPs;
  return {
    addUpdateDataType,
    valueRetrievingSPs: data,
    valueRetrievingSPsLoading: isLoading,
    drawerStatus,
    bmdDataTypes,
  };
};

const mapDispatchToProps = {
  changeDrawerStatus: bmdDataTypes.openDrawer,
  addBMDDataType: bmdDataType.save,
  updateBMDDataType: bmdDataType.update,
  getBMDDataTypeValueRetrievingSP: bmdDataTypeValueRetrievingSP.get,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddEditDataTypeForm);
