import { IBMDInitialState, IBMDListModel, IDrawerStatus } from "us.collection/interfaces/BMD";

/**
 * clone an object of T type with defined property name
 * @param state current redux state of IBMDInitialState
 * @param propName property name of IBMDInitialState
 * @param data new data to be assigned
 * @param isLoading if the API call is still running
 * @param isError if there is any error
 * @returns new cloned object with the passed propName param
 */
export const getBMDDrawerObject = (
  state: IBMDInitialState,
  propName: keyof IBMDInitialState,
  data: IDrawerStatus
) =>
  Object.assign({}, state, {
    [propName]: data,
  });
