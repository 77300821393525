import { IVehicleAssets } from "./interfaces";
import moment from "moment";

export function SaveVehicleAssets(this: IVehicleAssets) {
  return {
    entityId: this.entityId,
    entityType: this.entityType,
    estimatedValue: this.estimatedValue,
    valuationAmount: this.valuationAmount,
    valuationDate:
      this.valuationDate && moment(this.valuationDate).isValid()
        ? moment(this.valuationDate).format("YYYY-MM-DD")
        : null,
    description: this.description,
    registrationNo: this.registrationNo,
    yearOrModel: this.yearOrModel,
    comment: this.comment,
  };
}
