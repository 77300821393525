import { initialState } from './State';
import { CreditorCases} from 'us.collection.creditor/constants';

const {GET_CREDITOR_CASES, GET_CREDITOR_CASES_SUCCESS, GET_CREDITOR_CASES_FAIL} = CreditorCases;

export default (state = initialState, { payload, type }: any) => {
    switch (type) {
            case GET_CREDITOR_CASES:
            return Object.assign({}, state, {
                caseList: {
                    data: [],
                    isLoading:true
                }
            });
        case GET_CREDITOR_CASES_SUCCESS:
            return Object.assign({}, state, {
                caseList: {
                    data: payload.data,
                    isLoading: false
                }
            });
        case GET_CREDITOR_CASES_FAIL:
            return Object.assign({}, state, {
                caseList: {
                    data: [],
                    isLoading: false
                }
            });
        default:
            return state;
    }
}