import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Common from "us.common";
import moment from "moment";
import { IntlDate } from "us.common/functions";
import {
  PlusOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
} from "us.icons";

const {
  $Input,
  $Button,
  $Popconfirm,
  $Skeleton,
  $Table,
  $Popover,
} = Common.Components;

interface INonWorkflowDays {
  nonWorkflowDays: Array<any>;
  isFetching?: boolean;
  currentLanguage: string;
  onSelectDate?: any;
  addNewNonProductionDay?: any;
  isImportCalendar?: boolean;
  removeNonProductionDay?: any;
}

const NonWorkflowDays: React.FC<INonWorkflowDays> = (props) => {
  const { t } = useTranslation(["US.COLLECTION.ADMIN", "US.COLLECTION.COMMON"]);
  const {
    nonWorkflowDays,
    isFetching,
    currentLanguage,
    onSelectDate,
    addNewNonProductionDay,
    isImportCalendar,
    removeNonProductionDay,
  } = props;

  // Popover actions in table
  const content = (record: any, index: number) => (
    <div className="table-more-content">
      <div className="d-flex  flex-row more-item">
        <div className="p-1">
          <EditOutlined />
        </div>
        <div className="p-1" onClick={() => onSelectDate(record, index)}>
          {t("US.COLLECTION.COMMON:COMMON.EDIT")}
        </div>
      </div>

      <$Popconfirm
        title={t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.POPCONFIRM2")}
        onConfirm={() => removeNonProductionDay(record, index)}
      >
        <div className="d-flex mb-2 flex-row more-item">
          <div className="p-1">
            <DeleteOutlined />
          </div>
          <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.DELETE")}</div>
        </div>
      </$Popconfirm>
    </div>
  );

  const tableColumns: any = [
    {
      title: "",
      key: "more",
      dataIndex: "more",
      width: "50px",
      render: (text: any, record: any, index: number) => {
        return (
          !record.isFixedDate &&
          !isImportCalendar && (
            <$Popover
              placement="rightTop"
              content={content(record, index)}
              destroyTooltipOnHide
            >
              <$Button icon={<MoreOutlined />} size="small" />
            </$Popover>
          )
        );
      },
    },
    {
      title: t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.DATE"),
      dataIndex: "date",
      key: "date",
      width: "200px",
      render: (text: any, record: any, index: any) => {
        return (
          <span>
            {IntlDate(text, currentLanguage, Common.Constants.dateParams.DATE)}
          </span>
        );
      },
    },
    {
      title: t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.REASON"),
      dataIndex: "reason",
      key: "reason",
      render: (text: any, record: any, index: any) => {
        return <span>{text}</span>;
      },
    },
  ];

  return (
    <>
      {!isImportCalendar && (
        <div className="">
          <$Button
            className="ml-3"
            type="dashed"
            size="small"
            icon={<PlusOutlined />}
            onClick={() =>
              addNewNonProductionDay({
                id: -1,
                date: moment.utc(""),
                reason: "",
                criteria: [
                  {
                    type: "NonBanking",
                    value: 0,
                  },
                  {
                    type: "NonSms",
                    value: 0,
                  },
                  {
                    type: "NonPrinting",
                    value: 0,
                  },
                  {
                    type: "NonWorkflow",
                    value: 1,
                  },
                ],
                isFixedDate: false,
              })
            }
          >
            {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.ADDNEW")}
          </$Button>
        </div>
      )}

      <$Skeleton loading={isFetching} active paragraph={{ rows: 2 }}>
        <$Table
          rowKey={isImportCalendar ? "id" : "key"}
          className="mt-2"
          dataSource={nonWorkflowDays}
          columns={tableColumns}
          pagination={false}
          onRow={(record: any, rowIndex: any) => {
            return {
              onDoubleClick: (event: any) => {
                if (!record.isFixedDate && !isImportCalendar) {
                  onSelectDate(record, rowIndex);
                }
              },
            };
          }}
        />
      </$Skeleton>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NonWorkflowDays);
