import { IAPIAction } from "us.collection/interfaces";
import { otherParty } from 'us.collection.case/constants/Actions';
import { IOtherParty } from 'us.collection.case/interfaces/Actions/OtherParty'

export const OtherPartyAction: Readonly<IOtherParty & IAPIAction> = {
    /**
     * @namespace otherParties
     */
    otherParties: {
        get: (data) => ({
            type: otherParty.GET_OTHER_PARTY_DATA_STRAT,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: otherParty.GET_OTHER_PARTY_DATA_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: otherParty.GET_OTHER_PARTY_DATA_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },

    /**
   * @namespace otherParty
   */
    otherParty: {
        get: (data) => ({
            type: otherParty.GET_OTHER_PARTY_BY_ID_STRAT,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: otherParty.GET_OTHER_PARTY_BY_ID_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: otherParty.GET_OTHER_PARTY_BY_ID_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        save: (data) => ({
            type: otherParty.SAVE_OTHER_PARTY_DATA_STRAT,
            payload: {
                data,
                isLoading: true
            }
        }),
        saveSuccess: (data) => ({
            type: otherParty.SAVE_OTHER_PARTY_DATA_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        saveFail: (error) => ({
            type: otherParty.SAVE_OTHER_PARTY_DATA_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        delete: (data) => ({
            type: otherParty.DELETE_OTHER_PARTY_DATA_STRAT,
            payload: {
                data,
                isLoading: true
            }
        }),
        deleteSuccess: (data) => ({
            type: otherParty.DELETE_OTHER_PARTY_DATA_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        deleteFail: (error) => ({
            type: otherParty.DELETE_OTHER_PARTY_DATA_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        search: (data) => ({
            type: otherParty.SEARCH_OTHER_PARTY_DATA_STRAT,
            payload: {
                data,
                isLoading: true
            }
        }),
        searchSuccess: (data) => ({
            type: otherParty.SEARCH_OTHER_PARTY_DATA_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        searchFail: (error) => ({
            type: otherParty.SEARCH_OTHER_PARTY_DATA_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        searchReset: (data) => ({
            type: otherParty.CLEAR_OTHER_PARTY_SEARCH_DATA,
            payload: {
                data,
                isLoading: true
            }
        }),
        openDrawer: (data) => ({
            type: otherParty.ADD_OTHER_PARTY_DRAWER,
            payload: {
                data,
                isLoading: true
            }
        }),
        reset:(data)=>({
            type: otherParty.GET_OTHER_PARTY_BY_ID_RESET,
            payload: {
                data,
                isLoading: true
            }
        })
    },

    updateState: {
        update: (data) => ({
            type: otherParty.UPDATE_OTHER_PARTY_BY_ACTIVE_STATE_STRAT,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: otherParty.UPDATE_OTHER_PARTY_BY_ACTIVE_STATE_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: otherParty.UPDATE_OTHER_PARTY_BY_ACTIVE_STATE_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },

    /**
     * @namespace for get other party role type
     */
    roleTypes: {
        get: (data) => ({
            type: otherParty.GET_OTHER_PARTY_ROLE_TYPE_STRAT,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: otherParty.GET_OTHER_PARTY_ROLE_TYPE_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: otherParty.GET_OTHER_PARTY_ROLE_TYPE_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },

    /**
     * @namespace for save other party role type
     */
    roleType: {
        save: (data) => ({
            type: otherParty.SAVE_OTHER_PARTY_ROLE_TYPE_STRAT,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: otherParty.SAVE_OTHER_PARTY_ROLE_TYPE_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: otherParty.SAVE_OTHER_PARTY_ROLE_TYPE_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),

    },
    deleteRoleType: {
        delete: (data) => ({
            type: otherParty.DELETE_OTHER_PARTY_ROLE_TYPE_STRAT,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: otherParty.DELETE_OTHER_PARTY_ROLE_TYPE_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: otherParty.DELETE_OTHER_PARTY_ROLE_TYPE_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },

    postalCode: {
        search: (data) => ({
            type: otherParty.SEARCH_POSTALCODE_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: otherParty.SEARCH_POSTALCODE_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: otherParty.SEARCH_POSTALCODE_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        reset:(data)=>({
            type: otherParty.CLEAR_POSTAL_DETAILS,
            payload: {
                data,
                isLoading: true
            }  
        })
    },
    minicipality: {
        search: (data) => ({
            type: otherParty.SEARCH_MUNICIPALITY_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: otherParty.SEARCH_MUNICIPALITY_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: otherParty.SEARCH_MUNICIPALITY_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
    /**
     * Get postal details
     */
    postalDetails: {
        get: (data) => ({
            type: otherParty.GET_POSTAL_DETAIL,
            payload: {
                data: data,
                isLoading: true,
            },
        }),
        success: (data) => ({
            type: otherParty.GET_POSTAL_DETAIL_SUCCESS,
            payload: {
                data: data,
                isLoading: false,
            },
        }),
        fail: (error) => ({
            type: otherParty.GET_POSTAL_DETAIL_FAIL,
            payload: {
                error: error,
                isLoading: false,
            },
        }),
        reset: (data) => ({
            type: otherParty.RESET_POSTAL_DETAIL,
            payload: {
              data,
              isLoading: true,
            },
        }),
    },
}