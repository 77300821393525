import { IInitialState } from "us.collection.documents/reducers/CaseDocuments/Interfaces";

export const initialState: IInitialState = {
  caseDocuments: {
    data: [],
    isFetching: false,
  },
  caseDocument: {
    data: "",
    isFetching: false,
  },
  categories: {
    data: [],
    isFetching: false,
  },
  rules: {
    data: [],
    isFetching: false,
  },
  previewDrawer: {
    title: "",
    visible: false,
  },
  uploadDrawer: {
    title: "",
    visible: false,
  },
};
