export enum FollowUps {
  GET_FOLLOW_UP_CATEGORIES = "GET_FOLLOW_UP_CATEGORIES",
  GET_FOLLOW_UP_CATEGORIES_SUCCESS = "GET_FOLLOW_UP_CATEGORIES_SUCCESS",
  GET_FOLLOW_UP_CATEGORIES_FAIL = "GET_FOLLOW_UP_CATEGORIES_FAIL",

  GET_FOLLOW_UP_LIST = "GET_FOLLOW_UP_LIST",
  GET_FOLLOW_UP_LIST_SUCCESS = "GET_FOLLOW_UP_LIST_SUCCESS",
  GET_FOLLOW_UP_LIST_FAIL = "GET_FOLLOW_UP_LIST_FAIL",

  UNPIN_FOLLOW_UP_ITEM = "UNPIN_FOLLOW_UP_ITEM",
  UNPIN_FOLLOW_UP_ITEM_SUCCESS = "UNPIN_FOLLOW_UP_ITEM_SUCCESS",
  UNPIN_FOLLOW_UP_ITEM_FAIL = "UNPIN_FOLLOW_UP_ITEM_FAIL",

  SET_UNPINNED_EXPANDED = "SET_UNPINNED_EXPANDED",
  SET_UNPINNED_EXPANDED_SUCCESS = "SET_UNPINNED_EXPANDED_SUCCESS",
  SET_UNPINNED_EXPANDED_FAIL = "SET_UNPINNED_EXPANDED_FAIL",

  GET_FOLLOW_UP_RESULT = "GET_FOLLOW_UP_RESULT",
  GET_FOLLOW_UP_RESULT_SUCCESS = "GET_FOLLOW_UP_RESULT_SUCCESS",
  GET_FOLLOW_UP_RESULT_FAIL = "GET_FOLLOW_UP_RESULT_FAIL",

  SET_FOLLOW_UP_FILTERS = "SET_FOLLOW_UP_FILTERS",
  SET_FOLLOW_UP_FILTERS_SUCCESS = "SET_FOLLOW_UP_FILTERS_SUCCESS",
  SET_FOLLOW_UP_FILTERS_FAIL = "SET_FOLLOW_UP_FILTERS_FAIL",

  SET_FOLLOW_UP_CATEGORY_FILTERS = "SET_FOLLOW_UP_CATEGORY_FILTERS",
  SET_FOLLOW_UP_CATEGORY_FILTERS_SUCCESS = "SET_FOLLOW_UP_CATEGORY_FILTERS_SUCCESS",
  SET_FOLLOW_UP_CATEGORY_FILTERS_FAIL = "SET_FOLLOW_UP_CATEGORY_FILTERS_FAIL",

  SET_FOLLOW_UP_TABLE_FILTERS = "SET_FOLLOW_UP_TABLE_FILTERS",
  SET_FOLLOW_UP_TABLE_FILTERS_SUCCESS = "SET_FOLLOW_UP_TABLE_FILTERS_SUCCESS",
  SET_FOLLOW_UP_TABLE_FILTERS_FAIL = "SET_FOLLOW_UP_TABLE_FILTERS_FAIL",

  SET_FOLLOW_UP_SORTERS = "SET_FOLLOW_UP_SORTERS",
  SET_FOLLOW_UP_SORTERS_SUCCESS = "SET_FOLLOW_UP_SORTERS_SUCCESS",
  SET_FOLLOW_UP_SORTERS_FAIL = "SET_FOLLOW_UP_SORTERS_FAIL",

  SET_FOLLOW_UP_RESULT_COLUMNS = "SET_FOLLOW_UP_RESULT_COLUMNS",
  SET_FOLLOW_UP_RESULT_COLUMNS_SUCCESS = "SET_FOLLOW_UP_RESULT_COLUMNS_SUCCESS",
  SET_FOLLOW_UP_RESULT_COLUMNS_FAIL = "SET_FOLLOW_UP_RESULT_COLUMNS_FAIL",

  SET_FOLLOW_UP_NAVIGATION = "SET_FOLLOW_UP_NAVIGATION",
  SET_FOLLOW_UP_NAVIGATION_SUCCESS = "SET_FOLLOW_UP_NAVIGATION_SUCCESS",
  SET_FOLLOW_UP_NAVIGATION_FAIL = "SET_FOLLOW_UP_NAVIGATION_FAIL",

  GET_FOLLOW_UP_HISTORY_FOR_ENTITY = "GET_FOLLOW_UP_HISTORY_FOR_ENTITY",
  GET_FOLLOW_UP_HISTORY_FOR_ENTITY_SUCCESS = "GET_FOLLOW_UP_HISTORY_FOR_ENTITY_SUCCESS",
  GET_FOLLOW_UP_HISTORY_FOR_ENTITY_FAIL = "GET_FOLLOW_UP_HISTORY_FOR_ENTITY_FAIL",

  GET_FOLLOW_UP_HISTORY_FOR_ITEM = "GET_FOLLOW_UP_HISTORY_FOR_ITEM",
  GET_FOLLOW_UP_HISTORY_FOR_ITEM_SUCCESS = "GET_FOLLOW_UP_HISTORY_FOR_ITEM_SUCCESS",
  GET_FOLLOW_UP_HISTORY_FOR_ITEM_FAIL = "GET_FOLLOW_UP_HISTORY_FOR_ITEM_FAIL",

  GET_FOLLOW_UP_HISTORY_FOR_ACTION = "GET_FOLLOW_UP_HISTORY_FOR_ACTION",
  GET_FOLLOW_UP_HISTORY_FOR_ACTION_SUCCESS = "GET_FOLLOW_UP_HISTORY_FOR_ACTION_SUCCESS",
  GET_FOLLOW_UP_HISTORY_FOR_ACTION_FAIL = "GET_FOLLOW_UP_HISTORY_FOR_ACTION_FAIL",

  MANAGE_FOLLOW_UP_HISTORY_DRAWER = "MANAGE_FOLLOW_UP_HISTORY_DRAWER",
  MANAGE_FOLLOW_UP_HISTORY_DRAWER_SUCCESS = "MANAGE_FOLLOW_UP_HISTORY_DRAWER_SUCCESS",
  MANAGE_FOLLOW_UP_HISTORY_DRAWER_FAIL = "MANAGE_FOLLOW_UP_HISTORY_DRAWER_FAIL",

  GET_ACTIVE_FOLLOW_UP = "GET_ACTIVE_FOLLOW_UP",
  GET_ACTIVE_FOLLOW_UP_SUCCESS = "GET_ACTIVE_FOLLOW_UP_SUCCESS",
  GET_ACTIVE_FOLLOW_UP_FAIL = "GET_ACTIVE_FOLLOW_UP_FAIL",

  EXECUTE_FOLLOW_UP_ACTION = "EXECUTE_FOLLOW_UP_ACTION",
  EXECUTE_FOLLOW_UP_ACTION_SUCCESS = "EXECUTE_FOLLOW_UP_ACTION_SUCCESS",
  EXECUTE_FOLLOW_UP_ACTION_FAIL = "EXECUTE_FOLLOW_UP_ACTION_FAIL",

  GET_FOLLOW_UP_ACTIVITIES_START = "GET_FOLLOW_UP_ACTIVITIES_START",
  GET_FOLLOW_UP_ACTIVITIES_SUCCESS = "GET_FOLLOW_UP_ACTIVITIES_SUCCESS",
  GET_FOLLOW_UP_ACTIVITIES_FAIL = "GET_FOLLOW_UP_ACTIVITIES_FAIL",

  EXECUTE_FOLLOW_UP_ACTIVITY_STRAT = "EXECUTE_FOLLOW_UP_ACTIVITY_STRAT",
  EXECUTE_FOLLOW_UP_ACTIVITY_SUCCESS = "EXECUTE_FOLLOW_UP_ACTIVITY_SUCCESS",
  EXECUTE_FOLLOW_UP_ACTIVITY_FAIL = "EXECUTE_FOLLOW_UP_ACTIVITY_FAIL",

  GET_ACTIVITY_BMD_START = "GET_ACTIVITY_BMD_START",
  GET_ACTIVITY_BMD_SUCCESS = "GET_ACTIVITY_BMD_SUCCESS",
  GET_ACTIVITY_BMD_FAIL = "GET_ACTIVITY_BMD_FAIL",

  GET_FOLLOW_UP_ACTIVITY_PARAMETERS_START = "GET_FOLLOW_UP_ACTIVITY_PARAMETERS_START",
  GET_FOLLOW_UP_ACTIVITY_PARAMETERS_SUCCESS = "GET_FOLLOW_UP_ACTIVITY_PARAMETERS_SUCCESS",
  GET_FOLLOW_UP_ACTIVITY_PARAMETERS_FAIL = "GET_FOLLOW_UP_ACTIVITY_PARAMETERS_FAIL",

  MANAGE_PARAMATER_DROP_DOWN = "MANAGE_PARAMATER_DROP_DOWN",
  UPDATE_ACTIVITY_PARAMETERS_DEFAULT_DATA = "UPDATE_ACTIVITY_PARAMETERS_DEFAULT_DATA",
  UPDATE_ACTIVITY_FORM_DATA = "UPDATE_ACTIVITY_FORM_DATA",

  EXECUTE_SINGLE_FOLLOW_UP_ACTIVITY = "EXECUTE_SINGLE_FOLLOW_UP_ACTIVITY",
  EXECUTE_SINGLE_FOLLOW_UP_ACTIVITY_SUCCESS = "EXECUTE_SINGLE_FOLLOW_UP_ACTIVITY_SUCCESS",
  EXECUTE_SINGLE_FOLLOW_UP_ACTIVITY_FAIL = "EXECUTE_SINGLE_FOLLOW_UP_ACTIVITY_FAIL",

  RESET_ACTIVITY_FROM = "RESET_ACTIVITY_FROM",

  FOLLOW_UP_RE_IMPORT = "FOLLOW_UP_RE_IMPORT",
  FOLLOW_UP_RE_IMPORT_SUCCESS = "FOLLOW_UP_RE_IMPORT_SUCCESS",
  FOLLOW_UP_RE_IMPORT_FAIL = "FOLLOW_UP_RE_IMPORT_FAIL",

  FOLLOW_UP_RE_IMPORT_RESET = "FOLLOW_UP_RE_IMPORT_RESET",

  ASSIGN_FOLLOW_UP_TO_USER = "ASSIGN_FOLLOW_UP_TO_USER",
  ASSIGN_FOLLOW_UP_TO_USER_SUCCESS = "ASSIGN_FOLLOW_UP_TO_USER_SUCCESS",
  ASSIGN_FOLLOW_UP_TO_USER_FAIL = "ASSIGN_FOLLOW_UP_TO_USER_FAIL",

  SET_LAST_ACTION_TO_FOLLOWUP_RESULT = 'SET_LAST_ACTION_TO_FOLLOWUP_RESULT',

  GET_CATEGORY_ID_FOR_SELECTED_FOLLOW_UP_START = "GET_CATEGORY_ID_FOR_SELECTED_FOLLOW_UP_START",
  GET_CATEGORY_ID_FOR_SELECTED_FOLLOW_UP_SUCCESS = "GET_CATEGORY_ID_FOR_SELECTED_FOLLOW_UP_SUCCESS",
  GET_CATEGORY_ID_FOR_SELECTED_FOLLOW_UP_FAIL = "GET_CATEGORY_ID_FOR_SELECTED_FOLLOW_UP_FAIL",

  GET_UPDATED_LAST_ACTION_START = 'GET_UPDATED_LAST_ACTION_START',
  GET_UPDATED_LAST_ACTION_SUCCESS = 'GET_UPDATED_LAST_ACTION_SUCCESS',
  GET_UPDATED_LAST_ACTION_FAIL = 'GET_UPDATED_LAST_ACTION_FAIL',

  SET_ACTIVITY_EXECUTION_ACTION = "SET_ACTIVITY_EXECUTION_ACTION"
}
