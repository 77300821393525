import { initialState } from './State';
import _ from 'lodash';
import { Creditor } from 'us.collection.creditor/constants';
import {
    combineAddress,
    setContactNOs,
    setTags,
    combineTwoFields,
    setBMDValues,
    setLastCase
} from 'us.collection.creditor/functions'

export default (state = initialState, { payload, type }: any) => {
    switch (type) {
        case Creditor.GET_GENERAL_AND_ADDRESS_DATA_START:
            return Object.assign({}, state, {
                general: {
                    data: state.general.data,
                    isLoading: true
                }
            });
        case Creditor.GET_GENERAL_AND_ADDRESS_DATA_SUCCESS:
            return Object.assign({}, state, {
                general: {
                    data: {
                        ...payload.data,
                        fullName: combineTwoFields(payload.data?.firstName, payload.data?.lastName),
                        address: combineAddress(payload.data),
                        postal: combineTwoFields(payload.data.zipCode, payload.data.zipName),
                        municipility: combineTwoFields(payload.data.municipalityCode, payload.data.municipalityName),
                        contacts: setContactNOs(payload.data.phoneNumbers),
                        extendedFields: setTags(payload.data?.tags)
                    },
                    isLoading: false
                }
            });
        case Creditor.GET_GENERAL_AND_ADDRESS_DATA_FAIL:
            return Object.assign({}, state, {
                general: {
                    data: {},
                    isLoading: false
                }
            });
        case Creditor.GET_OTHER_CONTACT_DATA_START:
            return Object.assign({}, state, {
                otherContacts: {
                    data: state.otherContacts.data,
                    isLoading: true
                }
            });
        case Creditor.GET_OTHER_CONTACT_DATA_SUCCESS:
            return Object.assign({}, state, {
                otherContacts: {
                    data: payload.data.map((item: any) => { return { ...item, fullName: combineTwoFields(item?.firstName, item?.lastName) } }),
                    isLoading: false
                }
            });
        case Creditor.GET_OTHER_CONTACT_DATA_FAIL:
            return Object.assign({}, state, {
                otherContacts: {
                    data: [],
                    isLoading: false
                }
            });
        case Creditor.GET_CREDITORS_DATA_START:
            return Object.assign({}, state, {
                creditors: {
                    data: state.creditors.data,
                    isLoading: true
                }
            });
        case Creditor.GET_CREDITORS_DATA_SUCCESS:
            return Object.assign({}, state, {
                creditors: {
                    data: payload.data.filter((item: any) => !item?.isMasterCreditor),
                    isLoading: false
                }
            });
        case Creditor.GET_CREDITORS_DATA_FAIL:
            return Object.assign({}, state, {
                creditors: {
                    data: [],
                    isLoading: false
                }
            });
        case Creditor.GET_CREDITORS_BMD_DATA_START:
            return Object.assign({}, state, {
                creditorBMDs: {
                    data: state.creditorBMDs.data,
                    isLoading: true
                }
            });
        case Creditor.GET_CREDITORS_BMD_DATA_SUCCESS:
            return Object.assign({}, state, {
                creditorBMDs: {
                    data: {
                        ...payload.data,
                        ...setLastCase(payload.data),
                        properties: setBMDValues(payload.data?.properties)
                    },
                    isLoading: false
                }
            });
        case Creditor.GET_CREDITORS_BMD_DATA_FAIL:
            return Object.assign({}, state, {
                creditorBMDs: {
                    data: {},
                    isLoading: false
                }
            });
        default:
            return state;
    }
}