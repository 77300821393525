import { initialState } from "us.collection.documents/reducers/CaseDocuments/State";
import { CaseDocuments } from "us.collection.documents/constants/Actions";
import moment from "moment";
import _ from "lodash";

const {
  GET_CASE_DOCUMENTS,
  GET_CASE_DOCUMENTS_SUCCESS,
  GET_CASE_DOCUMENTS_FAIL,
  GET_CASE_DOCUMENT_CONTENT,
  GET_CASE_DOCUMENT_CONTENT_SUCCESS,
  GET_CASE_DOCUMENT_CONTENT_FAIL,
  UPLOAD_DOCUMENTS,
  UPLOAD_DOCUMENTS_SUCCESS,
  UPLOAD_DOCUMENTS_FAIL,
  DELETE_CASE_DOCUMENT,
  DELETE_CASE_DOCUMENT_SUCCESS,
  DELETE_CASE_DOCUMENT_FAIL,
  GET_DOCUMENT_CATEGORIES,
  GET_DOCUMENT_CATEGORIES_SUCCESS,
  GET_DOCUMENT_CATEGORIES_FAIL,
  GET_UPLOAD_FILE_RULES,
  GET_UPLOAD_FILE_RULES_SUCCESS,
  GET_UPLOAD_FILE_RULES_FAIL,
  MANAGE_PREVIEW_DRAWER,
  MANAGE_UPLOAD_DRAWER,
} = CaseDocuments;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_CASE_DOCUMENTS:
      return Object.assign({}, state, {
        caseDocuments: {
          data: [],
          isFetching: true,
        },
      });
    case GET_CASE_DOCUMENTS_SUCCESS:
      return Object.assign({}, state, {
        caseDocuments: {
          data: payload?.data.map((doc: any) => {
            return {
              ...doc,
              uploadedDate: moment(doc.uploadedDate).format("YYYY-MM-DD"),
            };
          }),
          isFetching: false,
        },
      });
    case GET_CASE_DOCUMENTS_FAIL:
      return Object.assign({}, state, {
        caseDocuments: {
          data: [],
          isFetching: false,
        },
      });
    case GET_CASE_DOCUMENT_CONTENT:
      return Object.assign({}, state, {
        caseDocument: {
          data: "",
          isFetching: true,
        },
      });
    case GET_CASE_DOCUMENT_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        caseDocument: {
          data: payload?.data[0],
          isFetching: false,
        },
      });
    case GET_CASE_DOCUMENT_CONTENT_FAIL:
      return Object.assign({}, state, {
        caseDocument: {
          data: "",
          isFetching: false,
        },
      });
    case UPLOAD_DOCUMENTS:
      return Object.assign({}, state, {
        caseDocument: {
          ...state.caseDocument,
          isFetching: true,
        },
      });
    case UPLOAD_DOCUMENTS_SUCCESS:
      return Object.assign({}, state, {
        caseDocument: {
          ...state.caseDocument,
          isFetching: false,
        },
      });
    case UPLOAD_DOCUMENTS_FAIL:
      return Object.assign({}, state, {
        caseDocument: {
          ...state.caseDocument,
          isFetching: false,
        },
      });
    case DELETE_CASE_DOCUMENT:
      return Object.assign({}, state, {
        caseDocuments: {
          ...state.caseDocuments,
          isFetching: true,
        },
      });
    case DELETE_CASE_DOCUMENT_SUCCESS:
      _.remove(
        state.caseDocuments.data,
        (doc: any) => doc.documentId == payload?.data?.documentId
      );
      return Object.assign({}, state, {
        caseDocuments: {
          data: [...state.caseDocuments.data],
          isFetching: false,
        },
      });
    case DELETE_CASE_DOCUMENT_FAIL:
      return Object.assign({}, state, {
        caseDocuments: {
          ...state.caseDocuments,
          isFetching: false,
        },
      });
    case GET_DOCUMENT_CATEGORIES:
      return Object.assign({}, state, {
        categories: {
          ...state.categories,
          isFetching: true,
        },
      });
    case GET_DOCUMENT_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        categories: {
          data: payload?.data,
          isFetching: false,
        },
      });
    case GET_DOCUMENT_CATEGORIES_FAIL:
      return Object.assign({}, state, {
        categories: {
          ...state.categories,
          isFetching: false,
        },
      });
    case GET_UPLOAD_FILE_RULES:
      return Object.assign({}, state, {
        rules: {
          data: [],
          isFetching: true
        }
      })
    case GET_UPLOAD_FILE_RULES_SUCCESS:
      return Object.assign({}, state, {
        rules: {
          data: payload?.data,
          isFetching: false
        }
      })
    case GET_UPLOAD_FILE_RULES_FAIL:
      return Object.assign({}, state, {
        rules: {
          data: [],
          isFetching: false
        }
      })
    case MANAGE_PREVIEW_DRAWER:
      return Object.assign({}, state, {
        previewDrawer: {
          ...payload?.data,
        },
      });
    case MANAGE_UPLOAD_DRAWER:
      return Object.assign({}, state, {
        uploadDrawer: {
          ...payload?.data,
        },
      });
    default:
      return state;
  }
};
