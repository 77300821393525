import { template } from 'lodash';
import * as Yup from 'yup';

interface DomainMessageSMS extends Yup.MixedSchema {
	isPaymentInformation: boolean;
	documentType: string;
	smsNumber: string;
	dueDate: string;
	template: string;
	caseNote: string;
	templateNote: string;
}

export default () => {
	return Yup.object<DomainMessageSMS>().shape({
		isPaymentInformation: Yup.boolean(),
		documentType: Yup.string().required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
		),
		smsNumber: Yup.string()
		.typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD')
		.matches(/^\d{4,15}$/g, 'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS') 
		.nullable(),
		template: Yup.string(),
		caseNote: Yup.string().when(['isPaymentInformation', 'template'], {
			is: (isPaymentInformation, template) =>
				!isPaymentInformation && template == undefined,
			then: Yup.string().required(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
			),
		}),
	});
};
