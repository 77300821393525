import { useTranslation } from "react-i18next";
import { IFormikDatePickerKeys } from "../Interfaces/IFormikDatePickerKeys";

export const getDatePickersCol1 = (isEditing:boolean): IFormikDatePickerKeys[] => {
  const { t } = useTranslation(["US.COLLECTION.CASE", "US.COLLECTION.COMMON"], {
    useSuspense: true,
  });

  const DatePickersCol1:IFormikDatePickerKeys[] = [
    {
      name: "debtwarningDate",
      label: t("US.COLLECTION.CASE:CASEINFORMATION.DW_SENT_ON"),
      isDissabled: true,
    },
    {
      name: "boDate",
      label: t("US.COLLECTION.CASE:CASEINFORMATION.BO_DATE"),
      isDissabled: true,
    },
    {
      name: "dcfDate",
      label: t("US.COLLECTION.CASE:CASEINFORMATION.DCF_DATE"),
      isDissabled: true,
    },
    {
      name: "vtDate",
      label: t("US.COLLECTION.CASE:CASEINFORMATION.VT_DATE"),
      isDissabled: true,
    },
    {
      name: "lastBODate",
      label: t("US.COLLECTION.CASE:CASEINFORMATION.LAST_BO_DATE(VO_DATE)"),
      isDissabled: false,
    },
    {
      name: "obsoleteDate",
      label: t("US.COLLECTION.CASE:CASEINFORMATION.OBSOLETE_DATE"),
      isDissabled: false,
    },
    {
      name: "lastDistraintDate",
      label: t("US.COLLECTION.CASE:CASEINFORMATION.LAST_DISTRAINT_DATE"),
      isDissabled: true,
    },
    {
      name: "lastCourtDate",
      label: t("US.COLLECTION.CASE:CASEINFORMATION.LAST_COURT_DATE"),
      isDissabled: true,
    },
    {
      name: "migratedDate",
      label: t("US.COLLECTION.CASE:CASEINFORMATION.MIGRATED_DATE"),
      isDissabled: true,
    },
  ];

  return isEditing 
  ? DatePickersCol1
  :DatePickersCol1.map((picker:IFormikDatePickerKeys)=>({
    ...picker,
    isDissabled:true
  }));
};


export const getDatePickersCol2 = (isEditing:boolean): IFormikDatePickerKeys[] => {
  const { t } = useTranslation(["US.COLLECTION.CASE", "US.COLLECTION.COMMON"], {
    useSuspense: true,
  });

  const DatePickersCol2:IFormikDatePickerKeys[] = [
    {
      name: "objectionDate",
      label: t("US.COLLECTION.CASE:CASEINFORMATION.OBJECTION_DATE"),
      isDissabled: true,
    },
    {
      name: "interestStopDate",
      label: t("US.COLLECTION.CASE:CASEINFORMATION.INTEREST_TERMINATION_DATE"),
      isDissabled: false,
    },
    {
      name: "closeDate",
      label: t("US.COLLECTION.CASE:CASEINFORMATION.CLOSED_DATE"),
      isDissabled: true,
    }
  ];

  return isEditing 
  ? DatePickersCol2
  :DatePickersCol2.map((picker:IFormikDatePickerKeys)=>({
    ...picker,
    isDissabled:true
  }));
};