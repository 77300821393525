import { IEmailAttachment, IEmailAttachmentContent } from 'us.collection.case/reducers/Dashboard/Interfaces'

export const EmailAttachmentContent : IEmailAttachmentContent = {
    documentType : "",
    documentName : "",
    content : ""
}

export const EmailAttachment: IEmailAttachment = {
    data: [EmailAttachmentContent],
    isLoading: false,
    error: ""
}