import { Debtor } from "us.collection.debtor/constants/Actions";
import { IDebtorAction, SimilarDebtor } from "us.collection.debtor/Interfaces/Debtor";
import { IAPIAction } from "us.collection/interfaces";
const {
  GET_ADDRESS_INFO_START,
  GET_ADDRESS_INFO_SUCCESS,
  GET_ADDRESS_INFO_FAIL,
  GET_CREDITOR_INFO_FAIL,
  GET_CREDITOR_INFO_START,
  GET_CREDITOR_INFO_SUCCESS,
  GET_DEBTOR_HISTORY_START,
  GET_DEBTOR_HISTORY_SUCCESS,
  GET_DEBTOR_HISTORY_FAIL,
  GET_DEBTOR_OTHER_INFORMATION_START,
  GET_DEBTOR_OTHER_INFORMATION_SUCCESS,
  GET_DEBTOR_OTHER_INFORMATION_FAIL,
  SAVE_DEBTOR_DETAIL_START,
  SAVE_DEBTOR_DETAIL_SUCCESS,
  SAVE_DEBTOR_DETAIL_FAIL,
  GET_DEBTOR_CASE_INFORMATION_START,
  GET_DEBTOR_CASE_INFORMATION_SUCCESS,
  GET_DEBTOR_CASE_INFORMATION_FAIL,
  GET_DEBTOR_BADDEBT_INFO,
  GET_DEBTOR_BADDEBT_INFO_SUCCESS,
  GET_DEBTOR_BADDEBT_INFO_FAIL,
  GET_IF_DEBTOR_IS_BAD,
  GET_IF_DEBTOR_IS_BAD_SUCCESS,
  GET_IF_DEBTOR_IS_BAD_FAIL,
  GET_DEBTOR_CREDIT_INFO,
  GET_DEBTOR_CREDIT_INFO_SUCCESS,
  GET_DEBTOR_CREDIT_INFO_FAIL,
  GET_DEBTOR_POSTALCODE_START,
  GET_DEBTOR_POSTALCODE_SUCCESS,
  GET_DEBTOR_POSTALCODE_FAIL,
  GET_DEBTOR_MUNICIPALITY_START,
  GET_DEBTOR_MUNICIPALITY_SUCCESS,
  GET_DEBTOR_MUNICIPALITY_FAIL,
  CLEAR_POSTAL_DETAILS
} = Debtor;

export const getDebtorGeneralInfo = () => ({
  type: "GET_DEBTOR_INFO",
});

export const getDebtorGeneralInfoSuccess = (data: any) => ({
  type: "GET_DEBTOR_INFO_SUCCESS",
  payload: data,
});

export const getDebtorContactCategory = () => ({
  type: "GET_DEBTOR_CONTACT_CATEGORY",
});

export const getDebtorContactCategorySuccess = (data: any) => ({
  type: "GET_DEBTOR_CONTACT_CATEGORY_SUCCESS",
  payload: data,
});

export const addDebtorContactCategory = (data: any) => ({
  type: "ADD_DEBTOR_CONTACT_CATEGROY",
  payload: data,
});

export const addDebtorContactCategorySuccess = (data: any) => ({
  type: "ADD_DEBTOR_CONTACT_CATEGROY_SUCCESS",
  payload: data,
});

export const getDebtorAddressInfo = () => ({
  type: "GET_DEBTOR_ADDRESS_INFO",
});

export const getDebtorAddressInfoSuccess = (data: any) => ({
  type: "GET_DEBTOR_ADDRESS_INFO_SUCCESS",
  payload: data,
});

export const getDebtorEntHistory = () => ({
  type: "GET_DEBTOR_ENT_HISTORY",
});

export const getDebtorEntHistorySuccess = (data: any) => ({
  type: "GET_DEBTOR_ENT_HISTORY_SUCCESS",
  payload: data,
});

export const debtor: Readonly<IDebtorAction & IAPIAction> = {
  debtor: {
    save: (data) => ({
      type: SAVE_DEBTOR_DETAIL_START,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data: any) => ({
      type: SAVE_DEBTOR_DETAIL_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SAVE_DEBTOR_DETAIL_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    isBad: (caseNo) => ({
      type: GET_IF_DEBTOR_IS_BAD,
      payload: {
        caseNo: caseNo,
        isLoading: true,
      },
    }),
    isBadSuccess: (isBadDebtor) => ({
      type: GET_IF_DEBTOR_IS_BAD_SUCCESS,
      payload: {
        isBadDebtor: isBadDebtor,
        isLoading: false,
      },
    }),
    isBadFail: (error) => ({
      type: GET_IF_DEBTOR_IS_BAD_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  history: {
    getByDebtorNo: (debtorNumber) => ({
      type: GET_DEBTOR_HISTORY_START,
      payload: {
        debtorNumber,
        isLoading: true,
      },
    }),
    success: (data: any) => ({
      type: GET_DEBTOR_HISTORY_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_DEBTOR_HISTORY_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  badDebt: {
    get: (data) => ({
      type: GET_DEBTOR_BADDEBT_INFO,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data: any) => ({
      type: GET_DEBTOR_BADDEBT_INFO_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_DEBTOR_BADDEBT_INFO_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  address: {
    getByDebtorNo: (debtorNumber) => ({
      type: GET_ADDRESS_INFO_START,
      payload: {
        debtorNumber,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_ADDRESS_INFO_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_ADDRESS_INFO_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  creditor: {
    getByArNo: (arNo) => ({
      type: GET_CREDITOR_INFO_START,
      payload: {
        arNo,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_CREDITOR_INFO_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_CREDITOR_INFO_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  other: {
    getByArNo: (arNo) => ({
      type: GET_DEBTOR_OTHER_INFORMATION_START,
      payload: {
        arNo,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_DEBTOR_OTHER_INFORMATION_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_DEBTOR_OTHER_INFORMATION_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  case: {
    getByEntInfo: (
      similarDebtor: SimilarDebtor,
      debtorId: number = 0,
      creditorId: number = 0,
      arId: number = 0
    ) => ({
      type: GET_DEBTOR_CASE_INFORMATION_START,
      payload: {
        similarDebtor,
        debtorId,
        creditorId,
        arId,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_DEBTOR_CASE_INFORMATION_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_DEBTOR_CASE_INFORMATION_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  credit: {
    getByDebtorNo: (debtorNumber: number) => ({
      type: GET_DEBTOR_CREDIT_INFO,
      payload: {
        debtorNumber,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_DEBTOR_CREDIT_INFO_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_DEBTOR_CREDIT_INFO_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  postalCode: {
    search: (data) => ({
      type: GET_DEBTOR_POSTALCODE_START,
      payload: {
        data,
        isLoading: true
      }
    }),
    success: (data) => ({
      type: GET_DEBTOR_POSTALCODE_SUCCESS,
      payload: {
        data,
        isLoading: false
      }
    }),
    fail: (error) => ({
      type: GET_DEBTOR_POSTALCODE_FAIL,
      payload: {
        error,
        isLoading: false
      }
    }),
    reset: (data) => ({
      type: CLEAR_POSTAL_DETAILS,
      payload: {
        data,
        isLoading: true
      }
    })
  },
  minicipality: {
    search: (data) => ({
      type: GET_DEBTOR_MUNICIPALITY_START,
      payload: {
        data,
        isLoading: true
      }
    }),
    success: (data) => ({
      type: GET_DEBTOR_MUNICIPALITY_SUCCESS,
      payload: {
        data,
        isLoading: false
      }
    }),
    fail: (error) => ({
      type: GET_DEBTOR_MUNICIPALITY_FAIL,
      payload: {
        error,
        isLoading: false
      }
    }),
  }
};
