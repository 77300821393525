import Constants from "us.collection.case/constants";
import { InitialState } from "./State";
import { addMapStatusAndKey } from "us.collection.case/functions";
const { ACTIONS } = Constants;
const { Assets } = ACTIONS;
const {
  GET_ASSET,
  GET_ASSET_SUCCESS,
  GET_ASSET_FAIL,
  GET_ASSET_RESET,
  SAVE_REAL_ESTATE_ASSETS,
  SAVE_REAL_ESTATE_ASSETS_SUCCESS,
  SAVE_REAL_ESTATE_ASSETS_FAIL,
  CHANGE_DRAWER_STATUS,
  RESET_DRAWER_STATUS,
  DELETE_ASSET,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_FAIL,
  GET_REAL_ESTATE_ASSET,
  GET_REAL_ESTATE_ASSET_SUCCESS,
  GET_REAL_ESTATE_ASSET_FAIL,
  RESET_REAL_ESTATE_ASSETS,
  SAVE_PERSONAL_EFFECTS_ASSETS,
  SAVE_PERSONAL_EFFECTS_ASSETS_SUCCESS,
  SAVE_PERSONAL_EFFECTS_ASSETS_FAIL,
  GET_PERSONAL_EFFECTS_ASSET,
  GET_PERSONAL_EFFECTS_ASSET_SUCCESS,
  GET_PERSONAL_EFFECTS_ASSET_FAIL,
  SAVE_DOCUMENT_OF_TITLE_ASSETS,
  SAVE_DOCUMENT_OF_TITLE_ASSETS_SUCCESS,
  SAVE_DOCUMENT_OF_TITLE_ASSETS_FAIL,
  GET_DOCUMENT_OF_TITLE_ASSET,
  GET_DOCUMENT_OF_TITLE_ASSET_SUCCESS,
  GET_DOCUMENT_OF_TITLE_ASSET_FAIL,
  SAVE_VEHICLE_ASSETS,
  SAVE_VEHICLE_ASSETS_SUCCESS,
  SAVE_VEHICLE_ASSETS_FAIL,
  GET_VEHICLE_ASSET,
  GET_VEHICLE_ASSET_SUCCESS,
  GET_VEHICLE_ASSET_FAIL,
  SAVE_FINANCIAL_ASSETS,
  SAVE_FINANCIAL_ASSETS_SUCCESS,
  SAVE_FINANCIAL_ASSETS_FAIL,
  GET_FINANCIAL_ASSET,
  GET_FINANCIAL_ASSET_SUCCESS,
  GET_FINANCIAL_ASSET_FAIL,
  GET_POSTAL_DETAILS,
  GET_POSTAL_DETAILS_SUCCESS,
  GET_POSTAL_DETAILS_FAIL,
  RESET_POSTAL_DETAILS,
} = Assets;

export default (state: any = InitialState, action: any) => {
  switch (action.type) {
    case GET_ASSET:
      return Object.assign({}, state, {
        ...state,
        data: state.data,
        isFetching: true,
      });
    case GET_ASSET_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        data: addMapStatusAndKey(action.payload?.data),
        isFetching: false,
      });
    case GET_ASSET_FAIL:
      return Object.assign({}, state, {
        ...state,
        data: state.data,
        isFetching: false,
      });
    case GET_ASSET_RESET:
      return Object.assign({}, state, {
        ...state,
        data: [],
        isFetching: false,
      });
    case DELETE_ASSET:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case DELETE_ASSET_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
      });
    case DELETE_ASSET_FAIL:
      return Object.assign({}, state, {
        isFetching: false,
      });
    case SAVE_REAL_ESTATE_ASSETS:
      return Object.assign({}, state, {
        ...state,
        isFetching: true,
      });
    case SAVE_REAL_ESTATE_ASSETS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
      });
    case SAVE_REAL_ESTATE_ASSETS_FAIL:
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
      });
    case CHANGE_DRAWER_STATUS:
      return Object.assign({}, state, {
        ...state,
        drawerInfo: action.payload?.data,
      });
    case RESET_DRAWER_STATUS:
      return Object.assign({}, state, {
        ...state,
        drawerInfo: InitialState.drawerInfo,
      });
    case GET_REAL_ESTATE_ASSET:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: state.realEstateAssets,
        isREFetching: true,
      });
    case GET_REAL_ESTATE_ASSET_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: action.payload?.data,
        isREFetching: false,
      });
    case GET_REAL_ESTATE_ASSET_FAIL:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: state.data,
        isREFetching: false,
      });
    case RESET_REAL_ESTATE_ASSETS:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: { ...InitialState.realEstateAssets },
      });
    case SAVE_PERSONAL_EFFECTS_ASSETS:
      return Object.assign({}, state, {
        ...state,
        isFetching: true,
      });
    case SAVE_PERSONAL_EFFECTS_ASSETS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
      });
    case SAVE_PERSONAL_EFFECTS_ASSETS_FAIL:
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
      });
    case GET_PERSONAL_EFFECTS_ASSET:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: state.realEstateAssets,
        isREFetching: true,
      });
    case GET_PERSONAL_EFFECTS_ASSET_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: action.payload?.data,
        isREFetching: false,
      });
    case GET_PERSONAL_EFFECTS_ASSET_FAIL:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: state.data,
        isREFetching: false,
      });
    case SAVE_DOCUMENT_OF_TITLE_ASSETS:
      return Object.assign({}, state, {
        ...state,
        isFetching: true,
      });
    case SAVE_DOCUMENT_OF_TITLE_ASSETS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
      });
    case SAVE_DOCUMENT_OF_TITLE_ASSETS_FAIL:
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
      });
    case GET_DOCUMENT_OF_TITLE_ASSET:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: state.realEstateAssets,
        isREFetching: true,
      });
    case GET_DOCUMENT_OF_TITLE_ASSET_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: action.payload?.data,
        isREFetching: false,
      });
    case GET_DOCUMENT_OF_TITLE_ASSET_FAIL:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: state.data,
        isREFetching: false,
      });
    case SAVE_VEHICLE_ASSETS:
      return Object.assign({}, state, {
        ...state,
        isFetching: true,
      });
    case SAVE_VEHICLE_ASSETS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
      });
    case SAVE_VEHICLE_ASSETS_FAIL:
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
      });
    case GET_VEHICLE_ASSET:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: state.realEstateAssets,
        isREFetching: true,
      });
    case GET_VEHICLE_ASSET_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: action.payload?.data,
        isREFetching: false,
      });
    case GET_VEHICLE_ASSET_FAIL:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: state.data,
        isREFetching: false,
      });
    case SAVE_FINANCIAL_ASSETS:
      return Object.assign({}, state, {
        ...state,
        isFetching: true,
      });
    case SAVE_FINANCIAL_ASSETS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
      });
    case SAVE_FINANCIAL_ASSETS_FAIL:
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
      });
    case GET_FINANCIAL_ASSET:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: state.realEstateAssets,
        isREFetching: true,
      });
    case GET_FINANCIAL_ASSET_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: action.payload?.data,
        isREFetching: false,
      });
    case GET_FINANCIAL_ASSET_FAIL:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: state.data,
        isREFetching: false,
      });

    case GET_POSTAL_DETAILS:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: {
          ...action.payload?.data?.values,
          postalArea: "",
          municipalityCode: "",
          municipalityArea: "",
          financialInstitutionPostalName: "",
          financialInstitutionMunicipalityCode: "",
          financialInstitutionMunicipalityName: "",
        },
        isREFetching: false,
      });
    case GET_POSTAL_DETAILS_SUCCESS:
      const { postalCode, postalPlace, countyCode, countyName } =
        action.payload?.data && action.payload?.data[0];
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: {
          ...state.realEstateAssets,
          // postalCode: postalCode ?? "",
          postalArea: postalPlace ?? "",
          municipalityCode: countyCode ?? "",
          municipalityArea: countyName ?? "",
          financialInstitutionPostalName: postalPlace ?? "",
          financialInstitutionMunicipalityCode: countyCode ?? "",
          financialInstitutionMunicipalityName: countyName ?? "",
        },
        isREFetching: false,
      });
    case GET_POSTAL_DETAILS_FAIL:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: { ...state.realEstateAssets },
        isREFetching: false,
      });
    case RESET_POSTAL_DETAILS:
      return Object.assign({}, state, {
        ...state,
        realEstateAssets: InitialState.realEstateAssets,
        isREFetching: false,
      });
    default:
      return state;
  }
};
