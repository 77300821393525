import { template, values } from 'lodash';
import * as Yup from 'yup';

export interface IAddPartPayment extends Yup.MixedSchema {
    finalSettlement: boolean,
    remainingBalance: number,
    remainBalanceBMD: number,
    caseRemainingBalance: number,
    partPaymentDate: string,
    firstInstallmentDate: string,
    caseNote: string,
    selectPeriod: string,
    comunicationMethod: string,
    smsNo: number,
    email: string,
    SMSNoOrEmail: string,
    installmentAmount: number,
    noOfInstallment: number,
    noOfInstallmentDuplicate: number,
    installmentAmountBMD: number,
    noOfInstallmentBMD: number,
    existingPartPayment: any,
    installmentGenarate: any,
    BMDs: Array<any>
}
const AddPartPaymentValidation = Yup.object<IAddPartPayment>().shape({
    remainBalanceBMD: Yup.number(),
    caseRemainingBalance: Yup.number(),
    remainingBalance: Yup.number()
        .when(
            ["remainBalanceBMD"],
            (remainBalanceBMD: any, Yup: any) => Yup.min(remainBalanceBMD, 'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD')
        )
        .when(
            ["caseRemainingBalance"],
            (caseRemainingBalance: any, Yup: any) => Yup.max(caseRemainingBalance, 'US.COLLECTION.VALIDATIONS:REQUIRED.AMOUNT_SHOULD_BE_LESS_THAN_THE_CASE_REMAINING_BALANCE')
        ),
    installmentAmountBMD: Yup.number(),
    installmentAmount: Yup.number()
        .when(
            ["installmentAmountBMD"],
            (installmentAmountBMD: any, Yup: any) => Yup.min(installmentAmountBMD, 'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD')
        )
        .when(
            ["remainingBalance"],
            (remainingBalance: any, Yup: any) => Yup.max(remainingBalance, 'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD')
        ),
    noOfInstallmentDuplicate: Yup.number(),
    noOfInstallmentBMD: Yup.number(),
    noOfInstallment: Yup.string()
        .when(
            ["noOfInstallmentBMD", "noOfInstallmentDuplicate"],
            {
                is: (noOfInstallmentDuplicate,noOfInstallmentBMD) => noOfInstallmentDuplicate <  noOfInstallmentBMD || noOfInstallmentDuplicate == 0,
                then: Yup.string().test("noOfInstallment", 'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD', (val: any) => {                          
                                return false;
                    }),                  
            }
        ),
    comunicationMethod: Yup.string(),
    emailAddress: Yup.string()
        .when(
            ["comunicationMethod"],
            {
                is: (comunicationMethod) =>  comunicationMethod === "Email",
                then: Yup.string().required('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD')
                                  .email('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'),
            }
        ),
    smsNumber: Yup.number()
        .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD').nullable()
        .when(
            ["comunicationMethod"],
            {
                is: (comunicationMethod) => comunicationMethod === "SMS",
                then: Yup.number().required('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD')
                                  .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD').nullable()
                                  .test(
                                    "smsNumber",
                                    'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
                                    (val: any) => {
                                      val = typeof val == "undefined" ? "" : val;
                                      return /(^4|^9)/.test(val?.toString()) || val?.toString() === "";
                                    }
                                  ),
            }
        ),
});

export default AddPartPaymentValidation;
