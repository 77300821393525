import * as Yup from 'yup';

interface DomainMessageNote extends Yup.MixedSchema {
	dueDate: string;
	internal: boolean;
	copyAsDebtorNotes: boolean;
	communicationMethod: string;
	caseNote: string;
}

export default () => {
	return Yup.object<DomainMessageNote>().shape({
		communicationMethod: Yup.string().required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_SELECT_A_VALUE'
		),
		caseNote: Yup.string().required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
		),
	});
};
