import { put, call, takeLatest } from "redux-saga/effects";
import { debtor } from "us.collection.debtor/actions";
import { entity } from "us.common/actions";
import { Debtor } from "us.collection.debtor/constants/Actions";
import * as API from "us.collection.debtor/services";
import { $MessageBox } from "us.common/components";
import _ from "lodash";
import { appInsights } from "us.helper";

const {
  GET_DEBTOR_POSTALCODE_START,
  GET_ADDRESS_INFO_START,
  GET_CREDITOR_INFO_START,
  GET_DEBTOR_HISTORY_START,
  GET_DEBTOR_OTHER_INFORMATION_START,
  SAVE_DEBTOR_DETAIL_START,
  GET_DEBTOR_CASE_INFORMATION_START,
  GET_DEBTOR_BADDEBT_INFO,
  GET_DEBTOR_CREDIT_INFO,
  GET_DEBTOR_MUNICIPALITY_START,
} = Debtor;
const DebtorSagas = {
  debtor: {
    getAddress: function* (action: any) {
      const params = action.payload;
      try {
        const data = yield call(API.debtor.debtor.getAddress, params);
        if (data?.isError || data?.statusCode === 404 || _.isEmpty(data)) {
          yield put(debtor.address.fail(data));
        } else {
          yield put(debtor.address.success(data));
        }
      } catch (error) {
        yield put(debtor.address.fail(error));
        console.error(error);
        appInsights.trackException(
          `Debtor getAddress Saga Exception. - ${error}`
        );
      }
    },
    getHistory: function* (action: any) {
      const params = action.payload;
      try {
        const data = yield call(API.debtor.debtor.getHistory, params);
        if (data?.isError || data?.statusCode === 404 || _.isEmpty(data)) {
          yield put(debtor.history.fail(data));
        } else {
          yield put(debtor.history.success(data));
        }
      } catch (error) {
        yield put(debtor.history.fail(error));
        console.error(error);
        appInsights.trackException(
          `Debtor getHistory Saga Exception. - ${error}`
        );
      }
    },
    getOtherInfo: function* (action: any) {
      const params = action.payload;
      try {
        const data = yield call(API.debtor.debtor.getOtherInfo, params);
        if (data?.isError || data?.statusCode === 404) {
          yield put(debtor.other.fail(data));
        } else {
          yield put(debtor.other.success(data));
        }
      } catch (error) {
        yield put(debtor.other.fail(error));
        appInsights.trackException(
          `Debtor getOtherInfo Saga Exception. - ${error}`
        );
      }
    },
    save: function* (action: any) {
      const params = action.payload;
      try {
        const data = yield call(API.debtor.debtor.save, params);
        yield put(debtor.debtor.success(data));
        if (data?.hasOwnProperty("entId")) {
          $MessageBox(
            "success",
            "US.COLLECTION.DEBTOR:GENERAL.SUCCESSFULLY_EDITED",
            "",
            ""
          );
          yield put(entity.get(data.entRoleId));
        }
      } catch (error) {
        yield put(debtor.debtor.fail(error));
        appInsights.trackException(
          `Debtor data save Saga Exception. - ${error}`
        );
      }
    },
    getBadDebt: function* (action: any) {
      const params = action.payload.data;
      try {
        const data = yield call(API.debtor.debtor.getBadDebt, params);
        yield put(debtor.badDebt.success(data));
      } catch (error) {
        yield put(debtor.badDebt.fail(error));
        appInsights.trackException(
          `Debtor getBadDebt Saga Exception. - ${error}`
        );
      }
    },
    isBadDebtor: function* (action: any) {
      const params = action.payload.data;
      try {
        const data = yield call(API.debtor.debtor.isBadDebtor, params);
        if (data?.errorText !== "") {
          yield put(debtor.isBadDebtor.fail(data));
        } else {
          yield put(
            debtor.isBadDebtor.success({ ...data, isBadDebtor: false })
          );
        }
      } catch (error) {
        yield put(debtor.isBadDebtor.fail(error));
        appInsights.trackException(
          `Debtor isBadDebtor Saga Exception. - ${error}`
        );
      }
    },
    getCreditInfo: function* (action: any) {
      const params = action?.payload ?? {};
      try {
        const data = yield call(API.debtor.debtor.getCreditInfo, params);
        if (data?.statusCode != 404) {
          yield put(debtor.credit.success(data));
        } else {
          yield put(debtor.credit.fail(data?.message));
        }
      } catch (error) {
        yield put(debtor.credit.fail(error));
        appInsights.trackException(
          `Debtor getCreditInfo Saga Exception. - ${error}`
        );
      }
    },
  },
  creditors: {
    get: function* (action: any) {
      const params = action.payload;
      try {
        const data = yield call(API.debtor.creditors.get, params);
        if (
          data?.statusCode === 401 ||
          data?.statusCode === 404 ||
          _.isEmpty(data)
        ) {
          yield put(debtor.creditor.fail(data));
        } else {
          yield put(debtor.creditor.success(data));
        }
      } catch (error) {
        yield put(debtor.creditor.fail(error));
        console.error(error);
        appInsights.trackException(`getCreditors Saga Exception. - ${error}`);
      }
    },
  },
  case: {
    get: function* (action: any) {
      const params = action.payload;
      try {
        const data = yield call(API.debtor.case.get, params);
        if (
          data?.isError ||
          data?.statusCode === 404 ||
          data?.hasOwnProperty("errors")
        ) {
          yield put(debtor.case.fail(data));
        } else {
          yield put(debtor.case.success(data));
        }
      } catch (error) {
        yield put(debtor.case.fail(error));
        appInsights.trackException(
          `get Creditor Case Saga Exception. - ${error}`
        );
      }
    },
  },
  postalcode: {
    search: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.debtor.postelArea.search, params);
        if (Array.isArray(data)) {
          const { postalCode, postalPlace, countyCode, countyName } = data[0];
          yield put(
            entity.same.update({
              ...params?.formData,
              zipCode: postalCode,
              zipName: postalPlace,
              municipalityCode: countyCode,
              municipalityName: countyName,
              isExistingPostalCode: true,
              isExistingMunicipalityCode: true,
            })
          );
        } else {
          yield put(
            entity.same.update({
              ...params?.formData,
              zipName: "",
              municipalityCode: "",
              municipalityName: "",
              isExistingPostalCode: false,
            })
          );
        }
      } catch (error) {
        yield put(
          entity.same.update({
            ...params?.formData,
            zipName: "",
            municipalityCode: "",
            municipalityName: "",
            isExistingPostalCode: false,
          })
        );
        appInsights.trackException(
          `PostalCode search Saga Exception. - ${error}`
        );
      }
    },
  },
  municipality: {
    search: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.debtor.municipals.search, params);
        const { municipalityName } = data[0];
        if (Array.isArray(data)) {
          yield put(
            entity.same.update({
              ...params?.formData,
              municipalityName,
              isExistingMunicipalityCode: true,
            })
          );
          yield put(debtor.minicipality.success(data));
        } else {
          yield put(
            entity.same.update({
              ...params?.formData,
              municipalityName: "",
              isExistingMunicipalityCode: false,
            })
          );
        }
      } catch (error) {
        yield put(
          entity.same.update({
            ...params?.formData,
            municipalityName: "",
            isExistingMunicipalityCode: false,
          })
        );
        appInsights.trackException(
          `Municipality search Saga Exception. - ${error}`
        );
      }
    },
  },
};

export default [
  takeLatest(GET_ADDRESS_INFO_START, DebtorSagas.debtor.getAddress),
  takeLatest(GET_CREDITOR_INFO_START, DebtorSagas.creditors.get),
  takeLatest(GET_DEBTOR_HISTORY_START, DebtorSagas.debtor.getHistory),
  takeLatest(
    GET_DEBTOR_OTHER_INFORMATION_START,
    DebtorSagas.debtor.getOtherInfo
  ),
  takeLatest(SAVE_DEBTOR_DETAIL_START, DebtorSagas.debtor.save),
  takeLatest(GET_DEBTOR_CASE_INFORMATION_START, DebtorSagas.case.get),
  takeLatest(GET_DEBTOR_BADDEBT_INFO, DebtorSagas.debtor.getBadDebt),
  takeLatest(GET_DEBTOR_CREDIT_INFO, DebtorSagas.debtor.getCreditInfo),
  takeLatest(GET_DEBTOR_POSTALCODE_START, DebtorSagas.postalcode.search),
  takeLatest(GET_DEBTOR_MUNICIPALITY_START, DebtorSagas.municipality.search),
];
