import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { INavigationData, IRootState } from 'us.collection/interfaces';
import { IntlDate } from 'us.common/functions';
import Common from 'us.common';
import { useTranslation } from 'react-i18next';
import * as Actions from 'us.collection.creditor/actions';
import { $Tag } from 'us.common/components';
import { IGeneral } from 'us.collection.creditor/components/Dashboard/Interfaces';
import { checkValue } from 'us.collection.creditor/functions';
import './General.scss';
import { BoxIcons, IconTypes, EditOutlined } from 'us.icons';
import {
	RouteComponentProps,
	matchPath,
	withRouter,
	useHistory,
} from 'react-router-dom';

const { creditor } = Actions;
const { general } = creditor;
const { get } = general;
const { $Skeleton, $Button, $Tooltip, $Typography } = Common.Components;
const { Paragraph } = $Typography;

const Home: React.FC<IGeneral> = (props) => {
	const { t } = useTranslation(['US.COLLECTION.CREDITOR'], {
		useSuspense: true,
	});
	const {
		currentLanguage,
		get,
		general,
		location,
		metaData,
		currentDateFormat,
	} = props;

	const { isLoading, data } = metaData;

	const state = location.state as INavigationData;

	const navigate = (url: string): void => {
		window.scrollTo(0, 0);
		const { params }: any = matchPath(props.location.pathname, {
			path: '/creditor/:Id/:module?',
			exact: false,
			strict: false,
		});
		let id = params['Id'];
		props.history.push(`/creditor/${id}/${url}`, state);
	};

	return (
		<div className='widget creditor-basic-info'>
			<$Skeleton
				loading={general.isLoading || isLoading}
				active
				paragraph={{ rows: 4 }}>
				<div className='widget-body px-0'>
					<div className='mb-3'>
						{general.data?.extendedFields?.CreditorStatus?.toLowerCase() ===
						'true' ? (
							<$Tag className='tag-status-open'>
								<span
									style={{
										fontSize: 14,
										fontWeight: 'normal',
									}}>
									{t(
										'US.COLLECTION.CREDITOR:BASICINFO.ACTIVE'
									)}
								</span>
							</$Tag>
						) : (
							<$Tag className='tag-status-close'>
								<span
									style={{
										fontSize: 14,
										fontWeight: 'normal',
									}}>
									{t(
										'US.COLLECTION.CREDITOR:BASICINFO.INACTIVE'
									)}
								</span>
							</$Tag>
						)}
					</div>

					<div className='d-flex align-items-center mb-2'>
						<BoxIcons
							type={
								IconTypes.BOX_ICON
							}
							name='creditor'
							className='creditor-icon'
						/>
						<div className='ml-2'>
							<span className='d-flex align-items-center'>
								<strong>
									{
										general
											?.data
											?.pid
									}
								</strong>
								<$Tooltip
									placement='right'
									title={t(
										'US.COLLECTION.COMMON:COMMON.EDIT'
									)}>
									<$Button
										className='ml-2'
										size='small'
										onClick={() =>
											navigate(
												'creditor-information'
											)
										}>
										<EditOutlined />
									</$Button>
								</$Tooltip>
							</span>
							<strong className='d-block'>
								{
									general
										?.data
										?.fullName
								}
							</strong>
						</div>
					</div>
					<div className='dom-amt-list-table'>
						{checkValue(
							general.data,
							'idNo'
						) && (
							<div className='list-table-row'>
								<div
									className='list-table-col pb-2 pr-2 bui-label'
									style={{
										minWidth: 100,
									}}>
									{t(
										'US.COLLECTION.CREDITOR:BASICINFO.COMPANYID'
									)}
								</div>
								<div className='list-table-col pb-3'>
									{
										general
											.data
											?.idNo
									}
								</div>
							</div>
						)}
						{checkValue(
							general.data,
							'bankAccountNo'
						) && (
							<div className='list-table-row'>
								<div className='list-table-col pb-2 pr-2 bui-label'>
									{t(
										'US.COLLECTION.CREDITOR:BASICINFO.ACCOUNT'
									)}
								</div>
								<div className='list-table-col pb-3'>
									{
										general
											.data
											?.bankAccountNo
									}
								</div>
							</div>
						)}
						{checkValue(
							general.data
								?.extendedFields,
							'remitAccountNo'
						) && (
							<div className='list-table-row'>
								<div className='list-table-col pb-2 pr-2 bui-label'>
									{t(
										'US.COLLECTION.CREDITOR:BASICINFO.REMITACCOUNTNO'
									)}
								</div>
								<div className='list-table-col pb-3'>
									{
										general
											.data
											?.extendedFields
											?.remitAccountNo
									}
								</div>
							</div>
						)}
						{(checkValue(
							general.data
								?.extendedFields,
							'ProductNo'
						) ||
							checkValue(
								general.data
									?.extendedFields,
								'ProductName'
							)) && (
							<div className='list-table-row'>
								<div className='list-table-col pb-2 pr-2 bui-label'>
									{t(
										'US.COLLECTION.CREDITOR:BASICINFO.PRODUCT'
									)}
								</div>
								<div className='list-table-col pb-3'>
									{checkValue(
										general
											.data
											?.extendedFields,
										'ProductNo'
									) && (
										<span>
											{
												general
													.data
													?.extendedFields
													?.ProductNo
											}
										</span>
									)}
									{checkValue(
										general
											.data
											?.extendedFields,
										'ProductName'
									) && (
										<span>
											{' '}
											-{' '}
											{
												general
													.data
													?.extendedFields
													?.ProductName
											}
										</span>
									)}
								</div>
							</div>
						)}
						{(checkValue(
							general.data
								?.extendedFields,
							'creditorGroupId'
						) ||
							checkValue(
								general.data
									?.extendedFields,
								'GroupName'
							)) && (
							<div className='list-table-row'>
								<div className='list-table-col pb-2 pr-2 bui-label'>
									{t(
										'US.COLLECTION.CREDITOR:BASICINFO.CREDITORGROUP'
									)}
								</div>
								<div className='list-table-col pb-3'>
									{checkValue(
										general
											.data
											?.extendedFields,
										'creditorGroupId'
									) && (
										<span>
											{
												general
													.data
													?.extendedFields
													?.creditorGroupId
											}
										</span>
									)}
									{checkValue(
										general
											.data
											?.extendedFields,
										'GroupName'
									) && (
										<span>
											{' '}
											-{' '}
											{
												general
													.data
													?.extendedFields
													?.GroupName
											}
										</span>
									)}
								</div>
							</div>
						)}
						{checkValue(
							general.data
								?.extendedFields,
							'RegDate'
						) && (
							<div className='list-table-row'>
								<div className='list-table-col pb-2 pr-2 bui-label'>
									{t(
										'US.COLLECTION.CREDITOR:BASICINFO.REGDATE'
									)}
								</div>
								<div className='list-table-col pb-3'>
									{IntlDate(
										general
											.data
											?.extendedFields
											?.RegDate,
										currentLanguage,
										currentDateFormat
									)}
								</div>
							</div>
						)}
						{checkValue(
							general.data
								?.extendedFields,
							'CreatedUser'
						) && (
							<div className='list-table-row'>
								<div className='list-table-col pb-2 pr-2 bui-label'>
									{t(
										'US.COLLECTION.CREDITOR:BASICINFO.CREATEDUSER'
									)}
								</div>
								<div className='list-table-col pb-3'>
									<div
										style={{
											display: 'grid',
										}}>
										<Paragraph
											ellipsis>
											{
												general
													.data
													?.extendedFields
													?.CreatedUser
											}
										</Paragraph>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</$Skeleton>
		</div>
	);
};

const mapStateToProps = (state: IRootState) => {
	const { common, creditor, domainView } = state;
	const { currentLanguage, currentDateFormat } = common;
	const { general } = creditor;
	const { metaData } = domainView;
	return {
		currentLanguage,
		currentDateFormat,
		general,
		metaData,
	};
};
const mapDispatchToProps = {
	get,
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
