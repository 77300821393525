import { SearchFields } from "us.common/components/SelectDebtor/Components/Constants";

export function DebtorSearch(this: any) {
  return {
    SearchValue: this.firstSearchValue,
    SecValue: this.otherSearchValues.join(' AND '),
    Suggest: this.isNumeric ? 3 : 0,
    FieldType: this.fieldType,
    SearchEntityState: SearchFields.ALL_ENTITY,
    Hit: 1,
  };
}
