export enum CaseDocuments {
    GET_CASE_DOCUMENTS = 'GET_CASE_DOCUMENTS',
    GET_CASE_DOCUMENTS_SUCCESS = 'GET_CASE_DOCUMENTS_SUCCESS',
    GET_CASE_DOCUMENTS_FAIL = 'GET_CASE_DOCUMENTS_FAIL',

    GET_CASE_DOCUMENT_CONTENT = 'GET_CASE_DOCUMENT_CONTENT',
    GET_CASE_DOCUMENT_CONTENT_SUCCESS = 'GET_CASE_DOCUMENT_CONTENT_SUCCESS',
    GET_CASE_DOCUMENT_CONTENT_FAIL = 'GET_CASE_DOCUMENT_CONTENT_FAIL',

    DELETE_CASE_DOCUMENT = 'DELETE_CASE_DOCUMENT',
    DELETE_CASE_DOCUMENT_SUCCESS = 'DELETE_CASE_DOCUMENT_SUCCESS',
    DELETE_CASE_DOCUMENT_FAIL = 'DELETE_CASE_DOCUMENT_FAIL',
    
    UPLOAD_DOCUMENTS = 'UPLOAD_DOCUMENTS',
    UPLOAD_DOCUMENTS_SUCCESS = 'UPLOAD_DOCUMENTS_SUCCESS',
    UPLOAD_DOCUMENTS_FAIL = 'UPLOAD_DOCUMENTS_FAIL',

    GET_DOCUMENT_CATEGORIES = 'GET_DOCUMENT_CATEGORIES',
    GET_DOCUMENT_CATEGORIES_SUCCESS = 'GET_DOCUMENT_CATEGORIES_SUCCESS',
    GET_DOCUMENT_CATEGORIES_FAIL = 'GET_DOCUMENT_CATEGORIES_FAIL',
    
    GET_UPLOAD_FILE_RULES = 'GET_UPLOAD_FILE_RULES',
    GET_UPLOAD_FILE_RULES_SUCCESS = 'GET_UPLOAD_FILE_RULES_SUCCESS',
    GET_UPLOAD_FILE_RULES_FAIL = 'GET_UPLOAD_FILE_RULES_FAIL',

    MANAGE_PREVIEW_DRAWER = 'MANAGE_PREVIEW_DRAWER',
    MANAGE_UPLOAD_DRAWER = 'MANAGE_UPLOAD_DRAWER'
  }
  