import { EntityFollowUpPanel } from "us.collection.case/constants";

/**
 * @function
 * @description - return class name related to the panel
 * @param {string} panelName
 * @returns {string} class name
 */
export const getColorByPanelName = (panelName: string): string => {
  switch (panelName) {
    case EntityFollowUpPanel.DUE:
      return "due";
    case EntityFollowUpPanel.UPCOMING:
      return "upc";
    case EntityFollowUpPanel.OTHER:
      return "ftm";
    default:
      return "";
  }
};
