export const getActivityHistoryList = () => ({
    type: 'GET_ACTIVITY_HISTORY'
});

export const getActivityHistorySuccess = (data: any) => ({
    type: 'GET_ACTIVITY_HISTORY_SUCCESS',
    payload: data
});

export const getActivitiesData = () => ({
    type: 'GET_ACTIVITY_DATA'
});

export const getActivityDataSuccess = (data: any) => ({
    type: 'GET_ACTIVITY_DATA_SUCCESS',
    payload: data
});

export const getRoutinesAndActivities = () => ({
    type: 'GET_ROTINES_AND_ACTIVITIES'
});

export const getRoutinesAndActivitiesSuccess = (data: any) => ({
    type: 'GET_ROTINES_AND_ACTIVITIES_SUCCESS',
    payload: data
});

export * from './History'