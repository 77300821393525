import { PhoneNumberTypes } from "us.common/constants";

export enum DateFormats {
  REQ = "YYYY-MM-DD",
  NIN = "DDMMYY",
  MIN_DATE = "0001-01-01",
}

export enum Types {
  CREDITOR = "CRE",
}

export enum DebtorType {
  COMPANY = "N",
  PERSON = "P",
}

export const initPhoneNumberFields = {
  [PhoneNumberTypes.HOME]: "",
  [PhoneNumberTypes.MOBILE]: "",
  [PhoneNumberTypes.SMS]: "",
  [PhoneNumberTypes.WORK]: "",
};
