import React, { useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import Common from "us.common";
import "../../Home.scss";
import ExcelExporter from "us.collection/components/ExcelGenerator";
import { RootState } from "us.helper/types/RootState";
import { handleGoBack } from "us.helper/utility";
import {
  getCaseList,
  getExcelExportList,
} from "us.collection.creditor/components/CreditorCases/Functions";
import { ExcelColumnType } from "us.collection.creditor/components/CreditorCases/Interfaces";
import { EXCEL_COLUMNS, SearchDateOptions, SearchDateTypes } from "us.collection.creditor/components/CreditorCases/Constants";
import { useHistory } from "react-router-dom";
import { $Button, $Select } from "us.common/components";
import { SearchOutlined, ReloadOutlined } from "us.icons";
import { CREDITOR_CASES_INITIAL_VALUES } from "us.collection.creditor/constants";
import * as Actions from "us.collection.creditor/actions";
import { SearchCreditorCases } from "us.collection.creditor/components/CreditorCases/Repository";
const {
  $PageHeader,
  $Affix,
  $RangePicker,
  $Radio,
  $Divider,
  $Switch,
  $Checkbox,
  $Tooltip,
} = Common.Components;
/**
 * @description - Creditor Case Header View
 * @link Design Document - https://unicorn-solutions.atlassian.net/l/cp/0sYQ2euQ
 * @author Lashini Ayesha <lashinia@unicorn-solutions.com>
 * @since 24/05/2023
 */

const CreditorCases: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation([
    "US.COLLECTION.CREDITOR",
    "US.COLLECTION.COMMON",
    "US.COLLECTION.VALIDATIONS",
  ]);
  const history = useHistory();
  const { currentDateFormat, caseList, currentCurrency, currentLanguage, general, getCreditorCaseList} =
    props;

  const { setFieldValue, values, handleSubmit } = useFormikContext<any>();

  const ExcelExportComponent = useMemo(() => {
    return (
      <ExcelExporter
        dataSet={getExcelExportList(getCaseList(caseList.data, values), currentDateFormat)}
        columns={EXCEL_COLUMNS.map((column: ExcelColumnType) => {
          return {
            ...column,
            title: t(column.title),
          };
        })}
        name={`CaseList`}
        format={{
          currentDateFormat,
          currentCurrency,
          currentLanguage,
        }}
      />
    );
  }, [caseList, values, currentDateFormat]);

  const refreshButtonHandler = () => {
    const { pid } = general.data; 
    if (pid) {
      const requestParams = SearchCreditorCases.call({
        ...CREDITOR_CASES_INITIAL_VALUES,
        creditorId: pid,
      });     
      getCreditorCaseList && getCreditorCaseList(requestParams);
    }
  };
  
  return (
    <$Affix offsetTop={80}>
      <div className="page-header header-border pr-0" id="page-header">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <$PageHeader
              className="px-0"
              title={t("US.COLLECTION.CREDITOR:CREDITOR_CASES.CREDITOR_CASES")}
              onBack={() => handleGoBack(history)}
              style={{ width: 218 }}
            />
            <div className="d-flex align-items-center mr-4">
              <div className="mr-1">
                <$Tooltip title={t("US.COLLECTION.CREDITOR:CREDITOR_CASES.SELECT_DATE_RANGE")} placement="top">
                  <span>
                    <$RangePicker
                      name="caseDateRange"
                      size="small"
                      picker="date"
                      minuteStep={15}
                      order={false}
                      currentTimeFormat={currentDateFormat}
                      onOpenChange={(isOpen: boolean, e:any) => {
                        if (isOpen) {
                          setFieldValue("caseDateRange", undefined)
                        } 
                      }}
                    />
                  </span>
                </$Tooltip>
              </div>
              <div className="mr-1">
                <span>
                  <$Select
                      name="searchDateType"
                      allOption={false}
                      optionText="label"
                      optionValue="value"
                      options={SearchDateOptions.map((item) => ({
                        ...item,
                        label: t(`US.COLLECTION.CREDITOR:${item.label}`),
                      }))}
                      defaultValue={SearchDateTypes.REG_DATE}
                      onSearchBy={["label"]}
                      dropdownMatchSelectWidth={false}
                    />
                  </span>
              </div>
              <div>
                <span>
                    <$Button
                      type="default"
                      size="small"
                      className="mr-2"
                      icon={<SearchOutlined />}
                      onClick={()=>handleSubmit()}
                    />
                  </span>
              </div>
            </div>
            
            <div>
              <$Tooltip
                title={t("US.COLLECTION.COMMON:COMMON.OPEN")}
                placement="top"
              >
                <span>
                  <$Checkbox
                    name="isCaseOpen"
                    id="isCaseOpen"
                    onChange={(e:any) => {
                      setFieldValue("isCaseOpen", e.target.checked);
                      handleSubmit()
                    }}
                    checked={values.isCaseOpen}
                    className="ml-0 mr-0 checkbox-case-open"
                  />
                </span>
              </$Tooltip>
              <$Tooltip
                title={t("US.COLLECTION.COMMON:COMMON.CLOSE")}
                placement="top"
              >
                <span>
                  <$Checkbox
                    id="isCaseClosed"
                    name="isCaseClosed"
                    checked={values.isCaseClosed}
                    onChange={(e:any) => {
                      setFieldValue("isCaseClosed", e.target.checked);
                      handleSubmit()
                    }}
                    className="ml-0 mr-0 checkbox-case-close"
                  />
                </span>
              </$Tooltip>
            </div>
          </div>
          <div className="pr-3 d-flex align-items-center justify-content-end flex-grow-1">
            <div className="d-flex align-items-center">
              <div className="mr-2">
                <$Switch
                  name="isCollapsedAll"
                  checked={values.isCollapsedAll}
                  style={{marginTop:"-4px"}}
                />
              </div>
              <div className="flex-fill">
                {t("US.COLLECTION.CREDITOR:CREDITOR_CASES.COLLAPSE_ALL")}
              </div>
            </div>
            <$Divider className="bui-devider" type="vertical" />
            {ExcelExportComponent}
            <$Tooltip title={t('US.COLLECTION.COMMON:COMMON.REFRESH')}>
              <$Button
                type='dashed'
                size='small'
                onClick={refreshButtonHandler}
                icon={<ReloadOutlined />}
                className='mx-2'
              />
            </$Tooltip>
          </div>
        </div>
      </div>
    </$Affix>
  );
};

const mapStateToProps = (state: RootState) => {
  const { common, creditorCases, creditor } = state;
  const { currentDateFormat, currentCurrency, currentLanguage } = common;
  const { caseList } = creditorCases;
  const { general } = creditor;
  return {
    currentDateFormat,
    caseList,
    currentCurrency,
    currentLanguage,
    general
  };
};

const { creditorCases } = Actions;
const { caseList } = creditorCases;

const mapDispatchToProps = { getCreditorCaseList: caseList.get };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreditorCases);
