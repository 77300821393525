import { ICase } from 'us.collection.transactions/interfaces';
import { appInsights } from 'us.helper';
/**
 * @function
 * @description - Check the Source case is select as Destination and return the check status
 * @param {destinationCaseNo} caseno - Destination case no
 * @param {ICase[]} selectedCases - list of cases selected in the Home view
 * @param {(field: string, value: any, shouldValidate?: boolean) => void} setFieldValue - formik setFieldValue function
 * @return {*}  {selectedCases: ICase[] - list of all source case with property selectAsDestination
 */
export const IsSourceCaseAllowAsDestinationCase = (
  destinationCaseNo: string,
  selectedCases: ICase[],
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
): any => {
  try {
    const updatedSelectedCases = selectedCases.map(({ caseId }, index) => {
      return {
        ...selectedCases[index],
        selectAsDestination: caseId === destinationCaseNo,
      };
    });
    setFieldValue('selectedCases', updatedSelectedCases, false);
  } catch (error) {
    appInsights.trackException(
      `Error in add case and subcases drawerStatusHandler function. - ${error}`
    );
  }
};
