import { IntlDate, IntlCurrency } from "us.common/functions";

export const columnGenerator = (columns: any) => {
    let columnNames: any = [];
    columns?.map((column: any) => {
        column?.title?.length > 0 && columnNames?.push({
            title: column?.title,
            dataIndex: column?.dataIndex
        });
    });
    return columnNames
}

const formatter = (value: any, valueType: any, format: any) => {
    let result
    switch (valueType) {
        case 'Currency':
            result = IntlCurrency(value, format?.currentLanguage, format?.currentCurrency);
            break;
        case 'Date':
            result = IntlDate(value, format?.currentLanguage, format?.currentDateFormat);
            break;
        case 'Text':
            result = value ?? '';
            break;
        default:
            result = value ?? '';
    }
    return result;
}

export const columnDataGenerator = (data: any, columns: any, format: any) => {
    let columnDatas: any = [];
    let temData: any = [];
    data?.map((item: any) => {
        temData = [];
        columns?.map((col: any) => {
            if (col?.hasOwnProperty('config')) {
                if (eval(`${item[col?.dataIndex]}${col?.config[col?.dataIndex]}`)) {
                    temData?.push({
                        value: formatter(item[col?.dataIndex], col?.format, format)
                    })
                } else {
                    temData?.push({
                        value: ''
                    })
                }
            } else {
                temData?.push({
                    value: formatter(item[col?.dataIndex], col?.format, format)
                })
            }

        })
        columnDatas?.push(temData)
    })
    return columnDatas
}