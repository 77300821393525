import { initialState } from "us.collection.case/reducers/ExtendedField/State";
import { ExtendedField } from "us.collection.case/constants/Actions";

const {
  GET_EXTENDED_FIELD,
  GET_EXTENDED_FIELD_SUCCESS,
  GET_EXTENDED_FIELD_FAIL,
  MANAGE_EXTENDED_FIELD_DRAWER,
} = ExtendedField;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_EXTENDED_FIELD:
      return Object.assign({}, state, {
        extendedFields: {
          data: state.extendedFields.data,
          isFetching: true,
        },
      });

    case GET_EXTENDED_FIELD_SUCCESS:
      return Object.assign({}, state, {
        extendedFields: {
          data: payload?.data,
          isFetching: false,
        },
      });

    case GET_EXTENDED_FIELD_FAIL:
      return Object.assign({}, state, {
        extendedFields: {
          data: {
            extendedType: "",
            extendedData: [],
          },
          isFetching: false,
        },
      });

    case MANAGE_EXTENDED_FIELD_DRAWER:
      return Object.assign({}, state, {
        drawer: payload?.data,
      });

    default:
      return state;
  }
};
