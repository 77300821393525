export enum Creditor {
  GET_GENERAL_AND_ADDRESS_DATA_START = "GET_GENERAL_AND_ADDRESS_DATA_START",
  GET_GENERAL_AND_ADDRESS_DATA_SUCCESS = "GET_GENERAL_AND_ADDRESS_DATA_SUCCESS",
  GET_GENERAL_AND_ADDRESS_DATA_FAIL = "GET_GENERAL_AND_ADDRESS_DATA_FAIL",

  GET_OTHER_CONTACT_DATA_START = "GET_OTHER_CONTACT_DATA_START",
  GET_OTHER_CONTACT_DATA_SUCCESS = "GET_OTHER_CONTACT_DATA_SUCCESS",
  GET_OTHER_CONTACT_DATA_FAIL = "GET_OTHER_CONTACT_DATA_FAIL",

  GET_CREDITORS_DATA_START = "GET_CREDITORS_DATA_START",
  GET_CREDITORS_DATA_SUCCESS = "GET_CREDITORS_DATA_SUCCESS",
  GET_CREDITORS_DATA_FAIL = "GET_CREDITORS_DATA_FAIL",

  GET_CREDITORS_BMD_DATA_START = "GET_CREDITORS_BMD_DATA_START",
  GET_CREDITORS_BMD_DATA_SUCCESS = "GET_CREDITORS_BMD_DATA_SUCCESS",
  GET_CREDITORS_BMD_DATA_FAIL = "GET_CREDITORS_BMD_DATA_FAIL",
}