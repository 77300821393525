import { appInsights } from "us.helper";
import { ICaseSubCasesFrom } from "../Interfaces";

/**
 * @function
 * @description - Check for the desition to dissable the merge-case button
 * @param {ICaseSubCasesFrom} values - form values
 * @return {*}  {boolean} - boolean decision if the merge case button is dissabled
 */
export const isMergeCaseButtonDisabled = (values: ICaseSubCasesFrom): boolean => {
  try {
    return values.selectedCases?.filter((caseItem) => caseItem.parentId > 0 && caseItem.isMergeEnabled).length < 1
    || values.selectedCases?.filter((caseItem) => !caseItem.isMergeEnabled).length > 0
  } catch (error) {
    appInsights.trackException(
      `Cases and Subcases Home isMergeCaseButtonDisabled error - ${error}`
    );
    return true;
  }
};
