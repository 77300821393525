import React from 'react';
import { Activities } from '../components';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface ICnt_Activities extends RouteComponentProps {

}


const Cnt_Activities = (props: ICnt_Activities) => {

    const onMinimize = () => {
        props.history.push('')
    }

    return (
        <Activities.Home onMinimize={onMinimize} />
    )
}

export default withRouter(Cnt_Activities);