import { IReturnType } from "../interfaces";

/**
 * To validate the postal code input field value
 * @param input postal code input field value
 * @param postalAreas the list of postal areas
 * @returns the matching values of municipalityCode, municipalityName, zipName, zipCode for the input field value
 */
export const ValidateZipCode = (input: string, postalAreas: any[]): IReturnType => {
  if (postalAreas && postalAreas.length > 0) {
    const matchingPostalArea = postalAreas.filter((x) => x.postalCode === input);
    if (matchingPostalArea && matchingPostalArea[0]) {
      return {
        municipalityCode: matchingPostalArea[0].countyCode,
        municipalityName: matchingPostalArea[0].countyName,
        zipName: matchingPostalArea[0].postalPlace,
        zipCode: matchingPostalArea[0].postalCode,
      };
    }
  }
  return {
    municipalityCode: "",
    municipalityName: "",
    zipName: "",
    zipCode: "",
  };
};
