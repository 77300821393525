import React from "react";

interface IObsoluteInterestLink {
  isDrawerOpen: boolean;
}

export const ObsoluteInterestLink: React.FC<IObsoluteInterestLink> = (
  props
) => {
  return props.isDrawerOpen ? (
    <a>
        {/* When Drawer ON */}
      <strong>{props.children}</strong>
    </a>
  ) : (
    <a> {/* When Drawer OFF */}{props.children}</a>
  );
};
