import moment, { Moment } from "moment";

/**
 * To get the ISO8601 representation of the date
 * @param date date in either type Moment, string or undefined
 * @returns ISO8601 representation of the date if it's a valid date
 */
export const getISODateString = (date: Moment | string | undefined): string | null => {
  if (!date) {
    return null;
  } else if (moment.isMoment(date)) {
    return new Date(date.format("YYYY-MM-DD")).toISOString();
  } else if (typeof date === "string" && moment(date, moment.ISO_8601, true).isValid()) {
    return new Date(moment(date).format("YYYY-MM-DD")).toISOString();
  }else{
    return null;
  }
};
