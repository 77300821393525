import { ARNoteAction } from "us.collection/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "us.collection/services";
import { ARNote, RegisteredUserType, EntityType } from "us.collection/constants";
import { $MessageBox } from "us.common/components";

const { ArNote } = API.ArNotes;

const { arNoteHistory, addArNote, deleteArNote } = ARNoteAction;

const ArNotes = {
  get: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { data, status } = yield call(ArNote.get, params);
      if (status === 200) {
        yield put(arNoteHistory.success(data));
      } else {
        yield put(arNoteHistory.fail(data));
      }
    } catch (error) {
      yield put(arNoteHistory.fail(error as any));
    }
  },
  save: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { data, status } = yield call(ArNote.save, params);
      if (status === 200) {
        $MessageBox(
          "success",
          params.noteId > 0
            ? "US.COLLECTION.LAYOUT:CASE.NOTE_UPDATED_SUCCESSFULLY"
            : "US.COLLECTION.LAYOUT:CASE.NOTE_SAVED_SUCCESSFULLY",
          "",
          ""
        );
        yield put(addArNote.success(data));
        if (arNoteHistory.get) {
          yield put(
            arNoteHistory.get({
              entityId: params.entityType === EntityType.CREDITOR ? params.creditorId : params.entityId,
              entityType: params.entityType,
              registeredUserType: RegisteredUserType.ALL,
            })
          );
        }
      } else {
        $MessageBox(
          "error",
          "US.COLLECTION.LAYOUT:CASE.NOTE_SAVED_FAIL",
          "",
          ""
        );
        yield put(addArNote.fail(data));
      }
    } catch (error) {
      $MessageBox("error", "US.COLLECTION.LAYOUT:CASE.NOTE_SAVED_FAIL", "", "");
      yield put(addArNote.fail(error as any));
    }
  },
  delete: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { data, status } = yield call(ArNote.delete, params);
      if (status === 200) {
        $MessageBox(
          "success",
          "US.COLLECTION.LAYOUT:CASE.NOTE_DELETED_SUCCESSFULLY",
          "",
          ""
        );
        yield put(deleteArNote.success(data));
        if (arNoteHistory.get) {
          yield put(
            arNoteHistory.get({
              entityId: params.entityType === EntityType.CREDITOR ? params.creditorId : params.entityId,
              entityType: params.entityType,
              registeredUserType: RegisteredUserType.ALL,
            })
          );
        }
      } else {
        $MessageBox(
          "error",
          "US.COLLECTION.LAYOUT:CASE.NOTE_DELETED_FAIL",
          "",
          ""
        );
        yield put(deleteArNote.fail(data));
      }
    } catch (error) {
      $MessageBox(
        "error",
        "US.COLLECTION.LAYOUT:CASE.NOTE_DELETED_FAIL",
        "",
        ""
      );
      yield put(deleteArNote.fail(error as any));
    }
  },
};

export default [
  takeLatest(ARNote.GET_AR_NOTE_HISTORY_START, ArNotes.get),
  takeLatest(ARNote.ADD_AR_NOTE_START, ArNotes.save),
  takeLatest(ARNote.DELETE_AR_NOTE_START, ArNotes.delete),
];
