import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Common from "us.common";
import Constants from "us.common/constants";
import { IntlDate, IntlCurrency } from "us.common/functions";
import { IExperian } from "us.collection.debtor/Interfaces";
import { IRootState } from "us.collection/interfaces";
import { PlusOutlined, MinusOutlined } from "us.icons";
import { IOnFilter, IOnSort } from "us.common/components/antd/TableTree";

const { $Collapse, $Skeleton, $Table, $TableTree, $DateLabel, $AmountLabel } = Common.Components;

interface ISortOrder {
  columnKey: string;
  order: any;
}

interface IExperianProvider {
  currentDateFormat: string;
  currentCurrency: string;
  currentLanguage: string;
  debtorCreditInfo: IExperian;
  isFetching: boolean;
}

const ExperianProvider: React.FC<IExperianProvider> = (props) => {
  const { t } = useTranslation(
    ["US.COLLECTION.DEBTOR", "US.COLLECTION.COMMON"],
    { useSuspense: true }
  );
  const {
    debtorCreditInfo,
    isFetching,
    currentDateFormat,
    currentCurrency,
    currentLanguage,
  } = props;

  const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
    columnKey: "",
    order: "",
  });

  // Tax Tabel
  const taxColumns: any = [
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.TAXYEAR"),
      dataIndex: "taxYear",
      key: "taxYear",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.NETINCOME"),
      dataIndex: "netIncome",
      key: "netIncome",
      align: "right",
      className: "right-has-sort right-has-filter text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: "amount",
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <$AmountLabel value={record?.netIncome} />,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.ASSETS"),
      dataIndex: "assets",
      key: "assets",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.TAXPAID"),
      dataIndex: "taxPaid",
      key: "taxPaid",
      align: "right",
      className: "right-has-sort right-has-filter text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: "amount",
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <$AmountLabel value={record?.taxPaid} />,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.TAXCLASS"),
      dataIndex: "taxClass",
      key: "taxClass",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.GROSSINCOME"),
      dataIndex: "grossIncome",
      key: "grossIncome",
      align: "right",
      className: "right-has-sort right-has-filter text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: "amount",
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <$AmountLabel value={record?.grossIncome} />,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.DEDUCTION"),
      dataIndex: "deduction",
      key: "deduction",
      align: "right",
      className: "right-has-sort right-has-filter text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: "amount",
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <$AmountLabel value={record?.deduction} />,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.TAXCOUNTRY"),
      dataIndex: "taxCountry",
      key: "taxCountry",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.BUSENGAGEMENT"),
      dataIndex: "busEngagement",
      key: "busEngagement",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.SCORE"),
      dataIndex: "score",
      key: "score",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.OWNLTDCOM"),
      dataIndex: "ownLtdCom",
      key: "ownLtdCom",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.OWNRESCOM"),
      dataIndex: "ownResCom",
      key: "ownResCom",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.OWNSOLETRADE"),
      dataIndex: "ownSoleTrade",
      key: "ownSoleTrade",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.ADRCHANGEDATE"),
      dataIndex: "adrChangeDate",
      key: "adrChangeDate",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
  ];

  // Remarks Table
  const remarksColumns: any = [
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.REMARKREGDATE"),
      dataIndex: "remarkRegDate",
      key: "remarkRegDate",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: "date",
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <$DateLabel value={record?.remarkRegDate} />,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.TYPE"),
      dataIndex: "remType",
      key: "remType",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.REMARKNO"),
      dataIndex: "remNumber",
      key: "remNumber",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.AMOUNT"),
      dataIndex: "amount",
      key: "amount",
      align: "right",
      className: "right-has-sort right-has-filter text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: "amount",
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <$AmountLabel value={record?.amount} />,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.PAYDATE"),
      dataIndex: "remPayDate",
      key: "remPayDate",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: "date",
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <$DateLabel value={record?.remPayDate} />,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.DALARYNO"),
      dataIndex: "diaryNo",
      key: "diaryNo",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.DEBTOR:CREDITINFO.PAYSTATUS"),
      dataIndex: "payStatus",
      key: "payStatus",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
  ];

  // Sort table column
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter);
  };

  const handleSort: IOnSort = (sortData, dataSource) => {
    return sortData(dataSource);
  };

  const handleFilter: IOnFilter = (searchData, dataSource) => {
    return searchData(dataSource);
  };

  return (
    <>
      <$Collapse
        bordered={false}
        defaultActiveKey={["1", "2", "3"]}
        expandIcon={({ isActive }) =>
          isActive ? <MinusOutlined /> : <PlusOutlined />
        }
        className="bui-collapse"
      >
        <$Collapse.Panel
          header={t("US.COLLECTION.DEBTOR:CREDITINFO.GENERALINFO")}
          key="1"
          className="bui-collapse-panel"
        >
          <div className="d-flex flex-wrap px-2">
            {debtorCreditInfo?.identyNo && (
              <div className="info-item">
                <label>{t("US.COLLECTION.DEBTOR:CREDITINFO.IDENTYNO")} :</label>
                <span>{debtorCreditInfo?.identyNo}</span>
              </div>
            )}
            {debtorCreditInfo?.name && (
              <div className="info-item">
                <label>{t("US.COLLECTION.DEBTOR:CREDITINFO.NAME")} :</label>
                <span>{debtorCreditInfo?.name}</span>
              </div>
            )}
            {debtorCreditInfo?.address && (
              <div className="info-item">
                <label>{t("US.COLLECTION.DEBTOR:CREDITINFO.ADDRESS")} :</label>
                <span>{debtorCreditInfo?.address}</span>
              </div>
            )}
            {debtorCreditInfo?.postalCode && (
              <div className="info-item">
                <label>{t("US.COLLECTION.DEBTOR:CREDITINFO.POSTALCODE")} :</label>
                <span>{debtorCreditInfo?.postalCode}</span>
              </div>
            )}
            {debtorCreditInfo?.source && (
              <div className="info-item">
                <label>{t("US.COLLECTION.DEBTOR:CREDITINFO.SOURCE")} :</label>
                <span>{debtorCreditInfo?.source}</span>
              </div>
            )}
          </div>
        </$Collapse.Panel>
        <$Collapse.Panel
          header={t("US.COLLECTION.DEBTOR:CREDITINFO.TAX")}
          key={
            typeof debtorCreditInfo?.personalInformation == "undefined" ||
            debtorCreditInfo?.personalInformation?.length == 0
              ? "-2"
              : "2"
          }
          className="bui-collapse-panel"
          collapsible={
            typeof debtorCreditInfo?.personalInformation == "undefined" ||
            debtorCreditInfo?.personalInformation?.length == 0
              ? "disabled"
              : undefined
          }
        >
          <div>
            <$Skeleton loading={isFetching} active paragraph={{ rows: 2 }}>
              <$TableTree
                rowKey="taxYear"
                data={debtorCreditInfo?.personalInformation}
                columns={taxColumns}
                size="small"
                className=""
                onSort={handleSort}
                onFilter={handleFilter}
                filterOnType={true}
                resetOnSourceChange={true}
                bordered
                pagination={false}
                scroll={{ x: 1200 }}
                firstColSkipFilterProps={-1}
              />
            </$Skeleton>
          </div>
        </$Collapse.Panel>
        <$Collapse.Panel
          header={t("US.COLLECTION.DEBTOR:CREDITINFO.REMARKS")}
          key={
            typeof debtorCreditInfo?.remarkInformation == "undefined" ||
            debtorCreditInfo?.remarkInformation?.length == 0
              ? "-3"
              : "3"
          }
          className="bui-collapse-panel"
          collapsible={
            typeof debtorCreditInfo?.remarkInformation == "undefined" ||
            debtorCreditInfo?.remarkInformation?.length == 0
              ? "disabled"
              : undefined
          }
        >
          <div>
            <$Skeleton loading={isFetching} active paragraph={{ rows: 2 }}>
              <$TableTree
                rowKey="taxYear"
                data={debtorCreditInfo?.remarkInformation}
                columns={remarksColumns}
                size="small"
                className=""
                onSort={handleSort}
                onFilter={handleFilter}
                filterOnType={true}
                resetOnSourceChange={true}
                bordered
                pagination={false}
                scroll={{ x: 1200 }}
                firstColSkipFilterProps={-1}
              />
            </$Skeleton>
          </div>
        </$Collapse.Panel>
      </$Collapse>
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common } = state;
  return {
    currentDateFormat: common.currentDateFormat,
    currentCurrency: common.currentCurrency,
    currentLanguage: common.currentLanguage,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ExperianProvider);
