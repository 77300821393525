import {
  httpCollection,
  versionedHttpCollection,
} from "us.helper/http/collection";
import ServiceConfig from "service.config.js";
import { SimilarDebtor } from "us.collection.debtor/Interfaces/Debtor";
import { DebtorFilterBy } from "us.collection.debtor/components/DebtorBasicInfo/Constants";

export default {
  debtor: {
    getAddress: <P extends { debtorNumber: number }>(
      params: P
    ): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.get(
            "entityservice",
            "debtor/adressinfo",
            {
              debtorId: params.debtorNumber,
            },
            ServiceConfig()[`entityservice`]["debtor.adressinfo"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    getHistory: <P extends { debtorNumber: number }>(
      params: P
    ): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.get(
            "entityservice",
            "debtor/history",
            {
              debtorId: params.debtorNumber,
            },
            ServiceConfig()[`entityservice`]["debtor.history"]
          );
          //resolve(data);
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    getOtherInfo: <P extends { arNo: number }>(params: P): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.get(
            "entityService",
            `debtor/debtorInfo/${params.arNo}`,
            {},
            ServiceConfig()[`entityservice`]["debtor.debtorInfo"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    save: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          console.error(params?.data);

          const { data } = await versionedHttpCollection.post(
            "entityService",
            "entity",
            params?.data,
            ServiceConfig()[`entityservice`]["entity"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    getBadDebt: (caseNo: number): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.getById(
            "entityservice",
            "entities",
            `${caseNo}/badDebtInfo`
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    isBadDebtor: (caseNo: number): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.getById(
            "entityservice",
            "entities",
            `${caseNo}/isBadDebtor`
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    getCreditInfo: <P extends { debtorNumber: number }>(
      params: P
    ): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.get(
            "entityservice",
            `debtors/${params.debtorNumber}/creditinfo`,
            {}
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  creditors: {
    get: <P extends { arNo: number }>(params: P): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.get(
            "entityservice",
            `entity/creditors/${params.arNo}`,
            {},
            ServiceConfig()[`entityservice`]["entity.creditors"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  case: {
    get: <
      P extends {
        similarDebtor: SimilarDebtor;
        debtorId: number;
        creditorId: number;
        arId: number;
      }
    >({
      creditorId,
      debtorId,
      arId,
      similarDebtor,
    }: P): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { isSimilarDebtor, filterBy } = similarDebtor;
          const { data } = await httpCollection.get(
            "arservice",
            `ars/cases/${creditorId}/${debtorId}/${arId}/${isSimilarDebtor}?returnTransferredSubCases=false&similarByName=${filterBy?.includes(
              DebtorFilterBy.NAME
            )}&similarByPersonNo=${filterBy?.includes(
              DebtorFilterBy.PERSON_NO
            )}&similarByZipcode=${filterBy?.includes(DebtorFilterBy.ZIP_CODE)}`,
            {}
          );
          resolve(data);
          //resolve(caseList);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  postelArea: {
    search: async <
      P extends {
        countryCode: string;
        searchText: string;
      }
    >(
      params: P
    ): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.post(
            "entityservice",
            `postalCodes`,
            {
              countryCode: params.countryCode,
              searchText: params.searchText,
              searchBy: "PostalCode",
            },
            ServiceConfig()[`entityservice`][`postalCodes`]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  municipals: {
    search: async <
      P extends {
        countryCode: string;
        searchText: string;
      }
    >(
      params: P
    ): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.get(
            "entityservice",
            `entities/municipalities`,
            {
              countryCode: params.countryCode,
              searchText: params.searchText,
            },
            ServiceConfig()[`entityservice`][`entities.municipalities`]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
