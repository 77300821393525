import { IInitialState } from '../Interfaces';

export const initialState: IInitialState = {
	showGDPRCommentBox: false,
	showDashboard: true,
	gdprCommentIsSaving: false,
	case: {
		data: { caseId: '', caseNo: '', caseType: '' },
		isLoading: true,
	},
	workflow: {
		data: null,
		isLoading: true,
	},
	metaData: {
		data: {
			debtorEntNumber: 0,
			debtorId: 0,
			creditorId: 0,
			creditorEntNumber: 0,
			entityType: '',
			caseId: 0,
			caseNo: '',
			arId: 0,
			creditorGroupId: 0,
			pid: '',
			cid: '',
			creditInfoProvider: '',
			isObjected: false,
			parentCaseId: 0,
			parentCaseNo: 0,
		},
		isLoading: false,
		isError: false,
	},
};
