import React from "react";
import { ICase, ISubCase } from "us.collection.transactions/interfaces";
import { $AmountLabel, $Tag, $Tooltip } from "us.common/components";
import { CaseState } from "us.collection.transactions/constants";
import { ITableCustomComponents } from "../../../Interfaces";
import { appInsights } from "us.helper";

/**
 * @function
 * @description - Get the componetns for the table rendering based on the data index
 * @param {keyof ICase} dataIndex - The key of ICase interface
 * @return {*}  {ITableCustomComponents} - Custom componets for table rendering
 */
export const getTableRenderItems = (dataIndex: keyof ICase): ITableCustomComponents => {
  try {
    switch (dataIndex) {
      case "caseNumber":
        return {
          customSorter: (a: any, b: any) => a?.localeCompare(b),
          customRenderChild: (text: any, { caseNumber }: ISubCase, index: number) => {
            return {
              children: (
                <$Tooltip placement="topLeft" title={caseNumber}>
                  <div className="text-truncate">{caseNumber}</div>
                </$Tooltip>
              ),
              key: index,
              props: {
                colSpan: 1,
              },
            };
          },
        };
      case "workflowState":
        return {
          customSorter: (a: any, b: any) => a?.localeCompare(b),
          customRenderChild: (text: any, { workflowState, caseState }: ISubCase, index: number) => {
            return {
              children: (
                <>
                  {workflowState && (
                    <$Tooltip placement="topLeft" title={workflowState}>
                      <$Tag
                        className={
                          caseState === CaseState.OPEN
                            ? "case-state tag-status-active"
                            : "case-state tag-status-close"
                        }
                      >
                        {workflowState}
                      </$Tag>
                    </$Tooltip>
                  )}
                </>
              ),
              key: index,
              props: {
                colSpan: 1,
              },
            };
          },
        };

      case "mainAmount":
      case "otherCost":
      case "paidAmount":
      case "balance":
        return {
          customSorter: (a: any, b: any) => a - b,
          customRenderChild: (text: any, record: ISubCase, index: number) => {
            return {
              children: <$AmountLabel value={record[dataIndex]} />,
              key: index,
              props: {
                colSpan: 1,
              },
            };
          },
        };
      default:
        return {
          customSorter: (a: any, b: any) => a - b,
          customRenderParent: (text: any, record: ICase, index: number) => {},
          customRenderChild: (text: any, record: ISubCase, index: number) => {},
        };
    }
  } catch (error) {
    appInsights.trackException(
      `Error in add case and subcases drawerStatusHandler function. - ${error}`
    );
    return {
      customSorter: (a: any, b: any) => a - b,
      customRenderParent: (text: any, record: ICase, index: number) => {},
      customRenderChild: (text: any, record: ISubCase, index: number) => {},
    };
  }
};
