import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, FieldArray, useField } from 'formik';
import Common from 'us.common';
import * as Yup from 'yup';


const { $Form, $TextArea, $Button, $Tabs, $PageHeader,
    $Popconfirm, $Skeleton, $Table, $Row, $Col, $Popover, $Select, $Tooltip, $Switch,
    $AutoComplete, $Search } = Common.Components

interface IAddBureauLevelObjection {
    onClose(): void,
    formFields?: any,

}
    
interface IBase extends IAddBureauLevelObjection {
}
    
const validationSchema = Yup.object({
    
});
    
const AddBureauLevelObjection: React.FC<IBase> = (props) => {
    const { onClose, formFields } = props;

    useEffect(() => {

    })
    
    const handleAddBureauLevelObjectionSubmit = ((data: any, { setErrors, setStatus, resetForm, isSubmitting, isValidating, setSubmitting }: any) => {
    })
    
    return (
        <Formik
            initialValues={{
                //displayName: '',
                //activeState: true
            }}
        
            validationSchema={validationSchema}
            onSubmit={handleAddBureauLevelObjectionSubmit}
        >
            {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValidating,
                resetForm,
                ...restProps
            }: any) => (
                <$Form onSubmit={handleSubmit}>
                    <div className="my-3">
                        <strong>Case note</strong>
                    </div>
                    <$Row gutter={16} className="mb-4">
                        <$Col span={24}>
                            <Common.Components.Radio.Group
                                onChange={(e: any) => {
                                }}
                                value='principal'
                                defaultValue='principal'
                                //disabled={}
                                options={[
                                    { value: "telephone", label: "Telephone", style: {marginRight:'2rem'} },
                                    { value: "email", label: "Email", style: {marginRight:'2rem'} },
                                    { value: "portal", label: "Portal", style: {marginRight:'2rem'} },
                                    { value: "post", label: "Post", style: {marginRight:'2rem'} },
                                ]}
                            />
                        </$Col>
                    </$Row>
                    <$Row gutter={16} className="mb-5">
                        <$Col span={24}>
                            <$TextArea
                                name="caseNote"
                                autoSize={{ minRows: 8, maxRows: 8 }}
                            />
                        </$Col>
                    </$Row>

                    <div className="drawer-footer-fixed align-content-center justify-content-end">
                        <div>
                            <$Button className="mr-2" disabled={!restProps.isValid || isSubmitting || !restProps.dirty} loading={isSubmitting || isValidating}
                                onClick={() => { handleSubmit(); onClose(); }}

                                type="primary">Save</$Button>
                            {/* {setIsEdited(rest.dirty)} */}
                            {restProps.dirty &&
                                <$Popconfirm
                                    title="Role creation has not been completed and your existing data will be lost. Do you wish to continue?"
                                    placement="topLeft"
                                    onConfirm={() => onClose()}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <$Button >Cancel</$Button>
                                </$Popconfirm>
                            }
                            {!restProps.dirty &&
                                <$Button onClick={() => onClose()}>Cancel</$Button>
                            }
                        </div>
                    </div>
                </$Form>
            )}
        </Formik>
    )
}
    
const mapStateToProps = (state: any) => {
    return {
    };
};

const mapDispatchToProps = {
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddBureauLevelObjection)