import React from "react";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { IEditor } from "us.collection.case/components/MessageTemplates/Interface";
import { DocumentType } from "us.collection.case/constants";
import { IFrameEditor } from "us.collection.case/components/MessageTemplates/Components";
import "./Editor.scss";

const { $Skeleton, $Button, $Popconfirm, $Empty } = Common.Components;

const Editor: React.FC<IEditor> = (props) => {
  const { t } = useTranslation();
  const {
    template,
    item,
    onClose,
    onExecute,
    onOpenEmail,
    isExecute,
    getContent,
    updateTemplateContent,
    tempalateUpdatedContent,
  } = props;
  const { plugin } = item;

  return (
    <$Skeleton loading={template.isLoading} active paragraph={{ rows: 2 }}>
      <div className="message-template-editor" id="ediorId">
        {template.content.length > 0 && (
          <>
            <IFrameEditor
              content={template.content}
              getContent={getContent}
              updateTemplateContent={updateTemplateContent}
              tempalateUpdatedContent={tempalateUpdatedContent}
            />
            <div className="drawer-footer-fixed align-content-center justify-content-end">
              <div>
                {plugin.toLowerCase() === DocumentType.PDF && (
                  <$Popconfirm
                    title={t(
                      "US.COLLECTION.CASE:MASSAGETEMPLATES.EMAILCONFIRM"
                    )}
                    onConfirm={onOpenEmail}
                    okText={t("US.COLLECTION.COMMON:COMMON.YES")}
                    cancelText={t("US.COLLECTION.COMMON:COMMON.NO")}
                  >
                    <$Button className="mr-2" type="primary">
                      {t("US.COLLECTION.CASE:MASSAGETEMPLATES.SEND_EMAIL")}
                    </$Button>
                  </$Popconfirm>
                )}
                <$Popconfirm
                  title={t(
                    "US.COLLECTION.CASE:MASSAGETEMPLATES.EXECUTECONFIRM"
                  )}
                  onConfirm={onExecute}
                  okText={t("US.COLLECTION.COMMON:COMMON.YES")}
                  cancelText={t("US.COLLECTION.COMMON:COMMON.NO")}
                >
                  <$Button className="mr-2" loading={isExecute}>
                    {t("US.COLLECTION.CASE:MASSAGETEMPLATES.EXECUTE")}
                  </$Button>
                </$Popconfirm>
                <$Button onClick={onClose}>
                  {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
                </$Button>
              </div>
            </div>
          </>
        )}
        {template.content.length === 0 && (
          <div className="d-flex justify-content-center">
            <div className="d-flex mt-4">
              <$Empty
                image={$Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>
                    {t(
                      "US.COLLECTION.CASE:MASSAGETEMPLATES.THERE_IS_NO_TEMPLATE_AVAILABLE"
                    )}
                  </span>
                }
              />
            </div>
          </div>
        )}
      </div>
    </$Skeleton>
  );
};
export default Editor;
