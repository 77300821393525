import { Interest } from 'us.collection.case/constants/Actions';
import { initialState } from './State';
import _ from 'lodash';

export default (state = initialState, { payload, type }: any) => {
	switch (type) {
		case Interest.GET_INTEREST_START:
			return Object.assign({}, state, {
				interests: {
					data: state.interests.data,
					isFetching: true,
				},
			});
		case Interest.GET_INTEREST_SUCCESS:
			return Object.assign({}, state, {
				interests: {
					data: {
						...payload?.data,
						interestDetails:
							payload?.data?.interestDetails.map(
								(
									interest: any
								) => {
									return {
										...interest,
										amount: interest?.amount?.toFixed(
											2
										),
										baseAmount: interest?.baseAmount?.toFixed(
											2
										),
									};
								}
							),
					},
					isFetching: false,
				},
			});
		case Interest.GET_INTEREST_FAIL:
			return Object.assign({}, state, {
				interests: {
					data: {},
					isFetching: false,
				},
			});
		case Interest.SET_INTEREST_FILTER:
			return Object.assign({}, state, {
				filter: payload.data.type,
			});
		default:
			return state;
	}
};
