import * as Yup from 'yup';

export default () => {
    return Yup.object().shape({
        category: Yup.number().required('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'),
        reason: Yup.number().required('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'),
        date: Yup.date().required('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'),
    })

}

