import _ from "lodash";
import { ICaseInformation } from "us.collection.case/reducers/Dashboard/Interfaces";

/**
 * @function
 * @description get date type record Or Not
 * @param {Array<ICaseInformation>}data 
 * @param {boolean} isDateType 
 * @returns {Array<ICaseInformation>}
 */
export const getDateTypeRecordOrNot = (
    data: Array<ICaseInformation> = [],
    isDateType: boolean
): Array<ICaseInformation> => {
    try {
        return data.filter(({ dataType }: ICaseInformation) =>
            isDateType ? dataType == "date" : dataType != "date"
        );
    } catch (error) {
        return [];
    }
};

/**
 * @function 
 * @description sort Widget Data By Date
 * @param {Array<ICaseInformation>} data 
 * @returns  {Array<ICaseInformation>}
 */
export const sortWidgetDataByDate = (
    data: Array<ICaseInformation> = []
): Array<ICaseInformation> => {
    try {
        const dateTypeData = getDateTypeRecordOrNot(data, true);
        const otherTypesData = getDateTypeRecordOrNot(data, false);
        return [..._.sortBy(dateTypeData, (o) => o.value), ...otherTypesData,];
    } catch (error) {
        return data;
    }
}; 