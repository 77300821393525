import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
	$Button,
	$Popconfirm,
	$Select,
	$Form,
	$DatePicker,
	$TextArea,
	$Row,
	$Col,
} from 'us.common/components';
import { Formik } from 'formik';
import { manualFollowUpActions } from 'us.collection.case/actions';
import { Reason } from 'us.collection.case/components/ManualFollowUps/Components/NewManualFollowUp/Components';
import { SaveManualFollowUp, SaveReasons } from 'us.collection.case/repository';
import { FollowUpName } from 'us.collection.case/constants';
import { IAddManualFollowUp } from './Interface';
import { ValidationSchema } from './Validation';
import moment from 'moment';
import './Style.scss';
import { RootState } from 'us.helper/types';

const { reasons, reason, manualFollowUp } = manualFollowUpActions;

const AddManualFollowUp: React.FC<PropsFromRedux & IAddManualFollowUp> = (
	props
) => {
	const {
		currentDateFormat,
		categories,
		saveReason,
		getReasons,
		assigners,
		save,
		metaData,
		onClose,
		initialValue,
		init,
		reasons: reasonsDetails,
		isManualFollowUpSaving,
		resetForm,
	} = props;

	const { caseId, entityType } = metaData.data ?? {};

	const { t } = useTranslation();

	useEffect(() => {
		resetForm && resetForm({});
		init && init({});
	}, []);

	const saveNewReason = (data: any) => {
		const request = SaveReasons.call(data, categories);
		saveReason && saveReason(request);
	};

	const handleSubmit = (data: any) => {
		const request = SaveManualFollowUp.call(
			data,
			FollowUpName.CommonFollowup,
			reasonsDetails.data,
			caseId,
			entityType
		);
		save && save(request);
	};

	return (
		<Formik
			initialValues={initialValue}
			onSubmit={handleSubmit}
			validationSchema={ValidationSchema}
			validateOnBlur
			validateOnChange
			enableReinitialize>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				setFieldValue,
				...rest
			}: any) => (
				<$Form
					onSubmit={handleSubmit}
					className='add-manual-followup'>
					<div className='amf-wrapper'>
						<div className='amf-left'>
							<$Select
								formitem={{
									label: t(
										'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.CATEGORY'
									),
								}}
								name='category'
								options={
									categories
								}
								className='w-100'
								allOption={
									false
								}
								optionText='categoryName'
								optionValue='categoryID'
								onSearchBy={[
									'categoryName',
									'categoryID',
								]}
								dropdownMatchSelectWidth={
									false
								}
								size='small'
								onSelect={(
									categoryId: number
								) => {
									getReasons &&
										getReasons(
											{
												categoryId,
												searchcriteria:
													values.reasonSearch,
											}
										);
								}}
								dataTestid='manualFollowupCategoryId'
							/>
							<Reason
								save={
									saveNewReason
								}
								values={values}
								setFieldValue={
									setFieldValue
								}
							/>
						</div>
						<div className='amf-right'>
							<$Row
								gutter={16}
								className='mb-3'>
								<$Col span={12}>
									<$DatePicker
										name='date'
										label={t(
											'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.DATE'
										)}
										format={
											currentDateFormat
										}
										value={
											values.date
										}
										disabledDate={(
											day: any
										) =>
											day.isBefore(
												moment().startOf(
													'day'
												)
											)
										}
										size='small'
										className='w-100'
										required
									/>
								</$Col>
								<$Col span={12}>
									<$Select
										formitem={{
											label: t(
												'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.ASSIGN_TO'
											),
										}}
										name='assign'
										options={
											assigners
										}
										className='w-100'
										allOption={
											true
										}
										defaultValue={
											'all'
										}
										dropdownMatchSelectWidth={
											false
										}
										size='small'
										optionText='displayName'
										optionValue='email'
										onSearchBy={[
											'displayName',
											'email',
										]}
										dataTestid='manualFollowupUserId'
									/>
								</$Col>
							</$Row>
							<$TextArea
								name='note'
								autoSize={{
									minRows: 4,
									maxRows: 4,
								}}
								size='small'
								label={t(
									'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.NOTE'
								)}
								className='w-100'
							/>
						</div>
					</div>

					<div className='drawer-footer-fixed align-content-center'>
						<div className='ml-auto'>
							<$Button
								className='mr-2'
								disabled={
									!rest.isValid ||
									isManualFollowUpSaving
								}
								loading={
									isManualFollowUpSaving
								}
								onClick={
									handleSubmit
								}
								type='primary'
								data-testid='manual-follow-up-save-btn'>
								{t(
									'US.COLLECTION.COMMON:COMMON.SAVE'
								)}
							</$Button>

							{rest.dirty && (
								<$Popconfirm
									title={t(
										'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.CANCEL_CONFIRMATION'
									)}
									onConfirm={
										onClose
									}
									placement='topLeft'
									okText={t(
										'US.COLLECTION.COMMON:COMMON.YES'
									)}
									cancelText={t(
										'US.COLLECTION.COMMON:COMMON.NO'
									)}>
									<$Button>
										{t(
											'US.COLLECTION.COMMON:COMMON.CANCEL'
										)}
									</$Button>
								</$Popconfirm>
							)}
							{!rest.dirty && (
								<$Button
									onClick={
										onClose
									}>
									{t(
										'US.COLLECTION.COMMON:COMMON.CANCEL'
									)}
								</$Button>
							)}
						</div>
					</div>
				</$Form>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, manualFollowUps, domainView } = state;
	const { currentDateFormat } = common;
	const {
		categories,
		assigners,
		reasons,
		initialValue,
		isManualFollowUpSaving,
	} = manualFollowUps;
	const { metaData } = domainView;
	return {
		currentDateFormat,
		categories,
		assigners,
		reasons,
		metaData,
		initialValue,
		isManualFollowUpSaving,
	};
};

const mapDispatchToProps = {
	enableNewReason: reason.openDrawer,
	saveReason: reason.save,
	updateFormData: reason.updateFormData,
	getReasons: reasons.get,
	save: manualFollowUp.save,
	init: manualFollowUp.init,
	resetForm: reason.reset,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddManualFollowUp);
