import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  $Skeleton,
  $TableTree,
  $Tooltip,
  $Button,
  $DateTimeLabel,
} from "us.common/components";
import { IOnFilter, IOnSort } from "us.common/components/antd/TableTree";
import { IRootState } from "us.collection/interfaces";
import { IFollowUpActionHistory } from "./Interfaces";
import {
  FOLLOW_UP_ACTION_HISTORY_COLUMNS,
  FollowUpActionHistoryDataIndex,
} from "./Constants";
import { getLastActionStatusIcon } from "us.collection.followup/functions";

/**
 * @description - Follow-up Action History that is open in a drawer
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2929295379/Get+Followup+Action+History+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 21/03/2022
 */
const FollowUpActionHistory: React.FC<PropsFromRedux & IFollowUpActionHistory> =
  (props) => {
    const { t } = useTranslation();

    const { onClose, action } = props;
    const { data, isFetching } = action;

    const handleSort: IOnSort = (sortData, dataSource) => {
      return sortData(dataSource);
    };

    const handleFilter: IOnFilter = (searchData, dataSource) => {
      return searchData(dataSource);
    };

    const columns: any = FOLLOW_UP_ACTION_HISTORY_COLUMNS.map(
      ({ dataIndex, title, width, ellipsis }) => ({
        title: t(`US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.${title}`),
        key: dataIndex,
        dataIndex,
        className: "text-nowrap",
        ...(width ? { width } : {}),
        ...(ellipsis ? { ellipsis } : {}),
        customSorter: (a: any, b: any) => a.localeCompare(b),
        customFilter:
          dataIndex === FollowUpActionHistoryDataIndex.CREATED_DATE
            ? "date"
            : true,
        customRenderChild: (text: any, record: any) => {
          const { createdDate, userName, action, actionStatus } = record;
          return (
            <>
              {dataIndex === FollowUpActionHistoryDataIndex.CREATED_DATE && (
                <$DateTimeLabel value={createdDate} />
              )}
              {dataIndex === FollowUpActionHistoryDataIndex.USER_NAME && (
                <$Tooltip title={userName}>{userName}</$Tooltip>
              )}
              {dataIndex === FollowUpActionHistoryDataIndex.ACTION && (
                <>
                  {getLastActionStatusIcon(actionStatus, action)}
                  <span>{action}</span>
                </>
              )}
            </>
          );
        },
      })
    );

    return (
      <div>
        <$Skeleton loading={isFetching} active paragraph={{ rows: 2 }}>
          <$TableTree
            rowKey={"id"}
            data={data}
            size="small"
            className=""
            onSort={handleSort}
            onFilter={handleFilter}
            filterOnType={true}
            resetOnSourceChange={true}
            bordered
            pagination={false}
            scroll={{ x: 600, y: "calc(100vh - 280px)" }}
            columns={columns}
            firstColSkipFilterProps={-1}
            data-testid="followUpActionHistory-table"
          />
        </$Skeleton>
        <div className="drawer-footer-fixed align-content-center justify-content-end">
          <div>
            <$Button onClick={() => onClose()}>
              {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
            </$Button>
          </div>
        </div>
      </div>
    );
  };

const mapStateToProps = (state: IRootState) => {
  const { followUps } = state;
  const { history } = followUps;
  const { action } = history;
  return { action };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FollowUpActionHistory);
