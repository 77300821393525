import Constants from "us.collection.transactions/constants";
import { IPaymentModel } from "us.collection.transactions/reducers/PaymentDistrubution/Interface";
import { InitialState } from "us.collection.transactions/reducers/PaymentDistrubution/State";
import { IApportionmentModel } from "./Interface/IApportionment";

const { ACTIONS } = Constants;
const { Payment, Apportionment } = ACTIONS;
const { GET_PAYMENTS, GET_PAYMENTS_SUCCESS, GET_PAYMENTS_FAIL } = Payment;
const {
  GET_APPORTIONMENTS,
  GET_APPORTIONMENTS_SUCCESS,
  GET_APPORTIONMENTS_FAIL,
} = Apportionment;

export default (state: any = InitialState, action: any) => {
  switch (action.type) {
    case GET_PAYMENTS:
      return Object.assign({}, state, {
        payment: <IPaymentModel>{
          data: {},
          isLoading: true,
          error: undefined,
        },
      });

    case GET_PAYMENTS_SUCCESS:
      let outputData;
      if(action.payload.data && Object.keys(action.payload.data).length > 0){
        outputData = action.payload.data;
      }else{
        outputData = {}
      }
      return Object.assign({}, state, {
        payment: <IPaymentModel>{
          data: outputData,
          isLoading: false,
          error: undefined,
        },
      });

    case GET_PAYMENTS_FAIL:
      return Object.assign({}, state, {
        payment: <IPaymentModel>{
          data: {},
          isLoading: false,
          error: action.payload.error,
        },
      });

    case GET_APPORTIONMENTS:
      return Object.assign({}, state, {
        apportionments: <IApportionmentModel>{
          data: [],
          isLoading: true,
          error: undefined,
        },
      });

    case GET_APPORTIONMENTS_SUCCESS:
      let arrangedData =[];
      if(action.payload.data != null && action.payload.data.length >0 ){
        arrangedData = action.payload.data.map((x:any,index:number)=>{
          return {...x,key:index};
        });
      }else{
        arrangedData = [];
      }
      return Object.assign({}, state, {
        apportionments: <IApportionmentModel>{
          data: arrangedData,
          isLoading: false,
          error: undefined,
        },
      });

    case GET_APPORTIONMENTS_FAIL:
      return Object.assign({}, state, {
        apportionments: <IApportionmentModel>{
          data: [],
          isLoading: false,
          error: action.payload.error,
        },
      });

    default:
      return state;
  }
};
