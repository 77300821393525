import { ObsoleteDate } from 'us.collection/constants/ObsoleteDateActions'
import { IAPIAction } from "us.collection/interfaces";

export const ObsoletedateAction: Readonly<IAPIAction> = {
    AddObsoletedate: {
        update: (data) => ({
          type: ObsoleteDate.ADD_OBSOLETE_DATE_STRAT,
          payload: {
            data,
            isLoading: true,
          },
        }),
        success: (data) => ({
          type: ObsoleteDate.ADD_OBSOLETE_DATE_SUCCESS,
          payload: {
            data: data,
            isLoading: false,
          },
        }),
        fail: (error) => ({
          type: ObsoleteDate.ADD_OBSOLETE_DATE_FAIL,
          payload: {
            error: error,
            isLoading: false,
          },
        }),
    },
}