import { initialState } from 'us.collection.case/reducers/Dashboard/State';
import {
	IMessageTemplate,
	MessageTemplateOptions,
	MessageTemplateText,
} from 'us.collection.case/interfaces';
import { EntityType } from 'us.helper/types/enums';

/**
 * @description To update the domainMessageInitial state
 * @param data
 * @returns return updated domainMessageInitial state
 */

export const updateDomainMessageInitialData = (domainMessageData: any) => {
	try {
		const { messgeType, emailOrTelNo } = domainMessageData;
		return {
			...initialState.domainMessageInitial,
			...domainMessageData,
			smsNumber: messgeType == 'SMS' ? emailOrTelNo : '',
			emailAddress: messgeType == 'Email' ? emailOrTelNo : '',
		};
	} catch (error) {
		return initialState.domainMessageInitial;
	}
};

/**
 * @description To reset thedomainMessageInitial state
 * @param {any[]}documentTypeList
 * @param {any[]}templateList
 * @param {string}debtorphone
 * @param {string}debtorEmail
 * @param {number}debtorEntityId
 * @returns return the domainMessageInitial state after reset
 */

export const resetDomainMessageInitialData = (
	documentTypeList: any[],
	templateList: any[],
	debtorphone: string,
	debtorEmail: string,
	debtorEntityId: number
) => {
	try {
		return {
			...initialState.domainMessageInitial,
			documentTypeList: documentTypeList,
			templateList: templateList,
			smsNumber: debtorphone,
			emailAddress: debtorEmail,
			receiverEntityId: debtorEntityId,
			templateNote: '',
			documentType: 'Debtor',
			dueDate: '',
			template: '',
			caseNote: '',
			attachmentOptions: [],
			attachment: [],
			isGiroChecked: false,
		};
	} catch (error) {
		return initialState.domainMessageInitial;
	}
};

/**
 * @description The templates add to Template Lists
 * @param {IMessageTemplate[]}templates
 * @returns return the Template List
 */

export const getMessageTemplateList = (templates: IMessageTemplate[]) => {
	try {
		const templateList = templates?.flatMap((template: IMessageTemplate) => {
			const { TextName } = template ?? {};

			if (TextName != undefined) {
				return {
					label: TextName,
					value: TextName,
					key: TextName,
				};
			} else {
				return [];
			}
		});
		return templateList;
	} catch (error) {
		return [];
	}
};

/**
 * @description To bind the debtor infomation to domainMessageInitial state
 * @param {any}entityDetails
 * @returns return the domainMessageInitial state after bind debtor infomation
 */

export const setDebtorInfo = (entityDetails: any) => {
	try {
		let debtorphone = '';
		let debtorEmail = '';
		let debtorEntityId = 0;

		const documentTypeList: Array<any> = [];
		entityDetails?.map((entity: any) => {
			if (entity.receiver === 'Debtor') {
				(debtorphone = entity.smsNo), (debtorEmail = entity.emailAddress);
				debtorEntityId = entity.entityId;
			}
			documentTypeList.push({
				label: entity?.receiver,
				value: entity?.receiver,
			});
		});
		return {
			documentTypeList,
			smsNumber: debtorphone,
			emailAddress: debtorEmail,
			receiverEntityId: debtorEntityId,
			documentType: 'Debtor',
		};
	} catch (error) {
		return initialState.domainMessageInitial;
	}
};

export const generateMessageTemplateOptions = (
	templateList: Array<MessageTemplateText>
): Array<MessageTemplateOptions> => {
	try {
		if (templateList && templateList.length > 0) {
			const options = templateList.map((template: MessageTemplateText) => {
				return {
					label: template.textName,
					value: template.id,
					key: template.id,
					text: template.text,
				};
			});
			return options;
		} else {
			return [];
		}
	} catch (error) {
		return [];
	}
};

export const getEntityTypeByKey = (
	entityType: 'S' | 'C' | 's' | 'c'
): string => {
	switch (entityType) {
		case 'S':
		case 's':
			return EntityType.SUB_CASE;
		case 'C':
		case 'c':
			return EntityType.CASE;
		default:
			return '';
	}
};
