import { put, call, takeLatest } from 'redux-saga/effects';
import * as Action from 'us.collection.case/actions/Dashboard/MessageAndNotes';
import { MessageAndNotes } from 'us.collection.case/constants/Actions';
import * as API from 'us.collection.case/services';
import { $MessageBox } from 'us.common/components';

const { MessageAndNoteAction } = Action;
const {
	notes,
	domainMessage,
	printedDocument,
	uploadedDocument,
	emailAttachmentContent,
} = API.messageAndNotes;

const messageAndNotesSagas = {
	save: function* (action: any) {
		const params = action.payload;
		try {
			const { data, status } = yield call(notes.save, params);
			yield put(MessageAndNoteAction.note.success(data));
			if (status === 200 && !data?.isError) {
				$MessageBox(
					'success',
					'US.COLLECTION.CASE:NOTESANDMESSAGES.NOTE_SAVED_SUCCESSFULLY',
					'',
					''
				);
			}
		} catch (error) {
			yield put(MessageAndNoteAction.note.fail(error as any));
		}
	},
	getTemplate: function* (action: any) {
		const params = action.payload;
		try {
			const { data, status } = yield call(
				domainMessage.getTemplate,
				params.data
			);
			if (status === 200) {
				yield put(
					MessageAndNoteAction.template.success(
						data
					)
				);
			} else {
				yield put(
					MessageAndNoteAction.template.fail({})
				);
			}
		} catch (error) {
			yield put(
				MessageAndNoteAction.template.fail(error as any)
			);
		}
	},
	getFormattedTemplate: function* (action: any) {
		const params = action.payload;
		const queryparam = {
			entityId: params.data?.entityId,
			entityType: params.data?.entityType,
			messageContent: params.data?.messageContent,
		};
		try {
			const { data, status } = yield call(
				domainMessage.getFormattedTemplate,
				queryparam
			);
			if (status === 200) {
				yield put(
					MessageAndNoteAction.formattedTemplate.success(
						data
					)
				);
			} else {
				$MessageBox(
					'error',
					'US.COLLECTION.CASE:NOTESANDMESSAGES.TEMPLATE_LOADING_FAILED',
					'',
					''
				);
				yield put(
					MessageAndNoteAction.formattedTemplate.fail(
						data
					)
				);
			}
		} catch (error) {
			yield put(
				MessageAndNoteAction.formattedTemplate.fail(
					error as any
				)
			);
		}
	},
	getBasicInfo: function* (action: any) {
		const params = action.payload;
		try {
			const { data, status } = yield call(
				domainMessage.getBasicInfo,
				params.data
			);
			if (status === 200) {
				yield put(
					MessageAndNoteAction.basicDetail.success(
						data
					)
				);
			} else {
				yield put(
					MessageAndNoteAction.basicDetail.fail(
						{}
					)
				);
			}
		} catch (error) {
			yield put(
				MessageAndNoteAction.basicDetail.fail(
					error as any
				)
			);
		}
	},
	send: function* (action: any) {
		const params = action.payload;
		try {
			const { sendDomainMessageData, basicdata } = yield call(
				domainMessage.send,
				params
			);

			if (
				sendDomainMessageData.data?.isSuccess &&
				basicdata.data != null
			) {
				const { data } = params;

				yield put(
					MessageAndNoteAction.domainMessage.success(
						sendDomainMessageData.data
					)
				);
				yield put(
					MessageAndNoteAction.basicDetail.success(
						basicdata.data
					)
				);

				let message = '';
				if (data.messgeType === 'SMS')
					message =
						'US.COLLECTION.CASE:NOTESANDMESSAGES.SMS_SENT_SUCCESSFULLY';
				else if (data.messgeType === 'Email')
					message =
						'US.COLLECTION.CASE:NOTESANDMESSAGES.EMAIL_SENT_SUCCESSFULLY';
				else if (data.messgeType === 'Document')
					message =
						'US.COLLECTION.CASE:NOTESANDMESSAGES.LETTER_SENT_SUCCESSFULLY';
				$MessageBox('success', message, '', '');
			}
		} catch (error) {
			yield put(
				MessageAndNoteAction.domainMessage.fail(
					error as any
				)
			);
		}
	},
	getPrintedDocument: function* (action: any) {
		const params = action.payload?.data;
		try {
			const { data, status } = yield call(
				printedDocument.get,
				params
			);
			if (status == 200) {
				yield put(
					MessageAndNoteAction.printedDocument.success(
						data
					)
				);
			} else {
				yield put(
					MessageAndNoteAction.printedDocument.fail(
						{}
					)
				);
			}
		} catch (error) {
			yield put(
				MessageAndNoteAction.printedDocument.fail(
					error as any
				)
			);
		}
	},
	getUploadedDocument: function* (action: any) {
		const params = action.payload?.data;
		try {
			const { data, status } = yield call(
				uploadedDocument.get,
				params
			);
			if (status == 200) {
				yield put(
					MessageAndNoteAction.uploadDocument.success(
						data
					)
				);
			} else {
				yield put(
					MessageAndNoteAction.uploadDocument.fail(
						{}
					)
				);
			}
		} catch (error) {
			yield put(
				MessageAndNoteAction.uploadDocument.fail(
					error as any
				)
			);
		}
	},
	getAttachmentDocument: function* (action: any) {
		const params = action.payload?.data;
		try {
			const { data, status } = yield call(
				emailAttachmentContent.get,
				params
			);
			if (status == 200) {
				yield put(
					MessageAndNoteAction.emailAttachmentContent.success(
						data
					)
				);
			} else {
				yield put(
					MessageAndNoteAction.emailAttachmentContent.fail(
						{}
					)
				);
			}
		} catch (error) {
			yield put(
				MessageAndNoteAction.emailAttachmentContent.fail(
					error as any
				)
			);
		}
	},
	getMessageText: function* (action: any) {
		const params = action.payload?.data;
		try {
			const { data, status } = yield call(
				domainMessage.getText,
				params
			);
			if (status == 200) {
				yield put(
					MessageAndNoteAction.messageText.success(
						data
					)
				);
			} else {
				yield put(
					MessageAndNoteAction.messageText.fail(
						{}
					)
				);
			}
		} catch (error) {
			yield put(
				MessageAndNoteAction.messageText.fail(
					error as any
				)
			);
		}
	},
	getMessageContent: function* (action: any) {
		const params = action.payload?.data;
		try {
			const { data, status } = yield call(
				domainMessage.getContent,
				params
			);
			if (status == 200) {
				yield put(
					MessageAndNoteAction.messageContent.success(
						data
					)
				);
			} else {
				yield put(
					MessageAndNoteAction.messageContent.fail(
						{}
					)
				);
			}
		} catch (error) {
			yield put(
				MessageAndNoteAction.messageContent.fail(
					error as any
				)
			);
		}
	},
	sendMessage: function* (action: any) {
		const params = action.payload?.data;
		try {
			const { data, status } = yield call(
				domainMessage.sendMessage,
				params
			);
			if (data?.statusCode == 200) {
				yield put(
					MessageAndNoteAction.dashboardMessage.success(
						{ ...data, isSuccess: true }
					)
				);
				let message = '';
				if (params.messageType === 'SMS')
					message =
						'US.COLLECTION.CASE:NOTESANDMESSAGES.SMS_SENT_SUCCESSFULLY';
				else if (params.messageType === 'Email')
					message =
						'US.COLLECTION.CASE:NOTESANDMESSAGES.EMAIL_SENT_SUCCESSFULLY';
				else if (params.messageType === 'PDF')
					message =
						'US.COLLECTION.CASE:NOTESANDMESSAGES.LETTER_SENT_SUCCESSFULLY';
				$MessageBox('success', message, '', '');
			} else {
				yield put(
					MessageAndNoteAction.dashboardMessage.fail(
						{ ...data, isSuccess: false }
					)
				);
			}
		} catch (error) {
			yield put(
				MessageAndNoteAction.dashboardMessage.fail(
					error as any
				)
			);
		}
	},
	sendCreditorPortalNote: function* (action: any) {
		const params = action.payload ?? {};
		try {
		  const { status } = yield call(notes.save, params);
		  if (status == 200) {
			yield put(MessageAndNoteAction.creditorPortalNote.success({}));
			$MessageBox(
			  "success",
			  "US.COLLECTION.CASE:NOTESANDMESSAGES.CREDITOR_PORTAL_NOTE_SENT_SUCCESSFULLY",
			  "",
			  ""
			);
		  } else {
			yield put(MessageAndNoteAction.creditorPortalNote.fail(new Error()));
			$MessageBox(
			  "error",
			  "US.COLLECTION.CASE:NOTESANDMESSAGES.CREDITOR_PORTAL_NOTE_SENT_FAILED",
			  "",
			  ""
			);
		  }
		} catch (error) {
		  $MessageBox(
			"error",
			"US.COLLECTION.CASE:NOTESANDMESSAGES.CREDITOR_PORTAL_NOTE_SENT_FAILED",
			"",
			""
		  );
		  yield put(MessageAndNoteAction.creditorPortalNote.fail(error as any));
		}
	},
};

export default [
	takeLatest(
		MessageAndNotes.SAVE_NOTE_DETAIL_START,
		messageAndNotesSagas.save
	),
	takeLatest(
		MessageAndNotes.GET_TEMPLATE_START,
		messageAndNotesSagas.getTemplate
	),
	takeLatest(
		MessageAndNotes.GET_FORMATTED_TEMPLATE_START,
		messageAndNotesSagas.getFormattedTemplate
	),
	takeLatest(
		MessageAndNotes.GET_DOMAIN_MESSAGE_BASIC_INFO_START,
		messageAndNotesSagas.getBasicInfo
	),
	takeLatest(
		MessageAndNotes.SEND_DOMAIN_MESSAGE_START,
		messageAndNotesSagas.send
	),
	takeLatest(
		MessageAndNotes.GET_PRINTED_DOCUMENT_START,
		messageAndNotesSagas.getPrintedDocument
	),
	takeLatest(
		MessageAndNotes.GET_UPLOAD_DOCUMENT_START,
		messageAndNotesSagas.getUploadedDocument
	),
	takeLatest(
		MessageAndNotes.GET_EMAIL_ATTACHMENT_CONTENT_START,
		messageAndNotesSagas.getAttachmentDocument
	),
	takeLatest(
		MessageAndNotes.GET_MESSAGE_TEXT,
		messageAndNotesSagas.getMessageText
	),
	takeLatest(
		MessageAndNotes.GET_MESSAGE_CONTENT,
		messageAndNotesSagas.getMessageContent
	),
	takeLatest(
		MessageAndNotes.SEND_DASHBOARD_MESSAGE_START,
		messageAndNotesSagas.sendMessage
	),
	takeLatest(
		MessageAndNotes.SEND_CREDITOR_PORTAL_MESSAGE_START,
		messageAndNotesSagas.sendCreditorPortalNote
	),
];
