import { Handlers } from "us.collection/constants";

/**
 * Get part payment details
 * @param state part payment state
 * @param payloadData action payload
 * @returns part payment state
 */
export const getPartPaymentInitial = (
  state: any,
  payloadData: any
) => {

    const {plan, calculateType} = payloadData ?? {}
  try {
    let partPaymentInit = {}
    if(calculateType == "calculateInstalment"){
        partPaymentInit = {...state,
                            noOfInstallment: plan?.isSaved ? 0  :  plan?.schemaList?.length,
                        }
    }
    else{
        partPaymentInit =  {...state, 
            noOfInstallment: plan?.isSaved ? 0  :  plan?.schemaList?.length,
            noOfInstallmentDuplicate: plan?.isSaved ? 0 : plan?.schemaList?.length,
            installmentAmount: plan?.isSaved ? 0.00 : plan?.firstInstallmentAmount,
            installmentAmountDuplicate: plan?.isSaved ? 0.00 : plan?.firstInstallmentAmount,
            forwardChargOrCosts: plan?.isSaved ? 'forwardCharge' : plan?.schemaList?.length < state.minInstallmentsForCostBMD ? 'forwardCharge' : state.forwardChargOrCosts,
            comunicationMethod: plan?.isSaved ? "SMS" : state?.comunicationMethod,
        }
    }
    return partPaymentInit;
  } catch (error) {
    console.log(error);
    return state;
  }
};