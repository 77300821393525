export enum Transaction {
	SAVE_TRANSACTIONS = 'SAVE_TRANSACTIONS',
	SAVE_TRANSACTIONS_SUCCESS = 'SAVE_TRANSACTIONS_SUCCESS',
	SAVE_TRANSACTIONS_FAIL = 'SAVE_TRANSACTIONS_FAIL',

	GET_TRANSACTIONS_BY_ENTITY_NUMBER_AND_ENTITY_TYPE_STRAT = 'GET_TRANSACTIONS_BY_ENTITY_NUMBER_AND_ENTITY_TYPE_STRAT',
	GET_TRANSACTIONS_BY_ENTITY_NUMBER_AND_ENTITY_TYPE_SUCCESS = 'GET_TRANSACTIONS_BY_ENTITY_NUMBER_AND_ENTITY_TYPE_SUCCESS',
	GET_TRANSACTIONS_BY_ENTITY_NUMBER_AND_ENTITY_TYPE_FAIL = 'GET_TRANSACTIONS_BY_ENTITY_NUMBER_AND_ENTITY_TYPE_FAIL',

	DELETE_TRANSACTION = 'DELETE_TRANSACTION',
	DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS',
	DELETE_TRANSACTION_FAIL = 'DELETE_TRANSACTION_FAIL',

	ADD_CREDIT_NOTE = 'ADD_CREDIT_NOTE',
	ADD_CREDIT_NOTE_SUCCESS = 'ADD_CREDIT_NOTE_SUCCESS',
	ADD_CREDIT_NOTE_FAIL = 'ADD_CREDIT_NOTE_FAIL',

	GET_MAPPING_TRANSACTIONS_BY_TRANSACTIONID_STRAT = 'GET_MAPPING_TRANSACTIONS_BY_TRANSACTIONID_STRAT',
	GET_MAPPING_TRANSACTIONS_BY_TRANSACTIONID_SUCCESS = 'GET_MAPPING_TRANSACTIONS_BY_TRANSACTIONID_SUCCESS',
	GET_MAPPING_TRANSACTIONS_BY_TRANSACTIONID_FAIL = 'GET_MAPPING_TRANSACTIONS_BY_TRANSACTIONID_FAIL',

	SELECT_TRANSACTION = 'SELECT_TRANSACTION',
	RESET_TRANSACTION_INFO = 'RESET_TRANSACTION_INFO',
	MANAGE_TRANSACION_DRAWER = 'MANAGE_TRANSACION_DRAWER',
	MANAGE_MAPPING_TRANSACION_DRAWER = 'MANAGE_MAPPING_TRANSACION_DRAWER',
	MANAGE_EDIT_INTEREST_DRAWER = 'MANAGE_EDIT_INTEREST_DRAWER',

	SET_CURRENT_USER = 'SET_CURRENT_USER',

	GET_AR_DETAILS = 'GET_AR_DETAILS',
	GET_AR_DETAILS_SUCCESS = 'GET_AR_DETAILS_SUCCESS',
	GET_AR_DETAILS_FAIL = 'GET_AR_DETAILS_FAIL',

	GET_POP_OVER_ITEMS = 'GET_POP_OVER_ITEMS',
	GET_POP_OVER_ITEMS_SUCCESS = 'GET_POP_OVER_ITEMS_SUCCESS',
	GET_POP_OVER_ITEMS_FAIL = 'GET_POP_OVER_ITEMS_FAIL',

	MANAGE_DYNAMIC_POP_OVER = 'MANAGE_DYNAMIC_POP_OVER',
	MANAGE_POP_OVER = 'MANAGE_POP_OVER',

	SAVE_DYNAMIC_POP_OVER = 'SAVE_DYNAMIC_POP_OVER',
	SAVE_DYNAMIC_POP_OVER_SUCCESS = 'SAVE_DYNAMIC_POP_OVER_SUCCESS',
	SAVE_DYNAMIC_POP_OVER_FAIL = 'SAVE_DYNAMIC_POP_OVER_FAIL',

	GET_POP_OVER_FORM_VALUES = 'GET_POP_OVER_FORM_VALUES',
	GET_POP_OVER_FORM_VALUES_SUCCESS = 'GET_POP_OVER_FORM_VALUES_SUCCESS',
	GET_POP_OVER_FORM_VALUES_FAIL = 'GET_POP_OVER_FORM_VALUES_FAIL',

	MAP_ITEM_BY_ITEM = 'MAP_ITEM_BY_ITEM',
	MAP_ITEM_BY_ITEM_SUCCESS = 'MAP_ITEM_BY_ITEM_SUCCESS',
	MAP_ITEM_BY_ITEM_FAIL = 'MAP_ITEM_BY_ITEM_FAIL',

	GET_MAPPING_TRANSACTIONS = 'GET_MAPPING_TRANSACTIONS',
	GET_MAPPING_TRANSACTIONS_SUCCESS = 'GET_MAPPING_TRANSACTIONS_SUCCESS',
	GET_MAPPING_TRANSACTIONS_FAIL = 'GET_MAPPING_TRANSACTIONS_FAIL',

	UPDATE_MAPPING_TRANSACTIONS = 'UPDATE_MAPPING_TRANSACTIONS',
	UPDATE_MAPPING_TRANSACTIONS_SUCCESS = 'UPDATE_MAPPING_TRANSACTIONS_SUCCESS',
	UPDATE_MAPPING_TRANSACTIONS_FAIL = 'UPDATE_MAPPING_TRANSACTIONS_FAIL',

	UPDATE_DRAWER_SETTINGS = 'UPDATE_DRAWER_SETTINGS',

	GET_MAPPING_CASES = 'GET_MAPPING_CASES',
	GET_MAPPING_CASES_SUCCESS = 'GET_MAPPING_CASES_SUCCESS',
	GET_MAPPING_CASES_FAIL = 'GET_MAPPING_CASES_FAIL',

	GET_INTEREST_BMD_VALUE = 'GET_INTEREST_BMD_VALUE',
	GET_INTEREST_BMD_VALUE_SUCCESS = 'GET_INTEREST_BMD_VALUE_SUCCESS',
	GET_INTEREST_BMD_VALUE_FAIL = 'GET_INTEREST_BMD_VALUE_FAIL',

	RESET_INTEREST_PREDICTION = 'RESET_INTEREST_PREDICTION',
}
