import { IFinancialAssetsUpdate } from "./interfaces";
import moment from "moment";

export function UpdateFinancialAssets(this: IFinancialAssetsUpdate) {
  const {
    assetId,
    entityId,
    entityType,
    comment,
    estimatedValue,
    valuationAmount,
    valuationDate,
    financialInstitutionName,
    financialInstitutionAddress1,
    financialInstitutionAddress2,
    nin,
    financialInstitutionPostalCode,
    financialInstitutionMunicipalityCode,
    description,
  } = this;
  return {
    assetId,
    entityId,
    entityType,
    nameOrType: comment,
    estimatedValue,
    valuationAmount,
    valuationDate:
      valuationDate && moment(valuationDate).isValid()
        ? moment(valuationDate).format("YYYY-MM-DD")
        : null,
    financialInstitutionName,
    financialInstitutionAddress1,
    financialInstitutionAddress2,
    nin,
    financialInstitutionPostalCode,
    financialInstitutionMunicipalityCode,
    description,
  };
}
