import { IAPIAction } from "us.collection/interfaces";
import { ARNote } from "us.collection/constants";

export const ARNoteAction: Readonly<IAPIAction> = {
  arNoteHistory: {
    get: (data) => ({
      type: ARNote.GET_AR_NOTE_HISTORY_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ARNote.GET_AR_NOTE_HISTORY_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ARNote.GET_AR_NOTE_HISTORY_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  addArNote: {
    save: (data) => ({
      type: ARNote.ADD_AR_NOTE_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ARNote.ADD_AR_NOTE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ARNote.ADD_AR_NOTE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    openDrawer: (data) => ({
      type: ARNote.ADD_AR_NOTE_DRAWER,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  deleteArNote: {
    save: (data) => ({
      type: ARNote.DELETE_AR_NOTE_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ARNote.DELETE_AR_NOTE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ARNote.DELETE_AR_NOTE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
