import { SentneceDrawerStatus } from "us.collection.case/constants";
import { ICourtSentenceDrawer } from "us.collection.case/interfaces";
import { IReturnAction } from "us.collection/interfaces";

/**
 * A function to handle the drawer
 * @param openDrawer Action to open drawer
 * @param isVisible Does the drawer need to be opend
 * @param name Drawer heading
 * @param status The Status of the opened drawer
 * @param sentenceId Sentence the drawer will open with
 * @param requestCaseAmounts Does the case amounts need to be requested
 * @param caseNumber The case numebr of the case amouts
 */
export const drawerStateHandler = (
  openDrawer: (<T>(data: object | T[]) => IReturnAction<T>) | undefined,
  isVisible: boolean,
  name = "",
  status = SentneceDrawerStatus.NOT_OPENED,
  sentenceId = 0,
  requestCaseAmounts = false,
  caseNumber = 0
) => {
  const drawerStatusParam: ICourtSentenceDrawer = {
    isVisible,
    caseNumber,
    name,
    requestCaseAmounts,
    sentenceId,
    status,
  };
  openDrawer && openDrawer(drawerStatusParam);
};
