import { ICase } from "us.collection.transactions/interfaces";

/**
 * @function
 * @description - repository to get the API request object for create case
 * @param {*} this - any type object
 * @return {*} - API calling object
 */
export function GetCreateCaseRequestObject(this: any) {
  return {
    arNo: this.arId,
    sourceCases: this.selectedCases.map((x: ICase) => parseInt(x.caseId)),
    creditorId:this.creditorId,
    debtorId:this.debtorId,
  };
}
