import { AuthorizationLevel, URLType } from 'us.collection/constants';
import { GDPRState } from 'us.collection/interfaces';

/**
 * Get GDPR view level
 * @param accessAuthorityLevel User authority level
 * @param isCommentEnable Default comment enable or disable
 * @returns GDPR comment box display or not
 */
export const getGDPRViewDetails = (authorizationProfile: any): boolean => {
	try {
		if (authorizationProfile) {
			const { accessAuthorityLevel, isCommentEnable } =
				authorizationProfile ?? {};
			if (
				accessAuthorityLevel ==
					AuthorizationLevel.COMPULSARY ||
				accessAuthorityLevel ==
					AuthorizationLevel.OPTIONAL ||
				(accessAuthorityLevel ==
					AuthorizationLevel.CAN_DISABLE &&
					!isCommentEnable)
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
};

/**
 * Get GDPR view of=r dashboard view
 * @param AuthorizationProfile Authorization profile details
 * @returns showGDPRCommentBox and showDashboard state
 */
export const getGDPRView = (authorizationProfile: any): GDPRState => {
	try {
		if (getGDPRViewDetails(authorizationProfile)) {
			return {
				showGDPRCommentBox: true,
				showDashboard: false,
				gdprCommentIsSaving: false,
			};
		} else {
			return {
				showGDPRCommentBox: false,
				showDashboard: true,
				gdprCommentIsSaving: false,
			};
		}
	} catch (error) {
		return {
			showGDPRCommentBox: false,
			showDashboard: false,
			gdprCommentIsSaving: false,
		};
	}
};
