import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { AddEnforcement } from 'us.collection.case/components/Enforcements/Add';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';
import _ from 'lodash';
import Common from 'us.common';
import {
	PlusOutlined,
	DeleteOutlined,
	MoreOutlined,
	EditOutlined,
	ReloadOutlined,
} from 'us.icons';
import {
	$Drawer,
	$Table,
	$TableTree,
	$Popconfirm,
	$AmountLabel,
	$Skeleton,
} from 'us.common/components';
import {
	enforcements,
	AccountSummeryWidgetActions,
} from 'us.collection.case/actions';
import { IRootState } from 'us.collection/interfaces';
import { IBaseEnforcement } from 'us.collection.case/interfaces';
import { IOnFilter, IOnSort } from 'us.common/components/antd/TableTree';
import { CaseType } from 'us.helper/types/enums';
import moment from 'moment';
import { appInsights } from 'us.helper';
import { EntityType } from 'us.collection/constants';
import { RootState } from 'us.helper/types';

const {
	$Affix,
	$PageHeader,
	$Divider,
	$Button,
	$DateLabel,
	$Popover,
	$Tooltip,
	$Search,
} = Common.Components;
const { accountSummery } = AccountSummeryWidgetActions;

const Enforcements: React.FC<IBaseEnforcement> = (props) => {
	const { t } = useTranslation();

	const {
		getEnforcements,
		enforcements,
		drawer,
		deleteEnforcement,
		filterEnforcement,
		manageEnforcementDrawer,
		resetEnforcementDetail,
		selectEnforcement,
		metaData,
		history,
		location,
		getAccountSummeryWidgetData,
	} = props;
	const { caseNo, caseId, entityType } = metaData.data ?? {};
	const [popVisible, setPopVisibile] = useState<number>(-1);

	useEffect(() => {
		if (entityType === EntityType.CASE) {
			if (getEnforcements) {
				getEnforcements({ caseId });
			}
		}
	}, [entityType]);

	const onClose = () => {
		manageEnforcementDrawer &&
			manageEnforcementDrawer({
				title: '',
				visible: false,
				isNewEnforcement: true,
			});
	};

	const addNewEnforcementHandler = () => {
		resetEnforcementDetail && resetEnforcementDetail({});
		manageEnforcementDrawer &&
			manageEnforcementDrawer({
				title: t(
					'US.COLLECTION.CASE:ENFORCEMENTS.NEWENFORCEMENT'
				),
				visible: true,
				isNewEnforcement: true,
			});
	};

	const updateEnforcementHandler = (enforcementId: number) => {
		const selectedEnforcement = _.find(enforcements?.data, {
			enforcementId,
		});
		selectEnforcement &&
			selectEnforcement({ ...selectedEnforcement });
		manageEnforcementDrawer &&
			manageEnforcementDrawer({
				title: t(
					'US.COLLECTION.CASE:ENFORCEMENTS.EDIT_ENFORCEMENT'
				),
				visible: true,
				isNewEnforcement: false,
			});
	};

	// Popover actions in table
	const content = (record: any) => (
		<div className='table-more-content'>
			<div
				className='d-flex flex-row more-item'
				onClick={() => {
					setPopVisibile(-1);
					updateEnforcementHandler(
						record?.enforcementId
					);
				}}>
				<div className='p-1'>
					<EditOutlined />
				</div>
				<div className='p-1'>
					{t('US.COLLECTION.COMMON:COMMON.EDIT')}
				</div>
			</div>
			<$Popconfirm
				title={t(
					'US.COLLECTION.CASE:EXTENDEDFIELD.DELETECONFIRM'
				)}
				onConfirm={() => {
					setPopVisibile(-1);
					deleteEnforcement &&
						deleteEnforcement({
							enforcementId:
								record?.enforcementId,
						});
				}}
				onCancel={() => setPopVisibile(-1)}>
				<div className='d-flex mb-2 flex-row more-item text-error'>
					<div className='p-1'>
						<DeleteOutlined />
					</div>
					<div className='p-1'>
						{t(
							'US.COLLECTION.COMMON:COMMON.DELETE'
						)}
					</div>
				</div>
			</$Popconfirm>
		</div>
	);

	const columns: any = [
		{
			title: '',
			dataIndex: 'referenceId',
			key: 'referenceId',
			width: '23px',
			className: 'group-td no-border py-3',
			customRenderParent: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<span>{`${t(
							'US.COLLECTION.CASE:ENFORCEMENTS.REFERENCE_NO'
						)} : ${
							record?.referenceId
						}`}</span>
					),
					key: index,
					props: {
						colSpan: 8,
					},
				};
			},
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: <></>,
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: '',
			key: 'more',
			dataIndex: 'more',
			width: '50px',
			className: 'no-border',
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Popover
							placement='rightTop'
							content={content(
								record
							)}
							destroyTooltipOnHide
							trigger='click'
							visible={
								record.key ==
								popVisible
							}
							onVisibleChange={(
								visbile: boolean
							) =>
								setPopVisibile(
									visbile
										? record.key
										: -1
								)
							}>
							<$Button
								icon={
									<MoreOutlined />
								}
								size='small'
							/>
						</$Popover>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: t(
				'US.COLLECTION.CASE:ENFORCEMENTS.OFFICIAL_REGISTRATION_DATE'
			),
			dataIndex: 'registrationDate',
			key: 'registrationDate',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$DateLabel
							value={
								record?.registrationDate
							}
						/>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: t('US.COLLECTION.CASE:ENFORCEMENTS.TYPE'),
			dataIndex: 'enforcementTypes',
			key: 'enforcementTypes',
			ellipsis: true,
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={
								record?.enforcementTypes
							}>
							{
								record?.enforcementTypes
							}
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: t('US.COLLECTION.CASE:ENFORCEMENTS.FACE_VALUE'),
			dataIndex: 'totalFaceValue',
			key: 'totalFaceValue',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={
								record?.totalFaceValue
							}>
							<$AmountLabel
								value={
									record?.totalFaceValue
								}
							/>
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: t('US.COLLECTION.CASE:ENFORCEMENTS.VALUATION'),
			dataIndex: 'totalValuation',
			key: 'totalValuation',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={
								record?.totalValuation
							}>
							<$AmountLabel
								value={
									record?.totalValuation
								}
							/>
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: t('US.COLLECTION.CASE:ENFORCEMENTS.COMMENT'),
			dataIndex: 'comment',
			key: 'comment',
			ellipsis: true,
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={record?.comment}>
							{record?.comment}
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: t('US.COLLECTION.CASE:ENFORCEMENTS.LAST_UPDATE'),
			dataIndex: 'lastModifiedDate',
			key: 'lastModifiedDate',
			ellipsis: true,
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<div className='d-flex flex-column'>
							{moment
								.utc(
									record?.lastModifiedDate
								)
								.isValid() &&
								moment
									.utc(
										record?.lastModifiedDate
									)
									.isAfter(
										moment.utc(
											'0001-01-01'
										)
									) && (
									<$DateLabel
										value={
											record?.lastModifiedDate
										}
									/>
								)}
							<$Tooltip
								placement='topLeft'
								title={
									record?.lastModifiedUser
								}>
								{record
									?.lastModifiedUser
									?.length >
									0 && (
									<span className='text-truncate text-muted font-sm'>
										{t(
											'US.COLLECTION.CASE:ENFORCEMENTS.BY'
										)}{' '}
										{
											record?.lastModifiedUser
										}
									</span>
								)}
							</$Tooltip>
						</div>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
	];

	const handleSort: IOnSort = (sortData, dataSource) => {
		try {
			return sortData(
				dataSource.map((i: any) => {
					return {
						...i,
						children: sortData(i.children),
					};
				})
			);
		} catch (error) {
			appInsights.trackException(
				`Enforcement list sorting Exception - ${error}`
			);
			return dataSource;
		}
	};

	const handleFilter: IOnFilter = (searchData, dataSource) => {
		try {
			let result: any[] = [];
			dataSource.flatMap((i: any) => {
				searchData(i?.children)?.length != 0 &&
					result.push({
						...i,
						children:
							searchData(i?.children)
								?.length == 0
								? []
								: searchData(
										i?.children
								  ),
					});
			});
			return result;
		} catch (error) {
			appInsights.trackException(
				`Enforcement list filtering Exception - ${error}`
			);
			return dataSource;
		}
	};

	const onSearchEnforcement = (filterValue: string) => {
		filterEnforcement && filterEnforcement({ filterValue });
	};
	const onChangeEnforcement = (filterValue: string) => {
		if (filterValue.length === 0) {
			filterEnforcement && filterEnforcement({ filterValue });
		}
	};

	const minimize = () => {
		getAccountSummeryWidgetData &&
			getAccountSummeryWidgetData({
				EntityType:
					entityType === CaseType.S
						? 'Subcase'
						: 'Case',
				EntityId: caseId,
			});
		history.push({ ...location, pathname: `/case/${caseNo}` });
	};

	const refreshButtonHandler = () => {
		if (entityType === EntityType.CASE) {
			if (getEnforcements) {
				getEnforcements({ caseId });
			}
		}
	};

	return (
		<Formik
			initialValues={{}}
			enableReinitialize
			onSubmit={() => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...restProps
			}: any) => (
				<>
					<div className='space-content'>
						<$Affix offsetTop={80}>
							<div className='page-header header-border pr-0'>
								<div className='d-flex flex-row align-items-center justify-content-between'>
									<div className='d-flex align-items-center'>
										<$PageHeader
											className='px-0'
											onBack={
												minimize
											}
											title={t(
												'US.COLLECTION.CASE:ENFORCEMENTS.ENFORCEMENTS'
											)}
										/>
										<$Divider
											className='bui-devider'
											type='vertical'
										/>
										<$Skeleton
											loading={
												enforcements?.isFetching
											}
											active
											paragraph={{
												rows: 0,
											}}>
											<$Button
												type='default'
												size='small'
												icon={
													<PlusOutlined />
												}
												onClick={() =>
													addNewEnforcementHandler()
												}>
												{t(
													'US.COLLECTION.CASE:ENFORCEMENTS.NEWENFORCEMENT'
												)}
											</$Button>
										</$Skeleton>
									</div>
									<div>
										<$Search
											name='refNumberSearch'
											size='small'
											style={{
												width: 250,
											}}
											placeholder={t(
												'US.COLLECTION.CASE:ENFORCEMENTS.REFERENCE_NO'
											)}
											allowClear={
												true
											}
											onChange={(
												e: any
											) =>
												onChangeEnforcement(
													e
														?.target
														?.value
												)
											}
											onSearch={(
												e: any
											) =>
												onSearchEnforcement(
													e
												)
											}
										/>

										<$Tooltip
											title={t(
												'US.COLLECTION.COMMON:COMMON.REFRESH'
											)}>
											<$Button
												type='dashed'
												size='small'
												onClick={() =>
													refreshButtonHandler()
												}
												icon={
													<ReloadOutlined />
												}
												className='ml-3'
											/>
										</$Tooltip>
									</div>
								</div>
							</div>
						</$Affix>
						<div>
							<$Affix offsetTop={127}>
								<$Skeleton
									loading={
										enforcements?.isFetching
									}>
									<$TableTree
										rowKey={(
											record: any
										) => {
											return record.key;
										}}
										columns={
											columns
										}
										data={
											enforcements?.dataSource
										}
										onSort={
											handleSort
										}
										onFilter={
											handleFilter
										}
										bordered
										className='bui-tabletree mt-3'
										filterOnType={
											true
										}
										defaultExpandAllRows={
											true
										}
										scroll={{
											x: 1200,
											y: 'calc(100vh - 280px)',
										}}
										size='small'
										resetOnSourceChange={
											true
										}
										onRow={(
											record: any,
											rowIndex: any
										) => {
											return {
												onDoubleClick:
													(
														event: any
													) => {
														record?.enforcementId &&
															updateEnforcementHandler(
																record?.enforcementId
															);
													},
											};
										}}
									/>
								</$Skeleton>
							</$Affix>
						</div>
					</div>
					<$Drawer
						title={drawer.title}
						width={'840'}
						visible={drawer.visible}
						onClose={onClose}
						destroyOnClose>
						<AddEnforcement
							{...props}
							onClose={onClose}
						/>
					</$Drawer>
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { enforcement, domainView } = state;
	const { enforcements, drawer } = enforcement;
	const { metaData } = domainView;
	return {
		enforcements,
		drawer,
		metaData,
	};
};

const mapDispatchToProps = {
	getEnforcements: enforcements.enforcements.get,
	deleteEnforcement: enforcements.enforcement.delete,
	filterEnforcement: enforcements.enforcements.setFilter,
	manageEnforcementDrawer: enforcements.enforcement.manageDrawer,
	resetEnforcementDetail: enforcements.enforcement.reset,
	selectEnforcement: enforcements.enforcement.set,
	getAccountSummeryWidgetData: accountSummery.get,
};
export default connect(mapStateToProps, mapDispatchToProps)(Enforcements);
