import * as Yup from "yup";

interface ICategoryValidation extends Yup.MixedSchema {
    categoryName: string,
    displayName: string,
    description: string
}
const CategoryValidation = Yup.object<ICategoryValidation>().shape({
    categoryName: Yup.string()
        .required(
        "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
        ),
    displayName: Yup.string()
        .required(
        "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
        )
});

export default CategoryValidation;
