import {
  httpCollection,
  versionedHttpCollection,
} from "us.helper/http/collection";
import ServiceConfig from 'service.config.js';


export default {

  get: <
    P extends {
      caseId: number;
      type: string;
    }
  >(
    params: P
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await versionedHttpCollection.get(
          "entityservice",
          `entities/otherParties/${params.caseId}/${params.type}`,
          {},
          ServiceConfig()[`entityservice`]['entities.otherParties']
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getById: <
    P extends {
      type: string;
      entityId: number;
    }
  >(
    params: P
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await versionedHttpCollection.get(
          "entityService",
          `entities/otherParty/${params.entityId}/${params.type}`,
          {},
          ServiceConfig()[`entityservice`]['entities.otherParty.:entityId']
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  save: (params: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await versionedHttpCollection.post(
          "entityservice",
          "entities/otherparty",
          params,
          ServiceConfig()[`entityservice`]['entities.otherparty']
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  delete: <P extends
    {
      entityId: number,
      entityType: string,
      entRoleId: string
    }
  >(params: P): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await versionedHttpCollection.patch(
          "entityservice",
          "entities/otherparties",
          {
            entityId: params.entityId,
            entityType: params.entityType,
            entRoleId: params.entRoleId
          },
          ServiceConfig()[`arservice`]['otherparty.otherpartyRoleType']
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateActiveState: <
    P extends {
      caseId: number;
      entRoleId: number;
      type: string;
      activeStatus: boolean;
    }
  >(
    params: P
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await versionedHttpCollection.patch(
          "entityService",
          `entities/otherParty`,
          {
            entityId: params.caseId,
            entRoleId: params.entRoleId,
            otherPartyBelongsType: params.type,
            activeStatus: params.activeStatus,
          },
          ServiceConfig()[`entityservice`]['entities.otherParty']
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  roleType: {
    get: <
      P extends {
        otherPartyType: string;
      }
    >(
      params: P
    ): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.get(
            "arservice",
            `otherparty/otherpartyRoleTypes/${params.otherPartyType}`,
            {},
            ServiceConfig()[`arservice`]['otherparty.otherpartyRoleTypes']
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },

    save: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.post(
            "arservice",
            "otherparty/otherpartyRoleType",
            params,
            ServiceConfig()[`arservice`]['otherparty.otherpartyRoleType']
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    delete: <P extends
      { roleTypeId: number }
    >(params: P): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.delete(
            "entityservice",
            "entities/otherpartyroletype",
            params.roleTypeId,
            {},
            ServiceConfig()[`entityservice`]['entities.otherpartyRoleType']
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    }
  },
  search: <
    P extends {
      roleType: string;
      searchCriteria: string;
      text: string
    }
  >(
    params: P
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await versionedHttpCollection.get(
          "searchservice",
          `entitysearch`,
          {
            roleType: params.roleType,
            searchCriteria: params.searchCriteria,
            keyword: params.text
          },
          ServiceConfig()[`searchservice`]['entitysearch']
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  postelArea: {
    search: async <
      P extends {
        countryCode: string;
        searchText: string;
      }
    >(
      params: P
    ): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.post(
            'entityservice',
            `postalCodes`,
            {
              countryCode: params.countryCode,
              searchText: params.searchText,
              searchBy: "PostalCode"
            },
            ServiceConfig()[`entityservice`][`postalCodes`]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    }
  },
  municipals: {
    search: async <
      P extends {
        countryCode: string;
        searchText: string;
      }
    >(
      params: P
    ): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.get(
            'entityservice',
            `entities/municipalities`,
            {
              countryCode: params.countryCode,
              searchText: params.searchText,
            },
            ServiceConfig()[`entityservice`][`entities.municipalities`]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    }
  }
};
