import moment from 'moment';
import { IMetaData } from 'us.collection/interfaces';
import { IEmployerAsset } from '../interfaces';

/**
 * To get the API required object in saving and updating the employer
 * @param data The formik form data
 * @param metadata case metadata
 * @param isEditing the state of the open drawer (editing or adding a new asset)
 * @returns
 */
export const SaveUpdateEmployer = (
	data: any,
	metadata: IMetaData,
	isEditing: boolean
): IEmployerAsset => {
	if (data?.otherPartyType === 'N') {
		data.organizationNo = data?.nin;
	} else {
		data.personNo = data?.nin;
	}

	return {
		action: isEditing ? 'update' : 'add',
		entityId: metadata.debtorId,
		otherpartyBelongstype: 'Debtor',
		entRoleId: data.entRoleId ?? -1,
		firstName: data.firstName ?? '',
		lastName: data.lastName ?? '',
		address1: data.address1 ?? '',
		address2: data.address2 ?? '',
		address3: data.address3 ?? '',
		otherpartyRoleType: 'Employer',
		otherpartyType: data.otherPartyType ?? '',
		personNo: data.personNo ?? '',
		organizationNo: data.organizationNo ?? '',
		birthDate:
			data.birthDate && moment(data.birthDate).isValid()
				? moment(data.birthDate).format('YYYY-MM-DD')
				: '',
		telephone: data.telephoneNo ?? '',
		emailAddress: data.emailAddress ?? '',
		reference: data.reference ?? '',
		zipCode: data.zipCode ?? '',
		zipName: data.zipName ?? '',
		municipalityCode: data.municipalityCode ?? '',
		municipalityName: data.municipalityName ?? '',
		countyId: '',
		countryId: data.country ?? '',
		isDefault: true,
		isAddressKnown:
			data.zipCode && data.zipCode.toString().length > 0
				? true
				: false,
		calledFromAssetEntNo: metadata.debtorEntNumber.toString(),
		debtorEntRoleId: metadata.debtorId,
	};
};
