export const getTransactionList = () => ({
	type: 'GET_TRANSACTION_LIST',
});

export const getTransactionListSuccess = (data: any) => ({
	type: 'GET_TRANSACTION_LIST_SUCCESS',
	payload: data,
});

export * from './Transactions';
export * from './TransactionTypes';
export * from './PaymentDistrubution';
export * from './ApplicableCourts';
export * from './CasesAndSubcases';
export * from './InterestApplication';
