import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from "service.config.js";

export default {
    AddObsoleteDate: {
      save: (params: any): Promise<any> => {
        return new Promise<{}>(async (resolve, reject) => {
          try {
            const { data } = await versionedHttpCollection.post(
              "arservice",
              `ars/${params.caseId}/caseinformationdate`,
              params,
              ServiceConfig()[`arservice`]["caseinformationdate.save"]
            );
            resolve(data);
          } catch (error) {
            reject(error);
          }
        });
      },
    },
};