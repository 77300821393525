export enum NonProductionDay {
    SAVE_NON_PRODUCTION_DAYS = "SAVE_NON_PRODUCTION_DAYS",
    SAVE_NON_PRODUCTION_DAYS_SUCCESS = "SAVE_NON_PRODUCTION_DAYS_SUCCESS",
    SAVE_NON_PRODUCTION_DAYS_FAIL = "SAVE_NON_PRODUCTION_DAYS_FAIL",

    SAVE_CALENDAR = "SAVE_CALENDAR",
    SAVE_CALENDAR_SUCCESS = "SAVE_CALENDAR_SUCCESS",
    SAVE_CALENDAR_FAIL = "SAVE_CALENDAR_FAIL",

    CHANGE_SELECTED_YEAR = "CHANGE_SELECTED_YEAR",

    GET_CALENDARS = "GET_CALENDARS",
    GET_CALENDARS_SUCCESS = "GET_CALENDARS_SUCCESS",
    GET_CALENDARS_FAIL = "GET_CALENDARS_FAIL",
    
    RESET_NON_PRODUCTION_DAYS = "RESET_NON_PRODUCTION_DAYS",
    
    CANCEL_SELECTED_CALENDAR = "CANCEL_SELECTED_CALENDAR",
    
    GET_NON_PRODUCTION_DAYS = "GET_NON_PRODUCTION_DAYS",
    GET_DEFAULT_NON_PRODUCTION_DAYS = "GET_DEFAULT_NON_PRODUCTION_DAYS",
    GET_NON_PRODUCTION_DAYS_SUCCESS = "GET_NON_PRODUCTION_DAYS_SUCCESS",
    GET_NON_PRODUCTION_DAYS_FAIL = "GET_NON_PRODUCTION_DAYS_FAIL",

    GET_NON_PRODUCTION_DAYS_WITH_SMS_SCHEDULE = "GET_NON_PRODUCTION_DAYS_WITH_SMS_SCHEDULE",
    GET_NON_PRODUCTION_DAYS_WITH_SMS_SCHEDULE_SUCCESS = "GET_NON_PRODUCTION_DAYS_WITH_SMS_SCHEDULE_SUCCESS",
    GET_NON_PRODUCTION_DAYS_WITH_SMS_SCHEDULE_FAIL = "GET_NON_PRODUCTION_DAYS_WITH_SMS_SCHEDULE_FAIL",
    
    GET_SMS_SCHEDULES = "GET_SMS_SCHEDULES",
    GET_SMS_SCHEDULES_SUCCESS = "GET_SMS_SCHEDULES_SUCCESS",
    GET_SMS_SCHEDULES_FAIL = "GET_SMS_SCHEDULES_FAIL",
    
    GET_PREVIOUS_CALENDAR = "GET_PREVIOUS_CALENDAR",
    GET_PREVIOUS_CALENDAR_SUCCESS = "GET_PREVIOUS_CALENDAR_SUCCESS",
    GET_PREVIOUS_CALENDAR_FAIL = "GET_PREVIOUS_CALENDAR_FAIL",

    IMPORT_CALENDAR_SELECT = "IMPORT_CALENDAR_SELECT",

    SELECT_NON_PRODUCTION_DAY = "SELECT_NON_PRODUCTION_DAY",
    SELECT_CRITERIA = "SELECT_CRITERIA",

    ADD_NEW_NON_PRODUCTION_DAY = "ADD_NEW_NON_PRODUCTION_DAY",
}