import { CaseRequest } from "us.collection.creditor/components/CreditorCases/Interfaces";
import { SERVER_DATE_FORMAT } from "../Constants";

export function SearchCreditorCases(this: any): CaseRequest {
  const { creditorId, isCaseOpen, isCaseClosed, caseDateRange, searchDateType } =
    this;
  return {
    currentState: {
      isCaseOpen: isCaseOpen,
      isCaseClosed: isCaseClosed,
      caseDateRange: caseDateRange,
    },
    params: {
      creditorId: Number(creditorId),
      invoiceType: isCaseOpen ? (isCaseClosed ? -1 : 0) : (isCaseClosed ? 2 : -2),
      startDate: caseDateRange[0].format(SERVER_DATE_FORMAT),
      endDate: caseDateRange[1].format(SERVER_DATE_FORMAT),
      EntityType:"Case",
      searchDateType: searchDateType
    },
  };
}
