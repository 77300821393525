
/**
 * Call server using http method.
 *
 * @param {String} url  URL
 * @param {String} method  Method
 * @param {Object} body request body
 *
 * @returns {Promise} response data
 */
const fetchData = async (url: string, method: "GET" | "POST" | "PUT" | "DELETE" | "OPTION", body?: any) => {
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': '',
  };
  const token = localStorage.getItem('token');
  if (token) {
    headers = { ...headers, 'Authorization': `Bearer ${token}`}
  }

  const response = await fetch(url, {
    credentials: 'include',
    headers: headers,
    method: method,
    body: (method == "GET")? null : JSON.stringify(body),
  });

  if (!response.ok) {
    let errorMessage;

    try {
      const data = await response.json();
      errorMessage = data.message || response.statusText;
    } catch (e) {
      errorMessage = response.statusText;
    }

    const error = new Error(errorMessage);
    error.name = 'Server request error';

    throw error;
  }
  let data = {};

  try {
    data = await response.json();
  } catch (e) {
    // ignore for empty rsp
  }
  return data;
};

export default fetchData;





