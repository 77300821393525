import moment from 'moment';
import { IGetCalculatedInterest } from '../Interfaces';
import { DateFormats } from 'us.collection.transactions/constants';

export function GetCalculatedInterest(this: any): IGetCalculatedInterest {
	return {
		transactionId: this.arItemNo,
		interestType: this.interestType,
		interestFromDate: moment(this.interestFromDate).format(DateFormats.REQ),
		interestRate: this.interestRate,
	};
}
