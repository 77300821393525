export enum SubDrawerName {
  POSTAL_CODE,
  MUNICIPALITY,
  EMPLOYER,
  COUNTRY_CHANGE_WARN,
}

interface IDrawerVisibility {
  DrawerName: SubDrawerName;
  IsVisible: boolean;
}

export interface IEmployer {
  DrawerVisibility: IDrawerVisibility;
  OldCountryValue: string;
}

export interface IEmployerScreen {
  showOnly?: boolean
}

export interface IReturnType {
  zipName: string;
  municipalityCode: string;
  municipalityName: string;
  zipCode: string;
}

export interface IEmployerAsset {
  action: string;
  entityId: number;
  otherpartyBelongstype: "Debtor";
  entRoleId: number;
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  address3: string;
  gender?: string;
  otherpartyRoleType: "Employer";
  otherpartyType: string;
  personNo: string;
  organizationNo: string;
  birthDate: string;
  telephone: string;
  emailAddress: string;
  reference: string;
  zipCode: string;
  zipName: string;
  municipalityCode: string;
  municipalityName: string;
  countyId: string;
  countryId: string;
  isDefault: boolean;
  isAddressKnown: boolean;
  calledFromAssetEntNo: string;
  debtorEntRoleId: number;
}
