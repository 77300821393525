import { ICaseDocument } from "us.collection.documents/interfaces";
import moment from "moment";
import _ from "lodash";

type filter = {
  uploadedDate: string;
  documentName: string;
};

export const filterCaseDocuments = (
  caseDocuments: Array<ICaseDocument>,
  filters: filter,
  currentDateFormat: string
) => {
  const beforeGroup = caseDocuments.filter((caseDocument: ICaseDocument) => {
    let matched = true;
    if (filters.uploadedDate != "" && filters.uploadedDate != undefined) {
      if (
        moment(caseDocument.uploadedDate).format(currentDateFormat) !=
        filters.uploadedDate
      ) {
        matched = false;
      }
    }
    if (filters.documentName != "" && filters.documentName != undefined) {
      if (
        caseDocument.documentName
          ?.toLowerCase()
          .indexOf(filters.documentName?.toLowerCase()) == -1
      ) {
        matched = false;
      }
    }
    return matched;
  });

  const finalObject: any = {};
  Object.entries(_.groupBy(beforeGroup, "uploadedDate")).forEach(
    ([uploadedDate, caseDocArr], index) => {
      finalObject[`${uploadedDate}`] = _.groupBy(
        _.orderBy(caseDocArr, ["documentCategory"], ["desc"]),
        "documentCategory"
      );
    }
  );
  return finalObject;
};

export const getBoxIconByFileFormat = (format: string) => {
  switch (format?.toLowerCase()) {
    case ".txt":
      return "text";
    case ".pdf":
      return "pdf";
    case ".doc":
      return "word-doc";
    case ".docx":
      return "word-doc";
    case ".csv":
      return "csv";
    case ".xls":
      return "excel";
    case ".xlsx":
      return "excel";
    case ".html":
      return "html";
    case ".xml":
      return "xml";
    default:
      return "global-file";
  }
};

export const getLookUpTypeByCaseType = (
  caseType: string,
  entityType: string
) => {
  switch (caseType) {
    case "case":
      return entityType;
    case "ar":
      return "A";
    case "creditor":
      return "CRE";
    case "bureau":
      return "B";
    case "payment":
      return "P";
    default:
      return "";
  }
};
export const getMIMETypeByFileFormat = (format: string) => {
  switch (format?.toLowerCase()) {
    case ".txt":
      return "text/plain";
    case ".pdf":
      return "application/pdf";
    case ".doc":
      return "application/msword";
    case ".docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case ".csv":
      return "text/csv";
    case ".xls":
      return "application/vnd.ms-excel";
    case ".xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case ".html":
      return "text/html";
    case ".png":
      return "image/png";
    case ".jpg":
      return "image/jpg";
    case ".jpeg":
      return "image/jpeg";
    case ".xml":
      return "application/xml";
    case "txt":
      return "text/plain";
    case "pdf":
      return "application/pdf";
    case "doc":
      return "application/msword";
    case "docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "csv":
      return "text/csv";
    case "xls":
      return "application/vnd.ms-excel";
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "html":
      return "text/html";
    case "png":
      return "image/png";
    case "jpg":
      return "image/jpg";
    case "jpeg":
      return "image/jpeg";
    case "xml":
      return "application/xml";
    default:
      return "application/pdf";
  }
};

export const base64toStr = (base64: string) => {
  if (base64) {
    let buff = Buffer.from(base64, "base64");
    let base64ToStringNew = buff.toString("utf-8");
    return base64ToStringNew;
  } else {
    return "";
  }
};
