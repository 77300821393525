import { IAPIAction } from "us.collection/interfaces";
import { ExtendedField } from "us.collection.case/constants/Actions";

export const extendedField: Readonly<IAPIAction> = {
  extendedFields: {
    get: (data) => ({
      type: ExtendedField.GET_EXTENDED_FIELD,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ExtendedField.GET_EXTENDED_FIELD_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ExtendedField.GET_EXTENDED_FIELD_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },

  drawer: {
    set: (data) => ({
      type: ExtendedField.MANAGE_EXTENDED_FIELD_DRAWER,
      payload: {
        data,
        isLoading: true
      }
    }),
    success: (data) => ({
      type: ExtendedField.MANAGE_EXTENDED_FIELD_DRAWER_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ExtendedField.MANAGE_EXTENDED_FIELD_DRAWER_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }), 
  }
};
