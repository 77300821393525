import React, { useEffect, useState } from "react";
import { getDebtorGeneralInfo } from "us.collection.debtor/actions";
import { connect } from "react-redux";
import { Button, Table, Drawer } from "antd";
import DEBTOR_CONSTANTS from "us.collection.debtor/constants";
import DebtorContactInfoForm from "./DebtorContactInfoForm";
import { IntlDate } from "us.common/functions";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";

class VisibleObj {
  title: string;
  visible: boolean;
  content: any;
  constructor() {
    this.title = "";
    this.visible = false;
  }
}

interface IContactPerson {
  debtorInfo: any;
  getDebtorGeneralInfo: any;
  currentLanguage: string;
}

const ContactPerson: React.FC<IContactPerson> = (props) => {
  const { t } = useTranslation();
  let debtorContactInfo = null;
  const { debtorInfo, getDebtorGeneralInfo, currentLanguage } = props;
  const [data, setData] = useState<any>();
  const [drawerContent, setDrawerContent] = useState(new VisibleObj());
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [lang, setLang] = useState<string>(currentLanguage);

  useEffect(() => {
    if (debtorInfo.length === 0) {
      getDebtorGeneralInfo();
    }
    if ((!data && debtorInfo.length !== 0) || lang !== currentLanguage) {
      setData(
        debtorInfo.map((item: any) => {
          return {
            ...item,
            BirthDate: IntlDate(item.BirthDate, currentLanguage),
            key: item.id,
          };
        })
      );
      setLang(currentLanguage);
    }
  }, [currentLanguage, data, debtorInfo, getDebtorGeneralInfo, lang]);

  /** will set the slide content to the Noe Deptor Catetory */
  const drawerContentHandler = (name: string) => {
    setDrawerContent({
      title: t("US.COLLECTION.DEBTOR:CONTACTPERSON.NEWDEBTORINFO"),
      visible: true,
      content: null,
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter);
  };

  const saveNewContactHandler = (value: any) => {
    setDrawerContent({ visible: false, title: "", content: null });
    value = {
      ...value,
      key: value.FirstName,
      BirthDate: value.BirthDate._d.toLocaleDateString(),
    };
    setData([...data, value]);
  };

  const onClose = () => {
    setDrawerContent({ visible: false, title: "", content: null });
  };

  const columns = [
    {
      title: t("US.COLLECTION.DEBTOR:CONTACTPERSON.FISTNAME"),
      dataIndex: "FirstName",
      key: "FirstName",
      sorter: (a: any, b: any) => a.FirstName.length - b.FirstName.length,
      sortOrder: sortedInfo.columnKey === "FirstName" && sortedInfo.order,
    },
    {
      title: t("US.COLLECTION.DEBTOR:CONTACTPERSON.LASTNAME"),
      dataIndex: "LastName",
      key: "LastName",
      sorter: (a: any, b: any) => a.LastName.length - b.LastName.length,
      sortOrder: sortedInfo.columnKey === "LastName" && sortedInfo.order,
    },
    {
      title: t("US.COLLECTION.DEBTOR:CONTACTPERSON.MOBILE"),
      dataIndex: "MobileNumber",
      key: "MobileNumber",
      sorter: (a: any, b: any) => a.LastName.length - b.LastName.length,
      sortOrder: sortedInfo.columnKey === "MobileNumber" && sortedInfo.order,
    },
    {
      title: t("US.COLLECTION.DEBTOR:CONTACTPERSON.BIRTHDATE"),
      dataIndex: "BirthDate",
      key: "BirthDate",
      sorter: (a: any, b: any) => a.BirthDate.length - b.BirthDate.length,
      sortOrder: sortedInfo.columnKey === "BirthDate" && sortedInfo.order,
    },
    {
      title: t("US.COLLECTION.DEBTOR:CONTACTPERSON.EMAIL"),
      dataIndex: "Email",
      key: "Email",
      sorter: (a: any, b: any) => a.Email.length - b.Email.length,
      sortOrder: sortedInfo.columnKey === "Email" && sortedInfo.order,
    },
  ];

  return (
    <div className="contact-person">
      <Button
        type="dashed"
        size="small"
        icon="plus"
        className="mb-2"
        onClick={() =>
          drawerContentHandler(DEBTOR_CONSTANTS.viewNames.debtContactInfo)
        }
      >
        {t("US.COLLECTION.DEBTOR:CONTACTPERSON.NEWCONTACT")}
      </Button>

      <Table
        className="bui-table"
        size="small"
        columns={columns}
        dataSource={data}
        onChange={handleChange}
        scroll={{ x: 1500 }}
      />

      <Drawer
        title={drawerContent.title}
        width={750}
        visible={drawerContent.visible}
        onClose={onClose}
      >
        <DebtorContactInfoForm
          cancelClick={onClose}
          saveClick={saveNewContactHandler}
          debtorContactInfo={debtorContactInfo}
        />
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { debtor, common } = state;
  return {
    debtorInfo: debtor.debtorInfo,
    currentLanguage: common.currentLanguage,
  };
};

const mapDispatchToProps = {
  getDebtorGeneralInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactPerson);
