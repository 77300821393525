
import _ from "lodash";
import { matchPath } from "react-router-dom";
import { CaseTypeOptions } from "us.collection.transactions/components/CasesAndSubcases/Constants";
import { ICase } from "us.collection.transactions/interfaces";
/**
 * @function
 * @description check Default activity execution is enable or disable
 * @param {string} pathName Browser path
 * @returns {boolean}
 */
export const isEnabledDefaultActivityExecution = (pathName: string): boolean => {
    try {
        const { params }: any = matchPath(pathName, {
            path: "/:entityType/:arId?/:module?",
            exact: true,
            strict: false,
        });
        const module: string = params["module"] || "";
        return module.length > 0 && module !== "cases-and-subcases";
    } catch (error) {
        return true;
    }
};

/**
 * @function
 * @description get caseIds of selected case and subCase
 * @param { Array<ICase>} selectedCaseAndSubCase selected cases
 * @returns {Array<number | string>}
 */
export const getSelectedCaseId = (
    selectedCaseAndSubCase: Array<ICase>
): Array<number | string> => {
    try {
        return selectedCaseAndSubCase.map(({ caseId }: ICase) => caseId);
    } catch (error) {
        return [];
    }
};

/**
 * @function
 * @description get case type of selected case and subCase
 * @param { Array<ICase>} selectedCaseAndSubCase selected cases
 * @returns {Array<string>} 
 */
export const getSelectedCaseType = (
    selectedCaseAndSubCase: Array<ICase>
): Array<string> => {
    try {
        return _.uniq(
            selectedCaseAndSubCase.map(({ caseType }: ICase) => caseType)
        );
    } catch (error) {
        return [];
    }
};

/**
 * @function
 * @description check case is merge or transfer to case
 * @param { Array<ICase>} selectedCaseAndSubCase selected cases
 * @returns {boolean} 
 */
export const isMergeCaseOrTransferToCase = (
    selectedCaseAndSubCase: Array<ICase>
): boolean => {
    let isMerge: boolean = false;
    try {
        selectedCaseAndSubCase.forEach(({ caseType, parentId }: ICase) => {
            if (caseType === "S" && parentId > 0) {
                isMerge = true;
            }
        });
        return isMerge;
    } catch (error) {
        return isMerge;
    }
};

/**
 * @function
 * @description check activity execution bar is enable or disable 
 * @param { Array<ICase>} selectedCaseAndSubCase selected cases
 * @returns {boolean} 
 */
export const isDisabledActivityExecutionForCaseAndSubCase = (
    selectedCaseAndSubCase: Array<ICase>, filterType: CaseTypeOptions
): boolean => {
    try {
        const CaseAndSubCase = filterType === CaseTypeOptions.CASE ? getOnlyCases(selectedCaseAndSubCase) : selectedCaseAndSubCase
        return (
            getSelectedCaseType(CaseAndSubCase).length !== 1 ||
            isMergeCaseOrTransferToCase(CaseAndSubCase)
        );
    } catch (error) {
        return false;
    }
};

const getOnlyCases = (selectedCaseAndSubCase: Array<ICase>): Array<ICase> => {
    try {
        return selectedCaseAndSubCase.filter(({ caseType, parentId }: ICase) => caseType === "C")
    } catch (error) {
        return []
    }
}