import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import Common from 'us.common';
import './BMDChangeCount.scss';
import {
	IBMDCategory,
	IBMDChangeCount,
	IBMDGroup,
	INavigationData,
	IProperty,
	IRootState,
} from 'us.collection/interfaces';
import { $Skeleton } from 'us.common/components';
import { BMDActions } from 'us.collection/actions';
import { connect, ConnectedProps } from 'react-redux';
import { EntityType } from '../../Constants';
import { Link, useHistory } from 'react-router-dom';
import { AddProperty } from '../AddProperty';

const { $Tag, $Button, $Breadcrumb, $Row, $Table, $Col } = Common.Components;

const BMDChangeCount: React.FC<PropsFromRedux> = (props) => {
	const {
		drawerStatus,
		bmdChangeCountList,
		isBMDChangeCountLoading,
		bmdListData,
		getBMDCountList,
		changeDrawerStatus,
	} = props;
	const { recordData } = drawerStatus;
	const { entityType, id, dataIndex, entityId } = recordData ?? {}; // dataIndex -> hold the value the colum clicked
	const { categories: bmdCatagories } = bmdListData;

	const { t } = useTranslation([
		'US.COLLECTION.CASE',
		'US.COLLECTION.COMMON',
	]);
	const { location } = useHistory();
	const [viewEditProperty, setViewEditProperty] = useState<{
		isVisible: boolean;
		entityId: number;
	}>({ isVisible: false, entityId: 0 });

	useEffect(() => {
		getBMDCountList &&
			getBMDCountList({
				propertyId: id,
				currentLevel: entityType,
				entityId:
					entityType === EntityType.BUREAU
						? 1
						: entityId,
				entityType: dataIndex,
			});
	}, [drawerStatus]);

	const getBMDIdAndNameTitles = (
		entityType: EntityType | undefined
	): { idTitle: string; nameTitle: string } => {
		switch (entityType) {
			case EntityType.CASE:
				return {
					idTitle: 'CASE_NO',
					nameTitle: 'DEBTOR_NAME',
				};
			case EntityType.AR:
				return {
					idTitle: 'AR_NO',
					nameTitle: 'DEBTOR_NAME',
				};
			case EntityType.CREDITOR:
				return {
					idTitle: 'CREDITOR_NO',
					nameTitle: 'CREDITOR_NAME',
				};
			case EntityType.CREDITOR_GROUP:
				return {
					idTitle: 'CREDITOR_GROUP_NO',
					nameTitle: 'CREDITOR_GROUP_NAME',
				};
			default:
				return { idTitle: '', nameTitle: '' };
		}
	};

	const getOverrideClassName = (overrideLevel: string): string => {
		switch (overrideLevel) {
			case EntityType.CASE:
				return 'tag-bmd-case-subcase bmd-table-tag';
			case EntityType.AR:
				return 'tag-bmd-ar bmd-table-tag';
			case EntityType.CREDITOR:
				return 'tag-bmd-creditor bmd-table-tag';
			case EntityType.CREDITOR_GROUP:
				return 'tag-bmd-creditor-group bmd-table-tag';
			case EntityType.BUREAU:
			default:
				return 'tag-bmd-bureau bmd-table-tag';
		}
	};

	const { propertyName, bmdGroupId, bmdGroupName, categoryName } =
		useMemo(() => {
			let property: IProperty | undefined,
				bmdGroup: IBMDGroup | undefined,
				category: IBMDCategory | undefined;
			category = bmdCatagories.find(({ bmdGroups }: any) => {
				bmdGroup = bmdGroups.find(
					({ propertyList }: any) => {
						property = propertyList.find(
							(property: any) =>
								property.id ===
								id
						);
						return property?.id;
					}
				);
				return bmdGroup?.id;
			});

			return {
				propertyName: property?.keyName,
				bmdGroupName: bmdGroup?.name,
				bmdGroupId: bmdGroup?.id,
				categoryName: category?.name,
			};
		}, [recordData]);

	const getRouteForEntityType = (
		entityType: string | undefined
	): string => {
		switch (entityType) {
			case EntityType.CASE:
				return 'case';
			case EntityType.AR:
				return 'ar';
			case EntityType.CREDITOR:
				return 'creditor';
			case EntityType.CREDITOR_GROUP:
				return 'creditor-group';
			default:
				return '';
		}
	};

	const columns: ColumnsType<IBMDChangeCount> = useMemo(() => {
		const { idTitle, nameTitle } = getBMDIdAndNameTitles(
			dataIndex as EntityType
		);
		const state = location?.state as INavigationData;
		return [
			{
				title: t(`US.COLLECTION.CASE:BMD.${idTitle}`),
				dataIndex: 'id',
				key: 'id',
				filters: [],
				render: (
					text: any,
					{ id }: IBMDChangeCount
				) => {
					return (
						<Link
							to={{
								...location,
								pathname: `/${getRouteForEntityType(
									dataIndex
								)}/${id}`,
								state: {
									...state,
									currentTab: 'creditor',
									creditorId: id,
								},
							}}>
							<span data-testid='searchResult-debtorCaseNavigation'>
								{text}
							</span>
						</Link>
					);
				},
				visibilityLevel: [
					EntityType.CASE,
					EntityType.AR,
					EntityType.CREDITOR,
					EntityType.CREDITOR_GROUP,
				],
			},
			{
				title: t(`US.COLLECTION.CASE:BMD.${nameTitle}`),
				dataIndex: 'name',
				key: 'name',
				filters: [],
				visibilityLevel: [
					EntityType.CASE,
					EntityType.AR,
					EntityType.CREDITOR,
					EntityType.CREDITOR_GROUP,
				],
			},
			{
				title: t('US.COLLECTION.CASE:BMD.VALUE'),
				dataIndex: 'value',
				key: 'value',
				filters: [],
				render: (
					text: any,
					record: IBMDChangeCount
				) => {
					return (
						<a
							onClick={(e) => {
								e.preventDefault();
								setViewEditProperty(
									{
										isVisible: true,
										entityId: record.id,
									}
								);
							}}>
							{text}
						</a>
					);
				},
				visibilityLevel: [
					EntityType.CASE,
					EntityType.AR,
					EntityType.CREDITOR,
					EntityType.CREDITOR_GROUP,
				],
			},
			{
				title: t('US.COLLECTION.CASE:BMD.BUREAU'),
				dataIndex: 'bureauLevelValue',
				key: 'bureauLevelValue',
				filters: [],
				render: (
					text: any,
					{ bureauOverrideLevel }: IBMDChangeCount
				) => {
					return (
						<$Tag
							className={getOverrideClassName(
								bureauOverrideLevel
							)}>
							{text}
						</$Tag>
					);
				},
				visibilityLevel: [
					EntityType.CASE,
					EntityType.AR,
					EntityType.CREDITOR,
					EntityType.CREDITOR_GROUP,
				],
			},
			{
				title: t(
					'US.COLLECTION.CASE:BMD.CREDITOR_GROUP'
				),
				dataIndex: 'creditorGroupLevelValue',
				key: 'creditorGroupLevelValue',
				filters: [],
				render: (
					text: any,
					{
						creditorGroupOverrideLevel,
					}: IBMDChangeCount
				) => {
					return (
						<$Tag
							className={getOverrideClassName(
								creditorGroupOverrideLevel
							)}>
							{text}
						</$Tag>
					);
				},
				visibilityLevel: [
					EntityType.CASE,
					EntityType.AR,
					EntityType.CREDITOR,
					EntityType.CREDITOR_GROUP,
				],
			},
			{
				title: t('US.COLLECTION.CASE:BMD.CREDITOR'),
				dataIndex: 'creditorLevelValue',
				key: 'creditorLevelValue',
				filters: [],
				render: (
					text: any,
					{
						creditorOverrideLevel,
					}: IBMDChangeCount
				) => {
					return (
						<$Tag
							className={getOverrideClassName(
								creditorOverrideLevel
							)}>
							{text}
						</$Tag>
					);
				},
				visibilityLevel: [
					EntityType.CASE,
					EntityType.AR,
					EntityType.CREDITOR,
				],
			},
			{
				title: t('US.COLLECTION.CASE:BMD.AR'),
				dataIndex: 'arLevelValue',
				key: 'arLevelValue',
				filters: [],
				render: (
					text: any,
					{ arOverrideLevel }: IBMDChangeCount
				) => {
					return (
						<$Tag
							className={getOverrideClassName(
								arOverrideLevel
							)}>
							{text}
						</$Tag>
					);
				},
				visibilityLevel: [
					EntityType.CASE,
					EntityType.AR,
				],
			},
			{
				title: t('US.COLLECTION.CASE:BMD.CASE/SUBCASE'),
				dataIndex: 'caseLevelValue',
				key: 'caseLevelValue',
				filters: [],
				render: (
					text: any,
					{ caseOverrideLevel }: IBMDChangeCount
				) => {
					return (
						<$Tag
							className={getOverrideClassName(
								caseOverrideLevel
							)}>
							{text}
						</$Tag>
					);
				},
				visibilityLevel: [EntityType.CASE],
			},
		].filter((x) =>
			x.visibilityLevel.includes((dataIndex as any) ?? '')
		);
	}, [recordData]);

	return (
		<$Skeleton active={true} loading={isBMDChangeCountLoading}>
			{viewEditProperty.isVisible && (
				<>
					<$Breadcrumb className='mb-0'>
						<$Breadcrumb.Item
							onClick={() =>
								setViewEditProperty(
									{
										isVisible: false,
										entityId: 0,
									}
								)
							}>
							<a>
								{t(
									'US.COLLECTION.CASE:BMD.BMD_COUNT'
								)}
							</a>
						</$Breadcrumb.Item>
						<$Breadcrumb.Item>
							{t(
								'US.COLLECTION.CASE:BMD.EDIT_PROPERTY'
							)}
						</$Breadcrumb.Item>
					</$Breadcrumb>
					<AddProperty
						selectedBMDGroupId={
							bmdGroupId ?? 0
						}
						routeDetails={{
							entityType: entityType as EntityType,
							entityId,
						}}
						bmdGroups={
							bmdListData.categories.find(
								(
									category: any
								) =>
									category.name ===
									categoryName
							)?.bmdGroups ?? []
						}
						dataIndexIdForCount={
							viewEditProperty.entityId
						}
						groupIdForCount={bmdGroupId}
					/>
				</>
			)}
			{!viewEditProperty.isVisible && (
				<>
					<div>
						<$Row gutter={16}>
							<$Col span={8}>
								<div className='bmd-count-tile '>
									<div className='font-weight-bold'>
										{
											categoryName
										}
									</div>
									<div className='text-muted'>
										{t(
											'US.COLLECTION.COMMON:COMMON.CATEGORY'
										)}
									</div>
								</div>
							</$Col>
							<$Col span={8}>
								<div className='bmd-count-tile'>
									<div className='font-weight-bold'>
										{
											bmdGroupName
										}
									</div>
									<div className='text-muted'>
										{t(
											'US.COLLECTION.CASE:BMD.BMD'
										)}
									</div>
								</div>
							</$Col>
							<$Col span={8}>
								<div className='bmd-count-tile'>
									<div className='font-weight-bold'>
										{
											propertyName
										}
									</div>
									<div className='text-muted'>
										{t(
											'US.COLLECTION.CASE:BMD.KEY_NAME'
										)}
									</div>
								</div>
							</$Col>
						</$Row>
					</div>
					<div className='d-flex mt-4'>
						<div className='d-flex align-items-center mr-4'>
							<span className='bmd-states tag-bmd-bureau  mr-2'></span>
							<span>
								{' '}
								{t(
									'US.COLLECTION.CASE:BMD.BUREAU'
								)}
							</span>
						</div>
						<div className='d-flex align-items-center mr-4'>
							<span className='bmd-states tag-bmd-creditor-group  mr-2'></span>
							<span>
								{' '}
								{t(
									'US.COLLECTION.CASE:BMD.CREDITOR_GROUP'
								)}
							</span>
						</div>
						<div className='d-flex align-items-center mr-4'>
							<span className='bmd-states tag-bmd-creditor  mr-2'></span>
							<span>
								{' '}
								{t(
									'US.COLLECTION.CASE:BMD.CREDITOR'
								)}
							</span>
						</div>
						<div className='d-flex align-items-center mr-4'>
							<span className='bmd-states tag-bmd-ar  mr-2'></span>
							<span>
								{' '}
								{t(
									'US.COLLECTION.CASE:BMD.AR'
								)}
							</span>
						</div>
						<div className='d-flex align-items-center mr-4'>
							<span className='bmd-states tag-bmd-case-subcase  mr-2'></span>
							<span>
								{' '}
								{t(
									'US.COLLECTION.CASE:BMD.CASE/SUBCASE'
								)}
							</span>
						</div>
					</div>
					<div>
						<$Table
							rowKey={(
								record: IBMDChangeCount
							) => record.id}
							columns={columns}
							dataSource={
								bmdChangeCountList ??
								[]
							}
							className='mt-1'
							bordered
							size='small'
						/>
					</div>

					<div className='drawer-footer-fixed align-content-center justify-content-end'>
						<div>
							<$Button
								onClick={() =>
									changeDrawerStatus &&
									changeDrawerStatus(
										{
											...drawerStatus,
											isVisible: false,
										}
									)
								}>
								{t(
									'US.COLLECTION.COMMON:COMMON.CANCEL'
								)}
							</$Button>
						</div>
					</div>
				</>
			)}
		</$Skeleton>
	);
};

const { bmdList, bmdDataTypes, bmdCountList } = BMDActions;

const mapStateToProps = (state: IRootState) => {
	const { bmd } = state;
	const { bmdChangeCount, drawerStatus, bmdList } = bmd;
	const { data, isLoading } = bmdChangeCount;
	return {
		bmdListData: bmdList.data,
		bmdChangeCountList: data,
		isBMDChangeCountLoading: isLoading,
		drawerStatus,
	};
};

const mapDispatchToProps = {
	getBMDList: bmdList.get,
	changeDrawerStatus: bmdDataTypes.openDrawer,
	getBMDCountList: bmdCountList.get,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(BMDChangeCount);
