import * as Yup from "yup";

interface IDocumentOfTitle extends Yup.MixedSchema {
  description: string;
  ownedPart: string;
  SNR: string;
  nameOfHousing: string;
  ninForHousingCompany: string;
  estimatedValue: string;
  valuationAmount: string;

}

const DocumentOfTitleSchema = Yup.object<IDocumentOfTitle>().shape({
  description: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.DESCRIPTION_IS_REQUIRED"
  ),
  nameOfHousing: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.NAME_OF_HOUSING_COMPANY_IS_REQUIRED"
  ),
  ninForHousingCompany: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.NIN_OF_HOUSING_COMPANY_IS_REQUIRED"
  ),
  estimatedValue: Yup.string()
  .test(
    "estimatedValue",
    "US.COLLECTION.VALIDATIONS:REQUIRED.ESTIMATED_VALUE_MUST_BE_POSITIVE",
    (val: any) => {
      return val >= 0;
    }
  ),
  valuationAmount: Yup.string()
  .test(
    "valuationAmount",
    "US.COLLECTION.VALIDATIONS:REQUIRED.VALUATION_AMOUNT_MUST_BE_POSITIVE",
    (val: any) => {
      return val >= 0;
    }
  ),
});

export default DocumentOfTitleSchema;
