import moment from "moment";
import { IAssets } from "../Interfaces";
import { AssetTypes } from "us.collection.case/constants";

export const InitialState: IAssets = {
  data: [],
  isFetching: false,
  isREFetching: false,
  drawerInfo: {
    title: "",
    visible: false,
    isEdit: false,
    assetId: "",
    showOnly: false,
  },
  realEstateAssets: {
    assetId: 0,
    entityId: 0,
    entityType: "",
    estimatedValue: 0.0,
    valuationAmount: 0.0,
    valuationDate: moment(),
    gnr: 0,
    bnr: 0,
    fnr: 0,
    snr: 0,
    sectionNumber: "",
    nameOfHousing: "",
    ninForHousingCompany: "",
    description: "",
    ownedPartOrShare: "",
    address1: "",
    address2: "",
    postalId: "",
    postalCode: "",
    postalArea: "",
    municipalityId: "",
    municipilityCode: 0,
    municipilityArea: "",
    comment: "",
    registrationNo: "",
    yearOrModel: "",
    financialInstitutionName: "",
    financialInstitutionAddress1: "",
    financialInstitutionAddress2: "",
    nin: "",
    financialInstitutionPostalId: "",
    financialInstitutionPostalCode: "",
    financialInstitutionPostalName: "",
    nameOrType: "",
  },
};
