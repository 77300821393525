import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './Sentences.scss';
import Common from 'us.common';
import { Formik } from 'formik';
import { ISentence } from 'us.collection.case/interfaces';
import { IAddCourtSentence } from '.';
import {
	getContinueTypeOptions,
	getInitialValues,
	getLegalDecisitonTypeOptions,
} from './Functions';
import { $Alert, $Skeleton, CaseBalanceUpdate } from 'us.common/components';
import { CourtSentenceActions } from 'us.collection.case/actions';
import { AddSentenceSchema } from './Validations';
import { SentneceDrawerStatus } from 'us.collection.case/constants';
import moment, { Moment } from 'moment';
import {
	AddCourtSentenceRepository,
	EditCourtSentenceRepository,
} from './Repository';
import { IRowProperties } from 'us.common/components/CaseBalanceUpdate/Interfaces';
import { rows } from './Constants/AddCourtSentence';
import { RootState } from 'us.helper/types';

const AddCourtSentence: React.FC<IAddCourtSentence & PropsFromRedux> = (
	props
) => {
	const {
		$Button,
		$Form,
		$Row,
		$Col,
		$DatePicker,
		$Select,
		$Input,
		$Popconfirm,
		$TextArea,
	} = Common.Components;

	const {
		currentDateFormat,
		drawerStatus,
		sentenceDetails,
		sentenceDetailsLoading,
		caseAmountDetails,
		caseAmountDetailsIsLoading,
		metaData,
		sentenceSummery,
		isSentenceSaving,
		isSentenceEditing,
		isLegalCase,
		sentenceId,
		getCaseAmounts,
		getSentenceDetails,
		addSentence,
		editSentence,
		closeDrawer,
	} = props;

	const { caseAmounts } = caseAmountDetails ?? {};

	const { caseId } = metaData.data ?? {};

	const {
		mainAmount = 0,
		caseCost = 0,
		collectionFee = 0,
		courtFee = 0,
		otherCosts = 0,
		interest = 0,
		interestCollection = 0,
	} = caseAmounts ?? {};

	const { t } = useTranslation();
	useEffect(() => {
		if (!metaData.isLoading && !metaData.isError) {
			getCaseAmounts && getCaseAmounts({ entityId: caseId });
			if (
				drawerStatus ===
				SentneceDrawerStatus.EDIT_SENTENCE_OPEN
			) {
				getSentenceDetails &&
					getSentenceDetails({ sentenceId });
			}
		}
	}, [metaData, drawerStatus]);

	return (
		<Formik
			enableReinitialize
			initialValues={getInitialValues(
				mainAmount,
				caseCost,
				collectionFee,
				courtFee,
				interest,
				interestCollection,
				otherCosts,
				caseId,
				drawerStatus,
				sentenceDetails
			)}
			onSubmit={(values: any, actions: any) => {
				if (
					drawerStatus ===
					SentneceDrawerStatus.EDIT_SENTENCE_OPEN
				) {
					editSentence &&
						editSentence(
							EditCourtSentenceRepository.call(
								values
							)
						);
				} else {
					addSentence &&
						addSentence(
							AddCourtSentenceRepository.call(
								values,
								sentenceSummery
							)
						);
				}
				actions.setSubmitting(false);
			}}
			validationSchema={AddSentenceSchema}
			validateOnBlur
			validateOnChange
			validateOnMount>
			{({
				values,
				handleSubmit,
				isSubmitting,
				dirty,
				setValues,
				resetForm,
				isValid,
			}: {
				values: ISentence;
				handleSubmit: any;
				isSubmitting: any;
				dirty: boolean;
				setValues: any;
				resetForm: any;
				isValid: boolean;
			}) => (
				<$Skeleton
					loading={
						sentenceDetailsLoading ||
						caseAmountDetailsIsLoading
					}
					active
					paragraph={{ rows: 2 }}>
					<$Form onSubmit={handleSubmit}>
						<div className='add-new-sentence'>
							{!isLegalCase && (
								<$Alert
									message={t(
										'US.COLLECTION.CASE:ADD_COURT_SENTENCE.LEGAL_STATE_WARNING'
									)}
									type='info'
									showIcon
									closable
									className='mb-3'
								/>
							)}
							<$Row
								className='mb-3'
								gutter={16}>
								<$Col
									span={6}
									className='required'
									data-testid={
										'add-court-sentence-sentence-date'
									}>
									<$DatePicker
										label={t(
											'US.COLLECTION.CASE:ADD_COURT_SENTENCE.SENTENCE_DATE'
										)}
										name='sentenceDate'
										value={
											values.sentenceDate
										}
										size='small'
										data-testid={
											'sentence-date'
										}
										style={{
											width: '100%',
										}}
										format={
											currentDateFormat
										}
										className='hh'
										required
										onChange={(
											date: Moment
										) => {
											setValues(
												(
													preVals: ISentence
												) => ({
													...preVals,
													sentenceDate:
														date,
													mainAmountFromDate:
														date,
													caseCostFromDate:
														date,
													collectionFeeFromDate:
														date,
													otherCostsFromDate:
														date,
												})
											);
										}}
									/>
								</$Col>
								<$Col
									span={6}
									className='required'
									data-testid={
										'add-court-sentence-declaration-date'
									}>
									<$DatePicker
										label={t(
											'US.COLLECTION.CASE:ADD_COURT_SENTENCE.DECLARATION_DATE'
										)}
										name='declarationDate'
										value={
											values.declarationDate
										}
										data-testid={
											'declaration-date'
										}
										size='small'
										style={{
											width: '100%',
										}}
										format={
											currentDateFormat
										}
										required
										allowClear
										onChange={(
											date: Moment
										) =>
											setValues(
												(
													preVals: ISentence
												) => ({
													...preVals,
													declarationDate:
														date,
													mainAmountInterestToDate:
														date,
													interestCollectionFeeTo:
														date,
													dueDate: moment(
														date
													).add(
														14,
														'days'
													),
												})
											)
										}
									/>
								</$Col>
								<$Col
									span={6}
									data-testid={
										'add-court-sentence-legal-decision-type'
									}>
									<$Select
										formitem={{
											label: t(
												'US.COLLECTION.CASE:ADD_COURT_SENTENCE.LEGAL_DECISION_TYPE'
											),
										}}
										data-testid={
											'add-court-sentence-select-legal-decision-type'
										}
										name='sentenceTypeId'
										size='small'
										required
										allOption={
											false
										}
										options={getLegalDecisitonTypeOptions(
											caseAmountDetails
										)}
									/>
								</$Col>
								<$Col
									span={6}
									data-testid={
										'add-court-sentence-court-reference'
									}>
									<$Input
										size='small'
										label={t(
											'US.COLLECTION.CASE:ADD_COURT_SENTENCE.COURT_REFERENCE'
										)}
										data-testid={
											'add-court-sentence-reference'
										}
										name='courtRef'
										required
									/>
								</$Col>
							</$Row>
							<$Row
								className='mb-3'
								gutter={16}>
								<$Col
									span={6}
									data-testid={
										'add-court-sentence-legal-continue-to'
									}>
									<$Select
										formitem={{
											label: t(
												'US.COLLECTION.CASE:ADD_COURT_SENTENCE.CONTINUE_TO'
											),
										}}
										data-testid={
											'add-court-sentence-select-legal-continue'
										}
										name='continueType'
										size='small'
										required
										allOption={
											false
										}
										disabled={
											drawerStatus ===
											SentneceDrawerStatus.EDIT_SENTENCE_OPEN
										}
										options={getContinueTypeOptions(
											caseAmountDetails
										)}
									/>
								</$Col>
							</$Row>
							<CaseBalanceUpdate
								rows={rows.map(
									({
										label,
										...restRowProperties
									}: IRowProperties) => ({
										label: t(
											label
										),
										...restRowProperties,
									})
								)}
								accountSummary={
									(caseAmounts as any) ??
									{}
								}
								headerName={t(
									'US.COLLECTION.CASE:ADD_COURT_SENTENCE.SENTENCES'
								)}
							/>
							<div
								className='mt-3'
								data-testid={
									'add-court-sentence-sentence-note'
								}>
								<$TextArea
									name='sentenceNote'
									autoSize={{
										minRows: 2,
										maxRows: 4,
									}}
									label={t(
										'US.COLLECTION.CASE:ADD_COURT_SENTENCE.NOTE'
									)}
									required
								/>
							</div>

							<div className='drawer-footer-fixed align-content-center justify-content-end'>
								<div>
									<$Button
										className='mr-2'
										disabled={
											!dirty ||
											!isValid
										}
										loading={
											isSubmitting ||
											isSentenceSaving ||
											isSentenceEditing
										}
										onClick={
											handleSubmit
										}
										type='primary'
										data-testid='btn-save'>
										{drawerStatus ===
											SentneceDrawerStatus.EDIT_SENTENCE_OPEN &&
											t(
												'US.COLLECTION.COMMON:COMMON.UPDATE'
											)}
										{drawerStatus !=
											SentneceDrawerStatus.EDIT_SENTENCE_OPEN &&
											t(
												'US.COLLECTION.COMMON:COMMON.SAVE'
											)}
									</$Button>
									{dirty && (
										<$Popconfirm
											title={t(
												'US.COLLECTION.CASE:ADD_COURT_SENTENCE.CANCEL_ERROR'
											)}
											placement='topLeft'
											onConfirm={() => {
												resetForm();
												closeDrawer();
											}}
											okText={t(
												'US.COLLECTION.COMMON:COMMON.YES'
											)}
											cancelText={t(
												'US.COLLECTION.COMMON:COMMON.NO'
											)}>
											<$Button
												disabled={
													isSentenceSaving ||
													isSentenceEditing
												}>
												{t(
													'US.COLLECTION.COMMON:COMMON.CANCEL'
												)}
											</$Button>
										</$Popconfirm>
									)}
									{!dirty && (
										<$Button
											disabled={
												isSentenceSaving ||
												isSentenceEditing
											}
											onClick={() =>
												closeDrawer()
											}>
											{t(
												'US.COLLECTION.COMMON:COMMON.CANCEL'
											)}
										</$Button>
									)}
								</div>
							</div>
						</div>
					</$Form>
				</$Skeleton>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { courtSentence, dashboard, domainView, common } = state;
	const { currentCurrency, currentLanguage, currentDateFormat } = common;
	const {
		drawerStatus,
		sentenceDetails,
		caseAmountDetails,
		sentenceSummery,
		sentenceSaveAtempt,
		sentenceEditAtempt,
	} = courtSentence;
	const { accountSummeryWidgetData } = dashboard;
	const { metaData } = domainView;
	const { sentenceId, status } = drawerStatus.data ?? {};
	return {
		currentCurrency,
		currentLanguage,
		currentDateFormat,
		drawerStatus: status ?? SentneceDrawerStatus.NOT_OPENED,
		sentenceId,
		sentenceDetails: sentenceDetails.data,
		sentenceDetailsLoading: sentenceDetails.isLoading,
		caseAmountDetails: caseAmountDetails.data,
		caseAmountDetailsIsLoading: caseAmountDetails.isLoading,
		metaData,
		sentenceSummery,
		isSentenceSaving: sentenceSaveAtempt.isLoading,
		isSentenceEditing: sentenceEditAtempt.isLoading,
		isLegalCase: accountSummeryWidgetData?.data?.case?.isLegal,
	};
};

const { sentenceDetails, caseAmounts, addSentence, editSentence } =
	CourtSentenceActions;

const mapDispatchToProps = {
	getSentenceDetails: sentenceDetails.get,
	getCaseAmounts: caseAmounts.get,
	addSentence: addSentence.save,
	editSentence: editSentence.update,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddCourtSentence);
