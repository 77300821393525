/**
 * To format person number
 * @param value input field value
 * @param setFieldValue formik setFieldValue
 */
export const FormatPersonNo = (value: any, setFieldValue: any): void => {
  if (value?.length === 6 || (value?.length >= 9 && value?.length <= 11)) {
    const parts = value.replace(/\s/g, "").match(/.{1,6}/g);
    setFieldValue("nin", parts.join(" "));
  }
};