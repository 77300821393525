import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getDebtorEntHistory } from 'us.collection.debtor/actions';
import { useTranslation } from 'react-i18next';
import {
  DateFormats,
  Types,
  DebtorType,
} from 'us.collection.debtor/components/DebtorInfo/Constants';
import Common from 'us.common';
import { debtor } from 'us.collection.debtor/actions';
import _ from 'lodash';
import { IDataModel } from 'us.collection.case/reducers/Dashboard/Interfaces';
import { IMetaData } from 'us.collection/interfaces';
import { IOnFilter, IOnSort } from 'us.common/components/antd/TableTree';
import moment from 'moment';
import { RootState } from 'us.helper/types';
const { $Skeleton, $TableTree, $DateLabel } = Common.Components;

interface IEntityHistory {
  getDebtorEntHistory: () => void;
  onClose(): void;
  isFetching: boolean;
  currentCurrency: string;
  currentLanguage: string;
  isFetchingDebtorHistory: boolean;
  debtorHistory: any;
  getDebtorHistory: any;
  debtorInformation: any;
  metaData: IDataModel<IMetaData>;
  isCreditor?: boolean;
  creditorMetaData: any;
}
const EntityHistory: React.FC<IEntityHistory> = (props) => {
  const { t } = useTranslation();
  const {
    isFetchingDebtorHistory,
    debtorHistory,
    getDebtorHistory,
    debtorInformation,
    metaData,
    isCreditor,
    creditorMetaData,
  } = props;
  const { debtorId } = metaData.data ?? {};
  const [type, setType] = useState<string>(DebtorType.PERSON);

  useEffect(() => {
    if (isCreditor) {
      if (creditorMetaData?.entRoleId) {
        getDebtorHistory(creditorMetaData?.entRoleId);
      }
    } else {
      if (debtorId) {
        getDebtorHistory(debtorId);
      }
    }
  }, [metaData]);

  useEffect(() => {
    if (
      debtorInformation?.debtorType === DebtorType.COMPANY ||
      debtorInformation?.entityType === Types.CREDITOR
    ) {
      setType(DebtorType.COMPANY);
    }
  }, [debtorHistory]);

  const columnsPerson: any = [
    {
      title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.NAME'),
      dataIndex: 'fullName',
      key: 'fullName',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.BIRTHDATE'),
      dataIndex: 'birthDate',
      key: 'birthDate',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: 'date',
      customRenderChild: (text: string, record: any) => {
        const { birthDate } = record;
        return (
          <span>
            {birthDate &&
              moment(birthDate).format(DateFormats.REQ) !=
                DateFormats.MIN_DATE && <$DateLabel value={text} />}
          </span>
        );
      },
    },
    {
      title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.PERSONNO'),
      dataIndex: 'personNo',
      key: 'personNo',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
    },
    {
      title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.ORIGINALREGDATE'),
      dataIndex: 'orginalRegDate',
      key: 'orginalRegDate',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: 'date',
      customRenderChild: (text: any, record: any) => {
        const { orginalRegDate } = record;
        return (
          <span>
            {orginalRegDate &&
              moment(orginalRegDate).format(DateFormats.REQ) !=
                DateFormats.MIN_DATE && <$DateLabel value={text} />}
          </span>
        );
      },
    },
    {
      title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.REG_DATE'),
      dataIndex: 'regDate',
      key: 'regDate',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: 'date',
      customRenderChild: (text: any, record: any) => {
        const { regDate } = record;
        return (
          <span>
            {regDate &&
              moment(regDate).format(DateFormats.REQ) !=
                DateFormats.MIN_DATE && <$DateLabel value={text} />}
          </span>
        );
      },
    },
    {
      title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.DEBTOR_TYPE'),
      dataIndex: 'debtorType',
      key: 'debtorType',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.CREATEDUSER'),
      dataIndex: 'createdUser',
      key: 'createdUser',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.MODIFIEDUSER'),
      dataIndex: 'modifiedUser',
      key: 'modifiedUser',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
  ];

  const columnsCompany: any = [
    {
      title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.NAME'),
      dataIndex: 'fullName',
      key: 'fullName',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.COMPANY_NO'),
      dataIndex: 'personNo',
      key: 'personNo',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
    },
    {
      title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.ORIGINALREGDATE'),
      dataIndex: 'orginalRegDate',
      key: 'orginalRegDate',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: 'date',
      customRenderChild: (text: any, record: any) => {
        const { orginalRegDate } = record;
        return (
          <span>
            {orginalRegDate &&
              moment(orginalRegDate).format(DateFormats.REQ) !=
                DateFormats.MIN_DATE && <$DateLabel value={text} />}
          </span>
        );
      },
    },
    {
      title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.REG_DATE'),
      dataIndex: 'regDate',
      key: 'regDate',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: 'date',
      customRenderChild: (text: any, record: any) => {
        const { regDate } = record;
        return (
          <span>
            {regDate &&
              moment(regDate).format(DateFormats.REQ) !=
                DateFormats.MIN_DATE && <$DateLabel value={text} />}
          </span>
        );
      },
    },
    {
      title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.DEBTOR_TYPE'),
      dataIndex: 'debtorType',
      key: 'debtorType',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.CREATEDUSER'),
      dataIndex: 'createdUser',
      key: 'createdUser',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.MODIFIEDUSER'),
      dataIndex: 'modifiedUser',
      key: 'modifiedUser',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
  ];

  const handleSort: IOnSort = (sortData, dataSource) => {
    return sortData(dataSource);
  };

  const handleFilter: IOnFilter = (searchData, dataSource) => {
    return searchData(dataSource);
  };

  return (
    <$Skeleton loading={isFetchingDebtorHistory} active paragraph={{ rows: 2 }}>
      <$TableTree
        rowKey="id"
        data={debtorHistory}
        size="small"
        className=""
        onSort={handleSort}
        onFilter={handleFilter}
        filterOnType={true}
        resetOnSourceChange={true}
        bordered
        pagination={{ defaultPageSize: 15 }}
        scroll={{ x: 1200 }}
        columns={type === DebtorType.COMPANY ? columnsCompany : columnsPerson}
      />
    </$Skeleton>
  );
};

const mapStateToProps = (state: RootState) => {
  const { common, entity, domainView, debtorDetail, creditor } = state;
  const { history } = debtorDetail;
  const { currentCurrency, currentLanguage } = common;
  const { metaData } = domainView;
  const { general } = creditor;
  const { data } = general;
  return {
    currentCurrency,
    currentLanguage,
    isFetching: entity.isFetching,
    isFetchingDebtorHistory: history.isFetching,
    debtorHistory: history.data,
    metaData,
    creditorMetaData: data,
  };
};

const mapDispatchToProps = {
  getDebtorEntHistory,
  getDebtorHistory: debtor.history.getByDebtorNo,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityHistory);
