import React, { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './Home.scss';
import Common from 'us.common';
import { Formik } from 'formik';
import { applicableCourts } from 'us.collection.transactions/actions';
import { IMetaData } from 'us.collection/interfaces';
import _ from 'lodash';
import { IOnFilter, IOnSort } from 'us.common/components';
import { RouteComponentProps } from 'react-router-dom';
import { IDataModel } from 'us.collection.case/reducers/Dashboard/Interfaces';
import { RootState } from 'us.helper/types';

const { $PageHeader, $Affix, $Skeleton, $TableTree, $Tooltip } =
	Common.Components;

interface IApplicableCourts extends RouteComponentProps {
	onMinimize(): void;
	arDetails: any;
	courts: any;
	getApplicableCourts?: any;
	metaData: IDataModel<IMetaData>;
}

export interface ISortOrder {
	columnKey: string;
	order: any;
}

const ApplicableCourts: React.FC<IApplicableCourts> = memo((props) => {
	const { t } = useTranslation(
		['US.COLLECTION.TRANSACTIONS', 'US.COLLECTION.COMMON'],
		{
			useSuspense: true,
		}
	);

	const {
		arDetails,
		courts,
		getApplicableCourts,
		onMinimize,
		history,
		location,
		metaData,
	} = props;

	const { arId } = metaData.data ?? {};
	useEffect(() => {
		if (arDetails?.debtorId != 0) {
			getApplicableCourts({
				entityType: 'Deb',
				entityId: arDetails?.debtorId,
			});
		}
	}, [arDetails]);

	const columnsTree: any = [
		{
			title: '',
			dataIndex: 'courtType',
			key: 'courtType',
			width: 50,
			ellipsis: true,
			customRenderParent: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={
								record?.courtType
							}>
							{record?.courtType}
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 5,
					},
				};
			},
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: <></>,
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:APPLICABLE_COURTS.NAME'
			),
			dataIndex: 'courtName',
			key: 'courtName',
			ellipsis: true,
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: true,
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={
								record?.courtName
							}>
							{record?.courtName}
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:APPLICABLE_COURTS.COURT_TYPE'
			),
			dataIndex: 'courtTypeName',
			key: 'courtTypeName',
			ellipsis: true,
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: true,
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={
								record?.courtTypeName
							}>
							{record?.courtTypeName}
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:APPLICABLE_COURTS.TELEPHONE'
			),
			dataIndex: 'telephoneNo',
			key: 'telephoneNo',
			ellipsis: true,
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: true,
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={
								record?.telephoneNo
							}>
							{record?.telephoneNo}
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:APPLICABLE_COURTS.ADDRESS'
			),
			dataIndex: 'courtAddress',
			key: 'courtAddress',
			ellipsis: true,
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: true,
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={
								record?.courtAddress
							}>
							{record?.courtAddress}
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
	];

	const handleSort: IOnSort = (sortData, dataSource) => {
		return sortData(
			dataSource.map((i: any) => {
				return {
					...i,
					children: sortData(i.children),
				};
			})
		);
	};

	const handleFilter: IOnFilter = (searchData, dataSource) => {
		let result: any[] = [];
		dataSource.flatMap((i: any) => {
			searchData(i?.children)?.length != 0 &&
				result.push({
					...i,
					children:
						searchData(i?.children)
							?.length == 0
							? []
							: searchData(
									i?.children
							  ),
				});
		});
		return result;
	};

	const minimize = () => {
		history.push(`/ar/${arId}`, location.state);
	};
	return (
		<Formik
			enableReinitialize
			initialValues={{}}
			onSubmit={(values: any, actions: any) => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				...rest
			}: any) => (
				<>
					<div className='space-content applicable-courts'>
						<$Affix offsetTop={80}>
							<div className='page-header header-border'>
								<div className='d-flex flex-row align-items-center justify-content-between'>
									<div className='d-flex align-items-center'>
										<$PageHeader
											className='px-0'
											title={t(
												'US.COLLECTION.TRANSACTIONS:APPLICABLE_COURTS.APPLICABLE_COURTS'
											)}
											onBack={
												minimize
											}
										/>
									</div>
								</div>
							</div>
						</$Affix>

						<div className='mt-3'>
							<$Skeleton
								loading={
									courts?.isFetching
								}
								active
								paragraph={{
									rows: 2,
								}}>
								<$TableTree
									rowKey={(
										record: any
									) => {
										return record.hasOwnProperty(
											'children'
										)
											? record.key
											: record.courtItemId;
									}}
									onSort={
										handleSort
									}
									onFilter={
										handleFilter
									}
									filterOnType={
										true
									}
									resetOnSourceChange={
										true
									}
									data={
										courts?.data
									}
									columns={
										columnsTree
									}
									size='small'
									className='activity-table'
									bordered
									pagination={{
										defaultPageSize: 20,
									}}
									scroll={{
										x: 1200,
										y: 'calc(100vh - 300px)',
									}}
									defaultExpandAllRows={
										true
									}
								/>
							</$Skeleton>
						</div>
					</div>
				</>
			)}
		</Formik>
	);
});

const mapStateToProps = (state: RootState) => {
	const { transaction, applicableCourts, domainView } = state;
	const { arDetails } = transaction;
	const { courts } = applicableCourts;
	const { metaData } = domainView;

	return {
		arDetails,
		courts,
		metaData,
	};
};

const mapDispatchToProps = {
	getApplicableCourts: applicableCourts.courts.get,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicableCourts);
