import Constants from "us.collection.case/constants";
import { InitialState } from "./State";
import { ICaseInformationModel } from "./Interfaces";
import { ICaseInfoUpdateModel } from "./Interfaces/ICaseInfoUpdate";

const { ACTIONS } = Constants;
const { CaseInformation } = ACTIONS;
const {
  GET_CASEINFO,
  GET_CASEINFO_SUCCESS,
  GET_CASEINFO_FAIL,
  GET_INTEREST_OCCURRENCES_START,
  GET_INTEREST_OCCURRENCES_SUCCESS,
  GET_INTEREST_OCCURRENCES_FAIL,
  ADD_INTEREST_OCCURRENCES_START,
  ADD_INTEREST_OCCURRENCES_SUCCESS,
  ADD_INTEREST_OCCURRENCES_FAIL,
  SAVE_CASEINFO,
  SAVE_CASEINFO_SUCCESS,
  SAVE_CASEINFO_FAIL,
  DELETE_INTEREST_OCCURRENCES_START,
  DELETE_INTEREST_OCCURRENCES_SUCCESS,
  DELETE_INTEREST_OCCURRENCES_FAIL
} = CaseInformation;

export default (state: any = InitialState, action: any) => {
  switch (action.type) {
    case GET_CASEINFO:
      return Object.assign({}, state, {
        caseInfo: <ICaseInformationModel>{
          data: {},
          isLoading: true,
          error: undefined,
        },
      });

    case GET_CASEINFO_SUCCESS:
      let outputData;
      if (
        action.payload.data &&
        Object.keys(action.payload.data).length > 0 &&
        action.payload.data.caseId > 0
      ) {
        outputData = action.payload.data;
      } else {
        outputData = {};
      }
      return Object.assign({}, state, {
        caseInfo: <ICaseInformationModel>{
          data: outputData,
          isLoading: false,
          error: undefined
        },
      });

    case GET_CASEINFO_FAIL:
      return Object.assign({}, state, {
        caseInfo: <ICaseInformationModel>{
          data: {},
          isLoading: false,
          error: action.payload.error
        },
      });
    case GET_INTEREST_OCCURRENCES_START:
        return Object.assign({}, state, {
            isFetching: true,
        });

    case GET_INTEREST_OCCURRENCES_SUCCESS:
        return Object.assign({}, state, {
            interestOccurrences: action.payload?.data,
            isFetching: false,
        });
    case GET_INTEREST_OCCURRENCES_FAIL:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case ADD_INTEREST_OCCURRENCES_START:
        return Object.assign({}, state, {
            isFetching: true,
        });

    case ADD_INTEREST_OCCURRENCES_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case ADD_INTEREST_OCCURRENCES_FAIL:
        return Object.assign({}, state, {
            isFetching: false,
        });

    case SAVE_CASEINFO:
    return Object.assign({}, state, {
      updatedCaseInfo: <ICaseInfoUpdateModel>{
        data: {},
        isLoading: true,
        error: undefined,
        isEditing:true
      },
    });
    case SAVE_CASEINFO_SUCCESS:
    return Object.assign({}, state, {
      updatedCaseInfo: <ICaseInfoUpdateModel>{
        data: action.payload.data,
        isLoading: false,
        error: undefined,
        isEditing:false
      },
    });
    case SAVE_CASEINFO_FAIL:
    return Object.assign({}, state, {
      updatedCaseInfo: <ICaseInfoUpdateModel>{
        data: {},
        isLoading: false,
        error: action.payload.error,
        isEditing:false
      },
    });

    case DELETE_INTEREST_OCCURRENCES_START:
        return Object.assign({}, state, {
            isFetching: true,
        });

    case DELETE_INTEREST_OCCURRENCES_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case DELETE_INTEREST_OCCURRENCES_FAIL:
        return Object.assign({}, state, {
            isFetching: false,
        });


    default:
      return state;
  }
};
