import React  from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';

interface IContactInfoProps{
    data:any
}

const ContactInfo:React.FC<IContactInfoProps> = props => {

    const { t } = useTranslation();
    // const [dataSource,setDataSource]=useState(props.data);

    // useEffect(() => {
    //     setDataSource(props.data);
    // }, [props.data])

    const columns = [
        {
            title: t('US.COLLECTION.CREDITOR:CONTACTINFO.FIRSTNAME'),
            dataIndex: 'FirstName',
            key: 'FirstName',
            width: 100,
            sorter: (a:any, b:any) => a.FirstName - b.FirstName,
        },
        {
            title: t('US.COLLECTION.CREDITOR:CONTACTINFO.LASTNAME'),
            dataIndex: 'LastName',
            key: 'LastName',
            width: 100,
            sorter: (a:any, b:any) => a.LastName - b.LastName,
        },
        {
            title: t('US.COLLECTION.CREDITOR:CONTACTINFO.BIRTHDATE'),
            dataIndex: 'BirthDate',
            key: 'BirthDate',
            width: 100,
            sorter: (a:any, b:any) => a.BirthDate - b.BirthDate,
        },
        {
            title: t('US.COLLECTION.CREDITOR:CONTACTINFO.MOBILENUMBER'),
            dataIndex: 'MobileNumber',
            key: 'MobileNumber',
            width: 100,
            sorter: (a:any, b:any) => a.MobileNumber - b.MobileNumber,
        },
        {
            title: t('US.COLLECTION.CREDITOR:CONTACTINFO.EMAIL'),
            dataIndex: 'Email',
            key: 'Email',
            width: 100,
            sorter: (a:any, b:any) => a.Email - b.Email,
        },
        {
            title: t('US.COLLECTION.CREDITOR:CONTACTINFO.SKYPE'),
            dataIndex: 'Skype',
            key: 'Skype',
            width: 100,
            sorter: (a:any, b:any) => a.Skype - b.Skype,
        },
        {
            title: t('US.COLLECTION.CREDITOR:CONTACTINFO.WORKPHONENUMBER'),
            dataIndex: 'WorkfPhoneNumber',
            key: 'WorkfPhoneNumber',
            width: 100,
            sorter: (a:any, b:any) => a.WorkfPhoneNumber - b.WorkfPhoneNumber,
        },
        {
            title: t('US.COLLECTION.CREDITOR:CONTACTINFO.MSN'),
            dataIndex: 'Msn',
            key: 'Msn',
            width: 100,
            sorter: (a:any, b:any) => a.Msn - b.Msn,
        },
    ];


    return (
        <>
            <Table dataSource={props.data} columns={columns} scroll={{ x: 100 }} />
        </>
    );
}

export default ContactInfo;