import { ICase, ISubCase } from "us.collection.transactions/interfaces";
import { CaseType } from "us.helper/types/enums";
import { CaseState } from "us.collection.transactions/constants";
import { appInsights } from "us.helper";

/**
 * @function
 * @description - to arrange the data by grouping based on the parentID
 * @param {ISubCase[]} data - API data ungrouped
 * @return {*}  {ICase[]} - list of arranged data
 */
export const ArrangeToCasesAndSubCases = (data: ISubCase[]): ICase[] => {
  try {
    let parentIdIndex = 0;
    let returnValue: ICase[];
    return data
      .map((item: ISubCase) => ({
        ...item,
        parentId: isNaN(parseInt(item.parentId.toString()))
          ? 0
          : parseInt(item.parentId.toString()),
        caseState: item.caseState.toString() === "1" ? CaseState.OPEN : CaseState.CLOSE,
        caseType: item.caseType === "C" ? CaseType.C : CaseType.S,
        key: parseFloat(Math.random().toFixed(50)), // parseInt(item.caseId),
      }))
      .reduce((previousValue: ICase[], currentValue: ISubCase) => {
        returnValue = [...previousValue];
        // finding if the currentValue related items are already added previously
        parentIdIndex = previousValue.findIndex((item) => item.parentId === currentValue.parentId);
        if (!currentValue.parentId) {
          // encounting a child
          let parentValue: ICase;
          let firstSubcaseIndex = previousValue.findIndex((item) => item.caseNumber === "0");
          if (firstSubcaseIndex < 0) {
            // it's the first sub-case encounted
            parentValue = {
              ...currentValue,
              caseNumber: "0",
              // groupCaseNo:0,
              workflowState: "",
              caseType: CaseType.C,
              caseState: CaseState.NOT_SPECIFIED,
              isMergeEnabled: false,
              isSplitEnabled: false,
              isCreateEnabled: false,
              children: [{ ...currentValue }],
              key: parseFloat(Math.random().toFixed(50)),
            };
            returnValue.push(parentValue);
          } else {
            returnValue[firstSubcaseIndex] = {
              ...returnValue[firstSubcaseIndex],
              children: [...previousValue[firstSubcaseIndex].children, currentValue].sort(
                (a, b) => parseInt(b.caseNumber) - parseInt(a.caseNumber)
              ),
            };
          }
        } else if (parentIdIndex < 0) {
          // encounting a new value
          returnValue.push({
            ...currentValue,
            children: currentValue.caseType === CaseType.C ? [] : [{ ...currentValue }],
            key: parseFloat(Math.random().toFixed(50)),
          });
        } else {
          // enconting item with existing parentID
          if (currentValue.caseType === CaseType.C) {
            returnValue[parentIdIndex] = {
              ...currentValue,
              key: parseFloat(Math.random().toFixed(50)),
              children: returnValue[parentIdIndex].children,
            };
          } else {
            returnValue[parentIdIndex].children = [
              ...returnValue[parentIdIndex].children,
              currentValue,
            ].sort((a, b) => parseInt(b.caseNumber) - parseInt(a.caseNumber));
          }
        }
        return returnValue;
      }, [])
      .filter((parentCases) => !(parentCases.parentId > 0 && parentCases.children?.length <= 0))
      .sort((a, b) => parseInt(b.caseNumber) - parseInt(a.caseNumber));
  } catch (error) {
    appInsights.trackException(`Error in case and subcases filterByCaseState funciton. - ${error}`);
    return [];
  }
};
