import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Statistic } from 'antd';
import './BasicInfo.scss';
import { connect } from 'react-redux';
import { IntlDate } from 'us.common/functions';
import { useTranslation } from 'react-i18next';

interface IOtherInfo{
    CreditorGeneralInfo:any;
    gridStyle:any;
    statisticStylle:any;
    currentLanguage:string;
}

const OtherInfo:React.FC<IOtherInfo> = (props) => {

    const { t } = useTranslation();
    let { CreditorGeneralInfo,currentLanguage } = props;
    const [otherInfo, setOtherInfo] = useState(CreditorGeneralInfo);

    useEffect(() => {
        setOtherInfo(CreditorGeneralInfo);
    }, [CreditorGeneralInfo])

    return (
        <>
            {
                props ?
                    (
                        <Row>
                            <Col className="gutter-row" span={6}>
                                <Card style={{ backgroundColor: '#9c9898' }}>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.CASEHANDLER')} value={undefined} /></Card.Grid>
                                    <Card.Grid style={{ ...props.gridStyle, width: '35%', height: '4em' }}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.USPAYMENT')} value={undefined} /></Card.Grid>
                                    <Card.Grid style={{ ...props.gridStyle, width: '50%', height: '4em' }}><Statistic valueStyle={props.statisticStylle} title="" value={undefined} /></Card.Grid>
                                    <Card.Grid style={{ ...props.gridStyle, width: '35%', height: '4em' }}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.USPAYMENTPAID')} value={undefined} /></Card.Grid>
                                    <Card.Grid style={{ ...props.gridStyle, width: '50%', height: '4em' }}><Statistic valueStyle={props.statisticStylle} title="" value={undefined} /></Card.Grid>
                                    <Card.Grid style={{ ...props.gridStyle, width: '35%', height: '4em' }}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.USPAYMENTBALANCE')} value={undefined} /></Card.Grid>
                                    <Card.Grid style={{ ...props.gridStyle, width: '50%', height: '4em' }}><Statistic valueStyle={props.statisticStylle} title="" value={undefined} /></Card.Grid>
                                    <Card.Grid style={{ ...props.gridStyle, width: '35%', height: '4em' }}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.COLLECTIONOPEN')} value={undefined} /></Card.Grid>
                                    <Card.Grid style={{ ...props.gridStyle, width: '50%', height: '4em' }}><Statistic valueStyle={props.statisticStylle} title="" value={undefined} /></Card.Grid>
                                    <Card.Grid style={{ ...props.gridStyle, width: '35%', height: '4em' }}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.COLLECTIONPAID')} value={undefined} /></Card.Grid>
                                    <Card.Grid style={{ ...props.gridStyle, width: '50%', height: '4em' }}><Statistic valueStyle={props.statisticStylle} title="" value={undefined} /></Card.Grid>
                                    <Card.Grid style={{ ...props.gridStyle, width: '35%', height: '4em' }}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.COLLECTIONBALANCE')} value={undefined} /></Card.Grid>
                                    <Card.Grid style={{ ...props.gridStyle, width: '50%', height: '4em' }}><Statistic valueStyle={props.statisticStylle} title="" value={undefined} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.LASTLOGINPORTAL')} value={undefined} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.LASTCONTACT')} value={undefined} /></Card.Grid>
                                </Card>
                            </Col>

                            <Col className="gutter-row" span={6}>
                                <Card style={{ backgroundColor: '#9c9898' }}>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.CASEINVOICE')} value={undefined} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.AGREEMENT')} value={undefined} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.VATY/N')} value={undefined} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.BBDID')} value={undefined} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.SENDBBSOKDATE')} value={IntlDate(otherInfo.SendBBSOkDate,currentLanguage)} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.SENDEXCELINEOKDATE')} value={IntlDate(otherInfo.SendExlineOkDate,currentLanguage)} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.SENDKUNDEOKDATE')} value={IntlDate(otherInfo.SendKundeOkDate,currentLanguage)} /></Card.Grid>
                                </Card>
                            </Col>

                            <Col className="gutter-row" span={6}>

                                <Card style={{ backgroundColor: '#9c9898' }}>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.ATGDATE')} value={IntlDate(otherInfo.AtgDate,currentLanguage)} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.ALLOWPRINTDATE')} value={IntlDate(otherInfo.AllowPrintDate,currentLanguage)} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.ALLOWPRINT')} value={otherInfo.AllowPrint} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.PRINTDISABLEDATE')} value={IntlDate(otherInfo.PrintDisableDate,currentLanguage)} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.PRINTALLOWCOMMENT')} value={otherInfo.PrintAllowComment} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.CATA')} value={otherInfo.CostaCatA} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.CATB')} value={otherInfo.CostaCatB} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.CATC')} value={otherInfo.CostaCatC} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:OTHERINFO.CATD')} value={otherInfo.CostaCatD} /></Card.Grid>
                                </Card>
                            </Col>
                        </Row>
                    )
                    :
                    <p>page loading......</p>
            }
        </>
    );
}

const mapStateToProps = (state: any) => {
    return {
        currentLanguage: state.common.currentLanguage
    };
};

export default connect(mapStateToProps)(OtherInfo) ;
