import { httpCollection } from 'us.helper/http/collection';
import {
	ICaseDetails,
	IDebtorInfoWidgetRequest,
} from 'us.collection.case/interfaces';
import { IDebtorWidgetInfo } from 'us.collection.case/reducers/Dashboard/Interfaces';

export const dashboard = {
	getMetadata: <P extends { id: number; type: string }>(
		params: P
	): Promise<any> => {
		return new Promise<{}>(async (resolve, reject) => {
			try {
				const { data } = await httpCollection.getById(
					'MetadataService',
					'system/domainView/metadata',
					params.id + '/' + params.type
				);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getDevCaseProperties: (caseNo: number): Promise<any> => {
		return new Promise<{}>(async (resolve, reject) => {
			try {
				const { data } = await httpCollection.getById(
					'ARService',
					'ars/cases',
					caseNo + '/properties'
				);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},

	getEntities: (
		entityInfo: IDebtorInfoWidgetRequest
	): Promise<IDebtorWidgetInfo> => {
		return new Promise<any>(async (resolve, reject) => {
			try {
				const { data } = await httpCollection.post(
					'systemservice',
					'EntInfo',
					entityInfo
				);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	getAccountSummeryDetails: (caseDetails: ICaseDetails): Promise<any> => {
		return new Promise<any>(async (resolve, reject) => {
			try {
				const { data } = await httpCollection.post(
					'systemservice',
					'CaseDetails',
					caseDetails
				);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	// getMeta: <P extends { id: number; type: string }>(
	// 	params: P
	// ): Promise<any> => {
	// 	return new Promise<{}>(async (resolve, reject) => {
	// 		const { id, type } = params;
	// 		try {
	// 			const { data } =
	// 				await versionedHttpCollection.getById(
	// 					'metadataservice',
	// 					'domainview',
	// 					`${id}/${type}`,
	// 					ServiceConfig()[
	// 						`metadataservice`
	// 					]['domiainview']
	// 				);
	// 			resolve(data);
	// 		} catch (error) {
	// 			reject(error);
	// 		}
	// 	});
	// },
};
