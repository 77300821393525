import * as Yup from 'yup';
import moment from 'moment';
import { AssetTypes } from 'us.collection.case/constants';

interface Enforcement extends Yup.MixedSchema {
	enforcementId: number;
	referenceId: string;
	journalId: string;
	registrationDate: moment.Moment;
	executedDate: moment.Moment;
	obsoleteDate: moment.Moment;
	comment: string;
	lastModificedDate: moment.Moment;
	lastModifiedUser: string;
	mainAmount: number;
	courtFee: number;
	collectionFee: number;
	interestOfMainAmount: number;
	interestOfCosts: number;
	interestFromDate: moment.Moment;
	enforcedItems: Array<any>;
	isEmployerEnforced: boolean;
}

let isEmployerEnforced = false;

const EnforcementSchema = Yup.object<Enforcement>().shape({
	registrationDate: Yup.date()
		.required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
		)
		.typeError(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
		),
	referenceId: Yup.string().required(
		'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
	),
	isEmployerEnforced: Yup.boolean().test(
		'isEmployerEnforced',
		'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD',
		(val: any) => {
			isEmployerEnforced = val;
			return true;
		}
	),
	enforcedItems: Yup.array().of(
		Yup.object().shape({
			assetType: Yup.string(),
			key: Yup.string()
				.required(
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
				)
				.typeError(
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
				)
				.when('assetType', {
					is: (assetType: string) =>
						assetType ===
						AssetTypes.EMPLOYER,
					then: Yup.string().test(
						'key',
						'US.COLLECTION.VALIDATIONS:INVALID.ALREADY_EMPLOYER_ENFORCEMENT_WARNING',
						() => !isEmployerEnforced
					),
				}),
			enforcementTypeId: Yup.string()
				.required(
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
				)
				.typeError(
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
				),
		})
	),
	comment: Yup.string().required(
		'US.COLLECTION.VALIDATIONS:REQUIRED.COMMENT_IS_REQUIRED'
	),
});

export default EnforcementSchema;
