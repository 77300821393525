import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PlusOutlined, MinusOutlined, ExclamationCircleFilled } from 'us.icons';
import { Formik } from 'formik';
import { withRouter, matchPath } from 'react-router-dom';
import Common from 'us.common';
import '../DebtorInformation.scss';
import AddressHistory from './AddressHistory';
import EntityHistory from './DebtorHistory';
import _, { isNumber } from 'lodash';
import { debtor } from 'us.collection.debtor/actions';
import { EditOtherPartyValidationSchema } from 'us.collection.case/components/Validations';
import { IBase } from 'us.collection.case/interfaces';
import PostalArea from 'us.common/containers/PostalArea/PostalArea';
import * as Action from 'us.common/actions';
import { OtherPartyAction } from 'us.collection.case/actions';
import { europeanCountry } from 'us.common/constants';
import { INavigationData, IRootState } from 'us.collection/interfaces';
import { validatePersonNo } from 'us.collection.case/functions';
import { OtherPartyTypes } from 'us.collection.case/components/OtherParty/Constants';
import { useLocation } from 'react-router-dom';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { DEFAULT_COUNTRY_CODE } from 'us.common/constants';

const {
	$Form,
	$Button,
	$Divider,
	$Collapse,
	$Input,
	$AsyncInput,
	$Select,
	$Row,
	$Col,
	$DatePicker,
	$Skeleton,
	$Switch,
	$Drawer,
	$Popover,
	$SelectGroup,
} = Common.Components;

const { otherParty, roleTypes, postalCode, minicipality } = OtherPartyAction;
const { creditor, other } = debtor;
const { entity } = Action;

const General: React.FC<IBase> = (props) => {
	const { t } = useTranslation(
		[
			'US.COLLECTION.DEBTOR',
			'US.COLLECTION.COMMON',
			'US.COLLECTION.CASE',
		],
		{ useSuspense: true }
	);
	const { state, pathname } = useLocation();
	const [popConfirmVisible, SetPopConfirmVisible] = useState(false);
	const {
		currentDateFormat,
		save,
		getOtherPartyInfo,
		otherPartyDetail,
		selectedOtherParty,
		getRoleType,
		roleType,
		drawerName,
		postalcode,
		municipility,
		searchPostalcode,
		searchMunicipility,
		resetPostalDetails,
		arDetails,
		metaData,
	} = props;

	const { creditorId } = state as INavigationData;
	const { debtorId, entityType, caseId } = metaData.data ?? {};

	const [isExistingPostalCode, setIsExistingPostalCode] = useState(true);
	const [isExistingMunicipalityCode, setIsExistingMunicipalityCode] =
		useState(true);
	const [countryCode, setCountryCode] = useState<string>();
	const [country, setCountry] = useState<string>();
	const [PostalAreaVisible, setPostalAreaVisible] = useState(false);
	const [otherPartyInfo, setOtherPartyInfo] = useState<any>();
	const [filledData, setFIlledData] = useState({});
	const [addressUnknownConfirmVisible, setAddressUnknownConfirmVisible] =
		useState(false);
	const [personNoFlag, setPersonNoFlag] = useState(false);

	useEffect(() => {
		let type: string | undefined = '';
		const { params }: any = matchPath(pathname, {
			path: '/:caseType/:caseId/:moduleName?',
			exact: false,
			strict: false,
		});
		const caseType = params['caseType'];
		switch (caseType) {
			case 'case':
				type = entityType;
				break;
			case 'ar':
				type = 'A';
				break;
			case 'creditor':
				type = 'Creditor';
				break;
			default:
				break;
		}
		if (!_.isEmpty(selectedOtherParty)) {
			getOtherPartyInfo({
				type,
				entityId: selectedOtherParty?.entRoleId,
				roleType: selectedOtherParty?.roleType,
			});
			getRoleType({
				otherPartyType:
					selectedOtherParty?.otherPartyType,
				currentTab: caseType,
			});
		}
	}, []);

	useEffect(() => {
		if (otherPartyDetail?.data.entityID > 0) {
			setCountry(otherPartyDetail?.data.country);
			setPersonNoFlag(
				otherPartyDetail?.data?.nin?.length > 0
					? false
					: true
			);
			setCountryCode(otherPartyDetail?.data?.country);
			setOtherPartyInfo({
				...otherPartyDetail.data,
				nin:
					otherPartyDetail?.data
						.otherPartyType ===
					OtherPartyTypes.PERSON
						? formatInitialPersonNo(
								otherPartyDetail
									.data
									.nin
						  )
						: formatInitialOrganizationNo(
								otherPartyDetail
									.data
									.nin
						  ),
				addrUnknown:
					!otherPartyDetail?.data.isAddressKnown,
			});
		}
	}, [otherPartyDetail]);

	useEffect(() => {
		if (postalcode && postalcode?.isResponse) {
			setTimeout(() => {
				if (resetPostalDetails) {
					resetPostalDetails({});
				}
			}, 50);
			if (postalcode?.data?.length > 0) {
				const { countyCode, countyName, postalPlace } =
					postalcode?.data[0];
				setOtherPartyInfo({
					...otherPartyInfo,
					zipName: postalPlace,
					municipalityName: countyName,
					municipalityCode: countyCode,
				});
				setIsExistingPostalCode(true);
				setIsExistingMunicipalityCode(true);
			} else {
				setOtherPartyInfo({
					...otherPartyInfo,
					zipName: '',
					municipalityName: '',
					municipalityCode: '',
				});
				setIsExistingPostalCode(false);
			}
		}
	}, [postalcode]);

	useEffect(() => {
		if (municipility && municipility.isResponse) {
			setTimeout(() => {
				if (resetPostalDetails) {
					resetPostalDetails({});
				}
			}, 50);
			if (municipility?.data?.length > 0) {
				const { municipalityName, municipalityCode } =
					municipility?.data[0];
				setOtherPartyInfo({
					...otherPartyInfo,
					municipalityName: municipalityName,
					municipalityCode: municipalityCode,
				});
				setIsExistingMunicipalityCode(true);
			} else {
				setOtherPartyInfo({
					...otherPartyInfo,
					municipalityName: '',
				});
				setIsExistingMunicipalityCode(false);
			}
		}
	}, [municipility]);

	const formatInitialPersonNo = (value: any) => {
		value = value.replace(/ /g, '');
		if (
			value?.length === 6 ||
			(value?.length >= 9 && value?.length <= 11)
		) {
			return value.match(/.{1,6}/g).join(' ');
		}
		return value;
	};
	const formatInitialOrganizationNo = (value: any) => {
		value = value.replace(/ /g, '');
		if (value?.length === 9) {
			return value.match(/.{1,3}/g).join(' ');
		}
		return value;
	};

	const handlePostalDoubleClick = (
		selectedPostalAreaDetail: any,
		rest: any,
		values: any
	) => {
		setPostalAreaVisible(false);
		setOtherPartyInfo({
			...values,
			municipalityCode:
				selectedPostalAreaDetail?.MunicipalityCode,
			municipalityName:
				selectedPostalAreaDetail?.MunicipalityName,
			zipCode: selectedPostalAreaDetail?.PostalCode,
			zipName: selectedPostalAreaDetail?.City,
		});
	};

	/**
	 * The method handling the debtor information form submission
	 * @param e the event returned by ant-design form
	 */

	/**
	 * change the view to postal code selection
	 */

	const changeView = () => {
		setPostalAreaVisible(true);
	};
	const postalAreaClose = () => {
		setPostalAreaVisible(false);
	};

	const formatOrganizationNo = (data: any, rest: any) => {
		let value = data.target.value;
		if (value?.length === 9) {
			let parts = value.replace(/\s/g, '').match(/.{1,3}/g);
			rest.setFieldValue('nin', parts.join(' '));
		}
	};

	const formatPersonNo = (value: any, rest: any) => {
		if (
			value?.length === 6 ||
			(value?.length >= 9 && value?.length <= 11)
		) {
			let parts = value.replace(/\s/g, '').match(/.{1,6}/g);
			rest.setFieldValue('nin', parts.join(' '));
		}
	};

	const handleAddressUnknown = (
		checked: boolean,
		rest: any,
		values: any
	) => {
		if (checked) {
			setOtherPartyInfo({
				...values,
				addrUnknown: true,
				zipCode: '',
				zipName: '',
				municipalityCode: '',
				municipalityName: '',
			});
		}
	};
	const handleSubmit = (
		data: any,
		{
			setErrors,
			setStatus,
			resetForm,
			isSubmitting,
			isValidating,
			setSubmitting,
		}: any
	) => {
		if (data?.zipCode.length !== 0) {
			if (data?.addrUnknown) {
				setAddressUnknownConfirmVisible(true);
				setFIlledData(data);
			} else {
				// data.countryCode = 'NO';
				setAddressUnknownConfirmVisible(false);
				saveData(data);
			}
		} else {
			saveData(data);
		}
	};

	const postalCodePopOverConfirmYes = (rest: any, values: any) => {
		setAddressUnknownConfirmVisible(false);
		setOtherPartyInfo({
			...values,
			addrUnknown: false,
		});
		saveData({ ...filledData, addrUnknown: false });
	};
	const postalCodePopOverConfirmNo = (rest: any, values: any) => {
		setAddressUnknownConfirmVisible(false);
		setOtherPartyInfo({
			...values,
			addrUnknown: true,
			zipCode: '',
			zipName: '',
			municipalityCode: '',
			municipalityName: '',
		});
		saveData({
			...filledData,
			addrUnknown: true,
			zipCode: '',
			zipName: '',
			municipalityCode: '',
			municipalityName: '',
		});
	};
	// Address and Debtor History Drawers
	const [AddressHistoryVisible, setAddressHistoryVisible] =
		useState(false);
	const [DebtorHistoryVisible, setDebtorHistoryVisible] = useState(false);

	const AddressHistoryHandler = () => {
		setAddressHistoryVisible(true);
	};
	const AddressHistoryClose = () => {
		setAddressHistoryVisible(false);
	};
	const DebtorHistoryHandler = () => {
		setDebtorHistoryVisible(true);
	};
	const DebtorHistoryClose = () => {
		setDebtorHistoryVisible(false);
	};

	const handleBirthday = (values: any, rest: any) => {
		let personNo = values.target.value;
		personNo === '' && setPersonNoFlag(true);
		let data = validatePersonNo(personNo.replaceAll(/\D/g, ''));
		if (data.isValid) {
			formatPersonNo(personNo, rest);
			rest.setFieldValue('birthDate', moment(data.birthDate));
		} else {
			rest.setFieldValue('birthDate', '');
		}
	};

	const handlePersonNo = (data: any, rest: any, values: any) => {
		rest.setFieldValue('birthDate', moment(data));
	};

	const disabledDate = (current: any) => {
		return !(current && current < moment().endOf('day'));
	};
	const onChangeCountry = (data: any, rest: any, values: any) => {
		setCountryCode(country);
		setCountry(data);
		rest.setFieldValue('country', data);
		if (values?.zipCode.length > 0) {
			SetPopConfirmVisible(true);
		}
	};
	const countryPopOverConfirmYes = (rest: any, values: any) => {
		setCountryCode(country);
		SetPopConfirmVisible(false);
		setOtherPartyInfo({
			...values,
			addrUnknown: true,
			country: country,
			zipCode: '',
			zipName: '',
			municipalityCode: '',
			municipalityName: '',
		});
	};

	const countryPopOverConfirmNo = (rest: any) => {
		setCountry(countryCode);
		rest.setFieldValue('country', countryCode);
		SetPopConfirmVisible(false);
	};
	const saveData = (data: any) => {
		const { params }: any = matchPath(pathname, {
			path: '/:type/:Id?/:module?',
			exact: false,
			strict: false,
		});
		let id = params['Id'];
		let caseType = params['type'];
		let type;
		let debtorEntRoleId;
		switch (caseType) {
			case 'case':
				type = entityType;
				debtorEntRoleId = debtorId;
				id = caseId;
				break;
			case 'ar':
				type = 'A';
				debtorEntRoleId = arDetails.debtorId;
				break;
			case 'creditor':
				type = 'Creditor';
				id = creditorId;
				break;
			default:
				type = 'S';
				debtorEntRoleId = debtorId;
				break;
		}

		let role: string;
		role = isNaN(data?.otherPartyRoleType)
			? data?.otherPartyRoleType
			: roleType.data?.filter(
					(item: any) =>
						item.value ===
						data?.otherPartyRoleType
			  )[0]?.label;
		let bDate =
			data.birthDate === '1900-01-01T00:00:00' ||
			!moment(data.birthDate).isValid()
				? ''
				: moment(data.birthDate).format('YYYY-MM-DD');

		if (data?.otherPartyType === OtherPartyTypes.COMPANY) {
			data.organizationNo = data?.nin
				.trim()
				.replace(/ /g, '');
		} else {
			data.personNo = data?.nin.trim().replace(/ /g, '');
		}

		let req = {
			action: 'update',
			entityId: id,
			otherpartyBelongstype: type,
			entRoleId: data?.entRoleId,
			firstName: data?.firstName,
			lastName: data?.lastName,
			address1: data?.address1,
			address2: data?.address2,
			address3: data?.address3,
			gender: data?.gender,
			otherpartyRoleType: role,
			otherpartyType: data?.otherPartyType,
			personNo: data?.personNo,
			organizationNo: data?.organizationNo,
			birthDate: bDate,
			telephone: data?.telephoneNo,
			emailAddress: data?.emailAddress,
			reference: data?.reference,
			zipCode: data?.zipCode,
			zipName: data?.zipName,
			countyId: data?.municipalityCode,
			municipalityCode: data?.municipalityCode,
			municipalityName: data?.municipalityName,
			countryId: data?.country,
			smsNo: data?.smsNo,
			isDefault: selectedOtherParty?.activeStatus,
			isAddressKnown: !data?.addrUnknown,
			debtorEntRoleId,
		};
		save(req);
	};

	const handlePanel = (keys: any) => {
		if (keys?.includes('3')) { 
		}
	};

	const searchZipDetail = (zipcode: string, values: any, rest: any) => {
		if (zipcode?.length > 0) {
			setOtherPartyInfo(values);
			if (searchPostalcode) {
				searchPostalcode({
					countryCode:
						values?.country?.length === 0
							? 'NO'
							: values?.country,
					searchText: zipcode,
				});
			}
		} else {
			rest.setFieldValue('zipName', '');
			rest.setFieldValue('municipalityName', '');
			rest.setFieldValue('municipalityCode', '');
		}
	};
	const searchMunicipilityDetail = (
		countyCode: string,
		values: any,
		rest: any
	) => {
		if (countyCode?.length > 0) {
			setOtherPartyInfo(values);
			if (searchMunicipility) {
				searchMunicipility({
					countryCode:
						values?.country?.length === 0
							? 'NO'
							: values?.country,
					searchText: countyCode,
				});
			}
		} else {
			rest.setFieldValue('municipalityName', '');
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				...otherPartyInfo,
				type:
					otherPartyInfo?.otherPartyType ===
					OtherPartyTypes.PERSON
						? 'Person'
						: 'Company',
			}}
			validationSchema={EditOtherPartyValidationSchema}
			onSubmit={handleSubmit}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				...rest
			}: any) => (
				<div>
					<$Form
						name='GeneralInfo'
						onSubmit={handleSubmit}>
						<div className='mb-3'>
							<$Button
								size='small'
								type='primary'
								disabled={
									popConfirmVisible ||
									(metaData.data &&
										isSubCaseTransferredToCase(
											metaData.data
										))
								}
								onClick={
									handleSubmit
								}
								className='px-3'>
								{t(
									'US.COLLECTION.COMMON:COMMON.SAVE'
								)}
							</$Button>
							<$Divider
								className='bui-devider'
								type='vertical'
							/>
							<$Button
								type='dashed'
								size='small'
								className='px-3 mr-2'
								onClick={() =>
									AddressHistoryHandler()
								}>
								{t(
									'US.COLLECTION.CASE:OTHERPARTY.ADDRESS_HISTORY'
								)}
							</$Button>
							<$Button
								type='dashed'
								size='small'
								className='px-3 mr-2'
								onClick={() =>
									DebtorHistoryHandler()
								}>
								{t(
									'US.COLLECTION.CASE:OTHERPARTY.OTHERPARTYHISTORY'
								)}
							</$Button>
						</div>

						<$Collapse
							bordered={false}
							defaultActiveKey={[
								'1',
								'2',
							]}
							expandIcon={({
								isActive,
							}) =>
								isActive ? (
									<MinusOutlined />
								) : (
									<PlusOutlined />
								)
							}
							className=''
							onChange={(
								panel: any
							) =>
								handlePanel(
									panel
								)
							}>
							{/* Basic information */}
							<$Collapse.Panel
								header={t(
									'US.COLLECTION.CASE:OTHERPARTY.BASIC_INFORMATIONS'
								)}
								key='1'>
								<div className='pl-1 pb-4'>
									<$Skeleton
										loading={
											otherPartyDetail?.isFetching
										}>
										<$Row
											gutter={
												16
											}
											className='mb-1'>
											<$Col
												span={
													8
												}>
												<$Input
													label={t(
														'US.COLLECTION.CASE:OTHERPARTY.TYPE'
													)}
													name='type'
													size='small'
													placeholder={
														values?.otherPartyType ===
														OtherPartyTypes.PERSON
															? t(
																	'US.COLLECTION.CASE:OTHERPARTY.PERSON'
															  )
															: t(
																	'US.COLLECTION.CASE:OTHERPARTY.COMPANY'
															  )
													}
													// defaultValue={values?.otherPartyType === 'P' ? "Person" : "Company"}
													value={
														values?.otherPartyType ===
														OtherPartyTypes.PERSON
															? t(
																	'US.COLLECTION.CASE:OTHERPARTY.PERSON'
															  )
															: t(
																	'US.COLLECTION.CASE:OTHERPARTY.COMPANY'
															  )
													}
													disabled
												/>
											</$Col>
										</$Row>

										<$Row
											gutter={
												16
											}
											className='mb-1'>
											<$Col
												span={
													values?.otherPartyType ==
													OtherPartyTypes.PERSON
														? 8
														: 12
												}>
												<$Input
													name='firstName'
													label={t(
														'US.COLLECTION.CASE:OTHERPARTY.FIRSTNAME'
													)}
													defaultValue={
														values.firstName
													}
													size='small'
												/>
											</$Col>
											<$Col
												span={
													values?.otherPartyType ==
													OtherPartyTypes.PERSON
														? 8
														: 12
												}>
												<$Input
													name='lastName'
													label={t(
														'US.COLLECTION.CASE:OTHERPARTY.LASTNAME'
													)}
													defaultValue={
														values.lastName
													}
													required
													size='small'
												/>
											</$Col>
											{values?.otherPartyType ===
												OtherPartyTypes.PERSON && (
												<$Col
													span={
														8
													}>
													<$Select
														formitem={{
															label: t(
																'US.COLLECTION.CASE:OTHERPARTY.GENDER'
															),
														}}
														name='gender'
														size='small'
														allOption={
															false
														}
														defaultValue={
															values.gender
														}
														options={[
															{
																label: 'Male',
																value: 'M',
															},
															{
																label: 'Female',
																value: 'F',
															},
														]}
													/>
												</$Col>
											)}
										</$Row>

										<$Row
											gutter={
												16
											}
											className='mb-1'>
											<$Col
												span={
													8
												}>
												<$Select
													allOption={
														false
													}
													formitem={{
														label: t(
															'US.COLLECTION.CASE:OTHERPARTY.ROLETYPE'
														),
													}}
													name='otherPartyRoleType'
													size='small'
													defaultValue={
														values.otherPartyRoleType
													}
													required
													//onSelect={changeRoleType}
													options={roleType.data?.filter(
														(
															x: any
														) =>
															x.label !==
															'Employer'
													)}
													disabled={
														otherPartyDetail
															.data
															?.otherPartyRoleType ===
														'Employer'
													}
													onSearchBy={[
														'label',
														'value',
													]}
												/>
											</$Col>
											<$Col
												span={
													values?.otherPartyType ===
													OtherPartyTypes.PERSON
														? 8
														: 16
												}>
												<$Input
													label={t(
														'US.COLLECTION.CASE:OTHERPARTY.NATIONAL_IDENTITY_NUMBER'
													)}
													name='nin'
													onBlur={
														values?.otherPartyType ===
														OtherPartyTypes.PERSON
															? (
																	value: any
															  ) =>
																	handleBirthday(
																		value,
																		rest
																	)
															: (
																	value: any
															  ) =>
																	formatOrganizationNo(
																		value,
																		rest
																	)
													}
													defaultValue={
														selectedOtherParty.nIN
													}
													size='small'
												/>
											</$Col>

											{values?.otherPartyType ===
												OtherPartyTypes.PERSON && (
												<$Col
													span={
														8
													}>
													<$DatePicker
														name='birthDate'
														label={t(
															'US.COLLECTION.CASE:OTHERPARTY.BIRTHDATE'
														)}
														placeholder={
															currentDateFormat
														}
														format={
															currentDateFormat
														}
														allowClear={
															true
														}
														onChange={(
															e: any
														) =>
															handlePersonNo(
																e,
																rest,
																values
															)
														}
														defaultPickerValue={moment(
															'2000/01/01'
														)}
														value={
															values.birthDate ===
															'1900-01-01T00:00:00'
																? null
																: moment(
																		values.birthDate
																  ).isValid()
																? moment(
																		values.birthDate
																  )
																: null
														}
														disabledDate={
															disabledDate
														}
														style={{
															width: '100%',
														}}
														size='small'
													/>
												</$Col>
											)}
										</$Row>

										<$Row
											gutter={
												16
											}
											className='mb-1'>
											<$Col
												span={
													8
												}>
												<$Input
													name='telephoneNo'
													label={t(
														'US.COLLECTION.CASE:OTHERPARTY.TELEPHONE'
													)}
													size='small'
												/>
											</$Col>
											<$Col
												span={
													8
												}>
												<$Input
													name='smsNo'
													label={t(
														'US.COLLECTION.DEBTOR:GENERAL.SMS'
													)}
													size='small'
												/>
											</$Col>
											<$Col
												span={
													8
												}>
												<$Input
													name='emailAddress'
													label={t(
														'US.COLLECTION.CASE:OTHERPARTY.EMAIL'
													)}
													size='small'
												/>
											</$Col>
										</$Row>

										<$Row
											gutter={
												16
											}
											className='mb-1'>
											<$Col
												span={
													8
												}>
												<$Input
													name='reference'
													label={t(
														'US.COLLECTION.CASE:OTHERPARTY.REFERENCE'
													)}
													size='small'
													maxLength={
														20
													}
												/>
											</$Col>
										</$Row>

										<$Row
											gutter={
												16
											}
											className='mb-1'>
											<$Col
												span={
													24
												}>
												<$Popover
													placement='topLeft'
													content={
														<div
															style={{
																maxWidth: '300px',
															}}>
															<div className='ant-popover-message'>
																<ExclamationCircleFilled />
																<div className='ant-popover-message-title'>
																	<div
																		style={{
																			paddingBottom:
																				'0.3rem',
																		}}>
																		Adding
																		address
																		will
																		change
																		as
																		known
																		address.
																	</div>
																	<div
																		style={{
																			paddingBottom:
																				'0.5rem',
																		}}>
																		<strong>
																			Do
																			you
																			want
																			to
																			continue?
																		</strong>
																	</div>
																</div>
															</div>
															<div className='ant-popover-buttons'>
																<$Button
																	size='small'
																	onClick={() =>
																		postalCodePopOverConfirmNo(
																			rest,
																			values
																		)
																	}>
																	{t(
																		'US.COMMON:COMMON.NO'
																	)}
																</$Button>
																<$Button
																	size='small'
																	type='primary'
																	onClick={() =>
																		postalCodePopOverConfirmYes(
																			rest,
																			values
																		)
																	}>
																	{t(
																		'US.COMMON:COMMON.YES'
																	)}
																</$Button>
															</div>
														</div>
													}
													trigger='click'
													visible={
														addressUnknownConfirmVisible
													}>
													<div className='mt-3 d-flex flex-1 align-items-top'>
														<div className='mr-3'>
															<$Switch
																name='addrUnknown'
																value={
																	values.addrUnknown
																}
																checked={
																	values.addrUnknown
																}
																onChange={(
																	checked: any
																) => {
																	rest.setFieldValue(
																		'addrUnknown',
																		checked
																	);
																	handleAddressUnknown(
																		checked,
																		rest,
																		values
																	);
																}}
															/>
														</div>
														<div className='d-flex flex-column ml-2'>
															<span>
																{t(
																	'US.COLLECTION.DEBTOR:GENERAL.ADDRESSUNKNOWN'
																)}
															</span>
															<small>
																(No
																letters
																will
																be
																sent
																to
																the
																party
																when
																the
																address
																is
																unknown)
															</small>
														</div>
													</div>
												</$Popover>
											</$Col>
										</$Row>
									</$Skeleton>
								</div>
							</$Collapse.Panel>

							{/* Address information */}
							<$Collapse.Panel
								header={t(
									'US.COLLECTION.CASE:OTHERPARTY.ADDRESS_INFORMATION'
								)}
								key='2'>
								<div className='pl-1 pb-4'>
									<$Skeleton
										loading={
											otherPartyDetail?.isFetching
										}>
										<$Row
											gutter={
												16
											}
											className='mb-1'>
											<$Col
												span={
													8
												}>
												<$Popover
													placement='rightTop'
													content={
														<div
															style={{
																maxWidth: '300px',
															}}>
															<div className='ant-popover-message'>
																<ExclamationCircleFilled />
																<div className='ant-popover-message-title'>
																	<div
																		style={{
																			paddingBottom:
																				'0.3rem',
																		}}>
																		Changing
																		the
																		country
																		will
																		remove
																		postal
																		and
																		municipality
																		details.
																	</div>
																	<div
																		style={{
																			paddingBottom:
																				'0.5rem',
																		}}>
																		<strong>
																			Do
																			you
																			want
																			to
																			continue?
																		</strong>
																	</div>
																</div>
															</div>
															<div className='ant-popover-buttons'>
																<$Button
																	size='small'
																	onClick={() =>
																		countryPopOverConfirmNo(
																			rest
																		)
																	}>
																	{t(
																		'US.COMMON:COMMON.NO'
																	)}
																</$Button>
																<$Button
																	size='small'
																	type='primary'
																	onClick={() =>
																		countryPopOverConfirmYes(
																			rest,
																			values
																		)
																	}>
																	{t(
																		'US.COMMON:COMMON.YES'
																	)}
																</$Button>
															</div>
														</div>
													}
													trigger='click'
													visible={
														popConfirmVisible
													}>
													<$SelectGroup
														className='country-select'
														required
														options={_.orderBy(
															europeanCountry,
															[
																'name',
																'label',
															],
															[
																'asc',
																'asc',
															]
														)}
														formitem={{
															label: t(
																'US.COLLECTION.CASE:OTHERPARTY.COUNTRY'
															),
														}}
														style={{
															width: '100%',
														}}
														value={
															values.country
														}
														name='country'
														optionFilterProp='children'
														onChange={(
															data: any
														) =>
															onChangeCountry(
																data,
																rest,
																values
															)
														}
														filterOption={(
															input: any,
															option: any
														) =>
															option.children
																?.toLowerCase()
																.indexOf(
																	input.toLowerCase()
																) >=
															0
														}
													/>
												</$Popover>
											</$Col>
										</$Row>
										<$Row
											gutter={
												16
											}
											className='mb-1'>
											<$Col
												span={
													8
												}>
												<$Input
													name='address1'
													value={
														values.address1
													}
													label={t(
														'US.COLLECTION.CASE:OTHERPARTY.POSTADDRESS1'
													)}
													size='small'
												/>
											</$Col>
											<$Col
												span={
													8
												}>
												<$Input
													name='address2'
													value={
														values.address2
													}
													label={t(
														'US.COLLECTION.CASE:OTHERPARTY.POSTADDRESS2'
													)}
													size='small'
												/>
											</$Col>
											<$Col
												span={
													8
												}>
												<$Input
													name='address3'
													value={
														values.address3
													}
													label={t(
														'US.COLLECTION.CASE:OTHERPARTY.POSTADDRESS3'
													)}
													size='small'
												/>
											</$Col>
										</$Row>
										<$Row
											gutter={
												16
											}
											className='mb-1'>
											<$Col
												span={
													6
												}>
												<a
													className='d-block'
													style={{
														paddingBottom:
															'0.13rem',
													}}
													onClick={() =>
														changeView()
													}>
													<strong>
														{t(
															'US.COLLECTION.COMMON:ENTITYSELECTION.POSTALCODE'
														)}
													</strong>
												</a>
												<$AsyncInput
													name='zipCode'
													size='small'
													isNumber={
														values.country ===
														DEFAULT_COUNTRY_CODE
													}
													onBlur={(
														data: any
													) =>
														searchZipDetail(
															data
																?.target
																?.value,
															values,
															rest
														)
													}
												/>
											</$Col>
											<$Col
												span={
													6
												}>
												<$Input
													name='zipName'
													label={t(
														'US.COLLECTION.COMMON:ENTITYSELECTION.POSTALAREA'
													)}
													size='small'
													disabled={
														isExistingPostalCode
													}
												/>
											</$Col>
											<$Col
												span={
													6
												}>
												<$Input
													name='municipalityCode'
													label={t(
														'US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYCODE'
													)}
													size='small'
													onBlur={(
														data: any
													) =>
														searchMunicipilityDetail(
															data
																?.target
																?.value,
															values,
															rest
														)
													}
													disabled={
														isExistingPostalCode
													}
												/>
											</$Col>
											<$Col
												span={
													6
												}>
												<$Input
													name='municipalityName'
													label={t(
														'US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYNAME'
													)}
													size='small'
													disabled={
														isExistingMunicipalityCode
													}
												/>
											</$Col>
										</$Row>
									</$Skeleton>
								</div>
							</$Collapse.Panel>
						</$Collapse>
					</$Form>

					<$Drawer
						title={t(
							'US.COLLECTION.DEBTOR:INFO.ADDRESSHISTORY'
						)}
						width={'90%'}
						visible={AddressHistoryVisible}
						onClose={AddressHistoryClose}
						destroyOnClose>
						<AddressHistory
							onClose={
								AddressHistoryClose
							}
							selectedOtherPartyDetail={
								selectedOtherParty
							}
						/>
					</$Drawer>
					<$Drawer
						title={t(
							'US.COLLECTION.CASE:OTHERPARTY.OTHER_PARTY_HISTORY'
						)}
						width={1200}
						visible={DebtorHistoryVisible}
						onClose={DebtorHistoryClose}
						destroyOnClose>
						<EntityHistory
							onClose={
								AddressHistoryClose
							}
							{...props}
							selectedOtherPartyDetail={
								selectedOtherParty
							}
						/>
					</$Drawer>
					<$Drawer
						title={drawerName}
						width={800}
						visible={PostalAreaVisible}
						onClose={postalAreaClose}
						destroyOnClose>
						<PostalArea
							postalAreaClose={() =>
								postalAreaClose()
							}
							drawerContentChangeClick={(
								e: any
							) =>
								handlePostalDoubleClick(
									e?.updatedInfo,
									rest,
									values
								)
							}
							{...props}
						/>
					</$Drawer>
				</div>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: IRootState) => {
	const {
		otherParty,
		entityDetails,
		common,
		debtorDetail,
		domainView,
		transaction,
	} = state;
	const { roleType, postalcode, municipility } = otherParty;
	const {
		postalAreas,
		selectedPostalAreaDetail,
		isFetching,
		entity,
		drawerName,
	} = entityDetails;
	const { currentDateFormat, currentCurrency, currentLanguage } = common;
	const { other, creditor } = debtorDetail;
	const { metaData } = domainView;
	const { arDetails } = transaction;
	return {
		otherPartyDetail: otherParty.otherParty,
		roleType,
		postalAreas,
		currentDateFormat,
		currentCurrency,
		currentLanguage,
		isFetching,
		debtorInfo: entity,
		isFetchingCreditorInfo: creditor.isFetching,
		creditorInfo: creditor,
		isFetchingOtherInformation: other.isFetching,
		metaData,
		selectedPostalAreaDetail,
		drawerName,
		postalcode,
		municipility,
		arDetails,
	};
};

const mapDispatchToProps = {
	getCreditorInfo: creditor.getByArNo,
	getOtherInfo: other.getByArNo,
	save: otherParty.save,
	getOtherPartyInfo: otherParty.get,
	getRoleType: roleTypes.get,
	getPostalAreas: entity.getPostalAreas,
	searchPostalcode: postalCode.search,
	searchMunicipility: minicipality.search,
	resetPostalDetails: postalCode.reset,
};

const wrappedForm = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(General)
);
export default wrappedForm;
