import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import Common from 'us.common';
import CategoryValidation from './Validation/CategoryValidation';
import { workflowAction } from 'us.collection/actions';
import { Category, WorkflowGroup } from 'us.collection/repository';
import { IWorkflowCategory } from 'us.collection/components/Workflow/Interfaces';
import {
	checkCategoryNameDuplication,
	getCategoryInitial,
} from 'us.collection/components/Workflow//Functions';
import { getEntityNumberAndType } from 'us.collection/components/BMD/Functions';
import { matchPath, useLocation } from 'react-router-dom';
import { RootState } from 'us.helper/types';
import { WorkflowDrawerActionType } from 'us.collection/constants';
import 'us.collection/components/Workflow/Home.scss';

const { $Popconfirm, $Button, $Input, $TextArea, $Form } = Common.Components;

/**
 * @description - The component used to add workflow category
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3055714493/Manage+State+Categories
 * @param {PropsFromRedux} props
 * @return {JSX.Element}
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 14/11/2022
 */

const WorkflowCategory: React.FC<PropsFromRedux & IWorkflowCategory> = (
	props
) => {
	const { t } = useTranslation();

	const {
		onClose,
		saveCategory,
		isCategorySaveing,
		workflowDrawer,
		updateCategory,
		workflowStates,
		metaData,
	} = props;

	const { actionType } = workflowDrawer;
	const isEdit = actionType == WorkflowDrawerActionType.EDIT;
	const { caseId } = metaData.data ?? {};

	const { pathname } = useLocation();

	const { params }: any = matchPath(pathname, {
		path: '/:type/:id?/:module?',
		exact: false,
		strict: false,
	});

	/**
	 * @function
	 * @description Save new category
	 * @param values Form values
	 * @returns Void
	 */
	const handleSubmit = (values: any) => {
		const stateRequest = WorkflowGroup.call(
			getEntityNumberAndType(
				params['type'],
				params['id'],
				caseId?.toString() ?? ''
			)
		);

		if (isEdit) {
			updateCategory &&
				updateCategory({
					requestObject: Category.call(values),
					stateRequest,
				});
		} else {
			saveCategory &&
				saveCategory({
					requestObject: Category.call(values),
					stateRequest,
				});
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={getCategoryInitial(
				workflowDrawer,
				workflowStates?.data
			)}
			onSubmit={(values: any) => {
				handleSubmit(values);
			}}
			validationSchema={CategoryValidation}>
			{({ values, handleSubmit }: any) => (
				<>
					<$Form>
						<div>
							<$Input
								name='categoryName'
								label={t(
									'US.COLLECTION.CASE:WORKFLOWS.NAME'
								)}
								size='small'
								required
								className='w-100'
								disabled={
									isCategorySaveing ||
									isEdit
								}
								maxLength={50}
							/>
						</div>
						{checkCategoryNameDuplication(
							workflowStates?.data,
							values?.categoryName
						) &&
							!isEdit && (
								<div className='text-error'>
									{t(
										'US.COLLECTION.CASE:WORKFLOWS.CATEGORY_NAME_ALREADY_EXISTS'
									)}
								</div>
							)}
						<div className='mb-3 mt-3'>
							<$Input
								name='displayName'
								label={t(
									'US.COLLECTION.CASE:WORKFLOWS.DISPLAY_NAME'
								)}
								size='small'
								required
								className='w-100'
								disabled={
									isCategorySaveing
								}
								maxLength={50}
							/>
						</div>
						<div className='mb-3'>
							<$TextArea
								label={t(
									'US.COLLECTION.CASE:WORKFLOWS.DESCRIPTION'
								)}
								name='description'
								autoSize={{
									minRows: 8,
									maxRows: 8,
								}}
								className='w-100'
								disabled={
									isCategorySaveing
								}
								maxLength={250}
							/>
						</div>
					</$Form>
					<div className='drawer-footer-fixed align-content-center justify-content-end'>
						<div>
							<$Button
								type='primary'
								onClick={
									handleSubmit
								}
								className='mr-2'
								disabled={
									isCategorySaveing ||
									(checkCategoryNameDuplication(
										workflowStates?.data,
										values?.categoryName
									) &&
										!isEdit)
								}
								loading={
									isCategorySaveing
								}>
								{isEdit
									? t(
											'US.COMMON:COMMON.UPDATE'
									  )
									: t(
											'US.COMMON:COMMON.SAVE'
									  )}
							</$Button>
							<$Popconfirm
								title={t(
									'US.COLLECTION.CASE:WORKFLOWS.CANCEL_ERROR'
								)}
								placement='topLeft'
								onConfirm={() =>
									onClose()
								}
								okText={t(
									'US.COMMON:COMMON.YES'
								)}
								cancelText={t(
									'US.COMMON:COMMON.NO'
								)}
								disabled={
									isCategorySaveing
								}>
								<$Button
									disabled={
										isCategorySaveing
									}>
									{t(
										'US.COMMON:COMMON.CANCEL'
									)}
								</$Button>
							</$Popconfirm>
						</div>
					</div>
				</>
			)}
		</Formik>
	);
};

const { category } = workflowAction;

const mapStateToProps = (state: RootState) => {
	const { Workflow, domainView } = state;
	const { isCategorySaveing, workflowDrawer, workflowStates } = Workflow;
	const { metaData } = domainView;
	return {
		isCategorySaveing,
		workflowDrawer,
		workflowStates,
		metaData,
	};
};

const mapDispatchToProps = {
	saveCategory: category.save,
	updateCategory: category.update,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WorkflowCategory);
