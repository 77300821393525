export enum ARNote {
  GET_AR_NOTE_HISTORY_START = "GET_AR_NOTE_HISTORY_START",
  GET_AR_NOTE_HISTORY_SUCCESS = "GET_AR_NOTE_HISTORY_SUCCESS",
  GET_AR_NOTE_HISTORY_FAIL = "GET_AR_NOTE_HISTORY_FAIL",

  ADD_AR_NOTE_DRAWER = "AR_NOTE_DRAWER",

  ADD_AR_NOTE_START = "ADD_AR_NOTE_START",
  ADD_AR_NOTE_SUCCESS = "ADD_AR_NOTE_SUCCESS",
  ADD_AR_NOTE_FAIL = "ADD_AR_NOTE_FAIL",

  DELETE_AR_NOTE_START = "DELETE_AR_NOTE_START",
  DELETE_AR_NOTE_SUCCESS = "DELETE_AR_NOTE_SUCCESS",
  DELETE_AR_NOTE_FAIL = "DELETE_AR_NOTE_FAIL",
}


export const DATE_FORMAT_TO_COMPARE = "YYYY-MM-DD"

export enum RegisteredUserType {
  ALL = "All",
  SYSTEM = "System",
  USER = "User",
}
