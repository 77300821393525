import React from "react";
import { useTranslation } from "react-i18next";
import { IActionHistoryButton } from "./Interfaces";
import { $Tooltip, $Button } from "us.common/components";
import { HourglassOutlined } from "us.icons";
import { IRootState } from "us.collection/interfaces";
import { connect, ConnectedProps } from "react-redux";
import { followUps } from "us.collection.followup/actions";
import { getEntityIdForEntity } from "us.collection.followup/functions";

const { historyDrawer, historyForAction } = followUps;

/**
 * @description - More button in table row data of followUp History
 * @param record - individual single row of data
 * @returns  jsx element of more button popup
 */
const ActionHistoryButton: React.FC<PropsFromRedux & IActionHistoryButton> = (
  props
) => {
  const { t } = useTranslation();
  const {
    record,
    entityType,
    followUpId,
    manageFollowUpHistoryDrawer,
    getFollowUpActionHistory,
  } = props;

  const handleActionHistoryClick = () => {
    getFollowUpActionHistory &&
      getFollowUpActionHistory({
        followUpId,
        entityId: getEntityIdForEntity(entityType, record),
      });
    manageFollowUpHistoryDrawer &&
      manageFollowUpHistoryDrawer({
        isActionHistoryVisible: true,
      });
  };
  return (
    <$Tooltip
      title={t("US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.ACTION_HISTORY")}
      placement="top"
    >
      <$Button
        icon={<HourglassOutlined />}
        size="small"
        onClick={() => handleActionHistoryClick()}
      />
    </$Tooltip>
  );
};

const mapStateToProps = (state: IRootState) => {
  return {};
};

const mapDispatchToProps = {
  manageFollowUpHistoryDrawer: historyDrawer.manage,
  getFollowUpActionHistory: historyForAction.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ActionHistoryButton);
