import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import "us.collection.creditor/components/ManageCreditors/Home.scss";
import { IContactInformation } from "us.collection.creditor/components/ManageCreditors/Components";
import { OtherParty } from "us.collection.case/components/OtherParty";

/**
 * @description - Manage Creditor home view component to manage creditor add / edit
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2532114555/Manage+Creditors
 * @author Kaushalya Sandamali <kaushalyas@unicorn-solutions.com>
 * @since 23/03/2022
 */
const ContactInformation: React.FC<IContactInformation> = (props) => {
  const { t } = useTranslation([
    "US.COLLECTION.CREDITOR",
    "US.COLLECTION.COMMON",
  ]);

  const {} = props;

  return (
    <>
      <Formik initialValues={{}} enableReinitialize onSubmit={() => {}}>
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
          resetForm,
          ...restProps
        }: any) => (
          <>
            <div className="pl-1 pb-4">
              <OtherParty {...props} />
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default connect()(ContactInformation);
