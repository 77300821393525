export enum CaseMappingTableColumn {
	CASE_NO = 'caseNo',
	STATE = 'caseState',
	// SELECTION = 'selection',
	TYPE = 'type',
	REF_NO = 'ref',
	REG_DATE = 'regDate',
	BALANCE = 'balance',
	NEW_BALANCE = 'newBalance',
}

export const CASE_MAPPING_TABLE_COLUMNS = [
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.CASE_NO',
		key: CaseMappingTableColumn.CASE_NO,
		filterType: false,
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.STATE',
		key: CaseMappingTableColumn.STATE,
		filterType: true,
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TYPE',
		key: CaseMappingTableColumn.TYPE,
		filterType: true,
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REF_NO',
		key: CaseMappingTableColumn.REF_NO,
		filterType: true,
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REGDATE',
		key: CaseMappingTableColumn.REG_DATE,
		filterType: 'date',
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.BALANCE',
		key: CaseMappingTableColumn.BALANCE,
		filterType: 'amount',
		align: 'right',
	},
];

export const CASE_RE_MAPPING_TABLE_COLUMNS = [
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.CASE_NO',
		key: CaseMappingTableColumn.CASE_NO,
		filterType: false,
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.STATE',
		key: CaseMappingTableColumn.STATE,
		filterType: true,
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TYPE',
		key: CaseMappingTableColumn.TYPE,
		filterType: true,
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REF_NO',
		key: CaseMappingTableColumn.REF_NO,
		filterType: true,
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REGDATE',
		key: CaseMappingTableColumn.REG_DATE,
		filterType: 'date',
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.BALANCE',
		key: CaseMappingTableColumn.BALANCE,
		filterType: 'amount',
		align: 'right',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.NEW_BALANCE',
		key: CaseMappingTableColumn.NEW_BALANCE,
		filterType: 'amount',
		align: 'right',
	},
];
