import React, { useEffect, useContext, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { INavigationData } from 'us.collection/interfaces';
import {
	getEntityDetail,
	getEntityId,
	IntlCurrency,
} from 'us.common/functions';
import { IHeader } from 'us.collection.creditor/components/Dashboard/Interfaces';
import { checkValue } from 'us.collection.creditor/functions';
import {
	PendingActivityExecution,
	AddToFollowUpButton,
} from 'us.collection/components';
import { BoxIcons, IconTypes } from 'us.icons';
import {
	$Skeleton,
	$Divider,
	$DateLabel,
	ActivityExecution,
} from 'us.common/components';
import { ConfigurationContext } from 'us.common/ConfigurationContext/ConfigurationProvider';
import * as Actions from 'us.collection.creditor/actions';
import './Header.scss';
import { BMDProperties } from 'us.collection.creditor/constants';
import { EntityType, EntityTypeShortForm, Mode } from 'us.common/constants';
import { RootState } from 'us.helper/types';

const { creditor } = Actions;
const { general, creditors, otherContact, creditorBMDs } = creditor;

const Header: React.FC<IHeader> = (props) => {
	const { t } = useTranslation();
	const context = useContext(ConfigurationContext);
	const { push } = useHistory();
	const {
		currentLanguage,
		currentCurrency,
		creditorBMDs,
		location,
		general,
		metaData,
		getGeneral,
		getCreditorBMDs,
	} = props;

	const state = location.state as INavigationData;
	const { refreshCount = 0, currentTab, creditorId } = state ?? {};

	const entityDetail = getEntityDetail(location.pathname, metaData.data);
	const { entityId } = entityDetail;

	useEffect(() => {
		if (state && currentTab === 'creditor' && !general.isLoading) {
			if (creditorId) {
				getGeneral && getGeneral({ creditorId });
				getCreditorBMDs &&
					getCreditorBMDs({
						creditorId,
						bmdProperties: `${BMDProperties.INVOICEFEE},${BMDProperties.INTEGRATIONS},${BMDProperties.PROVISIONAMOUNT},${BMDProperties.REMITFILE},${BMDProperties.OUTPUTINTEGRATION}`,
					});
			}
		}
	}, [creditorId, refreshCount]);

	/**
	 * Description - Check whether the given item should be available or not in the header
	 * @param {string} item - Header item name
	 */
	const isHeaderItemAvailable = useCallback(
		(item: string) => {
			if (
				context &&
				context.componentPermission['creditor']
			) {
				return context.componentPermission['creditor']
					?.components[item]?.isEnabled;
			} else {
				return false;
			}
		},
		[context]
	);

	/**
	 * @function
	 * @description handle navigation after execute activity
	 */
	const navigateAfterExecution = () => {
		push(
			{
				...location,
				pathname: `/creditor/${entityId}/history`,
			},
			state
		);
	};

	return (
		<div className='dom-creditor-header'>
			<$Skeleton
				loading={creditorBMDs.isLoading}
				active
				paragraph={{ rows: 0, width: 100 }}>
				<div className='d-flex flex-wrap align-items-center'>
					<div className='d-flex align-items-center'>
						<div className='pr-2'>
							<BoxIcons
								className='creditor-icon'
								type={
									IconTypes.BOX_ICON
								}
								name='creditor'
							/>
						</div>
						<div className='d-flex flex-wrap align-items-center'>
							<div className='creditor-name'>
								{
									general
										.data
										?.extendedFields
										?.CreditorInkassoId
								}
								{general.data
									?.extendedFields
									?.CreditorInkassoId
									? ' - '
									: ''}
								{
									general
										.data
										?.fullName
								}
							</div>
							{checkValue(
								general.data
									?.extendedFields,
								'MasterCreditorName'
							) && (
								<div className='creditor-belongs ml-3'>
									<small className='mr-1'>
										{t(
											'US.COLLECTION.CREDITOR:HEADER.BELONGS'
										)}
									</small>
									<Link
										to={{
											...location,
											pathname: `/creditor/${general.data?.extendedFields?.MasterCreditorInkassoId}`,
											state: {
												...state,
												currentTab: 'creditor',
												creditorId: general
													.data
													?.extendedFields
													?.MasterCreditorId,
											},
										}}>
										<strong>
											{
												general
													.data
													?.extendedFields
													?.MasterCreditorName
											}
										</strong>
									</Link>
								</div>
							)}
						</div>
						<$Divider
							type='vertical'
							className='bui-devider'
						/>
					</div>

					<div>
						<Link
							to={{
								...location,
								pathname: `/creditor/${entityId}/creditor-cases`,
								state: {
									...state,
								},
							}}
							className='creditor-cases-link'>
							<strong className='case-count'>
								{checkValue(
									creditorBMDs.data,
									'caseCount'
								)
									? creditorBMDs
											.data
											?.caseCount
									: 0}
							</strong>
							<span className='bui-label mr-2'>
								{t(
									'US.COLLECTION.CREDITOR:HEADER.CASES'
								)}
							</span>
						</Link>
						<$Divider
							type='vertical'
							className='bui-devider'
						/>
					</div>
					<div className='my-1'>
						<span className='bui-label mr-2'>
							{t(
								'US.COLLECTION.CREDITOR:HEADER.LAST_CASE'
							)}
						</span>
						{checkValue(
							creditorBMDs.data,
							'lastCaseNo'
						) ? (
							<strong>
								<span>
									{
										creditorBMDs
											.data
											?.lastCaseNo
									}
								</span>
								<small>
									{' '}
									-{' '}
									{moment(
										creditorBMDs
											.data
											?.lastCaseDate
									).isValid() && (
										<$DateLabel
											value={
												creditorBMDs
													.data
													?.lastCaseDate
											}
										/>
									)}
								</small>
							</strong>
						) : (
							'--'
						)}
						<$Divider
							type='vertical'
							className='bui-devider'
						/>
					</div>
					<div className='my-1'>
						<span className='bui-label mr-2'>
							{t(
								'US.COLLECTION.CREDITOR:HEADER.INVOICEFEE'
							)}
						</span>
						<strong>
							{creditorBMDs.data
								?.properties
								?.InvoiceFee
								? IntlCurrency(
										creditorBMDs
											.data
											?.properties
											?.InvoiceFee,
										currentLanguage,
										currentCurrency
								  )
								: '--'}
						</strong>
						<$Divider
							type='vertical'
							className='bui-devider'
						/>
					</div>
					<div className='my-1'>
						<span className='bui-label mr-2'>
							{t(
								'US.COLLECTION.CREDITOR:HEADER.PROVISIONSURVEILLANCEMAINAMOUNT'
							)}
						</span>
						<strong>
							{creditorBMDs.data
								?.properties
								?.ProvisionSurveillanceMainAmount
								? IntlCurrency(
										creditorBMDs
											.data
											?.properties
											?.ProvisionSurveillanceMainAmount,
										currentLanguage,
										currentCurrency
								  )
								: '--'}
						</strong>
					</div>
				</div>

				<div className='d-flex align-items-center mt-2'>
					<ActivityExecution
						mode={Mode.Initial}
						isHideTable={false}
						isDisabled={false}
						isBulkExecutionEnabled={false}
						isGroupActivityOptions={false}
						isActivityOptionWithCode={true}
						isHideDatePicker={false}
						activityAPIRequest={{
							endPoint: `activities`,
							parameters: {
								entitytype: EntityTypeShortForm.CREDITOR,
								entitystate: '',
								canExecute: true,
							},
						}}
						executeActivityEndPoint='activities'
						groupingProperty={{
							groupingLabel:
								'activityGroup',
							groupingValue:
								'activityGroupId',
							tableGrouping: 'type',
						}}
						entityId={getEntityId(
							entityDetail
						)}
						entityType={EntityType.CREDITOR}
						isPopConfirmEnabled={true}
						propConfirmDetail={{
							title: 'ARE_YOU_SURE_YOU_WANT_TO_EXECUTE_THIS_ACTIVITY',
						}}
						navigateAfterExecution={
							navigateAfterExecution
						}
						isPopConfirmEnabledDropDown={
							false
						}
					/>
					<div className='ml-auto d-flex'>
						{isHeaderItemAvailable(
							'addToFollowUp'
						) && <AddToFollowUpButton />}
						<PendingActivityExecution
							{...props}
						/>
					</div>
				</div>
			</$Skeleton>
		</div>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, creditor, domainView } = state;
	const { currentLanguage, currentDateFormat, currentCurrency } = common;
	const { creditorBMDs, general } = creditor;
	const { metaData } = domainView;

	return {
		currentLanguage,
		currentCurrency,
		currentDateFormat,
		creditorBMDs,
		general,
		metaData,
	};
};

const mapDispatchToProps = {
	getCreditors: creditors.get,
	getGeneral: general.get,
	getOtherContact: otherContact.get,
	getCreditorBMDs: creditorBMDs.get,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
