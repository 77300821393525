import React from "react";
import { IAsyncRoute } from "us.collection/interfaces";

interface IContainers {
  creditorContainers: Array<IAsyncRoute>;
  creditorGroupContainers: Array<IAsyncRoute>;
}

export const Containers: IContainers = {
  creditorContainers: [
    {
      Container: React.lazy(
        () => import("us.collection.case/containers/OtherParty")
      ),
      Route: "other-party",
    },
    {
      Container: React.lazy(
        () =>
          import(
            "us.collection.routines-and-activities/containers/RoutinesAndActivities"
          )
      ),
      Route: "history",
    },
    {
      Container: React.lazy(
        () => import("us.collection.creditor/containers/Dashboard/Home")
      ),
      Route: "",
    },
    {
      Container: React.lazy(
        () => import("us.collection.creditor/containers/CaseDocuments/Home")
      ),
      Route: "documents",
    },
    {
      Container: React.lazy(
        () =>
          import("us.collection.creditor/containers/CreditorNote/CreditorNote")
      ),
      Route: "notes",
    },
    {
      Container: React.lazy(() => import("us.collection/containers/BMD")),
      Route: "bmd",
    },
    {
      Container: React.lazy(
        () => import("us.collection.creditor/containers/ManageCreditors")
      ),
      Route: "creditor-information",
    },
    {
      Container: React.lazy(() => import("us.collection/containers/Workflow")),
      Route: "workflows",
    },
    {
      Container: React.lazy(() => import("us.collection.creditor/containers/CreditorCases/CreditorCases")),
      Route: "creditor-cases",
    },
    { 
      Container: React.lazy(
        () => import("us.collection/containers/WorkflowTroubleshoot")
      ),
      Route: "workflows-troubleshoot",
    },
  ],
  creditorGroupContainers: [
    {
      Container: React.lazy(
        () => import("us.collection.creditor/containers/SelectedCreditors/Home")
      ),
      Route: "selected-creditors",
    },
    {
      Container: React.lazy(() => import("us.collection/containers/BMD")),
      Route: "bmd",
    },
    {
      Container: React.lazy(() => import("us.collection/containers/Workflow")),
      Route: "workflows",
    },
    {
      Container: React.lazy(
        () => import("us.collection/containers/WorkflowTroubleshoot")
      ),
      Route: "workflows-troubleshoot",
    },
    {
      Container: React.lazy(
        () => import("us.collection.creditor/containers/SelectedCreditors/Home")
      ),
      Route: "",
    },
  ],
};
