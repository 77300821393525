import React, { useEffect } from 'react';
import { $Button, $Tooltip, $Notification, $Spin } from 'us.common/components';
import {
	BoxIcons,
	IconTypes,
	CheckCircleFilled,
	InfoCircleFilled,
	LoadingOutlined,
} from 'us.icons';
import { ConnectedProps, connect } from 'react-redux';
import { INavigationData } from 'us.collection/interfaces';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import * as Actions from 'us.collection/actions';
import { useTranslation } from 'react-i18next';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { RootState } from 'us.helper/types';

type CaseNumberAndType = {
	entityId: string;
	type: string;
	url: string;
	module: string;
	urlId: string;
};

const PendingActivityExecution: React.FC<PropsFromRedux> = (props) => {
	const { t } = useTranslation();
	const location = useLocation();
	const { push } = useHistory();

	const {
		isPendingActivityExecuteSuccess,
		isPendingActivityNotAvailable,
		metaData,
		resetPendingActivityState,
		execute,
	} = props;

	const state = location.state as INavigationData;
	const { creditorId } = state ?? {};
	const { caseId, entityType } = metaData.data ?? {}; //creditorId

	useEffect(() => {
		if (isPendingActivityExecuteSuccess) {
			if (getCaseNumberAndType().module != 'history') {
				successNotification(
					t(
						'US.COLLECTION.LAYOUT:CASE.ALL_PENDING_ACTIVITIES_ARE_EXECUTED'
					),
					ViewExecuted,
					''
				);
			} else {
				successNotification(
					t(
						'US.COLLECTION.LAYOUT:CASE.ALL_PENDING_ACTIVITIES_ARE_EXECUTED'
					),
					'',
					''
				);
			}
		}
		if (isPendingActivityNotAvailable) {
			infoNotification(
				t(
					'US.COLLECTION.LAYOUT:CASE.NO_PENDING_ACTIVITIES_ARE_AVAILABLE_TO_EXECUTE'
				),
				'',
				''
			);
		}
		if (
			isPendingActivityExecuteSuccess ||
			isPendingActivityNotAvailable
		) {
			resetPendingActivityState &&
				resetPendingActivityState({ status: false });
		}
	}, [isPendingActivityExecuteSuccess, isPendingActivityNotAvailable]);

	/**
	 * Get entityId, type, url and module
	 * @return {CaseNumberAndType}  entityId, type, url, module
	 */
	const getCaseNumberAndType = (): CaseNumberAndType => {
		const { params }: any = matchPath(location.pathname, {
			path: '/:caseType/:caseId?/:module?',
			exact: true,
			strict: false,
		});
		let module = params['module'];
		let caseType = params['caseType'];
		let entityId = params['caseId'];
		let type: string = '';
		let url: string = '';
		const urlId: string = params['caseId'];
		switch (caseType) {
			case 'case':
				if (entityType === 'S') {
					type = 'S';
				} else {
					type = 'C';
				}
				url = 'case';
				entityId = caseId;
				break;
			case 'ar':
				type = 'A';
				url = 'ar';
				break;
			case 'creditor':
				type = 'CRE';
				url = 'creditor';
				entityId = creditorId;
				break;
			case 'payment':
				type = 'P';
				url = 'payment';
				break;
			case 'bureau':
				type = 'BU';
				entityId = 0;
				url = 'bureau';
				break;
		}
		switch (module) {
			case 'payment-distribution':
				type = 'P';
				url = 'payment';
				break;
		}
		return {
			entityId,
			type,
			url,
			module,
			urlId,
		};
	};

	/**
	 * Navigate to activity execution history view
	 *
	 */
	const navigateTo = () => {
		const { url, urlId } = getCaseNumberAndType();
		if (url.toLowerCase() === 'bureau') {
			push(`/${url}/history`, location.state);
		} else {
			push(`/${url}/${urlId}/history`, location.state);
		}
		$Notification.close('2');
	};

	const ViewExecuted = (
		<a onClick={navigateTo}>
			{t('US.COLLECTION.LAYOUT:CASE.VIEW_EXECUTED_ACTIVITES')}
		</a>
	);

	/**
	 * Execute all pending activities by entityId and entityType
	 *
	 */
	const executeAllPendingActivies = () => {
		execute &&
			execute({
				entityId: getCaseNumberAndType().entityId,
				entityType: getCaseNumberAndType().type,
			});
		pendingNotification(
			t(
				'US.COLLECTION.LAYOUT:CASE.EXECUTING_ALL_PENDING_ACTIVITIES'
			),
			'',
			''
		);
	};

	/**
	 * Display pending notification
	 *
	 * @param {string} msg - notification message
	 * @param {string} description - notification description
	 * @param {*} key - unique key for notification
	 */
	const pendingNotification = (
		msg: string,
		description: string,
		key: any
	) => {
		$Notification.info({
			message: msg,
			description: description,
			key: '1',
			placement: 'topRight',
			icon: (
				<$Spin
					indicator={
						<LoadingOutlined
							style={{ fontSize: 24 }}
							spin
						/>
					}
				/>
			),
			duration: 15,
		});
	};

	/**
	 * Display success notification and close pending notification
	 *
	 * @param {string} msg - notification message
	 * @param {*} description - notification description
	 * @param {*} key - unique key for notification
	 */
	const successNotification = (
		msg: string,
		description: any,
		key: any
	) => {
		$Notification.success({
			message: msg,
			description: description,
			key: '2',
			placement: 'topRight',
			icon: <CheckCircleFilled className='text-success' />,
			duration:
				getCaseNumberAndType().module != 'history'
					? 30
					: 5,
		});
		$Notification.close('1');
	};

	/**
	 * Display info notification and close pending notification
	 *
	 * @param {string} msg - notification message
	 * @param {*} description - notification description
	 * @param {*} key - unique key for notification
	 */
	const infoNotification = (msg: string, description: any, key: any) => {
		$Notification.info({
			message: msg,
			//description: description,
			key: '3',
			placement: 'topRight',
			icon: <InfoCircleFilled className='text-info' />,
			duration: 5,
		});
		$Notification.close('1');
	};

	return (
		<$Tooltip
			placement='topLeft'
			title={t(
				'US.COLLECTION.LAYOUT:CASE.RUN_ALL_PENDING_ACTIVITIES'
			)}>
			<$Button
				className='bui-btn-tool-icon mx-1'
				onClick={executeAllPendingActivies}
				disabled={
					metaData.data &&
					isSubCaseTransferredToCase(
						metaData.data
					)
				}>
				<BoxIcons
					type={IconTypes.BOX_ICON}
					name='run-activities'
				/>
			</$Button>
		</$Tooltip>
	);
};

const mapState = (state: RootState) => {
	const { activity, domainView } = state;
	const {
		isPendingActivityExecuteSuccess,
		isPendingActivityNotAvailable,
	} = activity;
	const { metaData } = domainView;
	return {
		isPendingActivityExecuteSuccess,
		isPendingActivityNotAvailable,
		metaData,
	};
};

const { pendingActivities } = Actions.activityAction;

const mapDispatch = {
	execute: pendingActivities.save,
	resetPendingActivityState: pendingActivities.reset,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PendingActivityExecution);
