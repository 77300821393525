import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from "service.config.js";

export default {
  RetryWorkflowExecution: {
    get: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "workflowservice",
            `workflows/execute/${params.caseId}/${params.entityType}`,
            {},
            ServiceConfig()[`workflowservice`]["workflows.execute"]
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  WorkflowState: {
    get: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "workflowservice",
            `workflows/workflowgroups`,
            params,
            ServiceConfig()[`workflowservice`]["workflows.workflowgroups"]
          );
          resolve({ stateList: data, stateStatus: status });
        } catch (error) {
          reject(error);
        }
      });
    },
    save: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.post(
            "workflowservice",
            `workflows/states`,
            params,
            ServiceConfig()[`workflowservice`]["workflows.savestates"]
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
    update: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.put(
            "workflowservice",
            `workflows/states`,
            params,
            ServiceConfig()[`workflowservice`]["workflows.updatestates"]
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
    delete: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.patch(
            "workflowservice",
            `workflows/stateCategories/${params.categoryId}/states/${params.id}`,
            params?.requestPayload,
            ServiceConfig()[`workflowservice`]["workflows.deletestates"]
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
    change: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.patch(
            "workflowservice",
            `workflows/stateCategories/${params.categoryId}/states/${params.stateId}`,
            params.requestPayload,
            ServiceConfig()[`workflowservice`]["workflows.changestates"]
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  WorkflowCategory: {
    save: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.post(
            "workflowservice",
            `workflows/statecategories`,
            params,
            ServiceConfig()[`workflowservice`]["workflows.savestatecategories"]
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
    update: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.put(
            "workflowservice",
            `workflows/statecategories/${params.categoryId}`,
            params,
            ServiceConfig()[`workflowservice`][
              "workflows.updatestatecategories"
            ]
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
    delete: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.delete(
            "workflowservice",
            `workflows/statecategories`,
            params?.stateCategoryId,
            {},
            ServiceConfig()[`workflowservice`][
              "workflows.deletestatecategories"
            ]
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  DataAdapter: {
    get: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "workflowservice",
            `workflows/dataadapters`,
            params,
            ServiceConfig()[`workflowservice`]["workflows.dataadapters"]
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  WorkflowVersions: {
    get: ({stateId}: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "workflowservice",
            `workflows/${stateId}/versions`,
            {},
            "v1"
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  Event: {
    get: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "workflowservice",
            `workflows/events`,
            params,
            ServiceConfig()[`workflowservice`]["workflows.getevents"]
          );
          resolve({ events: data, eventStatus: status });
        } catch (error) {
          reject(error);
        }
      });
    },
    save: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.post(
            "workflowservice",
            `workflows/events`,
            params,
            ServiceConfig()[`workflowservice`]["workflows.saveevents"]
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
    update: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.put(
            "workflowservice",
            `workflows/events/${params.eventId}`,
            params,
            ServiceConfig()[`workflowservice`]["workflows.updateevents"]
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  ErrorCount: {
    get: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "workflowservice",
            `workflows/errors/count`,
            params,
            ServiceConfig()[`workflowservice`]["workflows.errorscount"]
          );
          resolve({ count: data, countStatus: status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  MonitorList: {
    get: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "workflowservice",
            `workflows/errors/list`,
            params,
            ServiceConfig()[`workflowservice`]["workflows.errorlist"]
          );
          resolve({ errorList: data, errorStatus: status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  TroubleshootWorkflow: {
    retry: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "workflowservice",
            `workflows/reexecuteworkflow`,
            params,
            ServiceConfig()[`workflowservice`]["workflows.reexecuteworkflow"]
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  WorkflowActivity: {
    retry: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.post(
            "activityservice",
            `activity/reexecute`,
            params,
            ServiceConfig()[`activityservice`]["activity.reexecute"]
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
    delete: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.delete(
            "activityservice",
            `pendingactivities`,
            params,
            {},
            ServiceConfig()[`activityservice`]["pendingactivities"]
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  WorkflowStatusHistory: {
    get: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const historyList = await versionedHttpCollection.get(
            "workflowservice",
            `workflows/history`,
            params,
            "v1"
          );
          
          const properties = await versionedHttpCollection.get(
            "workflowservice",
            `workflows/historydata?stackId=${historyList.data[0]?.stackId}`,
            {},
            "v1"
          );
          resolve({
            historyData: historyList.data,
            historyStatus: historyList.status,
            propertiesData:
              properties.status == 200 ? properties.data : undefined,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getProperties: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "workflowservice",
            `workflows/historydata`,
            params,
            "v1"
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
