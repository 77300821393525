import React, { useEffect } from "react";
import { ConnectedProps, connect } from "react-redux";
import { debtor } from "us.collection.debtor/actions/Debtor";
import { Link, useLocation } from "react-router-dom";
import Common from "us.common";
import { useTranslation } from "react-i18next";
import { IOnFilter, IOnSort } from "us.common/components";
import { filterByReportStatus } from "us.collection.debtor/components/DebtorBasicInfo/Functions";
import { DebtorInfoReportStatus } from "us.collection.debtor/components/DebtorBasicInfo/Constants";
import { appInsights } from "us.helper";
import { Formik } from "formik";
import { RootState } from "us.helper/types";

const { $Skeleton, $TableTree, $DateLabel, $Tooltip, $Tag, $Radio } =
  Common.Components;

const DebtorBadInfo: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation(["US.COLLECTION.DEBTOR"]);

  const { debtorBadDebtInfo, metaData, getDebtorBadDebtInfo } = props;
  const location = useLocation();

  useEffect(() => {
    if (metaData && metaData.data?.debtorEntNumber) {
      getDebtorBadDebtInfo &&
        getDebtorBadDebtInfo(metaData.data?.debtorEntNumber);
    }
  }, [metaData?.data?.debtorEntNumber]);

  const columns: any = [
    {
      title: t("US.COLLECTION.DEBTOR:BADDEBT.CASENO"),
      dataIndex: "exCaseNo",
      key: "exCaseNo",
      className: "text-nowrap",
      width: 180,
      ellipsis: true,
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
      customRenderChild: (text: any, record: any) => {
        return (
          <div className="d-flex align-items-center">
            <Link
              to={{
                pathname: `/case/${record.exCaseNo}`,
                state: location.state,
              }}
            >
              {text > 0 ? text : ""}
            </Link>
            {record?.isReported && (
              <$Tag className="ml-2 tag-reported">
                {t("US.COLLECTION.DEBTOR:BADDEBT.REPORTED")}
              </$Tag>
            )}
          </div>
        );
      },
    },
    {
      title: t("US.COLLECTION.DEBTOR:BADDEBT.RECORDTYPE"),
      dataIndex: "recordType",
      key: "recordType",
      className: "text-nowrap",
      width: 170,
      ellipsis: true,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.DEBTOR:BADDEBT.TYPE"),
      dataIndex: "type",
      key: "type",
      width: "100px",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.DEBTOR:BADDEBT.SETTLEMENTTYPE"),
      dataIndex: "settlementTypeString",
      key: "settlementTypeString",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.DEBTOR:BADDEBT.REASON"),
      dataIndex: "reason",
      key: "reason",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
      ellipsis: { showTitle: false },
      customRenderChild: (text: any) => (
        <$Tooltip placement="topLeft" title={text} key={text}>
          {text}
        </$Tooltip>
      ),
    },
    {
      title: t("US.COLLECTION.DEBTOR:BADDEBT.VALIDATION_COMMENT"),
      dataIndex: "validationComment",
      key: "validationComment",
      width: "200px",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
      ellipsis: { showTitle: false },
      customRenderChild: (text: any) => (
        <$Tooltip placement="topLeft" title={text} key={text}>
          {text}
        </$Tooltip>
      ),
    },
    {
      title: t("US.COLLECTION.DEBTOR:BADDEBT.REGISTEREDBY"),
      dataIndex: "registeredBy",
      key: "registeredBy",
      width: "150px",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
      ellipsis: { showTitle: false },
      customRenderChild: (text: any) => (
        <$Tooltip placement="topLeft" title={text} key={text}>
          {text}
        </$Tooltip>
      ),
    },
    {
      title: t("US.COLLECTION.DEBTOR:BADDEBT.REGDATE"),
      dataIndex: "regDate",
      key: "regDate",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: "date",
      customRenderChild: (text: any, record: any) => {
        return (
          <span>
            <$DateLabel value={text} />
          </span>
        );
      },
    },
    {
      title: t("US.COLLECTION.DEBTOR:BADDEBT.REPORTINGDATE"),
      dataIndex: "reportingDate",
      key: "reportingDate",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: "date",
      customRenderChild: (text: any, record: any) => {
        return (
          <span>
            <$DateLabel value={text} />
          </span>
        );
      },
    },
  ];

  const handleSort: IOnSort = (sortData, dataSource) => {
    try {
      return sortData(dataSource);
    } catch (error) {
      appInsights.trackException(
        `Error in Bad debt info sorting in Debtor Information . - ${error}`
      );
      return dataSource;
    }
  };

  const handleFilter: IOnFilter = (searchData, dataSource) => {
    try {
      return searchData(dataSource);
    } catch (error) {
      appInsights.trackException(
        `Error in Bad debt info filtering in Debtor Information . - ${error}`
      );
      return dataSource;
    }
  };

  return (
    <Formik
      initialValues={{
        reportStatus: DebtorInfoReportStatus.ALL,
      }}
      enableReinitialize
      onSubmit={() => {}}
    >
      {({ values }) => (
        <div className="bad-debt-info">
          <$Radio
            name="reportStatus"
            optionValue="value"
            optionText="label"
            disabled={debtorBadDebtInfo.isLoading}
            className="d-flex"
            options={[
              {
                label: t("COMMON.ALL"),
                value: DebtorInfoReportStatus.ALL,
              },
              {
                label: t("COMMON.REPORTED"),
                value: DebtorInfoReportStatus.REPORTED,
              },
              {
                label: t("COMMON.NOT_REPORTED"),
                value: DebtorInfoReportStatus.NOT_REPORTED,
              },
            ]}
          />

          <$Skeleton
            loading={debtorBadDebtInfo.isLoading}
            active
            paragraph={{ rows: 2 }}
          >
            <$TableTree
              rowKey="entityId"
              data={filterByReportStatus(
                debtorBadDebtInfo.data,
                values.reportStatus
              )}
              size="small"
              className="mt-3"
              onSort={handleSort}
              onFilter={handleFilter}
              filterOnType={true}
              resetOnSourceChange={true}
              bordered
              pagination={{
                defaultPageSize: 15,
              }}
              scroll={{
                x: 1550,
                y: "calc(100vh - 280px)",
              }}
              columns={columns}
              firstColSkipFilterProps={-1}
            />
          </$Skeleton>
        </div>
      )}
    </Formik>
  );
};

const mapState = (state: RootState) => {
  const { common, domainView, debtorDetail } = state;
  const { badDebt } = debtorDetail;
  const { metaData } = domainView;

  return {
    metaData,
    currentLanguage: common.currentLanguage,
    debtorBadDebtInfo: badDebt,
  };
};

const mapDispatch = {
  getDebtorBadDebtInfo: debtor.badDebt.get,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DebtorBadInfo);
