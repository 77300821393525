import { FollowUps } from "us.collection/constants";
import { initialState } from "./State";

const {
  MANAGE_ENTITY_FOLLOW_UP_DRAWER
} = FollowUps;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case MANAGE_ENTITY_FOLLOW_UP_DRAWER:
      return Object.assign({}, state, {
        drawer: payload?.data,
      })
    default:
      return state;
  }
};
