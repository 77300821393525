import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import XMLViewer from 'react-xml-viewer';
import printJS from 'print-js';
import parse from 'html-react-parser';

import Common from 'us.common';
import * as Actions from 'us.collection.routines-and-activities/actions';
import { FileExclamationOutlined, PrinterOutlined } from 'us.icons';
import {
  DocumentType,
  unableToDisplayFormats,
} from 'us.collection.routines-and-activities/constants';
import {
  attachementType,
  base64ToHtml,
  base64ToXmlString,
  changeExtension,
  getCaseNumberAndType,
  getLookUpTypeByCaseType,
  getPDFFromXML,
} from 'us.collection.routines-and-activities/functions';
import { DocumentViewerProps } from './Interfaces';
import { RootState } from 'us.helper/types';
import { b64toBlob } from 'us.helper';

const { $Button, $Skeleton, $Empty } = Common.Components;

const DocumentViewer: React.FC<DocumentViewerProps & PropsFromRedux> = (
  props
) => {
  const { t } = useTranslation();
  const {
    isXmlPdf,
    metaData,
    data,
    documentType,
    attachement,
    onClose,
    getAttachment,
    resetAttachment
  } = props;
  const { entityType } = metaData.data ?? {};
  const isUnSupportedDoc = unableToDisplayFormats.includes(documentType);

  useEffect(() => {
    const lookupId = data?.isUploadDocument
      ? data?.documentID
      : parseInt(data?.communicationJobId);

    if (lookupId && !isNaN(Number(lookupId))) {
      getAttachment &&
        getAttachment({
          lookupId,
          lookupType: data?.isUploadDocument
            ? data?.entityType
            : DocumentType.OUTPUT_DOCUMENT,
          system: data?.isUploadDocument
            ? DocumentType.ENTITY_DOCUMENT
            : DocumentType.USC_SERVICE,
        });
    }
    return () => {
      resetAttachment && resetAttachment({});
    };
  }, []);
  const getDocumentContent = (): string => {
    const { documentType, content } = attachement.data[0];
    try {
      if (documentType === DocumentType.XML) {
        if (isXmlPdf) {
          const xmlContent = base64ToXmlString(content);
          return getPDFFromXML(xmlContent);
        } else {
          return base64ToXmlString(content);
        }
      } else {
        return content;
      }
    } catch (error) {
      return content;
    }
  };

  const getDownloadLink = (): string => {
    const { documentType, content } = attachement.data[0];
    try {
      if (documentType === DocumentType.XML && isXmlPdf) {
        const xmlContent = base64ToXmlString(content);
        return `data:application/pdf
				};base64,${getPDFFromXML(xmlContent)}`;
      } else {
        return `data:text/${documentType}
				};base64,${content}`;
      }
    } catch (error) {
      return `data:text/${documentType}
				};base64,${content}`;
    }
  };

  /**
   * @description - Handle pdf file printing from the given base64
   * @param - Base64 content for the pdf
   */
  const handlePrint = (base64Data: string) => {
    try {
      const isFirefox = navigator.userAgent.includes('Firefox');
      if (isFirefox) {
        const blob = b64toBlob(base64Data, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        const printWindow = window.open(blobUrl, '_blank');
        if (printWindow) {
          printWindow.focus();
          printWindow.print();
        }
      } else {
        printJS({
          printable: base64Data,
          type: 'pdf',
          base64: true,
        });
      }
    } catch (error) {
      console.error('Print failed : ', error);
    }
  };

  /**
   * @description - Get viewer component related the document type
   * @param {DocumentType} documentType - Type of the document
   * @returns {JSX.Element}
   */
  const getViewer = (): JSX.Element => {
    const { documentType } = attachement.data[0];
    if (isUnSupportedDoc) {
      return (
        <div className="unable-to-display">
          <$Empty
            image={<FileExclamationOutlined />}
            description={
              <span>
                {t(
                  'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.PREVIEW_IS_NOT_AVAILABLE'
                )}
              </span>
            }
          />
        </div>
      );
    } else {
      switch (documentType) {
        case DocumentType.HTML:
        case DocumentType.EMAIL:
          return (
            <div className="history-doc-viwer-inner">
              <object
                className={'history-doc-html'}
                data={`data:${'text/html'};base64,${getDocumentContent()}`}
              />
            </div>
          );
        case DocumentType.PDF:
          return (
            <div className="history-doc-viwer-inner">
              <object
                className={'history-doc-frame frame-pdf'}
                data={`data:${'application/pdf'};base64,${getDocumentContent()}`}
              />
            </div>
          );
        case DocumentType.XML:
          return (
            <>
              {!isXmlPdf && (
                <XMLViewer
                  xml={getDocumentContent()}
                  theme={{
                    overflowBreak: true,
                  }}
                  indentSize={5}
                  collapsible={true}
                />
              )}
              {isXmlPdf && (
                <div className="history-doc-viwer-inner">
                  <object
                    className={'history-doc-frame frame-pdf'}
                    data={`data:${'application/pdf'};base64,${getDocumentContent()}`}
                  />
                </div>
              )}
            </>
          );
        default:
          return (
            <div className="history-doc-viwer-inner">
              <object
                className={'history-doc-frame frame-html'}
                data={`data:${attachementType(
                  documentType
                )};base64,${getDocumentContent()}`}
              />
            </div>
          );
      }
    }
  };

  return (
    <$Skeleton loading={attachement.isLoading}>
      {attachement.data?.length > 0 && (
        <div>
          {/* render viewer content */}
          {getViewer()}
          {/* Viewer bottom action pannel */}
          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Button
                className="mr-2"
                type="primary"
                download={
                  isXmlPdf
                    ? changeExtension(
                        attachement.data[0].documentName,
                        DocumentType.PDF
                      )
                    : attachement.data[0].documentName
                }
                href={getDownloadLink()}
              >
                {t('US.COLLECTION.COMMON:COMMON.DOWNLOAD')}
              </$Button>

              {attachement.data[0].documentType == DocumentType.PDF && (
                <$Button
                  className="mr-2"
                  type="primary"
                  icon={<PrinterOutlined />}
                  onClick={() => handlePrint(getDocumentContent())}
                />
              )}

              <$Button onClick={onClose}>
                {t('US.COLLECTION.COMMON:COMMON.CANCEL')}
              </$Button>
            </div>
          </div>
        </div>
      )}
      {!attachement.content && (
        <div>
          <$Empty
            className="mb-2"
            description={`${t(
              'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.NO_ATTACHMENTS'
            )}`}
            image={$Empty.PRESENTED_IMAGE_SIMPLE}
          />
        </div>
      )}
    </$Skeleton>
  );
};

const mapStateToProps = (state: RootState) => {
  const { domainView, logHistory } = state;
  const { metaData } = domainView;
  const { attachement } = logHistory;
  return {
    attachement,
    metaData,
  };
};
const { historyAction } = Actions;
const { attachment } = historyAction;

const mapDispatchToProps = {
  getAttachment: attachment.get,
  resetAttachment: attachment.reset
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DocumentViewer);
