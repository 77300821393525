/**
 * @description - Component for transaction table columns.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2919530635/Court+Fee+Handling+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 10/03/2022
 * @param transactionList - List of transactions.
 * @param editTransactionHandler - open edit transaction drawer.
 * @param handleTransactionMapping - open transaction mapping drawer.
 */

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  $AmountLabel,
  $Button,
  $Popover,
  $DateLabel,
  $Tooltip,
} from "us.common/components";
import { Link, useLocation } from "react-router-dom";
import { invalidDate } from "us.collection.transactions/components/Transactions/Constants";
import { MoreOutlined, BoxIcons, IconTypes } from "us.icons";
import { ConfigurationContext } from "us.common/ConfigurationContext/ConfigurationProvider";
import { getSum } from "us.collection.transactions/functions";
import { URLType } from "us.collection/constants";
import { PopOverContent } from "./Components";
import { ITableTreeColumns } from "us.common/components/antd/TableTree";
import { InterestType } from "us.collection.transactions/constants";
import { getRouteUrl } from 'us.helper';

export const TransactionColumns = (
  transactionList: Array<any>,
  editTransactionHandler: (transaction: any) => void,
  handleTransactionMapping: (transaction: any) => void,
  editInterestHandler: (transaction: any) => void,
  managePopOver: any,
  visiblePopOver: number
) => {
  const { t } = useTranslation();

  const context = useContext(ConfigurationContext);
  const location = useLocation<any>();

  return [
    {
      title: "",
      key: "more",
      dataIndex: "more",
      width: "50px",
      customRenderChild: (text: any, record: any) => {
        return (
          <$Popover
            trigger="click"
            placement="rightTop"
            content={
              <PopOverContent
                transaction={record}
                onClickMenuItem={(value: number) => managePopOver(value)}
                editTransactionHandler={editTransactionHandler}
                handleTransactionMapping={handleTransactionMapping}
                editInterestHandler={editInterestHandler}
              />
            }
            destroyTooltipOnHide
            visible={record.arItemNo == visiblePopOver}
            onVisibleChange={(visible: boolean) => {
              managePopOver(visible ? record.arItemNo : -1);
            }}
          >
            <$Button
              data-testid="transaction-more-options"
              icon={<MoreOutlined />}
              size="small"
            />
          </$Popover>
        );
      },
    },
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REF_NO"),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      className: "text-nowrap",
      width: 140,
      ellipsis: true,
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
      customRenderChild: (text: any, record: any) => {
        const { invoiceNumber, kid } = record;
        return (
          <div>
            <div>{invoiceNumber}</div>
            <div className="mt-n1">
              {kid && (
                <small>
                  {t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.KID")} -{kid}
                </small>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TYPE"),
      dataIndex: "type",
      key: "type",
      className: "text-nowrap",
      width: 120,
      ellipsis: true,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
      customRenderChild: (
        text: any,
        { type, referencingCaseNo, courtFeeInvoiceNo }: any
      ) => {
        return (
          <div className="d-flex">
            <span>
              {type}{" "}
              {referencingCaseNo > 0 && (
                <a
                  onClick={ ()=> 
                    getRouteUrl.moduleRoute("collection", `/case/${referencingCaseNo}`, true)
                  } 
                >
                  {referencingCaseNo}
                </a>
              )}
            </span>
            {courtFeeInvoiceNo && (
              <$Tooltip title={t("US.COMMON:COMMON.PAID")}>
                <$Button
                  type="link"
                  size="small"
                  icon={
                    <BoxIcons
                      type={IconTypes.BOX_ICON}
                      name="court-fee"
                      className=""
                    />
                  }
                />
              </$Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.ORDER_DATE"),
      dataIndex: "orderDate",
      key: "orderDate",
      className: "text-nowrap",
      width: 120,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: "date",
      customRenderChild: (text: any, record: any) => {
        const { orderDate } = record;
        return (
          <>
            {orderDate && orderDate != invalidDate && (
              <$DateLabel value={orderDate} />
            )}
          </>
        );
      },
    },
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.VOUCHERNO"),
      dataIndex: "voucherID",
      key: "voucherID",
      className: "text-nowrap",
      width: 140,
      ellipsis: true,
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
      customRenderChild: (text: any, record: any) => {
        const { voucherID } = record;
        return <span>{voucherID > 0 ? voucherID : ""}</span>;
      },
    },
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.VOUCHERDATE"),
      dataIndex: "voucherDate",
      key: "voucherDate",
      className: "text-nowrap",
      width: 140,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: "date",
      customRenderChild: (text: any, record: any) => {
        const { voucherDate } = record;
        return (
          <>
            {voucherDate && voucherDate != invalidDate && (
              <$DateLabel value={voucherDate} />
            )}
          </>
        );
      },
    },
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.DUEDATE"),
      dataIndex: "dueDate",
      key: "dueDate",
      className: "text-nowrap",
      width: 120,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: "date",
      customRenderChild: (text: any, record: any) => {
        const { dueDate } = record;
        return (
          <>
            {dueDate && dueDate != invalidDate && (
              <$DateLabel value={dueDate} />
            )}
          </>
        );
      },
    },
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REGDATE"),
      dataIndex: "regDate",
      key: "regDate",
      className: "text-nowrap",
      width: 120,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: "date",
      customRenderChild: (text: any, record: any) => {
        const { regDate } = record;
        return (
          <>
            {regDate && regDate != invalidDate && (
              <$DateLabel value={regDate} />
            )}
          </>
        );
      },
    },
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.CASENO"),
      dataIndex: "caseNo",
      key: "caseNo",
      className: "text-nowrap",
      width: 110,
      ellipsis: true,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
      customRenderChild: (text: any, record: any) => {
        const { caseNo, caseId } = record;
        return (
          <span>
            <Link
              to={{
                ...location,
                pathname: `/case/${caseNo}`,
                state: {
                  ...location?.state,
                  currentTab: URLType.SUBCASE,
                  subCaseId: caseId,
                },
              }}
            >
              {caseNo > 0 ? caseNo : ""}
            </Link>
          </span>
        );
      },
    },
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.PAYMENTID"),
      dataIndex: "paymentID",
      key: "paymentID",
      className: "text-nowrap",
      width: 130,
      ellipsis: true,
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
      customRenderChild: (text: any, record: any) => {
        const { paymentID } = record;
        return (
          <span>
            {context.componentPermission &&
              context.componentPermission["payment"]?.tabs["payment"]
                ?.isEnabled && (
                <Link
                  to={{
                    ...location,
                    pathname: `/payment/${paymentID}/distribution`,
                    state: {
                      originate: "payment",
                      accessTabs: ["payment"],
                      refreshCount: 0,
                      currentTab: "payment",
                    },
                  }}
                  replace={false}
                >
                  {paymentID > 0 ? paymentID : ""}
                </Link>
              )}
            {context.componentPermission &&
              !context.componentPermission["payment"]?.tabs["payment"]
                ?.isEnabled && <span>{paymentID > 0 ? paymentID : ""}</span>}
          </span>
        );
      },
    },
    {
      title: () => (
        <>
          <div>{t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.AMOUNT")}</div>
          <div className="header-custom-amount">
            <$AmountLabel value={getSum(transactionList, "amount")} />
          </div>
        </>
      ),
      dataIndex: "amount",
      key: "amount",
      align: "right",
      className: "right-has-sort right-has-filter text-nowrap",
      width: 110,
      customSorter: (a: any, b: any) => a - b,
      customFilter: "amount",
      customRenderChild: (text: any, record: any) => {
        return <$AmountLabel value={record.amount} />;
      },
    },
    {
      title: () => (
        <>
          <div>
            {t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.INTERESTAMOUNT")}
          </div>
          <div className="header-custom-amount">
            <$AmountLabel value={getSum(transactionList, "interestAmount")} />
          </div>
        </>
      ),
      dataIndex: "interestAmount",
      key: "interestAmount",
      align: "right",
      className: "right-has-sort right-has-filter text-nowrap",
      width: 110,
      customSorter: (a: any, b: any) => a - b,
      customFilter: "amount",
      customRenderChild: (text: any, record: any) => {
        return <$AmountLabel value={record.interestAmount} />;
      },
    },
    {
      title: () => (
        <>
          <div>{t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TOTAL_AMOUNT")}</div>
          <div className="header-custom-amount">
            <$AmountLabel value={getSum(transactionList, "totalAmount")} />
          </div>
        </>
      ),
      dataIndex: "totalAmount",
      key: "totalAmount",
      align: "right",
      className: "right-has-sort right-has-filter text-nowrap",
      width: 140,
      customSorter: (a: any, b: any) => a - b,
      customFilter: "amount",
      customRenderChild: (text: any, record: any) => {
        return <$AmountLabel value={record.totalAmount} />;
      },
    },
    {
      title: () => (
        <div>{t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.BALANCE")}</div>
      ),
      dataIndex: "balance",
      key: "balance",
      align: "right",
      className: "right-has-sort right-has-filter text-nowrap",
      width: 110,
      customSorter: (a: any, b: any) => a - b,
      customFilter: "amount",
      customRenderChild: (text: any, record: any) => {
        return <$AmountLabel value={record.balance} />;
      },
    },

    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.INTEREST_RATE"),
      dataIndex: "interestRate",
      key: "interestRate",
      className: "text-nowrap",
      width: 130,
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
      customRenderChild: (text: any, record: any) => {
        const { interestRate, interestType, isInterestApplicable } = record;
        return (
          <>
            {isInterestApplicable ? (
              <span>
                {interestType != InterestType.STANDARD_INTEREST && interestRate
                  ? interestRate + "%"
                  : ""}
              </span>
            ) : (
              <span>
                {t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.NOT_APPLICABLE")}
              </span>
            )}
          </>
        );
      },
    },
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.INTEREST_FROM_DATE"),
      dataIndex: "interestFromDate",
      key: "interestFromDate",
      className: "text-nowrap",
      width: 170,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: "date",
      customRenderChild: (text: any, record: any) => {
        const { interestFromDate } = record;
        return (
          <>
            {interestFromDate && interestFromDate != invalidDate && (
              <$DateLabel value={interestFromDate} />
            )}
          </>
        );
      },
    },
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.CREATEDBY"),
      dataIndex: "createdBy",
      key: "createdBy",
      className: "text-nowrap",
      width: 200,
      ellipsis: true,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.DESCRIPTION"),
      dataIndex: "description",
      key: "description",
      className: "text-nowrap",
      width: 250,
      ellipsis: true,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
  ] as ITableTreeColumns;
};
