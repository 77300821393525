import React from 'react';
import { Formik, useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Common from 'us.common';
import { connect, ConnectedProps } from 'react-redux';
import { IUpdateCaseBalance } from 'us.collection.case/components/Enforcements/Add/Components/CaseBalanceView/Interfaces';
import { rows } from './Constants';
import { replaceCaseBalanceByAccountSummary } from 'us.collection.case/functions';
import { RootState } from 'us.helper/types';

const { $PageHeader, $Button, $Breadcrumb, $Form, CaseBalanceUpdate } =
	Common.Components;

/**
 * @description - Case Balance Update Single Row Component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3040346132/Enforcement-Update+Case+Balance+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 05/07/2022
 */
const CaseBalanceView: React.FC<PropsFromRedux & IUpdateCaseBalance> = (
	props
) => {
	const { t } = useTranslation();
	const { values, setFieldValue } = useFormikContext() as any;
	const [field, meta, helpers] = useField('caseBalance'); //caseBalanceSwitch
	const [fieldCaseBalance] = useField('caseBalanceSwitch');
	const { backToNewEnforcement, metaData, accountSummary, drawer } = props;

	return (
		<Formik
			initialValues={{
				...field.value,
				...{
					...(field.value.isEdited
						? {}
						: replaceCaseBalanceByAccountSummary(
								accountSummary.data
						  )),
				},
			}}
			enableReinitialize
			onSubmit={(values: any, actions: any) => {
				// helpers.setValue({ ...values, isEdited: true });
				setFieldValue('caseBalance', {
					...values,
					isEdited: true,
				});
				setFieldValue('caseBalanceSwitch', true);
				backToNewEnforcement();
			}}
			validateOnMount
			validateOnBlur>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...rest
			}: any) => (
				<div className='update-case-balance'>
					<div className='d-flex'>
						<div className='flex-grow-1'>
							<$Breadcrumb>
								<$Breadcrumb.Item>
									<a
										onClick={() =>
											backToNewEnforcement()
										}>
										{t(
											'US.COLLECTION.CASE:ENFORCEMENTS.NEWENFORCEMENT'
										)}
									</a>
								</$Breadcrumb.Item>
								<$Breadcrumb.Item>
									{t(
										'US.COLLECTION.CASE:ENFORCEMENTS.UPDATE_CASE_BALANCE'
									)}
								</$Breadcrumb.Item>
							</$Breadcrumb>
							<$PageHeader
								className='p-0 mb-3'
								onBack={() =>{
									drawer.isNewEnforcement &&
									setFieldValue('caseBalanceSwitch', false);
									backToNewEnforcement()
								}}
								title={t(
									'US.COLLECTION.CASE:ENFORCEMENTS.UPDATE_CASE_BALANCE'
								)}
							/>
						</div>
					</div>
					<$Form layout='vertical'>
						<div className='mt-3'>
							<CaseBalanceUpdate
								rows={rows.map(
									({
										label,
										...rest
									}) => ({
										label: t(
											label
										),
										...rest,
									})
								)}
								accountSummary={
									accountSummary.data
								}
							/>
						</div>
					</$Form>

					<div className='drawer-footer-fixed align-content-center justify-content-end'>
						<div>
							<$Button
								className='ml-3 mr-2'
								type='primary'
								onClick={(e) =>
									handleSubmit(
										e
									)
								}
								data-testid={
									'btn-update'
								}>
								{t(
									'US.COLLECTION.COMMON:COMMON.UPDATE'
								)}
							</$Button>
							<$Button
								onClick={() =>{
									backToNewEnforcement();
									drawer.isNewEnforcement &&
									setFieldValue('caseBalanceSwitch', false);
								}}>
								{t(
									'US.COLLECTION.COMMON:COMMON.CANCEL'
								)}
							</$Button>
						</div>
					</div>
				</div>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, enforcement, domainView } = state;
	const { metaData } = domainView;
	const { currentDateFormat, currentLanguage, currentCurrency } = common;
	const { drawer, accountSummary } = enforcement;

	return {
		currentDateFormat,
		currentLanguage,
		currentCurrency,
		drawer,
		metaData,
		accountSummary,
	};
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CaseBalanceView);
