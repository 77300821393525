import React, { useEffect } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import Common from "us.common";
import { workflowAction } from "us.collection/actions";
import {
  WorkflowEvent,
  WorkflowGroup,
  StateDelete,
} from "us.collection/repository";
import { INewEvent } from "us.collection/components/Workflow/Interfaces";
import {
  getEventInitialDetails,
  getEventWorkflowType,
  checkEventNameDuplication,
} from "us.collection/components/Workflow/Functions";
import { EventValidation } from "../Validation";
import {
  eventDrawerInitial,
  WORKFLOW_TOOL_DOWNLOAD_URL,
} from "us.collection/components/Workflow/Constants";
import { RootState } from "us.helper/types";
import { EntityType, EntityTypeShortForm } from "us.common/constants";
import { getEntityNumberAndType } from "us.collection/components/BMD/Functions";
import { matchPath, useLocation } from "react-router-dom";
import "us.collection/components/Workflow/Home.scss";

const {
  $Popconfirm,
  $Button,
  $Input,
  $TextArea,
  $Form,
  $Radio,
  $Select,
  $Switch,
} = Common.Components;

/**
 * @description - The component used to add workflow event
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3116466232/Manage+Workflow+Events+-+UI
 * @param {PropsFromRedux} props
 * @return {JSX.Element}
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 17/11/2022
 */

const NewEvent: React.FC<PropsFromRedux & INewEvent> = (props) => {
  const { t } = useTranslation();

  const {
    dataAdapterList,
    isEventSaveing,
    eventDrawer,
    workflowEvents,
    searchFilters,
    isDeleting,
    duplicatingId,
    isWorkflowPopupVisible,
    getDataAdapter,
    openDrawer,
    saveEvent,
    updateEvent,
    restoreEvent,
    manageDuplicatingPopover,
    manageWorkflowPopover,
  } = props;

  const { workflowGroupStateId } = eventDrawer ?? {};

  const { pathname } = useLocation();

  const { params }: any = matchPath(pathname, {
    path: "/:type/:id?/:module?",
    exact: false,
    strict: false,
  });

  useEffect(() => {
    getDataAdapter &&
      getDataAdapter({
        workflowType: getEventWorkflowType(workflowEvents, eventDrawer),
      });
    return () => {
      manageDuplicatingPopover && manageDuplicatingPopover({});
    };
  }, []);

  /**
   * @function
   * @description Save new event
   * @param values Form values
   * @returns Void
   */
  const handleSubmit = (values: any) => {
    const { isOpenWorkflow } = values ?? {};
    const formValues = { ...values, workflowGroupStateId };
    
    if (eventDrawer.isEdit) {
      updateEvent &&
        updateEvent({
          request: WorkflowEvent.call(formValues),
          isOpenWorkflow,
          searchFilters,
        });
    } else {
      saveEvent &&
        saveEvent({
          request: WorkflowEvent.call(formValues),
          isOpenWorkflow,
          searchFilters,
        });
    }
  };

  /**
   * Get adapter sp list
   * @param value Workflow type
   * @param restProps Formik props
   */
  const getDataAdapterList = (value: string, restProps: any) => {
    restProps.setFieldValue("adapterName", "");
    if (value == EntityType.SUB_CASE) {
      getDataAdapter &&
        getDataAdapter({ workflowType: EntityTypeShortForm.SUB_CASE });
    } else {
      getDataAdapter &&
        getDataAdapter({ workflowType: EntityTypeShortForm.CASE });
    }
  };

  /**
   * @function
   * @description Restore existing workflow state
   */
  const restoreWorkflowState = () => {
    restoreEvent &&
      restoreEvent({
        ...StateDelete.call({ id: duplicatingId, categoryId: -1 }, 0),
        stateRequest: WorkflowGroup.call(
          getEntityNumberAndType(params["type"], params["id"], "")
        ),
        searchFilters,
        isReActivate: true,
      });
  };

  const onClose = () => {
    openDrawer && openDrawer(eventDrawerInitial);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={getEventInitialDetails(
        workflowEvents,
        eventDrawer,
        WorkflowGroup.call(
          getEntityNumberAndType(params["type"], params["id"], "")
        )
      )}
      onSubmit={(values: any, actions: any) => {
        handleSubmit(values);
      }}
      validationSchema={EventValidation}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        ...rest
      }: any) => (
        <>
          <$Form>
            <div>
              <$Input
                name="name"
                label={t("US.COLLECTION.CASE:WORKFLOWS.NAME")}
                size="small"
                required
                className="w-100"
                disabled={isEventSaveing || eventDrawer.isEdit}
                maxLength={100}
              />
            </div>
            {checkEventNameDuplication(workflowEvents?.data, values?.name) &&
              !eventDrawer?.isEdit && (
                <div className="text-error">
                  {t("US.COLLECTION.CASE:WORKFLOWS.EVENT_NAME_ALREADY_EXISTS")}
                </div>
              )}
            <div className="mb-3 mt-3">
              <$Input
                name="displayName"
                label={t("US.COLLECTION.CASE:WORKFLOWS.DISPLAY_NAME")}
                size="small"
                required
                className="w-100"
                disabled={isEventSaveing}
                maxLength={100}
              />
            </div>

            <div className="mb-3">
              <$Radio
                name="workflowType"
                optionText="label"
                optionValue="value"
                className="d-flex mb-3"
                size="small"
                defaultValue={"Case"}
                buttonStyle="solid"
                optionStyle="mr-2 align-items-center"
                onChange={(e: any) => getDataAdapterList(e.target.value, rest)}
                options={[
                  {
                    value: EntityType.CASE,
                    label: t("US.COMMON:COMMON.CASE"),
                  },
                  {
                    value: EntityType.SUB_CASE,
                    label: t("US.COMMON:COMMON.SUBCASE"),
                  },
                ]}
                disabled={isEventSaveing || eventDrawer.isEdit}
              />
            </div>
            <div className="mb-3">
              <$Select
                formitem={{
                  label: t("US.COLLECTION.CASE:WORKFLOWS.ADAPTER_NAME"),
                }}
                name="adapterName"
                size="small"
                required
                allOption={false}
                options={dataAdapterList?.data}
                loading={dataAdapterList.isLoading}
                disabled={isEventSaveing}
              />
            </div>

            <div className="mb-4">
              <$TextArea
                label={t("US.COLLECTION.CASE:WORKFLOWS.DESCRIPTION")}
                name="description"
                autoSize={{ minRows: 4, maxRows: 4 }}
                className="w-100"
                disabled={isEventSaveing}
              />
            </div>

            <div className="mb-3">
              <div className="d-flex align-items-start">
                <div style={{ marginTop: -1 }}>
                  <$Switch
                    size="small"
                    name="isDomainViewActive"
                    checked={values.isDomainViewActive}
                    disabled={isEventSaveing}
                  />
                </div>

                <div className="pl-2">
                  <div>
                    {t(
                      "US.COLLECTION.CASE:WORKFLOWS.CAN_EXECUTE_FROM_DOMAIN_VIEW"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </$Form>

          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              {typeof duplicatingId !== "number" && (
                <$Button
                  type="primary"
                  onClick={handleSubmit}
                  className="mr-2"
                  disabled={
                    isEventSaveing ||
                    (checkEventNameDuplication(
                      workflowEvents?.data,
                      values?.name
                    ) &&
                      !eventDrawer?.isEdit)
                  }
                  loading={isEventSaveing}
                >
                  {eventDrawer.isEdit
                    ? t("US.COMMON:COMMON.UPDATE")
                    : t("US.COMMON:COMMON.SAVE")}
                </$Button>
              )}
              {typeof duplicatingId === "number" && (
                <$Popconfirm
                  title={t(
                    "US.COLLECTION.CASE:WORKFLOWS.RESTORE_EVENT_MESSAGE"
                  )}
                  okText={t("US.COLLECTION.COMMON:COMMON.YES")}
                  cancelText={t("US.COLLECTION.COMMON:COMMON.NO")}
                  visible={typeof duplicatingId === "number"}
                  okButtonProps={{ disabled: isDeleting, loading: isDeleting }}
                  onConfirm={() => restoreWorkflowState()}
                  onCancel={() =>
                    manageDuplicatingPopover && manageDuplicatingPopover({})
                  }
                  placement="topRight"
                >
                  <$Button
                    type="primary"
                    onClick={handleSubmit}
                    className="mr-2"
                    disabled={
                      typeof duplicatingId === "number" ||
                      isEventSaveing ||
                      isDeleting ||
                      (checkEventNameDuplication(
                        workflowEvents?.data,
                        values?.name
                      ) &&
                        !eventDrawer?.isEdit)
                    }
                    loading={isEventSaveing}
                  >
                    {t("US.COMMON:COMMON.SAVE")}
                  </$Button>
                </$Popconfirm>
              )}
              {eventDrawer.isEdit && (
                <$Popconfirm
                  title={t(
                    "US.COLLECTION.CASE:WORKFLOWS.WORKFLOW_TOOL_IS_NOT_AVAILABLE_IN_YOUR_MACHINE"
                  )}
                  okText={t("US.COMMON:COMMON.DOWNLOAD")}
                  onConfirm={() => {
                    window.open(WORKFLOW_TOOL_DOWNLOAD_URL, "_self");
                    manageWorkflowPopover && manageWorkflowPopover({});
                    onClose();
                  }}
                  onCancel={() => {
                    manageWorkflowPopover && manageWorkflowPopover({});
                    onClose();
                  }}
                  visible={isWorkflowPopupVisible}
                  placement="topLeft"
                  overlayClassName="wt-not-available"
                >
                  <$Button
                    onClick={async () => {
                      rest.setFieldValue("isOpenWorkflow", true);
                      handleSubmit();
                    }}
                    className="mr-2"
                    disabled={
                      typeof duplicatingId === "number" ||
                      isEventSaveing ||
                      isWorkflowPopupVisible ||
                      (checkEventNameDuplication(
                        workflowEvents?.data,
                        values?.name
                      ) &&
                        !eventDrawer.isEdit)
                    }
                  >
                    {t("US.COLLECTION.CASE:WORKFLOWS.UPDATE_AND_OPEN_WORKFLOW")}
                  </$Button>
                </$Popconfirm>
              )}
              <$Popconfirm
                title={t("US.COLLECTION.CASE:WORKFLOWS.CANCEL_ERROR")}
                placement="topLeft"
                onConfirm={() => onClose()}
                okText={t("US.COMMON:COMMON.YES")}
                cancelText={t("US.COMMON:COMMON.NO")}
                disabled={isEventSaveing}
              >
                <$Button disabled={isEventSaveing}>
                  {t("US.COMMON:COMMON.CANCEL")}
                </$Button>
              </$Popconfirm>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  const { Workflow } = state;
  const {
    dataAdapterList,
    isEventSaveing,
    isDeleting,
    duplicatingId,
    eventDrawer,
    workflowEvents,
    isWorkflowPopupVisible,
  } = Workflow;
  return {
    dataAdapterList,
    isEventSaveing,
    eventDrawer,
    workflowEvents,
    isDeleting,
    duplicatingId,
    isWorkflowPopupVisible,
  };
};

const { dataAdapters, event, workflowState, eventsDelete } = workflowAction;

const mapDispatchToProps = {
  getDataAdapter: dataAdapters.get,
  openDrawer: event.openDrawer,
  saveEvent: event.save,
  updateEvent: event.update,
  restoreEvent: eventsDelete.delete,
  manageDuplicatingPopover: workflowState.manageDuplicatingPopover,
  manageWorkflowPopover: workflowState.manageWorkflowPopover,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NewEvent);
