import { CaseType } from "us.helper/types/enums";
import { IParameter } from "us.common/components/DynamicPopOver/Interface";
import { PopOverDataType } from "us.common/components/DynamicPopOver/Constants";
import { appInsights } from "us.helper";

/**
 * @function
 * @description - Filter transaction columns.
 * @param {Array<{ dataIndex: string }>} columns - list of columns.
 * @param {Array<string>} filterValues - list of filtered data.
 * @param {string} caseType - case type.
 * @returns {Array<any>} Filtered columns.
 */
export const filterColumnsByDataIndexAndCaseType = (
  columns: Array<{ dataIndex: string }>,
  filterValues: Array<string>,
  caseType: string
): Array<any> => {
  try {
    return caseType === CaseType.C
      ? columns
      : columns?.filter(
          ({ dataIndex }: { dataIndex: string }) =>
            !filterValues.includes(dataIndex)
        );
  } catch (error) {
    appInsights.trackException(
      `Filtering Columns By Data Index and CaseType Exception - ${error}`
    );
    return columns;
  }
};

/**
 * @function
 * @description - Get dynamic popOver parameters payload.
 * @param {Array<IParameter>} parameters - list of parameters.
 * @returns {Array<Omit<IParameter, "paramLength" | "isEditable">} Formatted array of parameters.
 */
export const popOverPayloadParameters = (
  parameters: Array<IParameter>
): Array<Omit<IParameter, "paramLength" | "isEditable">> => {
  try {
    return parameters.map(
      ({ paramName, paramType, paramValue, paramLength }: IParameter) => {
        return {
          paramName: paramName ? `@${paramName}` : paramName,
          paramType,
          paramLength,
          paramValue:
            paramType === PopOverDataType.BIT
              ? paramValue
                ? 1
                : 0
              : paramValue,
        };
      }
    );
  } catch (error) {
    appInsights.trackException(
      `DynamicPopOver payload params function Exception - ${error}`
    );
    return [];
  }
};
