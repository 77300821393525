import { call, put, takeLatest } from 'redux-saga/effects';
import * as API from 'us.collection/services';
import {
	AuthorizationLevel,
	DomainView,
	URLTypeId,
} from 'us.collection/constants';
import * as Actions from 'us.collection/actions';
import { $MessageBox } from 'us.common/components';
import * as CaseActions from 'us.collection.case/actions';
import { UserAuthorizationAction } from 'us.common/actions';

const { domainViewService } = API;

const { caseId, workflowStatus, gdprComment, metaData, gdprCommentView } =
	Actions.domainViewAction;
const {
	CaseInfoWidgetActions,
	AccountSummeryWidgetActions,
	manualFollowUpActions,
} = CaseActions;
const { caseInformation } = CaseInfoWidgetActions;
const { accountSummery } = AccountSummeryWidgetActions;
const { followUpsSummary } = manualFollowUpActions;

const { userAuthorizationProfile } = UserAuthorizationAction;

const DomainViewSaga = {
	caseId: {
		get: function* (action: any) {
			const params = action.payload?.data;
			try {
				const { exCaseNo, authorizationProfile } =
					params ?? {};

				if (gdprCommentView.reset) {
					yield put(gdprCommentView.reset({}));
				}

				const { data, status } = yield call(
					domainViewService.caseId.get,
					{ exCaseNo }
				);

				if (status === 200 && data && data.caseId) {
					yield put(
						caseId.success({
							...data,
							caseNo: exCaseNo,
						})
					);
					if (gdprCommentView.get) {
						yield put(
							gdprCommentView.get(
								authorizationProfile
							)
						);
					}
				} else {
					yield put(caseId.fail(data));
				}
			} catch (error) {
				yield put(caseId.fail(error as any));
			}
		},
	},
	metaData: {
		get: function* (action: any) {
			const params = action.payload?.data;
			try {
				const { data, status } = yield call(
					domainViewService.metaData.get,
					params
				);
				if (status === 200) {
					yield put(metaData.success(data));
				} else {
					yield put(metaData.fail(data));
				}
			} catch (error) {
				yield put(metaData.fail(error as any));
			}
		},
	},
	workflowStatus: {
		get: function* (action: any) {
			const params = action.payload?.data;
			try {
				const { data, status } = yield call(
					domainViewService.workflowStatus.get,
					params
				);
				if (status === 200) {
					yield put(workflowStatus.success(data));
				} else {
					yield put(workflowStatus.fail(data));
				}
			} catch (error) {
				yield put(workflowStatus.fail(error as any));
			}
		},
	},
	gdprComment: {
		save: function* (action: any) {
			const params = action.payload?.data;
			const {
				comment,
				caseId,
				entityType,
				authorizationProfile,
			} = params ?? {};
			try {
				const { data, status } = yield call(
					domainViewService.gdprComment.save,
					comment
				);
				if (status === 201) {
					yield put(gdprComment.success(data));

					if (
						authorizationProfile.accessAuthorityLevel ==
						AuthorizationLevel.CAN_DISABLE
					) {
						yield put(
							userAuthorizationProfile.success(
								{
									...authorizationProfile,
									isCommentEnable:
										comment?.isCommentEnable,
								}
							)
						);
					}

					if (entityType && caseId) {
						if (metaData.get) {
							yield put(
								metaData.get({
									id: caseId,
									type: URLTypeId.CASE,
								})
							);
						}
						if (workflowStatus.get) {
							yield put(
								workflowStatus.get(
									{
										caseId: caseId,
									}
								)
							);
						}
						if (accountSummery.get) {
							yield put(
								accountSummery.get(
									{
										EntityType: entityType,
										EntityId: caseId,
									}
								)
							);
						}
						if (caseInformation.get) {
							yield put(
								caseInformation.get(
									{
										caseId: caseId,
									}
								)
							);
						}
						if (followUpsSummary.get) {
							yield put(
								followUpsSummary.get(
									{
										caseId: caseId,
									}
								)
							);
						}
					}
					//-------------------------------------------------
				} else {
					$MessageBox(
						'error',
						'US.COLLECTION.LAYOUT:CASE.ACCESS_AUTHORITY_COMMENT_SAVE_FAIL',
						'',
						''
					);
					yield put(gdprComment.fail(data));
				}
			} catch (error) {
				yield put(gdprComment.fail(error as any));
			}
		},
	},
};

export default [
	takeLatest(DomainView.GET_CASE_ID, DomainViewSaga.caseId.get),
	takeLatest(
		DomainView.GET_WORKFLOW_STATUS,
		DomainViewSaga.workflowStatus.get
	),
	takeLatest(
		DomainView.ADD_GDPR_COMMENT_START,
		DomainViewSaga.gdprComment.save
	),
	takeLatest(DomainView.GET_META_DATA, DomainViewSaga.metaData.get),
];
