import { BMDInitialState } from "./State/InitialState";
import { BMDActionsConst } from "us.collection/constants";
import {
  addBMDCategoryToList,
  getBMDCategoryList,
  getBMDDrawerObject,
  getBMDObject,
} from "./Functions";
import { IBMDInitialState } from "us.collection/interfaces/BMD";

const {
  GET_BMD_LIST,
  GET_BMD_LIST_SUCCESS,
  GET_BMD_LIST_FAIL,

  ADD_BMD_PROPERTY,
  ADD_BMD_PROPERTY_SUCCESS,
  ADD_BMD_PROPERTY_FAIL,

  GET_BMD_DATA_TYPES,
  GET_BMD_DATA_TYPES_SUCCESS,
  GET_BMD_DATA_TYPES_FAIL,

  GET_BMD_DATA_TYPE_VALUES,
  GET_BMD_DATA_TYPE_VALUES_SUCCESS,
  GET_BMD_DATA_TYPE_VALUES_FAIL,

  GET_BMD_PROPERTY_DETAILS,
  GET_BMD_PROPERTY_DETAILS_SUCCESS,
  GET_BMD_PROPERTY_DETAILS_FAIL,

  CHANGE_BMD_PROPERTY_DETAILS,
  CHANGE_BMD_PROPERTY_DETAILS_SUCCESS,
  CHANGE_BMD_PROPERTY_DETAILS_FAIL,

  DELETE_BMD_PROPERTY_HISTORY_DETAILS,
  DELETE_BMD_PROPERTY_HISTORY_DETAILS_SUCCESS,
  DELETE_BMD_PROPERTY_HISTORY_DETAILS_FAIL,

  CHANGE_BMD_DRAWER_STATUS,

  ADD_BMD_CATEGORY,
  ADD_BMD_CATEGORY_SUCCESS,
  ADD_BMD_CATEGORY_FAIL,

  ADD_BMD_GROUP,
  ADD_BMD_GROUP_SUCCESS,
  ADD_BMD_GROUP_FAIL,

  ADD_BMD_GROUP_DRAWER,

  UPDATE_BMD_GROUP,
  UPDATE_BMD_GROUP_SUCCESS,
  UPDATE_BMD_GROUP_FAIL,

  GET_BMD_COUNT_LIST,
  GET_BMD_COUNT_LIST_SUCCESS,
  GET_BMD_COUNT_LIST_FAIL,

  SEARCH_LINKED_CREDITORS,
  SEARCH_LINKED_CREDITORS_SUCCESS,
  SEARCH_LINKED_CREDITORS_FAIL,

  ADD_DATA_TYPE,
  EDIT_DATA_TYPE,
  ADD_EDIT_DATA_TYPE_SUCCESS,
  ADD_EDIT_DATA_TYPE_FAIL,

  GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES,
  GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES_SUCCESS,
  GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES_FAIL,
} = BMDActionsConst;

export default (state: IBMDInitialState = BMDInitialState, action: any) => {
  switch (action.type) {
    case GET_BMD_LIST:
      return getBMDObject(state, "bmdList", undefined, true, false);
    case GET_BMD_LIST_SUCCESS:
      return {
        ...getBMDObject(state, "bmdList", action.payload.data, false, false),
        categoryList: {
          data: getBMDCategoryList(action.payload.data?.categories),
          isError: false,
          isLoading: false,
        },
      };
    case GET_BMD_LIST_FAIL:
      return getBMDObject(state, "bmdList", undefined, false, true);

    case ADD_BMD_PROPERTY:
      return getBMDObject(state, "addBmdProperty", undefined, true, false);
    case ADD_BMD_PROPERTY_SUCCESS:
      return getBMDObject(state, "addBmdProperty", action.payload.data, false, false);
    case ADD_BMD_PROPERTY_FAIL:
      return getBMDObject(state, "addBmdProperty", undefined, false, true);

    case GET_BMD_DATA_TYPES:
      return getBMDObject(state, "bmdDataTypes", undefined, true, false);
    case GET_BMD_DATA_TYPES_SUCCESS:
      return getBMDObject(state, "bmdDataTypes", action.payload.data, false, false);
    case GET_BMD_DATA_TYPES_FAIL:
      return getBMDObject(state, "bmdDataTypes", undefined, false, true);

    case GET_BMD_DATA_TYPE_VALUES:
      return getBMDObject(state, "bmdDataTypeValues", undefined, true, false);
    case GET_BMD_DATA_TYPE_VALUES_SUCCESS:
      return getBMDObject(state, "bmdDataTypeValues", action.payload.data, false, false);
    case GET_BMD_DATA_TYPE_VALUES_FAIL:
      return getBMDObject(state, "bmdDataTypeValues", undefined, false, true);

    case GET_BMD_PROPERTY_DETAILS:
      return getBMDObject(state, "bmdPropertyDetails", undefined, true, false);
    case GET_BMD_PROPERTY_DETAILS_SUCCESS:
      return getBMDObject(state, "bmdPropertyDetails", action.payload.data, false, false);
    case GET_BMD_PROPERTY_DETAILS_FAIL:
      return getBMDObject(state, "bmdPropertyDetails", undefined, false, true);

    case CHANGE_BMD_PROPERTY_DETAILS:
      return getBMDObject(state, "changeBmdProperty", undefined, true, false);
    case CHANGE_BMD_PROPERTY_DETAILS_SUCCESS:
      return getBMDObject(state, "changeBmdProperty", action.payload.data, false, false);
    case CHANGE_BMD_PROPERTY_DETAILS_FAIL:
      return getBMDObject(state, "changeBmdProperty", undefined, false, true);

    case DELETE_BMD_PROPERTY_HISTORY_DETAILS:
      return getBMDObject(state, "historyDelete", undefined, true, false);
    case DELETE_BMD_PROPERTY_HISTORY_DETAILS_SUCCESS:
      return getBMDObject(state, "historyDelete", action.payload.data, false, false);
    case DELETE_BMD_PROPERTY_HISTORY_DETAILS_FAIL:
      return getBMDObject(state, "historyDelete", undefined, false, true);

    case CHANGE_BMD_DRAWER_STATUS:
      return getBMDDrawerObject(state, "drawerStatus", action.payload.data);

    case ADD_BMD_CATEGORY:
      return Object.assign({}, state, {
        categoryList: { ...state.categoryList, isLoading: true },
        bmdGroupInitial: action.payload.data.values,
      });
    case ADD_BMD_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        categoryList: {
          data: addBMDCategoryToList(
            state.categoryList.data,
            action.payload.data.categoryId,
            action.payload.data.categoryName
          ),
          isError: false,
          isLoading: false,
        },
        bmdGroupInitial: {
          ...state.bmdGroupInitial,
          categoryId: action.payload.data.categoryId,
          newCategoryName: "",
        },
      });
    case ADD_BMD_CATEGORY_FAIL:
      return getBMDObject(state, "categoryList", state.categoryList.data, false, false);

    case ADD_BMD_GROUP_DRAWER:
      return Object.assign({}, state, {
        bmdGroupDrawer: action.payload.data,
        bmdGroupInitial: BMDInitialState.bmdGroupInitial,
      });

    case ADD_BMD_GROUP:
      return Object.assign({}, state, {
        bmdGroup: { ...state.bmdGroup, isLoading: true },
      });
    case ADD_BMD_GROUP_SUCCESS:
      return Object.assign({}, state, {
        bmdGroup: {
          data: action.payload.data,
          isLoading: false,
          isError: false,
        },
        bmdGroupInitial: BMDInitialState.bmdGroupInitial,
        bmdGroupDrawer: {
          title: "",
          isVisible: false,
          isEdit: false,
        },
      });
    case ADD_BMD_GROUP_FAIL:
      return Object.assign({}, state, {
        bmdGroup: {
          data: action.payload.data,
          isLoading: false,
          isError: true,
        },
      });
    case UPDATE_BMD_GROUP:
      return Object.assign({}, state, {
        bmdGroup: { ...state.bmdGroup, isLoading: true },
      });

    case SEARCH_LINKED_CREDITORS:
      return getBMDObject(state, "linkedCreditors", undefined, true, false);
    case SEARCH_LINKED_CREDITORS_SUCCESS:
      return getBMDObject(state, "linkedCreditors", action.payload.data, false, false);
    case SEARCH_LINKED_CREDITORS_FAIL:
      return getBMDObject(state, "linkedCreditors", undefined, false, true);

    case ADD_DATA_TYPE:
    case EDIT_DATA_TYPE:
      return getBMDObject(state, "addUpdateDataType", {}, true, false);
    case ADD_EDIT_DATA_TYPE_SUCCESS:
      return getBMDObject(state, "addUpdateDataType", action.payload.data, false, false);
    case ADD_EDIT_DATA_TYPE_FAIL:
      return getBMDObject(state, "addUpdateDataType", undefined, false, true);

    case GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES:
      return getBMDObject(state, "valueRetrievingSPs", [], true, false);
    case GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES_SUCCESS:
      return getBMDObject(state, "valueRetrievingSPs", action.payload.data, false, false);
    case GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES_FAIL:
      return getBMDObject(state, "valueRetrievingSPs", [], false, true);

    case GET_BMD_COUNT_LIST:
      return getBMDObject(state, "bmdChangeCount", undefined, true, false);
    case GET_BMD_COUNT_LIST_SUCCESS:
      return getBMDObject(state, "bmdChangeCount", action.payload.data, false, false);
    case GET_BMD_COUNT_LIST_FAIL:
      return getBMDObject(state, "bmdChangeCount", undefined, false, true);
      
    default:
      return state;
  }
};
