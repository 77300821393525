import React from "react";
import { connect } from "react-redux";
import { IRootState } from "us.collection/interfaces";
import Common from "us.common";
import { useTranslation } from "react-i18next";
import { IContact } from "us.collection.creditor/components/Dashboard/Interfaces";
import { checkValue } from "us.collection.creditor/functions";
import { BoxIcons, IconTypes } from "us.icons";
import "./Contact.scss";

  ;const { $Skeleton, $Row, $Col, $Tooltip, $Empty } = Common.Components;

const Home: React.FC<IContact> = (props) => {
  const { t } = useTranslation(["US.COLLECTION.CREDITOR"], {
    useSuspense: true,
  });
  const { general, metaData } = props;
  return (
    <div className="widget creditor-contact-info">
      <div className="widget-body py-3 px-3">
        <strong className="d-block mb-3">
          {t("US.COLLECTION.CREDITOR:ADDRESSINFO.ADDRESSINFO")}
        </strong>
        <$Skeleton
          loading={general?.isLoading || metaData.isLoading}
          active
          paragraph={{ rows: 2 }}
        >
          <div className="dom-amt-list-table mb-3 contact-info">
            <div className="list-table-row">
              <div className="list-table-col" style={{ width: "26px" }}>
              {
              !checkValue(general.data, "address") &&
              !checkValue(general.data, "postal") &&
              !checkValue(general.data, "municipility") && 
              !checkValue(general.data?.extendedFields, "CountryName") && (
                <$Empty
                  className="mb-4"
                  description={`${t(
                    "US.COLLECTION.CREDITOR:SETTINGS.NO_DATA"
                  )}`}
                  image={$Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
                {checkValue(general.data, "address") && (
                  <BoxIcons type={IconTypes.BOX_ICON} name="address" />
                )}
              </div>
              <div className="list-table-col">
                {checkValue(general.data, "address") && (
                  <div className="mb-2">{general.data?.address}</div>
                )}
                {checkValue(general.data, "postal") && (
                  <div className="list-table-row">
                    <div className="list-table-col bui-label">
                      {t("US.COLLECTION.CREDITOR:BASICINFO.POSTAL")}
                    </div>
                    <div className="list-table-col pl-3">
                      {general.data?.postal}
                    </div>
                  </div>
                )}
                {checkValue(general.data, "municipility") && (
                  <div className="list-table-row">
                    <div className="list-table-col bui-label">
                      {t("US.COLLECTION.CREDITOR:BASICINFO.MUNICIPALITY")}
                    </div>
                    <div className="list-table-col pl-3">
                      {general.data?.municipility}
                    </div>
                  </div>
                )}
                {checkValue(general.data?.extendedFields, "CountryName") && (
                  <div className="list-table-row">
                    <div className="list-table-col bui-label">
                      {t("US.COLLECTION.CREDITOR:BASICINFO.COUNTRY")}
                    </div>
                    <div className="list-table-col pl-3">
                      {general.data?.extendedFields?.CountryName}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </$Skeleton>
        <strong className="d-block mb-3">
          {t("US.COLLECTION.CREDITOR:INFO.CONTACT_INFO")}
        </strong>
        <$Skeleton loading={general?.isLoading} active paragraph={{ rows: 2 }}>
          <div className="dom-amt-list-table contact-info">
            {
             (general?.data?.contacts && Object.keys(general?.data?.contacts)?.length == 0) &&
              !checkValue(general.data, "email") &&
              !checkValue(general.data?.extendedFields, "msn") &&
              !checkValue(general.data?.extendedFields, "skype") && (
                <$Empty
                  className="mb-4"
                  description={`${t(
                    "US.COLLECTION.CREDITOR:SETTINGS.NO_DATA"
                  )}`}
                  image={$Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            {checkValue(general.data, "email") && (
              <div className="list-table-row">
                <div className="list-table-col" style={{ width: "26px" }}>
                  <$Tooltip
                    placement="top"
                    title={t("US.COLLECTION.CREDITOR:INFO.EMAIL")}
                  >
                    <BoxIcons
                      className="creditor-email-icon"
                      type={IconTypes.BOX_ICON}
                      name="email-fill"
                    />
                  </$Tooltip>
                </div>
                <div className="list-table-col">
                  <div>{general.data?.email}</div>
                </div>
              </div>
            )}
            {checkValue(general.data?.extendedFields, "msn") && (
              <div className="list-table-row">
                <div className="list-table-col" style={{ width: "26px" }}>
                  <$Tooltip
                    placement="top"
                    title={t("US.COLLECTION.CREDITOR:INFO.MSN")}
                  >
                    <BoxIcons
                      className="creditor-msn-icon"
                      type={IconTypes.BOX_ICON}
                      name="msn"
                    />
                  </$Tooltip>
                </div>
                <div className="list-table-col">
                  <div>{general.data?.extendedFields?.msn}</div>
                </div>
              </div>
            )}
          </div>
          <div className="dom-amt-list-table contact-info">
            <$Row>
              {checkValue(general.data?.contacts, "home") && (
                <$Col span={12}>
                  <div className="list-table-row">
                    <div className="list-table-col" style={{ width: "26px" }}>
                      <$Tooltip
                        placement="top"
                        title={t("US.COLLECTION.CREDITOR:INFO.HOME")}
                      >
                        <BoxIcons type={IconTypes.BOX_ICON} name="phone-home" />
                      </$Tooltip>
                    </div>
                    <div className="list-table-col">
                      <div>{general.data?.contacts?.home}</div>
                    </div>
                  </div>
                </$Col>
              )}
              {checkValue(general.data?.contacts, "sms") && (
                <$Col span={12}>
                  <div className="list-table-row">
                    <div className="list-table-col" style={{ width: "26px" }}>
                      <$Tooltip
                        placement="top"
                        title={t("US.COLLECTION.CREDITOR:INFO.SMS")}
                      >
                        <BoxIcons type={IconTypes.BOX_ICON} name="phone-sms" />
                      </$Tooltip>
                    </div>
                    <div className="list-table-col">
                      <div>{general.data?.contacts?.sms}</div>
                    </div>
                  </div>
                </$Col>
              )}
              {checkValue(general.data?.contacts, "mobile") && (
                <$Col span={12}>
                  <div className="list-table-row">
                    <div className="list-table-col" style={{ width: "26px" }}>
                      <$Tooltip
                        placement="top"
                        title={t("US.COLLECTION.CREDITOR:INFO.MOBILE")}
                      >
                        <BoxIcons
                          type={IconTypes.BOX_ICON}
                          name="phone-mobile"
                        />
                      </$Tooltip>
                    </div>
                    <div className="list-table-col">
                      <div>{general.data?.contacts?.mobile}</div>
                    </div>
                  </div>
                </$Col>
              )}
              {checkValue(general.data?.contacts, "fax") && (
                <$Col span={12}>
                  <div className="list-table-row">
                    <div className="list-table-col" style={{ width: "26px" }}>
                      <$Tooltip
                        placement="top"
                        title={t("US.COLLECTION.CREDITOR:INFO.FAX")}
                      >
                        <BoxIcons type={IconTypes.BOX_ICON} name="phone-fax" />
                      </$Tooltip>
                    </div>
                    <div className="list-table-col">
                      <div>{general.data?.contacts?.fax}</div>
                    </div>
                  </div>
                </$Col>
              )}
              {checkValue(general.data?.contacts, "work") && (
                <$Col span={12}>
                  <div className="list-table-row">
                    <div className="list-table-col" style={{ width: "26px" }}>
                      <$Tooltip
                        placement="top"
                        title={t("US.COLLECTION.CREDITOR:INFO.WORK")}
                      >
                        <BoxIcons type={IconTypes.BOX_ICON} name="phone-work" />
                      </$Tooltip>
                    </div>
                    <div className="list-table-col">
                      <div>{general.data?.contacts?.work}</div>
                    </div>
                  </div>
                </$Col>
              )}
              {checkValue(general.data?.extendedFields, "skype") && (
                <$Col span={12}>
                  <div className="list-table-row">
                    <div className="list-table-col" style={{ width: "26px" }}>
                      <$Tooltip
                        placement="top"
                        title={t("US.COLLECTION.CREDITOR:INFO.SKYPE")}
                      >
                        <BoxIcons
                          className="creditor-skype-icon"
                          type={IconTypes.BOX_ICON}
                          name="skype"
                        />
                      </$Tooltip>
                    </div>
                    <div className="list-table-col">
                      <div>{general.data?.extendedFields?.skype}</div>
                    </div>
                  </div>
                </$Col>
              )}
            </$Row>
          </div>
        </$Skeleton>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common, domainView, creditor } = state;
  const { currentLanguage, currentDateFormat } = common;
  const { general } = creditor;
  const { metaData } = domainView;
  return {
    currentLanguage,
    currentDateFormat,
    metaData,
    general,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
