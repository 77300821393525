import React from 'react';
import { useTranslation } from 'react-i18next';
import { parse } from 'query-string';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
	$Skeleton,
	$Collapse,
	FollowUpCard,
	$Empty,
} from 'us.common/components';
import { FollowUpPanel } from 'us.common/components/FollowUps/Constants';
import { manualFollowUpActions } from 'us.collection.case/actions';
import { filterManualFollowUps } from 'us.collection.case/functions';
import { getEntityTypeAndId } from 'us.collection.followup/functions';
import { initialFilter } from 'us.collection/components/FollowUps/Components/CurrentFollowUps/Components/Constants';
import { IManualFollowUp } from 'us.collection.case/interfaces';
import { IDueOrUpComing } from './Interfaces';
import { RootState } from 'us.helper/types';

const { manualFollowUp } = manualFollowUpActions;

/**
 * @description - Manual FollowUp list consists of followup cards inside the followup tab in entity followup drawer
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2912288817/Get+active+follow-up+for+EntityId+and+EntityType+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 09/03/2022
 */
const ManualFollowUps: React.FC<
	PropsFromRedux & IDueOrUpComing & RouteComponentProps
> = (props) => {
	const { t } = useTranslation();

	const { dataSource, metaData, list, doneOrRemoveFollowUp, location } =
		props;

	const { isLoading } = list;

	return (
		<>
			<$Skeleton
				loading={isLoading}
				paragraph={{ rows: 2 }}
				active>
				<$Collapse
					ghost
					accordion
					defaultActiveKey={'0'}
					collapsible={'header'}
					className='collection-followup-collapsed'>
					{dataSource.length === 0 && !isLoading && (
						<div className='fl-container'>
							<div className='flc-body'>
								<div className='followup-dashboard-msg'>
									<$Empty
										className='pt-5'
										description={`${t(
											'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.NO_DATA'
										)}`}
									/>
								</div>
							</div>
						</div>
					)}
					{dataSource.length > 0 &&
						filterManualFollowUps(
							dataSource,
							{
								...initialFilter,
								...parse(
									location.search
								),
							}
						).map(
							({
								followupDatasetId,
								note,
								followupReason,
								followupDate,
							}: IManualFollowUp) => (
								<FollowUpCard
									key={
										followupDatasetId
									}
									uniqueId={
										followupDatasetId
									}
									description={{
										label: t(
											'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.DESCRIPTION'
										),
										value: note,
									}}
									title={
										followupReason
									}
									date={{
										label: t(
											'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.DATE'
										),
										value: followupDate,
									}}
									followUpPanel={
										FollowUpPanel.DUE
									}
									onExecuteAction={(
										values: any
									) =>
										doneOrRemoveFollowUp &&
										doneOrRemoveFollowUp(
											values,
											getEntityTypeAndId(
												props.location,
												metaData
											)
										)
									}
								/>
							)
						)}
				</$Collapse>
			</$Skeleton>
		</>
	);
};

const mapStateToProps = (state: RootState) => {
	const { domainView, manualFollowUps } = state;
	const { metaData } = domainView;
	const { list } = manualFollowUps;

	return {
		metaData: metaData.data,
		list,
	};
};

const mapDispatchToProps = {
	doneOrRemoveFollowUp: manualFollowUp.doneOrRemove,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ManualFollowUps);
