import React from "react";
import { $AmountLabel } from "us.common/components";

interface IDisplayAmount {
  clickNavigate: () => void;
  balance: number;
  amount: number;
  name : string;
}

export const DisplayAmount: React.FC<IDisplayAmount> = (props) => {
  
  const { amount, balance, clickNavigate, name  } = props;
  return (
    <div className="list-table-row">
        <div className="list-table-col">  <a><p className="mb-2" onClick={clickNavigate}>{name}</p></a></div>
        <div className="list-table-col pr-3 text-right"><$AmountLabel value={amount} /></div>    
        <div className="list-table-col pr-3 text-right"><$AmountLabel value={amount-balance} /></div>
        <div className="list-table-col text-right"><$AmountLabel value={balance} /></div>
    </div>
  );
};
