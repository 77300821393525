import { IBMDInitialState, IBMDListModel } from "us.collection/interfaces/BMD";

/**
 * To get bmd object with defined prop name
 * @param propName propoerty name of IBMDInitialState
 * @param data data from the API
 * @param isLoading if the API call is still on going
 * @param isError if there is any error
 * @returns a valid object with propName defined
 */
/*export const getBMDObject = <T>(
  propName: keyof IBMDInitialState,
  data: T,
  isLoading: boolean,
  isError: boolean
) => ({
  [propName]: <IBMDListModel<T>>{
    isLoading,
    data,
    isError,
  },
});*/

/**
 * clone an object of T type with defined property name
 * @param state current redux state of IBMDInitialState
 * @param propName property name of IBMDInitialState
 * @param data new data to be assigned
 * @param isLoading if the API call is still running
 * @param isError if there is any error
 * @returns new cloned object with the passed propName param
 */
export const getBMDObject = <T>(
  state: IBMDInitialState,
  propName: keyof IBMDInitialState,
  data: T,
  isLoading?: boolean,
  isError?: boolean
) =>
  Object.assign({}, state, {
    [propName]: <IBMDListModel<T>>{
      isLoading,
      data,
      isError,
    },
  });
