import React, { useEffect, useState } from "react";
import { StatusHistory } from "us.collection/reducers/Workflow/Interfaces";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { workflowAction } from "us.collection/actions";
import { RootState } from "us.helper/types";
import { getDefaultTabKey } from "./Functions";
import { WorkflowStatus } from "./Constants";
import {
  $Skeleton,
  $DateTimeLabel,
  $Menu,
  $TableTree,
  ITableTreeColumns,
} from "us.common/components";
import "./WorkflowStatusHistory.scss";

/**
 * @description - Workflow status history
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 10/07/2023
 */
const WorkflowStatusHistory: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const {
    getStatusHistory,
    getStatusProperties,
    resetList,
    statusProperties,
    statusHistory,
    workflowInfo,
    metaData,
  } = props;

  const { caseId } = metaData.data ?? {};
  const { nextDueDate } = workflowInfo?.data ?? {};
  const { data: historyList, isLoading: isHistoryLoading } =
    statusHistory ?? {};

  const [selectedTab, setSelectedTab] = useState<Array<string>>(["-1"]);

  useEffect(() => {
    getStatusHistory && getStatusHistory({ caseId });

    return () => {
      resetList && resetList({});
    };
  }, []);

  useEffect(() => {
    if (historyList && historyList.length > 0) {
      setSelectedTab(getDefaultTabKey(historyList));
    }
  }, [historyList]);

  const columns: ITableTreeColumns = [
    {
      title: t("US.COLLECTION.LAYOUT:CASE.PROPERTY"),
      dataIndex: "property",
      key: "property",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
      ellipsis: true,
      width: "50%",
    },
    {
      title: t("US.COLLECTION.LAYOUT:CASE.CURRENT_VALUE"),
      dataIndex: "value",
      key: "value",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
      ellipsis: true,
      width: "50%",
    },
  ];

  /**
   * Workflow history tab click
   * @param stackId - Workflow stack id
   */
  const handleTabClick = (stackId: number) => {
    setSelectedTab([stackId?.toString()]);
    getStatusProperties && getStatusProperties({ stackId });
  };

  return (
    <>
      <div className="wsh-layout">
        <div className="wsh-layout-side">
          <$Skeleton loading={isHistoryLoading} active paragraph={{ rows: 2 }}>
            <$Menu selectedKeys={selectedTab} className="wsh-ant-menu">
              {historyList?.map((status: StatusHistory) => {
                const {
                  stateName,
                  runDate,
                  stackId,
                  status: workflowStatus,
                } = status ?? {};
                return (
                  <$Menu.Item
                    key={stackId}
                    onClick={() => handleTabClick(stackId)}
                  >
                    <p>{stateName}</p>
                    {workflowStatus?.trim()?.toLowerCase() !==
                      WorkflowStatus.WAITING && (
                      <small>
                        <strong>
                          <$DateTimeLabel value={runDate} />
                        </strong>
                      </small>
                    )}
                  </$Menu.Item>
                );
              })}
            </$Menu>
          </$Skeleton>
        </div>
        <div className="wsh-layout-content">
          <div className="d-flex mb-4">
            <span>
              <strong className="text-truncate">
                {t("US.COLLECTION.LAYOUT:CASE.NEXT_UP_TIME")}
              </strong>
              <strong className="mx-1">:</strong>
            </span>
            {workflowInfo?.isLoading && (
              <$Skeleton.Input
                style={{ width: 200 }}
                active={true}
                size={"small"}
              />
            )}
            {!workflowInfo?.isLoading && nextDueDate && (
              <strong>
                <$DateTimeLabel value={nextDueDate} />
              </strong>
            )}
          </div>
          <$Skeleton
            loading={statusProperties.isLoading}
            active
            paragraph={{ rows: 2 }}
          >
            <$TableTree
              rowKey={({ key }: any) => {
                return key;
              }}
              onSort={(sortData, dataSource) => {
                return sortData(dataSource);
              }}
              onFilter={(searchData, dataSource) => {
                return searchData(dataSource);
              }}
              filterOnType={true}
              data={statusProperties.data}
              columns={columns}
              size="small"
              bordered
              pagination={{ defaultPageSize: 200 }}
              scroll={{ x: 650, y: "calc(100vh - 210px)" }}
              firstColSkipFilterProps={-1}
            />
          </$Skeleton>
        </div>
      </div>
    </>
  );
};

const { statusHistoryInit, statusProperties } = workflowAction;

const mapStateToProps = (state: RootState) => {
  const { Workflow, domainView } = state;
  const { metaData, workflow: workflowInfo } = domainView;
  const { statusHistory, statusProperties } = Workflow;
  return {
    statusHistory,
    statusProperties,
    workflowInfo,
    metaData,
  };
};

const mapDispatchToProps = {
  getStatusHistory: statusHistoryInit.get,
  getStatusProperties: statusProperties.get,
  resetList: statusHistoryInit.reset,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WorkflowStatusHistory);
