export enum FollowUpEntityType {
    CASE = "Case",
    INVOICE = "Invoice",
    CREDITOR = "Creditor",
    DEBTOR = "Debtor",
    PAYMENT = "Payment",
    SUBCASE = "SubCase"
  }

  export enum FollowUpUniqueCol {
    EX_CASE_NO = "Excaseno",
    CREDITOR_NO = "Creditorno",
    AR_NO = "Arno",
    PAYMENT_ID = "Paymentid",
    PAYMENT_NO = "Paymentno",
  }
