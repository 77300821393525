import { IFollowUpResult } from "us.collection.followup/interfaces";
import { FollowUpEntityType, FollowUpType } from "us.collection.followup/constants";

export const followUpResult: IFollowUpResult = {
  followupName: "",
  entityType: FollowUpEntityType.CASE,
  followupType: FollowUpType.DYNAMIC_FOLLOW_UP,
  followupHeaders: [],
  followupData: [],
};
