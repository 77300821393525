import React, { useEffect, useState } from "react";
import { IBMDDataType, IRootState } from "us.collection/interfaces";
import { DataTypeList } from "./Components";
import { AddEditDataType } from "./Components/AddEditDataType/AddEditDataType";
import { CurrentView } from "./Constants";
import { IVeiwDetails } from "./Interfaces";
import { BMDActions } from "us.collection/actions";
import { connect, ConnectedProps } from "react-redux";

const ManageDataType: React.FC<PropsFromRedux> = (props) => {
  const { getBMDDataTypes } = props;

  const [currentViewDetails, setcurrentViewDetails] = useState<IVeiwDetails>({
    currentView: CurrentView.List,
  });

  useEffect(() => {
    getBMDDataTypes && getBMDDataTypes({});
  }, []);

  const editBMDDataTypeEventHandler = (selectedBMDDataType: IBMDDataType) => {
    setcurrentViewDetails({
      currentView: CurrentView.Edit,
      selectedBMDDataType,
    });
  };

  return (
    <>
      {currentViewDetails.currentView === CurrentView.List && (
        <DataTypeList
          viewchangeEvent={(newView) => setcurrentViewDetails({ currentView: newView })}
          editBMDDataTypeEvent={editBMDDataTypeEventHandler}
        />
      )}
      {[CurrentView.Add, CurrentView.Edit].includes(currentViewDetails.currentView) && (
        <AddEditDataType
          currentViewDetails={currentViewDetails}
          viewchangeEvent={(newView) => setcurrentViewDetails({ currentView: newView })}
        />
      )}
    </>
  );
};

const { bmdDataTypes } = BMDActions;

const mapStateToProps = (state: IRootState) => {
  const { bmd } = state;
  const { drawerStatus, bmdDataTypes } = bmd;
  const { data, isLoading } = bmdDataTypes;
  return {
    dataTypes: data,
    dataTypesLoading: isLoading,
    drawerStatus,
  };
};

const mapDispatchToProps = {
  getBMDDataTypes: bmdDataTypes.get,
  changeDrawerStatus: bmdDataTypes.openDrawer,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ManageDataType);
