import Constants from 'us.collection.case/constants';
import { IAPIAction } from 'us.collection/interfaces';
import { IObjectionAndAgreementsAction } from './Interfaces';

const { ACTIONS } = Constants;
const { ObjectionAndAgreements } = ACTIONS;

const {
	CANCEL_OBJECTION_SUCCESS,
	CANCEL_OBJECTION_FAIL,
	CANCEL_OBJECTION_START,

	CLAIM_OF_PAYMENT_START,
	CLAIM_OF_PAYMENT_SUCCESS,
	CLAIM_OF_PAYMENT_FAIL,

	GET_COP_INITIAL_VALUE,
	GET_INVOICES_START,
	GET_INVOICES_SUCCESS,
	GET_INVOICES_FAIL,

	DEBTOR_OBJECTION_START,
	DEBTOR_OBJECTION_SUCCESS,
	DEBTOR_OBJECTION_FAIL,

	UPDATE_DRAWER,
	GET_DO_INITIAL_VALUE,

	GET_OBJECTION_PROFILE_START,
	GET_OBJECTION_PROFILE_SUCCESS,
	GET_OBJECTION_PROFILE_FAIL,

	GET_KID_LIST,
	GET_KID_LIST_SUCCESS,
	GET_KID_LIST_FAIL,
} = ObjectionAndAgreements;

export const ObjectionAndAgreementsAction: Readonly<
	IObjectionAndAgreementsAction & IAPIAction
> = {
	Objection: {
		cancel: (caseNo, metaData) => ({
			type: CANCEL_OBJECTION_START,
			payload: {
				data: { caseNo, metaData },
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: CANCEL_OBJECTION_SUCCESS,
			payload: {
				data: data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: CANCEL_OBJECTION_FAIL,
			payload: {
				error: error,
				isLoading: false,
			},
		}),
	},
	claimOfPayment: {
		save: (data) => ({
			type: CLAIM_OF_PAYMENT_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: CLAIM_OF_PAYMENT_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: CLAIM_OF_PAYMENT_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		get: (data) => ({
			type: GET_COP_INITIAL_VALUE,
			payload: {
				data,
				isLoading: true,
			},
		}),
	},
	invoices: {
		get: (data) => ({
			type: GET_INVOICES_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: GET_INVOICES_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: GET_INVOICES_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	kidList: {
		get: (data) => ({
			type: GET_KID_LIST,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: GET_KID_LIST_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: GET_KID_LIST_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	debtorObjection: {
		save: (data) => ({
			type: DEBTOR_OBJECTION_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: DEBTOR_OBJECTION_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: DEBTOR_OBJECTION_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		get: (data) => ({
			type: GET_DO_INITIAL_VALUE,
			payload: {
				data,
				isLoading: true,
			},
		}),
		openDrawer: (data) => ({
			type: UPDATE_DRAWER,
			payload: {
				data,
				isLoading: true,
			},
		}),
	},
	objectionProfile: {
		get: (data) => ({
			type: GET_OBJECTION_PROFILE_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: GET_OBJECTION_PROFILE_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: GET_OBJECTION_PROFILE_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
};
