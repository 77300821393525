export enum partPaymentType {
  NO_OF_INSTALLMENT = 'NoOfInstallment',
  INSTALLMENT_AMOUNT = 'InstallmentAmount',
}

export enum PartPaymentGenerateType {
  AMOUNT = 'amount',
  INSTALLMENT = 'installment',
}
export enum PartPaymentcomunicationMethod {
  SMS = 'sms',
  EMAIL = 'email',
}

