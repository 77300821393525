import moment from "moment";
import { EntityType, TABLE_ACTIONS } from "us.collection/components/BMD/Constants";
import { IBMDChangeHistory, IBMDPropertyDetails } from "us.collection/interfaces";
import { IReturnAction } from "us.common/interfaces";
import { IAddBMDProperty } from "../Interfaces";
import { DeleteBMDPropertyHistoryDetailsObject } from "../Repository";

/**
 * @function
 * @description - Handler function for the table function clicking
 * @param {TABLE_ACTIONS} eventType - the type of the event executed
 * @param {IBMDChangeHistory} record - the recored/row of the table applying the execution
 * @param {*} setValues - formik function to set values for the field
 * @param {((<T>(data: object | T[]) => IReturnAction<T>) | undefined)} bmdPropertyHistory - react action to delete the property
 * @param {({
 *     entityId: string | undefined;
 *     entityType: EntityType;
 *   })} routeDetails - Route details from Add BMD 
 * @param {IBMDPropertyDetails} bmdPropertyDetails - details of property details
 */
 export const tableClickEventHandler = (
  eventType: TABLE_ACTIONS,
  record: IBMDChangeHistory,
  setValues: any,
  bmdPropertyHistory: (<T>(data: object | T[]) => IReturnAction<T>) | undefined,
  routeDetails: {
    entityId: string | undefined;
    entityType: EntityType;
  },
  bmdPropertyDetails: IBMDPropertyDetails
) => {
  switch (eventType) {
    case TABLE_ACTIONS.DELETE:
      const { id, isNew } = record;
      if (id > 0) {
        bmdPropertyHistory &&
          bmdPropertyHistory(
            DeleteBMDPropertyHistoryDetailsObject.call({
              ...routeDetails,
              ...bmdPropertyDetails,
              id,
            })
          );
      } else if (isNew) {
        let editingHistoryitem: IBMDChangeHistory;
        setValues((preVal: IAddBMDProperty) => {
          const chagnedHistoryValues = preVal.changedHistory?.flatMap((hisotryItem) => {
            if (hisotryItem.id === record.id) {
              editingHistoryitem = hisotryItem;
              return [];
            } else {
              return [hisotryItem];
            }
          });
          return {
            ...preVal,
            changedHistory: chagnedHistoryValues,
          };
        });
      }

      break;
    case TABLE_ACTIONS.EDIT:
      let editingHistoryitem: IBMDChangeHistory;
      setValues((preVal: IAddBMDProperty) => {
        const chagnedHistoryValues = preVal.changedHistory?.flatMap((hisotryItem) => {
          if (hisotryItem.id === record.id) {
            editingHistoryitem = hisotryItem;
            return [];
          } else {
            return [hisotryItem];
          }
        });
        return {
          ...preVal,
          changedHistory: chagnedHistoryValues,
          comment: editingHistoryitem.comment,
          value: editingHistoryitem.value,
          fromDate: moment(editingHistoryitem.dateTime),
        };
      });
      break;
    default:
      break;
  }
};
