import { Moment } from "moment";
import { ICaseInformation } from "us.collection.case/reducers/CaseInformation/Interfaces";

export const disabledDate = (
  current: Moment,
  dateField: keyof ICaseInformation,
  dueDate: Moment | undefined
) => {
  if (dueDate && dateField === "obsoleteDate"){
    return current && current < dueDate.endOf("day");
  }else{
      return false;
  }
    
};
