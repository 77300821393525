export enum DomainMessageType {
  NOTE = "Note",
  CP_NOTE = "CPNote",
  SMS = "SMS",
  EMAIL = "Email",
  LETTERS = "Letters",
}
export enum DocumentType {
  DEBTOR = "Debtor",
  CREDITOR = "Creditor",
  OTHERPARTY = "OtherParty",
}
export enum DateFormat {
  REQ = "YYYY-MM-DD",
  CASE_DUE_DATE_FORMAT = "DD-MM-YYYY",
}
export enum EntityType {
  SUBCASE = "SubCase",
  CASE = "Case",
}
