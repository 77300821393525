import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FieldArray } from "formik";
import Common from "us.common";
import moment from "moment";
import { IntlDate } from "us.common/functions";
import {
  PlusOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
} from "us.icons";

const {
  $Input,
  $Button,
  $Popconfirm,
  $Skeleton,
  $Table,
  $Popover,
  $TimePicker,
  $Switch,
  $Collapse,
} = Common.Components;

interface INonSmsDays {
  nonSmsDays: Array<any>;
  smsAllowedTime: Array<any>;
  isFetching?: boolean;
  currentLanguage: string;
  onSelectDate?: any;
  addNewNonProductionDay?: any;
  addSmsTimeSlot?: any;
  removeSmsTimeSlot?: any;
  isImportCalendar?: boolean;
  removeNonProductionDay?: any;
}

const NonSmsDays: React.FC<INonSmsDays> = (props) => {
  const { t } = useTranslation(["US.COLLECTION.ADMIN", "US.COLLECTION.COMMON"]);
  const {
    nonSmsDays,
    smsAllowedTime,
    isFetching,
    currentLanguage,
    onSelectDate,
    addNewNonProductionDay,
    addSmsTimeSlot,
    removeSmsTimeSlot,
    isImportCalendar,
    removeNonProductionDay,
  } = props;

  // Popover actions in table
  const content = (record: any, index: any) => (
    <div className="table-more-content">
      <div className="d-flex  flex-row more-item">
        <div className="p-1">
          <EditOutlined />
        </div>
        <div className="p-1" onClick={() => onSelectDate(record, index)}>
          {t("US.COLLECTION.COMMON:COMMON.EDIT")}
        </div>
      </div>

      <$Popconfirm
        title={t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.POPCONFIRM2")}
        onConfirm={() => removeNonProductionDay(record, index)}
      >
        <div className="d-flex mb-2 flex-row more-item">
          <div className="p-1">
            <DeleteOutlined />
          </div>
          <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.DELETE")}</div>
        </div>
      </$Popconfirm>
    </div>
  );

  const tableColumns: any = [
    {
      title: "",
      key: "more",
      dataIndex: "more",
      width: "50px",
      render: (text: any, record: any, index: number) => {
        return (
          !record.isFixedDate &&
          !isImportCalendar && (
            <$Popover
              placement="rightTop"
              content={content(record, index)}
              destroyTooltipOnHide
            >
              <$Button icon={<MoreOutlined />} size="small" />
            </$Popover>
          )
        );
      },
    },
    {
      title: t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.DATE"),
      dataIndex: "date",
      key: "date",
      width: "200px",
      render: (text: any, record: any, index: any) => {
        return (
          <span>
            {IntlDate(text, currentLanguage, Common.Constants.dateParams.DATE)}
          </span>
        );
      },
    },
    {
      title: t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.REASON"),
      dataIndex: "reason",
      key: "reason",
      render: (text: any, record: any, index: any) => {
        return <span>{text}</span>;
      },
    },
  ];

  const smstableColumns = [
    {
      title: t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.SELECTEDDAYS"),
      dataIndex: "day",
      key: "day",
      width: "300px",
      //className: 'pt-2',
      render: (text: any, record: any, index: any) => {
        return (
          <div className="d-flex align-items-top">
            <div className="mr-3">
              <$Switch
                name={`smsAllowedTime[${index}].isEnable`}
                size="small"
                checked={smsAllowedTime[index].isEnable}
                disabled={isImportCalendar}
              />
            </div>
            <div className="pt-1">{text}</div>
            <div className="ml-auto pr-5">
              <$Button
                type="link"
                block
                disabled={
                  !smsAllowedTime[index].isEnable ||
                  smsAllowedTime[index].timeSlots.length >= 5
                }
                onClick={() => addSmsTimeSlot(index)}
              >
                {!isImportCalendar && (
                  <small>
                    <PlusOutlined />{" "}
                    {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.ADDTIMESLOT")}
                  </small>
                )}
              </$Button>
            </div>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div className="d-flex align-items-center">
            <div style={{ width: "220px", marginRight: "1rem" }}>
              {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.STARTTIME")}
            </div>
            <div style={{ width: "220px", marginRight: "1rem" }}>
              {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.ENDTIME")}
            </div>
          </div>
        );
      },
      dataIndex: "timeSlots",
      key: "timeSlots",
      className: "pt-2",
      render: (text: any, record: any, index: any) => {
        return (
          <div>
            <FieldArray
              name={`smsAllowedTime${index}`}
              render={(arrayHelpers: any) => (
                <>
                  {record?.timeSlots?.map((slot: any, slotIndex: number) => {
                    return (
                      <div
                        className="d-flex align-items-top mb-1"
                        key={`${index}.${slotIndex}`}
                      >
                        <div
                          style={{ width: "220px", marginRight: "1rem" }}
                          key={`${index}.${slotIndex}startCont`}
                        >
                          <div key={`${index}.${slotIndex}startOuter`}>
                            {!isImportCalendar && (
                              <$TimePicker
                                // use12Hours
                                name={`smsAllowedTime[${index}].timeSlots[${slotIndex}]['startTime']`}
                                currentTimeFormat="HH:mm"
                                key={`${index}.${slotIndex}start`}
                                allowClear={false}
                                disabled={!smsAllowedTime[index].isEnable}
                                open={isImportCalendar && false}
                                style={{width:'100%'}}
                              />
                            )}
                            {isImportCalendar && (
                              <$TimePicker
                                // use12Hours
                                name={`smsAllowedTime[${index}].timeSlots[${slotIndex}]['startTimeImport']`}
                                value={
                                  smsAllowedTime[index].timeSlots[slotIndex]
                                    .startTime
                                }
                                currentTimeFormat="HH:mm"
                                key={`${index}.${slotIndex}start`}
                                allowClear={false}
                                disabled={!smsAllowedTime[index].isEnable}
                                open={isImportCalendar && false}
                                style={{width:'100%'}}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          style={{ width: "220px", marginRight: "1rem" }}
                          key={`${index}.${slotIndex}endCont`}
                        >
                          <div key={`${index}.${slotIndex}endOuter`}>
                            {!isImportCalendar && (
                              <$TimePicker
                                // use12Hours
                                name={`smsAllowedTime[${index}].timeSlots[${slotIndex}]['endTime']`}
                                currentTimeFormat="HH:mm"
                                key={`${index}.${slotIndex}end`}
                                allowClear={false}
                                disabled={!smsAllowedTime[index].isEnable}
                                open={isImportCalendar && false}
                                style={{width:'100%'}}
                              />
                            )}
                            {isImportCalendar && (
                              <$TimePicker
                                // use12Hours
                                name={`smsAllowedTime[${index}].timeSlots[${slotIndex}]['endTimeImport']`}
                                value={
                                  smsAllowedTime[index].timeSlots[slotIndex]
                                    .endTime
                                }
                                currentTimeFormat="HH:mm"
                                key={`${index}.${slotIndex}end`}
                                allowClear={false}
                                disabled={!smsAllowedTime[index].isEnable}
                                open={isImportCalendar && false}
                                style={{width:'100%'}}
                              />
                            )}
                          </div>
                        </div>
                        {!isImportCalendar && (
                          <div className="pt-1">
                            <$Button
                              name={t("US.COLLECTION.COMMON:COMMON.DELETE")}
                              size="small"
                              type="primary"
                              danger
                              disabled={
                                smsAllowedTime[index].timeSlots.length <= 1
                              }
                              icon={<DeleteOutlined />}
                              onClick={() =>
                                removeSmsTimeSlot(index, slotIndex)
                              }
                              key={`${index}.${slotIndex}del`}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <$Collapse defaultActiveKey={[]} ghost>
        <$Collapse.Panel
          header={t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.SMSALLOWEDTIME")}
          key="1"
        >
          <$Skeleton loading={isFetching} active paragraph={{ rows: 2 }}>
            <$Table
              rowKey="dayId"
              className="sms-time-table ml-3"
              dataSource={smsAllowedTime}
              //size="small"
              columns={smstableColumns}
              pagination={false}
              size="small"
            />
          </$Skeleton>
        </$Collapse.Panel>

        <$Collapse.Panel
          header={t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NOSMSDAYS")}
          key="2"
        >
          {!isImportCalendar && (
            <div className="ml-3">
              <$Button
                className="ml-2"
                type="dashed"
                size="small"
                icon={<PlusOutlined />}
                onClick={() =>
                  addNewNonProductionDay({
                    id: -1,
                    date: moment.utc(""),
                    reason: "",
                    criteria: [
                      {
                        type: "NonBanking",
                        value: 0,
                      },
                      {
                        type: "NonSms",
                        value: 1,
                      },
                      {
                        type: "NonPrinting",
                        value: 0,
                      },
                      {
                        type: "NonWorkflow",
                        value: 0,
                      },
                    ],
                    isFixedDate: false,
                  })
                }
              >
                {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.ADDNEW")}
              </$Button>
            </div>
          )}

          <$Skeleton loading={isFetching} active paragraph={{ rows: 2 }}>
            <$Table
              rowKey={isImportCalendar ? "id" : "key"}
              className="ml-3 mt-2"
              dataSource={nonSmsDays}
              //size="small"
              columns={tableColumns}
              pagination={false}
              onRow={(record: any, rowIndex: any) => {
                return {
                  onDoubleClick: (event: any) => {
                    if (!record.isFixedDate && !isImportCalendar) {
                      onSelectDate(record, rowIndex);
                    }
                  },
                };
              }}
            />
          </$Skeleton>
        </$Collapse.Panel>
      </$Collapse>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NonSmsDays);
