import ServiceConfig from "service.config.js";
import { versionedHttpCollection } from "us.helper/http/collection";

export const DebtorDetail = {
  save: async (params: any): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.post(
          "arservice",
          `ars/cases/${params.caseId}/changedebtor`,
          params,
          ServiceConfig()[`arservice`]["ars.changedebtor"]
        );
        if (status === 200) {
          resolve({ data, status });
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
};
