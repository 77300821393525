import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Actions from 'us.collection.case/actions';
import { matchPath } from 'react-router-dom';
import { IOtherParty } from 'us.collection.case/components/Interfaces/OtherParty/interfaces';
import {
	PlusOutlined,
	MoreOutlined,
	EditOutlined,
	DeleteOutlined,
} from 'us.icons';
import Common from 'us.common';
import { AddOtherParty } from '../Add';
import EditOtherParty from '../Edit/Home';
import { useHistory, useLocation } from 'react-router-dom';
import { INavigationData, IRootState } from 'us.collection/interfaces';
import { OtherPartyTypes } from '../Constants';
import { IOnFilter, IOnSort } from 'us.common/components/antd/TableTree';
import { appInsights } from 'us.helper';
import { filterOtherPartiesForCreditorManagement } from 'us.collection.case/components/OtherParty/Functions';
import { isSubCaseTransferredToCase } from 'us.common/functions';

const {
	$Tag,
	$Popover,
	$Popconfirm,
	$Button,
	$Divider,
	$PageHeader,
	$Affix,
	$Drawer,
	$Skeleton,
	$Message,
	$TableTree,
} = Common.Components;

const { OtherPartyAction } = Actions;
const { otherParty, otherParties, updateState, postalCode } = OtherPartyAction;

const OtherParty: React.FC<IOtherParty> = (props) => {
	const { t } = useTranslation();
	const { state: locationState, pathname } = useLocation();
	const { push, location } = useHistory();

	const [selectedOtherParty, setSelectedOtherParty] = useState({});
	const [popVisible, setPopVisibile] = useState<number>(-1);

	const {
		otherParties,
		getOtherParies,
		metaData,
		addDrawer,
		manageAddDrawer,
		updateActiveState,
		deleteParty,
		resetPostalDetails,
		isDrawer,
		isCreditorManagement,
		nextCreditorNo,
		newEntityData,
	} = props;

	const { caseId, entityType } = metaData.data ?? {}; //creditorId
	const state = locationState as INavigationData;

	useEffect(() => {
		if (entityType) {
			const { id, type } = getCaseNumberAndType();
			getOtherParies({
				caseId: id,
				type,
			});
		}
	}, [entityType]);

	const getCaseNumberAndType = () => {
		const { params }: any = matchPath(pathname, {
			path: '/:type/:Id?/:module?',
			exact: false,
			strict: false,
		});
		let id = params['Id'];
		let caseType = params['type'];
		let type;
		switch (caseType) {
			case 'case':
				type = entityType;
				id = caseId;
				break;
			case 'ar':
				type = 'A';
				break;
			case 'creditor':
				type = 'Creditor';
				id = state?.creditorId;
				break;
			case 'bureau':
				type = 'bureau';
				id = newEntityData?.entRoleId;
				break;
			case 'creditor-group':
				type = 'creditor';
				id = newEntityData?.entRoleId;
				break;
			default:
				type = 'S';
				break;
		}
		return {
			type,
			id,
		};
	};
	/**
	 * Open other party add drawer
	 * @param drawerType Openning drawer type, this can be `ADD` or `Edit`
	 * @param drwerData data passing to drawer
	 */
	const OtherPartyHandler = (drawerType: string, drwerData?: any) => {
		if (drawerType === 'ADD') {
			manageAddDrawer({
				title:
					isCreditorManagement || isDrawer
						? 'US.COLLECTION.CASE:OTHERPARTY.NEW_CONTACT_PERSON'
						: 'US.COLLECTION.CASE:OTHERPARTY.NEWOTHERPARTY',
				visible: true,
			});
		} else {
			manageAddDrawer({
				title:
					isCreditorManagement || isDrawer
						? 'US.COLLECTION.CASE:OTHERPARTY.EDIT_CONTACT_PERSON'
						: 'US.COLLECTION.CASE:OTHERPARTY.EDITOTHERPARTY',
				visible: true,
			});
			setSelectedOtherParty(drwerData);
		}
	};

	/**
	 * Claose drawer
	 */
	const OtherPartyClose = () => {
		if (resetPostalDetails) {
			resetPostalDetails({});
		}
		manageAddDrawer({ title: '', visible: false });
		setSelectedOtherParty({});
	};

	const onMinimize = () => {
		const { params }: any = matchPath(pathname, {
			path: '/:type/:Id/:module?',
			exact: false,
			strict: false,
		});
		let id = params['Id'];
		let type = params['type'];
		push({ ...location, pathname: `/${type}/${id}` });
	};

	/**
	 * toggle active status
	 * @param {*} record - single record of otherparty table
	 */
	const activeStateChange = (record: any) => {
		updateActiveState({
			caseId: parseInt(getCaseNumberAndType().id),
			type: getCaseNumberAndType().type,
			entRoleId: record?.entRoleId,
			activeStatus: !record?.activeStatus,
		});
	};

	const deleteOtherParty = (record: any) => {
		if (!record?.activeStatus) {
			const { id, type } = getCaseNumberAndType();
			deleteParty({
				...record,
				entityType: type,
				entityId: parseInt(id),
			});
		} else {
			$Message.error(
				t(
					'US.COLLECTION.CASE:OTHERPARTY.DELETING_AN_ACTIVE_OTHER_PARTY_IS_IMPOSSIBLE'
				)
			);
		}
	};

	const formatPersonNo = (value: any) => {
		value = value.replace(/ /g, '');
		try {
			if (
				value?.length === 6 ||
				(value?.length >= 9 && value?.length <= 11)
			) {
				return value.match(/.{1,6}/g).join(' ');
			}
			return value;
		} catch (error) {
			appInsights.trackException(
				`Error in formatPersonNo function in OtherParty. - ${error}`
			);
			return value;
		}
	};
	const formatOrganizationNo = (value: any) => {
		value = value.replace(/ /g, '');
		try {
			if (value?.length === 9) {
				return value.match(/.{1,3}/g).join(' ');
			}
			return value;
		} catch (error) {
			appInsights.trackException(
				`Error in formatOrganizationNo function in OtherParty. - ${error}`
			);
			return value;
		}
	};

	// Popover actions in table
	const content = (record: any) => (
		<div className='table-more-content'>
			<div className='d-flex  flex-row more-item'>
				<div className='p-1'>
					<EditOutlined />
				</div>
				<div
					className='p-1'
					onClick={() => {
						if (
							metaData.data &&
							!isSubCaseTransferredToCase(
								metaData.data
							)
						) {
							setPopVisibile(-1);
							OtherPartyHandler(
								'EDIT',
								record
							);
						}
					}}>
					{t('US.COLLECTION.COMMON:COMMON.EDIT')}
				</div>
			</div>

			<$Popconfirm
				title={t(
					'US.COLLECTION.CASE:OTHERPARTY.DELETECONFIRM'
				)}
				onConfirm={() => {
					if (
						metaData.data &&
						!isSubCaseTransferredToCase(
							metaData.data
						)
					) {
						setPopVisibile(-1);
						deleteOtherParty(record);
					}
				}}
				okText={t('US.COLLECTION.COMMON:COMMON.YES')}
				cancelText={t(
					'US.COLLECTION.COMMON:COMMON.NO'
				)}>
				<div className='d-flex mb-2 flex-row more-item text-error'>
					<div className='p-1'>
						<DeleteOutlined />
					</div>
					<div className='p-1'>
						{t(
							'US.COLLECTION.COMMON:COMMON.DELETE'
						)}
					</div>
				</div>
			</$Popconfirm>
			<$Divider className='popup-divider my-2' />
			<$Popconfirm
				title={`${t(
					'US.COLLECTION.CASE:OTHERPARTY.ACTIVESTATECONFIRM'
				)} ${
					record.activeStatus
						? t(
								'US.COLLECTION.CASE:OTHERPARTY.DEACTIVATE'
						  )
						: t(
								'US.COLLECTION.CASE:OTHERPARTY.ACTIVATE'
						  )
				}?`}
				onConfirm={() => {
					setPopVisibile(-1);
					activeStateChange(record);
				}}
				okText={t('US.COLLECTION.COMMON:COMMON.YES')}
				cancelText={t(
					'US.COLLECTION.COMMON:COMMON.NO'
				)}>
				<div className='d-flex flex-column'>
					<$Button
						disabled={
							record.roleType ===
							'Employer'
						}
						type='primary'
						className='mb-1'
						size='small'>
						{record.activeStatus
							? t(
									'US.COLLECTION.CASE:OTHERPARTY.DEACTIVATE'
							  )
							: t(
									'US.COLLECTION.CASE:OTHERPARTY.ACTIVATE'
							  )}
					</$Button>
				</div>
			</$Popconfirm>
		</div>
	);

	const columns: any = [
		{
			title: '',
			key: 'more',
			dataIndex: 'more',
			width: '50px',
			customRenderChild: (text: any, record: any) => {
				return (
					<$Popover
						placement='rightTop'
						content={content(record)}
						destroyTooltipOnHide
						trigger='click'
						visible={
							record.entityId ==
							popVisible
						}
						onVisibleChange={(
							visbile: boolean
						) =>
							setPopVisibile(
								visbile
									? record.entityId
									: -1
							)
						}>
						<$Button
							icon={<MoreOutlined />}
							size='small'
							disabled={
								metaData.data &&
								isSubCaseTransferredToCase(
									metaData.data
								)
							}
						/>
					</$Popover>
				);
			},
		},
		{
			title: t('US.COLLECTION.CASE:OTHERPARTY.NAME'),
			dataIndex: 'name',
			key: 'name',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
		{
			title: t('US.COLLECTION.CASE:OTHERPARTY.STATUS'),
			dataIndex: 'activeStatusString',
			key: 'activeStatusString',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: true,
			customRenderChild: (text: any, record: any) => {
				return (
					<div>
						{record.activeStatus ? (
							<$Tag
								className='tag-status-active'
								style={{
									width: '60px',
									textAlign: 'center',
								}}>
								ACTIVE
							</$Tag>
						) : (
							<$Tag
								className='tag-status-deactive'
								style={{
									width: '60px',
									textAlign: 'center',
								}}>
								INACTIVE
							</$Tag>
						)}
					</div>
				);
			},
		},
		{
			title: t('US.COLLECTION.CASE:OTHERPARTY.TYPE'),
			dataIndex: 'roleType',
			key: 'roleType',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
		{
			title: t('US.COLLECTION.CASE:OTHERPARTY.NIN'),
			key: 'nin',
			dataIndex: 'nin',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			customRenderChild: (text: any, record: any) => {
				return (
					<div>
						{record.otherPartyType ===
						OtherPartyTypes.PERSON
							? formatPersonNo(
									record.nin
							  )
							: formatOrganizationNo(
									record.nin
							  )}
					</div>
				);
			},
		},
		{
			title: t('US.COLLECTION.CASE:OTHERPARTY.REFERENCE'),
			key: 'reference',
			dataIndex: 'reference',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
		{
			title: t('US.COLLECTION.CASE:OTHERPARTY.ADDRESS'),
			key: 'address',
			dataIndex: 'address',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
	];

	const handleSort: IOnSort = (sortData, dataSource) => {
		try {
			return sortData(dataSource);
		} catch (error) {
			appInsights.trackException(
				`Error in sort Other Party list - ${error}`
			);
			return dataSource;
		}
	};

	const handleFilter: IOnFilter = (searchData, dataSource) => {
		try {
			return searchData(dataSource);
		} catch (error) {
			appInsights.trackException(
				`Error in filter Other Party list - ${error}`
			);
			return dataSource;
		}
	};

	return (
		<Formik initialValues={{}} onSubmit={() => {}}>
			<div className='space-content'>
				<$Affix offsetTop={80}>
					{!(
						isCreditorManagement || isDrawer
					) && (
						<div className='page-header header-border'>
							<div className='d-flex flex-row align-items-center justify-content-between'>
								<div className='d-flex align-items-center'>
									<$PageHeader
										className='px-0'
										title={t(
											'US.COLLECTION.CASE:OTHERPARTY.OTHERPARTY'
										)}
										onBack={() =>
											onMinimize()
										}
										data-testid='otherparty-back'
									/>
									<$Divider
										className='bui-devider'
										type='vertical'
									/>
									<$Skeleton
										loading={
											otherParties?.isFetching
										}
										active
										paragraph={{
											rows: 0,
										}}>
										<$Button
											onClick={() =>
												OtherPartyHandler(
													'ADD'
												)
											}
											type='dashed'
											size='small'
											icon={
												<PlusOutlined />
											}
											data-testid='otherparty-new'
											disabled={
												metaData.data &&
												isSubCaseTransferredToCase(
													metaData.data
												)
											}>
											{t(
												'US.COLLECTION.CASE:OTHERPARTY.NEWOTHERPARTY'
											)}
										</$Button>
									</$Skeleton>
								</div>
							</div>
						</div>
					)}
					{(isCreditorManagement || isDrawer) && (
						<$Skeleton
							loading={
								otherParties?.isFetching
							}
							active
							paragraph={{ rows: 0 }}>
							<$Button
								onClick={() =>
									OtherPartyHandler(
										'ADD'
									)
								}
								type='dashed'
								size='small'
								icon={
									<PlusOutlined />
								}
								data-testid='contact-person-new'>
								{t(
									'US.COLLECTION.CASE:OTHERPARTY.NEW_CONTACT_PERSON'
								)}
							</$Button>
						</$Skeleton>
					)}
				</$Affix>

				<$Skeleton
					loading={otherParties?.isFetching}
					active
					paragraph={{ rows: 2 }}>
					<$TableTree
						rowKey='entityId'
						data={
							!isDrawer &&
							isCreditorManagement
								? filterOtherPartiesForCreditorManagement(
										otherParties
								  )
								: otherParties.data
						}
						size='small'
						className='mt-3'
						onSort={handleSort}
						onFilter={handleFilter}
						filterOnType={true}
						resetOnSourceChange={true}
						bordered
						pagination={{
							defaultPageSize: 15,
						}}
						scroll={{ x: 1200 }}
						columns={columns}
						onRow={(record: any) => {
							return {
								onDoubleClick:
									() => {
										OtherPartyHandler(
											'EDIT',
											record
										);
									},
							};
						}}
					/>
				</$Skeleton>

				<$Drawer
					title={t(addDrawer.title)}
					width={
						addDrawer.title ===
							'US.COLLECTION.CASE:OTHERPARTY.NEWOTHERPARTY' ||
						addDrawer.title ===
							'US.COLLECTION.CASE:OTHERPARTY.NEW_CONTACT_PERSON'
							? 900
							: 'auto'
					}
					visible={addDrawer.visible}
					onClose={OtherPartyClose}
					destroyOnClose
					className='other-party-edit'>
					{(addDrawer.title ===
						'US.COLLECTION.CASE:OTHERPARTY.NEWOTHERPARTY' ||
						addDrawer.title ===
							'US.COLLECTION.CASE:OTHERPARTY.NEW_CONTACT_PERSON') && (
						<AddOtherParty
							onClose={
								OtherPartyClose
							}
							{...props}
						/>
					)}
					{!(
						addDrawer.title ===
							'US.COLLECTION.CASE:OTHERPARTY.NEWOTHERPARTY' ||
						addDrawer.title ===
							'US.COLLECTION.CASE:OTHERPARTY.NEW_CONTACT_PERSON'
					) && (
						<div className='mt-n3'>
							<EditOtherParty
								selectedOtherParty={
									selectedOtherParty
								}
								{...props}
							/>
						</div>
					)}
				</$Drawer>
			</div>
		</Formik>
	);
};

const mapStateToProps = (state: IRootState) => {
	const { otherParty, common, domainView, manageCreditors } = state;
	const { otherParties, addDrawer } = otherParty;
	const { currentDateFormat, currentCurrency, currentLanguage } = common;
	const { metaData } = domainView;
	const { nextCreditorNo, newEntityData } = manageCreditors;
	return {
		currentDateFormat,
		currentCurrency,
		currentLanguage,
		otherParties,
		metaData,
		addDrawer,
		nextCreditorNo,
		newEntityData,
	};
};
const mapDispatchToProps = {
	getOtherParies: otherParties.get,
	manageAddDrawer: otherParty.openDrawer,
	updateActiveState: updateState.update,
	deleteParty: otherParty.delete,
	resetPostalDetails: postalCode.reset,
};
export default connect(mapStateToProps, mapDispatchToProps)(OtherParty);
