import {
  TransactionNavigate,
  WorkflowState,
} from "us.collection.case/constants";
import { ICase } from "us.collection.case/reducers/Dashboard/Interfaces/IAccountSummeryWidget";
import { appInsights } from "us.helper";

export const getColorForWorkFlowState = (state: string): string => {
  switch (state) {
    case WorkflowState.ADDRESS_UNKNOWN:
    case WorkflowState.BANCRUPTCY:
    case WorkflowState.CONFORMATION:
    case WorkflowState.CORE_WORKFLOW:
    case WorkflowState.CREDIT_CHECK:
    case WorkflowState.CREDITTOR_BO:
    case WorkflowState.CREDITTOR_DW:
    case WorkflowState.CREDITTOR_REMINDER:
    case WorkflowState.CREDITTOR_RESPOND:
    case WorkflowState.DEBTSETTLEMENT:
    case WorkflowState.ERROR:
    case WorkflowState.HIGH_BALANCE_CAMPAIGN_COMPLETED:
    case WorkflowState.HIGHER_BALANCE_SURVEILLANCE:
    case WorkflowState.INITIALIZE:
    case WorkflowState.INITILIZING:
    case WorkflowState.KEEP_WAITING:
    case WorkflowState.LOW_BALANCE_CAMPAIGN_COMPLETED:
    case WorkflowState.LOW_BALANCE_SURVEILLANCE:
    case WorkflowState.MINOR:
    case WorkflowState.NAGGING:
    case WorkflowState.OBJECTED_CLAIM:
    case WorkflowState.OBJECTION:
    case WorkflowState.OVERVAKET:
    case WorkflowState.PART_PAYMENT:
    case WorkflowState.PAYMENT_AGREEMENT:
    case WorkflowState.QUARANTINE:
    case WorkflowState.REMINDER:
    case WorkflowState.REST_REMINDER:
    case WorkflowState.SALARY_DEDUCTION:
    case WorkflowState.START_SURVEILLANCE:
    case WorkflowState.SUBCASE_OBJECTED_CLAIM:
    case WorkflowState.SURVEILLANCE:
    case WorkflowState.SUV_OBJECTION_STATE:
    case WorkflowState.SUV_PART_PAYMENT:
    case WorkflowState.SUV_UNKNOWN:
    case WorkflowState.TRANSFER_TO_CASE:
    case WorkflowState.UNKNOWN:
    case WorkflowState.WAITING:
      return "open";
    case WorkflowState.CLOSE:
    case WorkflowState.CLOSED:
    case WorkflowState.SUV_CLOSE_CASE:
      return "close";
    case WorkflowState.ENFORCEMENT:
    case WorkflowState.LEGAL:
    case WorkflowState.LEGAL_OVERVAKET:
    case WorkflowState.LEGAL_COLLECTION:
    case WorkflowState.LEGAL_SURVEILLANCE:
      return "court";
    case WorkflowState.FREEZE:
    case WorkflowState.MANUAL:
      return "freeze";
    case WorkflowState.SENTENCE_RECEIVED:
      return "sentence";
    default:
      return "open";
  }
};

export const getTransactionType = (type: string) => {
  switch (type) {
    case TransactionNavigate.MAIN_AMOUNT:
      return TransactionNavigate.IN;
    case TransactionNavigate.COLLECTION_FEE:
      return TransactionNavigate.CF;
    case TransactionNavigate.COURT_FEE:
      return TransactionNavigate.COURT_FEE;
    case TransactionNavigate.CASE_COST:
      return TransactionNavigate.CASE_COST;
    case TransactionNavigate.OTHER_COST:
      return TransactionNavigate.OTHER_COST;
    case TransactionNavigate.TOTAL:
      return TransactionNavigate.ALL;
    default:
      return TransactionNavigate.ALL;
  }
};

export const sumCaseAmount = (accCase: ICase | undefined): number => {
  try {
    const {
      mainAmount = 0,
      collectionFee = 0,
      caseCosts = 0,
      courtFee = 0,
      otherAmount = 0,
      interestCost = 0,
      interestMainAmount = 0,
    } = accCase ?? {};

    return (
      mainAmount +
      collectionFee +
      courtFee +
      caseCosts +
      otherAmount +
      interestMainAmount +
      interestCost
    );
  } catch (error) {
    appInsights.trackException(
      `AccountSummary SumCase Amount Exeception - ${error}`
    );
    return 0;
  }
};
/**
 * Calculate updated payment agreement balance when receiving payment
 * @param total - number
 * @param paidAmount - number
 * @returns The agreement balance
 */
export const calculatePaymentAgreementBalance = (
  total: number,
  paidAmount: number
): number => {
  try {
    if (isNaN(total) || isNaN(paidAmount)) {
      return 0;
    }

    return total - Math.abs(paidAmount);
  } catch (error) {
    appInsights.trackException(
      `Payment Agreement Balance Exception - ${error}`
    );
    return 0;
  }
};
