import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Common from 'us.common';
import { Formik } from 'formik';
import PostalArea from 'us.common/containers/PostalArea/PostalArea';
import DocumentOfTitleSchema from './validations';
import { SaveDocumentOfTitleAssets } from './SaveDocumentOfTitleAssets';
import { UpdateDocumentOfTitleAssets } from './UpdateDocumentOfTitleAssets';
import { IMetaData } from 'us.collection/interfaces';
import moment from 'moment';
import { AssetsAction } from 'us.collection.case/actions';
import { SelectMunicipal } from 'us.common/components';
import { IDataModel } from 'us.collection.case/reducers/Dashboard/Interfaces';
import { RootState } from 'us.helper/types';

const {
	$Input,
	$DatePicker,
	$Form,
	$Button,
	$Col,
	$Drawer,
	$Row,
	$Skeleton,
	$TextArea,
	$Popconfirm,
	$InputAmount,
} = Common.Components;

interface IDocumentOfTitle {
	asset?: any;
	isEditing: boolean;
	onSearchPostaCode?(): void;
	postalCodeDetails?: any;
}

interface IBase extends IDocumentOfTitle {
	postalAreas: any;
	currentLanguage: string;
	currentCurrency: string;
	currentDateFormat: string;
	metaData: IDataModel<IMetaData>;
	addDocumentOfTitleAssets: any;
	changeDrawerInfo: any;
	drawerInfo: any;
	getDocumentOfTitleAssets: any;
	realEstateAssets: any;
	resetRealEstateAssets: any;
	updateDocumentOfTitleAssets: any;
	isREFetching: boolean;
	drawerName: any;
	showOnly?: boolean;
	getPostalDetails: any;
}

const DocumentOfTitle: React.FC<IBase> = (props) => {
	const { t } = useTranslation(['US.COLLECTION.CASE'], {
		useSuspense: true,
	});
	const {
		postalAreas,
		currentLanguage,
		currentCurrency,
		currentDateFormat,
		metaData,
		addDocumentOfTitleAssets,
		changeDrawerInfo,
		drawerInfo,
		getDocumentOfTitleAssets,
		realEstateAssets,
		resetRealEstateAssets,
		updateDocumentOfTitleAssets,
		isREFetching,
		drawerName,
		showOnly,
		getPostalDetails,
	} = props;

	const { debtorEntNumber } = metaData.data ?? {};

	const [postalCodeId, setPostalCodeId] = useState(0);
	const [municipalityId, setMunicipalityId] = useState(0);

	const handleCancel = (resetForm: any) => {
		changeDrawerInfo({
			title: '',
			visible: false,
		});
		resetForm();
		resetRealEstateAssets();
	};

	const [postalDrawerVisible, setPostalDrawerVisible] = useState(false);
	const [municipalityDrawerVisible, setMunicipalityDrawerVisible] =
		useState(false);
	const openPostalArea = () => {
		setPostalDrawerVisible(true);
	};
	const closePostalArea = () => {
		setPostalDrawerVisible(false);
	};
	const openMunicipalityArea = () => {
		setMunicipalityDrawerVisible(true);
	};
	const closeMunicipalityArea = () => {
		setMunicipalityDrawerVisible(false);
	};
	const postalAreaClose = () => {
		setPostalDrawerVisible(false);
	};
	const handlePostalDoubleClick = (
		selectedPostalAreaDetail: any,
		rest: any
	) => {
		setPostalDrawerVisible(false);
		let selectedPostalInfo = postalAreas?.filter(
			(p: any) =>
				p.postalCode ==
				selectedPostalAreaDetail?.PostalCode
		);
		let postalCodeId =
			selectedPostalInfo.length > 0
				? selectedPostalInfo[0].postalCodeId
				: 0;
		let municipalityId =
			selectedPostalInfo.length > 0
				? selectedPostalInfo[0].countyCode
				: 0;
		setPostalCodeId(postalCodeId);
		setMunicipalityId(municipalityId);
		rest.setFieldValue(
			'postalCode',
			selectedPostalAreaDetail?.PostalCode
		);
		rest.setFieldValue(
			'postalArea',
			selectedPostalAreaDetail?.City
		);
		rest.setFieldValue(
			'municipalityCode',
			selectedPostalAreaDetail?.MunicipalityCode
		);
		rest.setFieldValue(
			'municipalityArea',
			selectedPostalAreaDetail?.MunicipalityName
		);
	};

	const handleMunicipalityDoubleClick = (
		municipalityDetails: any,
		rest: any
	) => {
		rest.setFieldValue(
			'municipalityCode',
			municipalityDetails?.municipalityCode
		);
		rest.setFieldValue(
			'municipalityArea',
			municipalityDetails?.municipalityName
		);
		rest.setFieldValue('postalCode', '');
		rest.setFieldValue('postalArea', '');
	};

	const descriptionInputRef = React.useRef<any>();

	useEffect(() => {
		descriptionFocus();
	}, []);

	useEffect(() => {
		if (drawerInfo?.isEdit) {
			getDocumentOfTitleAssets(drawerInfo?.assetId);
		}
	}, [drawerInfo?.isEdit]);

	const checkPostalCode = (data: any, values: any) => {
		getPostalDetails({
			countryCode: 'NO',
			searchText: data.target.value,
			searchBy: 'PostalCode',
			values,
		});
	};

	const descriptionFocus = () => {
		typeof descriptionInputRef?.current !== 'undefined' &&
			descriptionInputRef.current.focus();
	};

	return (
		<Formik
			initialValues={{
				...realEstateAssets,
				snr:
					realEstateAssets.snr === 0
						? ''
						: realEstateAssets.snr,
			}}
			enableReinitialize
			validationSchema={DocumentOfTitleSchema}
			onSubmit={(values: any, actions: any) => {
				actions.setSubmitting(true);
				if (!drawerInfo?.isEdit) {
					let entityInfo = {
						entityId: debtorEntNumber,
						entityType: 'DEB',
					};
					let payload =
						SaveDocumentOfTitleAssets.call({
							...values,
							...entityInfo,
							postalCodeId,
							municipalityId,
						});
					addDocumentOfTitleAssets(payload);
				} else {
					let entityInfo = {
						entityId: debtorEntNumber,
						entityType: 'DEB',
						assetId: drawerInfo?.assetId,
					};
					let postalCodeId =
						realEstateAssets.postalCode;
					let municipalityId =
						realEstateAssets.municipalityCode;
					let payload =
						UpdateDocumentOfTitleAssets.call(
							{
								...values,
								...entityInfo,
								postalCodeId,
								municipalityId,
							}
						);
					updateDocumentOfTitleAssets(payload);
				}
				changeDrawerInfo({
					title: '',
					visible: false,
				});
				resetRealEstateAssets();
				actions.resetForm();
			}}
			validateOnChange
			validateOnBlur>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...restProps
			}: any) => (
				<>
					<$Form>
						<$Skeleton
							loading={
								isREFetching &&
								drawerInfo?.isEdit
							}
							active
							paragraph={{ rows: 2 }}>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={24}>
									<$TextArea
										name='description'
										required
										ref={
											descriptionInputRef
										}
										tabIndex={
											1
										}
										autoSize={{
											minRows: 2,
											maxRows: 4,
										}}
										size='small'
										label={t(
											'US.COLLECTION.CASE:DOCUMENTOFTITLE.NAME/TYPE'
										)}
										disabled={
											showOnly
										}
									/>
								</$Col>
							</$Row>

							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={12}>
									<$Input
										size='small'
										label={t(
											'US.COLLECTION.CASE:DOCUMENTOFTITLE.SECTION_NO'
										)}
										name='snr'
										tabIndex={
											2
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
								<$Col span={12}>
									<$Row
										className='mb-1'
										gutter={
											16
										}>
										<$Col
											span={
												12
											}>
											<$InputAmount
												size='small'
												className='w-100'
												label={t(
													'US.COLLECTION.CASE:DOCUMENTOFTITLE.ESTIMATEDVALUE'
												)}
												name='estimatedValue'
												currentLanguage={
													currentLanguage
												}
												currentCurrency={
													currentCurrency
												}
												value={
													values?.estimatedValue
												}
												tabIndex={
													7
												}
												disabled={
													showOnly
												}
											/>
										</$Col>
										<$Col
											span={
												12
											}>
											<$InputAmount
												size='small'
												className='w-100'
												label={t(
													'US.COLLECTION.CASE:DOCUMENTOFTITLE.VALUATION'
												)}
												name='valuationAmount'
												currentLanguage={
													currentLanguage
												}
												currentCurrency={
													currentCurrency
												}
												value={
													values?.valuationAmount
												}
												tabIndex={
													8
												}
												disabled={
													showOnly
												}
											/>
										</$Col>
									</$Row>
								</$Col>
							</$Row>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={12}>
									<$Input
										size='small'
										label={t(
											'US.COLLECTION.CASE:DOCUMENTOFTITLE.NIN_FOR_HOUSING_COMPANY'
										)}
										name='ninForHousingCompany'
										tabIndex={
											3
										}
										required
										disabled={
											showOnly
										}
									/>
								</$Col>
								<$Col span={6}>
									<$DatePicker
										label={t(
											'US.COLLECTION.CASE:DOCUMENTOFTITLE.VALUATIONDATE'
										)}
										name='valuationDate'
										size='small'
										style={{
											width: '100%',
										}}
										format={
											currentDateFormat
										}
										value={
											values?.valuationDate &&
											moment(
												values?.valuationDate
											).isValid()
												? moment(
														values?.valuationDate
												  )
												: undefined
										}
										allowClear
										tabIndex={
											9
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
							</$Row>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={12}>
									<$Input
										size='small'
										label={t(
											'US.COLLECTION.CASE:DOCUMENTOFTITLE.NAMEOFHOUSINGCOMPANY'
										)}
										name='nameOfHousing'
										required
										tabIndex={
											4
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
								<$Col span={12}>
									{!showOnly && (
										<a
											className='d-block'
											style={{
												paddingBottom:
													'0.13rem',
											}}
											onClick={() =>
												openPostalArea()
											}>
											<strong>
												{t(
													'US.COLLECTION.COMMON:ENTITYSELECTION.POSTALCODE'
												)}
											</strong>
										</a>
									)}
									{showOnly && (
										<strong>
											{t(
												'US.COLLECTION.COMMON:ENTITYSELECTION.POSTALCODE'
											)}
										</strong>
									)}
									<$Input
										name='postalCode'
										size='small'
										value={
											values.postalCode
										}
										onBlur={(
											data: any
										) =>
											checkPostalCode(
												data,
												values
											)
										}
										tabIndex={
											10
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
							</$Row>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={12}>
									<$Input
										size='small'
										label={t(
											'US.COLLECTION.CASE:DOCUMENTOFTITLE.OWNED_PART/SHARE'
										)}
										name='ownedPart'
										tabIndex={
											5
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
								<$Col span={12}>
									<$Input
										label={t(
											'US.COLLECTION.COMMON:ENTITYSELECTION.POSTALAREA'
										)}
										name='postalArea'
										size='small'
										value={
											values.postalArea
										}
										tabIndex={
											11
										}
										disabled
									/>
								</$Col>
							</$Row>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={12}>
									<$TextArea
										name='comment'
										autoSize={{
											minRows: 4,
											maxRows: 4,
										}}
										size='small'
										label={t(
											'US.COLLECTION.COMMON:COMMON.COMMENT'
										)}
										tabIndex={
											6
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
								<$Col span={12}>
									<div>
										{!showOnly && (
											<a
												className='d-block'
												style={{
													paddingBottom:
														'0.13rem',
												}}
												onClick={() =>
													openMunicipalityArea()
												}>
												<strong>
													{t(
														'US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYCODE'
													)}
												</strong>
											</a>
										)}
										{showOnly && (
											<strong>
												{t(
													'US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYCODE'
												)}
											</strong>
										)}
										<$Input
											name='municipalityCode'
											size='small'
											value={
												values.municipalityCode
											}
											tabIndex={
												12
											}
											disabled
										/>
									</div>
									<div>
										<$Input
											label={t(
												'US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYNAME'
											)}
											name='municipalityArea'
											size='small'
											tabIndex={
												13
											}
											disabled
										/>
									</div>
								</$Col>
							</$Row>

							{!showOnly && (
								<div className='drawer-footer-fixed align-content-center justify-content-end'>
									<div>
										<$Button
											className='mr-2'
											disabled={
												!restProps.isValid
											}
											onClick={(
												e: any
											) =>
												handleSubmit(
													e
												)
											}
											type='primary'>
											{drawerInfo?.isEdit
												? t(
														'US.COLLECTION.COMMON:COMMON.UPDATE'
												  )
												: t(
														'US.COLLECTION.COMMON:COMMON.SAVE'
												  )}
										</$Button>
										{restProps.dirty && (
											<$Popconfirm
												title={t(
													'US.COLLECTION.COMMON:COMMON.CANCEL_ERROR'
												)}
												placement='topLeft'
												onConfirm={() =>
													handleCancel(
														resetForm
													)
												}
												okText={t(
													'US.COLLECTION.COMMON:COMMON.YES'
												)}
												cancelText={t(
													'US.COLLECTION.COMMON:COMMON.NO'
												)}>
												<$Button>
													{t(
														'US.COLLECTION.COMMON:COMMON.CANCEL'
													)}
												</$Button>
											</$Popconfirm>
										)}
										{!restProps.dirty && (
											<$Button
												onClick={() =>
													handleCancel(
														resetForm
													)
												}>
												{t(
													'US.COLLECTION.COMMON:COMMON.CANCEL'
												)}
											</$Button>
										)}
									</div>
								</div>
							)}

							{/* Postal Area ---------------------------------------------------------------------------------*/}
							<$Drawer
								title={
									drawerName
								}
								width={800}
								visible={
									postalDrawerVisible
								}
								onClose={
									closePostalArea
								}
								destroyOnClose>
								<PostalArea
									postalAreaClose={() =>
										postalAreaClose()
									}
									drawerContentChangeClick={(
										e: any
									) =>
										handlePostalDoubleClick(
											e?.updatedInfo,
											restProps
										)
									}
									{...props}
								/>
							</$Drawer>
							{/* Municipality Area ---------------------------------------------------------------------------------*/}
							<$Drawer
								title={
									drawerName
								}
								width={800}
								visible={
									municipalityDrawerVisible
								}
								onClose={
									closeMunicipalityArea
								}
								destroyOnClose>
								<SelectMunicipal
									{...props}
									onCancelClick={() =>
										closeMunicipalityArea()
									}
									currentStep
									drawerContentChangeClick={() => {}}
									onRowClickMuniciples={(
										e: any
									) =>
										handleMunicipalityDoubleClick(
											e,
											restProps
										)
									}
								/>
							</$Drawer>
						</$Skeleton>
					</$Form>
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, entityDetails, assets, domainView } = state;
	const { currentLanguage, currentDateFormat, currentCurrency } = common;
	const { metaData } = domainView;
	const { postalAreas, drawerName } = entityDetails;
	const { drawerInfo, realEstateAssets, isREFetching } = assets;

	return {
		currentLanguage,
		currentDateFormat,
		currentCurrency,
		metaData,
		postalAreas,
		drawerInfo,
		realEstateAssets,
		isREFetching,
		drawerName,
	};
};

const mapDispatchToProps = {
	addDocumentOfTitleAssets: AssetsAction.AddDocumentOfTitleAssets.save,
	changeDrawerInfo: AssetsAction.AddRealEstateAssets.openDrawer,
	getDocumentOfTitleAssets: AssetsAction.DocumentOfTitleAssets.get,
	resetRealEstateAssets: AssetsAction.RealEstateAssets.reset,
	updateDocumentOfTitleAssets:
		AssetsAction.UpdateDocumentOfTitleAssets.update,
	getPostalDetails: AssetsAction.postalDetails.get,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentOfTitle);
