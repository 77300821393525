import React, { useEffect, useState } from 'react';
import { CurrentView } from '../../Constants';
import { IViewHandler } from '../../Interfaces';
import { useTranslation } from 'react-i18next';
import { $Button, $Skeleton, $TableTree } from 'us.common/components';
import { PlusOutlined } from 'us.icons';
import { IOnFilter, IOnSort } from 'us.common/components/antd/TableTree';
import { BMDActions } from 'us.collection/actions';
import { IRootState } from 'us.collection/interfaces';
import { connect, ConnectedProps } from 'react-redux';
import { IBMDDataTypeList } from './Interfaces';
import { COLUMNS } from './Constants';

export const DataTypeList: React.FC<
  IBMDDataTypeList & IViewHandler & PropsFromRedux
> = (props) => {
  const {
    viewchangeEvent,
    dataTypes,
    dataTypesLoading,
    editBMDDataTypeEvent,
    changeDrawerStatus,
    drawerStatus,
  } = props;
  const { t } = useTranslation(['US.COLLECTION.CASE', 'US.COLLECTION.COMMON']);

  const handleSort: IOnSort = (sortData, dataSource) => {
    return sortData(dataSource);
  };

  const handleFilter: IOnFilter = (searchData, dataSource) => {
    return searchData(dataSource);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {};

  const columns: any = COLUMNS.map(
    ({ title, dataIndex, customFilter, customSorter, ellipsis, width }) => ({
      title: t(title),
      dataIndex,
      key: dataIndex,
      customSorter,
      customFilter,
      ellipsis,
      width,
    })
  );

  return (
    <$Skeleton
      loading={dataTypesLoading}
      active
      paragraph={{ rows: 2 }}
      className={dataTypesLoading ? 'px-3' : ''}
    >
      <div className="manage-bmd-types">
        <div className="d-flex mb-4">
          <$Button
            type="dashed"
            size="small"
            icon={<PlusOutlined />}
            onClick={(e) => {
              e.preventDefault();
              viewchangeEvent(CurrentView.Add);
            }}
          >
            {t('US.COLLECTION.CASE:BMD.NEW_TYPE')}
          </$Button>
        </div>
        <$Skeleton loading={false} active paragraph={{ rows: 2 }}>
          <$TableTree
            onChange={handleTableChange}
            rowKey="id"
            data={dataTypes}
            size="small"
            className=""
            onSort={handleSort}
            onFilter={handleFilter}
            filterOnType={true}
            resetOnSourceChange={true}
            bordered
            pagination={{ defaultPageSize: 100 }}
            columns={columns}
            firstColSkipFilterProps={-1}
            scroll={{ x: '100%', y: 'calc(100vh - 300px)' }}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: (event) => editBMDDataTypeEvent(record),
              };
            }}
          />
        </$Skeleton>

        <div className="drawer-footer-fixed align-content-center justify-content-end">
          <div>
            <$Button
              onClick={() =>
                changeDrawerStatus &&
                changeDrawerStatus({ ...drawerStatus, isVisible: false })
              }
            >
              {t('US.COLLECTION.COMMON:COMMON.CANCEL')}
            </$Button>
          </div>
        </div>
      </div>
    </$Skeleton>
  );
};

const { bmdDataTypes } = BMDActions;

const mapStateToProps = (state: IRootState) => {
  const { bmd } = state;
  const { drawerStatus, bmdDataTypes } = bmd;
  const { data, isLoading } = bmdDataTypes;
  return {
    dataTypes: data,
    dataTypesLoading: isLoading,
    drawerStatus,
  };
};

const mapDispatchToProps = {
  changeDrawerStatus: bmdDataTypes.openDrawer,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DataTypeList);
