import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	$Tooltip,
	$AmountLabel,
	$DateLabel,
	$Typography,
} from 'us.common/components';

const { Text } = $Typography;

interface ITileView {
	label: string;
	value: any;
	type: 'date' | 'amount' | 'string';
	kid?: string;
}

/**
 * @description -  AR Transaction mapping drawer TileView
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3029336065/Item+by+Item+Mapping+in+AR+UI+Implementation
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 13/05/2022
 */
const TileView: React.FC<ITileView> = ({ label, value, type, kid }) => {
	const { t } = useTranslation();

	return (
		<div className='tm-summery-item-inner'>
			<div className='tm-summery-item-value'>
				{type === 'string' && value && (
					<$Tooltip
						placement='topLeft'
						title={value}>
						<span>{value}</span>
					</$Tooltip>
				)}
				{type === 'string' && !value && (
					<span>---</span>
				)}
				{type === 'amount' &&
					typeof value === 'number' && (
						<$Tooltip
							placement='topLeft'
							title={value}>
							<$AmountLabel
								value={value}
							/>
						</$Tooltip>
					)}
				{type === 'amount' &&
					typeof value !== 'number' && (
						<span>---</span>
					)}
				{type === 'date' && value && (
					<$Tooltip
						placement='topLeft'
						title={value}>
						<$DateLabel value={value} />
					</$Tooltip>
				)}
				{type === 'date' && !value && <span>---</span>}
			</div>
			{kid && (
				<Text type='secondary' className='mb-0 mt-0'>
					<$Tooltip
						placement='topLeft'
						title={kid}>
						<span>
							<small>
								{`${t(
									'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.KID'
								)} - ${kid}`}
							</small>
						</span>
					</$Tooltip>
				</Text>
			)}
			<div className='tm-summery-item-title'>{t(label)}</div>
		</div>
	);
};

export default TileView;
