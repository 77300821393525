import { IBMDDataType } from 'us.collection/interfaces';
import { isArray } from 'lodash';
/**
 * To check the Duplicity of BMD Data Type Name
 * @param dataTypeName - user input BMD Data Type Name
 * @param bmdDataTypes array - Already saved in DB
 * @returns Return the true or false according result
 */
export const isDataTypeNameDuplicating = (
  dataTypeName: string,
  bmdDataTypes: Array<IBMDDataType>,
  selectedBMDDataType: IBMDDataType | undefined
) => {
  try {
    if (isArray(bmdDataTypes)) {
      return bmdDataTypes.some(
        (bmdDataType) =>
          bmdDataType.name?.toLowerCase() === dataTypeName.toLowerCase() &&
          !(
            dataTypeName.toLowerCase() ===
            selectedBMDDataType?.name.toLowerCase()
          )
      );
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
