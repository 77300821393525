import * as Yup from "yup";
export default () => {
  return Yup.object().shape({
    noteText: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
    ),
    postponeDate: Yup.date().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
    ),
  });
};
