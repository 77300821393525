import React from "react";
import { ToggleClass } from "us.helper";
import { WorkflowTroubleshoot } from "us.collection/components";
import { RouteComponentProps, withRouter } from "react-router-dom";

const Cnt_Troubleshoot: React.FC<RouteComponentProps> = () => {
  ToggleClass("change-body-color-dom");
  return <WorkflowTroubleshoot />;
};

export default withRouter(Cnt_Troubleshoot);
