import React from 'react';

const MainSearch = React.lazy(() => import('us.collection/containers/MainSearch'));
const UserRoleSettings = React.lazy(() => import('us.common/containers/UserManagement/UserRoleSettings'));
const UserSettings = React.lazy(() => import('us.common/containers/UserManagement/UserSettings'));
const CreditorDashboard = React.lazy(() => import('us.collection.creditor/containers/Dashboard/Home'));
const Home = React.lazy(() => import('us.collection/containers/Home'));
const FollowUps = React.lazy(() => import('us.collection.followup/containers/FollowUps'));

export {
    MainSearch,
    UserRoleSettings,
    UserSettings,
    CreditorDashboard,
    Home,
    FollowUps
}