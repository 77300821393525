import { takeEvery, put, call, all, takeLatest } from "redux-saga/effects";
import * as API from "us.collection.case/services";
import { otherParty } from "us.collection.case/constants/Actions";
import * as Actions from "us.collection.case/actions";
import { $MessageBox } from "us.common/components";
import _ from "lodash";
import { appInsights } from "us.helper";

const { OtherPartyAction, AssetsAction } = Actions;

const otherPartySagas = {
  otherParty: {
    get: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.otherParty.get, params);
        if (data?.statusCode === 404) {
          yield put(OtherPartyAction.otherParties.fail({}));
        } else {
          yield put(OtherPartyAction.otherParties.success(data));
        }
      } catch (error) {
        appInsights.trackException(`get OtherParty Saga exception. - ${error}`);
        yield put(OtherPartyAction.otherParties.fail(error));
      }
    },
    getById: function* (action: any): any {
      const params = action.payload.data;
      const { roleType } = params;
      roleType && _.unset(params, "roleType");
      const otherPartyGetParams = {
        ...params,
        type: roleType === "Employer" ? "debtor" : params.type,
      };
      try {
        const data = yield call(API.otherParty.getById, otherPartyGetParams);
        yield put(OtherPartyAction.otherParty.success(data));
      } catch (error) {
        appInsights.trackException(
          `get OtherParty by Id Saga exception. - ${error}`
        );
        yield put(OtherPartyAction.otherParty.fail(error));
      }
    },

    save: function* (action: any): any {
      const params = action.payload.data;
      let msg: string;
      params?.action === "add"
        ? (msg = "SUCCESSFULLY_ADDED")
        : (msg = "SUCCESSFULLY_UPDATED");
      _.unset(params, "action");
      const { calledFromAssetEntNo, debtorEntRoleId } = params;
      calledFromAssetEntNo && _.unset(params, "calledFromAssetEntNo");
      debtorEntRoleId && _.unset(params, "debtorEntRoleId");
      const callingObject = {
        ...params,
        entityId:
          params.otherpartyRoleType === "Employer"
            ? debtorEntRoleId
            : params.entityId,
        otherpartyBelongstype:
          params.otherpartyRoleType === "Employer"
            ? "Debtor"
            : params.otherpartyBelongstype,
      };
      try {
        const data = yield call(API.otherParty.save, callingObject);
        if (!data.isError && data.hasOwnProperty("isError")) {
          $MessageBox(
            "success",
            `US.COLLECTION.CASE:OTHERPARTY.${msg}`,
            "",
            ""
          );
          if (calledFromAssetEntNo) {
            const asstesData = yield call(
              API.Assets.assets.get,
              calledFromAssetEntNo,
              "DEB"
            );
            yield put(AssetsAction.Assets.success(asstesData));
          } else {
            const res = yield call(API.otherParty.get, {
              caseId: params.entityId,
              type: params.otherpartyBelongstype,
            });
            yield put(OtherPartyAction.otherParties.success(res));
          }

          if (OtherPartyAction.otherParty.openDrawer) {
            yield put(
              OtherPartyAction.otherParty.openDrawer({
                title: "",
                visible: false,
              })
            );
          }
        }
      } catch (error) {
        appInsights.trackException(
          `save OtherParty Saga exception. - ${error}`
        );
        if (OtherPartyAction.otherParty.saveFail) {
          yield put(OtherPartyAction.otherParty.saveFail(error));
        }
      }
    },
    delete: function* (action: any): any {
      const params = action.payload.data;

      const { calledFromAssetEntNo } = params;
      calledFromAssetEntNo && _.unset(params, "calledFromAssetEntNo");
      try {
        const otherPartyGetParams = {
          caseId: params.entityId,
          type: params.entityType,
        };
        const otherPartyDeleteParams = {
          ...params,
          entityType:
            params.roleType === "Employer" ? "Debtor" : params.entityType,
        };
        const data = yield call(API.otherParty.delete, otherPartyDeleteParams);
        if (OtherPartyAction.otherParty.deleteSuccess) {
          yield put(OtherPartyAction.otherParty.deleteSuccess(data));
        }
        if (!data.isError && data.hasOwnProperty("isError")) {
          $MessageBox(
            "success",
            `US.COLLECTION.CASE:OTHERPARTY.SUCCESSFULLY_DELETED`,
            "",
            ""
          );

          if (calledFromAssetEntNo) {
            const asstesData = yield call(
              API.Assets.assets.get,
              calledFromAssetEntNo,
              "DEB"
            );
            yield put(AssetsAction.Assets.success(asstesData));
          } else {
            const res = yield call(API.otherParty.get, otherPartyGetParams);
            yield put(OtherPartyAction.otherParties.success(res));
          }
        }
      } catch (error) {
        appInsights.trackException(
          `delete OtherParty Saga exception. - ${error}`
        );
        if (OtherPartyAction.otherParty.deleteFail) {
          yield put(OtherPartyAction.otherParty.deleteFail(error));
        }
      }
    },
    updateActiveState: function* (action: any): any {
      const params = action.payload.data;
      let tempParams = {
        caseId: params.caseId,
        type: params.type,
      };

      try {
        const data = yield call(API.otherParty.updateActiveState, params);
        if (!data.isError && data.hasOwnProperty("isError")) {
          const res = yield call(API.otherParty.get, tempParams);
          yield put(OtherPartyAction.otherParties.success(res));
        } else if (data.hasOwnProperty("isError")) {
          $MessageBox(
            "info",
            `US.COLLECTION.CASE:OTHERPARTY.CAN_NOT_DEACTIVATE_ASSET`,
            "",
            ""
          );
        }
      } catch (error) {
        appInsights.trackException(
          `updateActiveState OtherParty Saga exception. - ${error}`
        );
        yield put(OtherPartyAction.updateState.fail(error));
      }
    },
    search: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.otherParty.search, params);
        if (OtherPartyAction.otherParty.searchSuccess) {
          yield put(OtherPartyAction.otherParty.searchSuccess(data));
        }
      } catch (error) {
        appInsights.trackException(
          `search OtherParty Saga exception. - ${error}`
        );
        if (OtherPartyAction.otherParty.searchFail) {
          yield put(OtherPartyAction.otherParty.searchFail(error));
        }
      }
    },
  },
  roleTypes: {
    get: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.otherParty.roleType.get, params);
        yield put(OtherPartyAction.roleTypes.success(data));
      } catch (error) {
        appInsights.trackException(
          `get RoleTypes OtherParty Saga exception. - ${error}`
        );
        yield put(OtherPartyAction.roleTypes.fail(error));
      }
    },
  },
  roleType: {
    save: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.otherParty.roleType.save, params);
        yield put(OtherPartyAction.roleType.success(data));
      } catch (error) {
        appInsights.trackException(
          `save RoleType OtherParty Saga exception. - ${error}`
        );
        yield put(OtherPartyAction.roleType.fail(error));
      }
    },
    delete: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.otherParty.roleType.delete, params);
        if (data.hasOwnProperty("isError") && !data?.isError) {
          $MessageBox(
            "success",
            `US.COLLECTION.CASE:OTHERPARTY.SUCCESSFULLY_DELETED`,
            "",
            ""
          );
          const result = yield call(API.otherParty.roleType.get, {
            otherPartyType: params.type,
          });
          yield put(OtherPartyAction.roleTypes.success(result));
        } else if (data?.isError) {
          $MessageBox(
            "error",
            `US.COLLECTION.CASE:OTHERPARTY.YOU_CANNOT_DELETE_THIS_ROLE_TYPE_AS_IT_HAS_BEEN_CURRENTLY_USED`,
            "",
            ""
          );
        }
      } catch (error) {
        appInsights.trackException(
          `delete RoleType OtherParty Saga exception. - ${error}`
        );
        yield put(OtherPartyAction.deleteRoleType.fail(error));
      }
    },
  },
  postalcode: {
    search: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.otherParty.postelArea.search, params);
        if (Array.isArray(data)) {
          yield put(OtherPartyAction.postalCode.success(data));
        } else {
          yield put(OtherPartyAction.postalCode.fail([]));
        }
      } catch (error) {
        appInsights.trackException(
          `search PostalCode OtherParty Saga exception. - ${error}`
        );
        yield put(OtherPartyAction.postalCode.fail([]));
      }
    },
  },
  municipality: {
    search: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.otherParty.municipals.search, params);
        if (Array.isArray(data)) {
          yield put(OtherPartyAction.minicipality.success(data));
        } else {
          yield put(OtherPartyAction.minicipality.fail([]));
        }
      } catch (error) {
        appInsights.trackException(
          `search municipality OtherParty Saga exception. - ${error}`
        );
        yield put(OtherPartyAction.minicipality.fail([]));
      }
    },
  },
  podtalDetail: {
    get: function* (action: any): any {
      const params = action.payload.data as any;
      try {
        const data = yield call(API.otherParty.postelArea.search, params);
        yield put(OtherPartyAction.postalDetails.success(data));
      } catch (error) {
        appInsights.trackException(`get podtalDetail OtherParty Saga exception. - ${error}`);
        yield put(OtherPartyAction.postalDetails.fail(error as any));
      }
    },
  },
};

export default [
  takeLatest(
    otherParty.GET_OTHER_PARTY_DATA_STRAT,
    otherPartySagas.otherParty.get
  ),
  takeLatest(
    otherParty.SAVE_OTHER_PARTY_DATA_STRAT,
    otherPartySagas.otherParty.save
  ),
  takeLatest(
    otherParty.GET_OTHER_PARTY_BY_ID_STRAT,
    otherPartySagas.otherParty.getById
  ),
  takeLatest(
    otherParty.UPDATE_OTHER_PARTY_BY_ACTIVE_STATE_STRAT,
    otherPartySagas.otherParty.updateActiveState
  ),
  takeLatest(
    otherParty.GET_OTHER_PARTY_ROLE_TYPE_STRAT,
    otherPartySagas.roleTypes.get
  ),
  takeLatest(
    otherParty.SAVE_OTHER_PARTY_ROLE_TYPE_STRAT,
    otherPartySagas.roleType.save
  ),
  takeLatest(
    otherParty.DELETE_OTHER_PARTY_ROLE_TYPE_STRAT,
    otherPartySagas.roleType.delete
  ),
  takeLatest(
    otherParty.DELETE_OTHER_PARTY_DATA_STRAT,
    otherPartySagas.otherParty.delete
  ),
  takeLatest(
    otherParty.SEARCH_OTHER_PARTY_DATA_STRAT,
    otherPartySagas.otherParty.search
  ),
  takeLatest(
    otherParty.SEARCH_POSTALCODE_START,
    otherPartySagas.postalcode.search
  ),
  takeLatest(
    otherParty.SEARCH_MUNICIPALITY_START,
    otherPartySagas.municipality.search
  ),
  takeLatest(otherParty.GET_POSTAL_DETAIL, otherPartySagas.podtalDetail.get),
];
