import { DrawerTypes } from "us.collection/constants";
import { IAddBMDProperty } from "../Interfaces";

/**
 * @constant
 * @description - The list of columns in Change bmd drawer grid
 * @type {IAddBMDProperty} - BMD Add/Change
 */
export const INITIAL_VALUES: IAddBMDProperty = {
  keyName: "",
  dataTypeId: null,
  displayName: "",
  fromDate: null,
  comment: "",
  value: "",
  cgVisibility: true,
  crVisibility: true,
  arVisibility: true,
  caVisibility: true,
  description: "",
  isSaveNewClicked: false,
  selectedBMDDataType: undefined,
  drawerType:DrawerTypes.ADD_PROPERTY,
  isAddButtonClicked:false,
  shouldShowPopup:false,
  selectedCreditorIds:[],
  updateLowerLevel:false,
  showCreditorView:false
};
