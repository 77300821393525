export enum FollowupFooter {
    GET_FOOTER_FOLLOWUPS_START = " GET_FOOTER_FOLLOWUPS_START",
    GET_FOOTER_FOLLOWUPS_SUCCESS = "GET_FOOTER_FOLLOWUPS_SUCCESS",
    GET_FOOTER_FOLLOWUPS_FAIL = "GET_FOOTER_FOLLOWUPS_FAIL",

    GET_FOOTER_FOLLOWUP_NEXT_RESULT_START = " GET_FOOTER_FOLLOWUP_NEXT_RESULT_START",
    GET_FOOTER_FOLLOWUP_NEXT_RESULT_SUCCESS = "GET_FOOTER_FOLLOWUP_NEXT_RESULT_SUCCESS",
    GET_FOOTER_FOLLOWUP_NEXT_RESULT_FAIL = "GET_FOOTER_FOLLOWUP_NEXT_RESULT_FAIL",

    SET_FOOTER_FOLLOWUP_PREVIOUS_RESULT_START = " SET_FOOTER_FOLLOWUP_PREVIOUS_RESULT_START",
    SET_FOOTER_FOLLOWUP_PREVIOUS_RESULT_SUCCESS = "SET_FOOTER_FOLLOWUP_PREVIOUS_RESULT_SUCCESS",
    SET_FOOTER_FOLLOWUP_PREVIOUS_RESULT_FAIL = "SET_FOOTER_FOLLOWUP_PREVIOUS_RESULT_FAIL",

    SET_FOLLOWUP_CLOSE_BUTTON_VISIBILITY_START = "SET_FOLLOWUP_CLOSE_BUTTON_VISIBILITY_START",
    SET_FOLLOWUP_CLOSE_BUTTON_VISIBILITY_SUCCESS = "SET_FOLLOWUP_CLOSE_BUTTON_VISIBILITY_SUCCESS",
    SET_FOLLOWUP_CLOSE_BUTTON_VISIBILITY_FAIL = "SET_FOLLOWUP_CLOSE_BUTTON_VISIBILITY_FAIL",
}
export enum DirectForm {
    FOLLOW_UP = 'followup'
}
