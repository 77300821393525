import { IAPIAction, IActivityAction } from 'us.collection/interfaces';
import { DomainView } from 'us.collection/constants';

export const domainViewAction: Readonly<IAPIAction & IActivityAction> = {
	metaData: {
		get: (data) => ({
			type: DomainView.GET_META_DATA,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: DomainView.GET_META_DATA_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: DomainView.GET_META_DATA_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		reset: (data) => ({
			type: DomainView.RESET_META_DATA,
			payload: {
				data: data,
				isLoading: true,
			},
		}),
	},
	gdprCommentView: {
		get: (data) => ({
			type: DomainView.GET_GDPR_COMMENT_VIEW_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: DomainView.GET_GDPR_COMMENT_VIEW_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: DomainView.GET_GDPR_COMMENT_VIEW_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		reset: (data) => ({
			type: DomainView.GET_GDPR_COMMENT_VIEW_RESET,
			payload: {
				data,
				isLoading: true,
			},
		}),
	},
	gdprComment: {
		save: (data) => ({
			type: DomainView.ADD_GDPR_COMMENT_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: DomainView.ADD_GDPR_COMMENT_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: DomainView.ADD_GDPR_COMMENT_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	caseId: {
		get: (data) => ({
			type: DomainView.GET_CASE_ID,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: DomainView.GET_CASE_ID_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: DomainView.GET_CASE_ID_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		reset: (data) => ({
			type: DomainView.RESET_DOMAIN_VIEW_DATA,
			payload: {
				data,
				isLoading: true,
			},
		}),
	},
	workflowStatus: {
		get: (data) => ({
			type: DomainView.GET_WORKFLOW_STATUS,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: DomainView.GET_WORKFLOW_STATUS_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: DomainView.GET_WORKFLOW_STATUS_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
};
