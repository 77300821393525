import React, { useEffect, useState, useContext, useCallback } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { getEntityDetail, getEntityId } from 'us.common/functions';
import { INavigationData } from 'us.collection/interfaces';
import {
	$Skeleton,
	$Divider,
	$Button,
	$Tooltip,
	ActivityExecution,
	$AmountLabel,
	$DateLabel,
} from 'us.common/components';
import * as Actions from 'us.collection.transactions/actions';
import { BoxIcons, IconTypes } from 'us.icons';
import {
	PendingActivityExecution,
	AddToFollowUpButton,
} from 'us.collection/components';
import './Header.scss';
import { ConfigurationContext } from 'us.common/ConfigurationContext/ConfigurationProvider';
import { IDataModel } from 'us.collection.case/reducers/Dashboard/Interfaces';
import { IMetaData } from 'us.collection/interfaces';
import {
	EntityType,
	EntityTypeShortForm,
	ExecutionSource,
	Mode,
} from 'us.common/constants';
import {
	getSelectedCaseId,
	getSelectedCaseType,
	isDisabledActivityExecutionForCaseAndSubCase,
	isEnabledDefaultActivityExecution,
} from './Functions';
import { CaseTypeOptions } from 'us.collection.transactions/components/CasesAndSubcases/Constants';
import { RootState } from 'us.helper/types';

interface IHeader extends RouteComponentProps {
	currentLanguage: string;
	currentCurrency: string;
	currentDateFormat: string;
	getArDetails?: any;
	arDetails?: any;
	isArDetailsFetching?: boolean;
	metaData: IDataModel<IMetaData>;
	selectedCaseAndSubCase: { data: []; filterType: CaseTypeOptions.ALL };
}

const Header: React.FC<IHeader> = (props) => {
	const { t } = useTranslation();
	const context = useContext(ConfigurationContext);
	const { push } = useHistory();
	const [arNumber, setArNumber] = useState<number>(0);

	const {
		getArDetails,
		arDetails,
		isArDetailsFetching,
		location,
		metaData,
		selectedCaseAndSubCase,
	} = props;

	const { arId } = metaData.data ?? {};
	const state = location.state as INavigationData;
	const { refreshCount = 0, currentTab } = state ?? {};
	const { pathname } = location;
	const entityDetail = getEntityDetail(pathname, metaData.data);
	const { entityId } = entityDetail;
	const { data, filterType } = selectedCaseAndSubCase;

	useEffect(() => {
		if (state && currentTab === 'ar')
			if (arId) {
				setArNumber(Number(arId));
				getArDetails(arId);
			}
	}, [metaData, refreshCount]);

	/**
	 * Description - Check whether the given item should be available or not in the header
	 * @param {string} item - Header item name
	 */
	const isHeaderItemAvailable = useCallback(
		(item: string) => {
			if (context && context.componentPermission['ar']) {
				return context.componentPermission['ar']
					?.components[item]?.isEnabled;
			} else {
				return false;
			}
		},
		[context]
	);

	/**
	 * @function
	 * @description handle navigation after execute activity
	 */
	const navigateAfterExecution = () => {
		push(
			{ ...location, pathname: `/ar/${entityId}/history` },
			state
		);
	};

	return (
		<div className='dom-ar-header'>
			<$Skeleton
				loading={
					isArDetailsFetching ||
					metaData.isLoading
				}
				active
				paragraph={{ rows: 0, width: 100 }}>
				<div className='d-flex flex-wrap'>
					<div className='d-flex my-1'>
						<span className='bui-label mr-2 text-nowrap'>
							{t(
								'US.COLLECTION.LAYOUT:AR.AR_NO'
							)}
						</span>
						<strong className='text-truncate AR_NO'>
							{arDetails?.arId
								? arDetails?.arId
								: '--'}
						</strong>
						<$Divider
							type='vertical'
							className='bui-devider'
						/>
					</div>

					<div className='d-flex my-1'>
						<span className='bui-label mr-2 text-nowrap'>
							{t(
								'US.COLLECTION.LAYOUT:AR.CREDITOR'
							)}
						</span>
						<$Tooltip
							placement='topLeft'
							title={
								<>
									{arDetails?.pid ? (
										<span>
											{
												arDetails?.pid
											}
										</span>
									) : (
										'---'
									)}
									{arDetails?.pid &&
									arDetails?.creditorName ? (
										<span className='mx-1'>
											-
										</span>
									) : (
										''
									)}
									{arDetails?.creditorName ? (
										<span>
											{
												arDetails?.creditorName
											}
										</span>
									) : (
										'---'
									)}
								</>
							}>
							<strong className='text-truncate arh-truncate'>
								{arDetails?.pid ? (
									<span>
										{
											arDetails?.pid
										}
									</span>
								) : (
									'---'
								)}
								{arDetails?.pid &&
								arDetails?.creditorName ? (
									<span className='mx-1'>
										-
									</span>
								) : (
									''
								)}
								{arDetails?.creditorName ? (
									<span>
										{
											arDetails?.creditorName
										}
									</span>
								) : (
									'---'
								)}
							</strong>
						</$Tooltip>

						<$Divider
							type='vertical'
							className='bui-devider'
						/>
					</div>

					<div className='d-flex my-1'>
						<span className='bui-label mr-2 text-nowrap'>
							{t(
								'US.COLLECTION.LAYOUT:AR.DEBTOR'
							)}
						</span>
						<$Tooltip
							placement='topLeft'
							title={
								<>
									{arDetails?.cid ? (
										<span>
											{
												arDetails?.cid
											}
										</span>
									) : (
										'---'
									)}
									{arDetails?.cid &&
									arDetails?.debtorName ? (
										<span className='mx-1'>
											-
										</span>
									) : (
										''
									)}
									{arDetails?.debtorName ? (
										<span>
											{
												arDetails?.debtorName
											}
										</span>
									) : (
										'---'
									)}
								</>
							}>
							<strong className='text-truncate arh-truncate'>
								{arDetails?.cid ? (
									<span>
										{
											arDetails?.cid
										}
									</span>
								) : (
									'---'
								)}
								{arDetails?.cid &&
								arDetails?.debtorName ? (
									<span className='mx-1'>
										-
									</span>
								) : (
									''
								)}
								{arDetails?.debtorName ? (
									<span>
										{
											arDetails?.debtorName
										}
									</span>
								) : (
									'---'
								)}
							</strong>
						</$Tooltip>
						<$Divider
							type='vertical'
							className='bui-devider'
						/>
					</div>

					<div className='d-flex my-1'>
						<span className='bui-label mr-2 text-nowrap'>
							{t(
								'US.COLLECTION.LAYOUT:AR.LAST_PAYMENT'
							)}
						</span>
						<strong className='text-truncate LAST_PAYMENT'>
							{arDetails?.lastPaymentDate ? (
								<$DateLabel
									value={
										arDetails?.lastPaymentDate
									}
								/>
							) : (
								'--'
							)}
						</strong>
						<$Divider
							type='vertical'
							className='bui-devider'
						/>
					</div>

					<div className='d-flex my-1'>
						<span className='bui-label mr-2 text-nowrap'>
							{t(
								'US.COLLECTION.LAYOUT:AR.TOTAL_BALANCE'
							)}
						</span>
						<strong className='text-truncate TOTAL_BALANCE'>
							{arDetails?.balance !=
								null ||
							arDetails?.balance !=
								'undefined' ? (
								<$AmountLabel
									value={
										arDetails?.balance
									}
								/>
							) : (
								'--'
							)}
						</strong>
						<$Divider
							type='vertical'
							className='bui-devider'
						/>
					</div>

					<div className='d-flex my-1'>
						<$Button
							size='small'
							onClick={() =>
								getArDetails(
									arNumber,
									true
								)
							}>
							<BoxIcons
								type={
									IconTypes.BOX_ICON
								}
								name='update-balance'
								className='mr-2'
							/>
							<span>
								{t(
									'US.COLLECTION.LAYOUT:AR.UPDATE_BALANCE'
								)}
							</span>
						</$Button>
					</div>
				</div>
				<div className='d-flex align-items-center mt-2'>
					{isEnabledDefaultActivityExecution(
						pathname
					) && (
						<ActivityExecution
							mode={Mode.Initial}
							isHideTable={false}
							isDisabled={false}
							isBulkExecutionEnabled={
								false
							}
							isGroupActivityOptions={
								false
							}
							isActivityOptionWithCode={
								true
							}
							isHideDatePicker={false}
							activityAPIRequest={{
								endPoint: `activities`,
								parameters: {
									entitytype: EntityTypeShortForm.AR,
									entitystate:
										'',
									canExecute: true,
								},
							}}
							executeActivityEndPoint='activities'
							groupingProperty={{
								groupingLabel:
									'activityGroup',
								groupingValue:
									'activityGroupId',
								tableGrouping:
									'type',
							}}
							entityId={getEntityId(
								entityDetail
							)}
							entityType={
								EntityType.AR
							}
							isPopConfirmEnabled={
								true
							}
							propConfirmDetail={{
								title: 'ARE_YOU_SURE_YOU_WANT_TO_EXECUTE_THIS_ACTIVITY',
							}}
							navigateAfterExecution={
								navigateAfterExecution
							}
							isPopConfirmEnabledDropDown={
								false
							}
						/>
					)}

					{!isEnabledDefaultActivityExecution(
						pathname
					) && (
						<ActivityExecution
							mode={Mode.Click}
							isHideTable={false}
							isDisabled={isDisabledActivityExecutionForCaseAndSubCase(
								data,
								filterType
							)}
							isBulkExecutionEnabled={
								true
							}
							isGroupActivityOptions={
								false
							}
							isActivityOptionWithCode={
								true
							}
							isHideDatePicker={false}
							activityAPIRequest={{
								endPoint: `activities`,
								parameters: {
									entitytype: getSelectedCaseType(
										data
									)[0],
									entitystate:
										'All',
									canExecute: true,
								},
							}}
							executeActivityEndPoint='bulkactivity'
							groupingProperty={{
								groupingLabel:
									'activityGroup',
								groupingValue:
									'activityGroupId',
								tableGrouping:
									'type',
							}}
							isPopConfirmEnabled={
								true
							}
							isPopConfirmEnabledDropDown={
								true
							}
							propConfirmDetail={{
								title: 'ARE_YOU_SURE_YOU_WANT_TO_EXECUTE_THIS_ACTIVITY',
							}}
							selectedEntities={getSelectedCaseId(
								data
							)}
							maxEntityCountExecuteAtOnce={
								100
							}
							isRowKeysAsEntityIds={
								true
							}
							bulkExecutionSource={
								ExecutionSource.CasesAndSubCasesBulkExecution
							}
						/>
					)}

					<div className='ml-auto d-flex'>
						{isHeaderItemAvailable(
							'addToFollowUp'
						) && <AddToFollowUpButton />}
						<PendingActivityExecution
							{...props}
						/>
					</div>
				</div>
			</$Skeleton>
		</div>
	);
};
const mapStateToProps = (state: RootState) => {
	const { common, transaction, domainView, casesAndSubCases } = state;
	const { currentLanguage, currentDateFormat, currentCurrency } = common;
	const { arDetails, isArDetailsFetching } = transaction;
	const { metaData } = domainView;
	const { selectedCaseAndSubCase } = casesAndSubCases;
	return {
		currentLanguage,
		currentCurrency,
		currentDateFormat,
		arDetails,
		isArDetailsFetching,
		metaData,
		selectedCaseAndSubCase,
	};
};

const mapDispatchToProps = {
	getArDetails: Actions.transactions.getArDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
