import React, { useEffect } from 'react';
import Form from 'antd/lib/form';
import { Input, Button } from 'antd';
import DEBTOR_CONSTANTS from 'us.collection.debtor/constants';
import { addDebtorContactCategory } from 'us.collection.debtor/actions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IRootState } from "us.collection/interfaces";

const { viewNames } = DEBTOR_CONSTANTS

interface INewDebtorCategory {
    cancelClick(info: any): void;
    drawerContentChangeClick(stepInfo: any): void;
    currentStep: string;
}

interface IBase extends INewDebtorCategory {
    addDebtorContactCategory: (newCategory: any) => void;
    newContactCategorySuccessMsg: any;
}

const NewDebtorCategory: React.FC<IBase> = (props) => {

    const { t } = useTranslation();
    const { newContactCategorySuccessMsg, addDebtorContactCategory, drawerContentChangeClick, currentStep } = props;


    useEffect(() => {
        // if (newContactCategorySuccessMsg?.Name.trim() !== '') {
        //     drawerContentChangeClick(currentStep); // changing the view if the addtion of new contact is true.
        // }
    }, [currentStep])

    return (
        <>

            <Form>
                <Form.Item label={t('US.COLLECTION.DEBTOR:NEWCATETORY.NAME')}>
                   <Input allowClear />
                </Form.Item>

                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button type="primary">{t('US.COLLECTION.COMMON:COMMON.SAVE')}</Button>
                    <Button onClick={() => props.cancelClick(viewNames.debtContactInfo)} style={{ marginRight: 8 }}>{t('US.COLLECTION.COMMON:COMMON.CANCEL')}</Button>
                </div>
            </Form>
        </>
    );
}

const mapStateToProps = (state: IRootState) => {
    const {debtor} = state
    return {
        newContactCategorySuccessMsg: debtor.newContactCategorySuccessMsg
    };
};

const mapDispatchToProps = {
    addDebtorContactCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewDebtorCategory);