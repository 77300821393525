import {
    IActivity,
    ISetting,
    IDefaultData,
} from "us.collection.followup/interfaces";
import {
    getEntityIds,
    getActivitiesRequst,
} from "us.collection.followup/functions";
import { ActivityQueue, ExecutionSource } from "us.collection.followup/constants";

export function ExecuteActivity(
    this: any,
    list: Array<ISetting>,
    activityList: Array<IActivity>,
    defaultData: IDefaultData,
    dataSource: Array<Object>,
    selectedRowKeys: Array<string | number>,
    isEnableParameter: boolean,
    entityType?: string
) {
    const { activity } = this;
    return {
        activityBatchId: -1,
        activityCode: activityList.find(
            (act: IActivity) => act.displayName == activity && act.entitytype == entityType
        )?.activityCode,
        activityQueue: ActivityQueue.Express,
        executionSource: ExecutionSource.FollowUpBulkExecution,
        activities: getActivitiesRequst(
            this,
            list,
            defaultData,
            getEntityIds(dataSource, selectedRowKeys, defaultData),
            dataSource,
            isEnableParameter
        ),
    };
}
