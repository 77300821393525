import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import Common from "us.common";
import "us.collection.creditor/components/ManageCreditors/Home.scss";
import {
  IBasicInformation,
  IDrawerInfo,
} from "us.collection.creditor/components/ManageCreditors/Components/BasicInformation";
import _ from "lodash";
import { europeanCountry } from "us.common/constants";
import PostalArea from "us.common/containers/PostalArea/PostalArea";
import { creditorGroup } from "us.collection.admin/actions";
import { CreditorGroupHome } from "us.collection.admin/components/CreditorGroup/Home";
import {
  initialDrawerInfo,
  DrawerTypes,
  PostalCodeParams,
} from "us.collection.creditor/components/ManageCreditors/Constants";
import { getDrawerTitle } from "us.collection.creditor/components/ManageCreditors/Functions";
import * as Actions from "us.collection.creditor/actions";
import { IRootState } from "us.collection/interfaces";

const {
  $Row,
  $Col,
  $Input,
  $SelectGroup,
  $InputAccountNo,
  $Drawer,
  $Message,
  $Select,
  $Button,
  $Skeleton,
  $SelectCreditors,
  $InputWithValue,
} = Common.Components;

/**
 * @description - Manage Creditor home view component to manage creditor add / edit
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2532114555/Manage+Creditors
 * @author Kaushalya Sandamali <kaushalyas@unicorn-solutions.com>
 * @since 23/03/2022
 */
const BasicInformation: React.FC<PropsFromRedux & IBasicInformation> = (
  props
) => {
  const { t } = useTranslation([
    "US.COLLECTION.CREDITOR",
    "US.COLLECTION.COMMON",
  ]);

  const { values, setFieldValue } = useFormikContext<any>();

  const {
    getCreditorsByGroupId,
    isCreditorManagement,
    getPostalAreas,
    postalDetails,
    getBelongsToCreditor,
    isValidBelongsToCreditor,
    nextCreditorNoLoading,
    isDrawer,
    isGroupDisabled,
    validateCreditorNo,
    users,
    belongsToCreditor,
    isCreditorNoValid,
    resetBelongsToCreditor,
  } = props;

  const [drawerInfo, setDrawerInfo] = useState<IDrawerInfo>(initialDrawerInfo);

  useEffect(() => {
    setFieldValue("postalPlace", postalDetails?.postalPlace ?? "");
  }, [postalDetails]);

  useEffect(() => {
    if (Object.keys(belongsToCreditor).length != 0) {
      setFieldValue("belongsToId", belongsToCreditor?.creditorNo);
      setFieldValue(
        "belongsTo",
        belongsToCreditor?.creditorNo +
          " - " +
          (belongsToCreditor?.firstName + " " + belongsToCreditor?.lastName)
      );
    }
  }, [belongsToCreditor]);

  useEffect(() => {
    if (!isCreditorNoValid) {
      setFieldValue("creditorNumber", "");
    }
  }, [isCreditorNoValid]);

  /**
   * @description - Handle Drawer info
   * @param type - drawer type `DrawerTypes`
   */
  const handleDrawerInfo = (type: string) => {
    if (type === DrawerTypes.BELONGS_TO && !values?.groupNo) {
      $Message.error(
        t("US.COLLECTION.CREDITOR:CREDITOR_INFO.PLEASE_SELECT_GROUP_NO")
      );
    } else {
      setDrawerInfo({
        title: t(getDrawerTitle(type)),
        type,
        visibility: true,
      });
    }
  };

  /**
   * @description - handle drawer content double click function
   * @param selectedData - selected drawer content data
   * @param type - drawer type `DrawerTypes`
   */
  const handleDrawerDoubleClick = (selectedData: any, type: string) => {
    setDrawerInfo(initialDrawerInfo);
    if (type === DrawerTypes.GROUP_NO) {
      setFieldValue("groupNo", selectedData?.id);
      setFieldValue("groupName", selectedData?.groupName);
      // setFieldValue("belongsTo", "");
    } else if (type === DrawerTypes.BELONGS_TO) {
      setFieldValue("belongsToId", selectedData?.pid);
      setFieldValue(
        "belongsTo",
        selectedData?.pid + " - " + selectedData?.creditorName
      );
    } else if (type === DrawerTypes.POSTAL_CODE) {
      setFieldValue("postalPlace", selectedData?.City);
    }
  };

  /**
   * @description - handle onBlur function in inputs (postalCode and belongsTo)
   * @param type - input type `DrawerTypes`
   * @param value - typed value for input
   */
  const handleInputOnBlur = (type: string, value: any) => {
    if (type === DrawerTypes.POSTAL_CODE && value != "") {
      handlePostalCode(value);
    } else if (type === DrawerTypes.BELONGS_TO && value != "") {
      handleBelongsToCreditor(value);
    }
  };

  /**
   * @description - Call postal code search API action
   * @param searchText - search text
   */
  const handlePostalCode = (searchText: any) => {
    getPostalAreas &&
      getPostalAreas({
        countryCode: PostalCodeParams.COUNTRY_CODE,
        searchBy: PostalCodeParams.POSTAL_CODE,
        searchText,
      });
  };

  /**
   * @description - Call belongs to creditor search API action
   * @param searchText - search text
   */
  const handleBelongsToCreditor = (pId: any) => {
    getBelongsToCreditor && getBelongsToCreditor(pId);
  };

  /**
   * @description - Call creditor no validation API action
   * @param newCreditorNo - entered creditor no
   */
  const handleCreditorNoValidation = (newCreditorNo: number) => {
    validateCreditorNo && validateCreditorNo(newCreditorNo);
  };

  // creditor selection and update the UI
  const onSelectCreditors = (creditorValues: any) => {
    const { customers, customerGroups, customerData } = creditorValues;
    const { pid, creditorName, groupId } = customerData[0];
    setFieldValue("belongsToId", pid);
    setFieldValue("belongsTo", pid + " - " + creditorName);
    setFieldValue("belongsToGroupId", groupId);
    resetBelongsToCreditor && resetBelongsToCreditor();
  };

  const handleBelongsToOnBlur = (value: any) => {
    if (!isNaN(value) && value != 0) {
      if (values?.groupNo) {
        handleInputOnBlur("belongsTo", value);
      } else {
        setFieldValue("belongsTo", "");
        $Message.error(
          t("US.COLLECTION.CREDITOR:CREDITOR_INFO.PLEASE_SELECT_GROUP_NO")
        );
      }
    }
  };

  return (
    <>
      {/* Basic information */}
      <div className="pl-1 pb-4">
        <$Row gutter={16}>
          <$Skeleton
            loading={nextCreditorNoLoading}
            active
            paragraph={{ rows: 2, width: 100 }}
          >
            <$Col span={8}>
              <div className="d-flex">
                <div className="mr-1">
                  <$Input
                    label={t(
                      "US.COLLECTION.CREDITOR:CREDITOR_INFO.CREDITOR_NO"
                    )}
                    required
                    size="small"
                    name="creditorNumber"
                    data-testid="creditorNo"
                    onBlur={(e: any) => {
                      if (e.target.value != "") {
                        handleCreditorNoValidation(e.target.value);
                      }
                    }}
                    disabled={!isDrawer}
                    type="number"
                  />
                </div>
                <div className="flex-fill">
                  <$Input
                    label={t(
                      "US.COLLECTION.CREDITOR:CREDITOR_INFO.CREDITOR_NAME"
                    )}
                    name="creditorName"
                    size="small"
                    required
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="mr-1">
                  <$Button
                    type="link"
                    className="m-0 p-0 text-left font-weight-bold"
                    size="small"
                    data-testid="group-no-link"
                    onClick={() => handleDrawerInfo(DrawerTypes.GROUP_NO)}
                    disabled={isGroupDisabled}
                  >
                    {t("US.COLLECTION.CREDITOR:CREDITOR_INFO.GROUP_NO")}
                  </$Button>
                  <$Input size="small" disabled name="groupNo" />
                </div>
                <div className="flex-fill">
                  <$Input
                    label={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.GROUP_NAME")}
                    name="groupName"
                    size="small"
                    disabled
                    required
                  />
                </div>
              </div>
              <div>
                <$Input
                  label={
                    <a
                      className="font-weight-bold"
                      onClick={() => handleDrawerInfo(DrawerTypes.BELONGS_TO)}
                      data-testid="belongsTo-link"
                    >
                      {t("US.COLLECTION.CREDITOR:CREDITOR_INFO.BELONGS_TO")}
                    </a>
                  }
                  name="belongsTo"
                  size="small"
                  style={{
                    borderColor: !isValidBelongsToCreditor ? "red" : "",
                  }}
                  onBlur={(e: any) => handleBelongsToOnBlur(e.target.value)}
                />
                {!isValidBelongsToCreditor && values?.belongsTo != "" && (
                  <span className="text-error">
                    {t(
                      "US.COLLECTION.CREDITOR:CREDITOR_INFO.INVALID_BELONGS_TO_CREDITOR"
                    )}
                  </span>
                )}
              </div>
              <$Row gutter={16}>
                <$Col span={12}>
                  <$Input
                    label={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.NIN")}
                    name="nin"
                    size="small"
                  />
                </$Col>
                <$Col span={12}>
                  <$InputAccountNo
                    label={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.ACCOUNT")}
                    name="account"
                    size="small"
                    required
                  />
                </$Col>
              </$Row>
              <$Row gutter={16}>
                <$Col span={12}>
                  <$InputAccountNo
                    label={t(
                      "US.COLLECTION.CREDITOR:CREDITOR_INFO.REMIT_ACCOUNT_NO"
                    )}
                    name="accountNo"
                    size="small"
                  />
                </$Col>
                <$Col span={12}>
                  <$Input
                    label={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.PRODUCT_NO")}
                    name="productNo"
                    size="small"
                    type="number"
                  />
                </$Col>
              </$Row>
              <$Row gutter={16}>
                <$Col span={12}>
                  <$Select
                    formitem={{
                      label: t(
                        "US.COLLECTION.CREDITOR:CREDITOR_INFO.CASE_HANDLER"
                      ),
                    }}
                    name="caseHandler"
                    size="small"
                    allOption={false}
                    options={users}
                    optionValue="azureUserId"
                    optionText="displayName"
                    onSearchBy={["displayName", "azureUserId"]}
                  />
                </$Col>
                <$Col span={12}>
                  <$Input
                    label={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.AGREEMENT")}
                    name="agreement"
                    size="small"
                  />
                </$Col>
              </$Row>
            </$Col>
            <$Col span={6}>
              <$Input
                label={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.ADDRESS_1")}
                name="address1"
                size="small"
              />
              <$Input
                label={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.ADDRESS_2")}
                name="address2"
                size="small"
              />
              <$Input
                label={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.ADDRESS_3")}
                name="address3"
                size="small"
              />

              <div className="mb-1">
                <$SelectGroup
                  className="country-select"
                  options={_.orderBy(
                    europeanCountry,
                    ["name", "label"],
                    ["asc", "asc"]
                  )}
                  defaultValue={"NO"}
                  formitem={{
                    label: t("US.COLLECTION.CREDITOR:CREDITOR_INFO.COUNTRY"),
                  }}
                  style={{ width: "100%" }}
                  name="country"
                  optionFilterProp="children"
                  data-testid="country"
                />
              </div>
              <$Row gutter={16}>
                <$Col span={12}>
                  <a
                    className="ant-form-item-required d-block font-weight-bold"
                    style={{ paddingBottom: "0.13rem" }}
                    onClick={() => handleDrawerInfo(DrawerTypes.POSTAL_CODE)}
                    data-testid="postal-code-link"
                  >
                    {t("US.COLLECTION.COMMON:ENTITYSELECTION.POSTALCODE")}
                  </a>
                  <$InputWithValue
                    name="postalCode"
                    size="small"
                    value={values?.postalCode}
                    onChange={(e: any) => {
                      setFieldValue("postalPlace", "");
                      setFieldValue("postalCode", e.target.value);
                    }}
                    onBlur={(e: any) => {
                      handleInputOnBlur("postalCode", e.target.value);
                    }}
                  />
                </$Col>
                <$Col span={12}>
                  <$Input
                    name="postalPlace"
                    label={t("US.COLLECTION.COMMON:ENTITYSELECTION.POSTALAREA")}
                    size="small"
                    disabled
                  />
                </$Col>
              </$Row>
            </$Col>

            <$Col span={6}>
              <$Input
                name="email"
                label={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.EMAIL")}
                size="small"
                required
              />

              <$Row gutter={16}>
                <$Col span={12}>
                  <$Input
                    name="direct"
                    label={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.DIRECT_NO")}
                    size="small"
                  />
                </$Col>
                <$Col span={12}>
                  <$Input
                    name="mobile"
                    label={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.MOBILE")}
                    size="small"
                  />
                </$Col>
              </$Row>

              <$Row gutter={16}>
                <$Col span={12}>
                  <$Input
                    name="office"
                    label={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.OFFICE")}
                    size="small"
                  />
                </$Col>
                <$Col span={12}>
                  <$Input
                    name="fax"
                    label={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.FAX")}
                    size="small"
                  />
                </$Col>
              </$Row>
              <$Row gutter={16}>
                <$Col span={12}>
                  <$Input
                    name="im1"
                    label={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.IM1")}
                    size="small"
                  />
                </$Col>
                <$Col span={12}>
                  <$Input
                    name="im2"
                    label={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.IM2")}
                    size="small"
                  />
                </$Col>
                <$Col span={12}>
                  <$Input
                    name="im3"
                    label={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.IM3")}
                    size="small"
                  />
                </$Col>
              </$Row>
            </$Col>
          </$Skeleton>
        </$Row>
      </div>
      <$Drawer
        title={drawerInfo.title}
        width={drawerInfo.type === DrawerTypes.POSTAL_CODE ? 800 : 1400}
        visible={
          drawerInfo.type === DrawerTypes.BELONGS_TO
            ? false
            : drawerInfo.visibility
        }
        onClose={() => setDrawerInfo(initialDrawerInfo)}
        destroyOnClose
      >
        {drawerInfo.type === DrawerTypes.GROUP_NO && (
          <CreditorGroupHome
            drawerContentChangeClick={(e: any) => {
              handleDrawerDoubleClick(
                e?.selectedGroupData,
                DrawerTypes.GROUP_NO
              );
            }}
            isCreditorManagement={true}
          />
        )}
        {drawerInfo.type === DrawerTypes.POSTAL_CODE && (
          <PostalArea
            name="selectedPostalCodes"
            postalAreaClose={() => setDrawerInfo(initialDrawerInfo)}
            drawerContentChangeClick={(e: any) => {
              handleDrawerDoubleClick(e?.updatedInfo, DrawerTypes.POSTAL_CODE);
              setFieldValue("postalCode", e?.updatedInfo?.PostalCode);
            }}
            countryCode={values.country}
          />
        )}
      </$Drawer>
      {drawerInfo.type === DrawerTypes.BELONGS_TO && (
        <$SelectCreditors
          name="customers"
          groupName="customerGroups"
          visible={drawerInfo.visibility}
          multiple={false}
          onClose={() => setDrawerInfo(initialDrawerInfo)}
          onConfirm={(
            customers: Array<number>,
            customerGroups: Array<number>,
            customerData: Array<number>
          ) => {
            setDrawerInfo(initialDrawerInfo);
            onSelectCreditors({ customers, customerGroups, customerData });
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageCreditors } = state;
  const {
    postalDetails,
    isValidBelongsToCreditor,
    nextCreditorNoLoading,
    users,
    belongsToCreditor,
    isCreditorNoValid,
  } = manageCreditors;
  return {
    postalDetails,
    isValidBelongsToCreditor,
    nextCreditorNoLoading,
    users,
    belongsToCreditor,
    isCreditorNoValid,
  };
};

const { manageCreditors } = Actions;
const {
  postalAreas,
  belongsToCreditor,
  creditorNoValidateStatus,
  caseHandlers,
} = manageCreditors;

const mapDispatchToProps = {
  getCreditorsByGroupId: creditorGroup.selectedCreditors.get,
  getPostalAreas: postalAreas.get,
  getBelongsToCreditor: belongsToCreditor.get,
  validateCreditorNo: creditorNoValidateStatus.getById,
  getCaseHandlers: caseHandlers.get,
  resetBelongsToCreditor: belongsToCreditor.restWithoutParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(BasicInformation);
