export enum Workflow {
    RETRY_WORKFLOW_START = "RETRY_WORKFLOW_START",
    RETRY_WORKFLOW_SUCCESS = "RETRY_WORKFLOW_SUCCESS",
    RETRY_WORKFLOW_FAIL = "RETRY_WORKFLOW_FAIL",

    GET_WORKFLOW_STATES_START = "GET_WORKFLOW_STATES_START",
    GET_WORKFLOW_STATES_SUCCESS = "GET_WORKFLOW_STATES_SUCCESS",
    GET_WORKFLOW_STATES_FAIL = "GET_WORKFLOW_STATES_FAIL",

    HANDLE_WORKFLOW_DRAWER = "HANDLE_WORKFLOW_DRAWER",
    MANAGE_DUPLICATE_STATE_POPOVER= "MANAGE_DUPLICATE_STATE_POPOVER",
    MANAGE_WORKFLOW_TOOL_POPOVER= "MANAGE_WORKFLOW_TOOL_POPOVER",

    ADD_WORKFLOW_CATEGORY_START = "ADD_WORKFLOW_CATEGORY_START",
    ADD_WORKFLOW_CATEGORY_SUCCESS = "ADD_WORKFLOW_CATEGORY_SUCCESS",
    ADD_WORKFLOW_CATEGORY_FAIL = "ADD_WORKFLOW_CATEGORY_FAIL",
    UPDATE_WORKFLOW_CATEGORY_START = "UPDATE_WORKFLOW_CATEGORY_START",

    DELETE_WORKFLOW_CATEGORY_START = "DELETE_WORKFLOW_CATEGORY_START",
    DELETE_WORKFLOW_CATEGORY_SUCCESS = "DELETE_WORKFLOW_CATEGORY_SUCCESS",
    DELETE_WORKFLOW_CATEGORY_FAIL = "DELETE_WORKFLOW_CATEGORY_FAIL",

    ADD_WORKFLOW_STATE_START = "ADD_WORKFLOW_STATE_START",
    ADD_WORKFLOW_STATE_SUCCESS = "ADD_WORKFLOW_STATE_SUCCESS",
    ADD_WORKFLOW_STATE_FAIL = "ADD_WORKFLOW_STATE_FAIL",
    UPDATE_WORKFLOW_STATE_START = "UPDATE_WORKFLOW_STATE_START",

    GET_DATA_ADAPTERS_START = "GET_DATA_ADAPTERS_START",
    GET_DATA_ADAPTERS_SUCCESS = "GET_DATA_ADAPTERS_SUCCESS",
    GET_DATA_ADAPTERS_FAIL = "GET_DATA_ADAPTERS_FAIL",

    GET_WORKFLOW_VERSIONS_START = "GET_WORKFLOW_VERSIONS_START",
    GET_WORKFLOW_VERSIONS_SUCCESS = "GET_WORKFLOW_VERSIONS_SUCCESS",
    GET_WORKFLOW_VERSIONS_FAIL = "GET_WORKFLOW_VERSIONS_FAIL",

    DELETE_WORKFLOW_STATE_START = "DELETE_WORKFLOW_STATE_START",
    DELETE_WORKFLOW_STATE_SUCCESS = "DELETE_WORKFLOW_STATE_SUCCESS",
    DELETE_WORKFLOW_STATE_FAIL = "DELETE_WORKFLOW_STATE_FAIL",

    GET_EVENTS_START = "GET_EVENTS_START",
    GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS",
    GET_EVENTS_FAIL = "GET_EVENTS_FAIL",

    HANDLE_EVENT_DRAWER = "HANDLE_EVENT_DRAWER",

    ADD_WORKFLOW_EVENT_START = "ADD_WORKFLOW_EVENT_START",
    ADD_WORKFLOW_EVENT_SUCCESS = "ADD_WORKFLOW_EVENT_SUCCESS",
    ADD_WORKFLOW_EVENT_FAIL = "ADD_WORKFLOW_EVENT_FAIL",
    UPDATE_WORKFLOW_EVENT_START = "UPDATE_WORKFLOW_EVENT_START",

    DELETE_WORKFLOW_EVENT_START = "DELETE_WORKFLOW_EVENT_START",
    DELETE_WORKFLOW_EVENT_SUCCESS = "DELETE_WORKFLOW_EVENT_SUCCESS",
    DELETE_WORKFLOW_EVENT_FAIL = "DELETE_WORKFLOW_EVENT_FAIL",

    GET_ERROR_COUNT_START = "GET_ERROR_COUNT_START",
    GET_ERROR_COUNT_SUCCESS = "GET_ERROR_COUNT_SUCCESS",
    GET_ERROR_COUNT_FAIL = "GET_ERROR_COUNT_FAIL",

    GET_MONITORING_LIST_START = "GET_MONITORING_LIST_START",
    GET_MONITORING_LIST_SUCCESS = "GET_MONITORING_LIST_SUCCESS",
    GET_MONITORING_LIST_FAIL = "GET_MONITORING_LIST_FAIL",

    RETRY_TROUBLESHOOT_WORKFLOW_START = "RETRY_TROUBLESHOOT_WORKFLOW_START",
    RETRY_TROUBLESHOOT_WORKFLOW_SUCCESS = "RETRY_TROUBLESHOOT_WORKFLOW_SUCCESS",
    RETRY_TROUBLESHOOT_WORKFLOW_FAIL = "RETRY_TROUBLESHOOT_WORKFLOW_FAIL",

    RETRY_TROUBLESHOOT_ACTIVITY_START = "RETRY_TROUBLESHOOT_ACTIVITY_START",
    IGNORE_TROUBLESHOOT_ACTIVITY_START = "IGNORE_TROUBLESHOOT_ACTIVITY_START",
    DELETE_TROUBLESHOOT_ACTIVITY_START = "DELETE_TROUBLESHOOT_ACTIVITY_START",
    TROUBLESHOOT_ACTIVITY_SUCCESS = "TROUBLESHOOT_ACTIVITY_SUCCESS",
    TROUBLESHOOT_ACTIVITY_FAIL = "TROUBLESHOOT_ACTIVITY_FAIL",

    HANDLE_TROUBLESHOOT_DRAWER = "HANDLE_TROUBLESHOOT_DRAWER",

    CHANGE_WORKFLOW_STATE_START = "CHANGE_WORKFLOW_STATE_START",
    CHANGE_WORKFLOW_STATE_SUCCESS = "CHANGE_WORKFLOW_STATE_SUCCESS",
    CHANGE_WORKFLOW_STATE_FAIL = "CHANGE_WORKFLOW_STATE_FAIL",

    GET_WORKFLOW_HISTORY_INIT_START = "GET_WORKFLOW_HISTORY_INIT_START",
    GET_WORKFLOW_HISTORY_INIT_SUCCESS = "GET_WORKFLOW_HISTORY_INIT_SUCCESS",
    GET_WORKFLOW_HISTORY_INIT_FAIL = "GET_WORKFLOW_HISTORY_INIT_FAIL",

    GET_WORKFLOW_STATUS_PROPERTIES_START = "GET_WORKFLOW_STATUS_PROPERTIES_START",
    GET_WORKFLOW_STATUS_PROPERTIES_SUCCESS = "GET_WORKFLOW_STATUS_PROPERTIES_SUCCESS",
    GET_WORKFLOW_STATUS_PROPERTIES_FAIL = "GET_WORKFLOW_STATUS_PROPERTIES_FAIL",
    RESET_WORKFLOW_STATUS_HISTORY = "RESET_WORKFLOW_STATUS_HISTORY"
}