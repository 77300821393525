import moment from 'moment';
import { DateFormats } from 'us.collection.transactions/constants';
import { ITransaction } from 'us.collection.transactions/interfaces';

export function CreditNote(this: ITransaction | any, other?: any) {
	return {
		ArItemNo: this.arItemNo,
		Amount: this.cancellationAmount,
		CancellationItemTypeId: this.cancellationType,
		Description: this.cancellationTypeDescription,
		Voucherdate: moment(this.voucherDate).format(DateFormats.REQ),
	};
}
