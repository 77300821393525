import { IRowProperties } from "us.common/components/CaseBalanceUpdate/Interfaces";

export const rows: Array<IRowProperties> = [
  {
    label: "US.COLLECTION.CASE:ENFORCEMENTS.MAIN_AMOUNT",
    amountLabelName: "mainAmount",
    inputAmountName: "mainAmount",
    interestFromName: "mainAmountFromDate",
    interestToName: "mainAmountToDate",
    interestRateName: "mainAmountRate",
  },
  {
    label: "US.COLLECTION.CASE:ENFORCEMENTS.CASE_COST",
    amountLabelName: "caseCost",
    inputAmountName: "caseCost",
    interestFromName: "caseCostFromDate",
    interestToName: "caseCostToDate",
    interestRateName: "caseCostRate",
  },
  {
    label: "US.COLLECTION.CASE:ENFORCEMENTS.COLLECTION_FEE",
    amountLabelName: "collectionFee",
    inputAmountName: "collectionFee",
    interestFromName: "collectionFeeFromDate",
    interestToName: "collectionFeeToDate",
    interestRateName: "collectionFeeRate",
  },
  {
    label: "US.COLLECTION.CASE:ENFORCEMENTS.COURT_FEE",
    amountLabelName: "courtFee",
    inputAmountName: "courtFee",
    interestFromName: "courtFeeFromDate",
    interestToName: "courtFeeToDate",
    interestRateName: "courtFeeRate",
  },
  {
    label: "US.COLLECTION.CASE:ENFORCEMENTS.OTHER_COSTS",
    amountLabelName: "otherCost",
    inputAmountName: "otherCost",
    interestFromName: "otherCostFromDate",
    interestToName: "otherCostToDate",
    interestRateName: "otherCostRate",
  },
  {
    label: "US.COLLECTION.CASE:ENFORCEMENTS.MAIN_AMOUNT_INTEREST",
    amountLabelName: "mainAmountInterest",
    inputAmountName: "interestOfMainAmount",
    interestFromName: "interestOfMainAmountFromDate",
    interestToName: "interestOfMainAmountToDate",
    interestRateName: "interestOfMainAmountRate",
  },
  {
    label: "US.COLLECTION.CASE:ENFORCEMENTS.INTEREST_OF_COSTS",
    amountLabelName: "interestOfCost",
    inputAmountName: "interestOfCosts",
    interestFromName: "interestOfCostsFromDate",
    interestToName: "interestOfCostsToDate",
    interestRateName: "interestOfCostsRate",
  },
];
