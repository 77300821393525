import {
  dateFormats,
  IconType,
} from "us.collection.routines-and-activities/constants";
import _ from "lodash";
import moment from "moment";
import { matchPath } from "react-router-dom";
import { IMetaData } from "us.collection/interfaces";
import {
  EntityType,
  Type,
  URLType,
  DocumentType,
} from "us.collection.routines-and-activities/constants";
import { NoteType, CreditorNotesAvailability } from "us.collection/constants";
import { EVENT_ACTIVITY_TYPE } from "us.common/constants";
import { appInsights } from "us.helper";
import { ActivityVersion } from "us.common/constants";
import { isArray } from "lodash";
import { i18n } from "us.helper";
type filter = {
  activityCode: string;
  displayName: string;
  description: string;
};

/**
 * @description Format workflow history
 * @param workflowHistory workflow history array
 * @param searchValue selected search value
 * @returns Formatted workflow history
 */
export const formatWorkflowHistory = (
  workflowHistory: any[],
  searchValue?: any
): Array<any> => {
  try {
    const { type = DocumentType.ALL, isHideWorkflowEvents } = searchValue ?? {};

    if (
      (type == DocumentType.ALL || type == "") &&
      !isHideWorkflowEvents &&
      Array.isArray(workflowHistory)
    ) {
      return workflowHistory
        ?.filter(
          ({ stateType }: any) =>
            stateType?.trim()?.toLowerCase() ==
            EVENT_ACTIVITY_TYPE?.toLowerCase()
        )
        .map((workflow: any) => {
          const {
            stackId,
            stateName,
            createdUser,
            status,
            stateType,
            runDate,
          } = workflow ?? {};

          return {
            ...workflow,
            id: stackId,
            name: stateName,
            activityName: stateName,
            date: moment("1910-01-01").isBefore(moment(runDate)) ? runDate : "",
            user: createdUser,
            type: null,
            executedStatus: "",
            isSuccess: true,
            text: status,
            activityType: stateType,
            executedTime: "",
            scheduledTime: "",
            executedUser: createdUser,
            comment: status,
            isWorkflowHistory: true,
          };
        });
    } else {
      return [];
    }
  } catch (error) {
    appInsights.trackException(
      `Workflow history objects format Exception - ${error}`
    );
    return [];
  }
};

/**
 * Format note data
 * @param data Note Data
 * @returns Formatted note data
 */
export const formatNoteHistory = (data: any[]) => {
  try {
    let noteHistory = data?.map((note: any) => {
      const { noteId, noteText, createdDate, createrName } = note;
      return {
        ...note,
        id: noteId,
        name: noteText,
        date: createdDate,
        user: createrName,
        type: "text",
        executedStatus: "text",
        isSuccess: true,
        text: "",
        scheduledTime: "",
      };
    });
    return noteHistory;
  } catch (error) {
    appInsights.trackException(`Note objects format Exception - ${error}`);
    return [];
  }
};

/**
 * Format Activity data
 * @param data activity data
 * @returns Formatted activity data
 */
export const formatActivityHistory = (data: any[]) => {
  try {
    let activityHistory = data?.map((activity: any) => {
      const {
        activityHistoryId,
        activityCode,
        activityName,
        executedTime,
        executedUser,
        messageType,
        recordType,
        isSuccess,
        comment,
        errorMessage,
        type,
        activityVersion,
      } = activity;
      return {
        ...activity,
        id: activityHistoryId,
        name: activityCode ? `${activityCode} - ${activityName}` : activityName,
        version:
          activityVersion == ActivityVersion.V2 ? `${activityVersion}` : ``,
        date: executedTime,
        user: executedUser,
        type: messageType,
        executedStatus: recordType,
        text: isSuccess ? comment : errorMessage,
        isRoutine: type?.toLowerCase() === DocumentType.ROUTINE ? true : false,
      };
    });
    return activityHistory;
  } catch (error) {
    appInsights.trackException(`Note objects format Exception - ${error}`);
    return [];
  }
};

/**
 * Format Document data
 * @param data Document Data
 * @returns Formatted Document data
 */
export const formatDocumentHistory = (data: any[]) => {
  try {
    if (isArray(data)) {
      return data?.map((document: any) => {
        const {
          documentID,
          documentName,
          uploadedDate,
          uploadedBy,
          documentType,
          documentPath,
        } = document ?? {};
        return {
          ...document,
          id: documentID,
          name:
            i18n.t(
              "US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.UPLOAD_DOCUMENT"
            ) +
            " - " +
            documentName.split(".")[0],
          date: uploadedDate,
          user: uploadedBy,
          isUploadDocument: true,
		  type:
		  documentType == '.txt' ? 'text' : documentType.replace(/^\./, ''),
          scheduledTime: "",
          isSuccess: true,
          outputInfo: documentPath,
        };
      });
    } else {
      return [];
    }
  } catch (error) {
    appInsights.trackException(`Note objects format Exception - ${error}`);
    return [];
  }
};

/**
 * Set order for activities
 * @param activities activity data
 * @param searchValue search params
 * @param activityType filter value
 * @returns ordered activity array
 */
const orderActivities = (
  activities: Array<any>,
  searchValue: string,
  activityType?: string | undefined
) => {
  try {
    let formattedPendingActivities;
    let formattedExecutedActivities;
    if (activityType === "activities" || typeof activityType == "undefined") {
      formattedPendingActivities = formatActivityHistory(
        activities?.filter((item: any) => item.recordType === "pending")
      );
      formattedExecutedActivities = formatActivityHistory(
        activities?.filter((item: any) => item.recordType !== "pending")
      );
    }
    if (activityType === "messages") {
      formattedPendingActivities = formatActivityHistory(
        activities?.filter(
          (item: any) =>
            item.recordType === "pending" &&
            item?.activityType?.toLowerCase() === "message"
        )
      );
      formattedExecutedActivities = formatActivityHistory(
        activities?.filter(
          (item: any) =>
            item.recordType !== "pending" &&
            item?.activityType?.toLowerCase() === "message"
        )
      );
    }
    if (activityType === EVENT_ACTIVITY_TYPE) {
      formattedPendingActivities = formatActivityHistory(
        activities?.filter(
          (item: any) =>
            item.recordType === "pending" &&
            item?.activityType?.toLowerCase() ==
              EVENT_ACTIVITY_TYPE?.toLowerCase()
        )
      );
      formattedExecutedActivities = formatActivityHistory(
        activities?.filter(
          (item: any) =>
            item.recordType !== "pending" &&
            item?.activityType?.toLowerCase() ==
              EVENT_ACTIVITY_TYPE?.toLowerCase()
        )
      );
    }
    return [
      ..._.orderBy(
        _.isEmpty(searchValue)
          ? formattedPendingActivities
          : searchActivities(
              searchScheduleDate(
                searchActivityCode(
                  searchCreatedBy(formattedPendingActivities, searchValue),
                  searchValue
                ),
                searchValue
              ),
              searchValue
            ),
        ["scheduledTime"],
        ["asc"]
      ),
      ..._.orderBy(
        _.isEmpty(searchValue)
          ? formattedExecutedActivities
          : searchActivities(
              searchScheduleDate(
                searchActivityCode(
                  searchCreatedBy(formattedExecutedActivities, searchValue),
                  searchValue
                ),
                searchValue
              ),
              searchValue
            ),
        ["date"],
        ["desc"]
      ),
    ];
  } catch (error) {
    appInsights.trackException(`Activity list order Exception - ${error}`);
    return [];
  }
};

/**
 * Order Upload document Data
 * @param documents document data
 * @param searchValue search params
 * @returns ordered document array
 */
const orderDocument = (documents: Array<any>, searchValue: any) => {
	try {
	  if (isArray(documents)) {
		if (searchValue == 'all' || searchValue?.type == 'all') {
		  return formatDocumentHistory(documents);
		} else {
		  return [
			..._.orderBy(
			  documents.filter((document) => document.type === searchValue?.type),
			  ['desc']
			),
		  ];
		}
	  } else {
		return [];
	  }
	} catch (error) {
	  appInsights.trackException(`Activity list order Exception - ${error}`);
	  return [];
	}
  };
  
export const valueFilter = (data: any, value?: any) => {
  let filterValue =
    typeof value?.filterValue === "undefined"
      ? data?.filterValue
      : value?.filterValue;
  let searchValue =
    typeof value?.searchValue === "undefined"
      ? data?.searchValue
      : value?.searchValue;

  const workflowHistory = formatWorkflowHistory(
    data?.workflowHistory,
    searchValue
  );

  if (
    (searchValue.hasOwnProperty("scheduleDate") &&
      searchValue?.scheduleDate?.length > 0) ||
    (searchValue.hasOwnProperty("activityCode") &&
      searchValue?.activityCode?.length > 0)
  ) {
    filterValue = "activities";
  }
  if (
    filterValue == "" &&
    searchValue.hasOwnProperty("type") &&
    (searchValue?.type == "all" || searchValue?.type == "text")
  ) {
    filterValue = searchValue?.type == "all" ? "all" : "caseLogs";
  }
  switch (filterValue) {
    case "caseLogs":
      return _.orderBy(
        _.isEmpty(searchValue)
          ? formatNoteHistory(data?.note?.data)
          : searchNote(
              searchCreatedBy(formatNoteHistory(data?.note?.data), searchValue),
              searchValue
            ),
        ["date"],
        ["desc"]
      );
    case "activities":
      return orderActivities(data?.activity?.data, searchValue, "activities");
    case "messages":
		return [
			..._.orderBy(
			  [
				...orderActivities(data?.activity?.data, searchValue, 'messages'),
				...orderDocument(
				  formatDocumentHistory(data?.document?.data),
				  searchValue
				),
			  ],
			  ['date'],
			  ['desc']
			),
		  ];
    case EVENT_ACTIVITY_TYPE?.toLowerCase():
      return orderActivities(
        [...data?.activity?.data, ...workflowHistory],
        searchValue,
        EVENT_ACTIVITY_TYPE
      );
    case NoteType.CP_NOTE:
      return _.orderBy(
        _.isEmpty(searchValue)
          ? formatNoteHistory(data?.creditorPortalNote?.data)
          : searchNote(
              searchCreatedBy(
                formatNoteHistory(data?.creditorPortalNote?.data),
                searchValue
              ),
              searchValue
            ),
        ["date"],
        ["desc"]
      );
    case "all":
      return [
        ..._.orderBy(
          _.isEmpty(searchValue)
            ? formatActivityHistory(
                data?.activity?.data?.filter(
                  (item: any) => item.recordType === "pending"
                )
              )
            : searchActivities(
                searchScheduleDate(
                  searchActivityCode(
                    searchCreatedBy(
                      formatActivityHistory(
                        data?.activity?.data?.filter(
                          (item: any) => item.recordType === "pending"
                        )
                      ),
                      searchValue
                    ),
                    searchValue
                  ),
                  searchValue
                ),
                searchValue
              ),
          ["scheduledTime"],
          ["asc"]
        ),
        ..._.orderBy(
          _.isEmpty(searchValue)
            ? [
                ...formatActivityHistory(
                  data?.activity?.data?.filter(
                    (item: any) => item.recordType !== "pending"
                  )
                ),
                ...workflowHistory,
                ...formatNoteHistory(data?.note?.data),
                ...formatNoteHistory(data?.creditorPortalNote?.data),
				...formatDocumentHistory(data?.document?.data),
              ]
            : [
                ...searchActivities(
                  searchScheduleDate(
                    searchActivityCode(
                      searchCreatedBy(
                        formatActivityHistory(
                          data?.activity?.data?.filter(
                            (item: any) => item.recordType !== "pending"
                          )
                        ),
                        searchValue
                      ),
                      searchValue
                    ),
                    searchValue
                  ),
                  searchValue
                ),
                ...workflowHistory,
                ...searchNote(
                  searchCreatedBy(
                    formatNoteHistory(data?.note?.data),
                    searchValue
                  ),
                  searchValue
                ),
                ...searchNote(
                  searchCreatedBy(
                    formatNoteHistory(data?.creditorPortalNote?.data),
                    searchValue
                  ),
                  searchValue
                ),
				...orderDocument(
					formatDocumentHistory(data?.document?.data),
					searchValue
				  ),
              ],
          ["date"],
          ["desc"]
        ),
      ];
    default:
      return orderActivities(data?.activity?.data, searchValue);
  }
};

/**
 * Saerch Note
 * @param data Note Data
 * @param search search params
 * @returns Search result as array
 */
const searchNote = (data: any, search: any): any => {
  try {
    let temArr: any[] = [];
    if (search?.type?.length > 0) {
      if (search?.type === 'text' || search?.type === 'all') {
        data?.map((item: any) => {
          if (search?.name?.length > 0) {
            if (search?.date?.length > 0) {
              if (
                item?.name
                  ?.toLocaleLowerCase()
                  .includes(search?.name?.toLocaleLowerCase()) &&
                moment(item?.date).isSame(moment(search?.date), "days")
              ) {
                temArr.push(item);
              }
            } else if (
              item?.name
                ?.toLocaleLowerCase()
                .includes(search?.name?.toLocaleLowerCase())
            ) {
              temArr.push(item);
            }
          } else if (search?.date?.length > 0) {
            if (moment(item?.date).isSame(moment(search?.date), 'days')) {
              temArr.push(item);
            }
          } else {
            temArr = formatNoteHistory(data);
          }
        });
      } else {
        temArr = [];
      }
    } else {
      if (search?.name?.length > 0 || search?.date?.length > 0) {
        data?.map((item: any) => {
          if (search?.name?.length > 0) {
            if (search?.date?.length > 0) {
              if (
                item?.name
                  ?.toLocaleLowerCase()
                  .includes(search?.name?.toLocaleLowerCase()) &&
                moment(item?.date).isSame(moment(search?.date), "days")
              ) {
                temArr.push(item);
              }
            } else if (
              item?.name
                ?.toLocaleLowerCase()
                .includes(search?.name?.toLocaleLowerCase())
            ) {
              temArr.push(item);
            }
          } else if (search?.date?.length > 0) {
            if (moment(item?.date).isSame(moment(search?.date), "days")) {
              temArr.push(item);
            }
          }
        });
      } else {
        temArr = formatNoteHistory(data);
      }
    }
    return temArr;
  } catch (error) {
    appInsights.trackException(`Note search Exception - ${error}`);
    return [];
  }
};

/**
 * Search activities
 * @param data Activity Data
 * @param search search parameter
 * @returns search result as Array
 */
const searchActivities = (data: any, search: any) => {
  try {
    let temArr: any[] = [];
    if (
      search?.name?.length > 0 ||
      search?.date?.length > 0 ||
      search?.type?.length > 0
    ) {
      data?.map((item: any) => {
        if (
          search?.name?.length > 0 &&
          search?.date?.length > 0 &&
          search?.type?.length > 0
        ) {
          if (
            item?.name
              ?.toLocaleLowerCase()
              .includes(search?.name?.toLocaleLowerCase()) &&
            moment(item?.date).isSame(moment(search?.date), "days") &&
            (item?.type === search?.type || search?.type === "all")
          ) {
            temArr.push(item);
          }
        } else if (search?.name?.length > 0) {
          if (search?.date?.length > 0) {
            if (
              item?.name
                ?.toLocaleLowerCase()
                .includes(search?.name?.toLocaleLowerCase()) &&
              moment(item?.date).isSame(moment(search?.date), "days")
            ) {
              temArr.push(item);
            }
          } else if (search?.type?.length > 0) {
            if (
              item?.name
                ?.toLocaleLowerCase()
                .includes(search?.name?.toLocaleLowerCase()) &&
              (item?.type === search?.type || search?.type === "all")
            ) {
              temArr.push(item);
            }
          } else {
            if (
              item?.name
                ?.toLocaleLowerCase()
                .includes(search?.name?.toLocaleLowerCase())
            ) {
              temArr.push(item);
            }
          }
        } else if (search?.date?.length > 0) {
          if (search?.type?.length > 0) {
            if (
              moment(item?.date).isSame(moment(search?.date), "days") &&
              (item?.type === search?.type || search?.type === "all")
            ) {
              temArr.push(item);
            }
          } else {
            if (moment(item?.date).isSame(moment(search?.date), "days")) {
              temArr.push(item);
            }
          }
        } else if (search?.type?.length > 0) {
          if (item?.type === search?.type || search?.type === "all") {
            temArr.push(item);
          }
        }
      });
    } else {
      temArr = formatActivityHistory(data);
    }
    return temArr;
  } catch (error) {
    appInsights.trackException(`Activity search Exception - ${error}`);
    return [];
  }
};

/**
 * Search history by created person's username
 * @param data History data
 * @param search search params
 * @returns search result as array
 */
const searchCreatedBy = (data: any, search: any) => {
  try {
    let value = search?.createdBy;
    if (typeof value === "undefined" || search?.createdBy?.length === 0) {
      return data;
    } else {
      return data?.filter((item: any) =>
        item?.user
          ?.toLocaleLowerCase()
          .includes(search?.createdBy?.toLocaleLowerCase())
      );
    }
  } catch (error) {
    appInsights.trackException(
      `History search by created person's usename Exception - ${error}`
    );
    return [];
  }
};

/**
 * Search history by activity code
 * @param data History data
 * @param search search params
 * @returns search result
 */
const searchActivityCode = (data: any, search: any) => {
  try {
    let value = search?.activityCode;
    if (typeof value === "undefined" || search?.activityCode?.length === 0) {
      return data;
    } else {
      return data?.filter((item: any) =>
        item?.activityCode?.includes(search?.activityCode)
      );
    }
  } catch (error) {
    appInsights.trackException(
      `History search by activityCode Exception - ${error}`
    );
    return [];
  }
};

/**
 * History search by schedule date
 * @param data History data
 * @param search search params
 * @returns Search result
 */
const searchScheduleDate = (data: any, search: any) => {
  try {
    let value = search?.scheduleDate;
    if (typeof value === "undefined" || search?.scheduleDate?.length === 0) {
      return data;
    } else {
      return data?.filter((item: any) =>
        moment(item?.scheduledTime).isSame(moment(search?.scheduleDate), "days")
      );
    }
  } catch (error) {
    appInsights.trackException(
      `History search by scheduledate Exception - ${error}`
    );
    return [];
  }
};

export const setOptions = (values: any) => {
  return values.map((item: any) => {
    return {
      label: item.value,
      value: item.id,
    };
  });
};

/**
 * Create data object for dynamically render fields
 * @param data
 * @returns
 */
export const setTableData = (data: any) => {
  try {
    let tem: any = [];
    let EntityID = {
      visible: false,
      value: "",
    };
    let Username = {
      visible: false,
      value: "",
    };

    data?.map((item: any) => {
      if (item.valueType?.toLowerCase() == "list") {
        tem.push({
          ...item,
          field: "select",
          options: setOptions(item?.value),
        });
      } else {
        if (
          item?.name?.toLowerCase() === "entityid" ||
          item?.name?.toLowerCase() === "entityno"
        ) {
          EntityID = {
            visible: true,
            value: item?.value,
          };
        } else if (item?.name?.toLowerCase() === "username") {
          Username = {
            visible: true,
            value: item?.value,
          };
        }
        let field: string;
        switch (item?.parameterType?.toLowerCase()) {
          case "boolean":
            field = "switch";
            break;
          case "date":
            field = "datePicker";
            break;
          case "datetime":
            field = "datePicker";
            break;
          case "int":
            field = "input";
            break;
          case "string":
            field = "input";
            break;
          default:
            field = "input";
            break;
        }

        tem.push({
          ...item,
          field,
          EntityID,
          Username,
        });
      }
    });
    return tem;
  } catch (error) {
    appInsights.trackException(
      `Create dynamically table data Exception - ${error}`
    );
    return [];
  }
};

/**
 * Set initial value for parameterized activity
 * @param data parameters of activity
 * @param state state data -{caseNumber, userName}
 * @returns
 */
export const setInitialValue = (data: any, state: any) => {
  let result: any = {};
  try {
    data?.map((item: any) => {
      if (
        item["name"]?.toLowerCase() === "entityid" ||
        item["name"]?.toLowerCase() === "entityno" ||
        item["name"]?.toLowerCase() === "username"
      ) {
        result[item["name"]] =
          item["name"]?.toLowerCase() === "entityid" ||
          item["name"]?.toLowerCase() === "entityno"
            ? state?.userAndCase?.caseNumber
            : state?.userAndCase?.user;
      } else {
        result[item["name"]] =
          item?.valueType?.toLowerCase() === "list" ? "" : item["value"];
      }
    });
    return result;
  } catch (error) {
    appInsights.trackException(
      `Initial value for parameterized activity Exception - ${error}`
    );
    return result;
  }
};

/**
 * Set activity drop down
 * @param values acitvity list
 * @returns
 */
export const setAutoOptions = (values: any) => {
  try {
    return values.map((item: any) => {
      const { displayName, activityCode, name, activityId } = item;
      return {
        label: displayName,
        value: activityCode,
        name: name,
        activityId: activityId,
      };
    });
  } catch (error) {
    appInsights.trackException(
      `Dynamically set activity drop down Exception - ${error}`
    );
    return [];
  }
};

/**
 * set group list for activity table
 * @param values activity data
 * @returns
 */
export const setGroupsOptions = (values: any) => {
  try {
    let result: [] = values.map((item: any) => {
      return {
        label: item.activityGroup,
        value: item.activityGroupId,
      };
    });
    return _.uniqBy(result, "label");
  } catch (error) {
    appInsights.trackException(
      `Group list for activity table Exception - ${error}`
    );
    return [];
  }
};

/**
 * Filter data according to activity group name
 * @param data Activity data
 * @param filterValue filter data
 * @returns
 */
export const filterData = (data: any, filterValue: string | number = "all") => {
  try {
    if (filterValue === "all") {
      return data?.values;
    } else {
      return data?.values?.filter(
        (item: any) => item?.activityGroupId === filterValue
      );
    }
  } catch (error) {
    appInsights.trackException(
      `Filter data according to activity group name Exception - ${error}`
    );
    return [];
  }
};

/**
 * Set activity data for group table
 * @param values activity data
 * @returns
 */
export const setActivitiesData = (values: any) => {
  try {
    let result: any = [];
    Object.values(_.groupBy(values, "type")).map((item: any, index: number) => {
      result.push({
        key: index,
        group: item[0].type,
        activityCode: "",
        displayName: "",
        name: "",
        children: item,
      });
    });
    return result;
  } catch (error) {
    appInsights.trackException(
      `Set activity data for group tableException - ${error}`
    );
    return [];
  }
};

export const getLookUpTypeByCaseType = (
  caseType: string,
  entityType: string
) => {
  switch (caseType) {
    case URLType.CASE:
      return entityType;
    case URLType.AR:
      return Type.AR;
    case URLType.CREDITOR:
      return Type.CREDITOR;
    case URLType.BUREAU:
      return "B";
    case URLType.PAYMENT:
      return Type.PAYMENT;
    default:
      return "";
  }
};

export const attachementType = (data: string): string => {
  let type: string = "application/pdf";
  switch (data?.toLowerCase()) {
    case DocumentType.PDF:
      type = "application/pdf";
      break;
    case DocumentType.DOC:
      type = "text/doc";
      break;
    case DocumentType.HTML:
      type = "text/html";
      break;
    case DocumentType.PNG:
      type = "image/png";
      break;
    case DocumentType.JPG:
      type = "image/jpg";
      break;
    case DocumentType.JPEG:
      type = "image/jpeg";
      break;
    case DocumentType.TXT:
      type = "text/plain";
      break;
    case DocumentType.XML:
      type = "application/xml";
      break;
    default:
      break;
  }
  return type;
};

/**
 * Filter activities
 * @param activities history data
 * @param columnFilters filter params
 * @returns filtered data
 */
export const filterActivities = (activities: any, columnFilters: filter) => {
  let temArr: Array<any> = [];
  try {
    if (
      columnFilters.activityCode === "" &&
      columnFilters.displayName === "" &&
      columnFilters.description === ""
    ) {
      temArr = activities;
    } else {
      activities?.map((item: any) => {
        let temChildren: Array<any> = [];
        item?.children?.map((ch: any) => {
          if (
            columnFilters.activityCode != "" &&
            columnFilters.displayName != "" &&
            columnFilters.description != ""
          ) {
            if (
              columnFilters.activityCode === ch?.activityCode &&
              ch?.displayName
                ?.toLowerCase()
                .includes(columnFilters.displayName.toLowerCase()) &&
              ch?.description
                ?.toLowerCase()
                .includes(columnFilters.description.toLowerCase())
            ) {
              temChildren.push(ch);
            }
          } else if (
            columnFilters.activityCode != "" &&
            columnFilters.displayName != ""
          ) {
            if (
              columnFilters.activityCode === ch?.activityCode &&
              ch?.displayName
                ?.toLowerCase()
                .includes(columnFilters.displayName.toLowerCase())
            ) {
              temChildren.push(ch);
            }
          } else if (columnFilters.activityCode != "") {
            if (columnFilters.activityCode === ch?.activityCode) {
              temChildren.push(ch);
            }
          } else if (
            columnFilters.displayName != "" &&
            columnFilters.description != ""
          ) {
            if (
              ch?.displayName
                ?.toLowerCase()
                .includes(columnFilters.displayName.toLowerCase()) &&
              ch?.description
                ?.toLowerCase()
                .includes(columnFilters.description.toLowerCase())
            ) {
              temChildren.push(ch);
            }
          } else if (columnFilters.displayName != "") {
            if (
              ch?.displayName
                ?.toLowerCase()
                .includes(columnFilters.displayName.toLowerCase())
            ) {
              temChildren.push(ch);
            }
          } else if (columnFilters.description != "") {
            if (
              ch?.description
                ?.toLowerCase()
                .includes(columnFilters.description.toLowerCase())
            ) {
              temChildren.push(ch);
            }
          }
        });
        if (temChildren?.length > 0) {
          temArr.push({
            ...item,
            children: _.uniqBy(temChildren, "activityCode"),
          });
        }
      });
    }
    return temArr;
  } catch (error) {
    appInsights.trackException(`Filter activities table Exception - ${error}`);
    return temArr;
  }
};

/**
 * Set case datas
 * @param location route object
 * @param metaData meta data for case
 * @returns
 */
export const getCaseNumberAndType = (
  location: any,
  metaData: IMetaData
): {
  type: string | undefined;
  caseNo: string;
  eType: string;
  url: string;
  id: string;
} => {
  try {
    const { caseId, entityType } = metaData ?? {};
    const { creditorId } = location.state ?? {};
    const { params }: any = matchPath(location.pathname, {
      path: "/:caseType/:caseId?/:module?",
      exact: true,
      strict: false,
    });
    let caseType = params["caseType"];
    let caseNo = params["caseId"];
    let type: string | undefined = "All";
    let eType: string = "";
    let url: string = "";
    const id: string = params["caseId"];
    switch (caseType) {
      case URLType.SUBCASE:
        type = Type.SUBCASE;
        eType = EntityType.SUBCASE;
        url = URLType.SUBCASE;
        break;
      case URLType.CASE:
        if (entityType === "S") {
          eType = EntityType.SUBCASE;
        } else {
          eType = EntityType.CASE;
        }
        type = entityType;
        url = URLType.CASE;
        caseNo = caseId;
        break;
      case URLType.AR:
        type = Type.AR;
        eType = EntityType.AR;
        url = URLType.AR;
        break;
      case URLType.CREDITOR:
        type = Type.CREDITOR;
        eType = EntityType.CREDITOR;
        url = URLType.CREDITOR;
        caseNo = creditorId;
        break;
      case URLType.PAYMENT:
        type = Type.PAYMENT;
        eType = EntityType.PAYMENT;
        url = URLType.PAYMENT;
        break;
      case URLType.BUREAU:
        type = Type.BUREAU;
        eType = EntityType.BUREAU;
        url = URLType.BUREAU;
        caseNo = 0;
        break;
      default:
        type = Type.ALL;
        eType = "";
        break;
    }
    return {
      type,
      caseNo,
      eType,
      url,
      id,
    };
  } catch (error) {
    appInsights.trackException(`Case data Exception - ${error}`);
    return {
      type: "",
      caseNo: "",
      eType: "",
      url: "",
      id: "",
    };
  }
};

/**
 * @description Get icon name by activity type and type of history item
 * @param activityType - `activityType` field from history item
 * @param type - `type` field from history item
 * @returns styleTerm
 */
export const getStyleTermByExecutedStatus = (
  executedStatus: string
): string => {
  let styleTerm: string = IconType.DELIVERED;
  try {
    switch (executedStatus?.toLowerCase()) {
      /**
       * note
       */
      case IconType.TEXT:
        styleTerm = IconType.NOTE;
        break;

      /**
       * pending item
       */
      case IconType.PENDING:
        styleTerm = IconType.PENDING;
        break;
      /**
       * executed item
       */
      case IconType.EXECUTED:
        styleTerm = IconType.DELIVERED;
        break;

      default:
        break;
    }
    return styleTerm;
  } catch (error) {
    return styleTerm;
  }
};

/**
 * @description Get icon name by activity type and type of history item
 * @param activityType - `activityType` field from history item
 * @param type - `type` field from history item
 * @returns icon name
 */
export const getIconByActivityType = (
  activityType: string,
  type: string
): string => {
  try {
    if (activityType?.toLowerCase() === DocumentType.MESSAGE) {
      switch (type?.toLowerCase()) {
        case DocumentType.PDF:
          return DocumentType.PDF;
        case DocumentType.SMS:
          return DocumentType.SMS;
        case DocumentType.EMAIL:
          return DocumentType.EMAIL;
        case DocumentType.HTML:
          return DocumentType.HTML;
        case DocumentType.TEXT:
          return DocumentType.TEXT;
        case DocumentType.XML:
          return DocumentType.XML;
        default:
          return DocumentType.UNKNOWN;
      }
    } else if (
      activityType?.toLowerCase() == EVENT_ACTIVITY_TYPE?.toLowerCase()
    ) {
      return DocumentType.EVENTS;
    } else {
      return DocumentType.SP;
    }
  } catch (error) {
    return DocumentType.UNKNOWN;
  }
};

/**
 * @description Get icon name by type of document history item
 * @param type - `type` field from document history item
 * @returns icon name
 */
export const getIconForUploadDocument = (type: string): string => {
  try {
    switch (type?.toLowerCase()) {
      case DocumentType.PDF:
        return DocumentType.PDF;
      case DocumentType.HTML:
        return DocumentType.HTML;
      case DocumentType.TEXT:
        return DocumentType.TEXT;
      case DocumentType.XML:
        return DocumentType.XML;
      default:
        return DocumentType.UNKNOWN;
    }
  } catch (error) {
    return DocumentType.UNKNOWN;
  }
};
/**
 * @description - Convert base64 to XML string
 * @param base64 - Base64 code
 * @returns String xml content
 */
export const base64ToXmlString = (base64: string) => {
  try {
    const xmlString = atob(base64);
    return xmlString.substring(xmlString.indexOf("<?xml"));
  } catch (error) {
    return "";
  }
};

/**
 * @description - Remove deleted elements from list
 * @param noteList - Note array
 * @param deletedId - Id for deleted elements
 * @returns Notes without deleted elements
 */
export const removeDeletedNote = (
  noteList: Array<any> | {},
  deletedId: string | number
): Array<any> => {
  try {
    if (Array.isArray(noteList)) {
      return noteList?.filter(
        ({ noteId }: { noteId: string }) => noteId !== deletedId
      );
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

/**
 * @description - Get PDF file base64 content from the given xml file string
 * @param xmlString - XML file content as string
 * @returns Base64 string which can decode to view a PDF
 */
export const getPDFFromXML = (xmlString: string): string => {
  try {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");
    if (xmlDoc) {
      const pdfBase64 = xmlDoc.getElementsByTagName(
        "cbc:EmbeddedDocumentBinaryObject"
      )[0].textContent;
      return pdfBase64 ?? "";
    }
    return "";
  } catch (error) {
    return "";
  }
};

/**
 * @description - Change extension of an any file name by using given extension
 * @param documentName - Original name of the document with or without extension
 * @param extension - New file extension to be change
 * @returns New file name with proposed extension
 */
export const changeExtension = (
  documentName: string,
  extension: string
): string => {
  try {
    const extensionIndex = documentName.lastIndexOf(".");
    if (extensionIndex === -1) {
      return documentName;
    } else {
      const fileNameWithoutExtension = documentName.substring(
        0,
        extensionIndex
      );

      return `${fileNameWithoutExtension}.${extension}`;
    }
  } catch (error) {
    return documentName;
  }
};

/**
 * Check edit access for user note
 * @param record Note
 * @azureName user name
 * @returns can edit or not
 */
export const isEditableUserNote = (record: any, azureName: string) => {
  try {
    return (
      moment(record.createdDate).format(dateFormats.req) ==
        moment().format(dateFormats.req) &&
      azureName.replace(/\s+/g, " ").trim() == record.createdBy
    );
  } catch (error) {
    return false;
  }
};

/**
 * @description - Get suitable width for the drawer according to the document type
 * @param {DocumentType} type - Document Type
 */
export const getDrawerWidthByDocumentType = (type: DocumentType) => {
  switch (type) {
    case DocumentType.HTML:
    case DocumentType.EMAIL:
      return "830px";
    default:
      return "75%";
  }
};

/**
 * @description - Format history log data
 * @param historyData - history data
 * @param enableCreditorPortalNotes - BMD status of CP note visibility
 * @returns Formatted history data
 */
export const formatLogHistory = (
  historyData: any,
  enableCreditorPortalNotes: CreditorNotesAvailability | undefined
): object => {
  try {
    const { noteHistory, activityHistory = [] } = historyData ?? {};
    if (historyData) {
      if (Array.isArray(noteHistory)) {
        return {
          ...historyData,
          activityHistory,
          noteHistory: noteHistory.filter(
            ({ noteType }: { noteType: string }) => noteType != NoteType.CP_NOTE
          ),
          creditorPortalNotes:
            enableCreditorPortalNotes == CreditorNotesAvailability.YES
              ? noteHistory.filter(
                  ({ noteType }: { noteType: string }) =>
                    noteType == NoteType.CP_NOTE
                )
              : [],
        };
      } else {
        return {
          ...historyData,
          activityHistory,
          noteHistory: [],
          creditorPortalNotes: [],
        };
      }
    } else {
      return {
        noteHistory: [],
        creditorPortalNotes: [],
        activityHistory: [],
      };
    }
  } catch (error) {
    return {
      noteHistory: [],
      creditorPortalNotes: [],
      activityHistory: [],
    };
  }
};

/**
 * @description - Remove selected history types from options array
 * @param historyOptions - History options list
 * @param enableCreditorPortalNotes - BMD status of CP note visibility
 * @returns Filtered options list
 */
export const filterHistoryTypesByBMDValue = (
	historyOptions: Array<any>,
	enableCreditorPortalNotes: CreditorNotesAvailability
  ): Array<any> => {
	try {
	  if (Array.isArray(historyOptions)) {
		return historyOptions?.filter(({ value }: { value: string }) =>
		  enableCreditorPortalNotes != CreditorNotesAvailability.YES
			? value != NoteType.CP_NOTE
			: true
		);
	  } else {
		return [];
	  }
	} catch (error) {
	  return [];
	}
  };

  /**
 * @description - Convert base64 to byte array
 * @param {string} base64 - base64 string
 */
const base64ToArrayBuffer = (base64: string): ArrayBufferLike => {
	var binaryString = atob(base64);
	var bytes = new Uint8Array(binaryString.length);
	for (var i = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}
	return bytes.buffer;
};

/**
 * @description - Convert base64 to HTML string using iso-8859-1
 * @param {string} base64Content - base64 string
 */
export const base64ToHtml = (base64Content: string): string => {
	try {
		const textEncoder = new TextDecoder();
		const arrayBuffer = base64ToArrayBuffer(base64Content);
		let encoded = textEncoder.decode(arrayBuffer);
		return encoded;
	} catch (error) {
		return `<></>`;
	}
};

/**
 * @description - Update notify status of user viewed note
 * @param notes - List of notes data
 * @param noteId - Viewed note id
 * @returns Updated notes data
 */
export const updateNoteNotifyStatus = (
  notes: Array<any> | {},
  noteId: number
): Array<any> => {
  try {
    if (Array.isArray(notes)) {
      return notes.map((note: any)=> {
        return note.noteId == noteId ? { ...note, notified: true } : note;
      })
    } else {
      return [];
    }
  } catch (error) {
    return Array.isArray(notes) ? notes : [];
  }
};