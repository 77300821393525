import * as Yup from 'yup';

interface IGDPR extends Yup.MixedSchema {
    comment: string,
}
const GDPRValidation = Yup.object<IGDPR>().shape({
    comment: Yup.string()
            .test('comment', 'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD', 
                (val: any )=> {
                let value = typeof val == "undefined" ? '' : val;
                    return (
                        value.toString().trim().length > 0
                    )
                }
            )
            .required('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD')    
});

export default GDPRValidation;
