import { IInitialState } from 'us.collection.creditor/reducers/Creditor/Interfaces';

export const initialState: IInitialState = {
    general: {
        data: {},
        isLoading: false
    },
    otherContacts: {
        data: [],
        isLoading: false
    },
    creditors: {
        data: [],
        isLoading: false
    },
    creditorBMDs:{
        data:{},
        isLoading: false
    },
    caseList: {
        data : [],
        isLoading: false
    },
    filterList: {
        data : [],
        isLoading: false
    }
}

