import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Formik, useField } from "formik";
import Common from "us.common";
import { useTranslation } from "react-i18next";
import { PlusOutlined, SearchOutlined } from "us.icons";
import { IOnFilter, IOnSort } from "us.common/components/antd/TableTree";
import "./SelectDebtor.scss";
import TableColumns from "./TableColumns/TableColumns";
import { $AutoComplete, $Drawer } from "us.common/components";
import { Input } from "antd";
import * as Actions from "us.common/actions";
import { SearchFields, searchOption } from "../Constants";
import { IDebtorSelection } from "../../Interfaces";
import { NewDebtor } from "us.common/components";
import { getFieldType, getSearchOptionList } from "./Functions";
import { DebtorSearch } from "us.common/repository";
import { CustId } from "../CustId";

const { $Select, $Button, $TableTree, $Skeleton } = Common.Components;

/**
 * @description - Component for debtor selection form
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2961113115/Select+Debtor+-+Common+UI+Implementation
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 02/05/2022
 * */

const DebtorSelection: React.FC<IDebtorSelection> = (props) => {
  const { t } = useTranslation();
  const {
    name,
    onSelect,
    getDebtors,
    debtors,
    resetDebtorList,
    onDrawerClose,
    debtorDrawer,
    addDebtorDrawer,
    saveDebtor,
    addNewDebtor
  } = props;

  const [field, , helpers] = useField(name);

  const [selectedDebtorDetails, setSelectedDebtorDetails] = useState<any>({});
  const [isNewCustId, setISNewCustId] = useState<boolean>(false);

  /**
   * @description - Reset debtor table
   */
  useEffect(() => {
    resetDebtorList && resetDebtorList({});
  }, []);

  /**
   * @description - Submit selected debtor
   */
  const handleSelectDebtorSubmit = (newCustId?: Number) => {
    if((selectedDebtorDetails.custId == -1 || selectedDebtorDetails.custId == "") && !newCustId){
      setISNewCustId(true);
    }
    else{
      helpers.setValue({
        ...selectedDebtorDetails,
        custId: newCustId ? newCustId : selectedDebtorDetails.custId
      });
      onSelect && onSelect({
        ...selectedDebtorDetails,
        custId: newCustId ? newCustId : selectedDebtorDetails.custId
      });
      setISNewCustId(false);
    }
  };


  /**
   * @description -  Handle row selection in table
   * @param {object} setFieldValue - Formik properties
   */
  const rowSelection = (
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => ({
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedDebtorDetails(selectedRows[0]);
      setFieldValue("selectedDebtor", selectedRows[0].custId ?? "", false);
    },
  });

  /**
   * @description - Add search value to search option list
   * @param {string} searchValue - Search value
   * @param setValues - Formik properties
   */
  const handleAutoCompleteChange = (
    searchValue: string,
    setValues: any
  ): void => {
    setValues((preValues: any) => {
      return {
        ...preValues,
        searchOption: getSearchOptionList(searchOption, searchValue)
      };
    });
  };

  /**
   * Search api call
   */
  let isQuickFirstCall = true;
  /**
   * @description - Search debtor or other party
   * @param searchValue - Search text
   */

  const handleOnPressSearch = (searchValue: string): void => {
    if (!isQuickFirstCall) {
      return;
    }
    setSelectedDebtorDetails({});
    const CRITERIA_SEPERATOR: string = ":";
    let firstSearchValue: string,
      otherSearchValues: string[],
      isNumeric: boolean,
      fieldType: string;
    if (searchValue.includes(CRITERIA_SEPERATOR)) {
      const [searchCriteria, ...searchValues] = searchValue.split(CRITERIA_SEPERATOR);
      fieldType = getFieldType(searchCriteria) || SearchFields.OTEHR_PARTY_FREE_TEXT;
      isNumeric = !isNaN(parseInt(searchValues.join(" ")));
      [firstSearchValue, ...otherSearchValues] = searchValues.join(CRITERIA_SEPERATOR).split(" ");
    } else {
      fieldType = SearchFields.OTEHR_PARTY_FREE_TEXT;
      isNumeric = !isNaN(parseInt(searchValue));
      [firstSearchValue, ...otherSearchValues] = searchValue.split(" ");
    }
    getDebtors &&
      getDebtors(DebtorSearch.call({ firstSearchValue, otherSearchValues, isNumeric, fieldType }));

    isQuickFirstCall = false;
    setTimeout(() => {
      isQuickFirstCall = true;
    }, 500);
  };

  /**
   * @description - Open add debtor drawer
   */
  const handleAddDebtor = () => {
    debtorDrawer &&
      debtorDrawer({
        title: t("US.COLLECTION.COMMON:COMMON.ADD_DEBTOR"),
        visible: true,
      });
  };

  /**
   * @description - Close add debtor drawer
   */
  const handleAddDebtorClose = () => {
    debtorDrawer && debtorDrawer({ title: "", visible: false });
  };

  const addDebtor = (data: any) => {
    saveDebtor && saveDebtor(data);
    addNewDebtor && addNewDebtor(data);
  }

  const handleSort: IOnSort = (sortData, dataSource) => {
    return sortData(dataSource);
  };

  const handleFilter: IOnFilter = (searchData, dataSource) => {
    return searchData(dataSource);
  };

  return (
    <Formik
      initialValues={{
        extraColumns: [],
        selectedDebtor: "",
        searchOption: searchOption,
      }}
      onSubmit={() => handleSelectDebtorSubmit()}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        setFieldValue,
        setValues,
        ...restProps
      }: any) => (
        <div className="select-debtor">
          <div>
            <$Button
              type="dashed"
              size="small"
              onClick={handleAddDebtor}
              icon={<PlusOutlined />}
              data-testid="add-new-debtor-button"
            >
              {t("US.COLLECTION.DEBTOR:SELECT_DEBTOR.NEW_DEBTOR")}
            </$Button>
          </div>

          <div className="mt-3 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center flex-grow-1">
              <$AutoComplete
                name="search"
                backfill
                data-testid="search-debtor"
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ width: 300 }}
                listHeight="500px"
                style={{ width: "400px" }}
                size="small"
                options={values?.searchOption}
                onChange={(searchValue: string) => {
                  handleAutoCompleteChange(searchValue, setValues);
                }}
                onSelect={(text:any) => handleOnPressSearch(text)}
                addonAfter={<SearchOutlined />}
              >
                <Input.Search
                  placeholder={t(
                    "US.COLLECTION.COMMON:COMMON.FILTER_BY_NUMBER_AND_NAME"
                  )}
                  size="small"
                  allowClear={true}
                  onSearch={(text:any)=>handleOnPressSearch(text)}
                />
              </$AutoComplete>
              {false && (
                <$Select
                  name="type"
                  size="small"
                  defaultValue="all"
                  allOption={false}
                  options={[
                    {
                      label: t("US.COLLECTION.DEBTOR:SELECT_DEBTOR.ALL"),
                      value: "all",
                    },
                  ]}
                  className="ml-3"
                  style={{ width: 150 }}
                />
              )}
            </div>
            <div className="d-flex align-items-center">
              <$Select
                name="extraColumns"
                size="small"
                data-testid="extra-coloum-selection"
                options={[
                  {
                    label: t("US.COLLECTION.COMMON:COMMON.POSTAL_CODE"),
                    value: "zipCode",
                  },
                  {
                    label: t("US.COLLECTION.COMMON:COMMON.POSTAL_AREA"),
                    value: "zipName",
                  },
                ]}
                mode="multiple"
                placeholder={t("US.COLLECTION.COMMON:COMMON.SELECT_COLUMNS")}
                allOption={false}
                style={{ width: 300 }}
                className="ml-2"
              />
            </div>
          </div>
          <$Skeleton loading={debtors.isLoading} active paragraph={{ rows: 2 }}>
            <$TableTree
              rowKey="key"
              data={debtors.data}
              size="small"
              className="select-debtor-table mt-3"
              onSort={handleSort}
              onFilter={handleFilter}
              filterOnType={true}
              resetOnSourceChange={true}
              bordered
              pagination={{ defaultPageSize: 200 }}
              scroll={{ x: 920, y: "calc(100vh - 365px)" }}
              columns={TableColumns(values?.extraColumns)}
              rowSelection={{ type: "radio", ...rowSelection(setFieldValue) }}
              firstColSkipFilterProps={-1}
            />
          </$Skeleton>

          <div className="drawer-footer-fixed align-content-center justify-content-between">
            <div className="selected-debtor">
              {selectedDebtorDetails?.custId && (
                <>
                  <div>
                    <strong>{selectedDebtorDetails?.custId}</strong>
                  </div>
                  <div>
                    <small>
                      {t("US.COLLECTION.DEBTOR:SELECT_DEBTOR.SELECTED_DEBTOR")}
                    </small>
                  </div>
                </>
              )}
            </div>
            <div>
              <$Button
                data-testid="save-button"
                className="ml-3 mr-2"
                type="primary"
                onClick={handleSubmit}
                disabled={!selectedDebtorDetails.key}
              >
                {t("US.COLLECTION.COMMON:COMMON.SELECT")}
              </$Button>
              <$Button 
                data-testid="cancel-button"
                onClick={onDrawerClose}
              >
                {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
              </$Button>
            </div>
          </div>
          <$Drawer
            title={addDebtorDrawer.title}
            width={"auto"}
            visible= {addDebtorDrawer.visible}
            onClose={handleAddDebtorClose}
            destroyOnClose
            className="other-party-edit"
          >
            <NewDebtor
              isAddOtherParty={false}
              onClose={handleAddDebtorClose}
              onSubmit={ (e: any) => addDebtor(e) }
            />
          </$Drawer>
          <$Drawer
            title={t("US.COLLECTION.DEBTOR:SELECT_DEBTOR.NEW_CUST_ID")}
            width={400}
            visible= {isNewCustId}
            onClose={handleAddDebtorClose}
            destroyOnClose
            className="other-party-edit"
          >
            <CustId 
              selectedDebtorDetails={selectedDebtorDetails} 
              addCustId={(newCustId: Number) => handleSelectDebtorSubmit(newCustId)}
              onCloseDrawer={() => setISNewCustId(false)}
            />
          </$Drawer>
        </div>
      )}
    </Formik>
  );
};

const { debtorsList, addDebtor } = Actions.debtorSelectionActions;

const mapStateToProps = (state: any) => {
  const { debtorSelection } = state;
  const { debtors, addDebtorDrawer } = debtorSelection;
  return {
    debtors: debtors,
    addDebtorDrawer: addDebtorDrawer,
  };
};

const mapDispatchToProps = {
  getDebtors: debtorsList.get,
  resetDebtorList: debtorsList.reset,
  debtorDrawer: addDebtor.openDrawer,
  saveDebtor: addDebtor.save,
};

export default connect(mapStateToProps, mapDispatchToProps)(DebtorSelection);
