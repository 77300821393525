import React from "react";
import {
  $Form,
  $Button,
  $Popover,
  $TextArea,
  $DatePicker,
} from "us.common/components";
import { useTranslation } from "react-i18next";
import { IActionButton } from "./Interfaces";
import { FollowUpAction } from "us.collection.followup/constants";
import { Formik } from "formik";
import { connect, ConnectedProps } from "react-redux";
import { followUps } from "us.collection.followup/actions";
import { IRootState } from "us.collection/interfaces";
import { appInsights } from "us.helper";
import { ValidationSchema } from "./Validation";
import { ExecuteAction } from "us.collection.followup/repository";
import moment from "moment";

const { action } = followUps;

/**
 * @description Followup action button
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2912026690/Follow-up+Activity+Execution+UI+Implementation
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 09/03/2022
 */
const ActionButton: React.FC<PropsFromRedux & IActionButton> = (props) => {
  const {
    type,
    onVisibleChange,
    visible,
    currentDateFormat,
    followupdataIds,
    executeAction,
    followUpId,
  } = props;

  const { t } = useTranslation();
  /**
   * @function
   * @description handle action exection
   * @param data form data
   */
  const handleSubmit = (data: any) => {
    const request = ExecuteAction.call(data, followupdataIds, type);
    executeAction && executeAction(request, { followUpId });
  };

  /**
   * @function
   * @description get content for popOver
   * @param {string} title  title of action - done or remove
   * @returns {JSX.Element} content for popover
   */
  const getContent = (title: string): JSX.Element => {
    try {
      return (
        <div style={{ width: 300 }}>
          <Formik
            initialValues={{
              noteText: "",
              postponeDate: "",
              actionType: title,
            }}
            onSubmit={handleSubmit}
            validationSchema={ValidationSchema}
            validateOnBlur
            validateOnChange
          >
            {({ values, handleSubmit, ...rest }: any) => (
              <$Form onSubmit={handleSubmit}>
                <$TextArea
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  size="small"
                  label={t("US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.COMMENT")}
                  className="w-100"
                  name="noteText"
                />
                {title === FollowUpAction.POSTPONE && (
                  <$DatePicker
                    name="postponeDate"
                    allowClear={true}
                    size="small"
                    format={currentDateFormat}
                    label={t(
                      "US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.POSTPONE_DATE"
                    )}
                    className="w-100 "
                    value={values.postponeDate}
                    disabledDate={(d: any) =>
                      !d || d.isBefore(moment().add(1, "day").startOf("day"))
                    }
                  />
                )}
                <div className="d-flex justify-content-end mt-3">
                  <$Button
                    size="small"
                    className="mr-2"
                    htmlType="submit"
                    type="primary"
                    danger={title === FollowUpAction.REMOVE}
                    disabled={!rest.isValid}
                    onClick={() => {
                      values.noteText.length > 0 && onVisibleChange(false);
                    }}
                  >
                    {t(`US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.${title}`)}
                  </$Button>
                  <$Button
                    size="small"
                    onClick={() => {
                      onVisibleChange(false);
                    }}
                  >
                    {t("US.COMMON:COMMON.CANCEL")}
                  </$Button>
                </div>
              </$Form>
            )}
          </Formik>
        </div>
      );
    } catch (error) {
      appInsights.trackException(
        `Error in ManualFollowup Action component getContent- ${error}`
      );
      return <></>;
    }
  };

  return (
    <$Popover
      placement="topRight"
      title={t(`US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.${type}`)}
      content={getContent(type)}
      destroyTooltipOnHide
      trigger="click"
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      {type === FollowUpAction.POSTPONE && (
        <$Button
          className="mx-1"
          disabled={followupdataIds.length === 0}
          data-testid="action-execution-postpone"
        >
          {t("US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.POSTPONE")}
        </$Button>
      )}
      {type === FollowUpAction.REMOVE && (
        <$Button
          className="mx-1"
          type="primary"
          danger
          disabled={followupdataIds.length === 0}
          data-testid="action-execution-remove"
        >
          {t("US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.REMOVE")}
        </$Button>
      )}
      {type === FollowUpAction.DONE && (
        <$Button
          className="mx-1"
          type="primary"
          disabled={followupdataIds.length === 0}
          data-testid="action-execution-done"
        >
          {t("US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.DONE")}
        </$Button>
      )}
    </$Popover>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common } = state;
  const { currentDateFormat } = common;
  return {
    currentDateFormat,
  };
};

const mapDispatchToProps = {
  executeAction: action.execute,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ActionButton);
