import { IBMDDataType, IBMDListModel } from "us.collection/interfaces/BMD";
import { DynamicComponent } from "us.common/constants";
import { appInsights } from "us.helper";

/**
 * @function
 * @description - To get the matching component for the input form
 * @param {IBMDListModel<string[]>} bmdDataTypeValues - The BMD list values
 * @param {IBMDDataType} [selectedBMDDataType] - The selected BMD data type
 * @return {*}  {DynamicComponent} - The dynamic component type required
 */
export const getValueComponent = (
  bmdDataTypeValues: IBMDListModel<string[]>,
  selectedBMDDataType?: IBMDDataType
): DynamicComponent => {
  try {
    if (bmdDataTypeValues.isLoading) {
      return DynamicComponent.Skeliton;
    } else if (!bmdDataTypeValues.data) {
      return DynamicComponent.Input;
    } else if (selectedBMDDataType?.isPrimitive && selectedBMDDataType?.isPrimitive > 0) {
      if (selectedBMDDataType.name === "Double" || selectedBMDDataType.name === "Int") {
        return DynamicComponent.InputNumber;
      }
      return DynamicComponent.Input;
    } else {
      if (selectedBMDDataType?.storedProcedure) {
        return DynamicComponent.Select;
      } else {
        return DynamicComponent.Input;
      }
    }
  } catch (error) {
    appInsights.trackException(`Error in add BMD getValueComponent function. - ${error}`);
    return DynamicComponent.Input;
  }
};
