import { IBMDChangeHistory } from "us.collection/interfaces";
import { IColumn } from "../Interfaces";

/**
 * @constant
 * @description - The list of columns in Change bmd drawer grid
 * @type {IColumn<IBMDChangeHistory & { more: "more" }>[]} - basic colum type with more column for row-vise actions
 */
export const ADD_PROPERTY_COLUMNS: IColumn<IBMDChangeHistory & { more: "more" }>[] = [
  {
    title: "",
    dataIndex: "more",
    width: 50,
    customFilter: false,
  },
  {
    title: "DATE_TIME",
    dataIndex: "dateTime",
    width: 190,
    ellipsis: { showTitle: false },
    customFilter: false,
  },
  {
    title: "VALUE",
    dataIndex: "value",
    width: "33%",
    ellipsis: { showTitle: false },
    customFilter: false,
  },
  {
    title: "USER",
    dataIndex: "user",
    width: "33%",
    ellipsis: { showTitle: false },
    customFilter: false,
  },
  {
    title: "COMMENT",
    dataIndex: "comment",
    width: "33%",
    ellipsis: { showTitle: false },
    customFilter: false,
  },
];
