import { IAPIAction } from "us.collection/interfaces";
import { Enforcement } from "us.collection.case/constants/Actions";
import { IEnforcementAction } from "us.collection.case/interfaces/Actions";

export const enforcements: Readonly<IEnforcementAction & IAPIAction> = {
  /**
   * A namespace
   * @namespace enforcements
   */
  enforcements: {
    /**
     * Get all enforcements start action
     */
    get: (data) => ({
      type: Enforcement.GET_ENFORCEMENTS_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    /**
     * Get all enforcements success action
     */
    success: (data) => ({
      type: Enforcement.GET_ENFORCEMENTS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    /**
     * Get all enforcements fail action
     */
    fail: (error) => ({
      type: Enforcement.GET_ENFORCEMENTS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    setFilter: (data) => ({
      type: Enforcement.SET_ENFORCEMENTS_FILTER,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },

  /**
   * A namespace
   * @namespace enforcement
   */
  enforcement: {
    /**
     * Delete enforcement
     */
    delete: (data) => ({
      type: Enforcement.DELETE_ENFORCEMENTS_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    save: (data) => ({
      type: Enforcement.SAVE_ENFORCEMENT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Enforcement.SAVE_ENFORCEMENT_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Enforcement.SAVE_ENFORCEMENT_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    deleteSuccess: (data) => ({
      type: Enforcement.DELETE_ENFORCEMENTS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    deleteFail: (error) => ({
      type: Enforcement.DELETE_ENFORCEMENTS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    manageDrawer: (data) => ({
      type: Enforcement.MANAGE_DRAWER,
      payload: {
        data,
        isLoading: true,
      },
    }),
    set: (data) => ({
      type: Enforcement.SELECT_ENFORCEMENT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    reset: (data) => ({
      type: Enforcement.RESET_ENFORCEMENT_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },

  /**
   * A namespace
   * @namespace item
   */
  item: {
    set: (data) => ({
      type: Enforcement.SET_ENFORCEMENT_ITEM,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Enforcement.SET_ENFORCEMENT_ITEM_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Enforcement.SET_ENFORCEMENT_ITEM_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: Enforcement.RESET_ENFORCEMENT_ITEM,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },

  /**
   * A namespace
   * @namespace types
   */
  types: {
    /**
     * Get all enforcements types action
     */
    get: (data) => ({
      type: Enforcement.GET_ENFORCEMENT_TYPES,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Enforcement.GET_ENFORCEMENT_TYPES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Enforcement.GET_ENFORCEMENT_TYPES_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },

  /**
   * A namespace
   * @namespace salaryDeductionPlan
   */
  salaryDeductionPlan: {
    /**
     * Get salaryDeductionPlan action
     */
    get: (data) => ({
      type: Enforcement.GET_SALARY_DEDUCTION_PLAN,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Enforcement.GET_SALARY_DEDUCTION_PLAN_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Enforcement.GET_SALARY_DEDUCTION_PLAN_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    save: (data) => ({
      type: Enforcement.SAVE_SALARY_DEDUCTION_PLAN,
      payload: {
        data,
        isLoading: true,
      },
    }),
    saveSuccess: (data) => ({
      type: Enforcement.SAVE_SALARY_DEDUCTION_PLAN_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    saveFail: (error) => ({
      type: Enforcement.SAVE_SALARY_DEDUCTION_PLAN_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    set: (data) => ({
      type: Enforcement.SET_SALARY_DEDUCTION_PLAN,
      payload: {
        data,
        isLoading: true,
      },
    }),
    reset: (data) => ({
      type: Enforcement.RESET_SALARY_DEDUCTION_PLAN,
      payload: {
        data,
        isLoading: true,
      },
    }),
    resetDeductionSchema: (data) => ({
      type: Enforcement.RESET_SALARY_DEDUCTION_SCHEMA,
      payload: {
        data,
        isLoading: true,
      },
    }),
    delete: (data) => ({
      type: Enforcement.DELETE_SALARY_DEDUCTION_PLAN,
      payload: {
        data,
        isLoading: true,
      },
    }),
    resetDeleted: (data) => ({
      type: Enforcement.RESET_DELETED_SALARY_DEDUCTION_PLAN,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },

  /**
   * A namespace
   * @namespace accountSummary
   */
  accountSummary: {
    /**
     * Get enforcement account summary action
     */
    get: (data) => ({
      type: Enforcement.GET_ENFORCEMENT_ACCOUNT_SUMMARY,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Enforcement.GET_ENFORCEMENT_ACCOUNT_SUMMARY_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Enforcement.GET_ENFORCEMENT_ACCOUNT_SUMMARY_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
