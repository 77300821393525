export enum BMDActionsConst {
  GET_BMD_LIST = "GET_BMD_LIST",
  GET_BMD_LIST_SUCCESS = "GET_BMD_LIST_SUCCESS",
  GET_BMD_LIST_FAIL = "GET_BMD_LIST_FAIL",

  ADD_BMD_PROPERTY = "ADD_BMD_PROPERTY",
  ADD_BMD_PROPERTY_SUCCESS = "ADD_BMD_PROPERTY_SUCCESS",
  ADD_BMD_PROPERTY_FAIL = "ADD_BMD_PROPERTY_FAIL",

  GET_BMD_DATA_TYPES = "GET_BMD_DATA_TYPES",
  GET_BMD_DATA_TYPES_SUCCESS = "GET_BMD_DATA_TYPES_SUCCESS",
  GET_BMD_DATA_TYPES_FAIL = "GET_BMD_DATA_TYPES_FAIL",

  GET_BMD_DATA_TYPE_VALUES = "GET_BMD_DATA_TYPE_VALUES",
  GET_BMD_DATA_TYPE_VALUES_SUCCESS = "GET_BMD_DATA_TYPE_VALUES_SUCCESS",
  GET_BMD_DATA_TYPE_VALUES_FAIL = "GET_BMD_DATA_TYPE_VALUES_FAIL",

  GET_BMD_PROPERTY_DETAILS = "GET_BMD_PROPERTY_DETAILS",
  GET_BMD_PROPERTY_DETAILS_SUCCESS = "GET_BMD_PROPERTY_DETAILS_SUCCESS",
  GET_BMD_PROPERTY_DETAILS_FAIL = "GET_BMD_PROPERTY_DETAILS_FAIL",

  CHANGE_BMD_PROPERTY_DETAILS = "CHANGE_BMD_PROPERTY_DETAILS",
  CHANGE_BMD_PROPERTY_DETAILS_SUCCESS = "CHANGE_BMD_PROPERTY_DETAILS_SUCCESS",
  CHANGE_BMD_PROPERTY_DETAILS_FAIL = "CHANGE_BMD_PROPERTY_DETAILS_FAIL",

  DELETE_BMD_PROPERTY_HISTORY_DETAILS = "DELETE_BMD_PROPERTY_HISTORY_DETAILS",
  DELETE_BMD_PROPERTY_HISTORY_DETAILS_SUCCESS = "DELETE_BMD_PROPERTY_HISTORY_DETAILS_SUCCESS",
  DELETE_BMD_PROPERTY_HISTORY_DETAILS_FAIL = "DELETE_BMD_PROPERTY_HISTORY_DETAILS_FAIL",

  CHANGE_BMD_DRAWER_STATUS = "CHANGE_BMD_DRAWER_STATUS",

  ADD_BMD_CATEGORY = "ADD_BMD_CATEGORY",
  ADD_BMD_CATEGORY_SUCCESS = "ADD_BMD_CATEGORY_SUCCESS",
  ADD_BMD_CATEGORY_FAIL = "ADD_BMD_CATEGORY_FAIL",

  ADD_BMD_GROUP = "ADD_BMD_GROUP",
  ADD_BMD_GROUP_SUCCESS = "ADD_BMD_GROUP_SUCCESS",
  ADD_BMD_GROUP_FAIL = "ADD_BMD_GROUP_FAIL",

  UPDATE_BMD_GROUP = "UPDATE_BMD_GROUP",
  UPDATE_BMD_GROUP_SUCCESS = "UPDATE_BMD_GROUP_SUCCESS",
  UPDATE_BMD_GROUP_FAIL = "UPDATE_BMD_GROUP_FAIL",

  ADD_BMD_GROUP_DRAWER = "ADD_BMD_GROUP_DRAWER",

  GET_BMD_COUNT_LIST = "GET_BMD_GET_BMD_COUNT_LISTLIST",
  GET_BMD_COUNT_LIST_SUCCESS = "GET_BMD_COUNT_LIST_SUCCESS",
  GET_BMD_COUNT_LIST_FAIL = "GET_BMD_COUNT_LIST_FAIL",

  SEARCH_LINKED_CREDITORS = "SEARCH_LINKED_CREDITORS",
  SEARCH_LINKED_CREDITORS_SUCCESS = "SEARCH_LINKED_CREDITORS_SUCCESS",
  SEARCH_LINKED_CREDITORS_FAIL = "SEARCH_LINKED_CREDITORS_FAIL",

  ADD_DATA_TYPE = "ADD_DATA_TYPE",
  EDIT_DATA_TYPE = "EDIT_DATA_TYPE",
  ADD_EDIT_DATA_TYPE_SUCCESS = "ADD_EDIT_DATA_TYPE_SUCCESS",
  ADD_EDIT_DATA_TYPE_FAIL = "ADD_EDIT_DATA_TYPE_FAIL",

  GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES = "GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES",
  GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES_SUCCESS = "GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES_SUCCESS",
  GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES_FAIL = "GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES_FAIL",
}
