import { IExcleColumn } from "us.collection/components/ExcelGenerator/Interface";

/**
 * @constant
 * @description - The list of columns in the excel export view
 * @type {IExcleColumn[]} - excel column type
 */
export const EXCEL_COLUMNS: IExcleColumn[] = [
  {
    title: "CASE_NO",
    dataIndex: "caseNumber",
    format: "Text",
  },
  {
    title: "MAIN",
    dataIndex: "mainAmount",
    format: "Currency",
  },
  {
    title: "COLLECTION",
    dataIndex: "collectionFee",
    format: "Currency",
  },
  {
    title: "COURT",
    dataIndex: "courtFee",
    format: "Currency",
  },
  {
    title: "OTHER",
    dataIndex: "otherCost",
    format: "Currency",
  },
  {
    title: "INTEREST",
    dataIndex: "interestAmount",
    format: "Currency",
  },
  {
    title: "COST",
    dataIndex: "caseCost",
    format: "Currency",
  },
  {
    title: "PAID",
    dataIndex: "paidAmount",
    format: "Currency",
  },
  {
    title: "BALANCE",
    dataIndex: "balance",
    format: "Currency",
  },
];
