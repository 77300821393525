import React, { useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import Common from "us.common";
import { ITableTreeColumns } from "us.common/components";
import {
  ColumnType,
  CREDITOR_CASES_COLUMNS,
  CaseTypes,
} from "us.collection.creditor/components/CreditorCases/Constants";
import {
  getCaseList,
  handleExpandedRows,
  handleFilterGroup,
  handleSortGroup,
} from "us.collection.creditor/components/CreditorCases/Functions";
import { useTranslation } from "react-i18next";
import { RootState } from "us.helper/types/RootState";
import { useFormikContext } from "formik";
import moment from "moment";

const { $Skeleton, $TableTree, $AmountLabel, $Popover, $Tooltip } =
  Common.Components;

/**
 * @description - Creditor Case List Table View
 * @link Design Document - https://unicorn-solutions.atlassian.net/l/cp/0sYQ2euQ
 * @author Lashini Ayesha <lashinia@unicorn-solutions.com>
 * @since 24/05/2023
 */

const CaseTable: React.FC<PropsFromRedux> = (props) => {
  const { caseList, currentDateFormat } = props;

  const { t } = useTranslation([
    "US.COLLECTION.CREDITOR",
    "US.COLLECTION.COMMON",
  ]);

  const [expandedRows, setExpandedRows] = useState<Array<number>>([]);
  const { values } = useFormikContext<any>();
  const { isCollapsedAll } = values;

  const { modifiedList, caseCount, mainAmount, totalBalance } = useMemo(() => {
    return getCaseList(caseList.data, values);
  }, [caseList, values]);

  useEffect(() => {
    if (!isCollapsedAll) {
      setExpandedRows(
        handleExpandedRows(modifiedList, expandedRows, 0, true, true)
      );
    } else {
      setExpandedRows([]);
    }
  }, [isCollapsedAll, modifiedList]);

  /**
   * Handle the row click event of creditor case view table
   * @param e click event
   * @param exCaseNo case view table row caseNo value
   */
  const rowClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    exCaseNo: number
  ) => {
    e.preventDefault();
    window.open(`/case/${exCaseNo}`, "_blank");
  };

  const tableColumns: ITableTreeColumns = CREDITOR_CASES_COLUMNS.map(
    (column: any) => {
      const { dataIndex } = column;
      if (dataIndex === ColumnType.CASE) {
        return {
          title: () => (
            <>
              <div>{t(column.columnTitleCaseNo)}</div>
              <div className="d-flex align-items-center header-custom-amount">
                <span data-testid="case-count" className="case-count mr-1">
                  {caseCount}
                </span>
                {t(column.columnTitleCaseCount)}
              </div>
            </>
          ),
          ...column,
          customSorter: (a: any, b: any) => a - b,
          customFilter: true,
          customRenderParent: (text: any, record: any, index: number) => {
            const { custId, debtorName, debtorAddress } = record ?? {};
            return {
              children: (
                <div className="debtor-td">
                  <div>
                    <strong>
                      {custId}
                      {" - "}
                      {debtorName}
                    </strong>
                  </div>
                  <small>{debtorAddress}</small>
                </div>
              ),
              key: index,
              props: {
                colSpan: 1,
              },
            };
          },
          customRenderChild: (text: any, record: any, index: number) => {
            const { exCaseNo, caseStatus } = record ?? {};
            return {
              children: (
                <div className="d-flex align-items-center">
                  {caseStatus == CaseTypes.OPEN ? (
                    <$Tooltip title={t(column.rowTitleOpen)}>
                      <div className={`row-tag tag-status-open`}></div>
                    </$Tooltip>
                  ) : (
                    <$Tooltip title={t(column.rowTitleClosed)}>
                      <div className={`row-tag tag-status-close`}></div>
                    </$Tooltip>
                  )}
                  <a onClick={(e: any) => rowClick(e, exCaseNo)}>{exCaseNo}</a>
                </div>
              ),
              key: index,
              props: {
                colSpan: 1,
              },
            };
          },
        };
      } else if (dataIndex === ColumnType.KEY) {
        return {
          ...column,
          key: dataIndex,
          ellipsis: true,
          customRenderParent: (text: any, record: any, index: number) => {
            return {
              children: <></>,
              key: index,
              props: {
                colSpan: 1,
              },
            };
          },
          customRenderChild: (text: any, record: any, index: number) => {
            return {
              children: <></>,
              key: index,
              props: {
                colSpan: 1,
              },
            };
          },
        };
      } else if (
        dataIndex === ColumnType.INVOICE_NO ||
        dataIndex === ColumnType.INVOICE_KID ||
        dataIndex === ColumnType.VOUCHER_DATE ||
        dataIndex === ColumnType.REG_DATE
      ) {
        return {
          ...column,
          title: t(column.title),
          ellipsis: true,
          customSorter: (a: any, b: any) => a?.localeCompare(b),
          customFilter: true,
          customRenderParent: (text: any, record: any, index: number) => {
            return {
              children: <></>,
              key: index,
              props: {
                colSpan: 1,
              },
            };
          },
          customRenderChild: (text: any, record: any, index: number) => {
            const { refs, kiDs, voucherDates, regDates } = record ?? {};
            const tableItem =
              dataIndex === ColumnType.INVOICE_NO
                ? refs
                : dataIndex === ColumnType.INVOICE_KID
                ? kiDs
                : dataIndex === ColumnType.REG_DATE
                ? regDates
                : voucherDates;
            return {
              children:
                dataIndex === ColumnType.REG_DATE ||
                dataIndex === ColumnType.VOUCHER_DATE
                  ? moment(tableItem[0], "DD/MM/YYYY").format(currentDateFormat)
                  : tableItem[0],
              key: index,
              props: {
                colSpan: 1,
              },
            };
          },
        };
      } else {
        return {
          title: () => (
            <>
              <div>{t(column.columnTitle)}</div>
              <div className="header-custom-amount">
                <span className="total-amount">
                  {dataIndex === ColumnType.MAIN_AMOUNT ? (
                    <$AmountLabel value={mainAmount ? mainAmount : 0} />
                  ) : (
                    <$AmountLabel value={totalBalance ? totalBalance : 0} />
                  )}
                </span>
              </div>
            </>
          ),
          ...column,
          customSorter: (a: any, b: any) => a - b,
          customFilter: true,
          customRenderParent: (text: any, record: any, index: number) => {
            const { totalAmount, totalBalance } = record ?? {};
            return {
              children: (
                <strong>
                  {dataIndex === ColumnType.MAIN_AMOUNT ? (
                    <$AmountLabel value={totalAmount} />
                  ) : (
                    <$AmountLabel value={totalBalance} />
                  )}
                </strong>
              ),
              key: index,
              props: {
                colSpan: 1,
              },
            };
          },
          customRenderChild: (text: any, record: any, index: number) => {
            const { mainAmount, balance } = record ?? {};
            return {
              children: (
                <$AmountLabel
                  value={
                    dataIndex === ColumnType.MAIN_AMOUNT ? mainAmount : balance
                  }
                />
              ),
              key: index,
              props: {
                colSpan: 1,
              },
            };
          },
        };
      }
    }
  );

  return (
    <>
      <div className="cc-results">
        <$Skeleton loading={caseList.isLoading} active paragraph={{ rows: 2 }}>
          <$TableTree
            rowKey={(record: any) => {
              const { key, exCaseNo } = record ?? {};
              return record.hasOwnProperty("children") ? key : exCaseNo;
            }}
            data={modifiedList}
            onSort={handleSortGroup}
            onFilter={handleFilterGroup}
            filterOnType={true}
            size="small"
            className="mt-3 header-custom-tag cc-table"
            bordered
            pagination={{
              defaultPageSize: 8,
            }}
            scroll={{
              x: 900,
              y: "calc(100vh - 280px)",
            }}
            columns={tableColumns}
            defaultExpandAllRows={true}
            expandedRowKeys={expandedRows}
            onExpand={(isExpand: boolean, record: any) =>
              setExpandedRows(
                handleExpandedRows(
                  modifiedList,
                  expandedRows,
                  record?.key,
                  isExpand
                )
              )
            }
            firstColSkipFilterProps={-1}
            resetOnSourceChange={true}
          />
        </$Skeleton>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const { creditorCases, common } = state;
  const { currentDateFormat } = common;
  const { caseList } = creditorCases;
  return { caseList, currentDateFormat };
};

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CaseTable);
