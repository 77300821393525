import { IAPIAction } from "us.collection/interfaces";
import Constants from "us.collection.case/constants";
import { ICourtSentenceAction } from ".";

const {
  DELETE_SENTENCE_FAIL,
  DELETE_SENTENCE_START,
  DELETE_SENTENCE_SUCCESS,
  GET_SENTENCE_CASE_AMOUNTS_FAIL,
  GET_SENTENCE_CASE_AMOUNTS_START,
  GET_SENTENCE_CASE_AMOUNTS_SUCCESS,
  GET_SENTENCE_FAIL,
  GET_SENTENCE_START,
  GET_SENTENCE_SUCCESS,
  GET_SENTENCE_SUMMERY_FAIL,
  GET_SENTENCE_SUMMERY_START,
  GET_SENTENCE_SUMMERY_SUCCESS,
  SAVE_SENTENCE_FAIL,
  SAVE_SENTENCE_START,
  SAVE_SENTENCE_SUCCESS,
  UPDATE_SENTENCE_FAIL,
  UPDATE_SENTENCE_START,
  UPDATE_SENTENCE_SUCCESS,
  CHANGE_DRAWER_STATUS
} = Constants.ACTIONS.CourtSentence;

export const CourtSentenceActions: Readonly <IAPIAction & ICourtSentenceAction> = {
  caseAmounts: {
    get: (data) => ({
      type: GET_SENTENCE_CASE_AMOUNTS_START,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_SENTENCE_CASE_AMOUNTS_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_SENTENCE_CASE_AMOUNTS_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  summery: {
    get: (data) => ({
      type: GET_SENTENCE_SUMMERY_START,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_SENTENCE_SUMMERY_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_SENTENCE_SUMMERY_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
    openDrawer:(data) => ({
      type: CHANGE_DRAWER_STATUS,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
  },
  sentenceDetails: {
    get: (data) => ({
      type: GET_SENTENCE_START,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_SENTENCE_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_SENTENCE_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  addSentence: {
    save: (data) => ({
      type: SAVE_SENTENCE_START,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SAVE_SENTENCE_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SAVE_SENTENCE_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    })
  },
  editSentence: {
    update: (data) => ({
      type: UPDATE_SENTENCE_START,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: UPDATE_SENTENCE_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: UPDATE_SENTENCE_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  sentenceDelete: {
    delete: (data) => ({
      type: DELETE_SENTENCE_START,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: DELETE_SENTENCE_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: DELETE_SENTENCE_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
};
