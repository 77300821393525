import * as Yup from "yup";

interface IStateValidation extends Yup.MixedSchema {
    name: string;
    displayName: string;
    workflowType: string;
    adapterName: string;
    eventCode: string;
}
const EventValidation = Yup.object<IStateValidation>().shape({
    name: Yup.string()
        .required(
        "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
        ),
    displayName: Yup.string()
        .required(
        "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
        ),
    workflowType: Yup.string()
        .required(
        "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
        ),
    adapterName: Yup.string()
        .required(
        "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
        ),
});

export default EventValidation;
