import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import Common from "us.common";
import { workflowAction } from "us.collection/actions";
import { RootState } from "us.helper/types";
import { matchPath, useLocation } from "react-router-dom";
import { StateChange, WorkflowGroup } from "us.collection/repository";
import { getEntityNumberAndType } from "us.collection/components/BMD/Functions";
import { Formik } from "formik";
import {
  formatStateData,
  searchState,
} from "us.collection/components/WorkflowTroubleshoot/Functions";
import { troubleshootDrawerInitial } from "us.collection/components/WorkflowTroubleshoot/Constants";
import { IMoveTo } from "us.collection/components/WorkflowTroubleshoot/Interfaces";
import "us.collection/components/WorkflowTroubleshoot/Home.scss";

const { $Skeleton, $TableTree, $Search, $Button, $Popconfirm } =
  Common.Components;

/**
 * @description - The component used in view of workflow state change
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3156934695/Troubleshoot+View+-+UI
 * @param {PropsFromRedux} props
 * @return {JSX.Element}
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 05/12/2022
 */
const MoveTo: React.FC<PropsFromRedux & IMoveTo> = memo((props) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const { params }: any = matchPath(pathname, {
    path: "/:type/:id?/:module?",
    exact: false,
    strict: false,
  });

  const {
    workflowStates,
    getWorkflowStates,
    searchFilters,
    openDrawer,
    changeState,
    troubleshootDrawer,
    isStateChanging,
  } = props;

  const [selectedData, setSelectedData] = useState<any>();
  const [expandKeys, setExpandKeys] = useState<Array<string>>([]);

  useEffect(() => {
    getWorkflowStates &&
      getWorkflowStates(
        WorkflowGroup.call(
          getEntityNumberAndType(params["type"], params["id"], "")
        )
      );
  }, []);

  const tableColumns: any = [
    {
      title: t("US.COLLECTION.CASE:WORKFLOWS.STATES"),
      dataIndex: "stateCategoryDisplayName",
      key: "stateCategoryDisplayName",
      ellipsis: true,
      customRenderParent: (text: any, record: any, index: number) => {
        return {
          children: <>{record.stateCategoryDisplayName}</>,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <>{record.displayName}</>,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
  ];

  /**
   * Table row expand
   * @param idExpand Row expand or not
   * @param record Row data
   */
  const onTableRowExpand = (idExpand: any, record: any) => {
    let keys = expandKeys;
    if (idExpand) {
      setExpandKeys([...keys, record.stateCategoryName]);
    } else {
      setExpandKeys(
        keys.filter((item: any) => item !== record.stateCategoryName)
      );
    }
  };

  /**
   * Row selection functionality
   */
  const rowSelection = {
    onSelect: (record: any) => setSelectedData(record),
    hideSelectAll: true,
  };

  /**
   * Change workflow state api call
   * @param values Selected workflow state details
   */
  const changeWorkflowState = (values: any) => {
    const { id, stateGroupId } = values ?? {};
    const { caseId } = troubleshootDrawer ?? {};
    changeState &&
      changeState(
        StateChange.call({ id, caseId, stateGroupId, searchFilters })
      );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ searchText: "" }}
      onSubmit={() => changeWorkflowState(selectedData)}
    >
      {({ values, handleSubmit }: any) => (
        <div className="move-to">
          <$Search
            size="small"
            name="searchText"
            className="mb-3"
            style={{ width: 300 }}
            allowClear={true}
            placeholder={t("US.COLLECTION.CASE:WORKFLOWS.SEARCH_STATES")}
            disabled={isStateChanging}
          />
          <$Skeleton loading={false} active paragraph={{ rows: 2 }}>
            <$TableTree
              rowKey={(record: any) => {
                return record.hasOwnProperty("children")
                  ? record.stateCategoryName
                  : record.id;
              }}
              data={formatStateData(
                searchState(workflowStates?.data, values?.searchText) ?? [],
                troubleshootDrawer.workflowType
              )}
              filterOnType={true}
              size="small"
              bordered
              pagination={{ defaultPageSize: 15 }}
              scroll={{ x: "100%", y: "calc(100vh - 215px)" }}
              columns={tableColumns}
              defaultExpandAllRows={true}
              onRow={(record: any, rowIndex: any) => {
                return {
                  onDoubleClick: () => changeWorkflowState(record),
                };
              }}
              expandable={{
                defaultExpandAllRows: true,
                onExpand: (isExpand, record) =>
                  onTableRowExpand(isExpand, record),
                childrenColumnName: "children",
                expandedRowKeys: expandKeys,
              }}
              resetOnSourceChange={true}
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              firstColSkipFilterProps={-1}
            />
            <div className="drawer-footer-fixed align-content-center justify-content-end">
              <div>
                <$Button
                  type="primary"
                  onClick={handleSubmit}
                  className="mr-2"
                  disabled={!selectedData?.id || isStateChanging}
                  loading={isStateChanging}
                >
                  {t("US.COMMON:COMMON.SELECT")}
                </$Button>
                <$Popconfirm
                  title={t("US.COLLECTION.CASE:WORKFLOWS.CANCEL_ERROR")}
                  placement="topLeft"
                  onConfirm={() =>
                    openDrawer && openDrawer(troubleshootDrawerInitial)
                  }
                  okText={t("US.COMMON:COMMON.YES")}
                  cancelText={t("US.COMMON:COMMON.NO")}
                  disabled={isStateChanging}
                >
                  <$Button>{t("US.COMMON:COMMON.CANCEL")}</$Button>
                </$Popconfirm>
              </div>
            </div>
          </$Skeleton>
        </div>
      )}
    </Formik>
  );
});

const { workflowStates, stateChange, troubleshootWorkflowRetry } =
  workflowAction;

const mapStateToProps = (state: RootState) => {
  const { Workflow } = state;
  const { workflowStates, troubleshootDrawer, isStateChanging } = Workflow;
  return {
    workflowStates,
    troubleshootDrawer,
    isStateChanging,
  };
};

const mapDispatchToProps = {
  getWorkflowStates: workflowStates.get,
  changeState: stateChange.save,
  openDrawer: troubleshootWorkflowRetry.openDrawer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MoveTo);
