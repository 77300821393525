import moment from "moment";
import { DrawerTypes } from "us.collection/constants";
import * as Yup from "yup";
import { IAddBMDProperty } from "../Interfaces";

/**
 * @constant
 * @description - the validatoin object used in Add bmd property form
 */
export default Yup.object<IAddBMDProperty & Yup.MixedSchema>().shape({
  drawerType: Yup.number(),
  isAddButtonClicked: Yup.boolean(),
  keyName: Yup.string().when("drawerType", {
    is: DrawerTypes.ADD_PROPERTY,
    then: Yup.string()
      .required("US.COLLECTION.VALIDATIONS:REQUIRED.KEY_IS_REQUIRED")
      .typeError("US.COLLECTION.VALIDATIONS:REQUIRED.KEY_IS_REQUIRED")
      .matches(/^\S+$/, {
        message: "US.COLLECTION.VALIDATIONS:REQUIRED.KEY_CAN_NOT_CONTAIN_ANY_SPACES",
      }),
  }),

  dataTypeId: Yup.number()
    .required("US.COLLECTION.VALIDATIONS:REQUIRED.TYPE_IS_REQUIRED")
    .typeError("US.COLLECTION.VALIDATIONS:REQUIRED.TYPE_IS_REQUIRED"),

  description: Yup.string().when("drawerType", {
    is: DrawerTypes.ADD_PROPERTY,
    then: Yup.string()
      .required("US.COLLECTION.VALIDATIONS:REQUIRED.DESCRIPTION_IS_REQUIRED")
      .typeError("US.COLLECTION.VALIDATIONS:REQUIRED.DESCRIPTION_IS_REQUIRED"),
    otherwise: Yup.string().nullable(true),
  }),

  fromDate: Yup.object().when(["drawerType", "isAddButtonClicked"], {
    is: (drawerType, isAddButtonClicked) =>
      drawerType === DrawerTypes.ADD_PROPERTY ||
      (drawerType === DrawerTypes.EDIT_BMD && isAddButtonClicked),
    then: Yup.object()
      .nullable(true)
      .required("US.COLLECTION.VALIDATIONS:REQUIRED.DATE_IS_REQUIRED"),
      otherwise: Yup.object().nullable(true)
  }),

  value: Yup.string().when(["drawerType", "isAddButtonClicked"], {
    is: (drawerType, isAddButtonClicked) =>
      drawerType === DrawerTypes.ADD_PROPERTY ||
      (drawerType === DrawerTypes.EDIT_BMD && isAddButtonClicked),
    then: Yup.string().required("US.COLLECTION.VALIDATIONS:REQUIRED.VALUE_IS_REQUIRED"),
  }),

  comment: Yup.string().when(["drawerType", "isAddButtonClicked"], {
    is: (drawerType, isAddButtonClicked) =>
      drawerType === DrawerTypes.ADD_PROPERTY ||
      (drawerType === DrawerTypes.EDIT_BMD && isAddButtonClicked),
    then: Yup.string()
      .required("US.COLLECTION.VALIDATIONS:REQUIRED.COMMENT_IS_REQUIRED")
      .typeError("US.COLLECTION.VALIDATIONS:REQUIRED.COMMENT_IS_REQUIRED"),
  }),
  changedHistory: Yup.array().when(["drawerType", "isAddButtonClicked"], {
    is: (drawerType, isAddButtonClicked) =>
      drawerType === DrawerTypes.EDIT_BMD && !isAddButtonClicked,
    then: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number(),
          dateTime: Yup.string(),
          value: Yup.string(),
          user: Yup.string(),
          comment: Yup.string(),
          isEffective: Yup.boolean(),
          isNew: Yup.boolean(),
        })
      )
      .compact((item) => (item?.isNew ?? false) === false)
      .min(0, "US.COLLECTION.VALIDATIONS:REQUIRED.AT_LEAST_ONE_NEW_VALUE_NEED_TO_BE_ADDED_TO_THE_TABLE")
      .required("US.COLLECTION.VALIDATIONS:REQUIRED.NEW_RECORD_NEED_TO_BE_ADDED_TO_THE_TABLE"),
  }),
});
