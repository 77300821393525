import _ from "lodash";
import { ActivityTypeId } from "us.collection.followup/constants";
import { IActivity, IActivityTableData } from "../Interfaces";

/**
 * @function
 * @description Set activity data for TableTree
 * @param {Array<IActivity>} activities activtiy data
 * @returns {Array<IActivityTableData>} grouping data
 */
export const formatActivityTableData = (
    activities: Array<IActivity>
): Array<IActivityTableData> => {
    let formatActivities: Array<IActivityTableData> = [];
    try {
        Object.values(_.groupBy(activities, 'type')).map(
            (groupActivities: Array<IActivity>, index: number) => {
                formatActivities.push({
                    key: index,
                    group: groupActivities[0].type,
                    activityCode: '',
                    displayName: "",
                    description: "",
                    children: groupActivities
                });

            }
        );
        return formatActivities;
    } catch (error) {
        // appInsights.trackException(
        //     `FollowUp-Activity formatActivityTableData  Exeception -${error}`
        // );
        return formatActivities;
    }
};