import { IInitialState } from "us.collection.followup/reducers/FollowUps/Interfaces";
import { followUpCategory } from "./FollowUpCategory";
import { followUpResult } from "./FollowUpResult";
import { followUpFilters } from "./FollowUpFilters";
import { activityFromInitail } from "./ActivityForm";

export const initialState: IInitialState = {
  categories: {
    data: [followUpCategory],
    selectedCategoryId: -1,
    categorySearchText:"",
    isFetching: false,
  },
  list: {
    data: [],
    isFetching: false,
  },
  pinStatus: {
    data: [],
    isExpanded: false,
  },
  result: {
    data: followUpResult,
    followupId: -1,
    dataSource: [],
    isFetching: false,
  },
  followUpNavigation: {
    viewedItems: [],
    isBack: false,
    initialEntityId: 0,
    entityIndex:0,
    hit:0,
  },
  filters: {
    data: followUpFilters,
    isFetching: false,
  },
  tableFilters: {
    data: [],
    isFetching: false,
  },
  tableSorters: {
    data: {},
    isFetching: false,
  },
  tableDataWithTableFilters:{
    data:[],
    isFetching:false,
  },
  columns: {
    data: [],
    tableColumns:[],
    tableColumnWithStates:[],
    isFetching: false,
  },
  history: {
    entity: {
      data: [],
      isFetching: false,
    },
    item: {
      data: followUpResult,
      dataSource: [],
      isFetching: false,
      columns: [],
    },
    action: {
      data: [],
      isFetching: false,
    },
  },
  historyDrawer: {
    isActionHistoryVisible: false,
    isAction: false,
  },
  entity: {
    active: {
      data: [],
      isFetching: false,
    },
  },
  activites: {
    data: [],
    categories: [],
    isFetching: false,
    isEmpty: false,
  },
  parameters: {
    isEnableDropDown: false,
    list: [],
    dataSource: [],
  },
  defaultData: {
    entityType: "",
    userName: "",
  },
  activityForm: {
    initialValues: activityFromInitail,
  },
  maxFollowUpscount: 10,
  isActivityExecuting: false,
  isBulkFollowupReImporting: false,
  activityExecutionAction: "",
  isFollowupReImporting: false,
  reimportingFollowupId: 0
};
