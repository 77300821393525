import { ISentence } from 'us.collection.case/interfaces';
import * as Yup from 'yup';

const AddSentenceSchema = Yup.object<ISentence & Yup.MixedSchema>().shape({
	sentenceDate: Yup.date()
		.required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.SENTENCE_DATE_IS_REQUIRED'
		)
		.typeError(
			'US.COLLECTION.VALIDATIONS:REQUIRED.SENTENCE_DATE_IS_REQUIRED'
		),
	declarationDate: Yup.date()
		.required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.DECLARATION_DATE_IS_REQUIRED'
		)
		.typeError(
			'US.COLLECTION.VALIDATIONS:REQUIRED.DECLARATION_DATE_IS_REQUIRED'
		),
	sentenceTypeId: Yup.string()
		.required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.LEGAL_DECISION_TYPE_IS_REQUIRED'
		)
		.typeError(
			'US.COLLECTION.VALIDATIONS:REQUIRED.LEGAL_DECISION_TYPE_IS_REQUIRED'
		),
	courtRef: Yup.string().required(
		'US.COLLECTION.VALIDATIONS:REQUIRED.COURT_REFERENCE_IS_REQUIRED'
	),
	continueType: Yup.string()
		.required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.CONTINUE_TO_IS_REQUIRED'
		)
		.typeError(
			'US.COLLECTION.VALIDATIONS:REQUIRED.CONTINUE_TO_IS_REQUIRED'
		),
	sentenceNote: Yup.string().required(
		'US.COLLECTION.VALIDATIONS:REQUIRED.NOTE_IS_REQUIRED'
	),
});

export default AddSentenceSchema;
