import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';

interface IAddressInfoProps {
    data:any
}

const AddressInfo:React.FC<IAddressInfoProps> = props => {

    const { t } = useTranslation();
    // let { data } = props;
    // const [dataSource, setDataSource] = useState(data);

    // useEffect(() => {
    //     setDataSource(data);
    // }, [data])

    const columns = [
        {
            title: t('US.COLLECTION.CREDITOR:ADDRESSINFO.ROWSTATE'),
            dataIndex: 'RowState',
            key: 'RowState',
            width: 100,
            sorter: (a:any, b:any) => a.RowState - b.RowState,
        },
        {
            title: t('US.COLLECTION.CREDITOR:ADDRESSINFO.ADDRESS1'),
            dataIndex: 'Address1',
            key: 'Address1',
            width: 100,
            sorter: (a:any, b:any) => a.Address1 - b.Address1,
        },
        {
            title: t('US.COLLECTION.CREDITOR:ADDRESSINFO.ADDRESS2'),
            dataIndex: 'Address2',
            key: 'Address2',
            width: 100,
            sorter: (a:any, b:any) => a.Address2 - b.Address2,
        },
        {
            title: t('US.COLLECTION.CREDITOR:ADDRESSINFO.ADDRESS3'),
            dataIndex: 'Address3',
            key: 'Address3',
            width: 100,
            sorter: (a:any, b:any) => a.Address3 - b.Address3,
        },
        {
            title: t('US.COLLECTION.CREDITOR:ADDRESSINFO.ZIPNAME'),
            dataIndex: 'ZipName',
            key: 'ZipName',
            width: 100,
            sorter: (a:any, b:any) => a.ZipName - b.ZipName,
        },
        {
            title: t('US.COLLECTION.CREDITOR:ADDRESSINFO.COUNTRYNAME'),
            dataIndex: 'CountyName',
            key: 'CountyName',
            width: 100,
            sorter: (a:any, b:any) => a.CountyName - b.CountyName,
        },
        {
            title: t('US.COLLECTION.CREDITOR:ADDRESSINFO.COUNTRYID'),
            dataIndex: 'CountryID',
            key: 'CountryID',
            width: 100,
            sorter: (a:any, b:any) => a.CountryID - b.CountryID,
        },
        {
            title: t('US.COLLECTION.CREDITOR:ADDRESSINFO.TELHOME'),
            dataIndex: 'TelHome',
            key: 'TelHome',
            width: 100,
            sorter: (a:any, b:any) => a.TelHome - b.TelHome,
        },
        {
            title: t('US.COLLECTION.CREDITOR:ADDRESSINFO.TELMOBILENO'),
            dataIndex: 'TelMobileNo',
            key: 'addrTelMobileNoess',
            width: 100,
            sorter: (a:any, b:any) => a.TelMobileNo - b.TelMobileNo,
        },
        {
            title: t('US.COLLECTION.CREDITOR:ADDRESSINFO.TELWORK'),
            dataIndex: 'TelWork',
            key: 'TelWork',
            width: 100,
            sorter: (a:any, b:any) => a.TelWork - b.TelWork,
        },
        {
            title: t('US.COLLECTION.CREDITOR:ADDRESSINFO.FAX'),
            dataIndex: 'Fax',
            key: 'Fax',
            width: 100,
            sorter: (a:any, b:any) => a.Fax - b.Fax,
        },
        {
            title: t('US.COLLECTION.CREDITOR:ADDRESSINFO.EMAIL'),
            dataIndex: 'Email',
            key: 'Email',
            width: 100,
            sorter: (a:any, b:any) => a.Email - b.Email,
        },
        {
            title: t('US.COLLECTION.CREDITOR:ADDRESSINFO.SMS'),
            dataIndex: 'Sms',
            key: 'Sms',
            width: 100,
            sorter: (a:any, b:any) => a.Sms - b.Sms,
        },
        {
            title: t('US.COLLECTION.CREDITOR:ADDRESSINFO.SKYPE'),
            dataIndex: 'Skype',
            key: 'Skype',
            width: 100,
            sorter: (a:any, b:any) => a.Skype - b.Skype,
        },
        {
            title: t('US.COLLECTION.CREDITOR:ADDRESSINFO.MSN'),
            dataIndex: 'Msn',
            key: 'Msn',
            width: 100,
            sorter: (a:any, b:any) => a.Msn - b.Msn,
        },
        {
            title: t('US.COLLECTION.CREDITOR:ADDRESSINFO.REGDATETIME'),
            dataIndex: 'RegDateTime',
            key: 'RegDateTime',
            width: 100,
            sorter: (a:any, b:any) => a.RegDateTime - b.RegDateTime,
        },
        {
            title: t('US.COLLECTION.CREDITOR:ADDRESSINFO.MODIFIEDUSER'),
            dataIndex: 'ModifiedUser',
            key: 'ModifiedUser',
            width: 100,
            sorter: (a:any, b:any) => a.ModifiedUser - b.ModifiedUser,
        }
    ];

    return (
        <>
            <Table dataSource={props.data} columns={columns} scroll={{ x: 100 }} />
        </>
    );
}

export default AddressInfo;
