import { ICase } from "us.collection.transactions/interfaces";

/**
 * @function
 * @description - repository to get the API request object for merge case
 * @param {*} this - any type object
 * @return {*} - API calling object
 */
export function GetMergeCasesRequestObject(this: any) {
  return {
    arNo: this.arId,
    creditorId: this.creditorId,
    debtorId: this.debtorId,
    destinationCaseNo: this.destinationCase,
    value: this.selectedCases
      .filter((selectCase: ICase) => selectCase.caseId !== this.destinationCase)
      .map(({ caseId }: any) => caseId),
  };
}
