import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getDebtorEntHistory } from 'us.collection.debtor/actions';
import { useTranslation } from 'react-i18next';
import Common from 'us.common';
import { debtor } from 'us.collection.debtor/actions';
import { IRootState } from 'us.collection/interfaces';
import moment from 'moment';
import { IEntityHistory } from 'us.collection.case/components/OtherParty/Edit/Components/Interfaces';
import {
	DateFormats,
	OtherPartyTypes,
} from 'us.collection.case/components/OtherParty/Constants';
import { IOnFilter, IOnSort } from 'us.common/components/antd/TableTree';

const { $Table, $Skeleton, $DateLabel, $TableTree } = Common.Components;
const { history } = debtor;

const EntityHistory: React.FC<IEntityHistory> = (props) => {
	const { t } = useTranslation();
	const {
		isFetchingDebtorHistory,
		debtorHistory,
		getDebtorHistory,
		selectedOtherPartyDetail,
	} = props;
	const [sortedInfo, setSortedInfo] = useState<any>({});
	const [type, setType] = useState<string>(OtherPartyTypes.PERSON);

	useEffect(() => {
		getDebtorHistory(selectedOtherPartyDetail?.entRoleId);

		if (selectedOtherPartyDetail?.otherPartyType) {
			setType(selectedOtherPartyDetail?.otherPartyType);
		}
	}, []);

	const handleTableChange = (
		pagination: any,
		filters: any,
		sorter: any
	) => {
		setSortedInfo(sorter);
	};

	const columnsPerson: any = [
		{
			title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.FULLNAME'),
			dataIndex: 'fullName',
			key: 'fullName',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
		{
			title: t(
				'US.COLLECTION.DEBTOR:ENTITYHISTORY.BIRTHDATE'
			),
			dataIndex: 'birthDate',
			key: 'birthDate',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (text: any, record: any) => {
				const { birthDate } = record;
				return (
					<span>
						{moment(birthDate).isValid() &&
							moment
								.utc(birthDate)
								.isAfter(
									moment.utc(
										DateFormats.MIN_DATE
									)
								) && (
								<$DateLabel
									value={
										birthDate
									}
								/>
							)}
					</span>
				);
			},
		},
		{
			title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.PERSONNO'),
			dataIndex: 'personNo',
			key: 'personNo',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
		},
		{
			title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.REGDATE'),
			dataIndex: 'regDate',
			key: 'regDate',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (text: any, record: any) => {
				const { regDate } = record;
				return (
					<span>
						{moment(regDate).isValid() &&
							moment
								.utc(regDate)
								.isAfter(
									moment.utc(
										DateFormats.MIN_DATE
									)
								) && (
								<$DateLabel
									value={
										regDate
									}
								/>
							)}
					</span>
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.DEBTOR:ENTITYHISTORY.MODIFIEDUSER'
			),
			dataIndex: 'modifiedUser',
			key: 'modifiedUser',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
	];

	const columnsCompany: any = [
		{
			title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.FULLNAME'),
			dataIndex: 'fullName',
			key: 'fullName',
			sclassName: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
		{
			title: t(
				'US.COLLECTION.DEBTOR:ENTITYHISTORY.COMPANY_NO'
			),
			dataIndex: 'personNo',
			key: 'personNo',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
		},
		{
			title: t('US.COLLECTION.DEBTOR:ENTITYHISTORY.REG_DATE'),
			dataIndex: 'regDate',
			key: 'regDate',
			lassName: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (text: any, record: any) => {
				const { regDate } = record;
				return (
					<span>
						{moment(regDate).isValid() &&
							moment
								.utc(regDate)
								.isAfter(
									moment.utc(
										DateFormats.MIN_DATE
									)
								) && (
								<$DateLabel
									value={
										regDate
									}
								/>
							)}
					</span>
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.DEBTOR:ENTITYHISTORY.MODIFIEDUSER'
			),
			dataIndex: 'modifiedUser',
			key: 'modifiedUser',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
	];

	const handleSort: IOnSort = (sortData, dataSource) => {
		return sortData(dataSource);
	};

	const handleFilter: IOnFilter = (searchData, dataSource) => {
		return searchData(dataSource);
	};

	return (
		<$Skeleton
			loading={isFetchingDebtorHistory}
			active
			paragraph={{ rows: 2 }}>
			<$TableTree
				onChange={handleTableChange}
				rowKey='id'
				data={debtorHistory}
				size='small'
				className='mt-3'
				onSort={handleSort}
				onFilter={handleFilter}
				filterOnType={true}
				resetOnSourceChange={true}
				bordered
				pagination={{ defaultPageSize: 15 }}
				scroll={{ x: 1200 }}
				columns={
					type === OtherPartyTypes.COMPANY
						? columnsCompany
						: columnsPerson
				}
			/>
		</$Skeleton>
	);
};

const mapStateToProps = (state: IRootState) => {
	const { debtor, common, entity, debtorDetail, domainView } = state;
	const { currentCurrency, currentLanguage, currentDateFormat } = common;
	const { debtorEntHistory } = debtor;
	const { history } = debtorDetail;
	const { isFetching } = entity;
	const { metaData } = domainView;
	return {
		debtorEntHistory,
		currentCurrency,
		currentLanguage,
		isFetching,
		isFetchingDebtorHistory: history.isFetching,
		debtorHistory: history.data,
		currentDateFormat,
		metaData,
	};
};

const mapDispatchToProps = {
	getDebtorEntHistory,
	getDebtorHistory: history.getByDebtorNo,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityHistory);
