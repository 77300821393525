import React from 'react';
import ManageAgreements from 'us.collection/components/ManageAgreements/ManageAgreements';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ToggleClass } from 'us.helper';
import { connect } from 'react-redux';

interface ICnt_Agreements extends RouteComponentProps {
    caseNo: number;
}

const Cnt_Agreements: React.FC<ICnt_Agreements> = (props) => {

    ToggleClass('change-body-color-dom');

    const onMinimize = () => {
        props.history.push(`/case/${props.caseNo}`);
    }

    return (
        <>
            <ManageAgreements onMinimize={() => onMinimize() } {...props} />
        </>


    )
}

// const mapStateToProps = (state: any) => {
//     return {
//         caseNo: state.case.caseNo
//     };
// };

// export default connect<{},{}, any>(mapStateToProps)(withRouter(Cnt_Agreements));

export default withRouter(Cnt_Agreements);
