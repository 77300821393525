import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { Formik } from 'formik';
import Common from 'us.common';
import { LoadingOutlined, SendOutlined } from 'us.icons';
import { SMSValidationSchema } from 'us.collection.case/components/Validations';
import { $Drawer, $Skeleton, $TextEditor, $Spin } from 'us.common/components';
import { MessageAndNoteAction } from 'us.collection.case/actions';
import { ISMS } from 'us.collection.case/components/Interfaces/NotesAndMessage';
import moment from 'moment';
import {
	FormatedTemplate,
	SendDomainMassage,
} from 'us.collection.case/repository';
import { DomainMessageType } from 'us.collection.case/constants/NotesAndMessage';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { appInsights } from 'us.helper';
import '../../ContentV1.scss';
import { RootState } from 'us.helper/types';

const {
	$Form,
	$Input,
	$Select,
	$Button,
	$DatePicker,
	$Switch,
	$TextArea,
	$Tooltip,
	$AutoComplete,
	$Row,
	$Col,
	$Typography,
} = Common.Components;

const { Paragraph } = $Typography;

const { formattedTemplate, domainMessage } = MessageAndNoteAction;

const SMS: React.FC<ISMS & PropsFromRedux> = (props) => {
	const { t } = useTranslation();

	const {
		currentDateFormat,
		domainMessageTemplate,
		domainMessageFormattedTemplate,
		openTemplateDrawer,
		domainMessageBasicdetail,
		metaData,
		domainMessageInitial,
		setNoteView,
		sendDomainMessage,
		getFormattedTemplate,
		openDrawer,
		reset,
		send,
	} = props;

	useEffect(() => {
		if (sendDomainMessage?.data?.isSuccess) {
			reset && reset({});
			setNoteView(DomainMessageType.SMS);
		}
	}, [sendDomainMessage?.data?.isSuccess]);

	const handleSubmit_ = (data: any) => {
		try {
			if (metaData.data) {
				const requestBody = SendDomainMassage.call(
					data,
					'SMS',
					metaData.data,
					domainMessageFormattedTemplate?.data
				);
				send && send(requestBody);
				openDrawer &&
					openDrawer({
						title: '',
						visible: false,
					});
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard NotesAndMessage-SMS Submit Exeception - ${error}`
			);
		}
	};

	const handleTemplateDrawer = (
		value: string,
		rest: any,
		values: any
	) => {
		try {
			if (metaData.data) {
				rest.setFieldValue('template', value);
				const template = domainMessageTemplate?.find(
					(template: any) =>
						template.TextName == value
				);
				const queryObject = FormatedTemplate.call(
					template.Text,
					value,
					values,
					metaData.data
				);
				getFormattedTemplate &&
					getFormattedTemplate(queryObject);
				openDrawer &&
					openDrawer({
						title: t(
							'US.COLLECTION.CASE:NOTESANDMESSAGES.EDIT_DOCUMENT'
						),
						visible: true,
					});
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard NotesAndMessage-SMS Drawer Handling Exeception - ${error}`
			);
		}
	};

	const closeTemplateDrawer = (rest: any) => {
		reset && reset({});
		rest.setFieldValue('template', '');
		rest.setFieldValue('templateNote', '');
		openDrawer && openDrawer({ title: '', visible: false });
	};

	const changeDocumentType = (receiver: string, rest: any) => {
		const entityDetails =
			domainMessageBasicdetail.entityDetails?.find(
				(entity: any) => entity.receiver === receiver
			);
		rest.setFieldValue('smsNumber', entityDetails?.smsNo);
		rest.setFieldValue('receiverEntityId', entityDetails?.entityId);
	};

	const handleChangeTemplateText = (content: any, rest: any) => {
		rest.setFieldValue('templateNote', content);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={domainMessageInitial}
			validationSchema={SMSValidationSchema}
			onSubmit={handleSubmit_}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...rest
			}: any) => (
				<div className='py-3'>
					<$Form>
						<$Row
							gutter={16}
							className='mt-0'>
							<$Col
								span={24}
								xxl={{
									span: 24,
								}}
								xl={{
									span: 24,
								}}>
								<div
									className='d-flex align-items-center'
									data-testid='payment-info'>
									<$Switch
										{...rest}
										id='isPaymentInfo'
										size='small'
										name='isPaymentInformation'
										checked={
											values.isPaymentInformation
										}
										defaultChecked={
											values.isPaymentInformation
										}
										disabled={
											sendDomainMessage?.isProgress ||
											(metaData.data &&
												isSubCaseTransferredToCase(
													metaData.data
												))
										}
									/>
									<Paragraph
										ellipsis
										className='mb-0 ml-2'
										data-testid='payment-info-label'>
										{t(
											'US.COLLECTION.CASE:NOTESANDMESSAGES.PAYMENTINFO'
										)}
									</Paragraph>
								</div>
							</$Col>
						</$Row>

						<$Row
							gutter={16}
							className='mt-3'>
							<$Col
								span={10}
								xxl={{
									span: 10,
								}}
								xl={{
									span: 12,
								}}>
								<div
									style={{
										marginTop: 0,
									}}
									data-testid='document-type-label'>
									{t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.DOCUMENTTYPE'
									)}
								</div>
							</$Col>
							<$Col
								span={14}
								xxl={{
									span: 14,
								}}
								xl={{
									span: 12,
								}}
								data-testid='select-document-type'>
								<$Select
									className='w-100'
									name='documentType'
									data-testid='document-type'
									placeholder='Status'
									size='small'
									allOption={
										false
									}
									options={
										domainMessageInitial.documentTypeList
									}
									onChange={(
										type: any
									) =>
										changeDocumentType(
											type,
											rest
										)
									}
									defaultValue={
										values.documentType
									}
									disabled={
										sendDomainMessage?.isProgress ||
										(metaData.data &&
											isSubCaseTransferredToCase(
												metaData.data
											))
									}
								/>
							</$Col>
						</$Row>

						<$Row
							gutter={16}
							className='mt-2'>
							<$Col
								span={10}
								xxl={{
									span: 10,
								}}
								xl={{
									span: 12,
								}}>
								<div
									style={{
										marginTop: 5,
									}}
									data-testid='sms-label'>
									{t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.SMSNUMBER'
									)}
								</div>
							</$Col>
							<$Col
								span={14}
								xxl={{
									span: 14,
								}}
								xl={{
									span: 12,
								}}
								data-testid='sms-number'>
								<$Input
									name='smsNumber'
									size='small'
									defaultValue={
										values.smsNumber
									}
									disabled={
										sendDomainMessage?.isProgress ||
										(metaData.data &&
											isSubCaseTransferredToCase(
												metaData.data
											))
									}
								/>
							</$Col>
						</$Row>

						<$Row gutter={16}>
							<$Col
								span={10}
								xxl={{
									span: 10,
								}}
								xl={{
									span: 12,
								}}>
								<div
									style={{
										marginTop: 5,
									}}
									data-testid='due-date-label'>
									{t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.MANUALDUEDATE'
									)}
								</div>
							</$Col>
							<$Col
								span={14}
								xxl={{
									span: 14,
								}}
								xl={{
									span: 12,
								}}>
								<$DatePicker
									className='w-100'
									formitem
									formitemlabel={t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.DUEDATE'
									)}
									style={{
										width: '100%',
									}}
									name='dueDate'
									size='small'
									placeholder={
										currentDateFormat
									}
									format={
										currentDateFormat
									}
									allowClear
									value={
										values.dueDate
									}
									defaultPickerValue={
										values.dueDate
									}
									disabledDate={(
										day: any
									) =>
										day.isBefore(
											moment().startOf(
												'day'
											)
										)
									}
									disabled={
										sendDomainMessage?.isProgress ||
										(metaData.data &&
											isSubCaseTransferredToCase(
												metaData.data
											))
									}
								/>
							</$Col>
						</$Row>

						{!values.isPaymentInformation && (
							<>
								<$Row
									gutter={
										16
									}>
									<$Col
										span={
											10
										}
										xxl={{
											span: 10,
										}}
										xl={{
											span: 12,
										}}>
										<div
											style={{
												marginTop: 5,
											}}
											data-testid='template-label'>
											{t(
												'US.COLLECTION.CASE:NOTESANDMESSAGES.TEMPLATE'
											)}
										</div>
									</$Col>
									<$Col
										span={
											14
										}
										xxl={{
											span: 14,
										}}
										xl={{
											span: 12,
										}}
										data-testid='domain-templates'>
										<$AutoComplete
											{...rest}
											className='w-100'
											size='small'
											name='template'
											data-testid='select-domain-templates-options'
											options={
												domainMessageInitial.templateList
											}
											defaultValue={
												values.template
											}
											onSelect={(
												templateValue: string
											) =>
												handleTemplateDrawer(
													templateValue,
													rest,
													values
												)
											}
											disabled={
												sendDomainMessage?.isProgress ||
												(metaData.data &&
													isSubCaseTransferredToCase(
														metaData.data
													)) ||
												(rest
													.touched
													.smsNumber
													? rest
															.errors
															.smsNumber
													: !values.smsNumber)
											}
										/>
									</$Col>
								</$Row>

								{!openTemplateDrawer.visible && (
									<$Row
										gutter={
											16
										}
										className='mt-2'>
										<$Col
											span={
												24
											}
											xxl={{
												span: 24,
											}}
											xl={{
												span: 24,
											}}>
											<$TextArea
												name='caseNote'
												className='note-message'
												data-testid='case-note'
												placeholder={t(
													'US.COLLECTION.CASE:NOTESANDMESSAGES.TYPEANOTEHERE'
												)}
												defaultValue={
													values.caseNote
												}
												disabled={
													sendDomainMessage?.isProgress ||
													(metaData.data &&
														isSubCaseTransferredToCase(
															metaData.data
														))
												}
											/>
										</$Col>
									</$Row>
								)}
							</>
						)}

						<div className='d-flex align-items-center justify-content-between mt-2'>
							<div>
								{sendDomainMessage?.isProgress && (
									<small className='d-block'>
										<$Spin
											size='small'
											indicator={
												<LoadingOutlined
													style={{
														fontSize: 20,
													}}
													spin
												/>
											}
										/>
										<span className='ml-2'>
											{t(
												'US.COLLECTION.CASE:NOTESANDMESSAGES.SEND_SMS_IN_PROGRESS'
											)}
										</span>
									</small>
								)}
							</div>
							<$Button
								type='default'
								htmlType='submit'
								onClick={
									handleSubmit
								}
								icon={
									<SendOutlined />
								}
								disabled={
									(metaData.data &&
										isSubCaseTransferredToCase(
											metaData.data
										)) ||
									sendDomainMessage?.isProgress
								}
								data-testid='send-btn'>
								{t(
									'US.COLLECTION.COMMON:COMMON.SEND'
								)}
							</$Button>
						</div>

						<$Drawer
							width='800'
							title={
								openTemplateDrawer.title
							}
							placement='right'
							closable={false}
							onClose={() =>
								closeTemplateDrawer(
									rest
								)
							}
							visible={
								openTemplateDrawer.visible
							}
							data-testid='edit-template'>
							<$Skeleton
								loading={
									domainMessageFormattedTemplate?.isLoading
								}
								active
								paragraph={{
									rows: 2,
								}}>
								<div>
									<$TextEditor
										value={
											values.templateNote
										}
										handleChange={(
											content: any
										) =>
											handleChangeTemplateText(
												content,
												rest
											)
										}
										name='templateNote'
									/>
								</div>
							</$Skeleton>
							<div className='drawer-footer-fixed align-content-center justify-content-end'>
								<div>
									<$Button
										disabled={
											!rest.dirty &&
											!(
												values
													.templateNote
													?.length ==
												0
											)
										}
										className='mr-2'
										type='primary'
										htmlType='submit'
										onClick={
											handleSubmit
										}>
										{t(
											'US.COLLECTION.COMMON:COMMON.SEND'
										)}
									</$Button>
									<$Button
										data-testid='drawer-cancel-btn'
										onClick={() =>
											closeTemplateDrawer(
												rest
											)
										}>
										{t(
											'US.COLLECTION.COMMON:COMMON.CANCEL'
										)}
									</$Button>
								</div>
							</div>
						</$Drawer>
					</$Form>
				</div>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, domainView, messageAndNotes } = state;
	const { isFetching, currentDateFormat } = common;
	const { metaData } = domainView;
	const {
		domainMessageTemplate,
		domainMessageFormattedTemplate,
		openTemplateDrawer,
		domainMessageBasicdetail,
		domainMessageInitial,
		sendDomainMessage,
	} = messageAndNotes;

	return {
		isFetching,
		currentDateFormat,
		domainMessageTemplate,
		domainMessageFormattedTemplate,
		openTemplateDrawer,
		domainMessageBasicdetail,
		metaData,
		domainMessageInitial,
		sendDomainMessage,
	};
};

const mapDispatchToProps = {
	getFormattedTemplate: formattedTemplate.get,
	openDrawer: formattedTemplate.openDrawer,
	reset: formattedTemplate.reset,
	send: domainMessage.save,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SMS);
