import { put, call, takeLatest, select } from "redux-saga/effects";
import { MessageTemplate } from "us.collection.case/constants";
import * as API from "us.collection.case/services";
import * as Actions from "us.collection.case/actions";
import { $MessageBox } from "us.common/components";
import { messageTemplateInitial } from "us.collection.case/reducers/MassageTemplates/State";
import { createMessageTemplatePatchObject } from "us.collection.case/repository";

const { messageTemplateAction } = Actions;
const {
  messageTemplates,
  messageTemplate,
  messageTemplateActivity,
  email,
  deleteMessageTempale,
  pinUnpinTemplate,
} = messageTemplateAction;

const {
  GET_MESSAGE_TEMPLATES_START,
  DELETE_MESSAGE_TEMPLATE_START,
  GET_MESSAGE_TEMPLATE_BY_ID_START,
  EXECUTE_MESSAGE_TEMPLATE_START,
  SEND_EMAIL_START,
  PIN_UNPIN_MESSAGE_TEMPLATE,
} = MessageTemplate;

const { pinUnpinMessageTemplate } = API.messageTemplate;

const messageTemplateSagas = {
  messageTemplates: {
    get: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.messageTemplate.getAll, params);
        if (Array.isArray(data)) {
          yield put(messageTemplates.success(data));
        } else {
          yield put(messageTemplates.fail({}));
        }
      } catch (error) {
        yield put(messageTemplates.fail(error as any));
      }
    },
  },
  messageTemplate: {
    get: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.messageTemplate.getById, params);
        if (data.hasOwnProperty("communicationJobId")) {
          yield put(messageTemplate.success(data));
        } else {
          yield put(messageTemplate.fail({}));
        }
      } catch (error) {
        yield put(messageTemplate.fail(error as any));
      }
    },
    delete: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.messageTemplate.delete, params);
        if (data.hasOwnProperty("messageTemplateId") && !data?.isError) {
          if (deleteMessageTempale.success) {
            yield put(deleteMessageTempale.success(params));
          }
          $MessageBox(
            "success",
            `US.COLLECTION.CASE:MASSAGETEMPLATES.SUCCESSFULLY_DELETED`,
            "",
            ""
          );
        } else {
          $MessageBox(
            "error",
            `US.COLLECTION.CASE:MASSAGETEMPLATES.DELETION_FAILED`,
            "",
            ""
          );
        }
      } catch (error) {
        $MessageBox(
          "error",
          `US.COLLECTION.CASE:MASSAGETEMPLATES.DELETION_FAILED`,
          "",
          ""
        );
        if (deleteMessageTempale.fail) {
          yield put(deleteMessageTempale.fail(error as any));
        }
      }
    },
  },
  messageTemplateActivity: {
    execute: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.messageTemplate.excuteActivity, params);
        if (data.hasOwnProperty("communicationJobId")) {
          $MessageBox(
            "success",
            `US.COLLECTION.CASE:MASSAGETEMPLATES.SUCCESSFULLY_GENERATED`,
            "",
            ""
          );
          if (messageTemplate.previewEditDrawer) {
            yield put(
              messageTemplate.previewEditDrawer({
                title: "",
                visible: false,
                isPreview: false,
                messageTemplate: messageTemplateInitial,
              })
            );
          }
          yield put(messageTemplateActivity.success(data));
        } else {
          $MessageBox(
            "error",
            `US.COLLECTION.CASE:MASSAGETEMPLATES.MESSAGE_GENERATION_FAILED`,
            "",
            ""
          );
          yield put(messageTemplateActivity.fail({}));
        }
      } catch (error) {
        $MessageBox(
          "error",
          `US.COLLECTION.CASE:MASSAGETEMPLATES.MESSAGE_GENERATION_FAILED`,
          "",
          ""
        );
        yield put(messageTemplateActivity.fail(error as any));
      }
    },
  },
  email: {
    send: function* (action: any): any {
      const params = action.payload.data;
      try {
        const executedata:
          | {
              communicationJobId: number;
              generatedMessgePath: string;
            }
          | undefined = yield call(API.messageTemplate.excuteActivity, params);
        const { communicationJobId, generatedMessgePath } = executedata ?? {};
        if (communicationJobId && generatedMessgePath) {
          const data = yield call(API.messageTemplate.sendEmail, {
            ...params,
            communicationJobId,
            attachmentPaths: [generatedMessgePath],
          });
          if (data.hasOwnProperty("isSuccess") && data.isSuccess) {
            $MessageBox(
              "success",
              `US.COLLECTION.CASE:MASSAGETEMPLATES.SUCCESSFULLY_SENT`,
              "",
              ""
            );
            if (messageTemplate.previewEditDrawer) {
              yield put(
                messageTemplate.previewEditDrawer({
                  title: "",
                  visible: false,
                  isPreview: false,
                  messageTemplate: messageTemplateInitial,
                })
              );
            }
            if (email.openDrawer) {
              yield put(
                email.openDrawer({
                  title: "",
                  visible: false,
                })
              );
            }
            yield put(email.success(data));
          } else {
            yield put(email.fail({}));
            $MessageBox(
              "error",
              `US.COLLECTION.CASE:MASSAGETEMPLATES.FAILED_TO_SEND_EMAIL`,
              "",
              ""
            );
          }
        } else {
          yield put(email.fail({}));
          $MessageBox(
            "error",
            `US.COLLECTION.CASE:MASSAGETEMPLATES.FAILED_TO_SEND_EMAIL`,
            "",
            ""
          );
        }
      } catch (error) {
        $MessageBox(
          "error",
          `US.COLLECTION.CASE:MASSAGETEMPLATES.FAILED_TO_SEND_EMAIL`,
          "",
          ""
        );
        yield put(messageTemplateActivity.fail(error as any));
      }
    },
  },
  pinUnpinMessageTemplate: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { data, templateLists } = yield call(
        pinUnpinMessageTemplate,
        createMessageTemplatePatchObject(params)
      );
      if (templateLists.status == 200) {
        yield put(pinUnpinTemplate.success(data));
        yield put(messageTemplates.success(templateLists.data));
      }
    } catch (error) {
      yield put(pinUnpinTemplate.fail(error as any));
    }
  },
};

export default [
  takeLatest(
    GET_MESSAGE_TEMPLATES_START,
    messageTemplateSagas.messageTemplates.get
  ),
  takeLatest(
    DELETE_MESSAGE_TEMPLATE_START,
    messageTemplateSagas.messageTemplate.delete
  ),
  takeLatest(
    GET_MESSAGE_TEMPLATE_BY_ID_START,
    messageTemplateSagas.messageTemplate.get
  ),
  takeLatest(
    EXECUTE_MESSAGE_TEMPLATE_START,
    messageTemplateSagas.messageTemplateActivity.execute
  ),
  takeLatest(SEND_EMAIL_START, messageTemplateSagas.email.send),
  takeLatest(
    PIN_UNPIN_MESSAGE_TEMPLATE,
    messageTemplateSagas.pinUnpinMessageTemplate
  ),
];
