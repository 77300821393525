import React, { useState, useEffect, useContext, memo } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { extendedField } from 'us.collection.case/actions';
import {
	EditOutlined,
	PlusOutlined,
	MoreOutlined,
	DeleteOutlined,
} from 'us.icons';
import Common from 'us.common';
import { AddExtendedField } from '../Add';
import { default as CaseConstants } from 'us.collection.case/constants';
import { IDataModel } from 'us.collection.case/reducers/Dashboard/Interfaces';
import { IMetaData } from 'us.collection/interfaces';
import { ConfigurationContext } from 'us.common/ConfigurationContext/ConfigurationProvider';
import { IOnFilter, IOnSort } from 'us.common/components/antd/TableTree';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { RootState } from 'us.helper/types';

const {
	$Button,
	$Popover,
	$PageHeader,
	$Divider,
	$Drawer,
	$Affix,
	$Popconfirm,
	$Input,
	$Skeleton,
	$TableTree,
	$DateLabel,
} = Common.Components;

const { EXTENDED_FIELD_TYPE } = CaseConstants;

interface IExtendedFieldHome extends RouteComponentProps {
	onMinimize(): void;
	getExtendedField: any;
	extendedFields: any;
	currentDateFormat: string;
	manageExtendedFieldDrawer?: any;
	drawer?: any;
	metaData: IDataModel<IMetaData>;
}

export interface ISortOrder {
	columnKey: string;
	order: any;
}

const ExtendedFieldHome: React.FC<IExtendedFieldHome> = memo((props) => {
	const { t } = useTranslation();
	const context = useContext(ConfigurationContext);
	const extendedFieldPermission =
		context.componentPermission['case']?.routes['extendedField'];

	const [popVisible, setPopVisibile] = useState<number>(-1);

	const {
		extendedFields,
		getExtendedField,
		manageExtendedFieldDrawer,
		drawer,
		metaData,
		history,
		location,
	} = props;

	const { entityType, caseId, caseNo } = metaData.data ?? {};
	useEffect(() => {
		if (caseId && entityType) {
			const extendedType =
				entityType === 'S'
					? EXTENDED_FIELD_TYPE.Transaction
					: EXTENDED_FIELD_TYPE.Case;
			const fieldId = caseId;
			getExtendedField({ extendedType, fieldId });
		}
	}, [metaData]);

	// Popover actions in table
	const content = (record: any) => (
		<div className='table-more-content'>
			<div
				className='d-flex flex-row more-item'
				onClick={() => {
					setPopVisibile(-1);
					handleEditExtended();
				}}>
				<div className='p-1'>
					<EditOutlined />
				</div>
				<div className='p-1'>
					{t('US.COLLECTION.COMMON:COMMON.EDIT')}
				</div>
			</div>
			<$Popconfirm
				title={t(
					'US.COLLECTION.CASE:EXTENDEDFIELD.DELETECONFIRM'
				)}
				onConfirm={() => setPopVisibile(-1)}
				onCancel={() => setPopVisibile(-1)}>
				<div className='d-flex mb-2 flex-row more-item text-error'>
					<div className='p-1'>
						<DeleteOutlined />
					</div>
					<div className='p-1'>
						{t(
							'US.COLLECTION.COMMON:COMMON.DELETE'
						)}
					</div>
				</div>
			</$Popconfirm>
		</div>
	);

	const columns: any = [
		{
			title: '',
			key: 'more',
			dataIndex: 'more',
			width: '50px',
			customRenderChild: (text: any, record: any) => {
				return (
					<$Popover
						placement='rightTop'
						content={content(record)}
						destroyTooltipOnHide
						trigger='click'
						visible={
							record.id == popVisible
						}
						onVisibleChange={(
							visbile: boolean
						) =>
							setPopVisibile(
								visbile
									? record.id
									: -1
							)
						}>
						<$Button
							icon={<MoreOutlined />}
							size='small'
							disabled={
								metaData.data &&
								isSubCaseTransferredToCase(
									metaData.data
								)
							}
						/>
					</$Popover>
				);
			},
		},
		{
			title: t('US.COLLECTION.CASE:EXTENDEDFIELD.NAME'),
			dataIndex: 'name',
			key: 'name',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
		{
			title: t(
				'US.COLLECTION.CASE:EXTENDEDFIELD.DISPLAYNAME'
			),
			dataIndex: 'displayName',
			key: 'displayName',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
		{
			title: t('US.COLLECTION.CASE:EXTENDEDFIELD.DATATYPE'),
			dataIndex: 'dataType',
			key: 'dataType',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
		{
			title: t('US.COLLECTION.CASE:EXTENDEDFIELD.VALUE'),
			dataIndex: 'value',
			key: 'value',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			render: (text: any, record: any) => {
				return (
					<>
						{record.dataType == 'Date' && (
							<$DateLabel
								value={
									record.value
								}
							/>
						)}
						{record.dataType != 'Date' &&
							record.value}
					</>
				);
			},
		},
	];

	const handleAddNewExtended = () => {
		manageExtendedFieldDrawer({
			title: t(
				'US.COLLECTION.CASE:EXTENDEDFIELD.NEWEXTENDED'
			),
			visible: true,
			isNewExtendedField: true,
		});
	};

	const handleEditExtended = () => {
		manageExtendedFieldDrawer({
			title: t(
				'US.COLLECTION.CASE:EXTENDEDFIELD.EDIT_EXTENDED_FIELD'
			),
			visible: true,
			isNewExtendedField: false,
		});
	};

	const onClose = () => {
		manageExtendedFieldDrawer({
			title: '',
			visible: false,
			isNewExtendedField: true,
		});
	};

	const minimize = () => {
		history.push({ ...location, pathname: `/case/${caseNo}` });
	};

	const handleSort: IOnSort = (sortData, dataSource) => {
		return sortData(dataSource);
	};

	const handleFilter: IOnFilter = (searchData, dataSource) => {
		return searchData(dataSource);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{}}
			onSubmit={() => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
			}: any) => (
				<>
					<div className='space-content'>
						<$Affix offsetTop={80}>
							<div className='page-header header-border'>
								<div className='d-flex flex-row align-items-center justify-content-between'>
									<div className='d-flex align-items-center'>
										<$PageHeader
											className='px-0'
											title={t(
												'US.COLLECTION.CASE:EXTENDEDFIELD.TITLE'
											)}
											onBack={
												minimize
											}
										/>
										<$Divider
											className='bui-devider'
											type='vertical'
										/>
										{extendedFieldPermission
											?.components[
											'newField'
										]
											?.isEnabled && (
											<$Skeleton
												loading={
													extendedFields?.isFetching
												}
												active
												paragraph={{
													rows: 0,
												}}>
												<$Button
													type='dashed'
													size='small'
													onClick={() =>
														handleAddNewExtended()
													}
													icon={
														<PlusOutlined />
													}
													disabled={
														metaData.data &&
														isSubCaseTransferredToCase(
															metaData.data
														)
													}>
													{t(
														'US.COLLECTION.CASE:EXTENDEDFIELD.ADDNEWFIELD'
													)}
												</$Button>
											</$Skeleton>
										)}
									</div>
								</div>
							</div>
						</$Affix>

						<$Skeleton
							loading={
								extendedFields?.isFetching
							}
							active
							paragraph={{ rows: 2 }}>
							<$TableTree
								rowKey='id'
								data={
									extendedFields
										?.data
										?.extendedData
								}
								size='small'
								className='mt-3'
								onSort={
									handleSort
								}
								onFilter={
									handleFilter
								}
								filterOnType={
									true
								}
								resetOnSourceChange={
									true
								}
								bordered
								pagination={{
									defaultPageSize: 15,
								}}
								scroll={{
									x: 1200,
								}}
								columns={
									columns
								}
								onRow={(
									record: any
								) => {
									return {
										onDoubleClick:
											() => {
												handleEditExtended();
											},
									};
								}}
							/>
						</$Skeleton>
					</div>

					<$Drawer //Add extended field
						title={drawer.title}
						width={450}
						visible={drawer.visible}
						onClose={() => onClose()}
						destroyOnClose>
						<AddExtendedField
							onClose={onClose}
						/>
					</$Drawer>
				</>
			)}
		</Formik>
	);
});

const mapStateToProps = (state: RootState) => {
	const { common, extendedField, domainView } = state;
	const { currentDateFormat } = common;
	const { extendedFields, drawer } = extendedField;
	const { metaData } = domainView;
	return {
		extendedFields,
		drawer,
		currentDateFormat,
		metaData,
	};
};

const mapDispatchToProps = {
	getExtendedField: extendedField.extendedFields.get,
	manageExtendedFieldDrawer: extendedField.drawer.set,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtendedFieldHome);
