import {
  FollowUpEntityType,
  FollowUpUniqueCol,
  LastAction,
} from "us.collection.followup/constants";
import { FollowUpAction } from "us.common/components/FollowUps/Constants";

/**
 * @function
 * @description filter followup data according to last action
 * @param dataSource followup table data
 * @param lastAction last action
 * @returns
 */
export const filterFollowUpByLastAction = (
  dataSource: Array<Object>,
  lastAction: string
): Array<Object> => {
  switch (lastAction) {
    case LastAction.ALL:
      return dataSource;
    case LastAction.PENDING:
      return dataSource.filter(
        (followUp: any) => followUp?.Lastactionstatus === LastAction.PENDING
      );
    case LastAction.EXECUTED:
      return dataSource.filter(
        (followUp: any) =>
          followUp?.Lastactionstatus === LastAction.SUCCESS ||
          followUp?.Lastactionstatus === LastAction.EXECUTED
      );
    case LastAction.FAILED:
      return dataSource.filter(
        (followUp: any) =>
          followUp?.Lastactionstatus === LastAction.FAILED ||
          followUp?.Lastactionstatus === LastAction.PENDING_FAILED
      );
    case LastAction.OTHER:
      return dataSource.filter(
        (followUp: any) =>
          followUp?.Lastactionstatus === "" ||
          followUp?.Lastactionstatus === LastAction.OPEN ||
          followUp?.Lastactionstatus === LastAction.VIEW
      );
    default:
      return dataSource;
  }
};

/**
 * @description - Check whether the given id (case no, sub case no, debtor or creditor no) related result is viewed or not
 * @param {Array<string | number>} viewedList
 * @param {string | number} id
 */
export const isViewed = (
  viewedList: Array<string | number>,
  id: string | number
): Boolean => {
  try {
    if (viewedList.length > 0) {
      return viewedList.some((viewedId) => viewedId == id);
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

/**
 * @function
 * @description get displayName
 * @param {Array<any>} followups -followup list
 * @param {string | number} followupId -followup Id that is in query params
 * @returns {string} followup name
 */
export const getFollowupName = (
  followups: Array<any>,
  followupId: string | number
): string => {
  try {
    if(followups.length > 0 && followupId){
      return followups.find(
          (followup: any) => followup.followupId.toString() == followupId
      )?.followupDisplayName ?? "";
    }else 
    return ""     
  } catch (error) {
      return "";
  }
};

/**
 * @description - Get find linked column with respect to the entityType
 * @param entityType - entityType
 * @returns  final array of possible columns to linked.
 */
export const getLinkedEntity = (entityType: string): string => {
  switch (entityType) {
    case FollowUpEntityType.CASE:
    case FollowUpEntityType.INVOICE:
      return FollowUpUniqueCol.EX_CASE_NO;
    case FollowUpEntityType.CREDITOR:
      return FollowUpUniqueCol.CREDITOR_NO;
    case FollowUpEntityType.DEBTOR:
      return FollowUpUniqueCol.AR_NO;
    case FollowUpEntityType.PAYMENT:
      return FollowUpUniqueCol.PAYMENT_ID;
    default:
      return "";
  }
};

/**
 * @description - Get conformation message for action 
 * @param type - Action type
 * @returns 
 */
export const getConfirmationMessage = (type: string) : string => {
  switch(type){
    case FollowUpAction.POSTPONE:
      return "US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.POSTPONE_CONFIRM"
    case FollowUpAction.DONE:
      return "US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.DONE_CONFIRM"
    case FollowUpAction.REMOVE:
      return "US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.REMOVE_CONFIRM"
    default:
      return ""
  }
}
