import { DrawerType } from "us.collection.transactions/constants";
import { IManageCaseForm } from "../Interfaces";

/**
 * @function
 * @description - make the decision if the merge button need to be enabled
 * @param {IManageCaseForm} { destinationCase, newCase } - form data
 * @param {DrawerType} - drawer type
 * @return {*}  {boolean} - enabling decision
 */
export const isMergeButtonEnabled = (
  { destinationCase, newCase }: IManageCaseForm,
  drawerType: DrawerType
): boolean => {
  return (
    destinationCase !== "" &&
    ((!newCase && drawerType === DrawerType.CREATE_CASES) || drawerType === DrawerType.MERGE_CASES)
  );
};
