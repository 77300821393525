import React, { useEffect, useState, memo } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import "../NonProductionDays.scss";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
import Common from "us.common";
import { nonProductionDays } from "us.collection.admin/actions";
import { useParams, useHistory } from "react-router-dom";
import {
  CalendarOutlined,
  LeftOutlined,
  RightOutlined,
  DeleteOutlined,
} from "us.icons";
import { $Form } from "us.common/components";
import {
  NonBankingDays,
  NonSmsDays,
  NonPrintingDays,
  NonWorkflowDays,
} from "./Components";
import { ImportCalendar } from "us.collection.admin/components/NonProductionDays";
import { SaveCalendar } from "us.collection.admin/repository";
import { IRootState } from "us.collection/interfaces";

import CalendarScehama from "./Validations";
import rootReducer from "rootReducer";
import { set } from "lodash";

const {
  $Tabs,
  $Input,
  $Button,
  $Popconfirm,
  $PageHeader,
  $Divider,
  $Skeleton,
  $Affix,
  $Calendar,
  $DatePicker,
  $Tooltip,
  $Switch,
  $Col,
  $Row,
  $Breadcrumb,
} = Common.Components;

export interface ISortOrder {
  columnKey: string;
  order: any;
}

interface ICalendar {
  getCalendars?: any;
  isFetching: boolean;
  isImported: boolean;
  currentDateFormat: string;
  currentLanguage: string;
  changeSelectedYear?: any;
  getNonProdDaysWithSmsSchedule?: any;
  cancelSelectedCalendar?: any;
  selectCriteria?: any;
  saveCalendar?: any;
  nonProductionDays: Array<any>;
  previousCalendars: Array<any>;
  smsAllowedTime: Array<any>;
  selectedNonProductionDate?: any;
  calendars?: any;
  selectedYear: number;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

interface IBase extends ICalendar {}

const Calendar: React.FC<IBase> = memo((props) => {
  const { t } = useTranslation(["US.COLLECTION.ADMIN", "US.COLLECTION.COMMON"]);
  const history = useHistory();
  const {
    getCalendars,
    isFetching,
    isImported,
    currentDateFormat,
    currentLanguage,
    getNonProdDaysWithSmsSchedule,
    cancelSelectedCalendar,
    saveCalendar,
    calendars,
    changeSelectedYear,
    selectedYear,
    startDate,
    endDate,
    nonProductionDays,
    previousCalendars,
    smsAllowedTime,
    selectedNonProductionDate,
  } = props;

  const { calendarId } = useParams<{ calendarId: string }>();
  const [selectedCriteria, setSelectedCriteria] = useState<string>(
    "NonBanking"
  );
  const [popYear, setPopYear] = useState<number>(-1);
  const [popVisible, setPopVisible] = useState<boolean>(false);
  const [pickVisible, setPickVisible] = useState<boolean>(false);

  useEffect(() => {
    if (calendarId) {
      //fetch the non-prod days according to the calendarId and year
      getNonProdDaysWithSmsSchedule({
        calendarId,
        startDate,
        endDate,
        criteria: "All",
        dayId: -1,
      });
    }
    getCalendars();
    console.log(calendarId);
  }, [selectedYear]);

  const decrementYear = () => {
    changeSelectedYear(selectedYear - 1, calendarId ? true : false);
  };
  const incrementYear = () => {
    changeSelectedYear(selectedYear + 1, calendarId ? true : false);
  };

  const addNewNonProductionDay = (nonProdDay: any, values: any, rest: any) => {
    console.log("/*/*/*/*/*", nonProdDay, values, rest);
    const newNonProdDay = {
      ...nonProdDay,
      key: values?.nonProductionDays?.length,
      isNew: true,
    };
    rest.setFieldValue("selectedNonProductionDate", newNonProdDay);
    newNonProdDay?.criteria?.map((cri: any) => {
      rest.setFieldValue(`${cri.type}Switch`, cri.value == 1 ? true : false);
    });
  };

  const removeNonProductionDay = (
    record: any,
    index: number,
    values: any,
    rest: any
  ) => {
    values?.nonProductionDays?.splice(record.key, 1);
    const newNonProdDay = values?.nonProductionDays?.map(
      (day: any, index: number) => ({ ...day, key: index })
    );
    rest.setFieldValue("nonProductionDays", newNonProdDay);
  };

  const addSmsTimeSlot = (index: number, values: any, rest: any) => {
    const smsAllowedTime = values?.smsAllowedTime;
    smsAllowedTime[index].timeSlots.push({
      id: -1,
      startTime: moment().set({ hour: 8, minute: 0 }).year(selectedYear),
      endTime: moment({ hour: 20, minute: 0 }).year(selectedYear),
    });
    rest.setFieldValue("smsAllowedTime", smsAllowedTime);
  };

  const removeSmsTimeSlot = (
    index: any,
    slotIndex: any,
    values: any,
    rest: any
  ) => {
    const smsAllowedTime = values?.smsAllowedTime;
    smsAllowedTime[index].timeSlots.splice(slotIndex, 1);
    rest.setFieldValue("smsAllowedTime", smsAllowedTime);
  };

  const onSelectDateInput = (
    record: any,
    index: number,
    values: any,
    rest: any
  ) => {
    console.log("GGGG", record, index, values, rest);
    rest.setFieldValue("selectedNonProductionDate", record);
    rest.setFieldValue(
      "NonBankingSwitch",
      record?.criteria.some(
        (cri: any) => cri["type"] == "NonBanking" && cri["value"] == 1
      )
    );
    rest.setFieldValue(
      "NonSmsSwitch",
      record?.criteria.some(
        (cri: any) => cri["type"] == "NonSms" && cri["value"] == 1
      )
    );
    rest.setFieldValue(
      "NonPrintingSwitch",
      record?.criteria.some(
        (cri: any) => cri["type"] == "NonPrinting" && cri["value"] == 1
      )
    );
    rest.setFieldValue(
      "NonWorkflowSwitch",
      record?.criteria.some(
        (cri: any) => cri["type"] == "NonWorkflow" && cri["value"] == 1
      )
    );
  };

  const selectDate = (date: any, values: any, rest: any) => {
    const newSelected = {
      ...values?.selectedNonProductionDate,
      date: date,
    };
    rest.setFieldValue("selectedNonProductionDate", newSelected);
  };

  const toggleAllSwitch = (
    value: boolean,
    selectedCriteria: string,
    rest: any
  ) => {
    rest.setFieldValue("NonBankingSwitch", value);
    rest.setFieldValue("NonSmsSwitch", value);
    rest.setFieldValue("NonPrintingSwitch", value);
    rest.setFieldValue("NonWorkflowSwitch", value);
    rest.setFieldValue(`${selectedCriteria}Switch`, true);
  };

  const saveNonProductionDay = (
    nonProductionDay: any,
    criterias: any,
    values: any,
    rest: any
  ) => {
    let newNonProductionDay = { ...nonProductionDay };
    if (criterias.All) {
      const a = nonProductionDay.criteria.map((criteria: any) => {
        criteria.value = 1;
      });
      newNonProductionDay = { ...nonProductionDay };
    } else {
      nonProductionDay.criteria.map((criteria: any) => {
        if (criteria.type == "NonBanking") {
          criterias.NonBanking ? (criteria.value = 1) : (criteria.value = 0);
        }
        if (criteria.type == "NonSms") {
          criterias.NonSms ? (criteria.value = 1) : (criteria.value = 0);
        }
        if (criteria.type == "NonPrinting") {
          criterias.NonPrinting ? (criteria.value = 1) : (criteria.value = 0);
        }
        if (criteria.type == "NonWorkflow") {
          criterias.NonWorkflow ? (criteria.value = 1) : (criteria.value = 0);
        }
      });
      newNonProductionDay = nonProductionDay;
    }
    const index = values?.nonProductionDays.findIndex(
      (day: any) => day.key === newNonProductionDay?.key
    );
    const prevNonProdDays = values?.nonProductionDays;
    if (index != -1) {
      prevNonProdDays[index] = { ...newNonProductionDay, isNew: false };
    } else {
      prevNonProdDays.push({ ...newNonProductionDay, isNew: false });
    }
    rest.setFieldValue("nonProductionDays", prevNonProdDays);
    rest.setFieldValue("selectedNonProductionDate", null);
    rest.setFieldValue("NonBankingSwitch", false);
    rest.setFieldValue("NonSmsSwitch", false);
    rest.setFieldValue("NonPrintingSwitch", false);
    rest.setFieldValue("NonWorkflowSwitch", false);
  };

  const disbaledDate = (current: any, values: any) => {
    let index = values.nonProductionDays.findIndex(
      (day: any) =>
        moment(day?.date).format("YYYY-MM-DD") === current.format("YYYY-MM-DD")
    );
    return index > -1 || current < moment().utcOffset(0, true);
  };

  const onSelectTab = (activeKey: string) => {
    setSelectedCriteria(activeKey);
  };

  const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
    columnKey: "",
    order: "",
  });

  return (
    <Formik
      initialValues={{
        ...calendars.filter((cal: any) => cal.calendarId == calendarId)[0],
        selectedNonProductionDate,
        nonProductionDays,
        previousCalendars,
        smsAllowedTime: smsAllowedTime?.map((day: any) => {
          const timeSlots = day?.timeSlots?.filter(
            (slot: any) =>
              slot.startTime.year() == selectedYear &&
              slot.endTime.year() == selectedYear
          );
          return {
            ...day,
            isEnable: timeSlots.length == 0 ? false : day.isEnable,
            timeSlots:
              timeSlots.length == 0
                ? [
                    {
                      id: -1,
                      startTime: moment()
                        .year(selectedYear)
                        .set({ hour: 8, minute: 0 }),
                      endTime: moment()
                        .year(selectedYear)
                        .set({ hour: 20, minute: 0 }),
                    },
                  ]
                : timeSlots,
          };
        }),
        openYearPicker: false,
      }}
      validationSchema={CalendarScehama}
      validateOnChange
      validateOnBlur
      validateOnMount
      enableReinitialize
      onSubmit={(values: any, actions: any) => {
        console.log("SUBMIT", values);
        actions.setSubmitting(true);
        let saveCalendarReq = SaveCalendar.call(values);
        if (actions.isValidating) return;
        saveCalendar(saveCalendarReq, history);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...rest
      }: any) => (
        <div className="non-production-days space-content">
          {console.log("4444", values)}
          <$Affix offsetTop={48}>
            <div className="page-header header-border">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="py-3">
                    <$Breadcrumb>
                      <$Breadcrumb.Item
                        onClick={() => history.push("/non-production-days")}
                      >
                        <a>
                          {t(
                            "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONPRODUCTIONDAYS"
                          )}
                        </a>
                      </$Breadcrumb.Item>
                      <$Breadcrumb.Item>
                        {calendarId
                          ? values?.calendarName
                          : t(
                              "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.ADDNEWCALENDAR"
                            )}
                      </$Breadcrumb.Item>
                    </$Breadcrumb>

                    <div className="d-flex align-items-center">
                      <$PageHeader
                        className="px-0 py-0"
                        onBack={() => history.push("/non-production-days")}
                        title={
                          calendarId
                            ? values?.calendarName
                            : t(
                                "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NEWCALENDAR"
                              )
                        }
                      />
                      <$Divider className="bui-devider" type="vertical" />
                      <$Skeleton
                        loading={isFetching}
                        active
                        paragraph={{ rows: 0 }}
                      >
                        <$Button
                          onClick={(e: any) => handleSubmit(e)}
                          type="primary"
                          size="small"
                          className="px-4"
                          disabled={!rest.isValid || isSubmitting}
                          loading={isSubmitting || isValidating}
                        >
                          {t("US.COLLECTION.COMMON:COMMON.SAVE")}
                        </$Button>
                      </$Skeleton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </$Affix>

          <$Form>
            <div className="mt-3">
              <$Input
                //size="small"
                placeholder={t(
                  "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.CALENDARNAME"
                )}
                name="calendarName"
                style={{ width: "400px" }}
              />
            </div>
            {/* Top Bar - Year selection Panel. */}
            <div className="mt-3">
              <div className="d-flex align-items-center">
                {rest.dirty && (
                  <$Popconfirm
                    title={t(
                      "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.POPCONFIRM1"
                    )}
                    placement="topLeft"
                    onConfirm={() => decrementYear()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <$Button
                      disabled={
                        typeof calendarId == "undefined" &&
                        selectedYear - 1 < moment().year()
                      }
                      size="small"
                      // onClick={() => decrementYear()}
                    >
                      <LeftOutlined />
                    </$Button>
                  </$Popconfirm>
                )}
                {!rest.dirty && (
                  <$Button
                    disabled={
                      typeof calendarId == "undefined" &&
                      selectedYear - 1 < moment().year()
                    }
                    size="small"
                    onClick={() => decrementYear()}
                  >
                    <LeftOutlined />
                  </$Button>
                )}
                <div className="px-4 current-year">
                  <strong>{selectedYear}</strong>
                </div>
                {rest.dirty && (
                  <$Popconfirm
                    title={t(
                      "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.POPCONFIRM1"
                    )}
                    placement="topLeft"
                    onConfirm={() => incrementYear()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <$Button size="small">
                      <RightOutlined />
                    </$Button>
                  </$Popconfirm>
                )}
                {!rest.dirty && (
                  <$Button size="small" onClick={() => incrementYear()}>
                    <RightOutlined />
                  </$Button>
                )}
                <$Button
                  size="small"
                  className="ml-2"
                  onClick={() => {
                    setPickVisible(pickVisible ? false : true)
                    // if (values?.openYearPicker) {
                    //   rest.setFieldValue("openYearPicker", false);
                    // } else {
                    //   rest.setFieldValue("openYearPicker", true);
                    // }
                  }}
                >
                  <CalendarOutlined />
                  {rest.dirty && (
                    <$Popconfirm
                      title={t(
                        "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.POPCONFIRM1"
                      )}
                      placement="topLeft"
                      onConfirm={() => {
                        setPopVisible(false);
                        setPickVisible(false)
                        changeSelectedYear(popYear, calendarId ? true : false);
                      }}
                      onCancel={() => setPopVisible(false)}
                      okText="Yes"
                      cancelText="No"
                      visible={popVisible}
                      onVisibleChange={(visible: boolean) => {values?.openYearPicker && setPopVisible(false)}}
                    >
                      <$DatePicker
                        className="year-pick"
                        name="yearPicker"
                        // open={values?.openYearPicker}
                        open={pickVisible}
                        // style={{ display: "none" }}
                        picker="year"
                        onSelect={(date: any) => {
                          if (date.year() != selectedYear) {
                            console.log("dirty", popVisible);
                            setPopYear(date.year());
                            setPopVisible(true);
                          }
                          setPickVisible(false)
                          // rest.setFieldValue("openYearPicker", false);
                          console.log("KKK", values?.openYearPicker);
                        }}
                        disabledDate={(current: any) => {
                          return typeof calendarId == "undefined"
                            ? current && current < moment()
                            : false;
                        }}
                      />
                    </$Popconfirm>
                  )}
                  {!rest.dirty && (
                    <$DatePicker
                      className="year-pick"
                      name="yearPicker"
                      // open={values?.openYearPicker}
                      open={pickVisible}
                      // style={{ display: "none" }}
                      picker="year"
                      onSelect={(date: any) => {
                        if (date.year() != selectedYear) {
                          console.log("not dirty", popVisible);
                          changeSelectedYear(
                            date.year(),
                            calendarId ? true : false
                          );
                        }
                        setPickVisible(false)
                        // rest.setFieldValue("openYearPicker", false);
                        console.log("KKK", values?.openYearPicker);
                      }}
                      disabledDate={(current: any) => {
                        return typeof calendarId == "undefined"
                          ? current && current < moment()
                          : false;
                      }}
                    />
                  )}
                </$Button>
                {/* <$DatePicker open={ }/> */}
                {calendarId &&
                  values?.nonProductionDays.length != 0 &&
                  isImported && (
                    <div>
                      <$Divider type="vertical" className="mx-3" />
                      <$Popconfirm
                        title={t(
                          "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.POPCONFIRM1"
                        )}
                        placement="topLeft"
                        onConfirm={() => {
                          cancelSelectedCalendar();
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <$Tooltip
                          placement="top"
                          title={t(
                            "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.REMOVESELECTEDCALENDAR"
                          )}
                        >
                          <$Button
                            size="small"
                            className="ml-2"
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                          />
                        </$Tooltip>
                      </$Popconfirm>
                    </div>
                  )}
              </div>
            </div>
            {/* When there are non Production days available and tab view for diffrent types of non production days */}
            {(typeof calendarId == "undefined" ||
              values?.nonProductionDays.length != 0) && (
              <div className="mt-3 mb-5">
                <$Row gutter={16}>
                  <$Col xl={{ span: 18 }} xxl={{ span: 14 }} className="pr-5">
                    <$Tabs
                      className="bui-tabs"
                      defaultActiveKey={selectedCriteria}
                      onChange={onSelectTab}
                    >
                      <$Tabs.TabPane
                        tab={
                          <$Tooltip
                            placement="top"
                            title={t(
                              "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONBANKINGDAYSDESC"
                            )}
                          >
                            {t(
                              "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONBANKINGDAYS"
                            )}
                          </$Tooltip>
                        }
                        key="NonBanking"
                      >
                        <NonBankingDays
                          nonBankingDays={values?.nonProductionDays.filter(
                            (nonProdDay: any) =>
                              nonProdDay?.criteria.find(
                                (criteria: any) =>
                                  criteria.type === "NonBanking" &&
                                  criteria.value === 1
                              )
                          )}
                          isFetching={isFetching}
                          currentLanguage={currentLanguage}
                          onSelectDate={(record: any, index: number) =>
                            onSelectDateInput(record, index, values, rest)
                          }
                          addNewNonProductionDay={(nonProdDay: any) =>
                            addNewNonProductionDay(nonProdDay, values, rest)
                          }
                          removeNonProductionDay={(
                            record: any,
                            index: number
                          ) =>
                            removeNonProductionDay(record, index, values, rest)
                          }
                        />
                      </$Tabs.TabPane>
                      <$Tabs.TabPane
                        tab={
                          <$Tooltip
                            placement="top"
                            title={t(
                              "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONSMSDAYSDESC"
                            )}
                          >
                            {t(
                              "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONSMSDAYS"
                            )}
                          </$Tooltip>
                        }
                        key="NonSms"
                      >
                        <NonSmsDays
                          nonSmsDays={values?.nonProductionDays.filter(
                            (nonProdDay: any) =>
                              nonProdDay?.criteria.find(
                                (criteria: any) =>
                                  criteria.type === "NonSms" &&
                                  criteria.value === 1
                              )
                          )}
                          smsAllowedTime={values?.smsAllowedTime}
                          isFetching={isFetching}
                          currentLanguage={currentLanguage}
                          onSelectDate={(record: any, index: number) =>
                            onSelectDateInput(record, index, values, rest)
                          }
                          addNewNonProductionDay={(nonProdDay: any) =>
                            addNewNonProductionDay(nonProdDay, values, rest)
                          }
                          removeNonProductionDay={(
                            record: any,
                            index: number
                          ) =>
                            removeNonProductionDay(record, index, values, rest)
                          }
                          addSmsTimeSlot={(index: number) =>
                            addSmsTimeSlot(index, values, rest)
                          }
                          removeSmsTimeSlot={(index: any, slotIndex: any) =>
                            removeSmsTimeSlot(index, slotIndex, values, rest)
                          }
                        />
                      </$Tabs.TabPane>
                      <$Tabs.TabPane
                        tab={
                          <$Tooltip
                            placement="top"
                            title={t(
                              "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONPRINTINGDAYSDESC"
                            )}
                          >
                            {t(
                              "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONPRINTINGDAYS"
                            )}
                          </$Tooltip>
                        }
                        key="NonPrinting"
                      >
                        <NonPrintingDays
                          nonPrintingDays={values?.nonProductionDays.filter(
                            (nonProdDay: any) =>
                              nonProdDay?.criteria.find(
                                (criteria: any) =>
                                  criteria.type === "NonPrinting" &&
                                  criteria.value === 1
                              )
                          )}
                          isFetching={isFetching}
                          currentLanguage={currentLanguage}
                          onSelectDate={(record: any, index: number) =>
                            onSelectDateInput(record, index, values, rest)
                          }
                          addNewNonProductionDay={(nonProdDay: any) =>
                            addNewNonProductionDay(nonProdDay, values, rest)
                          }
                          removeNonProductionDay={(
                            record: any,
                            index: number
                          ) =>
                            removeNonProductionDay(record, index, values, rest)
                          }
                        />
                      </$Tabs.TabPane>
                      <$Tabs.TabPane
                        tab={
                          <$Tooltip
                            placement="top"
                            title={t(
                              "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONWORKFLOWDAYSDESC"
                            )}
                          >
                            {t(
                              "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONWORKFLOWDAYS"
                            )}
                          </$Tooltip>
                        }
                        key="NonWorkflow"
                      >
                        <NonWorkflowDays
                          nonWorkflowDays={values?.nonProductionDays.filter(
                            (nonProdDay: any) =>
                              nonProdDay?.criteria.find(
                                (criteria: any) =>
                                  criteria.type === "NonWorkflow" &&
                                  criteria.value === 1
                              )
                          )}
                          isFetching={isFetching}
                          currentLanguage={currentLanguage}
                          onSelectDate={(record: any, index: number) =>
                            onSelectDateInput(record, index, values, rest)
                          }
                          addNewNonProductionDay={(nonProdDay: any) =>
                            addNewNonProductionDay(nonProdDay, values, rest)
                          }
                          removeNonProductionDay={(
                            record: any,
                            index: number
                          ) =>
                            removeNonProductionDay(record, index, values, rest)
                          }
                        />
                      </$Tabs.TabPane>
                    </$Tabs>
                  </$Col>
                  {values?.selectedNonProductionDate && (
                    <$Col span={8} className="pl-5 right-panel">
                      <h2 className="mb-3">
                        {values?.selectedNonProductionDate?.isNew
                          ? t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.ADD")
                          : t("US.COLLECTION.COMMON:COMMON.EDIT")}{" "}
                        : {values?.selectedNonProductionDate?.reason}
                      </h2>
                      <div className="mb-3">
                        <$Input
                          label={t(
                            "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.REASON"
                          )}
                          name="selectedNonProductionDate.reason"
                          value={values?.selectedNonProductionDate?.reason}
                          size="small"
                          style={{ width: "300px" }}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <div className="ant-col ant-form-item-label">
                          <label className="ant-form-item-required">
                            {t(
                              "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.SELECTDATE"
                            )}
                          </label>
                        </div>
                        <div className="inline-calendar">
                          <$Calendar
                            fullscreen={false}
                            mode="month"
                            onSelect={(date: any) =>
                              selectDate(date, values, rest)
                            }
                            disabledDate={(current: any) =>
                              disbaledDate(current, values)
                            }
                            value={
                              moment(
                                values?.selectedNonProductionDate?.date
                              ).isValid()
                                ? moment(
                                    values?.selectedNonProductionDate?.date
                                  )
                                : moment().year(selectedYear)
                            }
                            validRange={[
                              moment(`${selectedYear}-01-01`),
                              moment(`${selectedYear}-12-31`),
                            ]}
                            //onPanelChange={onPanelChange}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="ant-col ant-form-item-label">
                          <label>
                            {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.APPLYTO")}
                          </label>
                        </div>
                        <div className="mt-2 d-flex flex-1 align-items-top">
                          <div className="mr-3">
                            <$Switch
                              name="All"
                              checked={
                                values?.NonBankingSwitch &&
                                values?.NonSmsSwitch &&
                                values?.NonPrintingSwitch &&
                                values?.NonWorkflowSwitch
                              }
                              onChange={(val: boolean) =>
                                toggleAllSwitch(val, selectedCriteria, rest)
                              }
                            />
                          </div>
                          <div>{t("US.COLLECTION.COMMON:COMMON.ALL")}</div>
                        </div>
                        <div className="mt-1 d-flex flex-1 align-items-top">
                          <div className="mr-3">
                            <$Switch
                              name="NonBankingSwitch"
                              checked={
                                selectedCriteria == "NonBanking" ||
                                values?.NonBankingSwitch
                              }
                              disabled={selectedCriteria == "NonBanking"}
                            />
                          </div>
                          <div>
                            {t(
                              "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONBANKING"
                            )}
                          </div>
                        </div>
                        <div className="mt-1 d-flex flex-1 align-items-top">
                          <div className="mr-3">
                            <$Switch
                              name="NonSmsSwitch"
                              checked={
                                selectedCriteria == "NonSms" ||
                                values?.NonSmsSwitch
                              }
                              disabled={selectedCriteria == "NonSms"}
                            />
                          </div>
                          <div>
                            {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONSMS")}
                          </div>
                        </div>
                        <div className="mt-1 d-flex flex-1 align-items-top">
                          <div className="mr-3">
                            <$Switch
                              name="NonPrintingSwitch"
                              checked={
                                selectedCriteria == "NonPrinting" ||
                                values?.NonPrintingSwitch
                              }
                              disabled={selectedCriteria == "NonPrinting"}
                            />
                          </div>
                          <div>
                            {t(
                              "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONPRINTING"
                            )}
                          </div>
                        </div>
                        <div className="mt-1 d-flex flex-1 align-items-top">
                          <div className="mr-3">
                            <$Switch
                              name="NonWorkflowSwitch"
                              checked={
                                selectedCriteria == "NonWorkflow" ||
                                values?.NonWorkflowSwitch
                              }
                              disabled={selectedCriteria == "NonWorkflow"}
                            />
                          </div>
                          <div>
                            {t(
                              "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONWORKFLOW"
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="d-flex align-content-center justify-content-end mb-5">
                        <$Button
                          className="mr-2"
                          disabled={
                            isSubmitting ||
                            values?.selectedNonProductionDate?.reason == "" ||
                            !moment(
                              values?.selectedNonProductionDate?.date
                            ).isValid()
                          }
                          loading={isSubmitting || isValidating}
                          type="primary"
                          onClick={() =>
                            saveNonProductionDay(
                              values?.selectedNonProductionDate,
                              {
                                All: values?.All,
                                NonBanking: values?.NonBankingSwitch,
                                NonSms: values?.NonSmsSwitch,
                                NonPrinting: values?.NonPrintingSwitch,
                                NonWorkflow: values?.NonWorkflowSwitch,
                              },
                              values,
                              rest
                            )
                          }
                        >
                          {t("US.COLLECTION.COMMON:COMMON.ADD")}
                        </$Button>
                        {values?.selectedNonProductionDate?.reason != "" && (
                          <$Popconfirm
                            title={t(
                              "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.POPCONFIRM1"
                            )}
                            placement="topLeft"
                            onConfirm={() =>
                              rest.setFieldValue(
                                "selectedNonProductionDate",
                                null
                              )
                            }
                            okText="Yes"
                            cancelText="No"
                          >
                            <$Button>
                              {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
                            </$Button>
                          </$Popconfirm>
                        )}
                        {values?.selectedNonProductionDate?.reason == "" && (
                          <$Button
                            onClick={() =>
                              rest.setFieldValue(
                                "selectedNonProductionDate",
                                null
                              )
                            }
                          >
                            {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
                          </$Button>
                        )}
                      </div>
                    </$Col>
                  )}
                </$Row>
              </div>
            )}
            {/* Import Calendar View */}
            <ImportCalendar
              values={values}
              selectedYear={selectedYear}
              calendarId={calendarId}
              smsAllowedTime={smsAllowedTime}
            />
          </$Form>
        </div>
      )}
    </Formik>
  );
});

const mapStateToProps = (state: IRootState) => {
  const { nonProductionDays, common } = state;
  const { calendar, calendars, redDays, smsSchedule } = nonProductionDays;
  return {
    isFetching: redDays.isFetching,
    isImported: calendar.data.isImported,
    currentDateFormat: common.currentDateFormat,
    currentLanguage: common.currentLanguage,
    selectedYear: calendar.data.selectedYear,
    startDate: calendar.data.startDate,
    endDate: calendar.data.endDate,
    calendars: calendars.data,
    nonProductionDays: redDays.data.nonProductionDays,
    previousCalendars: calendar.data.previousCalendars,
    smsAllowedTime: smsSchedule.data,
    selectedNonProductionDate: redDays.data.selectedNonProductionDate,
  };
};

const mapDispatchToProps = {
  getCalendars: nonProductionDays.calendars.get,
  changeSelectedYear: nonProductionDays.calendar.changeYear,
  getNonProdDaysWithSmsSchedule: nonProductionDays.redDaysWithSmsSchedule.get,
  cancelSelectedCalendar: nonProductionDays.calendar.reset,
  selectCriteria: nonProductionDays.calendar.selectCriteria,
  saveCalendar: nonProductionDays.calendar.saveWithHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
