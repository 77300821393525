import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { CaseFilter, CaseTable, Header } from "./Components";
import * as Actions from "us.collection.creditor/actions";
import { RootState } from "us.helper/types/RootState";
import { SearchCreditorCases } from "./Repository";
import { CREDITOR_CASES_INITIAL_VALUES } from "us.collection.creditor/constants";
import "./Home.scss";

/**
 * @description - Creditor Case List Main View
 * @link Design Document - https://unicorn-solutions.atlassian.net/l/cp/0sYQ2euQ
 * @author Lashini Ayesha <lashinia@unicorn-solutions.com>
 * @since 24/05/2023
 */

const CreditorCases: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation([
    "US.COLLECTION.CREDITOR",
    "US.COLLECTION.COMMON",
    "US.COLLECTION.VALIDATIONS",
  ]);

  const { getCreditorCaseList, general } = props;

  useEffect(() => {
    searchCaseHandler(CREDITOR_CASES_INITIAL_VALUES);
  }, [general]);

  const searchCaseHandler = (filterValues: any) => {
    const creditorId = general.data?.pid;
    if (creditorId) {
      const requestParams = SearchCreditorCases.call({
        ...filterValues,
        creditorId,
      });
      getCreditorCaseList && getCreditorCaseList(requestParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={CREDITOR_CASES_INITIAL_VALUES}
        enableReinitialize
        validateOnChange
        validateOnBlur
        validateOnMount
        onSubmit={searchCaseHandler}
      >
        {({ values }: any) => (
          <>
            <div className="creditor-cases">
              <Header />
              <div className="cc-wrap">
                <CaseFilter />
                <CaseTable />
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const { creditorCases, creditor } = state;
  const { general } = creditor;
  return {
    general,
  };
};

const { creditorCases } = Actions;
const { caseList } = creditorCases;

const mapDispatchToProps = { getCreditorCaseList: caseList.get };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreditorCases);
