import {
	CalculatedInterest,
	ITransaction,
} from 'us.collection.transactions/interfaces';

/**
 * @description - Check whether tha given transaction is paid or not
 * @param {ITransaction} transaction - Transaction details
 * @param {boolean}
 */
export const isPaidTransaction = (transaction: ITransaction): boolean => {
	try {
		if (transaction) {
			return transaction.paid > 0;
		} else {
			return false;
		}
	} catch (error) {
		return false;
	}
};

export const sumCalculatedInterest = (
	interests: CalculatedInterest[]
): number => {
	try {
		const sum = interests.reduce(
			(accumulator: number, interest: CalculatedInterest) => {
				return accumulator + interest.interestAmount;
			},
			0
		);
		return sum;
	} catch (error) {
		return 0;
	}
};
