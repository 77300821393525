import { IDomainMessageFormattedTemplate, IFormattedTemplate } from "us.collection.case/reducers/Dashboard/Interfaces";

const FormattedTemplate : IFormattedTemplate = {
    communicationJodId: "",
    formattedText: ""
};

export const DomainMessageFormattedTemplate : IDomainMessageFormattedTemplate = {
    data : FormattedTemplate,
    isLoading : false
}