import { getISODateString, getValidRate } from "../Functions";

export function EditCourtSentenceRepository(this: any) {
  return {
    sentenceObject: {
      id: this.id,
      caseId: this.caseId,
      collectionFee: this.collectionFee,
      collectionFeeFromDate: getISODateString(this.collectionFeeFromDate),
      collectionFeeRate: getValidRate(this.collectionFeeRate),
      // continueType: this.continueType,
      caseCost: this.caseCost,
      caseCostFromDate: getISODateString(this.caseCostFromDate),
      caseCostRate: getValidRate(this.caseCostRate),
      courtFee: this.courtFee,
      courtRef: this.courtRef,
      declarationDate: getISODateString(this.declarationDate),
      dueDate: getISODateString(this.dueDate),
      interestCollectionFee: this.interestCollectionFee,
      interestCollectionFeeRate: getValidRate(this.interestCollectionFeeRate),
      interestCollectionFeeTo: getISODateString(this.interestCollectionFeeTo),
      limitDate: getISODateString(this.limitDate),
      mainAmount: this.mainAmount,
      mainAmountFromDate: getISODateString(this.mainAmountFromDate),
      mainAmountRate: getValidRate(this.mainAmountRate),
      mainAmountInterest: this.mainAmountInterest,
      mainAmountInterestRate: getValidRate(this.mainAmountInterestRate),
      mainAmountInterestToDate: getISODateString(this.mainAmountInterestToDate),
      modifiedUser: this.modifiedUser,
      otherCosts: this.otherCosts,
      otherCostsFromDate: getISODateString(this.otherCostsFromDate),
      otherCostsRate: getValidRate(this.otherCostsRate),
      sentenceDate: getISODateString(this.sentenceDate),
      sentenceNote: this.sentenceNote,
      sentenceTypeId: this.sentenceTypeId,
    },
    sentenceId: this.id,
  };
}
