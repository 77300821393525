import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { $Button, $DatePicker, $Input, $Select } from "us.common/components";
import { useTranslation } from "react-i18next";
import { RootState } from "us.helper/types/RootState";
import { useFormikContext } from "formik";
import { CREDITOR_CASES_INITIAL_VALUES } from "us.collection.creditor/constants";
import { Moment } from "moment";
import { CASE_TYPE_DROP_DOWN, EntityType } from "us.collection.creditor/components/CreditorCases/Constants";

/**
 * @description - Creditor Case List Dynamic Filter View
 * @link Design Document - https://unicorn-solutions.atlassian.net/l/cp/0sYQ2euQ
 * @author Lashini Ayesha <lashinia@unicorn-solutions.com>
 * @since 24/05/2023
 */

const CaseFilter: React.FC<PropsFromRedux> = (props) => {
  const { currentDateFormat } = props;

  const { setValues, values, setFieldValue } = useFormikContext<any>();

  const { t } = useTranslation([
    "US.COLLECTION.CREDITOR",
    "US.COLLECTION.COMMON",
  ]);

  /**
   * @description reset function of the dynamic filter
   * @returns {void}
   */
  const handleReset = () => {
    const {
      caseDateRange,
      isCaseOpen,
      isCaseClosed,
      isCollapsedAll,
    } = values;
    const valuesToBeReset = {
      ...CREDITOR_CASES_INITIAL_VALUES,
      caseDateRange,
      isCaseOpen,
      isCaseClosed,
      isCollapsedAll,
    };
    setValues(valuesToBeReset);
  };

  return (
    <div className="cc-filters" data-testid="dynamic-filter">
      <div className="mb-3">
        <strong>{t("US.COLLECTION.CREDITOR:CREDITOR_CASES.FILTERS")}</strong>
      </div>
      <div className="mb-2">
        <$Select
          name="caseType"
          size="small"
          allOption={false}
          style={{ width: '100%' }}
          options={CASE_TYPE_DROP_DOWN.map((item) => ({
            ...item,
            label: t(
              `US.COLLECTION.CREDITOR:CREDITOR_CASES.${item.label}`
            ),
          }))}
          defaultValue={EntityType.ALL}
          optionValue="value"
          optionText="label"
          data-testid="case-type-select-options"
        />
      </div>
      <div className="mb-2">
        <$Input
          name="debtorNameOrNumber"
          label={t("US.COLLECTION.CREDITOR:CREDITOR_CASES.DEBTOR")}
          placeholder={t(
            "US.COLLECTION.CREDITOR:CREDITOR_CASES.DEBTOR_NAME_AND_NUMBER"
          )}
          size="small"
        />
      </div>
      <div className="mb-2">
        <$DatePicker
          name="regDate"
          size={"small"}
          label={t("US.COLLECTION.CREDITOR:CREDITOR_CASES.INVOICE_REG_DATE")}
          className="w-100"
          value={values.regDate}
          defaultPickerValue={null}
          format={currentDateFormat}
          allowClear
          onChange={(momentTime: Moment) =>
            setFieldValue(
              'regDate',
              momentTime
            )
          }
        />
      </div>
      <div className="mb-2">
        <$DatePicker
          name="voucherDate"
          size={"small"}
          label={t(
            "US.COLLECTION.CREDITOR:CREDITOR_CASES.INVOICE_VOUCHER_DATE"
          )}
          className="w-100"
          defaultPickerValue={null}
          format={currentDateFormat}
          value={values.voucherDate}
          allowClear
          onChange={(momentTime: Moment) =>
            setFieldValue(
              'voucherDate',
              momentTime
            )
          }
        />
      </div>
      <div className="mb-2">
        <$Input
          name="invoiceRef"
          label={t("US.COLLECTION.CREDITOR:CREDITOR_CASES.REF")}
          size="small"
        />
      </div>
      <div className="mb-3">
        <$Input
          name="invoiceKid"
          label={t("US.COLLECTION.CREDITOR:CREDITOR_CASES.INVOICE_KID")}
          size="small"
        />
      </div>
      <div className="mb-3">
        <div>{t("US.COLLECTION.CREDITOR:CREDITOR_CASES.MAIN_AMOUNT")}</div>
        <div className="d-flex align-items-center">
          <div style={{ width: "50%" }}>
            <$Input
              name="mainAmountFrom"
              placeholder={t("US.COLLECTION.CREDITOR:CREDITOR_CASES.FROM")}
              size="small"
            />
          </div>
          <div style={{ width: "50%" }}>
            <$Input
              name="mainAmountTo"
              placeholder={t("US.COLLECTION.CREDITOR:CREDITOR_CASES.TO")}
              size="small"
            />
          </div>
        </div>
      </div>
      <div>
        <$Button
          type="default"
          size="small"
          className="w-100"
          onClick={handleReset}
        >
          {t("US.COLLECTION.CREDITOR:CREDITOR_CASES.RESET")}
        </$Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { common } = state;
  const { currentDateFormat } = common;
  return { currentDateFormat };
};

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CaseFilter);
