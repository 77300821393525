import * as Yup from 'yup';

interface DomainMessageLetter extends Yup.MixedSchema {
	documentType: string;
	dueDate: string;
	isGiro: boolean;
	template: string;
	caseNote: string;
	templateNote: string;
}

export default () => {
	return Yup.object<DomainMessageLetter>().shape({
		documentType: Yup.string().required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
		),
		template: Yup.string(),
		caseNote: Yup.string().when('template', {
			is: (template) => template == '' || template == undefined,
			then: Yup.string().required(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
			),
		}),
	});
};
