import { IInitialState } from "us.collection.debtor/reducers/Debtor/Interfaces";
import { otherInformation } from "./otherInformation";

export const initialState: IInitialState = {
  address: {
    data: [],
    isFetching: false,
  },
  creditor: {
    data: [],
    isFetching: false,
  },
  history: {
    data: [],
    isFetching: false,
  },
  other: {
    data: otherInformation,
    isFetching: false,
  },
  case: {
    data: [],
    isFetching: false,
  },
  badDebt: {
    data: [],
    isBadDebtor: false,
    isFetching: false,
  },
  credit: {
    data: {},
    isFetching: false,
  }, postalcode: {
    data: [],
    isLoading: false,
    isResponse: false
  },
  municipility: {
    data: [],
    isLoading: false,
    isResponse: false
  },
  isSaving: false
};
