import { DashboardAction } from 'us.collection.case/interfaces';
import Constants from 'us.collection.case/constants';
import { IAPIAction } from 'us.collection/interfaces';

const {
	GET_ACCOUNT_SUMMERY_WIDGET_DATA,
	GET_ACCOUNT_SUMMERY_WIDGET_DATA_FAIL,
	GET_ACCOUNT_SUMMERY_WIDGET_DATA_SUCCESS,
} = Constants.ACTIONS.AccountSummeryWidget;

export const AccountSummeryWidgetActions: IAPIAction = {
	accountSummery: {
		get: (data) => ({
			type: GET_ACCOUNT_SUMMERY_WIDGET_DATA,
			payload: {
				data: data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: GET_ACCOUNT_SUMMERY_WIDGET_DATA_SUCCESS,
			payload: {
				data: data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: GET_ACCOUNT_SUMMERY_WIDGET_DATA_FAIL,
			payload: {
				error: error,
				isLoading: false,
			},
		}),
	},
};
