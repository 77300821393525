import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import Common from 'us.common';
import './Enforcement.scss';
import { CloseOutlined, InfoCircleOutlined, PlusOutlined } from 'us.icons';
import { SelectAssets } from 'us.collection.case/components/Assets/SelectAssets';
import { AddAssets } from 'us.collection.case/components';
import { SalaryDeductionPlan } from 'us.collection.case/components/Enforcements/Add/Components';
import { CaseBalanceView } from 'us.collection.case/components/Enforcements/Add/Components';
import { newEnforcedItems } from 'us.collection.case/components/Enforcements/Add/Constants';
import {
	extractCaseBalancesFromEnforcement,
	onSelectAsset,
	selectAssetOnDropDown,
	selectEnforcementTypeOnDropDown,
} from 'us.collection.case/components/Enforcements/Add/Functions';
import {
	enforcements,
	AssetsAction,
	OtherPartyAction,
} from 'us.collection.case/actions';
import { IEnforcementItem } from 'us.collection.case/interfaces';
import {
	AssetTypes,
	EnforcementScreens,
	EntityTypes,
} from 'us.collection.case/constants';
import {
	getAssetsOption,
	getEnforcementTypeOption,
} from 'us.collection.case/functions';
import { SaveEnforcement } from 'us.collection.case/repository';
import { IAddEnforcement } from 'us.collection.case/components/Enforcements/Add/Interfaces';
import EnforcementSchema from './Validations';
import { appInsights } from 'us.helper';
import { calculateTotalValue } from 'us.common/components/CaseBalanceUpdate/Functions';
import { rows } from './Components/CaseBalanceView/Constants';
import { BalanceTypes } from 'us.common/components/CaseBalanceUpdate/Constants';
import { RootState } from 'us.helper/types';

const {
	$AmountLabel,
	$Col,
	$DatePicker,
	$Form,
	$Drawer,
	$DateLabel,
	$Input,
	$TextArea,
	$InputAmount,
	$Row,
	$Select,
	$Tooltip,
	$Divider,
	$Button,
	$Alert,
	$Skeleton,
	$Switch,
} = Common.Components;

const { enforcement, types, item, salaryDeductionPlan, accountSummary } =
	enforcements;
const { AddRealEstateAssets, RealEstateAssets, Assets } = AssetsAction;
const { otherParty } = OtherPartyAction;

const AddEnforcement: React.FC<IAddEnforcement> = (props) => {
	const { t } = useTranslation();
	const {
		currentDateFormat,
		currentLanguage,
		currentCurrency,
		enforcementList,
		enforcementDetail,
		enforcementDetailLoading,
		enforcementTypes,
		selectedEnforcementItem,
		salaryDeductionPlan,
		assets,
		getEnforcementTypes,
		setSelectedEnforcementItem,
		saveEnforcement,
		resetRealEstateAssets,
		setEnforcementDetails,
		getSalaryDeductionPlan,
		deleteSalaryDeductionPlan,
		resetSalaryDeductionPlan,
		resetDeleteSalaryDeductionPlan,
		changeDrawerInfo,
		isLegalCase,
		onClose,
		drawer,
		resetOtherPartyInfo,
		metaData,
		resetAssetlist,
		getAccountSummary,
		accountSummary,
	} = props;

	const { debtorEntNumber, caseId } = metaData.data ?? {};
	const [enforcementScreen, setEnforcementScreen] =
		useState<EnforcementScreens>(
			EnforcementScreens.NEW_ENFORCEMENT
		);
	const [editedAssetType, setAssetType] = useState<string>(
		AssetTypes.REAL_ESTATE
	);

	useEffect(() => {
		getEnforcementTypes({
			entityType: EntityTypes.DEBTOR,
			entityId: debtorEntNumber,
		});
		getAccountSummary && getAccountSummary({ caseId });
		enforcementDetail?.enforcementId > 0 &&
			!drawer.isNewEnforcement &&
			getSalaryDeductionPlan({
				caseId,
				enforcementId: enforcementDetail?.enforcementId,
			});
		resetAssetlist();
	}, []);

	const selectAssetLink = (
		selectedEnforcementItem: IEnforcementItem,
		index: number,
		formData: any
	) => {
		setSelectedEnforcementItem({
			selectedEnforcementItem,
			index,
			formData,
		});
		setEnforcementScreen(EnforcementScreens.SELECT_ASSETS);
	};

	const changeScreen = (screen: EnforcementScreens) => {
		setEnforcementScreen(screen);
	};

	const updateEmployerAsset = (
		employerAsset: any,
		values: any,
		rest: any
	) => {
		let finalValues = values;
		try {
			const indexEmployerBefore = _.findIndex(
				finalValues.enforcedItems,
				{
					assetType: 'Employer',
				}
			);
			finalValues.enforcedItems?.splice(
				indexEmployerBefore,
				1,
				{
					...finalValues.enforcedItems[
						indexEmployerBefore
					],
					faceValue: employerAsset?.faceValue,
				}
			);
			rest.setFieldValue(
				'enforcedItems',
				finalValues.enforcedItems
			);
		} catch (error) {
			appInsights.trackException(
				`Enforcement update Employer asset values exceptions - ${error}`
			);
			rest.setFieldValue(
				'enforcedItems',
				finalValues.enforcedItems
			);
		}
	};

	/**
	 * closing the add asset drawer
	 */
	const onAssetDrawerClose = () => {
		resetRealEstateAssets();
		changeDrawerInfo({
			title: t(''),
			visible: false,
			isEdit: false,
		});
		resetOtherPartyInfo({});
	};

	// Edit Assets details
	const handleShowAsset = ({ assetId, assetType }: any) => {
		changeDrawerInfo({
			title: t('US.COLLECTION.CASE:ASSETS.VIEW_ASSET'),
			visible: true,
			isEdit: true,
			assetId,
			showOnly: true,
		});
		setAssetType(assetType);
		resetRealEstateAssets();
	};

	const handleSubmit = (
		values: any,
		{ setSubmitting, isValidating }: any
	) => {
		setSubmitting(true);
		const saveEnforcementReq = SaveEnforcement.call(
			values,
			accountSummary
		);
		if (isValidating) return;
		saveEnforcement(saveEnforcementReq);
	};

	return (
		<Formik
			initialValues={{
				...enforcementDetail,
				caseId,
				isEmployerEnforced: drawer.isEmployerEnforced,
				caseBalance: {
					...extractCaseBalancesFromEnforcement(
						enforcementDetail
					),
					isEdited: false,
				},
				caseBalanceSwitch:
					enforcementDetail.isUpdateCaseBalance,
			}}
			validationSchema={EnforcementSchema}
			enableReinitialize
			onSubmit={handleSubmit}
			validateOnBlur
			validateOnChange
			validateOnMount>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...rest
			}: any) => (
				<>
					{enforcementScreen ===
						EnforcementScreens.NEW_ENFORCEMENT && (
						<$Form layout='vertical'>
							{/* The case is not in Legal state msg. */}
							{!isLegalCase && (
								<$Alert
									message={t(
										'US.COLLECTION.CASE:ENFORCEMENTS.NOT_IN_LEGAL_STATE_MSG'
									)}
									type='info'
									showIcon
									closable
									className='mb-3'
								/>
							)}
							<div className='enf-asset-item-table'>
								{/* Reapeatable assets row*/}
								<FieldArray
									name='enforcedItems'
									render={(
										arrayHelpers: any
									) => (
										<>
											{values?.enforcedItems?.map(
												(
													item: IEnforcementItem,
													index: number
												) => {
													return (
														<$Skeleton
															loading={
																enforcementDetailLoading
															}
															active
															title={{
																width: '500px',
															}}
															paragraph={{
																rows: 1,
																width: '700px',
															}}
															key={
																index
															}>
															<div
																key={
																	index
																}
																className='enf-asset-item-table-row bg-cell'>
																<div
																	className='enf-asset-item-table-col'
																	style={{
																		width: 300,
																	}}>
																	<div className='d-flex align-items-end'>
																		<div className='d-flex flex-column flex-fill'>
																			{(drawer.isNewEnforcement ||
																				(!drawer.isNewEnforcement &&
																					item.enforcementItemId ==
																						-1)) && (
																				<a
																					className='mt-1'
																					onClick={() =>
																						selectAssetLink(
																							item,
																							index,
																							values
																						)
																					}>
																					<span className='required'>
																						*
																					</span>{' '}
																					<strong>
																						{t(
																							'US.COLLECTION.CASE:ENFORCEMENTS.ASSETS'
																						)}
																					</strong>
																				</a>
																			)}
																			{!drawer.isNewEnforcement &&
																				item.enforcementItemId !=
																					-1 && (
																					<div>
																						<span className='required'>
																							*
																						</span>{' '}
																						<strong>
																							{t(
																								'US.COLLECTION.CASE:ENFORCEMENTS.ASSETS'
																							)}
																						</strong>
																					</div>
																				)}
																			<div
																				className='d-flex align-items-center'
																				style={{
																					marginTop: '0.09rem',
																				}}>
																				<div className='flex-fill '>
																					<$Select
																						name={`enforcedItems[${index}]['key']`}
																						dataTestid={`select-enforcementItems${index}`}
																						size='small'
																						style={{
																							width: '265px',
																						}}
																						formitem={{}}
																						options={getAssetsOption(
																							assets?.data,
																							item,
																							values?.enforcedItems
																						)}
																						onSelect={(
																							key: string
																						) => {
																							if (
																								item.assetType ==
																									AssetTypes.EMPLOYER &&
																								item.key !=
																									key
																							) {
																								//Change an employer asset to other asset
																								if (
																									_.find(
																										enforcementDetail.enforcedItems,
																										{
																											key,
																										}
																									)
																										?.assetType ==
																									AssetTypes.EMPLOYER
																								) {
																									//select already selected employer asset from another employer asset
																								} else {
																									//Change an employer asset to other asset other than already employer
																									resetSalaryDeductionPlan(
																										item
																									);
																								}
																							}
																							rest.setFieldValue(
																								'enforcedItems',
																								selectAssetOnDropDown(
																									values.enforcedItems,
																									index,
																									assets?.data,
																									item,
																									key
																								)
																							);
																						}}
																						optionText='displayName'
																						optionValue='key'
																						allOption={
																							false
																						}
																						autoFocus={
																							true
																						}
																						tabIndex={
																							1
																						}
																						dropdownMatchSelectWidth={
																							false
																						}
																						disabled={
																							!drawer.isNewEnforcement &&
																							item.enforcementItemId !=
																								-1
																						}
																						onSearchBy={[
																							'displayName',
																							'assetId',
																						]}
																						onBlur={(
																							e: any
																						) =>
																							rest.setTouched(
																								{
																									...rest.touched,
																									enforcedItems:
																										values.enforcedItems.map(
																											(
																												item: any
																											) => {
																												return {
																													key: true,
																													enforcementTypeId:
																														true,
																												};
																											}
																										),
																								}
																							)
																						}
																					/>
																				</div>
																				{item?.assetId !=
																					-1 &&
																					item?.assetId?.toString() !=
																						'' && (
																						<div
																							className='ml-1'
																							style={{
																								marginBottom:
																									'0.2rem',
																							}}>
																							<$Tooltip
																								placement='top'
																								title={t(
																									'US.COLLECTION.COMMON:COMMON.MORE_INFO'
																								)}>
																								<a
																									onClick={() =>
																										handleShowAsset(
																											item
																										)
																									}>
																									<InfoCircleOutlined />
																								</a>
																							</$Tooltip>
																						</div>
																					)}
																			</div>
																		</div>
																	</div>
																</div>
																<div
																	className='enf-asset-item-table-col'
																	style={{
																		width: 120,
																	}}>
																	<div className=''>
																		<$InputAmount
																			label={t(
																				'US.COLLECTION.CASE:ENFORCEMENTS.FACE_VALUE'
																			)}
																			min={
																				0
																			}
																			currentLanguage={
																				currentLanguage
																			}
																			currentCurrency={
																				currentCurrency
																			}
																			placeholder={
																				'0.00'
																			}
																			className='bui-number-input enf-amount-width w-100'
																			size='small'
																			name={`enforcedItems[${index}]['faceValue']`}
																			value={
																				values
																					?.enforcedItems[
																					index
																				]
																					?.faceValue
																			}
																			onChange={(
																				val: any
																			) => {
																				values.enforcedItems[
																					index
																				] =
																					{
																						...values
																							?.enforcedItems[
																							index
																						],
																						faceValue: val,
																					};
																				rest.setFieldValue(
																					'enforcedItems',
																					values.enforcedItems
																				);
																			}}
																			disabled={
																				values
																					?.enforcedItems[
																					index
																				]
																					?.assetId ==
																					'' ||
																				(values
																					?.enforcedItems[
																					index
																				]
																					?.assetType ==
																					AssetTypes.EMPLOYER &&
																					salaryDeductionPlan
																						.data
																						.caseId >
																						0) ||
																				(!drawer.isNewEnforcement &&
																					item.enforcementItemId !=
																						-1)
																			}
																			tabIndex={
																				2
																			}
																		/>
																	</div>
																</div>
																<div
																	className='enf-asset-item-table-col'
																	style={{
																		width: 120,
																	}}>
																	<div className='d-flex flex-column align-items-end'>
																		<div>
																			{t(
																				'US.COLLECTION.CASE:ENFORCEMENTS.VALUATION'
																			)}
																		</div>
																		<div className='text-right font-weight-bold pt-2 flex-fill'>
																			<$AmountLabel
																				value={
																					values
																						?.enforcedItems[
																						index
																					]
																						.valuationAmount
																				}
																			/>
																		</div>
																	</div>
																</div>
																<div
																	className='enf-asset-item-table-col select-type'
																	style={{
																		width: 212,
																	}}>
																	<$Select
																		name={`enforcedItems[${index}]['enforcementTypeId']`}
																		dataTestid={`select-enforcementTypes${index}`}
																		formitem={{
																			label: t(
																				'US.COLLECTION.CASE:ENFORCEMENTS.TYPE'
																			),
																		}}
																		size='small'
																		options={getEnforcementTypeOption(
																			enforcementTypes?.data,
																			item,
																			values?.enforcedItems
																		)}
																		optionText='enforcementTypeName'
																		optionValue='enforcementTypeId'
																		allOption={
																			false
																		}
																		onSelect={(
																			enforcementTypeId: string
																		) => {
																			rest.setFieldValue(
																				'enforcedItems',
																				selectEnforcementTypeOnDropDown(
																					values.enforcedItems,
																					index,
																					enforcementTypes?.data,
																					enforcementTypeId
																				)
																			);
																		}}
																		tabIndex={
																			3
																		}
																		disabled={
																			!drawer.isNewEnforcement &&
																			item.enforcementItemId !=
																				-1
																		}
																		required
																		dropdownMatchSelectWidth={
																			false
																		}
																		onSearchBy={[
																			'enforcementTypeName',
																			'enforcementTypeId',
																		]}
																		onBlur={(
																			e: any
																		) =>
																			rest.setTouched(
																				{
																					...rest.touched,
																					enforcedItems:
																						values.enforcedItems.map(
																							(
																								item: any
																							) => {
																								return {
																									key: true,
																									enforcementTypeId:
																										true,
																								};
																							}
																						),
																				}
																			)
																		}
																	/>
																</div>

																<div
																	className='enf-asset-item-table-col px-0'
																	style={{
																		width: 28,
																	}}>
																	{values
																		?.enforcedItems
																		?.length >
																		1 && (
																		<$Tooltip
																			placement='top'
																			title={t(
																				'US.COLLECTION.COMMON:COMMON.REMOVE'
																			)}>
																			<$Button
																				name={`enforcedItems[${index}]remove`}
																				size='small'
																				type='link'
																				danger
																				icon={
																					<CloseOutlined />
																				}
																				onClick={() => {
																					arrayHelpers.remove(
																						index
																					);
																					item.assetType ==
																						AssetTypes.EMPLOYER &&
																						item.enforcementItemId !=
																							-1 &&
																						deleteSalaryDeductionPlan(
																							item
																						);
																					item.assetType ==
																						AssetTypes.EMPLOYER &&
																						item.enforcementItemId ==
																							-1 &&
																						resetSalaryDeductionPlan(
																							item
																						);
																				}}
																				tabIndex={
																					4
																				}
																			/>
																		</$Tooltip>
																	)}
																</div>
															</div>
														</$Skeleton>
													);
												}
											)}
										</>
									)}
								/>

								{/* Total footer removed as client request CCM-1269*/}
							</div>

							<$Divider
								orientation='left'
								className='my-4'>
								<$Button
									name='addNew'
									data-testid='btn-add-asset'
									type='default'
									size='small'
									icon={
										<PlusOutlined />
									}
									onClick={() =>
										rest.setFieldValue(
											'enforcedItems',
											newEnforcedItems(
												values?.enforcedItems
											)
										)
									}
									disabled={_.find(
										values?.enforcedItems,
										(
											item: any
										) =>
											item?.assetType ==
												AssetTypes.NO_ASSET ||
											item?.assetType ==
												''
									)}>
									{t(
										'US.COLLECTION.CASE:ENFORCEMENTS.ADD_ASSET'
									)}
								</$Button>
							</$Divider>

							<$Row
								gutter={16}
								className='mb-2'>
								<$Col span={6}>
									<$DatePicker
										required
										label={t(
											'US.COLLECTION.CASE:ENFORCEMENTS.OFFICIAL_REGISTRATION_DATE'
										)}
										formProps={{
											'data-testid':
												'datapicker-formItem-registration-date',
										}}
										size='small'
										name='registrationDate'
										data-testid='datapicker-registration-date'
										format={
											currentDateFormat
										}
										placeholder={t(
											'US.COLLECTION.CASE:ENFORCEMENTS.SELECT_DATE'
										)}
										value={
											values?.registrationDate
										}
										onChange={(
											date: any
										) => {
											rest.setFieldValue(
												'registrationDate',
												moment(
													date,
													currentDateFormat
												)
											);
											rest.setFieldValue(
												'obsoleteDate',
												moment(
													date,
													currentDateFormat
												).add(
													10,
													'years'
												)
											);
										}}
										className='w-100'
										allowClear
										disabledDate={(
											current: moment.Moment
										) =>
											current &&
											current >
												moment()
										}
									/>
								</$Col>
								<$Col span={6}>
									<$DatePicker
										label={t(
											'US.COLLECTION.CASE:ENFORCEMENTS.EXECUTED_DATE'
										)}
										formProps={{
											'data-testid':
												'datapicker-formItem-executed-date',
										}}
										size='small'
										name='executedDate'
										data-testid='datapicker-executed-date'
										format={
											currentDateFormat
										}
										placeholder={t(
											'US.COLLECTION.CASE:ENFORCEMENTS.SELECT_DATE'
										)}
										value={
											values?.executedDate
										}
										className='w-100'
										allowClear
									/>
								</$Col>
								<$Col span={6}>
									<$DatePicker
										label={t(
											'US.COLLECTION.CASE:ENFORCEMENTS.OBSOLETE_DATE'
										)}
										size='small'
										name='obsoleteDate'
										data-testid='datapicker-obsolete-date'
										format={
											currentDateFormat
										}
										placeholder={t(
											'US.COLLECTION.CASE:ENFORCEMENTS.SELECT_DATE'
										)}
										value={
											values?.obsoleteDate
										}
										className='w-100'
									/>
								</$Col>
							</$Row>

							<$Row
								gutter={16}
								className='mb-2'>
								<$Col span={6}>
									<$Input
										name='referenceId'
										dataTestid='input-referenceId'
										label={t(
											'US.COLLECTION.CASE:ENFORCEMENTS.REFERENCE_NO'
										)}
										size='small'
										type='text'
										required
										data-private
									/>
								</$Col>
								<$Col span={6}>
									<$Input
										name='journalId'
										label={t(
											'US.COLLECTION.CASE:ENFORCEMENTS.JOURNAL_NO'
										)}
										size='small'
										type='text'
									/>
								</$Col>
							</$Row>
							<div>
								<$TextArea
									style={{
										width: '100%',
									}}
									label={t(
										'US.COLLECTION.COMMON:COMMON.COMMENT'
									)}
									name='comment'
									autoSize={{
										minRows: 2,
										maxRows: 4,
									}}
									required
								/>
							</div>
							{/* Salary Deduction plan start */}
							{!values.isEmployerEnforced &&
								_.find(
									values?.enforcedItems,
									{
										assetType: AssetTypes.EMPLOYER,
									}
								) && (
									<div
										className='my-4'
										data-testid='div-salary-deduction'>
										{(!salaryDeductionPlan.isSet ||
											(salaryDeductionPlan.isSet &&
												salaryDeductionPlan
													.data
													?.salaryDeductionSchema
													?.length ==
													0)) && (
											<$Alert
												message={t(
													'US.COLLECTION.CASE:ENFORCEMENTS.PLEASE_A_CREATE_SALARY_DEDUCTION_PLAN_FIRST'
												)}
												type='info'
												showIcon
												className='mb-3'
											/>
										)}

										<div className='d-flex mb-3'>
											<h3 className='flex-fill'>
												{t(
													'US.COLLECTION.CASE:ENFORCEMENTS.SALARY_DEDUCTION_PLAN'
												)}
											</h3>
											<div>
												<$Button
													size='small'
													type='default'
													onClick={() => {
														changeScreen(
															EnforcementScreens.SALARY_DEDUCTION
														);
													}}>
													{!salaryDeductionPlan.isSet ||
													(salaryDeductionPlan.isSet &&
														salaryDeductionPlan
															.data
															?.salaryDeductionSchema
															?.length ==
															0)
														? t(
																'US.COLLECTION.CASE:ENFORCEMENTS.CREATE_PLAN'
														  )
														: t(
																'US.COMMON:COMMON.UPDATE'
														  )}
												</$Button>
											</div>
										</div>
										{salaryDeductionPlan.isSet &&
											salaryDeductionPlan
												.data
												?.salaryDeductionSchema
												?.length >
												0 && (
												<$Row>
													<$Col
														span={
															6
														}>
														<div className='d-flex'>
															<span className='mr-1'>
																{t(
																	'US.COLLECTION.COMMON:COMMON.START_FROM'
																)}

																:
															</span>
															<b>
																<$DateLabel
																	value={
																		salaryDeductionPlan
																			.data
																			.fromDate
																	}
																/>
															</b>
														</div>
													</$Col>
													<$Col
														span={
															8
														}>
														<div className='d-flex'>
															<span className='mr-1'>
																{t(
																	'US.COLLECTION.COMMON:COMMON.DEDUCTION_AMOUNT'
																)}

																:
															</span>
															<b>
																<$AmountLabel
																	value={Number(
																		_.find(
																			salaryDeductionPlan
																				.data
																				.salaryDeductionSchema,
																			({
																				outstandingAmount,
																			}) =>
																				Number(
																					outstandingAmount
																				) >
																				0
																		)
																			?.termAmount
																	)}
																/>
															</b>
														</div>
													</$Col>
													<$Col
														span={
															6
														}>
														<div className='d-flex'>
															<span className='mr-1'>
																{t(
																	'US.COLLECTION.COMMON:COMMON.DUE_DATE'
																)}

																:
															</span>
															<b>
																<$DateLabel
																	value={
																		_.find(
																			salaryDeductionPlan
																				.data
																				.salaryDeductionSchema,
																			({
																				outstandingAmount,
																			}) =>
																				Number(
																					outstandingAmount
																				) >
																				0
																		)
																			?.dueDate
																	}
																/>
															</b>
														</div>
													</$Col>
												</$Row>
											)}
										<$Divider />
									</div>
								)}
							{/* Salary Deduction plan end */}

							{/* Update case balance start */}
							<div className='d-flex align-items-top mt-5'>
								<div className='mr-1'>
									<$Switch
										name='caseBalanceSwitch'
										disabled={!drawer.isNewEnforcement}
										checked={
											drawer.isNewEnforcement
												? enforcementDetail.isUpdateCaseBalance ||
												  values
														.caseBalance
														.isEdited
												: values.caseBalanceSwitch
										}
										onChange={(value: boolean) => {
											rest.setFieldValue("caseBalanceSwitch", value);
											if (value) {
												changeScreen(EnforcementScreens.UPDATE_CASE_BALANCE);
											} else {
												rest.setFieldValue("caseBalance", {
													...extractCaseBalancesFromEnforcement(
														enforcementDetail
													),
													isEdited: false,
												});
											}
										}}
									/>
								</div>
								<div className='flex-fill'>
									{(enforcementDetail.isUpdateCaseBalance ||
										values
											.caseBalance
											.isEdited) && (
										<$Button
											size='small'
											type='link'
											onClick={() => {
												changeScreen(
													EnforcementScreens.UPDATE_CASE_BALANCE
												);
											}}
											data-testid={
												'text-update-case-balance'
											}>
											{t(
												'US.COLLECTION.CASE:ENFORCEMENTS.UPDATE_CASE_BALANCE'
											)}
										</$Button>
									)}
									{!enforcementDetail.isUpdateCaseBalance &&
									!values.caseBalance.isEdited && (
										<span className="pl-2">
											{t(
											"US.COLLECTION.CASE:ENFORCEMENTS.UPDATE_CASE_BALANCE"
											)}
										</span>
									)}
									{(drawer.isNewEnforcement &&
										(enforcementDetail.isUpdateCaseBalance ||
											values
												.caseBalance
												.isEdited)) ||
										(!drawer.isNewEnforcement &&
											values.caseBalanceSwitch && (
												<div className='mt-2 pl-2'>
													<span>
														{t(
															'US.COLLECTION.CASE:ENFORCEMENTS.CASE_BALANCE_HAS_BEEN_UPDATED_TO'
														)}
													</span>{' '}
													<span className='font-weight-bold ml-2'>
														{' '}
														<$AmountLabel
															value={calculateTotalValue(
																{
																	...values.caseBalance,
																},
																BalanceTypes.CASE_BALANCE,
																rows
															)}
														/>
													</span>
												</div>
											))}
								</div>
							</div>
							{/* Update case balance end */}
						</$Form>
					)}

					{/* Select Assets */}
					{enforcementScreen ===
						EnforcementScreens.SELECT_ASSETS && (
						<SelectAssets
							{...props}
							backToNewEnforcement={() =>
								changeScreen(
									EnforcementScreens.NEW_ENFORCEMENT
								)
							}
							selectedAssets={_.filter(
								getAssetsOption(
									assets?.data,
									selectedEnforcementItem.data,
									values?.enforcedItems
								),
								(asset: any) =>
									asset.assetId !=
										-1 &&
									asset.assetId !=
										''
							)}
							onSelectAsset={(
								record: any
							) => {
								rest.setFieldValue(
									'enforcedItems',
									onSelectAsset(
										record,
										selectedEnforcementItem.index,
										values.enforcedItems
									)
								);
								changeScreen(
									EnforcementScreens.NEW_ENFORCEMENT
								);
							}}
						/>
					)}

					{/* Salary Deduction Plan */}
					{enforcementScreen ===
						EnforcementScreens.SALARY_DEDUCTION && (
						<SalaryDeductionPlan
							backToNewEnforcement={() =>
								changeScreen(
									EnforcementScreens.NEW_ENFORCEMENT
								)
							}
							selectedSalaryAsset={_.find(
								values?.enforcedItems,
								{
									assetType: AssetTypes.EMPLOYER,
								}
							)}
							updateEmployerAsset={(
								employerAsset: any
							) =>
								updateEmployerAsset(
									employerAsset,
									values,
									rest
								)
							}
						/>
					)}

					{enforcementScreen ===
						EnforcementScreens.NEW_ENFORCEMENT && (
						<div className='drawer-footer-fixed align-content-center justify-content-end'>
							<div>
								<$Button
									name='save'
									data-testid='btn-save'
									className='ml-3 mr-2'
									type='primary'
									disabled={
										!rest.isValid ||
										isSubmitting ||
										(_.find(
											values?.enforcedItems,
											{
												assetType: AssetTypes.EMPLOYER,
											}
										) &&
											!salaryDeductionPlan.isSet)
									}
									loading={
										isSubmitting ||
										isValidating
									}
									onClick={(
										e: any
									) =>
										handleSubmit(
											e
										)
									}>
									{t(
										'US.COLLECTION.COMMON:COMMON.SAVE'
									)}
								</$Button>
								<$Button
									onClick={() =>
										onClose()
									}>
									{t(
										'US.COLLECTION.COMMON:COMMON.CANCEL'
									)}
								</$Button>
							</div>
						</div>
					)}

					{/* Update case balance */}
					{enforcementScreen ===
						EnforcementScreens.UPDATE_CASE_BALANCE && (
						<CaseBalanceView
							backToNewEnforcement={() =>
								changeScreen(
									EnforcementScreens.NEW_ENFORCEMENT
								)
							}
						/>
					)}

					<$Drawer //Add Asserts
						title={
							assets?.drawerInfo
								?.title
						}
						width={720}
						visible={
							assets?.drawerInfo
								?.visible
						}
						onClose={() =>
							onAssetDrawerClose()
						}
						destroyOnClose>
						<AddAssets
							enforcementEmployeeBreadcrumb={
								false
							}
							enforcementPostalBreadcrumb={
								false
							}
							addAssetType={
								AssetTypes.REAL_ESTATE
							}
							assetType={
								editedAssetType
							}
							showOnly={
								assets
									?.drawerInfo
									?.showOnly
							}
						/>
					</$Drawer>
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { enforcement, assets, dashboard, common, domainView } = state;
	const { currentDateFormat, currentLanguage, currentCurrency } = common;
	const {
		enforcements,
		enforcementDetail,
		enforcementTypes,
		selectedEnforcementItem,
		salaryDeductionPlan,
		drawer,
		accountSummary,
	} = enforcement;
	const { accountSummeryWidgetData } = dashboard;
	const { metaData } = domainView;

	return {
		currentDateFormat,
		currentLanguage,
		currentCurrency,
		enforcementList: enforcements.data,
		enforcementDetail: enforcementDetail.data,
		enforcementDetailLoading: enforcementDetail.isFetching,
		enforcementTypes,
		selectedEnforcementItem,
		salaryDeductionPlan,
		assets,
		isLegalCase: accountSummeryWidgetData?.data?.case?.isLegal,
		drawer,
		metaData,
		accountSummary: accountSummary.data,
	};
};

const mapDispatchToProps = {
	getEnforcementTypes: types.get,
	saveEnforcement: enforcement.save,
	setEnforcementDetails: enforcement.set,
	setSelectedEnforcementItem: item.set,
	getSalaryDeductionPlan: salaryDeductionPlan.get,
	deleteSalaryDeductionPlan: salaryDeductionPlan.delete,
	resetSalaryDeductionPlan: salaryDeductionPlan.reset,
	resetDeleteSalaryDeductionPlan: salaryDeductionPlan.resetDeleted,
	changeDrawerInfo: AddRealEstateAssets.openDrawer,
	resetRealEstateAssets: RealEstateAssets.reset,
	resetOtherPartyInfo: otherParty.reset,
	resetAssetlist: Assets.reset,
	getAccountSummary: accountSummary.get,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEnforcement);
