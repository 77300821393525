import { CloseCase } from "us.collection/constants";
import { ICloseCaseInitialState } from "./Interfaces";
import { initialState } from "./State";

export default (state: ICloseCaseInitialState = initialState, action: any) => {
  switch (action.type) {
    case CloseCase.GET_CLOSE_CASE_START:
      return Object.assign({}, state, {
        reasons: { ...state.reasons, isLoading: true, isReasonAPIReset: false },
      });
    case CloseCase.GET_CLOSE_CASE_SUCCESS:
      return Object.assign({}, state, {
        reasons: {
          data: action.payload.data,
          isLoading: false,
          isReasonAPIReset: false,
        },
      });
    case CloseCase.GET_CLOSE_CASE_FAIL:
      return Object.assign({}, state, {
        reasons: { ...state.reasons, isLoading: false, isReasonAPIReset: true },
      });
    case CloseCase.CLOSE_CASE_POPOVER:
      return Object.assign({}, state, {
        popover: action.payload.data,
      });
    case CloseCase.CLOSE_CASE_START:
      return Object.assign({}, state, {
        isClosing: true
      });
    case CloseCase.CLOSE_CASE_SUCCESS:
      return Object.assign({}, state, {
        popover: { visible: false },
        isClosing: false
      });
    case CloseCase.CLOSE_CASE_FAIL:
      return Object.assign({}, state, {
        popover: { visible: true },
        isClosing: false
      });
    default:
      return state;
  }
};
