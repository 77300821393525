export default {
    endPoints: {
        generalInfo: '/CreditorGeneralInfo',
        otherInfo: '/CreditorGeneralInfo',
        addressInfo: '/CreditorAddressInfo',
        contactInfo: '/CreditorContactInfo'
    },
    viewNames: {
        CreditorInfo: 'CreditorInfo',
        OtherInfo: 'OtherInfo',
        Address: 'Address',
        CntInfo: 'CntInfo'
    },
    levelNames: {
        level1: 'level1',
        level2: 'level2',
        level3: 'level3'
    }
}

export * from './Actions'
export * from './Creditor'
export * from './CreditorCases'