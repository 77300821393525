import moment from "moment";
import { DATE_FORMAT_TO_COMPARE, RegisteredUserType } from "us.collection/constants";
import { INoteHistory } from "us.collection/reducers/ARNotes/Interfaces";

/**
 * Get filter notes
 * @param notes All notes
 * @param values Form values
 * @returns Table data array
 */
export const filterNote = (notes: Array<INoteHistory>, values: any) => {
  try {
    return notes?.filter((item: INoteHistory) =>
      values.category === RegisteredUserType.ALL
        ? true
        : values.category === RegisteredUserType.SYSTEM
        ? item.registeredUserType === RegisteredUserType.SYSTEM
        : item.registeredUserType != RegisteredUserType.SYSTEM
    );
  } catch (error) {
    return [];
  }
};

/**
 * Check edit access for user note
 * @param record Note
 * @azureName user name
 * @returns can edit or not
 */
export const isEditUserNote = (record: INoteHistory, azureName: string) => {
  try {
    return (record.registeredUserType != RegisteredUserType.SYSTEM &&
      moment(record.createdDate).format(DATE_FORMAT_TO_COMPARE) ==
        moment().format(DATE_FORMAT_TO_COMPARE) && azureName == record.createdBy)
  } catch (error) {
    return false;
  }
};
