import { ICourtSentenceInitialState, ISentenceModel } from "us.collection.case/interfaces";

/**
 * clone an object of T type with defined property name
 * @param state current redux state
 * @param propName property name of ICourtSentenceInitialState
 * @param data new data to be assigned
 * @param isLoading if the API call is still running
 * @param isError if there is any error
 * @returns new cloned object with the passed propName param
 */
export const getSentenceObjectClone = <T>(
    state: ICourtSentenceInitialState,
    propName: keyof ICourtSentenceInitialState,
    data: T,
    isLoading?: boolean,
    isError?: boolean,
  ) =>
    Object.assign({}, state, {
      [propName]: <ISentenceModel<T>>{
        isLoading,
        data,
        isError,
      },
    });