import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { $Skeleton, $TableTree, $Button, $Empty } from "us.common/components";
import { IOnFilter, IOnSort } from "us.common/components/antd/TableTree";
import { IRootState } from "us.collection/interfaces";
import { followUps } from "us.collection.followup/actions";
import { IFollowUpHistory } from "./Interfaces";
import { FollowUpUniqueCol } from "us.collection.followup/constants";

const { historyForItem } = followUps;

const FollowUpHistory: React.FC<PropsFromRedux & IFollowUpHistory> = (
  props
) => {
  const { t } = useTranslation();

  const { getFollowUpHistory, item, followUpItem, onClose } = props;
  const { followupId } = followUpItem;
  const { data, dataSource, columns, isFetching } = item;
  const { followupData } = data;

  useEffect(() => {
    followupId &&
      getFollowUpHistory &&
      getFollowUpHistory({ followUpId: followupId });
  }, [followupId]);


  const handleSort: IOnSort = (sortData, dataSource) => {
    return sortData(dataSource);
  };

  const handleFilter: IOnFilter = (searchData, dataSource) => {
    return searchData(dataSource);
  };

  return (
    <div>
      {followupData.length === 0 && !isFetching && (
        <div className="fl-container">
          <div className="flc-body">
            <div className="followup-dashboard-msg">
              <$Empty
                className="pt-5"
                description={`${t(
                  "US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.NO_DATA"
                )}`}
              />
            </div>
          </div>
        </div>
      )}
      {(followupData.length > 0 || isFetching) && (
        <$Skeleton loading={isFetching} active paragraph={{ rows: 2 }}>
          <$TableTree
            rowKey={FollowUpUniqueCol.FOLLOWUP_ID}
            data={dataSource}
            size="small"
            className=""
            onSort={handleSort}
            onFilter={handleFilter}
            filterOnType={true}
            resetOnSourceChange={true}
            bordered
            pagination={false}
            scroll={{ x: 600, y: "calc(100vh - 210px)" }}
            columns={columns}
            data-testid={"followUpHistory-table"}
          />
        </$Skeleton>
      )}
      <div className="drawer-footer-fixed align-content-center justify-content-end">
        <div>
          <$Button onClick={() => onClose()}>
            {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
          </$Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { followUps } = state;
  const { history } = followUps;
  const { item } = history;
  return { item };
};

const mapDispatchToProps = {
  getFollowUpHistory: historyForItem.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FollowUpHistory);
