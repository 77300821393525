import moment from 'moment';
import { validateNumber } from 'us.common/functions';
import { dateFormats } from 'us.collection/components/ManageAgreements/Constants';
import Common from 'us.common';
import { IMetaData } from 'us.collection/interfaces';

const getNumber = (value: any, currentLanguage: string): number => {
	const returnVal = validateNumber(value, currentLanguage);
	return returnVal && returnVal > 0 ? returnVal : 0;
};

export function Interest(
	this: any,
	date: string | moment.Moment,
	metaData: IMetaData,
	currentLanguage: string
) {
	const {
		mainAmount,
		collectionFee,
		costFee,
		courtFee,
		otherFee,
		intersetCostsBalance,
		interestMainBalance,
		collectionBalance,
		costBalance,
		courtBalance,
		otherBalance,
		mainBalance,
		minCaseBalance,
		payDate,
	} = this;

	const convertToNumber = {
		mainAmount: getNumber(mainAmount, currentLanguage),
		collectionFee: getNumber(collectionFee, currentLanguage),
		costFee: getNumber(costFee, currentLanguage),
		courtFee: getNumber(courtFee, currentLanguage),
		otherFee: getNumber(otherFee, currentLanguage),
		interestCostBalance: getNumber(
			intersetCostsBalance,
			currentLanguage
		),
		interestMainBalance: getNumber(
			interestMainBalance,
			currentLanguage
		),
		collectionBalance: getNumber(
			collectionBalance,
			currentLanguage
		),
		costBalance: getNumber(costBalance, currentLanguage),
		courtBalance: getNumber(courtBalance, currentLanguage),
		otherBalance: getNumber(otherBalance, currentLanguage),
		mainBalance: getNumber(mainBalance, currentLanguage),
		minCaseBalance: getNumber(minCaseBalance, currentLanguage),
	};
	return {
		caseNo: metaData?.caseId,
		date: moment(date).format(dateFormats.req),
		values: {
			...this,
			...convertToNumber,
			currentLanguage,
			interestDate: date,
			payDate: moment(
				payDate,
				Common.Constants.dateFormats.enGB
			),
		},
	};
}
