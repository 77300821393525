import { matchPath } from 'react-router-dom';
import { IMetaData } from 'us.collection/interfaces';
import { Type, URLType } from 'us.collection.routines-and-activities/constants';

export const getCaseNumberAndType = (
	location: any,
	metaData: IMetaData
): {
	type: string;
	eType: string;
	caseNo: string;
	pid: string | number;
} => {
	const { params }: any = matchPath(location.pathname, {
		path: '/:caseType/:caseId/:module?',
		exact: false,
		strict: false,
	});
	const { creditorId } = location.state ?? {};
	const { entityType, caseId, pid: PID } = metaData;
	const caseType = params['caseType'];
	let caseNo = params['caseId'];
	let type: string = '';
	let pid: string | number = PID;
	let eType: string = entityType;

	switch (caseType) {
		case URLType.CASE:
			type = entityType;
			eType = entityType;
			caseNo = caseId;
			pid = caseId;
			break;
		case URLType.AR:
			type = Type.AR;
			eType = Type.AR;
			pid = caseNo;
			break;
		case URLType.CREDITOR:
			type = Type.CR;
			eType = Type.CREDITOR;
			caseNo = creditorId;
			break;
	}
	return {
		type,
		eType,
		caseNo,
		pid
	};
};
