import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Statistic } from 'antd';
import './BasicInfo.scss';
import { connect } from 'react-redux';
import { IntlNumber, IntlDate } from 'us.common/functions';
import { useTranslation } from 'react-i18next';

interface ICreditorBasicInfoProps{
    gridStyle:any,
    statisticStylle:any,
    CreditorGeneralInfo:any
    
    currentLanguage: string
}

const CreditorBasicInfo:React.FC<ICreditorBasicInfoProps> = props => {

    const { t } = useTranslation();
    const { CreditorGeneralInfo,currentLanguage } = props

    const [genInfo, setGenInfo] = useState(CreditorGeneralInfo);

    useEffect(() => {
        setGenInfo(CreditorGeneralInfo)
    }, [CreditorGeneralInfo])

    return (
        <>
            {
                props ?
                    (
                        <Row>
                            <Col className="gutter-row" span={6}>
                                <Card style={{ backgroundColor: '#9c9898' }}>
                                    <Card.Grid style={props.gridStyle}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.NAME')}</p><p className='ant-statistic-content-value'> {genInfo.CreditorName}</p></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.CREDITORNO')}</p><p className='ant-statistic-content-value'> {IntlNumber(genInfo.CreditorInkasoId,currentLanguage)}</p></Card.Grid>
                                    <Card.Grid style={{ ...props.gridStyle, width: '35%' }}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.GROUPNO')}</p><p className='ant-statistic-content-value'> {IntlNumber(genInfo.GroupId,currentLanguage)}</p></Card.Grid>                                    
                                    <Card.Grid style={{ ...props.gridStyle, width: '50%' }}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.GROUPNAME')}</p><p className='ant-statistic-content-value'> {genInfo.CreditorGroupName}</p></Card.Grid>                                   
                                    <Card.Grid style={props.gridStyle}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.BELONGSTO(CREDITORNO)')}</p><p className='ant-statistic-content-value'> {IntlNumber(genInfo.BelongsTo,currentLanguage)} </p></Card.Grid>                                    
                                    <Card.Grid style={props.gridStyle}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.ADDRESS1')}</p><p className='ant-statistic-content-value'> {genInfo.Add1}</p></Card.Grid>                                    
                                    <Card.Grid style={props.gridStyle}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.ADDRESS2')}</p><p className='ant-statistic-content-value'> {genInfo.Add2}</p></Card.Grid>                                    
                                    <Card.Grid style={props.gridStyle}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.ADDRESS3')}</p><p className='ant-statistic-content-value'> {genInfo.Add3}</p></Card.Grid>                                    
                                    <Card.Grid style={{ ...props.gridStyle, width: '35%' }}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.POSTAL')}</p><p className='ant-statistic-content-value'> {IntlNumber(genInfo.ZipCode,currentLanguage)}</p></Card.Grid>                                    
                                    <Card.Grid style={{ ...props.gridStyle, width: '50%' }}><p className='ant-statistic-title'>-</p><p className='ant-statistic-content-value'> {genInfo.ZipName}</p></Card.Grid>                                    
                                    <Card.Grid style={props.gridStyle}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.MUNICIPALITY')}</p><p className='ant-statistic-content-value'> {IntlNumber(genInfo.CountyID,currentLanguage)}</p></Card.Grid>                                    
                                    <Card.Grid style={{ ...props.gridStyle, width: '50%' }}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.MUNICIPALITY')}</p><p className='ant-statistic-content-value'> {genInfo.CountyName}</p></Card.Grid>                                    
                                    <Card.Grid style={props.gridStyle}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.COUNTRY')}</p><p className='ant-statistic-content-value'> {genInfo.Country}</p></Card.Grid>
                                </Card>
                            </Col>

                            <Col className="gutter-row" span={6}>
                                <Card style={{ backgroundColor: '#9c9898' }}>
                                    <Card.Grid style={props.gridStyle}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.DIRECTNO')}</p><p className='ant-statistic-content-value'> {IntlNumber(genInfo.TeleHome,currentLanguage)}</p></Card.Grid>                                    
                                    <Card.Grid style={props.gridStyle}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.MOBILE')}</p><p className='ant-statistic-content-value'> {IntlNumber(genInfo.MobileNo,currentLanguage)}</p></Card.Grid>                                    
                                    <Card.Grid style={props.gridStyle}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.OFFICE')}</p><p className='ant-statistic-content-value'> {IntlNumber(genInfo.TeleWork,currentLanguage)}</p></Card.Grid>                                    
                                    <Card.Grid style={props.gridStyle}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.FAX')}</p><p className='ant-statistic-content-value'> {IntlNumber(genInfo.Fax,currentLanguage)}</p></Card.Grid>                                    
                                    <Card.Grid style={props.gridStyle}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.EMAIL')}</p><p className='ant-statistic-content-value'> {genInfo.EmailID}</p></Card.Grid>                                    
                                    <Card.Grid style={props.gridStyle}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.SMS')}</p><p className='ant-statistic-content-value'> {IntlNumber(genInfo.SMS,currentLanguage)}</p></Card.Grid>                                    
                                    <Card.Grid style={props.gridStyle}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.SKYPE')}</p><p className='ant-statistic-content-value'> {genInfo.SkypeID}</p></Card.Grid>                                    
                                    <Card.Grid style={props.gridStyle}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.MSN')}</p><p className='ant-statistic-content-value'> {genInfo.MsnID}</p></Card.Grid>                                    
                                    <Card.Grid style={props.gridStyle}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.NOTIFYBAN')}</p><p className='ant-statistic-content-value'> {genInfo.NotifyBank}</p></Card.Grid>                                    
                                    <Card.Grid style={props.gridStyle}><p className='ant-statistic-title'>{t('US.COLLECTION.CREDITOR:BASICINFO.INNSYNURL')}</p><p className='ant-statistic-content-value'> {genInfo.InnSynURL}</p></Card.Grid>
                                </Card>
                            </Col>

                            <Col className="gutter-row" span={6}>

                                <Card style={{ backgroundColor: '#9c9898' }}>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:BASICINFO.COMPANYID')} value={IntlNumber(genInfo.CompanyID,currentLanguage)} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:BASICINFO.STATUS')} value={genInfo.CreditorStatus} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:BASICINFO.ACCOUNT')} value={IntlNumber(genInfo.AccountNo,currentLanguage)} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:BASICINFO.HELPACCOUNTNO')} value={IntlNumber(genInfo.HelpAccountNo,currentLanguage)} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:BASICINFO.REMITACCOUNTNO')} value={IntlNumber(genInfo.RemitAccountNo,currentLanguage)} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:BASICINFO.PRODUCTNO')} value={IntlNumber(genInfo.ProductNo,currentLanguage)} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:BASICINFO.NETSID')} value={genInfo.NetsId} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:BASICINFO.REGDATE')} value={IntlDate(genInfo.RegDate,currentLanguage)} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:BASICINFO.CREATEDUSER')} value={genInfo.CreatedUser} /></Card.Grid>
                                    <Card.Grid style={props.gridStyle}><Statistic valueStyle={props.statisticStylle} title={t('US.COLLECTION.CREDITOR:BASICINFO.MODIFIEDUSER')} value={genInfo.ModifiedUser} /></Card.Grid>
                                </Card>
                            </Col>
                        </Row>
                    )
                    :
                    <p>page loading......</p>
            }
        </>
    )

}

const mapStateToProps = (state: any) => {
    return {
        currentLanguage: state.common.currentLanguage
    };
};


export default connect(mapStateToProps)(CreditorBasicInfo)
