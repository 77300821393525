import { call, put, takeLatest } from "redux-saga/effects";
import { payments as API } from "us.collection.transactions/services";
import { PaymentAction } from "us.collection.transactions/actions";
import Constants from "us.collection.transactions/constants";

const { ACTIONS } = Constants;
const { Payment } = ACTIONS;

const paymentSagas = {
  get: function* (action: any) {
    const params = action.payload.data;
    try {
      const data = yield call(API.get, params.paymentId);
      yield put(PaymentAction.Payments.success(data));
    } catch (error) {
      yield put(PaymentAction.Payments.fail(error));
    }
  },
};

export default [takeLatest(Payment.GET_PAYMENTS, paymentSagas.get)];
