import { IFollowUpItem } from "us.collection.followup/interfaces";

/**
 * @function
 * @description search followup in followupList
 * @param {Array<IFollowUpItem>} followups followup list
 * @param {string} searchText entered value
 * @returns {Object} filtered followups
 */
export const searchFollowup = (
  followups: Array<IFollowUpItem>,
  searchText: string
): {
  pinnedFollowup: Array<IFollowUpItem>;
  unpinnedFollowup: Array<IFollowUpItem>;
} => {
  try {
    const searchedFollowup = followups.filter((followup: IFollowUpItem) =>
      followup.followupDisplayName
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
    return {
      pinnedFollowup: searchedFollowup?.filter(
        ({ pinStatus }: any) => pinStatus
      ),
      unpinnedFollowup: searchedFollowup?.filter(
        ({ pinStatus }: any) => !pinStatus
      ),
    };
  } catch (error) {
    return { pinnedFollowup: [], unpinnedFollowup: [] };
  }
};
