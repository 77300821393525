import { IModel } from "us.collection.transactions/interfaces";

/**
 * clone an object of T type with defined property name
 * @param state current redux state of IBMDInitialState
 * @param propName property name of IBMDInitialState
 * @param data new data to be assigned
 * @param isLoading if the API call is still running
 * @param isError if there is any error
 * @returns new cloned object with the passed propName param
 */
 export const getReduxObject = <T,D>(
    state: T,
    propName: keyof T,
    data: D,
    isLoading?: boolean,
    isError?: boolean
  ) =>
    Object.assign({}, state, {
      [propName]: <IModel<D>>{
        isLoading,
        data,
        isError,
      },
    });