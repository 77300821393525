import { InterestType } from 'us.collection.transactions/constants';

export const INTEREST_TYPE_OPTIONS = [
	{
		value: InterestType.STANDARD_INTEREST,
		label: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.STANDARD_INTEREST',
	},
	{
		value: InterestType.FIXED_INTEREST,
		label: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.FIXED_INTEREST',
	},
	{
		value: InterestType.NO_INTEREST,
		label: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.NO_INTEREST',
	}
];
