import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PendingActivityExecution } from 'us.collection/components';
import './Header.scss';
import Common from 'us.common';
import { EntityType, EntityTypeShortForm, Mode } from 'us.common/constants';
import { INavigationData } from 'us.collection/interfaces';

const { ActivityExecution } = Common.Components;
interface IHeader extends RouteComponentProps {}

const Header: React.FC<IHeader> = (props) => {
	const { t } = useTranslation(['US.COLLECTION.ADMIN'], {
		useSuspense: true,
	});
	const history = useHistory();
	const { location, push } = history;
	const state = location?.state as INavigationData;

	/**
	 * @function
	 * @description handle navigation after execute activity
	 */
	const navigateAfterExecution = () => {
		push(`/bureau/history`, state);
	};

	return (
		<div className='dom-bureau-header'>
			<div className='d-flex align-items-center mt-2'>
				<ActivityExecution
					mode={Mode.Initial}
					isHideTable={false}
					isDisabled={false}
					isBulkExecutionEnabled={false}
					isGroupActivityOptions={false}
					isActivityOptionWithCode={true}
					isHideDatePicker={false}
					activityAPIRequest={{
						endPoint: `activities`,
						parameters: {
							entitytype: EntityTypeShortForm.BUREAU,
							entitystate: '',
							canExecute: true,
						},
					}}
					executeActivityEndPoint='activities'
					groupingProperty={{
						groupingLabel: 'activityGroup',
						groupingValue:
							'activityGroupId',
						tableGrouping: 'type',
					}}
					entityType={EntityType.BUREAU}
					isPopConfirmEnabled={true}
					propConfirmDetail={{
						title: 'ARE_YOU_SURE_YOU_WANT_TO_EXECUTE_THIS_ACTIVITY',
					}}
					navigateAfterExecution={
						navigateAfterExecution
					}
					isPopConfirmEnabledDropDown={false}
				/>

				<div className='ml-auto d-flex'>
					<PendingActivityExecution {...props} />
				</div>
			</div>
		</div>
	);
};

export default Header;
