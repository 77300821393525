import moment from "moment";
import { IAccountSummary } from "us.common/components/CaseBalanceUpdate/Interfaces";

export function SaveEnforcement(this: any, accountSummary: IAccountSummary) {
  const {
    mainAmount,
    mainAmountFromDate,
    mainAmountToDate,
    mainAmountRate,
    caseCost,
    caseCostFromDate,
    caseCostToDate,
    caseCostRate,
    collectionFee,
    collectionFeeFromDate,
    collectionFeeToDate,
    collectionFeeRate,
    courtFee,
    courtFeeFromDate,
    courtFeeToDate,
    courtFeeRate,
    otherCost,
    otherCostFromDate,
    otherCostToDate,
    otherCostRate,
    interestOfMainAmount,
    interestOfMainAmountFromDate,
    interestOfMainAmountToDate,
    interestOfMainAmountRate,
    interestOfCosts,
    interestOfCostsFromDate,
    interestOfCostsToDate,
    interestOfCostsRate,
  } = this.caseBalance;
  return {
    enforcementId: this.enforcementId ?? -1,
    caseId: this.caseId,
    referenceId: this.referenceId,
    journalNo: this.journalId,
    registrationDate: moment(this.registrationDate).format("YYYY-MM-DD"),
    executedDate: moment(this.executedDate).format("YYYY-MM-DD"),
    obsoleteDate: moment(this.obsoleteDate).format("YYYY-MM-DD"),
    comment: this.comment,
    mainAmount: this.caseBalanceSwitch ? mainAmount : accountSummary.mainAmount,
    mainAmountFromDate,
    mainAmountToDate,
    mainAmountRate,
    caseCost: this.caseBalanceSwitch ? caseCost : accountSummary.caseCost,
    caseCostFromDate,
    caseCostToDate,
    caseCostRate,
    collectionFee: this.caseBalanceSwitch
      ? collectionFee
      : accountSummary.collectionFee,
    collectionFeeFromDate,
    collectionFeeToDate,
    collectionFeeRate,
    courtFee: this.caseBalanceSwitch ? courtFee : accountSummary.courtFee,
    courtFeeFromDate,
    courtFeeToDate,
    courtFeeRate,
    otherCost: this.caseBalanceSwitch ? otherCost : accountSummary.otherCost,
    otherCostFromDate,
    otherCostToDate,
    otherCostRate,
    interestOfMainAmount: this.caseBalanceSwitch
      ? interestOfMainAmount
      : accountSummary.mainAmountInterest,
    interestOfMainAmountFromDate,
    interestOfMainAmountToDate,
    interestOfMainAmountRate,
    interestOfCosts: this.caseBalanceSwitch
      ? interestOfCosts
      : accountSummary.interestOfCost,
    interestOfCostsFromDate,
    interestOfCostsToDate,
    interestOfCostsRate,
    isUpdateCaseBalance: this.caseBalanceSwitch,
    assets: this.enforcedItems?.map((enforcedItem: any) => {
      return {
        enforcementItemId: enforcedItem?.enforcementItemId ?? -1,
        assetId: enforcedItem?.assetId,
        faceValue: enforcedItem?.faceValue,
        enforcementTypeId: enforcedItem?.enforcementTypeId,
        assetTypeId: enforcedItem?.assetTypeId,
        isDeleted: false,
      };
    }),
  };
}
