import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import Common from "us.common";
import { useTranslation } from "react-i18next";
import { ICustId } from "../../Interfaces";
import { $Input } from "us.common/components/antd";

const { $Form, $Button } = Common.Components;

const CustId: React.FC<ICustId> = (props) => {
  const { t } = useTranslation();

  const {
    selectedDebtorDetails,
    addCustId,
    onCloseDrawer,
  } = props;

  const handleSubmit = (data: any) => {
    addCustId(data?.customerId);
  };

  return (
    <Formik
      initialValues={{
        customerId: "",
      }}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        setFieldValue,
        setValues,
        ...restProps
      }: any) => (
        <div className="add-custid">
          <$Form>
            <div className="bui-label">
              {t("US.COLLECTION.DEBTOR:SELECT_DEBTOR.DEBTOR")}
            </div>
            <h3>{selectedDebtorDetails.debtorName}</h3>
            <div className="mt-4">
              <strong className="d-block text-error">
                {t("US.COLLECTION.DEBTOR:SELECT_DEBTOR.SELECTED_DEBTOR_DOES_NOT_HAVE_A_CUSTOMER_ID")}
              </strong>
              <span>{t("US.COLLECTION.DEBTOR:SELECT_DEBTOR.PLEASE_ENTER_A_CUSTOMER_ID_AND_CONTINUE")}...</span>
            </div>

            <div className="mt-4" style={{ width: 200 }}>
              <$Input
                label={t("US.COLLECTION.DEBTOR:SELECT_DEBTOR.CUSTOMER_ID")}
                name="customerId"
                size="small"
                required
                className="w-100"
              />
            </div>
          </$Form>

          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Button
                data-testid="save-button"
                className="ml-3 mr-2"
                type="primary"
                onClick={handleSubmit}
              >
                {t("US.COLLECTION.COMMON:COMMON.SAVE")}
              </$Button>

              <$Button
                data-testid="cancel-button"
                onClick={() => onCloseDrawer()}
              >
                {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
              </$Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustId);
