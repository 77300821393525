import moment from "moment";
import { IManualFollowUp, IReason } from "us.collection.case/interfaces";
import { appInsights } from "us.helper";
import { DateFormat, EntityFollowUpPanel } from "us.collection.case/constants";
import { IInitialFilter } from "us.collection/components/FollowUps/Components/CurrentFollowUps/Components/Interfaces";

/**
 * get followups data set
 * @param data add followup form data
 * @returns {Array<{name: string, value: string | number}>} followup data set
 */
export const getFollowUpDataSet = (
  data: any
): Array<{
  name: string;
  value: string | number;
}> => {
  try {
    return Object.keys(data).map((key: any, index: number) => {
      return {
        name: key,
        value: Object.values(data)[index] as string,
      };
    });
  } catch (error) {
    appInsights.trackException(
      `Error in ManualFollowup setFollowUpData - ${error}`
    );
    return [];
  }
};

/**
 * separate due and upcoming data
 * @param {Array<IManualFollowUp>} data all manualfollowups
 * @returns {{due: Array<IManualFollowUp>;upcoming: Array<IManualFollowUp>}} separated manualFollowUps
 */
export const getDueAndUpcomingManualFollowup = (
  data: Array<IManualFollowUp>
): {
  due: Array<IManualFollowUp>;
  upcoming: Array<IManualFollowUp>;
} => {
  try {
    return {
      due: data.filter((manualFollowup: IManualFollowUp) =>
        moment(manualFollowup.followupDate).isSameOrBefore(
          moment().format(DateFormat),
          "day"
        )
      ),
      upcoming: data.filter((manualFollowup: IManualFollowUp) =>
        moment(moment.utc(manualFollowup.followupDate).format()).isAfter(
          moment().format(DateFormat),
          "day"
        )
      ),
    };
  } catch (error) {
    // appInsights.trackException(
    //   `Error in ManualFollowup getDueAndUpcomingManualFollowup - ${error}`
    // );
    return {
      due: [],
      upcoming: [],
    };
  }
};

/**
 * append newly added reason to exisiting reasons
 * @param {Array<IReason>} reasons exisiting reasons
 * @param {IReason} newReason newly added reason
 * @returns {Array<IReason>} reason list array, array length should be five or less than five
 */
export const appendNewReason = (
  reasons: Array<IReason>,
  newReason: IReason
): Array<IReason> => {
  try {
    if (reasons.length >= 5) {
      reasons.unshift(newReason);
      reasons.splice(5, reasons.length - 1);
      return reasons;
    } else {
      reasons.unshift(newReason);
      return reasons;
    }
  } catch (error) {
    appInsights.trackException(
      `Error in ManualFollowup appendNewReason - ${error}`
    );
    return [];
  }
};

export const filterManualFollowUps = (
  data: Array<IManualFollowUp>,
  filters: IInitialFilter
): Array<IManualFollowUp> => {
  try {
    const {
      name: filterName,
      date: filterDate,
      description: filterDescription,
    } = filters;
    return data.filter((followUp: IManualFollowUp) => {
      const { followupReason, followupDate, note } = followUp;
      return (
        followupReason.toLowerCase().includes(filterName.toLowerCase()) &&
        (moment(filterDate).isValid()
          ? moment(followupDate).isSame(moment(filterDate), "day")
          : true) &&
        note.toLowerCase().includes(filterDescription.toLowerCase())
      );
    });
  } catch (error) {
    return data;
  }
};
