import { versionedHttpCollection } from "us.helper/http/collection";
export default {
    nextResult: {
        get: <P extends { followupId: string; entityId: string }>(params: P): Promise<any> => {
            return new Promise<any>(async (resolve, reject) => {
                try {
                    const { followupId, entityId } = params;
                    const { data, status } = await versionedHttpCollection.get(
                        "followupservice",
                        `followupnextentity/${followupId}/${entityId}`,
                        {},
                        'v1'
                    );
                    if (status == 200) {
                        resolve(data);
                    } else {
                        reject(data);
                    }
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
    followups: {
        get: (params: any): Promise<any> => {
            return new Promise<any>(async (resolve, reject) => {
                try {
                    const { data, status } = await versionedHttpCollection.get(
                        "followupservice",
                        `followupList`,
                        {},
                        'v1'
                    );
                    if (status == 200) {
                        resolve(data);
                    } else {
                        reject(data);
                    }
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
};
