import { IColumn } from "./Interfaces";
import { URLType } from "us.collection/constants/DomainView";

export const BMD_TABLE_COLUMNS: IColumn[] = [
  {
    dataIndex: "more",
    title: "",
    width: "50px",
    visibility:[URLType.CASE,URLType.AR,URLType.CREDITOR,URLType.CREDITOR_GROUP,URLType.BUREAU]
  },
  {
    dataIndex: "keyName",
    title: "BMD_NAME",
    width: "260px",
    visibility:[URLType.CASE,URLType.AR,URLType.CREDITOR,URLType.CREDITOR_GROUP,URLType.BUREAU],
    ellipsis:{showTitle: false }
  },
  {
    dataIndex: "value",
    title: "VALUE",
    width: "150px",
    visibility:[URLType.CASE,URLType.AR,URLType.CREDITOR,URLType.CREDITOR_GROUP,URLType.BUREAU],
    ellipsis:{showTitle: false }
  },
  {
    dataIndex: "description",
    title: "DESCRIPTION",
    width: "150px",
    visibility:[URLType.CASE,URLType.AR,URLType.CREDITOR,URLType.CREDITOR_GROUP,URLType.BUREAU],
    ellipsis:{showTitle: false }
  },
  {
    dataIndex: "displayName",
    title: "DISPLAY_NAME",
    width: "200px",
    visibility:[URLType.CASE,URLType.AR,URLType.CREDITOR,URLType.CREDITOR_GROUP,URLType.BUREAU],
    ellipsis:{showTitle: false }
  },
  {
    dataIndex: "groupCount",
    title: "GROUP_COUNT",
    width: "140px",
    visibility:[URLType.BUREAU]
  },
  {
    dataIndex: "creditorCount",
    title: "CREDITOR_COUNT",
    width: "150px",
    visibility:[URLType.CREDITOR_GROUP,URLType.BUREAU]
  },
  {
    dataIndex: "arCount",
    title: "AR_COUNT",
    width: "130px",
    visibility:[URLType.CREDITOR,URLType.CREDITOR_GROUP,URLType.BUREAU]
  },
  {
    dataIndex: "caseCount",
    title: "CASE_COUNT",
    width: "140px",
    visibility:[URLType.AR,URLType.CREDITOR,URLType.CREDITOR_GROUP,URLType.BUREAU]
  },
];
