import { IInitialState } from 'us.collection.case/reducers/Interest/Interfaces'

export const initialState: IInitialState = {
    interests: {
        data: {
            totalInterest: 0.00,
            runningInterestMain: 0.00,
            runningInterestCosts: 0.00,
            transactionInterestMain: 0.00,
            transactionInterestCost: 0.00,
            caseAmount: 0.00,
            interestDetails: [{
                caseId: 0,
                startDate: '',
                endDate: '',
                baseAmount: 0,
                noOfDays: 0,
                rate: 0,
                amount: 0.00,
                transType: '',
                createdBy: ''
            }]
        },
        isFetching: false
    },
    
    filter: 'all'
}