import { IOnSort } from "us.common/components";
import { appInsights } from "us.helper";

/**
 * @function
 * @description - To get the table values sorted
 * @param {*} sortData - call back function
 * @param {*} dataSource - the data set of the table
 * @return {*} - sorted data
 */
 export const handleSort: IOnSort = (sortData, dataSource) => {
  try {
    return sortData(
      dataSource.map((i: any) => {
        return {
          ...i,
          children: sortData(i.children),
        };
      })
    );
  } catch (error) {
    appInsights.trackException(`Cases and Subcases Home sort error - ${error}`);
    return dataSource;
  }
};
