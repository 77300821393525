import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import {
	$Button,
	$Popconfirm,
	$Popover,
	$Drawer,
	$Breadcrumb,
	$Affix,
	$PageHeader,
} from 'us.common/components';
import { MoreOutlined, PushpinOutlined, HourglassOutlined } from 'us.icons';
import { followUps } from 'us.collection.followup/actions';
import { IRootState } from 'us.collection/interfaces';
import { IItem } from './Interfaces';
import { $Tooltip } from 'us.common/components';
import {
	FollowUpHistory,
	FollowUpActionHistory,
} from 'us.collection.followup/components/FollowUps/Components';
import { PinType } from 'us.collection.followup/components/FollowUps/Components/FollowUpList/Constants';

const { item, historyDrawer } = followUps;

const FollowUpItem: React.FC<PropsFromRedux & IItem> = (props) => {
	const { t } = useTranslation();
	const {
		followUpItem,
		type,
		pinStatus,
		isSelected,
		historyDrawer,
		onSelect,
		unPinFollowUpItem,
		manageFollowUpHistoryDrawer,
	} = props;
	const [followupHistoryVisible, setFollowupHistoryVisible] =
		useState<boolean>(false);
	const [popVisible, setPopVisible] = useState<number>(-1);

	const { followupId, followupDisplayName, caseCount } = followUpItem;

	const followupHistoryClose = () => {
		manageFollowUpHistoryDrawer &&
			manageFollowUpHistoryDrawer({
				isActionHistoryVisible: false,
			});
		setFollowupHistoryVisible(false);
	};

	return (
		<>
			<Link
				to={`/follow-up/${followupId}`}
				onClick={onSelect}>
				<div
					className={`fl-cat-item ${
						isSelected ? 'selected' : ''
					}`}>
					<div
						className='fl-cat-content'
						key={`${followupId}`}>
						<div className='fl-cat-title'>
							<$Tooltip
								placement={
									'topLeft'
								}
								title={
									followupDisplayName
								}>
								{
									followupDisplayName
								}
							</$Tooltip>
						</div>
						<div className='fl-cat-count'>
							<span>
								<$Tooltip
									placement={
										'topLeft'
									}
									title={
										caseCount
									}>
									{
										caseCount
									}
								</$Tooltip>
							</span>
						</div>
					</div>
					<div className='fl-cat-action'>
						<$Popover
							placement='left'
							trigger='click'
							visible={
								followupId ==
								popVisible
							}
							onVisibleChange={(
								visible: boolean
							) => {
								setPopVisible(
									visible
										? followupId
										: -1
								);
							}}
							content={
								<div className='table-more-content'>
									<$Popconfirm
										title={
											type ==
											PinType.PINNED
												? t(
														'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.UNPIN_CONFIRM'
												  )
												: t(
														'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.PIN_CONFIRM'
												  )
										}
										onConfirm={() => {
											unPinFollowUpItem &&
												unPinFollowUpItem(
													{
														caselistQueryId:
															followupId,
														pinStatus:
															type ==
															PinType.PINNED
																? false
																: true,
													}
												);
											setPopVisible(
												-1
											);
										}}
										onCancel={() =>
											setPopVisible(
												-1
											)
										}>
										<div className='d-flex mb-2 flex-row more-item'>
											<div className='p-1'>
												<PushpinOutlined />
											</div>
											<div className='p-1'>
												{type ==
												PinType.PINNED
													? t(
															'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.UNPIN'
													  )
													: t(
															'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.PIN'
													  )}
											</div>
										</div>
									</$Popconfirm>
									<div
										className='d-flex mb-2 flex-row more-item'
										onClick={() => {
											setFollowupHistoryVisible(
												true
											);
											setPopVisible(
												-1
											);
										}}>
										<div className='p-1'>
											<HourglassOutlined />
										</div>
										<div className='p-1'>
											{t(
												'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.HISTORY'
											)}
										</div>
									</div>
								</div>
							}
							destroyTooltipOnHide>
							<$Button
								type='link'
								icon={
									<MoreOutlined />
								}
								size='small'
								loading={pinStatus.data?.includes(
									followupId
								)}
							/>
						</$Popover>
					</div>
				</div>
			</Link>
			<$Drawer
				title={t(
					'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.HISTORY'
				)}
				width={'90%'}
				visible={followupHistoryVisible}
				onClose={followupHistoryClose}
				destroyOnClose>
				{!historyDrawer.isActionHistoryVisible && (
					<FollowUpHistory
						followUpItem={followUpItem}
						onClose={followupHistoryClose}
					/>
				)}
				{historyDrawer.isActionHistoryVisible && (
					<>
						<$Breadcrumb>
							<$Breadcrumb.Item
								onClick={() =>
									manageFollowUpHistoryDrawer &&
									manageFollowUpHistoryDrawer(
										{
											isActionHistoryVisible:
												false,
										}
									)
								}>
								<a href='#'>
									{t(
										'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.HISTORY'
									)}
								</a>
							</$Breadcrumb.Item>
							<$Breadcrumb.Item>
								{t(
									'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.ACTION_HISTORY'
								)}
							</$Breadcrumb.Item>
						</$Breadcrumb>
						<$Affix offsetTop={80}>
							<div className='page-header'>
								<div className='d-flex align-items-center'>
									<$PageHeader
										className='px-0 py-0 mr-n3'
										onBack={() =>
											manageFollowUpHistoryDrawer &&
											manageFollowUpHistoryDrawer(
												{
													isActionHistoryVisible:
														false,
												}
											)
										}
										title={t(
											'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.ACTION_HISTORY'
										)}
									/>
								</div>
							</div>
						</$Affix>
						<div className='mt-4'>
							<FollowUpActionHistory
								onClose={
									followupHistoryClose
								}
							/>
						</div>
					</>
				)}
			</$Drawer>
		</>
	);
};

const mapStateToProps = (state: IRootState) => {
	const { followUps } = state;
	const { historyDrawer, pinStatus } = followUps;
	return { historyDrawer, pinStatus };
};

const mapDispatchToProps = {
	unPinFollowUpItem: item.unPin,
	manageFollowUpHistoryDrawer: historyDrawer.manage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FollowUpItem);
