import { IMessageTemplatePinStatus } from "us.collection.case/interfaces";
/**
 * @description create pinStatusData object for update pin status by using patch request
 * @param {pinUnpinStatus}IMessageTemplatePinStatus
 * @returns return the pinStatusData object
 */

export const createMessageTemplatePatchObject = (
  pinUnpinStatus: IMessageTemplatePinStatus
) => {
  try {
    const { isPin, templateId, entityType } = pinUnpinStatus;

    const pinStatusObject = [
      {
        op: "add",
        path: "/isPin",
        value: !isPin,
      },
    ];

    return { pinStatusObject, templateId, entityType };
  } catch (error) {
    return {};
  }
};
