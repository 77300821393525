import React from "react";
import { Handlers } from "us.collection/constants";
import { IHandler } from "us.collection/reducers/Handler/Interfaces";
import { $Menu, $Skeleton } from "us.common/components";

interface ICourtHandlerList {
  setSelectHandler: any;
  courtHandlerDetails: IHandler;
  isLoading: boolean;
}

export const CourtHandlerList: React.FC<ICourtHandlerList> = (props) => {
  const { setSelectHandler, courtHandlerDetails, isLoading } = props;

  return (
    <>
      {isLoading && (
        <div className="ant-dropdown-menu px-3 pt-3">
          <$Skeleton
            loading={true}
            active
            title={false}
            paragraph={{ rows: 2 }}
          />
        </div>
      )}
      {!isLoading && (
        <div className="ant-dropdown-menu" data-testid={"court-list"}>
          <$Menu>
            {courtHandlerDetails?.list.map(
              ({
                courtHandlerName,
                courtHandlerId,
              }: {
                courtHandlerName: string;
                courtHandlerId: number;
              }) => {
                return (
                  <$Menu.Item
                    key={courtHandlerId}
                    onClick={() =>
                      setSelectHandler(courtHandlerName, Handlers.COURT)
                    }
                    className="ant-dropdown-menu-item my-0"
                  >
                    <span>{courtHandlerName}</span>
                  </$Menu.Item>
                );
              }
            )}
          </$Menu>
        </div>
      )}
    </>
  );
};
