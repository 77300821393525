import React, { useEffect, useState } from "react";
import { $Skeleton, $Radio, $TableTree } from "us.common/components";
import { useTranslation } from "react-i18next";
import { IOnFilter, IOnSort } from "us.common/components/antd/TableTree";
import { appInsights } from "us.helper";
import { IActivityTable, IActivityTableData, IActivity } from "./Interfaces";
import { FilterCategoryId } from "us.collection/constants";
import { formatActivityTableData } from "./Functions";

const ActivityTable: React.FC<IActivityTable> = (props) => {
  const { t } = useTranslation();

  const {
    onClose,
    categories,
    data,
    currentCurrency,
    handleSelect,
    setFieldValue,
  } = props;

  const [filterId, setFilterId] = useState<number>(1);

  /**
   * get filter data for table
   * @returns {Array<IActivityTableData>} filter data
   */
  const getFilterData = (
    filterId: number | string
  ): Array<IActivityTableData> => {
    try {
      switch (filterId) {
        case FilterCategoryId.All:
          return formatActivityTableData(data);
        case FilterCategoryId.CategoryOne:
          return formatActivityTableData(
            data.filter((activity: IActivity) => activity.isHandlingActivity)
          );
        case FilterCategoryId.CategoryTwo:
          return formatActivityTableData(
            data.filter((activity: IActivity) => !activity.isHandlingActivity)
          );
        default:
          return formatActivityTableData(data);
      }
    } catch (error) {
      appInsights.trackException(
        `Activity Table External Filter Exeception -${error}`
      );
      return formatActivityTableData(data);
    }
  };

  const columns: any = [
    {
      title: "",
      dataIndex: "group",
      key: "group",
      className: "group-td",
      width: 24,
      customRenderParent: (text: any, record: any, index: number) => {
        return {
          children: text,
          key: index,
          props: {
            colSpan: 4,
          },
        };
      },
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <></>,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: t("US.COLLECTION.COMMON:ACTIVITYEXECUTION.CODE"),
      dataIndex: "activityCode",
      key: "activityCode",
      width: 120,
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <span>{record?.activityCode}</span>,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: t("US.COLLECTION.COMMON:ACTIVITYEXECUTION.NAME"),
      dataIndex: "displayName",
      key: "displayName",
      className: "text-nowrap",
      ellipsis: true,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <span>{record?.displayName}</span>,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: t("US.COLLECTION.COMMON:ACTIVITYEXECUTION.DESCRIPTION"),
      dataIndex: "description",
      key: "description",
      className: "text-nowrap",
      ellipsis: true,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <span>{record?.description}</span>,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
  ];

  const onSelectedActivity = (data: any): void => {
    try {
      handleSelect(data?.displayName);
      onClose();
    } catch (error) {
      appInsights.trackException(
        `Activity Table onSelectedActivity - ${error}`
      );
    }
  };

  const handleSort: IOnSort = (sortData, dataSource) => {
    try {
      return sortData(
        dataSource.map((i: any) => {
          return {
            ...i,
            children: sortData(i.children),
          };
        })
      );
    } catch (error) {
      appInsights.trackException(`Activity Table Sorting - ${error}`);
      return [];
    }
  };

  const handleFilter: IOnFilter = (searchData, dataSource) => {
    let result: any[] = [];
    try {
      dataSource.flatMap((i: any) => {
        searchData(i?.children)?.length != 0 &&
          result.push({
            ...i,
            children:
              searchData(i?.children)?.length == 0
                ? []
                : searchData(i?.children),
          });
      });
      return result;
    } catch (error) {
      appInsights.trackException(`Activity Table Filter Exeception -${error}`);
      return result;
    }
  };

  return (
    <div className="d-flex select-activity-body">
      <div className="activity-group">
        <p>
          <strong>
            {t("US.COLLECTION.COMMON:ACTIVITYEXECUTION.ACTIVITY_GROUP")}
          </strong>
        </p>
        <div>
          <$Radio
            name="filter"
            optionText="categoryName"
            optionValue="categoryId"
            className=""
            size="small"
            value={filterId}
            buttonStyle="solid"
            optionStyle=""
            options={categories}
            onChange={(e: any) => {
              setFilterId(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="flex-grow-1 activity-list">
        <$Skeleton loading={false} active paragraph={{ rows: 2 }}>
          <$TableTree
            onRow={(record: any, rowIndex: any) => {
              return {
                onDoubleClick: (event: any) => {
                  onSelectedActivity(record);
                },
              };
            }}
            rowKey={(record: any) => {
              return record.key;
            }}
            onSort={handleSort}
            onFilter={handleFilter}
            filterOnType={true}
            data={getFilterData(filterId)}
            columns={columns}
            size="small"
            className="activity-table"
            bordered
            pagination={{ defaultPageSize: 20 }}
            scroll={{ x: 780, y: "calc(100vh - 170px)" }}
            defaultExpandAllRows={true}
            resetOnSourceChange={true}
            currentCurrency={currentCurrency}
          />
        </$Skeleton>
      </div>
    </div>
  );
};

export default ActivityTable;
