import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from 'service.config.js';


const temData = [
    {
        creditorId: "10",
        creditorInkassoId: "4321",
        name: "Abans",
        isMasterCreditor: false
    },
    {
        creditorId: "11",
        creditorInkassoId: "4321",
        name: "Abans",
        isMasterCreditor: false
    }
]


const temBMDs = {
    caseCount: 10,
    properties: [
        {
            propertyName: "invoiceFee",
            propertyValue: "50"
        },
        {
            propertyName: "integrations",
            propertyValue: "24Seven"
        },
        {
            propertyName: "InputIntegration",
            propertyValue: "UnicornAPI"
        },
        {
            propertyName: "OutputIntegration",
            propertyValue: "EHF"
        },
        {
            propertyName: "OutputIntegration",
            propertyValue: "EHF1"
        },
        {
            propertyName: "NotifyBank",
            propertyValue: "No"
        }
    ]
}

export default {
    general: {
        getById: <
            P extends {
                creditorId: number;
            }
        >(
            params: P
        ): Promise<any> => {
            return new Promise<any>(async (resolve, reject) => {
                try {
                    const { data } = await versionedHttpCollection.get(
                        "entityservice",
                        `entities/${params.creditorId}`,
                        {},
                        ServiceConfig()[`entityservice`]['entities']
                    );
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            });
        }

    },
    otherContact: {
        getById: <
            P extends {
                creditorId: number;
            }
        >(
            params: P
        ): Promise<any> => {
            return new Promise<any>(async (resolve, reject) => {
                try {
                    const { data } = await versionedHttpCollection.get(
                        "entityservice",
                        `entities/contactPersons/${params.creditorId}`,
                        {},
                        ServiceConfig()[`entityservice`]['entities.contactperson']
                    );
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            });
        }
    },
    settings: {
        getById: <
            P extends {
                creditorId: number;
                bmdProperties: string;
            }
        >(
            params: P
        ): Promise<any> => {
            return new Promise<any>(async (resolve, reject) => {
                try {
                    const { data } = await versionedHttpCollection.post(
                        "systemservice",
                        `CreditorDetails`,
                        {
                            creditorId: params.creditorId,
                            bmdProperties: params.bmdProperties
                        },
                        ServiceConfig()[`systemservice`]['CreditorDetails']
                    );
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            });
        }
    },
    creditors: {
        getById: <
            P extends {
                creditorId: number;
            }
        >(
            params: P
        ): Promise<any> => {
            return new Promise<any>(async (resolve, reject) => {
                try {
                    const { data } = await versionedHttpCollection.get(
                        "entityservice",
                        `entities/linkedCreditors/${params.creditorId}`,
                        {},
                        ServiceConfig()[`entityservice`]['entities.linkedcreditors']
                    );
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            });
        }
    }
}