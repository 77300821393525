import { IFinancialAssets } from "./interfaces";
import moment from "moment";

export function SaveFinancialAssets(this: IFinancialAssets) {
  return {
    entityId: this.entityId,
    entityType: this.entityType,
    nameOrType: this.comment,
    estimatedValue: this.estimatedValue,
    valuationAmount: this.valuationAmount,
    valuationDate:
      this.valuationDate && moment(this.valuationDate).isValid()
        ? moment(this.valuationDate).format("YYYY-MM-DD")
        : null,
    financialInstitutionName: this.financialInstitutionName,
    financialInstitutionAddress1: this.financialInstitutionAddress1,
    financialInstitutionAddress2: this.financialInstitutionAddress2,
    nin: this.nin,
    financialInstitutionPostalCode: this.financialInstitutionPostalCode,
    financialInstitutionMunicipalityCode: this.financialInstitutionMunicipalityCode,
    description: this.description,
  };
}
