import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
	$Button,
	$Form,
	$TextArea,
	$Popover,
	$Tooltip,
	$Radio,
	$Skeleton,
	$Search,
} from 'us.common/components';
import { Formik } from 'formik';
import { CloseCaseAction } from 'us.collection/actions';
import 'us.collection/layouts/CaseView/Layout.scss';
import { BoxIcons, IconTypes } from 'us.icons';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { SaveCloseCase } from 'us.collection/repository';
import 'us.collection/layouts/CaseView/Components/Header/Header.scss';
import { CloseStates } from 'us.collection/constants';
import { IReason } from 'us.collection/reducers/CloseCase/Interfaces';
import { filterCloseState } from 'us.collection/layouts/CaseView/Functions';
import { CaseCloseState } from 'us.collection/layouts/CaseView/Constants';
import { RootState } from 'us.helper/types';
import Common from 'us.common';

const { reasons, closeCase } = CloseCaseAction;

const CloseCase: React.FC<PropsFromRedux> = (props) => {
	const { t } = useTranslation();

	const {
		metaData,
		reasons,
		popover,
		isClosing,
		currentLanguage,
		workflow,
		getCloseCaseReasons,
		openPopover,
		saveCloseCase,
	} = props;

	const { workflowDescription = '' } = workflow.data ?? {};

	const { data: reasonList, isLoading, isReasonAPIReset } = reasons ?? {};
	const { caseId, arId, entityType } = metaData.data ?? {};

	/**
	 * Close CloseCase view and reset form
	 * @param restProps formik props
	 */
	const closeCloseCase = (restProps: any) => {
		openPopover && openPopover({ visible: false });
		restProps.resetForm();
	};

	/**
	 * Save close case
	 * @param values form values
	 */
	const saveCloseCaseComment = (values: any) => {
		const reasonData = reasonList.find(
			(reason: IReason) =>
				reason.closeReasonId === values.reason
		);
		const requestObject = SaveCloseCase.call({
			values,
			reasonData,
		});
		saveCloseCase &&
			saveCloseCase({
				requestObject,
				caseId,
				arId,
				entityType,
			});
	};

	const popoverContent = (
		<Formik
			initialValues={{
				reason: 0,
				comment: '',
				reasonSearch: '',
			}}
			enableReinitialize
			onSubmit={saveCloseCaseComment}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				...rest
			}: any) => (
				<$Form>
					<div className='close-case-content'>
						<strong data-testid='reasons-title'>
							<span className='text-error mr-1'>
								*
							</span>
							{t(
								'US.COLLECTION.LAYOUT:CASE.REASON_FOR_CLOSURE'
							)}
						</strong>
						<div className='pt-2 pb-3'>
							<$Search
								size='small'
								name='reasonSearch'
								className='w-100'
								allowClear={
									true
								}
								placeholder={t(
									'US.COLLECTION.LAYOUT:CASE.SEARCH'
								)}
							/>
						</div>
						<div className='reasons-wrap mb-3'>
							{!isReasonAPIReset && (
								<$Skeleton
									loading={
										isLoading
									}
									active
									paragraph={{
										rows: 1,
									}}>
									<$Radio
										name='reason'
										options={filterCloseState(
											reasonList,
											values,
											currentLanguage
										)}
										optionValue='closeReasonId'
										optionText={
											currentLanguage ==
											Common
												.Constants
												.languageCodes
												.enGB
												? CaseCloseState.CLOSE_REASON
												: CaseCloseState.DISPLAY_NAME
										}
									/>
								</$Skeleton>
							)}
							{isReasonAPIReset && (
								<div>
									<$Button
										size='small'
										onClick={() =>
											getCloseCaseReasons &&
											getCloseCaseReasons(
												{}
											)
										}>
										{t(
											'US.COMMON:COMMON.RESET'
										)}
									</$Button>
								</div>
							)}
						</div>
						<$TextArea
							autoSize={{
								minRows: 4,
								maxRows: 4,
							}}
							size='small'
							label={t(
								'US.COLLECTION.LAYOUT:CASE.COMMENT'
							)}
							className=''
							name='comment'
							data-testid='close-comment'
						/>
						<div className='d-flex justify-content-end pt-2'>
							<$Button
								size='small'
								className='mr-2'
								htmlType='submit'
								type='primary'
								loading={
									isClosing
								}
								disabled={
									values.reason ===
										0 ||
									isClosing
								}
								onClick={
									handleSubmit
								}
								data-testid='close-case-save'>
								{t(
									'US.COMMON:COMMON.SAVE'
								)}
							</$Button>
							<$Button
								size='small'
								data-testid='close-case-cancel'
								onClick={() =>
									closeCloseCase(
										rest
									)
								}>
								{t(
									'US.COMMON:COMMON.CANCEL'
								)}
							</$Button>
						</div>
					</div>
				</$Form>
			)}
		</Formik>
	);

	return (
		<$Popover
			placement='bottomLeft'
			title={t('US.COLLECTION.LAYOUT:CASE.CLOSE_CASE')}
			content={popoverContent}
			trigger='click'
			visible={popover?.visible}
			onVisibleChange={(visible: boolean) => {
				if (
					!(
						(metaData.data &&
							isSubCaseTransferredToCase(
								metaData.data
							)) ||
						CloseStates.includes(
							workflowDescription
						)
					)
				) {
					openPopover &&
						openPopover({
							visible,
						});
				}
			}}>
			<$Tooltip
				placement='topLeft'
				title={t(
					'US.COLLECTION.LAYOUT:CASE.CLOSE_CASE'
				)}>
				<$Button
					onClick={() => {
						getCloseCaseReasons &&
							getCloseCaseReasons({});
						openPopover &&
							openPopover({
								visible: true,
							});
					}}
					className='bui-btn-tool-icon mr-3'
					data-testid='dashboard-closeCase'
					disabled={
						(metaData.data &&
							isSubCaseTransferredToCase(
								metaData.data
							)) ||
						CloseStates.includes(
							workflowDescription
						)
					}>
					<BoxIcons
						type={IconTypes.BOX_ICON}
						name='close-case'
					/>
				</$Button>
			</$Tooltip>
		</$Popover>
	);
};

const mapStateToProps = (state: RootState) => {
	const { dashboard, CloseCase, common, domainView } = state;
	const { currentLanguage } = common;
	const { accountSummeryWidgetData } = dashboard;
	const { reasons, popover, isClosing } = CloseCase;
	const { metaData, workflow } = domainView;
	return {
		currentLanguage,
		metaData,
		reasons,
		popover,
		accountSummeryWidgetData,
		isClosing,
		workflow,
	};
};

const mapDispatchToProps = {
	getCloseCaseReasons: reasons.get,
	openPopover: reasons.openDrawer,
	saveCloseCase: closeCase.save,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CloseCase);
