import * as Yup from 'yup';
import { organizationNoValidate } from 'us.collection/functions';
import { DEFAULT_COUNTRY_CODE } from 'us.common/constants';

export default () => {
	return Yup.object().shape({
		lastName: Yup.string().required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
		),
		email: Yup.string()
			.email(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.nullable(),
		companyNo: Yup.string().when('debtorType', {
			is: (val) => val === 'N',
			then: Yup.string()
				.typeError(
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
				)
				.test(
					'len',
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
					(val: any) => {
						val =
							typeof val ==
							'undefined'
								? ''
								: val.toString();
						return organizationNoValidate(
							val
						);
					}
				),
		}),
		home: Yup.string()
			.typeError(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.matches(
				/^\d{4,15}$/g,
				'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS'
			)
			.nullable(),
		mobile: Yup.string()
			.typeError(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.matches(
				/^\d{4,15}$/g,
				'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS'
			)
			.nullable(),
		work: Yup.string()
			.typeError(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.matches(
				/^\d{4,15}$/g,
				'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS'
			)
			.nullable(),
		sms: Yup.string()
			.typeError(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.matches(
				/^\d{4,15}$/g,
				'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS'
			)
			.nullable(),
		zipCode: Yup.string()
			.typeError(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.when('addrUnknown', {
				is: false,
				then: Yup.string()
					.required(
						'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
					)
			})
			.when('countryCode', {
				is: (countryId) =>
					countryId ===
					DEFAULT_COUNTRY_CODE,
				then: Yup.string().matches(
					/^\d+$/,
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
				),
			}),
		zipName: Yup.string()
			.nullable()
			.typeError(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.when(['zipCode', 'addrUnknown'], {
				is: (zipCode, addrUnknown) => {
					zipCode =
						typeof zipCode == 'undefined'
							? ''
							: zipCode;
					return (
						zipCode?.toString().length !==
							0 && !addrUnknown
					);
				},
				then: Yup.string()
					.required(
						'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
					)
					.test(
						'zipNameDigit',
						'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
						(val: any) => {
							let value =
								typeof val ==
								'undefined'
									? ''
									: val;
							return !/^\d+$/.test(
								value
							);
						}
					),
			}),
		municipalityCode: Yup.number()
			.nullable()
			.typeError(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			),
		municipalityName: Yup.string()
			.typeError(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.nullable(),
	});
};
