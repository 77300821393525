import { versionedHttpCollection } from 'us.helper/http/collection';
import ServiceConfig from 'service.config.js';

export default {
	caseId: {
		get: <P extends { exCaseNo: string }>(
			params: P
		): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { exCaseNo } = params;
					const { data, status } =
						await versionedHttpCollection.get(
							'metadataservice',
							`case/${exCaseNo}`,
							{},
							'v1'
						);
					if (status == 200) {
						resolve({ data, status });
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	metaData: {
		get: <P extends { id: number; type: string }>({
			id,
			type,
		}: P): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.getById(
							'metadataservice',
							'domainview',
							`${id}/${type}`,
							ServiceConfig()[
								`metadataservice`
							]['domiainview']
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	workflowStatus: {
		get: <P extends { caseId: number }>(
			params: P
		): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { caseId } = params;
					const { data, status } =
						await versionedHttpCollection.get(
							'metadataservice',
							`workflow/${caseId}`,
							{},
							'v1'
						);
					if (status == 200) {
						resolve({ data, status });
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	gdprComment: {
		save: (params: any): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.post(
							'userservice',
							`users/accessauthoritycomments`,
							params,
							ServiceConfig()[
								`userservice`
							][
								'users.accessauthoritycomments'
							]
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
	},
};
