import React from 'react';
import { Route } from 'react-router-dom';
import { IAsyncRoute } from 'us.collection/interfaces';
import { CaseType } from 'us.helper/types/enums';

export const asyncRoutes = (
    url: string,
    caseNo: number | string,
    caseType: CaseType,
    Containers:Array<IAsyncRoute>
  ) => (
    <>
      {Containers.map((container: IAsyncRoute, index: number) => {
        return (
          <Route
            exact
            key={container.Route}
            path={`${url}/${container.Route}`}
            render={(props) => (
              <container.Container
                {...props}
                caseNo={caseNo}
                caseType={caseType}
              />
            )}
          />
        );
      })}
    </>
  );