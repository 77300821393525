import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { stringify, parse } from "query-string";
import { $Button, $Select, $DatePicker, $InputWithValue } from "us.common/components";
import { DateFormat, EntityFollowUpPanel } from "us.collection.case/constants";
import { INavigationData, IRootState } from "us.collection/interfaces";
import { IInitialFilter } from "./Interfaces";
import { initialFilter } from "./Constants";
import moment from "moment";

/**
 * @description - Current Active Follow-ups filter panel in left side panel
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2912288817/Get+active+follow-up+for+EntityId+and+EntityType+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 09/03/2022
 */
const FilterPanel: React.FC<PropsFromRedux & RouteComponentProps> = (props) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<IInitialFilter>(initialFilter);

  const {
    currentDateFormat,
    drawer,
    history,
    location,
  } = props;

  const state = location.state as INavigationData;
  const { panel } = drawer;

  useEffect(() => {
    const currentFilters = parse(location.search);
    setFilters({...filters, ...currentFilters})
  }, [location]);

  /**
   * @function
   * @description - read from already typed search filters and update with newly searched field value
   * @param {string} field - search field name
   * @param {any} value - search value
   * @returns 
   */
  const handleFilterChange = (field: string, value: any) => {
    const previousFilters = parse(location.search);
    const newFilters = stringify({ ...previousFilters, [field]: value });

    history.replace({ state, search: newFilters });
  };

  /**
   * @function
   * @description - reset filter values in query params URL
   * @returns 
   */
  const resetFilter = () => {
    history.replace({ state, search: "" });
    setFilters(initialFilter)
  };

  return (
    <>
      <$InputWithValue
        name="name"
        size="small"
        type="text"
        placeholder={t("US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.NAME")}
        className="w-100"
        value={filters?.name}
        onChange={(e: any) => handleFilterChange("name", e.target.value)}
      />

      <$DatePicker
        name="date"
        size="small"
        allowClear={true}
        placeholder={t(
          `US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.${
            panel === EntityFollowUpPanel.OTHER ? "REG_DATE" : "DATE"
          }`
        )}
        format={currentDateFormat}
        className="w-100"
        value={moment(filters?.date)}
        onChange={(date: any) => handleFilterChange("date", moment(date).format(DateFormat))}
      />

      {panel === EntityFollowUpPanel.OTHER && (
        <$Select
          name="assignedTo"
          placeholder={t("US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.ASSIGNED_TO")}
          size="small"
          allOption={false}
          value={filters?.assignedTo}
          optionText={"label"}
          optionValue={"value"}
          options={[
            {
              label: t("US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.ALL"),
              value: "All",
            },
            {
              label: t("US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.ROLE"),
              value: "Role",
            },
            {
              label: t("US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.USER"),
              value: "User",
            },
          ]}
          className="w-100 my-1"
          onChange={(option: any) => handleFilterChange("assignedTo", option)}
        />
      )}

      {(panel === EntityFollowUpPanel.DUE ||
        panel === EntityFollowUpPanel.UPCOMING) && (
        <$InputWithValue
          name="description"
          size="small"
          type="text"
          placeholder={t("US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.NOTE")}
          className="w-100"
          value={filters?.description}
          onChange={(e: any) =>
            handleFilterChange("description", e.target.value)
          }
        />
      )}

      <$Button
        type="default"
        size="small"
        block
        className="mt-2"
        onClick={() => resetFilter()}
      >
        {t("US.COLLECTION.COMMON:COMMON.RESET")}
      </$Button>
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common, entityFollowUps } = state;
  const { currentDateFormat } = common;
  const { drawer } = entityFollowUps;

  return {
    currentDateFormat,
    drawer,
  };
};

const mapDispatchToProps = {
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FilterPanel);
