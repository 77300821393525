import * as Yup from "yup";

interface IRealEstate extends Yup.MixedSchema {
  description: string;
  postalCode: string;
  estimatedValue: string;
  valuationAmount: string;
}

const RealEstateSchema = Yup.object<IRealEstate>().shape({
  description: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.DESCRIPTION_IS_REQUIRED"
  ),
  postalCode: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.POSTAL_CODE_IS_REQUIRED"
  ),
  estimatedValue: Yup.string()
  .test(
    "estimatedValue",
    "US.COLLECTION.VALIDATIONS:REQUIRED.ESTIMATED_VALUE_MUST_BE_POSITIVE",
    (val: any) => {
      return val >= 0;
    }
  ),
  valuationAmount: Yup.string()
  .test(
    "valuationAmount",
    "US.COLLECTION.VALIDATIONS:REQUIRED.VALUATION_AMOUNT_MUST_BE_POSITIVE",
    (val: any) => {
      return val >= 0;
    }
  ),
});

export default RealEstateSchema;