import { IAPIAction } from "us.collection/interfaces";
import { Creditor } from 'us.collection.creditor/constants';

export const creditor: Readonly<IAPIAction> = {
    general: {
        get: (data) => ({
            type: Creditor.GET_GENERAL_AND_ADDRESS_DATA_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: Creditor.GET_GENERAL_AND_ADDRESS_DATA_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: Creditor.GET_GENERAL_AND_ADDRESS_DATA_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
    otherContact: {
        get: (data) => ({
            type: Creditor.GET_OTHER_CONTACT_DATA_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: Creditor.GET_OTHER_CONTACT_DATA_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: Creditor.GET_OTHER_CONTACT_DATA_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
    creditors: {
        get: (data) => ({
            type: Creditor.GET_CREDITORS_DATA_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: Creditor.GET_CREDITORS_DATA_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: Creditor.GET_CREDITORS_DATA_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
    creditorBMDs: {
        get: (data) => ({
            type: Creditor.GET_CREDITORS_BMD_DATA_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: Creditor.GET_CREDITORS_BMD_DATA_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: Creditor.GET_CREDITORS_BMD_DATA_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    }
}
