import {
  CreditorCaseState,
  ExcelColumnType,
  IDropDown,
} from "us.collection.creditor/components/CreditorCases/Interfaces";

export enum CaseTypes {
  OPEN = "Open",
  CLOSED = "Closed",
  NEXT_DUE_DATE = "Freezed",
  SENT_TO_COURT = "SendToCourt",
  SENTENCE_RECEIVED = "SentenceReceived",
}

export enum EntityType {
  SUB_CASE = "S",
  CASE = "C",
  ALL ="A"
}

export const SERVER_DATE_FORMAT = `YYYY-MM-DD`;
export const STANDARD_DATE_FORMAT = `DD/MM/YYYY`

export const CREDITOR_CASES_COLUMNS = [
  {
    dataIndex: "key",
    width: "5%",
  },
  {
    columnTitleCaseNo: "US.COLLECTION.CREDITOR:CREDITOR_CASES.CASE_NO",
    columnTitleCaseCount: "US.COLLECTION.CREDITOR:CREDITOR_CASES.CASES",
    rowTitleOpen: "US.COLLECTION.CREDITOR:CREDITOR_CASES.OPEN",
    rowTitleClosed:"US.COLLECTION.CREDITOR:CREDITOR_CASES.CLOSED",
    dataIndex: "exCaseNo",
    key: "exCaseNo",
    width: "27%",
  },
  {
    title: "US.COLLECTION.CREDITOR:CREDITOR_CASES.INVOICE_REG_DATE",
    dataIndex: "regDates",
    key: "regDates",
    className: "",
    width: "12%",
  },
  {
    title: "US.COLLECTION.CREDITOR:CREDITOR_CASES.INVOICE_VOUCHER_DATE",
    dataIndex: "voucherDates",
    key: "voucherDates",
    className: "",
    width: "12%",
  },
  {
    title: "US.COLLECTION.CREDITOR:CREDITOR_CASES.REF",
    dataIndex: "refs",
    key: "refs",
    className: "",
    width: "12%",
  },
  {
    title: "US.COLLECTION.CREDITOR:CREDITOR_CASES.INVOICE_KID",
    dataIndex: "kiDs",
    key: "kiDs",
    className: "",
    width: "12%",
  }, 
  {
    columnTitle: "US.COLLECTION.CREDITOR:CREDITOR_CASES.MAIN_AMOUNT",
    dataIndex: "mainAmount",
    key: "mainAmount",
    className: "right-has-sort right-has-filter text-nowrap",
    align: "right",
    width: "12%",
  },
  {
    columnTitle: "US.COLLECTION.CREDITOR:CREDITOR_CASES.TOTAL_BALANCE",
    dataIndex: "balance",
    key: "balance",
    className: "right-has-sort right-has-filter text-nowrap",
    align: "right",
    width: "12%",
  },
];

export const EXCEL_COLUMNS: Array<ExcelColumnType> = [
  {
    title: "US.COLLECTION.CREDITOR:CREDITOR_CASES.CASE_NO",
    dataIndex: "exCaseNo",
    format: "Text",
  },
  {
    title: "US.COLLECTION.CREDITOR:CREDITOR_CASES.DEBTOR",
    dataIndex: "debtorNameOrNumber",
    format: "Text",
  },
  {
    title: "US.COLLECTION.CREDITOR:CREDITOR_CASES.INVOICE_REG_DATE",
    dataIndex: "regDate",
    format: "Text",
  },
  {
    title: "US.COLLECTION.CREDITOR:CREDITOR_CASES.INVOICE_VOUCHER_DATE",
    dataIndex: "voucherDate",
    format: "Text",
  }, 
  {
    title: "US.COLLECTION.CREDITOR:CREDITOR_CASES.REF",
    dataIndex: "invoiceNo",
    format: "Text",
  },
  {
    title: "US.COLLECTION.CREDITOR:CREDITOR_CASES.INVOICE_KID",
    dataIndex: "invoiceKid",
    format: "Text",
  },
  {
    title: "US.COLLECTION.CREDITOR:CREDITOR_CASES.MAIN_AMOUNT",
    dataIndex: "mainAmount",
    format: "Currency",
  },
  {
    title: "US.COLLECTION.CREDITOR:CREDITOR_CASES.TOTAL_BALANCE",
    dataIndex: "totalBalance",
    format: "Currency",
  },
];

export const DEFAULT_MODIFIED_LIST: CreditorCaseState = {
  modifiedList: [],
  caseCount: 0,
  mainAmount: 0,
  totalBalance: 0,
};

export enum ColumnType {
  KEY="key",
  INVOICE_NO = "refs",
  INVOICE_KID="kiDs",
  DEBTOR = "custId",
  CASE = "exCaseNo",
  MAIN_AMOUNT = "mainAmount",
  TOTAL_BALANCE = "balance",
  REG_DATE = "regDates",
  VOUCHER_DATE = "voucherDates"
}

export const CASE_TYPE_DROP_DOWN: IDropDown<EntityType>[] = [
  {
    label: "ALL_CASES",
    value: EntityType.ALL,
  },
  {
    label: "SUBCASES",
    value: EntityType.SUB_CASE,
  },
  {
    label: "CASES",
    value: EntityType.CASE,
  },
];

export enum SearchDateTypes {
  REG_DATE = "RegDate",
  VOUCHER_DATE = "VoucherDate"
}

export const SearchDateOptions = [
  {
    label: "CREDITOR_CASES.INVOICE_REG_DATE",
    value: SearchDateTypes.REG_DATE
  },
  {
    label: "CREDITOR_CASES.INVOICE_VOUCHER_DATE",
    value: SearchDateTypes.VOUCHER_DATE
  }
]
