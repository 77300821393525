import moment from "moment";
import { matchPath } from "react-router-dom";
import { IActiveFollowUp } from "us.collection.followup/interfaces";
import {
  FollowUpEntityType,
  UrlModule,
} from "us.collection.followup/constants";
import { Types } from "us.collection/components/ActivityExecution/Constants";
import { IInitialFilter } from "us.collection/components/FollowUps/Components/CurrentFollowUps/Components/Interfaces";

/**
 * @function
 * @description - Filter Active EntityFollowUp by filter panel values
 * @param {Array<IActiveFollowUp>} followUpList - initial followup list
 * @param {IInitialFilter} filters - filter values
 * @returns  {Array<IActiveFollowUp>} filtered out active filters
 */
export const filterActiveEntityFollowUps = (
  followUpList: Array<IActiveFollowUp>,
  filters: IInitialFilter
): Array<IActiveFollowUp> => {
  const {
    name: filterName,
    date: filterDate,
    assignedTo: filterAssignedTo,
  } = filters;
  try {
    return followUpList.filter((followUp: IActiveFollowUp) => {
      const { followupName, createdDate, assignedTo } = followUp;
      return (
        followupName.toLowerCase().includes(filterName.toLowerCase()) &&
        (moment(filterDate).isValid()
          ? moment(createdDate).isSame(moment(filterDate), "day")
          : true) &&
        assignedTo.toLowerCase().includes(filterAssignedTo.toLowerCase())
      );
    });
  } catch (error) {
    return followUpList;
  }
};

/**
 * @function
 * @description - get entityId and entityType using url and meta data redux state value
 * @param {any} location - location details
 * @param {any} metaData - metaData redux state
 * @returns { entityId: number; entityType: string } entityDetails
 */
export const getEntityTypeAndId = (
  location: any,
  metaData: any,
  isExecutionBar?: boolean
): { entityId: number; entityType: string } => {
  const { params }: any = matchPath(location.pathname, {
    path: "/:entityType/:entityId?/:module?",
    exact: true,
    strict: false,
  });
  const urlEntityId = params["entityId"];
  const urlEntityType = params["entityType"];
  const { caseId, entityType, creditorId } = metaData ?? {
    caseId: 0,
    entityType: "",
    creditorId: 0,
  };
  switch (urlEntityType) {
    case UrlModule.CASE:
      if (entityType === Types.SUBCASE) {
        return { entityId: caseId, entityType: FollowUpEntityType.INVOICE };
      } else {
        return { entityId: caseId, entityType: FollowUpEntityType.CASE };
      }
    case UrlModule.AR:
      return { entityId: urlEntityId, entityType: FollowUpEntityType.DEBTOR };
    case UrlModule.CREDITOR:
      return {
        entityId: isExecutionBar ? creditorId : urlEntityId,
        entityType: FollowUpEntityType.CREDITOR,
      };
    case UrlModule.PAYMENT:
      return {
        entityId: urlEntityId,
        entityType: FollowUpEntityType.PAYMENT,
      };
    default:
      return { entityId: urlEntityId, entityType: FollowUpEntityType.CASE };
  }
};
