import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from "service.config.js";

export const CourtSentence = {
  getSummery: <P extends { caseId: number }>(params: P): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      const { caseId } = params;
      try {
        const { data, status } = await versionedHttpCollection.get(
          "sentenceservice",
          "sentences/case/summary",
          { caseId },
          ServiceConfig()[`sentenceservice`]["sentnece.summery"]
        );
        if ([200, 204].includes(status) && Array.isArray(data)) {
          resolve(data);
        } else {
          reject([]);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteSentence: (sentenceId: number): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data } = await versionedHttpCollection.patch(
          "sentenceservice",
          `sentences/${sentenceId}`,
          {},
          ServiceConfig()[`sentenceservice`]["sentence.delete"]
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getCaseAmounts: (entityId: number): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.get(
          "sentenceservice",
          "sentences/caseamounts",
          { entityId, entityType: "C" },
          ServiceConfig()[`sentenceservice`]["sentence.amounts"]
        );
        if (200 === status) {
          resolve(data);
        } else {
          reject({});
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  getSentence: (sentenceId: number): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.getById(
          "sentenceservice",
          "sentences",
          sentenceId,
          ServiceConfig()[`sentenceservice`]["sentence.details"]
        );
        if (200 === status) {
          resolve(data);
        } else {
          reject({});
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  addSentence: (sentence: any): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.post(
          "sentenceservice",
          "sentences",
          sentence,
          ServiceConfig()[`sentenceservice`]["sentence.add"]
        );
        if ([200, 201].includes(status)) {
          resolve(data);
        } else {
          reject({});
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  editSentence: (sentence: any,sentenceId:number): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.put(
          "sentenceservice",
          `sentences/${sentenceId}`,
          sentence,
          ServiceConfig()[`sentenceservice`]["sentence.edit"]
        );
        if ([200, 201].includes(status)) {
          resolve(data);
        } else {
          reject({});
        }
      } catch (error) {
        reject(error);
      }
    });
  },
};
