import moment from "moment"
import { DateFormat } from 'us.collection.case/constants'

export const initialValue = {
    category: "",
    date: moment().add(7, 'days').format(DateFormat),
    assign: "all",
    note: "",
    reasonSearch: "",
    reasonName: "",
    reason: '',
}