export enum WorkflowState {
    ADDRESS_UNKNOWN = 'AddressUnknown',
    BANCRUPTCY = 'Bancruptcy',
    CLOSE = 'Close',
    CLOSED = 'Closed',
    CONFORMATION = 'Conformation',
    CORE_WORKFLOW = 'CoreWorkflow',
    CREDIT_CHECK = 'CreditCheck',
    CREDITTOR_BO = 'CreditorBO',
    CREDITTOR_DW = 'CreditorDW',
    CREDITTOR_REMINDER = 'CreditorReminder',
    CREDITTOR_RESPOND = 'CreditorRespond',
    DEBTSETTLEMENT = 'Debtsettlement',
    ENFORCEMENT = 'Enforcement',
    ERROR = 'Error',
    FREEZE = 'Freeze',
    HIGH_BALANCE_CAMPAIGN_COMPLETED = 'HighBalanceCampaignCompleted',
    HIGHER_BALANCE_SURVEILLANCE = 'HigherBalanceSurveillance',
    INITIALIZE = 'Initialize',
    INITILIZING = 'Initilizing',
    KEEP_WAITING = 'KeepWaiting ',
    LEGAL = 'Legal',
    LEGAL_OVERVAKET= 'Legal overvåket',
    LEGAL_COLLECTION= 'LegalCollection',
    LEGAL_SURVEILLANCE= 'LegalSurveillance',
    LOW_BALANCE_CAMPAIGN_COMPLETED = 'LowBalanceCampaignCompleted',
    LOW_BALANCE_SURVEILLANCE = 'LowBalanceSurveillance',
    MANUAL = 'Manual',
    MINOR = 'Minor',
    NAGGING = 'Nagging',
    OBJECTED_CLAIM = 'ObjectedClaim',
    OBJECTION = 'Objection',
    OVERVAKET = 'overvåket',
    PART_PAYMENT = 'Partpayment',
    PAYMENT_AGREEMENT = 'PaymentAgreement',
    QUARANTINE = 'Quarantine ',
    REMINDER = 'Reminder',
    REST_REMINDER = 'RestReminder',
    SALARY_DEDUCTION = 'SalaryDeduction',
    SENTENCE_RECEIVED = 'Sentence Received',
    START_SURVEILLANCE = 'StartSurveillance',
    SUBCASE_OBJECTED_CLAIM = 'SubCaseObjectedClaim',
    SURVEILLANCE = 'Surveilance',
    SUV_CLOSE_CASE = 'SuvCloseCase',
    SUV_OBJECTION_STATE = 'SuvObjectionState',
    SUV_PART_PAYMENT = 'SuvPartpayment',
    SUV_UNKNOWN = 'SuvUnknown',
    TRANSFER_TO_CASE = 'TransferToCase',
    UNKNOWN = 'UnKnown',
    WAITING = 'Waiting'
}

export enum TransactionNavigate {
    MAIN_AMOUNT = 'mainAmount',
    COLLECTION_FEE = 'collectionFee',
    COURT_FEE = 'courtFee',
    CASE_COST = 'caseCost',
    OTHER_COST = 'otherCost',
    INTEREST_MAIN = 'interestMain',
    INTEREST_COST = 'interestCost',
    TOTAL = 'total',
    IN = 'IN',
    CF = 'CF',
    ALL = 'all'
}
  