import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import Common from "us.common";
import { workflowAction } from "us.collection/actions";
import { PlusOutlined, DeleteOutlined, MoreOutlined } from "us.icons";
import {
  workflowDrawerInitial,
  WorkflowDrawerType,
  ItemMenuActions,
  WORKFLOW_TOOL_DOWNLOAD_URL,
} from "us.collection/components/Workflow/Constants";
import { IStateCategory } from "us.collection/components/Workflow/Interfaces";
import { StateDelete, WorkflowGroup } from "us.collection/repository";
import { IStateList } from "us.collection/reducers/Workflow/Interfaces";
import { RootState } from "us.helper/types";
import { EntityType, WorkflowDrawerActionType } from "us.collection/constants";
import { getOverriddenColor } from "us.collection/components/Workflow/Functions";
import { useFormikContext } from "formik";
import { getEntityNumberAndType } from "us.collection/components/BMD/Functions";
import { matchPath, useLocation } from "react-router-dom";
import { WorkflowTag } from "us.collection/components/Workflow/Components";
import { ItemMenu } from "./Components";
import { openWorkflowTool } from "us.collection/functions";
import "us.collection/components/Workflow/Home.scss";
import _ from "lodash";

const { $Popconfirm, $Button, $Tooltip, $Empty, $Popover } = Common.Components;

/**
 * @description - The component used in view of workflow category and states
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3055714493/Manage+State+Categories
 * @param {PropsFromRedux} props
 * @return {JSX.Element}
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 14/11/2022
 */

const StateCategory: React.FC<PropsFromRedux & IStateCategory> = (props) => {
  const { t } = useTranslation();

  const {
    workflowGroup,
    stateType,
    openDrawer,
    deleteCategory,
    deleteState,
    isDeleting,
    metaData,
  } = props;

  const { caseId } = metaData.data ?? {};

  const { values } = useFormikContext<any>();
  const { pathname } = useLocation();

  const [popoverOpenId, setPopoverOpenId] = useState(-1);
  const [isDownloadPopupVisible, setDownloadPopupVisible] =
    useState<boolean>(false);

  const { params }: any = matchPath(pathname, {
    path: "/:type/:id?/:module?",
    exact: false,
    strict: false,
  });

  const entityData = WorkflowGroup.call(
    getEntityNumberAndType(
      params["type"],
      params["id"],
      caseId?.toString() ?? ""
    )
  );

  /**
   * @function
   * @description Get state request payload
   * @returns State request
   */
  const getStateRequest = () => {
    return WorkflowGroup.call(
      getEntityNumberAndType(
        params["type"],
        params["id"],
        caseId?.toString() ?? ""
      )
    );
  };

  /**
   * @function
   * @description Delete workflow category
   * @returns Void
   */
  const deleteWorkflowCategory = () => {
    deleteCategory &&
      deleteCategory({
        stateCategoryId: workflowGroup?.stateCategoryId,
        stateRequest: getStateRequest(),
      });
  };

  /**
   * @function
   * @description Delete workflow state
   * @param id Workflow state id
   * @returns Void
   */
  const deleteWorkflowState = (id: number, categoryId: number) => {
    const deleteStateParams = StateDelete.call({ id, categoryId });
    deleteState &&
      deleteState({
        ...deleteStateParams,
        stateRequest: getStateRequest(),
        searchFilters: _.omit(values, "statesAndEvent"),
      });
    setPopoverOpenId(-1);
  };

  /**
   * Open add edit drawer
   * @param title Drawer title
   * @param type Workflow type (State/Category)
   * @param isEdit Edit view
   * @param stateCategoryId Category id
   * @param stateId State id
   */
  const openWorkflowDrawer = (
    title: string,
    type: string,
    actionType: WorkflowDrawerActionType,
    stateCategoryId?: number,
    stateId?: number,
    workflowGroupStateId?: number
  ) => {
    openDrawer &&
      openDrawer({
        ...workflowDrawerInitial,
        title: title,
        isVisible: true,
        actionType,
        type,
        stateCategoryId,
        stateType,
        stateId,
        workflowGroupStateId,
      });
  };

  /**
   * @description handle states menu actions
   * @param action - action of clicked menu icon
   * @param record - selected record for action
   */
  const handleStateAction = async (
    action: ItemMenuActions,
    record: IStateList
  ) => {
    const { id, workflowGroupStateId } = record ?? {};
    switch (action) {
      case ItemMenuActions.OPEN_WORKFLOW:
        const socketStatus = await openWorkflowTool({
          ...record,
          ...entityData,
        });
        setDownloadPopupVisible(socketStatus);
        break;
      case ItemMenuActions.DOWNLOAD:
        window.open(WORKFLOW_TOOL_DOWNLOAD_URL, "_self");
        setPopoverOpenId(-1);
        break;
      case ItemMenuActions.EDIT:
        openWorkflowDrawer(
          "US.COLLECTION.CASE:WORKFLOWS.EDIT_STATE",
          WorkflowDrawerType.STATE,
          WorkflowDrawerActionType.EDIT,
          workflowGroup?.stateCategoryId,
          id,
          workflowGroupStateId
        );
        setPopoverOpenId(-1);
        break;
      case ItemMenuActions.COPY:
        openWorkflowDrawer(
          "US.COLLECTION.CASE:WORKFLOWS.COPY_STATE",
          WorkflowDrawerType.STATE,
          WorkflowDrawerActionType.COPY,
          workflowGroup?.stateCategoryId,
          id,
          workflowGroupStateId
        );
        setPopoverOpenId(-1);
        break;
      case ItemMenuActions.DELETE:
        deleteWorkflowState(id, workflowGroup?.stateCategoryId);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="ws-tile">
        <div className="ws-cat-item">
          <div className="ws-cat-item-header">
            <a
              className="wscih-title"
              onClick={() =>
                openWorkflowDrawer(
                  "US.COLLECTION.CASE:WORKFLOWS.EDIT_STATE_CATEGORY",
                  WorkflowDrawerType.CATEGORY,
                  WorkflowDrawerActionType.EDIT,
                  workflowGroup?.stateCategoryId
                )
              }
            >
              <$Tooltip
                placement="topLeft"
                title={workflowGroup?.stateCategoryDisplayName}
              >
                {workflowGroup?.stateCategoryDisplayName}
              </$Tooltip>
            </a>
            <div className="wscih-actions">
              <$Tooltip
                placement="top"
                title={t("US.COLLECTION.CASE:WORKFLOWS.DELETE_CATEGORY")}
              >
                <$Popconfirm
                  title={t(
                    "US.COLLECTION.CASE:WORKFLOWS.DO_YOU_WANT_TO_DELETE_THE_CATEGORY"
                  )}
                  placement="topLeft"
                  onConfirm={() => deleteWorkflowCategory()}
                  okText={t("US.COMMON:COMMON.YES")}
                  cancelText={t("US.COMMON:COMMON.NO")}
                  disabled={workflowGroup?.stateList?.length > 0}
                >
                  <$Button
                    type="link"
                    size="small"
                    icon={<DeleteOutlined />}
                    danger
                    disabled={workflowGroup?.stateList?.length > 0}
                  />
                </$Popconfirm>
              </$Tooltip>
              <$Tooltip
                placement="top"
                title={t("US.COLLECTION.CASE:WORKFLOWS.ADD_NEW_STATE")}
              >
                <$Button
                  type="default"
                  size="small"
                  icon={<PlusOutlined />}
                  disabled={isDeleting}
                  onClick={() =>
                    openWorkflowDrawer(
                      "US.COLLECTION.CASE:WORKFLOWS.ADD_NEW_STATE",
                      WorkflowDrawerType.STATE,
                      WorkflowDrawerActionType.ADD,
                      workflowGroup?.stateCategoryId
                    )
                  }
                  className="ml-2"
                />
              </$Tooltip>
            </div>
          </div>
          <div className="ws-cat-item-body">
            {workflowGroup?.stateList?.length == 0 && (
              <$Empty
                className="py-5 m-0"
                description={`${t(
                  "US.COLLECTION.CASE:WORKFLOWS.NO_STATES_ARE_FOUND_UNDER_THIS_WORKFLOW_CATEGORY"
                )}`}
                image={$Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
            {workflowGroup?.stateList?.length > 0 && (
              <>
                {workflowGroup?.stateList?.map((item: IStateList) => {
                  const {
                    id,
                    overriddenBy,
                    workflowType,
                    activeStatus,
                    dueInDays,
                    dueInType,
                    displayName,
                  } = item ?? {};
                  return (
                    <div
                      key={id.toString()}
                      className={
                        "d-flex align-items-stretch swg-item" +
                        getOverriddenColor(overriddenBy ?? EntityType.BUREAU)
                      }
                      data-testid="workflow-state"
                    >
                      <div className="d-flex flex-grow-1 align-items-center swg-item-click">
                        <WorkflowTag
                          workflowType={workflowType}
                          activeStatus={activeStatus}
                        />
                        <span className="d-flex align-items-center mr-2 swg-item-days">
                          <span className="d-flex align-items-center">
                            {dueInDays}
                            <small>{dueInType}</small>
                          </span>
                        </span>
                        <span className="d-flex align-items-center swg-item-desc">
                          <span>{displayName}</span>
                        </span>
                      </div>

                      <div className="actions">
                        <$Popover
                          placement="rightTop"
                          content={
                            <ItemMenu
                              isDownloadPopupVisible={isDownloadPopupVisible}
                              onCallAction={(action) =>
                                handleStateAction(action, item)
                              }
                              onCancelChildPopup={() => {
                                setPopoverOpenId(-1);
                                setDownloadPopupVisible(false);
                              }}
                            />
                          }
                          destroyTooltipOnHide
                          trigger="click"
                          visible={id == popoverOpenId}
                          onVisibleChange={(visible: boolean) => {
                            setPopoverOpenId(visible ? id : -1);
                            setDownloadPopupVisible(false);
                          }}
                        >
                          <$Button
                            type="link"
                            icon={<MoreOutlined />}
                            data-testid="popover-btn"
                            size="small"
                            onClick={() => {
                              setPopoverOpenId(id);
                            }}
                          />
                        </$Popover>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const { category, categoryDeletion, stateDeletion } = workflowAction;

const mapStateToProps = (state: RootState) => {
  const { Workflow, domainView } = state;
  const { workflowStateDrawer, isDeleting } = Workflow;
  const { metaData } = domainView;
  return {
    workflowStateDrawer,
    isDeleting,
    metaData,
  };
};

const mapDispatchToProps = {
  openDrawer: category.openDrawer,
  deleteCategory: categoryDeletion.delete,
  deleteState: stateDeletion.delete,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StateCategory);
