import { IInitialState } from "../Interfaces";

export const initialState: IInitialState = {
  nextResult: {
    followupId: 0,
    nextResultId: 0,
    entityType: '',
    nextResultEntityId: 0
  },
  followupList: {
    data: [],
    isFetching: false
  },
  previousResult: {
    previousResultIds: [],
  },
  isActive: false,
};