import { TABLE_ACTIONS } from "us.collection/components/BMD/Constants";
import { IBMDChangeHistory } from "us.collection/interfaces";
import { ITableActionCombined } from "../../TableAction/Interfaces";

 /**
 * @function
 * @description - to get the table actions (edit/delete)
 * @param {IBMDChangeHistory} record - the record data rendering in the row
 * @return {*}  {ITableActionCombined[]}
 */
  export const getActions = (record: IBMDChangeHistory): ITableActionCombined[] => {
  if (record.isNew) {
    return [
      { action: TABLE_ACTIONS.EDIT, isPopupDisabled: !record.isNew, popupMessage: "" },
      { action: TABLE_ACTIONS.DELETE, isPopupDisabled: true, popupMessage: "" },
    ];
  } else {
    return [{ action: TABLE_ACTIONS.DELETE, isPopupDisabled: !record.isNew, popupMessage: "" }];
  }
};
