import React, { useEffect } from "react";
import { Formik } from "formik";
import { IRootState } from "us.collection/interfaces";
import * as Actions from "us.collection.routines-and-activities/actions";
import { connect } from "react-redux";
import Common from "us.common";
import { useTranslation } from "react-i18next";
import { INote } from "us.collection.routines-and-activities/components/Note/Interfaces";
import { getCaseNumberAndType } from "us.collection.routines-and-activities/components/Note/Functions";
import * as Azure from "us.helper/azure";
import { isEditableUserNote } from "us.collection.routines-and-activities/functions";
import { Type } from "us.collection.routines-and-activities/constants";
import { NoteType, RegisteredUserType, ActivityStatusForPayload, ActivityTypesForPayload, NoteSource } from "us.collection/constants";
import { UpdateNotePayload } from "./Repository";

const { historyAction } = Actions;
const { noteStatus, note, deleteNote } = historyAction;
const { $TextArea, $Switch, $Button, $Typography, $Popconfirm } =
  Common.Components;

const { Paragraph } = $Typography;

const Note: React.FC<INote> = (props) => {
  const { t } = useTranslation();
  const { setNotifyStatus, close, data, edit, deleteNote, location, metaData, isNoteDeleting } =
    props;
  const { noteId, notified, source, noteType } = data ?? {};
  
  let isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
  let auth: any;
  if (isAzureAD) {
    auth = new Azure.ADAuth();
  } else {
    auth = new Azure.B2CAuth();
  }

  const azureUser: any = auth.currentUser();

  useEffect(() => {
    if (!notified && (noteType === NoteType.CP_NOTE ? source != NoteSource.US_BOX : true)) {
      setNotifyStatus && setNotifyStatus({ noteId });
    }
  }, []);

  const updateNote = (values: any) => {
      const notePayload = UpdateNotePayload.call({values, noteId, location, metaData});

      edit({ params: notePayload, closeAction: close });
  };

  const noteDelete = () => {
    if (metaData.data)
      deleteNote({
        params: {
          noteId,
          entityType: getCaseNumberAndType(location, metaData.data)?.type,
        },
        closeAction: close,
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        internalNote: data?.isInternalNote,
        copyToDebNotes: data?.copiedToDebtorNotes,
        noteText: data?.name,
        isSystemGeneratedNote:
          data?.createdBy?.toLowerCase() ==
          RegisteredUserType.SYSTEM?.toLowerCase(),
      }}
      onSubmit={updateNote}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...rest
      }: any) => (
        <>
          <$TextArea
            name="noteText"
            className="note-message"
            style={{
              height:
                noteType != NoteType.CP_NOTE
                  ? "calc(88vh - 127px)"
                  : "calc(88vh - 70px)",
            }}
            disabled={
              !isEditableUserNote(data, azureUser?.unique_name) ||
              noteType == NoteType.CP_NOTE
            }
          />
          {noteType != NoteType.CP_NOTE && metaData.data && 
            getCaseNumberAndType(location, metaData.data)?.type != Type.AR &&
            getCaseNumberAndType(location, metaData.data)?.type != Type.CR && (
            <div
              className="dom-amt-list-table mb-2"
              style={{
                tableLayout: "fixed",
              }}
            >
              <div className="list-table-row">
                <div
                  className="list-table-col"
                  style={{
                    width: 120,
                  }}
                >
                  <div className="d-flex align-items-center mt-3">
                    <$Switch
                      size="small"
                      name="internalNote"
                      checked={values.internalNote}
                      disabled={!isEditableUserNote(data, azureUser?.unique_name)}
                    />
                    <Paragraph ellipsis className="mb-0 ml-2">
                      {t("US.COLLECTION.CASE:NOTESANDMESSAGES.INTERNAL")}
                    </Paragraph>
                  </div>
                </div>
                <div className="list-table-col">
                  <div className="d-flex align-items-center mt-3">
                    <$Switch
                      size="small"
                      name="copyToDebNotes"
                      checked={values.copyToDebNotes}
                      disabled={!isEditableUserNote(data, azureUser?.unique_name)}
                    />
                    <Paragraph ellipsis className="mb-0 ml-2">
                      {t(
                        "US.COLLECTION.CASE:NOTESANDMESSAGES.COPYASDEBTORNOTES"
                      )}
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Popconfirm
                title={t("US.COLLECTION.CASE:MANAGE_AGREEMENTS.SURE_TO_DELETE")}
                okText={t("US.COMMON:COMMON.YES")}
                cancelText={t("US.COMMON:COMMON.NO")}
                onConfirm={() => noteDelete()}
                placement="right"
                disabled={
                  !isEditableUserNote(data, azureUser?.unique_name) || isNoteDeleting
                }
              >
                <$Button
                  className="mr-2"
                  loading={isNoteDeleting}
                  disabled={
                    !isEditableUserNote(data, azureUser?.unique_name) || isNoteDeleting
                  }
                  danger
                  type="primary"
                >
                  {t("US.COLLECTION.COMMON:COMMON.DELETE")}
                </$Button>
              </$Popconfirm>
              {noteType != NoteType.CP_NOTE && (
                <$Button
                  className="mr-2"
                  disabled={!isEditableUserNote(data, azureUser?.unique_name)}
                  type="primary"
                  onClick={handleSubmit}
                >
                  {t("US.COLLECTION.COMMON:COMMON.SAVE")}
                </$Button>
              )}
              <$Button onClick={() => close()}>
                {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
              </$Button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common, domainView, logHistory } = state;
  const { currentLanguage, currentDateFormat } = common;
  const { metaData } = domainView;
  const { isNoteDeleting } = logHistory;
  return {
    currentLanguage,
    currentDateFormat,
    isNoteDeleting,
    metaData,
  };
};

const mapDispatchToProps = {
  edit: note.save,
  setNotifyStatus: noteStatus.set,
  deleteNote: deleteNote.delete,
};
export default connect(mapStateToProps, mapDispatchToProps)(Note);
