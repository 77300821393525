import { ExistingPartpayment } from 'us.collection.partpayments/reducers/AddPartPayment/State/ExistingPartPayment'

export const AddPartPaymentInitialState = {
    finalSettlement: false,
    remainingBalance: 0.00,
    caseRemainingBalance: 0,
    partPaymentDate: "",
    firstInstallmentDate: "",
    caseNote: "",
    selectPeriod: "Month",
    comunicationMethod: "SMS",
    smsNo: "",
    email: "",
    smsNumber: "",
    emailAddress: "",
    installmentAmount: 0.00,
    noOfInstallment: 0,
    noOfInstallmentDuplicate: 0,
    installmentAmountBMD: 0,
    noOfInstallmentBMD: 0,
    forwardChargOrCosts: "forwardCharge",
    forwardCharge: 0.00,
    forwardChargeBMD: 0.00,
    minInstallmentsForCostBMD: 0,
    existingPartPayment: ExistingPartpayment,
    minRemainingBalanceForCost: 0
}