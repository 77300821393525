import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';

import Common from 'us.common';
import { SearchOutlined, PlusOutlined, FilterOutlined } from 'us.icons';
import { CourtSentenceActions } from 'us.collection.case/actions';
import { $AmountLabel, $DateLabel } from 'us.common/components';
import { SentneceDrawerStatus } from 'us.collection.case/constants';
import * as DashboardActions from 'us.collection.case/actions';
import { GridMoreColumn } from './Components';
import { ISortOrder } from './Interface';
import { drawerStateHandler } from 'us.collection.case/functions';
import { AddCourtSentence } from './Components/AddCourtSentence';
import { Formik } from 'formik';
import moment from 'moment';
import { RootState } from 'us.helper/types';

const {
	$Button,
	$PageHeader,
	$Divider,
	$Affix,
	$Input,
	$Skeleton,
	$Table,
	$Popover,
	$Drawer,
} = Common.Components;

const Sentences: React.FC<PropsFromRedux> = (props) => {
	const {
		getSentenceSummery,
		metaData,
		isMetaDataLoading,
		sentenceSummeryData,
		isSentenceSummeryLoading,
		sentenceDeleteAtempt,
		openDrawer,
		isDrawerVisible,
		drawerName,
		getAccountSummeryWidgetData,
		currentDateFormat,
	} = props;

	const { caseId, caseNo } = metaData ?? {};

	const { t } = useTranslation();
	const history = useHistory();
	const { location } = history;
	const [searchText, setSearchText] = useState<string>('');
	const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
		columnKey: '',
		order: '',
	});

	useEffect(() => {
		const { caseId } = metaData ?? {};
		if (caseId && getSentenceSummery) {
			getSentenceSummery({ caseId });
		}
	}, [metaData]);

	/**
	 * To get the search popup UI
	 * @param dataIndex index of the record
	 * @param title Title of the popup
	 * @returns JSX element of the search popup
	 */
	const getColumnSearchProps = (dataIndex: string, title: string) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}: any) => (
			<div style={{ padding: 8 }}>
				<$Input
					name='filterInput'
					placeholder={`${t(
						'US.COLLECTION.COMMON:COMMON.SEARCH'
					)} ${title}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(
							e.target.value
								? [
										e
											.target
											.value,
								  ]
								: []
						)
					}
					onPressEnter={() =>
						handleSearch(
							selectedKeys,
							confirm
						)
					}
					style={{
						width: 188,
						marginBottom: 8,
						display: 'block',
					}}
				/>
				<$Button
					type='primary'
					onClick={() =>
						handleSearch(
							selectedKeys,
							confirm
						)
					}
					icon={<SearchOutlined />}
					size='small'
					style={{ width: 90, marginRight: 8 }}>
					{t(
						'US.COLLECTION.COMMON:COMMON.SEARCH'
					)}
				</$Button>
				<$Button
					onClick={() =>
						handleReset(clearFilters)
					}
					size='small'
					style={{ width: 90 }}>
					{t('US.COLLECTION.COMMON:COMMON.RESET')}
				</$Button>
			</div>
		),
		filterIcon: (filtered: string) => (
			<FilterOutlined
				style={{
					color: filtered ? '#1890ff' : undefined,
				}}
			/>
		),
		onFilter: (value: string, record: any) =>
			moment.isMoment(record[dataIndex])
				? record[dataIndex]
						.format(currentDateFormat)
						.includes(value)
				: record[dataIndex]
						.toString()
						.toLowerCase()
						.includes(value.toLowerCase()),
		render: (text: string) => (
			<Highlighter
				highlightStyle={{
					backgroundColor: '#ffc069',
					padding: 0,
				}}
				searchWords={[searchText]}
				autoEscape
				textToHighlight={text ? text.toString() : ''}
			/>
		),
	});

	/**
	 * handler for the triggered filter event
	 * @param selectedKeys FilterDropdownProps.selectedKeys
	 * @param confirm FilterDropdownProps.confirm
	 */
	const handleSearch = (selectedKeys: any, confirm: any) => {
		confirm();
		setSearchText(selectedKeys[0]);
	};

	/**
	 * handler for the triggered rest
	 * @param clearFilters FilterDropdownProps.clearFilters
	 */
	const handleReset = (clearFilters: any) => {
		clearFilters();
		setSearchText('');
	};

	const columns: any = [
		{
			title: '',
			key: 'more',
			dataIndex: 'more',
			width: 50,
			render: (text: any, record: any, index: number) => {
				return (
					<GridMoreColumn
						record={record}
						index={index}
					/>
				);
			},
		},
		{
			title: t('US.COLLECTION.CASE:SENTENCES.SENTENCEDATE'),
			dataIndex: 'sentenceDate',
			width: 200,
			key: 'sentenceDate',
			className: 'text-nowrap',
			sorter: (a: any, b: any) =>
				a.sentenceDate & b.sentenceDate
					? new Intl.Collator().compare(
							a.sentenceDate.toISOString(),
							b.sentenceDate.toISOString()
					  )
					: -1,
			sortOrder:
				sortedInfo.columnKey === 'sentenceDate' &&
				sortedInfo.order,
			...getColumnSearchProps(
				'sentenceDate',
				t('US.COLLECTION.CASE:SENTENCES.SENTENCEDATE')
			),
			render: (text: any) => {
				return <$DateLabel value={text} />;
			},
		},
		{
			title: t('US.COLLECTION.CASE:SENTENCES.TYPE'),
			dataIndex: 'sentenceType',
			width: 250,
			key: 'sentenceType',
			className: 'text-nowrap',
			sorter: (a: any, b: any) =>
				a.sentenceType.localeCompare(b.sentenceType),
			sortOrder:
				sortedInfo.columnKey === 'sentenceType' &&
				sortedInfo.order,
			...getColumnSearchProps(
				'sentenceType',
				t('US.COLLECTION.CASE:SENTENCES.TYPE')
			),
			render: (text: any) => {
				return text;
			},
		},
		{
			title: t('US.COLLECTION.CASE:SENTENCES.PRINCIPALSUM'),
			dataIndex: 'mainAmount',
			width: 250,
			key: 'mainAmount',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			sorter: (a: any, b: any) =>
				new Intl.Collator().compare(
					a.mainAmount,
					b.mainAmount
				),
			sortOrder:
				sortedInfo.columnKey === 'mainAmount' &&
				sortedInfo.order,
			...getColumnSearchProps(
				'mainAmount',
				t('US.COLLECTION.CASE:SENTENCES.PRINCIPALSUM')
			),
			render: (text: any) => {
				return <$AmountLabel value={text} />;
			},
		},
		{
			title: t('US.COLLECTION.CASE:SENTENCES.MODIFIED_ON'),
			dataIndex: 'modifiedDate',
			width: 300,
			key: 'modifiedDate',
			className: 'text-nowrap',
			sorter: (a: any, b: any) =>
				a.modifiedDate & b.modifiedDate
					? new Intl.Collator().compare(
							a.modifiedDate.toISOString(),
							b.modifiedDate.toISOString()
					  )
					: -1,
			sortOrder:
				sortedInfo.columnKey === 'modifiedDate' &&
				sortedInfo.order,
			...getColumnSearchProps(
				'modifiedDate',
				t('US.COLLECTION.CASE:SENTENCES.MODIFIED_BY')
			),
			render: (text: any, record: any) => {
				return (
					<>
						{record.modifiedDate && (
							<span>
								<$DateLabel
									value={
										record.modifiedDate
									}
								/>
							</span>
						)}
						{record.modifiedUser && (
							<small className='bui-label d-block'>
								{t(
									'US.COLLECTION.CASE:SENTENCES.BY'
								)}
								{' - '}
								{
									record.modifiedUser
								}
							</small>
						)}
					</>
				);
			},
		},
		{
			title: t('US.COLLECTION.CASE:SENTENCES.NOTE'),
			dataIndex: 'note',
			key: 'note',
			className: 'text-nowrap',
			width: 300,
			ellipsis: true,
			sorter: (a: any, b: any) =>
				a.note.localeCompare(b.note),
			sortOrder:
				sortedInfo.columnKey === 'note' &&
				sortedInfo.order,
			...getColumnSearchProps(
				'note',
				t('US.COLLECTION.CASE:SENTENCES.NOTE')
			),
			render: (text: any) => {
				return (
					<$Popover
						content={
							<div className='add-new-sentence-note-popover'>
								{text}
							</div>
						}
						title={t(
							'US.COLLECTION.CASE:SENTENCES.NOTE'
						)}
						trigger='hover'
						placement='topRight'>
						<span>{text}</span>
					</$Popover>
				);
			},
		},
	];

	/**
	 * To navigate back to the dasnboard.
	 */
	const navigateToDashboard = () => {
		getAccountSummeryWidgetData &&
			getAccountSummeryWidgetData({
				EntityType: 'Case',
				EntityId: caseId,
			});
		history.push({ ...location, pathname: `/case/${caseNo}` });
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{}}
			onSubmit={() => {}}>
			<div className='space-content'>
				<$Affix offsetTop={80}>
					<div className='page-header header-border'>
						<div className='d-flex flex-row align-items-center justify-content-between'>
							<div
								className='d-flex align-items-center'
								data-testid='sentence-header'>
								<$PageHeader
									className='px-0'
									title={t(
										'US.COLLECTION.CASE:SENTENCES.SENTENCES'
									)}
									onBack={() =>
										navigateToDashboard()
									}
								/>
								<$Divider
									className='bui-devider'
									type='vertical'
								/>
								<$Button
									type='dashed'
									data-testid='add-new-sentence-button'
									size='small'
									onClick={() =>
										drawerStateHandler(
											openDrawer,
											true,
											'US.COLLECTION.CASE:SENTENCES.ADDLEGALDECISION',
											SentneceDrawerStatus.ADD_SENTENCE_OPEN
										)
									}
									icon={
										<PlusOutlined />
									}>
									{t(
										'US.COLLECTION.CASE:SENTENCES.NEWCOURTSENTENCE'
									)}
								</$Button>
							</div>
						</div>
					</div>
				</$Affix>

				<$Skeleton
					loading={
						isSentenceSummeryLoading ||
						isMetaDataLoading
					}
					active
					paragraph={{ rows: 2 }}>
					<$Table
						onRow={(record) => {
							return {
								onDoubleClick:
									() =>
										drawerStateHandler(
											openDrawer,
											true,
											'US.COLLECTION.CASE:SENTENCES.EDIT_LEGAL_DECISION',
											SentneceDrawerStatus.EDIT_SENTENCE_OPEN,
											record.id
										),
							};
						}}
						onChange={(
							pagination: any,
							filters: any,
							sorter: any
						) => setSortedInfo(sorter)}
						rowKey='id'
						dataSource={sentenceSummeryData}
						size='small'
						className='mt-3'
						scroll={{ x: 1200 }}
						bordered
						pagination={{
							defaultPageSize: 20,
						}}
						columns={columns}
						loading={
							sentenceDeleteAtempt.isLoading
						}
					/>
				</$Skeleton>

				<$Drawer //Add Sentences
					title={t(drawerName)}
					width={900}
					visible={isDrawerVisible}
					onClose={() =>
						drawerStateHandler(
							openDrawer,
							false
						)
					}>
					<AddCourtSentence
						closeDrawer={() =>
							drawerStateHandler(
								openDrawer,
								false
							)
						}
					/>
				</$Drawer>
			</div>
		</Formik>
	);
};

const { summery } = CourtSentenceActions;

const mapStateToProps = (state: RootState) => {
	const { courtSentence, dashboard, common, domainView } = state;
	const { sentenceSummery, sentenceDeleteAtempt, drawerStatus } =
		courtSentence;
	const { accountSummeryWidgetData } = dashboard;
	const { currentDateFormat } = common;
	const { metaData } = domainView;

	return {
		isSentenceSummeryLoading: sentenceSummery.isLoading,
		sentenceSummeryData: sentenceSummery.data,
		isMetaDataLoading: metaData.isLoading,
		metaData: metaData.data,
		sentenceDeleteAtempt,
		drawerName: drawerStatus.data?.name ?? '',
		isDrawerVisible: drawerStatus.data?.isVisible ?? false,
		isLegalCase: accountSummeryWidgetData?.data?.case?.isLegal,
		currentDateFormat,
	};
};

const { AccountSummeryWidgetActions } = DashboardActions;
const { accountSummery } = AccountSummeryWidgetActions;
const mapDispatchToProps = {
	getSentenceSummery: summery.get,
	openDrawer: summery.openDrawer,
	getAccountSummeryWidgetData: accountSummery.get,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Sentences);
