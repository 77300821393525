import { ICaseAmountsDetails } from "us.collection.case/interfaces";

/**
 * To get the legal decision type options
 * @param caseAmountDetails The case amout details from the API
 * @returns legal decision type options drop down values
 */
export const getLegalDecisitonTypeOptions = (
  caseAmountDetails: ICaseAmountsDetails | undefined
): Array<any> => {
  let returnCaseAmountsDetails:Array<any> = [];
  try {
    returnCaseAmountsDetails =  caseAmountDetails
    ? caseAmountDetails.legalDecisionTypes.map(({ id, displayValue }) => ({
        label: displayValue,
        value: id,
      }))
    : [];
  } catch (error) {
    console.error(error);
  }
  return returnCaseAmountsDetails;
};
