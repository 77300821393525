import { IExcleColumn } from 'us.collection/components/ExcelGenerator/Interface';

export const excelColumns: Array<IExcleColumn> = [
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REF_NO',
		dataIndex: 'invoiceNumber',
		format: 'Text',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TYPE',
		dataIndex: 'type',
		format: 'Text',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.ORDER_DATE',
		dataIndex: 'orderDate',
		format: 'Date',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.VOUCHERNO',
		dataIndex: 'voucherID',
		format: 'Text',
		config: { voucherID: '!=0' },
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.VOUCHERDATE',
		dataIndex: 'voucherDate',
		format: 'Date',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.DUEDATE',
		dataIndex: 'dueDate',
		format: 'Date',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REGDATE',
		dataIndex: 'regDate',
		format: 'Date',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.CASENO',
		dataIndex: 'caseNo',
		format: 'Text',
		config: { caseNo: '!=0' },
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.PAYMENTID',
		dataIndex: 'paymentID',
		format: 'Text',
		config: { paymentID: '!=-1' },
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.INTERESTAMOUNT',
		dataIndex: 'interestAmount',
		format: 'Currency',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.AMOUNT',
		dataIndex: 'amount',
		format: 'Currency',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.BALANCE',
		dataIndex: 'balance',
		format: 'Currency',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.CREATEDBY',
		dataIndex: 'createdBy',
		format: 'Text',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.DESCRIPTION',
		dataIndex: 'description',
		format: 'Text',
	},
];

export const invalidDate = '0001-01-01T00:00:00';

export enum TransactionFilterTypes {
	INVOICE = 'Invoice',
	DIRECT_PAYMENT = 'Direct Payment',
	AR = 'AR',
	COURT = 'Court',
	CLIENT_ACC_PAYMENT = 'Client Account Payment',
	INTEREST = 'Interest',
	COST = 'Cost',
	COLLECTION = 'Collection',
	OTHER = 'Other',
	CANCELLATION = 'Cancellation',
}

export enum PopOverMenuTypes {
	MAPPING = 97,
	CANCELLATION = 98,
	DELETE = 99,
	COURT_FEE = 100,
	EDITINTEREST = 101,
}

export enum TransactionType {
	DB = 'DB',
}

export enum TransactionGroup {
	INTEREST = 'Interest',
}
