import {
  CaseGroupByDebtor,
  CreditorCaseList,
  CreditorCaseState,
  CreditorModifiedCaseList,
  FilterValues,
  ExcelExportType,
} from "us.collection.creditor/components/CreditorCases/Interfaces";
import { IOnFilter, IOnSort } from "us.common/components";
import { appInsights } from "us.helper";
import {
  ColumnType,
  DEFAULT_MODIFIED_LIST,
  EntityType,
  STANDARD_DATE_FORMAT,
} from "us.collection.creditor/components/CreditorCases/Constants";
import moment, { Moment } from "moment";

/**
 * @description group caseList by debtor
 * @param {CreditorCaseList[]} caseList creditor case list
 * @param {filterValues} filterValues filter Criteria
 * @returns {CreditorCaseState} modified creditor case list
 */

export const getCaseList = (
  caseList: CreditorCaseList[],
  filterValues: FilterValues
): CreditorCaseState => {
  try {
    if (caseList.length > 0) {
      const filteredList = getFilteredCaseList(caseList, filterValues);
      const groupedList = getGroupedCaseList(filteredList);
      const caseCount: number = filteredList.length;
      const mainAmount: number = getTotalValue(
        filteredList,
        ColumnType.MAIN_AMOUNT
      );
      const totalBalance: number = getTotalValue(
        filteredList,
        ColumnType.TOTAL_BALANCE
      );
      const modifiedList = getModifiedCaseList(groupedList);
      return {
        modifiedList,
        caseCount,
        mainAmount,
        totalBalance,
      };
    } else {
      return DEFAULT_MODIFIED_LIST;
    }
  } catch (error) {
    return DEFAULT_MODIFIED_LIST;
  }
};

/**
 * @description get excel export list format by modified case list
 * @param {CreditorCaseState} caseList creditor case list
 * @param {any} currentDateFormat current Date Format
 * @returns {ExcelExportType[]} modified creditor case list
 */

export const getExcelExportList = (
  caseList: CreditorCaseState,
  currentDateFormat: any
): ExcelExportType[] => {
  try {
    if (caseList) {
      const { caseCount, mainAmount, totalBalance, modifiedList } = caseList;

      const excelFormattedList: ExcelExportType[] = [];

      modifiedList.map((debtorInfo: any) => {
        const { custId, debtorName, totalAmount, totalBalance } = debtorInfo;
        debtorInfo.children.map((caseInfo: any) => {
          const {
            exCaseNo,
            refs,
            mainAmount,
            balance,
            kiDs,
            voucherDates,
            regDates,
          } = caseInfo;
          excelFormattedList.push({
            exCaseNo: exCaseNo.toString(),
            debtorNameOrNumber: custId + " - " + debtorName,
            regDate:regDates[0]? moment(regDates[0], "DD/MM/YYYY").format(currentDateFormat).toString():"",
            voucherDate: voucherDates[0]? moment(voucherDates[0], "DD/MM/YYYY").format(currentDateFormat).toString():"",
            invoiceNo: refs[0]?.toString(),
            invoiceKid: kiDs[0]?.toString(),
            mainAmount: mainAmount,
            totalBalance: balance,
          });
        });
        excelFormattedList.push({
          exCaseNo: "Sub Total",
          debtorNameOrNumber: "",
          regDate: "",
          voucherDate: "",
          invoiceNo: "",
          invoiceKid: "",
          mainAmount: totalAmount,
          totalBalance: totalBalance,
        });
      });
      excelFormattedList.push({
        exCaseNo: `Total (${caseCount} Cases)`,
        debtorNameOrNumber: "",
        regDate: "",
        voucherDate: "",
        invoiceNo: "",
        invoiceKid: "",
        mainAmount: mainAmount,
        totalBalance: totalBalance,
      });
      return excelFormattedList;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

/**
 * @description group caseList by debtor
 * @param {CreditorCaseList[]} caseList creditor case list
 * @returns {ICaseGroupByDebtor[]} modified creditor case list
 */
export const getGroupedCaseList = (
  caseList: CreditorCaseList[]
): CaseGroupByDebtor[] => {
  try {
    if (caseList.length > 0) {
      const groupedData = caseList.reduce(
        (result: any, item: CreditorCaseList) => {
          const { custId, debtorName, debtorAddress, ...rest } = item;

          const key = `${custId}-${debtorName}`;

          if (!result[key]) {
            result[key] = {
              key,
              custId,
              debtorName,
              debtorAddress,
              children: [],
            };
          }
          result[key].children.push({ ...rest });
          return result;
        },
        {}
      );
      return Object.values(groupedData);
    } else return [];
  } catch (error) {
    return [];
  }
};

/**
 * @description get modified case list with total amount and total balance for each record
 * @param {ICaseGroupByDebtor[]} caseList creditor grouped case list
 * @returns {ICreditorModifiedCaseList[]} modified creditor case list
 */
export const getModifiedCaseList = (
  caseList: CaseGroupByDebtor[]
): CreditorModifiedCaseList[] => {
  try {
    if (caseList.length > 0) {
      return caseList.map((caseItem: any) => {
        const { children } = caseItem;
        const totalBalance = getTotalValue(children, ColumnType.TOTAL_BALANCE);
        const totalAmount = getTotalValue(children, ColumnType.MAIN_AMOUNT);
        return { ...caseItem, totalAmount, totalBalance };
      });
    } else return [];
  } catch (error) {
    return [];
  }
};

/**
 * @function
 * @description - expanded rows keys
 * @param dataSource {Array<any>} - table data source
 * @param expandedRows {Array<any>} - expanded row's keys array
 * @param rowKey {number} - currently expanded/collapsed clicked row
 * @param isExpand {boolean} - expand or collapse
 * @param isAll {boolean} - whether all the rows are collapse
 * @returns expanded row keys
 */
export const handleExpandedRows = (
  dataSource: Array<any>,
  expandedRows: Array<any>,
  rowKey: number,
  isExpand: boolean,
  isAll?: boolean
): Array<any> => {
  try {
    if (isAll) {
      return dataSource?.map((item: any) => {
        return item.key;
      });
    } else if (typeof isAll != "undefined" && !isAll) {
      return [];
    } else {
      if (isExpand) {
        return [...expandedRows, rowKey];
      } else {
        return expandedRows.filter(
          (expandedRow: number) => expandedRow != rowKey
        );
      }
    }
  } catch (error) {
    return [];
  }
};

/**
 * @function
 * @description - sorting function on table tree
 * @param sortData - data sorting function
 * @param dataSource - data source with children data
 * @returns sorted data
 */
export const handleSortGroup: IOnSort = (sortData, dataSource) => {
  try {
    return sortData(
      dataSource.map((dataItem: any) => {
        return {
          ...dataItem,
          children: sortData(dataItem.children),
        };
      })
    );
  } catch (error) {
    appInsights.trackException(`creditor sorting Exception - ${error}`);
    return dataSource;
  }
};

/**
 * @function
 * @description - filtering function on table tree
 * @param searchData - data searching function
 * @param dataSource - data source with children data
 * @returns filtered data
 */
export const handleFilterGroup: IOnFilter = (searchData, dataSource) => {
  const result: any[] = [];
  try {
    dataSource.flatMap((dataItem: any) => {
      searchData(dataItem?.children)?.length != 0 &&
        result.push({
          ...dataItem,
          children:
            searchData(dataItem?.children)?.length == 0
              ? []
              : searchData(dataItem?.children),
        });
    });
    return result;
  } catch (error) {
    appInsights.trackException(`creditor filtering Exception - ${error}`);
  }
  return result;
};

/**
 * @function
 * @description - get total count
 * @param {CreditorCaseList } data - case List
 * @param {string} type - amount type to be calculated
 * @returns {number} total amount
 */
export const getTotalValue = (
  caseList: CreditorCaseList[],
  type: string
): number => {
  try {
    if (caseList.length > 0) {
      return caseList.reduce(
        (total, item) =>
          total +
          (type === ColumnType.MAIN_AMOUNT ? item.mainAmount : item.balance),
        0
      );
    } else return 0;
  } catch (error) {
    return 0;
  }
};

/**
 * @function
 * @description Determine if the case should be included based on the filter criteria
 * @param {ICreditorCaseList} caseList list to be filtered
 * @param {FilterValues} filterValues filter criteria
 * @returns {boolean} the boolean decision if the case should be included
 */
export const doesCaseInclude = (
  {
    debtorName,
    caseType,
    custId,
    mainAmount,
    refs,
    kiDs,
    regDates,
    voucherDates,
  }: CreditorCaseList,
  {
    debtorNameOrNumber,
    caseType : filterCaseType,
    regDate: filterRegDate,
    voucherDate: filterVoucherDate,
    invoiceRef,
    invoiceKid: filterInvoiceKid,
    mainAmountFrom,
    mainAmountTo,
  }: FilterValues
): boolean => {
  try {
    return (
      (debtorNameOrNumber
        ? isStringInclusive(debtorName?.toUpperCase(), debtorNameOrNumber) ||
          isStringInclusive(custId.toString(), debtorNameOrNumber)
        : true) &&
      (filterCaseType ? (filterCaseType === EntityType.ALL?true: (filterCaseType === caseType)) : true) &&  
      (filterVoucherDate
        ? isDateInclusive(voucherDates, filterVoucherDate)
        : true) &&
      (filterRegDate ? isDateInclusive(regDates, filterRegDate) : true) &&
      (invoiceRef ? isNumberInclusive(refs, invoiceRef) : true) &&
      (filterInvoiceKid ? isNumberInclusive(kiDs, filterInvoiceKid) : true) &&
      (mainAmountFrom ? mainAmount >= mainAmountFrom : true) &&
      (mainAmountTo ? mainAmount <= mainAmountTo : true)
    );
  } catch (error) {
    return false;
  }
};

/**
 * @function
 * @description check the string is inclusive or not
 * @param {Array<string>} listItem string list to be filtered
 * @param {string} stringItem2 filter criteria
 * @returns {boolean} the boolean decision if the 1st string is inclusive in second string
 */

const isNumberInclusive = (listItem: Array<string>, stringItem: string) => {
  try {
    return listItem?.join(" ").includes(stringItem);
  } catch (error) {
    return false;
  }
};

/**
 * @function
 * @description check the string is inclusive or not
 * @param {string} stringItem1 list to be filtered
 * @param {string} stringItem2 filter criteria
 * @returns {boolean} the boolean decision if the 1st string is inclusive in second string
 */

const isStringInclusive = (stringItem1: string, stringItem2: string) => {
  try {
    return stringItem1?.includes(stringItem2.toUpperCase());
  } catch (error) {
    return false;
  }
};

/**
 * @function
 * @description check the date is inclusive or not
 * @param {string} dateListItem list to be filtered
 * @param {Moment} dateItem filter criteria
 * @returns {boolean} the boolean decision if the 1st string is inclusive in second string
 */

const isDateInclusive = (dateListItem: Array<string>, dateItem: Moment) => {
  try {
    return dateListItem
      ?.join(" ")
      .includes(dateItem?.format(STANDARD_DATE_FORMAT).toString());
  } catch (error) {
    return false;
  }
};

/**
 * @function
 * @description get filtered case list
 * @param { CreditorCaseList[]} caseList initial case list
 * @param {FilterValues} filterValues filter criteria
 * @returns {CreditorCaseList[]} filtered case list
 */
const getFilteredCaseList = (
  caseList: CreditorCaseList[],
  filterValues: FilterValues
): CreditorCaseList[] => {
  try {
    if (caseList.length > 0) {
      return  caseList.filter((listItem) =>
        doesCaseInclude(listItem, filterValues)
      );
    } else return [];
  } catch (error) {
    return caseList;
  }
};
