import * as Yup from "yup";

interface Calendar extends Yup.MixedSchema {
  calendarId: number;
  calendarName: string;
  nonProductionDays: Array<any>;
  previousCalendars: Array<any>;
  selectedNonProductionDate: any;
  smsAllowedTime: Array<any>;
}

const CalendarScehama = Yup.object<Calendar>().shape({
  calendarName: Yup.string().required("Add a value into the field."),
  nonProductionDays: Yup.array().of(
    Yup.object().shape({
      date: Yup.date()
        .required("Date is required.")
        .typeError("Date is required."),
      reason: Yup.string().required("Reason is required."),
    })
  ),
  smsAllowedTime: Yup.array().of(
    Yup.object().shape({
      timeSlots: Yup.array().of(
        Yup.object().shape({
          startTime: Yup.date().required("Start time is required."),
          endTime: Yup.date()
            .required("End time is required.")
            .when(
              "startTime",
              (startTime: any, Yup: any) =>
                startTime &&
                Yup.min(startTime, "End time should be before start time.")
            ),
        })
      ),
    })
  )
});

export default CalendarScehama;
