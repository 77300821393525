import { call, put, takeLatest } from 'redux-saga/effects';
import * as API from 'us.collection.transactions/services';
import { CasesAndSubCasesAction } from 'us.collection.transactions/actions';
import { MainSearchActions } from "us.common/actions"
import Constants, { DrawerType } from 'us.collection.transactions/constants';
import { $MessageBox } from 'us.common/components';
import { ICase, ISaveResponse } from 'us.collection.transactions/interfaces';
import { ArrangeToCasesAndSubCases } from './Functions';

const { ACTIONS } = Constants;
const { CasesAndSubCases } = ACTIONS;
const { caseList, mergeCases, createCases, dueDate, splitSubCases } =
	CasesAndSubCasesAction;
const { history } = MainSearchActions;

const CasesAndSubCasesSagas = {
	get: function* (action: any) {
		const params = action.payload.data;
		try {
			const caseListResponse: ICase[] = yield call(
				API.CasesAndSubCases.get,
				params.creditorId,
				params.debtorId,
				params.arId,
				params.isSimilarDebtor,
				params.returnTransferredSubCases
			);
			if (Array.isArray(caseListResponse)) {
				yield put(
					caseList.success(
						ArrangeToCasesAndSubCases(
							caseListResponse
						)
					)
				);
			} else {
				yield put(caseList.success([]));
			}
		} catch (error) {
			yield put(caseList.fail(error as any));
		}
	},
	create: function* (action: any) {
		const params = action.payload.data;
		try {
			const data: ISaveResponse = yield call(
				API.CasesAndSubCases.create,
				params
			);

			if (!data?.isError) {
				yield put(createCases.success(data));
				if (caseList.openDrawer) {
					yield put(
						caseList.openDrawer({
							title: '',
							type: DrawerType.CREATE_CASES,
							isOpen: false,
						})
					);
				}
				$MessageBox(
					'success',
					'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.SUCCESSFULLY_CREATED',
					'',
					''
				);

				if (caseList.get)
					yield put(
						caseList.get({
							creditorId: params.creditorId,
							debtorId: params.debtorId,
							arId: params.arNo,
							isSimilarDebtor: false,
							returnTransferredSubCases:
								true,
						})
					);
			} else {
				yield put(createCases.fail(data));
				$MessageBox('error', data.errorMessage, '', '');
			}
		} catch (error) {
			yield put(createCases.fail(error as any));
			$MessageBox(
				'error',
				'US.COLLECTION.COMMON:COMMON.UPDATE_FAILED',
				'',
				''
			);
		}
	},

	merge: function* (action: any) {
		const { arNo, creditorId, debtorId, ...rest } =
			action.payload.data;
		try {
			const data: ISaveResponse = yield call(
				API.CasesAndSubCases.merge,
				{ ...rest, arNo }
			);

			if (!data?.isError) {
				yield put(mergeCases.success(data));
				if(history.get)
				yield put(history.get({}))
				
				if (caseList.openDrawer) {
					yield put(
						caseList.openDrawer({
							title: '',
							type: DrawerType.MERGE_CASES,
							isOpen: false,
						})
					);
				}
				$MessageBox(
					'success',
					'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.SUCCESSFULLY_MERGED',
					'',
					''
				);

				if (caseList.get)
					yield put(
						caseList.get({
							creditorId: creditorId,
							debtorId: debtorId,
							arId: arNo,
							isSimilarDebtor: false,
							returnTransferredSubCases:
								true,
						})
					);
			} else {
				yield put(mergeCases.fail(data));
				$MessageBox('error', data.errorMessage, '', '');
			}
		} catch (error) {
			yield put(mergeCases.fail(error as any));
			if ((error as any).errorMessage) {
				$MessageBox(
					'error',
					(error as any).errorMessage,
					'',
					''
				);
			} else {
				$MessageBox(
					'error',
					'US.COLLECTION.COMMON:COMMON.MERGE_CASE_FAILED',
					'',
					''
				);
			}
		}
	},

	updatedueDate: function* (action: any) {
		const params = action.payload.data;
		try {
			const data: ISaveResponse = yield call(
				API.CasesAndSubCases.updateduedate,
				params
			);
			if (!data?.isError) {
				yield put(dueDate.success(data));
				$MessageBox(
					'success',
					'US.COLLECTION.COMMON:COMMON.UPDATED_SUCCESSFULLY',
					'',
					''
				);
			} else {
				yield put(dueDate.fail(data as any));
				$MessageBox('error', data.errorMessage, '', '');
			}
		} catch (error) {
			yield put(dueDate.fail(error as any));
			$MessageBox(
				'error',
				'US.COLLECTION.COMMON:COMMON.UPDATE_FAILED',
				'',
				''
			);
		}
	},

	split: function* (action: any) {
		const params = action.payload.data;
		try {
			const data: ISaveResponse = yield call(
				API.CasesAndSubCases.split,
				params
			);

			if (!data?.isError) {
				yield put(splitSubCases.success(data));
				$MessageBox(
					'success',
					'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.SUCCESSFULLY_SPLITTED_CASE',
					'',
					''
				);

				if (caseList.get)
					yield put(
						caseList.get({
							creditorId: params.creditorId,
							debtorId: params.debtorId,
							arId: params.arNo,
							isSimilarDebtor: false,
							returnTransferredSubCases:
								true,
						})
					);
			} else {
				yield put(splitSubCases.fail(data as any));
				$MessageBox('error', data.errorMessage, '', '');
			}
		} catch (error) {
			yield put(splitSubCases.fail(error as any));
			$MessageBox(
				'error',
				'US.COLLECTION.COMMON:COMMON.UPDATE_FAILED',
				'',
				''
			);
		}
	},
};

export default [
	takeLatest(
		CasesAndSubCases.GET_CASES_AND_SUBCASES,
		CasesAndSubCasesSagas.get
	),
	takeLatest(CasesAndSubCases.CREATE_CASES, CasesAndSubCasesSagas.create),
	takeLatest(
		CasesAndSubCases.SAVE_MERGECASES,
		CasesAndSubCasesSagas.merge
	),
	takeLatest(
		CasesAndSubCases.UPDATE_DUEDATE,
		CasesAndSubCasesSagas.updatedueDate
	),
	takeLatest(
		CasesAndSubCases.SPLIT_SUBCASES,
		CasesAndSubCasesSagas.split
	),
];
