export enum DebtorFilterBy {
  NAME = "name",
  PERSON_NO = "personNo",
  ZIP_CODE = "zipCode",
}

export const DEFAULT_SIMILAR_DEBTOR_FILTERS = {
  isSimilarDebtor: false,
  filterBy: [
    DebtorFilterBy.NAME,
    DebtorFilterBy.PERSON_NO,
    DebtorFilterBy.ZIP_CODE,
  ],
};

export const DEFAULT_SUMMARY_DATA = [
  {
    mainAmount: "0.00",
    courtAmount: "0.00",
    collectionFee: "0.00",
    otherAmount: "0.00",
    caseCost: "0.00",
    interestAmount: "0.00",
    paidAmount: "0.00",
    balance: "0.00",
    courtFee: "0.00",
    otherCost: "0.00",
  },
];

export const DEFAULT_TABLE_INFO = { expandKeys: [], isExpandColumns: false };

export enum DebtorInfoReportStatus {
  ALL = "All",
  REPORTED = "Reported",
  NOT_REPORTED = "NotReported",
}

export enum DebtorType {
  PERSON = "P",
  COMPANY = "N",
}

export enum DateValues {
  minDate = "0001-01-01",
}
