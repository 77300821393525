import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import Common from 'us.common';
import { InfoCircleOutlined } from 'us.icons';
import { ObjectionAndAgreementsAction } from 'us.collection.case/actions';
import DOValidation from 'us.collection.case/components/Validations/DebtorObjectionValidation';
import { IDebtorObjection } from 'us.collection.case/components/Interfaces/Objection';
import { useTranslation } from 'react-i18next';
import { $Skeleton, $TableTree } from 'us.common/components';
import {
	DebtorObjectionRequest,
	InitialDataDO,
} from 'us.collection.case/repository';
import { IOnFilter, IOnSort } from 'us.common/components';
import { appInsights } from 'us.helper';
import { RootState } from 'us.helper/types';

const {
	$Form,
	$Input,
	$Button,
	$Popconfirm,
	$Row,
	$Col,
	$TextArea,
	$Switch,
	$Typography,
	$Radio,
	$AmountLabel,
} = Common.Components;

const { Text } = $Typography;

const DebtorObjection: React.FC<IDebtorObjection> = (props) => {
	const { t } = useTranslation();

	const [selectedData, setSelectedData] = useState<any>();
	const [selectRowKeys, setSelectRowKeys] = useState<any>([]);
	const [selectReasonId, setSelectReasonId] = useState(0);
	const [isInvoiceSelected, setIsInvoiceSelected] = useState(false);

	const {
		onClose,
		debtorObjectionInitial,
		getInitialData,
		metaData,
		accountSummeryWidgetData,
		caseInfoWidgetData,
		debtorWidgetData,
		save,
	} = props;

	useEffect(() => {
		if (
			caseInfoWidgetData.data &&
			debtorWidgetData.data &&
			accountSummeryWidgetData.data
		) {
			getInitialData(
				InitialDataDO.call({
					caseProps: caseInfoWidgetData?.data,
					caseId: accountSummeryWidgetData.data
						?.case?.caseId,
					debtor: debtorWidgetData.data
						?.debtorDetails,
				})
			);
		}
	}, [debtorWidgetData, caseInfoWidgetData, accountSummeryWidgetData]);

	const handleDebtorObjectionSubmit = (data: any) => {
		try {
			if (
				selectRowKeys?.length === 0 &&
				debtorObjectionInitial?.invoiceNumber === 0
			) {
				setIsInvoiceSelected(true);
			} else {
				let table = [];
				debtorObjectionInitial?.invoiceNumber === 0
					? (table = selectedData)
					: (table =
							debtorObjectionInitial?.invoicetable);

				table = table?.map((row: any) => {
					const {
						invoiceNo,
						kid,
						amount,
						paid,
						balance,
					} = row;
					return {
						invoiceNo,
						kid,
						amount,
						paidAmount: paid,
						balance,
					};
				});
				onClose();

				const requestParam =
					DebtorObjectionRequest.call(
						data,
						accountSummeryWidgetData.data
							?.case?.caseId,
						table,
						selectReasonId
					);
				const requestObject = {
					requestParam,
					metaData: metaData.data,
				};
				save(requestObject);
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard DebtorObjection Submit Exception - ${error}`
			);
		}
	};

	/**
	 * Invoice table validation enable in not row selection
	 */
	const invoiceTableValidation = () => {
		if (
			selectRowKeys?.length === 0 &&
			debtorObjectionInitial?.invoiceNumber === 0
		) {
			setIsInvoiceSelected(true);
		}
	};

	const onSelectChange = (selectedRowKeys: any, selectedRows: any[]) => {
		if (debtorObjectionInitial?.invoiceNumber === 0) {
			setSelectRowKeys(selectedRowKeys);
			setSelectedData(selectedRows);
		} else {
			setSelectRowKeys(debtorObjectionInitial?.invoiceNumber);
		}
	};
	const rowSelection = {
		onChange: onSelectChange,
		selectedRowKeys:
			debtorObjectionInitial?.invoiceNumber === 0
				? selectRowKeys
				: [debtorObjectionInitial?.invoiceNumber],
	};

	const columns: any = [
		{
			title: t(
				'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.INVOICE_NO'
			),
			dataIndex: 'invoiceNo',
			key: 'invoiceNo',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
		},
		{
			title: t('US.COLLECTION.COMMON:COMMON.AMOUNT'),
			dataIndex: 'amount',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return <$AmountLabel value={record?.amount} />;
			},
		},
		{
			title: t(
				'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.PAID'
			),
			dataIndex: 'paid',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return <$AmountLabel value={record?.paid} />;
			},
		},
	];

	const handleSwitchResons = (id: any) => {
		setSelectReasonId(id);
	};

	/**
	 * To get the list of dynamic reasons
	 * @param {(field: string, value: any, shouldValidate?: boolean) => void} setFieldValue - the formik function
	 * @return {*}  {JSX.Element} - the updated reson list as UI elelment
	 */
	const reasons = (
		setFieldValue: (
			field: string,
			value: any,
			shouldValidate?: boolean
		) => void
	): JSX.Element =>
		debtorObjectionInitial?.objectionReasonList?.map(
			({
				reasonId,
				category,
				isCategoryNameShow,
				reason,
			}: {
				reasonId: number;
				category: string;
				isCategoryNameShow: boolean;
				reason: string;
			}) => {
				return (
					<div key={reasonId}>
						{isCategoryNameShow && (
							<div className='mb-2 mt-3'>
								{category}
							</div>
						)}
						<div className='mb-1 pl-3 d-flex align-items-top'>
							<div className='mr-2'>
								<$Switch
									name={
										reason
									}
									id={
										reasonId
									}
									onChange={() => {
										handleSwitchResons(
											reasonId
										);
										setFieldValue(
											'objectionReason',
											reason
										);
									}}
									checked={
										selectReasonId ===
										reasonId
									}
								/>
							</div>
							<div>{reason}</div>
						</div>
					</div>
				);
			}
		);

	const handleEmailEdit = (emailValue: any, restProps: any) => {
		if (
			emailValue?.length === 0 &&
			debtorObjectionInitial?.emailInitial?.length
		) {
			restProps.setFieldValue(
				'emailAddress',
				debtorObjectionInitial?.emailInitial
			);
		}
	};
	const handleSMSEdit = (smsValue: any, restProps: any) => {
		if (
			smsValue?.length === 0 &&
			debtorObjectionInitial?.smsInitial?.length
		) {
			restProps.setFieldValue(
				'smsNumber',
				debtorObjectionInitial?.smsInitial
			);
		}
	};

	const handleSort: IOnSort = (sortData, dataSource) => {
		return sortData(dataSource);
	};

	const handleFilter: IOnFilter = (searchData, dataSource) => {
		return searchData(dataSource);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				...debtorObjectionInitial,
				objectionReason: '',
			}}
			validationSchema={DOValidation}
			onSubmit={handleDebtorObjectionSubmit}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...restProps
			}: any) => (
				<$Form>
					<div className='debtor-objections'>
						<div
							className='bui-label mb-1'
							data-testid='debtor-label'>
							{t(
								'US.COLLECTION.COMMON:COMMON.DEBTOR'
							)}
						</div>
						<div className='mb-4'>
							<strong data-testid='debtor-name'>
								{
									values.debtorName
								}
							</strong>
						</div>

						<label className='required'>
							<strong data-testid='select-invoice'>
								{t(
									'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.SELECT_INVOICE'
								)}
							</strong>
						</label>
						<div className='mt-1 mb-4'>
							<$Skeleton
								active
								loading={
									!values
										.invoicetable
										.length
								}
								paragraph={{
									rows: 2,
								}}>
								<$TableTree
									rowKey='key'
									data={
										values.invoicetable
									}
									size='small'
									className=''
									onSort={
										handleSort
									}
									onFilter={
										handleFilter
									}
									filterOnType={
										true
									}
									resetOnSourceChange={
										true
									}
									bordered
									pagination={
										false
									}
									scroll={{
										x: 700,
									}}
									columns={
										columns
									}
									rowSelection={
										rowSelection
									}
									firstColSkipFilterProps={
										-1
									}
								/>
							</$Skeleton>
							{isInvoiceSelected &&
								selectRowKeys?.length ===
									0 && (
									<div className='ant-form-item-explain ant-form-item-explain-error mt-1'>
										{t(
											'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.PLEASE_SELECT_AT_LEAST_ONE_INVOICE'
										)}
									</div>
								)}
						</div>

						<div className='mb-4'>
							<label className='required'>
								<strong data-testid='objection-resons-label'>
									{t(
										'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.REASON_FOR_THE_OBJECTION'
									)}
								</strong>
							</label>
							<$Skeleton
								active
								loading={
									!values
										.objectionReasonList
										.length
								}
								paragraph={{
									rows: 3,
								}}>
								<div
									className='ml-3'
									data-testid='objection-resons'>
									{reasons(
										restProps.setFieldValue
									)}
								</div>
							</$Skeleton>
						</div>

						<label className='required'>
							<strong data-testid='originated-in-label'>
								{t(
									'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.ORIGINATED_IN'
								)}
							</strong>
						</label>
						<$Row
							gutter={16}
							className='mb-4'
							data-testid='communication-method'>
							<$Col span={24}>
								<$Radio
									onChange={(
										e: any
									) => {
										restProps.setFieldValue(
											'communicationMethod',
											e
												.target
												.value
										);
									}}
									name='communicationMethod'
									options={[
										{
											value: '1',
											label: t(
												'US.COLLECTION.COMMON:COMMON.TELEPHONE'
											),
										},
										{
											value: '2',
											label: t(
												'US.COLLECTION.COMMON:COMMON.EMAIL'
											),
										},
										// { value: "3", label: "Portal", },
										{
											value: '4',
											label: t(
												'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.POST'
											),
										},
									]}
									optionValue='value'
									optionText='label'
								/>
							</$Col>
						</$Row>

						<div className='mb-2'>
							<label
								className={`${
									values.objectionReason?.toLowerCase() ===
									'other'
										? 'required'
										: ''
								}`}>
								<strong data-testid='case-note-label'>
									{t(
										'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.CASE_NOTE'
									)}
								</strong>
							</label>
						</div>
						<$Row
							gutter={16}
							className='mb-4'>
							<$Col span={24}>
								<$TextArea
									name='caseNote'
									autoSize={{
										minRows: 2,
										maxRows: 6,
									}}
								/>
							</$Col>
						</$Row>

						<div className='mb-2'>
							<Text type='warning'>
								<InfoCircleOutlined className='mr-2' />
							</Text>
							<strong>
								<small data-testid='telephone-warning-message'>
									{t(
										'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.TELEPHONE_WARNING'
									)}
								</small>
							</strong>
						</div>
						{/* Register your telephone number and e-mail to receive confirmation of your inquiry */}
						<$Row
							gutter={16}
							className='mb-4'>
							<$Col span={6}>
								<div
									className='flex-fill'
									data-testid='sms-number-label'>
									<$Input
										formitem
										size='small'
										label={t(
											'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.SMS_NUMBER'
										)}
										name='smsNumber'
										onBlur={(
											e: any
										) =>
											handleSMSEdit(
												e
													.target
													.value,
												restProps
											)
										}
									/>
								</div>
							</$Col>
							<$Col span={18}>
								<div
									className='flex-fill'
									data-testid='email-address-label'>
									<$Input
										formitem
										size='small'
										label={t(
											'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.EMAIL_ADDRESS'
										)}
										name='emailAddress'
										onBlur={(
											e: any
										) =>
											handleEmailEdit(
												e
													.target
													.value,
												restProps
											)
										}
									/>
								</div>
							</$Col>
						</$Row>

						<div className='drawer-footer-fixed align-content-center justify-content-end'>
							<div>
								<$Button
									className='mr-2'
									data-testid='save-button'
									onClick={() => {
										handleSubmit(),
											invoiceTableValidation();
									}}
									disabled={
										// !restProps.dirty ||
										selectReasonId ===
										0
									}
									htmlType='submit'
									type='primary'>
									{t(
										'US.COLLECTION.COMMON:COMMON.SAVE'
									)}
								</$Button>
								{restProps.dirty && (
									<$Popconfirm
										title={t(
											'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.CANCEL_CONFORM'
										)}
										// "Your existing data will be lost. Do you wish to continue?"
										placement='topLeft'
										onConfirm={() =>
											onClose()
										}
										okText={t(
											'US.COLLECTION.COMMON:COMMON.YES'
										)}
										cancelText={t(
											'US.COLLECTION.COMMON:COMMON.NO'
										)}>
										<$Button>
											{t(
												'US.COLLECTION.COMMON:COMMON.CANCEL'
											)}
										</$Button>
									</$Popconfirm>
								)}
								{!restProps.dirty && (
									<$Button
										onClick={() =>
											onClose()
										}>
										{t(
											'US.COLLECTION.COMMON:COMMON.CANCEL'
										)}
									</$Button>
								)}
							</div>
						</div>
					</div>
				</$Form>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { dashboard, objectionAndAgreements, domainView } = state;
	const {
		caseInfoWidgetData,
		accountSummeryWidgetData,
		debtorWidgetData,
	} = dashboard;
	const { metaData } = domainView;
	const { debtorObjectionInitial } = objectionAndAgreements;

	return {
		metaData,
		caseInfoWidgetData,
		accountSummeryWidgetData,
		debtorWidgetData,
		debtorObjectionInitial,
	};
};

const { debtorObjection } = ObjectionAndAgreementsAction;

const mapDispatchToProps = {
	save: debtorObjection.save,
	getInitialData: debtorObjection.get,
};

export default connect(mapStateToProps, mapDispatchToProps)(DebtorObjection);
