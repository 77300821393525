import { FollowUps } from 'us.collection.followup/constants/Actions';
import { initialState } from './State';
import {
	generateFollowUpResultColumns,
	filterFollowUpResult,
	getCategories,
	getParameterTableData,
	getFieldName,
	setUpdatedLastActions,
	getReImportProgressingState,
	setTableColumnFilter,
	getFilteredColumnListWithStates,
	getFilteredColumnList,
} from 'us.collection.followup/functions';
import _ from 'lodash';
import { ReduxAction } from 'us.collection.followup/constants';

const {
	GET_FOLLOW_UP_CATEGORIES,
	GET_FOLLOW_UP_CATEGORIES_SUCCESS,
	GET_FOLLOW_UP_CATEGORIES_FAIL,
	SET_FOLLOW_UP_CATEGORY_FILTERS,
	GET_FOLLOW_UP_LIST,
	GET_FOLLOW_UP_LIST_SUCCESS,
	GET_FOLLOW_UP_LIST_FAIL,
	GET_FOLLOW_UP_RESULT,
	GET_FOLLOW_UP_RESULT_SUCCESS,
	GET_FOLLOW_UP_RESULT_FAIL,
	SET_FOLLOW_UP_FILTERS,
	SET_FOLLOW_UP_SORTERS,
	SET_FOLLOW_UP_TABLE_FILTERS,
	SET_FOLLOW_UP_RESULT_COLUMNS,
	SET_FOLLOW_UP_NAVIGATION,
	SET_UNPINNED_EXPANDED,
	UNPIN_FOLLOW_UP_ITEM,
	UNPIN_FOLLOW_UP_ITEM_SUCCESS,
	UNPIN_FOLLOW_UP_ITEM_FAIL,
	GET_FOLLOW_UP_HISTORY_FOR_ENTITY,
	GET_FOLLOW_UP_HISTORY_FOR_ENTITY_SUCCESS,
	GET_FOLLOW_UP_HISTORY_FOR_ENTITY_FAIL,
	MANAGE_FOLLOW_UP_HISTORY_DRAWER,
	GET_FOLLOW_UP_HISTORY_FOR_ITEM,
	GET_FOLLOW_UP_HISTORY_FOR_ITEM_SUCCESS,
	GET_FOLLOW_UP_HISTORY_FOR_ITEM_FAIL,
	GET_FOLLOW_UP_HISTORY_FOR_ACTION,
	GET_FOLLOW_UP_HISTORY_FOR_ACTION_SUCCESS,
	GET_FOLLOW_UP_HISTORY_FOR_ACTION_FAIL,
	GET_ACTIVE_FOLLOW_UP,
	GET_ACTIVE_FOLLOW_UP_SUCCESS,
	GET_ACTIVE_FOLLOW_UP_FAIL,
	GET_FOLLOW_UP_ACTIVITIES_START,
	GET_FOLLOW_UP_ACTIVITIES_SUCCESS,
	GET_FOLLOW_UP_ACTIVITIES_FAIL,
	GET_FOLLOW_UP_ACTIVITY_PARAMETERS_START,
	GET_FOLLOW_UP_ACTIVITY_PARAMETERS_SUCCESS,
	GET_FOLLOW_UP_ACTIVITY_PARAMETERS_FAIL,
	MANAGE_PARAMATER_DROP_DOWN,
	UPDATE_ACTIVITY_FORM_DATA,
	GET_ACTIVITY_BMD_START,
	GET_ACTIVITY_BMD_SUCCESS,
	GET_ACTIVITY_BMD_FAIL,
	EXECUTE_FOLLOW_UP_ACTIVITY_STRAT,
	EXECUTE_FOLLOW_UP_ACTIVITY_SUCCESS,
	EXECUTE_FOLLOW_UP_ACTIVITY_FAIL,
	EXECUTE_SINGLE_FOLLOW_UP_ACTIVITY,
	EXECUTE_SINGLE_FOLLOW_UP_ACTIVITY_SUCCESS,
	EXECUTE_SINGLE_FOLLOW_UP_ACTIVITY_FAIL,
	RESET_ACTIVITY_FROM,
	GET_UPDATED_LAST_ACTION_SUCCESS,
	FOLLOW_UP_RE_IMPORT,
	FOLLOW_UP_RE_IMPORT_FAIL,
	FOLLOW_UP_RE_IMPORT_SUCCESS,
	FOLLOW_UP_RE_IMPORT_RESET,
	SET_LAST_ACTION_TO_FOLLOWUP_RESULT,
	GET_CATEGORY_ID_FOR_SELECTED_FOLLOW_UP_SUCCESS,
	GET_CATEGORY_ID_FOR_SELECTED_FOLLOW_UP_FAIL,
	SET_ACTIVITY_EXECUTION_ACTION,
} = FollowUps;

export default (state = initialState, { payload, type }: any) => {
	switch (type) {
		case GET_FOLLOW_UP_CATEGORIES:
			return Object.assign({}, state, {
				categories: {
					data: initialState.categories.data,
					selectedCategoryId: -1,
					categorySearchText: '',
					isFetching: true,
				},
			});
		case GET_FOLLOW_UP_CATEGORIES_SUCCESS:
			return Object.assign({}, state, {
				categories: {
					data: [
						...initialState.categories.data,
						...payload?.data,
					],
					selectedCategoryId: -1,
					categorySearchText: '',
					isFetching: false,
				},
			});
		case GET_FOLLOW_UP_CATEGORIES_FAIL:
			return Object.assign({}, state, {
				categories: {
					data: initialState.categories.data,
					selectedCategoryId: -1,
					categorySearchText: '',
					isFetching: false,
				},
			});
		case SET_FOLLOW_UP_CATEGORY_FILTERS:
			return Object.assign({}, state, {
				categories: {
					...state.categories,
					categorySearchText: payload?.data,
				},
			});
		case GET_FOLLOW_UP_LIST:
			return Object.assign({}, state, {
				categories: {
					...state.categories,
					selectedCategoryId:
						payload?.data?.categoryId,
				},
				list: {
					data: [],
					isFetching: true,
				},
				columns: {
					data: [...state.columns.data],
					tableColumns: [
						...state.columns.tableColumns,
					],
					tableColumnWithStates:
						setTableColumnFilter(
							state.columns
								.tableColumns
						),
				},
				result: {
					data: { ...initialState.result.data },
					followupId: initialState.result
						.followupId,
					dataSource: [
						...initialState.result
							.dataSource,
					],
					isFetching: false,
				},
				pinStatus: {
					...state.pinStatus,
				},
			});
		case GET_FOLLOW_UP_LIST_SUCCESS:
			return Object.assign({}, state, {
				list: {
					data: payload?.data,
					isFetching: false,
				},
			});
		case GET_FOLLOW_UP_LIST_FAIL:
			return Object.assign({}, state, {
				list: {
					data: [],
					isFetching: false,
				},
			});
		case GET_FOLLOW_UP_RESULT:
			return Object.assign({}, state, {
				columns: {
					data: [...initialState.columns.data],
					tableColumns: [
						...initialState.columns
							.tableColumns,
					],
					tableColumnWithStates: [
						...initialState.columns
							.tableColumnWithStates,
					],
					isFetching: true,
				},
				result: {
					data: { ...initialState.result.data },
					followupId: state.result.followupId,
					dataSource: [
						...initialState.result
							.dataSource,
					],
					isFetching: true,
				},
				tableFilters: {
					...initialState.tableFilters.data,
				},
				tableSorters: {
					...initialState.tableSorters.data,
				},
				tableDataWithTableFilters: {
					...initialState
						.tableDataWithTableFilters.data,
				},
			});
		case GET_FOLLOW_UP_RESULT_SUCCESS:
			const { response: data, followupId } = payload?.data;
			const filteredTableSource = filterFollowUpResult(
				{
					...initialState.filters.data,
					entityType: data?.entityType,
				},
				data
			);
			return Object.assign({}, state, {
				columns: {
					data: generateFollowUpResultColumns(
						data,
						data.followupHeaders,
						state.followUpNavigation
							.viewedItems,
						false,
						followupId
					),
					tableColumns: data.followupHeaders,
					tableColumnWithStates:
						setTableColumnFilter(
							data.followupHeaders
						),
					isFetching: false,
				},
				filters: {
					data: {
						...initialState.filters.data,
						entityType: data?.entityType,
					},
					isFetching: false,
				},
				result: {
					data,
					followupId: followupId,
					dataSource: filteredTableSource,
					isFetching: false,
				},
				tableDataWithTableFilters: {
					data: filteredTableSource,
					isFetching: false,
				},
			});
		case GET_FOLLOW_UP_RESULT_FAIL:
			return Object.assign({}, state, {
				columns: {
					data: [...initialState.columns.data],
					tableColumns: [
						...initialState.columns
							.tableColumns,
					],
					tableColumnWithStates: [
						...initialState.columns
							.tableColumnWithStates,
					],
					isFetching: false,
				},
				result: {
					data: { ...initialState.result.data },
					followupId: initialState.result
						.followupId,
					dataSource: [
						...initialState.result
							.dataSource,
					],
					isFetching: false,
				},
			});
		case SET_FOLLOW_UP_FILTERS:
			const filteredResult = filterFollowUpResult(
				payload?.data,
				state.result.data
			);
			return Object.assign({}, state, {
				filters: {
					data: payload?.data,
					isFetching: false,
				},
				result: {
					...state.result,
					dataSource: filteredResult,
				},
				tableFilters: {
					...initialState.tableFilters.data,
				},
				tableSorters: {
					...initialState.tableSorters.data,
				},
				tableDataWithTableFilters: {
					data: filteredResult,
					isFetching: false,
				},
			});
		case SET_FOLLOW_UP_TABLE_FILTERS:
			const { filters, dataWithFilters } = payload?.data;
			return Object.assign({}, state, {
				tableFilters: {
					data: filters,
					isFetching: false,
				},
				tableDataWithTableFilters: {
					data: dataWithFilters,
					isFetching: false,
				},
			});
		case SET_FOLLOW_UP_SORTERS:
			const { sorters, dataWithSorters } = payload?.data;
			return Object.assign({}, state, {
				tableSorters: {
					data: sorters,
					isFetching: false,
				},
				tableDataWithTableFilters: {
					data: dataWithSorters,
					isFetching: false,
				},
			});
		case SET_FOLLOW_UP_RESULT_COLUMNS:
			const { filter, value } = payload?.data;
			const columns = getFilteredColumnList(
				state.columns.tableColumns,
				filter
			);
			const columnsWithStates =
				getFilteredColumnListWithStates(
					state.columns.tableColumnWithStates,
					filter
				);
			return Object.assign({}, state, {
				columns: {
					tableColumns: value
						? [
								...state.columns
									.tableColumns,
								filter,
						  ]
						: columns,
					tableColumnWithStates: [
						...columnsWithStates,
						{ [`${filter}`]: value },
					],
					data: generateFollowUpResultColumns(
						state.result.data,
						value
							? [
									...state
										.columns
										.tableColumns,
									filter,
							  ]
							: columns,
						state.followUpNavigation
							.viewedItems,
						false,
						state.result.followupId
					),
					isFetching: false,
				},
			});
		case SET_FOLLOW_UP_NAVIGATION:
			return Object.assign({}, state, {
				followUpNavigation: payload?.data,
				columns: {
					tableColumns: [
						...state.columns.tableColumns,
					],
					tableColumnWithStates: [
						...state.columns
							.tableColumnWithStates,
					],
					data: generateFollowUpResultColumns(
						state.result.data,
						state.columns.tableColumns,
						state.followUpNavigation
							.viewedItems,
						false,
						state.result.followupId
					),
					isFetching: false,
				},
			});
		case SET_UNPINNED_EXPANDED:
			return Object.assign({}, state, {
				pinStatus: {
					...state.pinStatus,
					isExpanded: !state.pinStatus.isExpanded,
				},
			});
		case UNPIN_FOLLOW_UP_ITEM:
			return Object.assign({}, state, {
				pinStatus: {
					...state.pinStatus,
					data: [
						...state.pinStatus.data,
						payload.data?.caselistQueryId,
					],
				},
			});
		case UNPIN_FOLLOW_UP_ITEM_SUCCESS:
			return Object.assign({}, state, {
				pinStatus: {
					...state.pinStatus,
					data: state.pinStatus.data?.filter(
						(pinId: any) =>
							pinId !=
							payload.data?.pinId
					),
				},
			});
		case UNPIN_FOLLOW_UP_ITEM_FAIL:
			return Object.assign({}, state, {
				pinStatus: {
					...state.pinStatus,
					data: [],
				},
			});
		case GET_FOLLOW_UP_HISTORY_FOR_ENTITY:
			return Object.assign({}, state, {
				history: {
					entity: { data: [], isFetching: true },
				},
			});
		case GET_FOLLOW_UP_HISTORY_FOR_ENTITY_SUCCESS:
			return Object.assign({}, state, {
				history: {
					entity: {
						data: payload?.data,
						isFetching: false,
					},
				},
			});
		case GET_FOLLOW_UP_HISTORY_FOR_ENTITY_FAIL:
			return Object.assign({}, state, {
				history: {
					entity: {
						data: [],
						isFetching: false,
					},
				},
			});
		case GET_FOLLOW_UP_HISTORY_FOR_ITEM:
			return Object.assign({}, state, {
				history: {
					...state.history,
					item: {
						data: {
							...initialState.history
								.item.data,
						},
						dataSource: [
							...initialState.result
								.dataSource,
						],
						isFetching: true,
						columns: [
							...initialState.columns
								.data,
						],
					},
				},
			});
		case GET_FOLLOW_UP_HISTORY_FOR_ITEM_SUCCESS:
			const { response, followUpId } = payload?.data;
			return Object.assign({}, state, {
				history: {
					...state.history,
					item: {
						data: response,
						dataSource: response.followupData,
						isFetching: false,
						columns: generateFollowUpResultColumns(
							response,
							response.followupHeaders,
							state.followUpNavigation
								.viewedItems,
							true,
							followUpId
						),
					},
				},
			});
		case GET_FOLLOW_UP_HISTORY_FOR_ITEM_FAIL:
			return Object.assign({}, state, {
				history: {
					...state.history,
					item: {
						data: {
							...initialState.history
								.item.data,
						},
						dataSource: [
							...initialState.history
								.item
								.dataSource,
						],
						isFetching: false,
						columns: [
							...initialState.history
								.item.columns,
						],
					},
				},
			});
		case GET_FOLLOW_UP_HISTORY_FOR_ACTION:
			return Object.assign({}, state, {
				history: {
					...state.history,
					action: {
						data: [],
						isFetching: true,
					},
				},
			});
		case GET_FOLLOW_UP_HISTORY_FOR_ACTION_SUCCESS:
			return Object.assign({}, state, {
				history: {
					...state.history,
					action: {
						data: _.orderBy(
							payload?.data,
							['createdDate'],
							['desc']
						),
						isFetching: false,
					},
				},
			});
		case GET_FOLLOW_UP_HISTORY_FOR_ACTION_FAIL:
			return Object.assign({}, state, {
				history: {
					...state.history,
					action: {
						data: [],
						isFetching: false,
					},
				},
			});
		case MANAGE_FOLLOW_UP_HISTORY_DRAWER:
			return Object.assign({}, state, {
				historyDrawer: payload?.data,
			});
		case GET_ACTIVE_FOLLOW_UP:
			return Object.assign({}, state, {
				entity: {
					...state.entity,
					active: {
						data: [],
						isFetching: true,
					},
				},
			});
		case GET_ACTIVE_FOLLOW_UP_SUCCESS:
			return Object.assign({}, state, {
				entity: {
					...state.entity,
					active: {
						data: payload?.data,
						isFetching: false,
					},
				},
			});
		case GET_ACTIVE_FOLLOW_UP_FAIL:
			return Object.assign({}, state, {
				entity: {
					...state.entity,
					active: {
						data: [],
						dataSource: [],
						isFetching: false,
					},
				},
			});
		case GET_FOLLOW_UP_ACTIVITIES_START:
			return Object.assign({}, state, {
				activites: {
					data: [],
					categories: [],
					isFetching: true,
					isEmpty: false,
				},
			});
		case GET_FOLLOW_UP_ACTIVITIES_SUCCESS:
			return Object.assign({}, state, {
				activites: {
					data: payload?.data,
					categories: getCategories(),
					isFetching: false,
					isEmpty: payload?.data?.length == 0,
				},
			});
		case GET_FOLLOW_UP_ACTIVITIES_FAIL:
			return Object.assign({}, state, {
				activites: {
					data: [],
					categories: [],
					isFetching: false,
					isEmpty: true,
				},
			});
		case GET_FOLLOW_UP_ACTIVITY_PARAMETERS_START:
			return Object.assign({}, state, {
				parameters: {
					isEnableDropDown: false,
					list: [],
					dataSource: [],
				},
			});
		case GET_FOLLOW_UP_ACTIVITY_PARAMETERS_SUCCESS:
			return Object.assign({}, state, {
				parameters: {
					isEnableDropDown: !_.isEmpty(
						getFieldName(
							payload.data.settings,
							state.defaultData
								.entityType
						)
					),
					list: payload.data.settings,
					dataSource: getParameterTableData(
						payload.data.settings,
						state.defaultData.entityType
					),
				},
				activityForm: {
					initialValues: {
						...state.activityForm
							.initialValues,
						...getFieldName(
							payload.data.settings,
							state.defaultData
								.entityType
						),
					},
				},
			});
		case GET_FOLLOW_UP_ACTIVITY_PARAMETERS_FAIL:
			return Object.assign({}, state, {
				parameters: {
					isEnableDropDown: false,
					list: [],
					dataSource: [],
				},
				activityForm: {
					initialValues:
						initialState.activityForm
							.initialValues,
				},
			});
		case MANAGE_PARAMATER_DROP_DOWN:
			return Object.assign({}, state, {
				parameters: {
					...state.parameters,
					isEnableDropDown: false,
				},
				activityForm: {
					initialValues:
						initialState.activityForm
							.initialValues,
				},
			});
		case UPDATE_ACTIVITY_FORM_DATA:
			return Object.assign({}, state, {
				activityForm: {
					initialValues: payload.data,
				},
			});
		case GET_ACTIVITY_BMD_START:
			return Object.assign({}, state, {
				maxFollowUpscount: 10,
			});
		case GET_ACTIVITY_BMD_SUCCESS:
			return Object.assign({}, state, {
				maxFollowUpscount: Number(
					payload.data[0]?.value
				),
			});
		case GET_ACTIVITY_BMD_FAIL:
			return Object.assign({}, state, {
				maxFollowUpscount: 10,
			});
		case EXECUTE_FOLLOW_UP_ACTIVITY_STRAT:
			return Object.assign({}, state, {
				isActivityExecuting: true,
			});
		case EXECUTE_FOLLOW_UP_ACTIVITY_SUCCESS:
			return Object.assign({}, state, {
				isActivityExecuting: false,
			});
		case EXECUTE_FOLLOW_UP_ACTIVITY_FAIL:
			return Object.assign({}, state, {
				isActivityExecuting: false,
			});
		case EXECUTE_SINGLE_FOLLOW_UP_ACTIVITY:
			return Object.assign({}, state, {
				isActivityExecuting: true,
			});
		case EXECUTE_SINGLE_FOLLOW_UP_ACTIVITY_SUCCESS:
			return Object.assign({}, state, {
				isActivityExecuting: false,
			});
		case EXECUTE_SINGLE_FOLLOW_UP_ACTIVITY_FAIL:
			return Object.assign({}, state, {
				isActivityExecuting: false,
			});
		case RESET_ACTIVITY_FROM:
			return Object.assign({}, state, {
				activityForm: {
					initialValues:
						initialState.activityForm
							.initialValues,
				},
				activites: {
					...state.activites,
					isEmpty: false,
				},
			});
		case GET_UPDATED_LAST_ACTION_SUCCESS:
		case SET_LAST_ACTION_TO_FOLLOWUP_RESULT:
			return Object.assign({}, state, {
				result: {
					data: {
						...state.result.data,
						followupData:
							setUpdatedLastActions(
								state.result
									.data
									.followupData,
								payload?.data
							),
					},
					followupId: state.result.followupId,
					dataSource: filterFollowUpResult(
						{
							...state.filters.data,
							entityType: state
								.filters.data
								.entityType,
						},
						{
							...state.result.data,
							followupData:
								setUpdatedLastActions(
									state
										.result
										.data
										.followupData,
									payload?.data
								),
						}
					),
					isFetching: false,
				},
			});
		case FOLLOW_UP_RE_IMPORT:
			return Object.assign({}, state, {
				...getReImportProgressingState(
					payload.data?.followUpId,
					ReduxAction.START,
					state
				),
			});
		case FOLLOW_UP_RE_IMPORT_SUCCESS:
			return Object.assign({}, state, {
				...getReImportProgressingState(
					payload.data?.followUpId,
					ReduxAction.SUCCESS,
					state
				),
			});
		case FOLLOW_UP_RE_IMPORT_FAIL:
			return Object.assign({}, state, {
				...getReImportProgressingState(
					payload.data?.followUpId,
					ReduxAction.FAIL,
					state
				),
			});
		case FOLLOW_UP_RE_IMPORT_RESET:
			return Object.assign({}, state, {
				isBulkFollowupReImporting: false,
			});
		case GET_CATEGORY_ID_FOR_SELECTED_FOLLOW_UP_SUCCESS:
			return Object.assign({}, state, {
				selectedCategoryId: payload.data?.categoryId,
			});
		case GET_CATEGORY_ID_FOR_SELECTED_FOLLOW_UP_FAIL:
			return Object.assign({}, state, {
				selectedCategoryId: -1,
			});
		case SET_ACTIVITY_EXECUTION_ACTION:
			return Object.assign({}, state, {
				activityExecutionAction: payload.data?.action,
			});
		default:
			return state;
	}
};
