import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { CourtSentenceActions } from "us.collection.case/actions";
import { SentneceDrawerStatus } from "us.collection.case/constants";
import { IRootState } from "us.collection/interfaces";
import { $Popconfirm } from "us.common/components";
import { DeleteOutlined, EditOutlined } from "us.icons";
import { IGridMoreColumnContent } from "./Interfaces";
import { drawerStateHandler } from "us.collection.case/functions";


export const GridMoreColumnContent: React.FC<IGridMoreColumnContent & PropsFromRedux> = (props) => {
  const { record, setPopVisibile, openDrawer, deleteSentence, sentenceSummery } = props;
  const { t } = useTranslation();
  return (
    <div className="table-more-content">
      <div className="d-flex  flex-row more-item">
        <div className="p-1">
          <EditOutlined />
        </div>
        <div
          className="p-1"
          onClick={() => {
            drawerStateHandler(
              openDrawer,
              true,
              "US.COLLECTION.CASE:SENTENCES.EDIT_LEGAL_DECISION",
              SentneceDrawerStatus.EDIT_SENTENCE_OPEN,
              record.id
            );
            setPopVisibile(-1);
          }}
        >
          {t("US.COLLECTION.COMMON:COMMON.EDIT")}
        </div>
      </div>

      <$Popconfirm
        title={t("US.COLLECTION.CASE:SENTENCES.DELETE_CONFIRM")}
        onConfirm={() => {
          setPopVisibile(-1);
          deleteSentence &&
            deleteSentence({ sentenceIdToDelete: record.id, allSentences: sentenceSummery.data });
        }}
        onCancel={() => setPopVisibile(-1)}
        okText={t("US.COLLECTION.COMMON:COMMON.YES")}
        cancelText={t("US.COLLECTION.COMMON:COMMON.NO")}
      >
        <div className="d-flex mb-2 flex-row more-item text-error">
          <div className="p-1">
            <DeleteOutlined />
          </div>
          <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.DELETE")}</div>
        </div>
      </$Popconfirm>
    </div>
  );
};

const { summery, sentenceDelete } = CourtSentenceActions;

const mapStateToProps = (state: IRootState) => {
  const { courtSentence } = state;
  const { sentenceSummery } = courtSentence;
  return {
    sentenceSummery,
  };
};

const mapDispatchToProps = {
  deleteSentence: sentenceDelete.delete,
  openDrawer: summery.openDrawer,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GridMoreColumnContent);
