import React, { useEffect, memo, useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import Common from 'us.common';
import { workflowAction } from 'us.collection/actions';
import {
	BoxIcons,
	IconTypes,
	PlusOutlined,
	UploadOutlined,
	SearchOutlined,
} from 'us.icons';
import { NewState, StateCategory, WorkflowCategory, Event } from './Components';
import {
	StateType,
	workflowDrawerInitial,
	WorkflowDrawerType,
	WorkflowView,
	WorkflowStateStatus,
} from './Constants';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { getEntityNumberAndType } from '../BMD/Functions';
import { handleGoBack } from 'us.helper/utility';
import { WorkflowGroup } from 'us.collection/repository';
import { IWorkflowGroup } from 'us.collection/reducers/Workflow/Interfaces';
import { URLType, EntityType } from 'us.collection/constants';
import {
	searchState,
	getTroubleshootNavigationUrl,
	getOverriddenLevels,
} from './Functions';
import { RootState } from 'us.helper/types';
import './Home.scss';

const {
	$Button,
	$Divider,
	$PageHeader,
	$Drawer,
	$Affix,
	$Skeleton,
	$Radio,
	$Tooltip,
	$Select,
	$Input,
	$InputGroup,
	$Checkbox,
} = Common.Components;

/**
 * @description - The component used in home view of Workflow
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3055714493/Manage+State+Categories
 * @param {PropsFromRedux} props
 * @return {JSX.Element}
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 14/11/2022
 */

const Workflow: React.FC<PropsFromRedux> = memo((props) => {
	const {
		workflowStates,
		getWorkflowStates,
		workflowDrawer,
		openDrawer,
		metaData,
		searchFilters,
	} = props;

	const { t } = useTranslation();

	const location = useLocation();
	const history = useHistory();

	const { params }: any = matchPath(location.pathname, {
		path: '/:type/:id?/:module?',
		exact: false,
		strict: false,
	});

	const { caseId } = metaData.data ?? {};

	const [visibleTab, setVisibleTab] = useState(WorkflowView.STATES);

	useEffect(() => {
		getWorkflowStates &&
			getWorkflowStates(
				WorkflowGroup.call(
					getEntityNumberAndType(
						params['type'],
						params['id'],
						caseId?.toString() ?? ''
					)
				)
			);
	}, []);

	/**
	 * @description Navigation click action for troubleshoot button
	 */
	const handleTroubleshootNavigation = () => {
		window.scrollTo(0, 0);
		history.push({
			...location,
			pathname: getTroubleshootNavigationUrl(
				params['id'],
				params['type']
			),
		});
	};

	/**
	 * @description Handles overridden tags click action
	 * @param values formik valus
	 * @param rest formik rest props
	 * @param overriddenLevel selected overridden level
	 */
	const handleOverriddenSelection = (
		values: any,
		rest: any,
		overriddenLevel: string
	) => {
		const { overriddenBy } = values ?? {};
		const { setFieldValue } = rest ?? {};

		if (overriddenBy && setFieldValue) {
			if (overriddenBy?.includes(overriddenLevel)) {
				setFieldValue(
					'overriddenBy',
					getOverriddenLevels(
						overriddenBy,
						overriddenLevel
					)
				);
			} else {
				setFieldValue('overriddenBy', [
					...overriddenBy,
					overriddenLevel,
				]);
			}
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				...searchFilters,
				statesAndEvent: visibleTab,
			}}
			onSubmit={() => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				...rest
			}: any) => (
				<>
					<div className='workflows'>
						<$Affix offsetTop={80}>
							<div className='page-header header-border'>
								<div className='d-flex flex-row align-items-center justify-content-between'>
									<div className='d-flex align-items-center'>
										<$PageHeader
											className='px-0'
											title={t(
												'US.COLLECTION.CASE:WORKFLOWS.WORKFLOWS'
											)}
											onBack={() =>
												handleGoBack(
													history
												)
											}
										/>
										<div className='states-events-toggle'>
											<$Radio
												name='statesAndEvent'
												options={[
													{
														value: WorkflowView.STATES,
														label: t(
															'US.COLLECTION.CASE:WORKFLOWS.STATES'
														),
													},
													{
														value: WorkflowView.EVENTS,
														label: t(
															'US.COLLECTION.CASE:WORKFLOWS.EVENTS'
														),
													},
												]}
												onChange={(
													e: any
												) => {
													setVisibleTab(
														e
															.target
															.value
													);
												}}
												className='d-flex'
												optionStyle='flex-fill text-center'
												optionType='button'
												buttonStyle='solid'
												radioButton='true'
												optionValue='value'
												optionContent={(
													item: any,
													index: number
												) =>
													item.label
												}
												size='small'
											/>
										</div>
										<$Button
											name='troubleshoot'
											size='small'
											className='ml-5'
											onClick={() =>
												handleTroubleshootNavigation()
											}>
											<BoxIcons
												type={
													IconTypes.BOX_ICON
												}
												name='workflows-troubleshoot'
												className='mr-1'
											/>
											{t(
												'US.COLLECTION.CASE:WORKFLOWS.TROUBLESHOOT'
											)}
										</$Button>
									</div>

									<div className='d-flex align-items-center flex-grow-1 justify-content-end'>
										{visibleTab ==
											WorkflowView.STATES &&
											params[
												'type'
											] !=
												URLType.BUREAU && (
												<div className='d-flex align-items-center mr-4'>
													<$Tooltip
														placement='top'
														title={t(
															'US.COLLECTION.CASE:WORKFLOWS.OVERRIDDEN_IN_BUREAU'
														)}>
														<span>
															<$Checkbox
																name='bu'
																checked={values.overriddenBy?.includes(
																	''
																)}
																className='ml-1 mr-0 checkbox-bu'
																onChange={() =>
																	handleOverriddenSelection(
																		values,
																		rest,
																		''
																	)
																}
															/>
														</span>
													</$Tooltip>
													<$Tooltip
														placement='top'
														title={t(
															'US.COLLECTION.CASE:WORKFLOWS.OVERRIDDEN_IN_CREDITOR_GROUP'
														)}>
														<span>
															<$Checkbox
																name='cg'
																checked={values.overriddenBy?.includes(
																	EntityType.CREDITOR_GROUP
																)}
																className='ml-1 mr-0 checkbox-cg'
																onChange={() =>
																	handleOverriddenSelection(
																		values,
																		rest,
																		EntityType.CREDITOR_GROUP
																	)
																}
															/>
														</span>
													</$Tooltip>
													<$Tooltip
														placement='top'
														title={t(
															'US.COLLECTION.CASE:WORKFLOWS.OVERRIDDEN_IN_CREDITOR'
														)}>
														<span>
															<$Checkbox
																name='cr'
																checked={values.overriddenBy?.includes(
																	EntityType.CREDITOR
																)}
																className='ml-1 mr-0 checkbox-cr'
																onChange={() =>
																	handleOverriddenSelection(
																		values,
																		rest,
																		EntityType.CREDITOR
																	)
																}
															/>
														</span>
													</$Tooltip>
												</div>
											)}
										<div className='d-flex flex-1 align-items-center mr-2'>
											<div
												style={{
													lineHeight: 1,
												}}>
												<$InputGroup
													compact
													className='no-mb-group-item mt-1'>
													<$Tooltip
														title={t(
															'COMMON.STATUS'
														)}
														placement='top'>
														{visibleTab ==
															WorkflowView.STATES && (
															<$Select
																name='activeStates'
																size='small'
																options={[
																	{
																		label: t(
																			'COMMON.ACTIVE'
																		),
																		value: WorkflowStateStatus.ACTIVE,
																	},
																	{
																		label: t(
																			'COMMON.INACTIVE'
																		),
																		value: WorkflowStateStatus.INACTIVE,
																	},
																]}
																defaultValue={
																	WorkflowStateStatus.ALL
																}
																optionText='label'
																optionValue='value'
																hideSearch={
																	true
																}
																style={{
																	width: '120px',
																}}
																className='mt-1'
															/>
														)}
														{visibleTab ==
															WorkflowView.EVENTS && (
															<$Select
																name='activeEvents'
																size='small'
																options={[
																	{
																		label: t(
																			'US.COLLECTION.CASE:WORKFLOWS.EXECUTABLE_FROM_DOMAIN_VIEW'
																		),
																		value: WorkflowStateStatus.ACTIVE,
																	},
																	{
																		label: t(
																			'US.COLLECTION.CASE:WORKFLOWS.NOT_EXECUTABLE_FROM_DOMAIN_VIEW'
																		),
																		value: WorkflowStateStatus.INACTIVE,
																	},
																]}
																defaultValue={
																	WorkflowStateStatus.ALL
																}
																optionText='label'
																optionValue='value'
																hideSearch={
																	true
																}
																style={{
																	width: '250px',
																}}
																className='mt-1'
															/>
														)}
													</$Tooltip>
													<$Input
														size='small'
														name='searchText'
														className='w-100 mb-0'
														allowClear={
															true
														}
														value={
															values.searchText
														}
														placeholder={t(
															'US.COLLECTION.CASE:WORKFLOWS.SEARCH_STATES_EVENTS'
														)}
														addonAfter={
															<a className='px-2'>
																<SearchOutlined />
															</a>
														}
													/>
												</$InputGroup>
											</div>
										</div>
									</div>

									{false && ( // This use in next feature implementation
										<$Button
											type='dashed'
											size='small'
											icon={
												<UploadOutlined />
											}
											disabled={
												true
											}>
											{t(
												'US.COLLECTION.CASE.COMMON:COMMON.UPLOAD'
											)}
										</$Button>
									)}
								</div>
							</div>
						</$Affix>
						<$Skeleton
							loading={
								workflowStates?.isLoading &&
								visibleTab ==
									WorkflowView.STATES
							}
							active
							paragraph={{ rows: 2 }}
							className={
								workflowStates?.isLoading
									? 'p-3'
									: ''
							}>
							{visibleTab ===
								WorkflowView.STATES && (
								<div className='workflows-states-layout'>
									<div className='workflows-states-layout-col'>
										<div className='d-flex align-items-center'>
											<h3 className='mb-0'>
												{t(
													'US.COLLECTION.CASE:WORKFLOWS.MAIN_STATES'
												)}
											</h3>
											<$Divider
												className='bui-devider'
												type='vertical'
											/>
											<$Button
												type='default'
												size='small'
												icon={
													<PlusOutlined />
												}
												onClick={() => {
													openDrawer &&
														openDrawer(
															{
																...workflowDrawerInitial,
																title: 'US.COLLECTION.CASE:WORKFLOWS.ADD_NEW_MAIN_STATE_CATEGORY',
																isVisible: true,
																type: WorkflowDrawerType.CATEGORY,
																stateType: StateType.MAIN,
															}
														);
												}}>
												{t(
													'US.COLLECTION.CASE:WORKFLOWS.NEW_CATEGORY'
												)}
											</$Button>
										</div>
										<div className='ws-cat-wrap mt-4'>
											<div className='pr-3'>
												<div className='ws-tile-container'>
													{searchState(
														workflowStates?.data,
														values
													) &&
														searchState(
															workflowStates?.data,
															values
														)?.map(
															(
																group: IWorkflowGroup
															) => {
																if (
																	group?.type ===
																	StateType.MAIN
																)
																	return (
																		<StateCategory
																			key={
																				group?.stateCategoryId
																			}
																			workflowGroup={
																				group
																			}
																			stateType={
																				StateType.MAIN
																			}
																		/>
																	);
															}
														)}
												</div>
											</div>
										</div>
									</div>
									<div className='workflows-states-layout-col'>
										<div className='d-flex align-items-center'>
											<h3 className='mb-0'>
												{t(
													'US.COLLECTION.CASE:WORKFLOWS.OPTIONAL_STATES'
												)}
											</h3>
											<$Divider
												className='bui-devider'
												type='vertical'
											/>
											<$Button
												type='default'
												size='small'
												icon={
													<PlusOutlined />
												}
												onClick={() => {
													openDrawer &&
														openDrawer(
															{
																...workflowDrawerInitial,
																title: 'US.COLLECTION.CASE:WORKFLOWS.ADD_NEW_OPTIONAL_STATE_CATEGORY',
																isVisible: true,
																type: WorkflowDrawerType.CATEGORY,
																stateType: StateType.OPTIONAL,
															}
														);
												}}>
												{t(
													'US.COLLECTION.CASE:WORKFLOWS.NEW_CATEGORY'
												)}
											</$Button>
										</div>
										<div className='ws-cat-wrap mt-4'>
											<div className='pr-3'>
												<div className='ws-tile-container'>
													{searchState(
														workflowStates?.data,
														values
													) &&
														searchState(
															workflowStates?.data,
															values
														)?.map(
															(
																group: IWorkflowGroup
															) => {
																if (
																	group?.type ===
																	StateType.OPTIONAL
																)
																	return (
																		<StateCategory
																			key={
																				group?.stateCategoryId
																			}
																			workflowGroup={
																				group
																			}
																			stateType={
																				StateType.OPTIONAL
																			}
																		/>
																	);
															}
														)}
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</$Skeleton>
						{visibleTab ===
							WorkflowView.EVENTS && (
							<Event />
						)}
					</div>
					<$Drawer
						title={t(workflowDrawer.title)}
						width={600}
						visible={
							workflowDrawer.isVisible
						}
						onClose={() =>
							openDrawer &&
							openDrawer(
								workflowDrawerInitial
							)
						}
						destroyOnClose>
						{workflowDrawer?.type ===
							WorkflowDrawerType.CATEGORY && (
							<WorkflowCategory
								onClose={() =>
									openDrawer &&
									openDrawer(
										workflowDrawerInitial
									)
								}
							/>
						)}
						{workflowDrawer?.type ===
							WorkflowDrawerType.STATE && (
							<NewState
								onClose={() =>
									openDrawer &&
									openDrawer(
										workflowDrawerInitial
									)
								}
							/>
						)}
					</$Drawer>
				</>
			)}
		</Formik>
	);
});

const { workflowStates, category, workflowState } = workflowAction;

const mapStateToProps = (state: RootState) => {
	const { Workflow, domainView } = state;
	const { workflowStates, workflowDrawer, searchFilters } = Workflow;
	const { metaData } = domainView;
	return {
		workflowStates,
		workflowDrawer,
		metaData,
		searchFilters,
	};
};

const mapDispatchToProps = {
	getWorkflowStates: workflowStates.get,
	openDrawer: category.openDrawer,
	openStateDrawer: workflowState.openDrawer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Workflow);
