import { SearchByLabels } from "us.common/components";

export function SearchLinkedCreditorsRequestObject(this: any): any {
  return {
    creditorId: this.creditorId,
    searchText: this.searchText,
    criteria:
      this.criteria === SearchByLabels.CREDITOR_NO
        ? "number"
        : SearchByLabels.CREDITOR_NAME
        ? "name"
        : "all",
  };
}
