import { WorkflowDrawerActionType } from "us.collection/constants";

export const workflowDrawerInitial = {
  title: "",
  isVisible: false,
  actionType: WorkflowDrawerActionType.ADD,
  type: "",
  stateCategoryId: 0,
  stateId: 0,
  stateType: "",
};

export const eventDrawerInitial = {
  title: "",
  isVisible: false,
  isEdit: false,
  eventId: 0,
};

export enum StateType {
  MAIN = "Main",
  OPTIONAL = "Optional",
}

export enum WorkflowDrawerType {
  CATEGORY = "Category",
  STATE = "State",
}

export const initialWorkflowCategory = {
  categoryId: 0,
  categoryName: "",
  displayName: "",
  description: "",
};

export const workflowStateInitial = {
  id: 0,
  name: "",
  displayName: "",
  stateGroupName: "",
  workflowType: "Case",
  dueInDays: "",
  adapterName: "",
  stateType: "",
  activeStatus: true,
  description: "",
};

export const workflowEventInitial = {
  workflowType: "Case",
  name: "",
  displayName: "",
  adapterName: "",
  description: "",
  isDomainViewActive: false,
};

export enum WorkflowView {
  STATES = "states",
  EVENTS = "events",
}

export enum WorkflowStateStatus {
  ALL = "all",
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export const SearchFiltersDefault = {
  searchText: "",
  activeStates: WorkflowStateStatus.ALL,
  overriddenBy: [],
  activeEvents: WorkflowStateStatus.ALL,
};

export enum ItemMenuActions {
  DOWNLOAD = "DOWNLOAD",
  OPEN_WORKFLOW = "OPEN_WORKFLOW",
  COPY = "COPY",
  EDIT = "EDIT",
  DELETE = "DELETE",
}

export const WORKFLOW_TOOL_DOWNLOAD_URL = `${window._ENV.REACT_APP_WORKFLOW_TOOL_URL}/US.BOX.WorkflowDesigner.SetupWix.msi`;