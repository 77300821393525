import React, { useEffect, useState, useRef } from 'react';
import { Formik } from 'formik';
import {
	IRootState,
	IActivityExecutionBar,
	ITabSequence,
} from 'us.collection/interfaces';
import * as Actions from 'us.collection/actions';
import { connect } from 'react-redux';
import {
	$Table,
	$Input,
	$DatePicker,
	$Switch,
	$Button,
	$Select,
	$AutoComplete,
	$MessageBox,
	$Dropdown,
	$Drawer,
	$Popconfirm,
	$Tooltip,
} from 'us.common/components';
import { useTranslation } from 'react-i18next';
import { ActivityTable } from './ActivityTable';
import * as Azure from 'us.helper/azure';
import { CaseType } from 'us.helper/types/enums';
import _ from 'lodash';
import { BoxIcons, IconTypes, LoadingOutlined } from 'us.icons';
import './Home.scss';
import moment from 'moment';
import { Activity } from 'us.collection/repository';
import { checkDefaultParams } from 'us.collection/functions';
import { getCaseNumberAndType } from 'us.collection/components/ActivityExecution/Functions';
import { appInsights } from 'us.helper';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { RootState } from 'us.helper/types';

const { activityAction } = Actions;
const { activities, activity, parameters } = activityAction;

const ActivityExecutionBar: React.FC<IActivityExecutionBar> = (props) => {
	const { t } = useTranslation();
	const dropDownRef = useRef<any>();
	const activityRef = useRef<HTMLInputElement>();
	const [options, setOptions] = useState<Array<any>>([]);
	const [activityTableVisible, setActivityTableVisible] =
		useState<boolean>(false);
	const [parameterListVisible, setParameterListVisible] =
		useState<boolean>(false);
	const [visibleConfirm, setVisibleConfirm] = useState<boolean>(false);
	const [visibleDropdown, setVisibleDropdown] = useState<boolean>(false);
	const [tabSequence, setTabSequence] = useState<ITabSequence>({
		dateTabIndex: 2,
		runButton: 3,
		yesConfirm: 4,
		noConfirm: 5,
	});
	const [parameterDate, setParameterDate] = useState<
		string | moment.Moment
	>('');
	const currentUser: any = (
		window._ENV.REACT_APP_AZURE_AD_SETUP
			? new Azure.ADAuth()
			: new Azure.B2CAuth()
	).currentUser();

	const {
		accountSummeryWidgetData,
		activities,
		parameters,
		setCaseNumberAndUser,
		history,
		currentDateFormat,
		execute,
		isExecuteSuccess,
		activityDetail,
		resetActivity,
		location,
		isExecuting,
		metaData,
		userAndCase,
		setNewActivity,
		getParameters,
	} = props;

	const { case: accountCase } = accountSummeryWidgetData.data ?? {};
	const { workflowStatus } = accountCase ?? {};
	const { dataSource, initailValue } = parameters?.data ?? {};
	const { isDefault, tableData, buttonTabIndex } = dataSource ?? {};
	const { list } = activities?.data ?? {};
	const {
		displayName,
		actionType,
		executedStatus,
		scheduledTime,
		clickTime,
	} = activityDetail ?? {};

	const caseDetails = getCaseNumberAndType(location, metaData.data);
	const { type, eType, typeId } = caseDetails;

	useEffect(() => {
		if (isExecuteSuccess) {
			setParameterListVisible(false);
			toggleBodyOverlay(false);
			setParameterDate('');
		} else {
			if (typeof isDefault != 'undefined' && !isDefault) {
				setParameterListVisible(true);
				toggleBodyOverlay(true);
			} else {
				setParameterListVisible(false);
				toggleBodyOverlay(false);
			}
		}
	}, [parameters, isExecuteSuccess]);

	useEffect(() => {
		document.addEventListener(
			'keydown',
			function (e) {
				if (e.altKey && e.key == 'a') {
					activityRef.current &&
						activityRef.current.focus();
				}
			},
			true
		);
	}, []);

	useEffect(() => {
		if (!userAndCase.user) {
			setCaseNumberAndUser({
				user: currentUser?.unique_name,
				caseNumber: typeId,
				level: eType,
			});
		}
		toggleBodyOverlay(false);
	}, []);

	useEffect(() => {
		if (activityDetail.activityId) {
			resetActivity({});
		}
		toggleBodyOverlay(false);
	}, [location]);

	useEffect(() => {
		if (activityDetail.clickTime != 0) {
			activityRef.current && activityRef.current.focus();
		}
	}, [activityDetail]);

	const save = (value: any) => {
		try {
			setVisibleConfirm(false);
			const req = Activity.call(
				value,
				activities,
				activityDetail,
				tableData,
				parameterDate,
				currentUser,
				caseDetails,
				history,
				location
			);
			execute(req);
		} catch (error) {
			appInsights.trackException(
				`Activity Execution Excepetion - ${error}`
			);
		}
	};

	const closeActivityTable = () => {
		setActivityTableVisible(false);
	};

	const checkActivity = (activity: string): boolean => {
		return !list?.some(
			(item: any) =>
				item.label?.toLowerCase() ===
				activity?.toLowerCase()
		);
	};

	const validateActivity = (value: any) => {
		const { activities } = value;
		if (!checkActivity(activities)) {
			setVisibleConfirm(true);
		}
		if (
			activities?.trim().length === 0 ||
			activities === null ||
			typeof activities == 'undefined'
		) {
			$MessageBox(
				'error',
				'US.COLLECTION.COMMON:ACTIVITYEXECUTION.PLEASE_SELECT_AN_ACTIVITY',
				'',
				''
			);
		} else {
			if (
				!list?.some(
					(item: any) =>
						item.label?.toLowerCase() ===
						activities?.toLowerCase()
				)
			) {
				$MessageBox(
					'error',
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
					'',
					''
				);
			}
		}
	};

	const activityDropDownItem = (value: string, label: string) => {
		try {
			return {
				label: (
					<div className='py-1'>
						<span className='d-block'>{`${t(
							'US.COLLECTION.COMMON:ACTIVITYEXECUTION.CODE'
						).toString()}: ${value}`}</span>
						<span className='d-block'>{`${t(
							'US.COLLECTION.COMMON:ACTIVITYEXECUTION.NAME'
						).toString()}: ${label} `}</span>
					</div>
				),
				value: label,
			};
		} catch (error) {
			appInsights.trackException(
				`Activity Drop Down Excepetion - ${error}`
			);
			return {
				label: <></>,
				value: '',
			};
		}
	};

	const parametersColumns: any = [
		{
			title: t(
				'US.COLLECTION.COMMON:ACTIVITYEXECUTION.PARAMETER_NAME'
			),
			dataIndex: 'name',
			key: 'name',
			width: 150,
			className: 'text-nowrap',
			ellipsis: true,
		},
		{
			title: t(
				'US.COLLECTION.COMMON:ACTIVITYEXECUTION.PARAMETER_DATA_TYPE'
			),
			dataIndex: 'parameterType',
			key: 'parameterType',
			width: 150,
			className: 'text-nowrap',
			ellipsis: true,
		},
		{
			title: t(
				'US.COLLECTION.COMMON:ACTIVITYEXECUTION.VALUE'
			),
			dataIndex: 'value',
			key: 'value',
			className: 'parameters-value',
			render: (text: any, record: any) => {
				const {
					field,
					name,
					value,
					tabIndex,
					options,
				} = record;
				const autoFocus = tabIndex === 1;
				return (
					<>
						{field === 'input' &&
							!checkDefaultParams(
								name?.toLowerCase(),
								eType
							) &&
							record?.hasOwnProperty(
								'tabIndex'
							) && (
								<div>
									<$Input
										name={
											name
										}
										size='small'
										tabIndex={
											tabIndex
										}
										style={{
											width: '100%',
											marginBottom:
												'-5px',
										}}
										autoFocus={
											autoFocus
										}
									/>
								</div>
							)}
						{field === 'input' &&
							checkDefaultParams(
								name?.toLowerCase(),
								eType
							) && (
								<div>
									<$Input
										name={
											name
										}
										size='small'
										disabled
										style={{
											width: '100%',
											marginBottom:
												'-5px',
										}}
									/>
								</div>
							)}
						{field === 'datePicker' && (
							<div>
								<$DatePicker
									name={
										name
									}
									allowClear={
										true
									}
									size='small'
									tabIndex={
										tabIndex
									}
									placeholder={`${currentDateFormat}`}
									format={
										currentDateFormat
									}
									defaultValue={
										value
									}
									value={
										parameterDate ==
										''
											? value
											: parameterDate
									}
									onChange={(
										e: any
									) =>
										setParameterDate(
											e
										)
									}
									style={{
										width: '100%',
										marginBottom:
											'-5px',
									}}
									autoFocus={
										autoFocus
									}
								/>
							</div>
						)}
						{field === 'switch' && (
							<div className='d-flex align-items-center mb-1'>
								<$Switch
									name={
										name
									}
									tabIndex={
										tabIndex
									}
									autoFocus={
										autoFocus
									}
								/>
							</div>
						)}
						{field === 'select' && (
							<div>
								<$Select
									name={
										name
									}
									allOption={
										false
									}
									optionText='label'
									optionValue='value'
									style={{
										width: '100%',
										margin: '4px 0 7px 0',
									}}
									options={
										options
									}
									onSearchBy={[
										'label',
									]}
									dropdownMatchSelectWidth={
										false
									}
									tabIndex={
										tabIndex
									}
									autoFocus={
										autoFocus
									}
								/>
							</div>
						)}
					</>
				);
			},
		},
	];

	const onActivityChange = (searchValue: any) => {
		try {
			const opts: any = [];
			if (searchValue == '') {
				setParameterListVisible(false);
				toggleBodyOverlay(false);
			} else if (searchValue === ' ') {
				setVisibleDropdown(true);
				list?.map(({ value, label }: any) => {
					opts.push(
						activityDropDownItem(
							value,
							label
						)
					);
				});
			} else if (searchValue?.length > 0) {
				const temArr = list?.filter(
					({ value, label }: any) =>
						label
							?.toLowerCase()
							.includes(
								searchValue?.toLowerCase()
							) ||
						value
							?.toLowerCase()
							.includes(
								searchValue?.toLowerCase()
							)
				);
				temArr?.map(({ value, label }: any) => {
					opts.push(
						activityDropDownItem(
							value,
							label
						)
					);
				});
				if (temArr?.length > 0) {
					setVisibleDropdown(true);
				}
			}
			setOptions(opts);
		} catch (error) {
			appInsights.trackException(
				`Activity Drop Down Handling Excepetion - ${error}`
			);
		}
	};

	const handleSearchData = (searchValue: string) => {
		try {
			setVisibleDropdown(false);
			const activityId = list?.filter(
				(item: any) => item.label === searchValue
			)[0]?.activityId;
			setNewActivity &&
				setNewActivity({
					displayName: searchValue,
					activityId,
				});
			getParameters &&
				getParameters({
					activityId,
					state:
						type === CaseType.C ||
						type === CaseType.S
							? workflowStatus
							: 'defaultState',
				});
		} catch (error) {
			appInsights.trackException(
				`Activity Select Exeception - ${error}`
			);
		}
	};

	const CloseParamsList = () => {
		setParameterListVisible(false);
		toggleBodyOverlay(false);
		resetActivity({});
	};

	const paramsMenu = (values: any) => {
		const { executingDateTime } = values;
		const { dateIndex, runIndex, cancelIndex } =
			buttonTabIndex ?? {};
		return (
			<div
				className='parameters-dropdown'
				style={{ width: 600 }}>
				<$Table
					rowKey='id'
					columns={parametersColumns}
					dataSource={tableData}
					className='parameters mt-1 mb-3'
					size='small'
					pagination={false}
					bordered
				/>

				<div className='d-flex justify-content-end mt-2'>
					<$Tooltip
						placement='topLeft'
						title={t(
							'US.COLLECTION.COMMON:ACTIVITYEXECUTION.SCHEDULE_DATE_AND_TIME'
						)}>
						<$DatePicker
							name='executingDateTime'
							placeholder={`${currentDateFormat} HH:MM`}
							allowClear={true}
							value={
								moment(
									executingDateTime
								).isValid()
									? executingDateTime
									: ''
							}
							format={`${currentDateFormat} HH:mm`}
							style={{
								width: '200px',
							}}
							showTime={{
								format: 'HH:mm',
							}}
							tabIndex={dateIndex}
							className='mr-3'
							disabledDate={(
								date: any
							) =>
								!date ||
								date.isBefore(
									moment().startOf(
										'day'
									)
								)
							}
						/>
					</$Tooltip>
					<$Button
						type='primary'
						className='mr-2'
						style={{ width: 90 }}
						icon={
							<>
								{typeof isDefault !=
									'undefined' &&
									!isDefault &&
									isExecuting && (
										<LoadingOutlined
											style={{
												fontSize: 18,
											}}
										/>
									)}
								{!(
									typeof isDefault !=
										'undefined' &&
									!isDefault &&
									isExecuting
								) && (
									<BoxIcons
										type={
											IconTypes.BOX_ICON
										}
										name='run-activity'
									/>
								)}
							</>
						}
						onClick={() => save(values)}
						tabIndex={runIndex}>
						<span className='ml-1'>
							{t(
								'US.COLLECTION.COMMON:ACTIVITYEXECUTION.RUN'
							)}
						</span>
					</$Button>
					<$Button
						className=''
						onClick={CloseParamsList}
						tabIndex={cancelIndex}>
						{t(
							'US.COLLECTION.COMMON:COMMON.CANCEL'
						)}
					</$Button>
				</div>
			</div>
		);
	};

	const elem: HTMLElement = document.getElementById(
		'hiddenOverlay'
	) as HTMLElement;
	const addBodyClass = (className: any) =>
		document.body.classList.add(className);
	const removeBodyClass = (className: any) =>
		document.body.classList.remove(className);
	const toggleBodyOverlay = (show: any) =>
		show === true
			? addBodyClass('hidden-overlay-open')
			: removeBodyClass('hidden-overlay-open');

	const pressKey = (key: any) => {
		if (key === 'Enter') {
			if (typeof isDefault != 'undefined') {
				if (isDefault) {
					setVisibleConfirm(true);
				}
				setTabSequence({
					dateTabIndex: -1,
					runButton: -1,
					noConfirm: 3,
					yesConfirm: 2,
				});
			}
		} else {
			setTabSequence({
				dateTabIndex: 2,
				runButton: 3,
				noConfirm: 5,
				yesConfirm: 4,
			});
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				activities: displayName,
				executingDateTime:
					actionType === 'editActivity' &&
					executedStatus != 'executed'
						? moment(scheduledTime)
						: '',
				...initailValue,
				refresh: clickTime,
			}}
			onSubmit={save}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				setFieldValue,
				...rest
			}: any) => (
				<div className='activity-bar'>
					<div className='activity-bar-select'>
						<$Button
							data-testid='activityExecution-activityTable'
							type='link'
							className='activity-bar-select-before'
							onClick={() =>
								setActivityTableVisible(
									true
								)
							}
							disabled={
								metaData.data &&
								isSubCaseTransferredToCase(
									metaData.data
								)
							}>
							<BoxIcons
								type={
									IconTypes.BOX_ICON
								}
								name='select-activity'
							/>
						</$Button>
						<$AutoComplete
							style={{ width: 340 }}
							placeholder={t(
								'US.COLLECTION.COMMON:ACTIVITYEXECUTION.RUNACTIVITY'
							)}
							name='activities'
							ref={activityRef}
							onSearch={(
								searchValue: string
							) =>
								onActivityChange(
									searchValue
								)
							}
							options={options}
							onSelect={(
								inputValue: string
							) =>
								handleSearchData(
									inputValue
								)
							}
							className='activity-bar-select-input mb-0'
							allowClear={true}
							open={visibleDropdown}
							tabIndex={1}
							onKeyPress={(e: any) =>
								pressKey(e?.key)
							}
							onDropdownVisibleChange={() =>
								visibleDropdown &&
								setVisibleDropdown(
									false
								)
							}
							data-testid='activityExecution-activityList'
							disabled={
								metaData.data &&
								isSubCaseTransferredToCase(
									metaData.data
								)
							}
						/>
						<$Dropdown
							getPopupContainer={() =>
								elem
							}
							overlay={paramsMenu(
								values
							)}
							placement='bottomLeft'
							disabled={
								typeof isDefault ===
									'undefined' ||
								isDefault
							}
							trigger={['click']}
							arrow
							visible={
								parameterListVisible
							}>
							<span className='parameters-dropdown-btn'></span>
						</$Dropdown>
					</div>

					<$Tooltip
						placement='topLeft'
						title={t(
							'US.COLLECTION.COMMON:ACTIVITYEXECUTION.SCHEDULE_DATE_AND_TIME'
						)}>
						<$DatePicker
							placeholder={`${currentDateFormat} HH:MM`}
							name='executingDateTime'
							showTime={{
								format: 'HH:mm',
							}}
							format={`${currentDateFormat} HH:mm`}
							allowClear={true}
							clssName='mb-0'
							style={{ width: 190 }}
							value={
								typeof isDefault !=
									'undefined' &&
								isDefault &&
								moment(
									values?.executingDateTime
								).isValid()
									? values?.executingDateTime
									: ''
							}
							disabledDate={(
								d: any
							) =>
								!d ||
								d.isBefore(
									moment().startOf(
										'day'
									)
								)
							}
							disabled={
								(typeof isDefault !=
									'undefined' &&
									!isDefault) ||
								(metaData.data &&
									isSubCaseTransferredToCase(
										metaData.data
									))
							}
							tabIndex={
								tabSequence.dateTabIndex
							}
							data-testid='activityExecution-executingDateTime'
						/>
					</$Tooltip>
					<$Popconfirm
						placement='right'
						id='popConfirmId'
						ref={dropDownRef}
						title={`${t(
							'US.COLLECTION.COMMON:ACTIVITYEXECUTION.ARE_YOU_SURE_YOU_WANT_TO_EXECUTE_THIS_ACTIVITY'
						)}?`}
						onConfirm={handleSubmit}
						onCancel={() =>
							setVisibleConfirm(false)
						}
						okText={t(
							'US.COLLECTION.COMMON:COMMON.YES'
						)}
						cancelText={t(
							'US.COLLECTION.COMMON:COMMON.NO'
						)}
						okButtonProps={{
							tabIndex: tabSequence.yesConfirm,
							autoFocus: true,
						}}
						cancelButtonProps={{
							tabIndex: tabSequence.noConfirm,
						}}
						onVisibleChange={() =>
							visibleConfirm &&
							setVisibleConfirm(false)
						}
						visible={visibleConfirm}>
						<div className='d-flex flex-1 align-items-top'>
							<div className='mr-3'>
								<$Button
									className='ml-1'
									data-testid='activityExecution-run'
									onClick={() =>
										validateActivity(
											values
										)
									}
									disabled={
										(typeof isDefault !=
											'undefined' &&
											!isDefault) ||
										(metaData.data &&
											isSubCaseTransferredToCase(
												metaData.data
											))
									}
									tabIndex={
										tabSequence.runButton
									}>
									{typeof isDefault !=
										'undefined' &&
										isDefault &&
										isExecuting && (
											<LoadingOutlined
												style={{
													fontSize: 18,
												}}
												spin
											/>
										)}
									{!(
										typeof isDefault !=
											'undefined' &&
										isDefault &&
										isExecuting
									) && (
										<BoxIcons
											type={
												IconTypes.BOX_ICON
											}
											name='run-activity'
										/>
									)}
								</$Button>
							</div>
						</div>
					</$Popconfirm>
					<$Drawer
						title={t(
							'US.COLLECTION.COMMON:ACTIVITYEXECUTION.SELECT_ACTIVITY'
						)}
						width={1100}
						visible={activityTableVisible}
						onClose={closeActivityTable}
						destroyOnClose
						className='select-activity'>
						<ActivityTable
							close={
								closeActivityTable
							}
							handleSearchData={
								handleSearchData
							}
						/>
					</$Drawer>
				</div>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, dashboard, activity, domainView } = state;
	const { currentLanguage, currentDateFormat } = common;
	const { accountSummeryWidgetData } = dashboard;
	const { metaData } = domainView;
	const {
		activities,
		parameters,
		isExecuteSuccess,
		activityDetail,
		isExecuting,
		userAndCase,
	} = activity;
	return {
		currentLanguage,
		currentDateFormat,
		accountSummeryWidgetData,
		activities,
		metaData,
		parameters,
		isExecuteSuccess,
		activityDetail,
		isExecuting,
		userAndCase,
	};
};

const mapDispatchToProps = {
	getParameters: parameters.get,
	setCaseNumberAndUser: activities.set,
	execute: activity.save,
	resetActivity: activity.reset,
	setNewActivity: activity.set,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ActivityExecutionBar);
