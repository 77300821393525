import { EntityType } from "us.collection/components/BMD/Constants";

/**
 * @function
 * @description - To decide if the next button should be displayed
 * @param {EntityType} entityType - The type of the tab we are opened in
 * @param {*} creditors - the list of linked credtiors
 * @param {boolean} showCreditorView - the form decision if we need to show the creditor view
 * @return {*}  {boolean} - the decision if we need the next button to be shown
 */
export const shouldTheNextBtnBeShown = (
  entityType: EntityType,
  creditors: any,
  showCreditorView: boolean
): boolean => entityType === EntityType.CREDITOR && creditors.length > 0 && !showCreditorView;
