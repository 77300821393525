export enum CasesAndSubCases {
  GET_CASES_AND_SUBCASES = "GET_CASES_AND_SUBCASES",
  GET_CASES_AND_SUBCASES_SUCCESS = "GET_CASES_AND_SUBCASES_SUCCESS",
  GET_CASES_AND_SUBCASES_FAIL = "GET_CASES_AND_SUBCASES_FAIL",

  CREATE_CASES = "CREATE_CASES",
  CREATE_CASES_SUCCESS = "CREATE_CASES_SUCCESS",
  CREATE_CASES_FAIL = "CREATE_CASES_FAIL",

  SAVE_MERGECASES = "SAVE_MERGECASES",
  SAVE_MERGECASES_SUCCESS = "SAVE_MERGECASES_SUCCESS",
  SAVE_MERGECASES_FAIL = "SAVE_MERGECASES_FAIL",

  MANAGE_DRAWER = "MANAGE_DRAWER",

  UPDATE_DUEDATE = "UPDATE_DUEDATE",
  UPDATE_DUEDATE_SUCCESS = "UPDATE_DUEDATE_SUCCESS",
  UPDATE_DUEDATE_FAIL = "UPDATE_DUEDATE_FAIL",

  SPLIT_SUBCASES = "SPLIT_SUBCASES",
  SPLIT_SUBCASES_SUCCESS = "SPLIT_SUBCASES_SUCCESS",
  SPLIT_SUBCASES_FAIL = "SPLIT_SUBCASES_FAIL",

  SET_SELECTED_CASES_AND_SUBCASES = "SET_SELECTED_CASES_AND_SUBCASES"
}