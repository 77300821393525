import React from 'react';
import Common from 'us.common';

const { $Input, $Col } = Common.Components;

interface IBase {
	labelName: string;
	name: string;
	maxlength?: number;
	required?: boolean;
}

export const AccountNumber: React.FC<IBase> = (props) => {
	const { labelName, name, maxlength, required } = props;

	return (
		<$Col span={12}>
			<div
				className='flex-fill'
				data-testid={`paid-accouts-${name}`}>
				<$Input
					size='small'
					label={labelName}
					name={name}
					maxLength={maxlength}
					required={required}
				/>
			</div>
		</$Col>
	);
};
