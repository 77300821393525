import { IBMDDataType } from "us.collection/interfaces";
import * as Yup from "yup";

/**
 * @constant
 * @description - the validatoin object used in Add edit bmd data type form
 */
export default Yup.object<IBMDDataType & Yup.MixedSchema>().shape({
  name: Yup.string().required("US.COLLECTION.VALIDATIONS:REQUIRED.NAME_IS_REQUIRED"),
  storedProcedure: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.STORED_PROCEDURE_NAME_IS_REQUIRED"
  ),
});
