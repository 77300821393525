import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

import Common from 'us.common';
import './ClaimOfPayment.scss';
import { InfoCircleOutlined, UploadOutlined } from 'us.icons';
import { COPValidation } from 'us.collection.case/components/Validations';
import { ObjectionAndAgreementsAction } from 'us.collection.case/actions';
import { IClaimOfPayment } from 'us.collection.case/components/Interfaces/Objection/Objection';
import { AccountNumber } from 'us.collection.case/components/Detail/ObjectionAndAgreement/Components/ClaimOfPayment/Components';
import {
	ClaimOfPaymentRequest,
	InitialDataCOP,
} from 'us.collection.case/repository';
import { caseDocuments } from 'us.collection.documents/actions';
import { getLookUpTypeByCaseType } from 'us.collection/functions';
import { IOnFilter, IOnSort } from 'us.common/components';
import { appInsights } from 'us.helper';
import { RootState } from 'us.helper/types';

const {
	$Form,
	$Input,
	$Button,
	$Select,
	$DatePicker,
	$Popconfirm,
	$Table,
	$Row,
	$Col,
	$Typography,
	$TextArea,
	$Upload,
	$AutoComplete,
	$AmountLabel,
	$InputAmount,
	$Radio,
	$Skeleton,
	$TableTree,
} = Common.Components;

const { Text } = $Typography;
const { Dragger } = $Upload;

const ClaimOfPayment: React.FC<IClaimOfPayment> = (props) => {
	const { t } = useTranslation();

	const [selectedData, setSelectedData] = useState<any>();
	const [selectRowKeys, setSelectRowKeys] = useState<any>([]);
	const [fileList, setFileList] = useState<Array<any>>([]);
	const [fileStatus, setFileStatus] = useState<string | undefined>(
		undefined
	);
	const [isInvoiceSelected, setIsInvoiceSelected] = useState(false);

	const {
		onClose,
		currentCurrency,
		currentDateFormat,
		currentLanguage,
		metaData,
		debtorWidgetData,
		caseInfoWidgetData,
		accountSummeryWidgetData,
		claimOfPaymentInitial,
		save,
		getInitialData,
		openDrawer,
		saveCaseDocuments,
	} = props;

	const { caseId } = metaData.data ?? {};

	useEffect(() => {
		if (
			debtorWidgetData.data &&
			caseInfoWidgetData.data &&
			accountSummeryWidgetData.data
		) {
			getInitialData(
				InitialDataCOP.call({
					caseProps: caseInfoWidgetData?.data,
					caseId: accountSummeryWidgetData.data
						?.case?.caseId,
					entity: debtorWidgetData?.data,
				})
			);
		}
	}, [debtorWidgetData, caseInfoWidgetData, accountSummeryWidgetData]);

	/**
	 * Select invoices in table
	 * @param selectedRowKeys
	 * @param selectedRows
	 */
	const onSelectChange = (selectedRowKeys: any, selectedRows: any[]) => {
		try {
			if (claimOfPaymentInitial?.invoiceNumber == 0) {
				setSelectRowKeys(selectedRowKeys);
				setSelectedData(selectedRows);
			} else {
				setSelectRowKeys(
					claimOfPaymentInitial?.invoiceNumber
				);
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard ClaimOfPayment Invoice Select Exeception - ${error}`
			);
		}
	};

	const rowSelection = {
		onChange: onSelectChange,
		selectedRowKeys:
			claimOfPaymentInitial?.invoiceNumber === 0
				? selectRowKeys
				: [claimOfPaymentInitial?.invoiceNumber],
	};

	const handleFileStatus = (message: string): void => {
		try {
			setFileStatus(message);
			setTimeout(function () {
				setFileStatus(undefined);
			}, 3000);
		} catch (error) {
			appInsights.trackException(
				`Dashboard ClaimOfPayment handleFileStatus Exeception - ${error}`
			);
		}
	};

	const uploadProps = {
		name: 'fileUpload',
		multiple: false,
		className: 'text-left px-3 mb-2',
		onChange: (file: any) => {
			const formData = new FormData();
			formData.append('files[]', file);
		},
		onRemove: (file: any) => {
			setFileList(
				fileList.flatMap((fileItem) =>
					fileItem.name === file.name
						? []
						: fileItem
				)
			);
		},
		beforeUpload: (file: any, list: any) => {
			if (
				['pdf', 'jpeg', 'png', 'jpg'].indexOf(
					file.name.toLowerCase().split('.').pop()
				) < 0
			) {
				handleFileStatus('UNSUPPORTED_FILE_TYPE');
			} else if (fileList.length >= 3) {
				handleFileStatus(
					'MAXIMUM_NUMBER_OF_FILES_ARE_EXCEEDED'
				);
			} else if (
				fileList.findIndex(
					(x) => x.name === file.name
				) >= 0
			) {
				handleFileStatus('FILE_ALREADY_EXISTS');
			} else if (file.size / 1024 / 1024 > 5) {
				handleFileStatus('FILE_SIZE_EXCEEDED');
			} else {
				setFileList([...fileList, ...list]);
			}
			return false;
		},
		fileList,
	};

	const submitFiles = (
		noteText: any,
		addToHistory: any,
		documentType: any
	) => {
		try {
			const { params }: any = matchPath(location.pathname, {
				path: '/:type/:id?/:module?',
				exact: false,
				strict: false,
			});
			const entityType = params['type'];

			saveCaseDocuments(fileList, {
				entityType: getLookUpTypeByCaseType(entityType),
				entityId: caseId,
				noteText,
				addToHistory,
				documentType,
			});
		} catch (error) {
			appInsights.trackException(
				`Dashboard ClaimOfPayment Submit files Exception - ${error}`
			);
		}
	};

	const handleClaimOfPaymentSubmit = (data: any, { resetForm }: any) => {
		try {
			if (
				selectRowKeys?.length === 0 &&
				claimOfPaymentInitial?.invoiceNumber === 0
			) {
				setIsInvoiceSelected(true);
			} else {
				let table: any[] = [];
				const fileListArray: Array<any> = [];

				claimOfPaymentInitial?.invoiceNumber === 0
					? (table = selectedData)
					: (table =
							claimOfPaymentInitial?.invoicetable);

				table = table?.map((row: any) => {
					const {
						invoiceNo,
						kid,
						amount,
						paid,
						balance,
					} = row;
					return {
						invoiceNo,
						kid,
						amount,
						paidAmount: paid,
						balance,
					};
				});

				resetForm({ claimOfPaymentInitial });
				onClose();

				const requestParam = ClaimOfPaymentRequest.call(
					data,
					accountSummeryWidgetData.data?.case
						?.caseId,
					table,
					fileListArray
				);
				const requestObject = {
					requestParam,
					metaData: metaData.data,
				};
				save(requestObject);

				if (fileList && fileList.length) {
					submitFiles(
						data.caseNote,
						true,
						'Objection'
					);
				}
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard ClaimOfPayment Submit Exception - ${error}`
			);
		}
	};
	/**
	 * Invoice table validation enable in not row selection
	 */
	const invoiceTableValidation = () => {
		if (
			selectRowKeys?.length <= 0 &&
			claimOfPaymentInitial?.invoiceNumber == 0
		) {
			setIsInvoiceSelected(true);
		}
	};

	const closeDrawerView = () => {
		openDrawer && openDrawer({ title: '', visible: false });
		onClose();
	};

	const handleSort: IOnSort = (sortData, dataSource) => {
		return sortData(dataSource);
	};

	const handleFilter: IOnFilter = (searchData, dataSource) => {
		return searchData(dataSource);
	};

	const columns: any = [
		{
			title: t(
				'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.INVOICE_NO'
			),
			dataIndex: 'invoiceNo',
			key: 'invoiceNo',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
			customRenderChild: (text: any, record: any) => {
				const { invoiceNo, kid } = record ?? {};
				return (
					<div>
						{invoiceNo}
						<small className='d-block'>
							{t(
								'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.KID'
							)}{' '}
							-{kid}
						</small>
					</div>
				);
			},
		},
		{
			title: t('US.COLLECTION.COMMON:COMMON.AMOUNT'),
			dataIndex: 'amount',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return <$AmountLabel value={record?.amount} />;
			},
		},
		{
			title: t(
				'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.PAID'
			),
			dataIndex: 'paid',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return <$AmountLabel value={record?.paid} />;
			},
		},
		{
			title: t('US.COLLECTION.COMMON:COMMON.BALANCE'),
			dataIndex: 'balance',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return <$AmountLabel value={record?.balance} />;
			},
		},
	];

	const handleEmailEdit = (emailValue: any, restProps: any) => {
		if (
			emailValue?.length === 0 &&
			claimOfPaymentInitial?.emailInitial?.length
		) {
			restProps.setFieldValue(
				'emailAddress',
				claimOfPaymentInitial?.emailInitial
			);
		}
	};

	const handleSMSEdit = (smsValue: any, restProps: any) => {
		if (
			smsValue?.length === 0 &&
			claimOfPaymentInitial?.smsInitial?.length
		) {
			restProps.setFieldValue(
				'smsNumber',
				claimOfPaymentInitial?.smsInitial
			);
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={claimOfPaymentInitial}
			validationSchema={COPValidation}
			onSubmit={handleClaimOfPaymentSubmit}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				isValid,
				resetForm,
				...restProps
			}: any) => (
				<$Form>
					<div className='claim-of-payment'>
						<div className='mb-2'>
							<strong data-testid='debtor-has-paid-to'>
								{t(
									'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.DEBTOR_HAS_PAID_TO'
								)}
							</strong>
						</div>
						<$Row
							gutter={16}
							className='mb-3'>
							<$Col
								span={24}
								data-testid='radio-has-paid-to'>
								<$Radio
									className='pay-to'
									onChange={(
										e: any
									) => {
										restProps.setFieldValue(
											'paidTo',
											e
												.target
												.value
										);
									}}
									value={
										values.paidTo
									}
									defaultValue={
										values.paidTo
									}
									name='paidTo'
									options={[
										{
											value: '1',
											label: values.creditorName,
										},
										{
											value: '2',
											label: t(
												'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.UNICORN_COLLECTION_AS'
											),
										},
									]}
									optionValue='value'
									optionText='label'
								/>
							</$Col>
						</$Row>

						<$Row
							gutter={16}
							className='mb-3'>
							<$Col span={7}>
								<div
									className='flex-fill'
									data-testid='date-of-payment-label'>
									<$DatePicker
										formitem
										required
										allowClear
										data-testid='date-of-payment'
										label={t(
											'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.DATE_OF_PAYMENT'
										)}
										name='paidDate'
										size='small'
										style={{
											width: '100%',
										}}
										format={
											currentDateFormat
										}
										defaultValue={
											values.paidDate
										}
										value={
											values.paidDate
										}
										disabledDate={(
											day: any
										) =>
											day.isAfter(
												moment().endOf(
													'day'
												)
											)
										}
									/>
								</div>
							</$Col>
							<$Col span={7}>
								<div
									className='flex-fill'
									data-testid='paid-amount-label'>
									<$InputAmount
										required
										size='small'
										data-testid='paid-amount'
										className='w-100'
										label={t(
											'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.AMOUNT_PAID'
										)}
										name='amountPaid'
										placeholder='0.00'
										currentLanguage={
											currentLanguage
										}
										currentCurrency={
											currentCurrency
										}
									/>
								</div>
							</$Col>
							<$Col span={10}>
								<div
									className='flex-fill'
									data-testid='payer-name-lebal'>
									<$Input
										formitem
										size='small'
										label={t(
											'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.PAYERS_NAME'
										)}
										name='payerName'
									/>
								</div>
							</$Col>
						</$Row>

						<label className='required'>
							<strong data-testid='select-invoice-label'>
								{t(
									'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.SELECT_INVOICE'
								)}
							</strong>
						</label>
						<div
							className='mt-2 mb-4'
							data-testid='invoice-table'>
							<$Skeleton
								active
								loading={
									!values
										.invoicetable
										?.length
								}
								paragraph={{
									rows: 2,
								}}>
								<$TableTree
									rowKey='key'
									data={
										values.invoicetable
									}
									size='small'
									className='mt-3'
									onSort={
										handleSort
									}
									onFilter={
										handleFilter
									}
									filterOnType={
										true
									}
									resetOnSourceChange={
										true
									}
									bordered
									pagination={
										false
									}
									scroll={{
										x: 700,
									}}
									columns={
										columns
									}
									rowSelection={
										rowSelection
									}
									firstColSkipFilterProps={
										-1
									}
								/>
							</$Skeleton>
							{isInvoiceSelected &&
								selectRowKeys?.length ===
									0 && (
									<div className='ant-form-item-explain ant-form-item-explain-error mt-1'>
										{t(
											'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.PLEASE_SELECT_AT_LEAST_ONE_INVOICE'
										)}
									</div>
								)}
						</div>
						<$Row gutter={16}>
							<$Col span={12}>
								<div
									className='flex-fill'
									data-testid='payment-method-label'>
									<$Select
										formitem={{
											label: t(
												'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.PAYMENT_METHOD'
											),
										}}
										data-testid='payment-method'
										name='paymentMethod'
										size='small'
										defaultValue='1'
										required
										allOption={
											false
										}
										options={[
											{
												label: t(
													'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.CASH'
												),
												value: '1',
											},
											{
												label: t(
													'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.CARD_PAYMENT'
												),
												value: '2',
											},
										]}
									/>
								</div>
							</$Col>
							{values.paymentMethod ==
								'2' && (
								<AccountNumber
									labelName={t(
										'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.CARD_NUMBER'
									)}
									name='cardNumber'
									maxlength={
										4
									}
									required
								/>
							)}
						</$Row>
						<$Row gutter={16}>
							<AccountNumber
								labelName={t(
									'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.PAID_FROM'
								)}
								name='paidFromAccount'
							/>
							<AccountNumber
								labelName={t(
									'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.PAID_TO'
								)}
								name='paidToAccount'
							/>
						</$Row>

						<$Row
							gutter={16}
							className='mb-4'>
							<$Col span={12}>
								<div
									className='flex-fill'
									data-testid='kid-numbers-label'>
									<$AutoComplete
										size='small'
										label={t(
											'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.KID_NUMBER'
										)}
										name='kidNumber'
										options={
											values.kidNumberList
										}
										filterOption={(
											inputValue: any,
											option: any
										) =>
											option!.value
												.toLowerCase()
												.indexOf(
													inputValue.toLowerCase()
												) !==
											-1
										}
									/>
								</div>
							</$Col>
						</$Row>

						<label className='required'>
							<strong data-testid='originated-in-label'>
								{t(
									'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.ORIGINATED_IN'
								)}
							</strong>
						</label>
						<$Row
							gutter={16}
							className='mt-1 mb-4'
							data-testid='communication-method'>
							<$Col span={24}>
								<$Radio
									onChange={(
										e: any
									) => {
										restProps.setFieldValue(
											'communicationMethod',
											e
												.target
												.value
										);
									}}
									options={[
										{
											value: '1',
											label: t(
												'US.COLLECTION.COMMON:COMMON.TELEPHONE'
											),
										},
										{
											value: '2',
											label: t(
												'US.COLLECTION.COMMON:COMMON.EMAIL'
											),
										},
										// { value: "3", label: "Portal", },
										{
											value: '4',
											label: t(
												'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.POST'
											),
										},
									]}
									name='communicationMethod'
									optionValue='value'
									optionText='label'
								/>
							</$Col>
						</$Row>

						<div className='mb-2'>
							<strong data-testid='case-note-label'>
								{t(
									'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.CASE_NOTE'
								)}
							</strong>
						</div>
						<$Row
							gutter={16}
							className='mb-4'>
							<$Col
								span={24}
								data-testid='case-note'>
								<$TextArea
									name='caseNote'
									autoSize={{
										minRows: 2,
										maxRows: 6,
									}}
								/>
							</$Col>
						</$Row>

						<div className='mb-2'>
							<Text type='warning'>
								<InfoCircleOutlined className='mr-2' />
							</Text>
							<strong>
								<small data-testid='telephone-warning'>
									{t(
										'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.TELEPHONE_WARNING'
									)}
								</small>
							</strong>
						</div>
						{/* Register your telephone number and e-mail to receive confirmation of your inquiry */}
						<$Row
							gutter={16}
							className='mb-4'>
							<$Col span={8}>
								<div
									className='flex-fill'
									data-testid='sms-number-label'>
									<$Input
										formitem
										size='small'
										label={t(
											'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.SMS_NUMBER'
										)}
										name='smsNumber'
										onBlur={(
											e: any
										) =>
											handleSMSEdit(
												e
													.target
													.value,
												restProps
											)
										}
									/>
								</div>
							</$Col>
							<$Col span={16}>
								<div
									className='flex-fill'
									data-testid='email-label'>
									<$Input
										formitem
										size='small'
										label={t(
											'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.EMAIL_ADDRESS'
										)}
										name='emailAddress'
										onBlur={(
											e: any
										) =>
											handleEmailEdit(
												e
													.target
													.value,
												restProps
											)
										}
									/>
								</div>
							</$Col>
						</$Row>

						<$Row
							gutter={16}
							className='mb-5'>
							<$Col span={24}>
								<div className='mb-2'>
									<strong data-testid='upload-files-label'>
										{t(
											'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.UPLOAD_FILES'
										)}

										(
										{
											fileList.length
										}
										/3)
									</strong>
								</div>
								{fileStatus && (
									<div className='ant-form-item-explain ant-form-item-explain-error mb-2'>
										{t(
											`US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.${fileStatus}`
										)}
									</div>
								)}
								<div>
									<Dragger
										{...uploadProps}>
										<div className='d-flex align-items-center'>
											<p className='ant-upload-drag-icon mb-0 mr-3'>
												<UploadOutlined />
											</p>
											<div>
												<p
													className='ant-upload-text mb-0'
													data-testid='select-file-label'>
													{t(
														'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.SELECT_A_FILE'
													)}
												</p>
												<div>
													<Text
														type='secondary'
														data-testid='uplode-file-type'>
														{t(
															'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.UPLOAD_FILETYPE'
														)}
													</Text>
												</div>
												{/* Only allowed jpg, jpeg, png, pdf (Maximum upload size 5MB) */}
											</div>
										</div>
									</Dragger>
								</div>
							</$Col>
						</$Row>

						<div className='drawer-footer-fixed align-content-center justify-content-end'>
							<div>
								<$Button
									className='mr-2'
									onClick={() => {
										handleSubmit(),
											invoiceTableValidation();
									}}
									type='primary'
									htmlType='submit'
									data-testid='save-btn'>
									{t(
										'US.COLLECTION.COMMON:COMMON.SAVE'
									)}
								</$Button>
								{restProps.dirty && (
									<$Popconfirm
										title={t(
											'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.CANCEL_CONFORM'
										)}
										// "Your existing data will be lost. Do you wish to continue?"
										placement='topLeft'
										onConfirm={() =>
											closeDrawerView()
										}
										okText={t(
											'US.COLLECTION.COMMON:COMMON.YES'
										)}
										cancelText={t(
											'US.COLLECTION.COMMON:COMMON.NO'
										)}>
										<$Button>
											{t(
												'US.COLLECTION.COMMON:COMMON.CANCEL'
											)}
										</$Button>
									</$Popconfirm>
								)}
								{!restProps.dirty && (
									<$Button
										onClick={() =>
											closeDrawerView()
										}>
										{t(
											'US.COLLECTION.COMMON:COMMON.CANCEL'
										)}
									</$Button>
								)}
							</div>
						</div>
					</div>
				</$Form>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, dashboard, objectionAndAgreements, domainView } = state;
	const { currentCurrency, currentLanguage, currentDateFormat } = common;
	const {
		debtorWidgetData,
		caseInfoWidgetData,
		accountSummeryWidgetData,
	} = dashboard;
	const { metaData } = domainView;
	const { claimOfPaymentInitial } = objectionAndAgreements;
	return {
		currentDateFormat,
		currentCurrency,
		currentLanguage,
		metaData,
		debtorWidgetData,
		caseInfoWidgetData,
		accountSummeryWidgetData,
		claimOfPaymentInitial,
	};
};

const { claimOfPayment } = ObjectionAndAgreementsAction;
const { documents, rules } = caseDocuments;

const mapDispatchToProps = {
	save: claimOfPayment.save,
	getInitialData: claimOfPayment.get,
	openDrawer: claimOfPayment.openDrawer,
	saveCaseDocuments: documents.upload,
	getFileUploadRules: rules.get,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimOfPayment);
