export const initialDrawerInfo = {
  title: "",
  visibility: false,
  type: ''
};

export enum DrawerTypes {
  GROUP_NO = "groupNo",
  BELONGS_TO = "belongsTo",
  POSTAL_CODE = "postalCode",
  ADDRESS_HISTORY = "addressHistory",
  ENTITY_HISTORY = "entityHistory",
}

export enum PostalCodeParams {
  COUNTRY_CODE = "NO",
  POSTAL_CODE = "PostalCode",
  ALL_SEARCH_TYPE = "all",
}

export const initialCreditorData = {
  entRoleId: -1,
  entId: -1,
  creditorNumber: '',
  creditorName:  '',
  groupNo:  '',
  groupName:  '',
  belongsTo:  '',
  belongsToId:  0,
  nin:  '',
  account:  '',
  accountNo:  '',
  productNo:  '',
  caseHandler: "",
  agreement: "",
  address1:  '',
  address2: '',
  address3: '',
  country: 'NO',
  postalCode: '',
  postalPlace: '',
  email: '',
  direct: '',
  mobile: '',
  office: '',
  fax: '',
  im1: '',
  im2: '',
  im3: '',
  AllowMoveToBMD: false,
  GetBelongsToSettings: false
};
