import React from "react";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { Formik } from "formik";
import { connect } from "react-redux";
import {
  getMIMETypeByFileFormat,
  base64toStr,
} from "us.collection.documents/functions";
import { IRootState } from "us.collection/interfaces";
import { FileExclamationOutlined } from "us.icons";
import XMLViewer from "react-xml-viewer";

const { $Button, $Skeleton, $Empty } = Common.Components;

interface ICaseDocumentContent {
  onClose: () => void;
  caseDocument?: any;
}

const CaseDocumentContent: React.FC<ICaseDocumentContent> = (props) => {
  const { t } = useTranslation();
  const { onClose, caseDocument } = props;
  const unableToDisplayFormats = ["doc", "docx", "csv", "xls", "xlsx"];

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={(values: any, actions: any) => {}}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        ...rest
      }: any) => (
        <>
          <$Skeleton loading={caseDocument.isFetching}>
            {unableToDisplayFormats.includes(
              caseDocument?.data?.documentType
            ) && (
              <div className="unable-to-display">
                <$Empty
                  image={<FileExclamationOutlined />}
                  description={
                    <span>
                      {t(
                        "US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.PREVIEW_IS_NOT_AVAILABLE"
                      )}
                    </span>
                  }
                />
              </div>
            )}
            {!unableToDisplayFormats.includes(
              caseDocument?.data?.documentType
            ) &&
              caseDocument?.data?.documentType == "xml" && (
                <XMLViewer
                  xml={base64toStr(caseDocument.data?.content)}
                  theme={{ overflowBreak: true }}
                  indentSize={5}
                  collapsible={true}
                />
              )}
            {!unableToDisplayFormats.includes(
              caseDocument?.data?.documentType
            ) &&
              caseDocument?.data?.documentType != "xml" && (
                <div className="case-doc-viwer-inner">
                  <object
                    className={
                      caseDocument?.data?.documentType === "pdf"
                        ? "case-doc-frame frame-pdf"
                        : "case-doc-frame frame-html"
                    }
                    type={getMIMETypeByFileFormat(
                      caseDocument?.data?.documentType
                    )}
                    data={`data:${getMIMETypeByFileFormat(
                      caseDocument?.data?.documentType
                    )};base64,${caseDocument.data?.content}`}
                  />
                </div>
              )}
            <div className="drawer-footer-fixed align-content-center justify-content-end">
              <div>
                <$Button
                  className="mr-2"
                  type="primary"
                  download={caseDocument.data?.documentName}
                  href={`data:${getMIMETypeByFileFormat(
                    caseDocument?.data?.documentType
                  )};base64,${caseDocument.data?.content}`}
                >
                  {t("US.COLLECTION.COMMON:COMMON.DOWNLOAD")}
                </$Button>
                <$Button onClick={() => onClose()}>
                  {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
                </$Button>
              </div>
            </div>
          </$Skeleton>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common, documents } = state;
  const { currentDateFormat, currentLanguage } = common;
  const { caseDocument } = documents;
  return {
    currentDateFormat,
    currentLanguage,
    caseDocument,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseDocumentContent);
