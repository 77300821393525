import React, { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Common from "us.common";
import { useHistory } from "react-router-dom";
import { nonProductionDays } from "us.collection.admin/actions";
import { PlusOutlined, MoreOutlined, EditOutlined } from "us.icons";
import { IRootState } from "us.collection/interfaces";
import { handleGoBack } from "us.helper/utility";

const {
  $Button,
  $PageHeader,
  $Divider,
  $Skeleton,
  $Table,
  $Affix,
  $Popover,
  $Row,
  $Col,
} = Common.Components;

export interface ISortOrder {
  columnKey: string;
  order: any;
}

interface INonProductionDaysHome {
  getCalendars?: any;
  resetNonProdDays?: any;
  calendars?: any;
  isCalendarsFetching: boolean;
}

interface IBase extends INonProductionDaysHome {}

const NonProductionDaysHome: React.FC<IBase> = memo((props) => {
  const { t } = useTranslation(["US.COLLECTION.ADMIN", "US.COLLECTION.COMMON"]);
  const history = useHistory();
  const {
    getCalendars,
    resetNonProdDays,
    calendars,
    isCalendarsFetching,
  } = props;

  useEffect(() => {
    resetNonProdDays();
    getCalendars();
  }, [getCalendars]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter);
  };

  const addNewCalendarHandler = () => {
    history.push("/non-production-days/calendar");
  };

  const editNonProductionDaysHandler = (record: any) => {
    history.push(`/non-production-days/calendar/${record?.calendarId}`);
  };
  // Popover actions in table
  const content = (record: any) => (
    <div className="table-more-content">
      <div
        className="d-flex flex-row more-item"
        onClick={() => {
          history.push(`/non-production-days/calendar/${record?.calendarId}`);
        }}
      >
        <div className="p-1">
          <EditOutlined />
        </div>
        <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.EDIT")}</div>
      </div>
    </div>
  );
  const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
    columnKey: "",
    order: "",
  });
  const tableColumns: any = [
    {
      title: "",
      key: "more",
      dataIndex: "more",
      width: "50px",
      render: (text: any, record: any) => {
        return (
          <$Popover
            placement="rightTop"
            content={content(record)}
            destroyTooltipOnHide
          >
            <$Button icon={<MoreOutlined />} size="small" />
          </$Popover>
        );
      },
    },
    {
      title: t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.CALENDAR"),
      dataIndex: "calendarName",
      key: "calendarName",
      sorter: (a: any, b: any) => a.calendarName.localeCompare(b.calendarName),
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "calendarName" && sortedInfo.order
        : null,
    },
  ];

  return (
    <div className="space-content">
      <$Affix offsetTop={48}>
        <div className="page-header header-border">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <$PageHeader
                className="px-0 py-3"
                onBack={() => handleGoBack(history)}
                title={t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONPRODUCTIONDAYS")}
              />
              <$Divider className="bui-devider" type="vertical" />
              <$Skeleton
                loading={isCalendarsFetching}
                active
                paragraph={{ rows: 0 }}
              >
                <$Button
                  onClick={() => addNewCalendarHandler()}
                  type="dashed"
                  size="small"
                  icon={<PlusOutlined />}
                >
                  {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.ADDNEWCALENDAR")}
                </$Button>
              </$Skeleton>
            </div>
          </div>
        </div>
      </$Affix>

      <$Row gutter={16} className="mb-5">
        <$Col
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 18 }}
          xl={{ span: 12 }}
          xxl={{ span: 8 }}
        >
          <$Skeleton
            loading={isCalendarsFetching}
            active
            paragraph={{ rows: 2 }}
          >
            <$Table
              onChange={handleTableChange}
              rowKey="calendarId"
              dataSource={calendars}
              size="small"
              className="mt-3 bui-table"
              bordered
              columns={tableColumns}
              pagination={{ hideOnSinglePage: true, defaultPageSize: 20 }}
              onRow={(record: any, rowIndex: any) => {
                return {
                  onDoubleClick: (event: any) => {
                    editNonProductionDaysHandler(record);
                  },
                };
              }}
            />
          </$Skeleton>
        </$Col>
      </$Row>
    </div>
  );
});

const mapStateToProps = (state: IRootState) => {
  const { nonProductionDays } = state;
  const { calendars } = nonProductionDays;
  return {
    isCalendarsFetching: calendars.isFetching,
    calendars: calendars.data,
  };
};

const mapDispatchToProps = {
  getCalendars: nonProductionDays.calendars.get,
  resetNonProdDays: nonProductionDays.redDays.reset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NonProductionDaysHome);
