import moment from "moment";
import { DrawerTypes } from "us.collection/constants";
import { IBMDInitialState } from "us.collection/interfaces/BMD";

export const BMDInitialState: IBMDInitialState = {
  bmdList: {
    data: { categories: [], key: 1 },
    isError: false,
    isLoading: false,
  },
  bmdDataTypeValues: {
    data: [],
    isError: false,
    isLoading: false,
  },
  bmdDataTypes: {
    data: [],
    isError: false,
    isLoading: false,
  },
  addBmdProperty: {
    data: 0,
    isError: false,
    isLoading: false,
  },
  bmdPropertyDetails: {
    data: {
      propertyId: 0,
      keyName: "",
      dataTypeId: 0,
      dataTypeName: "",
      description: "",
      displayName: "",
      cgVisibility: false,
      crVisibility: false,
      arVisibility: false,
      caVisibility: false,
      changedHistory: [
        {
          id: -1,
          dateTime: "",
          value: "",
          user: "",
          comment: "",
          isEffective: false,
        },
      ],
    },
    isError: false,
    isLoading: false,
  },
  changeBmdProperty: {
    data: [],
    isError: false,
    isLoading: false,
  },
  drawerStatus: {
    drawerType: DrawerTypes.ADD_PROPERTY,
    isVisible: false,
    title: "",
  },
  historyDelete: {
    data: {
      propertyValueId: 0,
    },
    isError: false,
    isLoading: false,
  },
  categoryList: {
    data: [],
    isError: false,
    isLoading: false,
  },
  bmdGroupDrawer: {
    title: "",
    isVisible: false,
    isEdit: false,
  },
  bmdGroupInitial: {
    categoryId: undefined,
    groupId: undefined,
    name: "",
    newCategoryName: "",
    description: "",
  },
  bmdGroup: {
    data: undefined,
    isLoading: false,
    isError: false,
  },
  bmdChangeCount:{
    data:[],
    isLoading:false,
    isError:false
  },
  linkedCreditors: {
    data: [],
    isLoading: false,
    isError: false,
  },
  addUpdateDataType:{
    data:{},
    isLoading:false,
    isError:false
  },
  valueRetrievingSPs:{
    data:[],
    isLoading:false,
    isError:false
  }
};
