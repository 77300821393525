import React from "react";
import {
  Affix,
  PageHeader,
  Divider,
  Button,
  Drawer,
  Table,
  Form,
  Select,
  Input,
  Switch,
  Row,
  Col,
  List,
  Checkbox,
} from "antd";
import { Formik, Field, FieldArray } from "formik";
import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation();
  const data = [
    {
      title: 'Title 1',
    },
    {
      title: 'Title 2',
    },
    {
      title: 'Title 3',
    },
    {
      title: 'Title 4',
    },
    {
      title: 'Title 1',
    },
    {
      title: 'Title 2',
    },
    {
      title: 'Title 3',
    },
    {
      title: 'Title 4',
    },
    {
      title: 'Title 1',
    },
    {
      title: 'Title 2',
    },
    {
      title: 'Title 3',
    },
    {
      title: 'Title 4',
    },
  ];
  return (
    <>
      <Form>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label={t("US.COLLECTION.COMMON:COMMON.NAME")}>
              <Input size="small" />
            </Form.Item>
            <Form.Item label={t("US.COLLECTION.ADMIN:ACTIVITIES.ENTITYTYPE")}>
              <Select size="small"></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item label={t("US.COLLECTION.COMMON:COMMON.DISPLAYNAME")}>
              <Input size="small" />
            </Form.Item>
            <Form.Item label={t("US.COLLECTION.ADMIN:ACTIVITIES.SHORTCUTCODE")}>
              <Input size="small" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-4" gutter={16}>
          <Col span={24}>
          <Form.Item label={t("US.COLLECTION.ADMIN:ACTIVITIES.ACTIVITYSTATE")}>
                <List
               
                size="small"
                className="mt-2"
                dataSource={data}
                header={<Checkbox >Check All</Checkbox>}
                bordered
                grid={{ gutter: 16, column: 3 }}
                renderItem={(item) => <List.Item><Checkbox className="mt-2" >Reminder</Checkbox></List.Item>}
                />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-4" gutter={16}>
          <Col span={12}>
            <Form.Item label={t("US.COLLECTION.ADMIN:ACTIVITIES.ACTIVITYCODE")}>
              <Input size="small" />
            </Form.Item>
           
          </Col>
          <Col span={12}>
          
          <Form.Item label={t("US.COLLECTION.ADMIN:ACTIVITIES.ACTIVITYGROUP")}>
              <Select size="small"></Select>
            </Form.Item>
          </Col>
          
        </Row>
        <Row className="mt-4" gutter={16}>
        <Col span={24}>
          
            <Input.Group compact>
            <Form.Item label={t("US.COLLECTION.ADMIN:ACTIVITIES.ACTIVITYTYPE")} >
              <Select placeholder={t("US.COLLECTION.ADMIN:ACTIVITIES.ACTIVITYTYPE")}>
              </Select>
            </Form.Item>
            <Form.Item>
              <Input style={{ width: '50%' }} placeholder="Input street" />
            </Form.Item>
          </Input.Group>


            <Form.Item label={t("US.COLLECTION.ADMIN:ACTIVITIES.ACTIVITYTYPE")}>
            <Select size="small"></Select>
            </Form.Item>
          
            </Col>
            </Row>
        {/* <Form.Item label={t("US.COLLECTION.ADMIN:CUSTOMSETTINGS.ENTITY")}>
            <Select></Select>
          </Form.Item>
          <Form.Item label={t("US.COLLECTION.ADMIN:CUSTOMSETTINGS.DATATYPE")}>
            <Select></Select>
          </Form.Item>
          <Form.Item label={t("US.COLLECTION.COMMON:NAME")}>
          <Input />
          </Form.Item>
          <Form.Item label={t("US.COLLECTION.ADMIN:CUSTOMSETTINGS.DEFAULTVALUE")} >
          <Input />
          </Form.Item>
          <Form.Item label= {t("US.COLLECTION.COMMON:DISPLAYNAME")}>
          <Input />
          </Form.Item> */}
      </Form>
      <div className="drawer-footer-fixed align-content-center justify-content-end">
          <div>
            <Button className="mr-2" type="primary" htmlType="submit">
              {t("US.COLLECTION.COMMON:COMMON.SAVE")}
            </Button>
            <Button >
              {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
            </Button>
          </div>
        </div>
    </>
  );
};
