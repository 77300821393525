import moment from 'moment';
import { DateFormats } from 'us.collection.transactions/constants';
import { ITransactionInterestRate } from 'us.collection.transactions/interfaces/Transactions/InterestApplication';

export function UpdateTransactionInterest(this: any): ITransactionInterestRate {
	return {
		transactionId: this.arItemNo,
		interestType: this.interestType,
		interestFromDate: moment(this.interestFromDate).format(DateFormats.REQ),
		interestRate: this.interestRate,
		caseNo: this.caseId,
	};
}
