import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { MoreOutlined } from "us.icons";
import { $Popover, $Button, $Popconfirm } from "us.common/components";
import { followUps } from "us.collection.followup/actions";
import { IContent } from "./Interfaces";
import {
  FollowUpType,
} from "us.collection.followup/constants";
import { FollowUpAction } from "us.common/components/FollowUps/Constants";
import { getEntityIdForEntity } from "us.collection.followup/functions";
import { MoreAction } from "./Components/MoreAction";
import { RootState } from "us.helper/types";

const { historyForAction, historyDrawer, action } = followUps;

/**
 * @description - Content of more button in table row data
 * @param record - individual single row of data
 * @returns  jsx element of more button popup
 */
const MoreButton: React.FC<PropsFromRedux & IContent> = (props) => {
  const { t } = useTranslation();
  const { followUpId } = useParams<{ followUpId?: string }>();

  const [popVisible, setPopVisible] = useState<number>(-1);
  const [followupActionState, setFollowupActionState] = useState({
    isVisible: false,
    action: "",
  });

  const {
    record,
    entityType,
    getFollowUpActionHistory,
    manageFollowUpActionHistoryDrawer,
    result,
  } = props;

  const { data } = result;
  const { followupType, followupName } = data;

  const closePopover = () => {
    if (popVisible == record?.Followupid) {
      setPopVisible(-1);
      setFollowupActionState({
        isVisible: false,
        action: "",
      });
    }
  };

  return (
    <$Popover
      placement="rightTop"
      content={
        <>
          {!followupActionState.isVisible && (
            <div className="table-more-content">
              <div
                className="more-item"
                onClick={() => {
                  getFollowUpActionHistory &&
                    getFollowUpActionHistory({
                      followUpId,
                      entityId: getEntityIdForEntity(entityType, record),
                    });
                  manageFollowUpActionHistoryDrawer &&
                    manageFollowUpActionHistoryDrawer({ isAction: true });
                  setPopVisible(-1);
                }}
              >
                <div className="py-1 px-2">
                  {t("US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.ACTION_HISTORY")}
                </div>
              </div>
              {followupType != FollowUpType.DYNAMIC_FOLLOW_UP && (
                <div
                  className="more-item"
                  onClick={() =>
                    setFollowupActionState({
                      isVisible: true,
                      action: FollowUpAction.DONE,
                    })
                  }
                >
                  <div className="py-1 px-2">
                    {t("US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.DONE")}
                  </div>
                </div>
              )}
              <div
                className="more-item"
                onClick={() =>
                  setFollowupActionState({
                    isVisible: true,
                    action: FollowUpAction.POSTPONE,
                  })
                }
              >
                <div className="py-1 px-2">
                  {t("US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.POSTPONE")}
                </div>
              </div>
              <div
                className="more-item"
                onClick={() =>
                  setFollowupActionState({
                    isVisible: true,
                    action: FollowUpAction.REMOVE,
                  })
                }
              >
                <div className="py-1 px-2 text-error">
                  {t("US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.REMOVE")}
                </div>
              </div>
            </div>
          )}
          {followupActionState.isVisible && (
            <MoreAction
              record={record}
              onClose={() => closePopover()}
              actionType={followupActionState.action}
            />
          )}
        </>
      }
      destroyTooltipOnHide
      trigger="click"
      visible={record?.Followupid == popVisible}
      onVisibleChange={(visible: boolean) => {
        setPopVisible(visible ? record?.Followupid : -1);
        setFollowupActionState({
          isVisible: false,
          action: "",
        });
      }}
    >
      <$Button icon={<MoreOutlined />} size="small" />
    </$Popover>
  );
};

const mapStateToProps = (state: RootState) => {
  const { common, followUps } = state;
  const { currentDateFormat } = common;
  const { result } = followUps;
  return {
    currentDateFormat,
    result,
  };
};

const mapDispatchToProps = {
  getFollowUpActionHistory: historyForAction.get,
  manageFollowUpActionHistoryDrawer: historyDrawer.manage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MoreButton);
