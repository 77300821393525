import moment from 'moment';
import { getLookUpTypeByCaseType } from 'us.collection.case/functions/Functions/Home';
import { IMetaData } from 'us.collection/interfaces';
import { NoteType, NoteSource } from "us.collection/constants";
import { CaseType } from 'us.helper/types/enums';

import {
	DocumentType,
	DomainMessageType,
	DateFormat,
	EntityType,
} from 'us.collection.case/constants/NotesAndMessage';
import { i18n } from 'us.helper';

export function FormatedTemplate(
	this: any,
	templateName: string,
	formValues: any,
	metaData: IMetaData
) {
	const { caseId, entityType } = metaData;
	return {
		messageContent: this,
		entityId: caseId,
		entityType:
			entityType == CaseType.S
				? EntityType.SUBCASE
				: EntityType.CASE,
		values: { ...formValues, template: templateName },
	};
}
export function SendDomainMassage(
	this: any,
	type: string,
	metaData: IMetaData,
	formattedTemplate: any,
	attachment?: any
) {
	const { caseId, entityType } = metaData;
	return {
		entityId: caseId,
		entityType:
			entityType === CaseType.S
				? EntityType.SUBCASE
				: EntityType.CASE,
		communicationJobId:
			this.template?.length > 0 &&
			formattedTemplate.communicationJodId > 0
				? formattedTemplate.communicationJodId
				: -1,
		mesageContent:
			this.template?.length === 0
				? this.caseNote
				: this.templateNote,
		emailOrTelNo:
			type === DomainMessageType.EMAIL
				? this.emailAddress
				: type === DomainMessageType.SMS
				? this.smsNumber
				: '',
		documentType:
			this.documentType === DocumentType.DEBTOR ||
			this.documentType === DocumentType.CREDITOR
				? this.documentType
				: DocumentType.OTHERPARTY,
		isPaymentInfoSMS: this.isPaymentInformation,
		isGiroDocument: this.isGiroChecked,
		dueDate: moment(this.dueDate).isAfter(moment().startOf('day'))
			? moment(this.dueDate).format(DateFormat.REQ)
			: null,
		senderType: '',
		messgeType: type,
		attachmentPaths:
			type === DomainMessageType.EMAIL ? attachment : [''],
		receiverEntityId:
			type === DomainMessageType.EMAIL ||
			type === DomainMessageType.SMS
				? this.receiverEntityId
				: 0,
		caseNote: this.caseNote,
		templateNote: this.templateNote,
	};
}

export function SendDomainNote(this: any, metaData: IMetaData) {
	const { caseId, entityType } = metaData;
	return {
		entityId: caseId,
		entityType:
			entityType == CaseType.S
				? EntityType.SUBCASE
				: EntityType.CASE,
		messageContent:
			this.caseNote +
			(moment(this.dueDate).isAfter(moment().startOf('day'))
				? '\n' +
				  i18n.t(
						'US.COLLECTION.CASE:NOTESANDMESSAGES.NEXT_DUE_DATE'
				  ) +
				  ' : ' +
				  moment(this.dueDate).format(
						DateFormat.CASE_DUE_DATE_FORMAT
				  )
				: ''),
		noteType: NoteType.CASE_NOTE,
		source: NoteSource.US_BOX,
		isInternal: this.internal,
		isDebtorNote: this.copyAsDebtorNotes,
		communicationMethod: this.communicationMethod,
		manualDueDate: moment(this.dueDate).isAfter(
			moment().startOf('day')
		)
			? moment(this.dueDate).format(DateFormat.REQ)
			: '',
	};
}

export function SendDomainCPNote(this: any, metaData: IMetaData) {
	const { caseId, entityType } = metaData ?? {};
	return {
		entityId: caseId,
		entityType:
			entityType == CaseType.S
				? EntityType.SUBCASE
				: EntityType.CASE,
		messageContent: this.note,
		isInternal: false,
		isDebtorNote: false,
		communicationMethod: "note",
		manualDueDate: "",
		noteType: NoteType.CP_NOTE,
		source: NoteSource.US_BOX
	};
}

export function PrintedDocumentsForAttachment(metaData: IMetaData) {
	const { caseId, entityType } = metaData;
	return {
		entityId: caseId,
		entityType,
		activityType: 'All',
		activityStatus: 'All',
	};
}
export function UploadDocumentsForAttachment(metaData: IMetaData) {
	const { caseId, entityType } = metaData;
	return {
		entityId: caseId,
		entityType,
	};
}
export function PrintedDocumentContent(this: any, entitType: string) {
	return {
		system: this.activityName
			?.toLowerCase()
			.includes('documentupload')
			? 'EntityDocument'
			: 'USCService',
		lookupId: this.comJobId,
		lookupType: this.activityName
			?.toLowerCase()
			.includes('documentupload')
			? getLookUpTypeByCaseType(this.caseType, entitType)
			: 'outputdocument',
	};
}
export function UploadDocumentContent(this: any, entitType: string) {
	return {
		system: 'EntityDocument',
		lookupId: this.documentId,
		lookupType: getLookUpTypeByCaseType(this.caseType, entitType),
	};
}

export function GetMessageContent(this: any) {
	return {
		entityId: this.entityId,
		entityType: this.entityType, //entityType
		communicationJobId: -1,
		messageContent: '',
		emailOrTelNo: this.emailOrTelNo, //emailAddress or smsNumber
		documentType:
			this.documentType === DocumentType.DEBTOR ||
			this.documentType === DocumentType.CREDITOR
				? this.documentType
				: DocumentType.OTHERPARTY,
		isPaymentInfoSMS: this.isPaymentInfoSMS,
		isGiroDocument: this.isGiroDocument,
		dueDate: this.dueDate,
		senderType: '',
		messageType: this.messageType,
		attachmentPaths: this.attachmentPaths,
		receiverEntityId: this.receiverEntityId,
		caseNote: this.caseNote,
		templateNote: this.templateNote,
	};
}

export function SendDashboardMessage(
	this: any,
	initial: any,
	entityId: string,
	entityType: string,
	messageContent: Array<any>,
	attachment?: Array<string>
) {
	return {
		queueId: this.queueId,
		entityId: entityId,
		entityType:
			entityType === CaseType.S
				? EntityType.SUBCASE
				: EntityType.CASE,
		statusCode: this.statusCode,
		templateId: this.templateId,
		templateName: this.templateName,
		templateCategory: this.templateCategory,
		rowFiles: this.rowFiles,
		message: this.message,
		errorDescription: this.errorDescription,
		messages: messageContent,
		messageType: this.messageType,
		fileName: this.fileName,
		settings: this.settings,
		documentDetails: this.documentDetails,
		activityId: this.activityId,
		activityName: this.activityName,
		attachments: attachment,
		emailOrSMS:
			this.messageType == 'SMS'
				? initial.smsNumber
				: this.messageType == 'Email'
				? initial.emailAddress
				: '',
		documentType:
			this.documentType === DocumentType.DEBTOR ||
			this.documentType === DocumentType.CREDITOR
				? this.documentType
				: DocumentType.OTHERPARTY,
		receiverEntityId: initial.receiverEntityId,
		messgeType: this.messageType,
		dueDate: initial.dueDate
			? moment(initial.dueDate).format(DateFormat.REQ)
			: '',
	};
}
