import * as Yup from "yup";

interface DueDate extends Yup.MixedSchema {
  dueDate: Date;
  caseNote: string;
}

export default () => {
  return Yup.object<DueDate>().shape({
    caseNote: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
    ),
    dueDate: Yup.date()
      .nullable()
      .required("US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"),
  });
};
