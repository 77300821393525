import { put, call, takeLatest, select, take } from "redux-saga/effects";
import { BMDActionsConst, DrawerTypes } from "us.collection/constants/BMD";
import API from "us.collection/services";
import * as Actions from "us.collection/actions";
import { ISearchRequestObject } from "us.common/components/MainSearch/Interfaces";
import _ from "lodash";
import {
  IBMDChangeHistory,
  IBMDDataType,
  IBMDListModel,
  IChangeCountRequest,
} from "us.collection/interfaces";
import { IRootState } from "us.collection/interfaces";
import { $MessageBox } from "us.common/components";
import { EntityType } from "us.collection/components/BMD/Constants";
import { getCreditors, GetData } from "us.collection/functions/BMD";

const { BMDActions } = Actions;

const BMDSagas = {
  BMDList: {
    get: function* (action: any): any {
      const params: ISearchRequestObject = action.payload.data;
      try {
        const bmdList = yield call(API.BMDService.BMD.getList, params);
        if (bmdList?.hasOwnProperty("categories")) {
          yield put(BMDActions.bmdList.success(GetData(bmdList)));
        } else {
          yield put(BMDActions.bmdList.fail({}));
        }
      } catch (error) {
        yield put(BMDActions.bmdList.fail(error as Object));
      }
    },
  },
  BMDProperty: {
    add: function* (action: any): any {
      const params = action.payload.data;
      try {
        const bmdProperty = yield call(
          API.BMDService.BMD.addProperty,
          params.requestObject
        );
        if (bmdProperty?.hasOwnProperty("propertyId")) {
          $MessageBox(
            "success",
            "US.COLLECTION.CASE:BMD.BMD_PROPERTY_ADDED_SUCCESSFULLY",
            "",
            ""
          );
          yield put(BMDActions.bmdProperty.success(bmdProperty));
          if (
            !params.isSaveNew &&
            BMDActions.bmdDataTypes.openDrawer &&
            BMDActions.bmdList.get
          ) {
            // get bmd drawer status
            let recordData = yield select(
              (state: IRootState) => state.bmd.drawerStatus.recordData
            );
            yield put(
              BMDActions.bmdDataTypes.openDrawer({
                recordData,
                isVisible: false,
              })
            );
            yield put(BMDActions.bmdList.get(params.routeProps));
          }
          if (params.isSaveNew) {
            params.resetForm();
          }
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.CASE:BMD.BMD_PROPERTY_ADDITION_FAILED",
            "",
            ""
          );
          yield put(BMDActions.bmdProperty.fail({}));
        }
      } catch (error) {
        if ((error as any).errors && Array.isArray((error as any).errors)) {
          $MessageBox("error", (error as any).errors[0].error, "", "");
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.CASE:BMD.BMD_PROPERTY_ADDITION_FAILED",
            "",
            ""
          );
        }
        yield put(BMDActions.bmdProperty.fail(error as Object));
      }
    },
  },
  BMDDataTypes: {
    get: function* (action: any): any {
      try {
        const bmdDataTypes = yield call(API.BMDService.BMD.getDataTypes, []);
        if (Array.isArray(bmdDataTypes)) {
          yield put(
            BMDActions.bmdDataTypes.success(
              bmdDataTypes.map((type) => ({ ...type, key: type.id }))
            )
          );
        } else {
          $MessageBox(
            "info",
            "US.COLLECTION.CASE:BMD.NO_BMD_DATA_TYPES",
            "",
            ""
          );
          yield put(BMDActions.bmdDataTypes.fail([]));
        }
      } catch (error) {
        yield put(BMDActions.bmdDataTypes.fail(error as Object));
      }
    },
    reset: function* (action: any): any {
      if (!action.payload.data.isVisible) {
        yield put(BMDActions.bmdDataTypes.success([]));
      }
    },
  },
  BMDDataTypeValues: {
    get: function* (action: any): any {
      const params: number = action.payload.data.dataTypeId;
      // checkinf if dataType has already been fetched,
      const bmdDataTypes: IBMDListModel<IBMDDataType[]> = yield select(
        (state: IRootState) => state.bmd.bmdDataTypes
      );
      const bmdDataTypeSelected = bmdDataTypes.data?.find(
        (datatype) => datatype.id === params
      );
      if (bmdDataTypeSelected?.values) {
        yield put(
          BMDActions.bmdDataTypeValues.success(bmdDataTypeSelected.values)
        );
      } else {
        try {
          const bmdDataTypeValues = yield call(
            API.BMDService.BMD.getDataTypeValues,
            params
          );
          if (Array.isArray(bmdDataTypeValues)) {
            yield put(
              BMDActions.bmdDataTypes.success(
                bmdDataTypes.data?.map((type) => {
                  if (type.id === params) {
                    return { ...type, values: bmdDataTypeValues };
                  } else {
                    return type;
                  }
                })
              )
            );

            yield put(BMDActions.bmdDataTypeValues.success(bmdDataTypeValues));
          } else {
            $MessageBox(
              "info",
              "US.COLLECTION.CASE:BMD.NO_BMD_DATA_TYPE_VALUES",
              "",
              ""
            );
            yield put(BMDActions.bmdDataTypeValues.fail([]));
          }
        } catch (error) {
          yield put(BMDActions.bmdDataTypeValues.fail(error as Object));
        }
      }
    },
    reset: function* (action: any): any {
      if (!action.payload.data.isVisible) {
        yield put(BMDActions.bmdDataTypeValues.success([]));
      }
    },
  },
  BMDPropertyDetails: {
    get: function* (action: any): any {
      try {
        const bmdPropertyDetails = yield call(
          API.BMDService.BMD.getPropertyDetails,
          action.payload.data
        );
        if (
          bmdPropertyDetails?.hasOwnProperty("propertyId") &&
          bmdPropertyDetails["propertyId"] > 0
        ) {
          // get the linked creditor list
          if (action.payload.data.entityType === EntityType.CREDITOR) {
            const creditorId: IBMDDataType[] | undefined = yield select(
              (state: any) => state.router.location?.state?.creditorId
            );
            if (creditorId && BMDActions.linkedCreditors.search) {
              yield put(
                BMDActions.linkedCreditors.search({
                  creditorId,
                  searchText: "",
                  criteria: "all",
                })
              );
            }
          }

          // get bmdDataTypes if there isn't
          let bmdDataTypes: IBMDDataType[] | undefined = yield select(
            (state: IRootState) => state.bmd.bmdDataTypes.data
          );
          if (!bmdDataTypes || bmdDataTypes.length === 0) {
            if (BMDActions.bmdDataTypes.get)
              yield put(BMDActions.bmdDataTypes.get([]));

            // wait till we get the data types
            const { payload } = yield take(
              BMDActionsConst.GET_BMD_DATA_TYPES_SUCCESS
            );
            bmdDataTypes = payload.data;
          }

          // get the bmdDataTypeValues
          const selectedDataType = bmdDataTypes?.find(
            (datatype) => datatype.id === bmdPropertyDetails.dataTypeId
          );
          if (
            selectedDataType &&
            selectedDataType.isPrimitive === 0 &&
            selectedDataType.storedProcedure &&
            BMDActions.bmdDataTypeValues.get
          ) {
            yield put(
              BMDActions.bmdDataTypeValues.get({
                dataTypeId: bmdPropertyDetails.dataTypeId,
              })
            );
          }

          yield put(
            BMDActions.bmdPropertyDetails.success({
              ...bmdPropertyDetails,
              changedHistory: bmdPropertyDetails.changedHistory?.map(
                (historyItem: IBMDChangeHistory) => ({
                  ...historyItem,
                  key: historyItem.id,
                })
              ),
            })
          );
        } else {
          $MessageBox(
            "info",
            "US.COLLECTION.CASE:BMD.PROPERTY_DETAIL_RETRIEVAL_FAILED",
            "",
            ""
          );
          yield put(BMDActions.bmdPropertyDetails.fail([]));
        }
      } catch (error) {
        yield put(BMDActions.bmdPropertyDetails.fail(error as Object));
      }
    },
    change: function* (action: any): any {
      try {
        const changeBMDResponse = yield call(
          API.BMDService.BMD.changePropertyDetails,
          action.payload.data
        );
        if (Array.isArray(changeBMDResponse)) {
          // update the bmd list
          if (BMDActions.bmdList.get) {
            yield put(
              BMDActions.bmdList.get({
                entityId:
                  action.payload.data.drawerType === DrawerTypes.BMD_COUNT
                    ? action.payload.data.realEntityId
                    : action.payload.data.entityId[0],
                entityType:
                  action.payload.data.drawerType === DrawerTypes.BMD_COUNT
                    ? action.payload.data.realEntityType
                    : action.payload.data.entityType,
              })
            );
          }
          // get bmd drawer status
          let recordData = yield select(
            (state: IRootState) => state.bmd.drawerStatus.recordData
          );
          // close the drawer
          if (BMDActions.bmdDataTypes.openDrawer) {
            yield put(
              BMDActions.bmdDataTypes.openDrawer({
                recordData,
                isVisible: false,
              })
            );
          }

          // update the status
          yield put(
            BMDActions.changeBMDPropertyDetails.success(
              changeBMDResponse.map((type) => ({ ...type, key: type.id }))
            )
          );

          $MessageBox(
            "success",
            "US.COLLECTION.CASE:BMD.BMD_CHANGED_SUCCESFULLY",
            "",
            ""
          );
        } else {
          $MessageBox(
            "info",
            "US.COLLECTION.CASE:BMD.CHANGE_BMD_FAILED",
            "",
            ""
          );
          yield put(BMDActions.changeBMDPropertyDetails.fail([]));
        }
      } catch (error) {
        if ((error as any).errors && Array.isArray((error as any).errors)) {
          $MessageBox("error", (error as any).errors[0].error, "", "");
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.CASE:BMD.CHANGE_BMD_FAILED",
            "",
            ""
          );
        }
        yield put(BMDActions.changeBMDPropertyDetails.fail(error as Object));
      }
    },
  },
  BMDPropertyHistoryDetails: {
    delete: function* (action: any): any {
      try {
        const deleteHisotryResponse = yield call(
          API.BMDService.BMD.deletePropertyHistoryDetails,
          action.payload.data.deletePrameters
        );
        if (deleteHisotryResponse?.hasOwnProperty("propertyValueId")) {
          const { propertyValueId } = deleteHisotryResponse;

          // update the bmdPropertyDetail reducer
          yield put(
            BMDActions.bmdPropertyDetails.success({
              ...action.payload.data.bmdPropertyDetails,
              changedHistory:
                action.payload.data.bmdPropertyDetails?.changedHistory?.filter(
                  (historyItem: IBMDChangeHistory) =>
                    historyItem.id !==
                    action.payload.data.deletePrameters.propertyValueId
                ),
            })
          );
          $MessageBox(
            "success",
            "US.COLLECTION.CASE:BMD.BMD_HISTORY_DELETED_SUCCESFULLY",
            "",
            ""
          );
          yield put(BMDActions.bmdPropertyHistory.success({ propertyValueId }));
        } else {
          $MessageBox(
            "info",
            "US.COLLECTION.CASE:BMD.BMD_HISTORY_DELETION_FAILED",
            "",
            ""
          );
          yield put(BMDActions.bmdPropertyHistory.fail([]));
        }
      } catch (error) {
        $MessageBox(
          "info",
          "US.COLLECTION.CASE:BMD.BMD_HISTORY_DELETION_FAILED",
          "",
          ""
        );
        yield put(BMDActions.bmdPropertyHistory.fail(error as Object));
      }
    },
  },
  BMDCategory: {
    add: function* (action: any): any {
      const params = action.payload.data.requestBody;
      try {
        const { data, status } = yield call(
          API.BMDService.BMD.addCategory,
          params
        );
        if (status == 201) {
          $MessageBox(
            "success",
            "US.COLLECTION.CASE:BMD.BMD_CATEGORY_ADDED_SUCCESSFULLY",
            "",
            ""
          );
          yield put(
            BMDActions.bmdCategory.success({
              categoryId: data.categoryId,
              categoryName: params.category,
            })
          );
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.CASE:BMD.BMD_CATEGORY_ADDITION_FAILED",
            "",
            ""
          );
          yield put(BMDActions.bmdCategory.fail(data));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.CASE:BMD.BMD_CATEGORY_ADDITION_FAILED",
          "",
          ""
        );
        yield put(BMDActions.bmdCategory.fail(error as Object));
      }
    },
  },
  BMDGroup: {
    add: function* (action: any): any {
      const params = action.payload.data;
      try {
        const { data, status } = yield call(
          API.BMDService.BMD.addGroup,
          params
        );
        if (status == 201 && data.groupId > 0) {
          $MessageBox(
            "success",
            "US.COLLECTION.CASE:BMD.BMD_GROUP_ADDED_SUCCESSFULLY",
            "",
            ""
          );
          yield put(BMDActions.bmdGroup.success(data));
          if (BMDActions.bmdList.get) {
            yield put(
              BMDActions.bmdList.get({ entityId: "-1", entityType: "BU" })
            );
          }
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.CASE:BMD.BMD_GROUP_ADDITION_FAILED",
            "",
            ""
          );
          yield put(BMDActions.bmdGroup.fail(data));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.CASE:BMD.BMD_GROUP_ADDITION_FAILED",
          "",
          ""
        );
        yield put(BMDActions.bmdGroup.fail(error as Object));
      }
    },
    update: function* (action: any): any {
      const params = action.payload.data;
      try {
        const { data, status } = yield call(
          API.BMDService.BMD.updateGroup,
          params
        );
        if (status == 200 && data.groupId > 0) {
          $MessageBox(
            "success",
            "US.COLLECTION.CASE:BMD.BMD_GROUP_UPDATED_SUCCESSFULLY",
            "",
            ""
          );
          yield put(
            BMDActions.bmdGroup.success({
              ...data,
              categoryId: params.categoryId,
            })
          );
          if (BMDActions.bmdList.get) {
            yield put(
              BMDActions.bmdList.get({ entityId: "-1", entityType: "BU" })
            );
          }
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.CASE:BMD.BMD_GROUP_UPDATE_FAILED",
            "",
            ""
          );
          yield put(BMDActions.bmdGroup.fail(data));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.CASE:BMD.BMD_GROUP_UPDATE_FAILED",
          "",
          ""
        );
        yield put(BMDActions.bmdGroup.fail(error as Object));
      }
    },
  },
  BMDChangeCount: {
    get: function* (action: any): any {
      const params: IChangeCountRequest = action.payload.data;
      try {
        const bmdCountList = yield call(API.BMDService.BMD.changeCount, params);
        yield put(BMDActions.bmdCountList.success(bmdCountList ?? []));
      } catch (error) {
        yield put(BMDActions.bmdCountList.fail(error as Object));
      }
    },
  },
  LinkedCreditors: {
    search: function* (action: any): any {
      const params: { creditorId: string } = action.payload.data;
      try {
        const creditorList = yield call(
          API.BMDService.BMD.searchLinkedCreditors,
          params
        );
        if (creditorList?.length) {
          yield put(
            BMDActions.linkedCreditors.success(getCreditors(creditorList))
          );
        } else {
          yield put(BMDActions.linkedCreditors.fail({}));
        }
      } catch (error) {
        yield put(BMDActions.linkedCreditors.fail(error as Object));
      }
    },
  },
  BMDDataType: {
    add: function* (action: any): any {
      try {
        const params = action.payload.data;
        const bmdDataTypeResponse = yield call(
          API.BMDService.BMD.addDataType,
          params
        );
        if (
          bmdDataTypeResponse.hasOwnProperty("isError") &&
          !bmdDataTypeResponse.isError
        ) {
          $MessageBox("success", bmdDataTypeResponse.message, "", "");
          yield put(BMDActions.bmdDataType.success(bmdDataTypeResponse));

          // udpate the bmd data type list
          if (BMDActions.bmdDataTypes.get) {
            yield put(BMDActions.bmdDataTypes.get({}));
          }

          // close the drawer
          if (BMDActions.bmdDataTypes.openDrawer) {
            yield put(BMDActions.bmdDataTypes.openDrawer({ isVisible: false }));
          }
        } else {
          $MessageBox("info", bmdDataTypeResponse.message, "", "");
          yield put(BMDActions.bmdDataType.fail([]));
        }
      } catch (error) {
        yield put(BMDActions.bmdDataType.fail(error as Object));
      }
    },
    update: function* (action: any): any {
      try {
        const params = action.payload.data;
        const bmdDataTypeResponse = yield call(
          API.BMDService.BMD.updateDataType,
          params
        );
        if (
          bmdDataTypeResponse.hasOwnProperty("isError") &&
          !bmdDataTypeResponse.isError
        ) {
          $MessageBox("success", bmdDataTypeResponse.message, "", "");
          yield put(BMDActions.bmdDataType.success(bmdDataTypeResponse));

          // udpate the bmd data type list
          if (BMDActions.bmdDataTypes.get) {
            yield put(BMDActions.bmdDataTypes.get({}));
          }

          // close the drawer
          if (BMDActions.bmdDataTypes.openDrawer) {
            yield put(BMDActions.bmdDataTypes.openDrawer({ isVisible: false }));
          }
        } else {
          $MessageBox("info", bmdDataTypeResponse.message, "", "");
          yield put(BMDActions.bmdDataType.fail([]));
        }
      } catch (error) {
        yield put(BMDActions.bmdDataType.fail(error as Object));
      }
    },
  },
  BMDDataTypeValueRetrievingSPs: {
    get: function* (action: any): any {
      try {
        const bmdDataTypeValueRetrievingSPs = yield call(
          API.BMDService.BMD.getBMDDataTypeValueRetrievingSPs,
          {}
        );
        if (Array.isArray(bmdDataTypeValueRetrievingSPs.data)) {
          yield put(
            BMDActions.bmdDataTypeValueRetrievingSP.success(
              bmdDataTypeValueRetrievingSPs.data.map(
                (item: string, index: number) => ({ name: item, key: index })
              )
            )
          );
        } else {
          $MessageBox(
            "info",
            "US.COLLECTION.CASE:BMD.NO_BMD_DATA_TYPE_VALUE_RETRIEVING_SPS",
            "",
            ""
          );
          yield put(BMDActions.bmdDataTypeValueRetrievingSP.fail([]));
        }
      } catch (error) {
        yield put(
          BMDActions.bmdDataTypeValueRetrievingSP.fail(error as Object)
        );
      }
    },
  },
};

export default [
  takeLatest(BMDActionsConst.GET_BMD_LIST, BMDSagas.BMDList.get),
  takeLatest(BMDActionsConst.ADD_BMD_PROPERTY, BMDSagas.BMDProperty.add),
  takeLatest(BMDActionsConst.GET_BMD_DATA_TYPES, BMDSagas.BMDDataTypes.get),
  takeLatest(
    BMDActionsConst.GET_BMD_DATA_TYPE_VALUES,
    BMDSagas.BMDDataTypeValues.get
  ),
  takeLatest(
    BMDActionsConst.CHANGE_BMD_DRAWER_STATUS,
    BMDSagas.BMDDataTypeValues.reset
  ),
  takeLatest(
    BMDActionsConst.CHANGE_BMD_DRAWER_STATUS,
    BMDSagas.BMDDataTypes.reset
  ),
  takeLatest(
    BMDActionsConst.GET_BMD_PROPERTY_DETAILS,
    BMDSagas.BMDPropertyDetails.get
  ),
  takeLatest(
    BMDActionsConst.CHANGE_BMD_PROPERTY_DETAILS,
    BMDSagas.BMDPropertyDetails.change
  ),
  takeLatest(
    BMDActionsConst.DELETE_BMD_PROPERTY_HISTORY_DETAILS,
    BMDSagas.BMDPropertyHistoryDetails.delete
  ),
  takeLatest(BMDActionsConst.ADD_BMD_CATEGORY, BMDSagas.BMDCategory.add),
  takeLatest(BMDActionsConst.ADD_BMD_GROUP, BMDSagas.BMDGroup.add),
  takeLatest(BMDActionsConst.UPDATE_BMD_GROUP, BMDSagas.BMDGroup.update),
  takeLatest(
    BMDActionsConst.SEARCH_LINKED_CREDITORS,
    BMDSagas.LinkedCreditors.search
  ),
  takeLatest(BMDActionsConst.ADD_DATA_TYPE, BMDSagas.BMDDataType.add),
  takeLatest(BMDActionsConst.EDIT_DATA_TYPE, BMDSagas.BMDDataType.update),
  takeLatest(
    BMDActionsConst.GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES,
    BMDSagas.BMDDataTypeValueRetrievingSPs.get
  ),
  takeLatest(BMDActionsConst.GET_BMD_COUNT_LIST, BMDSagas.BMDChangeCount.get),
];
