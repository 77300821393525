import { IAPIAction } from 'us.collection/interfaces';
import { ManualFollowUp } from 'us.collection.case/constants';
import { IManualFollowUpAction } from './Interface';

const {
	GET_MANUAL_FOLLOW_UP_USERS_START,
	GET_MANUAL_FOLLOW_UP_USERS_SUCCESS,
	GET_MANUAL_FOLLOW_UP_USERS_FAIL,
	GET_MANUAL_FOLLOW_UP_REASONS_START,
	GET_MANUAL_FOLLOW_UP_REASONS_SUCCESS,
	GET_MANUAL_FOLLOW_UP_REASONS_FAIL,
	GET_MANUAL_FOLLOW_UP_CATEGORIES_START,
	GET_MANUAL_FOLLOW_UP_CATEGORIES_SUCCESS,
	GET_MANUAL_FOLLOW_UP_CATEGORIES_FAIL,
	SAVE_MANUAL_FOLLOW_UP_START,
	SAVE_MANUAL_FOLLOW_UP_SUCCESS,
	SAVE_MANUAL_FOLLOW_UP_FAIL,
	GET_MANUAL_FOLLOW_UPS_START,
	GET_MANUAL_FOLLOW_UPS_SUCCESS,
	GET_MANUAL_FOLLOW_UPS_FAIL,
	SAVE_MANUAL_FOLLOW_UP_REASONS_START,
	SAVE_MANUAL_FOLLOW_UP_REASONS_SUCCESS,
	SAVE_MANUAL_FOLLOW_UP_REASONS_FAIL,
	DONE_OR_REMOVE_MANUAL_FOLLOW_UP_START,
	DONE_OR_REMOVE_MANUAL_FOLLOW_UP_SUCCESS,
	DONE_OR_REMOVE_MANUAL_FOLLOW_UP_FAIL,
	ENABLE_NEW_REASON_START,
	UPDATE_REASON_FORM_START,
	RESET_REASON_FORM,
	INIT_MANUAL_FOLLOWUP,
	GET_FOLLOW_UP_SUMMARY,
	GET_FOLLOW_UP_SUMMARY_SUCCESS,
	GET_FOLLOW_UP_SUMMARY_FAIL,
} = ManualFollowUp;

export const manualFollowUpActions: Readonly<
	IAPIAction & IManualFollowUpAction
> = {
	users: {
		get: (data) => ({
			type: GET_MANUAL_FOLLOW_UP_USERS_START,
			payload: {
				data,
				isLoading: true,
			},
		}),

		success: (data) => ({
			type: GET_MANUAL_FOLLOW_UP_USERS_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: GET_MANUAL_FOLLOW_UP_USERS_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	categories: {
		get: (data) => ({
			type: GET_MANUAL_FOLLOW_UP_CATEGORIES_START,
			payload: {
				data,
				isLoading: true,
			},
		}),

		success: (data) => ({
			type: GET_MANUAL_FOLLOW_UP_CATEGORIES_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: GET_MANUAL_FOLLOW_UP_CATEGORIES_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	reasons: {
		get: (data) => ({
			type: GET_MANUAL_FOLLOW_UP_REASONS_START,
			payload: {
				data,
				isLoading: true,
			},
		}),

		success: (data) => ({
			type: GET_MANUAL_FOLLOW_UP_REASONS_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: GET_MANUAL_FOLLOW_UP_REASONS_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	reason: {
		save: (data) => ({
			type: SAVE_MANUAL_FOLLOW_UP_REASONS_START,
			payload: {
				data,
				isLoading: true,
			},
		}),

		success: (data) => ({
			type: SAVE_MANUAL_FOLLOW_UP_REASONS_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: SAVE_MANUAL_FOLLOW_UP_REASONS_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		openDrawer: (data) => ({
			type: ENABLE_NEW_REASON_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		updateFormData: (data) => ({
			type: UPDATE_REASON_FORM_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		reset: (data) => ({
			type: RESET_REASON_FORM,
			payload: {
				data,
				isLoading: true,
			},
		}),
	},
	manualFollowUps: {
		get: (data) => ({
			type: GET_MANUAL_FOLLOW_UPS_START,
			payload: {
				data,
				isLoading: true,
			},
		}),

		success: (data) => ({
			type: GET_MANUAL_FOLLOW_UPS_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: GET_MANUAL_FOLLOW_UPS_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	manualFollowUp: {
		save: (data) => ({
			type: SAVE_MANUAL_FOLLOW_UP_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: SAVE_MANUAL_FOLLOW_UP_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: SAVE_MANUAL_FOLLOW_UP_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		doneOrRemove: (data, entity) => ({
			type: DONE_OR_REMOVE_MANUAL_FOLLOW_UP_START,
			payload: {
				data,
				entity,
				isLoading: true,
			},
		}),
		doneOrRemoveSuccess: (data) => ({
			type: DONE_OR_REMOVE_MANUAL_FOLLOW_UP_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		doneOrRemoveFail: (error) => ({
			type: DONE_OR_REMOVE_MANUAL_FOLLOW_UP_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		init: (data) => ({
			type: INIT_MANUAL_FOLLOWUP,
			payload: {
				data,
				isLoading: true,
			},
		}),
	},
	followUpsSummary: {
		get: (data) => ({
			type: GET_FOLLOW_UP_SUMMARY,
			payload: {
				data,
				isLoading: true,
			},
		}),

		success: (data) => ({
			type: GET_FOLLOW_UP_SUMMARY_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: GET_FOLLOW_UP_SUMMARY_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
};
