import React from "react";
import { ToggleClass } from "us.helper";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BMD } from "us.collection/components";

interface ICnt_BMD extends RouteComponentProps {}

const Cnt_BMD: React.FC<ICnt_BMD> = (props) => {
  ToggleClass("change-body-color-dom");
  return <BMD />;
};

export default withRouter(Cnt_BMD);
