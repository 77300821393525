import { IRowProperties } from "us.common/components/CaseBalanceUpdate/Interfaces";

export const rows: Array<IRowProperties> = [
    {
      label: "US.COLLECTION.CASE:ADD_COURT_SENTENCE.DUE_DATE",
      inputAmountName: "dueDate",
    },
    {
      label: "US.COLLECTION.CASE:ADD_COURT_SENTENCE.LIMIT_DATE",
      inputAmountName: "limitDate",
    },
    {
      label: "US.COLLECTION.CASE:ADD_COURT_SENTENCE.MAIN_AMOUNT",
      amountLabelName: "mainAmount",
      inputAmountName: "mainAmount",
      interestFromName: "mainAmountFromDate",
      interestToName: "mainAmountToDate",
      interestRateName: "mainAmountRate",
      isInterestToDisabled: true
    },
    {
      label: "US.COLLECTION.CASE:ADD_COURT_SENTENCE.CASE_COST",
      amountLabelName: "caseCost",
      inputAmountName: "caseCost",
      interestFromName: "caseCostFromDate",
      interestToName: "caseCostToDate",
      interestRateName: "caseCostRate",
      isInterestToDisabled: true
    },
    {
      label: "US.COLLECTION.CASE:ADD_COURT_SENTENCE.COLLECTION_FEE",
      amountLabelName: "collectionFee",
      inputAmountName: "collectionFee",
      interestFromName: "collectionFeeFromDate",
      interestToName: "collectionFeeToDate",
      interestRateName: "collectionFeeRate",
      isInterestToDisabled: true,
    },
    {
      label: "US.COLLECTION.CASE:ADD_COURT_SENTENCE.COURT_FEE",
      amountLabelName: "courtFee",
      inputAmountName: "courtFee",
      interestFromName: "courtFeeFromDate",
      interestToName: "courtFeeToDate",
      interestRateName: "courtFeeRate",
      isInterestFromDisabled: true,
      isInterestToDisabled: true,
      isInterestRateDisabled: true
    },
    {
      label: "US.COLLECTION.CASE:ADD_COURT_SENTENCE.OTHER_COSTS",
      amountLabelName: "otherCosts",
      inputAmountName: "otherCosts",
      interestFromName: "otherCostsFromDate",
      interestToName: "otherCostsToDate",
      interestRateName: "otherCostsRate",
      isInterestToDisabled: true
    },
    {
      label: "US.COLLECTION.CASE:ADD_COURT_SENTENCE.MAIN_AMOUNT_INTEREST",
      amountLabelName: "interest",
      inputAmountName: "mainAmountInterest",
      interestFromName: "mainAmountInterestFromDate",
      interestToName: "mainAmountInterestToDate",
      interestRateName: "mainAmountInterestRate",
      isInterestFromDisabled: true
    },
    {
      label: "US.COLLECTION.CASE:ADD_COURT_SENTENCE.INTEREST_OF_COSTS",
      amountLabelName: "interestCollection",
      inputAmountName: "interestCollectionFee",
      interestFromName: "interestCollectionFeeFromDate",
      interestToName: "interestCollectionFeeTo",
      interestRateName: "interestCollectionFeeRate",
      isInterestFromDisabled: true
    },
  ];