export enum LastAction {
  SUCCESS = "success",
  OPEN = "open",
  FAILED = "Failed",
  VIEW = "view",
  PENDING = "Pending",
  EXECUTED = "Executed",
  PENDING_FAILED = "Pending-Failed",
  OTHER = "Other",
  ALL = "all",
}

export type LastActionInterface =
  | LastAction.SUCCESS
  | LastAction.OPEN
  | LastAction.FAILED
  | LastAction.VIEW
  | LastAction.PENDING
  | LastAction.EXECUTED
  | LastAction.PENDING_FAILED
  | LastAction.OTHER
  | LastAction.ALL;
