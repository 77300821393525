import React from 'react';
import { ToggleClass } from 'us.helper';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IDataModel } from 'us.collection.case/reducers/Dashboard/Interfaces';
import { IMetaData } from 'us.collection/interfaces';
import { DebtorInformation } from 'us.collection.debtor/components';
import { RootState } from 'us.helper/types';

interface ICnt_DebtorInfo extends RouteComponentProps {
	caseNo: number;
	metaData: IDataModel<IMetaData>;
}

const Cnt_DebtorInfo: React.FC<ICnt_DebtorInfo> = (props) => {
	ToggleClass('change-body-color-dom');

	const { metaData, history, location } = props;
	const { caseNo } = metaData.data ?? {};
	const onMinimize = () => {
		history.push({ ...location, pathname: `/case/${caseNo}` });
	};

	return <DebtorInformation onMinimize={onMinimize} {...props} />;
};

const mapStateToProps = (state: RootState) => {
	const { domainView } = state;
	const { metaData } = domainView;
	return {
		metaData,
	};
};

export default withRouter(connect(mapStateToProps)(Cnt_DebtorInfo));
