import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { Formik } from 'formik';
import moment from 'moment';

import Common from 'us.common';
import { SendOutlined, LoadingOutlined } from 'us.icons';
import { LetterValidationSchema } from 'us.collection.case/components/Validations';
import '../../ContentV1.scss';
import { MessageAndNoteAction } from 'us.collection.case/actions';
import { $Skeleton, $TextEditor } from 'us.common/components';
import { IRootState } from 'us.collection/interfaces';
import { ILetter } from 'us.collection.case/components/Interfaces/NotesAndMessage';
import {
	FormatedTemplate,
	SendDomainMassage,
} from 'us.collection.case/repository';
import { DomainMessageType } from 'us.collection.case/constants/NotesAndMessage';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { appInsights } from 'us.helper';
import { RootState } from 'us.helper/types';

const {
	$Form,
	$Drawer,
	$Select,
	$Button,
	$DatePicker,
	$Switch,
	$TextArea,
	$AutoComplete,
	$Spin,
	$Row,
	$Col,
	$Typography,
} = Common.Components;

const { Paragraph } = $Typography;

const { formattedTemplate, domainMessage } = MessageAndNoteAction;

const Letter: React.FC<ILetter & PropsFromRedux> = (props) => {
	const { t } = useTranslation();

	const {
		currentDateFormat,
		domainMessageTemplate,
		domainMessageFormattedTemplate,
		getFormattedTemplate,
		openTemplateDrawer,
		openDrawer,
		metaData,
		domainMessageInitial,
		reset,
		send,
		setNoteView,
		sendDomainMessage,
	} = props;

	useEffect(() => {
		if (sendDomainMessage?.data?.isSuccess) {
			reset && reset({});
			setNoteView(DomainMessageType.LETTERS);
		}
	}, [sendDomainMessage?.data?.isSuccess]);

	const handleSubmit_ = (data: any) => {
		try {
			if (metaData.data) {
				const requestBody = SendDomainMassage.call(
					data,
					'Document',
					metaData.data,
					domainMessageFormattedTemplate?.data
				);
				send && send(requestBody);
				openDrawer &&
					openDrawer({
						title: '',
						visible: false,
					});
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard NotesAndMessage-Letter Submit Exeception - ${error}`
			);
		}
	};

	const handleTemplateDrawer = (
		value: string,
		rest: any,
		values: any
	) => {
		try {
			if (metaData.data) {
				rest.setFieldValue('template', value);
				const template = domainMessageTemplate?.find(
					(template: any) =>
						template.TextName === value
				);
				const queryObject = FormatedTemplate.call(
					template.Text,
					value,
					values,
					metaData.data
				);
				getFormattedTemplate &&
					getFormattedTemplate(queryObject);
				openDrawer &&
					openDrawer({
						title: t(
							'US.COLLECTION.CASE:NOTESANDMESSAGES.EDIT_DOCUMENT'
						),
						visible: true,
					});
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard NotesAndMessage-Letter Drawer Handling Exeception - ${error}`
			);
		}
	};

	const closeTemplateDrawer = (rest: any) => {
		reset && reset({});
		rest.setFieldValue('template', '');
		rest.setFieldValue('templateNote', '');
		openDrawer && openDrawer({ title: '', visible: false });
	};

	const handleChangeTemplateText = (content: any, rest: any) => {
		rest.setFieldValue('templateNote', content);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={domainMessageInitial}
			validationSchema={LetterValidationSchema}
			onSubmit={handleSubmit_}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...rest
			}: any) => (
				<div className='py-3'>
					<$Form>
						<$Row
							gutter={16}
							className='mt-0'>
							<$Col
								span={10}
								xxl={{
									span: 10,
								}}
								xl={{
									span: 12,
								}}>
								<div
									style={{
										marginTop: 0,
									}}
									data-testid='document-type-label'>
									{t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.DOCUMENTTYPE'
									)}
								</div>
							</$Col>
							<$Col
								span={14}
								xxl={{
									span: 14,
								}}
								xl={{
									span: 12,
								}}
								data-testid='select-document-type'>
								<$Select
									className='w-100'
									name='documentType'
									placeholder='Status'
									size='small'
									allOption={
										false
									}
									options={
										domainMessageInitial.documentTypeList
									}
									defaultValue={
										values.documentType
									}
									disabled={
										sendDomainMessage?.isProgress ||
										(metaData.data &&
											isSubCaseTransferredToCase(
												metaData.data
											))
									}
									data-testid='document-type'
								/>
							</$Col>
						</$Row>

						<$Row
							gutter={16}
							className='mt-2'>
							<$Col
								span={10}
								xxl={{
									span: 10,
								}}
								xl={{
									span: 12,
								}}>
								<div
									style={{
										marginTop: 5,
									}}
									data-testid='due-date-label'>
									{t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.MANUALDUEDATE'
									)}
								</div>
							</$Col>
							<$Col
								span={14}
								xxl={{
									span: 14,
								}}
								xl={{
									span: 12,
								}}>
								<$DatePicker
									formitem
									formitemlabel={t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.MANUALDUEDATE'
									)}
									className='w-100'
									name='dueDate'
									size='small'
									placeholder={
										currentDateFormat
									}
									format={
										currentDateFormat
									}
									value={
										values.dueDate
									}
									defaultPickerValue={
										values.dueDate
									}
									allowClear
									disabledDate={(
										day: any
									) =>
										day.isBefore(
											moment().startOf(
												'day'
											)
										)
									}
									disabled={
										sendDomainMessage?.isProgress ||
										(metaData.data &&
											isSubCaseTransferredToCase(
												metaData.data
											))
									}
									data-testid='manual-duedate'
								/>
							</$Col>
						</$Row>

						<$Row
							gutter={16}
							className='mt-0'>
							<$Col
								span={10}
								xxl={{
									span: 10,
								}}
								xl={{
									span: 12,
								}}>
								<div
									style={{
										marginTop: 5,
									}}
									data-testid='template-label'>
									{t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.TEMPLATE'
									)}
								</div>
							</$Col>
							<$Col
								span={14}
								xxl={{
									span: 14,
								}}
								xl={{
									span: 12,
								}}
								data-testid='domain-templates'>
								<$AutoComplete
									{...rest}
									size='small'
									name='template'
									options={
										domainMessageInitial.templateList
									}
									defaultValue={
										values.Template
									}
									onSelect={(
										templateValue: any
									) =>
										handleTemplateDrawer(
											templateValue,
											rest,
											values
										)
									}
									disabled={
										sendDomainMessage?.isProgress ||
										(metaData.data &&
											isSubCaseTransferredToCase(
												metaData.data
											))
									}
									data-testid='select-domain-templates-options'
								/>
							</$Col>
						</$Row>

						<$Row
							gutter={16}
							className='mt-2'>
							<$Col
								span={24}
								xxl={{
									span: 24,
								}}
								xl={{
									span: 24,
								}}>
								<div
									className='d-flex align-items-center'
									data-testid='is-giro'>
									<$Switch
										{...rest}
										size='small'
										name='isGiroChecked'
										defaultChecked={
											values.isGiroChecked
										}
										checked={
											values.isGiroChecked
										}
										disabled={
											sendDomainMessage?.isProgress ||
											(metaData.data &&
												isSubCaseTransferredToCase(
													metaData.data
												))
										}
									/>
									<Paragraph
										ellipsis
										className='mb-0 ml-2'
										data-testid='giro-label'>
										{t(
											'US.COLLECTION.CASE:NOTESANDMESSAGES.GIRO'
										)}
									</Paragraph>
								</div>
							</$Col>
						</$Row>

						{!openTemplateDrawer.visible && (
							<$Row
								gutter={16}
								className='mt-3'>
								<$Col
									span={
										24
									}
									xxl={{
										span: 24,
									}}
									xl={{
										span: 24,
									}}>
									<$TextArea
										name='caseNote'
										className='note-message'
										data-testid='case-note'
										placeholder={t(
											'US.COLLECTION.CASE:NOTESANDMESSAGES.TYPEANOTEHERE'
										)}
										defaultValue={
											values.caseNote
										}
										disabled={
											sendDomainMessage?.isProgress ||
											(metaData.data &&
												isSubCaseTransferredToCase(
													metaData.data
												))
										}
									/>
								</$Col>
							</$Row>
						)}

						<div className='d-flex align-items-center justify-content-between mt-2'>
							<div>
								{sendDomainMessage?.isProgress && (
									<small className='d-block'>
										<$Spin
											size='small'
											indicator={
												<LoadingOutlined
													style={{
														fontSize: 20,
													}}
													spin
												/>
											}
										/>
										<span className='ml-2'>
											{t(
												'US.COLLECTION.CASE:NOTESANDMESSAGES.SEND_LETTER_IN_PROGRESS'
											)}
										</span>
									</small>
								)}
							</div>
							<$Button
								type='default'
								htmlType='submit'
								onClick={
									handleSubmit
								}
								icon={
									<SendOutlined />
								}
								disabled={
									(metaData.data &&
										isSubCaseTransferredToCase(
											metaData.data
										)) ||
									sendDomainMessage?.isProgress
								}
								data-testid='send-btn'>
								{t(
									'US.COLLECTION.COMMON:COMMON.SEND'
								)}
							</$Button>
						</div>

						<$Drawer
							width='800'
							title={
								openTemplateDrawer.title
							}
							placement='right'
							closable={false}
							onClose={() =>
								closeTemplateDrawer(
									rest
								)
							}
							visible={
								openTemplateDrawer.visible
							}
							data-testid='edit-template'>
							<$Skeleton
								loading={
									domainMessageFormattedTemplate?.isLoading
								}
								active
								paragraph={{
									rows: 2,
								}}>
								<div>
									<$TextEditor
										value={
											values.templateNote
										}
										handleChange={(
											content: any
										) =>
											handleChangeTemplateText(
												content,
												rest
											)
										}
										name='templateNote'
									/>
								</div>
							</$Skeleton>
							<div className='drawer-footer-fixed align-content-center justify-content-end'>
								<div>
									<$Button
										className='mr-2'
										type='primary'
										htmlType='submit'
										onClick={
											handleSubmit
										}>
										{t(
											'US.COLLECTION.COMMON:COMMON.SEND'
										)}
									</$Button>
									<$Button
										data-testid='drawer-cancel-btn'
										onClick={() =>
											closeTemplateDrawer(
												rest
											)
										}>
										{t(
											'US.COLLECTION.COMMON:COMMON.CANCEL'
										)}
									</$Button>
								</div>
							</div>
						</$Drawer>
					</$Form>
				</div>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, domainView, messageAndNotes } = state;
	const { currentDateFormat } = common;
	const { metaData } = domainView;
	const {
		domainMessageTemplate,
		domainMessageFormattedTemplate,
		openTemplateDrawer,
		domainMessageBasicdetail,
		domainMessageInitial,
		sendDomainMessage,
	} = messageAndNotes;

	return {
		currentDateFormat,
		domainMessageTemplate,
		domainMessageFormattedTemplate,
		openTemplateDrawer,
		metaData,
		domainMessageInitial,
		domainMessageBasicdetail,
		sendDomainMessage,
	};
};

const mapDispatchToProps = {
	getFormattedTemplate: formattedTemplate.get,
	openDrawer: formattedTemplate.openDrawer,
	reset: formattedTemplate.reset,
	send: domainMessage.save,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Letter);
