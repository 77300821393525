import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Affix,  PageHeader,  Divider,  Button,  Drawer,  Table,  Form,  Select,  Input, Switch} from "antd";
import AddNewActivity from './AddNew';
import { useTranslation } from 'react-i18next';

interface IActivities extends RouteComponentProps {
    onMinimize(): void;
}

export default withRouter((props: IActivities) => {

    const { history } = props;
    const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
    const { t } = useTranslation();

    const AddClickHandler = () => {
        history.push('/activities');
    }

    const minimize = () => {
        props.onMinimize();
      };

    const showDrawer = () => {
        setIsDrawerVisible(true);
    }

    const closeDrawer = () => {
        setIsDrawerVisible(false);
    }

    return (
        <>
        <Affix offsetTop={48}>
          <div className="page-header">
            <div className="d-flex align-items-center">
              <PageHeader
                onBack={minimize}
                className="px-0 py-3 mr-n3"
                title={t("US.COLLECTION.COMMON:APPLAYOUT.ACTIVITIES")}
              />
               <Divider className="bui-devider" type="vertical" />
                <Button
                type="primary"
                size="small"
                icon="plus"
                onClick={showDrawer}
                >
                {t("US.COLLECTION.ADMIN:ACTIVITIES.NEWACTIVITY")}
                </Button>
                <div className="ml-auto d-flex">
                <Select
                      style={{ width: '150px' }}
                      placeholder="select one country"
                      defaultValue={['all']}
                      size ="small"
                     className="mr-3"
                    >
                   
                    </Select>
                    <Select
                      mode="multiple"
                      style={{ width: '350px' }}
                      placeholder="select one country"
                      defaultValue={['Collection','Default']}
                     size ="small"
                    >
                     
                    </Select>
              </div>
            </div>
          </div>
        </Affix>
        <div className="space-content">
          <Table className="mt-3" size="small" />
        </div>

            <Drawer
                title={t("US.COLLECTION.ADMIN:ACTIVITIES.NEWACTIVITY")}
                placement="right"
                width={780}
                onClose={closeDrawer}
                visible={isDrawerVisible}
                getContainer={false}
            >
                <AddNewActivity />
            </Drawer>
        </>
    )
});