import { versionedHttpCollection } from "us.helper/http/collection";
import {
  ISalaryDeductionPlan,
  ISalaryDeductionSchema,
} from "us.collection.case/interfaces";
import ServiceConfig from "service.config.js";
import moment from "moment";

export default {
  /**
   * A namespace
   * @namespace enforcements.services
   */
  enforcements: {
    /**
     * Get all enforcements
     * @param params {number} caseId - AR table caseno
     * @returns {Promise} Promise object represents the API response
     */
    get: <P extends { caseId: number }>(params: P): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.get(
            "enforcementservice",
            `enforcement`,
            {
              caseId: params.caseId,
            },
            "V1"
            // ServiceConfig()[`enforcementservice`]['enforcement']
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  enforcement: {
    save: <P extends {}>(params: P): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.post(
            "enforcementservice",
            "enforcement",
            params,
            "v1"
            // ServiceConfig()[`enforcementservice`]['enforcement.save']
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    delete: <
      P extends {
        enforcementId: number;
      }
    >(
      params: P
    ): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.patch(
            "enforcementservice",
            "enforcement",
            {
              enforcementId: params.enforcementId,
            },
            "v1"
            // ServiceConfig()[`enforcementservice`]['enforcement.delete']
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  types: {
    getWithAssets: <
      P extends {
        entityType: string;
        entityId: number;
      }
    >(
      params: P
    ): Promise<any> => {
      return new Promise<{
        enforcementTypes: Array<{}>;
        assets: Array<{}>;
      }>(async (resolve, reject) => {
        try {
          const enforcementTypes = await versionedHttpCollection.get(
            "enforcementservice",
            "enforcementtype",
            {},
            "v1"
            // ServiceConfig()[`enforcementservice`]['enforcement.type']
          );

          const assets = await versionedHttpCollection.get(
            "assetservice",
            "assets",
            params,
            "v1"
            // ServiceConfig()[`assetservice`]["assets"]
          );

          resolve({
            enforcementTypes: enforcementTypes?.data,
            assets: assets?.data,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  salaryDeductionPlan: {
    save: <P extends ISalaryDeductionPlan>(params: P): Promise<any> => {
      return new Promise<{
        salaryDeductionSchema: Array<ISalaryDeductionSchema>;
      }>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.post(
            "enforcementservice",
            "salarydeductionplan",
            params,
            "v1"
            // ServiceConfig()[`enforcementservice`]['enforcement.salarydeductionplan']
          );
          if ([400, 401, 403, 404, 500].includes(status)) {
            reject({
              salaryDeductionSchema: null,
            });
          } else {
            resolve({
              salaryDeductionSchema: data,
            });
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    get: <P extends {caseId: string, enforcementId: number}>(params: P): Promise<any> => {
      return new Promise<{
        salaryDeductionPlan: Array<ISalaryDeductionPlan>;
      }>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "enforcementservice",
            "salarydeductionplan",
            params,
            "v1"
            // ServiceConfig()[`enforcementservice`]['enforcement.salarydeductionplan']
          );
          if ([400, 401, 403, 404, 500, 204].includes(status)) {
            reject({
              salaryDeductionPlan: null,
            });
          } else {
            resolve({
              salaryDeductionPlan: data,
            });
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    delete:  <P extends {salaryDeductionPlanId: number}>(params: P): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.delete(
            "enforcementservice",
            `salarydeductionplan`,
            params.salaryDeductionPlanId,
            {},
            "v1"
            // ServiceConfig()[`enforcementservice`]['enforcement.salarydeductionplan']
          );
          if ([400, 401, 403, 404, 500].includes(status)) {
            reject(data);
          } else {
            resolve(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    }
  },
  accountSummary: {
    /**
     * Get enforcement account summary
     * @param params {number} caseId - AR table caseno
     * @returns {Promise} Promise object represents the API response
     */
    get: <P extends { caseId: number }>(params: P): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "enforcementservice",
            `enforcementamountsummery/${params.caseId}`,
            {},
            "V1"
          );
          if(status == 200){
            resolve(data);
          }
          else {
            reject(data)
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};