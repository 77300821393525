import React from "react";
import { useTranslation } from "react-i18next";
import { ConnectedProps, connect } from "react-redux";
import { Formik } from "formik";
import Common from "us.common";
import { SendOutlined, LoadingOutlined } from "us.icons";
import { CPNoteValidationSchema } from "us.collection.case/components/Validations";
import { MessageAndNoteAction } from "us.collection.case/actions";
import { SendDomainCPNote } from "us.collection.case/repository";
import { isSubCaseTransferredToCase } from "us.common/functions";
import { appInsights } from "us.helper";
import { RootState } from "us.helper/types";
import "../../ContentV1.scss";

const { $Form, $Button, $TextArea, $Spin, $Row, $Col } = Common.Components;

/**
 * @description - Creditor portal note sending component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3292627347/Domain+message+-+Template+editor+UI+design
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 11/13/2023
 */
const CreditorPortalNote: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();

  const { metaData, creditorPortalNote, sendNote } = props;

  const handleSubmit = (data: any) => {
    try {
      if (metaData.data) {
        const queryObject = SendDomainCPNote.call(data, {
          ...metaData.data,
        });

        sendNote && sendNote(queryObject);
      }
    } catch (error) {
      appInsights.trackException(
        `Dashboard NotesAndMessage-CP Note Submit Exeception - ${error}`
      );
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={creditorPortalNote.initFormData}
      validationSchema={CPNoteValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit }: any) => (
        <div className="py-3">
          <$Form>
            <span>
              {t(
                "US.COLLECTION.CASE:NOTESANDMESSAGES.WRITE_A_NOTE_TO_SEND_TO_THE_CREDITOR"
              )}
            </span>
            <$Row gutter={16} className="mt-3">
              <$Col
                span={24}
                xxl={{
                  span: 24,
                }}
                xl={{
                  span: 24,
                }}
              >
                <$TextArea
                  name="note"
                  className="note-message"
                  data-testid="cp-note"
                  placeholder={t(
                    "US.COLLECTION.CASE:NOTESANDMESSAGES.TYPEANOTEHERE"
                  )}
                  defaultValue={values.note}
                  disabled={
                    creditorPortalNote?.isSending ||
                    (metaData.data && isSubCaseTransferredToCase(metaData.data))
                  }
                />
              </$Col>
            </$Row>

            <div className="d-flex align-items-center justify-content-between mt-2">
              <div>
                {creditorPortalNote?.isSending && (
                  <small className="d-block">
                    <$Spin
                      size="small"
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 20,
                          }}
                          spin
                        />
                      }
                    />
                    <span className="ml-2">
                      {t(
                        "US.COLLECTION.CASE:NOTESANDMESSAGES.SEND_CP_NOTE_IN_PROGRESS"
                      )}
                    </span>
                  </small>
                )}
              </div>
              <$Button
                type="default"
                htmlType="submit"
                onClick={handleSubmit}
                icon={<SendOutlined />}
                disabled={
                  (metaData.data &&
                    isSubCaseTransferredToCase(metaData.data)) ||
                  creditorPortalNote?.isSending
                }
                data-testid="send-btn"
              >
                {t("US.COLLECTION.COMMON:COMMON.SEND")}
              </$Button>
            </div>
          </$Form>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  const { domainView, messageAndNotes } = state;
  const { metaData } = domainView;
  const { creditorPortalNote } = messageAndNotes;

  return {
    metaData,
    creditorPortalNote,
  };
};

const { creditorPortalNote } = MessageAndNoteAction;

const mapDispatchToProps = {
  sendNote: creditorPortalNote.save,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreditorPortalNote);
