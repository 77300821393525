import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { $Button, $Skeleton } from 'us.common/components';
import { RootState } from 'us.helper/types';
import { PushpinFilled, PushpinOutlined, RightOutlined } from 'us.icons';
import './Dashboard.scss';
import { followUps } from 'us.collection.followup/actions';
import { isArray } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { FollowUpItem } from './Components';
import { FollowUpPinStatus, Followup } from 'us.collection.followup/interfaces';
import { getNonEmptyFollowUps } from './Functions';
import { useMsal } from '@azure/msal-react';

/**
 * @description - Collection dashboard(home landing page) component
 * @link Design Document -
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 15/08/2023
 */
const Dashboard: React.FC<PropsFromRedux> = (props) => {
	const { followups, getFollowUpList } = props;
	const { t } = useTranslation();
	const { push } = useHistory();
	const location = useLocation();
	const { instance } = useMsal();

	const userName = useMemo(() => {
		return instance.getActiveAccount()?.name;
	}, [instance]);

	const pinnedFollowups = useMemo(() => {
		return getNonEmptyFollowUps(followups.data);
	}, [followups.data]);

	useEffect(() => {
		getFollowUpList &&
			getFollowUpList({
				categoryId: -1,
				pinStatus: FollowUpPinStatus.PIN,
			});
	}, []);

	return (
		<div
			className={`home-followup ${
				!followups.isFetching &&
				isArray(pinnedFollowups) &&
				pinnedFollowups.length == 0
					? 'home-followup-empty'
					: ''
			}`}>
			<div className='home-greeting'>
				<div className='line-1'>
					{`${t(
						'US.COLLECTION.HOME:HOME.HELLO'
					)}! ${userName}`}
					,
				</div>
				<div className='line-2'>
					{`${t(
						'US.COLLECTION.HOME:HOME.WELCOME_TO'
					)} `}
					<strong>UnicornBOX 4.0</strong>!
				</div>
			</div>

			<$Skeleton
				loading={followups.isFetching}
				active
				paragraph={{ rows: 4 }}>
				{isArray(pinnedFollowups) &&
					pinnedFollowups.length == 0 && (
						<div className='hfe-message'>
							<div className='line-3'>
								<PushpinFilled />
								{` ${t(
									'US.COLLECTION.HOME:HOME.PIN_YOUR_FOLLOW_UPS_TO_APPEAR_HERE'
								)}`}
							</div>
							<$Button
								type='primary'
								className='px-3'
								style={{
									marginTop: 10,
								}}
								onClick={() =>
									push({
										...location,
										pathname: `/follow-up`,
									})
								}>
								{t(
									'US.COLLECTION.HOME:HOME.ALL_FOLLOW_UPS'
								)}
								<RightOutlined className='ml-3' />
							</$Button>
						</div>
					)}
				{isArray(pinnedFollowups) &&
					pinnedFollowups.length > 0 && (
						<>
							<div className='d-flex align-items-center mb-4'>
								<h2 className='mr-5 mb-0'>
									{t(
										'US.COLLECTION.HOME:HOME.THINGS_TO_BE_DONE'
									)}
								</h2>
								<$Button
									type='primary'
									className='px-3'
									style={{
										marginTop: 10,
									}}
									size='large'
									onClick={() =>
										push(
											{
												...location,
												pathname: `/follow-up`,
											}
										)
									}>
									{t(
										'US.COLLECTION.HOME:HOME.ALL_FOLLOW_UPS'
									)}
									<RightOutlined className='ml-3' />
								</$Button>
							</div>

							<div className='home-followup-wrap'>
								{pinnedFollowups.map(
									(
										followup
									) => (
										<FollowUpItem
											key={followup.followupId.toString()}
											item={
												followup
											}
											onPress={(
												followupItem: Followup
											) =>
												push(
													{
														...location,
														pathname: `/follow-up/${followupItem.followupId}`,
													}
												)
											}
										/>
									)
								)}
							</div>
						</>
					)}
			</$Skeleton>
		</div>
	);
};

const mapStateToProps = (state: RootState) => {
	const { followUps } = state;
	const { categories, list, isBulkFollowupReImporting } = followUps;
	return {
		categories,
		followups: list,
		isBulkFollowupReImporting,
	};
};

const { list } = followUps;

const mapDispatchToProps = {
	getFollowUpList: list.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Dashboard);
