export enum EntityType {
    SUBCASE = "SubCase",
    CASE = "Case",
    AR = "AR",
    CREDITOR = "Creditor",
    PAYMENT = "Payment",
    BUREAU = "Bureau",
    ALL = "All"
}

export enum URLType {
    SUBCASE = "sub-case",
    CASE = "case",
    AR = "ar",
    CREDITOR = "creditor",
    PAYMENT = "payment",
    BUREAU = "bureau",
}
export enum Type {
    SUBCASE = "S",
    CASE = "C",
    AR = "A",
    CREDITOR = "CRE",
    PAYMENT = "P",
    BUREAU = "BU",
    ALL = "All",
    CR = "CR",
}
export enum DocumentType {
    EMAIL = "email",
    PDF = "pdf",
    TEXT = 'text',
    TXT = 'txt',
    SMS = "sms",
    XML = "xml",
    UNKNOWN = "doc-unknown",
    HTML = "html",
    SP = "db-sp",
    MESSAGE = 'message',
    ATTACHEMENT = "attachment",
    USC_SERVICE = "USCService",
    DOCUMENT_UPLOAD = "documentupload",
    OUTPUT_DOCUMENT = "outputdocument",
    ENTITY_DOCUMENT = "EntityDocument",
    DOC = "doc",
    PNG = "png",
    JPG = "jpg",
    JPEG = "jpeg",
    ROUTINE = "routine",
    EVENTS= "events",
    ALL= "all"
}

export enum IconType {
    DELIVERED = 'delivered',
    TEXT = "text",
    NOTE = "note",
    PENDING = "pending",
    EXECUTED = "executed",
    ERROR = "error"
}

export enum dateFormats {
    req = "YYYY-MM-DD",
    time = "HH:mm:ss",
    minDate = "0001-01-01"
}

export const unableToDisplayFormats = ["doc", "docx", "csv", "xls", "xlsx", "ppt", "pptx"];

export enum NoteNotifiedStatus {
    READ = 1,
    UNREAD = 0
}