import { URLType } from "us.collection/constants/DomainView";
import { EntityType } from "../Constants";

/**
 * To get the corrosponding entity number and type
 * @param paramType URL parameter
 * @param paramId URL param ID
 * @param caseId Case number
 * @returns object with corroponding entityId and entityType for the url
 */
export const getEntityNumberAndType = (
  paramType: URLType,
  paramId: string,
  caseId: string
): { entityId: string; entityType: EntityType } => {
  switch (paramType) {
    case URLType.CASE:
      return { entityId: caseId, entityType: EntityType.CASE };
    case URLType.AR:
      return { entityId: paramId, entityType: EntityType.AR };
    case URLType.CREDITOR:
      return { entityId: paramId, entityType: EntityType.CREDITOR };
    case URLType.CREDITOR_GROUP:
      return { entityId: paramId, entityType: EntityType.CREDITOR_GROUP };
    case URLType.BUREAU:
      return { entityId: "-1", entityType: EntityType.BUREAU };
    default:
      return { entityId: caseId, entityType: EntityType.CASE };
  }
};
