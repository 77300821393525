import moment from "moment";
import { ISmsTime } from "us.collection.admin/interfaces";

export function smsTime(this: number): Array<ISmsTime>{
  return [
    {
      dayId: 1,
      day: "Monday",
      isEnable: false,
      timeSlots: [{ id: -1, startTime: moment().year(this).set({hour: 8, minute: 0}), endTime: moment().year(this).set({hour: 20 , minute: 0}) }],
    },
    {
      dayId: 2,
      day: "Tuesday",
      isEnable: false,
      timeSlots: [{ id: -1, startTime: moment().year(this).set({hour: 8, minute: 0}), endTime: moment().year(this).set({hour: 20 , minute: 0}) }],
    },
    {
      dayId: 3,
      day: "Wednesday",
      isEnable: false,
      timeSlots: [{ id: -1, startTime: moment().year(this).set({hour: 8, minute: 0}), endTime: moment().year(this).set({hour: 20 , minute: 0}) }],
    },
    {
      dayId: 4,
      day: "Thursday",
      isEnable: false,
      timeSlots: [{ id: -1, startTime: moment().year(this).set({hour: 8, minute: 0}), endTime: moment().year(this).set({hour: 20 , minute: 0}) }],
    },
    {
      dayId: 5,
      day: "Friday",
      isEnable: false,
      timeSlots: [{ id: -1, startTime: moment().year(this).set({hour: 8, minute: 0}), endTime: moment().year(this).set({hour: 20 , minute: 0}) }],
    },
    {
      dayId: 6,
      day: "Saturday",
      isEnable: false,
      timeSlots: [{ id: -1, startTime: moment().year(this).set({hour: 8, minute: 0}), endTime: moment().year(this).set({hour: 20 , minute: 0}) }],
    },
    {
      dayId: 7,
      day: "Sunday",
      isEnable: false,
      timeSlots: [{ id: -1, startTime: moment().year(this).set({hour: 8, minute: 0}), endTime: moment().year(this).set({hour: 20 , minute: 0}) }],
    },
  ];
}
