import { IPaymentModel } from "../Interface";

export const Payment: IPaymentModel = {
  data:{
    paymentId:0,
    amount:0,
    sourceAccountNo:'',
    bankAccountNo:'',
    filename:'',
    invoiceNo:'',
    transType:'',
    transTypeId:0,
    kid:'',
    paymentDate:'',
    voucherDate:''
  },
  error: false,
  isLoading: false,
};
