import { initialState } from "us.collection.creditor/reducers/ManageCreditors/State";
import { ManageCreditor } from "us.collection.creditor/constants/Actions/ManageCreditors";

const {
  GET_POSTAL_AREAS_BY_CREDITOR,
  GET_POSTAL_AREAS_BY_CREDITOR_SUCCESS,
  GET_POSTAL_AREAS_BY_CREDITOR_FAIL,
  GET_BELONGS_TO_CREDITOR,
  GET_BELONGS_TO_CREDITOR_SUCCESS,
  GET_BELONGS_TO_CREDITOR_FAIL,
  GET_NEXT_CREDITOR_NO,
  GET_NEXT_CREDITOR_NO_SUCCESS,
  GET_NEXT_CREDITOR_NO_FAIL,
  GET_CREDITOR_NO_VALIDATION_STATUS,
  GET_CREDITOR_NO_VALIDATION_STATUS_SUCCESS,
  GET_CREDITOR_NO_VALIDATION_STATUS_FAIL,
  ADD_NEW_CREDITOR,
  ADD_NEW_CREDITOR_SUCCESS,
  ADD_NEW_CREDITOR_FAIL,
  GET_CASE_HANDLERS,
  GET_CASE_HANDLERS_SUCCESS,
  GET_CASE_HANDLERS_FAIL,
  RESET_BELONGS_TO_CREDITOR,
  UPDATE_NEW_CREDITOR,
  UPDATE_NEW_CREDITOR_SUCCESS,
  UPDATE_NEW_CREDITOR_FAIL,
} = ManageCreditor;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_POSTAL_AREAS_BY_CREDITOR:
      return Object.assign({}, state, {
        postalDetails: {},
        isValidPostalCodes: false
      });
    case GET_POSTAL_AREAS_BY_CREDITOR_SUCCESS:
      return Object.assign({}, state, {
        postalDetails: payload?.data[0],
        isValidPostalCodes: true
      });
    case GET_POSTAL_AREAS_BY_CREDITOR_FAIL:
      return Object.assign({}, state, {
        postalDetails: {},
        isValidPostalCodes: false
      });
    case GET_BELONGS_TO_CREDITOR:
      return Object.assign({}, state, {
        belongsToCreditor: {},
      });
    case GET_BELONGS_TO_CREDITOR_SUCCESS:
      return Object.assign({}, state, {
        belongsToCreditor: payload?.data,
        isValidBelongsToCreditor: true,
      });
    case GET_BELONGS_TO_CREDITOR_FAIL:
      return Object.assign({}, state, {
        belongsToCreditor: {},
        isValidBelongsToCreditor: false,
      });
    case RESET_BELONGS_TO_CREDITOR:
      return Object.assign({}, state, {
        belongsToCreditor: {},
        isValidBelongsToCreditor: true,
      });
    case GET_NEXT_CREDITOR_NO:
      return Object.assign({}, state, {
        nextCreditorNo: "",
        nextCreditorNoLoading: true,
        newEntityData: {},
        isValidBelongsToCreditor: true,
        isCreditorNoValid: true,
        isValidPostalCodes: false
      });
    case GET_NEXT_CREDITOR_NO_SUCCESS:
      return Object.assign({}, state, {
        nextCreditorNo: payload?.data,
        nextCreditorNoLoading: false,
      });
    case GET_NEXT_CREDITOR_NO_FAIL:
      return Object.assign({}, state, {
        nextCreditorNo: "",
        nextCreditorNoLoading: false,
      });
    case GET_CREDITOR_NO_VALIDATION_STATUS:
      return Object.assign({}, state, {
        isCreditorNoValid: true,
      });

    case GET_CREDITOR_NO_VALIDATION_STATUS_FAIL:
      return Object.assign({}, state, {
        nextCreditorNo: initialState.nextCreditorNo,
        isCreditorNoValid: false,
      });
    case GET_CASE_HANDLERS:
      return Object.assign({}, state, {
        users: [],
      });
    case GET_CASE_HANDLERS_SUCCESS:
      return Object.assign({}, state, {
        users: payload?.data,
      });
    case GET_CASE_HANDLERS_FAIL:
      return Object.assign({}, state, {
        users: [],
      });
    case ADD_NEW_CREDITOR:
      return Object.assign({}, state, {
        newEntityData: {},
        isAddingCreditor: true,
      });
    case ADD_NEW_CREDITOR_SUCCESS:
      return Object.assign({}, state, {
        newEntityData: payload?.data,
        isAddingCreditor: false,
      });
    case ADD_NEW_CREDITOR_FAIL:
      return Object.assign({}, state, {
        newEntityData: {},
        isAddingCreditor: false,
      });
    case UPDATE_NEW_CREDITOR:
      return Object.assign({}, state, {
        isUpdatingCreditor: true,
      });
    case UPDATE_NEW_CREDITOR_SUCCESS:
      return Object.assign({}, state, {
        isUpdatingCreditor: false,
      });
    case UPDATE_NEW_CREDITOR_FAIL:
      return Object.assign({}, state, {
        isUpdatingCreditor: false,
      });
    default:
      return state;
  }
};
