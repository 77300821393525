import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Common from 'us.common';
import { $Dropdown, $Popover } from 'us.common/components';
import { HandlerAction } from 'us.collection/actions';
import { ExclamationCircleFilled } from 'us.icons';
import { IHandler } from 'us.collection/layouts/CaseView/Interfaces/Interfaces';
import { Handlers } from 'us.collection/constants';
import { CourtHandlerList, CaseHandlerList } from './Components';
import { getHandlerId } from '../../Functions';
import { CaseType } from 'us.helper/types/enums';
import { handlerInitial } from '../../Constants';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { RootState } from 'us.helper/types';

const { $Button } = Common.Components;

const { handler, updateHandler } = HandlerAction;

const Handler: React.FC<IHandler> = (props) => {
	const { t } = useTranslation();

	const {
		metaData,
		handlerData,
		handlerLoading,
		getHandlerList,
		changeHandler,
	} = props;

	const [handlerDetails, setHandlerDetails] = useState(handlerInitial);

	const { caseId, entityType } = metaData.data ?? {};
	const { case: caseHandlerDetails, court } = handlerData ?? {};

	/**
	 * Chnage case/court handler
	 * @param type Handler type - case or court
	 */
	const handleUpdateHandler = (type: string) => {
		let requestBody = {};
		if (type === Handlers.CASE) {
			const handlerId = getHandlerId(
				caseHandlerDetails,
				type,
				handlerDetails.caseHandlerName
			);
			requestBody = {
				type: Handlers.CASE,
				entityId: caseId,
				entityType,
				caseHandlerId: handlerId,
				caseHandlerName: handlerDetails.caseHandlerName,
				previousName: caseHandlerDetails?.name,
			};
		} else {
			const handlerId = getHandlerId(
				court,
				type,
				handlerDetails.courtHandlerName
			);
			requestBody = {
				type: Handlers.COURT,
				entityId: caseId,
				entityType,
				courtHandlerId: handlerId,
				courtHandlerName:
					handlerDetails.courtHandlerName,
				previousName: court?.name,
			};
		}
		changeHandler && changeHandler(requestBody);
		setHandlerDetails(handlerInitial);
	};

	/**
	 * Set select handler to state
	 * @param HandlerName Select handler name
	 * @param type Handler type
	 */
	const setSelectHandler = (HandlerName: string, type: string) => {
		switch (type) {
			case Handlers.CASE:
				setHandlerDetails({
					...handlerDetails,
					caseHandlerName: HandlerName,
					isCaseHandler: true,
				});
				break;
			case Handlers.COURT:
				setHandlerDetails({
					...handlerDetails,
					courtHandlerName: HandlerName,
					isCourtHandler: true,
				});
				break;
			default:
				break;
		}
	};

	/**
	 * Get case/court handler list
	 * @param type Handler type
	 */
	const getHandleList = (type: string) => {
		if (
			(!caseHandlerDetails?.list.length &&
				type === Handlers.CASE) ||
			(!court?.list.length && type === Handlers.COURT)
		) {
			getHandlerList && getHandlerList({ type });
		}
		setHandlerDetails(handlerInitial);
	};

	return (
		<>
			<$Popover
				placement='bottomLeft'
				content={
					<div style={{ maxWidth: '300px' }}>
						<div className='ant-popover-message'>
							<ExclamationCircleFilled />
							<div className='ant-popover-message-title'>
								<div
									style={{
										paddingBottom:
											'0.3rem',
									}}
									data-testid={
										'case-handler-conformation'
									}>
									{/* Are you sure, You want to change case handler? */}
									{t(
										'US.COLLECTION.LAYOUT:CASE.CASE_HANDLER_CONFORM'
									)}
								</div>
							</div>
						</div>
						<div className='ant-popover-buttons'>
							<$Button
								size='small'
								onClick={() => {
									setHandlerDetails(
										{
											...handlerDetails,
											caseHandlerName:
												'',
											isCaseHandler:
												false,
										}
									);
								}}
								data-testid={
									'case-handler-conformation-no'
								}>
								{t(
									'US.COLLECTION.COMMON:COMMON.NO'
								)}
							</$Button>
							<$Button
								size='small'
								type='primary'
								onClick={() =>
									handleUpdateHandler(
										Handlers.CASE
									)
								}
								data-testid={
									'case-handler-conformation-yes'
								}>
								{t(
									'US.COLLECTION.COMMON:COMMON.YES'
								)}
							</$Button>
						</div>
					</div>
				}
				trigger='click'
				visible={handlerDetails.isCaseHandler}>
				<$Dropdown
					overlay={
						<CaseHandlerList
							setSelectHandler={
								setSelectHandler
							}
							caseHandlerDetails={
								caseHandlerDetails
							}
							isLoading={
								handlerLoading
							}
						/>
					}
					placement='bottomLeft'
					trigger={['click']}
					arrow
					overlayClassName='dom-handler-dropdown-list'
					disabled={
						metaData.data &&
						isSubCaseTransferredToCase(
							metaData.data
						)
					}>
					<div
						className='dom-handler dom-handler-dropdown mx-2'
						onClick={() =>
							getHandleList(
								Handlers.CASE
							)
						}
						data-testid='show-case-handler-list'>
						<div className='handler-pic case-handler'>
							<img alt='' />
						</div>
						<div className='handler-details'>
							<span
								className='handler-name'
								data-testid='case-handler-name'>
								{
									caseHandlerDetails?.name
								}
							</span>
							<span className='handler-type'>
								{t(
									'US.COLLECTION.LAYOUT:CASE.CASEHANDLER'
								)}
							</span>
						</div>
					</div>
				</$Dropdown>
			</$Popover>
			{entityType === CaseType.C && (
				<$Popover
					placement='bottomLeft'
					content={
						<div
							style={{
								maxWidth: '300px',
							}}>
							<div className='ant-popover-message'>
								<ExclamationCircleFilled />
								<div className='ant-popover-message-title'>
									<div
										style={{
											paddingBottom:
												'0.3rem',
										}}
										data-testid={
											'court-handler-conformation'
										}>
										{/* Are you sure, You want to change court handler? */}
										{t(
											'US.COLLECTION.LAYOUT:CASE.COURT_HANDLER_CONFORM'
										)}
									</div>
								</div>
							</div>
							<div className='ant-popover-buttons'>
								<$Button
									size='small'
									onClick={() => {
										setHandlerDetails(
											{
												...handlerDetails,
												courtHandlerName:
													'',
												isCourtHandler:
													false,
											}
										);
									}}>
									{t(
										'US.COLLECTION.COMMON:COMMON.NO'
									)}
								</$Button>
								<$Button
									size='small'
									type='primary'
									onClick={() =>
										handleUpdateHandler(
											Handlers.COURT
										)
									}
									data-testid={
										'court-handler-conformation-yes'
									}>
									{t(
										'US.COLLECTION.COMMON:COMMON.YES'
									)}
								</$Button>
							</div>
						</div>
					}
					trigger='click'
					visible={handlerDetails.isCourtHandler}>
					<$Dropdown
						overlay={
							<CourtHandlerList
								setSelectHandler={
									setSelectHandler
								}
								courtHandlerDetails={
									court
								}
								isLoading={
									handlerLoading
								}
							/>
						}
						placement='bottomLeft'
						trigger={['click']}
						arrow
						overlayClassName='dom-handler-dropdown-list'>
						<div
							className='dom-handler dom-handler-dropdown mx-2'
							onClick={() =>
								getHandleList(
									Handlers.COURT
								)
							}
							data-testid='show-court-handler-list'>
							<div className='handler-pic court-handler'>
								<img alt='' />
							</div>
							<div className='handler-details'>
								<span
									className='handler-name'
									data-testid='court-handler-name'>
									{
										court?.name
									}
								</span>
								<span className='handler-type'>
									{t(
										'US.COLLECTION.LAYOUT:CASE.COURTHANDLER'
									)}
								</span>
							</div>
						</div>
					</$Dropdown>
				</$Popover>
			)}
		</>
	);
};

const mapStateToProps = (state: RootState) => {
	const { domainView, Handler } = state;
	const { metaData } = domainView;
	const { handler } = Handler;

	return {
		metaData,
		handlerData: handler.data,
		handlerLoading: handler.isLoading,
	};
};

const mapDispatchToProps = {
	getHandlerList: handler.get,
	changeHandler: updateHandler.update,
};

export default connect(mapStateToProps, mapDispatchToProps)(Handler);
