import React from "react";
import { DebtorSelection } from "./Components";
import { useTranslation } from "react-i18next";
import { $Drawer } from "us.common/components";
import { ISelectDebtor } from "./Interfaces";
import PropTypes from "prop-types";

/**
 * @description -  Debtor selection component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2961113115/Select+Debtor+-+Common+UI+Implementation
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 02/05/2022
 */

const $S= <T extends ISelectDebtor>({
  drawerProps,
  name,
  visible = false,
  title,
  width = "1000",
  onSelect,
  onDrawerClose,
  addNewDebtor
}: T) => {
  const { t } = useTranslation();
  return (
        <$Drawer
          {...drawerProps}
          title={
            !title ? t("US.COLLECTION.COMMON:COMMON.SELECT_DEBTOR") : title
          }
          width={width}
          visible={visible}
          onClose={onDrawerClose}
          destroyOnClose={true}
        >
          <DebtorSelection
            name={name}
            onDrawerClose={onDrawerClose}
            onSelect={onSelect}
            addNewDebtor={addNewDebtor}
          />
        </$Drawer>
  );
};

$S.propTypes = {
  drawerProps: PropTypes.any,
  name: PropTypes.string,
  visible: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.string,
  onDrawerClose: PropTypes.func,
};

export const $SelectDebtor = $S;
