import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from "service.config.js";

export const payments = {
    get: async (paymentId: number): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data,status } = await versionedHttpCollection.getById(
            "paymentservice",
            "payments",
            `${paymentId}`,
            ServiceConfig()[`paymentService`]["payments"]
          );
          if(status === 200 || 204){
            resolve(data);
          }else{
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  };