import React from 'react';
import { connect } from 'react-redux';
import { IntlCurrency, IntlDate } from 'us.common/functions';
import Common from 'us.common';
import { useTranslation } from 'react-i18next';

interface IPaymentAgreementInfo {
	onMaximize: () => void;
	currentLanguage: string;
	currentCurrency: string;
}

const PaymentAgreementInfo: React.FC<IPaymentAgreementInfo> = (props) => {
	const { t } = useTranslation();
	const { onMaximize, currentLanguage, currentCurrency } = props;

	return (
		<div>
			<div className='widget widget-debtor-info pr-5'>
				<div
					className='widget-header widget-link-header d-flex align-items-center px-0'
					onClick={() => onMaximize()}>
					<span className='widget-title'>
						{t(
							'US.COLLECTION.TRANSACTIONS:PAYMENTAGREEMENTINFO.PAYMENTAGREEMENT'
						)}
					</span>
				</div>
				<div className='widget-body px-0'>
					<div className='dom-amt-list-table'>
						<div className='list-table-row'>
							<div className='list-table-col'>
								{t(
									'US.COLLECTION.TRANSACTIONS:PAYMENTAGREEMENTINFO.AGREEMENTBALANCE'
								)}{' '}
							</div>
							<div className='list-table-col text-right'>
								{IntlCurrency('5532', currentLanguage, currentCurrency)}
							</div>
						</div>
						<div className='list-table-row '>
							<div className='list-table-col'>
								{t(
									'US.COLLECTION.TRANSACTIONS:PAYMENTAGREEMENTINFO.AGREEMENTDATE'
								)}
							</div>
							<div className='list-table-col text-right'>
								{IntlDate(
									'2018-3-14',
									currentLanguage,
									Common.Constants.dateParams.DATE_LONG
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		currentLanguage: state.common.currentLanguage,
		currentCurrency: state.common.currentCurrency,
	};
};

export default connect(mapStateToProps)(PaymentAgreementInfo);
