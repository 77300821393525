import { IUpdateMappingTransactions } from 'us.collection.transactions/interfaces';

export function UpdateMappingTransactions(
	this: any
): IUpdateMappingTransactions {
	return {
		transactionId: this.arItemNo,
		mappings: this.mappings,
	};
}
