import React, { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { $Button, $Form, $TextArea, $Checkbox } from 'us.common/components';
import { Formik } from 'formik';
import { domainViewAction } from 'us.collection/actions';
import { AuthorityComment } from 'us.collection/repository';
import 'us.collection/layouts/CaseView/Layout.scss';
import { GDPRValidation } from '../../Validation';
import { AuthorizationLevel } from 'us.collection/constants';
import { RootState } from 'us.helper/types';
import { CaseType } from 'us.helper/types/enums';
import { EntityTypes } from 'us.collection.case/constants';

const { gdprComment } = domainViewAction;

const GDPRComment: React.FC<PropsFromRedux> = (props) => {
	const { t } = useTranslation();

	const [isEnable, setIsEnable] = useState<boolean>(false);

	const {
		authorizationProfile,
		gdprCommentIsSaving,
		caseViewData,
		saveGDPRAuthorityComment,
	} = props;

	const { caseType, caseNo, caseId } = caseViewData.data ?? {};

	const {
		accessAuthorityLevel,
		accessAuthorityComment,
		isCommentEnable,
	} = authorizationProfile ?? {};

	/**
	 * Save GDPR comment
	 * @param data Form data
	 */
	const saveAuthorityComment = (data: any) => {
		const comment = AuthorityComment.call({
			data,
			isEnable,
			caseType,
			caseNo,
		});
		const entityType =
			caseType === CaseType.S
				? EntityTypes.SUBCASE
				: EntityTypes.CASE;
		saveGDPRAuthorityComment &&
			saveGDPRAuthorityComment({
				comment,
				caseId,
				entityType,
				authorizationProfile,
			});
	};

	return (
		<Formik
			initialValues={{
				comment:
					accessAuthorityLevel ==
						AuthorizationLevel.OPTIONAL ||
					accessAuthorityLevel ==
						AuthorizationLevel.CAN_DISABLE
						? accessAuthorityComment
						: '',
				isCommentEnable,
			}}
			enableReinitialize
			onSubmit={saveAuthorityComment}
			validationSchema={GDPRValidation}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				setFieldValue,
				...rest
			}: any) => (
				<div>
					<$Form>
						<div className='gdpr-inline-box'>
							<div
								data-testid={
									'comment-box'
								}>
								<h4>
									{t(
										'US.COLLECTION.COMMON:COMMON.ADD_A_COMMENT'
									)}
								</h4>
								<$TextArea
									name='comment'
									autoSize={{
										minRows: 5,
										maxRows: 5,
									}}
									size='small'
									label={t(
										'US.COLLECTION.COMMON:COMMON.COMMENT'
									)}
									required
									data-testid={
										'gdpr-comment'
									}
									disabled={
										gdprCommentIsSaving
									}
								/>
								{accessAuthorityLevel ==
									AuthorizationLevel.CAN_DISABLE && (
									<$Checkbox
										name='isCommentEnable'
										checked={
											isEnable
										}
										onChange={(
											e: any
										) =>
											setIsEnable(
												e
													.target
													.checked
											)
										}
										disabled={
											gdprCommentIsSaving
										}>
										{t(
											"US.COLLECTION.LAYOUT:CASE.DON'T_SHOW_THE_MESSAGE_AGAIN"
										)}
									</$Checkbox>
								)}
							</div>
							<div
								className='gib-footer'
								data-testid={
									'comment-box-ok-button'
								}>
								<$Button
									className='ml-2'
									size='small'
									type='primary'
									htmlType='submit'
									onClick={
										handleSubmit
									}
									disabled={
										gdprCommentIsSaving
									}>
									{t(
										'US.COMMON:COMMON.OK'
									)}
								</$Button>
							</div>
						</div>
					</$Form>
				</div>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { AuthorizationProfile, domainView } = state;
	const { metaData, gdprCommentIsSaving } = domainView;
	const { authorizationProfile } = AuthorizationProfile;

	return {
		metaData,
		authorizationProfile,
		gdprCommentIsSaving,
		caseViewData: domainView.case,
	};
};

const mapDispatchToProps = {
	saveGDPRAuthorityComment: gdprComment.save,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GDPRComment);
