import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Transactions from 'us.collection.transactions';
import { ToggleClass } from 'us.helper';
import { CaseType } from 'us.helper/types/enums';

interface ICnt_Transactions extends RouteComponentProps {
    caseNo: number;
    caseType: CaseType
}

const Cnt_Transactions: React.FC<ICnt_Transactions> = (props) => {

    ToggleClass('change-body-color-dom');

    const onMinimize = (url: string) => {
        props.history.push(url);
    }

    return (
        <Transactions.Components.TransactionsHome onMinimize={() => onMinimize(`/case/${props.caseNo}`)}  {...props} />
    )
}

// const mapStateToProps = (state: any) => {
//     return {
//         caseNo: state.case.caseNo
//     };
// };

// export default connect<{}, {}, any>(mapStateToProps)(withRouter(Cnt_Transactions));
export default withRouter(Cnt_Transactions);