import { PopOverMenuTypes } from "us.collection.transactions/components/Transactions/Constants";
import { IMetaData } from "us.common/components/DynamicPopOver/Interface";
import {
  PopOverDataType,
  DependencyTypes,
  Conditions,
} from "us.common/components/DynamicPopOver/Constants";

export const MenuItems: Array<IMetaData> = [
  { activityCode: PopOverMenuTypes.DELETE },
  {
    activityCode: PopOverMenuTypes.CANCELLATION,
  },
  { activityCode: PopOverMenuTypes.EDITINTEREST },
  { activityCode: PopOverMenuTypes.MAPPING },
  {
    activityCode: PopOverMenuTypes.COURT_FEE,
    transactionId: 4525,
    title: "COURT_FEE_INVOICE",
    isCallback: true,
    parameters: [
      {
        paramName: "INVOICE_NUMBER",
        paramType: PopOverDataType.VARCHAR,
        paramValue: "",
        isEditable: true,
        paramLength: 8,
        dependency: [
          {
            paramName: ["INVOICE_NUMBER"],
            dependencyType: DependencyTypes.DISABLE,
            condition: Conditions.VALUE,
            isInitialValues: true
          },
        ],
      },
      {
        paramName: "REGISTERED_DATE",
        paramType: PopOverDataType.DATE_TIME,
        paramValue: "",
        isEditable: false,
        paramLength: 7,
      },
      {
        paramName: "REMOVE_INVOICE",
        paramType: PopOverDataType.BIT,
        paramValue: false,
        isEditable: true,
        paramLength: 0,
        dependency: [
          {
            paramName: ["INVOICE_NUMBER"],
            dependencyType: DependencyTypes.VISIBLE,
            condition: Conditions.VALUE,
            isInitialValues: true
          }
        ],
      },
      {
        paramName: "REMIT_JOURNAL_IDS",
        paramType: PopOverDataType.VARCHAR,
        paramValue: "",
        isEditable: false,
        paramLength: 7,
        dependency: [
          {
            paramName: ["REMIT_JOURNAL_IDS"],
            dependencyType: DependencyTypes.VISIBLE,
            condition: Conditions.VALUE,
            isInitialValues: true
          },
        ],
      },
      {
        paramName: "COMMENT",
        paramType: PopOverDataType.VARCHAR,
        paramValue: "",
        isEditable: true,
        paramLength: 255,
        dependency: [
          {
            paramName: ["REMOVE_INVOICE"],
            dependencyType: DependencyTypes.VISIBLE,
            condition: Conditions.TRUE,
          },
        ],
      },
    ],
  },
];
