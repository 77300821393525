export enum ManageCreditor {
  GET_POSTAL_AREAS_BY_CREDITOR = "GET_POSTAL_AREAS_BY_CREDITOR",
  GET_POSTAL_AREAS_BY_CREDITOR_SUCCESS = "GET_POSTAL_AREAS_BY_CREDITOR_SUCCESS",
  GET_POSTAL_AREAS_BY_CREDITOR_FAIL = "GET_POSTAL_AREAS_BY_CREDITOR_FAIL",

  GET_BELONGS_TO_CREDITOR = "GET_BELONGS_TO_CREDITOR",
  GET_BELONGS_TO_CREDITOR_SUCCESS = "GET_BELONGS_TO_CREDITOR_SUCCESS",
  GET_BELONGS_TO_CREDITOR_FAIL = "GET_BELONGS_TO_CREDITOR_FAIL",
  RESET_BELONGS_TO_CREDITOR = "RESET_BELONGS_TO_CREDITOR",

  ADD_NEW_CREDITOR = "ADD_NEW_CREDITOR",
  ADD_NEW_CREDITOR_SUCCESS = "ADD_NEW_CREDITOR_SUCCESS",
  ADD_NEW_CREDITOR_FAIL = "ADD_NEW_CREDITOR_FAIL",

  GET_NEXT_CREDITOR_NO = "GET_NEXT_CREDITOR_NO",
  GET_NEXT_CREDITOR_NO_SUCCESS = "GET_NEXT_CREDITOR_NO_SUCCESS",
  GET_NEXT_CREDITOR_NO_FAIL = "GET_NEXT_CREDITOR_NO_FAIL",

  GET_CREDITOR_NO_VALIDATION_STATUS = "GET_CREDITOR_NO_VALIDATION_STATUS",
  GET_CREDITOR_NO_VALIDATION_STATUS_SUCCESS = "GET_CREDITOR_NO_VALIDATION_STATUS_SUCCESS",
  GET_CREDITOR_NO_VALIDATION_STATUS_FAIL = "GET_CREDITOR_NO_VALIDATION_STATUS_FAIL",

  GET_CASE_HANDLERS = "GET_CASE_HANDLERS",
  GET_CASE_HANDLERS_SUCCESS = "GET_CASE_HANDLERS_SUCCESS",
  GET_CASE_HANDLERS_FAIL = "GET_CASE_HANDLERS_FAIL",

  UPDATE_NEW_CREDITOR = "UPDATE_NEW_CREDITOR",
  UPDATE_NEW_CREDITOR_SUCCESS = "UPDATE_NEW_CREDITOR_SUCCESS",
  UPDATE_NEW_CREDITOR_FAIL = "UPDATE_NEW_CREDITOR_FAIL",

}
