import { isArray, isEmpty } from 'lodash';
import { IEntity } from 'us.collection.case/reducers/Dashboard/Interfaces/IDebtorWidgetInfo';
import { DebtorInfoReportStatus } from 'us.collection.debtor/components/DebtorBasicInfo/Constants';
import { appInsights } from 'us.helper';

export const getAddressByDebtorDetails = (
	debtorDetails: IEntity | undefined
) => {
	try {
		const { address1, address2, address3, zipCode, zipName } =
			debtorDetails ?? {};
		return [address1, address2, address3, zipCode, zipName]
			.filter((line: any) => line)
			.join(', ');
	} catch (error) {
		appInsights.trackException(
			`Dashboard Debtor getAddressByDebtorDetails Exeception - ${error}`
		);
		return '';
	}
};

export const getPhoneNumbersByDebtorDetails = (
	debtorDetails: IEntity | undefined
) => {
	try {
		const { phoneNumbers } = debtorDetails ?? {};
		return [
			phoneNumbers?.find((num: any) => num.type === 'home')
				?.number,
			phoneNumbers?.find((num: any) => num.type === 'sms')
				?.number,
		]
			.filter((number: any) => number)
			.join(', ');
	} catch (error) {
		appInsights.trackException(
			`Dashboard Debtor getPhoneNumbersByDebtorDetails Exeception - ${error}`
		);
		return '';
	}
};

/**
 * @description Filter badDebtors by report status.
 * @param {Array} badDebtorsInfo list of badDebtors info.
 * @param {DebtorInfoReportStatus} reportStatus user input status.
 * @returns A filtered array of badDebtors.
 */
export const filterByReportStatus = (
	badDebtorsInfo: Array<any>,
	reportStatus: DebtorInfoReportStatus
): Array<any> => {
	try {
		if (Array.isArray(badDebtorsInfo)) {
			return badDebtorsInfo?.filter(
				({ isReported }: { isReported: boolean }) => {
					switch (reportStatus) {
						case DebtorInfoReportStatus.ALL:
							return true;
						case DebtorInfoReportStatus.REPORTED:
							return isReported;
						case DebtorInfoReportStatus.NOT_REPORTED:
							return !isReported;
					}
				}
			);
		} else {
			return [];
		}
	} catch (e) {
		return [];
	}
};

/**
 * @description - Check whether the latest search is save or not
 * @param {string | number} caseNo
 * @param {string | number} entRoleId
 * @param {any} searchHistory
 */
export const isHistorySaved = (
	caseNo: string | number,
	entRoleId: string | number,
	searchHistory: any
): boolean => {
	try {
		if (!isEmpty(searchHistory) && isArray(searchHistory)) {
			const recentItem = searchHistory[0];
			return (
				recentItem.caseNo == caseNo &&
				recentItem.debtorEntRoleId == entRoleId
			);
		} else {
			return false;
		}
	} catch (error) {
		return false;
	}
};
