import { isArray } from 'lodash';
import { Followup } from 'us.collection.followup/interfaces';

/**
 * @description - Get only non 0 case count followups
 * @param {Followup[]} followups - Pinned followups list
 */
export const getNonEmptyFollowUps = (followups: Followup[]): Followup[] => {
	try {
		if (followups && isArray(followups)) {
			// get non zero case count followups in DESC order
			const items = followups
				?.filter(
					({ caseCount }: Followup) =>
						caseCount > 0
				)
				.sort((a, b) => b.caseCount - a.caseCount);
			// picked only 9 items
			if (items.length > 9) {
				return items.slice(0, 9);
			} else {
				return items;
			}
		} else {
			return [];
		}
	} catch (error) {
		return [];
	}
};
