import React from "react";
import { Formik, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { RootState } from "us.helper/types";
import { connect, ConnectedProps } from "react-redux";
import { IVersions } from "us.collection/components/Workflow/Interfaces";
import { WorkflowVersion } from "us.collection/reducers/Workflow/Interfaces";
import {
  $Skeleton,
  $Button,
  $TableTree,
  ITableTreeColumns,
  $Tooltip,
  $DateLabel,
  $Tag
} from "us.common/components";

/**
 * @description - Select workflow version view.
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 15/05/2023
 */
const WorkflowVersions: React.FC<IVersions & PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { workflowVersions, onCancel } = props;

  const { values: parentValues, setFieldValue } = useFormikContext<any>();
  const { workflowVersion } = parentValues ?? {}

  const columns: ITableTreeColumns = [
    {
      title: t("US.COLLECTION.CASE:WORKFLOWS.VERSION"),
      dataIndex: "versionCode",
      key: "versionCode",
      showFilters: true,
      customFilter: true,
      width: "100px",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customRenderChild: (text: any) => (
        <$Tag>{`${text}`}</$Tag>
      ),
    },
    {
      title: t("US.COLLECTION.COMMON:COMMON.COMMENT"),
      dataIndex: "comment",
      key: "comment",
      width: "250px",
      ellipsis: { showTitle: false },
      showFilters: true,
      customFilter: true,
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customRenderChild: (text: any) => (
        <$Tooltip placement="left" title={text}>
          {text}
        </$Tooltip>
      ),
    },
    {
      title: t("US.COLLECTION.CASE:WORKFLOWS.ACCESS_LEVEL"),
      dataIndex: "accessLevel",
      key: "accessLevel",
      width: "180px",
      ellipsis: { showTitle: false },
      showFilters: true,
      customFilter: true,
      customSorter: (a: any, b: any) => a?.localeCompare(b),
    },
    {
      title: t("US.COLLECTION.CASE:WORKFLOWS.CREATED_USER"),
      dataIndex: "createdUser",
      key: "createdUser",
      ellipsis: { showTitle: false },
      showFilters: true,
      customFilter: true,
      width: "150px",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customRenderChild: (text: any) => (
        <$Tooltip placement="left" title={text}>
          {text}
        </$Tooltip>
      ),
    },
    {
      title: t("US.COLLECTION.CASE:WORKFLOWS.CREATED_DATE"),
      dataIndex: "createdDate",
      key: "createdDate",
      ellipsis: { showTitle: false },
      showFilters: true,
      customFilter: true,
      width: "150px",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customRenderChild: (text: any) => <$DateLabel value={text} />,
    },
  ];

  /**
   * @description -  Handle row selection in table.
   * @param {any} restProps Formik props.
   * @param {any} values Formik values.
   * @returns properties of ant table
   */
  const rowSelection = (restProps: any, values: any) => {
    return {
      onChange: (selectedRowKeys: any[]) => {
        restProps.setFieldValue("selectedVersion", selectedRowKeys);
      },
      selectedRowKeys: values?.selectedVersion,
      preserveSelectedRowKeys: true,
    };
  };

  return (
    <Formik
      initialValues={{
        selectedVersion: workflowVersion
          ? [workflowVersion]
          : [],
      }}
      enableReinitialize
      onSubmit={() => {}}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <$Skeleton
            loading={workflowVersions.isLoading}
            active
            paragraph={{
              rows: 2,
            }}
          >
            <$TableTree
              rowKey={(record: WorkflowVersion) => record.versionNo}
              columns={columns}
              data={workflowVersions.data}
              className="mt-2"
              size="small"
              bordered
              filterOnType
              resetOnSourceChange
              firstColSkipFilterProps={-1}
              onSort={(sortData, dataSource) => {
                return sortData(dataSource);
              }}
              onFilter={(searchData, dataSource) => {
                return searchData(dataSource);
              }}
              rowSelection={{
                type: "radio",
                ...rowSelection(restProps, values),
              }}
              pagination={{
                defaultPageSize: 13,
              }}
              scroll={{ x: "600px" }}
              onRow={({ versionNo }) => {
                return {
                  onDoubleClick: () => {
                    onCancel();
                    setFieldValue("workflowVersion", versionNo);
                  },
                };
              }}
            />
          </$Skeleton>

          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Button
                className="ml-3 mr-2"
                type="primary"
                disabled={!values?.selectedVersion?.length}
                onClick={() => {
                  onCancel();
                  setFieldValue(
                    "workflowVersion",
                    values?.selectedVersion[0]
                  );
                }}
              >
                {t("US.COLLECTION.COMMON:COMMON.SELECT")}
              </$Button>
              <$Button onClick={() => onCancel()}>
                {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
              </$Button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  const { Workflow } = state;
  const { workflowVersions } = Workflow;

  return {
    workflowVersions,
  };
};

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WorkflowVersions);
