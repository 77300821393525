export enum TransactionType {
  AR = "AR",
  SUB_CASE = "SubCase",
  CASE = "Case",
  IN = "IN",
  CF = "CF",
  RF = "RF",
}

export enum ArTransactionAction {
  CANCELATION = "CANCELATION",
  TRANSACTION_MAPPING = "TRANSACTION_MAPPING",
  DELETE = "DELETE",
  CASE_MAPPING = "CASE_MAPPING",
  NONE = "NONE",
}
