import React from "react";
import { useTranslation } from "react-i18next";
import { $Input, $Button, $Divider, $Select } from "us.common/components";
import { PlusOutlined } from "us.icons";
import { IRootState } from "us.collection/interfaces";
import { BMDActions } from "us.collection/actions";
import { connect } from "react-redux";
import { IAddBMDCategory } from "../Interfaces";
import { AddBMDCategory } from "us.collection/repository";
import { $MessageBox } from "us.common/components";


/**
 * @description - The component used in add bmd group drawer
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2910748725/Add+BMD+Group+-+UI+Implementation
 * @param {PropsFromRedux} props
 * @return {JSX.Element}
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 22/03/2022
 */
const BMDCategory: React.FC<IAddBMDCategory> = (props) => {
  const { t } = useTranslation();

  const { categoryList, save, restPropsValues, disabled } = props;

  const { data, isLoading } = categoryList ?? {};

  /**
   * @function
   * @description Add bmd category
   * @param values BMD category form values
   * @returns void
   */
  const addBMDCategory = (values: any) => {
    if (
      data.find((category) => category.categoryName == values.newCategoryName.trim())
    ) {
      $MessageBox(
        "error",
        "US.COLLECTION.CASE:BMD.BMD_CATEGORY_CAN_NOT_BE_DUPLICATE",
        "",
        ""
      );
    } else {
      const requestBody = AddBMDCategory.call(values);
      save && save({ requestBody, values });
    }
  };

  return (
    <$Select
      data-testid="bmd-category-options"
      name="categoryId"
      formitem={{
        label: t("US.COLLECTION.CASE:BMD.MAIN_CATEGORY"),
      }}
      size="small"
      options={data}
      optionValue="id"
      optionText="categoryName"
      allOption={false}
      autoFocus={true}
      tabIndex={1}
      required
      disabled={isLoading || disabled}
      dropdownRender={(menu: any) => (
        <div>
          {menu}
          <$Divider style={{ margin: "4px 0" }} />
          <div
            className="d-flex align-items-center p-1"
            data-testid="add-category-input"
          >
            <div className="flex-fill pl-1">
              <$Input
                name="newCategoryName"
                size="small"
                disabled={isLoading}
              />
            </div>
            <div>
              <$Button
                type="link"
                size="small"
                icon={<PlusOutlined />}
                onClick={() => addBMDCategory(restPropsValues)}
                disabled={isLoading}
              >
                {t("US.COLLECTION.CASE:BMD.ADD")}
              </$Button>
            </div>
          </div>
        </div>
      )}
    />
  );
};

const { bmdCategory } = BMDActions;

const mapStateToProps = (state: IRootState) => {
  const { bmd } = state;
  const { categoryList } = bmd;
  return {
    categoryList,
  };
};

const mapDispatchToProps = {
  save: bmdCategory.save,
};

export default connect(mapStateToProps, mapDispatchToProps)(BMDCategory);
