import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { $Menu, $Tag } from 'us.common/components';
import { INavigationData } from 'us.collection/interfaces';
import { EntityFollowUpPanel } from 'us.collection.case/constants';
import { URLType } from 'us.collection/constants';
import { followUps } from 'us.collection/actions';
import { getColorByPanelName } from './Functions';
import { ICategoryPanel } from './Interfaces';
import { getEntityTypeAndId } from 'us.collection.followup/functions';
import { FollowUpEntityType } from 'us.collection.followup/constants';
import { RootState } from 'us.helper/types';

const { entity } = followUps;

/**
 * @description - Current Active Follow-ups category panel in left side panel
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2912288817/Get+active+follow-up+for+EntityId+and+EntityType+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 09/03/2022
 */
const CategoryPanel: React.FC<
	PropsFromRedux & ICategoryPanel & RouteComponentProps
> = (props) => {
	const { t } = useTranslation();

	const {
		followupPanels,
		drawer,
		list,
		active,
		manageEntityFollowUpDrawer,
		location,
		metaData,
	} = props;
	const state = location.state as INavigationData;
	const { due, upcoming } = list;
	const { data } = active;

	const { entityType } = getEntityTypeAndId(location, metaData);

	/**
	 * @function
	 * @description - number of record
	 * @param {string} panelName - panel name
	 * @returns {number} count - count of record with respect to the panel
	 */
	const getCountByPanelName = (panelName: string): number => {
		switch (panelName) {
			case EntityFollowUpPanel.DUE:
				return due?.length;
			case EntityFollowUpPanel.UPCOMING:
				return upcoming?.length;
			case EntityFollowUpPanel.OTHER:
				return data?.length;
			default:
				return 0;
		}
	};

	/**
	 * @function
	 * @description - manage entity follow-up drawer
	 * @param isNewManualFollowUp - is user going to add new follow-up
	 * @param panel - opened follow-up panel
	 */
	const onClickPanelName = (panel: string) => {
		manageEntityFollowUpDrawer &&
			manageEntityFollowUpDrawer({ ...drawer, panel });
	};

	return (
		<$Menu
			defaultSelectedKeys={
				entityType == FollowUpEntityType.INVOICE ||
				entityType == FollowUpEntityType.CASE
					? [drawer.panel]
					: [EntityFollowUpPanel.OTHER]
			}
			className='followup-type-menu mr-n2 mb-4'>
			{followupPanels.map(
				(panelName: string) =>
					(![
						EntityFollowUpPanel.DUE.toString(),
						EntityFollowUpPanel.UPCOMING.toString(),
					].includes(panelName) ||
						state.currentTab ===
							URLType.CASE ||
						state.currentTab ===
							URLType.SUBCASE) && (
						<$Menu.Item
							key={panelName}
							onClick={() =>
								onClickPanelName(
									panelName
								)
							}
							data-testid={`category-${panelName}`}>
							<span className='ftm-title'>
								{t(
									`US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.${panelName}`
								)}
							</span>
							<$Tag
								className={`ml-2 ml-2 fd-${getColorByPanelName(
									panelName
								)}-tag`}>
								{getCountByPanelName(
									panelName
								)}
							</$Tag>
						</$Menu.Item>
					)
			)}
		</$Menu>
	);
};

const mapStateToProps = (state: RootState) => {
	const { entityFollowUps, manualFollowUps, followUps, domainView } =
		state;
	const { metaData } = domainView;
	const { drawer } = entityFollowUps;
	const { list } = manualFollowUps;
	const { entity } = followUps;
	const { active } = entity;
	return {
		drawer,
		list,
		active,
		metaData: metaData.data,
	};
};

const mapDispatchToProps = {
	manageEntityFollowUpDrawer: entity.manageDrawer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CategoryPanel);
