import React, { useState, useEffect, useCallback } from "react";
import { ConnectedProps, connect } from "react-redux";
import { Formik } from "formik";
import {
  BoxIcons,
  IconTypes,
  IconNames,
  ReloadOutlined,
  CloseCircleOutlined,
} from 'us.icons';
import { useTranslation } from 'react-i18next';
import Common from 'us.common';
import { IRoutineAndActivity } from './Interfaces';
import * as Actions from 'us.collection.routines-and-activities/actions';
import * as DashboardActions from 'us.collection.case/actions';
import { Note } from 'us.collection.routines-and-activities/components/Note';
import {
  getCaseNumberAndType,
  getStyleTermByExecutedStatus,
  getIconByActivityType,
  changeExtension,
  getDrawerWidthByDocumentType,
  getIconForUploadDocument,
  filterHistoryTypesByBMDValue
} from 'us.collection.routines-and-activities/functions';
import moment from 'moment';
import { CaseType } from 'us.helper/types/enums';
import {
  DocumentType,
  IconType,
  dateFormats,
  URLType,
} from "us.collection.routines-and-activities/constants";
import { NoteType, NoteSource, RegisteredUserType, ActivityStatusForPayload, ActivityTypesForPayload } from "us.collection/constants";
import {
  typeList,
  filterOptions,
  DOCUMENT_DRAWER_DEFAULT,
} from 'us.collection.routines-and-activities/components/RoutineAndActivity/Constants';
import { appInsights } from 'us.helper';
import { DocumentViewer } from '../DocumentViewer';
import { AttachmentViewer } from '../AttachmentViewer';
import { DocumentDrawer } from 'us.collection.routines-and-activities/interfaces';
import {
  getUSCVersionName,
  isSubCaseTransferredToCase,
} from 'us.common/functions';
import { activityExecutionActions } from 'us.common/actions';
import * as Azure from 'us.helper/azure';
import { getEntityDetail } from 'us.common/functions';
import { RootState } from 'us.helper/types';
import './RoutingsAndActivitiesDetails.scss';

const {
  $Button,
  $Skeleton,
  $PageHeader,
  $Select,
  $Affix,
  $Row,
  $Col,
  $Form,
  $Popover,
  $Tooltip,
  $Popconfirm,
  $Typography,
  $Drawer,
  $Radio,
  $MessageBox,
  $DateTimeLabel,
  $TableTree,
  $Switch,
  $Tag,
} = Common.Components;

const { Paragraph } = $Typography;

const RoutineAndActivity: React.FC<IRoutineAndActivity & PropsFromRedux> = (
  props
) => {
  const { t } = useTranslation();

  const {
    summaryData,
    note,
    attachement,
    isRefreshRoutineHistory,
    location,
    metaData,
    history,
    authorizationProfile,
    isPendingActivityExecuteSuccess,
    isPendingActivityNotAvailable,
    getLogHistory,
    filter,
    search,
    reset,
    deleteActivity,
    cancelPrintActivity,
    getAccountSummeryWidgetData,
    setActivityData,
    updateParameterDefaultData,
  } = props;

  const { caseId, entityType } = metaData.data ?? {};
  const { enableCreditorPortalNotes } = authorizationProfile ?? {};

  const { push } = history;

  const currentUser: any = (
    window._ENV.REACT_APP_AZURE_AD_SETUP
      ? new Azure.ADAuth()
      : new Azure.B2CAuth()
  ).currentUser();

  const { subEntityType: newEntityType } = getEntityDetail(
    location.pathname,
    metaData.data
  );
  const [drawerInfo, setDrawerInfo] = useState<DocumentDrawer>(
    DOCUMENT_DRAWER_DEFAULT
  );

  const minimize = () => {
    if (metaData.data) {
      const { url, id } = getCaseNumberAndType(location, metaData.data);
      url === URLType.BUREAU
        ? push({ ...location, pathname: `/${url}` })
        : push({
            ...location,
            pathname: `/${url}/${id}`,
          });
    }
    if (entityType === 'S' || entityType === 'C') {
      getAccountSummeryWidgetData &&
        getAccountSummeryWidgetData({
          EntityType: entityType === CaseType.S ? 'Subcase' : 'Case',
          EntityId: caseId,
        });
    }
  };

  useEffect(() => {
    reset && reset({});
    updateLogHistory(caseId, entityType);
  }, [
    isRefreshRoutineHistory,
    isPendingActivityExecuteSuccess,
    isPendingActivityNotAvailable,
    metaData,
  ]);

  useEffect(() => {
    if (Array.isArray(attachement.data) && attachement.data.length > 0) {
      setDrawerInfo({
        ...drawerInfo,
        title: getDocumentName(),
      });
    }
  }, [attachement]);

  /**
   *
   *@param {string} caseNo
   */
  const updateLogHistory = useCallback(
    (caseId: string | number, entityType: string) => {
      if (caseId && entityType) {
        getLogHistory &&
          getLogHistory({
            entityId: getCaseNumberAndType(location, metaData.data).caseNo,
            entityType: getCaseNumberAndType(location, metaData.data).type,
            activityType: ActivityTypesForPayload.ALL,
            activityStatus: ActivityStatusForPayload.ALL,
            registeredUserType: RegisteredUserType.ALL,
          });
      }
    },
    [caseId, entityType]
  );

  const searchValue = (values: any) => {
    search &&
      search({
        name: values.hasOwnProperty('search') ? values?.search : '',
        date: values.hasOwnProperty('searchDate')
          ? values?.searchDate?.format(dateFormats.req)
          : '',
        type: values.hasOwnProperty('type') ? values?.type : '',
        activityCode: values.hasOwnProperty('activityCode')
          ? values?.activityCode
          : '',
        scheduleDate: values.hasOwnProperty('scheduleDate')
          ? values?.scheduleDate?.format(dateFormats.req)
          : '',
        createdBy: values.hasOwnProperty('createdBy') ? values?.createdBy : '',
        isHideWorkflowEvents: values?.isHideWorkflowEvents
      });
  };
  const getDocumentName = (): string => {
    if (drawerInfo.isXmlPdf) {
      return changeExtension(
        attachement.data[0].documentName,
        DocumentType.PDF
      );
    } else {
      return attachement.data[0].documentName;
    }
  };

  const closeDrawer = () => {
    setDrawerInfo(DOCUMENT_DRAWER_DEFAULT);
  };

  const cancelPrint = (data: any) => {
    const { communicationJobId } = data;
    if (metaData.data) {
      cancelPrintActivity &&
        cancelPrintActivity({
          communicationJobId,
          entityId: getCaseNumberAndType(location, metaData.data).caseNo,
          entityType: getCaseNumberAndType(location, metaData.data).type,
          activityType: ActivityTypesForPayload.ALL,
          activityStatus: ActivityStatusForPayload.ALL,
          registeredUserType: RegisteredUserType.ALL,
        });
    }
  };

  const deleteRecord = (data: any) => {
    const { id, executedStatus } = data;
    if (metaData.data) {
      deleteActivity &&
        deleteActivity({
          historyId: id,
          entityType: getCaseNumberAndType(location, metaData.data).type,
          historyType: executedStatus,
        });
    }
  };

  // Run Activity
  const runActivityOpen = (data: any) => {
    const { activityName, isAllowedToEdit } = data;
    if (isAllowedToEdit) {
      window.scrollTo(0, 0);
      setActivityData &&
        setActivityData({
          ...data,
          activity: activityName,
          isEdit: true,
        });
      updateParameterDefaultData &&
        updateParameterDefaultData({
          entityType: newEntityType,
          userName: currentUser?.unique_name,
        });
    } else {
      $MessageBox(
        'error',
        'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.EDIT_NOT_ALLOWED',
        '',
        ''
      );
    }
  };
  const viewNote = (data?: any) => {
    setDrawerInfo({
      documentType: DocumentType.TEXT,
      title: t('US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.EDIT_NOTE'),
      isVisible: true,
      data,
    });
  };
  // View Attachments
  const openAttachment = (data: any) => {
    setDrawerInfo({
      documentType: DocumentType.ATTACHEMENT,
      title: '',
      isVisible: true,
      data,
    });
  };

  // View the document
  const openDocument = (data: any) => {
    const { outputInfo, type, isXmlPdf } = data;
    if (outputInfo?.length > 0 && metaData.data) {
      setDrawerInfo({
        ...DOCUMENT_DRAWER_DEFAULT,
        data,
        isVisible: true,
        documentType: type,
        isXmlPdf,
      });
    }
  };

  const handleDoubleClickRow = (record: any) => {
    if (metaData.data && record) {
      if (
        !isSubCaseTransferredToCase(metaData.data) &&
        record.type === DocumentType.TEXT
      ) {
        viewNote(record);
      } else {
        runActivityOpen(record);
      }
    }
  };

  const renderNewIcons = (data: any) => {
    try {
      const {
        isSuccess,
        executedStatus,
        channel,
        type,
        name,
        activityType,
        isRoutine,
        isUploadDocument,
        noteType,
        source
      
      } = data;
      let styleTerm: string = isSuccess
        ? getStyleTermByExecutedStatus(executedStatus)
        : IconType.ERROR;

      return (
        <>
          {isRoutine && (
            <div className={`row-icon row-icon-${styleTerm}`}>
              <$Tooltip
                placement="top"
                title={t('US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.ROUTINE')}
              >
                <$Button
                  onClick={() => openDocument(data)}
                  type="link"
                  size="small"
                >
                  <BoxIcons
                    type={IconTypes.BOX_ICON}
                    name={DocumentType.ROUTINE}
                  />
                </$Button>
              </$Tooltip>
            </div>
          )}
          {!isRoutine && channel?.length > 0 && (
            <div className={`row-icon row-icon-${styleTerm}`}>
          
                <$Button
                  onClick={() => openDocument(data)}
                  type="link"
                  size="small"
                >
                 <BoxIcons type={IconTypes.BOX_ICON} name="global" />
                </$Button>
   
            </div>
          )}
          {!isRoutine && !(channel?.length > 0) && noteType === NoteType.CP_NOTE && type === DocumentType.TEXT && (
            <div className="row-icon row-icon-cp-note">
              <$Popover
                overlayClassName="history-cp-note-preview"
                placement="right"
                title={(
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="mr-4">{t("US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.CREDITOR_NOTE")}</span>
                    <$Tag className={`tag-sr ${source == NoteSource.US_BOX ? 'tag-sr-sent' : 'tag-sr-received'}`}>
                      <BoxIcons type={IconTypes.BOX_ICON} name={source == NoteSource.US_BOX ? IconNames.SENT : IconNames.RECEIVED} className="mr-1"/>
                      {`${source == NoteSource.US_BOX ? t("US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.SENT") : t("US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.RECEIVED")}`}
                    </$Tag>
                  </div>
                )}
                content={name}
                trigger="hover"
              >
                <$Button type="link" size="small">
                  <BoxIcons type={IconTypes.BOX_ICON} name="creditor-notes" />
                </$Button>
              </$Popover>
            </div>
          )}
          {!isRoutine &&
            !(channel?.length > 0) &&
            noteType !== NoteType.CP_NOTE &&
            type === DocumentType.TEXT &&
            !isUploadDocument && (
              <div className="row-icon row-icon-note">
                <$Popover
                  overlayClassName="history-note-preview"
                  placement="right"
                  title={t('US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.NOTE')}
                  content={name}
                  trigger="hover"
                >
                  <$Button type="link" size="small">
                    <BoxIcons type={IconTypes.BOX_ICON} name="case-note" />
                  </$Button>
                </$Popover>
              </div>
            )}
          {isUploadDocument && noteType !== NoteType.CP_NOTE && (
            <div className={`row-icon row-icon-${styleTerm}`}>
              <$Tooltip placement="top" title={type && type.toUpperCase()}>
                <$Button
                  onClick={() => openDocument(data)}
                  type="link"
                  size="small"
                >
                  <BoxIcons
                    type={IconTypes.BOX_ICON}
                    name={getIconForUploadDocument(data.type)}
                  />
                </$Button>
              </$Tooltip>
            </div>
          )}
          {!isRoutine &&
            !(channel?.length > 0) &&
            type != DocumentType.TEXT &&
            !isUploadDocument && (
              <div className={`row-icon row-icon-${styleTerm}`}>
                {/* {type === DocumentType.XML &&(
                  <$Tooltip
                    placement="top"
                    title={t('US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.PDF')}
                    overlayClassName="dh-act-btn"
                  >
                    <$Button
                      onClick={() =>
                        openDocument({
                          ...data,
                          type: DocumentType.PDF,
                          isXmlPdf: true,
                        })
                      }
                      type="link"
                      size="small"
                      data-testid="dashboard-history-attachmentOpen"
                      className={`row-icon row-icon-${styleTerm}`}
                    >
                      <BoxIcons
                        type={IconTypes.BOX_ICON}
                        name={DocumentType.PDF}
                      />
                    </$Button>
                  </$Tooltip>
                )} */}
                <$Tooltip
                  placement="top"
                  title={
                    activityType?.toLowerCase() === DocumentType.MESSAGE
                      ? type && type.toUpperCase()
                      : ''
                  }
                >
                  <$Button
                    onClick={() => openDocument(data)}
                    type="link"
                    size="small"
                  >
                    <BoxIcons
                      type={IconTypes.BOX_ICON}
                      name={getIconByActivityType(data.activityType, data.type)}
                    />
                  </$Button>
                </$Tooltip>
              </div>
            )}
        </>
      );
    } catch (error) {
      appInsights.trackException(`Render new icon Exception - ${error}`);
      return <></>;
    }
  };

  const columns: any = [
    {
      title: '',
      key: 'delete',
      dataIndex: 'delete',
	  width:150,
      customRenderChild: (text: any, record: any) => {
        const {
          executedStatus,
          type,
          isPrinted,
          isAttachmentAvailable,
          isSuccess,
        } = record;
        return (
          <div className="d-flex justify-content-end row-icon-wrap">
            {/* Cancel Button */}
            {(type === DocumentType.PDF || type === DocumentType.XML) &&
              executedStatus === IconType.EXECUTED &&
              isSuccess && (
                <$Popconfirm
                  title={t(
                    'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.CANCELCONFIRM'
                  )}
                  onConfirm={() => cancelPrint(record)}
                  placement="rightTop"
                >
                  <$Tooltip
                    placement="top"
                    title={t('US.COLLECTION.COMMON:COMMON.CANCEL')}
                  >
                    <$Button
                      type="link"
                      size="small"
                      danger
                      disabled={
                        metaData.data &&
                        isSubCaseTransferredToCase(metaData.data)
                      }
                    >
                      <CloseCircleOutlined />
                    </$Button>
                  </$Tooltip>
                </$Popconfirm>
              )}
            {/* Delete Button */}
            {executedStatus === IconType.PENDING && type !== DocumentType.TEXT && (
              <$Popconfirm
                title={t(
                  'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.DELETECONFIRM'
                )}
                onConfirm={() => deleteRecord(record)}
                placement="rightTop"
                data-testid="routineAndActivity-activityDelete-confirm"
                disabled={
                  metaData.data && isSubCaseTransferredToCase(metaData.data)
                }
              >
                <$Tooltip
                  placement="top"
                  title={t('US.COLLECTION.COMMON:COMMON.DELETE')}
                >
                  <$Button
                    type="link"
                    size="small"
                    danger
                    disabled={
                      metaData.data && isSubCaseTransferredToCase(metaData.data)
                    }
                  >
                    <BoxIcons
                      type={IconTypes.BOX_ICON}
                      name="delete"
                      data-testid="routineAndActivity-activityDelete"
                    />
                  </$Button>
                </$Tooltip>
              </$Popconfirm>
            )}
            {/* Print Button */}
            {isPrinted && (
              <$Tooltip
                placement="top"
                title={t('US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.PRINT')}
              >
                <$Button
                  type="link"
                  size="small"
                  data-testid="routineAndActivity-print"
                  disabled={
                    metaData.data && isSubCaseTransferredToCase(metaData.data)
                  }
                >
                  <BoxIcons type={IconTypes.BOX_ICON} name="print" />
                </$Button>
              </$Tooltip>
            )}
            {/* Attachment Button */}
            {isAttachmentAvailable && (
              <$Tooltip
                placement="top"
                title={t(
                  'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.ATTACHMENTS'
                )}
              >
                <$Button
                  onClick={() => openAttachment(record)}
                  type="link"
                  size="small"
                  data-testid="routineAndActivity-attachmentView"
                >
                  <BoxIcons type={IconTypes.BOX_ICON} name="attachment" />
                </$Button>
              </$Tooltip>
            )}
            {renderNewIcons(record)}
          </div>
        );
      },
    },
    {
      title: t('US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.NAME'),
      dataIndex: 'name',
      key: 'name',
      className: 'text-nowrap',
	  width: "30%",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
      customRenderChild: (text: any, record: any) => {
        const { type, name = "", version, noteType, source } = record;
        return (
          <>
            {type === DocumentType.TEXT && name && (
              <>
                {noteType === NoteType.CP_NOTE && (
                  <>
                    <$Tooltip
                      placement="top"
                      title={`${
                        source == NoteSource.US_BOX
                          ? t(
                              "US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.SENT"
                            )
                          : t(
                              "US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.RECEIVED"
                            )
                      }`}
                    >
                      <span
                        className={`cl-icon-sr ${
                          source == NoteSource.US_BOX
                            ? "cl-icon-sr-sent"
                            : "cl-icon-sr-received"
                        }`}
                      >
                        <BoxIcons
                          type={IconTypes.BOX_ICON}
                          name={
                            source == NoteSource.US_BOX ? IconNames.SENT : IconNames.RECEIVED
                          }
                        />
                      </span>
                    </$Tooltip>
                    <span>
                      {name.substring(0, 50)}
                      {name.length > 50 ? "..." : ""}
                    </span>
                  </>
                )}
                {noteType !== NoteType.CP_NOTE && (
                  <span>
                    {name.substring(0, 50)}
                    {name.length > 50 ? "..." : ""}
                  </span>
                )}
              </>
            )}
            {type !== DocumentType.TEXT && name && (
              <div className="d-flex align-items-center">
				<$Tooltip
					placement="topLeft"
					title={name}
					className="text-truncate"
				>
					{name}
				</$Tooltip>
                {version && (
                  <$Tag
                    className={`ml-2 tag-version-${version}`}
                  >{`${getUSCVersionName(version)}`}</$Tag>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      title: t('US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.RUN_DATE'),
      dataIndex: 'date',
      key: 'date',
      className: 'text-nowrap',
	  width: 150,
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: 'date',
      customRenderChild: (text: any, record: any) => {
        const { date } = record;
        return (
          <span>
            {moment.utc(date).isValid() &&
              moment.utc(date).isAfter(moment.utc(dateFormats.minDate)) && (
                <$DateTimeLabel value={date} />
              )}
          </span>
        );
      },
    },
    {
      title: t('US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.SCHEDULEDATE'),
      dataIndex: 'scheduledTime',
      key: 'scheduledTime',
      className: 'text-nowrap',
	  width: 150,
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: 'date',
      customRenderChild: (text: any, record: any) => {
        const { scheduledTime } = record;
        return (
          <span>
            {moment.utc(scheduledTime).isValid() &&
              moment
                .utc(scheduledTime)
                .isAfter(moment.utc(dateFormats.minDate)) && (
                <$DateTimeLabel value={scheduledTime} />
              )}
          </span>
        );
      },
    },
    {
      title: t('US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.CREATEDBY'),
      dataIndex: 'user',
      key: 'user',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
      customRenderChild: (text: any, record: any) => {
        const { user } = record;
		return (
			<$Tooltip title={user} placement="topLeft">
			  <span>{user?.split("@")[0]}</span>
			</$Tooltip>
		  );
      },
    },
    {
      title: t('US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.TEXT'),
      dataIndex: 'text',
      key: 'text',
      className: 'text-nowrap',
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
      customRenderChild: (text: any, record: any) => {
        const { isSuccess } = record;
        return (
          <>
            {text?.trim().length > 0 && (
              <Paragraph
                className="mb-0 d-flex"
                copyable={{
                  text: text,
                  tooltips: [
                    t('US.COLLECTION.COMMON:COMMON.COPY_TO_CLIPBOARD'),
                    t('US.COLLECTION.COMMON:COMMON.COPIED'),
                  ],
                }}
              >
                <>
                  {!isSuccess && (
                    <span className="row-error-text text-truncate">
                      {text}
                    </span>
                  )}
                  {isSuccess && (
                    <span className="row-activity-text text-truncate">
                      {text}
                    </span>
                  )}
                </>
              </Paragraph>
            )}
          </>
        );
      },
    },
  ];

  const refreshButtonHandler = () => {
    reset && reset({});
    if (isRefreshRoutineHistory && metaData.data) {
      getLogHistory &&
        getLogHistory({
          entityId: getCaseNumberAndType(location, metaData.data).caseNo,
          entityType: getCaseNumberAndType(location, metaData.data).type,
          activityType: ActivityTypesForPayload.ALL,
          activityStatus: ActivityStatusForPayload.ALL,
          registeredUserType: RegisteredUserType.ALL,
        });
    }
  };

  return (
    <Formik initialValues={{}} onSubmit={() => {}}>
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        setFieldValue,
        resetForm,
        ...rest
      }: any) => (
        <div className="space-content">
          <$Form>
            <div className="case-history  pb-5">
              <$Affix offsetTop={80}>
                <div className="page-header header-border pr-0">
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <$PageHeader
                        onBack={() => minimize()}
                        className="px-0"
                        title={t(
                          'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.HISTORY'
                        )}
                        data-testid="routineAndActivity-back"
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="mr-4 d-flex flex-1 align-items-top">
                        <div className="mr-2">
                          <$Switch
                            size="small"
                            name="isHideWorkflowEvents"
                            checked={values?.isHideWorkflowEvents}
                            onChange={(isHideWorkflowEvents: boolean) => {
                              setFieldValue('type', DocumentType.ALL);
                              searchValue({
                                ...values,
                                type: DocumentType.ALL,
                                isHideWorkflowEvents,
                              });
                            }}
                            style={{
                              marginTop: '-4px',
                            }}
                          />
                        </div>
                        <div>
                          {t(
                            'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.HIDE_WORKFLOW_EVENTS'
                          )}
                        </div>
                      </div>
                      <$Radio
                        defaultValue={'all'}
                        name="filter"
                        optionText="label"
                        optionValue="value"
                        onChange={(e: any) => {
                          filter &&
                            filter({
                              filterValue: e.target.value,
                            });
                        }}
                        options={filterHistoryTypesByBMDValue(
                          filterOptions.map((option: any) => ({
                            ...option,
                            label: t(option.label),
                          })),
                          enableCreditorPortalNotes
                        )}
                        data-testid="routineAndActivity-radioFilter"
                        className="ch-filter-radio mr-4"
                      />

                      <$Select
                        name="type"
                        style={{
                          width: '120px',
                        }}
                        optionText="label"
                        optionValue="value"
                        defaultValue={DocumentType.ALL}
                        allOption={true}
                        onChange={(e: any) => {
                          searchValue({
                            ...values,
                            type: e,
                          });
                        }}
                        options={typeList.map((type: any) => {
                          return {
                            ...type,
                            label: t(type.label),
                          };
                        })}
                        onSearchBy={['label']}
                        placeholder={t(
                          'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.DOC_TYPE'
                        )}
                        data-testid="routineAndActivity-type"
                      />

                      <$Tooltip
                        title={t('US.COLLECTION.COMMON:COMMON.REFRESH')}
                      >
                        <$Button
                          type="dashed"
                          size="small"
                          onClick={() => refreshButtonHandler()}
                          icon={<ReloadOutlined />}
                          className="ml-3"
                        />
                      </$Tooltip>
                    </div>
                  </div>
                </div>
              </$Affix>

              <$Row gutter={16} className="mt-3">
                <$Col
                  xxl={{
                    span: 24,
                    offset: 0,
                  }}
                  xl={{
                    span: 24,
                    offset: 0,
                  }}
                >
                  <div className="history-table">
                    <$Skeleton
                      loading={note?.isLoading}
                      active
                      paragraph={{
                        rows: 2,
                      }}
                    >
                      <$TableTree
                        rowKey="id"
                        data={summaryData?.data}
						            rowClassName={({ notified, type, source, noteType }) =>
                          !notified &&
                          type === DocumentType.TEXT &&
                          (noteType === NoteType.CP_NOTE ? source != NoteSource.US_BOX : true)
                            ? "unread-note"
                            : ""
                        }
                        size="small"
                        className=""
                        onSort={(sortData, dataSource) => sortData(dataSource)}
                        onFilter={(searchData, dataSource) =>
                          searchData(dataSource)
                        }
                        filterOnType={true}
                        resetOnSourceChange={true}                      
                        bordered
                        scroll={{ y: "calc(100vh - 280px)", x: "1200px" }}
                        columns={columns}
                        onRow={(record: any) => {
                          return {
                            onDoubleClick: () => handleDoubleClickRow(record),
                          };
                        }}
                        virtual={true}                 
                      />
                    </$Skeleton>
                  </div>
                </$Col>
              </$Row>
              <$Drawer
                title={
                  drawerInfo.documentType == DocumentType.TEXT
                    ? t(drawerInfo.title)
                    : drawerInfo.title
                }
                width={getDrawerWidthByDocumentType(drawerInfo.documentType)}
                visible={drawerInfo.isVisible}
                onClose={closeDrawer}
                destroyOnClose
                placement="right"
                className={
                  drawerInfo.documentType === DocumentType.ATTACHEMENT
                    ? 'history-attach-viwer'
                    : 'history-doc-viwer'
                }
              >
                {![DocumentType.TEXT, DocumentType.ATTACHEMENT, DocumentType.UNKNOWN].includes(
                  drawerInfo.documentType
                ) && (
                  <DocumentViewer
                    isXmlPdf={drawerInfo.isXmlPdf}
                    documentType={drawerInfo.documentType}
                    data={drawerInfo.data}
                    onClose={closeDrawer}
                  />
                )}
                {drawerInfo.documentType === DocumentType.TEXT && (
                  <Note {...props} close={closeDrawer} data={drawerInfo.data} />
                )}
                {drawerInfo.documentType === DocumentType.ATTACHEMENT && (
                  <AttachmentViewer
                    communicationJobId={drawerInfo.data?.communicationJobId}
                    onClose={closeDrawer}
                  />
                )}
              </$Drawer>
            </div>
          </$Form>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    common,
    dashboard,
    logHistory,
    activity,
    activityExecution,
    domainView,
    AuthorizationProfile
  } = state;
  const { currentLanguage, currentDateFormat } = common;
  const { authorizationProfile } = AuthorizationProfile;
  const { metaData } = domainView;
  const { accountSummeryWidgetData } = dashboard;
  const {
    summaryData,
    note,
    attachement,
    parameters,
    noteDrawer,
    runActivityDrawer,
  } = logHistory;
  const { isRefreshRoutineHistory } = activityExecution;
  const { isPendingActivityExecuteSuccess, isPendingActivityNotAvailable } =
    activity;

  return {
    currentLanguage,
    currentDateFormat,
    summaryData,
    note,
    attachement,
    accountSummeryWidgetData,
    parameters,
    noteDrawer,
    isRefreshRoutineHistory,
    runActivityDrawer,
    metaData,
    isPendingActivityExecuteSuccess,
    isPendingActivityNotAvailable,
    authorizationProfile
  };
};

const { historyAction } = Actions;
const { AccountSummeryWidgetActions } = DashboardActions;
const { accountSummery } = AccountSummeryWidgetActions;
const { logHistory, attachment, note } = historyAction;
const { parameters, activity } = activityExecutionActions;

const mapDispatchToProps = {
  getLogHistory: logHistory.get,
  filter: logHistory.setFilter,
  search: logHistory.setSearch,
  reset: logHistory.reset,
  getAttachment: attachment.get,
  deleteActivity: historyAction.delete.delete,
  openDrawer: note.openDrawer,
  getAccountSummeryWidgetData: accountSummery.get,
  cancelPrintActivity: historyAction.print.cancel,
  setActivityData: activity.set,
  updateParameterDefaultData: parameters.updateDefaultData,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RoutineAndActivity);
