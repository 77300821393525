import React from "react";
import { useTranslation } from "react-i18next";
import { $Popconfirm } from "us.common/components";
import { DeleteOutlined, EditOutlined } from "us.icons";
import { TABLE_ACTIONS } from "../../Constants";
import { ITableAction } from "./Interfaces";


/**
 * @description - The component used in Table actions of BMD List
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2855272453/UI+BMD+-+List+Summery+View
 * @param {ITableAction} props
 * @return {JSX.Element}
 * @author Darshana Waasala <waasalajb@unicorn-solutions.com>
 * @since 16/03/2022
 */
  export const TableAction: React.FC<ITableAction> = (props) => {
  const { t } = useTranslation(["US.COLLECTION.CASE", "US.COLLECTION.COMMON"]);
  const { actions, clickEvent } = props;

  return (
    <div className="table-more-content">
      {actions.findIndex((action)=>action.action === TABLE_ACTIONS.EDIT) >=0 && (
        <div className="d-flex  flex-row more-item">
          <div className="p-1">
            <EditOutlined />
          </div>
          <div className="p-1" onClick={() => clickEvent(TABLE_ACTIONS.EDIT)}>
            {t("US.COLLECTION.COMMON:COMMON.EDIT")}
          </div>
        </div>
      )}
      {actions.findIndex((action)=>action.action === TABLE_ACTIONS.DELETE) >=0 && (
        <$Popconfirm
          title={t("US.COLLECTION.COMMON:COMMON.SURE_TO_DELETE_?")}
          data-testid="popUpConfirm-box"
          disabled={actions.find((action)=>action.action === TABLE_ACTIONS.DELETE)?.isPopupDisabled}
        >
          <div className="d-flex mb-2 flex-row more-item text-error">
            <div className="p-1">
              <DeleteOutlined />
            </div>
            <div className="p-1" onClick={() => clickEvent(TABLE_ACTIONS.DELETE)}>
              {t("US.COLLECTION.COMMON:COMMON.DELETE")}
            </div>
          </div>
        </$Popconfirm>
      )}
    </div>
  );
};
