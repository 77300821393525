import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from "service.config.js";

export default {
  reasonList: {
    get: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "arservice",
            `ars/cases/closereasons`,
            {},
            ServiceConfig()[`arservice`]["cases.closereasons"]
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  closeCase: {
    save: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.patch(
            "arservice",
            `ars/${params.arId}/cases/${params.caseId}`,
            params.requestObject,
            ServiceConfig()[`arservice`]["cases.updatecase"]
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
