import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from "service.config.js";
import { NoteNotifiedStatus } from "us.collection.routines-and-activities/constants";

export default {
  logs: {
    get: <
      P extends {
        entityId: number;
        entityType: string;
        activityType: string;
        activityStatus: string;
        registeredUserType: string;
      }
    >(
      params: P
    ): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.post(
            "systemservice",
            `entityhistorydetails`,
            { ...params },
            ServiceConfig()[`systemservice`]["entityhistorydetails"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  attachment: {
    get: <
      P extends {
        lookupId: number;
        system: string;
        lookupType: string;
      }
    >(
      params: P
    ): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.get(
            "documentservice",
            `document`,
            {
              system: params.system,
              lookupId: params.lookupId,
              lookupType: params.lookupType,
            },
            ServiceConfig()[`documentservice`]["document"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  parameters: {
    get: <
      P extends {
        activityId: string;
        state: string;
      }
    >(
      params: P
    ): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.get(
            "activityservice",
            `activities/${params.activityId}/${params.state}/settings`,
            {},
            ServiceConfig()[`activityservice`]["activities.settings"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  activity: {
    execute: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.post(
            "activityservice",
            "activities",
            params,
            ServiceConfig()[`activityservice`]["activities"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    delete: <
      P extends {
        historyId: number;
        entityType: string;
        historyType: string;
      }
    >(
      params: P
    ): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.patch(
            "activityservice",
            "activityhistory",
            params,
            ServiceConfig()[`activityservice`]["activityhistory"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    printCancel: <
      P extends {
        communicationJobId: number;
      }
    >(
      params: P
    ): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.patch(
            "documentservice",
            "printcancelstatus",
            {
              communicationqueId: params.communicationJobId,
            },
            ServiceConfig()[`documentservice`]["printcancelstatus"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  activities: {
    get: <
      P extends {
        entitytype: string;
        entitystate: string;
        canExecute: boolean;
      }
    >(
      params: P
    ): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.get(
            "activityservice",
            `activities`,
            params,
            ServiceConfig()[`activityservice`]["activities"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  note: {
    update: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.post(
            "noteservice",
            "note",
            params,
            ServiceConfig()[`noteservice`]["note"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    delete: <
      P extends {
        noteId: number;
        entityType: string;
      }
    >(
      params: P
    ): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.patch(
            "noteservice",
            "note",
            params,
            ServiceConfig()[`noteservice`]["noteservice.note"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    status: <
      P extends {
        noteId: number;
      }
    >({
      noteId,
    }: P): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.patch(
            "noteservice",
            "notestatus",
            {
              noteId,
              notifiedstatus: NoteNotifiedStatus.READ,
            },
            ServiceConfig()[`noteservice`]["noteservice.note"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
