import { DocumentType } from 'us.collection.routines-and-activities/constants';
import { DocumentDrawer } from 'us.collection.routines-and-activities/interfaces';

export const DOCUMENT_DRAWER_DEFAULT: DocumentDrawer = {
	documentType: DocumentType.UNKNOWN,
	isVisible: false,
	title: '',
	data: {},
	isXmlPdf: false,
};

export enum LogHistoryOptions {
	ALL = "all",
	CASE_LOGS = "caseLogs",
	CP_NOTES = "cpnote",
	ACTIVITIES = "messages"
}