import { IDynamicPopOverPayload } from 'us.collection.transactions/components/Transactions/Interfaces/Interfaces';
import { popOverPayloadParameters } from 'us.collection.transactions/components/Transactions/Functions';

export function DynamicPopOverPayload(this: any): IDynamicPopOverPayload {
	return {
		transactionId: Number(this.arItemNo),
		activityCode: this.activityCode,
		parameters: popOverPayloadParameters(this.parameters),
	};
}
