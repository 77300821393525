import React from 'react';
import { useTranslation } from 'react-i18next';
import Common from 'us.common';
const { $Empty } = Common.Components;

export const GetContent = (params: any) => {
	const { t } = useTranslation();
	if (!params.state.error) {
		return params.children;
	} else {
		return (
			<div className='space-content'>
				<$Empty
					className='mb-2'
					description={t(
						'US.COMMON:COMMON.OOOPS_SOMETHING_IS_NOT_RIGHT'
					)}
					image={$Empty.PRESENTED_IMAGE_SIMPLE}
				/>
			</div>
		);
	}
};
