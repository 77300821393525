import * as Yup from "yup";
import moment from "moment";
import { SalaryDeductionSchedules } from "us.collection.case/constants";
import { ISalaryDeductionSchema } from "us.collection.case/interfaces";

interface SalaryDeductionPlan extends Yup.MixedSchema {
  caseId: string;
  enforcementId: number;
  fromDate: moment.Moment;
  deductionSchedule: SalaryDeductionSchedules;
  deductionAmount: number;
  faceValue: number;
  isGiroTemplate: boolean;
  saveToDB: boolean;
  salaryDeductionSchema: Array<ISalaryDeductionSchema>;
}

const SalaryDeductionPlanSchema = Yup.object<SalaryDeductionPlan>().shape({
  fromDate: Yup.date()
    .required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
    )
    .typeError(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
    ),
  faceValue: Yup.number().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
  ),
  deductionAmount: Yup.number()
    .required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
    )
    .test(
      "deductionAmountZeroCheck",
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD",
      (deductionAmount: any) => Number(deductionAmount) > 0
    )
    .when("faceValue", (faceValue: any, Yup: any) =>
      Yup.test(
        "deductionAmount",
        "US.COLLECTION.VALIDATIONS:INVALID.DEDUCTION_AMOUNT_SHALL_BE_LESSER_THAN_FACE_VALUE",
        (deductionAmount: number) => deductionAmount <= faceValue
      )
    ),
  deductionSchedule: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
  ),
  salaryDeductionSchema: Yup.array().of(
    Yup.object().shape({
      dueDate: Yup.date()
        .required(
          "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
        )
        .typeError(
          "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
        ),
      balance: Yup.string(),
      termAmount: Yup.string()
        .required(
          "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
        )
        .when(
          ["balance", "outstandingAmount"],
          (
            balance: any,
            outstandingAmount: any,
            Yup: any
          ) => {
            return Yup.test(
              "termAmount",
              "US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD",
              (termAmount: any) =>
                Number(termAmount) <=
                Number(balance) + Number(outstandingAmount)
            );
          }
        ),
    })
  ),
});

export default SalaryDeductionPlanSchema;
