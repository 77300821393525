import React from "react";
import _ from "lodash";
import { getRouteUrl } from "us.helper";
import {
  $Menu,
  $Badge,
  $DateTimeLabel,
  $Dropdown,
  $Button,
  $Typography,
} from "us.common/components";
import { BoxIcons, IconTypes, DownOutlined, CloseOutlined } from "us.icons";
import { handleEndPointLocals } from "us.common/functions";
import * as actions from "us.common/actions";
import { connect, ConnectedProps } from "react-redux";
import { INotificationItem } from "us.common/components/NotificationMenu/Interface";
import { ActionTypes } from "us.common/constants/Component/Notification";

/**
 * @description -  Notification item.
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 23/05/2022
 * */
const NotificationItem: React.FC<INotificationItem & PropsFromRedux> = (
  props
) => {
  const {
    retryNotification,
    onRemoveNotification,
    data,
    notificationUpdateId,
    notificationRetryId,
  } = props;
  const { Paragraph } = $Typography;
  const [ellipsis] = React.useState(true);
  const {
    notificationId,
    title,
    titleData,
    message,
    notifiedDate,
    path,
    isSeen,
    isInProgress,
  } = data ?? {};

  /**
   * @function
   * @description - Retry action button status.
   */
  const isRetrySaving = (actionType: any) =>
    (actionType == ActionTypes.EXTERNAL_GET ||
      actionType == ActionTypes.EXTERNAL_POST) &&
    notificationRetryId == notificationId;

  /**
   * @function
   * @description - Action dropdown list if there are more than 2 actions.
   */
  const otherActions = () => {
    return (
      <$Menu>
        {path?.map(
          (
            { actionName, actionType, route, module, extendedData }: any,
            index: number
          ) =>
            index > 1 && (
              <$Menu.Item key={index}>
                <a
                  onClick={() =>
                    onClickAction(
                      notificationId,
                      actionType,
                      extendedData,
                      route,
                      module
                    )
                  }
                >
                  {actionName}
                </a>
              </$Menu.Item>
            )
        )}
      </$Menu>
    );
  };

  /**
   * @function
   * @description - Notification action click handler.
   */
  const onClickAction = (
    notificationId: number,
    actionType: ActionTypes,
    extendedData: any,
    route: any,
    module: any
  ) => {
    switch (actionType) {
      case ActionTypes.INTERNAL:
        window.open(route, "_self");
        break;
      case ActionTypes.INTERNAL_NEW:
        getRouteUrl.moduleRoute(module, route);
        break;
      case ActionTypes.EXTERNAL_GET:
        retryNotification &&
          retryNotification({
            actionType: ActionTypes.EXTERNAL_GET,
            notificationId,
            extendedData,
            url: route,
          });
        break;
      case ActionTypes.EXTERNAL_POST:
        retryNotification &&
          retryNotification({
            actionType: ActionTypes.EXTERNAL_POST,
            notificationId,
            extendedData,
            url: route,
          });
        break;
      default:
        getRouteUrl.moduleRoute(module, route);
    }
  };

  return (
    <div className="notification-item">
      <div className=" py-2 d-flex align-items-center justify-content-between">
        <div className="d-flex flex-fill">
          <div
            className={`noti-icon inv-printed ${
              isInProgress ? "pulsating-circle" : ""
            }`}
          >
            <$Badge dot={!isSeen}>
              <BoxIcons type={IconTypes.BOX_ICON} name="re-creat-remit" />
            </$Badge>
          </div>
          <div className="noti-dec w-100">
            <div className="d-flex flex-fill justify-content-between">
              <div>
                <div className="font-weight-bold text-truncate pb-1">
                  {title && handleEndPointLocals(`US.COMMON:COMMON.${title}`)}
                  <span className="text-muted">
                    {titleData && ` : ${titleData}`}
                  </span>
                </div>
                <div className="ant-form-item-extra">
                <Paragraph
                    ellipsis={
                      ellipsis
                        ? { rows: 2, tooltip: "I am ellipsis now!" }
                        : true
                    }
                  >
                    {message}
                  </Paragraph>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center w-100">
              <div>
                <span className="ant-form-item-extra">
                  <$DateTimeLabel value={notifiedDate} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="noti-overly">
        <$Button
          icon={<CloseOutlined />}
          shape="circle"
          type="ghost"
          size="small"
          className="noti-close"
          loading={notificationUpdateId == notificationId}
          onClick={() => onRemoveNotification(notificationId)}
        />

        <div className="noti-action w-100">
          {path?.length !== 0 && (
            <div className="w-100 d-flex justify-content-center align-items-end">
              {path?.map(
                (
                  { actionName, actionType, route, module, extendedData }: any,
                  index: number
                ) =>
                  index < 2 && (
                    <$Button
                      key={index}
                      type="ghost"
                      className="mx-1"
                      shape="round"
                      size={"small"}
                      disabled={isRetrySaving(actionType)}
                      loading={isRetrySaving(actionType)}
                      onClick={() =>
                        onClickAction(
                          notificationId,
                          actionType,
                          extendedData,
                          route,
                          module
                        )
                      }
                    >
                      {" "}
                      {actionName}
                    </$Button>
                  )
              )}
              {path?.length >= 3 && (
                <$Dropdown overlay={otherActions()} trigger={["hover"]}>
                  <$Button
                    type="ghost"
                    className="mx-1"
                    shape="round"
                    size={"small"}
                  >
                    <DownOutlined />
                  </$Button>
                </$Dropdown>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { notification } = state;
  const { notificationUpdateId, retry } = notification;
  return {
    notificationUpdateId: notificationUpdateId,
    notificationRetryId: retry.notificationId,
  };
};

const { notification } = actions;

const mapDispatchToProps = {
  retryNotification: notification.action.retry,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NotificationItem);
