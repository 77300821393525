import { FollowUps } from "us.collection/constants/FollowUps";
import { IFollowUpsAction } from "./Interfaces";

export const followUps: Readonly<IFollowUpsAction> = {
  /**
   * A namespace
   * @namespace entity
   */
   entity: {
    /**
     * Manage Drawer action for followUp Entity action
     */
     manageDrawer: (data) => ({
      type: FollowUps.MANAGE_ENTITY_FOLLOW_UP_DRAWER,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
};
