export enum MessageTemplate {
  GET_MESSAGE_TEMPLATES_START = "GET_MESSAGE_TEMPLATES_START",
  GET_MESSAGE_TEMPLATES_SUCCESS = "GET_MESSAGE_TEMPLATES_SUCCESS",
  GET_MESSAGE_TEMPLATES_FAIL = "GET_MESSAGE_TEMPLATES_FAIL",

  DELETE_MESSAGE_TEMPLATE_START = "DELETE_MESSAGE_TEMPLATE_START",
  DELETE_MESSAGE_TEMPLATE_SUCCESS = "DELETE_MESSAGE_TEMPLATE_SUCCESS",
  DELETE_MESSAGE_TEMPLATE_FAIL = "DELETE_MESSAGE_TEMPLATE_FAIL",

  MANAGE_PREVIEW_EDIT_DRAWER = "MANAGE_PREVIEW_EDIT_DRAWER",

  MANAGE_SEND_EMAIL_DRAWER = "MANAGE_SEND_EMAIL_DRAWER",

  GET_MESSAGE_TEMPLATE_BY_ID_START = "GET_MESSAGE_TEMPLATE_BY_ID_START",
  GET_MESSAGE_TEMPLATE_BY_ID_SUCCESS = "GET_MESSAGE_TEMPLATE_BY_ID_SUCCESS",
  GET_MESSAGE_TEMPLATE_BY_ID_FAIL = "GET_MESSAGE_TEMPLATE_BY_ID_FAIL",

  EXECUTE_MESSAGE_TEMPLATE_START = "EXECUTE_MESSAGE_TEMPLATE_START",
  EXECUTE_MESSAGE_TEMPLATE_SUCCESS = "EXECUTE_MESSAGE_TEMPLATE_SUCCESS",
  EXECUTE_MESSAGE_TEMPLATE_FAIL = "EXECUTE_MESSAGE_TEMPLATE_FAIL",

  SEND_EMAIL_START = " SEND_EMAIL_START",
  SEND_EMAIL_SUCCESS = " SEND_EMAIL_SUCCESS",
  SEND_EMAIL_FAIL = " SEND_EMAIL_FAIL",

  UPDATE_IFRAME_CONTENT = "UPDATE_IFRAME_CONTENT",
  PIN_UNPIN_MESSAGE_TEMPLATE = "PIN_UNPIN_MESSAGE_TEMPLATE",
  PIN_UNPIN_MESSAGE_TEMPLATE_SUCCESS = "PIN_UNPIN_MESSAGE_TEMPLATE_SUCCESS",
  PIN_UNPIN_MESSAGE_TEMPLATE_FAIL = "PIN_UNPIN_MESSAGE_TEMPLATE_FAIL",
}

export enum DocumentType {
  EMAIL = "email",
  PDF = "pdf",
  SMS = "sms",
  ALL = "all",
}

export enum DrawerType {
  PREVIEW = "preview",
  EDIT = "edit",
}

export enum ReceiverType {
  DEBTOR = "Debtor",
  CREDITOR = "Creditor",
}

export const FilterptionsForTemplate: Array<{ key: string; value: string }> = [
  {
    key: "ALL",
    value: DocumentType.ALL,
  },
  {
    key: "PDF",
    value: DocumentType.PDF,
  },
  {
    key: "EMAIL",
    value: DocumentType.EMAIL,
  },
  {
    key: "SMS",
    value: DocumentType.SMS,
  },
];
