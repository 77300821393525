import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import './Interest.scss';
import { IBase } from 'us.collection.case/components/Interfaces/Interest';
import {
	subcasesInterestObjectMaker,
	casesInterestObjectMaker,
} from 'us.collection.case/functions';
import { IOnFilter, IOnSort } from 'us.common/components/antd/TableTree';
import * as Actions from 'us.collection.case/actions';
import { IntlCurrency } from 'us.common/functions';
import Common from 'us.common';

const {
	$Skeleton,
	$PageHeader,
	$Select,
	$Affix,
	$Row,
	$Col,
	$Form,
	$Tag,
	$TableTree,
	$Tooltip,
	$DateLabel,
	$AmountLabel,
} = Common.Components;
import { CaseType } from 'us.helper/types/enums';
import { RootState } from 'us.helper/types';

const Interest: React.FC<IBase> = (props) => {
	const { t } = useTranslation(['US.COLLECTION.CASE'], {
		useSuspense: true,
	});
	const {
		get,
		currentLanguage,
		currentCurrency,
		metaData,
		interests,
		filter,
		setFilter,
		onMinimize,
	} = props;
	const { caseId, debtorId, entityType } = metaData.data ?? {};

	useEffect(() => {
		if (debtorId && caseId && filter) {
			get({
				caseId,
				filter,
			});
		}
	}, [metaData, filter]);

	const onChangeFilter = (type: string) => {
		setFilter({ type });
	};

	const subCaseColumns: any = [
		{
			title: t('US.COLLECTION.CASE:INTEREST.STARTDATE'),
			dataIndex: 'startDate',
			key: 'startDate',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (text: any, record: any) => {
				return (
					<span>
						{
							<$DateLabel
								value={
									record?.startDate
								}
							/>
						}
					</span>
				);
			},
		},
		{
			title: t('US.COLLECTION.CASE:INTEREST.ENDDATE'),
			dataIndex: 'endDate',
			key: 'endDate',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (text: any, record: any) => {
				return (
					<span>
						{
							<$DateLabel
								value={
									record?.endDate
								}
							/>
						}
					</span>
				);
			},
		},
		{
			title: t('US.COLLECTION.CASE:INTEREST.BASEAMOUNT'),
			dataIndex: 'baseAmount',
			key: 'baseAmount',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return (
					<$AmountLabel
						value={parseFloat(
							record?.baseAmount
						)}
					/>
				);
			},
		},
		{
			title: t('US.COLLECTION.CASE:INTEREST.NOOFDAYS'),
			dataIndex: 'noOfDays',
			key: 'noOfDays',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
		},

		{
			title: t('US.COLLECTION.CASE:INTEREST.RATE'),
			dataIndex: 'rate',
			key: 'rate',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return <$AmountLabel value={record?.rate} />;
			},
		},
		{
			title: () => (
				<>
					<div>
						{t(
							'US.COLLECTION.CASE:INTEREST.AMOUNT'
						)}
					</div>
					<div className='header-custom-amount'>
						{
							<$AmountLabel
								value={
									subcasesInterestObjectMaker(
										interests?.data
									)
										?.caseAmount
								}
							/>
						}
					</div>
				</>
			),
			dataIndex: 'amount',
			key: 'amount',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return (
					<$AmountLabel
						value={parseFloat(
							record?.amount
						)}
					/>
				);
			},
		},
		{
			title: t('US.COLLECTION.CASE:INTEREST.TRANSTYPE'),
			dataIndex: 'transType',
			key: 'transType',
			ellipsis: true,
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={
								record?.transType
							}>
							{record?.transType}
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: t('US.COLLECTION.CASE:INTEREST.CREATEDBY'),
			dataIndex: 'createdBy',
			key: 'createdBy',
			ellipsis: true,
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={
								record?.createdBy
							}>
							{record?.createdBy}
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
	];

	const handleSubCaseSort: IOnSort = (sortData, dataSource) => {
		return sortData(dataSource);
	};

	const handleSubCaseFilter: IOnFilter = (searchData, dataSource) => {
		return searchData(dataSource);
	};

	const caseColumnsTree: any = [
		{
			title: t('US.COLLECTION.CASE:INTEREST.CASENO'),
			dataIndex: 'caseId',
			key: 'caseId',
			width: 120,
			className: 'case-no-td',
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
			customRenderParent: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<span className='case-no'>
							<$Tag
								color='#87d068'
								className='text-center'
								style={{
									width: '60px',
								}}>
								{t(
									'US.COLLECTION.CASE:INTEREST.CASE'
								)}
							</$Tag>
							<strong>
								{t(
									'US.COLLECTION.CASE:INTEREST.CASENO'
								)}
								: {text}
							</strong>
							<span className='ml-3'>
								(
								{t(
									'US.COLLECTION.CASE:INTEREST.TOTALINTEREST'
								)}
								:
								{
									<$AmountLabel
										value={parseFloat(
											record.sameCaseAmount
										)}
									/>
								}
								)
							</span>
						</span>
					),
					key: index,
					props: {
						colSpan: 9,
					},
				};
			},
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: <></>,
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: t('US.COLLECTION.CASE:INTEREST.STARTDATE'),
			dataIndex: 'startDate',
			key: 'startDate',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={
								record?.startDate
							}>
							<$DateLabel
								value={
									record?.startDate
								}
							/>
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: t('US.COLLECTION.CASE:INTEREST.ENDDATE'),
			dataIndex: 'endDate',
			key: 'endDate',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={record?.endDate}>
							<$DateLabel
								value={
									record?.endDate
								}
							/>
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: t('US.COLLECTION.CASE:INTEREST.BASEAMOUNT'),
			dataIndex: 'baseAmount',
			key: 'baseAmount',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'amount',
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={
								record?.baseAmount
							}>
							<$AmountLabel
								value={parseFloat(
									record?.baseAmount
								)}
							/>
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: t('US.COLLECTION.CASE:INTEREST.NOOFDAYS'),
			dataIndex: 'noOfDays',
			key: 'noOfDays',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={
								record?.noOfDays
							}>
							{record?.noOfDays}
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: t('US.COLLECTION.CASE:INTEREST.RATE'),
			dataIndex: 'rate',
			key: 'rate',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={record?.rate}>
							<$AmountLabel
								value={
									record?.rate
								}
							/>
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: () => (
				<>
					<div>
						{t(
							'US.COLLECTION.CASE:INTEREST.AMOUNT'
						)}
					</div>
					<div className='header-custom-amount'>
						<$AmountLabel
							value={
								subcasesInterestObjectMaker(
									interests?.data
								)?.caseAmount
							}
						/>
					</div>
				</>
			),
			dataIndex: 'amount',
			key: 'amount',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'amount',
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={record?.amount}>
							<$AmountLabel
								value={parseFloat(
									record?.amount
								)}
							/>
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: t('US.COLLECTION.CASE:INTEREST.TRANSTYPE'),
			dataIndex: 'transType',
			key: 'transType',
			ellipsis: true,
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={
								record?.transType
							}>
							{record?.transType}
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
		{
			title: t('US.COLLECTION.CASE:INTEREST.CREATEDBY'),
			dataIndex: 'createdBy',
			key: 'createdBy',
			ellipsis: true,
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return {
					children: (
						<$Tooltip
							placement='topLeft'
							title={
								record?.createdBy
							}>
							{record?.createdBy}
						</$Tooltip>
					),
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
		},
	];
	const handleSort: IOnSort = (sortData, dataSource) => {
		return sortData(
			dataSource.map((i: any) => {
				return {
					...i,
					children: sortData(i.children),
				};
			})
		);
	};
	const handleFilter: IOnFilter = (searchData, dataSource) => {
		let result: any[] = [];
		dataSource.flatMap((i: any) => {
			searchData(i?.children)?.length != 0 &&
				result.push({
					...i,
					children:
						searchData(i?.children)
							?.length == 0
							? []
							: searchData(
									i?.children
							  ),
				});
		});
		return result;
	};

	return (
		<Formik
			enableReinitialize
			initialValues={
				entityType === CaseType.S
					? {
							...subcasesInterestObjectMaker(
								interests?.data
							),
							filterOption: filter,
					  }
					: {
							...casesInterestObjectMaker(
								interests?.data
							),
							filterOption: filter,
					  }
			}
			onSubmit={() => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...restProps
			}: any) => (
				<$Form>
					<div className='space-content case-interest'>
						<$Affix offsetTop={80}>
							<div className='page-header header-border'>
								<div className='d-flex flex-row align-items-center justify-content-between'>
									<div className='d-flex align-items-center'>
										<$PageHeader
											className='px-0'
											title={t(
												'US.COLLECTION.CASE:INTEREST.CASEINTEREST'
											)}
											onBack={() =>
												onMinimize()
											}
										/>
									</div>
								</div>
							</div>
						</$Affix>

						<div className='mt-3 mb-4'>
							<$Row
								gutter={16}
								className='ci-summery'>
								<$Col
									xl={{
										span: 4,
									}}
									xxl={{
										span: 3,
									}}
									className='ci-summery-item'>
									<div className='ci-summery-item-inner'>
										<div className='ci-summery-item-value'>
											{IntlCurrency(
												values?.totalInterest,
												currentLanguage,
												currentCurrency
											)}
										</div>
										<div className='ci-summery-item-title'>
											{t(
												'US.COLLECTION.CASE:INTEREST.TOTALINTEREST'
											)}
										</div>
									</div>
								</$Col>
								<$Col
									xl={{
										span: 4,
									}}
									xxl={{
										span: 3,
									}}
									className='ci-summery-item'>
									<div className='ci-summery-item-inner'>
										<div className='ci-summery-item-value'>
											{IntlCurrency(
												values?.runningInterestMain,
												currentLanguage,
												currentCurrency
											)}
										</div>
										<div className='ci-summery-item-title'>
											{t(
												'US.COLLECTION.CASE:INTEREST.RUNNINGMAININTEREST'
											)}
										</div>
									</div>
								</$Col>
								<$Col
									xl={{
										span: 4,
									}}
									xxl={{
										span: 3,
									}}
									className='ci-summery-item'>
									<div className='ci-summery-item-inner'>
										<div className='ci-summery-item-value'>
											{IntlCurrency(
												values?.runningInterestCosts,
												currentLanguage,
												currentCurrency
											)}
										</div>
										<div className='ci-summery-item-title'>
											{t(
												'US.COLLECTION.CASE:INTEREST.RUNNINGCOSTSINTEREST'
											)}
										</div>
									</div>
								</$Col>
								<$Col
									xl={{
										span: 4,
									}}
									xxl={{
										span: 3,
									}}
									className='ci-summery-item'>
									<div className='ci-summery-item-inner'>
										<div className='ci-summery-item-value'>
											{IntlCurrency(
												values?.transactionInterestMain,
												currentLanguage,
												currentCurrency
											)}
										</div>
										<div className='ci-summery-item-title'>
											{t(
												'US.COLLECTION.CASE:INTEREST.TRANSACTIONALMAININTEREST'
											)}
										</div>
									</div>
								</$Col>
								<$Col
									xl={{
										span: 4,
									}}
									xxl={{
										span: 3,
									}}
									className='ci-summery-item'>
									<div className='ci-summery-item-inner'>
										<div className='ci-summery-item-value'>
											{IntlCurrency(
												values?.transactionInterestCost,
												currentLanguage,
												currentCurrency
											)}
										</div>
										<div className='ci-summery-item-title'>
											{t(
												'US.COLLECTION.CASE:INTEREST.TRANSACTIONALCOSTSINTEREST'
											)}
										</div>
									</div>
								</$Col>
								<$Col
									xl={{
										span: 1,
									}}
									xxl={{
										span: 6,
									}}></$Col>
								<$Col
									xl={{
										span: 3,
									}}
									xxl={{
										span: 3,
									}}>
									<$Select
										formitem={{}}
										name='filterOption'
										size='small'
										allOption={
											false
										}
										onSelect={(
											type: any
										) =>
											onChangeFilter(
												type
											)
										}
										value={
											values.filterOption
										}
										options={[
											{
												label: t(
													'US.COLLECTION.COMMON:COMMON.ALL'
												),
												value: 'all',
											},
											{
												label: t(
													'US.COLLECTION.CASE:INTEREST.MAININTEREST'
												),
												value: 'interestMain',
											},
											{
												label: t(
													'US.COLLECTION.CASE:INTEREST.COSTSINTEREST'
												),
												value: 'interestCost',
											},
										]}
									/>
								</$Col>
							</$Row>
						</div>
						{entityType === CaseType.S && (
							<$Skeleton
								loading={
									interests?.isFetching
								}
								active
								paragraph={{
									rows: 2,
								}}>
								<$TableTree
									onSort={
										handleSubCaseSort
									}
									onFilter={
										handleSubCaseFilter
									}
									filterOnType={
										true
									}
									resetOnSourceChange={
										true
									}
									data={
										values?.interestDetails
									}
									columns={
										subCaseColumns
									}
									size='small'
									className='header-custom-tag'
									bordered
									pagination={{
										defaultPageSize: 15,
									}}
									scroll={{
										x: 1200,
										y: 'calc(100vh - 278px)',
									}}
									defaultExpandAllRows={
										true
									}
									firstColSkipFilterProps={
										-1
									}
								/>
							</$Skeleton>
						)}
						{entityType === CaseType.C && (
							<$Skeleton
								loading={
									interests?.isFetching
								}
								active
								paragraph={{
									rows: 2,
								}}>
								<$TableTree
									onSort={
										handleSort
									}
									onFilter={
										handleFilter
									}
									filterOnType={
										true
									}
									resetOnSourceChange={
										true
									}
									data={
										values?.interestDetails
									}
									columns={
										caseColumnsTree
									}
									size='small'
									className='header-custom-tag'
									bordered
									pagination={{
										defaultPageSize: 15,
									}}
									scroll={{
										x: 1200,
										y: 'calc(100vh - 278px)',
									}}
									defaultExpandAllRows={
										true
									}
									firstColSkipFilterProps={
										-1
									}
								/>
							</$Skeleton>
						)}
					</div>
				</$Form>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, domainView, interest } = state;
	const {
		isFetching,
		currentCurrency,
		currentLanguage,
		currentDateFormat,
	} = common;
	const { metaData } = domainView;
	const { filter, interests } = interest;
	return {
		isFetching,
		currentCurrency,
		currentLanguage,
		metaData,
		currentDateFormat,
		interests,
		filter,
	};
};

const { interest } = Actions.interestAction;

const mapDispatchToProps = {
	get: interest.get,
	setFilter: interest.setFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Interest);
