import React, { useEffect, useState } from 'react';
import '../../ContentV2.scss';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Formik } from 'formik';
import Common from 'us.common';
import { EyeOutlined, LoadingOutlined, SendOutlined, RightOutlined } from 'us.icons';
import { $Drawer, $Skeleton, $TextEditor, $Spin } from 'us.common/components';
import { MessageAndNoteAction } from 'us.collection.case/actions';
import { IRootState } from 'us.collection/interfaces';
import { ISMS } from 'us.collection.case/components/Interfaces/NotesAndMessage';
import moment from 'moment';
import {
	FormatedTemplate,
	GetMessageContent,
	SendDomainMassage,
} from 'us.collection.case/repository';
import { DomainMessageType } from 'us.collection.case/constants/NotesAndMessage';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { appInsights } from 'us.helper';
import { getEntityTypeByKey } from 'us.collection.case/functions';
import { TemplateEditor } from '../TemplateEditor';
import { SMSValidationSchema } from '../../Validations';
import { RootState } from 'us.helper/types';

const {
	$Form,
	$Input,
	$Select,
	$Button,
	$DatePicker,
	$Switch,
	$Row,
	$Col,
	$Typography,
} = Common.Components;

const { Paragraph } = $Typography;

type SMSProps = {
	setNoteView: <T>(data: T) => void;
};

const SMS: React.FC<SMSProps & PropsFromRedux> = (props) => {
	const { t } = useTranslation();

	const {
		currentDateFormat,
		domainMessageFormattedTemplate,
		openTemplateDrawer,
		openDrawer,
		domainMessageBasicdetail,
		metaData,
		domainMessageInitial,
		reset,
		setNoteView,
		sendDomainMessage,

		formattedMessageContentDetails,
		sendDashboardMessage,
		getMessageContent
	} = props;

	useEffect(() => {
		if (sendDomainMessage?.data?.isSuccess) {
			reset && reset({});
			setNoteView(DomainMessageType.SMS);
			openDrawer && openDrawer({ title: '',isTemplateEditor: false, visible: false });
		}
	}, [sendDomainMessage?.data?.isSuccess]);

	const handleTemplateDrawer = (values: any) => {
		try {
			if (metaData.data) {
				const payload = GetMessageContent.call({
					...values,
					entityType: getEntityTypeByKey(metaData.data.entityType),
					emailOrTelNo: values.smsNumber,
					entityId: metaData.data.caseId,
					messageType: "SMS"
				});
				getMessageContent && getMessageContent(payload);
				openDrawer && openDrawer({
					title: t('US.COLLECTION.CASE:NOTESANDMESSAGES.PREVIEW'),
					isTemplateEditor: true,
					visible: true,
				});
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard NotesAndMessage-SMS Drawer Handling Exeception - ${error}`
			);
		}
	};

	const closeTemplateDrawer = (rest: any) => {
		rest.setFieldValue('dueDate', "");
		openDrawer && openDrawer({ title: '', isTemplateEditor: false, visible: false });
	};

	const changeDocumentType = (receiver: string, rest: any) => {
		const entityDetails = domainMessageBasicdetail.entityDetails?.find(
			(entity: any) => entity.receiver === receiver
		);
		rest.setFieldValue('smsNumber', entityDetails?.smsNo);
		rest.setFieldValue('receiverEntityId', entityDetails?.entityId);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={domainMessageInitial}
			validationSchema={SMSValidationSchema}
			onSubmit={handleTemplateDrawer}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...rest
			}: any) => (
				<div className='py-3'>
					<$Form>
						<$Row gutter={16} className='mt-0'>
							<$Col span={24} xxl={{ span: 24 }} xl={{ span: 24 }}>
								<div
									className='d-flex align-items-center'
									data-testid='payment-info'>
									<$Switch
										{...rest}
										id='isPaymentInfo'
										size='small'
										name='isPaymentInformation'
										checked={values.isPaymentInformation}
										defaultChecked={values.isPaymentInformation}
										disabled={
											sendDomainMessage?.isProgress ||
											(metaData.data &&
												isSubCaseTransferredToCase(metaData.data))
										}
									/>
									<Paragraph
										ellipsis
										className='mb-0 ml-2'
										data-testid='payment-info-label'>
										{t('US.COLLECTION.CASE:NOTESANDMESSAGES.PAYMENTINFO')}
									</Paragraph>
								</div>
							</$Col>
						</$Row>

						<$Row gutter={16} className='mt-3'>
							<$Col span={10} xxl={{ span: 10 }} xl={{ span: 12 }}>
								<div style={{ marginTop: 0 }} data-testid='document-type-label'>
									{t('US.COLLECTION.CASE:NOTESANDMESSAGES.DOCUMENTTYPE')}
								</div>
							</$Col>
							<$Col
								span={14}
								xxl={{ span: 14 }}
								xl={{ span: 12 }}
								data-testid='select-document-type'>
								<$Select
									className='w-100'
									name='documentType'
									data-testid='document-type'
									placeholder='Status'
									size='small'
									allOption={false}
									options={domainMessageInitial.documentTypeList}
									onChange={(type: any) => changeDocumentType(type, rest)}
									defaultValue={values.documentType}
									disabled={
										sendDomainMessage?.isProgress ||
										(metaData.data && isSubCaseTransferredToCase(metaData.data))
									}
								/>
							</$Col>
						</$Row>

						<$Row gutter={16} className='mt-2'>
							<$Col span={10} xxl={{ span: 10 }} xl={{ span: 12 }}>
								<div style={{ marginTop: 5 }} data-testid='sms-label'>
									{t('US.COLLECTION.CASE:NOTESANDMESSAGES.SMSNUMBER')}
								</div>
							</$Col>
							<$Col
								span={14}
								xxl={{ span: 14 }}
								xl={{ span: 12 }}
								data-testid='sms-number'>
								<$Input
									name='smsNumber'
									size='small'
									defaultValue={values.smsNumber}
									disabled={
										sendDomainMessage?.isProgress ||
										(metaData.data && isSubCaseTransferredToCase(metaData.data))
									}
								/>
							</$Col>
						</$Row>

						<$Row gutter={16}>
							<$Col span={10} xxl={{ span: 10 }} xl={{ span: 12 }}>
								<div style={{ marginTop: 5 }} data-testid='due-date-label'>
									{t('US.COLLECTION.CASE:NOTESANDMESSAGES.MANUALDUEDATE')}
								</div>
							</$Col>
							<$Col span={14} xxl={{ span: 14 }} xl={{ span: 12 }}>
								<$DatePicker
									className='w-100'
									formitem
									formitemlabel={t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.DUEDATE'
									)}
									style={{ width: '100%' }}
									name='dueDate'
									size='small'
									placeholder={currentDateFormat}
									format={currentDateFormat}
									allowClear
									value={values.dueDate}
									defaultPickerValue={values.dueDate}
									disabledDate={(day: any) =>
										day.isBefore(moment().startOf('day'))
									}
									disabled={
										sendDomainMessage?.isProgress ||
										(metaData.data && isSubCaseTransferredToCase(metaData.data))
									}
								/>
							</$Col>
						</$Row>

						<div className='d-flex align-items-center justify-content-between mt-3'>
							<div>
								{sendDomainMessage?.isProgress && (
									<small className='d-block'>
										<$Spin
											size='small'
											indicator={
												<LoadingOutlined style={{ fontSize: 20 }} spin />
											}
										/>
										<span className='ml-2'>
											{t(
												'US.COLLECTION.CASE:NOTESANDMESSAGES.SEND_SMS_IN_PROGRESS'
											)}
										</span>
									</small>
								)}
							</div>
							<$Button
								type='default'
								onClick={handleSubmit}
								icon={values.isPaymentInformation ? <SendOutlined /> : <RightOutlined />}
								disabled={
									(metaData.data &&
										isSubCaseTransferredToCase(metaData.data)) ||
									sendDomainMessage?.isProgress
								}
								data-testid='send-btn'
							>
								{t('US.COLLECTION.COMMON:COMMON.NEXT')}
							</$Button>
						</div>
						{openTemplateDrawer.visible && (
							<TemplateEditor
								closeTemplateDrawer={() => closeTemplateDrawer(rest)}
								openTemplateDrawer={openTemplateDrawer}
								domainMessageFormattedTemplate={domainMessageFormattedTemplate}
								domainMessageInitial={values}
								documentType={DomainMessageType.LETTERS}
								messageContentDetails={formattedMessageContentDetails}
								send={sendDashboardMessage}
								metaData={metaData}
							/>
						)}
					</$Form>
				</div>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, domainView, messageAndNotes } = state;
	const { isFetching, currentDateFormat } = common;
	const { metaData } = domainView;
	const {
		domainMessageTemplate,
		domainMessageFormattedTemplate,
		openTemplateDrawer,
		domainMessageBasicdetail,
		domainMessageInitial,
		sendDomainMessage,
		formattedMessageContentDetails
	} = messageAndNotes;

	return {
		isFetching,
		currentDateFormat,
		domainMessageTemplate,
		domainMessageFormattedTemplate,
		openTemplateDrawer,
		domainMessageBasicdetail,
		metaData,
		domainMessageInitial,
		sendDomainMessage,
		formattedMessageContentDetails
	};
};

const {
	formattedTemplate,
	messageContent,
	dashboardMessage
} = MessageAndNoteAction;

const mapDispatchToProps = {
	getFormattedTemplate: formattedTemplate.get,
	openDrawer: formattedTemplate.openDrawer,
	reset: formattedTemplate.reset,
	sendDashboardMessage: dashboardMessage.save,
	getMessageContent: messageContent.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SMS);

