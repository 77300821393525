import { ITransaction } from 'us.collection.transactions/interfaces';
import moment from 'moment';

export const Transaction: ITransaction = {
	amount: 0,
	defaultAmount: 0,
	interestAmount: 0,
	totalAmount: 0,
	invoiceAmount: 0,
	description: '',
	type: '',
	typeName: '',
	itemTypeId: 1,
	caseId: '',
	caseNo: '',
	arItemNo: 0,
	kid: '',
	invoiceNumber: '',
	paymentID: 0,
	balance: 0,
	lineBalance: 0,
	createdBy: '',
	filePath: '',
	communicationChannel: '',
	voucherDate: moment(),
	orderDate: moment().add(15, 'days'),
	voucherID: 0,
	regDate: moment(),
	dueDate: moment().add(14, 'days'),
	transactionGroupId: 1,
	transactionGroupName: '',
	text: '',
	isPayment: false,
	isCancelable: true,
	isDeletable: false,
	arNo: 0,
	subCaseNo: 0,
	transferDate: moment(),
	cancelDate: moment().add(3, 'years'),
	transferred: false,
	isPrinted: false,
	invoicePath: '',
	delayed: false,
	subType: 0,
	workFlowStateID: 0,
	nextDueDate: null,
	workFlowState: null,
	paid: 0,
	cancellationType: 0,
	cancellationTypeDescription: null,
	isRemappingAllowed: false,
	interestFromDate: moment().add(15, 'days'),
	interestRate: 0,
	interestType: 'STD',
	isInterestApplicable: true,
};
