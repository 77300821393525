import { put, call, takeLatest, select } from "redux-saga/effects";
import Common from "us.common";
import * as API from "us.collection.followup/services";
import * as CollectionAPI from "us.collection/services";
import {
  FollowUps,
  FollowUpAction,
  FollowUpEntityType,
} from "us.collection.followup/constants";
import * as Actions from "us.collection.followup/actions";
import { appInsights } from "us.helper";
import { IRootState } from "us.collection/interfaces";
import { EntityTypes } from "us.collection/components/ActivityExecution/Constants";
import { DirectForm } from "us.collection/constants";
import { getUrlForEntity } from "us.collection.followup/functions";

const { $MessageBox } = Common.Components;

const { followUps } = Actions;

const getSelectedCategoryId = (state: IRootState) =>
  state.followUps.categories.selectedCategoryId;

const getFollowUpList = (state: IRootState) => state.followUps.list.data;

const getActionResponseMessage = (action: string, success: boolean): string => {
  switch (action) {
    case FollowUpAction.DONE:
      return success ? "MARKED_AS_DONE" : "MARKING_DONE_FAILED";
    case FollowUpAction.REMOVE:
      return success ? "SUCCESSFULLY_REMOVED" : "REMOVAL_FAILED";
    case FollowUpAction.POSTPONE:
      return success ? "SUCCESSFULLY_POSTPONED" : "POSTPONE_FAILED";
    case FollowUpAction.UNDO:
      return success ? "SUCCESSFULLY_UNDONE" : "UNDO_FAILED";
    default:
      return "";
  }
};

const entityTypeConversion = (entityType: EntityTypes | string) => {
  switch (entityType) {
    case EntityTypes.SUBCASE:
      return FollowUpEntityType.INVOICE;
    case EntityTypes.CASE:
      return FollowUpEntityType.CASE;
    case EntityTypes.AR:
      return FollowUpEntityType.DEBTOR;
    case EntityTypes.CREDITOR:
      return FollowUpEntityType.CREDITOR;
    case EntityTypes.PAYMENT:
      return FollowUpEntityType.PAYMENT;
    default:
      return "";
  }
};

const followUpsSagas = {
  categories: {
    get: function* (action: any): any {
      const params = action.payload.data ?? {};
      try {
        const data = yield call(API.followUps.categories.get, params);
        yield put(followUps.categories.success(data));
      } catch (error) {
        // appInsights.trackException(
        //   `Get FollowUp Categories Saga Exception - ${error}`
        // );
        yield put(followUps.categories.fail(error as any));
      }
    },
  },
  list: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.followUps.list.get, params);
        yield put(followUps.list.success(data));
      } catch (error) {
        // appInsights.trackException(
        //   `Get FollowUp List Saga Exception - ${error}`
        // );
        yield put(followUps.list.fail(error as any));
      }
    },
  },
  item: {
    unPin: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const selectedCategoryId = yield select(getSelectedCategoryId);
        const { listData, pinStatus } = yield call(API.followUps.item.unPin, {
          ...params,
          selectedCategoryId,
        });

        yield put(
          followUps.item.success({ pinStatus, pinId: params?.caselistQueryId })
        );
        if (listData?.length > 0) yield put(followUps.list.success(listData));
      } catch (error) {
        // appInsights.trackException(
        //   `UnPin FollowUp List Saga Exception - ${error}`
        // );
        yield put(followUps.item.fail(error as any));
      }
    },
  },
  result: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.followUps.result.get, params);
        yield put(
          followUps.result.success({
            response: data,
            followupId: params.followUpId,
          })
        );
      } catch (error) {
        // appInsights.trackException(
        //   `UnPin FollowUp List Saga Exception - ${error}`
        // );
        yield put(followUps.result.fail(error as any));
      }
    },
    reImport: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const followupReimportData = yield call(
          API.followUps.result.reImport,
          params
        );
        if (followupReimportData.isError) {
          yield put(followUps.reImport.fail(followupReimportData as any));
          $MessageBox(
            "error",
            `US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.ERROR_IN_FOLLOWUP_REIMPORT`,
            "",
            ""
          );
        } else {
          if (params?.followUpId != -1) {
            $MessageBox(
              "success",
              `US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.FOLLOWUP_RESULTS_REIMPORTED_SUCCESSFULLY`,
              "",
              ""
            );
            const followupResultData = yield call(
              API.followUps.result.get,
              params
            );
            yield put(
              followUps.reImport.success({ followUpId: params?.followUpId })
            );
            yield put(
              followUps.result.success({
                response: followupResultData,
                followupId: params.followUpId,
              })
            );
          }
        }
      } catch (error) {
        yield put(followUps.reImport.fail(error as any));
        $MessageBox(
          "error",
          `US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.ERROR_IN_FOLLOWUP_REIMPORT`,
          "",
          ""
        );
      }
    },
    assignToUser: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      const other = action?.payload?.other ?? {};
      try {
        const data = yield call(API.followUps.result.assignToUser, params);
      } catch (error) {
        // appInsights.trackException(
        //   `followUp assign to user Saga Exception - ${error}`
        // );
      }
    },
  },
  history: {
    entity: {
      get: function* (action: any): any {
        const params = action?.payload?.data ?? {};
        try {
          const data = yield call(API.followUps.history.entity.get, params);
          yield put(followUps.historyForEntity.success(data));
        } catch (error) {
          yield put(followUps.historyForEntity.fail(error as any));
        }
      },
    },
    item: {
      get: function* (action: any): any {
        const params = action?.payload?.data ?? {};
        try {
          const data = yield call(API.followUps.result.get, params, true);
          yield put(
            followUps.historyForItem.success({
              response: data,
              followUpId: params?.followUpId,
            })
          );
        } catch (error) {
          yield put(followUps.historyForItem.fail(error as any));
        }
      },
    },
    action: {
      get: function* (action: any): any {
        const params = action?.payload?.data ?? {};
        try {
          const data = yield call(API.followUps.history.action.get, params);
          yield put(followUps.historyForAction.success(data));
        } catch (error) {
          yield put(followUps.historyForAction.fail(error as any));
        }
      },
    },
  },
  entity: {
    active: {
      get: function* (action: any): any {
        const params = action?.payload?.data ?? {};
        try {
          const data = yield call(API.followUps.entity.active.get, params);
          yield put(followUps.activeFollowUps.success(data));
        } catch (error) {
          yield put(followUps.activeFollowUps.fail(error as any));
        }
      },
    },
    action: {
      execute: function* (action: any): any {
        const params = action?.payload?.data ?? {};
        const entity = action?.payload?.other ?? {};
        try {
          const data = yield call(API.followUps.entity.action.execute, params);
          yield put(followUps.action.success(data));
          if (!entity.hasOwnProperty("hide")) {
            $MessageBox(
              "success",
              `US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.${getActionResponseMessage(
                params?.actionName.toUpperCase(),
                true
              )}`,
              "",
              ""
            );
          }
          if (
            followUps.result.get &&
            entity.hasOwnProperty("followUpId") &&
            !entity.hasOwnProperty("hide")
          ) {
            yield put(followUps.result.get(entity));
          } else if (
            followUps.activeFollowUps.get &&
            !entity.hasOwnProperty("hide")
          ) {
            yield put(followUps.activeFollowUps.get(entity));
          }
        } catch (error) {
          if (
            entity.hasOwnProperty("followUpId") &&
            !entity.hasOwnProperty("hide")
          ) {
            $MessageBox(
              "error",
              "US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.ACTION_EXECUTION_FAILED",
              "",
              ""
            );
          } else if (entity.hasOwnProperty("entityId")) {
            $MessageBox(
              "error",
              `US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.${getActionResponseMessage(
                params?.actionName.toUpperCase(),
                false
              )}`,
              "",
              ""
            );
          }

          // appInsights.trackException(
          //   `Execute Active FollowUp-action Saga Exception - ${error}`
          // );
          yield put(followUps.action.fail(error as any));
        }
      },
    },
  },
  activities: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.followUps.activites.get, params);
        if (Array.isArray(data)) {
          yield put(followUps.activities.success(data));
        } else {
          yield put(followUps.activities.fail(data));
        }
      } catch (error) {
        // appInsights.trackException(
        //   `Get FollowUp Activities For Action Saga Exception - ${error}`
        // );
        yield put(followUps.activities.fail(error as any));
      }
    },
  },
  activity: {
    getParameters: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.followUps.activity.parameters.get, params);
        if (data.hasOwnProperty("activityId")) {
          yield put(followUps.parameters.success(data));
        } else {
          yield put(followUps.parameters.fail(data));
        }
      } catch (error) {
        yield put(followUps.parameters.fail(error as any));
        // appInsights.trackException(
        //   `Get FollowUp Activity Parameters Saga Exception - ${error}`
        // );
      }
    },
    execute: function* (action: any): any {
      const params = action.payload.data;
      const actionRequest = action?.payload?.other.actionRequest ?? {};
      const user = action?.payload?.other.user ?? {};
      const activity = action?.payload?.other.activity ?? {};
      try {
        const data = yield call(API.followUps.activity.execute, params);
        if (data.hasOwnProperty("batchId")) {
          const { batchId } = data;
          yield put(followUps.activity.success(data));
          $MessageBox(
            "success",
            `US.COLLECTION.COMMON:ACTIVITYEXECUTION.ACTIVITY_BATCH_IS_SCHEDULED_SUCCESSFULLY`,
            "",
            ""
          );
          if (followUps.action.execute) {
            yield put(
              followUps.action.execute(
                { ...actionRequest, batchId },
                { hide: true }
              )
            );
          }
          // if (followUps.result.setLastActions) {
          //   yield put(
          //     followUps.result.setLastActions(
          //       setPendingFollowups(actionRequest, user)
          //     )
          //   );
          // }
        } else {
          yield put(followUps.activity.fail(data));
          $MessageBox(
            "error",
            `US.COLLECTION.COMMON:ACTIVITYEXECUTION.ACTIVITY_EXECUTION_FAILED`,
            "",
            ""
          );
        }
      } catch (error) {
        yield put(followUps.activity.fail(error as any));
        $MessageBox(
          "error",
          `US.COLLECTION.COMMON:ACTIVITYEXECUTION.ACTIVITY_EXECUTION_FAILED`,
          "",
          ""
        );
        // appInsights.trackException(
        //   `FollowUp Activity Execute Saga Exception - ${error}`
        // );
      } finally {
        if (followUps.activity.mangeParameterDropDown) {
          yield put(followUps.activity.mangeParameterDropDown({}));
        }
      }
    },
    getBMD: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.followUps.activity.bmd.get, params);
        if (Array.isArray(data)) {
          yield put(followUps.bmd.success(data));
        } else {
          yield put(followUps.bmd.fail(data));
        }
      } catch (error) {
        yield put(followUps.bmd.fail(error as any));
        // appInsights.trackException(
        //   `Get FollowUp Activity BMD Saga Exception - ${error}`
        // );
      }
    },
    executeSingle: function* (action: any): any {
      const params = action?.payload?.data;
      const actionRequest = action?.payload?.other?.actionRequest ?? {};
      const entity = action?.payload?.other?.entity ?? {};
      try {
        const data = yield call(
          CollectionAPI.activityService.activity.execute,
          params
        );
        if (data.hasOwnProperty("message")) {
          if (data?.message?.toLowerCase()?.includes("scheduled")) {
            $MessageBox(
              "success",
              `US.COLLECTION.COMMON:ACTIVITYEXECUTION.ACTIVITY_SCHEDULED_SUCCESSFULLY`,
              "",
              ""
            );
          } else {
            $MessageBox(
              "success",
              `US.COLLECTION.COMMON:ACTIVITYEXECUTION.ACTIVITY_SUCCESSFULLY_EXECUTED`,
              "",
              ""
            );
          }
          if (followUps.activity.executeSingleSuccess) {
            yield put(followUps.activity.executeSingleSuccess(data));
          }
          if (followUps.action.execute) {
            yield put(followUps.action.execute(actionRequest, { hide: true }));
          }
          if (followUps.activeFollowUps.get) {
            yield put(
              followUps.activeFollowUps.get({
                entityId: entity?.entityId,
                entityType: entityTypeConversion(params?.entityType),
              })
            );
          }
        } else {
          if (data.hasOwnProperty("errors")) {
            $MessageBox(
              "error",
              `US.COLLECTION.COMMON:ACTIVITYEXECUTION.ACTIVITY_EXECUTION_FAILED`,
              "",
              ""
            );
          }
          if (followUps.activity.executeSingleFail) {
            yield put(followUps.activity.executeSingleFail(data));
          }
        }
      } catch (error) {
        appInsights.trackException(
          `Single activity Execution of followup Saga Exception - ${error}`
        );
      } finally {
        if (followUps.activity.mangeParameterDropDown) {
          yield put(followUps.activity.mangeParameterDropDown({}));
        }
      }
    },
  },
  lastAction: {
    get: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.followUps.lastAction.get, params);
        if (Array.isArray(data)) {
          yield put(followUps.lastActions.success(data));
        } else {
          yield put(followUps.lastActions.fail(data));
        }
      } catch (error) {
        yield put(followUps.lastActions.fail(error as any));
        // appInsights.trackException(
        //   `Get FollowUp Activity BMD Saga Exception - ${error}`
        // );
      }
    },
  },
  categoryId: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.followUps.categoryId.get, params);
        if (data.hasOwnProperty("categoryId")) {
          yield put(followUps.categoryId.success(data));
        } else {
          yield put(followUps.categoryId.fail(data));
        }
      } catch (error) {
        // appInsights.trackException(
        //   `Get FollowUp Activities For Action Saga Exception - ${error}`
        // );
        yield put(followUps.categoryId.fail(error as any));
      }
    },
  },
};
export default [
  takeLatest(FollowUps.GET_FOLLOW_UP_CATEGORIES, followUpsSagas.categories.get),
  takeLatest(FollowUps.GET_FOLLOW_UP_LIST, followUpsSagas.list.get),
  takeLatest(FollowUps.UNPIN_FOLLOW_UP_ITEM, followUpsSagas.item.unPin),
  takeLatest(FollowUps.GET_FOLLOW_UP_RESULT, followUpsSagas.result.get),
  takeLatest(
    FollowUps.GET_FOLLOW_UP_HISTORY_FOR_ENTITY,
    followUpsSagas.history.entity.get
  ),
  takeLatest(
    FollowUps.GET_FOLLOW_UP_HISTORY_FOR_ITEM,
    followUpsSagas.history.item.get
  ),
  takeLatest(
    FollowUps.GET_FOLLOW_UP_HISTORY_FOR_ACTION,
    followUpsSagas.history.action.get
  ),
  takeLatest(FollowUps.GET_ACTIVE_FOLLOW_UP, followUpsSagas.entity.active.get),
  takeLatest(
    FollowUps.EXECUTE_FOLLOW_UP_ACTION,
    followUpsSagas.entity.action.execute
  ),
  takeLatest(
    FollowUps.GET_FOLLOW_UP_ACTIVITIES_START,
    followUpsSagas.activities.get
  ),
  takeLatest(
    FollowUps.GET_FOLLOW_UP_ACTIVITY_PARAMETERS_START,
    followUpsSagas.activity.getParameters
  ),
  takeLatest(FollowUps.GET_ACTIVITY_BMD_START, followUpsSagas.activity.getBMD),
  takeLatest(
    FollowUps.EXECUTE_SINGLE_FOLLOW_UP_ACTIVITY,
    followUpsSagas.activity.executeSingle
  ),
  takeLatest(FollowUps.FOLLOW_UP_RE_IMPORT, followUpsSagas.result.reImport),
  takeLatest(
    FollowUps.ASSIGN_FOLLOW_UP_TO_USER,
    followUpsSagas.result.assignToUser
  ),
  takeLatest(
    FollowUps.GET_UPDATED_LAST_ACTION_START,
    followUpsSagas.lastAction.get
  ),
  takeLatest(
    FollowUps.GET_CATEGORY_ID_FOR_SELECTED_FOLLOW_UP_START,
    followUpsSagas.categoryId.get
  ),
];
