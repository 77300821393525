import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { $Tabs } from 'us.common/components';
import { EntityFollowUpHistory } from 'us.collection.followup/components/EntityFollowUps';
import { EntityFollowUpTabs } from 'us.collection/constants';
import { IRootState } from 'us.collection/interfaces';
import './Style.scss';
import { followUps } from 'us.collection/actions';
import { CurrentFollowUps } from './Components';

const { entity } = followUps;

const EntityFollowups: React.FC<PropsFromRedux & RouteComponentProps> = (
	props
) => {
	const { t } = useTranslation();

	const { drawer, manageEntityFollowUpDrawer } = props;

	return (
		<>
			<$Tabs
				className=''
				defaultActiveKey={drawer.selectedTab}
				onTabClick={(key: string, e: any) =>
					manageEntityFollowUpDrawer &&
					manageEntityFollowUpDrawer({
						...drawer,
						selectedTab: key,
					})
				}>
				<$Tabs.TabPane
					tab={t(
						'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.FOLLOWUP'
					)}
					key={EntityFollowUpTabs.FOLLOW_UP}>
					<CurrentFollowUps {...props} />
				</$Tabs.TabPane>
				<$Tabs.TabPane
					tab={t(
						'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.HISTORY'
					)}
					key={
						EntityFollowUpTabs.FOLLOW_UP_HISTORY
					}
					className='p-4'>
					<EntityFollowUpHistory
						{...props}
						onClose={
							manageEntityFollowUpDrawer
						}
					/>
				</$Tabs.TabPane>
			</$Tabs>
		</>
	);
};

const mapStateToProps = (state: IRootState) => {
	const { entityFollowUps } = state;
	const { drawer } = entityFollowUps;
	return { drawer };
};

const mapDispatchToProps = {
	manageEntityFollowUpDrawer: entity.manageDrawer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EntityFollowups);
