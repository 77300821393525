import React, { useState } from "react";
import { General } from "./Components";
import "./DebtorInformation.scss";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { CreditInformation } from "us.collection.debtor/components/DebtorBasicInfo/Components";
import Common from "us.common";
import { IDebtorInformation } from "us.collection.case/components/OtherParty/Edit/Interfaces";

const { $Affix, $Tabs, $PageHeader } = Common.Components;

const EditOtherParty: React.FC<IDebtorInformation> = (props) => {
  const [viewForm, setViewForm] = useState<boolean>(true);
  const [debtorFormData, setDebtorFormData] = useState<any>();
  const { t } = useTranslation();

  const callback = (key: any) => {};
  const { selectedOtherParty } = props;

  /**
   * changing the view as we click on the postal code
   * of the form
   */
  const changeView = (data: any) => {
    setDebtorFormData(data);
    window.scrollTo(0, 0);
    setViewForm(false);
  };

  const backToDebtorFormHandler = () => {
    setViewForm(true);
  };

  return (
    <div>
      {viewForm ? (
        <>
          <$Tabs className="bui-tabs" defaultActiveKey="1" onChange={callback}>
            <$Tabs.TabPane
              tab={t(`US.COLLECTION.DEBTOR:INFO.GENERAL`)}
              key="1"
              data-testid="otherparty-edit-general"
            >
              <div className="general-info">
                <General
                  changeView={(data: any) => changeView(data)}
                  dataBkp={debtorFormData}
                  {...props}
                />
              </div>
            </$Tabs.TabPane>
            <$Tabs.TabPane
              tab={t(`US.COLLECTION.DEBTOR:INFO.CREDITINFO`)}
              key="3"
              data-testid="otherparty-edit-creditInformation"
            >
              <div className="credit-info">
                <CreditInformation
                  entRoleId={selectedOtherParty?.entityId}
                  {...props}
                />
              </div>
            </$Tabs.TabPane>
          </$Tabs>
        </>
      ) : (
        <>
          <$Affix offsetTop={80}>
            <div className="page-header">
              <div className="d-flex align-items-center">
                <$PageHeader
                  className="px-0 py-3 mr-n3"
                  onBack={backToDebtorFormHandler}
                  title={t("US.COLLECTION.DEBTOR:INFO.SELECTPOSTALAREA")}
                />
              </div>
            </div>
          </$Affix>
        </>
      )}
    </div>
  );
};

export default withRouter<IDebtorInformation, any>(EditOtherParty);
