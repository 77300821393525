import React from 'react';
import { useTranslation } from 'react-i18next';
import { parse } from 'query-string';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import {
	$Skeleton,
	$Collapse,
	$Empty,
	FollowUpCard,
	ActivityExecution,
} from 'us.common/components';
import { followUps } from 'us.collection.followup/actions';
import {
	filterActiveEntityFollowUps,
	getEntityTypeAndId,
} from 'us.collection.followup/functions';
import { IActiveFollowUp } from 'us.collection.followup/interfaces';
import { FollowUpPanel } from 'us.common/components/FollowUps/Constants';
import './Style.scss';
import { initialFilter } from 'us.collection/components/FollowUps/Components/CurrentFollowUps/Components/Constants';
import { Mode } from 'us.common/constants';
import { RootState } from 'us.helper/types';

const { action, activeFollowUps } = followUps;

/**
 * @description - Other FollowUp list consists of followup cards inside the followup tab in entity followup drawer
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2912288817/Get+active+follow-up+for+EntityId+and+EntityType+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 09/03/2022
 */
const OtherEntityFollowUps: React.FC<PropsFromRedux & RouteComponentProps> = (
	props
) => {
	const { t } = useTranslation();

	const {
		metaData,
		active,
		location,
		executeFollowUpAction,
		getActiveFollowUps,
	} = props;
	const { data, isFetching } = active;

	return (
		<$Skeleton
			className={isFetching ? 'p-3' : ''}
			loading={isFetching}
			paragraph={{ rows: 2 }}
			active>
			<$Collapse
				ghost
				accordion
				defaultActiveKey={'0'}
				collapsible={'header'}
				className='collection-followup-collapsed'>
				{data.length === 0 && !isFetching && (
					<div className='fl-container'>
						<div className='flc-body'>
							<div className='followup-dashboard-msg'>
								<$Empty
									className='pt-5'
									description={`${t(
										'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.NO_DATA'
									)}`}
								/>
							</div>
						</div>
					</div>
				)}
				{data.length > 0 &&
					filterActiveEntityFollowUps(data, {
						...initialFilter,
						...parse(location.search),
					}).map(
						({
							followupDataId,
							description,
							followupName,
							createdDate,
							assignedTo,
							lastViewed,
							followupId,
							followupType,
						}: IActiveFollowUp) => (
							<FollowUpCard
								key={
									followupDataId
								}
								uniqueId={
									followupDataId
								}
								description={{
									label: t(
										'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.DESCRIPTION'
									),
									value: description,
								}}
								title={
									followupName
								}
								date={{
									label: t(
										'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.REG_DATE'
									),
									value: createdDate,
								}}
								assignedTo={
									assignedTo
								}
								lastViewed={
									lastViewed
								}
								followUpPanel={
									FollowUpPanel.OTHER
								}
								onExecuteAction={(
									values: any
								) =>
									executeFollowUpAction &&
									executeFollowUpAction(
										values,
										getEntityTypeAndId(
											props.location,
											metaData
										)
									)
								}
								followupId={
									followupId
								}
								followupType={
									followupType
								}
								entityDetails={getEntityTypeAndId(
									props.location,
									metaData
								)}
								activityExecution={
									<ActivityExecution
										mode={
											Mode.Click
										}
										isHideTable={
											true
										}
										isDisabled={
											false
										}
										isBulkExecutionEnabled={
											false
										}
										isGroupActivityOptions={
											false
										}
										isActivityOptionWithCode={
											false
										}
										isHideDatePicker={
											true
										}
										activityAPIRequest={{
											endPoint: `activitiesforfollowup/${followupId}/${true}`,
										}}
										executeActivityEndPoint='activities'
										isPopConfirmEnabled={
											true
										}
										isPopConfirmEnabledDropDown={
											true
										}
										propConfirmDetail={{
											title: 'DO_YOU_WANT_TO_REMOVE_RESULTS_FROM_THE_FOLLOWUP_AFTER_SUCCESSFUL_EXECUTION',
											isOnCancelSubmit:
												true,
										}}
										filter={
											getEntityTypeAndId(
												props.location,
												metaData
											)
												.entityType
										}
										reduxActivities={[
											{
												action: executeFollowUpAction,
											},
											{
												action: getActiveFollowUps,
											},
										]}
										entityId={
											getEntityTypeAndId(
												props.location,
												metaData,
												true
											)
												.entityId
										}
										entityType={
											getEntityTypeAndId(
												props.location,
												metaData
											)
												.entityType
										}
										isActionExecution={
											true
										}
										selectedEntities={[
											followupDataId,
										]}
									/>
								}
							/>
						)
					)}
			</$Collapse>
		</$Skeleton>
	);
};

const mapStateToProps = (state: RootState) => {
	const { domainView, followUps } = state;
	const { metaData } = domainView;
	const { entity } = followUps;
	const { active } = entity;
	return {
		metaData: metaData.data,
		active,
	};
};

const mapDispatchToProps = {
	executeFollowUpAction: action.execute,
	getActiveFollowUps: activeFollowUps.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OtherEntityFollowUps);
