import {
  IActivity,
  ISetting,
  IDefaultData,
} from "us.collection.followup/interfaces";
import { getParameterList } from "us.collection.followup/functions";
import moment from "moment";
import {
  FollowUpEntityType,
  FollowUpUniqueCol,
} from "us.collection.followup/constants";
import { EntityTypes } from "us.collection/components/ActivityExecution/Constants";

/**
 * @function
 * @description - entity type conversion from FollowUpEntityType to single activity execution's entityType field
 * @param {FollowUpEntityType | string} entityType - entity type - FollowUpEntityType
 * @returns {EntityTypes | ""}
 */
const entityTypeConversion = (
  entityType: FollowUpEntityType | string
): EntityTypes | "" => {
  switch (entityType) {
    case FollowUpEntityType.INVOICE:
      return EntityTypes.SUBCASE;
    case FollowUpEntityType.CASE:
      return EntityTypes.CASE;
    case FollowUpEntityType.DEBTOR:
      return EntityTypes.AR;
    case FollowUpEntityType.CREDITOR:
      return EntityTypes.CREDITOR;
    case FollowUpEntityType.PAYMENT:
      return EntityTypes.PAYMENT;
    default:
      return "";
  }
};

/**
 * @function
 * @description - get column name for the parameter list entityType
 * @param {FollowUpEntityType | string} entityType 
 * @returns {FollowUpUniqueCol | ""}
 */
const getColNameForEntityType = (
  entityType: FollowUpEntityType | string
): FollowUpUniqueCol | "" => {
  switch (entityType) {
    case FollowUpEntityType.INVOICE:
    case FollowUpEntityType.CASE:
      return FollowUpUniqueCol.CASE_NO;
    case FollowUpEntityType.DEBTOR:
      return FollowUpUniqueCol.AR_NO;
    case FollowUpEntityType.CREDITOR:
      return FollowUpUniqueCol.CREDITOR_NO;
    case FollowUpEntityType.PAYMENT:
      return FollowUpUniqueCol.PAYMENT_ID;
    default:
      return "";
  }
};

/**
 * @function
 * @description - repository function to prepare request object from form data for single activity execution endpoint
 * @param {any} this - formData
 * @param {Array<ISetting>} list - activity settings
 * @param {Array<IActivity>} activityList - list of activities
 * @param {IDefaultData} defaultData - default data that are not displayed to the user
 * @param {{ entityId: number; entityType: string }} entityDetails - entity details
 * @returns request data
 */
export function SingleActivityExReq(
  this: any,
  list: Array<ISetting>,
  activityList: Array<IActivity>,
  defaultData: IDefaultData,
  entityDetails?: { entityId: number; entityType: string },
  entityTypeFilter?: string
) {
  const { activity } = this;
  const { entityId = -1, entityType = "" } = entityDetails ?? {};
  const paramList = getParameterList(
    this,
    list,
    defaultData,
    { [getColNameForEntityType(entityType)]: 0 },
    entityId
  );
  return {
    uniqueId: -1,
    activityCode: activityList.find(
      (act: IActivity) => act.displayName == activity && act.entitytype == entityTypeFilter
    )?.activityCode,
    executingDateTime: moment().format(),
    caseId: entityId,
    entityType: entityTypeConversion(entityType),
    parameterList: paramList,
  };
}
