import React, { memo } from 'react';
import { Followup } from 'us.collection.followup/interfaces';

type FollowUpItemProps = {
	item: Followup;
	onPress: (item: any) => void;
};

/**
 * @description - Pinned followups list item component
 */
const FollowUpItem: React.FC<FollowUpItemProps> = ({ item, onPress }) => {
	return (
		<div
			className='home-followup-item'
			onClick={() => onPress(item)}>
			<div className='hfi-name'>
				{item.followupDisplayName}
			</div>
			<div className='hfi-count'>{item.caseCount}</div>
		</div>
	);
};

export default memo(FollowUpItem);
