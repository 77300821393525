import { ChangeDebtor } from "us.collection.debtor/constants/Actions";
import { IAPIAction } from "us.collection/interfaces/API";


export const changeDebtorActions: Readonly<IAPIAction> = {
  debtor: {
    save: (data) => ({
      type: ChangeDebtor.CHANGE_DEBTOR_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ChangeDebtor.CHANGE_DEBTOR_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ChangeDebtor.CHANGE_DEBTOR_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    openDrawer: (data) => ({
      type: ChangeDebtor.CHANGE_DEBTOR_DRAWER,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
};
