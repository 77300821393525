import { Formik } from 'formik';
import React, { useEffect, useState, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { connect, ConnectedProps } from 'react-redux';

import * as Actions from 'us.collection.transactions/actions';
import Common from 'us.common';
import { DeleteOutlined } from 'us.icons';
import { InterestTypeTag, TransactionTypes } from './Components';
import { Transaction, CreditNote } from 'us.collection.transactions/repository';
import {
  TransactionFilterTypes,
  TransactionGroup,
} from 'us.collection.transactions/components/Transactions/Constants';
import { appInsights } from 'us.helper';
import { ITransactionDetails } from './Interfaces/Interfaces';
import { ValidationSchema } from './Validations';
import { RootState } from 'us.helper/types';
import { InterestApplication } from '../InterestApplication';
import './TransactionDetails.scss';
import {
  selectedTransactionTypeDetails,
  isEnableInterestApplication,
} from './Functions';
import { DateFormats } from 'us.collection.transactions/constants';

const {
  $Input,
  $InputAmount,
  $Button,
  $Table,
  $Select,
  $Row,
  $Col,
  $Form,
  $DatePicker,
  $TextArea,
  $Popconfirm,
  $DateLabel,
  $AmountLabel,
  $Divider,
} = Common.Components;

const { transactions, transactionTypes, interestApplication } = Actions;
const { save, resetInfo, addCreditNote, manageDrawer } = transactions;
const { getAll } = transactionTypes;

const TransactionDetails: React.FC<ITransactionDetails & PropsFromRedux> = memo(
  (props) => {
    const { t } = useTranslation();
    const {
      isDeleteTransaction,
      isNewTransaction,
      filters,
      caseDetail,
      transactionDetails,
      transactionTypes,
      currentCurrency,
      currentDateFormat,
      currentLanguage,
      accountSummeryWidgetData,
      isSaving,
      metaData,
      standardInterest,
      onClose,
      getTransactionTypes,
      manageTransactionnDrawer,
      saveTransactions,
      deleteTransactions,
      addCreditNote,
      getStandardInterestRate,
    } = props;
    const [transactionsData, setTransactionsData] = useState<Array<any>>([]);
    const [transTypeVisible, setTransTypeVisible] = useState<boolean>(false);
    const [confirmedPastDate, setConfirmedPastDate] = useState<boolean>(false);

    const { data } = accountSummeryWidgetData ?? {};
    const { caseType, closedDate } = data?.case ?? {};

    const transactionTypeOptions = useMemo(() => {
      return transactionTypes?.map((item: any) => {
        return {
          ...item,
          label: `${item?.typeName} - ${item?.description}`,
        };
      });
    }, [transactionTypes]);

    useEffect(() => {
      if (isNewTransaction) {
        caseType && getTransactionTypes(caseType);
        getStandardInterestRate &&
          getStandardInterestRate({
            calculationdate: moment().format(DateFormats.REQ),
          });
      } else {
        getTransactionTypes(
          TransactionFilterTypes.CANCELLATION,
          transactionDetails?.type
        );
      }
    }, []);

    const submitNewTransactions = () => {
      saveTransactions(transactionsData, filters);
    };

    const handleTransactionSubmit = (values: any) => {
      try {
        if (isDeleteTransaction) {
          deleteTransactions(values, filters);
        } else if (!isNewTransaction) {
          const creditNote = CreditNote.call(values, {
            ...metaData.data,
          });
          addCreditNote(creditNote, filters);
        }
      } catch (error) {
        appInsights.trackException(`Transaction Submit Exception - ${error}`);
      }
    };

    const removeTransactionHandler = (index: any) => {
      try {
        transactionsData.splice(index, 1);
        setTransactionsData([...transactionsData]);
      } catch (error) {
        appInsights.trackException(`Transaction Remove Exception - ${error}`);
      }
    };

    const addTransaction = (values: any, rest: any) => {
      try {
        if (metaData.data) {
          const transaction = Transaction.call(values, transactionTypes, {
            ...metaData.data,
          });
          const newTransactionsData = [...transactionsData, transaction];
          setTransactionsData(newTransactionsData);
          rest.resetForm({ values });
          setConfirmedPastDate(false);
        }
      } catch (error) {
        appInsights.trackException(`Transaction Add Exception - ${error}`);
      }
    };

    const columns: any = [
      {
        title: '',
        key: 'Id',
        width: '50px',
        render: (text: string, record: any, index: any) => (
          <span>
            <$Button
              onClick={() => removeTransactionHandler(index)}
              icon={<DeleteOutlined />}
              size="small"
              danger
            />
          </span>
        ),
      },
      {
        title: t('US.COLLECTION.TRANSACTIONS:REGISTER.TRANSTYPE'),
        dataIndex: 'transType',
        key: 'transType',
        className: 'text-nowrap',
      },
      {
        title: t('US.COLLECTION.TRANSACTIONS:REGISTER.VOUCHERNO'),
        dataIndex: 'voucherNo',
        key: 'voucherNo',
        className: 'text-nowrap',
      },
      {
        title: t('US.COLLECTION.TRANSACTIONS:REGISTER.VOUCHERDATE'),
        dataIndex: 'voucherDate',
        key: 'voucherDate',
        className: 'text-nowrap',
        render: (text: any, record: any) => {
          const { voucherDate } = record;
          return (
            moment.utc(voucherDate).isValid() &&
            moment
              .utc(voucherDate)
              .isAfter(moment.utc(DateFormats.MIN_DATE)) && (
              <$DateLabel value={voucherDate} />
            )
          );
        },
      },
      {
        title: t('US.COLLECTION.TRANSACTIONS:REGISTER.DUEDATE'),
        dataIndex: 'dueDate',
        key: 'dueDate',
        className: 'text-nowrap',
        render: (text: any, record: any) => {
          const { dueDate } = record;
          return (
            moment.utc(dueDate).isValid() &&
            moment.utc(dueDate).isAfter(moment.utc(DateFormats.MIN_DATE)) && (
              <$DateLabel value={dueDate} />
            )
          );
        },
      },
      // {
      //   title: t('US.COLLECTION.TRANSACTIONS:REGISTER.INTEREST_FROM_DATE'),
      //   dataIndex: 'interestFromDate',
      //   key: 'interestFromDate',
      //   className: 'text-nowrap',
      //   render: (text: any, record: any) => {
      //     const { interestFromDate } = record;
      //     return (
      //       interestFromDate != null &&
      //       moment.utc(interestFromDate).isValid() && (
      //         <$DateLabel value={interestFromDate} />
      //       )
      //     );
      //   },
      // },
      // {
      //   title: t('US.COLLECTION.TRANSACTIONS:REGISTER.INTEREST_RATE'),
      //   dataIndex: 'interestRate',
      //   key: 'interestRate',
      //   className: 'text-nowrap',
      //   width: 150,
      //   customSorter: (a: any, b: any) => a - b,
      //   customFilter: true,
      //   render: (text: any, record: any) => {
      //     const { transType, interestRate, interestType } = record;
      //     return (
      //       isEnableInterestApplication(transType, transactionTypes) && (
      //         <InterestTypeTag
      //           rate={interestRate}
      //           interestType={interestType}
      //         />
      //       )
      //     );
      //   },
      // },
      {
        title: t('US.COLLECTION.TRANSACTIONS:REGISTER.AMOUNT'),
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',
        className: 'text-nowrap',
        render: (text: any, record: any) => {
          const { amount } = record;
          return <$AmountLabel value={amount} />;
        },
      },
      {
        title: t('US.COLLECTION.TRANSACTIONS:REGISTER.TEXT'),
        dataIndex: 'transText',
        key: 'transText',
        className: 'text-nowrap',
      },
    ];

    /**
     * @description open transaction selection view.
     */
    const openTransactionTypeSelection = () => {
      manageTransactionnDrawer({
        title: t('US.COLLECTION.TRANSACTIONS:REGISTER.TRANSACTION_TYPES'),
        visible: true,
        isNewTransaction: true,
        isDeleteTransaction: false,
        filters: caseDetail,
        width: 1000,
      });
      setTransTypeVisible(true);
    };

    /**
     * @description handle transaction type selection.
     */
    const onSelectTransactionType = (e: any, rest: any) => {
      if (e) {
        rest.setFieldValue('type', e.typeName);
        handleInterestStatus(e.typeName, rest);
      }
      manageTransactionnDrawer({
        title: t('US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REGISTERTRANSACTION'),
        visible: true,
        isNewTransaction: true,
        isDeleteTransaction: false,
        filters: caseDetail,
        width: 1000,
      });
      setTransTypeVisible(false);
    };

    /**
     * @description handle interest applicable status.
     */
    const handleInterestStatus = (typeName: any, rest: any) => {
      const transType = selectedTransactionTypeDetails(
        typeName,
        transactionTypes
      );
      if (transType) {
        rest.setFieldValue('description', transType.description);
      }
    };

    return (
      <Formik
        enableReinitialize
        initialValues={{
          ...transactionDetails,
          cancellationAmount: transactionDetails?.lineBalance.toFixed(2),
          voucherID:
            transactionDetails?.voucherID === 0
              ? ''
              : transactionDetails?.voucherID,
          closedDate,
          isNewTransaction: isNewTransaction,
          isDeleteTransaction: isDeleteTransaction,
          interestRate: standardInterest.data?.interestRate,
        }}
        validateOnChange
        validateOnBlur
        validateOnMount
        validationSchema={ValidationSchema}
        onSubmit={handleTransactionSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
          ...rest
        }: any) => (
          <div>
            {transTypeVisible && (
              <TransactionTypes
                onSelectTransactionType={(e) =>
                  onSelectTransactionType(e, rest)
                }
                enableHeader={true}
              />
            )}
            {!transTypeVisible && (
              <>
                <$Form onFinish={handleSubmit}>
                  {/*Only on transaction ADD*/}
                  {isNewTransaction && (
                    <>
                      <$Row gutter={16}>
                        <$Col span={8}>
                          <div>
                            <div className="ant-col ant-form-item-label">
                              {isNewTransaction && (
                                <div
                                  style={{
                                    marginBottom: '4px',
                                  }}
                                >
                                  <a
                                    onClick={() =>
                                      isNewTransaction &&
                                      openTransactionTypeSelection()
                                    }
                                  >
                                    <span className="required">*</span>
                                    <strong>
                                      {` ${t(
                                        'US.COLLECTION.TRANSACTIONS:REGISTER.TRANSACTIONTYPE'
                                      )}`}
                                    </strong>
                                  </a>
                                </div>
                              )}
                            </div>
                            <$Select
                              placeholder={t(
                                'US.COLLECTION.TRANSACTIONS:REGISTER.INPUTHERE'
                              )}
                              required
                              allOption={false}
                              value={values?.type}
                              name="type"
                              size="small"
                              options={transactionTypeOptions}
                              className="d-flex flex-column"
                              onSearchBy={['typeName', 'description']}
                              optionValue="typeName"
                              optionText="label"
                              onSelect={(typeName: any) => {
                                handleInterestStatus(typeName, rest);
                              }}
                              tabIndex={1}
                            />
                          </div>
                        </$Col>
                        <$Col span={8}>
                          <$Input
                            disabled={!values?.type || !isNewTransaction}
                            size="small"
                            label={t(
                              'US.COLLECTION.TRANSACTIONS:REGISTER.DESCRIPTION'
                            )}
                            value={
                              selectedTransactionTypeDetails(
                                values?.type,
                                transactionTypes
                              )?.description
                            }
                            name="description"
                            required
                            tabIndex={2}
                          />
                        </$Col>
                        <$Col span={8}>
                          <$Input
                            label={t(
                              'US.COLLECTION.TRANSACTIONS:REGISTER.VOUCHERNO'
                            )}
                            name="voucherID"
                            size="small"
                            disabled={
                              !isNewTransaction ||
                              !transactionTypes.find(
                                (transType: any) =>
                                  transType.typeName === values.type
                              )?.isPayment
                            }
                            tabIndex={3}
                          />
                        </$Col>
                      </$Row>

                      <$Row gutter={16}>
                        <$Col span={8}>
                          <$DatePicker
                            label={t(
                              'US.COLLECTION.TRANSACTIONS:REGISTER.VOUCHERDATE'
                            )}
                            name="voucherDate"
                            value={
                              moment(values?.voucherDate).isValid()
                                ? moment(values?.voucherDate)
                                : moment()
                            }
                            disabled={
                              !isNewTransaction &&
                              values.cancellationType == null
                            }
                            placeholder={currentDateFormat}
                            format={currentDateFormat}
                            style={{
                              width: '100%',
                            }}
                            size="small"
                            formProps={{
                              required: true,
                            }}
                            onChange={(date: any) => {
                              rest.setFieldValue(
                                'voucherDate',
                                moment(date, currentDateFormat)
                              );
                              if (isNewTransaction) {
                                rest.setFieldValue(
                                  'dueDate',
                                  moment(date, currentDateFormat).add(
                                    14,
                                    'days'
                                  ),
                                  false
                                );
                                rest.setFieldValue(
                                  'interestFromDate',
                                  moment(date, currentDateFormat).add(
                                    15,
                                    'days'
                                  ),
                                  false
                                );
                              }
                            }}
                          />
                        </$Col>
                        <$Col span={8}>
                          <$Popconfirm
                            title={t(
                              'US.COLLECTION.TRANSACTIONS:REGISTER.DUE_DATE_WARNING'
                            )}
                            placement="topLeft"
                            visible={
                              isNewTransaction &&
                              moment(values?.dueDate) <
                                moment().subtract(3, 'months') &&
                              !confirmedPastDate
                            }
                            onConfirm={() => {
                              setConfirmedPastDate(true);
                            }}
                            onCancel={() => rest.setFieldValue('dueDate', '')}
                            okText={t('US.COMMON:COMMON.YES')}
                            cancelText={t('US.COMMON:COMMON.NO')}
                          >
                            <$DatePicker
                              label={t(
                                'US.COLLECTION.TRANSACTIONS:REGISTER.DUEDATE'
                              )}
                              name="dueDate"
                              value={
                                !moment(values?.dueDate).isSame(
                                  moment(DateFormats.MIN_DATE)
                                )
                                  ? moment(values?.dueDate).isValid()
                                    ? moment(values?.dueDate)
                                    : moment(values?.voucherDate).isValid()
                                    ? moment(values?.voucherDate).add(
                                        14,
                                        'days'
                                      )
                                    : moment().add(14, 'days')
                                  : null
                              }
                              placeholder={currentDateFormat}
                              format={currentDateFormat}
                              style={{
                                width: '100%',
                              }}
                              size="small"
                              formProps={{
                                required: true,
                              }}
                              disabledDate={(current: any) => {
                                return (
                                  current &&
                                  current <
                                    moment(values?.voucherDate).add(14, 'days')
                                );
                              }}
                              tabIndex={5}
                              onChange={(date: any) => {
                                rest.setFieldValue(
                                  'dueDate',
                                  moment(date, currentDateFormat)
                                );
                                if (isNewTransaction) {
                                  rest.setFieldValue(
                                    'interestFromDate',
                                    moment(date, currentDateFormat).add(
                                      1,
                                      'days'
                                    ),
                                    false
                                  );
                                }
                              }}
                            />
                          </$Popconfirm>
                        </$Col>
                        <$Col span={8}>
                          <$InputAmount
                            name="amount"
                            size="small"
                            className="w-100"
                            label={t(
                              'US.COLLECTION.TRANSACTIONS:REGISTER.AMOUNT'
                            )}
                            currentCurrency={currentCurrency}
                            currentLanguage={currentLanguage}
                            value={values?.amount}
                            placeholder={'0.00'}
                            required
                            tabIndex={6}
                          />
                        </$Col>
                      </$Row>

                      <$Row gutter={16}>
                        <$Col span={24}>
                          <$TextArea
                            label={t(
                              'US.COLLECTION.TRANSACTIONS:REGISTER.TEXT'
                            )}
                            name="text"
                            style={{
                              height: '3rem',
                            }}
                            tabIndex={7}
                          />
                        </$Col>
                      </$Row>
                    </>
                  )}

                  {/*Only on transaction DETAILS*/}
                  {!isNewTransaction && (
                    <div className="tei-summery">
                      <$Row gutter={16} className="tei-summery-item">
                        <$Col span={8}>
                          <div className="tei-summery-item-inner">
                            <div className="tei-summery-item-value">
                              {values?.type && <span>{values?.type}</span>}
                              {!values?.type && <span>---</span>}
                            </div>
                            <div className="tei-summery-item-title">
                              {t(
                                'US.COLLECTION.TRANSACTIONS:REGISTER.TRANSACTIONTYPE'
                              )}
                            </div>
                          </div>
                        </$Col>
                        <$Col span={8}>
                          <div className="tei-summery-item-inner">
                            <div className="tei-summery-item-value">
                              {selectedTransactionTypeDetails(
                                values?.type,
                                transactionTypes
                              )?.description && (
                                <span>
                                  {
                                    selectedTransactionTypeDetails(
                                      values?.type,
                                      transactionTypes
                                    )?.description
                                  }
                                </span>
                              )}
                              {!selectedTransactionTypeDetails(
                                values?.type,
                                transactionTypes
                              )?.description && <span>---</span>}
                            </div>
                            <div className="tei-summery-item-title">
                              {t(
                                'US.COLLECTION.TRANSACTIONS:REGISTER.DESCRIPTION'
                              )}
                            </div>
                          </div>
                        </$Col>
                        <$Col span={8}>
                          <div className="tei-summery-item-inner">
                            <div className="tei-summery-item-value">
                              {values.voucherID && (
                                <span>{values.voucherID}</span>
                              )}
                              {!values.voucherID && <span>---</span>}
                            </div>
                            <div className="tei-summery-item-title">
                              {t(
                                'US.COLLECTION.TRANSACTIONS:REGISTER.VOUCHERNO'
                              )}
                            </div>
                          </div>
                        </$Col>
                      </$Row>
                      <$Row gutter={16} className="tei-summery-item mt-3">
                        <$Col span={8}>
                          {!isNewTransaction &&
                            values.cancellationType == null && (
                              <div className="tei-summery-item-inner">
                                <div className="tei-summery-item-value">
                                  {values?.voucherDate && (
                                    <span>
                                      <$DateLabel value={values.voucherDate} />
                                    </span>
                                  )}
                                  {!values?.voucherDate && <span>---</span>}
                                </div>
                                <div className="tei-summery-item-title">
                                  {t(
                                    'US.COLLECTION.TRANSACTIONS:REGISTER.VOUCHERDATE'
                                  )}
                                </div>
                              </div>
                            )}
                          {!isNewTransaction &&
                            values.cancellationType != null && (
                              <$DatePicker
                                label={t(
                                  'US.COLLECTION.TRANSACTIONS:REGISTER.VOUCHERDATE'
                                )}
                                name="voucherDate"
                                value={
                                  moment(values?.voucherDate).isValid()
                                    ? moment(values?.voucherDate)
                                    : moment()
                                }
                                disabled={
                                  !isNewTransaction &&
                                  values.cancellationType == null
                                }
                                placeholder={currentDateFormat}
                                format={currentDateFormat}
                                style={{
                                  width: '100%',
                                  marginTop: '-2px',
                                }}
                                size="small"
                                formProps={{
                                  required: true,
                                }}
                                onChange={(date: any) => {
                                  rest.setFieldValue(
                                    'voucherDate',
                                    moment(date, currentDateFormat)
                                  );
                                  if (isNewTransaction) {
                                    rest.setFieldValue(
                                      'dueDate',
                                      moment(date, currentDateFormat).add(
                                        14,
                                        'days'
                                      ),
                                      false
                                    );
                                    rest.setFieldValue(
                                      'interestFromDate',
                                      moment(date, currentDateFormat).add(
                                        15,
                                        'days'
                                      ),
                                      false
                                    );
                                  }
                                }}
                              />
                            )}
                        </$Col>
                        <$Col span={8}>
                          <div className="tei-summery-item-inner">
                            <div className="tei-summery-item-value">
                              {values?.dueDate && (
                                <span>
                                  <$DateLabel value={values.dueDate} />
                                </span>
                              )}
                              {!values?.dueDate && <span>---</span>}
                            </div>
                            <div className="tei-summery-item-title">
                              {t('US.COLLECTION.TRANSACTIONS:REGISTER.DUEDATE')}
                            </div>
                          </div>
                        </$Col>
                        <$Col span={8}>
                          <div className="tei-summery-item-inner">
                            <div className="tei-summery-item-value">
                              {values?.amount && (
                                <span>
                                  <$AmountLabel value={values.amount} />
                                </span>
                              )}
                              {!values?.amount && <span>---</span>}
                            </div>
                            <div className="tei-summery-item-title">
                              {t('US.COLLECTION.TRANSACTIONS:REGISTER.AMOUNT')}
                            </div>
                          </div>
                        </$Col>
                      </$Row>
                      <$Row gutter={16} className="tei-summery-item mt-3">
                        <$Col span={24}>
                          <div className="tei-summery-item-inner">
                            <div className="tei-summery-item-value">
                              {values?.text && <span>{values?.text}</span>}
                              {!values?.text && <span>---</span>}
                            </div>
                            <div className="tei-summery-item-title">
                              {t('US.COLLECTION.TRANSACTIONS:REGISTER.TEXT')}
                            </div>
                          </div>
                        </$Col>
                      </$Row>
                    </div>
                  )}

                  {/* {isEnableInterestApplication(values.type, transactionTypes) &&
                    isNewTransaction && (
                      <div className="mt-2">
                        <InterestApplication isNewTransaction={true} />
                      </div>
                    )} */}

                  {/*Only on transaction DETAILS and DELETE*/}
                  {!isDeleteTransaction && !isNewTransaction && (
                    <$Row gutter={16} className="mt-4">
                      <$Col span={12}>
                        <$Select
                          formitem={{
                            label: t(
                              'US.COLLECTION.TRANSACTIONS:REGISTER.CANCELLATION_TYPE'
                            ),
                          }}
                          required
                          disabled={
                            isDeleteTransaction ||
                            (!isNewTransaction && values?.balance <= 0)
                          }
                          allOption={false}
                          name="cancellationType"
                          size="small"
                          options={transactionTypeOptions}
                          className="d-flex flex-column"
                          onSearchBy={['typeName', 'description']}
                          optionValue="typeId"
                          optionText="label"
                          onSelect={(typeId: any) => {
                            const transType = transactionTypes.find(
                              (transType: any) => transType.typeId === typeId
                            );
                            rest.setFieldValue(
                              'cancellationTypeDescription',
                              transType?.description
                            );
                            rest.setFieldTouched(
                              'cancellationType',
                              true,
                              true
                            );
                          }}
                          onChange={() => rest.validateForm()}
                          tabIndex={8}
                        />
                      </$Col>
                      <$Col span={12}>
                        <$InputAmount
                          name="cancellationAmount"
                          size="small"
                          label={t(
                            'US.COLLECTION.TRANSACTIONS:REGISTER.CANCELLATION_AMOUNT'
                          )}
                          value={values?.cancellationAmount}
                          currentCurrency={currentCurrency}
                          currentLanguage={currentLanguage}
                          className="w-100"
                          tabIndex={9}
                        />
                      </$Col>
                    </$Row>
                  )}

                  {/*Only on transaction DELETE*/}
                  {isDeleteTransaction && (
                    <$Row gutter={16} className="mt-4">
                      <$Col span={24}>
                        <$TextArea
                          className="w-100 mb-2"
                          name="comment"
                          label={t(
                            'US.COLLECTION.TRANSACTIONS:REGISTER.COMMENT'
                          )}
                          required
                          autoSize={{
                            minRows: 3,
                            maxRows: 6,
                          }}
                          tabIndex={10}
                        />
                      </$Col>
                    </$Row>
                  )}

                  {/*Only on transaction ADD*/}
                  {isNewTransaction && (
                    <$Row gutter={16} className="my-2">
                      <$Col span={24}>
                        <$Divider orientation="left" className="">
                          <$Button
                            onClick={() => addTransaction(values, rest)}
                            type="dashed"
                            size="small"
                            disabled={!rest.isValid || isSubmitting}
                            tabIndex={11}
                          >
                            {t(
                              'US.COLLECTION.TRANSACTIONS:REGISTER.ADD_TO_LIST'
                            )}
                          </$Button>
                        </$Divider>
                      </$Col>
                    </$Row>
                  )}
                </$Form>

                {/*Only on transaction ADD*/}
                {isNewTransaction && (
                  <$Table
                    key={'voucherID'}
                    className="mt-3"
                    bordered
                    size="small"
                    pagination={false}
                    rowKey="Id"
                    columns={columns}
                    dataSource={transactionsData}
                    scroll={{
                      x: 900,
                    }}
                  />
                )}

                <div className="drawer-footer-fixed align-content-center">
                  {!isNewTransaction && values?.balance <= 0 && (
                    <div>
                      <h3 className="pt-1">
                        {`${t(
                          'US.COLLECTION.TRANSACTIONS:REGISTER.TRANSACTIONBALANCEIS'
                        )} `}
                        <$AmountLabel value={values?.balance} />
                      </h3>
                    </div>
                  )}
                  <div className="ml-auto">
                    {/*Only on transaction DELETE*/}
                    {isDeleteTransaction && (
                      <$Button
                        className="mr-2"
                        type="primary"
                        danger
                        disabled={
                          !values.isDeletable || !rest.isValid || isSubmitting
                        }
                        loading={isSubmitting || isValidating}
                        onClick={(e) => handleSubmit(e)}
                        tabIndex={12}
                      >
                        {t('US.COLLECTION.COMMON:COMMON.DELETE')}
                      </$Button>
                    )}

                    {/*Only on transaction ADD and DETAILS*/}
                    {!isDeleteTransaction && (
                      <$Button
                        className="mr-2"
                        type="primary"
                        disabled={
                          (isNewTransaction && transactionsData.length === 0) ||
                          (!isNewTransaction && !rest.isValid) ||
                          (!isNewTransaction && values?.balance <= 0) ||
                          isSubmitting ||
                          isSaving
                        }
                        loading={isSubmitting || isValidating || isSaving}
                        onClick={
                          isNewTransaction
                            ? () => submitNewTransactions()
                            : (e) => handleSubmit(e)
                        }
                        tabIndex={13}
                      >
                        {t('US.COLLECTION.COMMON:COMMON.SAVE')}
                      </$Button>
                    )}

                    <$Button onClick={() => onClose()} tabIndex={14}>
                      {t('US.COLLECTION.COMMON:COMMON.CANCEL')}
                    </$Button>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </Formik>
    );
  }
);

const mapStateToProps = (state: RootState) => {
  const { common, transaction, dashboard, domainView } = state;
  const { currentDateFormat, currentCurrency, currentLanguage } = common;
  const {
    transactionTypes,
    transactionDrawer,
    transactionDetails,
    isSaving,
    interestRateBMD,
    interestApplication,
  } = transaction;
  const { accountSummeryWidgetData } = dashboard;
  const { metaData } = domainView;
  const { standardInterest } = interestApplication;
  return {
    currentCurrency,
    currentLanguage,
    currentDateFormat,
    transactionDrawer,
    transactionDetails,
    transactionTypes,
    accountSummeryWidgetData,
    isSaving,
    metaData,
    interestRateBMD,
    standardInterest,
  };
};

const { standardInterestRate } = interestApplication;

const mapDispatchToProps = {
  getTransactionTypes: getAll,
  saveTransactions: save,
  deleteTransactions: transactions.delete,
  resetTransactionDetails: resetInfo,
  addCreditNote: addCreditNote,
  manageTransactionnDrawer: manageDrawer,
  getStandardInterestRate: standardInterestRate.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TransactionDetails);
