import { Formik } from "formik";
import React, { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as Actions from "us.collection.case/actions";
import Common from "us.common";
import { IAddExtendedField, IeditForm } from "./Interface";
import { IRootState } from "us.collection/interfaces";

const {
  $Input,
  $InputNumber,
  $Button,
  $Divider,
  $SelectGroup,
  $Checkbox,
  $Table,
  $Select,
  $Row,
  $Col,
  $AutoComplete,
  $Form,
  $FormItem,
  $DatePicker,
  $TextArea,
  $Popconfirm,
} = Common.Components;

const AddExtendedField: React.FC<IAddExtendedField> = memo((props) => {
  const { t } = useTranslation(["US.COLLECTION.CASE"]);
  const { currentDateFormat, onClose } = props;

  const [editForm, setEditForm] = useState<IeditForm>({
    ItemDisable: false,
    Name: "",
    DisplayName: "",
    DataType: "",
    Value: null,
  });

  const [newState, setNewState] = useState({
    isString: true,
    isBool: false,
    isDate: false,
    isDecimal: false,
    isInteger: false,
  });

  const [check, setCheck] = useState("False");

  useEffect(() => {}, []);

  const submitNewTransactions = () => {};

  const handleExtendedFieldSubmit = (values: any, actions: any) => {};

  const removeTransactionHandler = (index: any) => {};

  const addTransaction = (values: any, rest: any) => {};

  const openTransactionTypeSelection = () => {
    // manageTransactionnDrawer({
    //   title: "Transaction types",
    //   visible: true,
    //   isNewTransaction: true,
    //   isDeleteTransaction: false,
    //   filters: caseDetail,
    // });
  };

  const onChange = (e: any) => {
    e.preventDefault();
    if (e.target.checked === true) {
      setCheck("True");
    } else {
      setCheck("False");
    }
  };

  const handleDataType = (e: any) => {
    // setNewState({
    //   isString: e === "String",
    //   isBool: e === "Bool",
    //   isDate: e === "Date",
    //   isDecimal: e === "Decimal",
    //   isInteger: e === "Integer",
    // });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      validateOnChange
      validateOnBlur
      validateOnMount
    //   validationSchema={{}}
      onSubmit={handleExtendedFieldSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        ...rest
      }: any) => (
        <$Form onFinish={handleSubmit}>
          <$FormItem label={t("US.COLLECTION.CASE:EXTENDEDFIELD.NAME")}>
            <$Input name="name" size="small" disabled={editForm.ItemDisable} />
          </$FormItem>

          <$FormItem label={t("US.COLLECTION.CASE:EXTENDEDFIELD.DISPLAYNAME")}>
            <$Input
              name="displayName"
              size="small"
              disabled={editForm.ItemDisable}
            />
          </$FormItem>

          <$FormItem label={t("US.COLLECTION.CASE:EXTENDEDFIELD.DATATYPE")}>
            <$SelectGroup
              size="small"
              showSearch
              onChange={(e: any) => handleDataType(e)}
              disabled={editForm.ItemDisable}
            >
              {/* <$Option value="Bool">
                {t("US.COLLECTION.CASE:EXTENDEDFIELD.BOOL")}
              </$Option>
              <$Option value="Date">
                {t("US.COLLECTION.CASE:EXTENDEDFIELD.DATE")}
              </$Option>
              <$Option value="Decimal">
                {t("US.COLLECTION.CASE:EXTENDEDFIELD.DECIMAL")}
              </$Option>
              <$Option value="Integer">
                {t("US.COLLECTION.CASE:EXTENDEDFIELD.INTEGER")}
              </$Option>
              <$Option value="String">
                {t("US.COLLECTION.CASE:EXTENDEDFIELD.STRING")}
              </$Option> */}
            </$SelectGroup>
          </$FormItem>

          {newState.isBool ? (
            <$FormItem label={t("US.COLLECTION.CASE:EXTENDEDFIELD.VALUE")}>
              <$Checkbox
                defaultChecked={false}
                onChange={(e: any) => onChange(e)}
              >
                {check}
              </$Checkbox>
            </$FormItem>
          ) : null}

          {newState.isDate ? (
            <$FormItem label={t("US.COLLECTION.CASE:EXTENDEDFIELD.VALUE")}>
              <$DatePicker
                size="small"
                placeholder={currentDateFormat}
                format={currentDateFormat}
              />
            </$FormItem>
          ) : null}

          {newState.isDecimal ? (
            <$FormItem label={t("US.COLLECTION.CASE:EXTENDEDFIELD.VALUE")}>
              <$InputNumber size="small" step={0.1} />
            </$FormItem>
          ) : null}

          {newState.isInteger ? (
            <$FormItem label={t("US.COLLECTION.CASE:EXTENDEDFIELD.VALUE")}>
              <$InputNumber size="small" />
            </$FormItem>
          ) : null}

          {newState.isString ? (
            <$FormItem label={t("US.COLLECTION.CASE:EXTENDEDFIELD.VALUE")}>
              <$Input name="value" size="small" />
            </$FormItem>
          ) : null}
          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Button type="ghost">
                {t("US.COLLECTION.COMMON:COMMON.DELETE")}
              </$Button>
              <$Divider
                type="vertical"
                style={{ height: "2em" }}
                className="mx-3"
              ></$Divider>
              <$Button className="mr-2" type="primary" htmlType="submit">
                {t("US.COLLECTION.COMMON:COMMON.SAVE")}
              </$Button>
              <$Button onClick={() => onClose()}>
                {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
              </$Button>
            </div>
          </div>
        </$Form>
      )}
    </Formik>
  );
});

const mapStateToProps = (state: IRootState) => {
  const { common, dashboard } = state;
  const { currentDateFormat } = common;
  return { currentDateFormat };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddExtendedField);
