import moment, { Moment } from 'moment';
import { ICaseInformation } from 'us.collection.case/reducers/CaseInformation/Interfaces';
import * as Yup from 'yup';

export const CaseInformationValidator = Yup.object<ICaseInformation>().shape({
    caseDueDate:Yup.string(),
    obsoleteDate: Yup
    .string()
    .when('caseDueDate', (caseDueDate:Moment, schema:any) => {
      return schema.test({
        test: (obsoleteDate:Moment) => moment(obsoleteDate).isAfter(caseDueDate),
        message: 'US.COLLECTION.VALIDATIONS:REQUIRED.MAX_SHOULD_BE_>_MIN'
      })
    }),
    caseDescription:Yup
    .string().max(50,'US.COLLECTION.VALIDATIONS:REQUIRED.CAN_NOT_BE_MORE_THAN_50')
    .nullable()
})