import React, { useEffect, useState } from "react";
import { IIFrameEditor } from "us.collection.case/components/MessageTemplates/Interface";
import { $Button, Pagination } from "us.common/components";
import { useTranslation } from "react-i18next";
import { SaveOutlined } from "us.icons";
import { appInsights } from "us.helper";
import "./IFrameEditor.scss";

const IFrameEditor: React.FC<IIFrameEditor> = (props) => {
  const { t } = useTranslation();
  const {
    content,
    getContent,
    updateTemplateContent,
    tempalateUpdatedContent,
  } = props;
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [htmlContent, setHtmlContent] = useState(
    tempalateUpdatedContent && tempalateUpdatedContent?.length > 0
      ? tempalateUpdatedContent
      : content
  );

  useEffect(() => {
    if (document.getElementById("editor")) {
      setHTMLCode(htmlContent[currentPage]);
    }
  }, [content]);

  /**
   * Add contenteditable attribute for html tag
   * @param htmlStr html string
   * @returns
   */
  const addEditableAttribute = (htmlStr: string): string => {
    try {
      return htmlStr.replace("html", 'html contenteditable="true"');
    } catch (error) {
      // appInsights.trackException(
      //   `IFrameEditor addEditableAttribute Excepation ${error}`
      // );
      return "";
    }
  };

  /**
   * Set html string to iframe
   * @param html -{string} html string
   */
  const setHTMLCode = (html: string) => {
    try {
      const editorId = document.getElementById("editor") as any;
      /**
       * As solution for Cross domain iframe issue , use srcdoc attribute
       */
      editorId.srcdoc = addEditableAttribute(html);
      editorId.src = window.location.origin;
    } catch (error) {
      // appInsights.trackException(
      //   `IFrameEditor setHTMLCode Excepation ${error}`
      // );
    }
  };

  /**
   * Page Navigation
   */
  const onNavigation = (toNext: boolean) => {
    try {
      const scrollWrapper = document.getElementById("mteWrapper") as any;
      scrollWrapper.scrollTop = 0;

      if (toNext) {
        setHTMLCode(htmlContent[currentPage + 1]);
        setCurrentPage(currentPage + 1);
      } else {
        setHTMLCode(htmlContent[currentPage - 1]);
        setCurrentPage(currentPage - 1);
      }
    } catch (error) {
      // appInsights.trackException(
      //   `IFrameEditor onNavigation Excepation ${error}`
      // );
    }
  };

  /**
   * Save iframe content to state
   */
  const saveHTML = () => {
    try {
      const editorId = document.getElementById("editor") as any;
      const innerHTML =
        editorId.contentWindow.document.documentElement.outerHTML;
      const fullContent = [...htmlContent];
      fullContent[currentPage] = innerHTML;
      setHtmlContent(fullContent);
      getContent(fullContent);
      updateTemplateContent && updateTemplateContent(fullContent);
    } catch (error) {
      // appInsights.trackException(`IFrameEditor saveHTML Excepation ${error}`);
    }
  };

  return (
    <div className="mte-ieditor">
      <div id="mteWrapper" className="mte-ieditor-body">
        <iframe
          className="mte-ieditor-iframe"
          id="editor"
          height="1130px"
          width="820"
        />
      </div>
      <div className="mte-ieditor-footer">
        <Pagination
          dataLength={content.length}
          currentPage={currentPage}
          onNavigation={onNavigation}
        />
        <$Button
          className="mte-ieditor-save-btn"
          type="primary"
          onClick={() => saveHTML()}
          size="small"
          icon={<SaveOutlined />}
        >
          {t("US.COLLECTION.COMMON:COMMON.SAVE")}
        </$Button>
      </div>
    </div>
  );
};

export default IFrameEditor;
