import { FollowUpAction } from "us.collection.followup/constants"

export function ExecuteAction(this: any, followupdataIds: Array<string | number>, actionName: string | undefined) {
    const { noteText, postponeDate } = this
    return {
        followupdataIds,
        actionName,
        noteText,
        removeAfterExecution: noteText === FollowUpAction.DONE ? false : true,
        ...(actionName == FollowUpAction.POSTPONE ? { postponeDate } : {})
    }
}