import moment from "moment";

export const handleDateChange = (
    dateValue: string,
    name: string,
    setValues: any,
    currentDateFormat:string
  ) => {
    setValues((preVals: any) => ({
      ...preVals,
      [name]: moment(dateValue, currentDateFormat).isValid() ? moment(dateValue, currentDateFormat) : undefined,
    }));
  };
