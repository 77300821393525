import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import Common from "us.common";
import { workflowAction } from "us.collection/actions";
import { MoveTo, TroubleshootTable } from "./Components";
import { Formik } from "formik";
import { RootState } from "us.helper/types";
import { handleGoBack } from "us.helper/utility";
import {
  ErrorDescription,
  ErrorType,
  troubleshootDrawerInitial,
  TroubleShootSearchType,
} from "./Constants";
import {
  getErrorCountByType,
  getErrorDescription,
  getTotalErrorCount,
} from "./Functions";
import { SearchOutlined } from "us.icons";
import { MonitorList } from "us.collection/repository";
import { useHistory } from "react-router-dom";
import {
  $Affix,
  $Input,
  $InputGroup,
  $PageHeader,
  $Select,
} from "us.common/components";
import "./Home.scss";

const { $Menu, $Skeleton, $Drawer } = Common.Components;

/**
 * @description - The component used in view of troubleshoot
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3156934695/Troubleshoot+View+-+UI
 * @param {PropsFromRedux} props
 * @return {JSX.Element}
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 28/11/2022
 */
const Troubleshoot: React.FC<PropsFromRedux> = memo((props) => {
  const {
    errorCount,
    getErrorCount,
    getMonitorList,
    troubleshootDrawer,
    monitorList,
    openDrawer,
  } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const overLayElement: HTMLElement = document.getElementById(
    "hiddenOverlay"
  ) as HTMLElement;
  
  
  useEffect(() => {
    getErrorCount && getErrorCount({});
    getMonitorList &&
      getMonitorList(
        MonitorList.call({
          offset: 0,
          category: ErrorType.ACTIVITY_EXECUTION_FAILED,
        })
      );
  }, []);

  /**
   * Handle tab change
   * @param restProps Formik props
   * @param errorType Type of error
   */
  const handleChangeTab = (restProps: any, errorType: ErrorType) => {
    restProps?.setValues(
      (preValues: any) => ({
        ...preValues,
        searchText: "",
        searchKey: TroubleShootSearchType.ALL,
        workflowErrorType: errorType ?? "",
        errorDescription:
          getErrorDescription(errorCount?.data, errorType) ?? "",
      }),
      false
    );

    getMonitorList &&
      getMonitorList(
        MonitorList.call({
          offset: 0,
          category: errorType ?? "",
        })
      );
  };

  /**
   * Handle search errors
   * @param values Formik values
   */
  const onSearchErrors = (values: any) => {
    const { searchKey, workflowErrorType } = values ?? {};
    getMonitorList &&
      getMonitorList(
        MonitorList.call({
          offset: 0,
          searchText: values?.searchText,
          searchKey:
            values?.searchText == "" ? TroubleShootSearchType.ALL : searchKey,
          category: workflowErrorType ?? "",
        })
      );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        errorDescription: ErrorDescription.ACTIVITY_EXECUTION_FAILED,
        searchText: "",
        searchKey: TroubleShootSearchType.ALL,
        workflowErrorType: ErrorType.ACTIVITY_EXECUTION_FAILED,
      }}
      onSubmit={() => {}}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        ...rest
      }: any) => (
        <>
          <$Affix offsetTop={80}>
            <div className="page-header header-border">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <$PageHeader
                    className="px-0"
                    title={t(
                      "US.COLLECTION.CASE:WORKFLOWS.WORKFLOWS_TROUBLESHOOT"
                    )}
                    onBack={() => handleGoBack(history)}
                  />
                </div>

                <div className="d-flex align-items-center justify-content-end">
                  <$InputGroup compact className="no-mb-form-item mt-1">
                    <$Select
                      name="searchKey"
                      size="small"
                      options={[
                        {
                          value: TroubleShootSearchType.REFERENCE_ID,
                          label: t("US.COLLECTION.CASE:WORKFLOWS.REFERENCE_ID"),
                        },
                        {
                          value: TroubleShootSearchType.CASE_NO,
                          label: t("US.COLLECTION.CASE:WORKFLOWS.CASE_NO"),
                        },
                      ]}
                      defaultValue="All"
                      optionText="label"
                      optionValue="value"
                      hideSearch={true}
                      style={{ width: "120px" }}
                      className="mt-1"
                      disabled={monitorList?.isLoading}
                      getPopupContainer={() => overLayElement}
                    />
                    <$Input
                      size="small"
                      name="searchText"
                      className="w-100 mb-0"
                      allowClear={true}
                      value={values.searchText}
                      placeholder={t(
                        "US.COLLECTION.CASE:WORKFLOWS.SEARCH_ERRORS"
                      )}
                      addonAfter={
                        <a
                          className="px-2"
                          onClick={() =>
                            !monitorList?.isLoading && onSearchErrors(values)
                          }
                        >
                          <SearchOutlined />
                        </a>
                      }
                      onChange={(e: any) => {
                        rest.setFieldValue("searchText", e.target.value);
                        if (!e.target.value) {
                          rest.setFieldValue(
                            "searchKey",
                            TroubleShootSearchType.ALL
                          );
                          onSearchErrors({ ...values, searchText: "" });
                        }
                      }}
                      onPressEnter={() => onSearchErrors(values)}
                      disabled={monitorList?.isLoading}
                    />
                  </$InputGroup>
                </div>
              </div>
            </div>
          </$Affix>
          <div className="workflows-ts-layout">
            <div>
              <div className="workflows-ts-layout-side">
                <$Skeleton
                  loading={errorCount?.isLoading}
                  active
                  paragraph={{ rows: 2 }}
                  className={errorCount?.isLoading ? "pr-3" : ""}
                >
                  <$Menu
                    defaultSelectedKeys={[ErrorType.ACTIVITY_EXECUTION_FAILED]}
                    className="wts-ant-menu"
                  >
                    <$Menu.Item key="all" disabled={true}>
                      <div className="wts-cat">
                        <strong>
                          {t("US.COLLECTION.CASE:WORKFLOWS.ALL_ERRORS")}
                        </strong>
                      </div>
                      <div className="wts-count wts-count-all">
                        {getTotalErrorCount(errorCount?.data)}
                      </div>
                    </$Menu.Item>
                    <$Menu.Item
                      key={ErrorType.ACTIVITY_EXECUTION_FAILED}
                      onClick={() =>
                        handleChangeTab(
                          rest,
                          ErrorType.ACTIVITY_EXECUTION_FAILED
                        )
                      }
                    >
                      <div className="wts-cat">
                        {getErrorDescription(
                          errorCount?.data,
                          ErrorType.ACTIVITY_EXECUTION_FAILED
                        )}
                      </div>
                      <div className="wts-count wts-count-activityfailed flex-shrink-1">
                        {getErrorCountByType(
                          errorCount?.data,
                          ErrorType.ACTIVITY_EXECUTION_FAILED
                        )}
                      </div>
                    </$Menu.Item>
                    <$Menu.Item
                      key={ErrorType.FAILED_WORKFLOWS}
                      onClick={() =>
                        handleChangeTab(rest, ErrorType.FAILED_WORKFLOWS)
                      }
                    >
                      <div className="wts-cat">
                        {getErrorDescription(
                          errorCount?.data,
                          ErrorType.FAILED_WORKFLOWS
                        )}
                      </div>
                      <div className="wts-count wts-count-failed">
                        {getErrorCountByType(
                          errorCount?.data,
                          ErrorType.FAILED_WORKFLOWS
                        )}
                      </div>
                    </$Menu.Item>
                    <$Menu.Item
                      key={ErrorType.STUCK_WORKFLOWS}
                      onClick={() =>
                        handleChangeTab(rest, ErrorType.STUCK_WORKFLOWS)
                      }
                    >
                      <div className="wts-cat">
                        {getErrorDescription(
                          errorCount?.data,
                          ErrorType.STUCK_WORKFLOWS
                        )}
                      </div>
                      <div className="wts-count wts-count-stuck">
                        {getErrorCountByType(
                          errorCount?.data,
                          ErrorType.STUCK_WORKFLOWS
                        )}
                      </div>
                    </$Menu.Item>
                    <$Menu.Item
                      key={ErrorType.NOT_REGISTERED_IN_WORKFLOW}
                      onClick={() =>
                        handleChangeTab(
                          rest,
                          ErrorType.NOT_REGISTERED_IN_WORKFLOW
                        )
                      }
                    >
                      <div className="wts-cat">
                        {getErrorDescription(
                          errorCount?.data,
                          ErrorType.NOT_REGISTERED_IN_WORKFLOW
                        )}
                      </div>
                      <div className="wts-count wts-count-notregistered">
                        {getErrorCountByType(
                          errorCount?.data,
                          ErrorType.NOT_REGISTERED_IN_WORKFLOW
                        )}
                      </div>
                    </$Menu.Item>
                  </$Menu>
                </$Skeleton>
              </div>
            </div>
            <div className="flex-fill">
              <div className="workflows-ts-layout-content">
                <div>
                  {values?.errorDescription?.length > 0 && (
                    <strong data-testid="troubleshoot-title">
                      {t("US.COLLECTION.CASE:WORKFLOWS.TROUBLESHOOT")} -{" "}
                      {values?.errorDescription}
                    </strong>
                  )}
                </div>
                <TroubleshootTable searchFilters={values} />
              </div>
            </div>
          </div>
          <$Drawer
            title={t(troubleshootDrawer.title)}
            width={500}
            visible={troubleshootDrawer.isVisible}
            onClose={() => openDrawer && openDrawer(troubleshootDrawerInitial)}
            destroyOnClose
          >
            <MoveTo searchFilters={values} />
          </$Drawer>
        </>
      )}
    </Formik>
  );
});

const { errorCount, monitoringList, troubleshootWorkflowRetry } =
  workflowAction;

const mapStateToProps = (state: RootState) => {
  const { Workflow } = state;
  const { errorCount, troubleshootDrawer, monitorList } = Workflow;
  return {
    errorCount,
    monitorList,
    troubleshootDrawer,
  };
};

const mapDispatchToProps = {
  getErrorCount: errorCount.get,
  getMonitorList: monitoringList.get,
  openDrawer: troubleshootWorkflowRetry.openDrawer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Troubleshoot);
