import * as Yup from 'yup';
import { AddTransaction } from '../Interfaces';
import { TransactionGroup } from 'us.collection.transactions/components/Transactions/Constants';
import { InterestType } from 'us.collection.transactions/constants';

export default Yup.object<AddTransaction>().shape({
	type: Yup.string().required(
		'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ENTER_A_VALID_TRANACTION_TYPE'
	),
	description: Yup.string().required(
		'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_DESCRIPTION'
	),
	voucherDate: Yup.date()
		.typeError('US.COLLECTION.VALIDATIONS:INVALID.INVALID_DATE')
		.required('US.COLLECTION.VALIDATIONS:REQUIRED.VOUCHER_DATE_IS_REQUIRED'),
	dueDate: Yup.date()
		.typeError('US.COLLECTION.VALIDATIONS:INVALID.INVALID_DATE')
		.required('US.COLLECTION.VALIDATIONS:REQUIRED.DUE_DATE_IS_REQUIRED'),
	amount: Yup.string()
		.required('US.COLLECTION.VALIDATIONS:REQUIRED.AMOUNT_IS_REQUIRED')
		.test(
			'digit',
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
			(val: any) => {
				let value = typeof val == undefined ? '' : val;
				return (
					/^-?\d{1,3}(,\d{3})*(\.\d{2})?$/.test(value) ||
					/^-?\d*\.?\d*$/.test(value)
				);
			}
		)
		.test(
			'minAmount',
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
			(val: any) => {
				let value = typeof val == undefined ? '' : val;
				return value != '0.00';
			}
		)
		.when('arItemNo', {
			is: (val) => val == 0,
			then: Yup.string().test(
				'minAmount',
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
				(val: any) => {
					let value = typeof val == undefined ? '' : val;
					return value > 0;
				}
			),
		}),
	cancellationType: Yup.number().when(
		['isNewTransaction', 'isDeleteTransaction'],
		{
			is: (isNewTransaction, isDeleteTransaction) =>
				!isNewTransaction && !isDeleteTransaction,
			then: Yup.number().required(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_SELECT_A_CANCELLATION_TYPE'
			),
		}
	),
	comment: Yup.string().when(['isNewTransaction', 'isDeleteTransaction'], {
		is: (isNewTransaction, isDeleteTransaction) =>
			!isNewTransaction && isDeleteTransaction,
		then: Yup.string().required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
		),
	}),
	cancellationAmount: Yup.number().when(
		['balance', 'interestAmount', 'transactionGroupName'],
		(
			balance: any,
			interestAmount: any,
			transactionGroupName: any,
			Yup: any
		) => {
			const cancellationAmount =
				transactionGroupName == TransactionGroup.INTEREST
					? balance.toFixed(2)
					: (balance - interestAmount).toFixed(2);
			return Yup.max(
				cancellationAmount,
				'US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			);
		}
	),
	interestRate: Yup.number()
		.nullable()
		.max(
			100,
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
		)
		.when(['isNewTransaction', 'interestType'], {
			is: (isNewTransaction, interestType) =>
				interestType == InterestType.NO_INTEREST || !isNewTransaction,
			then: Yup.number().notRequired(),
			otherwise: Yup.number()
				.required(
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
				)
				.test(
					'digit',
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
					(val: any) => {
						return val > 0;
					}
				),
		}),
});
