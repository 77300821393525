import { IRealEstateAssets } from "./interfaces";
import moment from "moment";

export function SaveRealEstateAssets(this: IRealEstateAssets) {
  return {
    entityId: this.entityId,
    entityType: this.entityType,
    estimatedValue: this.estimatedValue,
    valuationAmount: this.valuationAmount,
    valuationDate:
      this.valuationDate && moment(this.valuationDate).isValid()
        ? moment(this.valuationDate).format("YYYY-MM-DD")
        : null,
    gnr: this.gnr ? this.gnr : 0,
    bnr: this.bnr ? this.bnr : 0,
    fnr: this.fnr ? this.fnr : 0,
    snr: this.snr ? this.snr : 0,
    description: this.description,
    ownedPart: this.ownedPart,
    address1: this.address1,
    address2: this.address2,
    postalCode: this.postalCode,
    municipalityCode: this.municipalityCode,
    comment: this.comment,
  };
}
