import { SentneceDrawerStatus } from "us.collection.case/constants";
import { ICourtSentenceInitialState } from "us.collection.case/interfaces";

export const CourtSentenceInitialState: ICourtSentenceInitialState = {
  sentenceSummery: {
    data: undefined,
    isError: false,
    isLoading: false,
  },
  sentenceDeleteAtempt: {
    data: undefined,
    isError: false,
    isLoading: false,
  },
  drawerStatus: {
    data: {
      caseNumber: undefined,
      name: "",
      status: SentneceDrawerStatus.NOT_OPENED,
      requestCaseAmounts: false,
      sentenceId: undefined,
      isVisible: false,
    },
    isError: false,
    isLoading: false,
  },
  sentenceDetails: {
    data: undefined,
    isError: false,
    isLoading: false,
  },
  caseAmountDetails: {
    data: undefined,
    isError: false,
    isLoading: false,
  },
  sentenceSaveAtempt: {
    data: undefined,
    isError: false,
    isLoading: false,
  },
  sentenceEditAtempt: {
    data: undefined,
    isError: false,
    isLoading: false,
  },
};
