import COLLECTION_CONSTANTS from 'us.collection/constants';
import constants from '../constants';
import { fetchData } from 'us.helper';

const {
	caselogs,
	assets,
	caseInterest,
	casePropDetails,
	currentStatus,
	partPayments,
	userAgreementDetails,
} = constants.endPoints;

export const getCaseLogs = async (params: any) =>
	fetchData(COLLECTION_CONSTANTS.server + caselogs, 'GET', params);
export const getCurrentStatus = async (params: any) =>
	fetchData(COLLECTION_CONSTANTS.server + currentStatus, 'GET', params);
export const getCaseAssets = async (params: any) =>
	fetchData(COLLECTION_CONSTANTS.server + assets, 'GET', params);
export const getUserAgreementDetails = async (params: any) =>
	fetchData(
		COLLECTION_CONSTANTS.server + userAgreementDetails,
		'GET',
		params
	);
export const getPartPaymentDetail = async (params: any) =>
	fetchData(COLLECTION_CONSTANTS.server + partPayments, 'GET', params);
export const getCasePropDetail = async (params: any) =>
	fetchData(COLLECTION_CONSTANTS.server + casePropDetails, 'GET', params);
export const getCaseInterest = async (params: any) =>
	fetchData(COLLECTION_CONSTANTS.server + caseInterest, 'GET', params);
export const getExtendedFields = async (params: any) =>
	fetchData(
		COLLECTION_CONSTANTS.server +
			COLLECTION_CONSTANTS.endPoints.extendedField
				.extendedFieldList,
		'GET',
		params
	);
export const getEnforcements = async (params: any) =>
	fetchData(
		COLLECTION_CONSTANTS.server +
			COLLECTION_CONSTANTS.endPoints.enforcement.enforcements,
		'GET',
		params
	);
export const getNotesList = async (params: any) =>
	fetchData(
		COLLECTION_CONSTANTS.server +
			COLLECTION_CONSTANTS.endPoints.notes.notesList,
		'GET',
		params
	);
export const getSentencesList = async (params: any) =>
	fetchData(
		COLLECTION_CONSTANTS.server +
			COLLECTION_CONSTANTS.endPoints.sentences.sentencesList,
		'GET',
		params
	);
