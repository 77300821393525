export enum DateFormats {
  REQ = "YYYY-MM-DD",
  NIN = "DDMMYY",
  MIN_DATE = "0001-01-01",
}

export enum OtherPartyTypes {
  PERSON = "P",
  COMPANY = "N"
}

export enum RoleType {
  CONTACT_PERSON = 'contact person'
}