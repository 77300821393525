import Constants from "us.collection.transactions/constants";
import { IAPIAction } from "us.collection/interfaces";

const { ACTIONS } = Constants;
const { Payment } = ACTIONS;

export const PaymentAction: Readonly<IAPIAction> = {
  Payments: {
    get: (data) => ({
      type: Payment.GET_PAYMENTS,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Payment.GET_PAYMENTS_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Payment.GET_PAYMENTS_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
};
