import React from "react";
import { ManageCreditorInformation } from "us.collection.creditor/components/ManageCreditors";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ToggleClass } from "us.helper";

interface ICnt_EditCreditorInfo extends RouteComponentProps {
  caseNo: number;
}

const Cnt_EditCreditorInfo: React.FC<ICnt_EditCreditorInfo> = (props) => {
  const onMinimize = () => {
    props.history.push(`/case/${props.caseNo}`);
  };
  ToggleClass("change-body-color-dom");
  return <ManageCreditorInformation onMinimize={onMinimize} {...props} />;
};

export default withRouter(Cnt_EditCreditorInfo);
