import { takeLatest, put, call, select, take } from "redux-saga/effects";
import * as API from "us.collection.documents/services";
import * as Actions from "us.collection.documents/actions";
import { CaseDocuments } from "us.collection.documents/constants/Actions";
import { getLookUpTypeByCaseType } from "us.collection.documents/functions";
import { $Message } from "us.common/components";

const { caseDocuments } = Actions;

const CaseDocumentsSagas = {
  caseDocuments: {
    get: function* (action: any) {
      const params = action?.payload.data ?? {};
      try {
        const {data, status} = yield call(API.caseDocuments.documents.get, params);
        if (
          !Array.isArray(data) &&
          (data?.code == 400 || data?.code == 404 || data?.errors?.length != 0)
        ) {
          yield put(caseDocuments.documents.fail(data));
          $Message.error(data?.errors[0]);
        }
        else if(status == 204){
          yield put(caseDocuments.documents.fail({}));
        }
        else {
          yield put(caseDocuments.documents.success(data));
        }
      } catch (error) {
        yield put(caseDocuments.documents.fail(error));
      }
    },
    upload: function* (action: any) {
      const params = action?.payload?.data ?? {};
      const fileList = action?.payload?.fileList ?? [];
      try {
        const data = yield call(
          API.caseDocuments.documents.upload,
          fileList,
          params
        );
        const jData = JSON.parse(data);
        if (
          !Array.isArray(jData) &&
          (jData?.code == 400 ||
            jData?.code == 404 ||
            jData?.errors ||
            jData?.isError)
        ) {
          if (caseDocuments.documents.uploadFail) {
            yield put(caseDocuments.documents.uploadFail(jData));
            $Message.error(
              jData?.errors != undefined
                ? jData?.errors[0]
                : jData?.errorMessage
            );
          }
        } else {
          if (caseDocuments.documents.uploadSuccess) {
            yield put(caseDocuments.documents.uploadSuccess(jData));
            $Message.success("Successfully Uploaded.");
            if (caseDocuments.document.manageUploadDrawer) {
              yield put(
                caseDocuments.document.manageUploadDrawer({
                  title: "",
                  visible: false,
                })
              );
            }
            if (caseDocuments.documents.get) {
              yield put(
                caseDocuments.documents.get({
                  entityType: params?.entityType,
                  entityId: params?.entityId,
                })
              );
            }
          }
        }
      } catch (error) {
        if (caseDocuments.documents.uploadFail) {
          yield put(caseDocuments.documents.uploadFail(error));
          $Message.error(error[0]);
        }
      }
    },
  },
  caseDocument: {
    get: function* (action: any) {
      const params = action?.payload.data ?? {};
      try {
        const data = yield call(API.caseDocuments.document.get, params);
        if (data?.code == 400 || data?.code == 404 || data?.errors) {
          yield put(caseDocuments.document.fail(data));
          $Message.error(data?.errors[0]);
        } else {
          yield put(caseDocuments.document.success(data));
        }
      } catch (error) {
        yield put(caseDocuments.document.fail(error));
      }
    },
    delete: function* (action: any) {
      const params = action?.payload.data ?? {};
      try {
        const data = yield call(API.caseDocuments.document.delete, params);
        if (data?.code == 400 || data?.code == 404 || data?.errors) {
          if (caseDocuments.document.deleteFail) {
            yield put(caseDocuments.document.deleteFail(data));
            $Message.error(data?.message);
          }
        } else {
          if (caseDocuments.document.deleteSuccess) {
            yield put(caseDocuments.document.deleteSuccess(data));
            $Message.success(data?.message);
          }
        }
      } catch (error) {
        if (caseDocuments.document.deleteFail) {
          yield put(caseDocuments.document.deleteFail(error));
          $Message.error(error);
        }
      }
    },
  },
  categories: {
    get: function* (action: any) {
      const params = action?.payload.data ?? {};
      try {
        const data = yield call(API.caseDocuments.categories.get, params);
        if (
          data?.code == 400 ||
          data?.code == 404 ||
          data?.statusCode == 404 ||
          data?.errors
        ) {
          yield put(caseDocuments.categories.fail(data));
          $Message.error(data?.errors[0]);
        } else {
          yield put(caseDocuments.categories.success(data));
        }
      } catch (error) {
        yield put(caseDocuments.categories.fail(error));
      }
    },
  },
  rules: {
    get: function* (action: any) {
      const params = action?.payload.data ?? {};
      try {
        const data = yield call(API.caseDocuments.rules.get, params);
        if (data?.code == 400 || data?.code == 404 || data?.errors) {
          yield put(caseDocuments.rules.fail(data));
          $Message.error(data?.errors[0]);
        } else {
          yield put(caseDocuments.rules.success(data));
        }
      } catch (error) {
        yield put(caseDocuments.rules.fail(error));
      }
    },
  },
};

export default [
  takeLatest(
    CaseDocuments.GET_CASE_DOCUMENTS,
    CaseDocumentsSagas.caseDocuments.get
  ),
  takeLatest(
    CaseDocuments.GET_CASE_DOCUMENT_CONTENT,
    CaseDocumentsSagas.caseDocument.get
  ),
  takeLatest(
    CaseDocuments.DELETE_CASE_DOCUMENT,
    CaseDocumentsSagas.caseDocument.delete
  ),
  takeLatest(
    CaseDocuments.UPLOAD_DOCUMENTS,
    CaseDocumentsSagas.caseDocuments.upload
  ),
  takeLatest(
    CaseDocuments.GET_DOCUMENT_CATEGORIES,
    CaseDocumentsSagas.categories.get
  ),
  takeLatest(
    CaseDocuments.GET_UPLOAD_FILE_RULES, 
    CaseDocumentsSagas.rules.get),
];
