import { IExcleColumn } from "us.collection/components/ExcelGenerator/Interface";

export const initialFilter = {
  caseNo: "",
  regDate: "",
  type: "All",
  voucherID: "",
  amount: "",
  invoiceNumber: "",
  kid: "",
  voucherDate: "",
  paymentID: "",
  interestAmount: "",
  totalAmount: "",
  paid: "",
  balance: "",
  description: "",
};

export const excelColumn: Array<IExcleColumn> = [
  {
    title: "US.COLLECTION.TRANSACTIONS:ARHOME.CASE_NO",
    dataIndex: "caseNo",
    format: "Text",
  },
  {
    title: "US.COLLECTION.TRANSACTIONS:ARHOME.REG_DATE",
    dataIndex: "regDate",
    format: "Date",
  },
  {
    title: "US.COLLECTION.TRANSACTIONS:ARHOME.TRANS_TYPE",
    dataIndex: "type",
    format: "Text",
  },
  {
    title: "US.COLLECTION.TRANSACTIONS:ARHOME.INVOICE_NO",
    dataIndex: "invoiceNumber",
    format: "Text",
  },
  {
    title: "US.COLLECTION.TRANSACTIONS:ARHOME.KID",
    dataIndex: "kid",
    format: "Text",
  },
  {
    title: "US.COLLECTION.TRANSACTIONS:ARHOME.VOUCHER_NO",
    dataIndex: "voucherID",
    format: "Text",
  },
  {
    title: "US.COLLECTION.TRANSACTIONS:ARHOME.VOUCHER_DATE",
    dataIndex: "voucherDate",
    format: "Date",
  },
  {
    title: "US.COLLECTION.TRANSACTIONS:ARHOME.PAYMENT_ID",
    dataIndex: "paymentID",
    format: "Text",
    config: { paymentID: "!=-1" },
  },
  {
    title: "US.COLLECTION.TRANSACTIONS:ARHOME.AMOUNT",
    dataIndex: "amount",
    format: "Currency",
  },
  {
    title: "US.COLLECTION.TRANSACTIONS:ARHOME.INTEREST",
    dataIndex: "interestAmount",
    format: "Currency",
  },
  {
    title: "US.COLLECTION.TRANSACTIONS:ARHOME.TOTAL_AMOUNT",
    dataIndex: "totalAmount",
    format: "Currency",
  },
  {
    title: "US.COLLECTION.TRANSACTIONS:ARHOME.PAID",
    dataIndex: "paid",
    format: "Currency",
  },
  {
    title: "US.COLLECTION.TRANSACTIONS:ARHOME.BALANCE",
    dataIndex: "balance",
    format: "Currency",
  },
  {
    title: "US.COLLECTION.TRANSACTIONS:ARHOME.DESCRIPTION",
    dataIndex: "description",
    format: "Text",
  },
];

export enum FilterTypes {
  AR = "AR",
  DB = "DB",
  ARType = "A"
}
export enum TableTypes {
  GROUP = "group",
  LIST = "list"
}