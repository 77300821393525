import * as Yup from 'yup';
import { validatePersonNo } from 'us.collection.case/functions';
import {
	organizationNoValidate,
	personNoValidation,
} from 'us.collection/functions';
import { DEFAULT_COUNTRY_CODE } from 'us.common/constants';

export default () => {
	return Yup.object().shape({
		otherpartyType: Yup.string().required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
		),
		lastName: Yup.string().required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
		),
		country: Yup.string().required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
		),
		emailAddress: Yup.string()
			.email(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.nullable(),
		otherpartyRoleType: Yup.string().when('isCreditorManagement', {
			is: (val) => {
				return !val;
			},
			then: Yup.string()
				.typeError(
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
				)
				.required(
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
				),
			otherwise: Yup.string().notRequired().nullable(),
		}),
		newOtherPartyRoleType: Yup.string().when('isRoleTypeExist', {
			is: (val) => val == true,
			then: Yup.string().test(
				'isRoleTypeExist',
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
				(val: any) => {
					val =
						typeof val == 'undefined'
							? false
							: val;
					return !val;
				}
			),
		}),
		zipCode: Yup.string()
			.typeError(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.when('country', {
				is: (countryCode) =>
					countryCode === DEFAULT_COUNTRY_CODE,
				then: Yup.string().matches(
					/^\d+$/,
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
				),
			}),
		nin: Yup.string().when('otherpartyType', {
			is: (val) => val === 'N',
			then: Yup.string()
				.typeError(
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
				)
				.test(
					'organizationNo',
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
					(val: any) => {
						val =
							typeof val ==
							'undefined'
								? ''
								: val.toString();
						return organizationNoValidate(
							val
						);
					}
				),
			otherwise: Yup.string()
				.typeError(
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
				)
				.test(
					'personNo',
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
					(value: any) => {
						let isValidDate = true;
						let val = value
							?.toString()
							.replaceAll(/\D/g, '');
						if (val) {
							if (isNaN(val)) {
								isValidDate =
									false;
							} else {
								isValidDate =
									validatePersonNo(
										val
									).isValid;
							}
						} else {
							val = '';
						}
						return (
							isValidDate ||
							val?.toString() === ''
						);
					}
				)
				.test(
					'personNo',
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
					(val: any) => {
						val =
							typeof val ==
							'undefined'
								? ''
								: val.toString();
						return personNoValidation(val);
					}
				),
		}),
		smsNo: Yup.string()
			.typeError(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.matches(
				/^\d{4,15}$/g,
				'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS'
			)
			.nullable(),
		telephone: Yup.string()
			.typeError(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.matches(
				/^\d{4,15}$/g,
				'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS'
			)
			.nullable(),
		zipName: Yup.string()
			.nullable()
			.typeError(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
			)
			.when(['zipCode'], {
				is: (zipCode) => {
					zipCode =
						typeof zipCode == 'undefined'
							? ''
							: zipCode;
					return zipCode?.toString().length !== 0;
				},
				then: Yup.string()
					.required(
						'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
					)
					.test(
						'zipNameDigit',
						'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
						(val: any) => {
							let value =
								typeof val ==
								'undefined'
									? ''
									: val;
							return !/^\d+$/.test(
								value
							);
						}
					),
			}),
	});
};
