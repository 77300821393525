/**
 * @description The function `getPaymentId` takes a string parameter `locationState` and returns the third element
 * of the string after splitting it by '/'.
 * @param {string} locationState - The `locationState` parameter is a string that represents the
 * current location state.
 * @returns a string value.
 */
export const getPaymentId = (locationState: string): string => {
	return locationState.split('/')[2];
};
