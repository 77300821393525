import React from "react";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import { $Button, $Radio, $Input, $Tooltip, $Empty } from "us.common/components";
import { PlusOutlined, CloseOutlined } from "us.icons";
import { IReasonHome } from "./Interface";
import { manualFollowUpActions } from "us.collection.case/actions";
import { connect, ConnectedProps } from "react-redux";

const { reasons, reason } = manualFollowUpActions;

const Reason: React.FC<PropsFromRedux & IReasonHome> = (props) => {
  const {
    save,
    updateFormData,
    reasons,
    values,
    getReasons,
    setFieldValue,
    isReasonSaving,
  } = props;
  const { t } = useTranslation();

  const { data, isFetching } = reasons;
  const { category, reasonSearch } = values;

  /**
   *get visibile status for Reason search $Input
   * @returns {boolean} true - if condition is true,, otherwise false
   */
  const getVisibleAddButton = (): boolean => {
    return (
      category.toString().length === 0 ||
      reasonSearch.length === 0 ||
      !(reasonSearch.length != 0 && data.length === 0) ||
      isReasonSaving
    );
  };
  return (
    <>
      <div className="mt-4">
        <div className="d-flex align-items-center justify-content-between">
          <strong>
            <span className="required">*</span>{" "}
            {t("US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.SELECT_REASON")}
          </strong>
        </div>
        <div className="d-flex align-items-top">
          <div className="flex-fill">
            <$Input
              size="small"
              name="reasonSearch"
              placeholder={t(
                "US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.SEARCH_REASON"
              )}
              disabled={category.toString().length === 0}
              onChange={(data: any) => {
                setFieldValue("reasonSearch", data.target.value);
                getReasons &&
                  getReasons({
                    categoryId: category,
                    searchcriteria: data.target.value,
                  });
              }}
              className="w-100"
              allowClear={true}
              dataTestid="reasonSearch-input"
            />
          </div>
          <$Tooltip title={t("US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.ADD_NEW")}>
            <$Button
              style={{ marginTop: 4 }}
              onClick={() => {
                updateFormData && updateFormData({ values });
                save(values);
              }}
              disabled={getVisibleAddButton()}
              type="default"
              size="small"
              icon={<PlusOutlined />}
              data-testid="newReasonBtnId"
            ></$Button>
          </$Tooltip>
        </div>
      </div>
      {data.length != 0 && (
        <$Radio
          className="d-flex flex-fill flex-column mt-3 amf-reason-list"
          optionText="reasonName"
          optionValue="reasonId"
          name="reason"
          optionStyle="radio-st-contnet"
          options={data}
          data-testid="reasonName-radio"
        />
      )}
      {data.length === 0 && !isFetching && (
        <div className="p-3">
          <$Empty
            description={`${t("US.COLLECTION.COMMON:COMMON.NO_DATA")}`}
            image={$Empty.PRESENTED_IMAGE_SIMPLE}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manualFollowUps } = state;
  const { reasons, isReasonSaving } = manualFollowUps;
  return {
    reasons,
    isReasonSaving,
  };
};

const mapDispatchToProps = {
  enableNewReason: reason.openDrawer,
  updateFormData: reason.updateFormData,
  getReasons: reasons.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Reason);
