import { FollowupFooter } from "us.collection/constants";
import { initialState } from "./State";
const {
  GET_FOOTER_FOLLOWUP_NEXT_RESULT_START,
  GET_FOOTER_FOLLOWUP_NEXT_RESULT_SUCCESS,
  GET_FOOTER_FOLLOWUP_NEXT_RESULT_FAIL,
  SET_FOOTER_FOLLOWUP_PREVIOUS_RESULT_START,
  GET_FOOTER_FOLLOWUPS_START,
  GET_FOOTER_FOLLOWUPS_SUCCESS,
  GET_FOOTER_FOLLOWUPS_FAIL,
  SET_FOLLOWUP_CLOSE_BUTTON_VISIBILITY_START,
} = FollowupFooter;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_FOOTER_FOLLOWUP_NEXT_RESULT_START:
      return Object.assign({}, state, {
        nextResult: {
          ...state.nextResult,
          nextResultId: 0,
        },
        isActive: true,
      });
    case GET_FOOTER_FOLLOWUP_NEXT_RESULT_SUCCESS:
      const { followupId, nextEntityId, entityType, nextEntityNumber } =
        payload.data;
      return Object.assign({}, state, {
        nextResult: {
          followupId,
          nextResultId: nextEntityId,
          entityType,
          nextResultEntityId: nextEntityNumber,
        },
      });
    case GET_FOOTER_FOLLOWUP_NEXT_RESULT_FAIL:
      return Object.assign({}, state, {
        nextResult: {
          followupId: 0,
          nextResultId: 0,
          entityType: "",
          nextEntityNumber: 0,
        },
      });
    case SET_FOOTER_FOLLOWUP_PREVIOUS_RESULT_START:
      return Object.assign({}, state, {
        previousResult: {
          previousResultIds: payload?.data,
        },
      });
    case SET_FOLLOWUP_CLOSE_BUTTON_VISIBILITY_START:
      return Object.assign({}, state,
        payload?.data,
      );
    case GET_FOOTER_FOLLOWUPS_START:
      return Object.assign({}, state, {
        followupList: {
          data: [],
          isFetching: true,
        },
      });
    case GET_FOOTER_FOLLOWUPS_SUCCESS:
      return Object.assign({}, state, {
        followupList: {
          data: payload.data,
          isFetching: false,
        },
      });
    case GET_FOOTER_FOLLOWUPS_FAIL:
      return Object.assign({}, state, {
        followupList: {
          data: [],
          isFetching: false,
        },
      });
    default:
      return state;
  }
};
