import { put, call, takeLatest, take } from 'redux-saga/effects';
import * as Actions from 'us.collection.debtor/actions';
import * as API from 'us.collection.debtor/services';
import { $MessageBox } from 'us.common/components';
import { ChangeDebtor } from 'us.collection.debtor/constants/Actions';
import { domainViewAction } from 'us.collection/actions';

const { changeDebtorActions } = Actions;
const { metaData } = domainViewAction;

const debtorSelectionSagas = {
	debtor: {
		change: function* (action: any): any {
			let params = action?.payload?.data ?? {};
			try {
				if (params.isAddDebtor) {
					const { payload } = yield take(
						'ADD_DEBTOR_SUCCESS'
					);
					params = {
						...params,
						newDebtorEntRoleId:
							payload?.data
								?.entRoleId,
					};
				}

				const { data, status } = yield call(
					API.DebtorDetail.save,
					params
				);
				if (status === 200) {
					yield put(
						changeDebtorActions.debtor.success(
							data
						)
					);
					$MessageBox(
						'success',
						`US.COMMON:MESSAGES.DEBTOR_CHANGE_SUCCESSFULLY`,
						'',
						''
					);
					if (metaData.get)
						yield put(
							metaData.get(
								params.metaDataObject
							)
						);
				} else {
					$MessageBox(
						'error',
						`US.COMMON:MESSAGES.DEBTOR_CHANGE_FAIL`,
						'',
						''
					);
					yield put(
						changeDebtorActions.debtor.fail(
							new Error()
						)
					);
				}
			} catch (error) {
				$MessageBox(
					'error',
					`US.COMMON:MESSAGES.DEBTOR_CHANGE_FAIL`,
					'',
					''
				);
				yield put(
					changeDebtorActions.debtor.fail(
						new Error()
					)
				);
			}
		},
	},
};
export default [
	takeLatest(
		ChangeDebtor.CHANGE_DEBTOR_START,
		debtorSelectionSagas.debtor.change
	),
];
