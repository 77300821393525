import React, { useEffect } from "react";
import { Formik, FormikErrors, FormikHelpers, FormikState } from "formik";
import { useTranslation } from "react-i18next";
import {
  $Input,
  $Button,
  $Row,
  $Col,
  $Form,
  $DatePicker,
  $Switch,
  $Select,
  $SelectCreditors,
  ISearchQuery,
  SearchByLabels,
  ExtraColumnsOptions,
} from "us.common/components";
import {
  $MessageBox,
  $Skeleton,
  $TextArea,
  $DynamicComponent,
  $FormItem,
  $Breadcrumb,
  $Divider,
  $TableTree,
  ITableTreeColumns,
} from "us.common/components";
import { BMDActions } from "us.collection/actions";
import * as CommonActions from "us.common/actions";
import { IBMDChangeHistory, IRootState } from "us.collection/interfaces";
import { connect, ConnectedProps } from "react-redux";
import { Moment } from "moment";
import {
  AddBMDPropertyRequestObject,
  GetBMDPropertyDetailsObject,
  ChangePropertyDetailsRequestObject as ChangePropertyDetailsRepo,
  SearchLinkedCreditorsRequestObject,
} from "./Repository";
import { AddBMDPropertySchema } from "./Validations";
import { IAddBMDProperty, IAddProperty } from "./Interfaces";
import {
  doesKeyNameExist,
  getBMDDataTypeValues,
  getInitialValues,
  getTableRenderItems,
  getValueComponent,
  handleAddValueToHisotryButton,
  isSameDate,
  tableClickEventHandler,
} from "./Functions";
import { DrawerTypes } from "us.collection/constants";
import { EntityType, TABLE_ACTIONS } from "../../Constants";
import { PlusOutlined } from "us.icons";
import moment from "moment";
import { ControlButtons } from "./Components";
import { range } from "lodash";
import { ADD_PROPERTY_COLUMNS, TIME_FORMAT } from "./Constants";
import { DynamicComponent } from "us.common/constants";
import { useLocation } from "react-router-dom";
import { IBMDDataType, IBMDListModel } from "us.collection/interfaces";

/**
 * @description - The component used in drawer for adding a BMD and chaning a bmd
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2885681153/UI+Add+BMD+Property
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2895904780/UI+-+Change+BMD+Property+Value
 * @param {IAddProperty & PropsFromRedux} props
 * @return {JSX.Element}
 * @author Darshana Waasala <waasalajb@unicorn-solutions.com>
 * @since 16/03/2022
 */
const AddProperty: React.FC<IAddProperty & PropsFromRedux> = (props) => {
  const {
    bmdDataTypes,
    drawerStatus,
    getDataTypes,
    getDataTypeValuesAction,
    bmdDataTypeValues,
    currentDateFormat,
    addBMDPropertyAction,
    selectedBMDGroupId,
    routeDetails,
    getBMDPropertyDetails,
    bmdPropertyDetails,
    changeBMDPropertyDetails,
    bmdPropertyHistory,
    bmdListData,
    linkedCreditors,
    searchLinkedCreditors,
    selectCreditors,
    selectedCreditors,
    dataIndexIdForCount,
    groupIdForCount
  } = props;

  const { t } = useTranslation(["US.COLLECTION.CASE", "US.COLLECTION.COMMON"]);

  useEffect(() => {
    if (drawerStatus.isVisible && drawerStatus.drawerType !== DrawerTypes.ADD_PROPERTY) {
      getBMDPropertyDetails &&
        getBMDPropertyDetails(GetBMDPropertyDetailsObject.call({...drawerStatus.recordData,dataIndexIdForCount,drawerType:drawerStatus.drawerType,
          groupIdForCount} ?? {}));
    }
    if (drawerStatus.drawerType === DrawerTypes.ADD_PROPERTY) {
      getDataTypes && getDataTypes([]);
    }
    selectCreditors && selectCreditors({ selectedKeys: [] });
  }, [drawerStatus.isVisible]);

  const { state } = useLocation<{ creditorId: string }>();
  const column = (setValues: any): ITableTreeColumns =>
    ADD_PROPERTY_COLUMNS.map((column) => ({
      ...column,
      ...getTableRenderItems(
        column.dataIndex,
        setValues,
        bmdPropertyHistory,
        routeDetails,
        bmdPropertyDetails.data
      ),
      title: column.dataIndex === "more" ? "" : t(`US.COLLECTION.CASE:BMD.${column.title}`),
      key: column.dataIndex,
    }));

  /**
   * @description handle the linked credtor search functionality
   * @param {string} searchText - searching text
   * @param {(SearchByLabels | "All")} criteria - the search criteria
   */
  const handleCreditorSelection = (searchText: string, criteria: SearchByLabels | "All") => {
    searchLinkedCreditors &&
      searchLinkedCreditors(
        SearchLinkedCreditorsRequestObject.call({
          creditorId: state?.creditorId,
          searchText,
          criteria,
        })
      );
  };

  /**
   * @function
   * @description Form submission for Add property and add new property value
   * @param {IAddBMDProperty} values - form values
   * @param {FormikHelpers<IAddBMDProperty>} actions - formik actions
   */
  const handleSubmit = (values: IAddBMDProperty, actions: FormikHelpers<IAddBMDProperty>): void => {
    actions.validateForm();
    if (doesKeyNameExist(bmdListData, values, drawerStatus)) {
      $MessageBox("error", "US.COLLECTION.CASE:BMD.BMD_PROPERTY_CAN_NOT_DUPLICATE", "", "");
    } else if (drawerStatus.drawerType === DrawerTypes.ADD_PROPERTY) {
      const params = AddBMDPropertyRequestObject.call({
        ...values,
        ...routeDetails,
        selectedBMDGroupId,
        resetForm: actions.resetForm,
      });
      addBMDPropertyAction && addBMDPropertyAction(params);
    } else if (drawerStatus.drawerType === DrawerTypes.EDIT_BMD) {
      const params = ChangePropertyDetailsRepo.call({
        ...values,
        ...routeDetails,
        selectedBMDGroupId,
        resetForm: actions.resetForm,
        propertyId: drawerStatus.recordData?.id,
        selectedCreditors,
      });
      changeBMDPropertyDetails && changeBMDPropertyDetails(params);
    }
    else if ( drawerStatus.drawerType === DrawerTypes.BMD_COUNT) {
      const params = ChangePropertyDetailsRepo.call({
        ...values,
        ...routeDetails,
        selectedBMDGroupId,
        resetForm: actions.resetForm,
        propertyId: drawerStatus.recordData?.id,
        entityType:drawerStatus.recordData?.dataIndex,
        entityId:dataIndexIdForCount,
        realEntityId:routeDetails.entityId,
        realEntityType:routeDetails.entityType,
        drawerType:drawerStatus.drawerType
      });
      changeBMDPropertyDetails && changeBMDPropertyDetails(params);
    }
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={getInitialValues(drawerStatus, bmdPropertyDetails, bmdDataTypes)}
      enableReinitialize
      validateOnBlur
      validateOnChange
      validateOnMount
      validationSchema={AddBMDPropertySchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        setValues,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        resetForm,
        ...restProps
      }: {
        values: IAddBMDProperty;
        errors: { [field: string]: string };
        setValues: any;
        handleSubmit: any;
        isSubmitting: boolean;
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
        resetForm: (nextState?: Partial<FormikState<IAddBMDProperty>>) => void;
        validateForm: (values?: IAddBMDProperty) => Promise<FormikErrors<IAddBMDProperty>>;
        setTouched: (fields: { [field: string]: boolean }, shouldValidate?: boolean) => void;
        isValidating: boolean;
        isValid: boolean;
        touched: { [field: string]: boolean };
      }) => (
        <$Skeleton active={true} loading={bmdDataTypes.isLoading || bmdPropertyDetails.isLoading}>
          <$Form layout="vertical" onSubmit={handleSubmit}>
            {values.showCreditorView && (
              <$Breadcrumb className="mb-0">
                <$Breadcrumb.Item onClick={() => setFieldValue("showCreditorView", false, false)}>
                  <a>{t("US.COLLECTION.CASE:BMD.EDIT_BMD")}</a>
                </$Breadcrumb.Item>
                <$Breadcrumb.Item onClick={() => setFieldValue("showCreditorView", true, false)}>
                  {t("US.COLLECTION.CASE:BMD.LINKED_CREDITORS")}
                </$Breadcrumb.Item>
              </$Breadcrumb>
            )}
            {!values.showCreditorView && (
              <>
                <$Row gutter={16} className="mb-2">
                  <$Col span={8}>
                    <$Input
                      label={t("US.COLLECTION.CASE:BMD.BMD_NAME")}
                      name="keyName"
                      size="small"
                      required
                      tabIndex={1}
                      disabled={drawerStatus.drawerType !== DrawerTypes.ADD_PROPERTY}
                    />
                  </$Col>
                  <$Col span={8}>
                    <$Select
                      name="dataTypeId"
                      formitem={{
                        label: t("US.COLLECTION.CASE:BMD.DATA_TYPE"),
                      }}
                      size="small"
                      options={
                        bmdDataTypes.data?.map(({ name, id }) => ({
                          label: name,
                          value: id,
                        })) ?? []
                      }
                      allOption={false}
                      autoFocus={true}
                      tabIndex={2}
                      onSearchBy={["label"]}
                      onSelect={(value) =>
                        setValues(
                          (preValues: IAddBMDProperty) => ({
                            ...preValues,
                            dataTypeId: value,
                            selectedBMDDataType: getBMDDataTypeValues(
                              value,
                              getDataTypeValuesAction,
                              bmdDataTypes.data ?? []
                            ),
                            value: "",
                          }),
                          false
                        )
                      }
                      required
                      disabled={drawerStatus.drawerType !== DrawerTypes.ADD_PROPERTY}
                    />
                  </$Col>
                  <$Col span={8}>
                    <$Input
                      label={t("US.COLLECTION.CASE:BMD.DISPLAY_NAME")}
                      name="displayName"
                      size="small"
                      tabIndex={3}
                    />
                  </$Col>
                </$Row>
                <$Row gutter={16} className="mb-2">
                  <$Col span={8}>
                    <$DynamicComponent
                      name="value"
                      component={getValueComponent(bmdDataTypeValues, values.selectedBMDDataType)}
                      label="VALUE"
                      className="w-100"
                      required
                      tabIndex={4}
                      options={
                        bmdDataTypeValues.data?.map((value: any) => ({
                          label: value,
                          value,
                        })) ?? []
                      }
                      onChange={(e: any) =>
                        setFieldValue(
                          "value",
                          getValueComponent(bmdDataTypeValues, values.selectedBMDDataType) ===
                            DynamicComponent.Input
                            ? e.target.value
                            : e
                        )
                      }
                    />
                  </$Col>
                  <$Col span={8}>
                    {!isSubmitting && (
                      <$DatePicker
                        name="fromDate"
                        size={"small"}
                        label={t("US.COLLECTION.CASE:BMD.DATE/TIME")}
                        placeholder={`${currentDateFormat} ${TIME_FORMAT}`}
                        allowClear={false}
                        value={values.fromDate}
                        format={`${currentDateFormat} ${TIME_FORMAT}`}
                        className="w-100"
                        showTime={{
                          format: `${TIME_FORMAT}`,
                          defaultValue: isSameDate(values.fromDate ?? undefined)
                            ? moment()
                            : moment("00:00", TIME_FORMAT),
                        }}
                        tabIndex={4}
                        onSelect={(date: Moment) => {
                          setFieldValue("fromDate", date, false);
                        }}
                        disabledDate={(day: Moment) => day.isBefore(moment().startOf("day"))}
                        disabledTime={() => ({
                          disabledHours: () =>
                            range(0, 24).splice(
                              0,
                              isSameDate(values.fromDate ?? undefined) ? moment().hours() : 0
                            ),
                          disabledMinutes: (hour: any) =>
                            range(0, 60).splice(
                              0,
                              moment().hours() == hour ? moment().minutes() : 0
                            ),
                        })}
                        required
                      />
                    )}
                  </$Col>
                  <$Col span={8}></$Col>
                </$Row>

                {drawerStatus.drawerType === DrawerTypes.ADD_PROPERTY && (
                  <div>
                    <$TextArea
                      size={"small"}
                      autoSize={{ minRows: 4, maxRows: 6 }}
                      label={t("US.COLLECTION.CASE:BMD.DESCRIPTION")}
                      name="description"
                      tabIndex={10}
                      showCount
                      maxLength={200}
                      required
                    />
                  </div>
                )}

                {routeDetails.entityType === EntityType.BUREAU && (
                  <div>
                    <label className="required mt-3 mb-2 d-block">
                      <span data-testid="select-invoice-label">
                        {t("US.COLLECTION.CASE:BMD.APPLICABLE_LEVELS")}
                      </span>
                    </label>
                    <div className="d-flex mb-4">
                      <div className="d-flex flex-1 align-items-top mr-4">
                        <div className="mr-1">
                          <$Switch
                            name="cgVisibility"
                            checked={values.cgVisibility}
                            tabIndex={6}
                            onChange={(isChecked: any) => {
                              setValues(
                                (preVals: IAddBMDProperty) => ({
                                  ...preVals,
                                  cgVisibility: isChecked,
                                  crVisibility: isChecked && preVals.crVisibility,
                                  arVisibility: isChecked && preVals.arVisibility,
                                  caVisibility: isChecked && preVals.caVisibility,
                                }),
                                false
                              );
                            }}
                          />
                        </div>
                        <div>{t("US.COLLECTION.CASE:BMD.CREDITOR_GROUP")}</div>
                      </div>
                      <div className="d-flex flex-1 align-items-top mr-4">
                        <div className="mr-1">
                          <$Switch
                            name="crVisibility"
                            checked={values.crVisibility}
                            tabIndex={7}
                            onChange={(isChecked: any) =>
                              setValues(
                                (preVals: IAddBMDProperty) => ({
                                  ...preVals,
                                  crVisibility: isChecked,
                                  arVisibility: isChecked && preVals.arVisibility,
                                  caVisibility: isChecked && preVals.caVisibility,
                                }),
                                false
                              )
                            }
                          />
                        </div>
                        <div>{t("US.COLLECTION.CASE:BMD.CREDITOR")}</div>
                      </div>
                      <div className="d-flex flex-1 align-items-top mr-4">
                        <div className="mr-1">
                          <$Switch
                            name="arVisibility"
                            checked={values.arVisibility}
                            tabIndex={8}
                            onChange={(isChecked: any) =>
                              setValues(
                                (preVals: IAddBMDProperty) => ({
                                  ...preVals,
                                  arVisibility: isChecked,
                                  caVisibility: isChecked && preVals.caVisibility,
                                }),
                                false
                              )
                            }
                          />
                        </div>
                        <div>{t("US.COLLECTION.CASE:BMD.AR")}</div>
                      </div>
                      <div className="d-flex flex-1 align-items-top mr-4">
                        <div className="mr-1">
                          <$Switch
                            name="caVisibility"
                            checked={values.caVisibility}
                            tabIndex={9}
                            onChange={(isChecked: any) =>
                              setFieldValue("caVisibility", isChecked, false)
                            }
                          />
                        </div>
                        <div>{t("US.COLLECTION.CASE:BMD.CASE/SUBCASE")}</div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mb-4">
                  <$TextArea
                    autoSize={{ minRows: 4, maxRows: 6 }}
                    label={t("US.COLLECTION.CASE:BMD.COMMENT")}
                    name="comment"
                    size={"small"}
                    tabIndex={5}
                    showCount
                    maxLength={500}
                    required
                  />
                </div>
                {drawerStatus.drawerType !== DrawerTypes.ADD_PROPERTY && (
                  <>
                    <$Divider orientation="left" className="ti-divider">
                      <$Button
                        loading={restProps.isValidating}
                        size="small"
                        type="dashed"
                        icon={<PlusOutlined />}
                        onClick={(e) =>
                          handleAddValueToHisotryButton(
                            restProps.validateForm,
                            values,
                            setValues,
                            restProps.setTouched
                          )
                        }
                      >
                        {t("US.COLLECTION.COMMON:COMMON.ADD")}
                      </$Button>
                    </$Divider>
                    <label className="required d-block mt-5">
                      <span data-testid="select-invoice-label">
                        {t("US.COLLECTION.CASE:BMD.HISTORY")}
                      </span>
                    </label>
                    {restProps.touched["changedHistory"] && errors["changedHistory"] && (
                      <div className="text-error my-2">
                        <strong>{t(errors["changedHistory"])}</strong>
                      </div>
                    )}
                    <div>
                      <$FormItem name="changedHistory">
                        <$TableTree
                          rowKey={(record: any) => record.any}
                          columns={column(setValues)}
                          data={
                            values.changedHistory?.sort((a, b) =>
                              b.dateTime.localeCompare(a.dateTime)
                            ) ?? []
                          }
                          className="mt-2"
                          bordered
                          onRow={(record: IBMDChangeHistory, rowIndex) => {
                            return {
                              onDoubleClick: (event) => {
                                record.id < 0 &&
                                  tableClickEventHandler(
                                    TABLE_ACTIONS.EDIT,
                                    record,
                                    setValues,
                                    bmdPropertyHistory,
                                    routeDetails,
                                    bmdPropertyDetails.data
                                  );
                              },
                            };
                          }}
                        />
                      </$FormItem>
                    </div>
                  </>
                )}
              </>
            )}
            {values.showCreditorView && (
              <$SelectCreditors
                name="creditors"
                groupName="creditorGroups"
                withDrawer={false}
                defaultAllSelectionFilter={true}
                showCreditorGroup={false}
                useReduxProps={false}
                creditorGroups={[]}
                creditors={linkedCreditors.data as any}
                getCreditors={({ searchText, criteria }: ISearchQuery) =>
                  handleCreditorSelection(searchText, criteria)
                }
                isCreditorsLoading={linkedCreditors.isLoading}
                isGroupsLoading={false}
                isInitLoading={linkedCreditors.isLoading}
                removeCreditors={undefined}
                selectCreditors={selectCreditors}
                selectedCreditors={selectedCreditors}
                multiple={true}
                restrictedExtraColumns={[
                  ExtraColumnsOptions.MASTER_CREDITOR_ID,
                  ExtraColumnsOptions.MASTER_CREDITOR_NAME,
                ]}
              />
            )}
            <ControlButtons
              routeDetails={routeDetails}
              isCreditorViewShown={values.showCreditorView}
            />
          </$Form>
        </$Skeleton>
      )}
    </Formik>
  );
};

const {
  bmdDataTypes,
  bmdDataTypeValues,
  bmdProperty,
  bmdPropertyDetails,
  changeBMDPropertyDetails,
  bmdPropertyHistory,
  linkedCreditors,
} = BMDActions;
const { creditors } = CommonActions.creditorSelectionActions;

const mapStateToProps = (state: IRootState) => {
  const { bmd, common, creditorSelection } = state;
  const {
    bmdDataTypes,
    drawerStatus,
    bmdDataTypeValues,
    addBmdProperty,
    bmdPropertyDetails,
    historyDelete,
    bmdList,
    linkedCreditors,
  } = bmd;
  const { currentDateFormat } = common;
  const { creditors } = creditorSelection;

  const bmdD: IBMDListModel<IBMDDataType[]> = bmdDataTypes;

  return {
    bmdDataTypes: bmdD,
    drawerStatus,
    bmdDataTypeValues,
    currentDateFormat,
    addBmdProperty,
    bmdPropertyDetails,
    historyDelete,
    bmdListData: bmdList.data,
    linkedCreditors,
    selectedCreditors: creditors.selected,
  };
};

const mapDispatchToProps = {
  getDataTypes: bmdDataTypes.get,
  changeDrawerStatus: bmdDataTypes.openDrawer,
  getDataTypeValuesAction: bmdDataTypeValues.get,
  addBMDPropertyAction: bmdProperty.save,
  getBMDPropertyDetails: bmdPropertyDetails.get,
  changeBMDPropertyDetails: changeBMDPropertyDetails.update,
  bmdPropertyHistory: bmdPropertyHistory.delete,
  searchLinkedCreditors: linkedCreditors.search,
  selectCreditors: creditors.select,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddProperty);
