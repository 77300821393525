import { IDropDown } from "../Interfaces";
import { CaseStateOptions } from "./CaseStateOptions";

/**
 * @constant
 * @description - The constants holding the case dropdown values
 * @type {IDropDown<CaseStateOptions>[]} - Case State options
 */
export const CASE_STATE_DROP_DOWN: IDropDown<CaseStateOptions>[] = [
  {
    label: "ALL",
    value: CaseStateOptions.ALL,
  },
  {
    label: "OPEN",
    value: CaseStateOptions.OPEN,
  },
  {
    label: "CLOSED",
    value: CaseStateOptions.CLOSED,
  },
];
