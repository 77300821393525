import { call, put, takeLatest } from "redux-saga/effects";
import * as API_ from "us.collection.case/services";
import API from "us.common/services";
import { AssetsAction } from "us.collection.case/actions";
import Constants from "us.collection.case/constants";
import { Notification } from "us.common/components";
import { $Message, $MessageBox } from "us.common/components";

const { ACTIONS } = Constants;
const { Assets } = ACTIONS;

const AssetsSagas = {
  get: function* (action: any) {
    const params = action.payload.data as any;
    try {
      const data = yield call(
        API_.Assets.assets.get,
        params.entityId,
        params.entityType
      );
      yield put(AssetsAction.Assets.success(data));
    } catch (error) {
      yield put(AssetsAction.Assets.fail(error));
    }
  },
  saveRealEstateAssets: function* (action: any) {
    const params = action.payload.data as any;
    try {
      const data = yield call(API_.Assets.saveRealEstateAssets, params);
      $MessageBox(
        "success",
        "US.COLLECTION.CASE:ASSETS.ASSET_CREATING_SUCCESSFULLY",
        "",
        ""
      );
      yield put(AssetsAction.AddRealEstateAssets.success(data));
      const getData = yield call(
        API_.Assets.assets.get,
        params.entityId,
        params.entityType
      );
      yield put(AssetsAction.Assets.success(getData));
    } catch (error) {
      $MessageBox(
        "error",
        "US.COLLECTION.CASE:ASSETS.ERROR_IN_CREATING_ASSET",
        "",
        ""
      );
      yield put(AssetsAction.AddRealEstateAssets.fail(error));
    }
  },
  delete: function* (action: any) {
    const params = action.payload.data as any;
    try {
      const data = yield call(
        API_.Assets.assets.delete,
        params.assetId,
        params.assetType
      );
      yield put(AssetsAction.DeleteAssets.success(data));
      if (!data?.isError) {
        $MessageBox(
          "success",
          "US.COLLECTION.CASE:ASSETS.ASSET_DELETED_SUCCESSFULLY",
          "",
          ""
        );
      }
      const asstesData = yield call(
        API_.Assets.assets.get,
        params.entityId,
        params.entityType
      );
      yield put(AssetsAction.Assets.success(asstesData));
    } catch (error) {
      yield put(AssetsAction.DeleteAssets.fail(error as any));
      $Message.error(error?.errors[0]?.message);
    }
  },

  getRealEstateAssets: function* (action: any) {
    const assetId = action.payload.data as any;
    try {
      const data = yield call(API_.Assets.getRealEstateAssets, assetId);
      yield put(AssetsAction.RealEstateAssets.success(data));
    } catch (error) {
      yield put(AssetsAction.RealEstateAssets.fail(error));
    }
  },
  UpdateRealEstateAssets: function* (action: any) {
    const params = action.payload.data as any;
    try {
      const data = yield call(API_.Assets.UpdateRealEstateAssets, params);
      $MessageBox(
        "success",
        "US.COLLECTION.CASE:ASSETS.ASSET_UPDATED_SUCCESSFULLY",
        "",
        ""
      );
      yield put(AssetsAction.UpdateRealEstateAssets.success(data));
      const getData = yield call(
        API_.Assets.assets.get,
        params.entityId,
        params.entityType
      );
      yield put(AssetsAction.Assets.success(getData));
    } catch (error) {
      $MessageBox(
        "error",
        "US.COLLECTION.CASE:ASSETS.ERROR_IN_CREATING_ASSET",
        "",
        ""
      );
      yield put(AssetsAction.UpdateRealEstateAssets.fail(error));
    }
  },
  savePersonalEffectAssets: function* (action: any) {
    const params = action.payload.data as any;
    try {
      const data = yield call(API_.Assets.savePersonalEffectsAssets, params);

      $MessageBox(
        "success",
        "US.COLLECTION.CASE:ASSETS.ASSET_CREATING_SUCCESSFULLY",
        "",
        ""
      );
      yield put(AssetsAction.AddPersonalEffectAssets.success(data));
      const getData = yield call(
        API_.Assets.assets.get,
        params.entityId,
        params.entityType
      );
      yield put(AssetsAction.Assets.success(getData));
    } catch (error) {
      $MessageBox(
        "error",
        "US.COLLECTION.CASE:ASSETS.ERROR_IN_CREATING_ASSET",
        "",
        ""
      );
      yield put(AssetsAction.AddPersonalEffectAssets.fail(error));
    }
  },
  getPersonalEffectsAssets: function* (action: any) {
    const assetId = action.payload.data as any;
    try {
      const data = yield call(API_.Assets.getPersonalEffectsAssets, assetId);
      yield put(AssetsAction.PersonalEffectsAssets.success(data));
    } catch (error) {
      yield put(AssetsAction.PersonalEffectsAssets.fail(error));
    }
  },
  UpdatePersonalEffetsAssets: function* (action: any) {
    const params = action.payload.data as any;
    try {
      const data = yield call(API_.Assets.UpdatePersonalEffetsAssets, params);
      $MessageBox(
        "success",
        "US.COLLECTION.CASE:ASSETS.ASSET_UPDATED_SUCCESSFULLY",
        "",
        ""
      );
      yield put(AssetsAction.UpdatePersonalEffectsAssets.success(data));
      const getData = yield call(
        API_.Assets.assets.get,
        params.entityId,
        params.entityType
      );
      yield put(AssetsAction.Assets.success(getData));
    } catch (error) {
      $MessageBox(
        "error",
        "US.COLLECTION.CASE:ASSETS.ERROR_IN_CREATING_ASSET",
        "",
        ""
      );
      yield put(AssetsAction.UpdatePersonalEffectsAssets.fail(error));
    }
  },
  saveDocumentOfTitleAssets: function* (action: any) {
    const params = action.payload.data as any;
    try {
      const data = yield call(API_.Assets.saveDocumentOfTitleAssets, params);
      $MessageBox(
        "success",
        "US.COLLECTION.CASE:ASSETS.ASSET_CREATING_SUCCESSFULLY",
        "",
        ""
      );
      yield put(AssetsAction.AddDocumentOfTitleAssets.success(data));
      const getData = yield call(
        API_.Assets.assets.get,
        params.entityId,
        params.entityType
      );
      yield put(AssetsAction.Assets.success(getData));
    } catch (error) {
      $MessageBox(
        "error",
        "US.COLLECTION.CASE:ASSETS.ERROR_IN_CREATING_ASSET",
        "",
        ""
      );
      yield put(AssetsAction.AddDocumentOfTitleAssets.fail(error));
    }
  },
  getDocumentOfTitleAssets: function* (action: any) {
    const assetId = action.payload.data as any;
    try {
      const data = yield call(API_.Assets.getDocumentOfTitleAssets, assetId);
      yield put(AssetsAction.DocumentOfTitleAssets.success(data));
    } catch (error) {
      yield put(AssetsAction.DocumentOfTitleAssets.fail(error));
    }
  },
  UpdateDocumentOfTitleAssets: function* (action: any) {
    const params = action.payload.data as any;
    try {
      const data = yield call(API_.Assets.UpdateDocumentOfTitleAssets, params);
      $MessageBox(
        "success",
        "US.COLLECTION.CASE:ASSETS.ASSET_UPDATED_SUCCESSFULLY",
        "",
        ""
      );
      yield put(AssetsAction.UpdateDocumentOfTitleAssets.success(data));
      const getData = yield call(
        API_.Assets.assets.get,
        params.entityId,
        params.entityType
      );
      yield put(AssetsAction.Assets.success(getData));
    } catch (error) {
      $MessageBox(
        "error",
        "US.COLLECTION.CASE:ASSETS.ERROR_IN_CREATING_ASSET",
        "",
        ""
      );
      yield put(AssetsAction.UpdateDocumentOfTitleAssets.fail(error));
    }
  },
  saveVehicleAssets: function* (action: any) {
    const params = action.payload.data as any;
    try {
      const data = yield call(API_.Assets.saveVehicleAssets, params);
      $MessageBox(
        "success",
        "US.COLLECTION.CASE:ASSETS.ASSET_CREATING_SUCCESSFULLY",
        "",
        ""
      );
      yield put(AssetsAction.AddVehicleAssets.success(data));
      const getData = yield call(
        API_.Assets.assets.get,
        params.entityId,
        params.entityType
      );
      yield put(AssetsAction.Assets.success(getData));
    } catch (error) {
      $MessageBox(
        "error",
        "US.COLLECTION.CASE:ASSETS.ERROR_IN_CREATING_ASSET",
        "",
        ""
      );
      yield put(AssetsAction.AddVehicleAssets.fail(error));
    }
  },
  getVehicleAssets: function* (action: any) {
    const assetId = action.payload.data as any;
    try {
      const data = yield call(API_.Assets.getVehicleAssets, assetId);
      yield put(AssetsAction.VehicleAssets.success(data));
    } catch (error) {
      yield put(AssetsAction.VehicleAssets.fail(error));
    }
  },
  UpdateVehicleAssets: function* (action: any) {
    const params = action.payload.data as any;
    try {
      const data = yield call(API_.Assets.UpdateVehicleAssets, params);
      $MessageBox(
        "success",
        "US.COLLECTION.CASE:ASSETS.ASSET_UPDATED_SUCCESSFULLY",
        "",
        ""
      );
      yield put(AssetsAction.UpdateVehicleAssets.success(data));
      const getData = yield call(
        API_.Assets.assets.get,
        params.entityId,
        params.entityType
      );
      yield put(AssetsAction.Assets.success(getData));
    } catch (error) {
      $MessageBox(
        "error",
        "US.COLLECTION.CASE:ASSETS.ERROR_IN_CREATING_ASSET",
        "",
        ""
      );
      yield put(AssetsAction.UpdateVehicleAssets.fail(error));
    }
  },
  saveFinancialAssets: function* (action: any) {
    const params = action.payload.data as any;
    try {
      const data = yield call(API_.Assets.saveFinancialAssets, params);
      $MessageBox(
        "success",
        "US.COLLECTION.CASE:ASSETS.ASSET_CREATING_SUCCESSFULLY",
        "",
        ""
      );
      yield put(AssetsAction.AddFinancialAssets.success(data));
      const getData = yield call(
        API_.Assets.assets.get,
        params.entityId,
        params.entityType
      );
      yield put(AssetsAction.Assets.success(getData));
    } catch (error) {
      $MessageBox(
        "error",
        "US.COLLECTION.CASE:ASSETS.ERROR_IN_CREATING_ASSET",
        "",
        ""
      );
      yield put(AssetsAction.AddFinancialAssets.fail(error));
    }
  },
  getFinancialAssets: function* (action: any) {
    const assetId = action.payload.data as any;
    try {
      const data = yield call(API_.Assets.getFinancialAssets, assetId);
      yield put(AssetsAction.FinancialAssets.success(data));
    } catch (error) {
      yield put(AssetsAction.FinancialAssets.fail(error));
    }
  },
  updateFinancialAssets: function* (action: any) {
    const params = action.payload.data as any;
    try {
      const data = yield call(API_.Assets.updateFinancialAssets, params);
      $MessageBox(
        "success",
        "US.COLLECTION.CASE:ASSETS.ASSET_UPDATED_SUCESSFULLY",
        "",
        ""
      );
      yield put(AssetsAction.UpdateFinancialAssets.success(data));
      const getData = yield call(
        API_.Assets.assets.get,
        params.entityId,
        params.entityType
      );
      yield put(AssetsAction.Assets.success(getData));
    } catch (error) {
      $MessageBox(
        "error",
        "US.COLLECTION.CASE:ASSETS.ERROR_IN_CREATING_ASSET",
        "",
        ""
      );
      yield put(AssetsAction.UpdateFinancialAssets.fail(error));
    }
  },

  getPostalDetails: function* (action: any) {
    const params = action.payload.data as any;
    try {
      const data = yield call(API.entityDetails.getPostelArea, params);
      yield put(AssetsAction.postalDetails.success(data));
    } catch (error) {
      yield put(AssetsAction.postalDetails.fail(error as any));
    }
  },
};

export default [
  takeLatest(Assets.GET_ASSET, AssetsSagas.get),
  takeLatest(Assets.SAVE_REAL_ESTATE_ASSETS, AssetsSagas.saveRealEstateAssets),
  takeLatest(Assets.DELETE_ASSET, AssetsSagas.delete),
  takeLatest(Assets.GET_REAL_ESTATE_ASSET, AssetsSagas.getRealEstateAssets),
  takeLatest(
    Assets.UPDATE_REAL_ESTATE_ASSET,
    AssetsSagas.UpdateRealEstateAssets
  ),
  takeLatest(
    Assets.SAVE_PERSONAL_EFFECTS_ASSETS,
    AssetsSagas.savePersonalEffectAssets
  ),
  takeLatest(
    Assets.GET_PERSONAL_EFFECTS_ASSET,
    AssetsSagas.getPersonalEffectsAssets
  ),
  takeLatest(
    Assets.UPDATE_PERSONAL_EFFECTS_ASSET,
    AssetsSagas.UpdatePersonalEffetsAssets
  ),
  takeLatest(
    Assets.SAVE_DOCUMENT_OF_TITLE_ASSETS,
    AssetsSagas.saveDocumentOfTitleAssets
  ),
  takeLatest(
    Assets.GET_DOCUMENT_OF_TITLE_ASSET,
    AssetsSagas.getDocumentOfTitleAssets
  ),
  takeLatest(
    Assets.UPDATE_DOCUMENT_OF_TITLE_ASSET,
    AssetsSagas.UpdateDocumentOfTitleAssets
  ),
  takeLatest(Assets.SAVE_VEHICLE_ASSETS, AssetsSagas.saveVehicleAssets),
  takeLatest(Assets.GET_VEHICLE_ASSET, AssetsSagas.getVehicleAssets),
  takeLatest(Assets.UPDATE_VEHICLE_ASSET, AssetsSagas.UpdateVehicleAssets),
  takeLatest(Assets.SAVE_FINANCIAL_ASSETS, AssetsSagas.saveFinancialAssets),
  takeLatest(Assets.GET_FINANCIAL_ASSET, AssetsSagas.getFinancialAssets),
  takeLatest(Assets.UPDATE_FINANCIAL_ASSET, AssetsSagas.updateFinancialAssets),
  takeLatest(Assets.GET_POSTAL_DETAILS, AssetsSagas.getPostalDetails),
];
