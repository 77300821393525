import { getISODateString } from "../Functions";
import { IAddBMDPropertyRequestObject } from "../Interfaces";


/**
 * @function
 * @description - get data object required for Add BMD property API request at the saga
 * @param {*} this - parameter object
 * @return {*}  {IAddBMDPropertyRequestObject} - the object required to get the API call done
 */
export function AddBMDPropertyRequestObject(this: any):IAddBMDPropertyRequestObject {
  return {
    requestObject: {
      propertyId: this.propertyId,
      bmdGroupId: this.selectedBMDGroupId,
      keyName: this.keyName,
      dataTypeId: this.dataTypeId,
      displayName: this.displayName,
      value: this.value,
      fromDate: getISODateString(this.fromDate),
      cgVisibility: this.cgVisibility,
      crVisibility: this.crVisibility,
      arVisibility: this.arVisibility,
      caVisibility: this.caVisibility,
      comment: this.comment,
      description: this.description,
    },
    isSaveNew: this.isSaveNewClicked ? true : false,
    resetForm:this.resetForm,
    routeProps:{
        entityId: this.entityId,
        entityType: this.entityType,
    }
  };
}
