import React from "react";
import { ToggleClass } from "us.helper";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Workflow } from "us.collection/components";

interface ICnt_Workflow extends RouteComponentProps {}

const Cnt_Workflow: React.FC<ICnt_Workflow> = (props) => {
  ToggleClass("change-body-color-dom");
  return <Workflow />;
};

export default withRouter(Cnt_Workflow);
