import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Common from 'us.common';
import { Formik } from 'formik';
import { AssetsAction } from 'us.collection.case/actions';
import VehicleSchema from './validations';
import { SaveVehicleAssets } from './SaveVehicleAssets';
import { UpdateVehicleAssets } from './UpdateVehicleAssets';
import { IMetaData } from 'us.collection/interfaces';
import * as Action from 'us.common/actions';
import { IDataModel } from 'us.collection.case/reducers/Dashboard/Interfaces';
import { RootState } from 'us.helper/types';

const {
	$Input,
	$DatePicker,
	$Form,
	$Button,
	$Col,
	$Row,
	$Skeleton,
	$TextArea,
	$Popconfirm,
	$InputAmount,
} = Common.Components;

interface IVehicle {
	asset?: any;
	isEditing: boolean;
	onSearchPostaCode?(): void;
	postalCodeDetails?: any;
	showOnly?: boolean;
}

interface IBase extends IVehicle {
	postalAreas: any;
	getPostalAreas: any;
	currentLanguage: string;
	currentCurrency: string;
	currentDateFormat: string;
	metaData: IDataModel<IMetaData>;
	addVehicleAssets: any;
	changeDrawerInfo: any;
	drawerInfo: any;
	getVehicleAssets: any;
	realEstateAssets: any;
	resetRealEstateAssets: any;
	updateVehicleAssets: any;
	isREFetching: boolean;
	drawerName: any;
}

const Vehicle: React.FC<IBase> = (props) => {
	const { t } = useTranslation(['US.COLLECTION.CASE'], {
		useSuspense: true,
	});
	const {
		currentLanguage,
		currentCurrency,
		currentDateFormat,
		metaData,
		addVehicleAssets,
		changeDrawerInfo,
		drawerInfo,
		getVehicleAssets,
		realEstateAssets,
		resetRealEstateAssets,
		updateVehicleAssets,
		isREFetching,
		showOnly,
	} = props;

	const { debtorEntNumber } = metaData.data ?? {};

	const descriptionTextAreaRef = React.useRef<any>();

	useEffect(() => {
		descriptionFocus();
	}, []);

	useEffect(() => {
		if (drawerInfo?.isEdit) {
			getVehicleAssets(drawerInfo?.assetId);
		}
	}, [drawerInfo?.isEdit]);

	const descriptionFocus = () => {
		typeof descriptionTextAreaRef?.current !== 'undefined' &&
			descriptionTextAreaRef.current.focus();
	};

	const handleCancel = (resetForm: any) => {
		changeDrawerInfo({
			title: '',
			visible: false,
		});
		resetForm();
		resetRealEstateAssets();
	};

	return (
		<Formik
			initialValues={{ ...realEstateAssets }}
			enableReinitialize
			validationSchema={VehicleSchema}
			onSubmit={(values: any, actions: any) => {
				actions.setSubmitting(true);
				if (!drawerInfo?.isEdit) {
					let entityInfo = {
						entityId: debtorEntNumber,
						entityType: 'DEB',
					};
					let payload = SaveVehicleAssets.call({
						...values,
						...entityInfo,
					});
					addVehicleAssets(payload);
				} else {
					let entityInfo = {
						entityId: debtorEntNumber,
						entityType: 'DEB',
						assetId: drawerInfo?.assetId,
					};
					let payload = UpdateVehicleAssets.call({
						...values,
						...entityInfo,
					});
					updateVehicleAssets(payload);
				}
				changeDrawerInfo({
					title: '',
					visible: false,
				});
				resetRealEstateAssets();
				actions.resetForm();
			}}
			validateOnChange
			validateOnBlur>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...restProps
			}: any) => (
				<>
					<$Form>
						<$Skeleton
							loading={
								isREFetching &&
								drawerInfo?.isEdit
							}
							active
							paragraph={{ rows: 2 }}>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={24}>
									<$TextArea
										name='description'
										autoSize={{
											minRows: 2,
											maxRows: 4,
										}}
										size='small'
										label={t(
											'US.COLLECTION.CASE:VEHICLE.DESCRIPTION/TYPE'
										)}
										required
										ref={
											descriptionTextAreaRef
										}
										tabIndex={
											1
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
							</$Row>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={12}>
									<$Input
										size='small'
										label={t(
											'US.COLLECTION.CASE:VEHICLE.REGISTRATIONNUMBER'
										)}
										name='registrationNo'
										required
										tabIndex={
											2
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
								<$Col span={6}>
									<$InputAmount
										size='small'
										className='w-100'
										label={t(
											'US.COLLECTION.CASE:VEHICLE.ESTIMATEDVALUE'
										)}
										name='estimatedValue'
										currentLanguage={
											currentLanguage
										}
										currentCurrency={
											currentCurrency
										}
										value={
											values?.estimatedValue
										}
										tabIndex={
											5
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
								<$Col span={6}>
									<$InputAmount
										size='small'
										className='w-100'
										label={t(
											'US.COLLECTION.CASE:VEHICLE.VALUATION'
										)}
										name='valuationAmount'
										currentLanguage={
											currentLanguage
										}
										currentCurrency={
											currentCurrency
										}
										value={
											values?.valuationAmount
										}
										tabIndex={
											6
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
							</$Row>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={12}>
									<$Input
										size='small'
										label={t(
											'US.COLLECTION.CASE:VEHICLE.YEAR/MODEL'
										)}
										name='yearOrModel'
										tabIndex={
											3
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
								<$Col span={6}>
									<$DatePicker
										label={t(
											'US.COLLECTION.CASE:VEHICLE.VALUATIONDATE'
										)}
										name='valuationDate'
										size='small'
										style={{
											width: '100%',
										}}
										format={
											currentDateFormat
										}
										value={
											values?.valuationDate &&
											moment(
												values?.valuationDate
											).isValid()
												? moment(
														values?.valuationDate
												  )
												: undefined
										}
										allowClear
										tabIndex={
											7
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
							</$Row>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={12}>
									<$TextArea
										name='comment'
										autoSize={{
											minRows: 4,
											maxRows: 4,
										}}
										size='small'
										label={t(
											'US.COLLECTION.COMMON:COMMON.COMMENT'
										)}
										// required
										tabIndex={
											4
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
							</$Row>

							{!showOnly && (
								<div className='drawer-footer-fixed align-content-center justify-content-end'>
									<div>
										<$Button
											className='mr-2'
											disabled={
												!restProps.isValid ||
												!restProps.dirty
											}
											//loading={isSubmitting || isValidating}
											onClick={(
												e: any
											) =>
												handleSubmit(
													e
												)
											}
											type='primary'>
											{drawerInfo?.isEdit
												? t(
														'US.COLLECTION.COMMON:COMMON.UPDATE'
												  )
												: t(
														'US.COLLECTION.COMMON:COMMON.SAVE'
												  )}
										</$Button>
										{restProps.dirty && (
											<$Popconfirm
												title={t(
													'US.COLLECTION.COMMON:COMMON.CANCEL_ERROR'
												)}
												placement='topLeft'
												onConfirm={() =>
													handleCancel(
														resetForm
													)
												}
												okText={t(
													'US.COLLECTION.COMMON:COMMON.YES'
												)}
												cancelText={t(
													'US.COLLECTION.COMMON:COMMON.NO'
												)}>
												<$Button>
													{t(
														'US.COLLECTION.COMMON:COMMON.CANCEL'
													)}
												</$Button>
											</$Popconfirm>
										)}
										{!restProps.dirty && (
											<$Button
												onClick={() =>
													handleCancel(
														resetForm
													)
												}>
												{t(
													'US.COLLECTION.COMMON:COMMON.CANCEL'
												)}
											</$Button>
										)}
									</div>
								</div>
							)}
						</$Skeleton>
					</$Form>
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, domainView, entityDetails, assets } = state;
	const { currentLanguage, currentDateFormat, currentCurrency } = common;
	const { metaData } = domainView;
	const { postalAreas, drawerName } = entityDetails;
	const { drawerInfo, realEstateAssets, isREFetching } = assets;

	return {
		currentLanguage,
		currentDateFormat,
		currentCurrency,
		metaData,
		postalAreas,
		drawerInfo,
		realEstateAssets,
		isREFetching,
		drawerName,
	};
};

const mapDispatchToProps = {
	getPostalAreas: Action.entity.getPostalAreas,
	addVehicleAssets: AssetsAction.AddVehicleAssets.save,
	changeDrawerInfo: AssetsAction.AddRealEstateAssets.openDrawer,
	getVehicleAssets: AssetsAction.VehicleAssets.get,
	resetRealEstateAssets: AssetsAction.RealEstateAssets.reset,
	updateVehicleAssets: AssetsAction.UpdateVehicleAssets.update,
};

export default connect(mapStateToProps, mapDispatchToProps)(Vehicle);
