import { CaseState } from "us.collection.transactions/constants";
import { ICase, ISubCase } from "us.collection.transactions/interfaces";
import { appInsights } from "us.helper";
import { CaseStateOptions, CaseTypeOptions } from "../Constants";

/**
 * @function
 * @description - Determine if the case item should be included
 * @param {ISubCase} caseItem - Iterating Case
 * @param {CaseStateOptions} drawerCaseState - Case state selected in the drawer
 * @param {CaseTypeOptions} drawerCaseType - Case type selected in the drawer
 * @return {*}  {boolean} - decition if the case item should be included or not
 */
const shouldIncludeItem = (
  { parentId, caseState }: ISubCase,
  drawerCaseState: CaseStateOptions,
  drawerCaseType: CaseTypeOptions
): boolean => {
  const isOpened = caseState === CaseState.OPEN || caseState === CaseState.NOT_SPECIFIED;
  const isClosed = caseState === CaseState.CLOSE || caseState === CaseState.NOT_SPECIFIED;

  if (drawerCaseState === CaseStateOptions.ALL) {
    if (drawerCaseType === CaseTypeOptions.ALL) {
      return true;
    } else {
      return drawerCaseType === CaseTypeOptions.CASE ? parentId > 0 : parentId === 0;
    }
  } else if (drawerCaseState === CaseStateOptions.OPEN) {
    if (drawerCaseType === CaseTypeOptions.ALL) {
      return isOpened;
    } else {
      return drawerCaseType === CaseTypeOptions.CASE
        ? parentId > 0 && isOpened
        : parentId === 0 && isOpened;
    }
  } else {
    //drawerCaseState === CaseStateOptions.CLOSE
    if (drawerCaseType === CaseTypeOptions.ALL) {
      return isClosed;
    } else {
      return drawerCaseType === CaseTypeOptions.CASE
        ? parentId > 0 && isClosed
        : parentId === 0 && isClosed;
    }
  }
};

/**
 * @function
 * @description - To filget the case by state drawer and case type drawer
 * @param {CaseStateOptions} [drawerCaseState=CaseStateOptions.ALL] - Case State value selected in the drop down
 * @param {CaseTypeOptions} [drawerCaseType=CaseTypeOptions.ALL] - Case Type value selected in the drop down
 * @param {ICase[]} caseList - the case list from the API
 * @return {*}  {ICase[]} - the filtered case list
 */
export const filterByCaseState = (
  drawerCaseState: CaseStateOptions = CaseStateOptions.ALL,
  drawerCaseType: CaseTypeOptions = CaseTypeOptions.ALL,
  caseList: ICase[]
): ICase[] => {
  let filteredChildren: ISubCase[];
  try {
    return caseList?.flatMap((caseItem) => {
      if (shouldIncludeItem(caseItem, drawerCaseState, drawerCaseType)) {
        if (caseItem.parentId === 0) {
          filteredChildren = caseItem.children.filter((subCase) => {
            if (drawerCaseState === CaseStateOptions.ALL) {
              return true;
            } else {
              return drawerCaseState === CaseStateOptions.OPEN
                ? subCase.caseState === CaseState.OPEN
                : subCase.caseState === CaseState.CLOSE;
            }
          });
        } else {
          filteredChildren = caseItem.children;
        }
        if (caseItem.parentId === 0) {
          return [
            {
              ...caseItem,
              mainAmount: filteredChildren.reduce((previousValue, { mainAmount }) => previousValue + mainAmount,0),
              collectionFee: filteredChildren.reduce((previousValue, { collectionFee }) => previousValue + collectionFee,0),
              courtFee: filteredChildren.reduce((previousValue, { courtFee }) => previousValue + courtFee,0),
              otherCost: filteredChildren.reduce((previousValue, { otherCost }) => previousValue + otherCost,0),
              interestAmount: filteredChildren.reduce((previousValue, { interestAmount }) => previousValue + interestAmount,0),
              caseCost: filteredChildren.reduce((previousValue, { caseCost }) => previousValue + caseCost,0),
              balance: filteredChildren.reduce((previousValue, { balance }) => previousValue + balance,0),
              paidAmount: filteredChildren.reduce((previousValue, { paidAmount }) => previousValue + paidAmount,0),
              children: filteredChildren,
            },
          ];
        } else {
          return [{ ...caseItem }];
        }
      } else {
        return [];
      }
    });
  } catch (error) {
    appInsights.trackException(`Error in case and subcases filterByCaseState funciton. - ${error}`);
    return [];
  }
};
