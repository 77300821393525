import React from "react";
import Common from "us.common";
import { WorkflowType } from "us.collection/constants";
import { useTranslation } from "react-i18next";
import { IWorkflowTag } from "us.collection/components/Workflow/Interfaces";
import { getTagStatusTitle } from "us.collection/components/Workflow/Functions";
import "us.collection/components/Workflow/Home.scss";

const { $Tooltip, $Avatar } = Common.Components;

/**
 * @description - Workflow type tag for states and events
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3116466232/Manage+Workflow+Events+-+UI
 * @param {PropsFromRedux} props
 * @return {JSX.Element}
 * @author Ishan Udyoga
 * @since 06/06/2023
 */
const WorkflowTag: React.FC<IWorkflowTag> = (props) => {
  const { t } = useTranslation();

  const { isEvent, activeStatus, workflowType } = props;
  const tagClass = isEvent ? "we" : "swg";
  return (
    <$Tooltip
      placement="topLeft"
      title={t(getTagStatusTitle(activeStatus, workflowType, isEvent))}
    >
      <$Avatar
        shape="square"
        size="small"
        className={
          activeStatus
            ? `${tagClass}-item-tag ${tagClass}-item-tag-active`
            : `${tagClass}-item-tag`
        }
      >
        {workflowType == WorkflowType.CASE ? "Ca" : "In"}
      </$Avatar>
    </$Tooltip>
  );
};

export default WorkflowTag;
