export enum ManualFollowUp {
	GET_MANUAL_FOLLOW_UP_USERS_START = 'GET_MANUAL_FOLLOW_UP_USERS_START',
	GET_MANUAL_FOLLOW_UP_USERS_SUCCESS = 'GET_MANUAL_FOLLOW_UP_USERS_SUCCESS',
	GET_MANUAL_FOLLOW_UP_USERS_FAIL = 'GET_MANUAL_FOLLOW_UP_USERS_FAIL',

	GET_MANUAL_FOLLOW_UP_REASONS_START = 'GET_MANUAL_FOLLOW_UP_REASONS_START',
	GET_MANUAL_FOLLOW_UP_REASONS_SUCCESS = 'GET_MANUAL_FOLLOW_UP_REASONS_SUCCESS',
	GET_MANUAL_FOLLOW_UP_REASONS_FAIL = 'GET_MANUAL_FOLLOW_UP_REASONS_FAIL',

	SAVE_MANUAL_FOLLOW_UP_REASONS_START = 'SAVE_MANUAL_FOLLOW_UP_REASONS_START',
	SAVE_MANUAL_FOLLOW_UP_REASONS_SUCCESS = 'SAVE_MANUAL_FOLLOW_UP_REASONS_SUCCESS',
	SAVE_MANUAL_FOLLOW_UP_REASONS_FAIL = 'SAVE_MANUAL_FOLLOW_UP_REASONS_FAIL',

	GET_MANUAL_FOLLOW_UP_CATEGORIES_START = 'GET_MANUAL_FOLLOW_UP_CATEGORIES_START',
	GET_MANUAL_FOLLOW_UP_CATEGORIES_SUCCESS = 'GET_MANUAL_FOLLOW_UP_CATEGORIES_SUCCESS',
	GET_MANUAL_FOLLOW_UP_CATEGORIES_FAIL = 'GET_MANUAL_FOLLOW_UP_CATEGORIES_FAIL',

	SAVE_MANUAL_FOLLOW_UP_START = 'SAVE_MANUAL_FOLLOW_UP_START',
	SAVE_MANUAL_FOLLOW_UP_SUCCESS = 'SAVE_MANUAL_FOLLOW_UP_SUCCESS',
	SAVE_MANUAL_FOLLOW_UP_FAIL = 'SAVE_MANUAL_FOLLOW_UP_FAIL',

	GET_MANUAL_FOLLOW_UPS_START = 'GET_MANUAL_FOLLOW_UPS_START',
	GET_MANUAL_FOLLOW_UPS_SUCCESS = 'GET_MANUAL_FOLLOW_UPS_SUCCESS',
	GET_MANUAL_FOLLOW_UPS_FAIL = 'GET_MANUAL_FOLLOW_UPS_FAIL',

	DONE_OR_REMOVE_MANUAL_FOLLOW_UP_START = 'DONE_OR_REMOVE_MANUAL_FOLLOW_UP_START',
	DONE_OR_REMOVE_MANUAL_FOLLOW_UP_SUCCESS = 'DONE_OR_REMOVE_MANUAL_FOLLOW_UP_SUCCESS',
	DONE_OR_REMOVE_MANUAL_FOLLOW_UP_FAIL = 'DONE_OR_REMOVE_MANUAL_FOLLOW_UP_FAIL',

	ENABLE_NEW_REASON_START = 'ENABLE_NEW_REASON',

	UPDATE_REASON_FORM_START = 'UPDATE_REASON_FORM_START',

	RESET_REASON_FORM = 'RESET_REASON_FORM',

	INIT_MANUAL_FOLLOWUP = 'INIT_MANUAL_FOLLOWUP',

	GET_FOLLOW_UP_SUMMARY = 'GET_FOLLOW_UP_SUMMARY',
	GET_FOLLOW_UP_SUMMARY_SUCCESS = 'GET_FOLLOW_UP_SUMMARY_SUCCESS',
	GET_FOLLOW_UP_SUMMARY_FAIL = 'GET_FOLLOW_UP_SUMMARY_FAIL',
}
