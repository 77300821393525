import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Formik } from "formik";
import { followUps } from "us.collection.followup/actions";
import { RootState } from "us.helper/types";
import { $Button, $DatePicker, $TextArea } from "us.common/components";
import moment from "moment";
import { RemoveAndDoneValidation, PostponeValidation } from "./Validation";
import { FollowUpActionReq } from "us.collection.followup/repository";
import { FollowUpUniqueCol } from "us.collection.followup/constants";
import { FollowUpAction } from "us.common/components/FollowUps/Constants";
import { IMoreAction } from "./Interfaces";
import { getConfirmationMessage } from "../../../../Functions";
import { useParams } from "react-router-dom";

const { action } = followUps;

/**
 * @description - Followup actions (Done/Remove/Postpone)
 * @link Design Document -
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 02/10/2023
 */

const MoreAction: React.FC<PropsFromRedux & IMoreAction> = (props) => {
  const { t } = useTranslation();

  const { followUpId } = useParams<{ followUpId?: string }>();

  const {
    currentDateFormat,
    onClose,
    executeFollowUpAction,
    record,
    actionType,
    result
  } = props;

  const { data } = result;
  const { followupName } = data ?? {};

  /**
   * Handle done, remove, postpone action
   * @param values - Form values
   */
  const handleAction = (values: any) => {
    const { noteText, postponeDate } = values ?? {};
    const request = FollowUpActionReq.call({
      key: record[FollowUpUniqueCol.FOLLOWUP_ID],
      action: actionType,
      comment: noteText,
      postponeDate: postponeDate,
      followupName: followupName
    });
    executeFollowUpAction && executeFollowUpAction(request, { followUpId });
    onClose();
  };

  return (
    <Formik
      initialValues={{
        noteText: "",
        postponeDate: "",
        actionType: "",
      }}
      onSubmit={handleAction}
      enableReinitialize
      validationSchema={
        actionType == FollowUpAction.POSTPONE ? PostponeValidation : RemoveAndDoneValidation
      }
    >
      {({ values, handleSubmit }: any) => (
        <div className="flc-popup-postpone">
          <div className="mb-2"><strong>{t(getConfirmationMessage(actionType))}</strong></div>
          <div className="mb-3">
            <$TextArea
              autoSize={{ minRows: 5, maxRows: 5 }}
              size="small"
              label={t("US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.COMMENT")}
              className="w-100"
              name="noteText"
            />
          </div>
          {actionType == FollowUpAction.POSTPONE && (
            <div className="mb-3">
              <$DatePicker
                name="postponeDate"
                allowClear={true}
                size="small"
                format={currentDateFormat}
                label={t("US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.POSTPONE_DATE")}
                className="w-100 mb-3"
                value={values.postponeDate}
                disabledDate={(date: any) =>
                  !date || date.isBefore(moment().add(1, "day").startOf("day"))
                }
              />
            </div>
          )}
          <div className="d-flex justify-content-end">
            <$Button
              size="small"
              className="mr-2"
              htmlType="submit"
              type="primary"
              onClick={handleSubmit}
              danger={actionType == FollowUpAction.REMOVE}
            >
              {t(`US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.${actionType?.toUpperCase()}`)}
            </$Button>
            <$Button size="small" onClick={() => onClose()}>
              {t("US.COMMON:COMMON.CANCEL")}
            </$Button>
          </div>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  const { common, followUps } = state;
  const { currentDateFormat } = common;
  const { result } = followUps;

  return { currentDateFormat, result };
};

const mapDispatchToProps = {
  executeFollowUpAction: action.execute,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MoreAction);
