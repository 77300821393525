import { IColumn } from "../Interfaces";

export const COLUMNS: IColumn[] = [
  {
    title: "US.COLLECTION.CASE:BMD.NAME",
    dataIndex: "name",
    customSorter: (a: any, b: any) => a.localeCompare(b),
    customFilter: true,
    ellipsis: true,
    width: "25%",
  },
  {
    title: "US.COLLECTION.CASE:BMD.DESCRIPTION",
    dataIndex: "description",
    customSorter: (a: any, b: any) => a.localeCompare(b),
    customFilter: true,
    ellipsis: true,
    width: "37%",
  },
  {
    title: "US.COLLECTION.CASE:BMD.VALUE_RETRIEVING_SP_NAME",
    dataIndex: "storedProcedure",
    customSorter: (a: any, b: any) => a.localeCompare(b),
    customFilter: true,
    ellipsis: true,
    width: "38%",
  },
];
