import moment, { Moment } from "moment";
import { appInsights } from "us.helper";

/**
 * @function
 * @description - To get the ISO8601 representation of the date
 * @param {(Moment | string | undefined)} date - date in either type Moment, string or undefined
 * @return {*}  {(string | null)} - ISO8601 representation of the date if it's a valid date
 */
export const getISODateString = (date: Moment | string | undefined): string | null => {
  try {
    if (!date) {
      return null;
    } else if (moment.isMoment(date)) {
      return date.utc().format();
    } else if (typeof date === "string" && moment(date, moment.ISO_8601, true).isValid()) {
      return moment(date).utc().format();
    } else {
      return null;
    }
  } catch (error) {
    appInsights.trackException(`Error in add BMD getISODateString function. - ${error}`);
    return null;
  }
};
