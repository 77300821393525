import { Debtor } from "us.collection.debtor/constants/Actions";
import {
  initialState,
  debtorHistory,
  addressDetail,
  caseList,
  otherInformation,
} from "us.collection.debtor/reducers/Debtor/State";
import _ from "lodash";

const {
  GET_ADDRESS_INFO_START,
  GET_ADDRESS_INFO_SUCCESS,
  GET_ADDRESS_INFO_FAIL,
  GET_CREDITOR_INFO_START,
  GET_CREDITOR_INFO_SUCCESS,
  GET_CREDITOR_INFO_FAIL,
  GET_DEBTOR_HISTORY_START,
  GET_DEBTOR_HISTORY_SUCCESS,
  GET_DEBTOR_HISTORY_FAIL,
  GET_DEBTOR_OTHER_INFORMATION_START,
  GET_DEBTOR_OTHER_INFORMATION_SUCCESS,
  GET_DEBTOR_OTHER_INFORMATION_FAIL,
  GET_DEBTOR_CASE_INFORMATION_START,
  GET_DEBTOR_CASE_INFORMATION_SUCCESS,
  GET_DEBTOR_CASE_INFORMATION_FAIL,
  GET_DEBTOR_BADDEBT_INFO,
  GET_DEBTOR_BADDEBT_INFO_SUCCESS,
  GET_DEBTOR_BADDEBT_INFO_FAIL,
  GET_IF_DEBTOR_IS_BAD,
  GET_IF_DEBTOR_IS_BAD_SUCCESS,
  GET_IF_DEBTOR_IS_BAD_FAIL,
  GET_DEBTOR_CREDIT_INFO,
  GET_DEBTOR_CREDIT_INFO_SUCCESS,
  GET_DEBTOR_CREDIT_INFO_FAIL,
  GET_DEBTOR_POSTALCODE_START,
  GET_DEBTOR_POSTALCODE_SUCCESS,
  GET_DEBTOR_POSTALCODE_FAIL,
  GET_DEBTOR_MUNICIPALITY_START,
  GET_DEBTOR_MUNICIPALITY_SUCCESS,
  GET_DEBTOR_MUNICIPALITY_FAIL,
  CLEAR_POSTAL_DETAILS,
  SAVE_DEBTOR_DETAIL_START,
  SAVE_DEBTOR_DETAIL_SUCCESS,
  SAVE_DEBTOR_DETAIL_FAIL,
} = Debtor;

export default (state: any = initialState, { payload, type }: any) => {
  // let newState = state;

  switch (type) {
    case GET_ADDRESS_INFO_START:
      return Object.assign({}, state, {
        address: {
          data: [],
          isFetching: true,
        },
      });

    case GET_ADDRESS_INFO_SUCCESS:
      return Object.assign({}, state, {
        address: {
          data: payload?.data,
          isFetching: false,
        },
      });

    case GET_ADDRESS_INFO_FAIL:
      return Object.assign({}, state, {
        address: {
          data: addressDetail,
          isFetching: false,
        },
      });

    case GET_CREDITOR_INFO_START:
      return Object.assign({}, state, {
        creditor: {
          data: [],
          isFetching: true,
        },
      });

    case GET_CREDITOR_INFO_SUCCESS:
      return Object.assign({}, state, {
        creditor: {
          data: payload?.data,
          isFetching: false,
        },
      });

    case GET_CREDITOR_INFO_FAIL:
      return Object.assign({}, state, {
        creditor: {
          data: [],
          isFetching: false,
        },
      });

    case GET_DEBTOR_HISTORY_START:
      return Object.assign({}, state, {
        history: {
          data: [],
          isFetching: true,
        },
      });

    case GET_DEBTOR_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        history: {
          data: payload?.data,
          isFetching: false,
        },
      });

    case GET_DEBTOR_HISTORY_FAIL:
      return Object.assign({}, state, {
        history: {
          data: [],
          isFetching: false,
        },
      });

    case GET_DEBTOR_OTHER_INFORMATION_START:
      return Object.assign({}, state, {
        other: {
          ...state.other,
          isFetching: true,
        },
      });

    case GET_DEBTOR_OTHER_INFORMATION_SUCCESS:
      return Object.assign({}, state, {
        other: {
          data: payload?.data[0],
          isFetching: false,
        },
      });

    case GET_DEBTOR_OTHER_INFORMATION_FAIL:
      return Object.assign({}, state, {
        other: {
          ...state.other,
          isFetching: false,
        },
      });

    case GET_DEBTOR_CASE_INFORMATION_START:
      return Object.assign({}, state, {
        case: {
          data: [],
          isFetching: true,
        },
      });

    case GET_DEBTOR_CASE_INFORMATION_SUCCESS:
      // let data = []
      // data = action.payload?.data.map((item: any) => ({ ...item, key: item.caseNo }))
      return Object.assign({}, state, {
        case: {
          data: payload?.data,
          isFetching: false,
        },
      });

    case GET_DEBTOR_CASE_INFORMATION_FAIL:
      // let data = []
      // data = action.payload?.data.map((item: any) => ({ ...item, key: item.caseNo }))
      return Object.assign({}, state, {
        case: {
          data: caseList,
          isFetching: false,
        },
      });

    case GET_DEBTOR_BADDEBT_INFO_SUCCESS:
      return Object.assign({}, state, {
        badDebt: {
          ...state.badDebt,
          data:
            payload.data && payload.data.length > 0
              ? payload.data.map((x: any, index: number) => {
                return { ...x, key: index + 1, settlementTypeString: x.settlementType ? x.settlementType : '' };
              })
              : [],
          isFetching: false,
        },
      });

    case GET_IF_DEBTOR_IS_BAD_SUCCESS:
      return Object.assign({}, state, {
        badDebt: {
          ...state.badDebt,
          isBadDebtor: payload?.data,
          isFetching: false,
        },
      });

    case GET_DEBTOR_CREDIT_INFO:
      return Object.assign({}, state, {
        credit: {
          data: {},
          isFetching: true,
        },
      });

    case GET_DEBTOR_CREDIT_INFO_SUCCESS:
      return Object.assign({}, state, {
        credit: {
          data: payload?.data,
          isFetching: false,
        },
      });

    case GET_DEBTOR_CREDIT_INFO_FAIL:
      return Object.assign({}, state, {
        credit: {
          data: {},
          isFetching: false,
        },
      });
    // case GET_DEBTOR_POSTALCODE_START:
    //   return Object.assign({}, state, {
    //     postalcode: {
    //       data: [],
    //       isLoading: true,
    //       isResponse: false
    //     },
    //   });
    // case GET_DEBTOR_POSTALCODE_SUCCESS:
    //   return Object.assign({}, state, {
    //     postalcode: {
    //       data: payload?.data,
    //       isLoading: false,
    //       isResponse: true
    //     },
    //   });
    // case GET_DEBTOR_POSTALCODE_FAIL:
    //   return Object.assign({}, state, {
    //     postalcode: {
    //       data: [],
    //       isLoading: false,
    //       isResponse: true
    //     },
    //   });

    // case GET_DEBTOR_MUNICIPALITY_START:
    //   return Object.assign({}, state, {
    //     municipility: {
    //       data: [],
    //       isLoading: true,
    //       isResponse: false
    //     },
    //   });
    // case GET_DEBTOR_MUNICIPALITY_SUCCESS:
    //   return Object.assign({}, state, {
    //     municipility: {
    //       data: payload?.data,
    //       isLoading: false,
    //       isResponse: true
    //     },
    //   });
    // case GET_DEBTOR_MUNICIPALITY_FAIL:
    //   return Object.assign({}, state, {
    //     municipility: {
    //       data: [],
    //       isLoading: false,
    //       isResponse: true
    //     },
    //   });
    // case CLEAR_POSTAL_DETAILS:
    //   return Object.assign({}, state, {
    //     postalcode: {
    //       data: [],
    //       isLoading: false,
    //       isResponse: false
    //     },
    //     municipility: {
    //       data: [],
    //       isLoading: false,
    //       isResponse: false
    //     },
    //   })
    case SAVE_DEBTOR_DETAIL_START:
      return Object.assign({}, state, {
        isSaving: true,
      });
    case SAVE_DEBTOR_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isSaving: false,
      });
    case SAVE_DEBTOR_DETAIL_FAIL:
      return Object.assign({}, state, {
        isSaving: false,
      });
    default:
      return state;
  }
};
