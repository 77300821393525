import Constants from "us.collection.transactions/constants";
import { IAPIAction } from "us.collection/interfaces";

const { ACTIONS } = Constants;
const { CasesAndSubCases } = ACTIONS;
const {
  GET_CASES_AND_SUBCASES,
  GET_CASES_AND_SUBCASES_SUCCESS,
  GET_CASES_AND_SUBCASES_FAIL,

  SAVE_MERGECASES,
  SAVE_MERGECASES_SUCCESS,
  SAVE_MERGECASES_FAIL,

  CREATE_CASES,
  CREATE_CASES_SUCCESS,
  CREATE_CASES_FAIL,

  UPDATE_DUEDATE,
  UPDATE_DUEDATE_SUCCESS,
  UPDATE_DUEDATE_FAIL,

  SPLIT_SUBCASES,
  SPLIT_SUBCASES_SUCCESS,
  SPLIT_SUBCASES_FAIL,

  MANAGE_DRAWER,

  SET_SELECTED_CASES_AND_SUBCASES
} = CasesAndSubCases;

/**
 * @constant
 * @description - The list of case and sub case actions
 * @type {IAPIAction} - basic action type
 */
export const CasesAndSubCasesAction: Readonly<IAPIAction> = {
  caseList: {
    get: (data) => ({
      type: GET_CASES_AND_SUBCASES,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_CASES_AND_SUBCASES_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_CASES_AND_SUBCASES_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
    openDrawer: (data) => ({
      type: MANAGE_DRAWER,
      payload: {
        data,
        isLoading: true,
      },
    }),
    set: (data) => ({
      type: SET_SELECTED_CASES_AND_SUBCASES,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  mergeCases: {
    save: (data) => ({
      type: SAVE_MERGECASES,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SAVE_MERGECASES_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SAVE_MERGECASES_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  createCases: {
    save: (data) => ({
      type: CREATE_CASES,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CREATE_CASES_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CREATE_CASES_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  dueDate: {
    update: (data) => ({
      type: UPDATE_DUEDATE,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: UPDATE_DUEDATE_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: UPDATE_DUEDATE_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  splitSubCases: {
    update: (data) => ({
      type: SPLIT_SUBCASES,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SPLIT_SUBCASES_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SPLIT_SUBCASES_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
};
