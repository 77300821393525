import { DomainMessageFormattedTemplate } from './DomainMessageFormatedTemplate';
import { domainMessageBasicInfo } from './DomainMessageBasicInfo';
import { DashboardMessageContent, DomainMessage } from './DomainMessage';
import { CreditorPortalNote } from "./CreditorPortalNote";
import { ClaimOfPayment } from './ClaimOfPayment';
import { DebtorObjection } from './DebtorObjection';
import { ObjectionCancellation } from './ObjectionCancellation';
import { OpenDrawer } from './OpenDrawer';
import { PrintedDocument } from './PrintedDocument';
import { UploadedDocuments } from './UploadedDocuments';
import { EmailAttachment } from './EmailAttachment';
import { SendDomainMessage } from './SendDomainMessage';
import { ISendDomainMessage } from '../Interfaces/ISendDomainMessage';
import {
	IAccountSummeryWidget,
	IDataModel,
	IDebtorWidgetInfo,
	ICaseInformation,
	IDomainMessage,
	IDashboardMessageContent,
} from '../Interfaces';
import { ObjectionAndAgreementDrawerType } from 'us.collection.case/constants/Actions';

const DataModel: IDataModel<any> = {
	data: undefined,
	isError: false,
	isLoading: false,
};

export const initialState: IDashboardInitialState = {
	caseLogList: [],
	domainMessageTemplate: [],
	domainMessageFormattedTemplate: DomainMessageFormattedTemplate,
	openTemplateDrawer: false,
	domainMessageBasicdetail: domainMessageBasicInfo,
	domainMessageInitial: DomainMessage,
	creditorPortalNote: CreditorPortalNote,
	claimOfPaymentInitial: ClaimOfPayment,
	claimOfPayment: DataModel,
	openCOPDrawer: OpenDrawer,
	invoices: [],
	debtorObjectionInitial: DebtorObjection,
	debtorObjection: DataModel,
	cancelObjection: ObjectionCancellation,
	openDODrawer: OpenDrawer,
	objectionProfile: [],
	openAttachmentOpenDrawer: OpenDrawer,
	printedDocuments: PrintedDocument,
	uploadedDocuments: UploadedDocuments,
	emailAttachmentContent: EmailAttachment,
	updateActivityLog: false,
	sendDomainMessage: SendDomainMessage,
	dashboardMetadata: DataModel,
	accountSummeryWidgetData: DataModel,
	debtorWidgetData: DataModel,
	caseInfoWidgetData: DataModel,
	interestCalculating: false,
	drawer: {
		title: '',
		visible: false,
		type: ObjectionAndAgreementDrawerType.NONE,
	},
	formattedMessageContentDetails: {
		data: DashboardMessageContent,
		isLoading: false,
	},
};

export interface IDashboardInitialState {
	caseLogList: any;
	domainMessageTemplate: any;
	domainMessageFormattedTemplate: any;
	openTemplateDrawer: any;
	domainMessageBasicdetail: any;
	domainMessageInitial: IDomainMessage;
	claimOfPaymentInitial: any;
	claimOfPayment: any;
	openCOPDrawer: any;
	invoices: any;
	debtorObjectionInitial: any;
	debtorObjection: any;
	cancelObjection: any;
	openDODrawer: any;
	objectionProfile: any;
	openAttachmentOpenDrawer: any;
	printedDocuments: any;
	uploadedDocuments: any;
	emailAttachmentContent: any;
	updateActivityLog: any;
	sendDomainMessage: ISendDomainMessage;
	dashboardMetadata: any;
	accountSummeryWidgetData: IDataModel<IAccountSummeryWidget>;
	debtorWidgetData: IDataModel<IDebtorWidgetInfo>;
	caseInfoWidgetData: IDataModel<ICaseInformation[]>;
	interestCalculating: boolean;
	creditorPortalNote: typeof CreditorPortalNote;
	formattedMessageContentDetails: {
		data: IDashboardMessageContent;
		isLoading: boolean;
	};
	drawer: {
		title: string;
		visible: boolean;
		type: ObjectionAndAgreementDrawerType;
	};
}
