import React from 'react';
import { Information } from '../components';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface ICnt_CreditorInfo extends RouteComponentProps {
	caseNo: number;
}

const Cnt_CreditorInfo: React.FC<ICnt_CreditorInfo> = (props) => {
	const onMinimize = () => {
		props.history.push(`/case/${props.caseNo}`);
	};

	return <Information onMinimize={onMinimize} />;
};

export default withRouter(Cnt_CreditorInfo);
