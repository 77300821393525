import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import Common from "us.common";
import { workflowAction } from "us.collection/actions";
import { ReExecuteActivity } from "us.collection/repository";
import { RootState } from "us.helper/types";
import { ITroubleshootTableButton } from "us.collection/components/WorkflowTroubleshoot/Interfaces";
import {
  ErrorCategory,
  OperationType,
} from "us.collection/components/WorkflowTroubleshoot/Constants";
import "us.collection/components/WorkflowTroubleshoot/Home.scss";

const { $Button, $Popconfirm } = Common.Components;

/**
 * @description - The component used in view of troubleshoot table button
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3156934695/Troubleshoot+View+-+UI
 * @param {PropsFromRedux} props
 * @return {JSX.Element}
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 28/11/2022
 */
const TroubleshootButton: React.FC<PropsFromRedux & ITroubleshootTableButton> = memo((props) => {
  const { t } = useTranslation();

  const {
    errorCategory,
    rowData,
    retryWorkflow,
    retryActivity,
    ignoreActivity,
    deleteActiity,
    searchFilters,
    openDrawer,
  } = props;

  const { caseId, referenceId, workflowType } = rowData ?? {};

  return (
    <>
      {errorCategory === ErrorCategory.WORKFLOW && (
        <>
          <$Popconfirm
            title={t("US.COLLECTION.CASE:WORKFLOWS.WORKFLOW_RETRY")}
            placement="topLeft"
            onConfirm={() =>
              retryWorkflow &&
              retryWorkflow({
                searchFilters,
                caseId,
              })
            }
            okText={t("US.COMMON:COMMON.YES")}
            cancelText={t("US.COMMON:COMMON.NO")}
          >
            <$Button style={{ marginRight: 1 }}>
              {t("US.COLLECTION.CASE:WORKFLOWS.RETRY")}
            </$Button>
          </$Popconfirm>
          <$Button
            onClick={() =>
              openDrawer &&
              openDrawer({
                title: "US.COLLECTION.CASE:WORKFLOWS.MOVE_TO",
                isVisible: true,
                caseId,
                workflowType,
              })
            }
            style={{ marginRight: 1 }}
          >
            {t("US.COLLECTION.CASE:WORKFLOWS.MOVE_TO")}
          </$Button>
        </>
      )}
      {errorCategory === ErrorCategory.ACTIVITY && (
        <>
          <$Popconfirm
            title={t("US.COLLECTION.CASE:WORKFLOWS.ACTIVITY_RETRY")}
            placement="topLeft"
            onConfirm={() =>
              retryActivity &&
              retryActivity({
                requestObject: ReExecuteActivity.call(
                  rowData,
                  OperationType.RE_EXECUTE
                ),
                searchFilters,
              })
            }
            okText={t("US.COMMON:COMMON.YES")}
            cancelText={t("US.COMMON:COMMON.NO")}
          >
            <$Button style={{ marginRight: 1 }}>
              {t("US.COLLECTION.CASE:WORKFLOWS.RETRY")}
            </$Button>
          </$Popconfirm>
          <$Popconfirm
            title={t("US.COLLECTION.CASE:WORKFLOWS.ACTIVITY_IGNORE")}
            placement="topLeft"
            onConfirm={() =>
              ignoreActivity &&
              ignoreActivity({
                requestObject: ReExecuteActivity.call(
                  rowData,
                  OperationType.IGNORE
                ),
                searchFilters,
              })
            }
            okText={t("US.COMMON:COMMON.YES")}
            cancelText={t("US.COMMON:COMMON.NO")}
          >
            <$Button style={{ marginRight: 1 }}>
              {t("US.COLLECTION.CASE:WORKFLOWS.IGNORE")}
            </$Button>
          </$Popconfirm>
          <$Popconfirm
            title={t("US.COLLECTION.CASE:WORKFLOWS.ACTIVITY_DELETE")}
            placement="topLeft"
            onConfirm={() =>
              deleteActiity &&
              deleteActiity({
                searchFilters,
                pendingexecutionid: referenceId,
              })
            }
            okText={t("US.COMMON:COMMON.YES")}
            cancelText={t("US.COMMON:COMMON.NO")}
          >
            <$Button style={{ marginRight: 1 }}>
              <span className="text-error">{t("US.COMMON:COMMON.DELETE")}</span>
            </$Button>
          </$Popconfirm>
        </>
      )}
    </>
  );
});

const { troubleshootWorkflowRetry, troubleshootActivity } = workflowAction;

const mapStateToProps = (state: RootState) => {
  const { Workflow } = state;
  const { monitorList } = Workflow;
  return {
    monitorList,
  };
};

const mapDispatchToProps = {
  retryWorkflow: troubleshootWorkflowRetry.retry,
  retryActivity: troubleshootActivity.retry,
  ignoreActivity: troubleshootActivity.ignore,
  deleteActiity: troubleshootActivity.delete,
  openDrawer: troubleshootWorkflowRetry.openDrawer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TroubleshootButton);