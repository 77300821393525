import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { Formik } from "formik";
import { PlusOutlined } from "us.icons";
import {
  ISelectAssets,
  ISortOrder,
} from "us.collection.case/components/Assets/SelectAssets/Interfaces";
import {
  onSearchAsset,
  onSelectAssetType,
} from "us.collection.case/components/Assets/SelectAssets/Functions";
import { AssetsAction } from "us.collection.case/actions";
import { AddAssets } from "us.collection.case/components";
import { AssetTypes } from "us.collection.case/constants";
import { IRootState } from "us.collection/interfaces";
import { IOnFilter, IOnSort } from "us.common/components/antd/TableTree";

const {
  $AmountLabel,
  $Form,
  $Drawer,
  $Select,
  $Button,
  $Breadcrumb,
  $Search,
  $PageHeader,
  $TableTree
} = Common.Components;

const { AddRealEstateAssets, RealEstateAssets } = AssetsAction;
const { openDrawer, resetDrawer } = AddRealEstateAssets;
const { reset } = RealEstateAssets;

const SelectAssets: React.FC<ISelectAssets> = (props) => {
  const { t } = useTranslation();
  const {
    selectedAssets,
    onSelectAsset,
    backToNewEnforcement,
    resetRealEstateAssets,
    changeDrawerInfo,
    resetDrawerInfo,
    assetList,
    drawerInfo,
  } = props;
  const [selectedData, setSelectedData] = useState<any>();
  const [dataSource, setDataSource] = useState<Array<any>>(selectedAssets);

  useEffect(() => {
    setDataSource(selectedAssets);
  }, [assetList]);

  const rowSelection = {
    onSelect: (record: any) => setSelectedData(record),
    hideDefaultSelections: true,
  };

  /**
   * closing the drawer
   */
  const onClose = () => {
    resetRealEstateAssets();
    resetDrawerInfo();
  };

  /**
   * opening the drawer for the new asset addtion.
   */
  const addNewButtonHandler = () => {
    changeDrawerInfo({
      title: t("US.COLLECTION.CASE:ASSETS.NEWASSET"),
      visible: true,
      isEdit: false,
      showOnly: false,
    });
    resetRealEstateAssets();
  };

  const columns: any = [
    {
      title: t("US.COLLECTION.CASE:SELECT_ASSETS.ASSET"),
      dataIndex: "description",
      key: "description",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.CASE:SELECT_ASSETS.TYPE"),
      dataIndex: "assetType",
      key: "assetType",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.CASE:SELECT_ASSETS.VALUATION"),
      dataIndex: "valuationAmount",
      key: "valuationAmount",
      align: "right",
      className: "right-has-sort right-has-filter text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: "amount",
      customRenderChild: (text: any, record: any) => {
        return <$AmountLabel value={record?.valuationAmount} />;
      },
    },
  ];

  const handleSort: IOnSort = (sortData, dataSource) => {
    return sortData(dataSource);
  };

  const handleFilter: IOnFilter = (searchData, dataSource) => {
    return searchData(dataSource);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={(values: any, actions: any) => {}}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        ...restProps
      }: any) => (
        <>
          <$Form>
            <div>
              <div>
                <$Breadcrumb>
                  <$Breadcrumb.Item onClick={() => backToNewEnforcement()}>
                    <a>{t("US.COLLECTION.CASE:ENFORCEMENTS.NEWENFORCEMENT")}</a>
                  </$Breadcrumb.Item>
                  <$Breadcrumb.Item>
                    {t("US.COLLECTION.CASE:ENFORCEMENTS.SELECTASSETS")}
                  </$Breadcrumb.Item>
                </$Breadcrumb>
                <div className="page-header pr-0">
                  <div className="d-flex align-items-center">
                    <$PageHeader
                      className="px-0 py-0 mr-n3"
                      onBack={() => backToNewEnforcement()}
                      title={t("US.COLLECTION.CASE:ENFORCEMENTS.SELECTASSETS")}
                    />
                    <div className="ml-auto">
                      <$Button
                        tabIndex={1}
                        type="default"
                        size="small"
                        onClick={() => addNewButtonHandler()}
                        icon={<PlusOutlined />}
                      >
                        {t("US.COLLECTION.CASE:ENFORCEMENTS.NEWASSETS")}
                      </$Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center mt-2">
                <div className="flex-fill mr-3">
                  <$Search
                    size="small"
                    name="assetSearch"
                    className="w-100"
                    onSearch={(val: string) =>
                      setDataSource(onSearchAsset(val, selectedAssets))
                    }
                    allowClear={true}
                    placeholder={t(
                      "US.COLLECTION.CASE:ENFORCEMENTS.SEARCH_ASSET_/_ASSET_TYPE"
                    )}
                  />
                </div>
                <div className="" style={{ width: 250 }}>
                  <$Select
                    name="assetTypeSelect"
                    className="w-100"
                    size="small"
                    options={_.uniqBy(selectedAssets, "assetType")}
                    defaultValue="All"
                    optionValue="assetType"
                    optionText="assetType"
                    onSelect={(val: any) =>
                      setDataSource(onSelectAssetType(val, selectedAssets))
                    }
                    allOption={true}
                    autoFocus={true}
                    tabIndex={3}
                    onSearchBy={["assetType"]}
                  />
                </div>
              </div>
              <div className="mt-3">
                <$TableTree
                  rowKey="key"
                  data={dataSource}
                  size="small"
                  className=""
                  onSort={handleSort}
                  onFilter={handleFilter}
                  filterOnType={true}
                  resetOnSourceChange={true}
                  bordered
                  pagination={{ defaultPageSize: 15 }}
                  scroll={{ x: 780 }}
                  columns={columns}
                  rowSelection={{ type: "radio", ...rowSelection }}
                  firstColSkipFilterProps={-1}
                />
              </div>
              <div className="drawer-footer-fixed align-content-center justify-content-end">
                <$Button
                  className="mr-2"
                  type="primary"
                  disabled={typeof selectedData == "undefined"}
                  onClick={() => {
                    onSelectAsset(selectedData);
                  }}
                >
                  {t("US.COMMON:COMMON.OK")}
                </$Button>
                <$Button onClick={() => backToNewEnforcement()}>
                  {t("US.COMMON:COMMON.CANCEL")}
                </$Button>
              </div>
            </div>
          </$Form>
          <$Drawer //Add Asserts
            title={drawerInfo?.title}
            width={720}
            visible={drawerInfo?.visible}
            onClose={() => onClose()}
            destroyOnClose
          >
            <AddAssets
              enforcementEmployeeBreadcrumb={false}
              enforcementPostalBreadcrumb={false}
              addAssetType={AssetTypes.REAL_ESTATE}
              assetType={AssetTypes.REAL_ESTATE}
              showOnly={drawerInfo?.showOnly}
            />
          </$Drawer>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { assets } = state;
  const { data, drawerInfo } = assets;
  return {
    assetList: data,
    drawerInfo,
  };
};

const mapDispatchToProps = {
  changeDrawerInfo: openDrawer,
  resetDrawerInfo: resetDrawer,
  resetRealEstateAssets: reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectAssets);
