export const onSearchAsset = (
  val: string,
  selectedAssets: Array<any>
): Array<any> => {
  try {
    return selectedAssets?.filter((asset: any) =>
      val != ""
        ? asset?.assetType
            ?.toLocaleLowerCase()
            .includes(val?.toLocaleLowerCase()) ||
          asset?.description
            ?.toLocaleLowerCase()
            .includes(val?.toLocaleLowerCase())
        : asset
    );
  } catch (error) {
    return selectedAssets;
  }
};

export const onSelectAssetType = (
  val: string,
  selectedAssets: Array<any>
): Array<any> => {
  try {
    return selectedAssets?.filter((asset: any) =>
      val != "All" ? asset?.assetType == val : asset
    );
  } catch (error) {
    return selectedAssets;
  }
};
