export enum InterestType {
	STANDARD_INTEREST = 'STD',
	FIXED_INTEREST = 'FIXED',
	NO_INTEREST = 'NO',
}

export enum DateFormats {
	REQ = 'YYYY-MM-DD',
	MIN_DATE = '0001-01-01',
}
