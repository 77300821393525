import { ExistingPartpayment } from './ExistingPartPayment'
import { AddPartPaymentInitialState } from './AddPartPaymentInitialState'
import { OpenDrawer } from './OpenDrawer'

export const initialState = {
    ExistingPartPaymentDetails: ExistingPartpayment,
    PartPaymentDetailsDuplicate: ExistingPartpayment,
    AddPartPaymentInitial: AddPartPaymentInitialState,
    partPaymentBMDs: [],
    addPaymentAgreementDrawer: OpenDrawer
}