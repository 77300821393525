import {  takeLatest, put, call } from "redux-saga/effects";
import * as API from "us.collection.admin/services";
import * as Actions from "us.collection.admin/actions";
import { NonProductionDay } from "us.collection.admin/constants/Actions";
import { $Message } from "us.common/components";
import moment from "moment";

const { nonProductionDays } = Actions;

const NonProductionDaySagas = {
  calendars: {
    get: function* (action: any) {
      const params = action?.payload.data ?? {};
      try {
        const data = yield call(API.nonProductionDays.calendars.get, params);
        yield put(nonProductionDays.calendars.success(data));
      } catch (error) {
        yield put(nonProductionDays.calendars.fail(error));
        console.log(error);
      }
    }
  },

  calendar: {
    save: function* (action: any) {
      const params = action?.payload?.data ?? {};
      const history = action?.payload?.history;
      try {
        const data = yield call(API.nonProductionDays.calendar.save, params);
        if (data?.error || data?.errors) {
          $Message.error(
            `Something wrong in calendar saving. ${
              data?.error ? data?.error : data?.errorType
            }`
          );
        } else {
          yield put(nonProductionDays.calendar.success(data));
          if(params.calendarId == -1) {
            $Message.success(`Successfully added.`);
          } else {
            $Message.success(`Edited successfully.`);
          }
          
          history.push("/non-production-days");
        }
      } catch (error) {
        yield put(nonProductionDays.calendar.fail(error));
        $Message.error(`Something wrong in calendar saving. ${error}`);
      }
    },
    getPrevious: function* (action: any) {
      const selectedYear = action?.payload?.selectedYear ?? 0;
      const calendarId = action?.payload?.calendarId ?? 0;
      const params = {
        calendarId,
        criteria: "All",
        startDate: moment.utc(`${selectedYear - 3}-01-01`),
        endDate: moment.utc(`${selectedYear - 1}-12-31`),
      };
      try {
        const data = yield call(API.nonProductionDays.redDays.get, params);
        if (Array.isArray(data) && data.length != 0) {
          if(nonProductionDays.calendar.getPreviousSuccess){
            yield put(nonProductionDays.calendar.getPreviousSuccess(data, selectedYear))
          } 
        } else {
          if(nonProductionDays.calendar.getPreviousFail){
            yield put(nonProductionDays.calendar.getPreviousFail(data));
          }     
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  
  redDays: {
    get: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.nonProductionDays.redDays.get, params);
        if (Array.isArray(data) && data.length != 0) {
          yield put(nonProductionDays.redDays.success(data));
        } else {
          yield put(nonProductionDays.redDays.fail(data));
        }
      } catch (error) {
        console.log(error);
        yield put(nonProductionDays.redDays.fail(error));
      }
    },
  },

  redDaysWithSmsSchedule: {
    get: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { nonProdDays, smsSchedule } = yield call(
          API.nonProductionDays.redDaysWithSmsSchedule.get,
          params
        );
        if (Array.isArray(nonProdDays) && nonProdDays.length != 0) {
          yield put(nonProductionDays.redDays.success(nonProdDays));
        } else {
          yield put(nonProductionDays.redDays.fail(nonProdDays));
        }
        if (Array.isArray(smsSchedule) && smsSchedule.length != 0) {
          yield put(nonProductionDays.smsSchedule.success(smsSchedule));
        } else {
          yield put(nonProductionDays.smsSchedule.fail(smsSchedule));
        }
      } catch (error) {
        console.log(error);
          yield put(nonProductionDays.withSmsSchedule.fail(error));
      }
    }
  },

  smsSchedule: {
    get: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.nonProductionDays.smsSchedule.get, params);
        if (Array.isArray(data) && data.length != 0) {
          yield put(nonProductionDays.smsSchedule.success(data));
        } else {
          yield put(nonProductionDays.smsSchedule.fail(data));
        }
      } catch (error) {
        console.log(error);
        yield put(nonProductionDays.smsSchedule.fail(error));
      }
    }
  }
};

export default [
  takeLatest(
    NonProductionDay.GET_CALENDARS,
    NonProductionDaySagas.calendars.get
  ),
  takeLatest(
    NonProductionDay.GET_NON_PRODUCTION_DAYS,
    NonProductionDaySagas.redDays.get
  ),
  takeLatest(
    NonProductionDay.GET_NON_PRODUCTION_DAYS_WITH_SMS_SCHEDULE,
    NonProductionDaySagas.redDaysWithSmsSchedule.get
  ),
  takeLatest(
    NonProductionDay.SAVE_CALENDAR,
    NonProductionDaySagas.calendar.save
  ),
  takeLatest(
    NonProductionDay.GET_PREVIOUS_CALENDAR,
    NonProductionDaySagas.calendar.getPrevious
  ),
];
