import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import XMLViewer from 'react-xml-viewer';

import { IRootState } from 'us.collection/interfaces';
import Common from 'us.common';
import * as Actions from 'us.collection.routines-and-activities/actions';
import {
	BoxIcons,
	DownloadOutlined,
	FileExclamationOutlined,
	IconTypes,
} from 'us.icons';
import {
	DocumentType,
	unableToDisplayFormats,
} from 'us.collection.routines-and-activities/constants';
import { attachementType } from 'us.collection.routines-and-activities/functions';
import { base64toStr } from 'us.collection.documents/functions';
import { AttachmentViewerProps } from './Interfaces';

const { $Button, $Skeleton, $Empty, $Collapse } = Common.Components;

const AttachmentViewer: React.FC<PropsFromRedux & AttachmentViewerProps> = (
	props
) => {
	const { t } = useTranslation();
	const { attachement, communicationJobId, onClose, getAttachment ,resetAttachment  } =
		props;

	useEffect(() => {
    const lookupId = parseInt(communicationJobId);
    if (lookupId && !isNaN(Number(lookupId))) {
      getAttachment &&
        getAttachment({
          lookupId,
          lookupType: DocumentType.ATTACHEMENT,
          system: DocumentType.USC_SERVICE,
        });
    }
    return () => {
      resetAttachment && resetAttachment({});
    };
  }, []);

	const downloadAll = (data: any) => {
		const downloadLink = document.createElement('a');
		data?.map((item: any) => {
			downloadLink.setAttribute(
				'download',
				item?.documentName
			);
			downloadLink.setAttribute(
				'href',
				`data:${
					item?.documentType === 'pdf'
						? 'application/pdf'
						: `text/${item?.documentType}`
				};base64,${item?.content}`
			);
			downloadLink.click();
		});
	};

	return (
		<$Skeleton loading={attachement?.isLoading}>
			<$Collapse
				defaultActiveKey={[]}
				expandIcon={() => <></>}
				className=''>
				{attachement.data?.length > 0 &&
					attachement.data.map(
						(item: any, index: number) => (
							<$Collapse.Panel
								header={
									<div className='attach-item-header'>
										<BoxIcons
											type={
												IconTypes.BOX_ICON
											}
											name={item.documentType?.toLowerCase()}
										/>
										<span>
											{
												item?.documentName
											}
										</span>
									</div>
								}
								key={index}
								extra={
									<$Button
										type='link'
										size='small'
										icon={
											<DownloadOutlined />
										}
										download={
											item.documentName
										}
										href={`data:${
											item.documentType ===
											'pdf'
												? 'application/pdf'
												: `text/${item.documentType}`
										};base64,${
											item.content
										}`}
										onClick={(
											event: any
										) =>
											event.stopPropagation()
										}>
										{t(
											'US.COLLECTION.COMMON:COMMON.DOWNLOAD'
										)}
									</$Button>
								}>
								<div className='history-attach-viwer-inner'>
									{unableToDisplayFormats.includes(
										item.documentType
									) && (
										<div className='unable-to-display'>
											<$Empty
												image={
													<FileExclamationOutlined />
												}
												description={
													<span>
														{t(
															'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.PREVIEW_IS_NOT_AVAILABLE'
														)}
													</span>
												}
											/>
										</div>
									)}
									{!unableToDisplayFormats.includes(
										item.documentType
									) &&
										item.documentType ==
											DocumentType.XML && (
											<XMLViewer
												xml={base64toStr(
													item.content
												)}
												theme={{
													overflowBreak:
														true,
												}}
												indentSize={
													5
												}
												collapsible={
													true
												}
											/>
										)}
									{!unableToDisplayFormats.includes(
										item.documentType
									) &&
										item.documentType !=
											DocumentType.XML && (
											<object
												className={
													item.documentType ===
													DocumentType.PDF
														? 'history-attach-frame frame-pdf'
														: 'history-attach-frame frame-html'
												}
												data={`data:${attachementType(
													item.documentType
												)};base64,${
													item.content
												}`}
											/>
										)}
								</div>
							</$Collapse.Panel>
						)
					)}
			</$Collapse>
			<div className='drawer-footer-fixed align-content-center justify-content-end'>
				<div>
					<$Button
						className='mr-2'
						type='primary'
						onClick={() =>
							downloadAll(
								attachement.data
							)
						}>
						{attachement.data?.length > 1
							? t(
									'US.COLLECTION.COMMON:COMMON.DOWNLOAD_ALL'
							  )
							: t(
									'US.COLLECTION.COMMON:COMMON.DOWNLOAD'
							  )}
					</$Button>
					<$Button onClick={onClose}>
						{t(
							'US.COLLECTION.COMMON:COMMON.CANCEL'
						)}
					</$Button>
				</div>
			</div>
		</$Skeleton>
	);
};

const mapStateToProps = (state: IRootState) => {
	const { domainView, logHistory } = state;
	const { metaData } = domainView;
	const { attachement } = logHistory;
	return {
		attachement,
		metaData,
	};
};
const { historyAction } = Actions;
const { attachment } = historyAction;

const mapDispatchToProps = {
	getAttachment: attachment.get,
	resetAttachment: attachment.reset,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AttachmentViewer);
