import * as Yup from "yup";

interface IPersonalEffects extends Yup.MixedSchema {
  description: string;
  estimatedValue: string;
  valuationAmount: string;
}

const PersonalEffectsSchema = Yup.object<IPersonalEffects>().shape({
  description: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.DESCRIPTION_IS_REQUIRED"
  ),
  estimatedValue: Yup.string()
  .test(
    "estimatedValue",
    "US.COLLECTION.VALIDATIONS:REQUIRED.ESTIMATED_VALUE_MUST_BE_POSITIVE",
    (val: any) => {
      return val >= 0;
    }
  ),
  valuationAmount: Yup.string()
  .test(
    "valuationAmount",
    "US.COLLECTION.VALIDATIONS:REQUIRED.VALUATION_AMOUNT_MUST_BE_POSITIVE",
    (val: any) => {
      return val >= 0;
    }
  ),
});

export default PersonalEffectsSchema;
