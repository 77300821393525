import * as ViewConstants from './Component';
import * as ActionConsts from './Actions';

export default {
    endPoints: {
        caselogs: '/caselogs',
        currentStatus: '/manageAgreements',
        assets: '/Assets',
        caseInterest: '/caseInterest',
        casePropDetails: '/USPCaseParameter',
        userAgreementDetails: '/UserAgreementsDetails',
        partPayments: '/partPayments',
        caseDashboardMeta: '/caseMetaData',
        caseDashboardBasic: '/caseDashboard',
        systemService: {
            CaseDetails: '/CaseDetails',
            EntInfo: '/EntInfo'
        },
        metadataService: {
            metadata: '/system/domainView/metadata'
        },
        arService: {
            caseProperties: '/cases'
        },
    },
    azureServiceAPIendpoints: {
        dashboardMain: '/case',
        caseProperties: '/func-unicornbox-arservice-dev/ars/v1/cases/2696/properties',
        metaData: '/metadataservice/v1/system/v1/domainView/metadata/10476/Case'
    },
    ASSET_VIEW_NAMES: ViewConstants.NewAssetViewNames,
    ENFORCEMENT_VIEW_NAMES: ViewConstants.NewEnforcementViewNames,
    EXTENDED_FIELD_TYPE: ViewConstants.ExtendedFieldType,
    ACTIONS: ActionConsts
}
export * from './AccountSummery';
export * from './OtherParty'
export * from './Test'
export * from './Enforcements'
export * from './MessageTemplates'
export * from './Assets';
export * from './CourtSentence';
export * from './ManualFollowUps'
