import { setNestedObjectValues, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { BMDActions } from "us.collection/actions";
import { DrawerTypes } from "us.collection/constants";
import { IRootState } from "us.collection/interfaces";
import { $Button, $Popconfirm } from "us.common/components";
import { IAddBMDProperty } from "../Interfaces";
import { isPopUpDissabled, shouldTheNextBtnBeShown } from "./Functions";
import { IControlButtons } from "./Interfaces";

/**
 * @description - The List of controller buttons on the drawer
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2885681153/UI+Add+BMD+Property
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2895904780/UI+-+Change+BMD+Property+Value
 * @param {IControlButtons & PropsFromRedux} props
 * @return {JSX.Element}
 * @author Darshana Waasala <waasalajb@unicorn-solutions.com>
 * @since 16/03/2022
 */
export const ControlButtons: React.FC<IControlButtons & PropsFromRedux> = (props) => {
  const { t } = useTranslation(["US.COLLECTION.CASE", "US.COLLECTION.COMMON"]);
  const {
    values,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    resetForm,
    validateForm,
    isValid,
    setTouched,
  } = useFormikContext<IAddBMDProperty>();
  const {
    changeDrawerStatus,
    drawerStatus,
    isBMDLading,
    routeDetails,
    creditors,
    changeBMDLoading,
    isCreditorViewShown,
    selectedCreditors,
  } = props;
  const { entityType } = routeDetails;
  const { showCreditorView, isSaveNewClicked } = values;

  return (
    <div className="drawer-footer-fixed align-content-center justify-content-between">
      <div>
        {isCreditorViewShown && (
          <div className="d-flex flex-column">
            <div className="font-weight-bold">
              {selectedCreditors.length <= 0
                ? t("US.COLLECTION.COMMON:COMMON.NONE")
                : selectedCreditors?.length}
            </div>
            <div>{t("US.COLLECTION.COMMON:COMMON.SELECTED_CREDITORS")}</div>
          </div>
        )}
      </div>
      <div>
        {shouldTheNextBtnBeShown(entityType, creditors.data ?? [], showCreditorView) && (
          <$Button
            className="mr-2"
            type="primary"
            onClick={async (e) => {
              const validationErrors = await validateForm();
              setTouched(setNestedObjectValues(validationErrors, true), true);
              if (isValid) {
                setFieldValue("showCreditorView", true, false);
              }
            }}
          >
            {" "}
            {t("US.COLLECTION.COMMON:BMD.NEXT")}
          </$Button>
        )}
        {!shouldTheNextBtnBeShown(entityType, creditors.data ?? [], showCreditorView) && (
          <$Popconfirm
            title={t("US.COLLECTION.CASE:BMD.OVERRIDDEN_VALUE_UPDATE_WARNING")}
            okText={t("US.COMMON:COMMON.YES")}
            cancelText={t("US.COMMON:COMMON.NO")}
            onConfirm={() => {
              setFieldValue("isSaveNewClicked", false, false);
              setFieldValue("updateLowerLevel", true, false);
              handleSubmit();
            }}
            onCancel={() => {
              setFieldValue("isSaveNewClicked", false, false);
              setFieldValue("updateLowerLevel", false, false);
              handleSubmit();
            }}
            placement="right"
            disabled={!isValid || isPopUpDissabled(entityType, drawerStatus)}
          >
            <$Button
              loading={!isSaveNewClicked && (isSubmitting || isBMDLading || changeBMDLoading)}
              className="mr-2"
              type="primary"
              onClick={async (e) => {
                e.preventDefault();
                setFieldValue("isSaveNewClicked", false, false);
                if (isPopUpDissabled(entityType, drawerStatus)) {
                  setFieldValue("isSaveNewClicked", false, false);
                  handleSubmit();
                } else {
                  const validationErrors = await validateForm();
                  setTouched(setNestedObjectValues(validationErrors, true), true);
                }
              }}
              disabled={isSaveNewClicked && (isSubmitting || isBMDLading)}
            >
              {t("US.COLLECTION.COMMON:COMMON.SAVE")}
            </$Button>
          </$Popconfirm>
        )}
        {drawerStatus.drawerType === DrawerTypes.ADD_PROPERTY && (
          <$Button
            onClick={(e: any) => {
              e.preventDefault();
              setFieldValue("isSaveNewClicked", true, false);
              handleSubmit(e);
            }}
            loading={isSaveNewClicked && (isSubmitting || isBMDLading)}
            disabled={!isSaveNewClicked && (isSubmitting || isBMDLading)}
            className="mr-2"
          >
            {t("US.COLLECTION.COMMON:COMMON.SAVE_&_NEW")}
          </$Button>
        )}
        <$Button
          onClick={(e) => {
            e.preventDefault();
            resetForm();
            changeDrawerStatus && changeDrawerStatus({ ...drawerStatus, isVisible: false });
          }}
          disabled={isSubmitting || isBMDLading}
        >
          {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
        </$Button>
      </div>
    </div>
  );
};
const { bmdDataTypes } = BMDActions;

const mapStateToProps = (state: IRootState) => {
  const { bmd, creditorSelection } = state;
  const { drawerStatus, addBmdProperty, changeBmdProperty, linkedCreditors } = bmd;
  const { isLoading } = changeBmdProperty;
  const { creditors } = creditorSelection;
  return {
    drawerStatus,
    isBMDLading: addBmdProperty.isLoading,
    creditors: linkedCreditors,
    changeBMDLoading: isLoading,
    selectedCreditors: creditors.selected,
  };
};

const mapDispatchToProps = {
  changeDrawerStatus: bmdDataTypes.openDrawer,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ControlButtons);
