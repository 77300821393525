import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { $Affix, $Empty, $PageHeader } from 'us.common/components';
import { Formik } from 'formik';
import './Home.scss';
import { followUps } from 'us.collection.followup/actions';
import { IFollowUpsHome } from 'us.collection.followup/components/FollowUps/Interfaces';
import {
	FollowUpList,
	FollowUpResult,
} from 'us.collection.followup/components/FollowUps/Components';
import { useParams } from 'react-router-dom';
import { DoubleRightOutlined, DoubleLeftOutlined } from 'us.icons';
import { RootState } from 'us.helper/types';
import { getFollowupName } from './Components/FollowUpResult/Functions';

const { result, reImport, categoryId } = followUps;

const FollowUpsHome: React.FC<PropsFromRedux & IFollowUpsHome> = (props) => {
	const { t } = useTranslation();
	const { followUpId } = useParams<{ followUpId?: string }>();
	const [menuopen, menuOpen] = useState<boolean>(false);
	const {
		history,
		categories,
		followUpNavigation,
		filters,
		list,
		result,
		getCategoryId,
	} = props;
	const { data: followupList } = list ?? {};
	const { followupId } = result ?? {};
	const { isBack } = followUpNavigation ?? {};
	const { selectedCategoryId, categorySearchText } = categories ?? {};
	const [searchText, setSearchText] = useState<string>('');
	const [followupSearch, setFollowupSearch] = useState<string>('');

	useEffect(() => {
		if (!isBack) {
			getCategoryId &&
				getCategoryId({ followUpId: followUpId ?? -1 });
		} else {
			setSearchText(filters.data?.searchText);
			setFollowupSearch(categorySearchText);
		}
	}, []);

	useEffect(() => {
		setFollowupSearch(categorySearchText);
	}, [selectedCategoryId]);

	const menuToggle = () => {
		menuOpen((prevCheck) => !prevCheck);
	};

	return (
		<Formik
			initialValues={{
				followUpCategory: selectedCategoryId,
				searchText,
				followupSearch,
			}}
			onSubmit={() => {}}
			enableReinitialize>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
			}: any) => (
				<>
					<div className='followup-list'>
						<$Affix offsetTop={48}>
							<div className='page-header'>
								<div className='d-flex flex-row align-items-center justify-content-between'>
									<div className='d-flex flex-row align-items-center'>
										<$PageHeader
											style={{
												minWidth: '120px',
											}}
											className='px-0'
											title={t(
												'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.FOLLOWUP'
											)}
											onBack={() =>
												history.push(
													'/'
												)
											}
										/>
										{followupId !=
											-1 && (
											<div className='flc-count mr-5 ml-5'>
												<span className='bui-label'>
													{t(
														'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.CURRENTLY_VIEWING'
													)}
													{
														' : '
													}{' '}
													{getFollowupName(
														followupList,
														followupId
													)}
												</span>
											</div>
										)}
									</div>

									<div className='ml-5'></div>
								</div>
							</div>
						</$Affix>
						<div
							className={
								menuopen
									? 'followup-list-wrap fl-menu-open'
									: 'followup-list-wrap'
							}>
							<FollowUpList
								{...props}
								categoryId={
									values.followUpCategory
								}
							/>
							{followUpId ===
								undefined && (
								<div className='fl-container'>
									<div className='flc-body'>
										<div className='followup-dashboard-msg'>
											<$Empty
												className='pt-5'
												description={
													false
												}
											/>
											<div className='fdm-popover ant-popover ant-popover-placement-right'>
												<div className='ant-popover-content'>
													<div className='ant-popover-arrow'>
														<span className='ant-popover-arrow-content'></span>
													</div>
													<div
														className='ant-popover-inner'
														role='tooltip'>
														<div className='ant-popover-inner-content'>
															<div>
																<p className='my-4 px-5'>
																	{t(
																		'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.PLEASE_SELECT_A_'
																	)}
																	<strong>
																		{t(
																			'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.FOLLOWUP'
																		)}
																	</strong>
																	{t(
																		'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME._FROM_THE_LIST'
																	)}
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
							{followUpId !==
								undefined && (
								<>
									<div className='fl-sidebar-toggle'>
										<div
											className='fl-sidebar-toggle-btn'
											onClick={() =>
												menuToggle()
											}>
											{menuopen && (
												<DoubleRightOutlined />
											)}
											{!menuopen && (
												<DoubleLeftOutlined />
											)}
										</div>
									</div>
									<FollowUpResult
										{...props}
									/>
								</>
							)}
						</div>
					</div>
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { followUps } = state;
	const { categories, filters, followUpNavigation, result, list } =
		followUps;
	return { categories, filters, followUpNavigation, result, list };
};

const mapDispatchToProps = {
	getFollowUpResult: result.get,
	reImportFollowUp: reImport.execute,
	getCategoryId: categoryId.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FollowUpsHome);
