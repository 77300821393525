import { ICase } from "us.collection.transactions/interfaces";
import { appInsights } from "us.helper";
import { ICaseSubCasesFrom } from "../Interfaces";
import { filterByCaseState } from "./FilterByCaseState";

/**
 * @function
 * @description - To handle the table row expansion
 * @param {ICase[]} casesAndSubCases - case list
 * @param {number} rowKey - the expading row
 * @param {boolean} isExpand - if expanded or not
 * @param {ICaseSubCasesFrom} { expandedRows, caseState, caseType } - the form values
 * @param {(
 *       fields: React.SetStateAction<{ [field: string]: any }>,
 *       shouldValidate?: boolean
 *     ) => void} setValues - formik function
 * @param {boolean} [isAll] - are all need to be expanded
 */
export const handleExpandedRows = (
  casesAndSubCases: ICase[],
  rowKey: number,
  isExpand: boolean,
  { expandedRows, caseState, caseType }: ICaseSubCasesFrom,
  setValues: (
    fields: React.SetStateAction<{ [field: string]: any }>,
    shouldValidate?: boolean
  ) => void,
  isAll?: boolean
) => {
  try {
    if (isAll) {
      setValues((preVal) => ({
        ...preVal,
        expandedRows: filterByCaseState(caseState, caseType, casesAndSubCases).map(
          (item) => item.key
        ),
      }));
    } else if (typeof isAll != "undefined" && !isAll) {
      setValues((preVal) => ({
        ...preVal,
        expandedRows: [],
      }));
    } else {
      if (isExpand) {
        setValues((preVal) => ({
          ...preVal,
          expandedRows: [...preVal.expandedRows, rowKey],
        }));
      } else {
        setValues((preVal) => ({
          ...preVal,
          expandedRows: expandedRows.filter((expandedRow: number) => expandedRow !== rowKey),
        }));
      }
    }
  } catch (error) {
    appInsights.trackException(`Cases and Subcases Home handleExpandedRows error - ${error}`);
  }
};
