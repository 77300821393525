import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { PlusOutlined, MinusOutlined } from "us.icons";
import "us.collection.creditor/components/ManageCreditors/Home.scss";
import {
  BasicInformation,
  ContactInformation,
} from "us.collection.creditor/components/ManageCreditors/Components";
import { ICreditorInformation } from "us.collection.creditor/components/ManageCreditors/Interfaces";
import { IRootState } from "us.collection/interfaces";
import {
  isVisibleElement,
  isVisibleContactPerson,
} from "us.collection.creditor/components/ManageCreditors/Functions";

const { $Collapse, $PageHeader } = Common.Components;

/**
 * @description - Manage Creditor home view component to manage creditor add / edit
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2532114555/Manage+Creditors
 * @author Kaushalya Sandamali <kaushalyas@unicorn-solutions.com>
 * @since 23/03/2022
 */
const CreditorInformation: React.FC<ICreditorInformation> = (props) => {
  const { t } = useTranslation([
    "US.COLLECTION.CREDITOR",
    "US.COLLECTION.COMMON",
  ]);

  const { newEntityData, isDrawer } = props;

  return (
    <div>
      {/* Basic information */}
      {isVisibleElement(isDrawer, newEntityData) && (
        <div>
          <$PageHeader
            className="font-weight-bold mb-2 p-0"
            title={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.BASIC_INFORMATION")}
          />
          <BasicInformation {...props} />
        </div>
      )}

      {/* Contact information */}
      {isVisibleContactPerson(isDrawer, newEntityData) && (
        <div>
          <$PageHeader
            className="font-weight-bold mb-2 p-0"
            title={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.CONTACT_PERSON")}
          />
          <ContactInformation {...props} />
        </div>
      )}

      {!isDrawer && (
        <$Collapse
          bordered={false}
          defaultActiveKey={["1", "2"]}
          expandIcon={({ isActive }) =>
            isActive ? <MinusOutlined /> : <PlusOutlined />
          }
          className="bui-collapse"
        >
          <$Collapse.Panel
            header={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.BASIC_INFORMATION")}
            key="1"
          >
            <BasicInformation {...props} />
          </$Collapse.Panel>
          <$Collapse.Panel
            header={t("US.COLLECTION.CREDITOR:CREDITOR_INFO.CONTACT_PERSON")}
            key="2"
          >
            <ContactInformation {...props} />
          </$Collapse.Panel>
        </$Collapse>
      )}
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageCreditors } = state;
  const { newEntityData } = manageCreditors;

  return {
    newEntityData,
  };
};

const connector = connect(mapStateToProps);

export default connector(CreditorInformation);
