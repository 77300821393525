import React, { useContext, useState } from 'react';
import General from './General';
import './DebtorInformation.scss';
import { useTranslation } from 'react-i18next';
import {
	CaseList,
	CreditInformation,
	DadDebtInfo,
} from 'us.collection.debtor/components/DebtorBasicInfo/Components';
import { ConfigurationContext } from 'us.common/ConfigurationContext/ConfigurationProvider';
import Common from 'us.common';
import { ConnectedProps, connect } from 'react-redux';
import {
	DebtorWidgetActions,
	AccountSummeryWidgetActions,
} from 'us.collection.case/actions';
import { CaseType } from 'us.helper/types/enums';
import { RootState } from 'us.helper/types';
import { RouteComponentProps } from 'react-router-dom';

const { accountSummery } = AccountSummeryWidgetActions;
const { $Affix, $Tabs, $PageHeader } = Common.Components;

interface IDebtorInformation extends RouteComponentProps {
	onMinimize: () => void;
}

const DebtorInformation: React.FC<IDebtorInformation & PropsFromRedux> = (
	props
) => {
	const context = useContext(ConfigurationContext);
	const [viewForm, setViewForm] = useState<boolean>(true);
	const [debtorFormData, setDebtorFormData] = useState<any>();
	const { t } = useTranslation(
		['US.COLLECTION.DEBTOR', 'US.COLLECTION.COMMON'],
		{ useSuspense: true }
	);

	const {
		metaData,
		debtorWidgetData,
		getDashboardWidgetData,
		onMinimize,
		getAccountSummeryWidgetData,
	} = props;

	const { debtorDetails } = debtorWidgetData.data ?? {};
	const { debtorType } = debtorDetails ?? {};

	const {
		caseId = 0,
		entityType = '',
		debtorEntNumber = 0,
		creditorEntNumber = 0,
		debtorId = 0,
		caseNo = 0,
	} = metaData.data ?? {};

	/**
	 * changing the view as we click on the postal code
	 * of the form
	 */
	const changeView = (data: any) => {
		setDebtorFormData(data);
		window.scrollTo(0, 0);
		setViewForm(false);
	};

	const caseListClose = () => {};

	const componentAvalibility = (name: string): boolean => {
		return context.componentPermission['case']?.components['debtor']
			?.components[name]?.isEnabled;
	};

	const backToDebtorFormHandler = () => {
		setViewForm(true);
	};

	const navigationCaseDashboard = () => {
		getDashboardWidgetData &&
			getDashboardWidgetData({
				otherPartyBelongsType: entityType,
				otherPartyEntityId: caseId,
				creditorEntNo: creditorEntNumber,
				debtorEntRoleId: debtorId,
				caseId: caseId,
				CaseNo: caseNo,
				DebtorEntNo: debtorEntNumber,
				CaseType:
					entityType === CaseType.S
						? 'Subcase'
						: 'Case',
			});

		getAccountSummeryWidgetData &&
			getAccountSummeryWidgetData({
				EntityType:
					entityType === CaseType.S
						? 'Subcase'
						: 'Case',
				EntityId: caseId,
			});

		onMinimize();
	};
	return (
		<div className='space-content'>
			{viewForm ? (
				<>
					<$Affix offsetTop={80}>
						<div className='page-header header-border'>
							<div className='d-flex flex-row align-items-center justify-content-between'>
								<div className='d-flex align-items-center'>
									<$PageHeader
										onBack={
											navigationCaseDashboard
										}
										className='px-0'
										title={t(
											'US.COLLECTION.DEBTOR:INFO.DEBTORINFORMATION'
										)}
										data-testid='debtor-back'
									/>
								</div>
							</div>
						</div>
					</$Affix>

					<$Tabs
						className='bui-tabs'
						defaultActiveKey='1'>
						<$Tabs.TabPane
							tab={t(
								'US.COLLECTION.DEBTOR:INFO.DEBTOR'
							)}
							key='1'
							data-testid='debtor-general'>
							<General
								changeView={(
									data: any
								) =>
									changeView(
										data
									)
								}
								dataBkp={
									debtorFormData
								}
								{...props}
							/>
						</$Tabs.TabPane>
						{componentAvalibility(
							'caseList'
						) && (
							<$Tabs.TabPane
								tab={t(
									'US.COLLECTION.DEBTOR:INFO.CASE_LIST'
								)}
								key='2'
								data-testid='debtor-caseList'>
								<CaseList
									debtorType={
										debtorType
									}
									onClose={
										caseListClose
									}
									{...props}
								/>
							</$Tabs.TabPane>
						)}
						{componentAvalibility(
							'creditInformation'
						) && (
							<$Tabs.TabPane
								tab={t(
									'US.COLLECTION.DEBTOR:INFO.CREDITOR_INFORMATION'
								)}
								key='3'
								data-testid='debtor-creditInformation'>
								<CreditInformation
									entRoleId={
										debtorEntNumber
									}
									{...props}
								/>
							</$Tabs.TabPane>
						)}
						{componentAvalibility(
							'badDebtInformation'
						) && (
							<$Tabs.TabPane
								tab={t(
									'US.COLLECTION.DEBTOR:INFO.BAD_DEBT_INFORMATION'
								)}
								key='4'
								data-testid='debtor-badDebtInformation'>
								<DadDebtInfo
									{...props}
								/>
							</$Tabs.TabPane>
						)}
					</$Tabs>
				</>
			) : (
				<>
					<$Affix offsetTop={80}>
						<div className='page-header'>
							<div className='d-flex align-items-center'>
								<$PageHeader
									className='px-0 py-3 mr-n3'
									onBack={
										backToDebtorFormHandler
									}
									title={t(
										'US.COLLECTION.DEBTOR:INFO.SELECTPOSTALAREA'
									)}
								/>
							</div>
						</div>
					</$Affix>
				</>
			)}
		</div>
	);
};

const mapState = (state: RootState) => {
	const { dashboard, domainView } = state;
	const { debtorWidgetData } = dashboard;
	const { metaData } = domainView;
	return { metaData, debtorWidgetData };
};

const mapDispatch = {
	getDashboardWidgetData: DebtorWidgetActions.debtorWidget.get,
	getAccountSummeryWidgetData: accountSummery.get,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DebtorInformation);
