import { INonProductionDaysAction } from "us.collection.admin/interfaces";
import { IAPIAction } from "us.collection/interfaces";
import { NonProductionDay } from "us.collection.admin/constants/Actions";

export const nonProductionDays: Readonly<INonProductionDaysAction & IAPIAction> = {
  calendars: {
    get: (data) => ({
      type: NonProductionDay.GET_CALENDARS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: NonProductionDay.GET_CALENDARS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: NonProductionDay.GET_CALENDARS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },

  calendar: {
    saveWithHistory: (data, history) => ({
      type: NonProductionDay.SAVE_CALENDAR,
      payload: {
        data,
        history,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: NonProductionDay.SAVE_CALENDAR_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: NonProductionDay.SAVE_CALENDAR_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    getPrevious: (selectedYear, calendarId) => ({
      type: NonProductionDay.GET_PREVIOUS_CALENDAR,
      payload: {
        selectedYear,
        calendarId,
        isLoading: true,
      },
    }),
    getPreviousSuccess: (data, selectedYear) => ({
      type: NonProductionDay.GET_PREVIOUS_CALENDAR_SUCCESS,
      payload: {
        data,
        selectedYear,
        isLoading: false,
      },
    }),
    selectImport: (data) => ({
      type: NonProductionDay.IMPORT_CALENDAR_SELECT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    reset: (data) => ({
      type: NonProductionDay.CANCEL_SELECTED_CALENDAR,
      payload: {
        data,
        isLoading: true,
      },
    }),
    changeYear: (year, isExistingCal) => ({
      type: NonProductionDay.CHANGE_SELECTED_YEAR,
      payload: {
        data: year,
        isExistingCal: isExistingCal,
      },
    }),
  },

  redDays: {
    get: (data) => ({
      type: NonProductionDay.GET_NON_PRODUCTION_DAYS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: NonProductionDay.GET_NON_PRODUCTION_DAYS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: NonProductionDay.GET_NON_PRODUCTION_DAYS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: NonProductionDay.RESET_NON_PRODUCTION_DAYS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    set: (data) => ({
      type: NonProductionDay.GET_DEFAULT_NON_PRODUCTION_DAYS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    select: (data) => ({
      type: NonProductionDay.SELECT_NON_PRODUCTION_DAY,
      payload: {
        data,
      },
    }),
    addNew: (data) => ({
      type: NonProductionDay.ADD_NEW_NON_PRODUCTION_DAY,
      payload: {
        data,
      },
    }),
    selectCriteria: (data) => ({
      type: NonProductionDay.SELECT_CRITERIA,
      payload: {
        data,
      },
    }),
  },

  redDaysWithSmsSchedule: {
    get: (data) => ({
      type: NonProductionDay.GET_NON_PRODUCTION_DAYS_WITH_SMS_SCHEDULE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: NonProductionDay.GET_NON_PRODUCTION_DAYS_WITH_SMS_SCHEDULE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: NonProductionDay.GET_NON_PRODUCTION_DAYS_WITH_SMS_SCHEDULE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  
  smsSchedule: {
    get: (data) => ({
      type: NonProductionDay.GET_SMS_SCHEDULES,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: NonProductionDay.GET_SMS_SCHEDULES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: NonProductionDay.GET_SMS_SCHEDULES_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};