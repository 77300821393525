import React, { useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, withRouter, matchPath } from 'react-router-dom';
import { BoxIcons, IconTypes } from 'us.icons';
import { ConnectedProps, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Header } from '../Header';
import { getPaymentId } from 'us.collection.transactions/components/PaymentDistribution/Function';
import Common from 'us.common';
import { getSelectedMenuKeyByModule } from 'us.collection/functions';
import { RootState } from 'us.helper/types';

const { $Layout, $Menu } = Common.Components;

const TransactionLayout: React.FC<PropsFromRedux & RouteComponentProps> = (
	props
) => {
	const { t } = useTranslation();

	const params: { type: string; id: string; module: string } =
		useMemo(() => {
			const { params }: any = matchPath(
				props.location.pathname,
				{
					path: '/:type/:id?/:module?',
					exact: false,
					strict: false,
				}
			);
			return {
				type: params['type'] ?? '',
				id: params['id'] ?? '',
				module: params['module'] ?? '',
			};
		}, [props.location.pathname]);

	const { Content, Sider } = $Layout;
	const BODY_CLASS = 'change-body-color-dom';

	const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

	const { history, locationState, children, location } = props;

	useEffect(() => {
		document.body.classList.add(BODY_CLASS);
		return () => {
			document.body.classList.remove(BODY_CLASS);
		};
	}, []);

	const navigate = (url: string): void => {
		window.scrollTo(0, 0);
		history.push({
			...location,
			pathname: `/payment/${getPaymentId(
				locationState
			)}/${url}`,
		});
	};

	return (
		<$Layout style={{ minHeight: '100vh' }}>
			<Header />
			<$Layout>
				<Content style={{ margin: '0' }}>
					<main>{children}</main>
				</Content>
				<Sider
					collapsible
					collapsed={isCollapsed}
					onCollapse={(collapsed) =>
						setIsCollapsed(collapsed)
					}
					reverseArrow={true}>
					<$Menu
						theme='dark'
						selectedKeys={getSelectedMenuKeyByModule(
							params['module']
						)}
						mode='inline'>
						<$Menu.Item
							onClick={() =>
								navigate(
									'history'
								)
							}
							key='16'>
							<BoxIcons
								type={
									IconTypes.BOX_ICON
								}
								name='activity-history'
							/>
							<span>
								{t(
									'US.COLLECTION.LAYOUT:CASE.HISTORY'
								)}
							</span>
						</$Menu.Item>

						<$Menu.Item
							onClick={() =>
								navigate(
									'distribution'
								)
							}
							key='3'>
							<BoxIcons
								type={
									IconTypes.BOX_ICON
								}
								name='payment-distribution'
							/>
							<span>
								{t(
									'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.PAYMENTDISTRIBUTION'
								)}
							</span>
						</$Menu.Item>
					</$Menu>
				</Sider>
			</$Layout>
		</$Layout>
	);
};

const mapStateToProps = (state: RootState) => {
	const { router } = state;
	const { location } = router;
	const { pathname } = location;
	return {
		locationState: pathname,
	};
};

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(TransactionLayout));
