import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { useFormikContext } from 'formik';

import {
	$Col,
	$DatePicker,
	$Radio,
	$Row,
	$InputNumber,
	$Button,
	$AmountLabel,
	$Skeleton,
	$Alert,
} from 'us.common/components';
import { RootState } from 'us.helper/types';
import { InterestApplicationProps } from './Interfaces';
import { INTEREST_TYPE_OPTIONS } from './Constants';
import { InterestType } from 'us.collection.transactions/constants';
import * as Actions from 'us.collection.transactions/actions';
import moment from 'moment';
import { isPaidTransaction, sumCalculatedInterest } from './Functions';
import { SettingOutlined } from 'us.icons';
import { GetCalculatedInterest } from './Repository';
import { isArray } from 'lodash';
import { InterestRate } from 'us.collection.transactions/repository';

/**
 * @description - Interest Application component for the Register Transaction and the Edit Interest
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3272310785/Edit+Transaction+Interest+UI+Design+Implementations
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 03/04/2023
 */
const InterestApplication: React.FC<PropsFromRedux & InterestApplicationProps> =
	(props) => {
		const {
			title,
			isNewTransaction,
			currentDateFormat,
			transactionDetails,
			interests,
			standardInterest,
			metaData,
			interestRateBMD,
			calculateInterestRate,
			resetInterestPrediction,
			getInterestBMD,
		} = props;

		const { caseId } = metaData.data ?? {};

		const { t } = useTranslation();
		const { values, setFieldValue } = useFormikContext<any>();

		useEffect(() => {
			getInterestBMD &&
				getInterestBMD(InterestRate.call(caseId));
		}, []);

		const isEnabledInterestRate = useMemo(() => {
			return (
				values?.interestType ==
				InterestType.FIXED_INTEREST
			);
		}, [values.interestType]);

		/**
		 * @description - Handle interest type selection and rate changes
		 * @param {any} type - Selected interest type
		 */
		const handleInterestTypeChange = (type: any) => {
			resetInterestPrediction();
			if (type) {
				if (type == InterestType.NO_INTEREST) {
					setFieldValue(
						'interestRate',
						null,
						false
					);
				} else if (
					type == InterestType.STANDARD_INTEREST
				) {
					setFieldValue(
						'interestRate',
						standardInterest.data
							.interestRate
					);
				} else if (
					type == InterestType.FIXED_INTEREST
				) {
					setFieldValue(
						'interestRate',
						interestRateBMD.value
					);
				}
			}
		};

		// set dates that should disabled for selecting more than 14 days
		const disabledDate = (current: any) => {
			// disable future dates
			return !current.isAfter(
				values?.dueDate
					? moment(
							values?.dueDate,
							currentDateFormat
					  )
					: moment(
							transactionDetails?.dueDate,
							currentDateFormat
					  )
			);
		};

		/**
		 * @description - Handle interest calculation
		 */
		const handleCalculateInterest = () => {
			const payload = GetCalculatedInterest.call({
				...transactionDetails,
				...values,
			});
			calculateInterestRate && calculateInterestRate(payload);
		};

		/**
		 * @description - Handle calculate interest button disable options
		 */
		const disableCalculateInterestBtn = () => {
			try {
				const { interestType, interestFromDate } =
					values;
				const noInterest =
					interestType ==
					InterestType.NO_INTEREST;
				const interestPaid =
					transactionDetails.isInterestPaid;
				const today = moment();
				const futureFromDate =
					moment(interestFromDate).isAfter(today);
				return (
					noInterest ||
					interestPaid ||
					futureFromDate
				);
			} catch (error) {
				return false;
			}
		};

		return (
			<div>
				<$Skeleton
					loading={
						standardInterest.isLoading ||
						interestRateBMD.isLoading
					}
					active
					paragraph={{ rows: 1 }}>
					<$Row gutter={16}>
						<$Col span={24}>
							<div>
								<strong>
									{title ??
										t(
											'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.INTEREST_APPLICATION'
										)}
								</strong>
							</div>
							{!isNewTransaction && (
								<$Alert
									data-testid='interest-application-warning-msg'
									type='warning'
									message={
										transactionDetails.isInterestPaid
											? t(
													'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.INTEREST_APPLICATION_INTEREST_APPORTIONED_MESSAGE'
											  )
											: t(
													'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.INTEREST_APPLICATION_WARNING_MESSAGE'
											  )
									}
									className='mt-4'
								/>
							)}
							<div className='mt-4'>
								<$Radio
									name='interestType'
									optionValue='value'
									optionText='label'
									size='small'
									value={
										values.interestType ??
										InterestType.NO_INTEREST
									}
									defaultValue={
										InterestType.STANDARD_INTEREST
									}
									optionStyle='mr-3'
									options={INTEREST_TYPE_OPTIONS.map(
										(
											option
										) => {
											return {
												...option,
												label: t(
													option.label
												),
												disabled:
													(isPaidTransaction(
														transactionDetails
													) &&
														option.value ==
															InterestType.NO_INTEREST) ||
													transactionDetails.isInterestPaid,
											};
										}
									)}
									onChange={(
										e: any
									) =>
										handleInterestTypeChange(
											e
												.target
												.value
										)
									}
								/>
							</div>
						</$Col>
					</$Row>

					<$Row gutter={16} className='mt-3'>
						<$Col span={5}>
							<$DatePicker
								label={t(
									'US.COLLECTION.TRANSACTIONS:REGISTER.INTEREST_FROM_DATE'
								)}
								name='interestFromDate'
								value={
									moment(
										values.interestFromDate
									).isValid()
										? moment(
												values.interestFromDate
										  )
										: moment(
												transactionDetails.dueDate
										  ).add(
												1,
												'd'
										  )
								}
								placeholder={
									currentDateFormat
								}
								format={
									currentDateFormat
								}
								style={{
									width: '100%',
								}}
								size='small'
								formProps={{
									required: true,
								}}
								tabIndex={8}
								allowClear={
									true
								}
								disabled={
									isPaidTransaction(
										transactionDetails
									) ||
									transactionDetails.isInterestPaid
								}
								disabledDate={
									disabledDate
								}
							/>
						</$Col>
						{values.interestType !=
							InterestType.STANDARD_INTEREST && (
							<$Col span={5}>
								<$InputNumber
									size='small'
									name='interestRate'
									label={t(
										'US.COLLECTION.TRANSACTIONS:REGISTER.INTEREST_RATE'
									)}
									disabled={
										!isEnabledInterestRate ||
										transactionDetails.isInterestPaid
									}
									min={0}
									max={
										100
									}
									addonAfter={
										<>
											{
												'%'
											}
										</>
									}
								/>
							</$Col>
						)}
						{!isNewTransaction && (
							<$Col span={5}>
								<$Button
									data-testid='calculate-interest-button'
									type='dashed'
									size='small'
									icon={
										<SettingOutlined />
									}
									loading={
										interests.isLoading
									}
									onClick={
										handleCalculateInterest
									}
									disabled={disableCalculateInterestBtn()}
									style={{
										marginTop: 28,
									}}>
									{t(
										'US.COLLECTION.TRANSACTIONS:REGISTER.CALCULATE_INTEREST'
									)}
								</$Button>
							</$Col>
						)}
						{interests.isLoading && (
							<$Col
								span={9}
								className='pl-5'>
								<$Skeleton
									loading={
										interests.isLoading
									}
									active
									paragraph={{
										rows: 1,
									}}
								/>
							</$Col>
						)}
						{isArray(interests.data) &&
							interests.data.length >
								0 &&
							!isNewTransaction && (
								<$Col
									span={9}
									className='pl-5'>
									<div
										style={{
											marginBottom: 5,
										}}
										data-testid='interest-amount-prediction'>
										{t(
											'US.COLLECTION.TRANSACTIONS:REGISTER.INTEREST_PREDICTION'
										)}
									</div>
									<strong
										style={{
											fontSize: '110%',
										}}>
										<$AmountLabel
											value={sumCalculatedInterest(
												interests.data
											)}
										/>
									</strong>
								</$Col>
							)}
					</$Row>
				</$Skeleton>
			</div>
		);
	};

const mapStateToProps = (state: RootState) => {
	const { common, transaction, domainView } = state;
	const { currentDateFormat, currentCurrency, currentLanguage } = common;
	const { metaData } = domainView;
	const {
		transactionTypes,
		transactionDrawer,
		transactionDetails,
		isSaving,
		interestRateBMD,
		interestApplication,
	} = transaction;
	const { interests, standardInterest } = interestApplication;
	return {
		currentCurrency,
		currentLanguage,
		currentDateFormat,
		transactionDrawer,
		transactionDetails,
		transactionTypes,
		isSaving,
		interestRateBMD,
		interests,
		standardInterest,
		metaData,
	};
};

const { calculatedInterest } = Actions.interestApplication;
const { resetInterestPrediction, getInterestBMD } = Actions.transactions;

const mapDispatchToProps = {
	calculateInterestRate: calculatedInterest.get,
	resetInterestPrediction,
	getInterestBMD,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(InterestApplication);
