import React, { useState, useEffect } from "react";
import ReactExport from "react-data-export";
import Common from "us.common";
import { IconTypes, BoxIcons } from "us.icons";
import { useTranslation } from "react-i18next";
import { columnGenerator, columnDataGenerator } from "./Functions";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const { $Button, $Tooltip } = Common.Components;

function ExcelExporter(props) {
  const { t } = useTranslation(["US.COLLECTION.COMMON"]);
  const { dataSet, columns, name, format } = props;

  const multiDataSet = [
    {
      columns,
      data: columnDataGenerator(dataSet, columns, format),
    },
  ];

  return (
    <ExcelFile
      filename={name}
      element={
        <$Tooltip placement="topLeft" title={t("US.COLLECTION.COMMON:COMMON.EXPORTTOEXCELSHEET")}>
          <$Button size="small" className="">
            <BoxIcons type={IconTypes.BOX_ICON} name="excel" />
          </$Button>
        </$Tooltip>
      }
    >
      <ExcelSheet dataSet={multiDataSet} name={name}></ExcelSheet>
    </ExcelFile>
  );
}

export default ExcelExporter;
