import { IInitialState } from 'us.collection.case/reducers/ManualFollowUps/Interface';
import { initialValue } from './InitialValue';

export const initialState: IInitialState = {
	categories: [],
	assigners: [],
	reasons: { data: [], isFetching: false },
	initialValue,
	list: {
		due: [],
		upcoming: [],
		data: [],
		isLoading: false,
	},
	isManualFollowUpSaving: false,
	isReasonSaving: false,
	followUpSummary: {
		data: {
			due: 0,
			upcoming: 0,
		},
		isLoading: true,
	},
};
