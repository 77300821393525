import { IBMDChangeHistory } from "us.collection/interfaces";

/**
 * @function
 * @description - To get a proper id for the newly adding record to the list of hitory changed items, temporaly
 * @param {IBMDChangeHistory[]} historyItems - the list of items in the history grid
 * @return {number} - the unique ID
 */
 export const getHistoryItemId = (historyItems: IBMDChangeHistory[]): number => {
  const negativeIds = historyItems.filter((item) => item.id < 0);
  if (negativeIds.length === 0) {
    return -1;
  } else {
    return Math.min(...negativeIds.map((item) => item.id)) - 1;
  }
};
