import moment, { Moment } from "moment";

/**
 * To verify if the date parameter passed is a date string or not
 * @param dateString any string value
 * @returns Moment date object or null
 */
export const isValidDate = (dateString: string): Moment | null => {
  return moment(dateString, moment.ISO_8601, true).isValid() ? moment(dateString).utcOffset(dateString,true) : null;
};
