import { IAPIAction } from "us.collection/interfaces";
import { CloseCase } from "us.collection/constants";

export const CloseCaseAction: Readonly<IAPIAction> = {
  reasons: {
    get: (data) => ({
      type: CloseCase.GET_CLOSE_CASE_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CloseCase.GET_CLOSE_CASE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CloseCase.GET_CLOSE_CASE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    openDrawer: (data) => ({
      type: CloseCase.CLOSE_CASE_POPOVER,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  closeCase: {
    save: (data) => ({
      type: CloseCase.CLOSE_CASE_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CloseCase.CLOSE_CASE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CloseCase.CLOSE_CASE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
