import _ from "lodash";
import { TroubleShootSearchType } from "us.collection/components/WorkflowTroubleshoot";

export function Category(this: any) {
  return {
    categoryId: this.categoryId,
    name: this.categoryName,
    displayName: this.displayName,
    description: this.description,
    stateType: this.stateType,
  };
}

export function WorkflowState(this: any, versions: Array<any>) {
  const xamlContent = _.find(versions, {
    versionNo: this.workflowVersion,
  })?.xamlContent;
  return {
    id: this.id,
    accessLevel: this.accessLevel,
    entityId: Number(this.entityId),
    workflowGroupStateId: this.workflowGroupStateId,
    workflowType: this.workflowType,
    name: this.name,
    displayName: this.displayName,
    stateGroupName: this.stateGroupName,
    dueInDays: Number(this.dueInDays),
    stateType: this.stateType,
    activeStatus: this.activeStatus,
    description: this.description,
    adapterName: this.adapterName,
    copiedXamlContent:
      this.workflowVersion != -1 && xamlContent ? xamlContent : "",
  };
}

export function WorkflowEvent(this: any) {
  return {
    eventId: this.id,
    accessLevel: this.accessLevel,
    entityId: Number(this.entityId),
    workflowType: this.workflowType,
    name: this.name,
    displayName: this.displayName,
    description: this.description,
    adapterName: this.adapterName,
    isDomainViewActive: this.isDomainViewActive,
    workflowGroupStateId: this.workflowGroupStateId,
  };
}

export function WorkflowGroup(this: any) {
  return {
    accessLevel: this.entityType,
    entityId: Number(this.entityId),
  };
}

export function StateDelete(this: any, value: number = 1) {
  return {
    id: this.id,
    categoryId: this.categoryId,
    requestPayload: [
      {
        op: "add",
        path: "/activeStatus",
        value,
      },
    ],
  };
}

export function ReExecuteActivity(this: any, operationType: string) {
  return {
    entityId: this.caseId,
    activityId: this.activityId,
    operationType,
  };
}

export function StateChange(this: any) {
  return {
    stateId: this.id,
    categoryId: this.stateGroupId,
    searchFilters: this.searchFilters,
    requestPayload: [
      {
        op: "add",
        path: "/caseId",
        value: this.caseId,
      },
    ],
  };
}

export function MonitorList(this: any) {
  return {
    limit: 100,
    offset: this.offset,
    category: this.category ?? "",
    searchKey: this.searchKey ?? TroubleShootSearchType.ALL,
    searchText: this.searchText ?? "",
  };
}
