import React, { useState, useEffect, memo } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Common from "us.common";
import { Link } from "react-router-dom";
import { AddCreditorGroup } from "../Add";
import { creditorGroup } from "us.collection.admin/actions";
import { INavigationData, IRootState } from "us.collection/interfaces";
import { IOnFilter, IOnSort } from "us.common/components/antd/TableTree";
import { ManageCreditorInformation } from "us.collection.creditor/components/ManageCreditors";
import { useLocation } from "react-router-dom";
import { initialDrawerData } from "us.collection.admin/components/CreditorGroup/Constants";

const {
  $Popover,
  $Popconfirm,
  $Button,
  $Divider,
  $PageHeader,
  $Drawer,
  $Affix,
  $Skeleton,
  $TableTree,
} = Common.Components;

import {
  PlusOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "us.icons";

interface ICreditorGroupHome {
  onMinimize?(state?: any): void;
  currentCurrency: string;
  currentLanguage: string;
  currentDateFormat: string;
  getCreditorGroups: any;
  creditorGroups: any;
  manageCreditorGroupDrawer?: any;
  drawer?: any;
  selectCreditor?: any;
  resetSelectedCreditor?: any;
  deleteCreditorGroup?: any;
  isCreditorManagement?: boolean;
  drawerContentChangeClick?: any;
}

export interface ISortOrder {
  columnKey: string;
  order: any;
}

const CreditorGroupHome: React.FC<ICreditorGroupHome> = memo((props) => {
  const { t } = useTranslation(["US.COLLECTION.CASE", "US.COLLECTION.COMMON"], {
    useSuspense: true,
  });
  const { state } = useLocation();

  const {
    getCreditorGroups,
    creditorGroups,
    manageCreditorGroupDrawer,
    drawer,
    selectCreditor,
    resetSelectedCreditor,
    deleteCreditorGroup,
    onMinimize,
    isCreditorManagement,
    drawerContentChangeClick,
  } = props;

  const [popVisible, setPopVisibile] = useState<number>(-1);
  const [drawerInfo, setDrawerInfo] = useState<any>(initialDrawerData);

  useEffect(() => {
    getCreditorGroups();
  }, []);

  const onCloseAddNewCreditorDrawer = () => {
    setDrawerInfo(initialDrawerData);
  };

  // Popover actions in table
  const content = (record: any) => (
    <div className="table-more-content">
      <div
        className="d-flex flex-row more-item"
        onClick={() => {
          setPopVisibile(-1);
          editCreditorGroupHandler(record);
        }}
      >
        <div className="p-1">
          <EditOutlined />
        </div>
        <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.EDIT")}</div>
      </div>
      <$Popconfirm
        title={t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.DELETECONFIRM")}
        onConfirm={() => {
          setPopVisibile(-1);
          deleteCreditorGroupHandler(record);
        }}
        onCancel={() => setPopVisibile(-1)}
      >
        <div className="d-flex mb-2 flex-row more-item text-error">
          <div className="p-1">
            <DeleteOutlined />
          </div>
          <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.DELETE")}</div>
        </div>
      </$Popconfirm>
      <div
        className="d-flex flex-row more-item"
        onClick={() => {
          setPopVisibile(-1);
          setDrawerInfo({
            title: t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.NEW_CREDITOR"),
            visibility: true,
            groupData: record,
          });
        }}
      >
        <div className="p-1">
          <PlusOutlined />
        </div>
        <div className="p-1">
          {t("US.COLLECTION.COMMON:COMMON.NEW_CREDITOR")}
        </div>
      </div>
    </div>
  );

  const columns: any = [
    {
      title: "",
      key: "more",
      dataIndex: "more",
      width: 50,

      customRenderChild: (text: any, record: any) => {
        return (
          <$Popover
            trigger="click"
            placement="rightTop"
            content={content(record)}
            destroyTooltipOnHide
            visible={record.id == popVisible && !drawerInfo?.visibility}
            onVisibleChange={(visible: boolean) => {
              setPopVisibile(visible ? record.id : -1);
            }}
          >
            <$Button icon={<MoreOutlined />} size="small" />
          </$Popover>
        );
      },
    },
    {
      title: t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.NO"),
      dataIndex: "id",
      key: "id",
      width: 120,
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
      customRenderChild: (text: any, record: any) => {
        const { invoiceNumber, kid } = record;
        return (
          <div>
            {!isCreditorManagement && (
              <Link
                to={{
                  pathname: `/creditor-group/${record?.id}`,
                  state: {
                    ...(state as INavigationData),
                    currentTab: "creditorgroup",
                  },
                }}
              >
                {text > 0 ? text : ""}
              </Link>
            )}
            {isCreditorManagement && text > 0 ? text : ""}
          </div>
        );
      },
    },
    {
      title: t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.NAME"),
      dataIndex: "groupName",
      key: "groupName",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
      ellipsis: true,
      width: "30%"
    },
    {
      title: t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.NUMBEROFCREDITORS"),
      dataIndex: "numberOfCreditors",
      key: "numberOfCreditors",
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
      ellipsis: true,
      width: 175,
    },
    {
      title: t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.DESCRIPTION"),
      dataIndex: "description",
      key: "description",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
      ellipsis: true,
      width: "70%"
    },
  ];

  const addNewCreditorGroupHandler = () => {
    resetSelectedCreditor({});
    manageCreditorGroupDrawer({
      title: t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.ADDNEWCREDITORGROUP"),
      visible: true,
      isNewCreditorGroup: true,
    });
  };

  const editCreditorGroupHandler = (record: any) => {
    selectCreditor(record);
    manageCreditorGroupDrawer({
      title: t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.EDIT_CREDITOR_GROUP"),
      visible: true,
      isNewCreditorGroup: false,
    });
  };

  const deleteCreditorGroupHandler = (record: any) => {
    deleteCreditorGroup(record?.id);
  };

  const onClose = () => {
    resetSelectedCreditor({});
    manageCreditorGroupDrawer({
      title: "",
      visible: false,
      isNewCreditorGroup: true,
    });
  };

  const handleSort: IOnSort = (sortData, dataSource) => {
    return sortData(dataSource);
  };

  const handleFilter: IOnFilter = (searchData, dataSource) => {
    return searchData(dataSource);
  };

  const rowDoubleClickHandler = (selectedGroupData: any, rowIndex: any) => {
    drawerContentChangeClick({
      selectedGroupData,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={(values: any, actions: any) => {}}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        ...rest
      }: any) => (
        <>
          <div className="space-content">
            {!isCreditorManagement && (
              <$Affix offsetTop={80}>
                <div className="page-header header-border">
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <$PageHeader
                        className="px-0"
                        title={t(
                          "US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.MANAGECREDITORGROUPS"
                        )}
                        onBack={() => onMinimize && onMinimize(state)}
                      />
                      <$Divider className="bui-devider" type="vertical" />
                      <$Skeleton
                        loading={creditorGroups?.isFetching}
                        active
                        paragraph={{ rows: 0 }}
                      >
                        <$Button
                          onClick={() => addNewCreditorGroupHandler()}
                          type="dashed"
                          size="small"
                          icon={<PlusOutlined />}
                        >
                          {t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.ADDNEW")}
                        </$Button>
                      </$Skeleton>
                    </div>
                  </div>
                </div>
              </$Affix>
            )}

            <div className="mt-3">
              <InfoCircleOutlined className="mr-2" />
              {t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.INFO")}
            </div>

            <$Skeleton
              loading={creditorGroups?.isFetching}
              active
              paragraph={{ rows: 2 }}
            >
              <$TableTree
                rowKey="arItemNo"
                data={creditorGroups?.data}
                size="small"
                className="mt-3"
                onSort={handleSort}
                onFilter={handleFilter}
                filterOnType={true}
                resetOnSourceChange={true}
                bordered
                pagination={{ defaultPageSize: 200 }}
                scroll={{ x: 1200, y: "calc(100vh - 350px)" }}
                columns={
                  !isCreditorManagement
                    ? columns
                    : columns.filter((col: any) => col.dataIndex !== "more")
                }
                onRow={(record: any, rowIndex: any) => {
                  return {
                    onDoubleClick: (event: any) => {
                      if (isCreditorManagement) {
                        rowDoubleClickHandler(record, rowIndex);
                      } else {
                        editCreditorGroupHandler(record);
                      }
                    },
                  };
                }}
              />
            </$Skeleton>
          </div>

          <$Drawer
            title={drawer?.title}
            width={500}
            visible={drawer?.visible}
            onClose={onClose}
            destroyOnClose
          >
            <AddCreditorGroup onClose={onClose} />
          </$Drawer>

          <$Drawer
            title={drawerInfo?.title}
            width={"80%"}
            visible={drawerInfo?.visibility}
            onClose={onCloseAddNewCreditorDrawer}
            destroyOnClose
          >
            <ManageCreditorInformation
              isDrawer={true}
              isGroupDisabled={true}
              creditorGroupData={drawerInfo?.groupData}
              onClose={onCloseAddNewCreditorDrawer}
            />
          </$Drawer>
        </>
      )}
    </Formik>
  );
});

const mapStateToProps = (state: IRootState) => {
  const { common, creditorGroup } = state;
  const { currentCurrency, currentLanguage, currentDateFormat } = common;
  const { creditorGroups, drawer } = creditorGroup;

  return {
    currentCurrency,
    currentLanguage,
    currentDateFormat,
    creditorGroups,
    drawer,
  };
};

const mapDispatchToProps = {
  getCreditorGroups: creditorGroup.creditorGroups.get,
  manageCreditorGroupDrawer: creditorGroup.drawer.set,
  selectCreditor: creditorGroup.creditorGroup.select,
  resetSelectedCreditor: creditorGroup.creditorGroup.reset,
  deleteCreditorGroup: creditorGroup.creditorGroup.delete,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditorGroupHome);
