import { DomainView } from 'us.collection/constants';
import { getGDPRView } from 'us.collection/functions';
import { MainSearchConstants } from 'us.common/constants';
import Constants from 'us.collection.case/constants';

import { initialState } from './State';
const { ObjectionAndAgreements } = Constants.ACTIONS;
const { CLAIM_OF_PAYMENT_SUCCESS } = ObjectionAndAgreements;
const {
	GET_CASE_ID,
	GET_CASE_ID_SUCCESS,
	GET_CASE_ID_FAIL,

	GET_META_DATA,
	GET_META_DATA_SUCCESS,
	GET_META_DATA_FAIL,

	GET_WORKFLOW_STATUS,
	GET_WORKFLOW_STATUS_FAIL,
	GET_WORKFLOW_STATUS_SUCCESS,

	RESET_DOMAIN_VIEW_DATA,

	GET_GDPR_COMMENT_VIEW_START,
	GET_GDPR_COMMENT_VIEW_SUCCESS,
	GET_GDPR_COMMENT_VIEW_FAIL,
	GET_GDPR_COMMENT_VIEW_RESET,

	ADD_GDPR_COMMENT_START,
	ADD_GDPR_COMMENT_SUCCESS,
	ADD_GDPR_COMMENT_FAIL,
} = DomainView;

const { UPDATE_VIEWED_LIST } = MainSearchConstants;

export default (state = initialState, { payload, type }: any) => {
	switch (type) {
		case UPDATE_VIEWED_LIST:
			return Object.assign({}, state, {
				...initialState,
			});
		case GET_GDPR_COMMENT_VIEW_START:
			return Object.assign({}, state, {
				...getGDPRView(payload.data),
			});
		case GET_GDPR_COMMENT_VIEW_SUCCESS:
			return Object.assign({}, state, {
				showGDPRCommentBox: false,
				showDashboard: false,
			});
		case GET_GDPR_COMMENT_VIEW_FAIL:
			return Object.assign({}, state, {
				showGDPRCommentBox: false,
				showDashboard: false,
			});
		case GET_GDPR_COMMENT_VIEW_RESET:
			return Object.assign({}, state, {
				showGDPRCommentBox: false,
				showDashboard: false,
			});
		case ADD_GDPR_COMMENT_START:
			return Object.assign({}, state, {
				gdprCommentIsSaving: true,
			});
		case ADD_GDPR_COMMENT_SUCCESS:
			return Object.assign({}, state, {
				showGDPRCommentBox: false,
				showDashboard: true,
				gdprCommentIsSaving: false,
			});
		case ADD_GDPR_COMMENT_FAIL:
			return Object.assign({}, state, {
				showGDPRCommentBox: true,
				showDashboard: false,
				gdprCommentIsSaving: false,
			});
		case RESET_DOMAIN_VIEW_DATA:
			return Object.assign({}, state, {
				...initialState,
			});
		case GET_CASE_ID:
			return Object.assign({}, state, {
				case: {
					data: {
						caseId: '',
						caseNo: '',
						caseType: '',
					},
					isLoading: true,
				},
				workflow: {
					data: null,
					isLoading: true,
				},
				metaData: {
					...initialState.metaData,
					isLoading: true,
				},
			});
		case GET_CASE_ID_SUCCESS:
			return Object.assign({}, state, {
				case: {
					data: {
						...state.case.data,
						...payload.data,
					},
					isLoading: false,
				},
			});
		case GET_CASE_ID_FAIL:
			return Object.assign({}, state, {
				case: {
					data: {
						...state.case.data,
						caseId: '',
					},
					isLoading: false,
				},
			});
		case GET_META_DATA:
			return Object.assign({}, state, {
				workflow: {
					data: null,
					isLoading: true,
				},
				metaData: {
					...initialState.metaData,
					isLoading: true,
				},
			});
		case GET_META_DATA_SUCCESS:
			return Object.assign({}, state, {
				metaData: {
					...initialState.metaData,
					isLoading: false,
					isError: false,
					data: payload.data,
				},
			});
		case GET_META_DATA_FAIL:
			return Object.assign({}, state, {
				metaData: {
					...initialState.metaData,
					isLoading: false,
					isError: true,
					data: payload.data,
				},
			});
		case GET_WORKFLOW_STATUS:
			return Object.assign({}, state, {
				workflow: {
					data: null,
					isLoading: true,
				},
			});
		case GET_WORKFLOW_STATUS_SUCCESS:
			return Object.assign({}, state, {
				workflow: {
					data: payload.data,
					isLoading: false,
				},
			});
		case GET_WORKFLOW_STATUS_FAIL:
			return Object.assign({}, state, {
				workflow: {
					...state.workflow,
					isLoading: false,
				},
			});
		case CLAIM_OF_PAYMENT_SUCCESS:
			return Object.assign({}, state, {
				metaData: {
					...state.metaData,
					data: {
						...state.metaData.data,
						isObjected: true,
					},
				},
			});
		default:
			return state;
	}
};
