import React from 'react';
import Common from 'us.common';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { PlusOutlined } from 'us.icons';
import { EntityFollowUpPanel } from 'us.collection.case/constants/ManualFollowUps';
import { followUps } from 'us.collection/actions';
import {
	EntityFollowUpTabs,
	initialEntityDrawer,
} from 'us.collection/constants';
import './Style.scss';
import { isClosedCase } from 'us.collection/functions';
import { appInsights } from 'us.helper';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { RootState } from 'us.helper/types';

const { $Button, $Divider, $Skeleton } = Common.Components;
const { entity } = followUps;

const Home: React.FC<PropsFromRedux> = (props) => {
	const {
		metaData,
		accountSummeryWidgetData,
		followUpSummary,
		manageEntityFollowUpDrawer,
	} = props;

	const { t } = useTranslation();
	const { case: caseDetail } = accountSummeryWidgetData.data ?? {};
	const { closedDate = '' } = caseDetail ?? {};
	const { due, upcoming } = followUpSummary.data;

	/**
	 * Drawer manage function
	 */
	const manageDrawer = (
		isNewManualFollowUp: boolean,
		panel: EntityFollowUpPanel
	) => {
		try {
			manageEntityFollowUpDrawer &&
				manageEntityFollowUpDrawer({
					visible: true,
					selectedTab:
						EntityFollowUpTabs.FOLLOW_UP,
					panel,
					manualFollowUp: {
						isNewManualFollowUp,
						panel,
					},
				});
		} catch (error) {
			manageEntityFollowUpDrawer &&
				manageEntityFollowUpDrawer(initialEntityDrawer);
			appInsights.trackException(
				`Error in ManualFollowUp-Home manageDrawer - ${error}`
			);
		}
	};

	return (
		<div className='widget followup-widget'>
			<div className='widget-header d-flex align-items-center px-0'>
				<span className='widget-title'>
					{t(
						'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.FOLLOWUP'
					)}
				</span>
				<$Divider className='mx-3' type='vertical' />
				<$Button
					className='px-0'
					type='link'
					size='small'
					icon={<PlusOutlined />}
					disabled={
						isClosedCase(closedDate) ||
						(metaData.data &&
							isSubCaseTransferredToCase(
								metaData.data
							))
					}
					onClick={() =>
						manageDrawer(
							true,
							EntityFollowUpPanel.DUE
						)
					}>
					{t(
						'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.NEW_FOLLOW-UP'
					)}
				</$Button>
			</div>
			<div className='widget-body px-0'>
				<div className='fw-btn-wrap'>
					<$Skeleton
						loading={
							followUpSummary.isLoading
						}
						active
						paragraph={{ rows: 0 }}>
						<$Button
							onClick={() =>
								manageDrawer(
									false,
									EntityFollowUpPanel.DUE
								)
							}
							className='fw-due-btn'
							type='primary'
							shape='round'
							size='small'>
							<span className='mr-3'>
								{t(
									'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.DUE'
								)}
							</span>
							<strong>{due}</strong>
						</$Button>
						<$Button
							onClick={() =>
								manageDrawer(
									false,
									EntityFollowUpPanel.UPCOMING
								)
							}
							className='ml-2 fw-upc-btn'
							shape='round'
							size='small'>
							<span className='mr-3'>
								{t(
									'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.UPCOMING'
								)}
							</span>
							<strong>
								{upcoming}
							</strong>
						</$Button>
					</$Skeleton>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: RootState) => {
	const { manualFollowUps, dashboard, domainView } = state;
	const { followUpSummary } = manualFollowUps;
	const { accountSummeryWidgetData } = dashboard;
	const { metaData } = domainView;
	return {
		metaData,
		accountSummeryWidgetData,
		domainViewData: domainView.case,
		followUpSummary,
	};
};

const mapDispatchToProps = {
	manageEntityFollowUpDrawer: entity.manageDrawer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Home);
