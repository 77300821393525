import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PlusOutlined, MinusOutlined, ExclamationCircleFilled } from 'us.icons';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import Common from 'us.common';
import './DebtorInformation.scss';
import AddressHistory from './AddressHistory';
import EntityHistory from './DebtorHistory';
import _ from 'lodash';
import { debtor } from 'us.collection.debtor/actions';
import { ValidationSchema } from '../Validation';
import { entity } from 'us.common/actions';
import {
  ISummaryAmount,
  IBase,
} from "us.collection.debtor/components/DebtorInfo/Interfaces";
import PostalArea from "us.common/containers/PostalArea/PostalArea";
import { europeanCountry } from "us.common/constants";
import { DateFormats } from "us.collection.debtor/components/DebtorInfo/Constants";
import { formInitialValue } from "us.collection.debtor/components/DebtorInfo/Functions";
import { IRootState } from "us.collection/interfaces";
import { validatePersonNo } from "us.collection.debtor/components/DebtorInfo/Functions";
import { appInsights } from "us.helper";
import { isSubCaseTransferredToCase, getPhoneNumbers } from "us.common/functions";
import { DEFAULT_COUNTRY_CODE } from 'us.common/constants';

const {
	$Form,
	$Button,
	$Divider,
	$Collapse,
	$Input,
	$Table,
	$Select,
	$Row,
	$Col,
	$DatePicker,
	$Skeleton,
	$Switch,
	$Typography,
	$Drawer,
	$Popover,
	$SelectGroup,
	$AmountLabel,
	$DateLabel,
} = Common.Components;

const General: React.FC<IBase> = (props) => {
	const { t } = useTranslation();
	const [creditorTableData, setCreditorTableData] = useState<Array<any>>(
		[]
	);
	const [addressUnknownConfirmVisible, setAddressUnknownConfirmVisible] =
		useState<boolean>(false);
	const [filledData, setFIlledData] = useState<any>({});
	const [AddressHistoryVisible, setAddressHistoryVisible] =
		useState<boolean>(false);
	const [DebtorHistoryVisible, setDebtorHistoryVisible] =
		useState<boolean>(false);
	const [expandKey, setExpandKey] = useState<Array<string>>([]);
	const [summaryData, setSummaryData] = useState<Array<ISummaryAmount>>([
		{
			numberOfCases: 0,
			reminder: '0.00',
			collection: '0.00',
			court: '0.00',
			surviellance: '0.00',
			balance: '0.00',
		},
	]);

	const {
		currentDateFormat,
		isFetching,
		debtorInformation,
		isFetchingCreditorInfo,
		getCreditorInfo,
		creditorInfo,
		getOtherInfo,
		isFetchingOtherInformation,
		otherInfo,
		save,
		getEntity,
		drawerName,
		isSaving,
		searchPostalcode,
		searchMunicipility,
		postalcode,
		metaData,
	} = props;
	const { debtorId, arId } = metaData.data ?? {};

	useEffect(() => {
		if (metaData.data) {
			getEntity(debtorId);
			getOtherInfo(arId);
		}
	}, [metaData]);

	useEffect(() => {
		if (creditorInfo.length !== 0) {
			const temData: any = [];
			const summaryTem: any = [];
			try {
				Object.values(
					_.groupBy(creditorInfo, 'pid')
				).map((opr: any) => {
					if (opr.length > 1) {
						temData.push({
							pid: opr[0].pid,
							creditorName:
								opr[0]
									.creditorName,
							numberOfCases:
								opr[0]
									.numberOfCases,
							balance: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.balance
									)
									.map(
										Number
									)
							).toFixed(2),
							collectionFee: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.collectionFee
									)
									.map(
										Number
									)
							).toFixed(2),
							reminderFee: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.reminderFee
									)
									.map(
										Number
									)
							).toFixed(2),
							courtFee: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.courtFee
									)
									.map(
										Number
									)
							).toFixed(2),
							surveillanceFee: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.surveillanceFee
									)
									.map(
										Number
									)
							).toFixed(2),
						});
					} else {
						temData.push({
							pid: opr[0].pid,
							creditorName:
								opr[0]
									.creditorName,
							numberOfCases:
								opr[0]
									.numberOfCases,
							balance: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.balance
									)
									.map(
										Number
									)
							).toFixed(2),
							collectionFee: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.collectionFee
									)
									.map(
										Number
									)
							).toFixed(2),
							reminderFee: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.reminderFee
									)
									.map(
										Number
									)
							).toFixed(2),
							courtFee: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.courtFee
									)
									.map(
										Number
									)
							).toFixed(2),
							surveillanceFee: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.surveillanceFee
									)
									.map(
										Number
									)
							).toFixed(2),
						});
					}
				});

				summaryTem.push({
					numberOfCases: _.sum(
						temData
							.map(
								(o: any) =>
									o.numberOfCases
							)
							.map(Number)
					),
					reminder: _.sum(
						temData
							.map(
								(o: any) =>
									o.reminderFee
							)
							.map(Number)
					).toFixed(2),
					collection: _.sum(
						temData
							.map(
								(o: any) =>
									o.collectionFee
							)
							.map(Number)
					).toFixed(2),
					court: _.sum(
						temData
							.map(
								(o: any) =>
									o.courtFee
							)
							.map(Number)
					).toFixed(2),
					surviellance: _.sum(
						temData
							.map(
								(o: any) =>
									o.surveillanceFee
							)
							.map(Number)
					).toFixed(2),
					balance: _.sum(
						temData
							.map(
								(o: any) =>
									o.balance
							)
							.map(Number)
					).toFixed(2),
				});
				setSummaryData(summaryTem);
				setCreditorTableData(temData);
			} catch (error) {
				appInsights.trackException(
					`Debtor Information General Data view data preparation error - ${error}`
				);
				setSummaryData(summaryTem);
				setCreditorTableData(temData);
			}
		}
	}, [debtorInformation, creditorInfo, metaData]);

	/**
	 * change the view to postal code selection
	 */
	const changeView = (rest: any) => {
		rest.setFieldValue('postalAreaVisible', true);
	};
	const postalAreaClose = (rest: any) => {
		rest.setFieldValue('postalAreaVisible', false);
	};

	const onChangeCountry = (value: any, rest: any, values: any) => {
		rest.setValues({
			...values,
			selectedCountryCode: value,
			popConfirmVisible: true,
		});
	};

	const saveData = (data: any) => {
		try {
			const {
				debtorType,
				birthDate,
				companyNo,
				personNo,
				addrUnknown,
				email,
				home,
				sms,
			} = data;
			if (debtorType === 'N') {
				data.birthDate = null;
				data.idNo = companyNo
					?.trim()
					?.replace(/ /g, '');
			} else {
				data.birthDate = moment(birthDate).isValid()
					? moment(birthDate).format(
							DateFormats.REQ
					  )
					: null;
				data.idNo = personNo?.trim()?.replace(/ /g, '');
			}
			data.pid = '';
			data.phoneNumbers = getPhoneNumbers(data);
			data.isAddressknown = !addrUnknown;
			data.email = email?.trim();
			(data.telephone = home?.trim()),
				(data.sms = sms?.trim()),
				save(data);
		} catch (error) {
			appInsights.trackException(
				`Save debtor information data preparation function failed.`
			);
		}
	};
	const handleSubmit = (data: any) => {
		if (data?.zipCode.length !== 0) {
			if (data?.addrUnknown) {
				setAddressUnknownConfirmVisible(true);
				setFIlledData(data);
			} else {
				setAddressUnknownConfirmVisible(false);
				saveData(data);
			}
		} else {
			saveData(data);
		}
	};

	const handlePostalDoubleClick = (
		selectedPostalAreaDetail: any,
		rest: any,
		values: any
	) => {
		const { MunicipalityCode, MunicipalityName, PostalCode, City } =
			selectedPostalAreaDetail;
		rest.setValues({
			...values,
			municipalityCode: MunicipalityCode,
			municipalityName: MunicipalityName,
			zipCode: PostalCode,
			zipName: City,
			postalAreaVisible: false,
		});
	};
	const handleAddressUnknown = (
		checked: boolean,
		rest: any,
		values: any
	) => {
		if (checked) {
			rest.setValues({
				...values,
				addrUnknown: true,
				zipCode: '',
				zipName: '',
				municipalityCode: '',
				municipalityName: '',
			});
		}
	};

	// Credit Information Table Columns and Data
	const columns: any = [
		{
			title: t('US.COLLECTION.DEBTOR:GENERAL.CREDITOR'),
			dataIndex: 'Creditor',
			key: 'Creditor',
			render: (text: any, record: any) => {
				const { pid, creditorName } = record;
				return (
					<div>
						{pid}
						{pid && ' - '}
						{creditorName}
					</div>
				);
			},
		},
		{
			title: t('US.COLLECTION.DEBTOR:GENERAL.NO_OF_CASES'),
			dataIndex: 'numberOfCases',
			key: 'numberOfCases',
		},
		{
			title: t('US.COLLECTION.DEBTOR:GENERAL.BALANCE'),
			dataIndex: 'balance',
			key: 'balance',
			align: 'right',
			render: (text: any, record: any) => {
				const { balance } = record;
				return (
					<span>
						{balance != 0 && (
							<$AmountLabel
								value={Number(
									balance
								)}
							/>
						)}
						{balance == 0 && '-'}
					</span>
				);
			},
		},
	];

	const AddressHistoryHandler = () => {
		setAddressHistoryVisible(true);
	};
	const AddressHistoryClose = () => {
		setAddressHistoryVisible(false);
	};
	const DebtorHistoryHandler = () => {
		setDebtorHistoryVisible(true);
	};
	const DebtorHistoryClose = () => {
		setDebtorHistoryVisible(false);
	};

	const handlePanel = (keys: any) => {
		setExpandKey(keys);
		if (keys?.includes('3') && !expandKey?.includes('3')) {
			getCreditorInfo(arId);
		}
	};

	const handleBirthday = (values: any, rest: any) => {
		const personNo = values.target.value;
		const data = validatePersonNo(personNo.replaceAll(/\D/g, ''));
		if (data.isValid) {
			formatPersonNo(personNo, rest);
			rest.setFieldValue('birthDate', moment(data.birthDate));
		} else {
			rest.setFieldValue('birthDate', '');
		}
	};

	const handlePersonNo = (data: any, rest: any) => {
		rest.setFieldValue('birthDate', moment(data));
	};

	const countryPopOverConfirmYes = (rest: any, values: any) => {
		rest.setValues({
			...values,
			addrUnknown: true,
			countryCode: values.selectedCountryCode,
			zipCode: '',
			zipName: '',
			municipalityCode: '',
			municipalityName: '',
			popConfirmVisible: false,
		});
	};

	const countryPopOverConfirmNo = (rest: any) => {
		rest.setFieldValue('popConfirmVisible', false);
	};

	const postalCodePopOverConfirmYes = (rest: any) => {
		setAddressUnknownConfirmVisible(false);
		rest.setFieldValue('addrUnknown', false);
		saveData({ ...filledData, addrUnknown: false });
	};
	const postalCodePopOverConfirmNo = (rest: any) => {
		setAddressUnknownConfirmVisible(false);
		rest.setFieldValue('zipCode', '');
		rest.setFieldValue('zipName', '');
		rest.setFieldValue('municipalityCode', '');
		rest.setFieldValue('municipalityName', '');
		saveData({
			...filledData,
			addrUnknown: true,
			zipCode: '',
			zipName: '',
			municipalityCode: '',
			municipalityName: '',
		});
	};

	const onchangeType = (value: any, rest: any) => {
		rest.setFieldValue('debtorType', value);
	};

	const formatOrganizationNo = (data: any, rest: any) => {
		const value = data.target.value;
		if (value?.length === 9) {
			const parts = value.replace(/\s/g, '').match(/.{1,3}/g);
			rest.setFieldValue('companyNo', parts.join(' '));
		}
	};
	const formatPersonNo = (value: any, rest: any) => {
		if (
			value?.length === 6 ||
			(value?.length >= 9 && value?.length <= 11)
		) {
			const parts = value.replace(/\s/g, '').match(/.{1,6}/g);
			rest.setFieldValue('personNo', parts.join(' '));
		}
	};

	const searchZipDetail = (zipcode: string, values: any, rest: any) => {
		if (zipcode?.length > 0) {
			if (searchPostalcode) {
				searchPostalcode({
					countryCode:
						values?.countryCode ??
						DEFAULT_COUNTRY_CODE,
					searchText: zipcode,
					formData: values,
				});
			}
		} else {
			rest.setValues({
				...values,
				zipName: '',
				municipalityCode: '',
				municipalityName: '',
			});
		}
	};

	const searchMunicipilityDetail = (
		countyCode: string,
		values: any,
		rest: any
	) => {
		if (countyCode?.length > 0) {
			if (searchMunicipility) {
				searchMunicipility({
					countryCode:
						values?.countryCode ??
						DEFAULT_COUNTRY_CODE,
					searchText: countyCode,
					formData: values,
				});
			}
		} else {
			rest.setFieldValue('municipalityName', '');
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={formInitialValue(debtorInformation)}
			validationSchema={ValidationSchema}
			validateOnChange
			validateOnBlur
			onSubmit={handleSubmit}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				...rest
			}: any) => (
				<div>
					<$Form
						name='GeneralInfo'
						onSubmit={handleSubmit}>
						<div className='mb-3'>
							<$Button
								size='small'
								type='primary'
								disabled={
									values.popConfirmVisible ||
									isSaving ||
									(metaData.data &&
										isSubCaseTransferredToCase(
											metaData.data
										))
								}
								onClick={
									handleSubmit
								}
								className='px-3'
								data-testid='debtor-general-save'>
								{t(
									'US.COLLECTION.COMMON:COMMON.SAVE'
								)}
							</$Button>

							<$Divider
								className='bui-devider'
								type='vertical'
							/>
							<$Button
								type='dashed'
								size='small'
								className='px-3 mr-2'
								onClick={() =>
									AddressHistoryHandler()
								}
								data-testid='debtor-general-addressHistory'>
								{t(
									'US.COLLECTION.DEBTOR:INFO.ADDRESSHISTORY'
								)}
							</$Button>
							<$Button
								type='dashed'
								size='small'
								className='px-3 mr-2'
								onClick={() =>
									DebtorHistoryHandler()
								}
								data-testid='debtor-general-debtorHistory'>
								{t(
									'US.COLLECTION.DEBTOR:INFO.DEBTOR_HISTORY'
								)}
							</$Button>
						</div>

						<div className='debtor-info'>
							<$Collapse
								bordered={false}
								defaultActiveKey={[
									'1',
									'2',
								]}
								expandIcon={({
									isActive,
								}) =>
									isActive ? (
										<MinusOutlined />
									) : (
										<PlusOutlined />
									)
								}
								className='bui-collapse'
								onChange={(
									panel: any
								) =>
									handlePanel(
										panel
									)
								}>
								{/* Basic information */}
								<$Collapse.Panel
									header={t(
										'US.COLLECTION.DEBTOR:INFO.BASICINFO'
									)}
									key='1'
									data-testid='debtor-general-debtorBasicInfromation'>
									<div className='pl-1 pb-4'>
										<$Skeleton
											loading={
												isFetching ||
												isSaving
											}>
											<$Row
												gutter={
													16
												}>
												<$Col
													span={
														6
													}>
													<$Select
														formitem={{
															label: t(
																'US.COLLECTION.DEBTOR:GENERAL.TYPE'
															),
														}}
														name='debtorType'
														allOption={
															false
														}
														size='small'
														onSelect={(
															e: any
														) =>
															onchangeType(
																e,
																rest
															)
														}
														value={
															values.debtorType
														}
														options={[
															{
																label: t(
																	'US.COLLECTION.DEBTOR:GENERAL.PERSON'
																),
																value: 'P',
															},
															{
																label: t(
																	'US.COLLECTION.DEBTOR:GENERAL.COMPANY'
																),
																value: 'N',
															},
														]}
														data-testid='debtor-general-debtorType'
													/>
												</$Col>
												<$Col
													span={
														9
													}>
													<$Input
														name='firstName'
														label={t(
															'US.COLLECTION.DEBTOR:GENERAL.FIRSTNAME'
														)}
														defaultValue={
															values.firstName
														}
														size='small'
														data-testid='debtor-general-firstName'
													/>
												</$Col>
												<$Col
													span={
														9
													}>
													<$Input
														name='lastName'
														label={t(
															'US.COLLECTION.DEBTOR:GENERAL.LASTNAME'
														)}
														defaultValue={
															values.lastName
														}
														required
														size='small'
														data-testid='debtor-general-lastName'
													/>
												</$Col>
											</$Row>

											<$Row
												gutter={
													16
												}
												className='mt-2'>
												<$Col
													span={
														6
													}>
													{values.debtorType ===
														'P' && (
														<$Input
															label={t(
																'US.COLLECTION.DEBTOR:GENERAL.PERSONNO'
															)}
															name='personNo'
															onBlur={(
																value: any
															) =>
																handleBirthday(
																	value,
																	rest
																)
															}
															defaultValue={
																values.personNo
															}
															size='small'
															data-testid='debtor-general-personNo'
														/>
													)}
													{values.debtorType ===
														'N' && (
														<$Input
															label={t(
																'US.COLLECTION.DEBTOR:GENERAL.COMPANY_NO'
															)}
															name='companyNo'
															defaultValue={
																values.companyNo
															}
															size='small'
															data-testid='debtor-general-companyNo'
															onBlur={(
																value: any
															) =>
																formatOrganizationNo(
																	value,
																	rest
																)
															}
														/>
													)}
												</$Col>
												{values.debtorType ===
													'P' && (
													<$Col
														span={
															9
														}>
														<$Row
															gutter={
																16
															}>
															<$Col
																span={
																	12
																}>
																<$Select
																	formitem={{
																		label: t(
																			'US.COLLECTION.DEBTOR:GENERAL.GENDER'
																		),
																	}}
																	name='gender'
																	size='small'
																	allOption={
																		false
																	}
																	defaultValue={
																		values.gender
																	}
																	options={[
																		{
																			label: t(
																				'US.COMMON:COMMON.MALE'
																			),
																			value: 'M',
																		},
																		{
																			label: t(
																				'US.COMMON:COMMON.FEMALE'
																			),
																			value: 'F',
																		},
																	]}
																	data-testid='debtor-general-gender'
																/>
															</$Col>
															<$Col
																span={
																	12
																}>
																<$DatePicker
																	name='birthDate'
																	label={t(
																		'US.COLLECTION.DEBTOR:GENERAL.BIRTHDATE'
																	)}
																	placeholder={
																		currentDateFormat
																	}
																	format={
																		currentDateFormat
																	}
																	onChange={(
																		e: any
																	) =>
																		handlePersonNo(
																			e,
																			rest
																		)
																	}
																	defaultPickerValue={moment(
																		'2000/01/01'
																	)}
																	value={
																		moment(
																			values.birthDate
																		).isValid()
																			? moment(
																					values.birthDate
																			  )
																			: ''
																	}
																	disabledDate={(
																		current: any
																	) => {
																		return !(
																			current &&
																			current <
																				moment().endOf(
																					'day'
																				)
																		);
																	}}
																	style={{
																		width: '100%',
																	}}
																	size='small'
																	data-testid='debtor-general-birthDate'
																	allowClear
																/>
															</$Col>
														</$Row>
													</$Col>
												)}
												<$Col
													span={
														9
													}>
													<$Input
														name='email'
														label={t(
															'US.COLLECTION.DEBTOR:GENERAL.EMAIL'
														)}
														defaultValue={values.email?.trim()}
														size='small'
														data-testid='debtor-general-email'
													/>
												</$Col>
											</$Row>

											<$Row
												gutter={
													16
												}
												className='mt-2'>
												<$Col
													span={
														6
													}>
													<$Input
														name='sms'
														label={t(
															'US.COLLECTION.DEBTOR:GENERAL.SMS'
														)}
														defaultValue={
															values.sms
														}
														size='small'
														data-testid='debtor-general-sms'
													/>
												</$Col>
												<$Col
													span={
														6
													}>
													<$Input
														name='mobile'
														label={t(
															'US.COLLECTION.DEBTOR:GENERAL.MOBILE'
														)}
														defaultValue={
															values.mobile
														}
														size='small'
													/>
												</$Col>
												<$Col
													span={
														6
													}>
													<$Input
														name='home'
														label={t(
															'US.COLLECTION.DEBTOR:GENERAL.TELEPHONE'
														)}
														defaultValue={
															values.home
														}
														size='small'
														data-testid='debtor-general-telephone'
													/>
												</$Col>
												<$Col
													span={
														6
													}>
													<$Input
														name='work'
														label={t(
															'US.COLLECTION.DEBTOR:GENERAL.WORK'
														)}
														defaultValue={
															values.work
														}
														size='small'
													/>
												</$Col>
											</$Row>
											<$Row
												gutter={
													16
												}>
												<$Col
													span={
														24
													}>
													<$Popover
														placement='topLeft'
														content={
															<div
																style={{
																	maxWidth: '300px',
																}}>
																<div className='ant-popover-message'>
																	<ExclamationCircleFilled />
																	<div className='ant-popover-message-title'>
																		<div
																			style={{
																				paddingBottom:
																					'0.3rem',
																			}}>
																			{t(
																				'US.COLLECTION.DEBTOR:GENERAL.ADDING_ADDRESS_WILL_CHANGE_AS_KNOWN_ADDRESS'
																			)}
																		</div>
																		<div
																			style={{
																				paddingBottom:
																					'0.5rem',
																			}}>
																			<strong>
																				{t(
																					'US.COLLECTION.DEBTOR:GENERAL.DO_YOU_WANT_TO_CONTINUE'
																				)}
																			</strong>
																		</div>
																	</div>
																</div>
																<div className='ant-popover-buttons'>
																	<$Button
																		size='small'
																		onClick={() =>
																			postalCodePopOverConfirmNo(
																				rest
																			)
																		}
																		data-testid='debtor-general-addressChangeConfirmNo'>
																		{t(
																			'US.COMMON:COMMON.NO'
																		)}
																	</$Button>
																	<$Button
																		size='small'
																		type='primary'
																		onClick={() =>
																			postalCodePopOverConfirmYes(
																				rest
																			)
																		}
																		data-testid='debtor-general-addressChangeConfirmYes'>
																		{t(
																			'US.COMMON:COMMON.YES'
																		)}
																	</$Button>
																</div>
															</div>
														}
														trigger='click'
														visible={
															addressUnknownConfirmVisible
														}>
														<div className='mt-3 d-flex flex-1 align-items-top'>
															<div className='mr-3'>
																<$Switch
																	name='addrUnknown'
																	value={
																		values.addrUnknown
																	}
																	checked={
																		values.addrUnknown
																	}
																	onChange={(
																		checked: any
																	) => {
																		rest.setFieldValue(
																			'addrUnknown',
																			checked
																		);
																		handleAddressUnknown(
																			checked,
																			rest,
																			values
																		);
																	}}
																	data-testid='debtor-general-addrUnknown'
																/>
															</div>
															<div className='d-flex flex-column ml-2'>
																<span>
																	{t(
																		'US.COLLECTION.DEBTOR:GENERAL.ADDRESSUNKNOWN'
																	)}
																</span>
																<small>
																	(
																	{t(
																		'US.COLLECTION.DEBTOR:GENERAL.NO_LETTER_WILL_SEND_TO_THE_PARTY_WHEN_THE_ADDRESS_IS_UNKNOWN'
																	)}

																	)
																</small>
															</div>
														</div>
													</$Popover>
												</$Col>
											</$Row>
										</$Skeleton>
									</div>
								</$Collapse.Panel>

								{/* Address information */}
								<$Collapse.Panel
									header={t(
										'US.COLLECTION.DEBTOR:INFO.ADDRESSINFO'
									)}
									key='2'
									data-testid='debtor-general-addressInformation'>
									<div className='pl-1 pb-4'>
										<$Skeleton
											loading={
												isFetching ||
												isSaving
											}>
											<$Row
												gutter={
													16
												}>
												<$Col
													span={
														6
													}>
													<$Popover
														placement='rightTop'
														content={
															<div
																style={{
																	maxWidth: '300px',
																}}>
																<div className='ant-popover-message'>
																	<ExclamationCircleFilled />
																	<div className='ant-popover-message-title'>
																		<div
																			style={{
																				paddingBottom:
																					'0.3rem',
																			}}>
																			{t(
																				'US.COLLECTION.DEBTOR:GENERAL.CHANGING_THE_COUNTRY_WILL_REMOVE_POSTAL_AND_MUNICIPALITY_DETAILS'
																			)}
																		</div>
																		<div
																			style={{
																				paddingBottom:
																					'0.5rem',
																			}}>
																			<strong>
																				{t(
																					'US.COLLECTION.DEBTOR:GENERAL.DO_YOU_WANT_TO_CONTINUE'
																				)}
																			</strong>
																		</div>
																	</div>
																</div>
																<div className='ant-popover-buttons'>
																	<$Button
																		size='small'
																		onClick={() =>
																			countryPopOverConfirmNo(
																				rest
																			)
																		}
																		data-testid='debtor-general-countryChangeConfirmNo'>
																		{t(
																			'US.COMMON:COMMON.NO'
																		)}
																	</$Button>
																	<$Button
																		size='small'
																		type='primary'
																		onClick={() =>
																			countryPopOverConfirmYes(
																				rest,
																				values
																			)
																		}
																		data-testid='debtor-general-countryChangeConfirmYes'>
																		{t(
																			'US.COMMON:COMMON.YES'
																		)}
																	</$Button>
																</div>
															</div>
														}
														trigger='click'
														visible={
															values.popConfirmVisible
														}>
														<$SelectGroup
															className='country-select'
															required
															options={_.orderBy(
																europeanCountry,
																[
																	'name',
																	'label',
																],
																[
																	'asc',
																	'asc',
																]
															)}
															formitem={{
																label: t(
																	'US.COLLECTION.DEBTOR:GENERAL.COUNTRY'
																),
															}}
															style={{
																width: '100%',
															}}
															value={
																values.countryCode
															}
															name='countryCode'
															defaultValue={
																values.countryCode
															}
															optionFilterProp='children'
															onChange={(
																value: any
															) =>
																onChangeCountry(
																	value,
																	rest,
																	values
																)
															}
															filterOption={(
																input: any,
																option: any
															) =>
																option.children
																	?.toLowerCase()
																	.indexOf(
																		input.toLowerCase()
																	) >=
																0
															}
															data-testid='debtor-general-country'
														/>
													</$Popover>
												</$Col>
											</$Row>
											<$Row
												gutter={
													16
												}
												className='mt-2'>
												<$Col
													span={
														8
													}>
													<$Input
														name='address1'
														value={
															values.address1
														}
														label={t(
															'US.COLLECTION.DEBTOR:GENERAL.POSTADDRESS1'
														)}
														size='small'
														data-testid='debtor-general-address1'
													/>
												</$Col>
												<$Col
													span={
														8
													}>
													<$Input
														name='address2'
														value={
															values.address2
														}
														label={t(
															'US.COLLECTION.DEBTOR:GENERAL.POSTADDRESS2'
														)}
														size='small'
														data-testid='debtor-general-address2'
													/>
												</$Col>
												<$Col
													span={
														8
													}>
													<$Input
														name='address3'
														value={
															values.address3
														}
														label={t(
															'US.COLLECTION.DEBTOR:GENERAL.POSTADDRESS3'
														)}
														size='small'
														data-testid='debtor-general-address3'
													/>
												</$Col>
											</$Row>
											<$Row
												gutter={
													16
												}
												className='mt-2'>
												<$Col
													span={
														6
													}>
													<a
														className='d-block'
														style={{
															paddingBottom:
																'0.13rem',
														}}
														onClick={() =>
															changeView(
																rest
															)
														}>
														<strong>
															{t(
																'US.COMMON:COMMON.POSTAL_CODE'
															)}
														</strong>
													</a>
													<$Input
														name='zipCode'
														size='small'
														value={
															values.zipCode
														}
														onBlur={(
															data: any
														) =>
															searchZipDetail(
																data
																	?.target
																	?.value,
																values,
																rest
															)
														}
													/>
												</$Col>
												<$Col
													span={
														6
													}>
													<$Skeleton
														loading={
															postalcode?.isLoading
														}
														active
														paragraph={{
															rows: 0,
														}}>
														<$Input
															name='zipName'
															label={t(
																'US.COMMON:COMMON.POSTAL_AREA'
															)}
															size='small'
															value={
																values.zipName
															}
															data-testid='debtor-general-zipName'
															disabled={
																values.isExistingPostalCode
															}
														/>
													</$Skeleton>
												</$Col>
												<$Col
													span={
														6
													}>
													<$Input
														name='municipalityCode'
														label={t(
															'US.COMMON:COMMON.MUNICIPALITY_CODE'
														)}
														size='small'
														value={
															values.municipalityCode
														}
														onBlur={(
															data: any
														) =>
															searchMunicipilityDetail(
																data
																	?.target
																	?.value,
																values,
																rest
															)
														}
														data-testid='debtor-general-municipalityCode'
														disabled={
															values.isExistingPostalCode
														}
													/>
												</$Col>
												<$Col
													span={
														6
													}>
													<$Input
														name='municipalityName'
														label={t(
															'US.COMMON:COMMON.MUNICIPALITY_NAME'
														)}
														size='small'
														value={
															values.municipalityName
														}
														data-testid='debtor-general-municipalityName'
														disabled={
															values.isExistingMunicipalityCode
														}
													/>
												</$Col>
											</$Row>
										</$Skeleton>
									</div>
								</$Collapse.Panel>

								{/* Creditor information */}
								<$Collapse.Panel
									header={t(
										'US.COLLECTION.DEBTOR:GENERAL.CREDITORCASES'
									)}
									key='3'
									data-testid='debtor-general-creditorInformation'>
									<div className='pl-1 pb-4'>
										<$Skeleton
											loading={
												isFetchingCreditorInfo
											}
											active
											paragraph={{
												rows: 2,
											}}>
											<$Table
												rowKey='pid'
												dataSource={
													creditorTableData
												}
												size='small'
												className=''
												bordered
												columns={
													columns
												}
												pagination={
													false
												}
												summary={() => (
													<$Table.Summary.Row>
														<$Table.Summary.Cell
															index={
																0
															}>
															<h3 className='mb-0 py-2'>
																{t(
																	'US.COMMON:COMMON.TOTAL'
																)}
															</h3>
														</$Table.Summary.Cell>
														<$Table.Summary.Cell
															index={
																0
															}>
															<strong>
																{
																	summaryData[0]
																		.numberOfCases
																}
															</strong>
														</$Table.Summary.Cell>
														<$Table.Summary.Cell
															index={
																0
															}
															className='text-right'>
															<strong>
																{summaryData[0]
																	.balance !=
																	'0.00' && (
																	<$AmountLabel
																		value={Number(
																			summaryData[0]
																				.balance
																		)}
																	/>
																)}
																{summaryData[0]
																	.balance ==
																	'0.00' &&
																	'-'}
															</strong>
														</$Table.Summary.Cell>
													</$Table.Summary.Row>
												)}
											/>
										</$Skeleton>
									</div>
								</$Collapse.Panel>

								{/* Other information */}
								<$Collapse.Panel
									header={t(
										'US.COLLECTION.DEBTOR:GENERAL.OTHER_INFORMATION'
									)}
									key='4'
									collapsible={
										moment
											.utc(
												otherInfo?.lastLoginPortal
											)
											.isSame(
												moment.utc(
													DateFormats.MIN_DATE
												)
											) &&
										otherInfo?.payment ==
											0
											? 'disabled'
											: undefined
									}
									data-testid='debtor-general-otherInformation'>
									<div className='pl-1 pb-4'>
										<$Skeleton
											loading={
												isFetchingOtherInformation
											}>
											<$Row
												gutter={
													16
												}>
												{moment
													.utc(
														otherInfo?.lastLoginPortal
													)
													.isValid() &&
													moment
														.utc(
															otherInfo?.lastLoginPortal
														)
														.isAfter(
															moment.utc(
																DateFormats.MIN_DATE
															)
														) && (
														<$Col
															span={
																6
															}>
															<$Typography.Text
																type='secondary'
																className='mb-0 mt-0'>
																{t(
																	'US.COLLECTION.DEBTOR:GENERAL.LAST_LOGIN_PORTAL'
																)}
															</$Typography.Text>
															<h3 className='mb-0 mt-0'>
																<$DateLabel
																	value={
																		otherInfo?.lastLoginPortal
																	}
																/>
															</h3>
														</$Col>
													)}
												{otherInfo?.payment !=
													0 && (
													<$Col
														span={
															12
														}>
														<$Typography.Text
															type='secondary'
															className='mb-0 mt-0'>
															{t(
																'US.COLLECTION.DEBTOR:GENERAL.LAST_PAYMENT'
															)}
														</$Typography.Text>
														<div className='d-flex align-items-end'>
															<h3 className='mb-0 mt-0'>
																<$AmountLabel
																	value={Number(
																		otherInfo?.payment
																	)}
																/>
																{otherInfo?.payment ==
																	0 &&
																	'-'}
															</h3>
															<h3 className='mb-0 mt-0 ml-2'>
																{moment
																	.utc(
																		otherInfo?.lastPaymentDate
																	)
																	.isValid() &&
																	moment
																		.utc(
																			otherInfo?.lastPaymentDate
																		)
																		.isAfter(
																			moment.utc(
																				DateFormats.MIN_DATE
																			)
																		) && (
																		<$DateLabel
																			value={
																				otherInfo?.lastPaymentDate
																			}
																		/>
																	)}
															</h3>
															{/* </span> */}
														</div>
													</$Col>
												)}
											</$Row>
										</$Skeleton>
									</div>
								</$Collapse.Panel>
							</$Collapse>
						</div>

						{/* Previous Code */}
						<div className='mt-2 mb-5'></div>
					</$Form>
					<$Drawer
						title={t(
							'US.COLLECTION.DEBTOR:INFO.ADDRESSHISTORY'
						)}
						width={'90%'}
						visible={AddressHistoryVisible}
						onClose={AddressHistoryClose}
						destroyOnClose>
						<AddressHistory
							onClose={
								AddressHistoryClose
							}
						/>
					</$Drawer>
					<$Drawer
						title={t(
							'US.COLLECTION.DEBTOR:INFO.DEBTOR_HISTORY'
						)}
						width={1200}
						visible={DebtorHistoryVisible}
						onClose={DebtorHistoryClose}
						destroyOnClose>
						<EntityHistory
							onClose={
								AddressHistoryClose
							}
							{...props}
						/>
					</$Drawer>
					<$Drawer
						title={drawerName}
						width={800}
						visible={
							values.postalAreaVisible
						}
						onClose={() =>
							postalAreaClose(rest)
						}
						destroyOnClose>
						<PostalArea
							postalAreaClose={() =>
								postalAreaClose(
									rest
								)
							}
							drawerContentChangeClick={(
								e: any
							) =>
								handlePostalDoubleClick(
									e?.updatedInfo,
									rest,
									values
								)
							}
							countryCode={
								values.countryCode
							}
							{...props}
						/>
					</$Drawer>
				</div>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: IRootState) => {
	const { common, domainView, entityDetails, debtorDetail } = state;
	const { currentCurrency, currentLanguage, currentDateFormat } = common;
	const { creditor, other, isSaving } = debtorDetail;
	const { metaData } = domainView;
	return {
		currentCurrency,
		currentLanguage,
		currentDateFormat,
		isFetching: entityDetails.isFetching,
		postalAreas: entityDetails.postalAreas,
		debtorInformation: entityDetails.entity,
		isFetchingCreditorInfo: creditor.isFetching,
		creditorInfo: creditor.data,
		otherInfo: other.data,
		isFetchingOtherInformation: other.isFetching,
		drawerName: entityDetails.drawerName,
		isSaving,
		metaData,
	};
};

const mapDispatchToProps = {
	getCreditorInfo: debtor.creditor.getByArNo,
	getOtherInfo: debtor.other.getByArNo,
	save: debtor.debtor.save,
	getEntity: entity.get,
	searchPostalcode: debtor.postalCode.search,
	searchMunicipility: debtor.minicipality.search,
	resetPostalDetails: debtor.postalCode.reset,
};

const wrappedForm = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(General)
);
export default wrappedForm;
