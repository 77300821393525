import { httpCollection } from "us.helper/http/collection";
import moment from "moment";
import { INonProductionDay, ISmsTime } from "us.collection.admin/interfaces";

export default {
  calendars: {
    get: <P extends {}>(params: P): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.get(
            "bmdservice",
            "calendars",
            params
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },

  calendar: {
    save: <
      P extends {
        calendarId: number;
        calendarName: string;
        nonProductionDays: Array<Omit<INonProductionDay, "key">>;
        smsSchedule: Array<Omit<ISmsTime, "isEnable">>;
      }
    >(
      params: P
    ): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.post(
            "bmdservice",
            "calendars",
            params
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },

  redDays: {
    get: <
      P extends {
        calendarId: number;
        startDate: moment.Moment;
        endDate: moment.Moment;
        criteria: string;
      }
    >(
      params: P
    ): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.get("bmdservice", "redDays", {
            calendarId: params?.calendarId,
            startDate: moment(params?.startDate).toDate(),
            endDate: moment(params?.endDate).toDate(),
            criteria: params?.criteria,
          });
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },

  redDaysWithSmsSchedule: {
    get: <
      P extends {
        calendarId: number;
        startDate: moment.Moment;
        endDate: moment.Moment;
        criteria: string;
        dayId: number;
      }
    >(
      params: P
    ): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const nonProdDays = await httpCollection.get(
            "bmdservice",
            "redDays",
            {
              calendarId: params?.calendarId,
              startDate: moment(params?.startDate).toDate(),
              endDate: moment(params?.endDate).toDate(),
              criteria: params?.criteria,
            }
          );
          const smsShedule = await httpCollection.get(
            "bmdservice",
            "smsSchedule",
            {
              calendarId: params?.calendarId,
              dayId: params?.dayId,
            }
          );
          resolve({
            nonProdDays: nonProdDays?.data,
            smsSchedule: smsShedule?.data,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  },

  smsSchedule: {
    get: <P extends { calendarId: number; dayId: number }>(
      params: P
    ): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.get(
            "bmdservice",
            "smsSchedule",
            {
              calendarId: params?.calendarId,
              dayId: params?.dayId,
            }
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
