import {
  ICloseCaseInitialState,
  IHandlePopover,
  IReason,
  IReasonList,
} from "../Interfaces";

const reasonList: IReason = {
  closeReasonId: 0,
  name: "",
  closeReason: "",
  displayName: "",
};

const reasonDetails: IReasonList = {
  data: [reasonList],
  isLoading: false,
  isReasonAPIReset: false,
};

const handlePopover: IHandlePopover = {
  visible: false,
};

export const initialState: ICloseCaseInitialState = {
  reasons: reasonDetails,
  popover: handlePopover,
  isClosing: false
};
