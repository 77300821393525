import { DashboardAction } from "us.collection.case/interfaces";
import Constants from "us.collection.case/constants";
import { IAPIAction } from "us.collection/interfaces";

const {
  GET_CASE_INFO_WIDGET_DATA,
  GET_CASE_INFO_WIDGET_DATA_FAIL,
  GET_CASE_INFO_WIDGET_DATA_SUCCESS,
} = Constants.ACTIONS.CaseInfoWidget;

export const CaseInfoWidgetActions: IAPIAction = {
  caseInformation: {
    get: (data) => ({
      type: GET_CASE_INFO_WIDGET_DATA,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_CASE_INFO_WIDGET_DATA_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_CASE_INFO_WIDGET_DATA_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
};
