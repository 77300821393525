import { Formik, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DrawerType } from 'us.collection.transactions/constants';
import { IRootState } from 'us.collection/interfaces';
import {
	$Button,
	$Col,
	$Divider,
	$Popconfirm,
	$Row,
	$Skeleton,
	$Switch,
	$TableTree,
	IOnFilter,
	IOnSort,
  $Tooltip,
} from 'us.common/components';
import { InfoCircleOutlined } from 'us.icons';
import { ICaseSubCasesFrom } from '../../Interfaces';
import { CasesAndSubCasesAction } from 'us.collection.transactions/actions';
import { connect, ConnectedProps } from 'react-redux';
import { drawerStatusHandler } from '../../Functions';
import {
	GetCreateCaseRequestObject,
	GetMergeCasesRequestObject,
} from './Repositories';
import { INITIAL_VALUES, MERGE_CASE_COLUMNS } from './Constants';
import { INITIAL_VALUES as PARENT_INITIAL_VALUES } from 'us.collection.transactions/components/CasesAndSubcases/Constants';
import {
  getDataArranged,
  getTableRenderItems,
  isCreateButtonEnabled,
  isMergeButtonEnabled,
  rowSelection,
} from './Functions';
import { IManageCaseForm } from './Interfaces';

/**
 * @description - The component used in managing the Cases and sub cases -> merging and creating
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2754347013/Cases+and+Sub+Cases+UI+Implementation
 * @param {PropsFromRedux} props
 * @return {JSX.Element}
 * @author Darshana Waasala <waasalajb@unicorn-solutions.com>
 * @since 14/03/2022
 */
export const ManageCase: React.FC<PropsFromRedux> = (props) => {
	const { t } = useTranslation();
	const { values, resetForm: resetParentForm } =
		useFormikContext<ICaseSubCasesFrom>();
	const { selectedCases } = values;
	const {
		saveStatus,
		openDrawer,
		mergeCases,
		createCases,
		metaData,
		drawerStatus,
		caseList,
	} = props;
	const { creditorId, debtorId, arId } = metaData.data ?? {};

	const columns = MERGE_CASE_COLUMNS.map((column) => ({
		...column,
		...getTableRenderItems(column.dataIndex),
		title: t(
			`US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.${column.title}`
		),
		key: column.dataIndex,
	}));

	/**
	 * @function
	 * @description - Form handling function
	 * @param {IManageCaseForm} { destinationCase, newCase } - Form data
	 * @param {*} { resetForm } - formik props
	 */
	const handleSubmit = (
		{ destinationCase, newCase }: IManageCaseForm,
		{ resetForm }: any
	) => {
		if (
			destinationCase &&
			((drawerStatus.type === DrawerType.CREATE_CASES &&
				!newCase) ||
				drawerStatus.type === DrawerType.MERGE_CASES)
		) {
			mergeCases &&
				mergeCases(
					GetMergeCasesRequestObject.call({
						arId,
						creditorId,
						debtorId,
						destinationCase,
						selectedCases,
					})
				);
		} else {
			createCases &&
				createCases(
					GetCreateCaseRequestObject.call({
						arId,
						selectedCases,
						creditorId,
						debtorId,
					})
				);
		}
		resetForm();
		resetParentForm({
			values: {
				...PARENT_INITIAL_VALUES,
				selectedCases: values?.selectedCases,
			},
		});
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{ ...INITIAL_VALUES, selectedCases }}
			onSubmit={handleSubmit}>
			{({
				values,
				isSubmitting,
				isValidating,
				dirty,
				handleSubmit,
				setFieldValue,
				setValues,
			}: {
				values: IManageCaseForm;
				isSubmitting: boolean;
				isValidating: boolean;
				dirty: boolean;
				handleSubmit: any;
				setFieldValue: (
					field: string,
					value: any,
					shouldValidate?: boolean
				) => void;
				setValues: (
					fields: React.SetStateAction<{
						[field: string]: any;
					}>,
					shouldValidate?: boolean
				) => void;
			}) => (
				<>
					<div className=''>
						<p className='mt-0'>
							<InfoCircleOutlined />{' '}
							{t(
								'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.MERGE_CASES_MSG'
							)}
						</p>
						<$Row
							className='mt-3'
							gutter={16}>
							<$Col
								span={5}
								offset={0}>
								<div className='source-subcases'>
									<p>
										<strong>
											{t(
												'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.SOURCE_CASES'
											)}
										</strong>
									</p>
                  {values.selectedCases?.map(
                    ({ caseId, caseNumber, selectAsDestination }: any) => (
                      <>
                        <$Tooltip
                          placement="top"
                          title={
                            selectAsDestination
                              ? t(
                                  'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.ALLOWED_TO_SELECT_AS_A_DESTINATION_CASE'
                                )
                              : ''
                          }
                        >
                          <p
                            key={caseId}
                            className={
                              selectAsDestination ? 'selectAsDestination' : ''
                            }
                          >
                            {caseNumber} ({caseId})
                          </p>
                        </$Tooltip>
                      </>
                    )
                  )}
								</div>
							</$Col>
							<$Col
								span={19}
								offset={0}>
								{drawerStatus.type ===
									DrawerType.CREATE_CASES && (
									<div className='d-flex justify-content-end'>
										<div className='mt-0 ml-auto d-flex flex-1 align-items-top'>
											<div className='mr-2'>
												<$Switch
													name='newCase'
													size='small'
													checked={
														values.newCase
													}
													onChange={(
														checked: boolean
													) =>
														setValues(
															(
																preVals: IManageCaseForm
															) => ({
																...preVals,
																newCase: checked,
																destinationCase:
																	checked
																		? ''
																		: preVals.destinationCase,
															})
														)
													}
												/>
											</div>
											<div className='d-flex flex-column'>
												<span>
													{t(
														'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.NEW_CASE'
													)}
												</span>
											</div>
										</div>
									</div>
								)}
								{drawerStatus.type ===
									DrawerType.CREATE_CASES &&
									values.newCase && (
										<>
											<p className='mt-0'>
												<strong>
													{t(
														'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.DESTINATION_CASES'
													)}
												</strong>
											</p>
											<$Divider className='my-2' />

											<p>
												{t(
													'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.DESTINATION_CASES_MSG'
												)}
											</p>
										</>
									)}
								{((drawerStatus.type ===
									DrawerType.CREATE_CASES &&
									!values.newCase) ||
									drawerStatus.type ===
										DrawerType.MERGE_CASES) && (
									<$Skeleton
										loading={
											false
										}
										active
										paragraph={{
											rows: 2,
										}}>
										<$TableTree
											rowKey={(
												record
											) =>
												record.caseId
											}
											data={getDataArranged(
												caseList.data											
											)}
											size='small'
											className='mt-3'
											onSort={(
												sortData,
												dataSource
											) =>
												sortData(
													dataSource
												)
											}
											onFilter={(
												searchData,
												dataSource
											) =>
												searchData(
													dataSource
												)
											}
											filterOnType={
												true
											}
											resetOnSourceChange={
												true
											}
											bordered
											pagination={
												false
											}
											scroll={{
												x: 600,
												y: 'calc(100vh - 238px)',
											}}
											columns={
												columns
											}
                      rowSelection={{
                        type: 'radio',
                        ...rowSelection(
                          setFieldValue,
                          values,
                          drawerStatus.type,
                          selectedCases
                        ),
                      }}
											firstColSkipFilterProps={
												-1
											}
										/>
									</$Skeleton>
								)}
							</$Col>
						</$Row>
					</div>
					<div className='drawer-footer-fixed align-content-center justify-content-end'>
						<div>
							{drawerStatus.type ===
								DrawerType.CREATE_CASES && (
								<$Button
									className='mr-2'
									disabled={
										!isCreateButtonEnabled(
											values
										)
									}
									loading={
										isCreateButtonEnabled(
											values
										) &&
										(saveStatus.isLoading ||
											isSubmitting ||
											isValidating)
									}
									onClick={
										handleSubmit
									}
									type='primary'>
									{t(
										'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.CREATE_NEW_CASE'
									)}
								</$Button>
							)}
							<$Button
								className='mr-2'
								disabled={
									!isMergeButtonEnabled(
										values,
										drawerStatus.type
									)
								}
								loading={
									isMergeButtonEnabled(
										values,
										drawerStatus.type
									) &&
									(saveStatus.isLoading ||
										isSubmitting ||
										isValidating)
								}
								onClick={
									handleSubmit
								}
								type='primary'>
								{t(
									'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.MERGE_CASES'
								)}
							</$Button>
							{dirty && (
								<$Popconfirm
									title={t(
										'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.DRAWERCANCELMSG'
									)}
									placement='topLeft'
									onConfirm={() =>
										drawerStatusHandler(
											DrawerType.CREATE_CASES,
											false,
											openDrawer
										)
									}
									okText={t(
										'US.COLLECTION.COMMON:COMMON.YES'
									)}
									cancelText={t(
										'US.COLLECTION.COMMON:COMMON.NO'
									)}>
									<$Button>
										{t(
											'US.COLLECTION.COMMON:COMMON.CANCEL'
										)}
									</$Button>
								</$Popconfirm>
							)}
							{!dirty && (
								<$Button
									onClick={() =>
										drawerStatusHandler(
											DrawerType.CREATE_CASES,
											false,
											openDrawer
										)
									}>
									{t(
										'US.COLLECTION.COMMON:COMMON.CANCEL'
									)}
								</$Button>
							)}
						</div>
					</div>
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: IRootState) => {
	const { casesAndSubCases, domainView } = state;
	const { drawerStatus, saveStatus, caseList } = casesAndSubCases;
	const { metaData } = domainView;
	return {
		drawerStatus,
		saveStatus,
		metaData,
		caseList,
	};
};

const { caseList, mergeCases, createCases } = CasesAndSubCasesAction;
const mapDispatchToProps = {
	mergeCases: mergeCases.save,
	openDrawer: caseList.openDrawer,
	createCases: createCases.save,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ManageCase);
