import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Formik } from 'formik';
import moment from 'moment';

import Common from 'us.common';
import { LoadingOutlined, RightOutlined } from 'us.icons';
import '../../ContentV2.scss';
import { MessageAndNoteAction } from 'us.collection.case/actions';
import { GetMessageContent } from 'us.collection.case/repository';
import { DomainMessageType } from 'us.collection.case/constants/NotesAndMessage';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { appInsights } from 'us.helper';
import { getEntityTypeByKey } from 'us.collection.case/functions';
import { TemplateEditor } from '../TemplateEditor';
import { LetterValidationSchema } from '../../Validations';
import { RootState } from 'us.helper/types';

const {
	$Form,
	$Select,
	$Button,
	$DatePicker,
	$Switch,
	$Spin,
	$Row,
	$Col,
	$Typography,
} = Common.Components;

const { Paragraph } = $Typography;

type LetterProps = {
	setNoteView: <T>(data: T) => void;
};

const Letter: React.FC<LetterProps & PropsFromRedux> = (props) => {
	const { t } = useTranslation();

	const {
		currentDateFormat,
		domainMessageFormattedTemplate,
		openTemplateDrawer,
		openDrawer,
		metaData,
		domainMessageInitial,
		reset,
		setNoteView,
		sendDomainMessage,
		formattedMessageContentDetails,
		sendDashboardMessage,
		getMessageContent,
	} = props;

	useEffect(() => {
		if (sendDomainMessage?.data?.statusCode == 200) {
			reset && reset({});
			setNoteView(DomainMessageType.LETTERS);
			openDrawer &&
				openDrawer({
					title: '',
					isTemplateEditor: false,
					visible: false,
				});
		}
	}, [sendDomainMessage?.data]);

	const handleTemplateDrawer = (
		value: string,
		rest: any,
		values: any,
		isSelectTemplate: boolean
	) => {
		try {
			if (metaData.data) {
				const payload = GetMessageContent.call({
					...values,
					entityType: getEntityTypeByKey(
						metaData.data.entityType
					),
					emailOrTelNo: values.emailAddress,
					entityId: metaData.data.caseId,
					messageType: 'Document',
				});
				getMessageContent && getMessageContent(payload);
				openDrawer &&
					openDrawer({
						title: t(
							'US.COLLECTION.CASE:NOTESANDMESSAGES.PREVIEW'
						),
						isTemplateEditor: true,
						visible: true,
					});
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard NotesAndMessage-Letter Drawer Handling Exeception - ${error}`
			);
		}
	};

	const closeTemplateDrawer = (rest: any) => {
		// reset && reset({});
		rest.setFieldValue('dueDate', '');
		openDrawer &&
			openDrawer({
				title: '',
				isTemplateEditor: false,
				visible: false,
			});
	};

	return (
		<Formik
			enableReinitialize
			initialValues={domainMessageInitial}
			validationSchema={LetterValidationSchema}
			onSubmit={() => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...rest
			}: any) => (
				<div className='py-3'>
					<$Form>
						<$Row
							gutter={16}
							className='mt-0'>
							<$Col
								span={10}
								xxl={{
									span: 10,
								}}
								xl={{
									span: 12,
								}}>
								<div
									style={{
										marginTop: 0,
									}}
									data-testid='document-type-label'>
									{t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.DOCUMENTTYPE'
									)}
								</div>
							</$Col>
							<$Col
								span={14}
								xxl={{
									span: 14,
								}}
								xl={{
									span: 12,
								}}
								data-testid='select-document-type'>
								<$Select
									className='w-100'
									name='documentType'
									placeholder='Status'
									size='small'
									allOption={
										false
									}
									options={
										domainMessageInitial.documentTypeList
									}
									defaultValue={
										values.documentType
									}
									disabled={
										sendDomainMessage?.isProgress ||
										(metaData.data &&
											isSubCaseTransferredToCase(
												metaData.data
											))
									}
									data-testid='document-type'
								/>
							</$Col>
						</$Row>

						<$Row
							gutter={16}
							className='mt-2'>
							<$Col
								span={10}
								xxl={{
									span: 10,
								}}
								xl={{
									span: 12,
								}}>
								<div
									style={{
										marginTop: 5,
									}}
									data-testid='due-date-label'>
									{t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.MANUALDUEDATE'
									)}
								</div>
							</$Col>
							<$Col
								span={14}
								xxl={{
									span: 14,
								}}
								xl={{
									span: 12,
								}}>
								<$DatePicker
									formitem
									formitemlabel={t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.MANUALDUEDATE'
									)}
									className='w-100'
									name='dueDate'
									size='small'
									placeholder={
										currentDateFormat
									}
									format={
										currentDateFormat
									}
									value={
										values.dueDate
									}
									defaultPickerValue={
										values.dueDate
									}
									allowClear
									disabledDate={(
										day: any
									) =>
										day.isBefore(
											moment().startOf(
												'day'
											)
										)
									}
									disabled={
										sendDomainMessage?.isProgress ||
										(metaData.data &&
											isSubCaseTransferredToCase(
												metaData.data
											))
									}
									data-testid='manual-duedate'
								/>
							</$Col>
						</$Row>

						<$Row
							gutter={16}
							className='mt-2'>
							<$Col
								span={24}
								xxl={{
									span: 24,
								}}
								xl={{
									span: 24,
								}}>
								<div
									className='d-flex align-items-center'
									data-testid='is-giro'>
									<$Switch
										{...rest}
										size='small'
										name='isGiroChecked'
										defaultChecked={
											values.isGiroChecked
										}
										checked={
											values.isGiroChecked
										}
										disabled={
											sendDomainMessage?.isProgress ||
											(metaData.data &&
												isSubCaseTransferredToCase(
													metaData.data
												))
										}
									/>
									<Paragraph
										ellipsis
										className='mb-0 ml-2'
										data-testid='giro-label'>
										{t(
											'US.COLLECTION.CASE:NOTESANDMESSAGES.GIRO'
										)}
									</Paragraph>
								</div>
							</$Col>
						</$Row>

						<div className='d-flex align-items-center justify-content-between mt-2'>
							<div>
								{sendDomainMessage?.isProgress && (
									<small className='d-block'>
										<$Spin
											size='small'
											indicator={
												<LoadingOutlined
													style={{
														fontSize: 20,
													}}
													spin
												/>
											}
										/>
										<span className='ml-2'>
											{t(
												'US.COLLECTION.CASE:NOTESANDMESSAGES.SEND_LETTER_IN_PROGRESS'
											)}
										</span>
									</small>
								)}
							</div>
							<$Button
								type='default'
								onClick={() => {
									handleTemplateDrawer(
										'',
										rest,
										values,
										false
									);
								}}
								icon={
									<RightOutlined />
								}
								disabled={
									(metaData.data &&
										isSubCaseTransferredToCase(
											metaData.data
										)) ||
									sendDomainMessage?.isProgress
								}
								data-testid='send-btn'>
								{t(
									'US.COLLECTION.COMMON:COMMON.NEXT'
								)}
							</$Button>
						</div>
						{openTemplateDrawer.visible && (
							<TemplateEditor
								closeTemplateDrawer={() =>
									closeTemplateDrawer(
										rest
									)
								}
								openTemplateDrawer={
									openTemplateDrawer
								}
								domainMessageFormattedTemplate={
									domainMessageFormattedTemplate
								}
								domainMessageInitial={
									values
								}
								documentType={
									DomainMessageType.LETTERS
								}
								messageContentDetails={
									formattedMessageContentDetails
								}
								send={
									sendDashboardMessage
								}
								metaData={
									metaData
								}
							/>
						)}
					</$Form>
				</div>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, domainView, messageAndNotes } = state;
	const { currentDateFormat } = common;
	const { metaData } = domainView;
	const {
		domainMessageTemplate,
		domainMessageFormattedTemplate,
		openTemplateDrawer,
		domainMessageBasicdetail,
		domainMessageInitial,
		sendDomainMessage,
		formattedMessageContentDetails,
	} = messageAndNotes;

	return {
		currentDateFormat,
		domainMessageTemplate,
		domainMessageFormattedTemplate,
		openTemplateDrawer,
		metaData,
		domainMessageInitial,
		domainMessageBasicdetail,
		sendDomainMessage,
		formattedMessageContentDetails,
	};
};
const { domainMessage, formattedTemplate, messageContent, dashboardMessage } =
	MessageAndNoteAction;

const mapDispatchToProps = {
	getMessageContent: messageContent.get,
	getFormattedTemplate: formattedTemplate.get,
	openDrawer: formattedTemplate.openDrawer,
	reset: formattedTemplate.reset,
	send: domainMessage.save,
	sendDashboardMessage: dashboardMessage.save,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Letter);
