import {
  eventDrawerInitial,
  troubleshootDrawerInitial,
  workflowDrawerInitial,
} from "us.collection/components";
import { Workflow } from "us.collection/constants";
import { IWorkflowStateInitial } from "./Interfaces";
import { initialState } from "./State";
import { getWorkflowInitVersion } from "us.collection/functions"
import _ from "lodash";

const {
  RETRY_WORKFLOW_START,
  RETRY_WORKFLOW_SUCCESS,
  RETRY_WORKFLOW_FAIL,
  GET_WORKFLOW_STATES_START,
  GET_WORKFLOW_STATES_SUCCESS,
  GET_WORKFLOW_STATES_FAIL,
  HANDLE_WORKFLOW_DRAWER,
  ADD_WORKFLOW_CATEGORY_START,
  ADD_WORKFLOW_CATEGORY_SUCCESS,
  ADD_WORKFLOW_CATEGORY_FAIL,
  UPDATE_WORKFLOW_CATEGORY_START,
  ADD_WORKFLOW_STATE_START,
  ADD_WORKFLOW_STATE_SUCCESS,
  ADD_WORKFLOW_STATE_FAIL,
  UPDATE_WORKFLOW_STATE_START,
  GET_DATA_ADAPTERS_START,
  GET_DATA_ADAPTERS_SUCCESS,
  GET_DATA_ADAPTERS_FAIL,
  DELETE_WORKFLOW_CATEGORY_START,
  DELETE_WORKFLOW_CATEGORY_SUCCESS,
  DELETE_WORKFLOW_CATEGORY_FAIL,
  DELETE_WORKFLOW_STATE_START,
  DELETE_WORKFLOW_STATE_SUCCESS,
  DELETE_WORKFLOW_STATE_FAIL,
  GET_EVENTS_START,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAIL,
  ADD_WORKFLOW_EVENT_START,
  ADD_WORKFLOW_EVENT_SUCCESS,
  ADD_WORKFLOW_EVENT_FAIL,
  UPDATE_WORKFLOW_EVENT_START,
  HANDLE_EVENT_DRAWER,
  DELETE_WORKFLOW_EVENT_START,
  DELETE_WORKFLOW_EVENT_SUCCESS,
  DELETE_WORKFLOW_EVENT_FAIL,
  GET_ERROR_COUNT_START,
  GET_ERROR_COUNT_SUCCESS,
  GET_ERROR_COUNT_FAIL,
  GET_MONITORING_LIST_START,
  GET_MONITORING_LIST_SUCCESS,
  GET_MONITORING_LIST_FAIL,
  RETRY_TROUBLESHOOT_WORKFLOW_START,
  RETRY_TROUBLESHOOT_WORKFLOW_SUCCESS,
  RETRY_TROUBLESHOOT_WORKFLOW_FAIL,
  RETRY_TROUBLESHOOT_ACTIVITY_START,
  IGNORE_TROUBLESHOOT_ACTIVITY_START,
  DELETE_TROUBLESHOOT_ACTIVITY_START,
  TROUBLESHOOT_ACTIVITY_SUCCESS,
  TROUBLESHOOT_ACTIVITY_FAIL,
  HANDLE_TROUBLESHOOT_DRAWER,
  CHANGE_WORKFLOW_STATE_START,
  CHANGE_WORKFLOW_STATE_SUCCESS,
  CHANGE_WORKFLOW_STATE_FAIL,
  GET_WORKFLOW_HISTORY_INIT_START,
  GET_WORKFLOW_HISTORY_INIT_SUCCESS,
  GET_WORKFLOW_HISTORY_INIT_FAIL,
  MANAGE_DUPLICATE_STATE_POPOVER,
  GET_WORKFLOW_STATUS_PROPERTIES_START,
  GET_WORKFLOW_STATUS_PROPERTIES_SUCCESS,
  GET_WORKFLOW_STATUS_PROPERTIES_FAIL,
  RESET_WORKFLOW_STATUS_HISTORY,
  GET_WORKFLOW_VERSIONS_START,
  GET_WORKFLOW_VERSIONS_SUCCESS,
  GET_WORKFLOW_VERSIONS_FAIL,
  MANAGE_WORKFLOW_TOOL_POPOVER
} = Workflow;

export default (state: IWorkflowStateInitial = initialState, action: any) => {
  switch (action.type) {
    case RETRY_WORKFLOW_START:
      return Object.assign({}, state, {
        retryWorkflow: {
          data: undefined,
          isError: false,
          isLoading: true,
        },
      });
    case RETRY_WORKFLOW_SUCCESS:
      return Object.assign({}, state, {
        retryWorkflow: {
          data: action.payload.data,
          isError: false,
          isLoading: false,
        },
      });
    case RETRY_WORKFLOW_FAIL:
      return Object.assign({}, state, {
        retryWorkflow: {
          data: undefined,
          isError: false,
          isLoading: false,
        },
      });
    case GET_WORKFLOW_STATES_START:
      return Object.assign({}, state, {
        workflowStates: {
          ...state?.workflowStates,
          isLoading: true,
        },
      });
    case GET_WORKFLOW_STATES_SUCCESS:
      return Object.assign({}, state, {
        workflowStates: {
          data: action.payload.data,
          isLoading: false,
        },
      });
    case GET_WORKFLOW_STATES_FAIL:
      return Object.assign({}, state, {
        workflowStates: {
          data: [],
          isLoading: false,
        },
      });
    case ADD_WORKFLOW_CATEGORY_START:
      return Object.assign({}, state, {
        isCategorySaveing: true,
      });
    case ADD_WORKFLOW_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        isCategorySaveing: false,
        workflowDrawer: workflowDrawerInitial,
      });
    case ADD_WORKFLOW_CATEGORY_FAIL:
      return Object.assign({}, state, {
        isCategorySaveing: false,
      });
      case GET_WORKFLOW_VERSIONS_START:
      return Object.assign({}, state, {
        workflowVersions: {
          data: [],
          isLoading: true
        },
      });
    case GET_WORKFLOW_VERSIONS_SUCCESS:
      return Object.assign({}, state, {
        workflowVersions: {
          data: action.payload.data,
          isLoading: false,
        },
        initWorkflowVersion: getWorkflowInitVersion(action.payload.data)
      });
    case GET_WORKFLOW_VERSIONS_FAIL:
      return Object.assign({}, state, {
        workflowVersions: {
          ...state.workflowVersions,
          isLoading: false
        },
        initWorkflowVersion: undefined
      });
    case UPDATE_WORKFLOW_CATEGORY_START:
      return Object.assign({}, state, {
        isCategorySaveing: true,
      });
    case HANDLE_WORKFLOW_DRAWER:
      return Object.assign({}, state, {
        workflowDrawer: action.payload.data,
        initWorkflowVersion: -1,
        isWorkflowPopupVisible: false,
      });
    case ADD_WORKFLOW_STATE_START:
      return Object.assign({}, state, {
        isStateSaving: true,
        searchFilters: {
          ...state.searchFilters,
          ...action.payload?.data?.searchFilters
        }
      });
    case ADD_WORKFLOW_STATE_SUCCESS:
      return Object.assign({}, state, {
        workflowDrawer: workflowDrawerInitial,
        isStateSaving: false,
        searchFilters: initialState.searchFilters
      });
    case ADD_WORKFLOW_STATE_FAIL:
      return Object.assign({}, state, {
        isStateSaving: false,
        duplicatingId: action.payload?.error?.duplicatingId,
      });
    case MANAGE_DUPLICATE_STATE_POPOVER:
      return Object.assign({}, state, {
        duplicatingId: undefined,
      });
    case UPDATE_WORKFLOW_STATE_START:
      return Object.assign({}, state, {
        isStateSaving: true,
        searchFilters: {
          ...state.searchFilters,
          ...action.payload?.data?.searchFilters
        }
      });
    case GET_DATA_ADAPTERS_START:
      return Object.assign({}, state, {
        dataAdapterList: {
          data: [],
          isLoading: true
        },
      });
    case GET_DATA_ADAPTERS_SUCCESS:
      const adapterList = action.payload.data?.map((item: string) => {
        return {
          label: item,
          value: item,
        };
      });
      return Object.assign({}, state, {
        dataAdapterList: {
          data: adapterList,
          isLoading: false
        },
      });
    case GET_DATA_ADAPTERS_FAIL:
      return Object.assign({}, state, {
        dataAdapterList: {
          data: [],
          isLoading: false
        },
      });
    case DELETE_WORKFLOW_CATEGORY_START:
      return Object.assign({}, state, {});
    case DELETE_WORKFLOW_CATEGORY_SUCCESS:
      return Object.assign({}, state, {});
    case DELETE_WORKFLOW_CATEGORY_FAIL:
      return Object.assign({}, state, {});
    case DELETE_WORKFLOW_STATE_START:
      return Object.assign({}, state, {
        searchFilters: {
          ...state.searchFilters,
          ...action.payload?.data?.searchFilters
        },
        isDeleting: true,
      });
    case DELETE_WORKFLOW_STATE_SUCCESS:
      return Object.assign({}, state, {
        searchFilters: initialState.searchFilters,
        duplicatingId: undefined,
        isDeleting: false,
        workflowDrawer: workflowDrawerInitial,
      });
    case DELETE_WORKFLOW_STATE_FAIL:
      return Object.assign({}, state, {
        duplicatingId: undefined,
        isDeleting: false,
      });
    case GET_EVENTS_START:
      return Object.assign({}, state, {
        workflowEvents: {
          ...state.workflowEvents,
          isLoading: true,
        },
      });
    case GET_EVENTS_SUCCESS:
      return Object.assign({}, state, {
        workflowEvents: {
          data: action.payload.data?.evetList,
          isLoading: false,
        },
      });
    case GET_EVENTS_FAIL:
      return Object.assign({}, state, {
        workflowEvents: {
          ...state.workflowEvents,
          isLoading: false,
        },
      });
    case HANDLE_EVENT_DRAWER:
      return Object.assign({}, state, {
        eventDrawer: action.payload.data,
        isWorkflowPopupVisible: false
      });
    case ADD_WORKFLOW_EVENT_START:
      return Object.assign({}, state, {
        isEventSaveing: true,
        searchFilters: {
          ...state.searchFilters,
          ...action.payload?.data?.searchFilters
        },
      });
    case ADD_WORKFLOW_EVENT_SUCCESS:
      return Object.assign({}, state, {
        eventDrawer: eventDrawerInitial,
        isEventSaveing: false,
        searchFilters: initialState.searchFilters,
      });
    case ADD_WORKFLOW_EVENT_FAIL:
      return Object.assign({}, state, {
        isEventSaveing: false,
        duplicatingId: action.payload?.error?.duplicatingId,
      });
    case UPDATE_WORKFLOW_EVENT_START:
      return Object.assign({}, state, {
        isEventSaveing: true,
        searchFilters: {
          ...state.searchFilters,
          ...action.payload?.data?.searchFilters
        },
      });
    case DELETE_WORKFLOW_EVENT_START:
      return Object.assign({}, state, {
        searchFilters: {
          ...state.searchFilters,
          ...action.payload?.data?.searchFilters
        },
        isDeleting: true,
      });
    case DELETE_WORKFLOW_EVENT_SUCCESS:
      return Object.assign({}, state, {
        searchFilters: initialState.searchFilters,
        duplicatingId: undefined,
        isDeleting: false,
        eventDrawer: eventDrawerInitial,
      });
    case DELETE_WORKFLOW_EVENT_FAIL:
      return Object.assign({}, state, {
        duplicatingId: undefined,
        isDeleting: false,
      });
    case GET_ERROR_COUNT_START:
      return Object.assign({}, state, {
        errorCount: {
          ...state.errorCount,
          isLoading: true,
        },
      });
    case GET_ERROR_COUNT_SUCCESS:
      return Object.assign({}, state, {
        errorCount: {
          data: action.payload.data,
          isLoading: false,
        },
      });
    case GET_ERROR_COUNT_FAIL:
      return Object.assign({}, state, {
        errorCount: {
          data: [],
          isLoading: false,
        },
      });
    case GET_MONITORING_LIST_START:
      return Object.assign({}, state, {
        monitorList: {
          ...state.monitorList,
          isLoading: true,
        },
        troubleshootCurrentOffset: action.payload.data?.offset
      });
    case GET_MONITORING_LIST_SUCCESS:
      return Object.assign({}, state, {
        monitorList: {
          data: action.payload.data,
          isLoading: false,
        },
      });
    case GET_MONITORING_LIST_FAIL:
      return Object.assign({}, state, {
        monitorList: {
          ...state.monitorList,
          isLoading: false,
        },
      });
    case MANAGE_WORKFLOW_TOOL_POPOVER:
      return Object.assign({}, state, {
        isWorkflowPopupVisible: !state.isWorkflowPopupVisible,
        searchFilters: initialState.searchFilters,
        isStateSaving: false,
        isEventSaveing: false,
        duplicatingId: undefined,
        isDeleting: false,
      });
    case RETRY_TROUBLESHOOT_WORKFLOW_START:
      return Object.assign({}, state, {
      });
    case RETRY_TROUBLESHOOT_WORKFLOW_SUCCESS:
      return Object.assign({}, state, {
      });
    case RETRY_TROUBLESHOOT_WORKFLOW_FAIL:
      return Object.assign({}, state, {
      });
    case RETRY_TROUBLESHOOT_ACTIVITY_START:
      return Object.assign({}, state, {
      });
    case IGNORE_TROUBLESHOOT_ACTIVITY_START:
      return Object.assign({}, state, {
      });
    case DELETE_TROUBLESHOOT_ACTIVITY_START:
      return Object.assign({}, state, {
      });
    case TROUBLESHOOT_ACTIVITY_SUCCESS:
      return Object.assign({}, state, {
      });
    case TROUBLESHOOT_ACTIVITY_FAIL:
      return Object.assign({}, state, {
      });
    case HANDLE_TROUBLESHOOT_DRAWER:
      return Object.assign({}, state, {
        troubleshootDrawer: action.payload.data
      });
    case CHANGE_WORKFLOW_STATE_START:
      return Object.assign({}, state, {
        isStateChanging: true
      });
    case CHANGE_WORKFLOW_STATE_SUCCESS:
      return Object.assign({}, state, {
        troubleshootDrawer: troubleshootDrawerInitial,
        isStateChanging: false
      });
    case CHANGE_WORKFLOW_STATE_FAIL:
      return Object.assign({}, state, {
        isStateChanging: false
      });
    case GET_WORKFLOW_HISTORY_INIT_START:
      return Object.assign({}, state, {
        statusHistory: { ...initialState.statusHistory, isLoading: true },
        statusProperties: { ...initialState.statusProperties, isLoading: true }
      });
    case GET_WORKFLOW_HISTORY_INIT_SUCCESS:
      const { statusHistory,  statusProperties } = action.payload.data ?? {}
      return Object.assign({}, state, {
        statusHistory: { data: statusHistory, isLoading: false },
        statusProperties: { data: statusProperties, isLoading: false }
      });
    case GET_WORKFLOW_HISTORY_INIT_FAIL:
      return Object.assign({}, state, {
        statusHistory: initialState.statusHistory ,
        statusProperties: initialState.statusProperties
      });
    case GET_WORKFLOW_STATUS_PROPERTIES_START:
      return Object.assign({}, state, {
        statusProperties: { ...initialState.statusProperties, isLoading: true }
      });
    case GET_WORKFLOW_STATUS_PROPERTIES_SUCCESS:
      return Object.assign({}, state, {
        statusProperties: { data: action.payload.data, isLoading: false }
      });
    case GET_WORKFLOW_STATUS_PROPERTIES_FAIL:
      return Object.assign({}, state, {
        statusProperties: initialState.statusProperties
      });
    case RESET_WORKFLOW_STATUS_HISTORY:
      return Object.assign({}, state, {
        statusHistory: { ...initialState.statusHistory },
        statusProperties: { ...initialState.statusProperties}
      });
      
    default:
      return state;
  }
};
