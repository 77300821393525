import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Notes } from "us.collection/components";
import { EntityType } from "us.collection/constants";
import { ToggleClass } from "us.helper";

interface INotes extends RouteComponentProps {
  caseNo: number;
}

const Cnt_Notes: React.FC<INotes> = (props) => {
  ToggleClass("change-body-color-dom");

  return (
    <Notes entityType={EntityType.CREDITOR} entityId={props.caseNo} {...props} />
  );
};

export default withRouter(Cnt_Notes);
