import { call, put, takeLatest } from "redux-saga/effects";
import { Apportionment as API } from "us.collection.transactions/services";
import { ApportionmentAction } from "us.collection.transactions/actions";
import Constants from "us.collection.transactions/constants";

const { ACTIONS } = Constants;
const { Apportionment } = ACTIONS;

const apportionmentSagas = {
  get: function* (action: any) {
    const params = action.payload.data;
    try {
      const data = yield call(API.get, params.paymentId);
      yield put(ApportionmentAction.Apportionments.success(data));
    } catch (error) {
      yield put(ApportionmentAction.Apportionments.fail(error));
    }
  },
};

export default [takeLatest(Apportionment.GET_APPORTIONMENTS, apportionmentSagas.get)];
