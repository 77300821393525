import { WorkflowState } from "us.collection.case/constants";

export enum CloseCase {
  GET_CLOSE_CASE_START = "GET_CLOSE_CASE_START",
  GET_CLOSE_CASE_SUCCESS = "GET_CLOSE_CASE_SUCCESS",
  GET_CLOSE_CASE_FAIL = "GET_CLOSE_CASE_FAIL",

  CLOSE_CASE_POPOVER = "CLOSE_CASE_POPOVER",

  CLOSE_CASE_START = "CLOSE_CASE_START",
  CLOSE_CASE_SUCCESS = "CLOSE_CASE_SUCCESS",
  CLOSE_CASE_FAIL = "CLOSE_CASE_FAIL",
}

export const CloseStates: Array<string> = [
  WorkflowState.CLOSE,
  WorkflowState.CLOSED,
  WorkflowState.SUV_CLOSE_CASE,
];