import * as Yup from 'yup';

interface DomainMessageEmail extends Yup.MixedSchema {
	documentType: string;
	emailAddress: string;
	attachment: string;
	dueDate: string;
	template: string;
	caseNote: string;
	templateNote: string;
}

export default () => {
	return Yup.object<DomainMessageEmail>().shape({
		documentType: Yup.string().required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
		),
		emailAddress: Yup.string()
			.email(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
			)
			.required(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
			),
	});
};
