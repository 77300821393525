import { IconType } from "antd/lib/notification";
import ServiceConfig from "service.config.js";
import { IBMDPropertyDetailsParams, IChangeCountRequest } from "us.collection/interfaces";
import { $Notification } from "us.common/components";
import { versionedHttpCollection } from "us.helper/http/collection";

const openNotificationWithIcon = (type: IconType, msg: string, description: string, key: any) => {
  $Notification[type]({
    message: msg,
    description: description,
    key,
  });
};
export const BMD = {
  getList: async <P extends {}>(params: P): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status, statusText } = await versionedHttpCollection.get(
          "bmdservice",
          "bmdlist",
          params,
          ServiceConfig()[`bmdservice`]["bmd.list"]
        );
        if (status === 200 || status === 204) {
          resolve(data);
        } else {
          openNotificationWithIcon("error", status.toString(), statusText, Math.random());
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  addProperty: async <P extends {}>(params: P): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status, statusText } = await versionedHttpCollection.post(
          "bmdservice",
          "bmdproperties",
          params,
          ServiceConfig()[`bmdservice`]["bmdProperty.add"]
        );
        if (status === 201 || status === 200) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  getDataTypes: async <P extends {}>(params: P): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status, statusText } = await versionedHttpCollection.get(
          "bmdservice",
          "bmddatatypes",
          params,
          ServiceConfig()[`bmdservice`]["bmd.dataTypes"]
        );
        if (status === 200 || status === 204) {
          resolve(data);
        } else {
          openNotificationWithIcon("error", status.toString(), statusText, Math.random());
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  getDataTypeValues: async <P>(dataTypeId: number): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status, statusText } = await versionedHttpCollection.getById(
          "bmdservice",
          "bmddatatypevalues",
          dataTypeId,
          ServiceConfig()[`bmdservice`]["bmd.dataTypeValues"]
        );
        if (status === 200 || status === 204) {
          resolve(data);
        } else {
          openNotificationWithIcon("error", status.toString(), statusText, Math.random());
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  getPropertyDetails: async <P>(params: IBMDPropertyDetailsParams): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status, statusText } =
          await versionedHttpCollection.get<IBMDPropertyDetailsParams>(
            "bmdservice",
            "bmdproperties",
            params,
            ServiceConfig()[`bmdservice`]["bmdPropertyDetails.get"]
          );
        if (status === 200 || status === 204) {
          resolve(data);
        } else {
          openNotificationWithIcon("error", status.toString(), statusText, Math.random());
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  changePropertyDetails: async <P>(params: any): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status, statusText } = await versionedHttpCollection.put(
          "bmdservice",
          "bmdproperties",
          params,
          ServiceConfig()[`bmdservice`]["bmdProperty.change"]
        );
        if (status === 200 || status === 204) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  deletePropertyHistoryDetails: async <P>(params: any): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status, statusText } = await versionedHttpCollection.patch(
          "bmdservice",
          "bmdproperties",
          params,
          ServiceConfig()[`bmdservice`]["bmdPropertyHistoryDetails.delete"]
        );
        if (status === 200 || status === 204) {
          resolve(data);
        } else {
          openNotificationWithIcon("error", status.toString(), statusText, Math.random());
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  addCategory: async <P extends {}>(params: P): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.post(
          "bmdservice",
          "bmdcategories",
          params,
          ServiceConfig()[`bmdservice`]["bmdcategories.add"]
        );
        resolve({ data, status });
      } catch (error) {
        reject(error);
      }
    });
  },
  addGroup: async <P extends {}>(params: P): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.post(
          "bmdservice",
          "bmdgroups",
          params,
          ServiceConfig()[`bmdservice`]["bmdgroups.add"]
        );
        resolve({ data, status });
      } catch (error) {
        reject(error);
      }
    });
  },
  updateGroup: async <P extends {}>(params: P): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.put(
          "bmdservice",
          "bmdgroups",
          params,
          ServiceConfig()[`bmdservice`]["bmdgroups.update"]
        );
        resolve({ data, status });
      } catch (error) {
        reject(error);
      }
    });
  },
  searchLinkedCreditors: async <P extends { creditorId: string }>(params: P): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status, statusText } = await versionedHttpCollection.post(
          "entityservice",
          `entities/linkedCreditors/${params.creditorId}`,
          params,
          ServiceConfig()[`entityservice`]["entities.linkedcreditors"]
        );
        if (status === 200 || status === 204) {
          resolve(data);
        } else {
          openNotificationWithIcon("error", status.toString(), statusText, Math.random());
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  addDataType: async <P extends {}>(params: P): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.post(
          "bmdservice",
          "bmddatatypes",
          params,
          ServiceConfig()[`bmdservice`]["dataTypes.add"]
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateDataType: async <P extends { id: number }>(params: P): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.put(
          "bmdservice",
          `bmddatatypes/${params.id}`,
          params,
          ServiceConfig()[`bmdservice`]["dataTypes.edit"]
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getBMDDataTypeValueRetrievingSPs: async <P extends {  }>(
    params: P
  ): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.get(
          "bmdservice",
          "datatypestoredprocedures",
          params,
          ServiceConfig()[`bmdservice`]["dataTypeValueRetrievingSP.get"]
        );
        resolve({ data, status });
      } catch (error) {
        reject(error);
      }
    });
  },
  changeCount: async <
    P extends IChangeCountRequest
  >(
    params: P
  ): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { propertyId, currentLevel, entityId, entityType } = params;
        const { data, status, statusText } = await versionedHttpCollection.get(
          "bmdservice",
          `bmds/properties/${propertyId}/changes/${currentLevel}/${entityType}/${entityId}`,
          {},
          ServiceConfig()[`bmdservice`]["changeCount.get"]
        );
        if (status === 200 || status === 204) {
          resolve(data);
        } else {
          openNotificationWithIcon("error", status.toString(), statusText, Math.random());
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
};
