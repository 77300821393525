import { IInitialState } from 'us.collection.routines-and-activities/reducers/History/Interfaces';
import moment from 'moment';

export const initialState: IInitialState = {
	note: {
		data: [],
		isLoading: true,
	},
	creditorPortalNote: {
		data: [],
		isLoading: true,
	},
	workflowHistory: [],
	activity: {
		data: [],
		isLoading: false,
	},
	summaryData: {
		data: [],
		isLoading: false,
	},
	filterValue: 'all',
	searchValue: {},
	attachement: {
		data: [],
		isLoading: false,
		content: '',
	},
	parameters: {
		data: {},
		isLoading: false,
	},
	initailValue: {
		runBy: false,
		runNow: true,
		executingDateTime: moment(),
	},
	activities: {
		data: [],
		isLoading: false,
	},
	activiesFilterValue: 'all',
	userAndCase: {
		user: '',
		caseNumber: '',
	},
	noteDrawer: {
		title: '',
		visible: false,
	},
	runActivityDrawer: {
		title: '',
		visible: false,
	},
	runActivityStatus: false,
};
