import { IInitialState } from 'us.collection.case/reducers/OtherParty/Interfaces'

export const initialState: IInitialState = {
    otherParties: {
        data: [],
        isFetching: false,
    },
    otherParty: {
        data: {},
        isFetching: false,
    },
    addDrawer: {
        title: '',
        visible: false
    },
    roleType: {
        data: [],
        isFetching: false
    },
    searchData: {
        data: [],
        isFetching: false
    },
    postalcode: {
        data: [],
        isLoading: false,
        isResponse: false
    },
    municipility: {
        data: [],
        isLoading: false,
        isResponse: false
    },
    currentTab: ''
}