import React from "react";
import { CreditorCases } from "us.collection.creditor/components/CreditorCases";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ToggleClass } from "us.helper";

interface ICnt_EditCreditorInfo extends RouteComponentProps {
}

const Cnt_EditCreditorInfo: React.FC<ICnt_EditCreditorInfo> = (props) => {
  
  ToggleClass("change-body-color-dom");
  return <CreditorCases  {...props} />;
};

export default withRouter(Cnt_EditCreditorInfo);
