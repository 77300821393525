import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import "../NonProductionDays.scss";
import moment from "moment";
import Common from "us.common";
import { nonProductionDays } from "us.collection.admin/actions";
import { PlusOutlined, MinusOutlined } from "us.icons";
import {
  NonBankingDays,
  NonSmsDays,
  NonPrintingDays,
  NonWorkflowDays,
} from "../Add/Components";
import { IRootState } from "us.collection/interfaces";

const {
  $Tabs,
  $Button,
  $Skeleton,
  $Empty,
  $Collapse,
  $Tooltip,
  $Col,
  $Row,
} = Common.Components;

export interface ISortOrder {
  columnKey: string;
  order: any;
}

interface IImportCalendar {
  isFetching: boolean;
  currentDateFormat: string;
  currentLanguage: string;
  calendarId: string;
  values: any;
  selectedYear: number;
  importCalendarSelect: any;
  getDefaultNonProductionDays?: any;
  getPreviousCalendar?: any;
  smsAllowedTime?: any;
}

interface IBase extends IImportCalendar {}

const ImportCalendar: React.FC<IBase> = (props) => {
  const { t } = useTranslation(["US.COLLECTION.ADMIN", "US.COLLECTION.COMMON"]);
  const {
    isFetching,
    currentDateFormat,
    currentLanguage,
    calendarId,
    values,
    selectedYear,
    importCalendarSelect,
    getDefaultNonProductionDays,
    getPreviousCalendar,
    smsAllowedTime,
  } = props;

  const [importCalendarView, setImportCalendarView] = useState<boolean>(false);

  const genImportBtn = (index: number) => (
    <$Button
      type="primary"
      size="small"
      className="px-2"
      disabled={values?.previousCalendars[index]?.days.length == 0}
      onClick={() => importCalendarSelect(index)}
    >
      {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.IMPORT")}
    </$Button>
  );

  const importButtonClick = () => {
    setImportCalendarView(true);
    getPreviousCalendar(selectedYear, calendarId);
  };
  const importDefaultClick = () => {
    getDefaultNonProductionDays(selectedYear);
  };

  useEffect(() => {}, []);

  return (
    <>
      {typeof calendarId && values?.nonProductionDays.length == 0 && (
        <div className="mt-3">
          <$Row gutter={16}>
            <$Col span={10} className="pr-5">
              <div className="empty-calendar">
                <$Empty
                  className="mb-2"
                  description={`${t(
                    "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.YEAR"
                  )} ${selectedYear} ${t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NOTCONFIGURED")}.`}
                  image={$Empty.PRESENTED_IMAGE_SIMPLE}
                />
                {selectedYear >= moment().year() && (
                  <div className="text-center my-3">
                    <$Button
                      className="mx-2"
                      type="dashed"
                      size="small"
                      icon={<PlusOutlined />}
                      onClick={() => importButtonClick()}
                    >
                      {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.IMPORTFROMPREVIOUSYEAR")}
                    </$Button>
                    <$Button
                      className="mx-2"
                      type="dashed"
                      size="small"
                      icon={<PlusOutlined />}
                      onClick={() => importDefaultClick()}
                    >
                      {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.IMPORTDEFAULTCALENDAR")}
                    </$Button>
                  </div>
                )}
              </div>
            </$Col>

            {/* Import Calendar View */}
            {importCalendarView && values?.previousCalendars.length != 0 && (
              <$Col span={14} className="pr-5 pb-5">
                <$Collapse
                  defaultActiveKey={
                    values?.previousCalendars?.findIndex(
                      (cal: any) => cal.days.length != 0
                    ) + 1
                  }
                  expandIcon={({ isActive }) =>
                    isActive ? <MinusOutlined /> : <PlusOutlined />
                  }
                  //onChange={callback}
                  //expandIconPosition={expandIconPosition}
                >
                  <$Collapse.Panel
                    header={values?.previousCalendars[0]?.year}
                    key="1"
                    extra={genImportBtn(0)}
                    collapsible={
                      values?.previousCalendars[0]?.days?.length == 0
                        ? "disabled"
                        : undefined
                    }
                  >
                    <$Tabs className="bui-tabs" defaultActiveKey="1">
                      <$Tabs.TabPane
                        tab={
                          <$Tooltip
                            placement="top"
                            title={t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONBANKINGDAYSDESC")}
                          >
                            {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONBANKINGDAYS")}
                          </$Tooltip>
                        }
                        key="1"
                      >
                        <$Skeleton
                          loading={isFetching}
                          active
                          paragraph={{ rows: 2 }}
                        >
                          <NonBankingDays
                            nonBankingDays={values?.previousCalendars[0]?.days?.filter(
                              (nonProdDay: any) =>
                                nonProdDay?.criteria.find(
                                  (criteria: any) =>
                                    criteria.type === "NonBanking" &&
                                    criteria.value === 1
                                )
                            )}
                            isFetching={isFetching}
                            currentLanguage={currentLanguage}
                            isImportCalendar={true}
                          />
                        </$Skeleton>
                      </$Tabs.TabPane>
                      <$Tabs.TabPane
                        tab={
                          <$Tooltip
                            placement="top"
                            title={t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONSMSDAYSDESC")}
                          >
                            {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONSMSDAYS")}
                          </$Tooltip>
                        }
                        key="2"
                      >
                        <$Skeleton
                          loading={isFetching}
                          active
                          paragraph={{ rows: 2 }}
                        >
                          <NonSmsDays
                            nonSmsDays={values?.previousCalendars[0]?.days?.filter(
                              (nonProdDay: any) =>
                                nonProdDay?.criteria.find(
                                  (criteria: any) =>
                                    criteria.type === "NonSms" &&
                                    criteria.value === 1
                                )
                            )}
                            smsAllowedTime={smsAllowedTime?.map((day: any) => {
                              const timeSlots = day?.timeSlots?.filter(
                                (slot: any) =>
                                  slot.startTime.year() ==
                                    values?.previousCalendars[0]?.year &&
                                  slot.endTime.year() ==
                                    values?.previousCalendars[0]?.year
                              );
                              return {
                                ...day,
                                isEnable:
                                  timeSlots.length == 0 ? false : day.isEnable,
                                timeSlots:
                                  timeSlots.length == 0
                                    ? [
                                        {
                                          id: -1,
                                          startTime: moment()
                                            .year(
                                              values?.previousCalendars[0]?.year
                                            )
                                            .set({ hour: 8, minute: 0 }),
                                          endTime: moment()
                                            .year(
                                              values?.previousCalendars[0]?.year
                                            )
                                            .set({ hour: 20, minute: 0 }),
                                        },
                                      ]
                                    : timeSlots,
                              };
                            })}
                            isFetching={isFetching}
                            currentLanguage={currentLanguage}
                            isImportCalendar={true}
                          />
                        </$Skeleton>
                      </$Tabs.TabPane>
                      <$Tabs.TabPane
                        tab={
                          <$Tooltip
                            placement="top"
                            title={t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONPRINTINGDAYSDESC")}
                          >
                            {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONPRINTINGDAYS")}
                          </$Tooltip>
                        }
                        key="3"
                      >
                        <$Skeleton
                          loading={isFetching}
                          active
                          paragraph={{ rows: 2 }}
                        >
                          <NonPrintingDays
                            nonPrintingDays={values?.previousCalendars[0]?.days?.filter(
                              (nonProdDay: any) =>
                                nonProdDay?.criteria.find(
                                  (criteria: any) =>
                                    criteria.type === "NonPrinting" &&
                                    criteria.value === 1
                                )
                            )}
                            isFetching={isFetching}
                            currentLanguage={currentLanguage}
                            isImportCalendar={true}
                          />
                        </$Skeleton>
                      </$Tabs.TabPane>
                      <$Tabs.TabPane
                        tab={
                          <$Tooltip
                            placement="top"
                            title={t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONWORKFLOWDAYSDESC")}
                          >
                            {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONWORKFLOWDAYS")}
                          </$Tooltip>
                        }
                        key="4"
                      >
                        <$Skeleton
                          loading={isFetching}
                          active
                          paragraph={{ rows: 2 }}
                        >
                          <NonWorkflowDays
                            nonWorkflowDays={values?.previousCalendars[0]?.days?.filter(
                              (nonProdDay: any) =>
                                nonProdDay?.criteria.find(
                                  (criteria: any) =>
                                    criteria.type === "NonWorkflow" &&
                                    criteria.value === 1
                                )
                            )}
                            isFetching={isFetching}
                            currentLanguage={currentLanguage}
                            isImportCalendar={true}
                          />
                        </$Skeleton>
                      </$Tabs.TabPane>
                    </$Tabs>
                  </$Collapse.Panel>
                  <$Collapse.Panel
                    header={values?.previousCalendars[1]?.year}
                    key="2"
                    extra={genImportBtn(1)}
                    collapsible={
                      values?.previousCalendars[1]?.days?.length == 0
                        ? "disabled"
                        : undefined
                    }
                  >
                    <$Tabs className="bui-tabs" defaultActiveKey="1">
                      <$Tabs.TabPane
                        tab={
                          <$Tooltip
                            placement="top"
                            title={t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONBANKINGDAYSDESC")}
                          >
                            {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONBANKINGDAYS")}
                          </$Tooltip>
                        }
                        key="1"
                      >
                        <$Skeleton
                          loading={isFetching}
                          active
                          paragraph={{ rows: 2 }}
                        >
                          <NonBankingDays
                            nonBankingDays={values?.previousCalendars[1]?.days?.filter(
                              (nonProdDay: any) =>
                                nonProdDay?.criteria.find(
                                  (criteria: any) =>
                                    criteria.type === "NonBanking" &&
                                    criteria.value === 1
                                )
                            )}
                            isFetching={isFetching}
                            currentLanguage={currentLanguage}
                            isImportCalendar={true}
                          />
                        </$Skeleton>
                      </$Tabs.TabPane>
                      <$Tabs.TabPane
                        tab={
                          <$Tooltip
                            placement="top"
                            title={t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONSMSDAYSDESC")}
                          >
                            {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONSMSDAYS")}
                          </$Tooltip>
                        }
                        key="2"
                      >
                        <$Skeleton
                          loading={isFetching}
                          active
                          paragraph={{ rows: 2 }}
                        >
                          <NonSmsDays
                            nonSmsDays={values?.previousCalendars[1]?.days?.filter(
                              (nonProdDay: any) =>
                                nonProdDay?.criteria.find(
                                  (criteria: any) =>
                                    criteria.type === "NonSms" &&
                                    criteria.value === 1
                                )
                            )}
                            smsAllowedTime={smsAllowedTime?.map((day: any) => {
                              const timeSlots = day?.timeSlots?.filter(
                                (slot: any) =>
                                  slot.startTime.year() ==
                                    values?.previousCalendars[1]?.year &&
                                  slot.endTime.year() ==
                                    values?.previousCalendars[1]?.year
                              );
                              return {
                                ...day,
                                isEnable:
                                  timeSlots.length == 0 ? false : day.isEnable,
                                timeSlots:
                                  timeSlots.length == 0
                                    ? [
                                        {
                                          id: -1,
                                          startTime: moment()
                                            .year(
                                              values?.previousCalendars[1]?.year
                                            )
                                            .set({ hour: 8, minute: 0 }),
                                          endTime: moment()
                                            .year(
                                              values?.previousCalendars[1]?.year
                                            )
                                            .set({ hour: 20, minute: 0 }),
                                        },
                                      ]
                                    : timeSlots,
                              };
                            })}
                            isFetching={isFetching}
                            currentLanguage={currentLanguage}
                            isImportCalendar={true}
                          />
                        </$Skeleton>
                      </$Tabs.TabPane>
                      <$Tabs.TabPane
                        tab={
                          <$Tooltip
                            placement="top"
                            title={t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONPRINTINGDAYSDESC")}
                          >
                            {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONPRINTINGDAYS")}
                          </$Tooltip>
                        }
                        key="3"
                      >
                        <$Skeleton
                          loading={isFetching}
                          active
                          paragraph={{ rows: 2 }}
                        >
                          <NonPrintingDays
                            nonPrintingDays={values?.previousCalendars[1]?.days?.filter(
                              (nonProdDay: any) =>
                                nonProdDay?.criteria.find(
                                  (criteria: any) =>
                                    criteria.type === "NonPrinting" &&
                                    criteria.value === 1
                                )
                            )}
                            isFetching={isFetching}
                            currentLanguage={currentLanguage}
                            isImportCalendar={true}
                          />
                        </$Skeleton>
                      </$Tabs.TabPane>
                      <$Tabs.TabPane
                        tab={
                          <$Tooltip
                            placement="top"
                            title={t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONWORKFLOWDAYSDESC")}
                          >
                            {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONWORKFLOWDAYS")}
                          </$Tooltip>
                        }
                        key="4"
                      >
                        <$Skeleton
                          loading={isFetching}
                          active
                          paragraph={{ rows: 2 }}
                        >
                          <NonWorkflowDays
                            nonWorkflowDays={values?.previousCalendars[1]?.days?.filter(
                              (nonProdDay: any) =>
                                nonProdDay?.criteria.find(
                                  (criteria: any) =>
                                    criteria.type === "NonWorkflow" &&
                                    criteria.value === 1
                                )
                            )}
                            isFetching={isFetching}
                            currentLanguage={currentLanguage}
                            isImportCalendar={true}
                          />
                        </$Skeleton>
                      </$Tabs.TabPane>
                    </$Tabs>
                  </$Collapse.Panel>
                  <$Collapse.Panel
                    header={values?.previousCalendars[2]?.year}
                    key="3"
                    extra={genImportBtn(2)}
                    collapsible={
                      values?.previousCalendars[2]?.days?.length == 0
                        ? "disabled"
                        : undefined
                    }
                  >
                    <$Tabs className="bui-tabs" defaultActiveKey="1">
                      <$Tabs.TabPane
                        tab={
                          <$Tooltip
                            placement="top"
                            title={t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONBANKINGDAYSDESC")}
                          >
                            {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONBANKINGDAYS")}
                          </$Tooltip>
                        }
                        key="1"
                      >
                        <$Skeleton
                          loading={isFetching}
                          active
                          paragraph={{ rows: 2 }}
                        >
                          <NonBankingDays
                            nonBankingDays={values?.previousCalendars[2]?.days?.filter(
                              (nonProdDay: any) =>
                                nonProdDay?.criteria.find(
                                  (criteria: any) =>
                                    criteria.type === "NonBanking" &&
                                    criteria.value === 1
                                )
                            )}
                            isFetching={isFetching}
                            currentLanguage={currentLanguage}
                            isImportCalendar={true}
                          />
                        </$Skeleton>
                      </$Tabs.TabPane>
                      <$Tabs.TabPane
                        tab={
                          <$Tooltip
                            placement="top"
                            title={t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONSMSDAYSDESC")}
                          >
                            {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONSMSDAYS")}
                          </$Tooltip>
                        }
                        key="2"
                      >
                        <$Skeleton
                          loading={isFetching}
                          active
                          paragraph={{ rows: 2 }}
                        >
                          <NonSmsDays
                            nonSmsDays={values?.previousCalendars[2]?.days?.filter(
                              (nonProdDay: any) =>
                                nonProdDay?.criteria.find(
                                  (criteria: any) =>
                                    criteria.type === "NonSms" &&
                                    criteria.value === 1
                                )
                            )}
                            smsAllowedTime={smsAllowedTime?.map((day: any) => {
                              const timeSlots = day?.timeSlots?.filter(
                                (slot: any) =>
                                  slot.startTime.year() ==
                                    values?.previousCalendars[2]?.year &&
                                  slot.endTime.year() ==
                                    values?.previousCalendars[2]?.year
                              );
                              return {
                                ...day,
                                isEnable:
                                  timeSlots.length == 0 ? false : day.isEnable,
                                timeSlots:
                                  timeSlots.length == 0
                                    ? [
                                        {
                                          id: -1,
                                          startTime: moment()
                                            .year(
                                              values?.previousCalendars[2]?.year
                                            )
                                            .set({ hour: 8, minute: 0 }),
                                          endTime: moment()
                                            .year(
                                              values?.previousCalendars[2]?.year
                                            )
                                            .set({ hour: 20, minute: 0 }),
                                        },
                                      ]
                                    : timeSlots,
                              };
                            })}
                            isFetching={isFetching}
                            currentLanguage={currentLanguage}
                            isImportCalendar={true}
                          />
                        </$Skeleton>
                      </$Tabs.TabPane>
                      <$Tabs.TabPane
                        tab={
                          <$Tooltip
                            placement="top"
                            title={t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONPRINTINGDAYSDESC")}
                          >
                            {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONPRINTINGDAYS")}
                          </$Tooltip>
                        }
                        key="3"
                      >
                        <$Skeleton
                          loading={isFetching}
                          active
                          paragraph={{ rows: 2 }}
                        >
                          <NonPrintingDays
                            nonPrintingDays={values?.previousCalendars[2]?.days?.filter(
                              (nonProdDay: any) =>
                                nonProdDay?.criteria.find(
                                  (criteria: any) =>
                                    criteria.type === "NonPrinting" &&
                                    criteria.value === 1
                                )
                            )}
                            isFetching={isFetching}
                            currentLanguage={currentLanguage}
                            isImportCalendar={true}
                          />
                        </$Skeleton>
                      </$Tabs.TabPane>
                      <$Tabs.TabPane
                        tab={
                          <$Tooltip
                            placement="top"
                            title={t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONWORKFLOWDAYSDESC")}
                          >
                            {t("US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.NONWORKFLOWDAYS")}
                          </$Tooltip>
                        }
                        key="4"
                      >
                        <$Skeleton
                          loading={isFetching}
                          active
                          paragraph={{ rows: 2 }}
                        >
                          <NonWorkflowDays
                            nonWorkflowDays={values?.previousCalendars[2]?.days?.filter(
                              (nonProdDay: any) =>
                                nonProdDay?.criteria.find(
                                  (criteria: any) =>
                                    criteria.type === "NonWorkflow" &&
                                    criteria.value === 1
                                )
                            )}
                            isFetching={isFetching}
                            currentLanguage={currentLanguage}
                            isImportCalendar={true}
                          />
                        </$Skeleton>
                      </$Tabs.TabPane>
                    </$Tabs>
                  </$Collapse.Panel>
                </$Collapse>
              </$Col>
            )}
          </$Row>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { nonProductionDays, common } = state;
  const { redDays } = nonProductionDays;
  return {
    isFetching: redDays.isFetching,
    currentDateFormat: common.currentDateFormat,
    currentLanguage: common.currentLanguage,
  };
};

const mapDispatchToProps = {
  getDefaultNonProductionDays: nonProductionDays.redDays.set,
  getPreviousCalendar: nonProductionDays.calendar.getPrevious,
  importCalendarSelect: nonProductionDays.calendar.selectImport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportCalendar);
