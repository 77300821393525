import { IColumn } from "./Interfaces";

export const enum EntityFollowUpsDataIndex {
  FOLLOWUP_NAME = "followupName",
  ACTION = "action",
  COMMENT = "comment",
  ASSIGNED_TO = "assignedTo",
  ACTION_BY = "actionBy",
  ACTION_DATE = "actionDate"
} 

export const ENTITY_FOLLOW_UP_HISTORY_COLUMNS: Array<IColumn> = [
  { dataIndex: EntityFollowUpsDataIndex.FOLLOWUP_NAME, title: "NAME" },
  { dataIndex: EntityFollowUpsDataIndex.ACTION, title: "ACTION" },
  { dataIndex: EntityFollowUpsDataIndex.COMMENT, title: "COMMENT" },
  { dataIndex: EntityFollowUpsDataIndex.ASSIGNED_TO, title: "ASSIGNED_TO" },
  { dataIndex: EntityFollowUpsDataIndex.ACTION_BY, title: "ACTION_BY" },
  {
    dataIndex: EntityFollowUpsDataIndex.ACTION_DATE,
    title: "ACTION_DATE",
    width: 150,
  },
];
