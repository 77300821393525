export enum FilterType {
    Open = "open",
    Sentence = "sentence",
    NextDueDate = "nextDueDate",
    Court = "court",
    Close = "close",
    SearchText = "searchText",
    EntityType = "entityType",
    UserOwnedClaims = "userOwnedClaims",
    ActionStatus = "actionStatus",
}