import {
  IErrorCount,
  IStateList,
  IWorkflowGroup,
} from "us.collection/reducers/Workflow/Interfaces";
import { ErrorType, TroubleshootTableColumns } from "us.collection/components/WorkflowTroubleshoot"

/**
 * Filter state from state list
 * @param stateList - State List
 * @param text - Search text
 * @returns - Filtered state list
 */
export const filterState = (
  stateList: Array<IStateList>,
  text: string
): Array<IStateList> => {
  try {
    const states = stateList?.filter((state: IStateList) => {
      return state.displayName.toLowerCase().includes(text.toLowerCase());
    });
    return states;
  } catch {
    return [];
  }
};

/**
 * Search states
 * @param list - State list
 * @param text - Search text
 * @returns - Filtered state list with group details
 */
export const searchState = (
  list: Array<IWorkflowGroup>,
  text: string
): Array<IWorkflowGroup> | undefined => {
  try {
    if (text.length > 0) {
      let groups: Array<IWorkflowGroup> = [];
      list?.map((group: IWorkflowGroup) => {
        const states = filterState(group?.stateList, text);
        if (states.length) {
          groups.push({
            ...group,
            stateList: states,
          });
        }
      });
      return groups;
    }
    return list;
  } catch {
    return [];
  }
};

/**
 * @function
 * Get total error count
 * @param errors Errot list
 * @returns Total error count
 */
export const getTotalErrorCount = (errors: Array<IErrorCount>): number => {
  try {
    return Object.values(errors).reduce((total, { count }) => total + count, 0);
  } catch {
    return 0;
  }
};

/**
 * @function
 * Get error description by type
 * @param errorCount Error list
 * @param errorType Error type
 * @returns Error description
 */
export const getErrorDescription = (
  errorCount: Array<IErrorCount>,
  errorType: string
): string => {
  try {
    const error = errorCount?.find((type: IErrorCount) => {
      return type.errorType === errorType;
    });
    return error?.errorDescription ?? "";
  } catch {
    return "";
  }
};

/**
 * @function
 * Get Error count by type
 * @param errorCount Error list
 * @param errorType Error type
 * @returns Error count
 */
export const getErrorCountByType = (
  errorCount: Array<IErrorCount>,
  errorType: string
): number => {
  try {
    const error = errorCount?.find((type: IErrorCount) => {
      return type.errorType === errorType;
    });
    return error?.count ?? 0;
  } catch {
    return 0;
  }
};

/**
 * @function
 * Add children prop for table data for row expand
 * @param workflowStates Workflow group state
 * @param workflowType Workflow current type
 * @returns
 */
export const formatStateData = (
  workflowStates: Array<IWorkflowGroup>,
  workflowType: string
): Array<IWorkflowGroup> => {
  const newGroupList: Array<IWorkflowGroup> = [];
  workflowStates.map((state: IWorkflowGroup) => {
    let newStateList : Array<IStateList> = [];
    state.stateList.map((item: IStateList ) => { 
      if(item.workflowType == workflowType){
        newStateList.push(item);
      }
    });
    if(newStateList.length > 0){
      newGroupList.push({
        ...state,
        children: newStateList,
      })
    }
  });
  return newGroupList;
};

/**
 * @function
 * @description Display workflow state column in failed and stuck workflow tabs
 * @param workflowErrorType active tab
 * @param columns Table columns
 * @returns Filtered columns
 */
export const filterVisibleColumns = (
  workflowErrorType: ErrorType,
  columns: Array<any>
): Array<any> => {
  try {
    if (
      workflowErrorType == ErrorType.FAILED_WORKFLOWS ||
      workflowErrorType == ErrorType.STUCK_WORKFLOWS
    ) {
      return columns;
    } else {
      return columns?.filter(
        ({ dataIndex }: any) =>
          dataIndex != TroubleshootTableColumns.WORKFLOW_STATE
      );
    }
  } catch {
    return columns;
  }
};