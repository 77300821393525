import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { INavigationData, IRootState } from 'us.collection/interfaces';
import { Header } from 'us.collection.transactions/layouts/Ar/Header';
import { RouteComponentProps, matchPath, withRouter } from 'react-router-dom';
import { BoxIcons, IconTypes } from 'us.icons';
import { useTranslation } from 'react-i18next';
import Common from 'us.common';
import { ConfigurationContext } from 'us.common/ConfigurationContext/ConfigurationProvider';
import { IDataModel } from 'us.collection.case/reducers/Dashboard/Interfaces';
import { IMetaData } from 'us.collection/interfaces';
import { getSelectedMenuKeyByModule } from 'us.collection/functions';

const { $Layout, $Menu } = Common.Components;

const { Content, Sider } = $Layout;

interface ILayout extends RouteComponentProps {
	metaData?: IDataModel<IMetaData>;
}

const Home: React.FC<ILayout> = (props) => {
	const { t } = useTranslation([
		'US.COLLECTION.LAYOUT',
		'US.COLLECTION.TRANSACTIONS',
	]);

	const context = useContext(ConfigurationContext);
	const [arPermission, setArPermission] = useState<any>();

	const { params }: any = matchPath(props.location.pathname, {
		path: '/:type/:Id?/:module?',
		exact: false,
		strict: false,
	});

	const { metaData, history, location } = props;
	const state = location.state as INavigationData;
	const { arId } = metaData?.data ?? {};

	const BODY_CLASS = 'change-body-color-dom';

	useEffect(() => {
		setArPermission(context.componentPermission['ar']);
	}, [context]);

	useEffect(() => {
		document.body.classList.add(BODY_CLASS);
		return () => {
			document.body.classList.remove(BODY_CLASS);
		};
	}, []);

	const navigate = (url: string): void => {
		if (arId) {
			window.scrollTo(0, 0);
			history.push({
				...location,
				pathname: `/ar/${arId}/${url}`,
			});
		}
	};

	const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

	const onCollapse = (collapsed: boolean): void => {
		setIsCollapsed(collapsed);
	};

	const menuFieldsAvalibility = (menuItem: string): boolean => {
		return arPermission?.routes[menuItem]?.isEnabled;
	};

	const headerAvailability = (toolMenu: string): boolean => {
		return arPermission?.components[toolMenu]?.isEnabled;
	};

	return (
		<$Layout style={{ minHeight: 'calc(100vh - 128px)' }}>
			{headerAvailability('activityExecutionBar') && (
				<Header {...props}></Header>
			)}
			<$Layout>
				<Content className='mt-0'>
					<main>{props.children}</main>
				</Content>
				<Sider
					collapsible
					collapsed={isCollapsed}
					onCollapse={(e) => onCollapse(e)}
					reverseArrow={true}>
					<$Menu
						theme='dark'
						mode='inline'
						selectedKeys={getSelectedMenuKeyByModule(
							params['module']
						)}>
						{menuFieldsAvalibility(
							'applicableCourt'
						) && (
							<$Menu.Item
								key='7'
								onClick={() =>
									navigate(
										'applicable-courts'
									)
								}
								data-testid='ar-layout-applicableCourt'>
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='applicable-courts'
								/>
								<span>
									{t(
										'US.COLLECTION.LAYOUT:AR.APPLICABLE_COURTS'
									)}
								</span>
							</$Menu.Item>
						)}
						{menuFieldsAvalibility(
							'caseDocuments'
						) && (
							<$Menu.Item
								onClick={() =>
									navigate(
										'documents'
									)
								}
								key='4'
								data-testid='ar-layout-caseDocuments'>
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='documents'
								/>
								<span>
									{t(
										'US.COLLECTION.LAYOUT:CASE.AR_DOCUMENTS'
									)}
								</span>
							</$Menu.Item>
						)}
						{menuFieldsAvalibility(
							'arTransaction'
						) && (
							<$Menu.Item
								key='18'
								onClick={() =>
									navigate(
										'transactions'
									)
								}
								data-testid='ar-layout-arTransactions'>
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='transaction'
								/>
								<span>
									{t(
										'US.COLLECTION.LAYOUT:AR.AR_TRANSACTIONS'
									)}
								</span>
							</$Menu.Item>
						)}
						{menuFieldsAvalibility(
							'assets'
						) && (
							<$Menu.Item
								onClick={() =>
									navigate(
										'assets'
									)
								}
								key='2'
								data-testid='ar-layout-assets'>
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='assets'
								/>
								<span>
									{t(
										'US.COLLECTION.LAYOUT:CASE.ASSETS'
									)}
								</span>
							</$Menu.Item>
						)}
						{menuFieldsAvalibility(
							'routinesAndActivities'
						) && (
							<$Menu.Item
								onClick={() =>
									navigate(
										'history'
									)
								}
								key='16'
								data-testid='ar-layout-routinesAndActivities'>
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='activity-history'
								/>
								<span>
									{t(
										'US.COLLECTION.LAYOUT:CASE.HISTORY'
									)}
								</span>
							</$Menu.Item>
						)}
						{menuFieldsAvalibility(
							'messageTemplates'
						) && (
							<$Menu.Item
								onClick={() =>
									navigate(
										'message-templates'
									)
								}
								key='12'
								data-testid='dashboard-layout-messageTemplates'>
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='template'
								/>
								<span>
									{t(
										'US.COLLECTION.LAYOUT:CASE.MESSAGETEMPLATES'
									)}
								</span>
							</$Menu.Item>
						)}
						{menuFieldsAvalibility(
							'caseAndSubcase'
						) && (
							<$Menu.Item
								key='6'
								onClick={() =>
									navigate(
										'cases-and-subcases'
									)
								}>
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='cases-subcases'
								/>
								<span>
									{t(
										'US.COLLECTION.LAYOUT:AR.CASES_AND_SUBCASES'
									)}
								</span>
							</$Menu.Item>
						)}
						{menuFieldsAvalibility(
							'otherParty'
						) && (
							<$Menu.Item
								onClick={() =>
									navigate(
										'other-party'
									)
								}
								key='14'
								data-testid='ar-layout-otherParty'>
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='other-party'
								/>
								<span>
									{t(
										'US.COLLECTION.LAYOUT:CASE.OTHERPARTY'
									)}
								</span>
							</$Menu.Item>
						)}
						{menuFieldsAvalibility(
							'notes'
						) && (
							<$Menu.Item
								onClick={() =>
									navigate(
										'notes'
									)
								}
								key='15'
								data-testid='ar-layout-notes'>
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='note'
								/>
								<span>
									{t(
										'US.COLLECTION.LAYOUT:CASE.NOTES'
									)}
								</span>
							</$Menu.Item>
						)}
						{menuFieldsAvalibility(
							'bmd'
						) && (
							<$Menu.Item
								onClick={() =>
									navigate(
										'bmd'
									)
								}
								key='17'
								data-testid='ar-layout-bmd'>
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='bmd'
								/>
								<span>
									{t(
										'US.COLLECTION.LAYOUT:CASE.BMD'
									)}
								</span>
							</$Menu.Item>
						)}
					</$Menu>
				</Sider>
			</$Layout>
		</$Layout>
	);
};

const mapStateToProps = (state: IRootState) => {
	const { domainView } = state;
	const { metaData } = domainView;
	return {
		metaData,
	};
};

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
