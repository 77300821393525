import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	IEntitySearch,
	ISortOrder,
} from 'us.collection.case/components/Interfaces/OtherParty/interfaces';
import Common from 'us.common';
import Highlighter from 'react-highlight-words';
import { Formik } from 'formik';
import { IRootState } from 'us.collection/interfaces';
import { connect } from 'react-redux';
import * as Actions from 'us.collection.case/actions';
import { IntlDate } from 'us.common/functions';
import { SearchOutlined, FilterOutlined } from 'us.icons';
import { Input } from 'antd';
import moment from 'moment';
import { Criteria } from 'us.collection.case/constants';
import { IOnFilter, IOnSort } from 'us.common/components/antd/TableTree';

const {
	$Button,
	$Input,
	$Table,
	$Skeleton,
	$AutoComplete,
	$Search,
	$TableTree,
	$DateLabel,
} = Common.Components;

export const invalidDate = '0001-01-01T00:00:00';

const { OtherPartyAction } = Actions;
const { otherParty } = OtherPartyAction;

const EntitySearch: React.FC<IEntitySearch> = (props) => {
	const { t } = useTranslation();
	const [searchText, setSearchText] = useState<string>('');

	const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
		columnKey: '',
		order: '',
	});
	const [options, setOptions] = useState<any>([]);
	const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);

	const {
		searchData,
		search,
		setData,
		currentLanguage,
		currentDateFormat,
		reset,
	} = props;

	useEffect(() => {
		reset({});
	}, []);

	const handleSearch = (selectedKeys: any, confirm: any) => {
		confirm();
		setSearchText(selectedKeys[0]);
	};

	const handleReset = (clearFilters: any) => {
		clearFilters();
		setSearchText('');
	};

	const handleTableChange = (
		pagination: any,
		filters: any,
		sorter: any
	) => {
		setSortedInfo(sorter);
	};

	const formatObject = (data: any) => {
		// amount: IntlCurrency(data?.amount, currentLanguage, currentCurrency),
		return {
			...data,
			birthDate: IntlDate(
				data?.birthDate,
				currentLanguage,
				currentDateFormat
			),
		};
	};
	const getColumnSearchProps = (dataIndex: string, title: string) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}: any) => (
			<div style={{ padding: 8 }}>
				<$Input
					name='columnSearch'
					placeholder={`${t(
						'US.COLLECTION.COMMON:COMMON.SEARCH'
					)} ${title}`}
					value={selectedKeys[0]}
					onChange={(e: any) =>
						setSelectedKeys(
							e.target.value
								? [
										e
											.target
											.value,
								  ]
								: []
						)
					}
					onPressEnter={() =>
						handleSearch(
							selectedKeys,
							confirm
						)
					}
					style={{
						width: 188,
						marginBottom: 8,
						display: 'block',
					}}
				/>

				<$Button
					type='primary'
					onClick={() =>
						handleSearch(
							selectedKeys,
							confirm
						)
					}
					icon={<SearchOutlined />}
					size='small'
					style={{ width: 90, marginRight: 8 }}>
					{t(
						'US.COLLECTION.COMMON:COMMON.SEARCH'
					)}
				</$Button>
				<$Button
					onClick={() =>
						handleReset(clearFilters)
					}
					size='small'
					style={{ width: 90 }}>
					{t('US.COLLECTION.COMMON:COMMON.RESET')}
				</$Button>
			</div>
		),
		filterIcon: (filtered: string) => (
			<FilterOutlined
				style={{
					color: filtered ? '#1890ff' : undefined,
				}}
			/>
		),
		onFilter: (value: string, record: any) => {
			let formatRecord = formatObject(record);
			return formatRecord[dataIndex]
				?.toString()
				.toLowerCase()
				.includes(value.toLowerCase());
		},
		render: (text: string) => (
			<Highlighter
				highlightStyle={{
					backgroundColor: '#ffc069',
					padding: 0,
				}}
				searchWords={[searchText]}
				autoEscape
				textToHighlight={text ? text.toString() : ''}
			/>
		),
	});

	const columns: any = [
		{
			title: t('US.COLLECTION.CASE:OTHERPARTY.NAME'),
			dataIndex: 'name',
			key: 'name',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
		{
			title: t('US.COLLECTION.CASE:OTHERPARTY.NIN'),
			dataIndex: 'nIN',
			key: 'nIN',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
		},
		{
			title: t('US.COLLECTION.CASE:OTHERPARTY.BIRTH_DATE'),
			dataIndex: 'birthDate',
			key: 'birthDate',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (text: any, record: any) => {
				const { birthDate } = record;
				return (
					birthDate != invalidDate &&
					!moment
						.utc(birthDate)
						.isSame(
							moment.utc('1900-01-01')
						) && (
						<$DateLabel value={birthDate} />
					)
				); //
			},
		},
		{
			title: t('US.COLLECTION.CASE:OTHERPARTY.ADDRESS'),
			dataIndex: 'address',
			key: 'address',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
	];

	const mainCategories = [
		{
			label: `${t(
				'US.COLLECTION.CASE:OTHERPARTY.NAME'
			).toString()}: `,
			value: `${Criteria.NAME}:`,
		},
		{
			label: `${t(
				'US.COLLECTION.CASE:OTHERPARTY.NIN'
			).toString()}: `,
			value: `${Criteria.NIN}:`,
		},
		{
			label: `${t(
				'US.COLLECTION.CASE:OTHERPARTY.BIRTH_DATE'
			).toString()}: `,
			value: `${Criteria.BIRTH_DATE}:`,
		},
		{
			label: `${t(
				'US.COLLECTION.CASE:OTHERPARTY.ADDRESS'
			).toString()}: `,
			value: `${Criteria.ADDRESS}:`,
		},
	];

	const onChange = (searchValue: any) => {
		setDropDownOpen(true);
		let value: any = searchValue;
		if (searchValue.includes(':')) {
			let [searchCriteria, text] = searchValue.split(':');
			value = text;
		}
		setOptions(
			mainCategories?.map((item: any) => {
				return {
					label: item.label + value.trim(),
					value: item.value + value.trim(),
				};
			})
		);
	};
	const onDropdownVisibleChange = () => {
		if (dropDownOpen) {
			setDropDownOpen(false);
		}
	};

	const dateSetup = (date: string) => {
		if (moment(date, currentDateFormat, true).isValid()) {
			return moment(date, currentDateFormat).format(
				'YYYY-MM-DD'
			);
		} else {
			return date;
		}
	};

	const handleSearchData = (searchValue: any) => {
		setDropDownOpen(!dropDownOpen);
		if (searchValue.includes(':')) {
			if (searchValue.split(':').length === 2) {
				let [searchCriteria, text] =
					searchValue.split(':');
				text =
					searchCriteria === Criteria.BIRTH_DATE
						? dateSetup(text)
						: text?.trim();
				search({
					roleType: props.roleType ?? '',
					searchCriteria,
					text,
				});
			} else {
				setOptions([]);
			}
		}
	};

	const handlePagination = () => {
		var x = document.getElementById('searchData');
		x?.scrollIntoView();
	};

	const handleSort: IOnSort = (sortData, dataSource) => {
		return sortData(dataSource);
	};

	const handleFilter: IOnFilter = (searchData, dataSource) => {
		return searchData(dataSource);
	};

	return (
		<Formik
			initialValues={{}}
			onSubmit={(values: any, actions: any) => {}}>
			<div id='searchData'>
				<div className='d-block' id='autocompleteid'>
					<$AutoComplete
						name='search'
						placeholder={t(
							'US.COLLECTION.CASE:OTHERPARTY.SEARCH'
						)}
						size='small'
						options={options}
						open={dropDownOpen}
						onSearch={(
							searchValue: string
						) => onChange(searchValue)}
						onDropdownVisibleChange={
							onDropdownVisibleChange
						}
						onSelect={(
							inputValue: string
						) =>
							handleSearchData(
								inputValue
							)
						}>
						<Input.Search
							onSearch={(
								inputValue: string
							) =>
								handleSearchData(
									inputValue
								)
							}
						/>
					</$AutoComplete>
				</div>

				<$Skeleton
					loading={searchData?.isFetching}
					active
					paragraph={{ rows: 2 }}>
					<$TableTree
						onChange={handleTableChange}
						rowKey='arItemNo'
						data={searchData?.data}
						size='small'
						className='mt-4'
						onSort={handleSort}
						onFilter={handleFilter}
						filterOnType={true}
						resetOnSourceChange={true}
						bordered
						pagination={{
							defaultPageSize: 15,
							onChange: () =>
								handlePagination(),
						}}
						scroll={{
							x: 800,
							y: 'calc(100vh - 315px)',
						}}
						columns={columns}
						onRow={(
							record: any,
							rowIndex: any
						) => {
							return {
								onDoubleClick: (
									event: any
								) => {
									setData(
										record
									);
								},
							};
						}}
						firstColSkipFilterProps={-1}
					/>
				</$Skeleton>
			</div>
		</Formik>
	);
};

const mapStateToProps = (state: IRootState) => {
	const { otherParty, common, domainView } = state;
	const { searchData } = otherParty;
	const { currentDateFormat, currentCurrency, currentLanguage } = common;
	const { metaData } = domainView;
	return {
		currentDateFormat,
		currentCurrency,
		currentLanguage,
		metaData,
		searchData,
	};
};
const mapDispatchToProps = {
	search: otherParty.search,
	reset: otherParty.searchReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntitySearch);
