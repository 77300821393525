import { Enforcement } from "us.collection.case/constants/Actions";
import {
  replaceCaseBalanceByAccountSummary,
  setupEnforcementData,
} from "us.collection.case/functions";
import { initialState } from "./State";
import { AssetTypes } from "us.collection.case/constants";
import _ from "lodash";

const {
  GET_ENFORCEMENTS_START,
  GET_ENFORCEMENTS_SUCCESS,
  GET_ENFORCEMENTS_FAIL,
  DELETE_ENFORCEMENTS_START,
  DELETE_ENFORCEMENTS_SUCCESS,
  DELETE_ENFORCEMENTS_FAIL,
  GET_ENFORCEMENT_TYPES,
  GET_ENFORCEMENT_TYPES_SUCCESS,
  GET_ENFORCEMENT_TYPES_FAIL,
  SET_ENFORCEMENT_ITEM,
  SET_ENFORCEMENTS_FILTER,
  SELECT_ENFORCEMENT,
  RESET_ENFORCEMENT_DETAILS,
  SAVE_SALARY_DEDUCTION_PLAN,
  SAVE_SALARY_DEDUCTION_PLAN_SUCCESS,
  SAVE_SALARY_DEDUCTION_PLAN_FAIL,
  GET_SALARY_DEDUCTION_PLAN,
  GET_SALARY_DEDUCTION_PLAN_SUCCESS,
  GET_SALARY_DEDUCTION_PLAN_FAIL,
  SET_SALARY_DEDUCTION_PLAN,
  RESET_SALARY_DEDUCTION_PLAN,
  RESET_SALARY_DEDUCTION_SCHEMA,
  DELETE_SALARY_DEDUCTION_PLAN,
  RESET_DELETED_SALARY_DEDUCTION_PLAN,
  MANAGE_DRAWER,
  GET_ENFORCEMENT_ACCOUNT_SUMMARY,
  GET_ENFORCEMENT_ACCOUNT_SUMMARY_SUCCESS,
  GET_ENFORCEMENT_ACCOUNT_SUMMARY_FAIL,
} = Enforcement;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_ENFORCEMENTS_START:
      return Object.assign({}, state, {
        enforcements: {
          data: [],
          isFetching: true,
        },
      });
    case GET_ENFORCEMENTS_SUCCESS:
      return Object.assign({}, state, {
        enforcements: {
          data: payload?.data?.map((enforcement: any) => {
            return {
              ...enforcement,
              enforcedItems: enforcement?.enforcedItems?.map(
                (enforcedItem: any) => ({
                  ...enforcedItem,
                  key: `${enforcedItem?.assetId}-${enforcedItem?.assetType}`,
                })
              ),
            };
          }),
          dataSource: setupEnforcementData(payload?.data),
          isFetching: false,
        },
      });
    case GET_ENFORCEMENTS_FAIL:
      return Object.assign({}, state, {
        enforcements: {
          data: [],
          isFetching: false,
        },
      });
    case DELETE_ENFORCEMENTS_SUCCESS:
      return Object.assign({}, state, {
        enforcements: {
          data: state?.enforcements?.data?.filter(
            (enf: any) => enf?.enforcementId != payload?.data?.enforcementId
          ),
          dataSource: setupEnforcementData(
            state?.enforcements?.data?.filter(
              (enf: any) => enf?.enforcementId != payload?.data?.enforcementId
            )
          ),
          isFetching: false,
        },
      });
    case SET_ENFORCEMENTS_FILTER:
      return Object.assign({}, state, {
        enforcements: {
          data: state?.enforcements?.data,
          dataSource:
            payload?.data?.filterValue === ""
              ? setupEnforcementData(state?.enforcements?.data)
              : setupEnforcementData(
                  state?.enforcements?.data?.filter(
                    (enf: any) =>
                      enf?.referenceId?.toLowerCase() ===
                      payload?.data?.filterValue?.toLowerCase()
                  )
                ),
          isFetching: false,
        },
      });
    case GET_ENFORCEMENT_TYPES:
      return Object.assign({}, state, {
        enforcementTypes: {
          data: state.enforcementTypes.data,
          isFetching: true,
        },
        enforcementDetail: {
          data: state.enforcementDetail.data,
          isFetching: true,
        },
      });
    case GET_ENFORCEMENT_TYPES_SUCCESS:
      return Object.assign({}, state, {
        enforcementTypes: {
          data: payload?.data,
          isFetching: false,
        },
        enforcementDetail: {
          data: {
            ...state.enforcementDetail.data,
            enforcedItems: state.enforcementDetail.data.enforcedItems.map(
              (item: any) => {
                const matchedEnforcementType = _.find(
                  payload?.data,
                  (enforcedType: any) =>
                    enforcedType?.assetType == item?.assetType &&
                    enforcedType?.enforcementTypeName == item?.enforcementType
                );
                return {
                  ...item,
                  enforcementTypeId: matchedEnforcementType?.enforcementTypeId,
                  assetTypeId: matchedEnforcementType?.assetTypeId,
                  valuationAmount: item?.valuation,
                };
              }
            ),
          },
          isFetching: false,
        },
      });
    case GET_ENFORCEMENT_TYPES_FAIL:
      return Object.assign({}, state, {
        enforcementTypes: {
          data: state.enforcementTypes.data,
          isFetching: false,
        },
        enforcementDetail: {
          data: state.enforcementDetail.data,
          isFetching: false,
        },
      });
    case SELECT_ENFORCEMENT:
      return Object.assign({}, state, {
        enforcementDetail: {
          data: payload?.data,
          isFetching: false,
        },
      });
    case RESET_ENFORCEMENT_DETAILS:
      return Object.assign({}, state, {
        enforcementDetail: { ...initialState.enforcementDetail },
        salaryDeductionPlan: { ...initialState.salaryDeductionPlan },
        deletedSalaryDeductionPlan: {
          ...initialState.deletedSalaryDeductionPlan,
        },
      });
    case SET_ENFORCEMENT_ITEM:
      const { selectedEnforcementItem, index, formData } = payload?.data ?? {};
      return Object.assign({}, state, {
        selectedEnforcementItem: {
          data: selectedEnforcementItem,
          index,
          isFetching: false,
        },
      });
    case SAVE_SALARY_DEDUCTION_PLAN:
      return Object.assign({}, state, {
        salaryDeductionPlan: {
          data: { ...state.salaryDeductionPlan.data, ...payload?.data },
          isFetching: true,
          isSet: false,
        },
      });
    case SAVE_SALARY_DEDUCTION_PLAN_SUCCESS:
      return Object.assign({}, state, {
        salaryDeductionPlan: {
          data: {
            ...state.salaryDeductionPlan.data,
            salaryDeductionSchema: payload?.data,
          },
          isFetching: false,
          isSet: false,
        },
      });
    case SAVE_SALARY_DEDUCTION_PLAN_FAIL:
      return Object.assign({}, state, {
        salaryDeductionPlan: {
          ...state.salaryDeductionPlan,
          isFetching: false,
          isSet: false,
        },
      });
    case SET_SALARY_DEDUCTION_PLAN:
      return Object.assign({}, state, {
        salaryDeductionPlan: {
          data: { ...state.salaryDeductionPlan.data, ...payload?.data },
          isFetching: false,
          isSet: true,
        },
      });
    case RESET_SALARY_DEDUCTION_PLAN:
      return Object.assign({}, state, {
        salaryDeductionPlan: { ...initialState.deletedSalaryDeductionPlan },
      });
    case RESET_SALARY_DEDUCTION_SCHEMA:
      return Object.assign({}, state, {
        salaryDeductionPlan: {
          data: {
            ...state.salaryDeductionPlan.data,
            salaryDeductionSchema: [],
          },
          isFetching: false,
          isSet: false,
        },
      });
    case DELETE_SALARY_DEDUCTION_PLAN:
      return Object.assign({}, state, {
        salaryDeductionPlan: { ...initialState.salaryDeductionPlan },
        deletedSalaryDeductionPlan: { data: state.salaryDeductionPlan.data },
      });
    case RESET_DELETED_SALARY_DEDUCTION_PLAN:
      return Object.assign({}, state, {
        salaryDeductionPlan: {
          data: { ...state.deletedSalaryDeductionPlan.data },
          isFetching: false,
          isSet: true,
        },
        deletedSalaryDeductionPlan: {
          ...initialState.salaryDeductionPlan.data,
        },
      });
    case GET_SALARY_DEDUCTION_PLAN:
      return Object.assign({}, state, {
        salaryDeductionPlan: {
          ...state.salaryDeductionPlan,
          isFetching: true,
          isSet: false,
        },
      });
    case GET_SALARY_DEDUCTION_PLAN_SUCCESS:
      return Object.assign({}, state, {
        salaryDeductionPlan: {
          data: { ...state.salaryDeductionPlan.data, ...payload?.data },
          isFetching: false,
          isSet: true,
        },
        deletedSalaryDeductionPlan: {
          ...initialState.deletedSalaryDeductionPlan,
        },
      });
    case GET_SALARY_DEDUCTION_PLAN_FAIL:
      return Object.assign({}, state, {
        salaryDeductionPlan: { ...initialState.salaryDeductionPlan },
        deletedSalaryDeductionPlan: {
          ...initialState.deletedSalaryDeductionPlan,
        },
      });
    case MANAGE_DRAWER:
      return Object.assign({}, state, {
        drawer: {
          ...payload?.data,
          isEmployerEnforced:
            state.enforcements.data
              .flatMap((enforcement: any) => enforcement.enforcedItems)
              .some((item: any) => item.assetType == AssetTypes.EMPLOYER) &&
            !state.enforcementDetail.data.enforcedItems.some(
              (item: any) => item.assetType == AssetTypes.EMPLOYER
            ),
        },
      });
    case GET_ENFORCEMENT_ACCOUNT_SUMMARY:
      return Object.assign({}, state, {
        accountSummary: {
          ...state.accountSummary,
          isFetching: true,
        },
      });
    case GET_ENFORCEMENT_ACCOUNT_SUMMARY_SUCCESS:
      return Object.assign({}, state, {
        accountSummary: {
          data: payload?.data,
          isFetching: false,
        },
      });
    case GET_ENFORCEMENT_ACCOUNT_SUMMARY_FAIL:
      return Object.assign({}, state, {
        accountSummary: {
          ...initialState.accountSummary,
        },
      });
    default:
      return state;
  }
};
