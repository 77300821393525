import { versionedHttpCollection } from 'us.helper/http/collection';
import ServiceConfig from 'service.config.js';

export default {
	notes: {
		save: (params: any): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.post(
							'uscservice',
							'notes',
							params?.data,
							ServiceConfig()[
								`uscservice`
							]['domainMessage.note']
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
	},

	domainMessage: {
		getTemplate: <
			P extends {
				category: string;
				entityType: string;
			}
		>(
			params: P
		): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'uscservice',
							`domainmessages/${params.category}/${params.entityType}`,
							{},
							ServiceConfig()[
								`uscservice`
							][
								'domainMessage.template'
							]
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
		getFormattedTemplate: <P>(params: P): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'uscservice',
							`domainmessageTexts`,
							params,
							ServiceConfig()[
								`uscservice`
							][
								'domainMessage.templateText'
							]
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
		getBasicInfo: <
			P extends {
				entityId: string;
				entityType: string;
			}
		>(
			params: P
		): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'uscservice',
							`domainmessages/basicdata/${params.entityId}/${params.entityType}`,
							{},
							ServiceConfig()[
								`uscservice`
							][
								'domainMessage.basicInfo'
							]
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
		send: (params: any): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					let basicdata = {};

					const sendDomainMessageData =
						await versionedHttpCollection.post(
							'uscservice',
							'messages',
							params?.data,
							ServiceConfig()[
								`uscservice`
							]['domainMessage.send']
						);

					if (
						sendDomainMessageData.data
							.isSuccess
					) {
						basicdata =
							await versionedHttpCollection.get(
								'uscservice',
								`domainmessages/basicdata/${params?.data.entityId}/${params?.data.entityType}`,
								{},
								ServiceConfig()[
									`uscservice`
								][
									'domainMessage.basicInfo'
								]
							);
					}

					resolve({
						sendDomainMessageData,
						basicdata,
					});
				} catch (error) {
					reject(error);
				}
			});
		},
		getText: <
			P extends {
				entityType: string;
			}
		>(
			params: P
		): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'communicationservice',
							`texts/${params.entityType}`,
							{},
							'v1'
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
		getContent: <P>(params: P): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.post(
							'activityservice',
							`dashboardmessage/content`,
							params,
							'v1'
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
		sendMessage: <P>(params: P): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.post(
							'activityservice',
							`dashboardmessage/message`,
							params,
							'v1'
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
	},

	printedDocument: {
		get: <P>(params: P): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'activityservice',
							'activityhistory',
							params,
							ServiceConfig()[
								`activityservice`
							]['activityhistory']
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
	},

	uploadedDocument: {
		get: <P>(params: P): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'documentservice',
							'document/documentdetails',
							params,
							ServiceConfig()[
								`documentservice`
							]['uploaded.documents']
						);
					if (status === 200) {
						resolve({ data, status });
					} else {
						reject({ data });
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	emailAttachmentContent: {
		get: <P>(params: P): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'documentservice',
							'document',
							params,
							ServiceConfig()[
								`documentservice`
							]['document']
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
	},
};
