import { versionedHttpCollection } from 'us.helper/http/collection';
import ServiceConfig from 'service.config.js';

export default {
	interest: {
		get: <
			P extends {
				caseId: number;
				filter: string;
			}
		>(
			params: P
		): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data } =
						await versionedHttpCollection.get(
							'arservice',
							`ars/cases/${params.caseId}/${params.filter}/interestData`,
							{},
							ServiceConfig()[
								`arservice`
							]['ars.interestData']
						);
					resolve({ data });
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	summaryInterest: {
		update: <
			P extends {
				caseId: number;
				interestDate: string;
			}
		>(
			params: P
		): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data } =
						await versionedHttpCollection.put(
							'arservice',
							`ars/cases/interest`,
							params,
							ServiceConfig()[
								`arservice`
							]['ars.case.interest']
						);
					resolve({ data });
				} catch (error) {
					reject(error);
				}
			});
		},
	},
};
