import { IInitialState } from "us.collection.case/reducers/ExtendedField/Interfaces";

export const initialState: IInitialState = {
  extendedFields: {
    data: {
      extendedType: "",
      extendedData: [],
    },
    isFetching: false,
  },
  drawer: {
    title: "",
    visible: false,
    isNewExtendedField: true
  }
};
