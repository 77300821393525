import { ICourt } from "us.collection.transactions/interfaces";
import _ from "lodash";

type filter = {
  courtItemId: string;
  courtName: string;
  courtAddress: string;
  telephoneNo: string;
  courtTypeName: string;
  courtTypeNo: string;
};

export const filterApplicableCourts = (
  courts: Array<ICourt>,
  filters: filter
) => {
  const beforeGroup = courts
    .map((court: ICourt, index: number) => {
      return { ...court, id: index };
    })
    .filter((court: ICourt) => {
      let matched = true;
      if (filters.courtName != "" && filters.courtName != undefined) {
        if (
          court?.courtName
            ?.toLocaleLowerCase()
            .indexOf(filters?.courtName?.toLocaleLowerCase()) == -1
        ) {
          matched = false;
        }
      }
      if (filters.courtTypeName != "" && filters.courtTypeName != undefined) {
        if (
          court?.courtTypeName
            ?.toLocaleLowerCase()
            .indexOf(filters.courtTypeName?.toLocaleLowerCase()) == -1
        ) {
          matched = false;
        }
      }
      if (filters.telephoneNo != "" && filters.telephoneNo != undefined) {
        if (
          court?.telephoneNo
            ?.toLocaleLowerCase()
            .indexOf(filters.telephoneNo?.toLocaleLowerCase()) == -1
        ) {
          matched = false;
        }
      }
      if (filters.courtAddress != "" && filters.courtAddress != undefined) {
        if (
          court?.courtAddress
            ?.toLocaleLowerCase()
            .indexOf(filters?.courtAddress?.toLocaleLowerCase()) == -1
        ) {
          matched = false;
        }
      }
      return matched;
    });

  const finalTable: any = [];
  Object.entries(_.groupBy(beforeGroup, "courtTypeName")).forEach(
    ([courtType, courtArr], index) => {
      finalTable.push({
        id: beforeGroup.length + index,
        courtType: courtType,
        children: courtArr,
      });
    }
  );
  return finalTable;
};

/**
 * Create datasource for table tree compenent
 * @param data
 * @returns datasource objects array
 */
export const setupCourtData = (data: Array<any>) => {
  return Object.values(_.groupBy(data, "courtTypeNo")).map(
    (item: any, index: number) => {
      return {
        key: index,
        courtType: item[0]?.courtTypeName,
        courtName: "",
        courtTypeName: "",
        telephoneNo: "",
        courtAddress: "",
        children: item,
      };
    }
  );
};
