import { IGetMappingTransactions } from 'us.collection.transactions/interfaces';
import { CommonMapping } from './CommonMapping';

export function GetMappingTransactions(this: any): IGetMappingTransactions {
	return {
		...CommonMapping.call(this),
		transGroupId: this.transactionGroupId,
		transactionId: this.arItemNo,
	};
}
