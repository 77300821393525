export enum Dashboard {
	GET_DASHBOARD_METADATA = 'GET_DASHBOARD_METADATA',
	GET_DASHBOARD_METADATA_SUCCESS = 'GET_DASHBOARD_METADATA_SUCCESS',
	GET_DASHBOARD_METADATA_FAIL = 'GET_DASHBOARD_METADATA_FAIL',

	RESET_DASHBOARD_METADATA = 'RESET_DASHBOARD_METADATA',
}

export enum DebtorWidget {
	GET_DEBTOR_WIDGET_DATA = 'GET_DEBTOR_WIDGET_DATA',
	GET_DEBTOR_WIDGET_DATA_SUCCESS = 'GET_DEBTOR_WIDGET_DATA_SUCCESS',
	GET_DEBTOR_WIDGET_DATA_FAIL = 'GET_DEBTOR_WIDGET_DATA_FAIL',
}

export enum AccountSummeryWidget {
	GET_ACCOUNT_SUMMERY_WIDGET_DATA = 'GET_ACCOUNT_SUMMERY_WIDGET_DATA',
	GET_ACCOUNT_SUMMERY_WIDGET_DATA_SUCCESS = 'GET_ACCOUNT_SUMMERY_WIDGET_DATA_SUCCESS',
	GET_ACCOUNT_SUMMERY_WIDGET_DATA_FAIL = 'GET_ACCOUNT_SUMMERY_WIDGET_DATA_FAIL',
}

export enum CaseInfoWidget {
	GET_CASE_INFO_WIDGET_DATA = 'GET_CASE_INFO_WIDGET_DATA',
	GET_CASE_INFO_WIDGET_DATA_SUCCESS = 'GET_CASE_INFO_WIDGET_DATA_SUCCESS',
	GET_CASE_INFO_WIDGET_DATA_FAIL = 'GET_CASE_INFO_WIDGET_DATA_FAIL',
}

export enum otherParty {
	GET_OTHER_PARTY_DATA_STRAT = ' GET_OTHER_PARTY_DATA_STRAT',
	GET_OTHER_PARTY_DATA_SUCCESS = ' GET_OTHER_PARTY_DATA_SUCCESS',
	GET_OTHER_PARTY_DATA_FAIL = ' GET_OTHER_PARTY_DATA_FAIL',

	SAVE_OTHER_PARTY_DATA_STRAT = 'SAVE_OTHER_PARTY_DATA_STRAT',
	SAVE_OTHER_PARTY_DATA_SUCCESS = 'SAVE_OTHER_PARTY_DATA_SUCCESS',
	SAVE_OTHER_PARTY_DATA_FAIL = 'SAVE_OTHER_PARTY_DATA_FAIL',

	GET_OTHER_PARTY_BY_ID_STRAT = 'GET_OTHER_PARTY_BY_ID_STRAT',
	GET_OTHER_PARTY_BY_ID_SUCCESS = 'GET_OTHER_PARTY_BY_ID_SUCCESS',
	GET_OTHER_PARTY_BY_ID_FAIL = 'GET_OTHER_PARTY_BY_ID_FAIL',
	GET_OTHER_PARTY_BY_ID_RESET = 'GET_OTHER_PARTY_BY_ID_RESET',

	DELETE_OTHER_PARTY_DATA_STRAT = 'DELETE_OTHER_PARTY_DATA_STRAT',
	DELETE_OTHER_PARTY_DATA_SUCCESS = 'DELETE_OTHER_PARTY_DATA_SUCCESS',
	DELETE_OTHER_PARTY_DATA_FAIL = 'DELETE_OTHER_PARTY_DATA_FAIL',

	ADD_OTHER_PARTY_DRAWER = 'ADD_OTHER_PARTY_DRAWER',

	UPDATE_OTHER_PARTY_BY_ACTIVE_STATE_STRAT = 'UPDATE_OTHER_PARTY_BY_ACTIVE_STATE_STRAT',
	UPDATE_OTHER_PARTY_BY_ACTIVE_STATE_SUCCESS = 'UPDATE_OTHER_PARTY_BY_ACTIVE_STATE_SUCCESS',
	UPDATE_OTHER_PARTY_BY_ACTIVE_STATE_FAIL = 'UPDATE_OTHER_PARTY_BY_ACTIVE_STATE_FAIL',

	GET_OTHER_PARTY_ROLE_TYPE_STRAT = 'GET_OTHER_PARTY_ROLE_TYPE_STRAT',
	GET_OTHER_PARTY_ROLE_TYPE_SUCCESS = 'GET_OTHER_PARTY_ROLE_TYPE_SUCCESS',
	GET_OTHER_PARTY_ROLE_TYPE_FAIL = 'GET_OTHER_PARTY_ROLE_TYPE_FAIL',

	SAVE_OTHER_PARTY_ROLE_TYPE_STRAT = 'SAVE_OTHER_PARTY_ROLE_TYPE_STRAT',
	SAVE_OTHER_PARTY_ROLE_TYPE_SUCCESS = 'SAVE_OTHER_PARTY_ROLE_TYPE_SUCCESS',
	SAVE_OTHER_PARTY_ROLE_TYPE_FAIL = 'SAVE_OTHER_PARTY_ROLE_TYPE_FAIL',

	DELETE_OTHER_PARTY_ROLE_TYPE_STRAT = 'DELETE_OTHER_PARTY_ROLE_TYPE_STRAT',
	DELETE_OTHER_PARTY_ROLE_TYPE_SUCCESS = 'DELETE_OTHER_PARTY_ROLE_TYPE_SUCCESS',
	DELETE_OTHER_PARTY_ROLE_TYPE_FAIL = 'DELETE_OTHER_PARTY_ROLE_TYPE_FAIL',

	SEARCH_OTHER_PARTY_DATA_STRAT = 'SEARCH_OTHER_PARTY_DATA_STRAT',
	SEARCH_OTHER_PARTY_DATA_SUCCESS = 'SEARCH_OTHER_PARTY_DATA_SUCCESS',
	SEARCH_OTHER_PARTY_DATA_FAIL = 'SEARCH_OTHER_PARTY_DATA_FAIL',

	CLEAR_OTHER_PARTY_SEARCH_DATA = 'CLEAR_OTHER_PARTY_SEARCH_DATA',

	SEARCH_POSTALCODE_START = 'SEARCH_POSTALCODE_START',
	SEARCH_POSTALCODE_SUCCESS = 'SEARCH_POSTALCODE_SUCCESS',
	SEARCH_POSTALCODE_FAIL = 'SEARCH_POSTALCODE_FAIL',

	SEARCH_MUNICIPALITY_START = 'SEARCH_MUNICIPALITY_START',
	SEARCH_MUNICIPALITY_SUCCESS = 'SEARCH_MUNICIPALITY_SUCCESS',
	SEARCH_MUNICIPALITY_FAIL = 'SEARCH_MUNICIPALITY_FAIL',

	CLEAR_POSTAL_DETAILS = 'CLEAR_POSTAL_DETAILS',

	GET_POSTAL_DETAIL = 'GET_POSTAL_DETAIL',
	GET_POSTAL_DETAIL_SUCCESS = 'GET_POSTAL_DETAIL_SUCCESS',
	GET_POSTAL_DETAIL_FAIL = 'GET_POSTAL_DETAIL_FAIL',

	RESET_POSTAL_DETAIL = 'RESET_POSTAL_DETAIL',
}

export enum MessageAndNotes {
	SAVE_NOTE_DETAIL_START = 'SAVE_NOTE_DETAIL_START',
	SAVE_NOTE_DETAIL_SUCCESS = 'SAVE_NOTE_DETAIL_SUCCESS',
	SAVE_NOTE_DETAIL_FAIL = 'SAVE_NOTE_DETAIL_FAIL',

	GET_TEMPLATE_START = 'GET_TEMPLATE_START',
	GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS',
	GET_TEMPLATE_FAIL = 'GET_TEMPLATE_FAIL',

	GET_FORMATTED_TEMPLATE_START = 'GET_FORMATTED_TEMPLATE_START',
	GET_FORMATTED_TEMPLATE_SUCCESS = 'GET_FORMATTED_TEMPLATE_SUCCESS',
	GET_FORMATTED_TEMPLATE_FAIL = 'GET_FORMATTED_TEMPLATE_FAIL',

	FORMATTED_TEMPLATE_DRAWER = 'FORMATTED_TEMPLATE_DRAWER',

	GET_DOMAIN_MESSAGE_BASIC_INFO_START = 'GET_DOMAIN_MESSAGE_BASIC_INFO_START',
	GET_DOMAIN_MESSAGE_BASIC_INFO_SUCCESS = 'GET_DOMAIN_MESSAGE_BASIC_INFO_SUCCESS',
	GET_DOMAIN_MESSAGE_BASIC_INFO_FAIL = 'GET_DOMAIN_MESSAGE_BASIC_INFO_FAIL',

	DOMAIN_MESSAGE_INITIAL_VALUE_RESET = 'DOMAIN_MESSAGE_INITIAL_VALUE_RESET',

	SEND_DOMAIN_MESSAGE_START = 'SEND_DOMAIN_MESSAGE_START',
	SEND_DOMAIN_MESSAGE_SUCCESS = 'SEND_DOMAIN_MESSAGE_SUCCESS',
	SEND_DOMAIN_MESSAGE_FAIL = 'SEND_DOMAIN_MESSAGE_FAIL',

	ADD_ATTACHMENT_EMAIL_START = 'ADD_ATTACHMENT_EMAIL_START',
	ADD_ATTACHMENT_EMAIL_SUCCESS = 'ADD_ATTACHMENT_EMAIL_SUCCESS',
	ADD_ATTACHMENT_EMAIL_FAIL = 'ADD_ATTACHMENT_EMAIL_FAIL',

	SHOW_EMAIL_ATTACHMENT = 'SHOW_EMAIL_ATTACHMENT',

	GET_PRINTED_DOCUMENT_START = 'GET_PRINTED_DOCUMENT_START',
	GET_PRINTED_DOCUMENT_SUCCESS = 'GET_PRINTED_DOCUMENT_SUCCESS',
	GET_PRINTED_DOCUMENT_FAIL = 'GET_PRINTED_DOCUMENT_FAIL',

	GET_UPLOAD_DOCUMENT_START = 'GET_UPLOAD_DOCUMENT_START',
	GET_UPLOAD_DOCUMENT_SUCCESS = 'GET_UPLOAD_DOCUMENT_SUCCESS',
	GET_UPLOAD_DOCUMENT_FAIL = 'GET_UPLOAD_DOCUMENT_FAIL',

	GET_EMAIL_ATTACHMENT_CONTENT_START = 'GET_EMAIL_ATTACHMENT_CONTENT_START',
	GET_EMAIL_ATTACHMENT_CONTENT_SUCCESS = 'GET_EMAIL_ATTACHMENT_CONTENT_SUCCESS',
	GET_EMAIL_ATTACHMENT_CONTENT_FAIL = 'GET_EMAIL_ATTACHMENT_CONTENT_FAIL',

	GET_MESSAGE_TEXT = 'GET_MESSAGE_TEXT',
	GET_MESSAGE_TEXT_SUCCESS = 'GET_MESSAGE_TEXT_SUCCESS',
	GET_MESSAGE_TEXT_FAIL = 'GET_MESSAGE_TEXT_FAIL',

	GET_MESSAGE_CONTENT = 'GET_MESSAGE_CONTENT',
	GET_MESSAGE_CONTENT_SUCCESS = 'GET_MESSAGE_CONTENT_SUCCESS',
	GET_MESSAGE_CONTENT_FAIL = 'GET_MESSAGE_CONTENT_FAIL',

	SEND_DASHBOARD_MESSAGE_START = 'SEND_DASHBOARD_MESSAGE_START',
	SEND_DASHBOARD_MESSAGE_SUCCESS = 'SEND_DASHBOARD_MESSAGE_SUCCESS',
	SEND_DASHBOARD_MESSAGE_FAIL = 'SEND_DASHBOARD_MESSAGE_FAIL',

	GET_EDITABLE_IFRAME = 'GET_EDITABLE_IFRAME',

	SEND_CREDITOR_PORTAL_MESSAGE_START = 'SEND_CREDITOR_PORTAL_MESSAGE_START',
	SEND_CREDITOR_PORTAL_MESSAGE_SUCCESS = 'SEND_CREDITOR_PORTAL_MESSAGE_SUCCESS',
	SEND_CREDITOR_PORTAL_MESSAGE_FAIL = 'SEND_CREDITOR_PORTAL_MESSAGE_FAIL',
}
export enum Interest {
	GET_INTEREST_START = 'GET_INTEREST_START',
	GET_INTEREST_SUCCESS = 'GET_INTEREST_SUCCESS',
	GET_INTEREST_FAIL = 'GET_INTEREST_FAIL',

	GET_SUMMARY_INTEREST_START = 'GET__SUMMARY_INTEREST_START',
	GET_SUMMARY_INTEREST_SUCCESS = 'GET_SUMMARY_INTEREST_SUCCESS',
	GET_SUMMARY_INTEREST_FAIL = 'GET_SUMMARY_INTEREST_FAIL',

	SET_INTEREST_FILTER = 'SET_INTEREST_FILTER',
}

export enum ObjectionAndAgreements {
	CLAIM_OF_PAYMENT_START = 'CLAIM_OF_PAYMENT_START',
	CLAIM_OF_PAYMENT_SUCCESS = 'CLAIM_OF_PAYMENT_SUCCESS',
	CLAIM_OF_PAYMENT_FAIL = 'CLAIM_OF_PAYMENT_FAIL',

	GET_COP_INITIAL_VALUE = 'GET_COP_INITIAL_VALUE',
	OPEN_COP_DRAWER = 'OPEN_COP_DRAWER',

	GET_INVOICES_START = 'GET_INVOICES_START',
	GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS',
	GET_INVOICES_FAIL = 'GET_INVOICES_FAIL',

	DEBTOR_OBJECTION_START = 'DEBTOR_OBJECTION_START',
	DEBTOR_OBJECTION_SUCCESS = 'DEBTOR_OBJECTION_SUCCESS',
	DEBTOR_OBJECTION_FAIL = 'DEBTOR_OBJECTION_FAIL',

	GET_DO_INITIAL_VALUE = 'GET_DO_INITIAL_VALUE',
	OPEN_DO_DRAWER = 'OPEN_DO_DRAWER',

	GET_OBJECTION_PROFILE_START = 'GET_OBJECTION_PROFILE_START',
	GET_OBJECTION_PROFILE_SUCCESS = 'GET_OBJECTION_PROFILE_SUCCESS',
	GET_OBJECTION_PROFILE_FAIL = 'GET_OBJECTION_PROFILE_FAIL',

	GET_KID_LIST = 'GET_KID_LIST',
	GET_KID_LIST_SUCCESS = 'GET_KID_LIST_SUCCESS',
	GET_KID_LIST_FAIL = 'GET_KID_LIST_FAIL',

	CANCEL_OBJECTION_START = 'CANCEL_OBJECTION_START',
	CANCEL_OBJECTION_SUCCESS = 'CANCEL_OBJECTION_SUCCESS',
	CANCEL_OBJECTION_FAIL = 'CANCEL_OBJECTION_FAIL',

	UPDATE_DRAWER = 'UPDATE_DRAWER',
}

export enum ExtendedField {
	GET_EXTENDED_FIELD = 'GET_EXTENDED_FIELD',
	GET_EXTENDED_FIELD_SUCCESS = 'GET_EXTENDED_FIELD_SUCCESS',
	GET_EXTENDED_FIELD_FAIL = 'GET_EXTENDED_FIELD_FAIL',

	MANAGE_EXTENDED_FIELD_DRAWER = 'MANAGE_EXTENDED_FIELD_DRAWER',
	MANAGE_EXTENDED_FIELD_DRAWER_SUCCESS = 'MANAGE_EXTENDED_FIELD_DRAWER_SUCCESS',
	MANAGE_EXTENDED_FIELD_DRAWER_FAIL = 'MANAGE_EXTENDED_FIELD_DRAWER_FAIL',
}

export enum CaseInformation {
	GET_CASEINFO = 'GET_CASEINFO',
	GET_CASEINFO_SUCCESS = 'GET_CASEINFO_SUCCESS',
	GET_CASEINFO_FAIL = 'GET_CASEINFO_FAIL',

	GET_INTEREST_OCCURRENCES_START = 'GET_INTEREST_OCCURRENCES_START',
	GET_INTEREST_OCCURRENCES_SUCCESS = 'GET_INTEREST_OCCURRENCES_SUCCESS',
	GET_INTEREST_OCCURRENCES_FAIL = 'GET_INTEREST_OCCURRENCES_FAIL',

	ADD_INTEREST_OCCURRENCES_START = 'ADD_INTEREST_OCCURRENCES_START',
	ADD_INTEREST_OCCURRENCES_SUCCESS = 'ADD_INTEREST_OCCURRENCES_SUCCESS',
	ADD_INTEREST_OCCURRENCES_FAIL = 'ADD_INTEREST_OCCURRENCES_FAIL',

	SAVE_CASEINFO = 'SAVE_CASEINFO',
	SAVE_CASEINFO_SUCCESS = 'SAVE_CASEINFO_SUCCESS',
	SAVE_CASEINFO_FAIL = 'SAVE_CASEINFO_FAIL',

	DELETE_INTEREST_OCCURRENCES_START = 'DELETE_INTEREST_OCCURRENCES_START',
	DELETE_INTEREST_OCCURRENCES_SUCCESS = 'DELETE_INTEREST_OCCURRENCES_SUCCESS',
	DELETE_INTEREST_OCCURRENCES_FAIL = 'DELETE_INTEREST_OCCURRENCES_FAIL',
}

export enum Assets {
	GET_ASSET = 'GET_ASSET',
	GET_ASSET_SUCCESS = 'GET_ASSET_SUCCESS',
	GET_ASSET_FAIL = 'GET_ASSET_FAIL',
	GET_ASSET_RESET = 'GET_ASSET_RESET',

	SAVE_REAL_ESTATE_ASSETS = 'SAVE_REAL_ESTATE_ASSETS',
	SAVE_REAL_ESTATE_ASSETS_SUCCESS = 'SAVE_REAL_ESTATE_ASSETS_SUCCESS',
	SAVE_REAL_ESTATE_ASSETS_FAIL = 'SAVE_REAL_ESTATE_ASSETS_FAIL',

	CHANGE_DRAWER_STATUS = 'CHANGE_DRAWER_STATUS',
	RESET_DRAWER_STATUS = 'RESET_DRAWER_STATUS',

	DELETE_ASSET = 'DELETE_ASSET',
	DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS',
	DELETE_ASSET_FAIL = 'DELETE_ASSET_FAIL',

	GET_REAL_ESTATE_ASSET = 'GET_REAL_ESTATE_ASSET',
	GET_REAL_ESTATE_ASSET_SUCCESS = 'GET_REAL_ESTATE_ASSET_SUCCESS',
	GET_REAL_ESTATE_ASSET_FAIL = 'GET_REAL_ESTATE_ASSET_FAIL',

	RESET_REAL_ESTATE_ASSETS = 'RESET_REAL_ESTATE_ASSETS',

	UPDATE_REAL_ESTATE_ASSET = 'UPDATE_REAL_ESTATE_ASSET',
	UPDATE_REAL_ESTATE_ASSET_SUCCESS = 'UPDATE_REAL_ESTATE_ASSET_SUCCESS',
	UPDATE_REAL_ESTATE_ASSET_FAIL = 'UPDATE_REAL_ESTATE_ASSET_FAIL',

	SAVE_PERSONAL_EFFECTS_ASSETS = 'SAVE_PERSONAL_EFFECTS_ASSETS',
	SAVE_PERSONAL_EFFECTS_ASSETS_SUCCESS = 'SAVE_PERSONAL_EFFECTS_ASSETS_SUCCESS',
	SAVE_PERSONAL_EFFECTS_ASSETS_FAIL = 'SAVE_PERSONAL_EFFECTS_ASSETS_FAIL',

	GET_PERSONAL_EFFECTS_ASSET = 'GET_PERSONAL_EFFECTS_ASSET',
	GET_PERSONAL_EFFECTS_ASSET_SUCCESS = 'GET_PERSONAL_EFFECTS_ASSET_SUCCESS',
	GET_PERSONAL_EFFECTS_ASSET_FAIL = 'GET_PERSONAL_EFFECTS_ASSET_FAIL',

	UPDATE_PERSONAL_EFFECTS_ASSET = 'UPDATE_PERSONAL_EFFECTS_ASSET',
	UPDATE_PERSONAL_EFFECTS_ASSET_SUCCESS = 'UPDATE_PERSONAL_EFFECTS_ASSET_SUCCESS',
	UPDATE_PERSONAL_EFFECTS_ASSET_FAIL = 'UPDATE_PERSONAL_EFFECTS_ASSET_FAIL',

	SAVE_DOCUMENT_OF_TITLE_ASSETS = 'SAVE_DOCUMENT_OF_TITLE_ASSETS',
	SAVE_DOCUMENT_OF_TITLE_ASSETS_SUCCESS = 'SAVE_DOCUMENT_OF_TITLE_ASSETS_SUCCESS',
	SAVE_DOCUMENT_OF_TITLE_ASSETS_FAIL = 'SAVE_DOCUMENT_OF_TITLE_ASSETS_FAIL',

	GET_DOCUMENT_OF_TITLE_ASSET = 'GET_DOCUMENT_OF_TITLE_ASSET',
	GET_DOCUMENT_OF_TITLE_ASSET_SUCCESS = 'GET_DOCUMENT_OF_TITLE_ASSET_SUCCESS',
	GET_DOCUMENT_OF_TITLE_ASSET_FAIL = 'GET_DOCUMENT_OF_TITLE_ASSET_FAIL',

	UPDATE_DOCUMENT_OF_TITLE_ASSET = 'UPDATE_DOCUMENT_OF_TITLE_ASSET',
	UPDATE_DOCUMENT_OF_TITLE_ASSET_SUCCESS = 'UPDATE_DOCUMENT_OF_TITLE_ASSET_SUCCESS',
	UPDATE_DOCUMENT_OF_TITLE_ASSET_FAIL = 'UPDATE_DOCUMENT_OF_TITLE_ASSET_FAIL',

	SAVE_VEHICLE_ASSETS = 'SAVE_VEHICLE_ASSETS',
	SAVE_VEHICLE_ASSETS_SUCCESS = 'SAVE_VEHICLE_ASSETS_SUCCESS',
	SAVE_VEHICLE_ASSETS_FAIL = 'SAVE_VEHICLE_ASSETS_FAIL',

	GET_VEHICLE_ASSET = 'GET_VEHICLE_ASSET',
	GET_VEHICLE_ASSET_SUCCESS = 'GET_VEHICLE_ASSET_SUCCESS',
	GET_VEHICLE_ASSET_FAIL = 'GET_VEHICLE_ASSET_FAIL',

	UPDATE_VEHICLE_ASSET = 'UPDATE_VEHICLE_ASSET',
	UPDATE_VEHICLE_ASSET_SUCCESS = 'UPDATE_VEHICLE_ASSET_SUCCESS',
	UPDATE_VEHICLE_ASSET_FAIL = 'UPDATE_VEHICLE_ASSET_FAIL',

	SAVE_FINANCIAL_ASSETS = 'SAVE_FINANCIAL_ASSETS',
	SAVE_FINANCIAL_ASSETS_SUCCESS = 'SAVE_FINANCIAL_ASSETS_SUCCESS',
	SAVE_FINANCIAL_ASSETS_FAIL = 'SAVE_FINANCIAL_ASSETS_FAIL',

	GET_FINANCIAL_ASSET = 'GET_FINANCIAL_ASSET',
	GET_FINANCIAL_ASSET_SUCCESS = 'GET_FINANCIAL_ASSET_SUCCESS',
	GET_FINANCIAL_ASSET_FAIL = 'GET_FINANCIAL_ASSET_FAIL',

	UPDATE_FINANCIAL_ASSET = 'UPDATE_FINANCIAL_ASSET',
	UPDATE_FINANCIAL_ASSET_SUCCESS = 'UPDATE_FINANCIAL_ASSET_SUCCESS',
	UPDATE_FINANCIAL_ASSET_FAIL = 'UPDATE_FINANCIAL_ASSET_FAIL',

	UPDATE_OTHER_ASSET = 'UPDATE_OTHER_ASSET',
	UPDATE_OTHER_ASSET_SUCCESS = 'UPDATE_OTHER_ASSET_SUCCESS',
	UPDATE_OTHER_ASSET_FAIL = 'UPDATE_OTHER_ASSET_FAIL',

	GET_POSTAL_DETAILS = 'GET_POSTAL_DETAILS',
	GET_POSTAL_DETAILS_SUCCESS = 'GET_POSTAL_DETAILS_SUCCESS',
	GET_POSTAL_DETAILS_FAIL = 'GET_POSTAL_DETAILS_FAIL',

	RESET_POSTAL_DETAILS = 'RESET_POSTAL_DETAILS',
}

export enum Enforcement {
	GET_ENFORCEMENTS_START = 'GET_ENFORCEMENTS_START',
	GET_ENFORCEMENTS_SUCCESS = 'GET_ENFORCEMENTS_SUCCESS',
	GET_ENFORCEMENTS_FAIL = 'GET_ENFORCEMENTS_FAIL',

	SAVE_ENFORCEMENT = 'SAVE_ENFORCEMENT',
	SAVE_ENFORCEMENT_SUCCESS = 'SAVE_ENFORCEMENT_SUCCESS',
	SAVE_ENFORCEMENT_FAIL = 'SAVE_ENFORCEMENT_FAIL',

	DELETE_ENFORCEMENTS_START = 'DELETE_ENFORCEMENTS_START',
	DELETE_ENFORCEMENTS_SUCCESS = 'DELETE_ENFORCEMENTS_SUCCESS',
	DELETE_ENFORCEMENTS_FAIL = 'DELETE_ENFORCEMENTS_FAIL',

	GET_ENFORCEMENT_TYPES = 'GET_ENFORCEMENT_TYPES',
	GET_ENFORCEMENT_TYPES_SUCCESS = 'GET_ENFORCEMENT_TYPES_SUCCESS',
	GET_ENFORCEMENT_TYPES_FAIL = 'GET_ENFORCEMENT_TYPES_FAIL',

	SET_ENFORCEMENT_ITEM = 'SET_ENFORCEMENT_ITEM',
	SET_ENFORCEMENT_ITEM_SUCCESS = 'SET_ENFORCEMENT_ITEM_SUCCESS',
	SET_ENFORCEMENT_ITEM_FAIL = 'SET_ENFORCEMENT_ITEM_FAIL',
	RESET_ENFORCEMENT_ITEM = 'RESET_ENFORCEMENT_ITEM',

	GET_SALARY_DEDUCTION_PLAN = 'GET_SALARY_DEDUCTION_PLAN',
	GET_SALARY_DEDUCTION_PLAN_SUCCESS = 'GET_SALARY_DEDUCTION_PLAN_SUCCESS',
	GET_SALARY_DEDUCTION_PLAN_FAIL = 'GET_SALARY_DEDUCTION_PLAN_FAIL',

	SAVE_SALARY_DEDUCTION_PLAN = 'SAVE_SALARY_DEDUCTION_PLAN',
	SAVE_SALARY_DEDUCTION_PLAN_SUCCESS = 'SAVE_SALARY_DEDUCTION_PLAN_SUCCESS',
	SAVE_SALARY_DEDUCTION_PLAN_FAIL = 'SAVE_SALARY_DEDUCTION_PLAN_FAIL',

	SET_SALARY_DEDUCTION_PLAN = 'SET_SALARY_DEDUCTION_PLAN',

	RESET_SALARY_DEDUCTION_PLAN = 'RESET_SALARY_DEDUCTION_PLAN',
	RESET_SALARY_DEDUCTION_SCHEMA = 'RESET_SALARY_DEDUCTION_SCHEMA',

	DELETE_SALARY_DEDUCTION_PLAN = 'DELETE_SALARY_DEDUCTION_PLAN',

	RESET_DELETED_SALARY_DEDUCTION_PLAN = 'RESET_DELETED_SALARY_DEDUCTION_PLAN',

	SET_ENFORCEMENTS_FILTER = 'SET_ENFORCEMENTS_FILTER',

	SELECT_ENFORCEMENT = 'SELECT_ENFORCEMENT',

	RESET_ENFORCEMENT_DETAILS = 'RESET_ENFORCEMENT_DETAILS',

	MANAGE_DRAWER = 'MANAGE_DRAWER',

	GET_ENFORCEMENT_ACCOUNT_SUMMARY = 'GET_ENFORCEMENT_ACCOUNT_SUMMARY',
	GET_ENFORCEMENT_ACCOUNT_SUMMARY_SUCCESS = 'GET_ENFORCEMENT_ACCOUNT_SUMMARY_SUCCESS',
	GET_ENFORCEMENT_ACCOUNT_SUMMARY_FAIL = 'GET_ENFORCEMENT_ACCOUNT_SUMMARY_FAIL',

	UPDATE_CASE_BALANCE = 'UPDATE_CASE_BALANCE',
}

export enum CourtSentence {
	GET_SENTENCE_SUMMERY_START = 'GET_SENTENCE_SUMMERY_START',
	GET_SENTENCE_SUMMERY_SUCCESS = 'GET_SENTENCE_SUMMERY_SUCCESS',
	GET_SENTENCE_SUMMERY_FAIL = 'GET_SENTENCE_SUMMERY_FAIL',

	GET_SENTENCE_CASE_AMOUNTS_START = 'GET_SENTENCE_CASE_AMOUNTS_START',
	GET_SENTENCE_CASE_AMOUNTS_SUCCESS = 'GET_SENTENCE_CASE_AMOUNTS_SUCCESS',
	GET_SENTENCE_CASE_AMOUNTS_FAIL = 'GET_SENTENCE_CASE_AMOUNTS_FAIL',

	GET_SENTENCE_START = 'GET_SENTENCE_START',
	GET_SENTENCE_SUCCESS = 'GET_SENTENCE_SUCCESS',
	GET_SENTENCE_FAIL = 'GET_SENTENCE_FAIL',

	SAVE_SENTENCE_START = 'SAVE_SENTENCE_START',
	SAVE_SENTENCE_SUCCESS = 'SAVE_SENTENCE_SUCCESS',
	SAVE_SENTENCE_FAIL = 'SAVE_SENTENCE_FAIL',

	UPDATE_SENTENCE_START = 'UPDATE_SENTENCE_START',
	UPDATE_SENTENCE_SUCCESS = 'UPDATE_SENTENCE_SUCCESS',
	UPDATE_SENTENCE_FAIL = 'UPDATE_SENTENCE_FAIL',

	DELETE_SENTENCE_START = 'DELETE_SENTENCE_START',
	DELETE_SENTENCE_SUCCESS = 'DELETE_SENTENCE_SUCCESS',
	DELETE_SENTENCE_FAIL = 'DELETE_SENTENCE_FAIL',

	CHANGE_DRAWER_STATUS = 'CHANGE_DRAWER_STATUS',
}

export enum ObjectionAndAgreementDrawerType {
	NONE = 'NONE',
	CLAIM_OF_PAYMENT = 'CLAIM_OF_PAYMENT',
	DEBTOR_OBJECTION = 'DEBTOR_OBJECTION',
	WAITING_FOR_CREDITOR_RESPONSE = 'WAITING_FOR_CREDITOR_RESPONSE',
	BUREAU_LEVEL_OBJECTION_CLOSE = 'BUREAU_LEVEL_OBJECTION_CLOSE',
}
