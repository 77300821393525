import { ITransactionsAction } from 'us.collection.transactions/interfaces';
import { Transaction } from 'us.collection.transactions/constants/Actions';

/**
 * Transactions actions
 */

export const transactions: ITransactionsAction = {
	save: (data, filters) => ({
		type: Transaction.SAVE_TRANSACTIONS,
		payload: {
			data,
			filters,
			isLoading: true,
		},
	}),
	success: (data) => ({
		type: Transaction.SAVE_TRANSACTIONS_SUCCESS,
		payload: {
			data,
			isLoading: false,
		},
	}),
	fail: (error) => ({
		type: Transaction.SAVE_TRANSACTIONS_FAIL,
		payload: {
			error,
			isLoading: false,
		},
	}),
	getAll: (entityType, entityId) => ({
		type: Transaction.GET_TRANSACTIONS_BY_ENTITY_NUMBER_AND_ENTITY_TYPE_STRAT,
		payload: {
			entityType,
			entityId,
			isLoading: true,
		},
	}),
	getAllSuccess: (data) => ({
		type: Transaction.GET_TRANSACTIONS_BY_ENTITY_NUMBER_AND_ENTITY_TYPE_SUCCESS,
		payload: {
			data,
			isLoading: false,
		},
	}),
	getAllFail: (error) => ({
		type: Transaction.GET_TRANSACTIONS_BY_ENTITY_NUMBER_AND_ENTITY_TYPE_FAIL,
		payload: {
			error,
			isLoading: false,
		},
	}),
	delete: (data, filters) => ({
		type: Transaction.DELETE_TRANSACTION,
		payload: {
			data,
			filters,
			isLoading: true,
		},
	}),
	deleteSuccess: (data) => ({
		type: Transaction.DELETE_TRANSACTION_SUCCESS,
		payload: {
			data,
			isLoading: false,
		},
	}),
	deleteFail: (error) => ({
		type: Transaction.DELETE_TRANSACTION_FAIL,
		payload: {
			error,
			isLoading: false,
		},
	}),
	getMapping: (data) => ({
		type: Transaction.GET_MAPPING_TRANSACTIONS_BY_TRANSACTIONID_STRAT,
		payload: {
			data,
			isLoading: true,
		},
	}),
	getMappingSuccess: (data) => ({
		type: Transaction.GET_MAPPING_TRANSACTIONS_BY_TRANSACTIONID_SUCCESS,
		payload: {
			data,
			isLoading: false,
		},
	}),
	getMappingFail: (error) => ({
		type: Transaction.GET_MAPPING_TRANSACTIONS_BY_TRANSACTIONID_FAIL,
		payload: {
			error,
			isLoading: false,
		},
	}),
	select: (data) => ({
		type: Transaction.SELECT_TRANSACTION,
		payload: {
			data,
		},
	}),
	resetInfo: (data) => ({
		type: Transaction.RESET_TRANSACTION_INFO,
		payload: {
			data,
		},
	}),
	addCreditNote: (data, filters) => ({
		type: Transaction.ADD_CREDIT_NOTE,
		payload: {
			data,
			filters,
			isLoading: true,
		},
	}),
	addCreditNoteSuccess: (data) => ({
		type: Transaction.ADD_CREDIT_NOTE_SUCCESS,
		payload: {
			data,
			isLoading: false,
		},
	}),
	addCreditNoteFail: (error) => ({
		type: Transaction.ADD_CREDIT_NOTE_FAIL,
		payload: {
			error,
			isLoading: false,
		},
	}),
	manageDrawer: (data) => ({
		type: Transaction.MANAGE_TRANSACION_DRAWER,
		payload: {
			data,
		},
	}),
	manageMappingDrawer: (data) => ({
		type: Transaction.MANAGE_MAPPING_TRANSACION_DRAWER,
		payload: {
			data,
		},
	}),
	manageInterestDrawer: (data) => ({
		type: Transaction.MANAGE_EDIT_INTEREST_DRAWER,
		payload: {
			data,
		},
	}),
	setCurrentUser: (data) => ({
		type: Transaction.SET_CURRENT_USER,
		payload: {
			data,
		},
	}),
	getArDetails: (data, withUpdate) => ({
		type: Transaction.GET_AR_DETAILS,
		payload: {
			data,
			withUpdate,
			isLoading: true,
		},
	}),
	getArDetailsSuccess: (data) => ({
		type: Transaction.GET_AR_DETAILS_SUCCESS,
		payload: {
			data,
			isLoading: false,
		},
	}),
	getArDetailsFail: (error) => ({
		type: Transaction.GET_AR_DETAILS_FAIL,
		payload: {
			error,
			isLoading: false,
		},
	}),
	getPopOverItems: (data) => ({
		type: Transaction.GET_POP_OVER_ITEMS,
		payload: {
			data,
			isLoading: true,
		},
	}),
	getPopOverItemsSuccess: (data) => ({
		type: Transaction.GET_POP_OVER_ITEMS_SUCCESS,
		payload: {
			data,
			isLoading: false,
		},
	}),
	getPopOverItemsFail: (error) => ({
		type: Transaction.GET_POP_OVER_ITEMS_FAIL,
		payload: {
			error,
			isLoading: false,
		},
	}),
	getPopOverValues: (data) => ({
		type: Transaction.GET_POP_OVER_FORM_VALUES,
		payload: {
			data,
			isLoading: true,
		},
	}),
	getPopOverValuesSuccess: (data) => ({
		type: Transaction.GET_POP_OVER_FORM_VALUES_SUCCESS,
		payload: {
			data,
			isLoading: false,
		},
	}),
	getPopOverValuesFail: (error) => ({
		type: Transaction.GET_POP_OVER_FORM_VALUES_FAIL,
		payload: {
			error,
			isLoading: false,
		},
	}),

	managePopOver: (data) => ({
		type: Transaction.MANAGE_POP_OVER,
		payload: {
			data,
			isLoading: true,
		},
	}),

	manageDynamicPopOver: (data) => ({
		type: Transaction.MANAGE_DYNAMIC_POP_OVER,
		payload: {
			data,
			isLoading: true,
		},
	}),
	saveDynamicPopOver: (data) => ({
		type: Transaction.SAVE_DYNAMIC_POP_OVER,
		payload: {
			data,
			isLoading: true,
		},
	}),
	saveDynamicPopOverSuccess: (data) => ({
		type: Transaction.SAVE_DYNAMIC_POP_OVER_SUCCESS,
		payload: {
			data,
			isLoading: false,
		},
	}),
	saveDynamicPopOverFail: (error) => ({
		type: Transaction.SAVE_DYNAMIC_POP_OVER_FAIL,
		payload: {
			error,
			isLoading: false,
		},
	}),
	mapItem: (data) => ({
		type: Transaction.MAP_ITEM_BY_ITEM,
		payload: {
			data,
			isLoading: true,
		},
	}),
	mapItemSuccess: (data) => ({
		type: Transaction.MAP_ITEM_BY_ITEM,
		payload: {
			data,
			isLoading: false,
		},
	}),
	mapItemFail: (error) => ({
		type: Transaction.MAP_ITEM_BY_ITEM,
		payload: {
			error,
			isLoading: false,
		},
	}),
	getMappingTransactions: (data) => ({
		type: Transaction.GET_MAPPING_TRANSACTIONS,
		payload: {
			data,
			isLoading: true,
		},
	}),
	getMappingTransactionsSuccess: (data) => ({
		type: Transaction.GET_MAPPING_TRANSACTIONS_SUCCESS,
		payload: {
			data,
			isLoading: false,
		},
	}),
	getMappingTransactionsFail: (error) => ({
		type: Transaction.GET_MAPPING_TRANSACTIONS_FAIL,
		payload: {
			error,
			isLoading: false,
		},
	}),
	updateMappingTransactions: (data) => ({
		type: Transaction.UPDATE_MAPPING_TRANSACTIONS,
		payload: {
			data,
			isLoading: true,
		},
	}),
	updateMappingTransactionsSuccess: (data) => ({
		type: Transaction.UPDATE_MAPPING_TRANSACTIONS_SUCCESS,
		payload: {
			data,
			isLoading: false,
		},
	}),
	updateMappingTransactionsFail: (error) => ({
		type: Transaction.UPDATE_MAPPING_TRANSACTIONS_FAIL,
		payload: {
			error,
			isLoading: false,
		},
	}),
	updateDrawerSettings: (data) => ({
		type: Transaction.UPDATE_DRAWER_SETTINGS,
		payload: {
			data,
		},
	}),
	getMappingCases: (data) => ({
		type: Transaction.GET_MAPPING_CASES,
		payload: {
			data,
			isLoading: true,
		},
	}),
	getMappingCasesSuccess: (data) => ({
		type: Transaction.GET_MAPPING_CASES_SUCCESS,
		payload: {
			data,
			isLoading: false,
		},
	}),
	getMappingCasesFail: (error) => ({
		type: Transaction.GET_MAPPING_CASES_FAIL,
		payload: {
			error,
			isLoading: false,
		},
	}),
	getInterestBMD: (data) => ({
		type: Transaction.GET_INTEREST_BMD_VALUE,
		payload: {
			data,
			isLoading: true,
		},
	}),
	getInterestBMDSuccess: (data) => ({
		type: Transaction.GET_INTEREST_BMD_VALUE_SUCCESS,
		payload: {
			data,
			isLoading: false,
		},
	}),
	getInterestBMDFail: (error) => ({
		type: Transaction.GET_INTEREST_BMD_VALUE_FAIL,
		payload: {
			error,
			isLoading: false,
		},
	}),
	resetInterestPrediction: () => ({
		type: Transaction.RESET_INTEREST_PREDICTION,
		payload: {},
	}),
};
