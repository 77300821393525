import React, { useEffect, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import Common from 'us.common';
import { workflowAction } from 'us.collection/actions';
import { StateValidation } from '../Validation';
import {
	WorkflowState,
	StateDelete,
	WorkflowGroup,
} from 'us.collection/repository';
import { INewState } from 'us.collection/components/Workflow/Interfaces';
import {
	isDuplicateStateName,
	getCategoryList,
	getWorkflowStateInitial,
	getWorkflowType,
} from 'us.collection/components/Workflow/Functions';
import { RootState } from 'us.helper/types';
import { EntityType, EntityTypeShortForm } from 'us.common/constants';
import { WorkflowDrawerActionType } from 'us.collection/constants';
import { WorkflowVersions } from './Components';
import { matchPath, useLocation } from 'react-router-dom';
import { getEntityNumberAndType } from 'us.collection/components/BMD/Functions';
import { WORKFLOW_TOOL_DOWNLOAD_URL } from 'us.collection/components/Workflow/Constants';

import 'us.collection/components/Workflow/Home.scss';
import _ from 'lodash';

const {
	$Popconfirm,
	$Button,
	$Input,
	$TextArea,
	$Form,
	$FormItem,
	$Radio,
	$Select,
	$Switch,
	$InputNumber,
	$Breadcrumb,
	$Alert,
	$Tag,
} = Common.Components;

/**
 * @description - The component used to add workflow state
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3115515905/Manage+Workflow+States+-+UI
 * @param {PropsFromRedux} props
 * @return {JSX.Element}
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 14/11/2022
 */

const NewState: React.FC<PropsFromRedux & INewState> = (props) => {
	const { t } = useTranslation();

	const {
		dataAdapterList,
		isStateSaving,
		isDeleting,
		duplicatingId,
		onClose,
		workflowDrawer,
		workflowStates,
		workflowVersions,
		initWorkflowVersion,
		metaData,
		isWorkflowPopupVisible,
		getDataAdapter,
		saveState,
		restoreState,
		getVersions,
		updateState,
		manageDuplicatingPopover,
		manageWorkflowPopover,
	} = props;

	const { stateId, stateCategoryId, actionType, workflowGroupStateId } =
		workflowDrawer ?? {};
	const isEdit = actionType == WorkflowDrawerActionType.EDIT;
	const isCopy = actionType == WorkflowDrawerActionType.COPY;

	const [isVersionsVisible, setVersionsVisible] =
		useState<boolean>(false);

	const { caseId } = metaData.data ?? {};
	const { pathname } = useLocation();

	const { params }: any = matchPath(pathname, {
		path: '/:type/:id?/:module?',
		exact: false,
		strict: false,
	});

	const parentFormik = useFormikContext<any>();
	const searchFilters = _.omit(parentFormik?.values, 'statesAndEvent');

	useEffect(() => {
		getDataAdapter &&
			getDataAdapter({
				workflowType: getWorkflowType(
					workflowStates?.data,
					stateCategoryId,
					stateId,
					actionType !=
						WorkflowDrawerActionType.ADD
				),
			});

		stateId && isCopy && getVersions && getVersions({ stateId });

		return () => {
			manageDuplicatingPopover &&
				manageDuplicatingPopover({});
		};
	}, []);

	/**
	 * @function
	 * @description Save new state
	 * @param values Form values
	 * @returns Void
	 */
	const handleSubmit = (values: any) => {
		const { isOpenWorkflow } = values ?? {};
		const formValues = { ...values, workflowGroupStateId };
		if (isEdit) {
			updateState &&
				updateState({
					request: WorkflowState.call(
						formValues,
						workflowVersions?.data
					),
					isOpenWorkflow,
					searchFilters,
				});
		} else {
			saveState &&
				saveState({
					request: WorkflowState.call(
						formValues,
						workflowVersions?.data
					),
					isOpenWorkflow,
					searchFilters,
					isCopy,
				});
		}
	};

	/**
	 * Get adapter sp list
	 * @param value Workflow type
	 * @param restProps Formik props
	 */
	const getDataAdapterList = (value: string, restProps: any) => {
		restProps.setFieldValue('adapterName', '');
		if (value == EntityType.SUB_CASE) {
			getDataAdapter &&
				getDataAdapter({
					workflowType:
						EntityTypeShortForm.SUB_CASE,
				});
		} else {
			getDataAdapter &&
				getDataAdapter({
					workflowType: EntityTypeShortForm.CASE,
				});
		}
	};

	/**
	 * @function
	 * @description Get state request payload
	 * @returns State request
	 */
	const getStateRequest = () => {
		return WorkflowGroup.call(
			getEntityNumberAndType(
				params['type'],
				params['id'],
				caseId?.toString() ?? ''
			)
		);
	};

	/**
	 * @function
	 * @description Restore existing workflow state
	 */
	const restoreWorkflowState = (values: any) => {
		restoreState &&
			restoreState({
				...StateDelete.call(
					{
						id: duplicatingId,
						categoryId: stateCategoryId,
					},
					0
				),
				stateRequest: getStateRequest(),
				searchFilters,
				isReActivate: true,
				isOpenWorkflow: values?.isOpenWorkflow,
				workflowGroupStateId,
			});
	};

	/**
	 * @function
	 * @description Display version and comments in drop down list
	 */
	const getVersionOptions = () => {
		try {
			return workflowVersions?.data?.map(
				({
					versionNo,
					versionCode,
					comment,
					accessLevel,
				}: any) => {
					return {
						displayLabel: (
							<div className='py-1'>
								<span className='d-block'>
									{
										accessLevel
									}
									{' - '}
									<$Tag>
										{
											versionCode
										}
									</$Tag>
								</span>
								<small className='d-block bui-label'>
									{
										comment
									}
								</small>
							</div>
						),
						label: versionCode,
						value: versionNo,
					};
				}
			);
		} catch (e) {
			return [];
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={getWorkflowStateInitial(
				workflowDrawer,
				workflowStates?.data,
				getStateRequest(),
				initWorkflowVersion
			)}
			onSubmit={(values: any) => {
				handleSubmit(values);
			}}
			validationSchema={StateValidation}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				...rest
			}: any) => (
				<>
					{isVersionsVisible && (
						<>
							<$Breadcrumb className='mb-2'>
								<$Breadcrumb.Item>
									<a
										onClick={(
											e: any
										) => {
											e.preventDefault();
											setVersionsVisible(
												false
											);
										}}>
										{t(
											'US.COLLECTION.CASE:WORKFLOWS.COPY_STATE'
										)}
									</a>
								</$Breadcrumb.Item>
								<$Breadcrumb.Item>
									{t(
										'US.COLLECTION.CASE:WORKFLOWS.SELECT_WORKFLOW_VERSION'
									)}
								</$Breadcrumb.Item>
							</$Breadcrumb>

							<WorkflowVersions
								onCancel={() =>
									setVersionsVisible(
										false
									)
								}
							/>
						</>
					)}
					{!isVersionsVisible && (
						<>
							<$Form>
								<div className='mb-4'>
									<$Select
										formitem={{
											label: t(
												'US.COLLECTION.CASE:WORKFLOWS.CATEGORY'
											),
										}}
										name='stateGroupName'
										size='small'
										required
										allOption={
											false
										}
										options={getCategoryList(
											workflowStates
										)}
										optionText='label'
										optionValue='value'
										disabled={
											isStateSaving
										}
									/>
								</div>
								<div>
									<$Input
										name='name'
										label={t(
											'US.COLLECTION.COMMON:COMMON.NAME'
										)}
										size='small'
										required
										className='w-100'
										disabled={
											isStateSaving ||
											isEdit
										}
										maxLength={
											100
										}
									/>
								</div>
								{isDuplicateStateName(
									workflowStates?.data,
									values?.name
								) &&
									!isEdit && (
										<div className='text-error'>
											{t(
												'US.COLLECTION.CASE:WORKFLOWS.STATE_NAME_ALREADY_EXISTS'
											)}
										</div>
									)}
								<div className='mb-3 mt-3'>
									<$Input
										name='displayName'
										label={t(
											'US.COLLECTION.CASE:WORKFLOWS.DISPLAY_NAME'
										)}
										size='small'
										required
										className='w-100'
										disabled={
											isStateSaving
										}
										maxLength={
											100
										}
									/>
								</div>
								<div className='mb-3'>
									<$FormItem
										required
										label={t(
											'US.COLLECTION.CASE:WORKFLOWS.DURATION'
										)}
										extra={t(
											'US.COLLECTION.CASE:WORKFLOWS.THE_WORKFLOW_WILL_RESUME_AFTER_THE_DEFINED_DURATION'
										)}>
										<$InputNumber
											name='dueInDays'
											size='small'
											required
											className='w-100'
											step={
												1
											}
											min={
												0
											}
											disabled={
												isStateSaving
											}
											maxLength={
												3
											}
										/>
									</$FormItem>
								</div>
								<div className='mb-3'>
									<$Radio
										name='workflowType'
										optionText='label'
										optionValue='value'
										className='d-flex mb-3'
										size='small'
										defaultValue={
											EntityType.CASE
										}
										buttonStyle='solid'
										optionStyle='mr-2 align-items-center'
										disabled={
											isStateSaving ||
											isEdit
										}
										onChange={(
											e: any
										) =>
											getDataAdapterList(
												e
													.target
													.value,
												rest
											)
										}
										options={[
											{
												value: EntityType.CASE,
												label: t(
													'US.COMMON:COMMON.CASE'
												),
											},
											{
												value: EntityType.SUB_CASE,
												label: t(
													'US.COMMON:COMMON.SUBCASE'
												),
											},
										]}
									/>
								</div>
								<div className='mb-4'>
									<$Select
										formitem={{
											label: t(
												'US.COLLECTION.CASE:WORKFLOWS.ADAPTER_NAME'
											),
										}}
										name='adapterName'
										size='small'
										required
										allOption={
											false
										}
										options={
											dataAdapterList?.data
										}
										optionText='label'
										optionValue='value'
										loading={
											dataAdapterList.isLoading
										}
										disabled={
											dataAdapterList.isLoading ||
											isStateSaving
										}
									/>
								</div>
								{isCopy && (
									<div className='mb-4'>
										<$Select
											formitem={{
												label: (
													<a
														onClick={(
															e: any
														) => {
															e.preventDefault();
															setVersionsVisible(
																true
															);
														}}>
														{t(
															'US.COLLECTION.CASE:WORKFLOWS.WORKFLOW_VERSION'
														)}
													</a>
												),
											}}
											name='workflowVersion'
											size='small'
											allOption={
												false
											}
											options={getVersionOptions()}
											optionValue='value'
											optionText='displayLabel'
											optionLabelProp='label'
											loading={
												workflowVersions.isLoading
											}
											disabled={
												workflowVersions.isLoading ||
												isStateSaving
											}
										/>
									</div>
								)}
								{values?.workflowVersion >=
									0 && (
									<$Alert
										type='warning'
										message={
											<span className='text-muted'>
												{t(
													'US.COLLECTION.CASE:WORKFLOWS.PLEASE_OPEN_IN_WORKFLOW_AND_VALIDATE_PARAMETERS'
												)}
											</span>
										}
										className='mt-n2 mb-4'
									/>
								)}
								<div className='mb-3'>
									<div className='d-flex align-items-start'>
										<div
											style={{
												marginTop: -1,
											}}>
											<$Switch
												size='small'
												name='activeStatus'
												checked={
													values.activeStatus
												}
												disabled={
													isStateSaving
												}
											/>
										</div>
										<div className='pl-2'>
											<div>
												{t(
													'US.COLLECTION.CASE:WORKFLOWS.IS_ACTIVE_STATE'
												)}
											</div>
											<small>
												{t(
													'US.COLLECTION.CASE:WORKFLOWS.SPECIFIES_WHETHER_THE_STATE_IS_ACTIVE'
												)}
											</small>
										</div>
									</div>
								</div>
								<div className='mb-3'>
									<$TextArea
										label={t(
											'US.COLLECTION.CASE:WORKFLOWS.DESCRIPTION'
										)}
										name='description'
										autoSize={{
											minRows: 3,
											maxRows: 3,
										}}
										className='w-100'
										disabled={
											isStateSaving
										}
									/>
								</div>
							</$Form>
							<div className='drawer-footer-fixed align-content-center justify-content-end'>
								<div>
									{typeof duplicatingId !==
										'number' && (
										<$Button
											type='primary'
											onClick={
												handleSubmit
											}
											className='mr-2'
											disabled={
												isStateSaving ||
												isWorkflowPopupVisible ||
												workflowVersions.isLoading ||
												(isDuplicateStateName(
													workflowStates?.data,
													values?.name
												) &&
													!isEdit)
											}
											loading={
												isStateSaving
											}
											data-testid='state-submit-btn'>
											{isEdit
												? t(
														'US.COMMON:COMMON.UPDATE'
												  )
												: t(
														'US.COMMON:COMMON.SAVE'
												  )}
										</$Button>
									)}
									{typeof duplicatingId ===
										'number' && (
										<$Popconfirm
											title={t(
												'US.COLLECTION.CASE:WORKFLOWS.RESTORE_MESSAGE'
											)}
											okText={t(
												'US.COLLECTION.COMMON:COMMON.YES'
											)}
											cancelText={t(
												'US.COLLECTION.COMMON:COMMON.NO'
											)}
											visible={
												typeof duplicatingId ===
												'number'
											}
											okButtonProps={{
												disabled: isDeleting,
												loading: isDeleting,
											}}
											onConfirm={() =>
												restoreWorkflowState(
													values
												)
											}
											onCancel={() =>
												manageDuplicatingPopover &&
												manageDuplicatingPopover(
													{}
												)
											}
											placement='topRight'>
											<$Button
												type='primary'
												className='mr-2'
												disabled={
													typeof duplicatingId ===
														'number' ||
													isDeleting ||
													isStateSaving ||
													isWorkflowPopupVisible ||
													workflowVersions.isLoading ||
													(isDuplicateStateName(
														workflowStates?.data,
														values?.name
													) &&
														!isEdit)
												}
												loading={
													isStateSaving
												}>
												{t(
													'US.COMMON:COMMON.SAVE'
												)}
											</$Button>
										</$Popconfirm>
									)}
									{actionType !=
										WorkflowDrawerActionType.ADD && (
										<$Popconfirm
											title={t(
												'US.COLLECTION.CASE:WORKFLOWS.WORKFLOW_TOOL_IS_NOT_AVAILABLE_IN_YOUR_MACHINE'
											)}
											okText={t(
												'US.COMMON:COMMON.DOWNLOAD'
											)}
											onConfirm={() => {
												window.open(
													WORKFLOW_TOOL_DOWNLOAD_URL,
													'_self'
												);
												manageWorkflowPopover &&
													manageWorkflowPopover(
														{}
													);
												onClose();
											}}
											onCancel={() => {
												manageWorkflowPopover &&
													manageWorkflowPopover(
														{}
													);
												onClose();
											}}
											visible={
												isWorkflowPopupVisible
											}
											placement='topLeft'
											overlayClassName='wt-not-available'>
											<$Button
												onClick={async () => {
													rest.setFieldValue(
														'isOpenWorkflow',
														true
													);
													handleSubmit();
												}}
												className='mr-2'
												disabled={
													typeof duplicatingId ===
														'number' ||
													isStateSaving ||
													isWorkflowPopupVisible ||
													workflowVersions.isLoading ||
													(isDuplicateStateName(
														workflowStates?.data,
														values?.name
													) &&
														!isEdit)
												}>
												{isEdit
													? t(
															'US.COLLECTION.CASE:WORKFLOWS.UPDATE_AND_OPEN_WORKFLOW'
													  )
													: t(
															'US.COLLECTION.CASE:WORKFLOWS.SAVE_AND_OPEN_WORKFLOW'
													  )}
											</$Button>
										</$Popconfirm>
									)}
									<$Popconfirm
										title={t(
											'US.COLLECTION.CASE:WORKFLOWS.CANCEL_ERROR'
										)}
										placement='topLeft'
										onConfirm={() =>
											onClose()
										}
										okText={t(
											'US.COMMON:COMMON.YES'
										)}
										cancelText={t(
											'US.COMMON:COMMON.NO'
										)}
										disabled={
											isStateSaving
										}>
										<$Button
											disabled={
												isStateSaving
											}>
											{t(
												'US.COMMON:COMMON.CANCEL'
											)}
										</$Button>
									</$Popconfirm>
								</div>
							</div>
						</>
					)}
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { Workflow, domainView } = state;
	const { metaData } = domainView;
	const {
		dataAdapterList,
		isStateSaving,
		isDeleting,
		duplicatingId,
		workflowDrawer,
		workflowStates,
		workflowVersions,
		initWorkflowVersion,
		isWorkflowPopupVisible,
	} = Workflow;
	return {
		dataAdapterList,
		isStateSaving,
		isDeleting,
		duplicatingId,
		workflowDrawer,
		workflowStates,
		workflowVersions,
		initWorkflowVersion,
		isWorkflowPopupVisible,
		metaData,
	};
};

const { dataAdapters, workflowState, stateDeletion, versions } = workflowAction;

const mapDispatchToProps = {
	getVersions: versions.get,
	getDataAdapter: dataAdapters.get,
	saveState: workflowState.save,
	updateState: workflowState.update,
	manageDuplicatingPopover: workflowState.manageDuplicatingPopover,
	manageWorkflowPopover: workflowState.manageWorkflowPopover,
	restoreState: stateDeletion.delete,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NewState);
