import { TransactionSagas } from './Transactions';
import { Payment, Apportionment } from './PaymentDistrubution';
import { ApplicableCourtsSagas } from './ApplicableCourts';
import { CasesAndSubCasesSagas } from './CasesAndSubCases';

export default [
	...TransactionSagas,
	...Payment,
	...Apportionment,
	...ApplicableCourtsSagas,
	...CasesAndSubCasesSagas,
];
