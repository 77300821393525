import moment, { Moment } from "moment";
import {
  ICaseInformation,
  ICaseInformationModel,
} from "us.collection.case/reducers/CaseInformation/Interfaces";
import { StandardDateFromat } from "../Constants";

export const GetFormikCaseInfo = (
  caseInfo: ICaseInformationModel
): ICaseInformation | {} => {
  return caseInfo.data
    ? {
        ...caseInfo.data,
        debtwarningDate:GetValidDate(caseInfo.data.debtwarningDate),
        boDate:GetValidDate(caseInfo.data.boDate),
        dcfDate:GetValidDate(caseInfo.data.dcfDate),
        vtDate:GetValidDate(caseInfo.data.vtDate),
        lastBODate:GetValidDate(caseInfo.data.lastBODate),
        lastDistraintDate:GetValidDate(caseInfo.data.lastDistraintDate),
        lastCourtDate:GetValidDate(caseInfo.data.lastCourtDate),
        migratedDate:GetValidDate(caseInfo.data.migratedDate),
        objectionDate:GetValidDate(caseInfo.data.objectionDate),
        obsoleteDate:GetValidDate(caseInfo.data.obsoleteDate),
        interestStopDate:GetValidDate(caseInfo.data.interestStopDate),
        closeDate:GetValidDate(caseInfo.data.closeDate),
        nextDueDate:GetValidDate(caseInfo.data.nextDueDate),
        caseDueDate:GetValidDate(caseInfo.data.caseDueDate)
      }
    : {};
};

export const GetValidDate = (dateString:string):Moment|undefined =>{
  return moment(dateString, StandardDateFromat).isValid()
  ?moment(dateString, StandardDateFromat)
  :undefined
}

export const GetValidDateStandardString = (date:Moment):string =>{
  return moment(date).isValid()
  ?moment(date).format(StandardDateFromat)
  :''
}
