import React, { useState, useEffect } from 'react';
import {
	Button,
	Input,
	Drawer,
	Checkbox,
	Form,
	Affix,
	PageHeader,
	Divider,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getNotesList } from 'us.collection.case/actions';
import './Home.scss';
import { IntlDate } from 'us.common/functions/intl';
import { IRootState } from 'us.collection/interfaces';
import { IDataModel } from 'us.collection.case/reducers/Dashboard/Interfaces';
import { IMetaData } from 'us.collection/interfaces';
import { RouteComponentProps } from 'react-router-dom';
interface INotes {
	metaData: IDataModel<IMetaData>;
}

interface IBase extends INotes, RouteComponentProps {
	notesList: any;
	getNotesList: () => void;
	currentLanguage: string;
}

const Notes: React.FC<IBase> = (props) => {
	const { t } = useTranslation(
		['US.COLLECTION.CASE', 'US.COLLECTION.COMMON'],
		{
			useSuspense: true,
		}
	);

	const { TextArea } = Input;
	const CheckboxGroup = Checkbox.Group;
	const plainOptions = [
		{ label: t('US.COLLECTION.CASE:CASENOTES.ALL'), value: 'All' },
		{
			label: t('US.COLLECTION.CASE:CASENOTES.SYSTEM'),
			value: 'System',
		},
		{
			label: t('US.COLLECTION.CASE:CASENOTES.USER'),
			value: 'User',
		},
	];

	const {
		notesList,
		getNotesList,
		currentLanguage,
		history,
		location,
		metaData,
	} = props;
	const { caseNo } = metaData.data ?? {};
	const [enterNotes, setEnterNotes] = useState<any>([]);

	useEffect(() => {
		if (notesList.length === 0) {
			getNotesList();
		} else {
			setEnterNotes(notesList);
		}
	}, [getNotesList, notesList]);

	const onChange = (checkedValues: any) => {
		if (checkedValues.length === 0) {
			setEnterNotes(notesList);
		}
		if (checkedValues.length === 1 && checkedValues[0] === 'All') {
			setEnterNotes(notesList);
		}
		if (checkedValues.length === 1 && checkedValues[0] === 'User') {
			let tempNote: any[] = [];
			enterNotes.forEach((element: any) => {
				if (element.texttype !== 'system') {
					tempNote.push(element);
				}
			});
			setEnterNotes(tempNote);
		}
		if (
			checkedValues.length === 1 &&
			checkedValues[0] === 'System'
		) {
			let tempNote: any[] = [];
			enterNotes.forEach((element: any) => {
				if (element.texttype === 'system') {
					tempNote.push(element);
				}
			});
			setEnterNotes(tempNote);
		}
		if (checkedValues.length > 1) {
			setEnterNotes(notesList);
		}
	};

	const visibleDrawer = {
		title: '',
		visible: false,
	};

	const drawerForm = {
		NoteText: '',
		CopiedToDebnotes: true,
		CpState: false,
	};

	const [drawer, setDrawer] = useState<any>(visibleDrawer);
	const [disableDelete, setDisableDelete] = useState<boolean>(false);
	const [editForm, setEditForm] = useState<any>(drawerForm);

	const onClose = () => {
		setEditForm('');
		setDrawer({
			title: '',
			visible: false,
		});
	};

	const handleAddNote = () => {
		setDisableDelete(true);
		setDrawer({
			title: t('US.COLLECTION.CASE:CASENOTES.CASENOTE'),
			visible: true,
		});
	};
	const handleDelete = () => {
	};
	const handleSave = (e: any) => {
		e.preventDefault();
	};
	const handleCancel = () => {
		onClose();
	};

	const onChangeCopiedToDebnotes = (e: any) => {
		setEditForm({
			...editForm,
			CopiedToDebnotes: e.target.value ? false : true,
		});
	};
	const onChangeCpState = (e: any) => {
		setEditForm({
			...editForm,
			CpState: e.target.value ? false : true,
		});
	};

	const minimize = () => {
		history.push(`/case/${caseNo}`, location.state);
	};

	return (
		<div>
			<Affix offsetTop={80}>
				<div className='page-header'>
					<div className='d-flex align-items-center'>
						<PageHeader
							className='px-0 py-3 mr-n3'
							onBack={minimize}
							title={t(
								'US.COLLECTION.CASE:CASENOTES.CASENOTE'
							)}
						/>
						<Divider
							className='bui-devider'
							type='vertical'
						/>
						<Button
							type='dashed'
							icon='plus'
							size='small'
							onClick={() =>
								handleAddNote()
							}>
							{t(
								'US.COLLECTION.CASE:CASENOTES.ADDNEWCASE'
							)}
						</Button>

						<div className='ml-auto'>
							<div className='chk-group'>
								<CheckboxGroup
									options={
										plainOptions
									}
									defaultValue={[
										'All',
									]}
									onChange={(
										e
									) =>
										onChange(
											e
										)
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</Affix>

			<div className='case-note-row'>
				{enterNotes.map((item: any) => {
					return (
						<div
							key={item.id}
							className='case-note-col'>
							<div className='case-note-card '>
								<div className='case-note-card-content'>
									<div className='mb-2 note-registred-by'>
										{t(
											'US.COLLECTION.CASE:CASENOTES.REGISTEREDBY'
										)}{' '}
										:{' '}
										<strong>
											{
												item.regby
											}{' '}
											|{' '}
											{IntlDate(
												item.regdate,
												currentLanguage
											)}
										</strong>
									</div>
									<p>
										{
											item.text
										}
									</p>
								</div>
								<div className='case-note-card-ellipsis'></div>
							</div>
						</div>
					);
				})}
			</div>

			<Drawer
				title={drawer.title}
				width={720}
				visible={drawer.visible}
				onClose={() => onClose()}>
				<Form>
					<FormItem>
						<TextArea rows={4} />
					</FormItem>

					<div className='d-flex'>
						<FormItem className='mr-2'>
							<Checkbox
								onChange={(e) =>
									onChangeCopiedToDebnotes(
										e
									)
								}
								checked={
									editForm
										? editForm.CopiedToDebnotes
										: false
								}>
								{t(
									'US.COLLECTION.CASE:CASENOTES.COPYASDEBTORNOTES'
								)}
							</Checkbox>
						</FormItem>
						<FormItem>
							<Checkbox
								onChange={(e) =>
									onChangeCpState(
										e
									)
								}
								checked={
									editForm
										? editForm.CpState
										: false
								}>
								{t(
									'US.COLLECTION.CASE:CASENOTES.INTERNAL'
								)}
							</Checkbox>
						</FormItem>
					</div>
				</Form>

				<div className='drawer-footer-fixed align-content-center justify-content-end'>
					<div>
						<Button
							type='ghost'
							onClick={() =>
								handleDelete()
							}
							disabled={
								disableDelete
							}>
							{t(
								'US.COLLECTION.COMMON:COMMON.DELETE'
							)}
						</Button>
						<Divider
							type='vertical'
							style={{
								height: '2em',
							}}
							className='mx-3'></Divider>
						<Button
							className='mr-2'
							type='primary'
							htmlType='submit'>
							{t(
								'US.COLLECTION.COMMON:COMMON.SAVE'
							)}
						</Button>
						<Button
							onClick={() =>
								handleCancel()
							}>
							{t(
								'US.COLLECTION.COMMON:COMMON.CANCEL'
							)}
						</Button>
					</div>
				</div>
			</Drawer>
		</div>
	);
};

const mapStateToProps = (state: IRootState) => {
	const { domainView, common } = state;
	const { metaData } = domainView;
	const { currentLanguage } = common;
	return {
		notesList: state.case.notesList,
		currentLanguage,
		metaData,
	};
};

const mapDispatchToProps = {
	getNotesList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
