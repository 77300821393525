import {
  IBMDGroup,
  IProperty,
  IBMDCategory,
} from "us.collection/interfaces/BMD";
import { IBMDList } from "us.collection/interfaces/BMD";
import { appInsights } from "us.helper";
import { IBMDListFrom } from "../Interfaces";
import { IOnFilter, IOnSort } from "us.common/components";
import { DEFAULT_GROUP } from "us.collection/components/BMD/Constants";
import _ from "lodash";

/**
 * To get the BMD list filtered based on the form data
 * @param param BMDList form data
 * @param bmdListData full BMD list
 * @returns filtered BMDList
 */
export const getDataFiltered = (
  formValues: IBMDListFrom,
  bmdListData: IBMDList
): IBMDList => {
  try {
    const { initialPropertyCheck } = formValues;
    const bmdSearch = formValues?.bmdSearch?.toLowerCase();

    if (!formValues?.bmdSearch && !initialPropertyCheck) {
      return {
        ...bmdListData,
      };
    } else {
      let resultingBMDGroups: IBMDGroup[],
        resultingPropertyList: IProperty[],
        categories: IBMDCategory[] | undefined;
      // filtering category
      categories = bmdListData?.categories.flatMap((category) => {
        if (category.name.toLocaleLowerCase().includes(bmdSearch)) {
          return [{ ...category }];
        }
        // filtering bmd groups
        resultingBMDGroups = category.bmdGroups.flatMap((bmdGroup) => {
          // filtering property
          resultingPropertyList = bmdGroup.propertyList.flatMap((property) => {
            if (bmdSearch && initialPropertyCheck) {
              return (property.keyName.toLowerCase().includes(bmdSearch) ||
                property.displayName.toLowerCase().includes(bmdSearch)) &&
                property.isInitialSetupProperty
                ? [property]
                : [];
            } else if (
              bmdSearch &&
              (property.keyName.toLowerCase().includes(bmdSearch) ||
                property.displayName.toLowerCase().includes(bmdSearch))
            ) {
              return [property];
            } else if (
              initialPropertyCheck &&
              property.isInitialSetupProperty
            ) {
              return [property];
            } else {
              return [];
            }
          });
          if (resultingPropertyList.length > 0) {
            return [{ ...bmdGroup, propertyList: resultingPropertyList }];
          } else {
            return [];
          }
        });
        if (resultingBMDGroups.length > 0) {
          return [{ ...category, bmdGroups: resultingBMDGroups }];
        } else {
          return [];
        }
      });

      return bmdListData && categories
        ? {
            ...bmdListData,
            categories,
          }
        : {
            key: 1,
            categories: [],
          };
    }
  } catch (error) {
    appInsights.trackException(
      `Error in BMD list View GetDataFiltered function. - ${error}`
    );
    return bmdListData;
  }
};

/**
 * To get formatted tree data for table tree
 * @param categories filtered categories
 * @param selectedCategoryId selected category id
 * @param selectedBmdGroup selected bmd group
 * @returns formatted BMDList
 */
export const getTreeData = (
  categories: Array<any>,
  selectedCategoryId: any,
  selectedBmdGroup: any
) => {
  try {
    let allData: any = [];
    categories
      ?.filter(({ id }: any) =>
        selectedCategoryId == 0 ? true : selectedCategoryId == id
      )
      ?.map((category: any) => {
        let groups: any[] = [];

        category?.bmdGroups
          ?.filter(({ name }: any) =>
            selectedBmdGroup == DEFAULT_GROUP ? true : selectedBmdGroup == name
          )
          ?.map((bmdGroup: any) => {
            groups.push({
              ...bmdGroup,
              key: `${category?.id}_${bmdGroup?.id}`,
              categoryId: category?.id,
              categoryName: category?.name,
              children: bmdGroup?.propertyList?.map((property: any) => ({
                ...property,
                key: `${category?.id}_${bmdGroup?.id}_${property?.id}`,
              })),
            });
          });
        groups?.length > 0 &&
          allData?.push({
            ...category,
            key: `${category?.id}`,
            children: groups,
          });
      });
    return allData;
  } catch (e) {
    return [];
  }
};

/**
 * To get tree table parent keys
 * @param categories filtered categories
 * @returns parent keys
 */
export const getAllTableParentKeys = (categories: Array<any>): Array<any> => {
  try {
    let keys: any = [];
    categories?.map((category: any) => {
      category?.bmdGroups?.map((bmdGroup: any) => {
        keys.push(`${category?.id}_${bmdGroup?.id}`);
      });
      keys?.push(`${category?.id}`);
    });
    return keys;
  } catch (e) {
    return [];
  }
};

/**
 * To handle tree table sorting
 * @param sortData data to sort
 * @param dataSource table data source
 * @returns sorted data
 */
export const handleTableSort: IOnSort = (sortData, dataSource): Array<any> => {
  try {
    return dataSource?.map((category: any) => {
      return {
        ...category,
        children: category.children?.map((group: any) => {
          return { ...group, children: sortData(group.children) };
        }),
      };
    });
  } catch (e) {
    return [];
  }
};

/**
 * To handle tree table filtering
 * @param searchData data to search for
 * @param dataSource table data source
 * @returns filtered data
 */
export const handleTableFilter: IOnFilter = (
  searchData,
  dataSource
): Array<any> => {
  try {
    const childData = (data: any) => {
      return data.children?.flatMap((group: any) => {
        if (searchData(group?.children)?.length == 0) {
          return [];
        } else {
          return {
            ...group,
            children: searchData(group?.children),
          };
        }
      });
    };
    return dataSource?.flatMap((category: any) => {
      if (childData(category)?.length == 0) {
        return [];
      } else {
        return {
          ...category,
          children: childData(category),
        };
      }
    });
  } catch (e) {
    return [];
  }
};

/**
 * To get all group list
 * @param categories filtered categories
 * @returns all groups
 */
export const getAllGroups = (categories: Array<any>): Array<any> => {
  try {
    let allGroups: any[] = [];
    categories.map(({ bmdGroups }: any) =>
      allGroups.push(...bmdGroups?.map(({ name }: any) => ({ name })))
    );
    return _.uniqBy(allGroups, "name");
  } catch (e) {
    return [];
  }
};
