import { INonProductionDay } from "us.collection.admin/interfaces";
import moment from "moment";

export function NonProductionDays(this: number): Array<INonProductionDay> {
  return [
    {
      id: -1,
      key: 0,
      date: moment.utc(`${this}-01-01`),
      reason: "New Year's Day",
      criteria: [
        {
          type: "NonBanking",
          value: 1,
        },
        {
          type: "NonSms",
          value: 1,
        },
        {
          type: "NonPrinting",
          value: 1,
        },
        {
          type: "NonWorkflow",
          value: 1,
        },
      ],
      isFixedDate: true
    },
    {
      id: -1,
      key: 1,
      date: moment.utc(`${this}-05-01`),
      reason: "Labour Day",
      criteria: [
        {
          type: "NonBanking",
          value: 1,
        },
        {
          type: "NonSms",
          value: 1,
        },
        {
          type: "NonPrinting",
          value: 1,
        },
        {
          type: "NonWorkflow",
          value: 1,
        },
      ],
      isFixedDate: true
    },
    {
      id: -1,
      key: 2,
      date: moment.utc(`${this}-05-17`),
      reason: "Constitution Day",
      criteria: [
        {
          type: "NonBanking",
          value: 1,
        },
        {
          type: "NonSms",
          value: 1,
        },
        {
          type: "NonPrinting",
          value: 1,
        },
        {
          type: "NonWorkflow",
          value: 1,
        },
      ],
      isFixedDate: true
    },
    {
      id: -1,
      key: 3,
      date: moment.utc(`${this}-12-24`),
      reason: "Christmas Eve",
      criteria: [
        {
          type: "NonBanking",
          value: 1,
        },
        {
          type: "NonSms",
          value: 1,
        },
        {
          type: "NonPrinting",
          value: 1,
        },
        {
          type: "NonWorkflow",
          value: 1,
        },
      ],
      isFixedDate: true
    },
    {
      id: -1,
      key: 4,
      date: moment.utc(`${this}-12-25`),
      reason: "Christmas Day",
      criteria: [
        {
          type: "NonBanking",
          value: 1,
        },
        {
          type: "NonSms",
          value: 1,
        },
        {
          type: "NonPrinting",
          value: 1,
        },
        {
          type: "NonWorkflow",
          value: 1,
        },
      ],
      isFixedDate: true
    },
    {
      id: -1,
      key: 5,
      date: moment.utc(`${this}-12-26`),
      reason: "Day after Christmas Day",
      criteria: [
        {
          type: "NonBanking",
          value: 1,
        },
        {
          type: "NonSms",
          value: 1,
        },
        {
          type: "NonPrinting",
          value: 1,
        },
        {
          type: "NonWorkflow",
          value: 1,
        },
      ],
      isFixedDate: true
    },
  ];
}
