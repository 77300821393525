import React, { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, matchPath } from 'react-router';
import Common from 'us.common';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { UploadOutlined, FileExclamationOutlined } from 'us.icons';
import { caseDocuments } from 'us.collection.documents/actions';
import { getLookUpTypeByCaseType } from 'us.collection.documents/functions';
import { IDocumentCategory } from 'us.collection.documents/interfaces';
import '../Home/CaseDocument.scss';
import _ from 'lodash';
import { IMetaData } from 'us.collection/interfaces';
import { IDataModel } from 'us.collection.case/reducers/Dashboard/Interfaces';
import {
	getMIMETypeByFileFormat,
	base64toStr,
} from 'us.collection.documents/functions';
import XMLViewer from 'react-xml-viewer';
import { RootState } from 'us.helper/types';

const {
	$Button,
	$Skeleton,
	$Upload,
	$Typography,
	$Popconfirm,
	$Form,
	$TextArea,
	$Drawer,
	$Input,
	$AutoComplete,
	$Switch,
	$Empty,
} = Common.Components;

const { Dragger } = $Upload;
const { Text } = $Typography;

interface IUploadCaseDocuments {
	onClose: () => void;
	fileList: Array<any>;
	caseDocument: any;
	categories: any;
	rules: any;
	onRemoveFile?: any;
	saveCaseDocuments?: any;
	getDocumentCategories?: any;
	uploadRules?: any;
	metaData: IDataModel<IMetaData>;
}

const UploadCaseDocuments: React.FC<IUploadCaseDocuments> = memo((props) => {
	const { t } = useTranslation(
		['US.COLLECTION.DOCUMENTS', 'US.COLLECTION.COMMON'],
		{
			useSuspense: true,
		}
	);

	const {
		onClose,
		fileList,
		caseDocument,
		categories,
		rules,
		onRemoveFile,
		saveCaseDocuments,
		getDocumentCategories,
		uploadRules,
		metaData,
	} = props;

	const { location } = useHistory();

	const { caseId, entityType, creditorId } = metaData.data ?? {};

	const [showPreview, setShowPreviewVisible] = useState<boolean>(false);
	const [fileContent, setFileContent] = useState<any>({
		type: '',
		data: '',
	});
	const [fileName, setFileName] = useState<string>('');
	const unableToDisplayFormats = ['doc', 'docx', 'csv', 'xls', 'xlsx'];

	useEffect(() => {
		getDocumentCategories();
	}, []);

	const getEntityTypeAndCaseId = () => {
		const { params }: any = matchPath(location.pathname, {
			path: '/:type/:Id?/:module?',
			exact: false,
			strict: false,
		});
		let id = params['Id'];
		let caseType = params['type'];
		switch (caseType.toLowerCase()) {
			case 'case':
				id = caseId;
				break;
			case 'creditor':
				id = creditorId;
		}
		return {
			caseType,
			id,
		};
	};
	const getBase64 = (file: any) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};

	const showPreviewClick = (file: any) => {
		setFileName(file?.name);
		getBase64(file)
			.then((data: any) =>
				setFileContent({
					type: file?.name.split('.')[1],
					data,
				})
			)
			.catch((error: any) =>
				setFileContent({ type: '', data: '' })
			);
		setShowPreviewVisible(true);
	};

	const submitFiles = (
		noteText: any,
		addToHistory: any,
		documentType: any,
		showInCreditorPortal:boolean
	) => {
		if (entityType) {
			saveCaseDocuments(fileList, {
				entityType: getLookUpTypeByCaseType(
					getEntityTypeAndCaseId().caseType,
					entityType
				),
				entityId: getEntityTypeAndCaseId().id,
				noteText,
				addToHistory,
				documentType,
				showInCreditorPortal
			});
		}
	};
	const closePreviewClick = () => {
		setShowPreviewVisible(false);
	};

	const onUploadDrawerClose = () => {
		onRemoveFile([]);
		onClose();
	};

	const draggerProps = {
		onRemove: (file: any) => {
			const index = fileList.indexOf(file);
			fileList.splice(index, 1);
			onRemoveFile([...fileList]);
		},
		beforeUpload: (file: any, list: any) => {
			const newList = [...fileList, ...list];
			newList.forEach((item: any) => {
				const isLtMax =
					item.size / 1024 / 1024 >
					uploadRules.maxSize;
				if (isLtMax) {
					item.status = 'error';
					item.response = t(
						'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.FILE_SIZE_EXCEEDED'
					);
				}
			});
			onRemoveFile(newList);
			return false;
		},
		accept: uploadRules.formats,
		multiple: true,
		fileList,
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				noteText: '',
				addToHistory: true,
				documentType: '',
				showInCreditorPortal:false
			}}
			onSubmit={(values: any, actions: any) => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				...rest
			}: any) => (
				<>
					<$Form onSubmit={handleSubmit}>
						<div>
							<div className='mb-2'>
								<strong>
									{t(
										'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.SELECTED_DOCUMENTS'
									)}{' '}
									(
									{
										fileList.length
									}
									/
									{
										uploadRules.maxCount
									}
									)
								</strong>
							</div>
							<div
								className={
									fileList.length >=
									uploadRules.maxCount
										? 'upload-disable'
										: ''
								}>
								<Dragger
									{...draggerProps}
									//defaultFileList={[...fileList]}
									name='fileUpload'
									className='text-left px-3 mb-2'
									onPreview={
										showPreviewClick
									}
									onChange={(
										file: any
									) => {
										const formData =
											new FormData();
										formData.append(
											'files[]',
											file
										);
									}}>
									<div className='d-flex align-items-center'>
										<p className='ant-upload-drag-icon mb-0 mr-3'>
											<UploadOutlined />
										</p>
										<div>
											<p className='ant-upload-text mb-0'>
												{t(
													'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.ADD_MORE_DOCUMENTS'
												)}
											</p>
											<div>
												<Text type='secondary'>
													{t(
														'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.ONLY_ALLOWED'
													) +
														` ${
															_.find(
																rules?.data,
																{
																	propertyName:
																		'AllowedFileTypes',
																}
															)
																?.propertyValue
														} ` +
														`(${t(
															'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.MAXIMUM_UPLOAD_SIZE'
														)} ${
															uploadRules.maxSize
														}MB)`}
												</Text>
											</div>
										</div>
									</div>
								</Dragger>
								<div className='required mt-3'>
									{fileList.length >
										uploadRules.maxCount && (
										<div>
											{`${t(
												'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.MAXIMUM_FILE_COUNT'
											)}(${
												uploadRules.maxCount
											}) ${t(
												'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.EXCEEDED'
											)}`}
										</div>
									)}
									{_.sumBy(
										fileList,
										'size'
									) /
										1024 /
										1024 >
										uploadRules.maxSize && (
										<div>
											{`${t(
												'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.MAXIMUM_UPLOAD_SIZE'
											)}(${
												uploadRules.maxSize
											} MB) ${t(
												'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.EXCEEDED'
											)}`}
										</div>
									)}
								</div>
							</div>
							<div className='mt-3'></div>
							<div className='mt-4'>
								<$AutoComplete
									size='small'
									name='documentType'
									style={{
										width: '100%',
									}}
									className='mr-2'
									label={t(
										'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.CATEGORY_NAME'
									)}
									options={categories?.data?.map(
										(
											category: IDocumentCategory
										) => {
											return {
												value: category.documentCategory,
												label: category.documentCategory,
											};
										}
									)}
								/>
							</div>

							<div className='mt-2'>
								<$TextArea
									name='noteText'
									autoSize={{
										minRows: 4,
										maxRows: 4,
									}}
									label={t(
										'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.NOTE'
									)}
								/>
							</div>

							<div className='mt-3 d-flex align-items-center'>
								<div className='mr-2'>
									<$Switch
										name='addToHistory'
										checked={
											values?.addToHistory
										}
									/>
								</div>
								<div>
									{t(
										'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.SHOW_IN_HISTORY'
									)}
								</div>
							</div>

							<div className='mt-3 d-flex align-items-center'>
								<div className='mr-2'>
									<$Switch
										name='showInCreditorPortal'
										checked={
											values?.showInCreditorPortal
										}
									/>
								</div>
								<div>
									{t(
										'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.SHOW_IN_CREDITOR_PORTAL'
									)}
								</div>
							</div>

							<div className='drawer-footer-fixed align-content-center justify-content-end'>
								<div>
									<$Button
										className='mr-2'
										disabled={
											!rest.isValid ||
											fileList.length ==
												0 ||
											fileList.length >
												uploadRules.maxCount ||
											_.find(
												fileList,
												{
													status: 'error',
												}
											) ||
											_.sumBy(
												fileList,
												'size'
											) /
												1024 /
												1024 >
												uploadRules.maxSize
										}
										loading={
											caseDocument.isFetching
										}
										onClick={() =>
											submitFiles(
												values?.noteText,
												values?.addToHistory,
												values?.documentType,
												values?.showInCreditorPortal,
											)
										}
										type='primary'>
										{t(
											'US.COLLECTION.COMMON:COMMON.UPLOAD'
										)}
									</$Button>
									{(rest.dirty ||
										fileList.length !=
											0) && (
										<$Popconfirm
											title={t(
												'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.UPLOAD_CANCEL_ERROR'
											)}
											placement='topLeft'
											onConfirm={() => {
												rest.resetForm();
												onUploadDrawerClose();
											}}
											okText={t(
												'US.COLLECTION.COMMON:COMMON.YES'
											)}
											cancelText={t(
												'US.COLLECTION.COMMON:COMMON.NO'
											)}>
											<$Button>
												{t(
													'US.COLLECTION.COMMON:COMMON.CANCEL'
												)}
											</$Button>
										</$Popconfirm>
									)}
									{!rest.dirty &&
										fileList.length ==
											0 && (
											<$Button
												onClick={() =>
													onUploadDrawerClose()
												}>
												{t(
													'US.COLLECTION.COMMON:COMMON.CANCEL'
												)}
											</$Button>
										)}
								</div>
							</div>
						</div>
					</$Form>
					{/* PREVIEW DOCUMENT */}
					<$Drawer
						title={fileName}
						width={'75%'}
						visible={showPreview}
						onClose={closePreviewClick}
						className='case-doc-viwer case-doc-preview'>
						<>
							<$Skeleton
								loading={false}>
								{unableToDisplayFormats.includes(
									fileContent.type
								) && (
									<div className='unable-to-display'>
										<$Empty
											image={
												<FileExclamationOutlined />
											}
											description={
												<span>
													{t(
														'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.PREVIEW_IS_NOT_AVAILABLE'
													)}
												</span>
											}
										/>
									</div>
								)}
								{!unableToDisplayFormats.includes(
									fileContent.type
								) &&
									fileContent?.type ==
										'xml' && (
										<XMLViewer
											xml={base64toStr(
												fileContent.data?.split(
													','
												)[1]
											)}
											theme={{
												overflowBreak:
													true,
											}}
											indentSize={
												5
											}
											collapsible={
												true
											}
										/>
									)}
								{!unableToDisplayFormats.includes(
									fileContent.type
								) &&
									fileContent?.type !=
										'xml' && (
										<div className='case-doc-viwer-inner'>
											<object
												className={
													fileContent.type ===
													'pdf'
														? 'case-doc-frame frame-pdf'
														: 'case-doc-frame frame-html'
												}
												type={getMIMETypeByFileFormat(
													fileContent.type
												)}
												data={
													fileContent.data
												}
											/>
										</div>
									)}
								<div className='drawer-footer-fixed align-content-center justify-content-end'>
									<div>
										<$Button
											onClick={() =>
												closePreviewClick()
											}>
											{t(
												'US.COLLECTION.COMMON:COMMON.CANCEL'
											)}
										</$Button>
									</div>
								</div>
							</$Skeleton>
						</>
					</$Drawer>
				</>
			)}
		</Formik>
	);
});

const mapStateToProps = (state: RootState) => {
	const { common, documents, domainView } = state;
	const { currentDateFormat, currentLanguage } = common;
	const { caseDocument, rules, categories } = documents;
	const { metaData } = domainView;
	return {
		currentDateFormat,
		currentLanguage,
		caseDocument,
		categories,
		rules,
		metaData,
	};
};

const mapDispatchToProps = {
	saveCaseDocuments: caseDocuments.documents.upload,
	getDocumentCategories: caseDocuments.categories.get,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UploadCaseDocuments);
