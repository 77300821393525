export enum URLType {
	CASE = 'case',
	SUBCASE = 'subcase',
	AR = 'ar',
	CREDITOR = 'creditor',
	CREDITORGROUP = 'creditorgroup',
	BUREAU = 'bureau',
	EXCASENO = 'caseno',
	CREDITOR_GROUP = 'creditor-group',
	TRANSACTIONSS = 'transactions',
	PAYMENT = 'payment',
	DEBTOR = 'debtor',
	INVOICE = 'invoice',
}

export enum URLTypeId {
	CASE = 'caseid',
	AR = 'arid',
	CREDITOR = 'creditorid',
	CREDITORGROUP = 'creditorgroupid',
	BUREAU = 'bureau',
	EXCASENO = 'caseno',
	PID = 'pid',
}

export enum EntityType {
	SUBCASE = 'S',
	CASE = 'C',
	AR = 'A',
	CREDITOR = 'CR',
	CREDITORGROUP = 'CREDITORGROUP',
	BUREAU = 'BU',
	PAYMENT = 'P',
	CREDITOR_GROUP = 'CG',
}

export enum LevelType {
	SUBCASE = 'SubCase',
	CASE = 'Case',
	AR = 'AR',
	CREDITOR = 'Creditor',
	PAYMENT = 'Payment',
	BUREAU = 'Bureau',
}

export enum WorkflowType {
	SUB_CASE = 'SubCase',
	CASE = 'Case',
}

export enum NoteType {
    CASE_NOTE = "casenote",
    CP_NOTE = "cpnote",
}

export enum NoteSource {
    US_BOX= "USBOX",
    CP = "CP",
    API = "API"
}

export enum CreditorNotesAvailability {
	YES = "Yes",
	NO = "No"
}

export enum ActivityTypesForPayload {
	ALL="All",
	MESSAGE="message"
}

export enum ActivityStatusForPayload {
	ALL="All",
	SUCCESS = "Success"
}

export enum DomainView {
	INIT_CASE_VIEW = 'INIT_CASE_VIEW',
	INIT_CASE_VIEW_SUCCESS = 'INIT_CASE_VIEW_SUCCESS',
	INIT_CASE_VIEW_FAIL = 'INIT_CASE_VIEW_FAIL',

	GET_CASE_ID = 'GET_CASE_ID',
	GET_CASE_ID_SUCCESS = 'GET_CASE_ID_SUCCESS',
	GET_CASE_ID_FAIL = 'GET_CASE_ID_FAIL',

	GET_META_DATA = 'GET_META_DATA',
	GET_META_DATA_SUCCESS = 'GET_META_DATA_SUCCESS',
	GET_META_DATA_FAIL = 'GET_META_DATA_FAIL',
	RESET_META_DATA = 'RESET_META_DATA',

	GET_WORKFLOW_STATUS = 'GET_WORKFLOW_STATUS',
	GET_WORKFLOW_STATUS_SUCCESS = 'GET_WORKFLOW_STATUS_SUCCESS',
	GET_WORKFLOW_STATUS_FAIL = 'GET_WORKFLOW_STATUS_FAIL',

	RESET_DOMAIN_VIEW_DATA = 'RESET_DOMAIN_VIEW_DATA',

	GET_GDPR_COMMENT_VIEW_START = 'GET_GDPR_COMMENT_VIEW_START',
	GET_GDPR_COMMENT_VIEW_SUCCESS = 'GET_GDPR_COMMENT_VIEW_SUCCESS',
	GET_GDPR_COMMENT_VIEW_FAIL = 'GET_GDPR_COMMENT_VIEW_FAIL',
	GET_GDPR_COMMENT_VIEW_RESET = 'GET_GDPR_COMMENT_VIEW_RESET',

	ADD_GDPR_COMMENT_START = 'ADD_GDPR_COMMENT_START',
	ADD_GDPR_COMMENT_SUCCESS = 'ADD_GDPR_COMMENT_SUCCESS',
	ADD_GDPR_COMMENT_FAIL = 'ADD_GDPR_COMMENT_FAIL',
}
