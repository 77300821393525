import {
  httpCollection,
  versionedHttpCollection,
} from "us.helper/http/collection";
import ServiceConfig from "service.config.js";

export default {
  courts: {
    get: <P extends { entityType: string; entityId: string }>(
      params: P
    ): Promise<{}> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.get(
            "entityservice",
            "entities/applicablecourts",
            params,
            ServiceConfig()[`entityservice`]["entities.applicablecourts"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
