import React from 'react';

interface IBmd {
    onMinimize: () => void;
}

const Bmd: React.FC<IBmd> = () => {
    return (
        <p>bmd...</p>
    )
}

export default Bmd;