import React, { useCallback, useContext, useMemo, useState } from 'react';
import './Header.scss';
import { ConnectedProps, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Common from 'us.common';
import { $Skeleton } from 'us.common/components';
import { ObsoletedateAction, workflowAction } from 'us.collection/actions';
import { INavigationData } from 'us.collection/interfaces';
import { Formik } from 'formik';
import { BoxIcons, IconTypes, LoadingOutlined } from 'us.icons';
import { useHistory, useLocation } from 'react-router-dom';
import { EntityFollowUpTabs } from 'us.collection/constants';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import {
	PendingActivityExecution,
	AddToFollowUpButton,
} from 'us.collection/components';
import { ConfigurationContext } from 'us.common/ConfigurationContext/ConfigurationProvider';
import { CloseCase, ObsoleteDate, WorkflowStatusHistory } from './Components';
import { Handler } from 'us.collection/layouts/CaseView/Components/Handler';
import { Mode } from 'us.common/constants';
import { getEntityDetail, getEntityId } from 'us.common/functions';
import { RootState } from 'us.helper/types';

const { $Button, $Tooltip, $Divider, ActivityExecution, $Drawer } = Common.Components;

const { AddObsoletedate } = ObsoletedateAction;
const { retryWorkflow } = workflowAction;

const Header: React.FC<PropsFromRedux> = (props) => {
	const { t } = useTranslation();
	const {
		metaData,
		accountSummeryWidgetData,
		debtorWidgetData,
		retryWorkflow,
		workflow,
		retryWorkflowExecute,
	} = props;

	const location = useLocation();
	const { push } = useHistory();

	const state = location.state as INavigationData;
	const context = useContext(ConfigurationContext);

	const { creditorDetails, debtorDetails } = debtorWidgetData.data ?? {};
	const { cid, pid, caseId, caseNo, entityType } = metaData.data ?? {};
	const { case: CaseInfo } = accountSummeryWidgetData.data ?? {};
	const { firstName: debFirstName, lastName: debLastName } =
		debtorDetails ?? {};
	const { firstName: credFirstName, lastName: credLastName } =
		creditorDetails ?? {};

	const { kid } = CaseInfo ?? {};
	const { workflowStatus } = workflow.data ?? {};

	const [isWorkflowStatusHistoryVisible, setWorkflowStatusHistoryVisible] = useState<boolean>(false);

	const entityDetail = useMemo(() => {
		return getEntityDetail(location.pathname, metaData.data);
	}, [metaData.data?.caseId]);

	const {
		entityTypeShortForm,
		entityId,
		entityType: newEntityType,
		urlEntityType,
	} = entityDetail ?? {};

	const isItemAvailable = useCallback(
		(item: string) => {
			return context.componentPermission['case']?.components[
				item
			]?.isEnabled;
		},
		[context]
	);

	// const handleRetryWorkflow = () => {
	// 	retryWorkflowExecute &&
	// 		retryWorkflowExecute({
	// 			caseId,
	// 			entityType,
	// 			accountSummeryWidgetData,
	// 		});
	// };

	/**
	 * @function
	 * @description handle navigation after execute activity
	 */
	const navigateAfterExecution = () => {
		if (!entityId) {
			push(
				{
					...location,
					pathname: `/${urlEntityType}/history`,
				},
				state
			);
		} else {
			push(
				{
					...location,
					pathname: `/${urlEntityType}/${entityId}/history`,
				},
				state
			);
		}
	};

	/**
	 * @function
	 * @description check activity execution is disable or not
	 * @returns {boolean}
	 */
	const isActivityExecutionDisabled = (): boolean => {
		try {
			return metaData.data
				? isSubCaseTransferredToCase(metaData.data)
				: false;
		} catch (error) {
			return false;
		}
	};

	return (
		<Formik initialValues={{}} onSubmit={() => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				setFieldValue,
				...rest
			}: any) => (
				<>
					<div className='dom-cas-header'>
						<$Skeleton
							loading={
								metaData.isLoading ||
								debtorWidgetData.isLoading
							}
							active
							paragraph={{
								rows: 0,
								width: 100,
							}}>
							<div className='d-flex align-items-center'>
								<span className='bui-label mr-2'>
									{t(
										'US.COLLECTION.LAYOUT:CASE.CASENO'
									)}
								</span>
								<strong>
									{caseNo}
								</strong>
								<$Divider
									type='vertical'
									className='bui-devider'
								/>
								<span className='bui-label mr-2'>
									{t(
										'US.COLLECTION.LAYOUT:CASE.DEBTOR'
									)}
								</span>
								<strong>{`${cid} - ${debFirstName} ${debLastName} `}</strong>
								<$Divider
									type='vertical'
									className='bui-devider'
								/>
								<span className='bui-label mr-2'>
									{t(
										'US.COLLECTION.LAYOUT:CASE.CREDITOR'
									)}
								</span>
								<strong>{`${pid} - ${credFirstName} ${credLastName} `}</strong>
								<$Divider
									type='vertical'
									className='bui-devider'
								/>
								<span className='bui-label mr-2'>
									{t(
										'US.COLLECTION.LAYOUT:CASE.KID'
									)}
								</span>
								<strong>
									{kid}
								</strong>{' '}
								<$Divider
									type='vertical'
									className='bui-devider'
								/>
								<ObsoleteDate />
							</div>
							<div className='d-flex align-items-center mt-2'>
								{/* {isItemAvailable(
									'registerWorkflow'
								) && (
									<$Tooltip
										placement='topLeft'
										title={t(
											'US.COLLECTION.LAYOUT:CASE.RETRY_WORKFLOW'
										)}>
										<$Button
											className='bui-btn-tool-icon '
											data-testid='dashboard-registerWorkFlow'
											disabled={
												retryWorkflow.isLoading ||
												(metaData.data &&
													isSubCaseTransferredToCase(
														metaData.data
													))
											}
											onClick={
												handleRetryWorkflow
											}>
											{!retryWorkflow.isLoading && (
												<BoxIcons
													type={
														IconTypes.BOX_ICON
													}
													name='register-workflow'
												/>
											)}
											{retryWorkflow.isLoading && (
												<LoadingOutlined
													spin
												/>
											)}
										</$Button>
									</$Tooltip>
								)} */}

								<$Tooltip
									placement='topLeft'
									title={t(
										'US.COLLECTION.LAYOUT:CASE.WORKFLOWSTATUSHISTORY'
									)}>
									<$Button
										className='bui-btn-tool-icon'
										data-testid='dashboard-workFlowStatusHistory'
										onClick={() => setWorkflowStatusHistoryVisible(true)}
										>
										<BoxIcons
											type={
												IconTypes.BOX_ICON
											}
											name='workflow-status-history'
										/>
									</$Button>
								</$Tooltip>


								{isItemAvailable(
									'migrateNewWorkflowVersion'
								) && (
									<$Tooltip
										placement='topLeft'
										title={t(
											'US.COLLECTION.LAYOUT:CASE.MIGRATENEWWORKFLOWVERSION'
										)}>
										<$Button
											className='bui-btn-tool-icon'
											data-testid='dashboard-migrateNewWorkFlowVersion'
											disabled={
												true
											}>
											<BoxIcons
												type={
													IconTypes.BOX_ICON
												}
												name='migrate-new-workflow'
											/>
										</$Button>
									</$Tooltip>
								)}
								{/* {isItemAvailable(
									'migrateNewWorkflowVersion'
								) && (
									<$Tooltip
										placement='topLeft'
										title={t(
											'US.COLLECTION.LAYOUT:CASE.REOPENCASE'
										)}>
										<$Button
											className='bui-btn-tool-icon'
											data-testid='dashboard-reopenCase'
											disabled={
												true
											}>
											<BoxIcons
												type={
													IconTypes.BOX_ICON
												}
												name='reopen-case'
											/>
										</$Button>
									</$Tooltip>
								)} */}
								{isItemAvailable(
									'closeCase'
								) && (
									<CloseCase />
								)}
								<ActivityExecution
									mode={
										Mode.Initial
									}
									isHideTable={
										false
									}
									isDisabled={isActivityExecutionDisabled()}
									isBulkExecutionEnabled={
										false
									}
									isGroupActivityOptions={
										false
									}
									isActivityOptionWithCode={
										true
									}
									isHideDatePicker={
										false
									}
									activityAPIRequest={{
										endPoint: `activities`,
										parameters: {
											entitytype: entityTypeShortForm,
											entitystate:
												workflowStatus,
											canExecute: true,
										},
									}}
									executeActivityEndPoint='activities'
									groupingProperty={{
										groupingLabel:
											'activityGroup',
										groupingValue:
											'activityGroupId',
										tableGrouping:
											'type',
									}}
									entityType={
										newEntityType
									}
									entityId={getEntityId(
										entityDetail
									)}
									isPopConfirmEnabled={
										true
									}
									propConfirmDetail={{
										title: 'ARE_YOU_SURE_YOU_WANT_TO_EXECUTE_THIS_ACTIVITY',
									}}
									navigateAfterExecution={
										navigateAfterExecution
									}
									isPopConfirmEnabledDropDown={
										false
									}
									isEventGrouping={true}
								/>
								<Handler />
								<div className='ml-auto d-flex'>
									{isItemAvailable(
										'addToFollowUp'
									) && (
										<AddToFollowUpButton
											selectedTab={
												EntityFollowUpTabs.FOLLOW_UP
											}
										/>
									)}
									<PendingActivityExecution />
								</div>
							</div>
						</$Skeleton>
					</div>
					<$Drawer
						title={t(
						"US.COLLECTION.LAYOUT:CASE.STATUS_HISTORY"
						)}
						width={1000}
						visible={isWorkflowStatusHistoryVisible}
						onClose={()=> setWorkflowStatusHistoryVisible(false)}
						destroyOnClose={true}
						className="workflow-status-history"
					>
						<WorkflowStatusHistory />
					</$Drawer>
				</>
			)}
		</Formik>
	);
};

const mapState = (state: RootState) => {
	const { dashboard, Workflow, domainView } = state;
	const { accountSummeryWidgetData, debtorWidgetData } = dashboard;
	const { metaData, workflow } = domainView;
	const { retryWorkflow } = Workflow;

	return {
		metaData,
		accountSummeryWidgetData,
		debtorWidgetData,
		retryWorkflow,
		workflow,
	};
};

const mapDispatch = {
	updateObsoleteDate: AddObsoletedate.update,
	retryWorkflowExecute: retryWorkflow.get,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Header);