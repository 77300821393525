import * as Actions from "./actions"
import { default as API } from "./services"
import * as Components from "./components"
import * as Layouts from "./layouts"
import * as Containers from "./containers"
import { default as Constants }  from "./constants"
import * as Reducers from "./reducers"
import { default as Routes } from "./routes"
import { default as Sagas } from "./sagas"


export default {
    Actions,
    API,
    Components,
    Layouts,
    Constants,
    Containers,
    Reducers,
    Routes,
    Sagas
}