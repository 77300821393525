import { put, call, takeLatest } from "redux-saga/effects";
import * as API from "us.collection.creditor/services";
import * as Actions from "us.collection.creditor/actions";
import { ManageCreditor } from "us.collection.creditor/constants/Actions/ManageCreditors";
import Common from "us.common";
import CommonAPI from "us.common/services";
import _ from "lodash";
import * as adminActions from "us.collection.admin/actions";

const { $MessageBox } = Common.Components;

const { manageCreditors, creditor } = Actions;

const {
  getBelongsToCreditor,
  addNewCreditor,
  getNextCreditorNo,
  validateCreditorNo,
  getCaseHandlerList,
  updateCreditor
} = API.manageCreditors.creditorInfo;

const {
  postalAreas,
  belongsToCreditor,
  newCreditor,
  nextCreditorNo,
  creditorNoValidateStatus,
  caseHandlers,
  existingCreditor
} = manageCreditors;

const ManageCreditorSagas = {
  manageCreditor: {
    getPostalAreas: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(CommonAPI.entityDetails.getPostelArea, params);
        if (!_.isEmpty(data)) {
          yield put(postalAreas.success(data));
        } else {
          $MessageBox(
            "error",
            `US.COLLECTION.VALIDATIONS:INVALID.PLEASE_SELECT_VALID_POSTAL_CODE`,
            "",
            ""
          );
          yield put(postalAreas.fail([]));
        }
      } catch (error) {
        yield put(postalAreas.fail([]));
        $MessageBox(
          "error",
          `US.COLLECTION.VALIDATIONS:INVALID.PLEASE_SELECT_VALID_POSTAL_CODE`,
          "",
          ""
        );
      }
    },
    getBelongsToCreditorInfo: function* (action: any) {
      const pId = action?.payload?.data ?? {};
      try {
        const creditorResults = yield call(getBelongsToCreditor, pId);
        if (creditorResults?.length === 0 || Object.keys(creditorResults).length === 0) {
          $MessageBox(
            "error",
            "US.COLLECTION.VALIDATIONS:INVALID.INVALID_CREDITOR_NO",
            "",
            ""
          );
          yield put(belongsToCreditor.fail(new Error()));
        } else {
          yield put(belongsToCreditor.success(creditorResults[0]));
            $MessageBox(
              "success",
              "US.COLLECTION.VALIDATIONS:INVALID.VALID_CREDITOR_NO",
              "",
              ""
            );
        }
      } catch (error) {
        yield put(belongsToCreditor.fail(new Error()));
        $MessageBox(
          "error",
          "US.COLLECTION.VALIDATIONS:INVALID.CREDITOR_NO_VALIDATION_FAILED",
          "",
          ""
        );
      }
    },
    addNewCreditor: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { newCreditorData, creditors } = yield call(addNewCreditor, params);
        if (newCreditorData.status == 200 || newCreditorData.status == 201) {
          yield put(newCreditor.success(newCreditorData.data));
          $MessageBox(
            "success",
            `US.COLLECTION.CREDITOR:CREDITOR_INFO.CREDITOR_CREATED_SUCCESSFULLY`,
            "",
            ""
          );
        } else if (newCreditorData?.data?.errorCode === 412) {
          yield put(newCreditor.fail([]));
          if(newCreditorData?.data?.message != '' || newCreditorData?.data?.message != null){
            $MessageBox(
              "error",
              `US.COLLECTION.CREDITOR:CREDITOR_INFO.${newCreditorData?.data?.message}`,
              "",
              ""
            );
          }
        } else {
          yield put(newCreditor.fail([]));
          $MessageBox(
            "error",
            `US.COLLECTION.CREDITOR:CREDITOR_INFO.CREDITOR_CREATION_FAILED`,
            "",
            ""
          );
        }
        if (creditors.status == 200 || creditors.status == 201) {
          yield put(adminActions.creditorGroup.selectedCreditors.success(creditors.data));
        }
      } catch (error) {
        yield put(newCreditor.fail([]));
        $MessageBox(
          "error",
          `US.COLLECTION.CREDITOR:CREDITOR_INFO.CREDITOR_CREATION_FAILED`,
          "",
          ""
        );
      }
    },
    getNextCreditorNo: function* (action: any) {
      try {
        const { nextCreditorNoData, caseHandlerData } = yield call(
          getNextCreditorNo
        );
        const { data, status } = nextCreditorNoData;
        if (status == 200) {
          yield put(nextCreditorNo.success(data?.creditorNo));
        } else {
          yield put(nextCreditorNo.fail([]));
        }
        if (
          caseHandlerData?.status == 200 &&
          caseHandlerData?.data?.length > 0
        ) {
          yield put(caseHandlers.success(caseHandlerData?.data));
        } else {
          yield put(caseHandlers.fail([]));
        }
      } catch (error) {
        yield put(nextCreditorNo.fail([]));
      }
    },
    validateNewCreditorNo: function* (action: any) {
      const creditorNo = action?.payload?.data ?? {};
      try {
        const validationResult = yield call(validateCreditorNo, creditorNo);
        const { data, status } = validationResult;
        if (status == 200) {
          if (data?.status) {
            $MessageBox(
              "error",
              "US.COLLECTION.CREDITOR:CREDITOR_INFO.CREDITOR_NO_ALREADY_EXIST",
              "",
              ""
            );
            yield put(creditorNoValidateStatus.fail(new Error()));
          } else {
            $MessageBox(
              "success",
              "US.COLLECTION.CREDITOR:CREDITOR_INFO.VALID_CREDITOR_NO",
              "",
              ""
            );
            yield put(
              creditorNoValidateStatus.success(data?.status)
            );
          }
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.CREDITOR:CREDITOR_INFO.CREDITOR_VALIDATION_FAILED",
            "",
            ""
          );
        }
      } catch (error) {
        yield put(creditorNoValidateStatus.fail(new Error()));
        $MessageBox(
          "error",
          "US.COLLECTION.CREDITOR:CREDITOR_INFO.CREDITOR_VALIDATION_FAILED",
          "",
          ""
        );
      }
    },
    getCaseHandlers: function* (action: any) {
      try {
        const { data, status } = yield call(
          getCaseHandlerList
        );
        if (status == 200) {
          yield put(caseHandlers.success(data));
        } else {
          yield put(caseHandlers.fail([]));
        }
      } catch (error) {
        yield put(caseHandlers.fail([]));
      }
    },
    updateExistingCreditor: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, creditorData } = yield call(updateCreditor, params);
        if(creditorData.status == 200 || creditorData.status == 201){
          yield put(creditor.general.success(creditorData.data))
        }
        if (data.status == 200 || data.status == 201) {
          yield put(existingCreditor.success(data.data));
          $MessageBox(
            "success",
            `US.COLLECTION.CREDITOR:CREDITOR_INFO.CREDITOR_UPDATED_SUCCESSFULLY`,
            "",
            ""
          );
        } else {
          yield put(existingCreditor.fail([]));
          $MessageBox(
            "error",
            `US.COLLECTION.CREDITOR:CREDITOR_INFO.CREDITOR_UPDATE_FAILED`,
            "",
            ""
          );
        }
      } catch (error) {
        yield put(existingCreditor.fail([]));
        $MessageBox(
          "error",
          `US.COLLECTION.CREDITOR:CREDITOR_INFO.CREDITOR_UPDATE_FAILED`,
          "",
          ""
        );
      }
    },
  },
};

export default [
  takeLatest(
    ManageCreditor.GET_POSTAL_AREAS_BY_CREDITOR,
    ManageCreditorSagas.manageCreditor.getPostalAreas
  ),
  takeLatest(
    ManageCreditor.GET_BELONGS_TO_CREDITOR,
    ManageCreditorSagas.manageCreditor.getBelongsToCreditorInfo
  ),
  takeLatest(
    ManageCreditor.ADD_NEW_CREDITOR,
    ManageCreditorSagas.manageCreditor.addNewCreditor
  ),
  takeLatest(
    ManageCreditor.GET_NEXT_CREDITOR_NO,
    ManageCreditorSagas.manageCreditor.getNextCreditorNo
  ),
  takeLatest(
    ManageCreditor.GET_CREDITOR_NO_VALIDATION_STATUS,
    ManageCreditorSagas.manageCreditor.validateNewCreditorNo
  ),
  takeLatest(
    ManageCreditor.GET_CASE_HANDLERS,
    ManageCreditorSagas.manageCreditor.getCaseHandlers
  ),
  takeLatest(
    ManageCreditor.UPDATE_NEW_CREDITOR,
    ManageCreditorSagas.manageCreditor.updateExistingCreditor
  ),
];
