import { i18n } from "us.helper";

/**
 * Check assets is mapped with Enforcement and add Map status and key for element
 * @param assetsList
 * @returns Return Assets with Map status
 */
export const addMapStatusAndKey = (assetsList: any) => {
  try {
    if (assetsList.length > 0) {
      return assetsList
        .sort((a: any, b: any) => b?.createdDate - a?.createdDate)
        .map((asset: any) => ({
          ...asset,
          key: `${asset.assetId}-${asset.assetType}`,
          status: `${
            asset.isEnforced
              ? i18n.t("US.COLLECTION.CASE:ASSETS.MAPPED")
              : i18n.t("US.COLLECTION.CASE:ASSETS.NOT_MAPPED")
          }`,
        }));
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};
