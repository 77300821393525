import { DrawerTypes } from "us.collection/constants/BMD";
import { IBMDList, IDrawerStatus } from "us.collection/interfaces/BMD";
import { appInsights } from "us.helper";
import { IAddBMDProperty } from "../Interfaces";

 /**
 * @function
 * @description - to check if the bmd key exists in the list  fetched
 * @param {IBMDList} { categories } - list of bmd categoried
 * @param {IAddBMDProperty} { keyName } - entered value for key in the form
 * @param {IDrawerStatus} drawerStatus - the drawer values from redux repo
 * @return {*}  {boolean} - if key is a previous existing value
 */
  export const doesKeyNameExist = (
  { categories }: IBMDList,
  { keyName }: IAddBMDProperty,
  drawerStatus: IDrawerStatus
): boolean => {
  try {
    return (
      drawerStatus.drawerType === DrawerTypes.ADD_PROPERTY &&
      categories.some((category) =>
        category.bmdGroups.some((bmdGrp) =>
          bmdGrp.propertyList.some((propItm) => propItm.keyName === keyName)
        )
      )
    );
  } catch (error) {
    appInsights.trackException(`Error in add BMD doesKeyNameExist function. - ${error}`);
    return false;
  }
};
