import moment from 'moment';
import { partPaymentType } from 'us.collection.partpayments/constants';
import { DateFormats } from 'us.common/constants';

export function GetPartPaymentBMDs(this: any) {
  return [
    {
      entityId: this.caseId,
      entityType: "CA",
      categoryName: "BasicData",
      bmdName: "PartPayment",
      displayKeyName: "Minimum",
    },
    {
      entityId: this.caseId,
      entityType: "CA",
      categoryName: "BasicData",
      bmdName: "PartPayment",
      displayKeyName: "MaxNoOfInstallment",
    },
    {
      entityId: this.caseId,
      entityType: "CA",
      categoryName: "BasicData",
      bmdName: "PartPayment",
      displayKeyName: "MinRemainingBalanceForCost",
    },
    {
      entityId: this.caseId,
      entityType: "CA",
      categoryName: "BasicData",
      bmdName: "PartPayment",
      displayKeyName: "MinInstallmentsForCost",
    },
    {
      entityId: Number(this.pid),
      entityType: "CR",
      categoryName: "Integrations",
      bmdName: "PartPaymentSettings",
      displayKeyName: "ForwardCharge",
    },
  ];
}
export function CreatePartPaymentRequest(
  partPaymentRequest: any,
  partPaymentFirstAPIResponse: any
) {
  const {
    caseId,
    InstallmentInterval,
    caseNote,
    communicationMethod,
    email,
    firstInstallmentDate,
    forwardCharge,
    hasPaymentAgreement,
    installmentAmount,
    partPaymentDate,
    minInstallmentsForCostBMD,
    remainingBalance,
    smsNumber,
    installmentCount,
    forwardChargOrCosts,
    writeToDB,
  } = partPaymentRequest;

  const { schemaList, isSaved } = partPaymentFirstAPIResponse;

  return {
    caseId,
    installmentAmount,
    partPaymentDate: moment(partPaymentDate).format(DateFormats.FORMAT_1),
    partPaymentType: partPaymentType.INSTALLMENT_AMOUNT,
    numberOfInstallment: isSaved ? 0 : schemaList?.length,
    email,
    smsNumber,
    InstallmentInterval,
    forwardCharge,
    hasPaymentAgreement,
    remainingBalance,
    isCaseCostAdded:
      minInstallmentsForCostBMD < Number(isSaved ? 0 : schemaList?.length) &&
      forwardChargOrCosts === 'costs',
    communicationMethod,
    firstInstallmentDate: moment(firstInstallmentDate).format(
      DateFormats.FORMAT_1
    ),
    installmentCount,
    caseNote,
    writeToDB,
  };
}
