export default {
	server: window._ENV.REACT_APP_API_BASE_URL,
	endPoints: {
		sentences: {
			sentencesList: '/sentences',
		},
		notes: {
			notesList: '/notes',
		},
		extendedField: {
			extendedFieldList: '/extendedField',
		},
		enforcement: {
			enforcements: '/Enforcement',
		},
		systemService: {
			CaseDetails: '/CaseDetails',
			ARsEntInfo: '/ARsEntInfo',
		},
		metadata: {
			metadata: '/system/domainView/metadata',
		},
		arService: {
			caseProperties: '/cases',
		},
	},
};

export * from './PaymentAgreementActions';
export * from './TransactionType';
export * from './ActivityExecution';
export * from './Workflow';
export * from './DomainView';
export * from './Test';
export * from './Handler';
export * from './BMD';
export * from './GDPRComment';
export * from './CaseView';
export * from './ARNotes';
export * from './CloseCase';
export * from './WorkFlowState';
export * from './FollowUps';
export * from './ActivityTable';
export * from './FollowupFooter';
