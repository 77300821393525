export enum ActivityType {
    All = 'ALL',
    Handling = "HANDLING",
    Other = "OTHER"
}
export enum ActivityTypeId {
    All = 1,
    Handling = 2,
    Other = 3
}
export const ActvityGroupProperty = "type"

export enum ActivityParametersState {
    DefaultState = "defaultState"
}
export enum ParameterType {
    INT = "int",
    DATE = "date",
    DATETIME = "datetime",
    BOOLEAN = "boolean",
    LIST = "List",
    STRING = "string",
    VARCHAR = "varchar"
}
export enum DefaultField {
    EntityNo = 'entityno',
    EntityId = "entityid",
    Id = "id",
    CaseNo = "caseno",
    CaseId = "caseid",
    ArNo = "arno",
    ArId = "arid",
    CreditorId = "creditorid",
    CreditorNo = "creditorno",
    User = "user",
    UserName = "username",
    PaymentId = "paymentid",
    PaymentNo = "paymentno"
}
export enum InitialField {
    Activity = "activity",
    ExecutingDateTime = "executingDateTime",
    ExecutingDateTimeWithParameter = "executingDateTimeWithParameter",
}
export enum ActivityQueue {
    Express = "Express"
}
export enum ExecutionSource {
    FollowUpBulkExecution = "FollowUpBulkExecution"
}
export enum BMDData {
    EntityType = 'BU',
    CategoryName = "Followup",
    BMDName = "FollowupService",
    DisplayKeyName = "MaxDirectActivityExecutionCount",
}


export enum Action {
    POSTPONE = "POSTPONE",
    REMOVE = "REMOVE",
    DONE = "DONE",
    UNDO = "UNDO"
}
