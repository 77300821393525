import { IInitialState } from 'us.collection.transactions/reducers/Transactions/Interfaces';
import { Transaction } from './Transactions';
import { ArDetails } from './ArDetails';
import { MenuItems } from './MenuItems';
import { defaultArTransactionDrawerSettings } from 'us.collection.transactions/constants';
import moment from 'moment';

export const initialState: IInitialState = {
	transactions: [],
	transactionDetails: Transaction,
	transactionTypes: [],
	mappingTransactions: [],
	popOverMenu: {
		items: MenuItems,
		visiblePopOver: -1,
		visibleDynamicPopOver: -1,
		isValuesLoading: false,
		isPopOverSaving: false,
		isLoading: false,
	},
	transactionDrawer: {
		title: 'Register Transaction',
		visible: false,
		isNewTransaction: true,
		isDeleteTransaction: false,
	},
	mappingTransactionDrawer: {
		title: 'Transaction Mapping',
		visible: false,
		isLinkTransaction: false,
	},
	editInterestDrawer: {
		title: 'Edit Interest',
		visible: false,
		isLinkTransaction: false,
	},
	arDetails: ArDetails,
	isArDetailsFetching: false,
	isFetching: false,
	isTransTypeFetching: false,
	isMappingTransFetching: false,
	isSaving: false,
	mapping: {
		transactions: {
			data: [],
			isLoading: false,
		},
		updatedTransactions: {
			data: null,
			isLoading: false,
		},
		defaultExpandedRowKeys: [],
		cases: {
			data: [],
			isLoading: false,
		},
	},
	drawerSettings: defaultArTransactionDrawerSettings,
	interestRateBMD: {
		value: 0,
		isLoading: false,
	},
	interestApplication: {
		interests: { data: [], isLoading: false },
		standardInterest: {
			data: { startDate: '', endDate: '', interestRate: 0 },
			isLoading: false,
		},
		interestRateUpdate: {
			data: null,
			isLoading: false,
		},
	},
};
