import { MessageTemplate } from "us.collection.case/constants";
import { IMessageTemplateItem } from "us.collection.case/interfaces/MessageTemplate/IMessageTemplate";
import { initialState } from "./State";

const { GET_MESSAGE_TEMPLATES_START,
  GET_MESSAGE_TEMPLATES_FAIL,
  GET_MESSAGE_TEMPLATES_SUCCESS,
  DELETE_MESSAGE_TEMPLATE_SUCCESS,
  MANAGE_PREVIEW_EDIT_DRAWER,
  GET_MESSAGE_TEMPLATE_BY_ID_START,
  GET_MESSAGE_TEMPLATE_BY_ID_SUCCESS,
  GET_MESSAGE_TEMPLATE_BY_ID_FAIL,
  MANAGE_SEND_EMAIL_DRAWER,
  EXECUTE_MESSAGE_TEMPLATE_FAIL,
  EXECUTE_MESSAGE_TEMPLATE_START,
  EXECUTE_MESSAGE_TEMPLATE_SUCCESS,
  SEND_EMAIL_START,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAIL,
  UPDATE_IFRAME_CONTENT } = MessageTemplate

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_MESSAGE_TEMPLATES_START:
      return Object.assign({}, state, {
        messageTemplates: {
          data: [],
          isLoading: true,
        },
      });
    case GET_MESSAGE_TEMPLATES_SUCCESS:
      return Object.assign({}, state, {
        messageTemplates: {
          data: payload.data,
          isLoading: false,
        },
      });
    case GET_MESSAGE_TEMPLATES_FAIL:
      return Object.assign({}, state, {
        messageTemplates: {
          data: [],
          isLoading: false,
        },
      });
    case DELETE_MESSAGE_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        messageTemplates: {
          data: state.messageTemplates.data.filter((messageTemplate: IMessageTemplateItem) => messageTemplate.id != payload.data.templateId),
          isLoading: false,
        },
      });
    case MANAGE_PREVIEW_EDIT_DRAWER:
      return Object.assign({}, state, {
        previewEditDrawerData: payload.data
      });

    case GET_MESSAGE_TEMPLATE_BY_ID_START:
      return Object.assign({}, state, {
        template: {
          communicationJobId: 0,
          content: [],
          isLoading: true,
        },
        tempalateUpdatedContent: []
      });
    case GET_MESSAGE_TEMPLATE_BY_ID_SUCCESS:
      const { communicationJobId, templateContent } = payload.data;
      return Object.assign({}, state, {
        template: {
          communicationJobId,
          content: templateContent,
          isLoading: false,
        },
        tempalateUpdatedContent: []
      });
    case GET_MESSAGE_TEMPLATE_BY_ID_FAIL:
      return Object.assign({}, state, {
        template: {
          communicationJobId: 0,
          content: [],
          isLoading: false,
        },
        tempalateUpdatedContent: []
      });
    case MANAGE_SEND_EMAIL_DRAWER:
      return Object.assign({}, state, {
        emailDrawerData: payload.data
      });
    case EXECUTE_MESSAGE_TEMPLATE_START:
      return Object.assign({}, state, {
        generateMessageData: {
          communicationJobId: 0,
          generatedMessgePath: '',
          isLoading: true
        }
      });
    case EXECUTE_MESSAGE_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        generateMessageData: {
          ...payload.data,
          isLoading: false
        }
      });
    case EXECUTE_MESSAGE_TEMPLATE_FAIL:
      return Object.assign({}, state, {
        generateMessageData: {
          communicationJobId: 0,
          generatedMessgePath: '',
          isLoading: false
        }
      });
    case SEND_EMAIL_START:
      return Object.assign({}, state, {
        email: {
          isLoading: true
        }
      });
    case SEND_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        email: {
          isLoading: false
        }
      });
    case SEND_EMAIL_FAIL:
      return Object.assign({}, state, {
        email: {
          isLoading: false
        }
      });
    case UPDATE_IFRAME_CONTENT:
      return Object.assign({}, state, {
        tempalateUpdatedContent: payload.data
      });
    default:
      return state;
  }
};
