import Constants from 'us.collection.case/constants';
import { GetEntityDetailsForCOP } from 'us.collection.case/functions';
import { IObjectionCancellation } from 'us.collection.case/reducers/Dashboard/Interfaces';
import { initialState } from 'us.collection.case/reducers/Dashboard/State';

const { ACTIONS } = Constants;
const { ObjectionAndAgreements } = ACTIONS;
const {
	CANCEL_OBJECTION_SUCCESS,
	CANCEL_OBJECTION_FAIL,
	CANCEL_OBJECTION_START,

	CLAIM_OF_PAYMENT_START,
	CLAIM_OF_PAYMENT_SUCCESS,
	CLAIM_OF_PAYMENT_FAIL,

	GET_COP_INITIAL_VALUE,
	GET_INVOICES_START,
	GET_INVOICES_SUCCESS,
	GET_INVOICES_FAIL,

	DEBTOR_OBJECTION_START,
	DEBTOR_OBJECTION_SUCCESS,
	DEBTOR_OBJECTION_FAIL,

	GET_DO_INITIAL_VALUE,
	UPDATE_DRAWER,

	GET_OBJECTION_PROFILE_START,
	GET_OBJECTION_PROFILE_SUCCESS,
	GET_OBJECTION_PROFILE_FAIL,

	GET_KID_LIST,
	GET_KID_LIST_SUCCESS,
	GET_KID_LIST_FAIL,
} = ObjectionAndAgreements;

export default (state: any = initialState, { payload, type }: any) => {
	switch (type) {
		case CLAIM_OF_PAYMENT_START:
			return Object.assign({}, state, {
				claimOfPayment: {
					data: undefined,
					isError: false,
					isLoading: true,
				},
				isFetching: true,
			});

		case CLAIM_OF_PAYMENT_SUCCESS:
			return Object.assign({}, state, {
				claimOfPayment: {
					data: undefined,
					isError: false,
					isLoading: false,
				},
				isFetching: false,
			});

		case CLAIM_OF_PAYMENT_FAIL:
			return Object.assign({}, state, {
				claimOfPayment: {
					data: undefined,
					isError: false,
					isLoading: false,
				},
				isFetching: false,
			});

		case GET_COP_INITIAL_VALUE:
			const { entity, caseProps, caseId } = payload?.data;
			const { debtorSMS, debtorEmail, creditorName } =
				GetEntityDetailsForCOP(entity);
			const invoiceNumber = caseProps?.find(
				(caseType: any) =>
					caseType.displayName == 'InvoiceNo'
			);

			return Object.assign({}, state, {
				claimOfPaymentInitial: {
					...initialState.claimOfPaymentInitial,
					paidTo: '1',
					caseId,
					smsNumber: debtorSMS?.number,
					smsInitial: debtorSMS?.number,
					creditorName,
					invoiceNumber:
						typeof invoiceNumber ==
						'undefined'
							? 0
							: invoiceNumber.value,
					emailAddress: debtorEmail,
					emailInitial: debtorEmail,
				},
				isFetching: false,
			});

		case GET_INVOICES_START:
			return Object.assign({}, state, {
				invoices: undefined,
				isFetching: true,
			});

		case GET_INVOICES_SUCCESS:
			let invoiceNum = 0;
			const invoicesList = payload?.data?.map((item: any) => {
				invoiceNum =
					payload?.data?.length === 1
						? item.subCaseNumber
						: 0;
				const {
					invoiceRef,
					kid,
					mainAmount,
					balance,
					subCaseNumber,
				} = item;
				return {
					key: subCaseNumber,
					invoiceNo: invoiceRef,
					kid,
					amount: mainAmount,
					paid: mainAmount - balance,
					balance,
					isRowSelected:
						payload?.data?.length == 1
							? true
							: false,
				};
			});
			return Object.assign({}, state, {
				claimOfPaymentInitial: {
					...state.claimOfPaymentInitial,
					invoiceNumber: invoiceNum,
					invoicetable: invoicesList,
				},
				debtorObjectionInitial: {
					...state.debtorObjectionInitial,
					invoiceNumber: invoiceNum,
					invoicetable: invoicesList,
				},
				isFetching: false,
			});

		case GET_INVOICES_FAIL:
			return Object.assign({}, state, {
				isFetching: false,
			});

		case UPDATE_DRAWER:
			return Object.assign({}, state, {
				drawer: {
					...state.drawer,
					...payload.data,
				},
				isFetching: true,
			});
		case DEBTOR_OBJECTION_START:
			return Object.assign({}, state, {
				debtorObjection: {
					data: undefined,
					isError: false,
					isLoading: true,
				},
				isFetching: true,
			});

		case DEBTOR_OBJECTION_SUCCESS:
			return Object.assign({}, state, {
				debtorObjection: {
					data: undefined,
					isError: false,
					isLoading: false,
				},
				isFetching: false,
			});

		case DEBTOR_OBJECTION_FAIL:
			return Object.assign({}, state, {
				debtorObjection: {
					data: undefined,
					isError: false,
					isLoading: false,
				},
				isFetching: false,
			});

		case GET_DO_INITIAL_VALUE:
			const { debtor } = payload?.data;
			const debtorSMS_ = debtor?.phoneNumbers.find(
				(x: any) => x.type === 'sms'
			);
			const debtorEmail_ = debtor?.email;
			const debtorName = `${debtor?.firstName} ${debtor?.lastName}`;
			const invoiceNumber_ = payload?.data?.caseProps?.find(
				(caseType: any) =>
					caseType.displayName == 'InvoiceNo'
			);

			return Object.assign({}, state, {
				debtorObjectionInitial: {
					...initialState.debtorObjectionInitial,
					caseId: payload?.data?.caseId,
					debtorName,
					smsNumber: debtorSMS_?.number,
					emailAddress: debtorEmail_,
					smsInitial: debtorSMS_?.number,
					emailInitial: debtorEmail_,
					invoiceNumber:
						typeof invoiceNumber_ ==
						'undefined'
							? 0
							: invoiceNumber_.value,
				},
				isFetching: false,
			});
		case GET_OBJECTION_PROFILE_START:
			return Object.assign({}, state, {
				isFetching: true,
			});

		case GET_OBJECTION_PROFILE_SUCCESS:
			const reasonArray: Array<any> = [];
			if (payload?.data) {
				let categoryName = '';
				payload.data.map((profile: any) => {
					profile.objectionCategoryList.map(
						(categoryItem: any) => {
							const {
								reasonList,
								category,
							} = categoryItem;
							reasonList.map(
								(item: any) => {
									if (
										categoryName ===
										category
									) {
										reasonArray.push(
											{
												...item,
												category,
												isCategoryNameShow:
													false,
											}
										);
									} else {
										categoryName =
											category;
										reasonArray.push(
											{
												...item,
												category,
												isCategoryNameShow:
													true,
											}
										);
									}
								}
							);
						}
					);
				});
			}
			return Object.assign({}, state, {
				debtorObjectionInitial: {
					...state.debtorObjectionInitial,
					objectionReasonList: reasonArray,
				},
				isFetching: false,
			});

		case GET_OBJECTION_PROFILE_FAIL:
			return Object.assign({}, state, {
				isFetching: false,
			});

		case GET_KID_LIST:
			return Object.assign({}, state, {
				isFetching: true,
			});
		case GET_KID_LIST_SUCCESS:
			const kidNumberList = payload.data.map(
				(profile: any) => {
					return { value: profile };
				}
			);
			return Object.assign({}, state, {
				claimOfPaymentInitial: {
					...state.claimOfPaymentInitial,
					kidNumberList,
				},
				isFetching: false,
			});
		case GET_KID_LIST_FAIL:
			return Object.assign({}, state, {
				isFetching: false,
			});
		case CANCEL_OBJECTION_START:
			return Object.assign({}, state, {
				cancelObjection: <IObjectionCancellation>{
					data: undefined,
					isLoading: true,
					error: undefined,
				},
			});

		case CANCEL_OBJECTION_SUCCESS:
			return Object.assign({}, state, {
				cancelObjection: <IObjectionCancellation>{
					data: payload.data,
					isLoading: false,
					error: undefined,
				},
			});

		case CANCEL_OBJECTION_FAIL:
			return Object.assign({}, state, {
				cancelObjection: <IObjectionCancellation>{
					data: payload.data,
					isLoading: false,
					error: undefined,
				},
			});

		default:
			return state;
	}
};
