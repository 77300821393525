import { EntityType } from "us.collection/components/BMD/Constants";
import { IDrawerStatus } from "us.collection/interfaces";

/**
 * @function
 * @description - Function to determine if there are any over-rides to the value
 * @param {EntityType} entityType - the route type/ tab type
 * @param {IDrawerStatus} drawerStatus - the drawer redux state value
 * @return {*}  {boolean} - decisition if value has any overrides
 */
export const hasOverRides = (entityType: EntityType, drawerStatus: IDrawerStatus): boolean => {
    const { caseCount, arCount, creditorCount, groupCount } = drawerStatus.recordData ?? {};
    switch (entityType) {
      case EntityType.BUREAU:
        return (
          (caseCount !== undefined && caseCount > 0) ||
          (arCount !== undefined && arCount > 0) ||
          (creditorCount !== undefined && creditorCount > 0) ||
          (groupCount !== undefined && groupCount > 0)
        );
      case EntityType.CREDITOR_GROUP:
        return (
          (caseCount !== undefined && caseCount > 0) ||
          (arCount !== undefined && arCount > 0) ||
          (creditorCount !== undefined && creditorCount > 0)
        );
      case EntityType.CREDITOR:
        return (caseCount !== undefined && caseCount > 0) || (arCount !== undefined && arCount > 0);
      case EntityType.AR:
        return caseCount !== undefined && caseCount > 0;
      default:
        return false;
    }
  };