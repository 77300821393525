import { CloseCaseAction } from 'us.collection/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as API from 'us.collection/services';
import { CloseCase, RegisteredUserType, LevelType, ActivityStatusForPayload, ActivityTypesForPayload } from 'us.collection/constants';
import { $MessageBox } from 'us.common/components';
import * as Actions from 'us.collection.case/actions';
import { CaseType } from 'us.helper/types/enums';
import * as ActivityActions from 'us.collection.routines-and-activities/actions';

const { reasonList, closeCase } = API.CloseCase;

const { AccountSummeryWidgetActions } = Actions;
const { historyAction } = ActivityActions;

const { logHistory } = historyAction;
const { reasons, closeCase: saveCloseCase } = CloseCaseAction;
const { accountSummery: acSummeryActions } = AccountSummeryWidgetActions;

const CloseReasons = {
	get: function* (action: any) {
		const params = action.payload?.data;
		try {
			const { data, status } = yield call(
				reasonList.get,
				params
			);
			if (status === 200) {
				yield put(reasons.success(data));
			} else {
				yield put(reasons.fail(data));
			}
		} catch (error) {
			yield put(reasons.fail(error as any));
		}
	},
};
const Close = {
	save: function* (action: any) {
		const params = action.payload?.data;
		try {
			const { data, status } = yield call(
				closeCase.save,
				params
			);
			if (status === 200 && !data?.isError) {
				$MessageBox(
					'success',
					'US.COLLECTION.LAYOUT:CASE.CASE_CLOSE_SUCCESSFULLY',
					'',
					''
				);
				yield put(saveCloseCase.success(data));
				if (acSummeryActions.get) {
					yield put(
						acSummeryActions.get({
							EntityType:
								params.entityType ===
								CaseType.S
									? 'Subcase'
									: LevelType.CASE,
							EntityId: params.caseId,
						})
					);
				}
				if (logHistory.get) {
					yield put(
						logHistory.get({
							entityId: params.caseId,
							entityType: params.entityType,
							activityType: ActivityTypesForPayload.MESSAGE,
							activityStatus: ActivityStatusForPayload.SUCCESS,
							registeredUserType: RegisteredUserType.ALL,
						})
					);
				}
			} else {
				$MessageBox(
					'error',
					'US.COLLECTION.LAYOUT:CASE.CASE_CLOSE_FAIL',
					'',
					''
				);
				yield put(saveCloseCase.fail(data));
			}
		} catch (error) {
			yield put(saveCloseCase.fail(error as any));
		}
	},
};

export default [
	takeLatest(CloseCase.GET_CLOSE_CASE_START, CloseReasons.get),
	takeLatest(CloseCase.CLOSE_CASE_START, Close.save),
];
