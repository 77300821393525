import { ICaseInformation } from 'us.collection.debtor/Interfaces'

export const caseList: ICaseInformation[] = [{
    caseState: -1,
    cid: 0,
    pid: 0,
    caseNumber: 0,
    caseId: 0,
    caseType: '',
    mainAmount: '',
    collectionFee: '',
    courtFee: '',
    caseCost: '',
    otherCost: '',
    interestAmount: '',
    paidAmount: '',
    balance: '',
    creditorName: '',
    caseHandler: ''
}]