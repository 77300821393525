import moment from "moment";
import { getFollowUpDataSet } from "us.collection.case/functions";
import { ICategory, IReason } from "us.collection.case/interfaces";
import { DateFormat } from "us.collection.case/constants";

export function SaveManualFollowUp(
    this: any,
    followupName: string,
    reasons: Array<IReason>,
    entityId?: number,
    entityType?: string
) {
    const { date, note, assign, reason } = this;
    return {
        followupName,
        entityId,
        entityType,
        followupDataSet: [
            ...getFollowUpDataSet({
                reason: reasons.find((item: IReason) => item.reasonId === reason)
                    ?.reasonName,
                dueDate: moment(date).format(DateFormat),
                assign,
                note,
            }),
            {
                name: "source",
                value: "User Added",
            },

            {
                name: "action",
                value: 1,
            },
        ],
    };
}
