import * as Yup from 'yup';
import { InterestType } from 'us.collection.transactions/constants';

export default Yup.object<any>().shape({
	interestType: Yup.string().required(),
	interestRate: Yup.number()
		.nullable()
		.max(
			100,
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
		)
		.when('interestType', {
			is: (interestType) => interestType == InterestType.NO_INTEREST,
			then: Yup.number().notRequired(),
			otherwise: Yup.number()
				.required(
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
				)
				.test(
					'digit',
					'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
					(val: any) => {
						return val > 0;
					}
				),
		}),
});
