import { IInitialState } from "us.collection.case/reducers/Enforcement/Interfaces";
import { accountSummary } from "./AccountSummary";
import { enforcement } from "./Enforcement";
import { salaryDeductionPlan } from "./SalaryDeductionPlan";

export const initialState: IInitialState = {
  enforcements: {
    data: [],
    dataSource: [],
    isFetching: false,
  },
  enforcementDetail: {
    data: enforcement,
    isFetching: false,
  },
  enforcementTypes: {
    data: [],
    isFetching: false,
  },
  salaryDeductionPlan: {
    data: salaryDeductionPlan,
    isFetching: false,
    isSet: false,
  },
  deletedSalaryDeductionPlan: {
    data: salaryDeductionPlan,
  },
  selectedEnforcementItem: {
    data: {},
    index: -1,
    isFetching: false,
  },
  drawer: {
    title: "",
    visible: false,
    isNewEnforcement: true,
    isEmployerEnforced: false,
  },
  accountSummary: {
    data: accountSummary,
    isFetching: false,
  },
};
