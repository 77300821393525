import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
	$Affix,
	$Drawer,
	$Popover,
	$Popconfirm,
	$Select,
	$PageHeader,
	$Divider,
	$Button,
} from 'us.common/components';
import {
	DeleteOutlined,
	PlusOutlined,
	EditOutlined,
	MoreOutlined,
} from 'us.icons';
import { AddNote } from './Components';
import { ARNoteAction } from 'us.collection/actions';
import {
	$DateLabel,
	$Skeleton,
	$TableTree,
	IOnFilter,
	IOnSort,
} from 'us.common/components';
import { matchPath } from 'react-router-dom';
import { INotes } from './Intefaces';
import { filterNote, isEditUserNote } from './Functions';
import { EntityType, RegisteredUserType } from 'us.collection/constants';
import { INoteHistory } from 'us.collection/reducers/ARNotes/Interfaces';
import * as Azure from 'us.helper/azure';
import { RootState } from 'us.helper/types';

const { arNoteHistory, addArNote, deleteArNote } = ARNoteAction;

const Notes: React.FC<INotes> = (props) => {
	const { t } = useTranslation();

	const {
		summery,
		getNoteHistory,
		openDrawer,
		noteDrawer,
		deleteNote,
		history,
		location,
		entityId,
		entityType,
		metaData,
	} = props;

	const { creditorId } = metaData.data ?? {};

	const filterOption = [
		{
			label: t('US.COLLECTION.COMMON:COMMON.ALL'),
			value: RegisteredUserType.ALL,
		},
		{
			label: t('US.COLLECTION.COMMON:COMMON.SYSTEM'),
			value: RegisteredUserType.SYSTEM,
		},
		{
			label: t('US.COLLECTION.COMMON:COMMON.USER'),
			value: RegisteredUserType.USER,
		},
	];

	let isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
	let auth: any;
	if (isAzureAD) {
		auth = new Azure.ADAuth();
	} else {
		auth = new Azure.B2CAuth();
	}
	const azureName: any = auth.currentUser();

	const [editNoteContent, setEditNoteContent] = useState({});
	const [popVisible, setPopVisible] = useState<number>(-1);

	useEffect(() => {
		if (entityId > 0) {
			getNoteHistory &&
				getNoteHistory({
					entityId:
						entityType ===
						EntityType.CREDITOR
							? creditorId
							: entityId,
					entityType,
					registeredUserType: RegisteredUserType.ALL,
				});
		}
	}, [creditorId]);

	/**
	 * Close drawer
	 */
	const onClose = () => {
		//
		openDrawer &&
			openDrawer({
				title: '',
				visible: false,
				isEdit: false,
			});
	};

	/**
	 * Open add note drawer
	 */
	const handleAddNote = () => {
		openDrawer &&
			openDrawer({
				title: t('US.COLLECTION.CASE:NOTES.NEW_NOTE'),
				visible: true,
				isEdit: false,
			});
	};

	/**
	 * Navigate dashboard
	 */
	const navigate = (): void => {
		const { params }: any = matchPath(location.pathname, {
			path: '/:caseType/:arId/notes',
			exact: true,
			strict: false,
		});
		const arNo = params['arId'];
		const caseType = params['caseType'];
		if (arNo > 0) {
			history.push(`/${caseType}/${arNo}`, location.state);
		}
	};

	/**
	 * Edit note
	 * @param record edit note details
	 */
	const editNote = (record: INoteHistory) => {
		setEditNoteContent(record);
		openDrawer &&
			openDrawer({
				title:
					record.registeredUserType === RegisteredUserType.SYSTEM ||
					!isEditUserNote(
						record,
						azureName?.unique_name
					)
						? t(
								'US.COLLECTION.CASE:NOTES.VIEW_NOTE'
						  )
						: t(
								'US.COLLECTION.CASE:NOTES.EDIT_NOTE'
						  ),
				visible: true,
				isEdit: true,
			});
	};

	/**
	 * Delete note
	 * @param record delete note details
	 */
	const handleArDelete = (record: INoteHistory) => {
		if (entityId > 0) {
			deleteNote &&
				deleteNote({
					noteId: record?.noteId,
					entityType,
					entityId,
					creditorId,
				});
		}
	};

	const handleSort: IOnSort = (sortData, data) => {
		return sortData(data);
	};

	const handleFilter: IOnFilter = (searchData, data) => {
		return searchData(data);
	};

	const content = (record: INoteHistory) => (
		<div className='table-more-content'>
			<div
				className='d-flex  flex-row more-item'
				onClick={() => {
					setPopVisible(-1);
					editNote(record);
				}}>
				<div className='p-1'>
					<EditOutlined />
				</div>
				<div className='p-1'>
					{t('US.COLLECTION.COMMON:COMMON.EDIT')}
				</div>
			</div>
			<$Popconfirm
				title={t(
					'US.COLLECTION.CASE:NOTES.DO_YOU_WANT_TO_DELETE_THIS_NOTE?'
				)}
				onConfirm={() => {
					setPopVisible(-1);
					handleArDelete(record);
				}}
				okText={t('US.COLLECTION.COMMON:COMMON.YES')}
				cancelText={t('US.COLLECTION.COMMON:COMMON.NO')}
				onCancel={() => setPopVisible(-1)}>
				<div className='d-flex mb-2 flex-row more-item text-error'>
					<div className='p-1'>
						<DeleteOutlined />
					</div>
					<div className='p-1'>
						{t(
							'US.COLLECTION.COMMON:COMMON.DELETE'
						)}
					</div>
				</div>
			</$Popconfirm>
		</div>
	);

	const columns: any = [
		{
			title: '',
			key: 'more',
			dataIndex: 'more',
			width: '50px',
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return (
					<>
						{isEditUserNote(
							record,
							azureName?.unique_name
						) && (
							<$Popover
								placement='rightTop'
								content={content(
									record
								)}
								destroyTooltipOnHide
								trigger='click'
								visible={
									record.noteId ==
									popVisible
								}
								onVisibleChange={(
									visible: boolean
								) => {
									setPopVisible(
										visible
											? record.noteId
											: -1
									);
								}}>
								<$Button
									disabled={
										record.registeredUserType ===
										RegisteredUserType.SYSTEM
									}
									icon={
										<MoreOutlined />
									}
									size='small'
								/>
							</$Popover>
						)}
						{(record.registeredUserType ===
							RegisteredUserType.SYSTEM ||
							!isEditUserNote(
								record,
								azureName?.unique_name
							)) && (
							<$Button
								disabled={
									record.registeredUserType ===
									RegisteredUserType.SYSTEM ||
									!isEditUserNote(
										record,
										azureName?.unique_name
									)
								}
								icon={
									<MoreOutlined />
								}
								size='small'
							/>
						)}
					</>
				);
			},
		},
		{
			title: t('US.COLLECTION.CASE:NOTES.REG_DATE'),
			dataIndex: 'createdDate',
			key: 'createdDate',
			width: '200px',
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return (
					<$DateLabel
						value={record?.createdDate}
					/>
				);
			},
		},
		{
			title: t('US.COLLECTION.CASE:NOTES.REG_BY'),
			dataIndex: 'createrName',
			key: 'createrName',
			width: '250px',
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: true,
		},
		{
			title: t('US.COLLECTION.CASE:NOTES.TEXT'),
			dataIndex: 'noteText',
			key: 'noteText',
			ellipsis: {
				showTitle: false,
			},
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: true,
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return (
					<$Popover
						placement='top'
						content={record?.noteText}>
						{record?.noteText}
					</$Popover>
				);
			},
		},
	];

	return (
		<Formik
			initialValues={{
				category: RegisteredUserType.ALL,
			}}
			enableReinitialize
			onSubmit={() => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...restProps
			}: any) => (
				<>
					<div className='space-content'>
						<$Affix offsetTop={47}>
							<div className='page-header header-border'>
								<div className='d-flex flex-row align-items-center justify-content-between'>
									<div className='d-flex align-items-center'>
										<$PageHeader
											className='px-0'
											title={t(
												'US.COLLECTION.CASE:NOTES.NOTES'
											)}
											onBack={
												navigate
											}
											data-testid='note-page=header'
										/>
										<$Divider
											className='bui-devider'
											type='vertical'
										/>
										<$Button
											type='default'
											size='small'
											className='mr-2'
											icon={
												<PlusOutlined />
											}
											onClick={() =>
												handleAddNote()
											}
											data-testid='add-new-note-button'>
											{t(
												'US.COLLECTION.CASE:NOTES.ADD_NOTE'
											)}
										</$Button>
									</div>
									<div>
										<$Select
											name='category'
											allOption={
												false
											}
											size='small'
											defaultValue={
												RegisteredUserType.ALL
											}
											style={{
												width: '200px',
											}}
											options={
												filterOption
											}
											data-testid='note-filter'
										/>
									</div>
								</div>
							</div>
						</$Affix>
						<div>
							<$Skeleton
								loading={
									summery?.isLoading
								}
								active
								paragraph={{
									rows: 2,
								}}>
								<$TableTree
									rowKey='noteId'
									data={filterNote(
										summery?.data,
										values
									)}
									size='small'
									className='mt-3'
									onSort={
										handleSort
									}
									onFilter={
										handleFilter
									}
									filterOnType={
										true
									}
									resetOnSourceChange={
										true
									}
									bordered
									pagination={{
										defaultPageSize: 20,
									}}
									scroll={{
										x: 1200,
									}}
									columns={
										columns
									}
									onRow={(
										record: any,
										rowIndex: any
									) => {
										return {
											onDoubleClick:
												() => {
													editNote(
														record
													);
												},
										};
									}}
									data-testid='note-table'
								/>
							</$Skeleton>
						</div>
					</div>

					<$Drawer
						title={noteDrawer.title}
						width={'840'}
						visible={noteDrawer.visible}
						onClose={() => onClose()}
						destroyOnClose>
						<AddNote
							editNoteContent={
								editNoteContent
							}
							entityId={entityId}
							entityType={entityType}
						/>
					</$Drawer>
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { ArNotes, domainView } = state;
	const { summery, noteDrawer } = ArNotes;
	const { metaData } = domainView;
	return {
		summery,
		noteDrawer,
		metaData,
	};
};

const mapDispatchToProps = {
	getNoteHistory: arNoteHistory.get,
	openDrawer: addArNote.openDrawer,
	deleteNote: deleteArNote.save,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
