import { SentneceDrawerStatus } from "us.collection.case/constants";
import { ISentence } from "us.collection.case/interfaces";

/**
 * To get the initial values for the Formik form
 * @param mainAmount main amount
 * @param caseCost case cost
 * @param collectionFee collection fee
 * @param courtFee court fee
 * @param interest interest
 * @param interestCollection interest collection
 * @param otherCosts other costs
 * @param caseId case id
 * @param drawerStatus drawer status
 * @param sentenceDetails sentence details
 * @returns returns the ISentence type object 
 */
export const getInitialValues = (
  mainAmount: number,
  caseCost: number,
  collectionFee: number,
  courtFee: number,
  interest: number,
  interestCollection: number,
  otherCosts: number,
  caseId: number | undefined,
  drawerStatus: SentneceDrawerStatus | undefined,
  sentenceDetails: ISentence | undefined
): ISentence => {
  if (drawerStatus === SentneceDrawerStatus.EDIT_SENTENCE_OPEN && sentenceDetails) {
    return sentenceDetails;
  }
  return {
    id: 0,
    caseId: caseId ?? 0,
    collectionFee: collectionFee ?? 0,
    collectionFeeFromDate: null,
    collectionFeeRate: null,
    continueType: "Manual",
    caseCost: caseCost ?? 0,
    caseCostFromDate: null,
    caseCostRate: null,
    courtFee: courtFee ?? 0,
    courtRef: "",
    createdUser: "",
    declarationDate: null,
    dueDate: null,
    interestCollectionFee: interestCollection ?? 0,
    interestCollectionFeeRate: null,
    interestCollectionFeeTo: null,
    isDelete: false,
    limitDate: null,
    mainAmount: mainAmount ?? 0,
    mainAmountFromDate: null,
    mainAmountRate: null,
    mainAmountInterest: interest ?? 0,
    mainAmountInterestRate: null,
    mainAmountInterestToDate: null,
    modifiedUser: "",
    otherCosts: otherCosts ?? 0,
    otherCostsFromDate: null,
    otherCostsRate: null,
    regDate: null,
    sentenceDate: null,
    sentenceNote: "",
    sentenceTypeId: null,
  };
};
