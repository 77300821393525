import { ILinkedCreditor } from "us.collection/interfaces";

export const getCreditors = (linkedCreditors: any): ILinkedCreditor[] => {
  if (Array.isArray(linkedCreditors)) {
    return linkedCreditors.map(
      ({
        creditorInkassoId,
        name,
        address1,
        address2,
        address3,
        zipCode,
        zipName,
        countryId,
        groupId,
        groupName,
        remitAccountNo,
        companyID,
      }: any) => {
        return {
          id: creditorInkassoId,
          pid: creditorInkassoId,
          creditorId: creditorInkassoId,
          creditorName: name,
          address1,
          address2,
          address3,
          zipCode,
          zipName,
          countryId,
          groupId,
          groupName,
          remitAccountNo,
          companyID,
          key: creditorInkassoId,
        };
      }
    );
  }
  return [];
};
