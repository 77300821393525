import { IBMDListFrom } from "../Interfaces";
import { IBMDCategory } from "us.collection/interfaces";
import { appInsights } from "us.helper";
import { ALL_CATEGORY } from "us.collection/constants";
import { DEFAULT_GROUP } from "us.collection/components/BMD/Constants";

/**
 * To get the initial values for the bmd list form
 * @param categories The current category
 * @returns Initial vales for the bmd list form
 */
export const getBMDListInitialValues = (
  categories: IBMDCategory[],
  selectedBMDGroupId: number,
  selectedCategoryID: number,
  bmdSearch: string,
  initialPropertyCheck: boolean
): IBMDListFrom => {
  try {
    if (selectedCategoryID && selectedBMDGroupId) {
      return {
        bmdGroup: selectedBMDGroupId > 0 ? categories
          .find((cat) => cat.id === selectedCategoryID)
          ?.bmdGroups.find((bmdG) => bmdG.id === selectedBMDGroupId)?.name : DEFAULT_GROUP,
        selectedCategory: categories.find((cat) => cat.id === selectedCategoryID),
        bmdSearch,
        selectedKey: "1",
        initialPropertyCheck,
      };
    } else {
      return {
        bmdGroup: DEFAULT_GROUP,
        selectedCategory: ALL_CATEGORY,
        bmdSearch: "",
        selectedKey: "1",
        initialPropertyCheck: false,
      };
    }
  } catch (error) {
    appInsights.trackException(`Error in BMD home getBMDListInitialValues function. - ${error}`);
    return {
      bmdGroup: "",
      selectedCategory: undefined,
      bmdSearch: "",
      selectedKey: "1",
      initialPropertyCheck: false,
    };
  }
};
