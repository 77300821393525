import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { $Skeleton, $Divider } from 'us.common/components';
import './Header.scss';
import { RootState } from 'us.helper/types';

const Header: React.FC<PropsFromRedux & RouteComponentProps> = (props) => {
	const { t } = useTranslation();
	const { creditorGroupDetails } = props;

	return (
		<div className='dom-creditor-group-header'>
			<$Skeleton
				loading={!creditorGroupDetails.data && true}
				active
				paragraph={{ rows: 0, width: 100 }}>
				<div className='d-flex flex-row'>
					<div className='my-1'>
						<span className='bui-label mr-2'>
							{t(
								'US.COLLECTION.CREDITOR:HEADER.GROUP_NO'
							)}
						</span>
						<strong>
							{
								creditorGroupDetails
									.data
									?.id
							}
						</strong>
						<$Divider
							type='vertical'
							className='bui-devider'
						/>
					</div>

					<div className='my-1'>
						<span className='bui-label mr-2'>
							{t(
								'US.COLLECTION.CREDITOR:HEADER.GROUP_NAME'
							)}
						</span>
						<strong>
							{
								creditorGroupDetails
									.data
									?.groupName
							}
						</strong>
						<$Divider
							type='vertical'
							className='bui-devider'
						/>
					</div>

					<div className='my-1'>
						<span className='bui-label mr-2'>
							{t(
								'US.COLLECTION.CREDITOR:HEADER.DESCRIPTION'
							)}
						</span>
						<strong>
							{creditorGroupDetails
								.data
								?.description
								? creditorGroupDetails
										.data
										?.description
								: '--'}
						</strong>
						<$Divider
							type='vertical'
							className='bui-devider'
						/>
					</div>
				</div>
			</$Skeleton>
		</div>
	);
};

const mapStateToProps = (state: RootState) => {
	const { creditorGroup } = state;
	const { creditorGroupDetails } = creditorGroup;

	return {
		creditorGroupDetails,
	};
};
const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Header);
