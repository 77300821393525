import {
  IEventDrawer,
  IEvents,
  IStateList,
  IWorkflowDrawer,
  IWorkflowEvents,
  IWorkflowGroup,
  IWorkflowStates,
} from "us.collection/reducers/Workflow/Interfaces";
import { EntityTypeShortForm, EntityType } from "us.common/constants";
import { WorkflowDrawerActionType } from "us.collection/constants";
import {
  workflowEventInitial,
  workflowStateInitial,
  WorkflowStateStatus,
} from "../Constants";
import { IStateRequest } from "../Interfaces";
import { WorkflowType, URLType } from "us.collection/constants";
/**
 * Add/Edit workflow state initial data
 * @param workflowDrawer - Drawer details
 * @param workflowStates - Workflow state group list
 * @param stateRequest - Get state request object
 * @returns Form initial data
 */
export const getWorkflowStateInitial = (
  workflowDrawer: IWorkflowDrawer,
  workflowStates: Array<IWorkflowGroup>,
  stateRequest: IStateRequest,
  initWorkflowVersion: number
) => {
  try {
    const { stateCategoryId, actionType, stateId, stateType } =
      workflowDrawer ?? {};

    const category = getWorkflowCategoryById(workflowStates, stateCategoryId);
    const { stateList, stateCategoryName } = category ?? {};

    if (actionType != WorkflowDrawerActionType.ADD) {
      const state = getWorkflowStateById(stateList, stateId);
      return {
        ...(state ?? {}),
        ...stateRequest,
        name:
          actionType == WorkflowDrawerActionType.COPY
            ? "Copy of " + state?.name
            : state?.name,
        stateType,
        actionType,
        workflowVersion: initWorkflowVersion,
        stateGroupName: stateCategoryName,
      };
    } else {
      return {
        ...workflowStateInitial,
        ...stateRequest,
        stateType,
        actionType,
        workflowVersion: initWorkflowVersion,
        stateGroupName: stateCategoryName,
      };
    }
  } catch {
    return workflowStateInitial;
  }
};

/**
 * Add/Edit workflow category initial data
 * @param workflowDrawer - Drawer details
 * @param workflowStates - Workflow state group list
 * @returns Form initial data
 */
export const getCategoryInitial = (
  workflowDrawer: IWorkflowDrawer,
  workflowStates: Array<IWorkflowGroup>
) => {
  try {
    const { stateCategoryId, actionType, stateType } = workflowDrawer ?? {};

    if (actionType != WorkflowDrawerActionType.ADD) {
      const category = getWorkflowCategoryById(workflowStates, stateCategoryId);

      const {
        stateCategoryName,
        stateCategoryDisplayName,
        type,
        stateCategoryDescription,
      } = category ?? {};

      return {
        categoryId: stateCategoryId,
        categoryName: stateCategoryName,
        displayName: stateCategoryDisplayName,
        description: stateCategoryDescription,
        stateType: type,
      };
    } else {
      return {
        categoryId: 0,
        categoryName: "",
        displayName: "",
        description: "",
        stateType,
      };
    }
  } catch {
    return {
      categoryId: 0,
      categoryName: "",
      displayName: "",
      description: "",
      stateType: "",
    };
  }
};

/**
 * Get category details using category id
 * @param workflowStates - Workflow state group list
 * @param id - Category Id
 * @returns Category details
 */
export const getWorkflowCategoryById = (
  workflowStates: Array<IWorkflowGroup>,
  id: number
): IWorkflowGroup | undefined => {
  try {
    return (
      workflowStates &&
      workflowStates?.find((group: any) => {
        return group.stateCategoryId == id;
      })
    );
  } catch {
    return undefined;
  }
};

/**
 * Get state details using state id
 * @param stateList - Workflow state list
 * @param id - State Id
 * @returns State details
 */
export const getWorkflowStateById = (
  stateList: Array<IStateList> | undefined,
  id: number
): IStateList | undefined => {
  try {
    return stateList?.find((stateItem: any) => {
      return stateItem.id == id;
    });
  } catch {
    return undefined;
  }
};

/**
 * Add/Edit workflow event initial data
 * @param workflowEvents Event List
 * @param eventDrawer Event drawer details
 * @param stateRequest Get event request object
 * @returns Event details
 */
export const getEventInitialDetails = (
  workflowEvents: IWorkflowEvents,
  eventDrawer: IEventDrawer,
  stateRequest: IStateRequest
) => {
  try {
    if (eventDrawer.isEdit) {
      const event = getWorkflowEventById(
        workflowEvents?.data,
        eventDrawer.eventId
      );
      return {
        ...(event ?? {}),
        ...stateRequest,
      };
    } else {
      return { ...workflowEventInitial, ...stateRequest };
    }
  } catch {
    return { ...workflowEventInitial, ...stateRequest };
  }
};
/**
 * Get event details using event id
 * @param list - Workflow event list
 * @param id - Event Id
 * @returns Event details
 */
export const getWorkflowEventById = (
  list: Array<IEvents> | undefined,
  id: number
): IEvents | undefined => {
  try {
    return list?.find((event: IEvents) => {
      return event.id == id;
    });
  } catch {
    return undefined;
  }
};

/**
 * Search Events
 * @param eventList - All event list
 * @param text - Search text
 * @returns - Filtered event list
 */
export const searchEvent = (
  eventList: Array<IEvents>,
  searchFilters: any
): Array<IEvents> => {
  const { searchText, activeEvents } = searchFilters ?? {};

  try {
    const events = eventList?.filter(
      ({ displayName, isDomainViewActive }: IEvents) => {
        return (
          displayName?.toLowerCase().includes(searchText?.toLowerCase()) &&
          getStateEventStatus(isDomainViewActive, activeEvents)
        );
      }
    );
    return events;
  } catch {
    return [];
  }
};
/**
 * @function
 * Check event name duplication
 * @param eventList event list
 * @param eventName Added name
 * @returns Duplicate or not
 */
export const checkEventNameDuplication = (
  eventList: Array<IEvents>,
  eventName: string
): boolean => {
  try {
    return eventList?.some(({ name }: any) => name == eventName);
  } catch {
    return false;
  }
};

/**
 * Filter state from state list
 * @param stateList - State List
 * @param text - Search text
 * @returns - Filtered state list
 */
export const filterState = (
  stateList: Array<IStateList>,
  values: any
): Array<IStateList> => {
  try {
    const { searchText, activeStates, overriddenBy } = values ?? {};

    return stateList?.filter((state: IStateList) => {
      return (
        state.displayName?.toLowerCase().includes(searchText?.toLowerCase()) &&
        getStateEventStatus(state.activeStatus, activeStates) &&
        (overriddenBy.length > 0
          ? overriddenBy?.some(
              (level: string) =>
                level == state?.overriddenBy?.toUpperCase()?.trim()
            )
          : true)
      );
    });
  } catch {
    return [];
  }
};

/**
 * Return active status
 * @param activeStatus - status of event or state
 * @param activeStates - user selected status
 * @returns - Return boolean according to active status
 */
const getStateEventStatus = (activeStatus: boolean, activeStates: string) => {
  switch (activeStates) {
    case WorkflowStateStatus.ACTIVE:
      return activeStatus;
    case WorkflowStateStatus.INACTIVE:
      return !activeStatus;
    case WorkflowStateStatus.ALL:
      return true;
    default:
      return true;
  }
};

/**
 * Search states
 * @param list - State list
 * @param text - Search text
 * @returns - Filtered state list with group details
 */
export const searchState = (
  list: Array<IWorkflowGroup>,
  values: any
): Array<IWorkflowGroup> | undefined => {
  try {
    const { searchText, activeStates, overriddenBy } = values ?? {};

    if (
      !searchText &&
      activeStates == WorkflowStateStatus.ALL &&
      overriddenBy?.length == 0
    ) {
      return list;
    } else {
      let groups: Array<IWorkflowGroup> = [];
      list?.map((group: IWorkflowGroup) => {
        const states = filterState(group?.stateList, values);
        if (states.length) {
          groups.push({
            ...group,
            stateList: states,
          });
        }
      });
      return groups;
    }
  } catch {
    return [];
  }
};

/**
 * @function
 * Get Category List
 * @param workflowStates Workflow group list
 * @returns Category list
 */
export const getCategoryList = (workflowStates: IWorkflowStates) => {
  try {
    const categories = workflowStates?.data.map((group: IWorkflowGroup) => {
      return {
        label: group.stateCategoryDisplayName,
        value: group.stateCategoryName,
      };
    });
    return categories.sort((a: any, b: any) => a.label.localeCompare(b.label));
  } catch {
    return [];
  }
};

/**
 * @function
 * Get access level colour
 * @param overriddenBy State access level
 * @returns Class name
 */
export const getOverriddenColor = (overriddenBy: string) => {
  try {
    switch (overriddenBy) {
      case EntityTypeShortForm.BUREAU:
        return "";
      case EntityTypeShortForm.CREDITOR_GROUP:
        return " swg-item-ov-cg";
      case EntityTypeShortForm.CREDIT:
        return " swg-item-ov-cr";
      default:
        return "";
    }
  } catch {
    return "";
  }
};

/**
 * @function
 * Check category name duplication
 * @param workflowState State list
 * @param name Added name
 * @returns Duplicate or not
 */
export const checkCategoryNameDuplication = (
  workflowState: Array<IWorkflowGroup>,
  name: string
): boolean => {
  try {
    if (
      workflowState?.find((category: IWorkflowGroup) => {
        return category.stateCategoryName == name;
      })
    ) {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
};
/**
 * @function
 * Check state name duplication
 * @param workflowState State list
 * @param name Added name
 * @returns Duplicate or not
 */
export const isDuplicateStateName = (
  workflowState: Array<IWorkflowGroup>,
  name: string
): boolean => {
  try {
    let isDuplicate = false;
    workflowState?.map((group: IWorkflowGroup) => {
      if (
        group?.stateList?.find((state: IStateList) => {
          return state.name == name;
        })
      ) {
        isDuplicate = true;
      }
    });
    return isDuplicate;
  } catch {
    return false;
  }
};
/**
 * Get workflow type for adapter sps
 * @param workflowStates State list
 * @param stateCategoryId Category Id
 * @param stateId State Id
 * @param isEdit State is new or edit
 * @returns Type
 */
export const getWorkflowType = (
  workflowStates: Array<IWorkflowGroup>,
  stateCategoryId: number,
  stateId: number,
  isEdit: boolean
): string => {
  try {
    const category = getWorkflowCategoryById(workflowStates, stateCategoryId);
    const state = getWorkflowStateById(category?.stateList, stateId);
    if (isEdit) {
      return state?.workflowType == EntityType.CASE
        ? EntityTypeShortForm.CASE
        : EntityTypeShortForm.SUB_CASE;
    }
    return EntityTypeShortForm.CASE;
  } catch {
    return EntityTypeShortForm.CASE;
  }
};

/**
 * Get Workflow type for event view
 * @param workflowEvents Event List
 * @param eventDrawer Drawer details
 * @returns Type
 */
export const getEventWorkflowType = (
  workflowEvents: IWorkflowEvents,
  eventDrawer: IEventDrawer
) => {
  try {
    const event = getWorkflowEventById(
      workflowEvents?.data,
      eventDrawer.eventId
    );
    if (eventDrawer.isEdit) {
      return event?.workflowType == EntityType.CASE
        ? EntityTypeShortForm.CASE
        : EntityTypeShortForm.SUB_CASE;
    }
    return EntityTypeShortForm.CASE;
  } catch {
    return EntityTypeShortForm.CASE;
  }
};

/**
 * Get Workflow state status title
 * @param activeStatus state status
 * @param workflowType state workflow type
 * @returns translation string
 */
export const getTagStatusTitle = (
  activeStatus: boolean,
  workflowType: string,
  isEvent?: boolean
): string => {
  try {
    const name = isEvent ? "EVENT" : "STATE";
    if (activeStatus) {
      if (workflowType == WorkflowType.CASE) {
        return `US.COLLECTION.CASE:WORKFLOWS.ACTIVE_CASE_${name}`;
      } else {
        return `US.COLLECTION.CASE:WORKFLOWS.ACTIVE_SUBCASE_${name}`;
      }
    } else {
      if (workflowType == WorkflowType.CASE) {
        return `US.COLLECTION.CASE:WORKFLOWS.INACTIVE_CASE_${name}`;
      } else {
        return `US.COLLECTION.CASE:WORKFLOWS.INACTIVE_SUBCASE_${name}`;
      }
    }
  } catch {
    return "US.COLLECTION.CASE:WORKFLOWS.ACTIVE_CASE_STATE";
  }
};

/**
 * Get Workflow state status title
 * @param Id Creditor ID or creditor group ID
 * @param navigationLevel navigation collection view level
 * @returns navigation url
 */
export const getTroubleshootNavigationUrl = (
  Id: string,
  navigationLevel: string
): string => {
  try {
    switch (navigationLevel) {
      case URLType.BUREAU:
        return "/bureau/workflows-troubleshoot";
      case URLType.CREDITOR:
        return `/creditor/${Id}/workflows-troubleshoot`;
      case URLType.CREDITOR_GROUP:
        return `/creditor-group/${Id}/workflows-troubleshoot`;
      default:
        return "";
    }
  } catch {
    return "";
  }
};

/**
 * @function
 * @description Get rest of the levels after deselection
 * @param overriddenBy array of overridden states
 * @param overriddenLevel selected level
 * @returns Rest of the levels
 */
export const getOverriddenLevels = (
  overriddenBy: Array<any>,
  overriddenLevel: string
): Array<any> => {
  try {
    return overriddenBy?.filter((level: string) => level !== overriddenLevel);
  } catch {
    return overriddenBy;
  }
};
