import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Common from 'us.common';
import { Formik } from 'formik';
import PostalArea from 'us.common/containers/PostalArea/PostalArea';
import FinancialSchema from './validations';
import { SaveFinancialAssets } from './SaveFinancialAssets';
import { UpdateFinancialAssets } from './UpdateFinancialAssets';
import { IMetaData } from 'us.collection/interfaces';
import { AssetsAction } from 'us.collection.case/actions';
import moment from 'moment';
import { SelectMunicipal } from 'us.common/components';
import { IDataModel } from 'us.collection.case/reducers/Dashboard/Interfaces';
import { RootState } from 'us.helper/types';

const {
	$Input,
	$DatePicker,
	$Form,
	$Button,
	$Divider,
	$Col,
	$Drawer,
	$Affix,
	$Row,
	$Skeleton,
	$TextArea,
	$Popconfirm,
	$InputAmount,
} = Common.Components;

interface IFinancial {
	onSearchPostaCode?(): any;
	postalCodeDetails?: any;
	asset?: any;
	isEditing: boolean;
	showOnly?: boolean;
}

interface IBase extends IFinancial {
	postalAreas: any;
	currentLanguage: string;
	currentCurrency: string;
	currentDateFormat: string;
	metaData: IDataModel<IMetaData>;
	addFinancialAssets: any;
	changeDrawerInfo: any;
	drawerInfo: any;
	getFinancialAssets: any;
	realEstateAssets: any;
	resetRealEstateAssets: any;
	updateFinancialAssets: any;
	isREFetching: boolean;
	drawerName: any;
	getPostalDetails: any;
}

const Financial: React.FC<IBase> = (props) => {
	const { t } = useTranslation(['US.COLLECTION.CASE'], {
		useSuspense: true,
	});
	const {
		postalAreas,
		currentLanguage,
		currentCurrency,
		currentDateFormat,
		metaData,
		addFinancialAssets,
		changeDrawerInfo,
		drawerInfo,
		getFinancialAssets,
		realEstateAssets,
		resetRealEstateAssets,
		updateFinancialAssets,
		isREFetching,
		drawerName,
		showOnly,
		getPostalDetails,
	} = props;

	const { debtorEntNumber } = metaData.data ?? {};

	const [postalCodeId, setPostalCodeId] = useState(0);
	const [municipalityId, setMunicipalityId] = useState(0);

	const handleCancel = (resetForm: any) => {
		changeDrawerInfo({
			title: '',
			visible: false,
		});
		resetForm();
		resetRealEstateAssets();
	};

	const [postalDrawerVisible, setPostalDrawerVisible] = useState(false);
	const [municipalityDrawerVisible, setMunicipalityDrawerVisible] =
		useState(false);
	const openPostalArea = () => {
		setPostalDrawerVisible(true);
	};
	const closePostalArea = () => {
		setPostalDrawerVisible(false);
	};
	const openMunicipalityArea = () => {
		setMunicipalityDrawerVisible(true);
	};
	const closeMunicipalityArea = () => {
		setMunicipalityDrawerVisible(false);
	};
	const postalAreaClose = () => {
		setPostalDrawerVisible(false);
	};
	const handlePostalDoubleClick = (
		selectedPostalAreaDetail: any,
		rest: any,
		values: any
	) => {
		setPostalDrawerVisible(false);
		let selectedPostalInfo = postalAreas?.filter(
			(p: any) =>
				p.postalCode ==
				selectedPostalAreaDetail?.PostalCode
		);
		let postalCodeId =
			selectedPostalInfo.length > 0
				? selectedPostalInfo[0].postalCodeId
				: 0;
		let municipalityId =
			selectedPostalInfo.length > 0
				? selectedPostalInfo[0].countyCode
				: 0;
		setPostalCodeId(postalCodeId);
		setMunicipalityId(municipalityId);
		rest.setFieldValue(
			'financialInstitutionPostalCode',
			selectedPostalAreaDetail?.PostalCode
		);
		rest.setFieldValue(
			'financialInstitutionPostalName',
			selectedPostalAreaDetail?.City
		);
		rest.setFieldValue(
			'financialInstitutionMunicipalityCode',
			selectedPostalAreaDetail?.MunicipalityCode
		);
		rest.setFieldValue(
			'financialInstitutionMunicipalityName',
			selectedPostalAreaDetail?.MunicipalityName
		);
	};

	const descriptionTextAreaRef = React.useRef<any>();

	useEffect(() => {
		descriptionFocus();
	}, []);

	useEffect(() => {
		if (drawerInfo?.isEdit) {
			getFinancialAssets(drawerInfo?.assetId);
		}
	}, [drawerInfo?.isEdit]);

	const checkPostalCode = (data: any, values: any) => {
		getPostalDetails({
			countryCode: 'NO',
			searchText: data.target.value,
			searchBy: 'PostalCode',
			values,
		});
	};

	const handleMunicipalityDoubleClick = (
		municipalityDetails: any,
		rest: any
	) => {
		rest.setFieldValue(
			'financialInstitutionMunicipalityCode',
			municipalityDetails?.municipalityCode
		);
		rest.setFieldValue(
			'financialInstitutionMunicipalityName',
			municipalityDetails?.municipalityName
		);
		rest.setFieldValue('financialInstitutionPostalCode', '');
		rest.setFieldValue('financialInstitutionPostalName', '');
	};

	const descriptionFocus = () => {
		typeof descriptionTextAreaRef?.current !== 'undefined' &&
			descriptionTextAreaRef.current.focus();
	};

	return (
		<Formik
			initialValues={{
				...realEstateAssets,
				comment: realEstateAssets.nameOrType,
			}}
			enableReinitialize
			validationSchema={FinancialSchema}
			onSubmit={(values: any, actions: any) => {
				actions.setSubmitting(true);
				if (!drawerInfo?.isEdit) {
					let entityInfo = {
						entityId: debtorEntNumber,
						entityType: 'DEB',
					};
					let payload = SaveFinancialAssets.call({
						...values,
						...entityInfo,
						postalCodeId,
						municipalityId,
					});
					addFinancialAssets(payload);
				} else {
					let entityInfo = {
						entityId: debtorEntNumber,
						entityType: 'DEB',
						assetId: drawerInfo?.assetId,
					};
					let postalCodeId =
						realEstateAssets.postalId;
					let municipalityId =
						realEstateAssets.municipalityId;
					let payload =
						UpdateFinancialAssets.call({
							...values,
							...entityInfo,
							postalCodeId,
							municipalityId,
						});
					updateFinancialAssets(payload);
				}
				changeDrawerInfo({
					title: '',
					visible: false,
				});
				resetRealEstateAssets();
				actions.resetForm();
			}}
			validateOnChange
			validateOnBlur>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...restProps
			}: any) => (
				<>
					<$Form>
						<$Skeleton
							loading={
								isREFetching &&
								drawerInfo?.isEdit
							}
							active
							paragraph={{ rows: 2 }}>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={24}>
									<$TextArea
										name='description'
										autoSize={{
											minRows: 2,
											maxRows: 4,
										}}
										size='small'
										label={t(
											'US.COLLECTION.CASE:FINANCIAL.NAME/DESCRIPTION'
										)}
										required
										ref={
											descriptionTextAreaRef
										}
										tabIndex={
											1
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
							</$Row>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={12}>
									<$Row
										className='mb-1'
										gutter={
											16
										}>
										<$Col
											span={
												12
											}>
											<$InputAmount
												size='small'
												className='w-100'
												label={t(
													'US.COLLECTION.CASE:FINANCIAL.ESTIMATED_VALUE'
												)}
												name='estimatedValue'
												currentLanguage={
													currentLanguage
												}
												currentCurrency={
													currentCurrency
												}
												value={
													values?.estimatedValue
												}
												tabIndex={
													2
												}
												disabled={
													showOnly
												}
											/>
										</$Col>

										<$Col
											span={
												12
											}>
											<$InputAmount
												size='small'
												className='w-100'
												label={t(
													'US.COLLECTION.CASE:FINANCIAL.VALUATION'
												)}
												name='valuationAmount'
												currentLanguage={
													currentLanguage
												}
												currentCurrency={
													currentCurrency
												}
												value={
													values?.valuationAmount
												}
												tabIndex={
													3
												}
												disabled={
													showOnly
												}
											/>
										</$Col>
									</$Row>

									<$Row
										className='mb-1'
										gutter={
											16
										}>
										<$Col
											span={
												12
											}>
											<$DatePicker
												label={t(
													'US.COLLECTION.CASE:FINANCIAL.VALUATION_DATE'
												)}
												name='valuationDate'
												size='small'
												style={{
													width: '100%',
												}}
												format={
													currentDateFormat
												}
												value={
													values?.valuationDate &&
													moment(
														values?.valuationDate
													).isValid()
														? moment(
																values?.valuationDate
														  )
														: undefined
												}
												allowClear
												tabIndex={
													4
												}
												disabled={
													showOnly
												}
											/>
										</$Col>
									</$Row>
								</$Col>

								<$Col span={12}>
									<$TextArea
										name='comment'
										autoSize={{
											minRows: 4,
											maxRows: 4,
										}}
										label={t(
											'US.COLLECTION.COMMON:COMMON.COMMENT'
										)}
										size='small'
										tabIndex={
											5
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
							</$Row>

							<$Divider
								className='my-4'
								orientation='left'>
								{t(
									'US.COLLECTION.CASE:OTHER.FINANCIAL'
								)}
							</$Divider>

							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={12}>
									<$Input
										size='small'
										label={t(
											'US.COLLECTION.COMMON:COMMON.NAME'
										)}
										name='financialInstitutionName'
										tabIndex={
											6
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
								<$Col span={12}>
									<$Input
										size='small'
										label={t(
											'US.COLLECTION.COMMON:COMMON.NIN'
										)}
										name='nin'
										tabIndex={
											7
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
							</$Row>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={12}>
									<$Input
										size='small'
										label={t(
											'US.COLLECTION.COMMON:COMMON.ADDRESS_1'
										)}
										name='financialInstitutionAddress1'
										tabIndex={
											8
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
								<$Col span={12}>
									<$Input
										size='small'
										label={t(
											'US.COLLECTION.COMMON:COMMON.ADDRESS_2'
										)}
										name='financialInstitutionAddress2'
										tabIndex={
											9
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
							</$Row>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={12}>
									{!showOnly && (
										<a
											className='d-block'
											style={{
												paddingBottom:
													'0.13rem',
											}}
											onClick={() =>
												openPostalArea()
											}>
											<strong>
												{t(
													'US.COLLECTION.COMMON:COMMON.POSTALCODE'
												)}
											</strong>
										</a>
									)}
									{showOnly && (
										<strong>
											{t(
												'US.COLLECTION.COMMON:COMMON.POSTALCODE'
											)}
										</strong>
									)}
									<$Input
										name='financialInstitutionPostalCode'
										size='small'
										value={
											'0472'
										}
										tabIndex={
											10
										}
										onBlur={(
											data: any
										) =>
											checkPostalCode(
												data,
												values
											)
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
								<$Col span={12}>
									<$Input
										size='small'
										label={t(
											'US.COLLECTION.COMMON:COMMON.POSTALPLACE'
										)}
										name='financialInstitutionPostalName'
										disabled
									/>
								</$Col>
							</$Row>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={12}>
									{!showOnly && (
										<a
											className='d-block'
											style={{
												paddingBottom:
													'0.13rem',
											}}
											onClick={() =>
												openMunicipalityArea()
											}>
											<strong>
												{t(
													'US.COLLECTION.CASE:OTHER.FINANCEINSTITUTIONMUNICIPALITYCODE'
												)}
											</strong>
										</a>
									)}
									{showOnly && (
										<strong>
											{t(
												'US.COLLECTION.CASE:OTHER.FINANCEINSTITUTIONMUNICIPALITYCODE'
											)}
										</strong>
									)}
									<$Input
										size='small'
										name='financialInstitutionMunicipalityCode'
										disabled
									/>
								</$Col>
								<$Col span={12}>
									<$Input
										size='small'
										label={t(
											'US.COLLECTION.CASE:FINANCIAL.MUNICIPALITY_NAME'
										)}
										name='financialInstitutionMunicipalityName'
										disabled
									/>
								</$Col>
							</$Row>

							{!showOnly && (
								<div className='drawer-footer-fixed align-content-center justify-content-end'>
									<div>
										<$Button
											className='mr-2'
											disabled={
												!restProps.isValid
											}
											onClick={(
												e: any
											) =>
												handleSubmit(
													e
												)
											}
											type='primary'>
											{drawerInfo?.isEdit
												? t(
														'US.COLLECTION.COMMON:COMMON.UPDATE'
												  )
												: t(
														'US.COLLECTION.COMMON:COMMON.SAVE'
												  )}
										</$Button>
										{restProps.dirty && (
											<$Popconfirm
												title={t(
													'US.COLLECTION.COMMON:COMMON.CANCEL_ERROR'
												)}
												placement='topLeft'
												onConfirm={() =>
													handleCancel(
														resetForm
													)
												}
												okText={t(
													'US.COLLECTION.COMMON:COMMON.YES'
												)}
												cancelText={t(
													'US.COLLECTION.COMMON:COMMON.NO'
												)}>
												<$Button>
													{t(
														'US.COLLECTION.COMMON:COMMON.CANCEL'
													)}
												</$Button>
											</$Popconfirm>
										)}
										{!restProps.dirty && (
											<$Button
												onClick={() =>
													handleCancel(
														resetForm
													)
												}>
												{t(
													'US.COLLECTION.COMMON:COMMON.CANCEL'
												)}
											</$Button>
										)}
									</div>
								</div>
							)}

							{/* Postal Area ---------------------------------------------------------------------------------*/}
							<$Drawer
								title={
									drawerName
								}
								width={800}
								visible={
									postalDrawerVisible
								}
								onClose={
									closePostalArea
								}
								destroyOnClose>
								<PostalArea
									postalAreaClose={() =>
										postalAreaClose()
									}
									drawerContentChangeClick={(
										e: any
									) =>
										handlePostalDoubleClick(
											e?.updatedInfo,
											restProps,
											values
										)
									}
									{...props}
								/>
							</$Drawer>
							{/* Municipality Area ---------------------------------------------------------------------------------*/}
							<$Drawer
								title={
									drawerName
								}
								width={800}
								visible={
									municipalityDrawerVisible
								}
								onClose={
									closeMunicipalityArea
								}
								destroyOnClose>
								<SelectMunicipal
									{...props}
									onCancelClick={() =>
										closeMunicipalityArea()
									}
									currentStep
									drawerContentChangeClick={() => {}}
									onRowClickMuniciples={(
										e: any
									) =>
										handleMunicipalityDoubleClick(
											e,
											restProps
										)
									}
								/>
							</$Drawer>
						</$Skeleton>
					</$Form>
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, domainView, entityDetails, assets } = state;
	const { currentLanguage, currentDateFormat, currentCurrency } = common;
	const { metaData } = domainView;
	const { postalAreas, drawerName } = entityDetails;
	const { drawerInfo, realEstateAssets, isREFetching } = assets;

	return {
		currentLanguage,
		currentDateFormat,
		currentCurrency,
		metaData,
		postalAreas,
		drawerInfo,
		realEstateAssets,
		isREFetching,
		drawerName,
	};
};

const mapDispatchToProps = {
	addFinancialAssets: AssetsAction.AddFinancialAssets.save,
	changeDrawerInfo: AssetsAction.AddRealEstateAssets.openDrawer,
	getFinancialAssets: AssetsAction.FinancialAssets.get,
	resetRealEstateAssets: AssetsAction.RealEstateAssets.reset,
	updateFinancialAssets: AssetsAction.UpdateFinancialAssets.update,
	getPostalDetails: AssetsAction.postalDetails.get,
};

export default connect(mapStateToProps, mapDispatchToProps)(Financial);
