import Constants from "us.collection.case/constants";
import { IAPIAction } from "us.collection/interfaces";
import { IAssetsAction } from "./Interface";

const { ACTIONS } = Constants;
const { Assets } = ACTIONS;

export const AssetsAction: Readonly<IAPIAction & IAssetsAction> = {
  /**
   * @namespace Assets
   */
  Assets: {
    get: (data) => ({
      type: Assets.GET_ASSET,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Assets.GET_ASSET_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Assets.GET_ASSET_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: Assets.GET_ASSET_RESET,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  AddRealEstateAssets: {
    save: (data) => ({
      type: Assets.SAVE_REAL_ESTATE_ASSETS,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Assets.SAVE_REAL_ESTATE_ASSETS_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Assets.SAVE_REAL_ESTATE_ASSETS_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
    openDrawer: (data) => ({
      type: Assets.CHANGE_DRAWER_STATUS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    resetDrawer: () => ({
      type: Assets.RESET_DRAWER_STATUS
    })
  },
  /**
   * DeleteAssets
   */
  DeleteAssets: {
    delete: (data) => ({
      type: Assets.DELETE_ASSET,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Assets.DELETE_ASSET_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Assets.DELETE_ASSET_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  /**
   * Get Real Estate Assets
   */
  RealEstateAssets: {
    get: (data) => ({
      type: Assets.GET_REAL_ESTATE_ASSET,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Assets.GET_REAL_ESTATE_ASSET_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Assets.GET_REAL_ESTATE_ASSET_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: Assets.RESET_REAL_ESTATE_ASSETS,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },

  /**
   * Edit Real Estate Assets
   */
  UpdateRealEstateAssets: {
    update: (data) => ({
      type: Assets.UPDATE_REAL_ESTATE_ASSET,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Assets.UPDATE_REAL_ESTATE_ASSET_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Assets.UPDATE_REAL_ESTATE_ASSET_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  /**
   * Add Personal Effect Assets
   */
  AddPersonalEffectAssets: {
    save: (data) => ({
      type: Assets.SAVE_PERSONAL_EFFECTS_ASSETS,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Assets.SAVE_PERSONAL_EFFECTS_ASSETS_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Assets.SAVE_PERSONAL_EFFECTS_ASSETS_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  /**
   * Get Personal Effect Assets
   */
  PersonalEffectsAssets: {
    get: (data) => ({
      type: Assets.GET_PERSONAL_EFFECTS_ASSET,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Assets.GET_PERSONAL_EFFECTS_ASSET_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Assets.GET_PERSONAL_EFFECTS_ASSET_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  /**
   * Edit Personal Effects Assets
   */
  UpdatePersonalEffectsAssets: {
    update: (data) => ({
      type: Assets.UPDATE_PERSONAL_EFFECTS_ASSET,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Assets.UPDATE_PERSONAL_EFFECTS_ASSET_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Assets.UPDATE_PERSONAL_EFFECTS_ASSET_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  /**
   * Add Document of Title Assets
   */
  AddDocumentOfTitleAssets: {
    save: (data) => ({
      type: Assets.SAVE_DOCUMENT_OF_TITLE_ASSETS,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Assets.SAVE_DOCUMENT_OF_TITLE_ASSETS_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Assets.SAVE_DOCUMENT_OF_TITLE_ASSETS_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  /**
   * Get Document of Title Assets
   */
  DocumentOfTitleAssets: {
    get: (data) => ({
      type: Assets.GET_DOCUMENT_OF_TITLE_ASSET,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Assets.GET_DOCUMENT_OF_TITLE_ASSET_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Assets.GET_DOCUMENT_OF_TITLE_ASSET_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  /**
   * Edit Document of Title Assets
   */
  UpdateDocumentOfTitleAssets: {
    update: (data) => ({
      type: Assets.UPDATE_DOCUMENT_OF_TITLE_ASSET,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Assets.UPDATE_DOCUMENT_OF_TITLE_ASSET_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Assets.UPDATE_DOCUMENT_OF_TITLE_ASSET_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  /**
   * Add Vehicle Assets
   */
  AddVehicleAssets: {
    save: (data) => ({
      type: Assets.SAVE_VEHICLE_ASSETS,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Assets.SAVE_VEHICLE_ASSETS_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Assets.SAVE_VEHICLE_ASSETS_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  /**
   * Get Vehicle Assets
   */
  VehicleAssets: {
    get: (data) => ({
      type: Assets.GET_VEHICLE_ASSET,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Assets.GET_VEHICLE_ASSET_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Assets.GET_VEHICLE_ASSET_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  /**
   * Edit Vehicle Assets
   */
  UpdateVehicleAssets: {
    update: (data) => ({
      type: Assets.UPDATE_VEHICLE_ASSET,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Assets.UPDATE_VEHICLE_ASSET_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Assets.UPDATE_VEHICLE_ASSET_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  /**
   * Add Other Assets
   */
  AddFinancialAssets: {
    save: (data) => ({
      type: Assets.SAVE_FINANCIAL_ASSETS,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Assets.SAVE_FINANCIAL_ASSETS_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Assets.SAVE_FINANCIAL_ASSETS_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  /**
   * Get Other Assets
   */
  FinancialAssets: {
    get: (data) => ({
      type: Assets.GET_FINANCIAL_ASSET,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Assets.GET_FINANCIAL_ASSET_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Assets.GET_FINANCIAL_ASSET_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  /**
   * Edit Other Assets
   */
  UpdateFinancialAssets: {
    update: (data) => ({
      type: Assets.UPDATE_FINANCIAL_ASSET,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Assets.UPDATE_FINANCIAL_ASSET_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Assets.UPDATE_FINANCIAL_ASSET_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  /**
   * Get postal details
   */
  postalDetails: {
    get: (data) => ({
      type: Assets.GET_POSTAL_DETAILS,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Assets.GET_POSTAL_DETAILS_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Assets.GET_POSTAL_DETAILS_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: Assets.RESET_POSTAL_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
};
