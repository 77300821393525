import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Common from 'us.common';
import { Formik } from 'formik';
import PostalArea from 'us.common/containers/PostalArea/PostalArea';
import PersonalEffectsSchema from './validations';
import { SavePersonalEffectsAssets } from './SavePersonalEffectsAssets';
import { UpdatePersonalEffectsAssets } from './UpdatePersonalEffectsAssets';
import { AssetsAction } from 'us.collection.case/actions';
import { SelectMunicipal } from 'us.common/components';
import { IDataModel } from 'us.collection.case/reducers/Dashboard/Interfaces';
import { IMetaData } from 'us.collection/interfaces';
import { RootState } from 'us.helper/types';

const {
	$Input,
	$DatePicker,
	$Form,
	$Button,
	$Col,
	$Drawer,
	$Row,
	$Skeleton,
	$TextArea,
	$Popconfirm,
	$InputAmount,
} = Common.Components;

interface IPersonalEffects {
	asset?: any;
	isEditing: boolean;
	onSearchPostaCode?(): void;
	postalCodeDetails?: any;
	showOnly?: boolean;
}

interface IBase extends IPersonalEffects {
	postalAreas: any;
	currentLanguage: string;
	currentCurrency: string;
	currentDateFormat: string;
	metaData: IDataModel<IMetaData>;
	drawerInfo: any;
	realEstateAssets: any;
	isREFetching: any;
	changeDrawerInfo: any;
	AddPersonalEffectAssets: any;
	resetRealEstateAssets: any;
	getPersonalEffectsAssets: any;
	updatePersonalEffectsAssets: any;
	drawerName: any;
	getPostalDetails: any;
}

const PersonalEffects: React.FC<IBase> = (props) => {
	const { t } = useTranslation(['US.COLLECTION.CASE'], {
		useSuspense: true,
	});
	const {
		postalAreas,
		currentLanguage,
		currentCurrency,
		currentDateFormat,
		metaData,
		drawerInfo,
		realEstateAssets,
		isREFetching,
		changeDrawerInfo,
		AddPersonalEffectAssets,
		resetRealEstateAssets,
		getPersonalEffectsAssets,
		updatePersonalEffectsAssets,
		drawerName,
		showOnly,
		getPostalDetails,
	} = props;

	const { debtorEntNumber } = metaData.data ?? {};

	const [postalCodeId, setPostalCodeId] = useState(0);
	const [municipalityId, setMunicipalityId] = useState(0);

	const handleCancel = (resetForm: any) => {
		changeDrawerInfo({
			title: '',
			visible: false,
		});
		resetForm();
		resetRealEstateAssets();
	};

	const [postalDrawerVisible, setPostalDrawerVisible] = useState(false);
	const [municipalityDrawerVisible, setMunicipalityDrawerVisible] =
		useState(false);
	const openPostalArea = () => {
		setPostalDrawerVisible(true);
	};
	const closePostalArea = () => {
		setPostalDrawerVisible(false);
	};
	const openMunicipalityArea = () => {
		setMunicipalityDrawerVisible(true);
	};
	const closeMunicipalityArea = () => {
		setMunicipalityDrawerVisible(false);
	};
	const postalAreaClose = () => {
		setPostalDrawerVisible(false);
	};
	const handlePostalDoubleClick = (
		selectedPostalAreaDetail: any,
		rest: any
	) => {
		setPostalDrawerVisible(false);
		let selectedPostalInfo = postalAreas?.filter(
			(p: any) =>
				p.postalCode ==
				selectedPostalAreaDetail?.PostalCode
		);
		let postalCodeId =
			selectedPostalInfo.length > 0
				? selectedPostalInfo[0].postalCodeId
				: 0;
		let municipalityId =
			selectedPostalInfo.length > 0
				? selectedPostalInfo[0].countyCode
				: 0;
		setPostalCodeId(postalCodeId);
		setMunicipalityId(municipalityId);
		rest.setFieldValue(
			'postalCode',
			selectedPostalAreaDetail?.PostalCode
		);
		rest.setFieldValue(
			'postalArea',
			selectedPostalAreaDetail?.City
		);
		rest.setFieldValue(
			'municipalityCode',
			selectedPostalAreaDetail?.MunicipalityCode
		);
		rest.setFieldValue(
			'municipalityArea',
			selectedPostalAreaDetail?.MunicipalityName
		);
	};

	const descriptionTextAreaRef = React.useRef<any>();

	useEffect(() => {
		descriptionFocus();
	}, []);

	useEffect(() => {
		if (drawerInfo?.isEdit) {
			getPersonalEffectsAssets(drawerInfo?.assetId);
		}
	}, [drawerInfo?.isEdit]);

	const checkPostalCode = (data: any, values: any) => {
		getPostalDetails({
			countryCode: 'NO',
			searchText: data.target.value,
			searchBy: 'PostalCode',
			values,
		});
	};

	const handleMunicipalityDoubleClick = (
		municipalityDetails: any,
		rest: any
	) => {
		rest.setFieldValue(
			'municipalityCode',
			municipalityDetails?.municipalityCode
		);
		rest.setFieldValue(
			'municipalityArea',
			municipalityDetails?.municipalityName
		);
		rest.setFieldValue('postalCode', '');
		rest.setFieldValue('postalArea', '');
	};

	const descriptionFocus = () => {
		typeof descriptionTextAreaRef?.current !== 'undefined' &&
			descriptionTextAreaRef.current.focus();
	};

	return (
		<Formik
			initialValues={{ ...realEstateAssets }}
			enableReinitialize
			validationSchema={PersonalEffectsSchema}
			onSubmit={(values: any, actions: any) => {
				actions.setSubmitting(true);
				if (!drawerInfo?.isEdit) {
					let entityInfo = {
						entityId: debtorEntNumber,
						entityType: 'DEB',
					};
					let payload =
						SavePersonalEffectsAssets.call({
							...values,
							...entityInfo,
							postalCodeId,
							municipalityId,
						});
					AddPersonalEffectAssets(payload);
				} else {
					let entityInfo = {
						entityId: debtorEntNumber,
						entityType: 'DEB',
						assetId: drawerInfo?.assetId,
					};
					let postalCodeId =
						realEstateAssets.postalCode;
					let municipalityId =
						realEstateAssets.municipalityCode;
					let payload =
						UpdatePersonalEffectsAssets.call(
							{
								...values,
								...entityInfo,
								postalCodeId,
								municipalityId,
							}
						);
					updatePersonalEffectsAssets(payload);
				}
				changeDrawerInfo({
					title: '',
					visible: false,
				});
				resetRealEstateAssets();
				actions.resetForm();
			}}
			validateOnChange
			validateOnBlur>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...restProps
			}: any) => (
				<>
					<$Form>
						<$Skeleton
							loading={
								isREFetching &&
								drawerInfo?.isEdit
							}
							active
							paragraph={{ rows: 2 }}>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={24}>
									<$TextArea
										name='description'
										autoSize={{
											minRows: 2,
											maxRows: 4,
										}}
										size='small'
										label={t(
											'US.COLLECTION.CASE:PERSONALEFFECTS.NAME/DESCRIPTION'
										)}
										required
										ref={
											descriptionTextAreaRef
										}
										tabIndex={
											1
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
							</$Row>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={12}>
									<$Input
										size='small'
										label={t(
											'US.COLLECTION.CASE:PERSONALEFFECTS.ADDRESS_LINE_1'
										)}
										name='address1'
										tabIndex={
											2
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
								<$Col span={6}>
									<$InputAmount
										size='small'
										className='w-100'
										label={t(
											'US.COLLECTION.CASE:PERSONALEFFECTS.ESTIMATEDVALUE'
										)}
										name='estimatedValue'
										currentLanguage={
											currentLanguage
										}
										currentCurrency={
											currentCurrency
										}
										value={
											values?.estimatedValue
										}
										tabIndex={
											9
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
								<$Col span={6}>
									<$InputAmount
										size='small'
										className='w-100'
										label={t(
											'US.COLLECTION.CASE:PERSONALEFFECTS.VALUATION'
										)}
										name='valuationAmount'
										currentLanguage={
											currentLanguage
										}
										currentCurrency={
											currentCurrency
										}
										value={
											values?.valuationAmount
										}
										tabIndex={
											10
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
							</$Row>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={12}>
									<$Input
										size='small'
										label={t(
											'US.COLLECTION.CASE:PERSONALEFFECTS.ADDRESS_LINE_2'
										)}
										name='address2'
										tabIndex={
											3
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
								<$Col span={6}>
									<$DatePicker
										label={t(
											'US.COLLECTION.CASE:PERSONALEFFECTS.VALUATIONDATE'
										)}
										name='valuationDate'
										size='small'
										style={{
											width: '100%',
										}}
										format={
											currentDateFormat
										}
										value={
											values?.valuationDate &&
											moment(
												values?.valuationDate
											).isValid()
												? moment(
														values?.valuationDate
												  )
												: undefined
										}
										allowClear
										tabIndex={
											11
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
							</$Row>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={6}>
									{!showOnly && (
										<a
											className='d-block'
											style={{
												paddingBottom:
													'0.13rem',
											}}
											onClick={() =>
												openPostalArea()
											}>
											{/* <span className="required">*</span>{" "} */}
											<strong>
												{t(
													'US.COLLECTION.COMMON:ENTITYSELECTION.POSTALCODE'
												)}
											</strong>
										</a>
									)}
									{showOnly && (
										<strong>
											{t(
												'US.COLLECTION.COMMON:ENTITYSELECTION.POSTALCODE'
											)}
										</strong>
									)}
									<$Input
										name='postalCode'
										size='small'
										value={
											values.postalCode
										}
										onBlur={(
											data: any
										) =>
											checkPostalCode(
												data,
												values
											)
										}
										tabIndex={
											4
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
								<$Col span={6}>
									<$Input
										size='small'
										label={t(
											'US.COLLECTION.COMMON:ENTITYSELECTION.POSTALAREA'
										)}
										name='postalArea'
										value={
											values.postalArea
										}
										tabIndex={
											5
										}
										disabled
									/>
								</$Col>
							</$Row>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={6}>
									{!showOnly && (
										<a
											className='d-block'
											style={{
												paddingBottom:
													'0.13rem',
											}}
											onClick={() =>
												openMunicipalityArea()
											}>
											<strong>
												{t(
													'US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYCODE'
												)}
											</strong>
										</a>
									)}
									{showOnly && (
										<strong>
											{t(
												'US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYCODE'
											)}
										</strong>
									)}
									<$Input
										name='municipalityCode'
										size='small'
										value={
											values.municipalityCode
										}
										tabIndex={
											6
										}
										disabled
									/>
								</$Col>
								<$Col span={6}>
									<$Input
										label={t(
											'US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYNAME'
										)}
										name='municipalityArea'
										size='small'
										tabIndex={
											7
										}
										disabled
									/>
								</$Col>
							</$Row>
							<$Row
								className='mb-1'
								gutter={16}>
								<$Col span={12}>
									<$TextArea
										name='comment'
										autoSize={{
											minRows: 4,
											maxRows: 4,
										}}
										size='small'
										label={t(
											'US.COLLECTION.COMMON:COMMON.COMMENT'
										)}
										// required
										tabIndex={
											8
										}
										disabled={
											showOnly
										}
									/>
								</$Col>
							</$Row>

							{!showOnly && (
								<div className='drawer-footer-fixed align-content-center justify-content-end'>
									<div>
										<$Button
											className='mr-2'
											disabled={
												!restProps.isValid
											}
											onClick={(
												e: any
											) =>
												handleSubmit(
													e
												)
											}
											type='primary'>
											{drawerInfo?.isEdit
												? t(
														'US.COLLECTION.COMMON:COMMON.UPDATE'
												  )
												: t(
														'US.COLLECTION.COMMON:COMMON.SAVE'
												  )}
										</$Button>
										{restProps.dirty && (
											<$Popconfirm
												title={t(
													'US.COLLECTION.COMMON:COMMON.CANCEL_ERROR'
												)}
												placement='topLeft'
												onConfirm={() =>
													handleCancel(
														resetForm
													)
												}
												okText={t(
													'US.COLLECTION.COMMON:COMMON.YES'
												)}
												cancelText={t(
													'US.COLLECTION.COMMON:COMMON.NO'
												)}>
												<$Button>
													{t(
														'US.COLLECTION.COMMON:COMMON.CANCEL'
													)}
												</$Button>
											</$Popconfirm>
										)}
										{!restProps.dirty && (
											<$Button
												onClick={() =>
													handleCancel(
														resetForm
													)
												}>
												{t(
													'US.COLLECTION.COMMON:COMMON.CANCEL'
												)}
											</$Button>
										)}
									</div>
								</div>
							)}

							{/* Postal Area ---------------------------------------------------------------------------------*/}
							<$Drawer
								title={
									drawerName
								}
								width={800}
								visible={
									postalDrawerVisible
								}
								onClose={
									closePostalArea
								}
								destroyOnClose>
								<PostalArea
									postalAreaClose={() =>
										postalAreaClose()
									}
									drawerContentChangeClick={(
										e: any
									) =>
										handlePostalDoubleClick(
											e?.updatedInfo,
											restProps
										)
									}
									{...props}
								/>
							</$Drawer>
							{/* Municipality Area ---------------------------------------------------------------------------------*/}
							<$Drawer
								title={
									drawerName
								}
								width={800}
								visible={
									municipalityDrawerVisible
								}
								onClose={
									closeMunicipalityArea
								}
								destroyOnClose>
								<SelectMunicipal
									{...props}
									onCancelClick={() =>
										closeMunicipalityArea()
									}
									currentStep
									drawerContentChangeClick={() => {}}
									onRowClickMuniciples={(
										e: any
									) =>
										handleMunicipalityDoubleClick(
											e,
											restProps
										)
									}
								/>
							</$Drawer>
						</$Skeleton>
					</$Form>
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, domainView, entityDetails, assets } = state;
	const { currentLanguage, currentDateFormat, currentCurrency } = common;
	const { metaData } = domainView;
	const { postalAreas, drawerName } = entityDetails;
	const { drawerInfo, realEstateAssets, isREFetching } = assets;

	return {
		currentLanguage,
		currentDateFormat,
		currentCurrency,
		metaData,
		postalAreas,
		drawerInfo,
		realEstateAssets,
		isREFetching,
		drawerName,
	};
};

const mapDispatchToProps = {
	AddPersonalEffectAssets: AssetsAction.AddPersonalEffectAssets.save,
	changeDrawerInfo: AssetsAction.AddRealEstateAssets.openDrawer,
	resetRealEstateAssets: AssetsAction.RealEstateAssets.reset,
	getPersonalEffectsAssets: AssetsAction.PersonalEffectsAssets.get,
	updatePersonalEffectsAssets:
		AssetsAction.UpdatePersonalEffectsAssets.update,
	getPostalDetails: AssetsAction.postalDetails.get,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalEffects);
