import * as Yup from 'yup';

export default () => {
    return Yup.object().shape({
        to: Yup.string().email(
            'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
        ).required(
            'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
        ),
        subject: Yup.string().required(
            'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
        ),
    });
};
