import { Formik } from 'formik';
import React, { useEffect, useState, memo, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import moment from 'moment';
import Common from 'us.common';
import * as Actions from 'us.collection.case/actions';
import { PlusOutlined, ExclamationCircleFilled, MinusOutlined } from 'us.icons';
import { validatePersonNo } from 'us.collection.case/functions';
import { europeanCountry } from 'us.common/constants';
import PostalArea from 'us.common/containers/PostalArea/PostalArea';
import * as Action from 'us.common/actions';
import { EntitySearch } from 'us.collection.case/components/OtherParty/Search';
import { AssetsAction } from 'us.collection.case/actions';
import { FormatPersonNo, ValidateZipCode } from './Functions';
import { IEmployer, SubDrawerName, IEmployerScreen } from './interfaces';
import { $Message, SelectMunicipal } from 'us.common/components';
import EmployerSchema from './Validations';
import { SaveUpdateEmployer } from './Functions/SaveUpdateEmployer';
import { RootState } from 'us.helper/types';

const {
	$Form,
	$Input,
	$Button,
	$DatePicker,
	$Popconfirm,
	$SelectGroup,
	$Row,
	$Col,
	$Select,
	$Drawer,
	$Popover,
	$Skeleton,
	$Collapse,
} = Common.Components;

const { OtherPartyAction } = Actions;
const { entity } = Action;
const { AddRealEstateAssets } = AssetsAction;

const { otherParty, roleTypes, postalDetails } = OtherPartyAction;

const Employer: React.FC<PropsFromRedux & IEmployerScreen> = memo((props) => {
	const { t } = useTranslation();
	const {
		currentDateFormat,
		roleType,
		drawerName,
		postalAreas,
		drawerInfo,
		otherPartyDetail,
		showOnly,
		metaData,
		save,
		getOtherPartyInfo,
		changeDrawerInfo,
		resetOtherPartyInfo,
		getPostalDetails,
	} = props;

	const [employerState, setEmployerState] = useState<IEmployer>({
		DrawerVisibility: {
			DrawerName: SubDrawerName.EMPLOYER,
			IsVisible: false,
		},
		OldCountryValue: '',
	});
	const firstNameRef = React.useRef<any>();

	useEffect(() => {
		if (firstNameRef.current) {
			firstNameRef.current.focus();
		}
		if (drawerInfo?.isEdit) {
			getOtherPartyInfo &&
				getOtherPartyInfo({
					type: 'Debtor',
					entityId: drawerInfo.assetId,
				});
		}
	}, [drawerInfo?.isEdit]);

	/**
	 * To validate and format birthdate
	 * @param values birthday input field value
	 * @param setFieldValue formik setFieldValue
	 */
	const handleBirthday = (value: string, setFieldValue: any): void => {
		const { isValid, birthDate } = validatePersonNo(
			value.replaceAll(/\D/g, '')
		);
		if (isValid) {
			FormatPersonNo(value, setFieldValue);
			setFieldValue('birthDate', moment(birthDate));
		} else {
			setFieldValue('birthDate', '');
		}
	};

	/**
	 * To cancel the dirty form
	 * @param resetForm formik resetForm
	 */
	const handleCancel = (resetForm: any): void => {
		changeDrawerInfo &&
			changeDrawerInfo({
				title: '',
				visible: false,
			});
		resetForm();
		resetOtherPartyInfo && resetOtherPartyInfo({});
	};

	/**
	 * To update the person number with the birthdate
	 * @param data datepicker moment value
	 * @param setFieldValue formik setFieldValue
	 */
	const handlePersonNo = (data: any, setFieldValue: any): void => {
		setFieldValue('personNo', moment(data).format('DDMMYY'));
		setFieldValue('birthDate', moment(data));
	};

	/**
	 * To format organization number
	 * @param value intput field value
	 * @param setFieldValue formik setFieldValue
	 */
	const formatOrganizationNo = (
		value: string,
		setFieldValue: any
	): void => {
		if (value.length === 9) {
			const parts = value.replace(/\s/g, '').match(/.{1,3}/g);
			setFieldValue('nin', parts?.join(' '));
		}
	};

	/**
	 * To check if the specific drawer is visible
	 * @param drawer The drawer name
	 * @returns desicion if the specific drawer is open or not
	 */
	const IsDrawerVisible = (drawer: SubDrawerName): boolean => {
		return (
			employerState.DrawerVisibility.DrawerName === drawer &&
			employerState.DrawerVisibility.IsVisible
		);
	};

	/**
	 * to close what-ever the drawer that is open
	 */
	const CloseDrawer = (): void => {
		setEmployerState((preState) => ({
			...preState,
			DrawerVisibility: {
				...preState.DrawerVisibility,
				IsVisible: false,
			},
		}));
	};

	/**
	 * To update the form based on the selected employer details
	 * @param data The selected otherparty-employer details
	 * @param setValues formik setValues
	 */
	const setSearchData = (data: any, setValues: any) => {
		if (data?.roleType !== 'Employer') {
			$Message.error(
				t(
					'US.COLLECTION.CASE:EMPLOYER.ONLY_EMPLOYER_TYPE_ENTITIES_ARE_ACCEPTED'
				)
			);
		} else {
			setValues(
				{
					entRoleId: data?.entRoleId,
					otherPartyType:
						data?.debtorType === 'Company'
							? 'N'
							: 'P',
					emailAddress: data?.emailAddress,
					personNo: '',
					organizationNo: '',
					otherpartyRoleType: data?.roleType,
					birthDate: moment(data?.birthDate),
					firstName: data?.firstName,
					lastName: data?.lastName,
					address1: data?.address1,
					address2: data?.address2,
					address3: data?.address3,
					telephone: data?.contacts?.hasOwnProperty(
						'Mobile'
					)
						? data?.contacts?.Mobile?.trim()
						: data?.contacts?.hasOwnProperty(
								'sms'
						  )
						? data?.contacts?.sms?.trim()
						: '',
					reference: data?.reference,
					gender: '',
					zipCode: data?.zipCode,
					zipName: data?.zipName,
					municipalityCode:
						data?.municipalityCode,
					municipalityName:
						data?.municipalityName,
					country: data?.countryCode,
					countryId: '',
					smsNo: '',
					isDefault: true,
					isAddressKnown: false,
					nin: data?.nIN,
				},
				true
			);
		}
		CloseDrawer();
	};

	/**
	 * To get the initial form values prepared
	 * @returns The formik initial values
	 */
	const GetInitialValues = (): any => {
		const { birthDate } = otherPartyDetail.data;
		const initialValues = drawerInfo.isEdit
			? {
					...otherPartyDetail.data,
					otherpartyRoleType: 'Employer',
					birthDate:
						birthDate &&
						(birthDate ===
							'1900-01-01T00:00:00' ||
							!moment(
								birthDate
							).isValid())
							? ''
							: moment(
									birthDate
							  ).format(
									'YYYY-MM-DD'
							  ),
			  }
			: {
					...otherPartyDetail.data,
					otherpartyRoleType: 'Employer',
					otherPartyType: 'N',
					entRoleId: -1,
					country: 'NO',
			  };
		return initialValues;
	};

	/**
	 * Get postal details
	 * @param data postal code
	 * @param values form values
	 */
	const checkPostalCode = (data: any, values: any) => {
		data.target.value &&
			getPostalDetails &&
			getPostalDetails({
				countryCode: 'NO',
				searchText: data.target.value,
				searchBy: 'PostalCode',
				values,
			});
	};

	/**
	 * @description - Handle form submission to save the asset
	 */
	const handleSubmit = (values: any) => {
		const payload = SaveUpdateEmployer(
			values,
			metaData.data,
			drawerInfo?.isEdit
		);
		save && metaData.data && save(payload);
		changeDrawerInfo &&
			changeDrawerInfo({
				title: '',
				visible: false,
			});
		resetOtherPartyInfo && resetOtherPartyInfo({});
	};

	return (
		<Formik
			initialValues={{ ...GetInitialValues() }}
			enableReinitialize
			validationSchema={EmployerSchema}
			onSubmit={handleSubmit}
			validateOnChange
			validateOnBlur>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				setValues,
				setFieldValue,
				...restProps
			}: any) => (
				<$Skeleton
					loading={
						otherPartyDetail.isFetching &&
						drawerInfo?.isEdit
					}
					active
					paragraph={{ rows: 2 }}>
					<$Form
						onSubmit={handleSubmit}
						className='other-party-edit'>
						<$Collapse
							bordered={false}
							defaultActiveKey={[
								'1',
								'2',
							]}
							expandIcon={({
								isActive,
							}) =>
								isActive ? (
									<MinusOutlined />
								) : (
									<PlusOutlined />
								)
							}
							className=''>
							{/* Basic information */}
							<$Collapse.Panel
								header={t(
									'US.COLLECTION.CASE:EMPLOYER.BASIC_INFORMATIONS'
								)}
								key='1'>
								<div className='pl-1'>
									<$Row
										gutter={
											16
										}
										className='mb-2'>
										<$Col
											span={
												8
											}>
											<$Select
												formitem={{
													label: t(
														'US.COLLECTION.CASE:EMPLOYER.TYPE'
													),
												}}
												name='otherPartyType'
												allOption={
													false
												}
												size='small'
												onSelect={(
													e: any
												) =>
													setFieldValue(
														'otherPartyType',
														e
													)
												}
												required
												value={
													values.otherPartyType
												}
												defaultValue={
													values.otherPartyType
												}
												style={{
													width: '100%',
												}}
												disabled={
													drawerInfo.isEdit
												}
												options={[
													{
														label: t(
															'US.COLLECTION.CASE:EMPLOYER.PERSON'
														),
														value: 'P',
														key: 'P',
													},
													{
														label: t(
															'US.COLLECTION.CASE:EMPLOYER.COMPANY'
														),
														value: 'N',
														key: 'N',
													},
												]}
											/>
										</$Col>
									</$Row>

									<$Row
										gutter={
											16
										}
										className='mb-2'>
										<$Col
											span={
												12
											}>
											{!showOnly && (
												<a
													onClick={() =>
														setEmployerState(
															(
																preState
															) => ({
																...preState,
																DrawerVisibility:
																	{
																		DrawerName: SubDrawerName.EMPLOYER,
																		IsVisible: true,
																	},
															})
														)
													}
													style={{
														display: 'block',
														marginBottom:
															'2px',
													}}>
													{' '}
													<strong>
														{t(
															'US.COLLECTION.CASE:EMPLOYER.FIRSTNAME'
														)}
													</strong>
												</a>
											)}
											{showOnly && (
												<strong>
													{t(
														'US.COLLECTION.CASE:EMPLOYER.FIRSTNAME'
													)}
												</strong>
											)}
											<$Input
												ref={
													firstNameRef
												}
												name='firstName'
												size='small'
												disabled={
													showOnly
												}
											/>
										</$Col>
										<$Col
											span={
												12
											}>
											<$Input
												name='lastName'
												label={t(
													'US.COLLECTION.CASE:EMPLOYER.LASTNAME'
												)}
												size='small'
												required
												disabled={
													showOnly
												}
											/>
										</$Col>
									</$Row>

									<$Row
										gutter={
											16
										}
										className='mb-2'>
										<$Col
											span={
												8
											}>
											<div
												style={{
													marginBottom:
														'6px',
												}}>
												<span className='required'>
													*
												</span>{' '}
												<strong>
													{t(
														'US.COLLECTION.CASE:EMPLOYER.ROLETYPE'
													)}
												</strong>
											</div>
											<$Select
												required
												disabled
												style={{
													width: '100%',
												}}
												placeholder=''
												className='mr-0'
												options={
													roleType.data
												}
												value={
													'Employer'
												}
												allOption={
													false
												}
												name='otherpartyRoleType'
											/>
										</$Col>
										<$Col
											span={
												values.otherPartyType ===
												'N'
													? 16
													: 8
											}>
											<$Input
												name='nin'
												onBlur={(
													e: ChangeEvent<HTMLInputElement>
												) =>
													values.otherPartyType ===
													'P'
														? handleBirthday(
																e
																	.target
																	.value,
																setFieldValue
														  )
														: formatOrganizationNo(
																e
																	.target
																	.value,
																setFieldValue
														  )
												}
												label={t(
													'US.COLLECTION.CASE:EMPLOYER.NATIONAL_IDENTITY_NUMBER'
												)}
												size='small'
												disabled={
													showOnly
												}
											/>
										</$Col>

										{values.otherPartyType ===
											'P' && (
											<$Col
												span={
													8
												}>
												<$DatePicker
													name='birthDate'
													label={t(
														'US.COLLECTION.CASE:EMPLOYER.BIRTHDATE'
													)}
													onChange={(
														e: any
													) =>
														handlePersonNo(
															e,
															setFieldValue
														)
													}
													defaultPickerValue={moment(
														'2000/01/01'
													)}
													format={
														currentDateFormat
													}
													placeholder={t(
														'US.COLLECTION.CASE:EMPLOYER.SELECT_DATE'
													)}
													value={
														values.birthDate
															? moment(
																	values.birthDate
															  )
															: null
													}
													style={{
														width: '100%',
													}}
													size='small'
													disabled={
														showOnly
													}
													disabledDate={(
														date: any
													) =>
														!date ||
														date >
															moment().startOf(
																'day'
															)
													}
												/>
											</$Col>
										)}
									</$Row>

									<$Row
										gutter={
											16
										}
										className='mb-4'>
										<$Col
											span={
												8
											}>
											<$Input
												name='telephoneNo'
												label={t(
													'US.COLLECTION.COMMON:COMMON.TELEPHONE'
												)}
												size='small'
												disabled={
													showOnly
												}
											/>
										</$Col>
										<$Col
											span={
												8
											}>
											<$Input
												name='emailAddress'
												label={t(
													'US.COLLECTION.COMMON:COMMON.EMAIL'
												)}
												size='small'
												disabled={
													showOnly
												}
											/>
										</$Col>
										<$Col
											span={
												8
											}>
											<$Input
												name='reference'
												label={t(
													'US.COLLECTION.CASE:EMPLOYER.REFERENCE'
												)}
												size='small'
												disabled={
													showOnly
												}
											/>
										</$Col>
									</$Row>
								</div>
							</$Collapse.Panel>

							{/* Address information */}
							<$Collapse.Panel
								header={t(
									'US.COLLECTION.CASE:EMPLOYER.ADDRESS_INFORMATION'
								)}
								key='2'>
								<div className='pl-1'>
									<$Row
										gutter={
											16
										}
										className='mb-2'>
										<$Col
											span={
												8
											}>
											<$Popover
												placement='rightTop'
												content={
													<div
														style={{
															maxWidth: '300px',
														}}>
														<div className='ant-popover-message'>
															<ExclamationCircleFilled />
															<div className='ant-popover-message-title'>
																<div
																	style={{
																		paddingBottom:
																			'0.3rem',
																	}}>
																	{t(
																		'US.COLLECTION.CASE:EMPLOYER.POSTAL_CODE_WARNING'
																	)}
																</div>
																<div
																	style={{
																		paddingBottom:
																			'0.5rem',
																	}}>
																	<strong>
																		{t(
																			'US.COLLECTION.CASE:EMPLOYER.CONTINUE_CONFIRM'
																		)}
																	</strong>
																</div>
															</div>
														</div>
														<div className='ant-popover-buttons'>
															<$Button
																size='small'
																onClick={() => {
																	setFieldValue(
																		'country',
																		employerState.OldCountryValue
																	);
																	CloseDrawer();
																}}>
																{t(
																	'US.COMMON:COMMON.NO'
																)}
															</$Button>
															<$Button
																size='small'
																type='primary'
																onClick={() => {
																	CloseDrawer();
																	setValues(
																		(
																			preVals: any
																		) => ({
																			...preVals,
																			...ValidateZipCode(
																				'',
																				postalAreas
																			),
																		})
																	);
																}}>
																{t(
																	'US.COMMON:COMMON.YES'
																)}
															</$Button>
														</div>
													</div>
												}
												trigger='click'
												visible={IsDrawerVisible(
													SubDrawerName.COUNTRY_CHANGE_WARN
												)}></$Popover>
											<$SelectGroup
												className='country-select'
												required
												options={europeanCountry.map(
													(
														country
													) => ({
														...country,
														key: country.label,
													})
												)}
												formitem={{
													label: t(
														'US.COLLECTION.CASE:EMPLOYER.COUNTRY'
													),
												}}
												style={{
													width: '100%',
												}}
												value={
													values.country
												}
												name='country'
												optionFilterProp='children'
												onFocus={() =>
													setEmployerState(
														(
															preVals: any
														) => ({
															...preVals,
															OldCountryValue:
																values.country,
														})
													)
												}
												onChange={(
													data: any
												) => {
													setFieldValue(
														'country',
														data
													);
													values.zipCode &&
														setEmployerState(
															(
																preState
															) => ({
																...preState,
																DrawerVisibility:
																	{
																		DrawerName: SubDrawerName.COUNTRY_CHANGE_WARN,
																		IsVisible: true,
																	},
															})
														);
												}}
												filterOption={(
													input: any,
													option: any
												) =>
													option.children
														?.toLowerCase()
														.indexOf(
															input.toLowerCase()
														) >=
													0
												}
												disabled={
													showOnly
												}
											/>
										</$Col>
									</$Row>

									<$Row
										gutter={
											16
										}
										className='mb-2'>
										<$Col
											span={
												8
											}>
											<$Input
												name='address1'
												label={t(
													'US.COLLECTION.CASE:EMPLOYER.POST_ADDRESS_1'
												)}
												size='small'
												disabled={
													showOnly
												}
											/>
										</$Col>
										<$Col
											span={
												8
											}>
											<$Input
												name='address2'
												label={t(
													'US.COLLECTION.CASE:EMPLOYER.POST_ADDRESS_2'
												)}
												size='small'
												disabled={
													showOnly
												}
											/>
										</$Col>
										<$Col
											span={
												8
											}>
											<$Input
												name='address3'
												label={t(
													'US.COLLECTION.CASE:EMPLOYER.POST_ADDRESS_3'
												)}
												size='small'
												disabled={
													showOnly
												}
											/>
										</$Col>
									</$Row>

									<$Row
										gutter={
											16
										}
										className='mb-2'>
										<$Col
											span={
												6
											}>
											{!showOnly && (
												<a
													className='d-block'
													style={{
														paddingBottom:
															'0.13rem',
													}}
													onClick={() =>
														setEmployerState(
															(
																preState
															) => ({
																...preState,
																DrawerVisibility:
																	{
																		DrawerName: SubDrawerName.POSTAL_CODE,
																		IsVisible: true,
																	},
															})
														)
													}>
													<span className='required'>
														*
													</span>{' '}
													<strong>
														{t(
															'US.COLLECTION.COMMON:COMMON.POSTALCODE'
														)}
													</strong>
												</a>
											)}
											{showOnly && (
												<strong>
													{t(
														'US.COLLECTION.COMMON:COMMON.POSTALCODE'
													)}
												</strong>
											)}
											<$Input
												name='zipCode'
												size='small'
												value={
													values.zipCode
												}
												onBlur={(
													data: any
												) =>
													checkPostalCode(
														data,
														values
													)
												}
												disabled={
													showOnly
												}
											/>
										</$Col>
										<$Col
											span={
												6
											}>
											<$Input
												name='zipName'
												label={t(
													'US.COLLECTION.COMMON:COMMON.POSTAL_AREA'
												)}
												size='small'
												value={
													values.zipName
												}
												disabled
											/>
										</$Col>
										<$Col
											span={
												6
											}>
											<div>
												{!showOnly && (
													<a
														className='d-block'
														style={{
															paddingBottom:
																'0.13rem',
														}}
														onClick={() =>
															setEmployerState(
																(
																	preState
																) => ({
																	...preState,
																	DrawerVisibility:
																		{
																			DrawerName: SubDrawerName.MUNICIPALITY,
																			IsVisible: true,
																		},
																})
															)
														}>
														<strong>
															{t(
																'US.COLLECTION.COMMON:EMPLOYER.MUNICIPALITY_CODE'
															)}
														</strong>
													</a>
												)}
												{showOnly && (
													<strong>
														{t(
															'US.COLLECTION.COMMON:EMPLOYER.MUNICIPALITY_CODE'
														)}
													</strong>
												)}
												<$Input
													name='municipalityCode'
													size='small'
													value={
														values.municipalityCode
													}
													disabled
												/>
											</div>
										</$Col>
										<$Col
											span={
												6
											}>
											<$Input
												name='municipalityName'
												label={t(
													'US.COLLECTION.COMMON:EMPLOYER.MUNICIPALITY_NAME'
												)}
												size='small'
												value={
													values.municipalityName
												}
												disabled
											/>
										</$Col>
									</$Row>
								</div>
							</$Collapse.Panel>
						</$Collapse>
					</$Form>
					{!showOnly && (
						<div className='drawer-footer-fixed align-content-center justify-content-end'>
							<div>
								<$Button
									className='mr-2'
									disabled={
										!restProps.isValid
									}
									onClick={(
										e: any
									) =>
										handleSubmit(
											e
										)
									}
									type='primary'>
									{drawerInfo?.isEdit
										? t(
												'US.COLLECTION.COMMON:COMMON.UPDATE'
										  )
										: t(
												'US.COLLECTION.COMMON:COMMON.SAVE'
										  )}
								</$Button>
								{restProps.dirty && (
									<$Popconfirm
										title={t(
											'US.COLLECTION.COMMON:COMMON.CANCEL_ERROR'
										)}
										placement='topLeft'
										onConfirm={() =>
											handleCancel(
												resetForm
											)
										}
										okText={t(
											'US.COLLECTION.COMMON:COMMON.YES'
										)}
										cancelText={t(
											'US.COLLECTION.COMMON:COMMON.NO'
										)}>
										<$Button>
											{t(
												'US.COLLECTION.COMMON:COMMON.CANCEL'
											)}
										</$Button>
									</$Popconfirm>
								)}
								{!restProps.dirty && (
									<$Button
										onClick={() =>
											handleCancel(
												resetForm
											)
										}>
										{t(
											'US.COLLECTION.COMMON:COMMON.CANCEL'
										)}
									</$Button>
								)}
							</div>
						</div>
					)}
					<$Drawer
						width={900}
						visible={IsDrawerVisible(
							SubDrawerName.EMPLOYER
						)}
						onClose={CloseDrawer}
						title={t(
							'US.COLLECTION.COMMON:COMMON.SEARCH'
						)}
						destroyOnClose>
						<EntitySearch
							roleType='Employer'
							setData={(data: any) =>
								setSearchData(
									data,
									setValues
								)
							}
						/>
					</$Drawer>
					<$Drawer
						title={drawerName}
						width={800}
						visible={IsDrawerVisible(
							SubDrawerName.POSTAL_CODE
						)}
						onClose={CloseDrawer}
						destroyOnClose>
						<PostalArea
							postalAreaClose={() =>
								CloseDrawer()
							}
							drawerContentChangeClick={({
								updatedInfo,
							}: any) => {
								setValues(
									(
										preVals: any
									) => ({
										...preVals,
										municipalityCode:
											updatedInfo.MunicipalityCode,
										municipalityName:
											updatedInfo.MunicipalityName,
										zipCode: updatedInfo.PostalCode,
										zipName: updatedInfo.City,
									})
								);
								CloseDrawer();
							}}
							{...props}
						/>
					</$Drawer>
					<$Drawer
						title={drawerName}
						width={800}
						visible={IsDrawerVisible(
							SubDrawerName.MUNICIPALITY
						)}
						onClose={CloseDrawer}
						destroyOnClose>
						<SelectMunicipal
							{...props}
							onCancelClick={() =>
								CloseDrawer()
							}
							currentStep
							drawerContentChangeClick={() => {}}
							onRowClickMuniciples={({
								municipalityCode,
								municipalityName,
							}: any) => {
								setValues(
									(
										preVals: any
									) => ({
										...preVals,
										municipalityCode,
										municipalityName,
										zipCode: '',
										zipName: '',
									})
								);
								CloseDrawer();
							}}
						/>
					</$Drawer>
				</$Skeleton>
			)}
		</Formik>
	);
});

const mapStateToProps = (state: RootState) => {
	const { common, domainView, otherParty, entityDetails, assets } = state;
	const { currentDateFormat } = common;
	const { metaData } = domainView;
	const { roleType } = otherParty;
	const { postalAreas, drawerName, municiples } = entityDetails;
	const { drawerInfo } = assets;
	return {
		currentDateFormat,
		metaData,
		roleType,
		drawerName,
		postalAreas,
		drawerInfo,
		otherPartyDetail: otherParty.otherParty,
		municiples,
	};
};

const mapDispatchToProps = {
	save: otherParty.save,
	getRoleType: roleTypes.get,
	getPostalAreas: entity.getPostalAreas,
	getOtherPartyInfo: otherParty.get,
	resetOtherPartyInfo: otherParty.reset,
	changeDrawerInfo: AddRealEstateAssets.openDrawer,
	getMunicipals: entity.getMunicipals,
	getPostalDetails: postalDetails.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Employer);
