import React, {
	useState,
	useEffect,
	useContext,
	useMemo,
	useCallback,
} from 'react';
import { connect } from 'react-redux';
import { INavigationData } from 'us.collection/interfaces';
import { Header } from 'us.collection.creditor/layouts/CreditorGroup/Header';
import { matchPath, RouteComponentProps, withRouter } from 'react-router-dom';
import { BoxIcons, IconTypes } from 'us.icons';
import { useTranslation } from 'react-i18next';
import Common from 'us.common';
import { creditorGroup } from 'us.collection.admin/actions';
import { ConfigurationContext } from 'us.common/ConfigurationContext/ConfigurationProvider';
import { getSelectedMenuKeyByModule } from 'us.collection/functions';
import { RootState } from 'us.helper/types';

const { $Layout, $Menu } = Common.Components;

const { Content, Sider } = $Layout;

interface ILayout extends RouteComponentProps {
	getCreditorsByGroupId?: any;
}

const Home: React.FC<ILayout> = (props) => {
	const { t } = useTranslation([
		'US.COLLECTION.LAYOUT',
		'US.COLLECTION.CREDITOR',
	]);
	const context = useContext(ConfigurationContext);

	const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

	const { getCreditorsByGroupId, location, history } = props;
	const state = location.state as INavigationData;
	const { refreshCount = 0 } = state ?? {};
	const BODY_CLASS = 'change-body-color-dom';

	const creditorGroupPermission =
		context.componentPermission['creditorgroup'];

	const params: { creditorGroupId: string; moduleName: string } =
		useMemo(() => {
			const { params }: any = matchPath(
				props.location.pathname,
				{
					path: '/creditor-group/:creditorGroupId?/:moduleName?',
					exact: false,
					strict: false,
				}
			);
			return {
				creditorGroupId:
					params['creditorGroupId'] ?? '',
				moduleName: params['moduleName'] ?? '',
			};
		}, [props.location.pathname]);

	const creditorGroupId = params['creditorGroupId'];

	useEffect(() => {
		document.body.classList.add(BODY_CLASS);
		return () => {
			document.body.classList.remove(BODY_CLASS);
		};
	}, []);

	useEffect(() => {
		if (creditorGroupId) {
			initCreditorGroup(creditorGroupId);
		}
	}, [creditorGroupId, refreshCount]);

	const initCreditorGroup = useCallback(
		(creditorGroupId: string | number) => {
			if (creditorGroupId) {
				getCreditorsByGroupId({
					creditorGroupId,
					withCreditors: true,
				});
			}
		},
		[creditorGroupId, refreshCount]
	);

	const navigate = (url: string): void => {
		if (creditorGroupId) {
			window.scrollTo(0, 0);
			history.push({
				...location,
				pathname: `/creditor-group/${creditorGroupId}/${url}`,
			});
		}
	};

	const onCollapse = (collapsed: boolean): void => {
		setIsCollapsed(collapsed);
	};

	const isMenuItemAvailable = useCallback(
		(menuItemName: string) => {
			return creditorGroupPermission?.routes[menuItemName]
				?.isEnabled;
		},
		[creditorGroupPermission]
	);

	return (
		<$Layout style={{ minHeight: '100vh' }}>
			<Header {...props} />
			<$Layout>
				<Content className='mt-0'>
					<main>{props.children}</main>
				</Content>
				<Sider
					collapsible
					collapsed={isCollapsed}
					onCollapse={(e) => onCollapse(e)}
					reverseArrow={true}>
					<$Menu
						theme='dark'
						mode='inline'
						selectedKeys={getSelectedMenuKeyByModule(
							params['moduleName']
						)}>
						{isMenuItemAvailable(
							'selectedCreditors'
						) && (
							<$Menu.Item
								key='13'
								onClick={() =>
									navigate(
										'selected-creditors'
									)
								}
								data-testid='creditorGroup-layout-selectedCreditors'>
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='selected-creditors'
								/>
								<span>
									{t(
										'US.COLLECTION.LAYOUT:CREDITOR_GROUP.SELECTED_CREDITORS'
									)}
								</span>
							</$Menu.Item>
						)}
						{isMenuItemAvailable('bmd') && (
							<$Menu.Item
								key='2'
								onClick={() =>
									navigate(
										'bmd'
									)
								}
								data-testid='creditorGroup-layout-bmd'>
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='bmd'
								/>
								<span>
									{t(
										'US.COLLECTION.LAYOUT:CREDITOR_GROUP.BMD'
									)}
								</span>
							</$Menu.Item>
						)}
						{/* <$Menu.Item
							key='3'
							onClick={() =>
								navigate(
									'workflows'
								)
							}
							data-testid='creditorGroup-layout-bmd'>
							<BoxIcons
								type={
									IconTypes.BOX_ICON
								}
								name='bureau-workflows'
							/>
							<span>
								{t(
									'US.COLLECTION.LAYOUT:CREDITOR_GROUP.WORKFLOWS'
								)}
							</span>
						</$Menu.Item> */}
						{/* <$Menu.Item
							key='4'
							onClick={() =>
								navigate(
									'workflows-troubleshoot'
								)
							}>
							<BoxIcons
								type={
									IconTypes.BOX_ICON
								}
								name='workflows-troubleshoot'
							/>
							<span>
								{t(
									'US.COLLECTION.LAYOUT:BUREAU.WORKFLOWS_TROUBLESHOOT'
								)}
							</span>
						</$Menu.Item> */}
					</$Menu>
				</Sider>
			</$Layout>
		</$Layout>
	);
};

const mapStateToProps = (state: RootState) => {
	return {};
};

const mapDispatchToProps = {
	getCreditorsByGroupId: creditorGroup.selectedCreditors.get,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
