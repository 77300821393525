import { IBMDDataType } from "us.collection/interfaces";

export const getInitialValues = (selectedBMDDataType: IBMDDataType | undefined): IBMDDataType => {
  return (
    selectedBMDDataType || {
      description: "",
      id: -1,
      isPrimitive: 0,
      name: "",
      storedProcedure: "",
    }
  );
};
