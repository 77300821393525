import { put, call, takeLatest } from 'redux-saga/effects';
import * as Actions from 'us.collection.case/actions';
import * as API from 'us.collection.case/services';
import Common from 'us.common';
import { Interest } from 'us.collection.case/constants/Actions';

const { $MessageBox } = Common.Components;

const { interestAction, AccountSummeryWidgetActions } = Actions;

const interestSagas = {
	interest: {
		get: function* (action: any) {
			const params = action.payload.data;
			try {
				const { data } = yield call(
					API.interests.interest.get,
					params
				);
				yield put(
					interestAction.interest.success(data)
				);
			} catch (error) {
				yield put(
					interestAction.interest.fail(
						error as any
					)
				);
			}
		},
	},
	summaryInterest: {
		update: function* (action: any) {
			const params = action.payload.data;
			try {
				const { data } = yield call(
					API.interests.summaryInterest.update,
					params
				);

				if (
					data.isSuccess &&
					!data.hasOwnProperty('errors')
				) {
					const result = yield call(
						API.dashboard
							.getAccountSummeryDetails,
						{
							EntityType:
								params?.type ===
								'S'
									? 'Subcase'
									: 'Case',
							EntityId: params?.caseId,
						}
					);
					yield put(
						AccountSummeryWidgetActions.accountSummery.success(
							result
						)
					);
					yield put(
						interestAction.summaryInterest.success(
							data
						)
					);
					$MessageBox(
						'success',
						'US.COLLECTION.TRANSACTIONS:ACCOUNT_SUMMERY.INTEREST_CALCULATED_SUCCESSFULLY',
						'',
						''
					);
				} else {
					yield put(
						interestAction.summaryInterest.fail(
							data
						)
					);
					$MessageBox(
						'error',
						data.message,
						'',
						''
					);
				}
			} catch (error) {
				yield put(
					interestAction.summaryInterest.fail({})
				);
			}
		},
	},
};

export default [
	takeLatest(Interest.GET_INTEREST_START, interestSagas.interest.get),
	takeLatest(
		Interest.GET_SUMMARY_INTEREST_START,
		interestSagas.summaryInterest.update
	),
];
