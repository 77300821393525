import { initialState } from "us.collection.transactions/reducers/ApplicableCourts/State";
import { ApplicableCourt } from "us.collection.transactions/constants/Actions";
import {setupCourtData} from 'us.collection.transactions/functions'
const {
  GET_APPLICABLE_COURT,
  GET_APPLICABLE_COURT_SUCCESS,
  GET_APPLICABLE_COURT_FAIL,
} = ApplicableCourt;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_APPLICABLE_COURT:
      return Object.assign({}, state, {
        courts: {
          data: [],
          isFetching: true,
        },
      });
    case GET_APPLICABLE_COURT_SUCCESS:
      return Object.assign({}, state, {
        courts: {
          data: setupCourtData(payload?.data),
          isFetching: false,
        },
      });
    case GET_APPLICABLE_COURT_FAIL:
      return Object.assign({}, state, {
        courts: {
          data: [],
          isFetching: false,
        },
      });
    default:
      return state;
  }
};
