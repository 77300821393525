import { call, put, takeLatest } from 'redux-saga/effects';
import { ObjectionAndAgreements } from 'us.collection.case/constants/Actions';
import { objectionAndAgreement as API } from 'us.collection.case/services';
import * as Actions from 'us.collection.case/actions';
import { $MessageBox } from 'us.common/components';
import { CaseType } from 'us.helper/types/enums';
import { domainViewAction } from 'us.collection/actions';

const { AccountSummeryWidgetActions, ObjectionAndAgreementsAction } = Actions;
const { accountSummery: acSummeryActions } = AccountSummeryWidgetActions;

const {
	objection,
	cop,
	invoices,
	debtorObjection,
	objectionProfile,
	kidListByCaseNo,
} = API;

const objectionSagas = {
	cancel: function* (action: any): any {
		const params = action.payload.data;
		try {
			const data = yield call(
				objection.cancel,
				params.caseNo
			);
			yield put(
				ObjectionAndAgreementsAction.Objection.success(
					data
				)
			);
			if (!data.isError) {
				$MessageBox(
					'success',
					'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.OBJECTION_CANCELLED_SUCCESSFULLY',
					'',
					''
				);
				yield put(
					domainViewAction.metaData.success({
						...params.metaData.data,
						isObjected: false,
					})
				);
				const { caseId, entityType } =
					params.metaData.data;
				if (acSummeryActions.get) {
					yield put(
						acSummeryActions.get({
							EntityType:
								entityType ===
								CaseType.S
									? 'Subcase'
									: 'Case',
							EntityId: caseId,
						})
					);
				}
			}
		} catch (error) {
			yield put(
				ObjectionAndAgreementsAction.Objection.fail(
					error as any
				)
			);
		}
	},
	copsend: function* (action: any) {
		const params = action.payload?.data;
		try {
			const { data, status } = yield call(
				cop.save,
				params.requestParam
			);
			yield put(
				ObjectionAndAgreementsAction.claimOfPayment.success(
					data
				)
			);
			if (!data.isError && status === 201) {
				yield put(
					domainViewAction.metaData.success({
						...params.metaData,
						isObjected: true,
					})
				);
			}
			if (data?.noteId > 0 && status === 201) {
				$MessageBox(
					'success',
					'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.SAVED_SUCCESSFULLY',
					'',
					''
				);
				const { caseId, entityType } = params.metaData;
				if (acSummeryActions.get) {
					yield put(
						acSummeryActions.get({
							EntityType:
								entityType ===
								CaseType.S
									? 'Subcase'
									: 'Case',
							EntityId: caseId,
						})
					);
				}
			}
			if (
				(data?.noteId == 0 && data?.isError) ||
				status != 201
			) {
				$MessageBox(
					'error',
					'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.ERROR_OCCURRED',
					'',
					''
				);
			}
		} catch (error) {
			yield put(
				ObjectionAndAgreementsAction.claimOfPayment.fail(
					error as any
				)
			);
		}
	},
	getInvoice: function* (action: any): any {
		const params = action.payload.data;
		try {
			const data = yield call(
				invoices.getInvoices,
				params.caseId
			);
			yield put(
				ObjectionAndAgreementsAction.invoices.success(
					data
				)
			);
		} catch (error) {
			yield put(
				ObjectionAndAgreementsAction.invoices.fail(
					error as any
				)
			);
		}
	},
	dosend: function* (action: any) {
		const params = action.payload?.data;
		try {
			const { data, status } = yield call(
				debtorObjection.save,
				params.requestParam
			);
			yield put(
				ObjectionAndAgreementsAction.debtorObjection.success(
					data
				)
			);
			if (!data.isError && status === 201) {
				yield put(
					domainViewAction.metaData.success({
						...params.metaData,
						isObjected: true,
					})
				);
			}
			if (data?.noteId > 0 && status === 201) {
				$MessageBox(
					'success',
					'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.SAVED_SUCCESSFULLY',
					'',
					''
				);
				const { caseId, entityType } = params.metaData;
				if (acSummeryActions.get) {
					yield put(
						acSummeryActions.get({
							EntityType:
								entityType ===
								CaseType.S
									? 'Subcase'
									: 'Case',
							EntityId: caseId,
						})
					);
				}
			}
			if (
				(data?.noteId == 0 && data?.isError) ||
				status != 201
			) {
				$MessageBox(
					'error',
					'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.ERROR_OCCURRED',
					'',
					''
				);
			}
		} catch (error) {
			yield put(
				ObjectionAndAgreementsAction.debtorObjection.fail(
					error as any
				)
			);
		}
	},
	getObjectionProfile: function* (action: any): any {
		const params = action.payload?.data;
		try {
			const data = yield call(
				objectionProfile.get,
				params.caseId
			);
			yield put(
				ObjectionAndAgreementsAction.objectionProfile.success(
					data
				)
			);
		} catch (error) {
			yield put(
				ObjectionAndAgreementsAction.objectionProfile.fail(
					error as any
				)
			);
		}
	},
	getKidList: function* (action: any) {
		const params = action.payload?.data;
		try {
			const { data, status } = yield call(
				kidListByCaseNo.get,
				params.caseId
			);
			if (status === 200) {
				yield put(
					ObjectionAndAgreementsAction.kidList.success(
						data
					)
				);
			} else {
				yield put(
					ObjectionAndAgreementsAction.kidList.fail(
						data
					)
				);
			}
		} catch (error) {
			yield put(
				ObjectionAndAgreementsAction.kidList.fail(
					error as any
				)
			);
		}
	},
};

export default [
	takeLatest(
		ObjectionAndAgreements.CANCEL_OBJECTION_START,
		objectionSagas.cancel
	),
	takeLatest(
		ObjectionAndAgreements.CLAIM_OF_PAYMENT_START,
		objectionSagas.copsend
	),
	takeLatest(
		ObjectionAndAgreements.GET_COP_INITIAL_VALUE,
		objectionSagas.getInvoice
	),
	takeLatest(
		ObjectionAndAgreements.GET_COP_INITIAL_VALUE,
		objectionSagas.getKidList
	),
	takeLatest(
		ObjectionAndAgreements.GET_DO_INITIAL_VALUE,
		objectionSagas.getInvoice
	),
	takeLatest(
		ObjectionAndAgreements.DEBTOR_OBJECTION_START,
		objectionSagas.dosend
	),
	takeLatest(
		ObjectionAndAgreements.GET_DO_INITIAL_VALUE,
		objectionSagas.getObjectionProfile
	),
];
