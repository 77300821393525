import React from "react";
import { useTranslation } from "react-i18next";
import {
  EditOutlined,
  DeleteOutlined,
  BoxIcons,
  IconTypes,
} from "us.icons";
import { $Popconfirm } from "us.common/components";
import { ItemMenuActions } from "us.collection/components/Workflow/Constants";
import { IItemMenu } from "us.collection/components/Workflow/Interfaces";

/**
 * @description - Event list action menu.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3055714493/Manage+State+Categories
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 20/06/2023
 */
const ItemMenu: React.FC<IItemMenu> = ({
  isDownloadPopupVisible,
  onCallAction,
  onCancelChildPopup,
}) => {
  const { t } = useTranslation();

  return (
    <div className="table-more-content" data-testid="popover-content">
      <$Popconfirm
        title={t(
          "US.COLLECTION.CASE:WORKFLOWS.WORKFLOW_TOOL_IS_NOT_AVAILABLE_IN_YOUR_MACHINE"
        )}
        okText={t("US.COMMON:COMMON.DOWNLOAD")}
        onConfirm={() =>
          onCallAction(ItemMenuActions.DOWNLOAD)
        }
        onCancel={() => onCancelChildPopup()}
        visible={isDownloadPopupVisible}
        placement="topLeft"
        overlayClassName="wt-not-available"
      >
        <div
          className="d-flex flex-row more-item"
          data-testid="open-workflow-btn"
          onClick={() =>
            onCallAction(ItemMenuActions.OPEN_WORKFLOW)
          }
        >
          <div className="p-1">
            <BoxIcons
              type={IconTypes.BOX_ICON}
              name="bureau-workflows"
              viewBox="-5 10 85 30"
            />
          </div>
          <div className="py-1 pr-1">
            {t("US.COLLECTION.CASE:WORKFLOWS.OPEN_WORKFLOW")}
          </div>
        </div>
      </$Popconfirm>
      <div
        className="d-flex flex-row more-item"
        onClick={() => onCallAction(ItemMenuActions.EDIT)}
      >
        <div className="p-1">
          <EditOutlined />
        </div>
        <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.EDIT")}</div>
      </div>
      <$Popconfirm
        title={t("US.COLLECTION.CASE:WORKFLOWS.DELETE_EVENT_CONFIRM")}
        onConfirm={() => onCallAction(ItemMenuActions.DELETE)}
        onCancel={() => onCancelChildPopup()}
      >
        <div className="d-flex mb-2 flex-row more-item text-error">
          <div className="p-1">
            <DeleteOutlined />
          </div>
          <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.DELETE")}</div>
        </div>
      </$Popconfirm>
    </div>
  );
};

export default ItemMenu;
