import * as Actions from 'us.collection.transactions/constants/Actions';
import { IAPIAction } from 'us.collection/interfaces';

const {
	GET_STANDARD_INTEREST_RATE_BY_DATE,
	GET_STANDARD_INTEREST_RATE_BY_DATE_FAIL,
	GET_STANDARD_INTEREST_RATE_BY_DATE_SUCCESS,

	GET_CALCULATED_INTEREST,
	GET_CALCULATED_INTEREST_FAIL,
	GET_CALCULATED_INTEREST_SUCCESS,

	UPDATE_TRANSACTION_INTEREST_RATE,
	UPDATE_TRANSACTION_INTEREST_RATE_FAIL,
	UPDATE_TRANSACTION_INTEREST_RATE_SUCCESS,
} = Actions.InterestApplication;

export const interestApplication: Readonly<IAPIAction> = {
	standardInterestRate: {
		get: (data) => ({
			type: GET_STANDARD_INTEREST_RATE_BY_DATE,
			payload: {
				data: data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: GET_STANDARD_INTEREST_RATE_BY_DATE_SUCCESS,
			payload: {
				data: data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: GET_STANDARD_INTEREST_RATE_BY_DATE_FAIL,
			payload: {
				error: error,
				isLoading: false,
			},
		}),
	},
	transactionInterest: {
		update: (data) => ({
			type: UPDATE_TRANSACTION_INTEREST_RATE,
			payload: {
				data: data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: UPDATE_TRANSACTION_INTEREST_RATE_SUCCESS,
			payload: {
				data: data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: UPDATE_TRANSACTION_INTEREST_RATE_FAIL,
			payload: {
				error: error,
				isLoading: false,
			},
		}),
	},
	calculatedInterest: {
		get: (data) => ({
			type: GET_CALCULATED_INTEREST,
			payload: {
				data: data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: GET_CALCULATED_INTEREST_SUCCESS,
			payload: {
				data: data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: GET_CALCULATED_INTEREST_FAIL,
			payload: {
				error: error,
				isLoading: false,
			},
		}),
	},
};
