import { ICasesAndSubCasesRequestObject } from "../Interfaces";

/**
 * @function
 * @description - repository to get the API request object for case and sub-case list
 * @param {*} this - any type object
 * @return {*} - API calling object
 */
export function GetCasesAndSubCasesRequestObject(this: any): ICasesAndSubCasesRequestObject {
  return {
    creditorId: this.creditorId,
    debtorId: this.debtorId,
    arId: this.arId,
    isSimilarDebtor: false,
    returnTransferredSubCases: true,
  };
}
