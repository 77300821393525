/**
 * Set custom message to template
 * @param html - template html content
 * @param text - custom text
 */
export const setHTMLCode = (html: string, text: any) => {
  try {
    const editorId = document.getElementById("editor") as any;

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const editableDiv = tempDiv.querySelector("#onholdmessage") as HTMLElement;
    if (editableDiv) {
      editableDiv.contentEditable = "true";
      editableDiv.style.padding = "10px";
      editableDiv.style.maxWidth = "300px";
      editableDiv.style.wordWrap = "break-word";
      editableDiv.innerHTML = text;
    }
    setIframeContent(editorId, tempDiv.innerHTML);
  } catch (error) {
    console.error(error);
  }
};

/**
 * Data bind to tocken value
 * @param dataItems  - Param value
 * @param domeNodeChildren - Param name
 * @returns
 */
export const getTockenDataValue = (dataItems: any, domeNodeChildren: any) => {
  try {
    const tockenName = domeNodeChildren?.data?.toString()?.trim();
    if (dataItems) {
      if (tockenName.length) {
        return dataItems[tockenName];
      }
    }
    return "";
  } catch (error) {
    return "";
  }
};

/**
 * Get editable tempate page and page number
 * @param messageContent - Template details
 * @returns
 */
export const getEditableContent = (messageContent: Array<any>) => {
  try {
    let editableDoc = {
      editContentId: 0,
      content: messageContent[0],
    };
    messageContent.map((docPart: string, index) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = docPart;
      const editableDiv = tempDiv.querySelector(
        "#onholdmessage"
      ) as HTMLElement;
      if (editableDiv) {
        editableDoc = {
          editContentId: index,
          content: docPart,
        };
      }
    });
    return editableDoc;
  } catch {
    return {
      editContentId: 0,
      content: "",
    };
  }
};

/**
 * Check the page is editable or not
 * @param messageContent - template details
 * @returns
 */
export const isEditablePage = (messageContent: string): boolean => {
  try {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = messageContent;
    const editableDiv = tempDiv.querySelector("#onholdmessage") as HTMLElement;
    if (editableDiv) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

/**
 * Change the iframe content
 * @param elementId - Html elment
 * @param htmlContent - Html content
 */
export const setIframeContent = (elementId: any, htmlContent: string) => {
  try {
    elementId.srcdoc = htmlContent;
    elementId.src = window.location.origin;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Get page content after navigate
 * @param currentPage - Current page number
 * @param editableMessageContent - Editable message content(on hold message)
 * @param messages - Template details
 * @param isNextPage - Page is next or previous
 * @returns
 */
export const getMainContentForNavigatePage = (
  currentPage: number,
  editableMessageContent: any,
  messages: Array<string>,
  isNextPage: boolean
) => {
  try {
    if (isNextPage) {
      return editableMessageContent.editContentId == currentPage + 1
        ? editableMessageContent.content
        : messages[currentPage + 1].toString();
    } else {
      return editableMessageContent.editContentId == currentPage - 1
        ? editableMessageContent.content
        : messages[currentPage - 1].toString();
    }
  } catch (error) {
    if (isNextPage) {
      return messages[currentPage + 1].toString();
    } else {
      return messages[currentPage - 1].toString();
    }
  }
};

/**
 * Get drawer title
 * @param messageContent - Template details
 * @returns
 */
export const getDrawerTitle = (messageContent: any) => {
  try {
    return messageContent?.isLoading
      ? ""
      : `${
          messageContent?.data?.fileName
        }.${messageContent?.data?.messageType?.toLowerCase()}`;
  } catch (error) {
    return "";
  }
};

/**
 * Get last message content
 * @param templateHtmlContent - Template html content
 * @param editableMessageContent  - Editable content details
 * @returns
 */
export const getMessageContent = (
  templateHtmlContent: Array<any>,
  editableMessageContent: any
): Array<any> => {
  try {
    const messageContent: Array<any> = [];

    templateHtmlContent?.map((message: string, index: any) => {
      if (index == editableMessageContent.editContentId) {
        const editorId = document.getElementById("editor") as any;
        const innerHTML =
          editorId.contentWindow.document.documentElement.outerHTML;
        if (isEditablePage(innerHTML)) {
          messageContent.push(innerHTML);
        } else {
          messageContent.push(editableMessageContent.content);
        }
      } else {
        messageContent.push(message);
      }
    });
    return messageContent;
  } catch (error) {
    return [];
  }
};

/**
 * Get file name from file path
 * @param filePath - File path url
 * @returns 
 */
export const getFileNameFromPath = (filePath: string): string =>{
  try{
    const fileName = filePath.split("/");
    if(fileName.length > 1){
      return fileName[fileName.length - 1];
    }
    return "";
  }
  catch(error){
    return ""
  }
}
/**
 * Get blob storage documents
 * @param documentDetails - Document all details
 * @returns 
 */
export const getBlobStorageFiles = (documentDetails: any): Array<any> => {
  try {
    return documentDetails?.filter((document: any) => document?.outputInfo?.includes("az"));
  }
  catch(error){
    return []
  }
}