import React, { useMemo } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { $Button, $Popconfirm, $Tooltip } from 'us.common/components';
import { BoxIcons, IconTypes, LoadingOutlined } from 'us.icons';
import { ObjectionAndAgreementsAction } from 'us.collection.case/actions';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { appInsights } from 'us.helper';
import { RootState } from 'us.helper/types';

const CancelObjection: React.FC<PropsFromRedux> = (props) => {
	const {
		metaData,
		accountSummeryWidgetData,
		cancelObjectionResponse,
		workflow,
		cancelObjection,
	} = props;
	const { t } = useTranslation(['US.COLLECTION.CASE']);

	const { caseId, isObjected } = metaData.data ?? {};
	const { case: accCase } = accountSummeryWidgetData.data ?? {};

	const isVisible = useMemo(() => {
		const { workflowStatus } = workflow.data ?? {};
		return workflowStatus;
	}, [workflow]);

	const handleCancellation = (): void => {
		try {
			if (cancelObjection && caseId && isButtonEnabled()) {
				cancelObjection(caseId, metaData);
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard CancelObjection Exception - ${error}`
			);
		}
	};

	const isButtonEnabled = (): boolean => {
		if (metaData && accCase) {
			return isObjected ?? false;
		} else {
			return false;
		}
	};

	return (
		<>
			{isVisible && (
				<$Tooltip
					placement='top'
					title={t(
						'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.CANCELOBJECTION'
					)}>
					<$Popconfirm
						title={t(
							'US.COLLECTION.CASE:OBJECTIONANDAGREEMENT.ARE_YOU_SURE,_YOU_WANT_TO_CANCEL_OBJECTION?'
						)}
						onConfirm={() =>
							handleCancellation()
						}
						disabled={
							!isButtonEnabled() ||
							cancelObjectionResponse?.isLoading
						}>
						<$Button
							className='case-action-objections'
							type='default'
							disabled={
								!isButtonEnabled() ||
								cancelObjectionResponse?.isLoading ||
								(metaData.data &&
									isSubCaseTransferredToCase(
										metaData.data
									))
							}>
							{!cancelObjectionResponse?.isLoading && (
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='cancelObjection'
								/>
							)}
							{cancelObjectionResponse?.isLoading && (
								<LoadingOutlined
									spin
								/>
							)}
						</$Button>
					</$Popconfirm>
				</$Tooltip>
			)}
		</>
	);
};

const mapStateToProps = (state: RootState) => {
	const { objectionAndAgreements, dashboard, domainView } = state;
	const { accountSummeryWidgetData } = dashboard;
	const { metaData } = domainView;

	return {
		cancelObjectionResponse: objectionAndAgreements.cancelObjection,
		metaData,
		accountSummeryWidgetData,
		workflow: domainView.workflow,
	};
};

const { Objection } = ObjectionAndAgreementsAction;
const { cancel } = Objection;

const mapDispatchToProps = {
	cancelObjection: cancel,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CancelObjection);
