import { ISentenceSummery } from "us.collection.case/interfaces";
import { isValidDate } from ".";

/**
 * To get hte summery data arranged befoe storing in the sentence summery redux state
 * @param summery any summery value returned form the API
 * @returns valid summery array
 */
export const arrangeSummeryData = (summery: any): ISentenceSummery[] | [] => {
  if (summery && Array.isArray(summery)) {
    return summery.map((summeryItem) => ({
      ...summeryItem,
      sentenceDate: isValidDate(summeryItem.sentenceDate),
      modifiedDate: isValidDate(summeryItem.modifiedDate),
      key: summeryItem.caseNo,
    }));
  }
  return [];
};
