import { put, call, takeLatest } from "redux-saga/effects";
import * as Actions from "us.collection/actions";
import * as API from "us.collection/services";
import { FollowupFooter } from "us.collection/constants";
const { followupFooterActions } = Actions;

const followupFooterSagas = {
    nextResult: {
        get: function* (action: any): any {
            const params = action.payload?.data;
            try {
                const data = yield call(
                    API.followupService.nextResult.get, params);
                yield put(followupFooterActions.nextResult.success(data));
            } catch (error) {
                yield put(followupFooterActions.nextResult.fail(new Error()));
            }
        },
    },
    followups: {
        get: function* (action: any): any {
            const params = action.payload?.data;
            try {
                const data = yield call(
                    API.followupService.followups.get, params);

                if (Array.isArray(data)) {
                    yield put(followupFooterActions.followups.success(data));
                } else {
                    yield put(followupFooterActions.followups.fail(new Error()));
                }
            } catch (error) {
                yield put(followupFooterActions.followups.fail(new Error()));
            }
        }
    }

};
export default [
    takeLatest(
        FollowupFooter.GET_FOOTER_FOLLOWUP_NEXT_RESULT_START,
        followupFooterSagas.nextResult.get
    ),
    takeLatest(
        FollowupFooter.GET_FOOTER_FOLLOWUPS_START,
        followupFooterSagas.followups.get
    )
];