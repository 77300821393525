import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { BMDActions } from 'us.collection/actions';
import { DrawerTypes } from 'us.collection/constants';
import { $Divider, $Select, $Button, $Tooltip } from 'us.common/components';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { IBMDListFrom } from '../../Interfaces';
import { ISearchView } from './Interfaces';
import _ from 'lodash';
import { PlusOutlined, EditOutlined } from 'us.icons';
import { scrollTo } from 'virtuallist-antd';
import { BmdTable } from './Components';
import { getAllGroups } from 'us.collection/components/BMD/Functions';
import { DEFAULT_GROUP } from 'us.collection/components/BMD/Constants';
import { RootState } from 'us.helper/types';

/**
 * @description - The component used in home view of BMD List when no search is done
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2855272453/UI+BMD+-+List+Summery+View
 * @param {ISerchView & PropsFromRedux} props
 * @return {JSX.Element}
 * @author Darshana Waasala <waasalajb@unicorn-solutions.com>
 * @since 16/03/2022
 */
const SearchView: React.FC<ISearchView & PropsFromRedux> = (props) => {
	const {
		filteredCategories,
		columns,
		selectedCategory,
		selectedBmdGroup,
		editBMDGroup,
		isBureauRoute,
		changeDrawerStatus,
		entityDetails,
		metaData,
	} = props;
	const { t } = useTranslation([
		'US.COLLECTION.CASE',
		'US.COLLECTION.COMMON',
	]);
	const { values } = useFormikContext<IBMDListFrom>();
	const { bmdGroups } = selectedCategory ?? {};

	return (
		<>
			<>
				<div className='bmd-result-header pt-2 px-1'>
					<div className='d-flex'>
						<div className='d-flex flex-column mr-5'>
							<div
								className='font-weight-bold'
								data-testid='bmd-list-category'>
								{t(
									'US.COLLECTION.CASE:BMD.CATEGORY'
								)}
							</div>
							<div data-testid='bmd-list-selected-category'>
								{
									selectedCategory?.name
								}
							</div>
						</div>
						<div className='d-flex flex-fill flex-column mr-2'>
							<div className='font-weight-bold'>
								{t(
									'US.COLLECTION.CASE:BMD.DESCRIPTION'
								)}
							</div>
							<div>
								{selectedBmdGroup?.description
									? selectedBmdGroup?.description
									: '-'}
							</div>
						</div>
						<div className='d-flex align-items-center justify-content-between'>
							{isBureauRoute && (
								<>
									<$Tooltip
										title={
											values?.bmdGroup ==
												DEFAULT_GROUP ||
											selectedCategory?.id ==
												0
												? t(
														'US.COLLECTION.CASE:BMD.PLEASE_SELECT_MAIN_CATEGORY_AND_SUB_CATEGORY'
												  )
												: null
										}>
										<$Button
											type='default'
											size='small'
											data-testid='btn-bmd-add-property'
											icon={
												<PlusOutlined />
											}
											disabled={
												values?.bmdGroup ==
													DEFAULT_GROUP ||
												selectedCategory?.id ==
													0
											}
											onClick={() =>
												changeDrawerStatus &&
												changeDrawerStatus(
													{
														isVisible: true,
														title: 'US.COLLECTION.CASE:BMD.ADD_PROPERTY',
														drawerType: DrawerTypes.ADD_PROPERTY,
														recordData: {
															bmdGroupId: selectedBmdGroup?.id,
															bmdCategoryId:
																selectedCategory?.id,
															bmdSearch: values.bmdSearch,
															initialPropertyCheck:
																values.initialPropertyCheck,
														},
													}
												)
											}>
											{t(
												'US.COLLECTION.CASE:BMD.NEW_PROPERTY'
											)}
										</$Button>
									</$Tooltip>
									<$Divider
										type='vertical'
										className='mt-1'
									/>
								</>
							)}
							<div className='d-flex align-items-center'>
								<div className='d-flex flex-column'>
									<$Select
										name='bmdGroup'
										size='small'
										style={{
											width: '400px',
										}}
										options={
											bmdGroups
												? selectedCategory?.id ==
												  0
													? getAllGroups(
															filteredCategories
													  )
													: bmdGroups
												: []
										}
										optionValue={
											'name'
										}
										optionText={
											'name'
										}
										data-testid='bmd-group-select'
										onSearchBy={[
											'name',
										]}
										defaultValue={
											DEFAULT_GROUP
										}
										onChange={() => {
											try {
												scrollTo(
													{
														y: 0,
													}
												);
											} catch (e) {
												console.error(
													e
												);
											}
										}}
									/>
								</div>
								{isBureauRoute && (
									<div className='ml-1 d-flex flex-column'>
										<$Tooltip
											title={
												values?.bmdGroup ==
													DEFAULT_GROUP ||
												selectedCategory?.id ==
													0
													? t(
															'US.COLLECTION.CASE:BMD.PLEASE_SELECT_MAIN_CATEGORY_AND_SUB_CATEGORY'
													  )
													: t(
															'US.COLLECTION.COMMON:COMMON.UPDATE'
													  )
											}>
											<$Button
												type='default'
												size='small'
												disabled={
													values?.bmdGroup ==
														DEFAULT_GROUP ||
													selectedCategory?.id ==
														0
												}
												onClick={() =>
													editBMDGroup()
												}
												icon={
													<EditOutlined />
												}
											/>
										</$Tooltip>
									</div>
								)}
							</div>
						</div>
					</div>
					<$Divider className='mt-3' />
				</div>
			</>

			<BmdTable
				filteredCategories={filteredCategories}
				columns={columns}
				onDoubleClick={(record: any) => {
					if (
						metaData.data &&
						!isSubCaseTransferredToCase(
							metaData.data
						)
					) {
						changeDrawerStatus &&
							changeDrawerStatus({
								isVisible: true,
								title: 'US.COLLECTION.CASE:BMD.EDIT_BMD',
								drawerType: DrawerTypes.EDIT_BMD,
								recordData: {
									...record,
									...entityDetails,
									bmdGroupId: selectedBmdGroup?.id,
									bmdCategoryId:
										selectedCategory?.id,
									bmdSearch: values.bmdSearch,
									initialPropertyCheck:
										values.initialPropertyCheck,
								},
							});
					}
				}}
			/>
		</>
	);
};

const { bmdDataTypes } = BMDActions;

const mapStateToProps = (state: RootState) => {
	const { domainView } = state;
	const { metaData } = domainView;
	return { metaData };
};

const mapDispatchToProps = {
	changeDrawerStatus: bmdDataTypes.openDrawer,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SearchView);
