import COLLECTION_CONSTANTS from 'us.collection/constants';
import CASE_CONSTANTS from 'us.collection.transactions/constants';
import { fetchData } from 'us.helper';
import {
	httpCollection,
	versionedHttpCollection,
} from 'us.helper/http/collection';
import ServiceConfig from 'service.config.js';
import {
	IGetMappingTransactions,
	IUpdateMappingTransactions,
} from 'us.collection.transactions/interfaces';
import { isMappingSuccess } from 'us.collection.transactions/functions';
import { ITransactionInterestRate } from 'us.collection.transactions/interfaces/Transactions/InterestApplication';

export const getTransaction = async (params: any) =>
	fetchData(
		COLLECTION_CONSTANTS.server + CASE_CONSTANTS.endPoints.transactions,
		'GET',
		params
	);
export const getTransactionTypes = async (params: any) =>
	fetchData(
		COLLECTION_CONSTANTS.server + CASE_CONSTANTS.endPoints.transactionTypes,
		'GET',
		params
	);

export const getTransactions = <
	P extends {
		entityType: string;
		entityId: string;
	}
>(
	params: P
): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.get(
				'transactionService',
				'transactions',
				{
					entityType: params.entityType,
					entityId: params.entityId,
				}
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const saveTransactions = (params: any): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.post(
				'transactionservice',
				'internal/transaction',
				params
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const addCreditNote = (params: any): Promise<any> => {
	const { ArItemNo, ...rest } = params;
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await versionedHttpCollection.post(
				'transactionservice',
				`transactions/${ArItemNo}/creditnote`,
				rest,
				ServiceConfig()[`transactionservice`]['creditNote.add']
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getTransactionType = <
	P extends {
		filterType: string;
		itemType: string;
	}
>(
	params: P
): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.get(
				'transactionService',
				'transactiontypes',
				{
					filterType: params.filterType,
					itemType: params.itemType,
				}
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getMappingTransactionsByArItemNo = <
	P extends { arItemNo: string }
>(
	params: P
): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.get(
				'transactionservice',
				'LinkedTransactions',
				{
					transactionId: params.arItemNo,
				}
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const deleteTransactions = (
	id: number,
	comment: string
): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await versionedHttpCollection.patch(
				'transactionservice',
				`transaction`,
				{
					transactionId: id,
					comment: comment,
				},
				ServiceConfig()[`transactionservice`]['delete']
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getArDetails = (arId: number, update: boolean): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await versionedHttpCollection.get(
				'arservice',
				`ars/${arId}`,
				{ update },
				ServiceConfig()[`arservice`]['ars']
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getPopOverItems = <P extends { arItemNo: string | number }>(
	params: P
): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.get('transactionservice', 'test', {
				transactionId: params.arItemNo,
			});
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getPopOverValues = <
	P extends { activityCode: string | number; transactionId: number }
>(
	params: P
): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data, status } = await httpCollection.get(
				'transactionservice',
				`activity/${params.activityCode}/data/${params.transactionId}`,
				{}
			);
			resolve({
				data,
				status,
			});
		} catch (error) {
			reject(error);
		}
	});
};

export const saveCourtFeeInvoice = <P>(params: P): Promise<any> => {
	return new Promise<any>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.post(
				'transactionservice',
				`activity`,
				params
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const mapItemByItem = <P>(params: P): Promise<any> => {
	return new Promise<any>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.post(
				'transactionservice',
				`activity`,
				params
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getMappingTransactions = ({
	type,
	referenceNo,
	transGroupId,
	transactionId,
}: IGetMappingTransactions): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data, status } = await httpCollection.get(
				'transactionservice',
				`mappingtransactions?type=${type}&referenceno=${referenceNo}&transgroupId=${transGroupId}&transactionId=${transactionId}`,
				{}
			);
			resolve({
				data,
				status,
			});
		} catch (error) {
			reject(error);
		}
	});
};

export const updateMappingTransactions = ({
	mappingParams,
	searchParams,
}: {
	mappingParams: IUpdateMappingTransactions;
	searchParams: any;
}): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data, status } = await httpCollection.post(
				'transactionservice',
				'mappedtransactions',
				mappingParams
			);
			if (isMappingSuccess(data)) {
				const transactions = await httpCollection.get(
					'transactionService',
					'transactions',
					searchParams
				);
				if (transactions.status === 200) {
					resolve({
						transactionsData: transactions.data,
						data,
						status,
					});
				} else {
					resolve({
						transactionsData: null,
						data,
						status,
					});
				}
			} else {
				resolve({
					transactionsData: null,
					data,
					status,
				});
			}
		} catch (error) {
			reject(error);
		}
	});
};

// get cases for case mapping
export const getMappingCases = (params: any): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data, status } = await httpCollection.get(
				'transactionservice',
				'mappingcases',
				params
			);
			resolve({
				data,
				status,
			});
		} catch (error) {
			reject(error);
		}
	});
};

// Get interest bmd
export const getInterestBMD = (params: any): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data, status } = await versionedHttpCollection.post(
				'bmdservice',
				'bmdkeyvalue',
				params,
				ServiceConfig()[`bmdservice`]['bmdkeyvalue']
			);
			if (status === 200 || status === 204) {
				resolve({ data, status });
			} else {
				reject(data);
			}
		} catch (error) {
			reject(error);
		}
	});
};

export const getStandardInterestRateByDate = (params: {
	calculationdate: string;
}): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data, status } = await versionedHttpCollection.get(
				'transactionservice',
				'transactions/standardinterestrate',
				params,
				'v1'
			);
			if (status === 200 || status === 204) {
				resolve({ data, status });
			} else {
				reject(data);
			}
		} catch (error) {
			reject(error);
		}
	});
};

export const getCalculatedInterestRate = (
	params: ITransactionInterestRate
): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data, status } = await versionedHttpCollection.post(
				'transactionservice',
				'transactions/calculatedinterest',
				params,
				'v1'
			);
			if (status === 200 || status === 204) {
				resolve({ data, status });
			} else {
				reject(data);
			}
		} catch (error) {
			reject(error);
		}
	});
};

export const updateTransactionInterestRate = (
	params: ITransactionInterestRate
): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data, status } = await versionedHttpCollection.put(
				'transactionservice',
				'transactions/interestbasis',
				params,
				'v1'
			);
			if (status === 200 || status === 204) {
				resolve({ data, status });
			} else {
				reject(data);
			}
		} catch (error) {
			reject(error);
		}
	});
};
