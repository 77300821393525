import Constants from 'us.collection.transactions/constants';
import {
	ICase,
	IInitialState,
	ISaveResponse,
} from 'us.collection.transactions/interfaces';
import { getReduxObject } from './Functions';
import { InitialState } from './State';

const { ACTIONS } = Constants;
const { CasesAndSubCases } = ACTIONS;
const {
	GET_CASES_AND_SUBCASES,
	GET_CASES_AND_SUBCASES_SUCCESS,
	GET_CASES_AND_SUBCASES_FAIL,
	SAVE_MERGECASES,
	SAVE_MERGECASES_SUCCESS,
	SAVE_MERGECASES_FAIL,
	CREATE_CASES,
	CREATE_CASES_SUCCESS,
	CREATE_CASES_FAIL,
	MANAGE_DRAWER,
	UPDATE_DUEDATE,
	UPDATE_DUEDATE_SUCCESS,
	UPDATE_DUEDATE_FAIL,
	SPLIT_SUBCASES,
	SPLIT_SUBCASES_SUCCESS,
	SPLIT_SUBCASES_FAIL,
	SET_SELECTED_CASES_AND_SUBCASES,
} = CasesAndSubCases;

export default (state: IInitialState = InitialState, action: any) => {
	switch (action.type) {
		case GET_CASES_AND_SUBCASES:
			return getReduxObject<IInitialState, ICase[]>(
				state,
				'caseList',
				[],
				true,
				false
			);
		case GET_CASES_AND_SUBCASES_SUCCESS:
			return getReduxObject<IInitialState, ICase[]>(
				state,
				'caseList',
				action.payload.data,
				false,
				false
			);
		case GET_CASES_AND_SUBCASES_FAIL:
			return getReduxObject<IInitialState, ICase[]>(
				state,
				'caseList',
				[],
				false,
				true
			);

		case SAVE_MERGECASES:
			return getReduxObject<IInitialState, ISaveResponse>(
				state,
				'saveStatus',
				{ errorMessage: '', isError: false },
				true,
				false
			);
		case SAVE_MERGECASES_SUCCESS:
			return getReduxObject<IInitialState, ISaveResponse>(
				state,
				'saveStatus',
				action.payload.data,
				false,
				false
			);
		case SAVE_MERGECASES_FAIL:
			return getReduxObject<IInitialState, ISaveResponse>(
				state,
				'saveStatus',
				action.payload.data,
				false,
				true
			);

		case CREATE_CASES:
			return getReduxObject<IInitialState, ISaveResponse>(
				state,
				'saveStatus',
				{ errorMessage: '', isError: false },
				true,
				false
			);
		case CREATE_CASES_SUCCESS:
			return getReduxObject<IInitialState, ISaveResponse>(
				state,
				'saveStatus',
				action.payload.data,
				false,
				false
			);
		case CREATE_CASES_FAIL:
			return getReduxObject<IInitialState, ISaveResponse>(
				state,
				'saveStatus',
				action.payload.data,
				false,
				true
			);

		case MANAGE_DRAWER:
			const { title, type, isOpen } =
				action.payload?.data ?? {};
			return Object.assign({}, state, {
				...state,
				drawerStatus: {
					title,
					type,
					isOpen,
				},
			});

		case UPDATE_DUEDATE:
			return getReduxObject<IInitialState, ISaveResponse>(
				state,
				'saveStatus',
				{ errorMessage: '', isError: false },
				true,
				false
			);
		case UPDATE_DUEDATE_SUCCESS:
			return getReduxObject<IInitialState, ISaveResponse>(
				state,
				'saveStatus',
				action.payload.data,
				false,
				false
			);
		case UPDATE_DUEDATE_FAIL:
			return getReduxObject<IInitialState, ISaveResponse>(
				state,
				'saveStatus',
				action.payload.data,
				false,
				true
			);

		case SPLIT_SUBCASES:
			return getReduxObject<IInitialState, ISaveResponse>(
				state,
				'saveStatus',
				{ errorMessage: '', isError: false },
				true,
				false
			);
		case SPLIT_SUBCASES_SUCCESS:
			return getReduxObject<IInitialState, ISaveResponse>(
				state,
				'saveStatus',
				action.payload.data,
				false,
				false
			);
		case SPLIT_SUBCASES_FAIL:
			return getReduxObject<IInitialState, ISaveResponse>(
				state,
				'saveStatus',
				action.payload.data,
				false,
				true
			);
		case SET_SELECTED_CASES_AND_SUBCASES:
			return Object.assign({}, state, {
				selectedCaseAndSubCase: action.payload.data,
			});
		default:
			return state;
	}
};
