import { RoleType } from "us.collection.case/components/OtherParty/Constants";

/**
 * @description - Get filters other parties for contact person
 * @param {any} otherParties - otherParties data
 * @returns - return filterd parties
 */
export const filterOtherPartiesForCreditorManagement = (
  otherParties: any
): Array<any> => {
  try {
    return otherParties?.data?.filter(
      (other: any) => other?.roleType?.toLowerCase() === RoleType.CONTACT_PERSON
    );
  } catch (error) {
    console.error(error);
    return [];
  }
};
