import { IColumn } from "../Interfaces";

/**
 * @constant
 * @description - The list of columns in CaseAndSubCase list view
 * @type {IColumn[]} - basic colum type
 */
export const CASE_COLUMNS: IColumn[] = [
  {
    title: "CASE_NO",
    dataIndex: "caseNumber",
    width: 300,
    className: "case-no-td text-nowrap",
    customFilter: true,
  },
  {
    title: "STATE",
    dataIndex: "workflowState",
    width: 150,
    className: "text-nowrap",
    customFilter: true,
  },
  {
    title: "MAIN",
    dataIndex: "mainAmount",
    width: 150,
    className: "right-has-sort right-has-filter text-nowrap",
    customFilter: true,
    align: "right",
  },
  {
    title: "COLLECTION",
    dataIndex: "collectionFee",
    width: 150,
    className: "right-has-sort right-has-filter text-nowrap",
    customFilter: true,
    align: "right",
  },
  {
    title: "COURT",
    dataIndex: "courtFee",
    width: 130,
    className: "right-has-sort right-has-filter text-nowrap",
    customFilter: true,
    align: "right",
  },
  {
    title: "OTHER",
    dataIndex: "otherCost",
    width: 130,
    className: "right-has-sort right-has-filter text-nowrap",
    customFilter: true,
    align: "right",
  },
  {
    title: "INTEREST",
    dataIndex: "interestAmount",
    width: 130,
    className: "right-has-sort right-has-filter text-nowrap",
    customFilter: true,
    align: "right",
  },
  {
    title: "COST",
    dataIndex: "caseCost",
    width: 130,
    className: "right-has-sort right-has-filter text-nowrap",
    customFilter: true,
    align: "right",
  },
  {
    title: "PAID",
    dataIndex: "paidAmount",
    width: 300,
    className: "right-has-sort right-has-filter text-nowrap",
    customFilter: true,
    align: "right",
  },
  {
    title: "BALANCE",
    dataIndex: "balance",
    width: 140,
    className: "right-has-sort right-has-filter text-nowrap",
    customFilter: true,
    align: "right",
  },
];
