import * as Yup from "yup";
import { IDebtorObjection } from "us.collection.case/reducers/Dashboard/Interfaces";

const DOValidation = Yup.object<IDebtorObjection>().shape({
  smsInitial: Yup.string(),
  emailInitial: Yup.string(),
  emailAddress: Yup.string()
    .email("US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD")
    .when(["emailInitial"], {
      is: (emailInitial) => emailInitial != undefined,
      then: Yup.string()
        .required("US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD")
        .email("US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD"),
    }),
  smsNumber: Yup.string()
    .typeError("US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD")
    .matches(/^\d{4,15}$/g, 'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS') 
		.nullable()
    .when(["smsInitial"], {
      is: (smsInitial) => smsInitial != undefined,
      then: Yup.string()
        .required("US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD")
        .typeError("US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD")
        .matches(/^\d{4,15}$/g, 'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS') 
		    .nullable(),
    }),
  communicationMethod: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_SELECT_YOUR_COMMUNICATION_METHOD"
  ),
  caseNote: Yup.string().when(["objectionReason"], {
    is: (objectionReason) => objectionReason === "Other",
    then: Yup.string()
      .required("US.COLLECTION.VALIDATIONS:REQUIRED.CASE_NOTE_IS_REQUIRED")
      .typeError("US.COLLECTION.VALIDATIONS:REQUIRED.CASE_NOTE_IS_REQUIRED"),
  }),
});

export default DOValidation;
