import { Formik } from 'formik';
import React, { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';
import { Link, matchPath, useLocation } from 'react-router-dom';

import Common from 'us.common';
import * as Actions from 'us.collection.transactions/actions';
import { INavigationData, IRootState } from 'us.collection/interfaces';
import { ITableTreeColumns, TableRowSelection } from 'us.common/components';
import './CaseMapping.scss';

import { CommonMapping, UpdateMappingTransactions } from '../../Repository';
import { isApportionmentMapping } from '../../Functions';
import { getRouteUrl } from 'us.helper';
import { MappingCase, MappingTransaction } from '../../Interfaces';
import { FilterTypes } from '../../Constants';
import { URLType } from 'us.collection/constants';
import {
	CaseMappingTableColumn,
	CASE_MAPPING_TABLE_COLUMNS,
	CASE_RE_MAPPING_TABLE_COLUMNS,
} from './Constants';
import { ICaseMapping } from './Interfaces';
import { CaseState } from 'us.collection.transactions/constants';
import { TransactionSummaryHeader } from '../TransactionSummaryHeader';
import {
	getCaseTypeString,
	groupByParentCaseId,
	mappingCasesWithNewBalance,
	getTotalMappedAmount,
	ableToReMap,
	getItemsToMap,
	isEnableRowSelection,
} from './Functions';
const {
	$Button,
	$Divider,
	$TableTree,
	$Skeleton,
	$Tooltip,
	$Popconfirm,
	$AmountLabel,
	$Tag,
	$DateLabel,
} = Common.Components;

/**
 * @description -  AR Transaction mapping drawer
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3029336065/Item+by+Item+Mapping+in+AR+UI+Implementation
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 13/05/2022
 */
const CaseMapping: React.FC<ICaseMapping & PropsFromRedux> = memo((props) => {
	const { t } = useTranslation(['US.COLLECTION.TRANSACTIONS']);
	const {
		transactionDetails,
		caseType,
		updatedTransactions,
		defaultExpandedRowKeys,
		cases,
		updateMappingTransactions,
		onClose,
		getMappingCases,
	} = props;
	const { state, pathname } = useLocation();
	// const state = state as INavigationData;
	const [enabledReMap, setEnabledReMap] = useState<boolean>(false);

	const routeDetails: any = matchPath(pathname, {
		path: '/ar/:arNo/transactions',
		exact: false,
		strict: false,
	});

	// define the available balance for mapping
	const availableBalanceToMap =
		(transactionDetails?.isPayment
			? transactionDetails?.paid
			: transactionDetails?.balance) ?? 0;

	// get initial mapping transactions
	useEffect(() => {
		const params = CommonMapping.call(transactionDetails);
		getMappingCases(params);
	}, []);

	/**
	 * @description - Get row style class name to highlight the row
	 * @param {MappingCase} record - Table row record
	 * @returns {string} class
	 */
	const getRowClass = ({
		isMapped,
		isMappedTransaction,
	}: MappingCase): string => {
		try {
			if (isMappedTransaction && !isMapped) {
				return 'success-table-row-bg';
			} else if (!isMappedTransaction && isMapped) {
				return 'success-table-row-bg';
			} else {
				return '';
			}
		} catch (error) {
			return '';
		}
	};

	/**
	 * If the transaction is an apportionment, navigate to the economy module apportionment view to map the
	 * payment. Otherwise, enable re-mapping in the AR transaction mapping
	 */
	const handleReMapping = () => {
		// decide the re-mapping view for the transaction
		if (isApportionmentMapping(transactionDetails)) {
			// navigate to the economy module apportionment view to map the payment
			getRouteUrl.mapPayment(
				transactionDetails.paymentID,
				'apportionment'
			);
		} else {
			// enable re-mapping in the AR transaction mapping
			setEnabledReMap(true);
		}
	};

	/**
	 * @description handle submit the re-mapping item
	 * @param {any} values - form values
	 */
	const submit = (values: any) => {
		const mappings = getItemsToMap(
			_.cloneDeep(values.transactions),
			values.selectedRowKeys
		);
		const mappingParams = UpdateMappingTransactions.call({
			...transactionDetails,
			mappings,
		});
		updateMappingTransactions({
			mappingParams,
			searchParams: {
				entityType: FilterTypes.AR,
				entityId: routeDetails?.params?.arNo,
			},
		});
	};

	/**
	 * @description Reset the selected row keys and mapped transactions list .
	 * @param {any} restProps - any - this is the props that are passed to the component from the formik
	 * form.
	 */
	const resetMapping = (restProps: any) => {
		// reset the selected row keys
		restProps.setFieldValue('selectedRowKeys', []);
		// reset the mapped transactions list
		restProps.setFieldValue('transactions', cases.data);
	};

	/**
	 * @description If the row is expanded, add the caseNo to the expandedRowKeys array, otherwise remove it
	 * @param {boolean} expanded - boolean - whether the row is expanded or not
	 * @param {any} record - The row that was expanded/collapsed
	 * @param {any} restProps - This is the props that are passed to the component.
	 * @param {string[]} prevExpandedRowKeys - The array of expanded row keys.
	 */
	const handleExpandedRows = (
		expanded: boolean,
		record: any,
		restProps: any,
		prevExpandedRowKeys: string[]
	) => {
		const { parentCaseId } = record ?? {};
		let updatedRowKeys;
		if (expanded) {
			updatedRowKeys = [
				...prevExpandedRowKeys,
				`${parentCaseId}`,
			];
		} else {
			updatedRowKeys = prevExpandedRowKeys?.filter(
				(expandedRow: string) =>
					expandedRow != parentCaseId
			);
		}
		restProps.setFieldValue('expandedRowKeys', updatedRowKeys);
	};

	/**
	 * If the user has enabled the re-map feature, reset the mapping and disable the re-map feature.
	 * Otherwise, close the modal
	 * @param {any} restProps - This is the rest of the props that are passed to the component.
	 */
	const onCancelMapping = (restProps: any) => {
		if (enabledReMap) {
			resetMapping(restProps);
			setEnabledReMap(false);
		} else {
			onClose();
		}
	};

	/**
	 * @description handle item check for mapping
	 * @param {React.Key[]} selectedRowKeys - Selected row keys to map
	 * @param {any} restProps - Formik rest properties
	 */
	const onSelectItemToMap = (
		selectedRowKeys: React.Key[],
		mappingCases: MappingCase[],
		restProps: any
	) => {
		restProps.setFieldValue('selectedRowKeys', selectedRowKeys);
		if (selectedRowKeys.length > 0) {
			// get updated transactions list
			const updatedTransactions = mappingCasesWithNewBalance(
				_.cloneDeep(mappingCases),
				selectedRowKeys,
				availableBalanceToMap
			);
			// update the form field
			restProps.setFieldValue(
				'transactions',
				updatedTransactions
			);
		} else {
			resetMapping(restProps);
		}
	};

	/**
	 * If enabledReMap is true, return a TableRowSelection object, otherwise return undefined.
	 * @param {any} values - any,
	 * @param {any} restProps - any - this is the props that are passed to the component
	 * @returns A function that returns a TableRowSelection object or undefined.
	 */
	const tableRawSelection = (
		values: any,
		restProps: any
	): TableRowSelection<any> | undefined => {
		if (enabledReMap) {
			const { selectedRowKeys, transactions } = values ?? {};
			return {
				type: 'checkbox',
				selectedRowKeys,
				onChange: (
					selectedRowKeys: React.Key[],
					_selectedRows: any[]
				) =>
					onSelectItemToMap(
						selectedRowKeys,
						transactions,
						restProps
					),
				checkStrictly: true,
				fixed: true,
				hideSelectAll: true,
				getCheckboxProps: (record: MappingCase) => ({
					disabled: !isEnableRowSelection(
						transactions,
						record,
						availableBalanceToMap,
						transactionDetails?.subCaseMappingOnly
					),
				}),
			};
		} else {
			return undefined;
		}
	};

	/**
	 * @description Generate table common cells for parent child records.
	 * @param {CaseMappingTableColumn} key - Column key,
	 * @param {MappingCase} record - Table record
	 * @returns {React.ReactNode} Table cell components
	 */
	const renderCommonCells = (
		key: CaseMappingTableColumn,
		record: MappingCase
	): React.ReactNode => {
		const {
			status,
			caseState,
			closeReason,
			ref,
			regDate,
			balance,
			type,
		} = record;
		return (
			<>
				{key === CaseMappingTableColumn.STATE &&
					status && (
						<$Tooltip
							placement='topLeft'
							title={`${status} ${
								caseState ===
									CaseState.CLOSE &&
								closeReason
									? ' - ' +
									  closeReason
									: ''
							}`}>
							<$Tag
								className={`case-state tag-status-${
									caseState ===
									CaseState.OPEN
										? 'active'
										: 'close'
								}`}>
								{status}
								{caseState ===
									CaseState.CLOSE &&
									closeReason &&
									' - ' +
										closeReason}
							</$Tag>
						</$Tooltip>
					)}
				{[CaseMappingTableColumn.REG_DATE].includes(
					key
				) && <$DateLabel value={regDate} />}

				{[
					CaseMappingTableColumn.BALANCE,
					CaseMappingTableColumn.NEW_BALANCE,
				].includes(key) && (
					<$AmountLabel
						value={Number(record[key])}
					/>
				)}

				{[CaseMappingTableColumn.REF_NO].includes(
					key
				) && (
					<span>
						{ref
							? Number(ref) > 0
								? ref
								: ''
							: ''}
					</span>
				)}

				{[CaseMappingTableColumn.TYPE].includes(
					key
				) && <span>{t(getCaseTypeString(type))}</span>}
			</>
		);
	};

	/**
	 * @description Generate table columns
	 * @returns {ITableTreeColumns}
	 */
	const getTableColumns = (
		restProps: any,
		values: any
	): ITableTreeColumns => {
		const tableColumns: ITableTreeColumns = [];
		// get columns
		// if enable remapping adding new balance
		const columns: Array<any> = enabledReMap
			? CASE_RE_MAPPING_TABLE_COLUMNS
			: CASE_MAPPING_TABLE_COLUMNS;

		columns.map(
			({
				key,
				title,
				filterType,
				align,
			}: {
				key: CaseMappingTableColumn;
				title: string;
				filterType: boolean | string;
				align: string;
			}) => {
				if (key === CaseMappingTableColumn.CASE_NO) {
					const column: any = {
						key,
						align,
						dataIndex: key,
						title: title
							? t(title)
							: undefined,
						className: 'text-nowrap',
						customFilter: filterType,
						customRenderParent: (
							_text: any,
							record: MappingCase,
							_index: number
						) => {
							const {
								caseId,
								caseNo,
							} = record;
							return {
								children: (
									<div className='mr-2 text-truncate'>
										<span className='font-weight-bold'>
											{`${t(
												'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.CASE_NO'
											)} : `}
											<Link
												to={{
													pathname: `/case/${caseNo}`,
													state: {
														...(state as INavigationData),
														currentTab:
															caseId !==
															-1
																? URLType.CASE
																: URLType.SUBCASE,
													},
												}}>
												{
													caseNo
												}
											</Link>
										</span>
										<span>
											{` (${t(
												'US.COLLECTION.COMMON:COMMON.CASE_ID'
											)} : ${caseId})`}
										</span>
									</div>
								),
								key: caseNo,
								props: {
									colSpan: 1,
								},
							};
						},
						customRenderChild: (
							_text: any,
							record: MappingTransaction
						) => {
							const {
								caseId,
								caseNo,
							} = record;
							return (
								<div className='mr-2 text-truncate'>
									<span>
										{`${t(
											'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.CASE_NO'
										)} : `}
										<Link
											to={{
												pathname: `/case/${caseNo}`,
												state: {
													...(state as INavigationData),
													currentTab:
														caseId !==
														-1
															? URLType.CASE
															: URLType.SUBCASE,
												},
											}}>
											{
												caseNo
											}
										</Link>
									</span>
									<span>
										{` (${t(
											'US.COLLECTION.COMMON:COMMON.CASE_ID'
										)} : ${caseId})`}
									</span>
								</div>
							);
						},
					};
					tableColumns.push(column);
				} else {
					const column: any = {
						key,
						dataIndex: key,
						title: title
							? t(title)
							: undefined,
						className: 'text-nowrap',
						customFilter: filterType,
						align,
						customSorter: (
							a: any,
							b: any
						) => {
							if (
								[
									CaseMappingTableColumn.TYPE,
								].includes(key)
							) {
								return a.localeCompare(
									b
								);
							} else {
								return a - b;
							}
						},
						customRenderParent: (
							_text: any,
							record: MappingCase,
							_index: number
						) =>
							renderCommonCells(
								key,
								record
							),
						customRenderChild: (
							_text: any,
							record: MappingCase,
							_index: number
						) =>
							renderCommonCells(
								key,
								record
							),
					};
					tableColumns.push(column);
				}
			}
		);
		return tableColumns;
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				selectedRowKeys: [],
				transactions: cases.data ?? [],
				expandedRowKeys: defaultExpandedRowKeys,
			}}
			onSubmit={submit}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...restProps
			}: any) => {
				const totalMappedAmount = getTotalMappedAmount(
					values.transactions
				);
				return (
					<div className='transaction-mapping'>
						<TransactionSummaryHeader
							transaction={
								transactionDetails
							}
							caseType={caseType}
						/>

						<$Divider />

						<div className='mt-4 d-flex align-items-center'>
							<div>
								<h3>
									{t(
										'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.MAPPING_DETAILS'
									)}
								</h3>
							</div>
							<$Divider
								className='bui-devider'
								type='vertical'
							/>
							<div>
								{!enabledReMap &&
									Array.isArray(
										values.transactions
									) && (
										<>
											{isApportionmentMapping(
												transactionDetails
											) && (
												<$Tooltip
													placement='top'
													defaultVisible={
														false
													}
													title={t(
														'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.YOU_WILL_NAVIGATE_TO_THE_APPORTIONMENT_VIEW_TO_MAP_THIS_TRANSACTION'
													)}>
													<$Button
														id='btnApportionmentMapping'
														className='ml-3 px-4'
														size='small'
														type='default'
														onClick={
															handleReMapping
														}>
														{t(
															'US.COLLECTION.COMMON:COMMON.RE_MAP'
														)}
													</$Button>
												</$Tooltip>
											)}
											{!isApportionmentMapping(
												transactionDetails
											) &&
												values
													.transactions
													.length >
													0 && (
													<$Button
														id='btnReMap'
														className='ml-3 px-4'
														size='small'
														type='default'
														onClick={
															handleReMapping
														}>
														{t(
															'US.COLLECTION.COMMON:COMMON.RE_MAP'
														)}
													</$Button>
												)}
										</>
									)}
							</div>
						</div>
						<div>
							<$Skeleton
								loading={
									cases.isLoading
								}
								active
								paragraph={{
									rows: 2,
								}}>
								<$TableTree
									id='case-mapping-table'
									rowKey={
										'id'
									}
									data={groupByParentCaseId(
										values.transactions
									)}
									columns={getTableColumns(
										restProps,
										values
									)}
									firstColSkipFilterProps={
										0
									}
									size='small'
									className='mt-3 header-custom-tag'
									onSort={(
										sortData,
										dataSource
									) => {
										return sortData(
											dataSource
										);
									}}
									rowClassName={
										getRowClass
									}
									onFilter={(
										searchData,
										dataSource
									) => {
										return searchData(
											dataSource
										);
									}}
									filterOnType
									resetOnSourceChange
									bordered
									expandable={{
										expandedRowKeys:
											values.expandedRowKeys,
										onExpand: (
											expanded: boolean,
											record: any
										) =>
											handleExpandedRows(
												expanded,
												record,
												restProps,
												values.expandedRowKeys
											),
									}}
									rowSelection={tableRawSelection(
										values,
										restProps
									)}
									pagination={{
										defaultPageSize: 15,
									}}
								/>
							</$Skeleton>
						</div>
						<div className='drawer-footer-fixed align-content-center'>
							<div className='ml-auto'>
								{enabledReMap && (
									<$Button
										id='btnReset'
										className='mx-2'
										onClick={() => {
											resetMapping(
												restProps
											);
										}}
										disabled={
											totalMappedAmount ===
											0
										}
										loading={
											updatedTransactions.isLoading
										}>
										{t(
											'US.COMMON:COMMON.RESET'
										)}
									</$Button>
								)}
								{enabledReMap && (
									<$Popconfirm
										title={t(
											'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.ARE_YOU_SURE_YOU_WANT_TO_RE_MAP_?'
										)}
										className='mx-2'
										placement='topLeft'
										onConfirm={
											handleSubmit
										}
										okText={t(
											'US.COLLECTION.COMMON:COMMON.YES'
										)}
										cancelText={t(
											'US.COLLECTION.COMMON:COMMON.NO'
										)}>
										<$Button
											id='btnSubmit'
											disabled={
												updatedTransactions.isLoading ||
												totalMappedAmount <
													availableBalanceToMap ||
												!ableToReMap(
													values
												)
											}
											loading={
												updatedTransactions.isLoading
											}
											type='primary'>
											{t(
												'US.COLLECTION.COMMON:COMMON.RE_MAP'
											)}
										</$Button>
									</$Popconfirm>
								)}
								<$Button
									id='btnCancel'
									className='ml-2'
									onClick={() =>
										onCancelMapping(
											restProps
										)
									}>
									{t(
										'US.COLLECTION.COMMON:COMMON.CANCEL'
									)}
								</$Button>
							</div>
						</div>
					</div>
				);
			}}
		</Formik>
	);
});

const mapStateToProps = (state: IRootState) => {
	const { common, transaction } = state;
	const { currentDateFormat, currentCurrency, currentLanguage } = common;
	const {
		transactionDetails,
		isFetching,
		isMappingTransFetching,
		mapping,
	} = transaction;
	const {
		transactions,
		updatedTransactions,
		defaultExpandedRowKeys,
		cases,
	} = mapping;
	return {
		isFetching,
		isMappingTransFetching,
		currentCurrency,
		currentLanguage,
		currentDateFormat,
		transactionDetails,
		transactions,
		updatedTransactions,
		defaultExpandedRowKeys,
		cases,
	};
};
const {
	getMappingTransactions,
	updateMappingTransactions,
	getMapping,
	getMappingCases,
} = Actions.transactions;

const mapDispatchToProps = {
	getMappingTransactions,
	updateMappingTransactions,
	getLinkedTransactions: getMapping,
	getMappingCases,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CaseMapping);
