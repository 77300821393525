import React from "react";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { Formik } from "formik";
import { IEmail } from "us.collection.case/components/MessageTemplates/Interface";
import { EmailValidationSchema } from "./Validation";

const {
  $Button,
  $TextEditor,
  $Input,
  $Breadcrumb,
  $Affix,
  $PageHeader,
  $Form,
} = Common.Components;

const Email: React.FC<IEmail> = (props) => {
  const { t } = useTranslation();
  const { onSendEmail, onClose, item, to, isSend } = props;
  const { templateName } = item;

  return (
    <Formik
      initialValues={{
        to,
        subject: templateName,
        attchementName: `${templateName} ${t(
          "US.COLLECTION.CASE:MASSAGETEMPLATES.TEMPLATE_ATTACHED"
        )}`,
        body: "",
      }}
      onSubmit={onSendEmail}
      validationSchema={EmailValidationSchema}
      validateOnChange
      validateOnBlur
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        ...rest
      }: any) => (
        <$Form onSubmit={handleSubmit}>
          <div className="msg-template-send-email">
            <div className="mtse-affix">
              <$Breadcrumb>
                <$Breadcrumb.Item>
                  <a onClick={onClose}>
                    {t("US.COLLECTION.CASE:MASSAGETEMPLATES.TEMPLATE_EDITOR")}
                  </a>
                </$Breadcrumb.Item>
                <$Breadcrumb.Item>
                  {t("US.COLLECTION.CASE:MASSAGETEMPLATES.SEND_EMAIL")}
                </$Breadcrumb.Item>
              </$Breadcrumb>
              <div className="page-header">
                <div className="d-flex align-items-center">
                  <$PageHeader
                    data-testid="sendEmailBack"
                    className="px-0 py-0 mr-n3"
                    onBack={onClose}
                    title={t("US.COLLECTION.CASE:MASSAGETEMPLATES.SEND_EMAIL")}
                  />
                </div>
              </div>
            </div>

            <div
              className="dom-amt-list-table mb-3"
              style={{ tableLayout: "fixed" }}
            >
              <div className="list-table-row">
                <div className="list-table-col" style={{ width: 100 }}>
                  <span>{t("US.COLLECTION.CASE:MASSAGETEMPLATES.TO")}</span>
                </div>
                <div className="list-table-col">
                  <div className="d-flex flex-column">
                    <$Input name="to" size="small" />
                  </div>
                </div>
              </div>
              <div className="list-table-row">
                <div className="list-table-col" style={{ width: 100 }}>
                  <span>
                    {t("US.COLLECTION.CASE:MASSAGETEMPLATES.SUBJECT")}
                  </span>
                </div>
                <div className="list-table-col">
                  <div className="d-flex flex-column">
                    <$Input name="subject" size="small" />
                  </div>
                </div>
              </div>
              <div className="list-table-row">
                <div className="list-table-col" style={{ width: 100 }}>
                  <span>
                    {t("US.COLLECTION.CASE:MASSAGETEMPLATES.ATTACHED")}
                  </span>
                </div>
                <div className="list-table-col">
                  <div className="d-flex flex-column">
                    <$Input
                      name="attchementName"
                      size="small"
                      className="w-100"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>

            <$TextEditor
              name="body"
              value={values.body}
              handleChange={(content: any) =>
                rest.setFieldValue("body", content)
              }
              style={{ height: "calc(100vh - 410px)" }}
            />
          </div>

          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Button
                className="mr-2"
                type="primary"
                onClick={handleSubmit}
                loading={isSend}
              >
                {t("US.COLLECTION.CASE:MASSAGETEMPLATES.SEND")}
              </$Button>
              <$Button onClick={onClose}>
                {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
              </$Button>
            </div>
          </div>
        </$Form>
      )}
    </Formik>
  );
};
export default Email;
