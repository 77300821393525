import _ from "lodash";
import moment from "moment";
import { IntlCurrency } from "us.common/functions";
import { appInsights } from "us.helper";
import { DocumentType } from "us.collection.routines-and-activities/constants";

type interestDetail = {
  caseId: string;
  startDate: string;
  endDate: string;
  baseAmount: string;
  noOfDays: string;
  rate: string;
  amount: string;
  transType: string;
  createdBy: string;
};

export const subcasesInterestObjectMaker = (data: any) => {
  try {
    let temData: any = [];
    Object.values(_.groupBy(data?.interestDetails, "caseId")).map(
      (item: any) => {
        temData = _.concat(temData, item);
      }
    );
    let caseAmount = parseFloat(
      _.sum(temData.map((o: any) => o.amount).map(Number)).toFixed(2)
    );

    return {
      ...data,
      caseAmount,
    };
  } catch (error) {
    appInsights.trackException(
      `Error in subcasesInterestObjectMaker function. - ${error}`
    );
    return {
      ...data,
    };
  }
};

export const casesInterestObjectMaker = (data: any) => {
  try {
    let temData: any = [];
    Object.values(_.groupBy(data?.interestDetails, "caseId")).map(
      (item: any, index: number) => {
        item.map((or: any) => {
          temData.push({
            key: index,
            caseId: or?.caseId,
            startDate: "",
            endDate: "",
            baseAmount: "",
            noOfDays: "",
            rate: "",
            amount: "",
            transType: "",
            sameCaseAmount: parseFloat(
              _.sum(item.map((o: any) => o.amount).map(Number)).toFixed(2)
            ),
            createdBy: "",
            children: item,
          });
        });
      }
    );
    let uniqueArr = _.uniqBy(temData, "caseId");
    let caseAmount = parseFloat(
      _.sum(uniqueArr.map((o: any) => o.sameCaseAmount).map(Number)).toFixed(2)
    );
    return {
      ...data,
      caseAmount,
      interestDetails: uniqueArr,
    };
  } catch (error) {
    appInsights.trackException(
      `Error in casesInterestObjectMaker function. - ${error}`
    );
    return {
      ...data,
    };
  }
};

export const generatePersonNo = (birthDate: string) => {
  return moment(birthDate).format("DDMMYY");
};

const generateBirthDate = (personNo: string) => {
  let day = parseInt(personNo.substring(0, 2));
  let month = parseInt(personNo.substring(2, 4));
  let year = personNo.substring(4, 6);
  let date: Date = new Date(moment().format());
  let yearCode: number = 0;
  try {
    if (personNo.length >= 9) {
      let centryCode = personNo.substring(6, 9);
      if (parseInt(centryCode) >= 0 && parseInt(centryCode) <= 499) {
        yearCode = parseInt(`19${year}`);
      } else if (parseInt(centryCode) >= 500 && parseInt(centryCode) <= 999) {
        yearCode = parseInt(`20${year}`);
      }
      date = new Date(yearCode, +month - 1, day);
    } else if (personNo.length === 6) {
      let currentYear = new Date().getFullYear().toString().substring(0, 2);
      let assumeYear = parseInt(currentYear + year);
      if (assumeYear > new Date().getFullYear()) {
        assumeYear = assumeYear - 100;
      }
      date = new Date(assumeYear, +month - 1, day);
    }
    return {
      date,
      month,
      day,
    };
  } catch (error) {
    appInsights.trackException(
      `Error in generateBirthDate function. - ${error}`
    );
    return {
      date,
      month,
      day,
    };
  }
};
const validatePersonNoLength = (
  personNo: string
): {
  isVaild: boolean;
  numberLength: number;
} => {
  let response = {
    isVaild: false,
    numberLength: 0,
  };
  if (personNo.length > 11) {
    response = {
      isVaild: false,
      numberLength: personNo.length,
    };
  } else if (personNo.length === 11) {
    response = {
      isVaild: true,
      numberLength: personNo.length,
    };
  } else if (personNo.length >= 9) {
    response = {
      isVaild: true,
      numberLength: personNo.length,
    };
  } else if (personNo.length === 6) {
    response = {
      isVaild: true,
      numberLength: personNo.length,
    };
  } else {
    response = {
      isVaild: false,
      numberLength: personNo.length,
    };
  }
  return response;
};

export const validatePersonNo = (
  personNumber: number | string
): {
  isValid: boolean;
  birthDate: string;
} => {
  let personNo = personNumber.toString();
  let validLength = validatePersonNoLength(personNo);
  let response = {
    isValid: false,
    birthDate: "",
  };
  try {
    if (validLength.isVaild) {
      let bDate = generateBirthDate(personNo);
      if (validLength.numberLength >= 9) {
        response = {
          isValid:
            Boolean(+bDate?.date) &&
            bDate?.date.getDate() == bDate.day &&
            bDate.date.getMonth() + 1 == bDate.month,
          birthDate: bDate.date.toString(),
        };
      } else if (validLength.numberLength === 6) {
        response = {
          isValid:
            Boolean(+bDate?.date) &&
            bDate?.date.getDate() == bDate.day &&
            bDate.date.getMonth() + 1 == bDate.month,
          birthDate: bDate.date.toString(),
        };
      }
    }
    if (
      moment().endOf("day") < moment(response.birthDate) &&
      response.isValid
    ) {
      return {
        isValid: false,
        birthDate: "",
      };
    }
    return response;
  } catch (error) {
    appInsights.trackException(
      `Error in validatePersonNo function in otherParty. - ${error}`
    );
    return response;
  }
};

export const combineAddress = (data: any) => {
  let address: string = "";
  try {
    if (data?.address1 && data?.address1.length > 0) {
      address = `${data.address1},`;
    }
    if (data?.address2 && data?.address2.length > 0) {
      address = address.concat(` ${data.address2},`);
    }

    if (data?.address3 && data?.address3.length > 0) {
      address = address.concat(` ${data.address3}`);
    } else {
      address = address.slice(0, -1);
    }
    return address;
  } catch (error) {
    appInsights.trackException(`Error in combineAddress function. - ${error}`);
    return address;
  }
};

/**
 * concat two values and filter undefined
 * @param value1 param 01
 * @param value2 param 02
 * @returns string
 */
export const combineTwoFields = (value1: any, value2: any) => {
  let result: string = "";
  try {
    if (value1 && value1?.toString().length > 0) {
      if (value2 && value2.toString().length > 0) {
        result = `${value1} ${value2}`;
      } else {
        result = value1;
      }
    } else if (value2) {
      result = value2;
    }
    return result;
  } catch (error) {
    appInsights.trackException(
      `Error in combineTwoFields function. - ${error}`
    );
    return result;
  }
};

/**
 * Create Contact Object using phoneNumbers array that is given by entity detail response
 * @param data phoneNumbers array
 * @returns contact Object
 */
export const setContactNOs = (data: any[]) => {
  let nums: any = {};
  try {
    data?.map((item: any) => {
      nums[item.type] = item.number;
    });
    return nums;
  } catch (error) {
    appInsights.trackException(
      `Error in making telnumbers array making in setContactNOs function in Other party. - ${error}`
    );
    return nums;
  }
};
const filterAmountFormat = (
  amount: string,
  currentLanguage: string,
  currentCurrency: string
): string => {
  let currentAmount = IntlCurrency(amount, currentLanguage, currentCurrency);
  try {
    if (
      amount.charAt(amount.length - 2) === "," ||
      amount.charAt(amount.length - 2) === "."
    ) {
      currentAmount = currentAmount.substr(0, currentAmount.length - 1);
    } else if (
      !(
        amount.charAt(amount.length - 3) === "," ||
        amount.charAt(amount.length - 3) === "."
      )
    ) {
      currentAmount = currentAmount.substr(0, currentAmount.length - 3);
    }
    return currentAmount;
  } catch (error) {
    appInsights.trackException(
      `Error in filterAmountFormat function. - ${error}`
    );
    return currentAmount;
  }
};

export const filterGroupData = (
  interest: any,
  filter: interestDetail,
  currentDateFormat: string,
  currentLanguage: string,
  currentCurrency: string
) => {
  try {
    const temInterest = interest?.interestDetails?.filter((iItem: any) => {
      let matched = true;
      if (filter.caseId != "" && filter.caseId != undefined) {
        if (iItem.caseNo.toString() != filter.caseId) {
          matched = false;
        }
      }
      if (filter.startDate != "" && filter.startDate != undefined) {
        if (
          moment(iItem.startDate).format(currentDateFormat) !=
            moment(filter.startDate).format(currentDateFormat) &&
          !moment(iItem.startDate)
            .format(currentDateFormat)
            .includes(filter.startDate)
        ) {
          matched = false;
        }
      }
      if (filter.endDate != "" && filter.endDate != undefined) {
        if (
          moment(iItem.endDate).format(currentDateFormat) !=
            moment(filter.endDate).format(currentDateFormat) &&
          !moment(iItem.endDate)
            .format(currentDateFormat)
            .includes(filter.endDate)
        ) {
          matched = false;
        }
      }
      if (filter.baseAmount != "" && filter.baseAmount != undefined) {
        if (
          parseFloat(iItem.baseAmount).toFixed(2).indexOf(filter.baseAmount) ==
            -1 &&
          IntlCurrency(iItem.baseAmount, currentLanguage, currentCurrency)
            .replace(/\s+/g, "")
            .indexOf(filter.baseAmount.replace(/\s+/g, "")) == -1
        ) {
          matched = false;
        }
      }
      if (filter.noOfDays != "" && filter.noOfDays != undefined) {
        if (iItem.noOfDays != filter.noOfDays) {
          matched = false;
        }
      }
      if (filter.rate != "" && filter.rate != undefined) {
        if (parseFloat(iItem.rate) != parseFloat(filter.rate)) {
          matched = false;
        }
      }
      if (filter.amount != "" && filter.amount != undefined) {
        if (
          parseFloat(iItem.amount).toFixed(2).indexOf(filter.amount) == -1 &&
          IntlCurrency(iItem.amount, currentLanguage, currentCurrency)
            .replace(/\s+/g, "")
            .indexOf(filter.amount.replace(/\s+/g, "")) == -1
        ) {
          matched = false;
        }
      }
      if (filter.transType != "" && filter.transType != undefined) {
        if (
          !iItem.transType
            .toLowerCase()
            .includes(filter.transType.toLowerCase())
        ) {
          matched = false;
        }
      }
      if (filter.createdBy != "" && filter.createdBy != undefined) {
        if (
          iItem.createdBy.toLowerCase().includes(filter.createdBy.toLowerCase())
        ) {
          matched = false;
        }
      }
      return matched;
    });
    return {
      ...interest,
      interestDetails: temInterest,
    };
  } catch (error) {
    appInsights.trackException(
      `Error in filterGroupData function of interest data. - ${error}`
    );
    return {
      ...interest,
    };
  }
};

export const getLookUpTypeByCaseType = (
  caseType: string,
  entityType: string
) => {
  switch (caseType) {
    case "case":
      return entityType;
    case "ar":
      return "A";
    case "creditor":
      return "CRE";
    case "bureau":
      return "B";
    case "payment":
      return "P";
    default:
      return "";
  }
};

export const attachementType = (data: string): string => {
  let type: string = "application/pdf";
  switch (data?.toLowerCase()) {
    case DocumentType.PDF:
      type = "application/pdf";
      break;
    case DocumentType.DOC:
      type = "text/doc";
      break;
    case DocumentType.HTML:
      type = "text/html";
      break;
    case DocumentType.PNG:
      type = "image/png";
      break;
    case DocumentType.JPG:
      type = "image/jpg";
      break;
    case DocumentType.JPEG:
      type = "image/jpeg";
      break;
    case DocumentType.TXT:
      type = "text/plain";
      break;
    case DocumentType.XML:
      type = "application/xml";
      break;
    default:
      break;
  }
  return type;
};

export const GetEntityDetailsForCOP = (entity: any) => {
  try {
    const debtorSMS = entity?.debtorDetails?.phoneNumbers?.find(
      (x: any) => x.type === "sms"
    );
    const debtorEmail = entity?.debtorDetails?.email;
    const creditorName =
      entity?.creditorDetails?.firstName?.length > 0
        ? `${entity?.creditorDetails?.firstName} ${entity?.creditorDetails?.lastName}`
        : entity?.creditorDetails?.lastName;
    return {
      debtorSMS,
      debtorEmail,
      creditorName,
    };
  } catch (error) {
    appInsights.trackException(
      `Error in GetEntityDetailsForCOP function in Objection view. - ${error}`
    );
    return {};
  }
};

/**
 * Filter role type according to level
 * @param data Role types
 */
export const filterRoleType = (
  currentTab: string,
  data: { roleName: string; roleId: number }[]
) => {
  try {
    if (currentTab.toString() === "creditor") {
      return data.filter(
        (item: { roleName: string; roleId: number }) =>
          item.roleName !== "Employer"
      );
    } else {
      return data;
    }
  } catch (error) {
    appInsights.trackException(
      `Error in filterRoleType function according to level in OtherParty view. - ${error}`
    );
    return data;
  }
};
