import moment from "moment";

export function ClaimOfPaymentRequest(this: any, boxCaseNumber: number| undefined, table: any, fileListArray: Array<any> ){
    return{
        caseId: boxCaseNumber,
        paidAmount: this.amountPaid,
        paidDate: moment(this.paidDate).format("YYYY-MM-DD"),
        fromAccount: this.paidFromAccount,
        toAccount: this.paidToAccount,
        kid: this.kidNumber,
        caseNote: this.caseNote,
        communicationType: this.communicationMethod,
        cardNumber: this.cardNumber,
        payerName: this.payerName,
        paymentMethod: this.paymentMethod,
        smsNumber: this.smsNumber,
        email: this.emailAddress,
        isPrincipal: true,
        invoiceList: table,
        fileList: fileListArray,
    }
}

export function DebtorObjectionRequest(this: any, boxCaseNumber: number| undefined, table: any, selectReasonId: number){
    return{
        caseId: boxCaseNumber,
        caseNote: this.caseNote,
        objectionReasonId: selectReasonId,
        communicationType: this.communicationMethod,
        smsNumber: this.smsNumber,
        email: this.emailAddress,
        invoices: table
    }
}

export function InitialDataCOP(this: any){
    return{
        caseProps: this.caseProps,
        caseId: this.caseId,
        entity: this.entity
    }
}

export function InitialDataDO(this: any){
    return{
        caseProps: this.caseProps,
        caseId: this.caseId,
        debtor: this.debtor
    }
}