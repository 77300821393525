import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from "service.config.js";

export default {
  assets: {
    get: async (entityId: number, entityType: string): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        let params = {
          entityId: entityId,
          entityType: entityType,
        };
        try {
          const { data, status } = await versionedHttpCollection.get(
            "assetservice",
            "assets",
            params,
            ServiceConfig()[`assetservice`]["assets"]
          );
          if (status === 200 || status === 204 || status === 404) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    delete: async (assetId: number, assetType: string): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        let payload = {
          AssetId: assetId,
          AssetType: assetType,
        };
        try {
          const { data, status } = await versionedHttpCollection.patch(
            "assetservice",
            "assets",
            payload,
            ServiceConfig()[`assetservice`]["assets"]
          );
          if (status === 200 || status === 404) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  saveRealEstateAssets: async (params: any): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.post(
          "assetservice",
          "assets/realestates",
          params,
          ServiceConfig()[`assetservice`]["assets"]
        );
        if (status === 200 || status === 201) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  getRealEstateAssets: async (assetId: number): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.getById(
          "assetservice",
          "assets/realestates",
          assetId,
          ServiceConfig()[`assetservice`]["assets"]
        );
        if (status === 200 || status === 404) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  UpdateRealEstateAssets: async (params: any): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.putById(
          "assetservice",
          "assets/realestates",
          params.assetId,
          params,
          ServiceConfig()[`assetservice`]["assets"]
        );
        if (status === 200 || status === 404) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  savePersonalEffectsAssets: async (params: any): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.post(
          "assetservice",
          "assets/personaleffects",
          params,
          ServiceConfig()[`assetservice`]["assets"]
        );
        if (status === 200 || status === 201) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  getPersonalEffectsAssets: async (assetId: number): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.getById(
          "assetservice",
          "assets/personaleffects",
          assetId,
          ServiceConfig()[`assetservice`]["assets"]
        );
        if (status === 200 || status === 404) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  UpdatePersonalEffetsAssets: async (params: any): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.putById(
          "assetservice",
          "assets/personaleffects",
          params.assetId,
          params,
          ServiceConfig()[`assetservice`]["assets"]
        );
        if (status === 200 || status === 404) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  saveDocumentOfTitleAssets: async (params: any): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.post(
          "assetservice",
          "assets/documentsoftitle",
          params,
          ServiceConfig()[`assetservice`]["assets"]
        );
        if (status === 200 || status === 201) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  getDocumentOfTitleAssets: async (assetId: number): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.getById(
          "assetservice",
          "assets/documentsoftitle",
          assetId,
          ServiceConfig()[`assetservice`]["assets"]
        );
        if (status === 200 || status === 404) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  UpdateDocumentOfTitleAssets: async (params: any): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.putById(
          "assetservice",
          "assets/documentsoftitle",
          params.assetId,
          params,
          ServiceConfig()[`assetservice`]["assets"]
        );
        if (status === 200 || status === 404) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  saveVehicleAssets: async (params: any): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.post(
          "assetservice",
          "assets/vehicles",
          params,
          ServiceConfig()[`assetservice`]["assets"]
        );
        if (status === 200 || status === 201) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  getVehicleAssets: async (assetId: number): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.getById(
          "assetservice",
          "assets/vehicles",
          assetId,
          ServiceConfig()[`assetservice`]["assets"]
        );
        if (status === 200 || status === 404) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  UpdateVehicleAssets: async (params: any): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.putById(
          "assetservice",
          "assets/vehicles",
          params.assetId,
          params,
          ServiceConfig()[`assetservice`]["assets"]
        );
        if (status === 200 || status === 404) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  saveFinancialAssets: async (params: any): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.post(
          "assetservice",
          "assets/financials",
          params,
          ServiceConfig()[`assetservice`]["assets"]
        );
        if (status === 200 || status === 201) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  getFinancialAssets: async (assetId: number): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.getById(
          "assetservice",
          "assets/financials",
          assetId,
          ServiceConfig()[`assetservice`]["assets"]
        );
        if (status === 200 || status === 404) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  updateFinancialAssets: async (params: any): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.putById(
          "assetservice",
          "assets/financials",
          params.assetId,
          params,
          ServiceConfig()[`assetservice`]["assets"]
        );
        if (status === 200 || status === 404) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
};
