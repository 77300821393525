import { IKeyName } from "./Interfaces";

export const KEY_NAMES: IKeyName[] = [
  {
    className: "bureau",
    displayName: "BU",
    overrideLevel: "BU",
    toolTip: "BUREAU",
  },
  {
    className: "creditor-group",
    displayName: "CG",
    overrideLevel: "CG",
    toolTip: "CREDITOR-GROUP",
  },
  {
    className: "creditor",
    displayName: "CR",
    overrideLevel: "CR",
    toolTip: "CREDITOR",
  },
  {
    className: "AR",
    displayName: "AR",
    overrideLevel: "AR",
    toolTip: "AR",
  },
  {
    className: "case-subcase",
    displayName: "CA/SC",
    overrideLevel: "CA",
    toolTip: "CASE/SUBCASE",
  },
];
