import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CaseInformationAction } from 'us.collection.case/actions';
import Common from 'us.common';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import {
	ICaseInformationModel,
	ICaseInformation,
} from 'us.collection.case/reducers/CaseInformation/Interfaces';
import { ObsoleteInterestDrawer, ObsoluteInterestLink } from './Components';
import { IReturnAction, IMetaData } from 'us.collection/interfaces';
import { GetContent } from './Components/GetContent';
import { GetFormikCaseInfo, GetValidDate } from './Functions/GetFormikCaseInfo';
import {
	getDatePickersCol1,
	getDatePickersCol2,
} from './Functions/GetDatePickers';
import { Moment } from 'moment';
import { ControllerButtons } from './Components/ControllerButtons';
import { ICaseInfoUpdateModel } from 'us.collection.case/reducers/CaseInformation/Interfaces/ICaseInfoUpdate';
import { ICaseInfoPayloadActionData } from 'us.collection.case/interfaces/CaseInformation';
import { StandardDateFromat } from './Constants';
import { handleDateChange } from './Functions/HandleDateChange';
import { disabledDate } from './Functions/GetDissabledDate';
import { CaseInformationValidator } from './Validations';
import { IDataModel } from 'us.collection.case/reducers/Dashboard/Interfaces';
import { RouteComponentProps } from 'react-router-dom';
import { RootState } from 'us.helper/types';
interface IPropertiesDetail
	extends IStateProp,
		IDispatcher,
		RouteComponentProps {
	minimize: (url: string) => void;
}

const {
	$TextArea,
	$Switch,
	$DatePicker,
	$PageHeader,
	$Skeleton,
	$Affix,
	$Form,
	$Row,
	$Col,
} = Common.Components;
const CaseInformation: React.FC<IPropertiesDetail> = (props) => {
	const { t } = useTranslation(
		['US.COLLECTION.CASE', 'US.COLLECTION.COMMON'],
		{
			useSuspense: true,
		}
	);
	const {
		caseInfo,
		updatedCaseInfo,
		GetCaseInformation,
		UpdateCaseInformation,
		currentDateFormat,
		locationState,
		getObsoleteInterest,
		metaData,
		history,
		location,
	} = props;

	const { caseId, caseNo } = metaData.data ?? {};

	useEffect(() => {
		if (caseId && caseId > 0) {
			getInitialValues();
			getObsoleteInterest(caseId);
		}
	}, [caseId]);

	useEffect(() => {
		formikInitialVAlues = GetFormikCaseInfo(caseInfo);
	}, [caseInfo, currentDateFormat, updatedCaseInfo]);

	const minimize = () => {
		history.push({ ...location, pathname: `/case/${caseNo}` });
	};

	const getInitialValues = () => {
		if (GetCaseInformation) {
			GetCaseInformation({
				caseId,
			});
		} else {
			console.error('Case/Sub-Case number is not valid');
		}
	};

	const onSaveClick = (values: ICaseInformation) => {
		const updateRequestObj: Partial<ICaseInformation> = {
			caseId: values.caseId,
			applyInterest: values.applyInterest,
			caseDescription: values.caseDescription,
			interestStopDate:
				GetValidDate(values.interestStopDate)?.format(
					StandardDateFromat
				) ?? '',
			isBlockedForNewClaims: values.isBlockedForNewClaims,
			obsoleteDate:
				GetValidDate(values.obsoleteDate)?.format(
					StandardDateFromat
				) ?? '',
			lastBODate:
				GetValidDate(values.lastBODate)?.format(
					StandardDateFromat
				) ?? '',
			applyObseleteInterestFeature:
				values.applyObseleteInterestFeature,
		};
		const updateInfoObj: ICaseInfoPayloadActionData = {
			updatedCaseInfo: updateRequestObj,
			saveCaseInfo: {
				...(caseInfo.data as ICaseInformation),
				...updateRequestObj,
			},
		};
		if (UpdateCaseInformation) {
			UpdateCaseInformation(updateInfoObj);
		}
	};

	const [drawerVisible, setIsDrawerVisible] = useState<boolean>(false);
	const drawerOpen = () => {
		setIsDrawerVisible(true);
	};
	const drawerClose = () => {
		setIsDrawerVisible(false);
	};

	let formikInitialVAlues: ICaseInformation | {} =
		GetFormikCaseInfo(caseInfo);

	return (
		<Formik
			enableReinitialize
			initialValues={formikInitialVAlues}
			validationSchema={CaseInformationValidator}
			onSubmit={(values: any, actions: any) => {}}
			validateOnChange>
			{({
				values,
				setValues,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				handleReset,
				...rest
			}: any) => (
				<div className='space-content'>
					<$Affix offsetTop={80}>
						<div className='page-header header-border'>
							<div className='d-flex flex-row align-items-center justify-content-between'>
								<div className='d-flex align-items-center'>
									<$PageHeader
										className='px-0'
										title={t(
											'US.COLLECTION.CASE:CASEINFORMATION.CASE_INFORMATION'
										)}
										onBack={
											minimize
										}
									/>
									{/* <$Divider className="bui-devider" type="vertical" /> */}
									<ControllerButtons
										onSaveClick={
											onSaveClick
										}
										isSaving={
											updatedCaseInfo.isLoading
										}
										handleReset={
											handleReset
										}
										setValues={
											setValues
										}
										values={
											values
										}
										reduxIsEditing={
											updatedCaseInfo.isEditing
										}
									/>
								</div>
							</div>
						</div>
					</$Affix>
					<GetContent state={caseInfo}>
						<$Skeleton
							loading={
								caseInfo.isLoading
							}
							active
							paragraph={{ rows: 2 }}>
							<$Form name='newDebtorInfo'>
								<$Row
									gutter={
										18
									}
									className='mt-3'>
									<$Col
										className='gutter-row'
										lg={{
											span: 12,
										}}
										xl={{
											span: 8,
										}}
										xxl={{
											span: 4,
										}}>
										{getDatePickersCol1(
											values.isEditing
										).map(
											(
												datePicker,
												index
											) => {
												return (
													<div className='mb-1'>
														<$DatePicker
															name={
																datePicker.name
															}
															placeholder={
																datePicker.isDissabled
																	? ''
																	: t(
																			'US.COLLECTION.CASE:CASEINFORMATION.SELECT_DATE'
																	  )
															}
															label={
																datePicker.label
															}
															format={
																currentDateFormat
															}
															value={
																values[
																	datePicker
																		.name
																]
															}
															style={{
																width: '100%',
															}}
															size='small'
															disabled={
																datePicker.isDissabled
															}
															key={
																index +
																datePicker.name
															}
															allowClear
															disabledDate={(
																currentValue: Moment
															) =>
																disabledDate(
																	currentValue,
																	datePicker.name,
																	values.caseDueDate
																)
															}
															onChange={(
																momentTime: Moment,
																stringTime: string
															) => {
																handleDateChange(
																	stringTime,
																	datePicker.name,
																	setValues,
																	currentDateFormat
																);
															}}
														/>
													</div>
												);
											}
										)}
									</$Col>

									<$Col
										className='gutter-row'
										lg={{
											span: 12,
										}}
										xl={{
											span: 8,
											offset: 0,
										}}
										xxl={{
											span: 4,
											offset: 1,
										}}>
										<div className='mb-1'>
											<div
												style={{
													marginTop: '27px',
													marginBottom:
														'12px',
												}}
												className='d-flex flex-1 align-items-top'>
												<div className='mr-2'>
													<$Switch
														name='isObjected'
														checked={
															values.isisObjected
														}
														disabled
													/>
												</div>
												<div className='d-flex flex-column'>
													<span>
														{t(
															'US.COLLECTION.CASE:CASEINFORMATION.IS_OBJECTED'
														)}
													</span>
												</div>
											</div>
										</div>

										{getDatePickersCol2(
											values.isEditing
										).map(
											(
												datePicker,
												index
											) => {
												return (
													<div className='mb-1'>
														<$DatePicker
															name={
																datePicker.name
															}
															placeholder={
																datePicker.isDissabled
																	? ''
																	: t(
																			'US.COLLECTION.CASE:CASEINFORMATION.SELECT_DATE'
																	  )
															}
															label={
																datePicker.label
															}
															format={
																currentDateFormat
															}
															value={
																values[
																	datePicker
																		.name
																]
															}
															style={{
																width: '100%',
															}}
															size='small'
															disabled={
																datePicker.isDissabled
															}
															key={
																index +
																datePicker.name
															}
															allowClear
															onChange={(
																momentTime: Moment,
																stringTime: string
															) => {
																handleDateChange(
																	stringTime,
																	datePicker.name,
																	setValues,
																	currentDateFormat
																);
															}}
														/>
													</div>
												);
											}
										)}

										<div className='mb-1'>
											<div
												style={{
													marginTop: '18px',
												}}
												className='mb-2 d-flex flex-1 align-items-top'>
												<div className='mr-2'>
													<$Switch
														name='applyInterest'
														disabled={
															!values.isEditing
														}
														checked={
															values.applyInterest
														}
													/>
												</div>
												<div className='d-flex flex-column'>
													<span>
														{t(
															'US.COLLECTION.CASE:CASEINFORMATION.APPLY_INTEREST'
														)}
													</span>
												</div>
											</div>
										</div>

										<div className='mb-1'>
											<div className='mb-2 d-flex flex-1 align-items-top'>
												<div className='mr-2'>
													<$Switch
														name='isBlockedForNewClaims'
														disabled={
															!values.isEditing
														}
														checked={
															values.isBlockedForNewClaims
														}
													/>
												</div>
												<div className='d-flex flex-column'>
													<span>
														{t(
															'US.COLLECTION.CASE:CASEINFORMATION.IS_BLOCKED_FOR_NEW_CLAIMS'
														)}
													</span>
												</div>
											</div>
										</div>

										<div className='mb-1'>
											<div
												style={{
													marginBottom:
														'30px',
												}}
												className='d-flex flex-1 align-items-top'>
												<div className='mr-2'>
													{/* onClick={drawerOpen} */}
													<$Switch
														name='applyObseleteInterestFeature'
														checked={
															values.applyObseleteInterestFeature
														}
														disabled={
															!values.isEditing
														}
													/>
												</div>
												<div
													className='d-flex flex-column'
													onClick={
														drawerOpen
													}>
													<ObsoluteInterestLink
														isDrawerOpen={
															drawerVisible
														}>
														{t(
															'US.COLLECTION.CASE:CASEINFORMATION.APPLY_OBSOLETE_INTEREST_FEATURE'
														)}
													</ObsoluteInterestLink>
												</div>
											</div>
										</div>

										<div className='mb-1'>
											<$TextArea
												name='caseDescription'
												label={t(
													'US.COLLECTION.CASE:CASEINFORMATION.CASE_DESCRIPTION'
												)}
												style={{
													height: '147px',
												}}
												disabled={
													!values.isEditing
												}
												maxLength={
													50
												}
												showCount
											/>
										</div>
									</$Col>
								</$Row>

								<ObsoleteInterestDrawer
									//currentDateFormat={currentDateFormat}
									drawerClose={
										drawerClose
									}
									drawerVisible={
										drawerVisible
									}
								/>
							</$Form>
						</$Skeleton>
					</GetContent>
				</div>
			)}
		</Formik>
	);
};

interface IStateProp {
	caseInfo: ICaseInformationModel;
	updatedCaseInfo: ICaseInfoUpdateModel;
	locationState: string;
	currentCurrency: string;
	currentDateFormat: string;
	metaData: IDataModel<IMetaData>;
}

interface IDispatcher {
	GetCaseInformation:
		| (<T>(data: object | T[]) => IReturnAction<T>)
		| undefined;
	UpdateCaseInformation:
		| (<T>(data: object | T[]) => IReturnAction<T>)
		| undefined;
	getObsoleteInterest: any;
}

const mapStateToProps = (state: RootState): IStateProp => {
	const { caseInformation, common, domainView } = state;
	const { caseInfo, updatedCaseInfo } = caseInformation;
	const { pathname } = location;
	const { currentCurrency, currentDateFormat } = common;
	const { metaData } = domainView;
	return {
		caseInfo,
		updatedCaseInfo,
		locationState: pathname,
		currentCurrency,
		currentDateFormat,
		metaData,
	};
};

const mapDispatchToProps: IDispatcher = {
	GetCaseInformation: CaseInformationAction.CaseInformation.get,
	UpdateCaseInformation: CaseInformationAction.CaseInformationSave.save,
	getObsoleteInterest: CaseInformationAction.InterestOccurrences.get,
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseInformation);
