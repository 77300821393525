import { Workflow } from 'us.collection/constants'
import { IAPIAction } from "us.collection/interfaces";
import { IWorkflowAction } from './Interfaces';

export const workflowAction: Readonly<IAPIAction & IWorkflowAction> = {
    retryWorkflow: {
        get: (data) => ({
          type: Workflow.RETRY_WORKFLOW_START,
          payload: {
            data,
            isLoading: true,
          },
        }),
        success: (data) => ({
          type: Workflow.RETRY_WORKFLOW_SUCCESS,
          payload: {
            data: data,
            isLoading: false,
          },
        }),
        fail: (error) => ({
          type: Workflow.RETRY_WORKFLOW_FAIL,
          payload: {
            error: error,
            isLoading: false,
          },
        }),
    },
    workflowStates: {
      get: (data) => ({
        type: Workflow.GET_WORKFLOW_STATES_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: Workflow.GET_WORKFLOW_STATES_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: Workflow.GET_WORKFLOW_STATES_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    category: {
      save: (data) => ({
        type: Workflow.ADD_WORKFLOW_CATEGORY_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      update: (data) => ({
        type: Workflow.UPDATE_WORKFLOW_CATEGORY_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: Workflow.ADD_WORKFLOW_CATEGORY_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: Workflow.ADD_WORKFLOW_CATEGORY_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
      openDrawer: (data) => ({
        type: Workflow.HANDLE_WORKFLOW_DRAWER,
        payload: {
          data,
          isLoading: true,
        },
      }),
    },
    categoryDeletion: {
      delete: (data) => ({
        type: Workflow.DELETE_WORKFLOW_CATEGORY_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: Workflow.DELETE_WORKFLOW_CATEGORY_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: Workflow.DELETE_WORKFLOW_CATEGORY_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    workflowState: {
      save: (data) => ({
        type: Workflow.ADD_WORKFLOW_STATE_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      manageDuplicatingPopover: (data) => ({
        type: Workflow.MANAGE_DUPLICATE_STATE_POPOVER,
        payload: {
          data,
          isLoading: true,
        },
      }),
      manageWorkflowPopover: (data) => ({
        type: Workflow.MANAGE_WORKFLOW_TOOL_POPOVER,
        payload: {
          data,
          isLoading: true,
        },
      }),
      update: (data) => ({
        type: Workflow.UPDATE_WORKFLOW_STATE_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: Workflow.ADD_WORKFLOW_STATE_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: Workflow.ADD_WORKFLOW_STATE_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      })
    },
    dataAdapters: {
      get: (data) => ({
        type: Workflow.GET_DATA_ADAPTERS_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: Workflow.GET_DATA_ADAPTERS_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: Workflow.GET_DATA_ADAPTERS_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      })
    },
    versions: {
      get: (data) => ({
        type: Workflow.GET_WORKFLOW_VERSIONS_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: Workflow.GET_WORKFLOW_VERSIONS_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: Workflow.GET_WORKFLOW_VERSIONS_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      })
    },
    stateDeletion: {
      delete: (data) => ({
        type: Workflow.DELETE_WORKFLOW_STATE_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: Workflow.DELETE_WORKFLOW_STATE_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: Workflow.DELETE_WORKFLOW_STATE_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    eventsByAccessLevel: {
      get: (data) => ({
        type: Workflow.GET_EVENTS_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: Workflow.GET_EVENTS_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: Workflow.GET_EVENTS_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    event: {
      save: (data) => ({
        type: Workflow.ADD_WORKFLOW_EVENT_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      update: (data) => ({
        type: Workflow.UPDATE_WORKFLOW_EVENT_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: Workflow.ADD_WORKFLOW_EVENT_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: Workflow.ADD_WORKFLOW_EVENT_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
      openDrawer: (data) => ({
        type: Workflow.HANDLE_EVENT_DRAWER,
        payload: {
          data,
          isLoading: true,
        },
      }),
    },
    eventsDelete: {
      delete: (data) => ({
        type: Workflow.DELETE_WORKFLOW_EVENT_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: Workflow.DELETE_WORKFLOW_EVENT_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: Workflow.DELETE_WORKFLOW_EVENT_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    errorCount: {
      get: (data) => ({
        type: Workflow.GET_ERROR_COUNT_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: Workflow.GET_ERROR_COUNT_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: Workflow.GET_ERROR_COUNT_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    monitoringList: {
      get: (data) => ({
        type: Workflow.GET_MONITORING_LIST_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: Workflow.GET_MONITORING_LIST_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: Workflow.GET_MONITORING_LIST_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    troubleshootWorkflowRetry: {
      retry: (data) => ({
        type: Workflow.RETRY_TROUBLESHOOT_WORKFLOW_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      openDrawer: (data) => ({
        type: Workflow.HANDLE_TROUBLESHOOT_DRAWER,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: Workflow.RETRY_TROUBLESHOOT_WORKFLOW_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: Workflow.RETRY_TROUBLESHOOT_WORKFLOW_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    troubleshootActivity: {
      retry: (data) => ({
        type: Workflow.RETRY_TROUBLESHOOT_ACTIVITY_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      ignore: (data) => ({
        type: Workflow.IGNORE_TROUBLESHOOT_ACTIVITY_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      delete: (data) => ({
        type: Workflow.DELETE_TROUBLESHOOT_ACTIVITY_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: Workflow.TROUBLESHOOT_ACTIVITY_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: Workflow.TROUBLESHOOT_ACTIVITY_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    stateChange: {
      save: (data) => ({
        type: Workflow.CHANGE_WORKFLOW_STATE_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: Workflow.CHANGE_WORKFLOW_STATE_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: Workflow.CHANGE_WORKFLOW_STATE_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    statusHistoryInit: {
      get: (data) => ({
        type: Workflow.GET_WORKFLOW_HISTORY_INIT_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: Workflow.GET_WORKFLOW_HISTORY_INIT_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: Workflow.GET_WORKFLOW_HISTORY_INIT_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
      reset: (data) => ({
        type: Workflow.RESET_WORKFLOW_STATUS_HISTORY,
        payload: {
          data,
          isLoading: true,
        },
      }),
    },
    statusProperties: {
      get: (data) => ({
        type: Workflow.GET_WORKFLOW_STATUS_PROPERTIES_START,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: Workflow.GET_WORKFLOW_STATUS_PROPERTIES_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),
      fail: (error) => ({
        type: Workflow.GET_WORKFLOW_STATUS_PROPERTIES_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
}