import { call, put, takeLatest } from 'redux-saga/effects';
import * as API from 'us.collection.case/services';
import { CaseInformationAction } from 'us.collection.case/actions';
import Constants from 'us.collection.case/constants';
import { ICaseInformation } from 'us.collection.case/reducers/CaseInformation/Interfaces';
import { $Message } from 'us.common/components';
import { IReturnAction } from 'us.collection/interfaces';
import { ICaseInfoPayloadActionData } from 'us.collection.case/interfaces/CaseInformation/ICaseInfoPayloadActionData';
import { ICaseInfoUpdate } from 'us.collection.case/reducers/CaseInformation/Interfaces/ICaseInfoUpdate';
import moment from 'moment';

const { ACTIONS } = Constants;
const { CaseInformation } = ACTIONS;

const CaseInformationSagas = {
	get: function* (action: IReturnAction<number>) {
		const params = action.payload.data as any;
		try {
			const data: ICaseInformation = yield call(
				API.caseInformation.Information.get,
				params.caseId
			);
			yield put(
				CaseInformationAction.CaseInformation.success(
					data
				)
			);
		} catch (error) {
			yield put(
				CaseInformationAction.CaseInformation.fail(
					error as any
				)
			);
		}
	},
	save: function* (action: IReturnAction<string>) {
		const payloadData: ICaseInfoPayloadActionData = action.payload
			.data as ICaseInfoPayloadActionData;
		try {
			const data: ICaseInfoUpdate = yield call(
				API.caseInformation.Information.save,
				payloadData.updatedCaseInfo
			);
			if (data.isError) {
				$Message.error(
					`Error in saving case info. \n ${data.message}`
				);
				yield put(
					CaseInformationAction.CaseInformationSave.fail(
						data
					)
				);
			} else {
				$Message.success(`${data.message}`);
				//#region Case Interest upda
				if (
					payloadData.saveCaseInfo
						.applyObseleteInterestFeature
				) {
					/**
					 * calling the case interest update only on
					 * case info save success and applyObseleteInterestFeature ture
					 */
					$Message.info(
						`Auto update interest started.`
					);
					const { data } = yield call(
						API.interests.summaryInterest
							.update,
						{
							caseId: payloadData
								.saveCaseInfo
								.caseId,
							interestDate: moment(
								new Date()
							).format(),
						}
					);

					if (
						(data as any)['isSuccess'] &&
						!data.hasOwnProperty('errors')
					) {
						$Message.success(`Auto update interest 
            success.`);
					} else {
						$Message.error(
							`Error - ${
								(data as any)
									.message
							}`
						);
					}
				}
				//#endregion
				yield put(
					CaseInformationAction.CaseInformationSave.success(
						data
					)
				);
				yield put(
					CaseInformationAction.CaseInformation.success(
						payloadData.saveCaseInfo
					)
				);
			}
		} catch (error) {
			$Message.error(`Error in saving case info.`);
			yield put(
				CaseInformationAction.CaseInformationSave.fail(
					error as any
				)
			);
		}
	},
};

const InterestOccurrences = {
	get: function* (action: any) {
		const params = action.payload.data;
		try {
			const data = yield call(
				API.caseInformation.ObsoleteInterest.get,
				params
			);
			yield put(
				CaseInformationAction.InterestOccurrences.success(
					{
						list: data,
						caseID: params,
					}
				)
			);
		} catch (error) {
			yield put(
				CaseInformationAction.InterestOccurrences.fail(
					error as any
				)
			);
		}
	},
	save: function* (action: any) {
		const params = action.payload.data;
		try {
			const data = yield call(
				API.caseInformation.ObsoleteInterest.save,
				params
			);
			yield put(
				CaseInformationAction.AddInterestOccurrences.success(
					data
				)
			);
			if (data?.isAdded) {
				$Message.success('Saved Successfully.');
			}
		} catch (error) {
			yield put(
				CaseInformationAction.AddInterestOccurrences.fail(
					error as any
				)
			);
		}
	},
	delete: function* (action: any) {
		const params = action.payload.data;
		try {
			const data = yield call(
				API.caseInformation.ObsoleteInterest.delete,
				params
			);
			yield put(
				CaseInformationAction.DeleteInterestOccurrences.success(
					data
				)
			);
			if (!data?.isError) {
				$Message.success(
					'Occurrence successfully deleted.'
				);
			}
		} catch (error) {
			yield put(
				CaseInformationAction.DeleteInterestOccurrences.fail(
					error as any
				)
			);
		}
	},
};

export default [
	takeLatest(CaseInformation.GET_CASEINFO, CaseInformationSagas.get),
	takeLatest(CaseInformation.SAVE_CASEINFO, CaseInformationSagas.save),
	takeLatest(
		CaseInformation.GET_INTEREST_OCCURRENCES_START,
		InterestOccurrences.get
	),
	takeLatest(
		CaseInformation.ADD_INTEREST_OCCURRENCES_START,
		InterestOccurrences.save
	),
	takeLatest(
		CaseInformation.DELETE_INTEREST_OCCURRENCES_START,
		InterestOccurrences.delete
	),
];
