import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IBMDGroup } from 'us.collection/interfaces/BMD';
import {
	$Button,
	$Divider,
	$Select,
	$TableTree,
	$Tooltip,
} from 'us.common/components';
import { PlusOutlined, ReloadOutlined } from 'us.icons';
import { IResultView } from './Interfaces';
import { DrawerTypes } from 'us.collection/constants';
import { BMDActions } from 'us.collection/actions';
import { useFormikContext } from 'formik';
import { IBMDListFrom } from '../../Interfaces';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { RootState } from 'us.helper/types';

/**
 * @description - The component used in home view of BMD List with the search is done
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2855272453/UI+BMD+-+List+Summery+View
 * @param {IResultView & PropsFromRedux} props
 * @return {JSX.Element}
 * @author Darshana Waasala <waasalajb@unicorn-solutions.com>
 * @since 16/03/2022
 */
export const ResultView: React.FC<IResultView & PropsFromRedux> = (props) => {
	const { t } = useTranslation();
	const {
		selectedBmdGroup,
		selectedCategory,
		columns,
		changeDrawerStatus,
		isBureauRoute,
		refreshData,
		entityDetails,
		editBMDGroup,
		metaData,
	} = props;
	const { bmdGroups, name: categoryName } = selectedCategory ?? {};
	const { name, description, propertyList, id } = selectedBmdGroup ?? {};
	const { values } = useFormikContext<IBMDListFrom>();
	return (
		<>
			<div className='d-flex'>
				<div className='d-flex flex-column mr-5'>
					<div
						className='font-weight-bold'
						data-testid='bmd-list-category'>
						{t(
							'US.COLLECTION.CASE:BMD.CATEGORY'
						)}
					</div>
					<div data-testid='bmd-list-selected-category'>
						{categoryName}
					</div>
				</div>
				<div className='d-flex flex-fill flex-column'>
					<div className='font-weight-bold'>
						{t(
							'US.COLLECTION.CASE:BMD.DESCRIPTION'
						)}
					</div>
					<div>{description}</div>
				</div>
			</div>
			<$Divider />
			<div className='mb-3 d-flex align-items-center'>
				<div className='d-flex flex-column'>
					<$Select
						name='bmdGroup'
						allOption={false}
						size='small'
						value={name}
						defaultValue={name}
						style={{ width: '400px' }}
						options={
							bmdGroups?.map(
								({
									name,
								}: IBMDGroup) => ({
									label: name,
									value: name,
								})
							) ?? []
						}
						data-testid='bmd-group-select'
						onSearchBy={['label']}
						disabled={
							bmdGroups === undefined
								? true
								: !bmdGroups?.some(
										(
											bmdGroup
										) =>
											bmdGroup.id >
											0
								  )
						}
					/>
				</div>
				{isBureauRoute && (
					<div className='ml-3 d-flex flex-column'>
						<$Button
							type='default'
							size='small'
							onClick={() =>
								editBMDGroup()
							}>
							{t(
								'US.COLLECTION.COMMON:COMMON.UPDATE'
							)}
						</$Button>
					</div>
				)}
			</div>

			<div className='d-flex align-items-center justify-content-between'>
				<div className='d-flex align-items-center flex-grow-1'>
					<h3 className='font-weight-bold'>
						{t(
							'US.COLLECTION.CASE:BMD.BMD_PROPERTIES'
						)}
					</h3>
					<$Divider
						className='bui-devider'
						type='vertical'
					/>
					{isBureauRoute && (
						<$Button
							type='default'
							size='small'
							data-testid='btn-bmd-add-property'
							icon={<PlusOutlined />}
							onClick={() =>
								changeDrawerStatus &&
								changeDrawerStatus(
									{
										isVisible: true,
										title: 'US.COLLECTION.CASE:BMD.ADD_PROPERTY',
										drawerType: DrawerTypes.ADD_PROPERTY,
										recordData: {
											bmdGroupId: selectedBmdGroup?.id,
											bmdCategoryId:
												selectedCategory?.id,
											bmdSearch: values.bmdSearch,
											initialPropertyCheck:
												values.initialPropertyCheck,
										},
									}
								)
							}>
							{t(
								'US.COLLECTION.CASE:BMD.NEW_PROPERTY'
							)}
						</$Button>
					)}
				</div>
				<$Tooltip
					placement='right'
					title={t(
						'US.COLLECTION.CASE:BMD.REFRESH_BMD_TABLE'
					)}>
					<$Button
						icon={<ReloadOutlined />}
						type='dashed'
						size='small'
						onClick={refreshData}
					/>
				</$Tooltip>
			</div>
			<div data-testid='bmd-search-result-table'>
				<$TableTree
					data={
						propertyList?.filter(
							(property) =>
								property.keyName
						) ?? []
					}
					rowKey='id'
					size='small'
					className='mt-3'
					onSort={(sortData, dataSource) =>
						sortData(dataSource)
					}
					onFilter={(searchData, dataSource) =>
						searchData(dataSource)
					}
					filterOnType={true}
					resetOnSourceChange={true}
					bordered
					pagination={{ defaultPageSize: 20 }}
					columns={columns}
					scroll={{ x: '1200px' }}
					onRow={(record, rowIndex) => {
						return {
							onDoubleClick: (
								event
							) => {
								if (
									metaData.data &&
									!isSubCaseTransferredToCase(
										metaData.data
									)
								) {
									changeDrawerStatus &&
										changeDrawerStatus(
											{
												isVisible: true,
												title: 'US.COLLECTION.CASE:BMD.EDIT_BMD',
												drawerType: DrawerTypes.EDIT_BMD,
												recordData: {
													...record,
													...entityDetails,
													bmdGroupId: selectedBmdGroup?.id,
													bmdCategoryId:
														selectedCategory?.id,
													bmdSearch: values.bmdSearch,
													initialPropertyCheck:
														values.initialPropertyCheck,
												},
											}
										);
								}
							},
						};
					}}
				/>
			</div>
		</>
	);
};

const { bmdDataTypes } = BMDActions;

const mapStateToProps = (state: RootState) => {
	const { domainView } = state;
	const { metaData } = domainView;
	return { metaData };
};

const mapDispatchToProps = {
	changeDrawerStatus: bmdDataTypes.openDrawer,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ResultView);
