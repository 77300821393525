import { ICaseAmountsDetails } from "us.collection.case/interfaces";

/**
 * To get the continue type options
 * @param caseAmountDetails The case amout details from the API
 * @returns Continue type options drop down values
 */
export const getContinueTypeOptions = (
  caseAmountDetails: ICaseAmountsDetails | undefined
): Array<any> => {
  try {
    return caseAmountDetails
      ? caseAmountDetails.continueTo.map(({ id, displayValue }) => ({
          label: displayValue,
          value: displayValue,
        }))
      : [];
  } catch (error) {
    console.error(error);
    return [];
  }
};
