import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { matchPath, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	$Skeleton,
	$TableTree,
	$DateTimeLabel,
	$Tooltip,
	$Button,
} from 'us.common/components';
import { IOnFilter, IOnSort } from 'us.common/components/antd/TableTree';
import { followUps } from 'us.collection.followup/actions';
import { IRootState } from 'us.collection/interfaces';
import { Types } from 'us.collection/components/ActivityExecution/Constants';
import {
	FollowUpEntityType,
	UrlModule,
} from 'us.collection.followup/constants';
import { IEntityFollowUpHistory } from './Interfaces';
import {
	ENTITY_FOLLOW_UP_HISTORY_COLUMNS,
	EntityFollowUpsDataIndex,
} from './Constants';
import {
	EntityFollowUpTabs,
	initialEntityDrawer,
} from 'us.collection/constants';

const { historyForEntity } = followUps;

const EntityFollowUpHistory: React.FC<
	PropsFromRedux & IEntityFollowUpHistory & RouteComponentProps
> = (props) => {
	const { t } = useTranslation();

	const {
		getFollowUpHistoryForEntity,
		entity,
		metaData,
		onClose,
		drawer,
	} = props;
	const { data, isFetching } = entity;
	const { selectedTab } = drawer;

	useEffect(() => {
		if (selectedTab == EntityFollowUpTabs.FOLLOW_UP_HISTORY) {
			const { entityId, entityType } = getEntityTypeAndId();
			getFollowUpHistoryForEntity &&
				getFollowUpHistoryForEntity({
					entityId,
					entityType,
				});
		}
	}, [selectedTab]);

	const getEntityTypeAndId = (): {
		entityId: number;
		entityType: string;
	} => {
		const { params }: any = matchPath(props.location.pathname, {
			path: '/:entityType/:entityId?/:module?',
			exact: true,
			strict: false,
		});
		const urlEntityId = params['entityId'];
		const urlEntityType = params['entityType'];
		const { caseId, entityType } = metaData ?? {
			caseId: 0,
			entityType: '',
		};
		switch (urlEntityType) {
			case UrlModule.CASE:
				if (entityType === Types.SUBCASE) {
					return {
						entityId: caseId,
						entityType: FollowUpEntityType.INVOICE,
					};
				} else {
					return {
						entityId: caseId,
						entityType: FollowUpEntityType.CASE,
					};
				}
			case UrlModule.AR:
				return {
					entityId: urlEntityId,
					entityType: FollowUpEntityType.DEBTOR,
				};
			case UrlModule.CREDITOR:
				return {
					entityId: urlEntityId,
					entityType: FollowUpEntityType.CREDITOR,
				};
			case UrlModule.PAYMENT:
				return {
					entityId: urlEntityId,
					entityType: FollowUpEntityType.PAYMENT,
				};
			default:
				return {
					entityId: urlEntityId,
					entityType: FollowUpEntityType.CASE,
				};
		}
	};

	const columns: any = ENTITY_FOLLOW_UP_HISTORY_COLUMNS.map(
		({ dataIndex, title, width }) => ({
			title: t(
				`US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.${title}`
			),
			dataIndex,
			key: dataIndex,
			className: 'text-nowrap',
			ellipsis: true,
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter:
				dataIndex ===
				EntityFollowUpsDataIndex.ACTION_DATE
					? 'date'
					: true,
			customRenderChild: (
				text: any,
				record: any,
				index: number
			) => {
				return (
					<>
						{dataIndex ===
							EntityFollowUpsDataIndex.ACTION_DATE && (
							<$DateTimeLabel
								value={
									record?.actionDate
								}
							/>
						)}
						{dataIndex !==
							EntityFollowUpsDataIndex.ACTION_DATE && (
							<$Tooltip
								placement='topLeft'
								title={
									record[
										`${dataIndex}`
									]
								}>
								{
									record[
										`${dataIndex}`
									]
								}
							</$Tooltip>
						)}
					</>
				);
			},
			...(width ? { width } : {}),
		})
	);

	const handleSort: IOnSort = (sortData, data) => {
		return sortData(data);
	};

	const handleFilter: IOnFilter = (searchData, data) => {
		return searchData(data);
	};

	return (
		<div>
			<$Skeleton
				loading={isFetching}
				active
				paragraph={{ rows: 2 }}>
				<$TableTree
					rowKey='followupHistoryId'
					data={data}
					size='small'
					className=''
					onSort={handleSort}
					onFilter={handleFilter}
					filterOnType={true}
					resetOnSourceChange={true}
					bordered
					pagination={{ defaultPageSize: 20 }}
					scroll={{
						x: 900,
						y: 'calc(100vh - 250px)',
					}}
					columns={columns}
					firstColSkipFilterProps={-1}
					data-testid={
						'entityFollowUpHistory-table'
					}
				/>
			</$Skeleton>

			<div className='drawer-footer-fixed align-content-center'>
				<div className='ml-auto'>
					<$Button
						onClick={() =>
							onClose &&
							onClose({
								...initialEntityDrawer,
								selectedTab: '',
							})
						}>
						{t(
							'US.COLLECTION.COMMON:COMMON.CANCEL'
						)}
					</$Button>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: IRootState) => {
	const { followUps, domainView, entityFollowUps } = state;
	const { history } = followUps;
	const { entity } = history;
	const { metaData } = domainView;
	const { drawer } = entityFollowUps;
	return { entity, metaData: metaData.data, drawer };
};

const mapDispatchToProps = {
	getFollowUpHistoryForEntity: historyForEntity.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EntityFollowUpHistory);
