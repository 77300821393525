import { versionedHttpCollection } from 'us.helper/http/collection';
import {
	FollowUpPinStatus,
	IFollowUpCategory,
	IFollowUpItem,
} from 'us.collection.followup/interfaces';
import ServiceConfig from 'service.config.js';

export default {
	/**
	 * A namespace
	 * @namespace enforcements
	 */
	categories: {
		/**
		 * Get all followUp categories
		 * @returns {Promise} Promise object represents the API response
		 */
		get: <P extends {}>(
			params: P
		): Promise<Array<IFollowUpCategory>> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'followupservice',
							`followupCategories`,
							{},
							'V1'
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	/**
	 * A namespace
	 * @namespace list
	 */
	list: {
		/**
		 * Get all followUp list by CategoryId
		 * @param params {number} categoryId - FollowUp CategoryId
		 * @returns {Promise} Promise object represents the API response
		 */
		get: <
			P extends {
				categoryId: number;
				pinStatus: FollowUpPinStatus;
			}
		>(
			params: P
		): Promise<Array<IFollowUpItem>> => {
			return new Promise<any>(async (resolve, reject) => {
				const { categoryId, pinStatus } = params;
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'followupservice',
							`followupListAdmin/${categoryId}/true`,
							{ pinStatus },
							'v1'
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	/**
	 * A namespace
	 * @namespace item
	 */
	item: {
		/**
		 * Unpin followUp item
		 * @param params {number} categoryID - FollowUp CategoryId
		 * @returns {Promise} Promise object represents the API response
		 */
		unPin: (params: any): Promise<Array<IFollowUpItem>> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const pinStatus =
						await versionedHttpCollection.put(
							'followupservice',
							`followuplist/pinstatus`,
							params,
							'v1'
						);
					const listData =
						await versionedHttpCollection.get(
							'followupservice',
							`followupListAdmin/${params.selectedCategoryId}/true`,
							{},
							'v1'
						);

					if (pinStatus?.status == 200) {
						resolve({
							pinStatus: pinStatus.data,
							listData: listData.data,
						});
					} else {
						reject({
							pinStatus: pinStatus.data,
							listData: listData.data,
						});
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	/**
	 * A namespace
	 * @namespace result
	 */
	result: {
		/**
		 * Get followUp result
		 * @param params {number} followUpId - FollowUpId
		 * @returns {Promise} Promise object represents the API response
		 */
		get: <P extends { followUpId: number }>(
			params: P,
			isHistory?: boolean
		): Promise<Array<IFollowUpItem>> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'followupservice',
							`followupdata/${
								params.followUpId
							}/${
								isHistory
									? 'true'
									: 'false'
							}`,
							{},
							'v1'
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
		/**
		 * Re-import follow-up
		 * @param params {number} followUpId - FollowUpId
		 * @returns {Promise} Promise object represents the API response
		 */
		reImport: <P extends { followUpId: number }>(
			params: P
		): Promise<Array<IFollowUpItem>> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.post(
							'followupservice',
							`followupreimport/${params.followUpId}`,
							{},
							'v1'
						);
					if (status == 201 || status == 202) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
		/**
		 * follow-up assignToUser
		 * @param params {number} followUpDataId - FollowUp Data Id
		 * @returns {Promise} Promise object represents the API response
		 */
		assignToUser: <P extends { followUpDataId: number }>(
			params: P
		): Promise<Array<IFollowUpItem>> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.post(
							'followupservice',
							`followupdataassigneduser/${params.followUpDataId}`,
							{},
							'v1'
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	/**
	 * A namespace
	 * @namespace history
	 */
	history: {
		/**
		 * A namespace
		 * @namespace entity
		 */
		entity: {
			/**
			 * Get followUp History For Entity
			 * @param params {number} entityId - Entity Id
			 * @param params {number} entityType - Entity Type
			 * @returns {Promise} Promise object represents the API response
			 */
			get: <
				P extends {
					entityId: number;
					entityType: string;
				}
			>(
				params: P
			): Promise<Array<IFollowUpItem>> => {
				return new Promise<any>(
					async (resolve, reject) => {
						try {
							const { data, status } =
								await versionedHttpCollection.get(
									'followupservice',
									`followupHistory/${params.entityType}/${params.entityId}`,
									{},
									'v1'
								);
							if (status == 200) {
								resolve(data);
							} else {
								reject(data);
							}
						} catch (error) {
							reject(error);
						}
					}
				);
			},
		},
		/**
		 * A namespace
		 * @namespace action
		 */
		action: {
			/**
			 * Get followUp History For Action
			 * @param params {number} followUpId - FollowUp Id
			 * @param params {number} entityId - Entity Id
			 * @returns {Promise} Promise object represents the API response
			 */
			get: <
				P extends {
					followUpId: number;
					entityId: number;
				}
			>(
				params: P
			): Promise<Array<IFollowUpItem>> => {
				return new Promise<any>(
					async (resolve, reject) => {
						try {
							const { data, status } =
								await versionedHttpCollection.get(
									'followupservice',
									`followupactionhistory/${params.followUpId}/${params.entityId}`,
									{},
									'v1'
								);
							if (status == 200) {
								resolve(data);
							} else {
								reject(data);
							}
						} catch (error) {
							reject(error);
						}
					}
				);
			},
		},
	},
	/**
	 * A namespace
	 * @namespace entity
	 */
	entity: {
		/**
		 * A namespace
		 * @namespace entity
		 */
		active: {
			/**
			 * Get active followUp For Entity
			 * @param params {number} entityId - Entity Id
			 * @param params {number} entityType - Entity Type
			 * @returns {Promise} Promise object represents the API response
			 */
			get: <
				P extends {
					entityId: number;
					entityType: string;
				}
			>(
				params: P
			): Promise<Array<IFollowUpItem>> => {
				return new Promise<any>(
					async (resolve, reject) => {
						try {
							const { data, status } =
								await versionedHttpCollection.get(
									'followupservice',
									`followupList/${params.entityId}/${params.entityType}`,
									{},
									'v1'
								);
							if (status == 200) {
								resolve(data);
							} else {
								reject(data);
							}
						} catch (error) {
							reject(error);
						}
					}
				);
			},
		},
		action: {
			/**
			 * Execute followUp action For Entity
			 * @param params action details
			 * @returns {Promise} Promise object represents the API response
			 */
			execute: <P extends {}>(
				params: P
			): Promise<Array<IFollowUpItem>> => {
				return new Promise<any>(
					async (resolve, reject) => {
						try {
							const { data, status } =
								await versionedHttpCollection.post(
									'followupservice',
									`followupaction`,
									params,
									'v1'
								);
							if (status == 200) {
								resolve(data);
							} else {
								reject(data);
							}
						} catch (error) {
							reject(error);
						}
					}
				);
			},
		},
	},
	/**
	 * A namespace
	 * @namespace activites
	 */
	activites: {
		/**
		 * Get followUp activities
		 * @param params {number} followUpId - FollowUpId, {boolean} isHandelingActivity
		 * @returns {Promise} Promise object represents the API response
		 */
		get: <
			P extends {
				followUpId: number;
				isHandelingActivity: boolean;
			}
		>(
			params: P
		): Promise<any> => {
			const { followUpId, isHandelingActivity } = params;
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'activityservice',
							`activitiesforfollowup/${followUpId}/${isHandelingActivity}`,
							{},
							'v1'
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	/**
	 * A namespace
	 * @namespace activity
	 */
	activity: {
		/**
		 * get parameters for selected activity
		 */
		parameters: {
			get: <
				P extends {
					activityId: string;
					state: string;
				}
			>(
				params: P
			): Promise<any> => {
				return new Promise<any>(
					async (resolve, reject) => {
						const { activityId, state } =
							params;
						try {
							const { data, status } =
								await versionedHttpCollection.get(
									'activityservice',
									`activities/${activityId}/${state}/settings`,
									{},
									'v1'
								);
							if (status == 200) {
								resolve(data);
							} else {
								reject(data);
							}
						} catch (error) {
							reject(error);
						}
					}
				);
			},
		},
		execute: (params: any): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data } =
						await versionedHttpCollection.post(
							'activityservice',
							'bulkactivity',
							params,
							ServiceConfig()[
								`activityservice`
							]['bulkactivity']
						);
					resolve(data);
				} catch (error) {
					reject(error);
				}
			});
		},
		bmd: {
			get: (params: any): Promise<any> => {
				return new Promise<any>(
					async (resolve, reject) => {
						try {
							const { data, status } =
								await versionedHttpCollection.post(
									'bmdservice',
									`bmdkeyvalue`,
									params,
									ServiceConfig()[
										`bmdservice`
									][
										'bmdkeyvalue'
									]
								);
							if (status == 200) {
								resolve(data);
							} else {
								reject(data);
							}
						} catch (error) {
							reject(error);
						}
					}
				);
			},
		},
	},
	lastAction: {
		get: <
			P extends {
				batchId: string;
			}
		>(
			params: P
		): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				const { batchId } = params;
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'followupservice',
							`followupdata/${batchId}`,
							{},
							'v1'
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	/**
	 * A namespace
	 * @namespace categoryId
	 */
	categoryId: {
		/**
		 * Get categoryId for selected followup
		 * @param   {number} params followupId
		 * @returns {Promise} Promise object represents the API response
		 */
		get: <P extends { followUpId: number }>(
			params: P
		): Promise<Array<IFollowUpItem>> => {
			return new Promise<any>(async (resolve, reject) => {
				const { followUpId } = params;
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'followupservice',
							`followupcategory/${followUpId}`,
							{},
							'v1'
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
};
