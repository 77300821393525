import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import Common from 'us.common';
import { Link, RouteComponentProps } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import './Style.scss';
import { IRootState } from 'us.collection/interfaces';

import { ISortOrder, IGuarantor } from './Interface';
const {
	$Input,
	$AutoComplete,
	$Search,
	$Button,
	$Divider,
	$Form,
	$PageHeader,
	$Drawer,
	$Affix,
	$Row,
	$Skeleton,
	$Table,
	$Typography,
} = Common.Components;
import {
	PlusOutlined,
	MoreOutlined,
	BoxIcons,
	IconTypes,
	EditOutlined,
	CopyOutlined,
	DeleteOutlined,
	InfoCircleOutlined,
	FilterOutlined,
	SearchOutlined,
} from 'us.icons';

const Guarantor: React.FC<IGuarantor> = (props) => {
	const visibleDrawer = {
		visible: false,
	};
	const { t } = useTranslation(
		['US.COLLECTION.CASE', 'US.COLLECTION.COMMON'],
		{
			useSuspense: true,
		}
	);
	const [drawer, setDrawer] = useState(visibleDrawer);
	const [searchText, setSearchText] = useState<string>('');
	const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
		columnKey: '',
		order: '',
	});

	const { history, location, metaData } = props;
	const { caseNo } = metaData.data ?? {};

	const getColumnSearchProps = (dataIndex: string, title: string) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}: any) => (
			<div style={{ padding: 8 }}>
				<$Input
					name='columnSearch'
					placeholder={`${t(
						'US.COLLECTION.COMMON:COMMON.SEARCH'
					)} ${title}`}
					value={selectedKeys[0]}
					onChange={(e: any) =>
						setSelectedKeys(
							e.target.value
								? [
										e
											.target
											.value,
								  ]
								: []
						)
					}
					onPressEnter={() =>
						handleSearch(
							selectedKeys,
							confirm
						)
					}
					style={{
						width: 188,
						marginBottom: 8,
						display: 'block',
					}}
				/>

				<$Button
					type='primary'
					onClick={() =>
						handleSearch(
							selectedKeys,
							confirm
						)
					}
					icon={<SearchOutlined />}
					size='small'
					style={{ width: 90, marginRight: 8 }}>
					{t(
						'US.COLLECTION.COMMON:COMMON.SEARCH'
					)}
				</$Button>
				<$Button
					onClick={() =>
						handleReset(clearFilters)
					}
					size='small'
					style={{ width: 90 }}>
					{t('US.COLLECTION.COMMON:COMMON.RESET')}
				</$Button>
			</div>
		),
		filterIcon: (filtered: string) => (
			<FilterOutlined
				style={{
					color: filtered ? '#1890ff' : undefined,
				}}
			/>
		),
		// onFilter: (value: string, record: any) => {
		//   let formatRecord = formatObject(record)
		//   return formatRecord[dataIndex]
		//     .toString()
		//     .toLowerCase()
		//     .includes(value.toLowerCase());
		// },
		// render: (text: string) =>
		//     searchedColumn === dataIndex ? (
		//         <Highlighter
		//             highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
		//             searchWords={[searchText]}
		//             autoEscape
		//             textToHighlight={text ? text.toString() : ''}
		//         />
		//     ) : (
		//             text
		//         ),
		render: (text: string) => (
			<Highlighter
				highlightStyle={{
					backgroundColor: '#ffc069',
					padding: 0,
				}}
				searchWords={[searchText]}
				autoEscape
				textToHighlight={text ? text.toString() : ''}
			/>
		),
	});

	const handleSearch = (selectedKeys: any, confirm: any) => {
		confirm();
		setSearchText(selectedKeys[0]);
	};

	const handleReset = (clearFilters: any) => {
		clearFilters();
		setSearchText('');
	};

	const columns: any = [
		{
			title: '',
			dataIndex: 'MASTER_CREDITOR',
			key: 'MASTER_CREDITOR',
			width: 24,
			className: 'master-td',
			render: (text: any, record: any) => {
				return (
					<span className='master-data'>
						{text}
					</span>
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.CREDITOR:SELECTEDCREDITOR.CREDITOR_NUMBER'
			),
			dataIndex: 'CREDITOR_NUMBER',
			key: 'CREDITOR_NUMBER',
			className: 'text-nowrap',
			width: 180,
			sorter: (a: any, b: any) =>
				a.CREDITOR_NUMBER - b.CREDITOR_NUMBER,
			sortOrder: sortedInfo
				? sortedInfo.columnKey === 'CREDITOR_NUMBER' &&
				  sortedInfo.order
				: null,
			...getColumnSearchProps(
				'CREDITOR_NUMBER',
				t(
					'US.COLLECTION.CREDITOR:SELECTEDCREDITOR.CREDITOR_NUMBER'
				)
			),
			render: (text: any, record: any) => {
				return (
					<div>
						<Link to={'#'}>
							{text > 0 ? text : ''}
						</Link>
					</div>
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.CREDITOR:SELECTEDCREDITOR.CREDITOR_NAME'
			),
			dataIndex: 'CREDITOR_NAME',
			key: 'CREDITOR_NAME',
			width: 300,
			ellipsis: true,
			sorter: (a: any, b: any) =>
				a.CREDITOR_NAME - b.CREDITOR_NAME,
			sortOrder: sortedInfo
				? sortedInfo.columnKey === 'CREDITOR_NAME' &&
				  sortedInfo.order
				: null,
			...getColumnSearchProps(
				'CREDITOR_NAME',
				t(
					'US.COLLECTION.CREDITOR:SELECTEDCREDITOR.CREDITOR_NAME'
				)
			),
		},
		{
			title: t('US.COLLECTION.CREDITOR:SELECTEDCREDITOR.NIN'),
			dataIndex: 'NIN',
			key: 'NIN',
			width: 150,
			className: 'text-nowrap',
			sorter: (a: any, b: any) => a.NIN - b.NIN,
			sortOrder: sortedInfo
				? sortedInfo.columnKey === 'NIN' &&
				  sortedInfo.order
				: null,
			...getColumnSearchProps(
				'NIN',
				t('US.COLLECTION.CREDITOR:SELECTEDCREDITOR.NIN')
			),
		},
		{
			title: t(
				'US.COLLECTION.CREDITOR:SELECTEDCREDITOR.ADDRESS'
			),
			dataIndex: 'ADDRESS',
			key: 'ADDRESS',
			ellipsis: true,
			sorter: (a: any, b: any) => a.ADDRESS - b.ADDRESS,
			sortOrder: sortedInfo
				? sortedInfo.columnKey === 'ADDRESS' &&
				  sortedInfo.order
				: null,
			...getColumnSearchProps(
				'ADDRESS',
				t(
					'US.COLLECTION.CREDITOR:SELECTEDCREDITOR.ADDRESS'
				)
			),
		},
	];

	const creditorGroupData = [
		{
			key: '1',
			MASTER_CREDITOR: '457 - Unicorn Collection AS',
			children: [
				{
					key: '1',
					CREDITOR_NUMBER: '48',
					CREDITOR_NAME: 'Softlogic PLC',
					NIN: '458 457 112',
					ADDRESS: '74, Sportsveien, Sandefejored, 9895-OSLO',
				},
				{
					key: '1',
					CREDITOR_NUMBER: '48',
					CREDITOR_NAME: 'Softlogic PLC',
					NIN: '458 457 112',
					ADDRESS: '74, Sportsveien, Sandefejored, 9895-OSLO',
				},
				{
					key: '1',
					CREDITOR_NUMBER: '48',
					CREDITOR_NAME: 'Softlogic PLC',
					NIN: '458 457 112',
					ADDRESS: '74, Sportsveien, Sandefejored, 9895-OSLO',
				},
				{
					key: '1',
					CREDITOR_NUMBER: '48',
					CREDITOR_NAME: 'Softlogic PLC',
					NIN: '458 457 112',
					ADDRESS: '74, Sportsveien, Sandefejored, 9895-OSLO',
				},
			],
		},
		{
			key: '2',
			MASTER_CREDITOR: '457 - Unicorn Collection AS',
			children: [
				{
					key: '1',
					CREDITOR_NUMBER: '48',
					CREDITOR_NAME: 'Softlogic PLC',
					NIN: '458 457 112',
					ADDRESS: '74, Sportsveien, Sandefejored, 9895-OSLO',
				},
				{
					key: '1',
					CREDITOR_NUMBER: '48',
					CREDITOR_NAME: 'Softlogic PLC',
					NIN: '458 457 112',
					ADDRESS: '74, Sportsveien, Sandefejored, 9895-OSLO',
				},
				{
					key: '1',
					CREDITOR_NUMBER: '48',
					CREDITOR_NAME: 'Softlogic PLC',
					NIN: '458 457 112',
					ADDRESS: '74, Sportsveien, Sandefejored, 9895-OSLO',
				},
				{
					key: '1',
					CREDITOR_NUMBER: '48',
					CREDITOR_NAME: 'Softlogic PLC',
					NIN: '458 457 112',
					ADDRESS: '74, Sportsveien, Sandefejored, 9895-OSLO',
				},
			],
		},
	];

	const handleTableChange = (
		pagination: any,
		filters: any,
		sorter: any
	) => {
		setSortedInfo(sorter);
	};

	const minimize = () => {
		history.push(`/case/${caseNo}`, location.state);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{}}
			onSubmit={(values: any, actions: any) => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				...restProps
			}: any) => (
				<>
					<div className='space-content selected-creditor'>
						<$Affix offsetTop={80}>
							<div className='page-header header-border'>
								<div className='d-flex flex-row align-items-center justify-content-between'>
									<div className='d-flex align-items-center'>
										<$PageHeader
											className='px-0'
											title={t(
												'US.COLLECTION.CREDITOR:SELECTEDCREDITOR.SELECTED_CREDITOR'
											)}
											onBack={
												minimize
											}
										/>
									</div>
									<div className='d-flex align-items-center'>
										<$Search
											name='code'
											//onChange={(val: any) =>}
											enterButton
											size='small'
											placeholder={t(
												'US.COLLECTION.CREDITOR:SELECTEDCREDITOR.SEARCH_MASTER_CREDITOR'
											)}
											style={{
												width: '300px',
											}}
										/>
									</div>
								</div>
							</div>
						</$Affix>

						<$Skeleton
							loading={false}
							active
							paragraph={{ rows: 2 }}>
							<$Table
								onChange={
									handleTableChange
								}
								rowKey='id'
								dataSource={
									creditorGroupData
								}
								size='small'
								className='mt-3 table-striped'
								bordered
								pagination={{
									hideOnSinglePage:
										true,
									defaultPageSize: 20,
								}}
								columns={
									columns
								}
								defaultExpandAllRows={
									true
								}
							/>
						</$Skeleton>
					</div>
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: IRootState) => {
	const { domainView, common } = state;
	const { metaData } = domainView;
	const { currentLanguage, currentDateFormat, currentCurrency } = common;
	return {
		currentCurrency,
		currentLanguage,
		currentDateFormat,
		metaData,
	};
};

export default connect(mapStateToProps)(Guarantor);
