import { versionedHttpCollection } from 'us.helper/http/collection';
import ServiceConfig from 'service.config.js';

export default {
	ArNote: {
		get: ({entityId, entityType, registeredUserType}: any): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'entityservice',
							`notehistory/${entityId}/${entityType}/${registeredUserType}`,
							{},
							ServiceConfig()[
								`entityservice`
							]['notehistory']
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
		save: (params: any): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.post(
							'noteservice',
							`note`,
							params,
							ServiceConfig()[
								`noteservice`
							]['note']
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
		delete: (params: any): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.patch(
							'noteservice',
							`note`,
							params,
							ServiceConfig()[
								`noteservice`
							]['note']
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
	},
};
