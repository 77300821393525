import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import {
	$Affix,
	$Skeleton,
	$Button,
	$Breadcrumb,
	$PageHeader,
} from 'us.common/components';
import { PlusCircleFilled } from 'us.icons';
import './Style.scss';
import { Formik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { INavigationData } from 'us.collection/interfaces';
import { CategoryPanel, FilterPanel } from './Components';
import { OtherEntityFollowUps } from 'us.collection.followup/components';
import { manualFollowUpActions } from 'us.collection.case/actions';
import { followUps } from 'us.collection.followup/actions';
import { followUps as collectionFollowUps } from 'us.collection/actions';
import { getEntityTypeAndId } from 'us.collection.followup/functions';
import {
	CaseType,
	EntityFollowUpPanel,
	FollowUpEntityType,
} from 'us.collection.case/constants';
import {
	AddManualFollowUp,
	ManualFollowUps,
} from 'us.collection.case/components/ManualFollowUps/Components';
import './Style.scss';
import {
	EntityFollowUpTabs,
	initialEntityDrawer,
	URLType,
} from 'us.collection/constants';
import { isClosedCase } from 'us.collection/functions';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { RootState } from 'us.helper/types';

const { activeFollowUps } = followUps;
const { entity } = collectionFollowUps;

/**
 * @description - Current Active Follow-ups views open inside the drawer as a tab (Manual-Due, Upcoming  & Other follow-ups)
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2912288817/Get+active+follow-up+for+EntityId+and+EntityType+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 09/03/2022
 */
const CurrentFollowUps: React.FC<PropsFromRedux & RouteComponentProps> = (
	props
) => {
	const { t } = useTranslation();

	const {
		accountSummeryWidgetData,
		metaData,
		list,
		drawer,
		location,
		domainViewData,
		getActiveFollowUps,
		manageEntityFollowUpDrawer,
		getManualFollowUps,
	} = props;
	const state = location.state as INavigationData;
	const { panel, manualFollowUp } = drawer;
	const { isNewManualFollowUp } = manualFollowUp;
	const { due, upcoming } = list;
	const { case: caseDetail } = accountSummeryWidgetData.data ?? {};
	const { closedDate = '' } = caseDetail ?? {};

	useEffect(() => {
		const { entityId, entityType } = getEntityTypeAndId(
			props.location,
			metaData
		);
		getManualFollowUps &&
			getManualFollowUps({
				caseId: domainViewData.data.caseId,
				caseType:
					entityType === FollowUpEntityType.CASE
						? CaseType.CASE
						: CaseType.SUBCASE,
			});
		getActiveFollowUps &&
			getActiveFollowUps({ entityId, entityType });
	}, []);

	/**
	 * @function
	 * @description - manage entity follow-up drawer
	 * @param {boolean} isNewManualFollowUp - is user going to add new follow-up
	 * @param {EntityFollowUpPanel} panel - opened follow-up panel
	 * @returns
	 */
	const manageDrawer = (isNewManualFollowUp: boolean) => {
		manageEntityFollowUpDrawer &&
			manageEntityFollowUpDrawer({
				visible: true,
				selectedTab: EntityFollowUpTabs.FOLLOW_UP,
				panel:
					panel == EntityFollowUpPanel.DUE
						? EntityFollowUpPanel.DUE
						: EntityFollowUpPanel.UPCOMING,
				manualFollowUp: {
					isNewManualFollowUp,
					panel,
				},
			});
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{}}
			onSubmit={(values: any, actions: any) => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				...rest
			}: any) => (
				<>
					{!isNewManualFollowUp && (
						<>
							<div className='collection-followup-layout'>
								<$Affix
									offsetTop={
										94
									}>
									<aside className='d-flex align-items-start flex-column collection-followup-side'>
										<$Skeleton
											loading={
												false
											}
											paragraph={{
												rows: 2,
											}}>
											<div className='mb-auto w-100'>
												<CategoryPanel
													followupPanels={[
														EntityFollowUpPanel.DUE,
														EntityFollowUpPanel.UPCOMING,
														EntityFollowUpPanel.OTHER,
													]}
													{...props}
												/>
												<FilterPanel
													{...props}
												/>
											</div>
										</$Skeleton>
										{(state.currentTab ===
											URLType.CASE ||
											state.currentTab ===
												URLType.SUBCASE) && (
											<div className='mb-3'>
												<$Button
													type='link'
													className='px-0'
													size='small'
													icon={
														<PlusCircleFilled />
													}
													onClick={() =>
														manageDrawer(
															true
														)
													}
													disabled={
														isClosedCase(
															closedDate
														) ||
														(metaData &&
															isSubCaseTransferredToCase(
																metaData
															))
													}>
													{t(
														`US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.MANUAL_FOLLOWUP`
													)}
												</$Button>
											</div>
										)}
									</aside>
								</$Affix>

								<div className='flex-fill'>
									{(panel ==
										EntityFollowUpPanel.DUE ||
										panel ==
											EntityFollowUpPanel.UPCOMING) && (
										<ManualFollowUps
											dataSource={
												panel ==
												EntityFollowUpPanel.DUE
													? due
													: upcoming
											}
											{...props}
										/>
									)}
									{panel ==
										EntityFollowUpPanel.OTHER && (
										<OtherEntityFollowUps
											{...props}
										/>
									)}
								</div>
							</div>

							<div className='drawer-footer-fixed align-content-center'>
								<div className='ml-auto'>
									<$Button
										onClick={() =>
											manageEntityFollowUpDrawer &&
											manageEntityFollowUpDrawer(
												initialEntityDrawer
											)
										}>
										{t(
											'US.COLLECTION.COMMON:COMMON.CANCEL'
										)}
									</$Button>
								</div>
							</div>
						</>
					)}
					{isNewManualFollowUp && (
						<div className='fd-manual-followup'>
							<div className='fd-mf-header'>
								<$Breadcrumb>
									<$Breadcrumb.Item
										onClick={() =>
											manageDrawer(
												false
											)
										}>
										<a>
											{t(
												'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.MANUAL_FOLLOWUP'
											)}
										</a>
									</$Breadcrumb.Item>
									<$Breadcrumb.Item>
										{t(
											'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.NEW_FOLLOWUP'
										)}
									</$Breadcrumb.Item>
								</$Breadcrumb>
								<$Affix
									offsetTop={
										80
									}>
									<div className=''>
										<div className='d-flex align-items-center'>
											<$PageHeader
												className='px-0 py-0 mr-n3'
												title={t(
													'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.NEW_FOLLOWUP'
												)}
												onBack={() =>
													manageDrawer(
														false
													)
												}
											/>
										</div>
									</div>
								</$Affix>
							</div>
							<AddManualFollowUp
								onClose={() =>
									manageDrawer(
										false
									)
								}
							/>
						</div>
					)}
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { dashboard, entityFollowUps, manualFollowUps, domainView } =
		state;
	const { metaData } = domainView;
	const { accountSummeryWidgetData } = dashboard;
	const { drawer } = entityFollowUps;
	const { list } = manualFollowUps;
	return {
		metaData: metaData.data,
		accountSummeryWidgetData,
		list,
		drawer,
		domainViewData: domainView.case,
	};
};

const { manualFollowUps } = manualFollowUpActions;

const mapDispatchToProps = {
	getManualFollowUps: manualFollowUps.get,
	getActiveFollowUps: activeFollowUps.get,
	manageEntityFollowUpDrawer: entity.manageDrawer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CurrentFollowUps);
