import { DocumentType } from 'us.collection.routines-and-activities/constants';
import { DocumentDrawer } from 'us.collection.routines-and-activities/interfaces';

enum filterOptionValues {
	ALL = 'all',
	ACTIVITIES = 'activities',
	MESSAGES = 'messages',
	CASELOGS = 'caseLogs',
	CP_NOTES = 'cpnote',
	EVENTS= "event"
}

export const typeList = [
	{
		label: 'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.EMAIL',
		value: DocumentType.EMAIL,
	},
	{
		label: 'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.PDF',
		value: DocumentType.PDF,
	},
	{
		label: 'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.SMS',
		value: DocumentType.SMS,
	},
	{
		label: 'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.TEXT',
		value: DocumentType.TEXT,
	},
	,
	{
		label: 'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.XML',
		value: DocumentType.XML,
	},
];

export const filterOptions = [
	{
		value: filterOptionValues.ALL,
		label: 'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.ALL',
		style: { marginRight: '0.5rem' },
	},
	{
		value: filterOptionValues.ACTIVITIES,
		label: 'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.ACTIVITY',
		style: { marginRight: '0.5rem' },
	},
	{
		value: filterOptionValues.MESSAGES,
		label: 'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.MESSAGE',
		style: { marginRight: '0.5rem' },
	},
	{
		value: filterOptionValues.CASELOGS,
		label: 'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.NOTE',
		style: { marginRight: '0.5rem' },
	},
	{
		value: filterOptionValues.CP_NOTES,
		label: 'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.CP_NOTES',
		style: { marginRight: '0.5rem' },
	},
	{
		value: filterOptionValues.EVENTS,
		label: 'US.COLLECTION.ROUTINESANDACTIVITIES:HISTORY.EVENTS',
		style: { marginRight: '0.5rem' },
	},
];

export const DOCUMENT_DRAWER_DEFAULT: DocumentDrawer = {
	documentType: DocumentType.UNKNOWN,
	isVisible: false,
	title: '',
	data: {},
};
