import _ from 'lodash';
import {
	ArTransactionAction,
	defaultArTransactionDrawerSettings,
} from 'us.collection.transactions/constants';
import { ArTransactionDrawerSettings } from 'us.collection.transactions/interfaces';
import { ITransaction } from 'us.collection.transactions/interfaces';
import { i18n } from 'us.helper';
import { FilterTypes } from '../Constants';
import { MappingTransaction } from '../Interfaces';

/**
 * If the transaction is a payment and the item type is not 11, then it's an apportionment mapping.
 * @param {ITransaction} arTransaction - ARTransaction
 * @returns A function that takes an argument of type ARTransaction and returns a boolean.
 */
export const isApportionmentMapping = (
	arTransaction: ITransaction
): boolean => {
	try {
		const { isPayment } = arTransaction;
		return isPayment;
	} catch (error) {
		return false;
	}
};

/**
 * @description If the checkbox is checked, add the selectedId to the selectedRowKeys array, otherwise remove it.
 * @param {boolean} checked - boolean - This is the checked state of the checkbox.
 * @param {number} selectedId - number - The id of the row that was selected
 * @param {number[]} selectedRowKeys - number[] - this is the array of selected row keys
 * @returns An array of numbers.
 */
export const updateSelectedRowKeys = (
	checked: boolean,
	selectedId: number,
	selectedRowKeys: number[]
): number[] => {
	try {
		const updatedRowKeys = new Set<number>(selectedRowKeys ?? []);

		if (checked && !updatedRowKeys.has(selectedId)) {
			updatedRowKeys.add(selectedId);
		} else {
			updatedRowKeys.delete(selectedId);
		}

		return Array.from(updatedRowKeys);
	} catch (error) {
		return [];
	}
};

/**
 * It takes an array of objects, groups them by a property, and returns an array of the keys of the
 * grouped objects
 * @param {MappingTransaction[]} mappingTransactions - MappingTransaction[]
 * @returns An array of strings.
 */
export const getDefaultExpandedRowKeys = (
	mappingTransactions: MappingTransaction[],
	isCaseMapping?: boolean
): string[] => {
	try {
		const groupedList = _.groupBy(
			mappingTransactions,
			isCaseMapping ? 'parentCaseId' : 'caseNo'
		);
		return Object.keys(groupedList);
	} catch (error) {
		return [];
	}
};

/**
 * @description Define available actions for the given AR Transaction and Return the actions array.
 * @param {ITransaction} transaction - ITransaction
 * @returns An array of ArTransactionAction
 */
export const getArTransactionActions = (
	transaction: ITransaction
): ArTransactionAction[] => {
	try {
		const {
			isCancelable,
			isDeletable,
			isRemappingAllowed,
			type,
			amount,
		} = transaction;
		const actions = new Set<ArTransactionAction>();

		// cancelation
		!isCancelable &&
			type != FilterTypes.DB &&
			!actions.has(ArTransactionAction.CANCELATION) &&
			actions.add(ArTransactionAction.CANCELATION);
		// delete
		isDeletable &&
			!actions.has(ArTransactionAction.DELETE) &&
			actions.add(ArTransactionAction.DELETE);
		// mapping
		if (isRemappingAllowed) {
			amount > 0 &&
				actions.add(ArTransactionAction.CASE_MAPPING);
			actions.add(ArTransactionAction.TRANSACTION_MAPPING);
		}
		return Array.from(actions);
	} catch (error) {
		return [];
	}
};

/**
 * @description - Get drawer configurations by type
 * @param {ITransaction} transaction - Transactions data
 * @param {ArTransactionAction} action - Transaction menu action type
 * @param {any} filters - filters for the drawer if available
 */
export const getDrawerSettingsByAction = (
	transaction: ITransaction,
	action: ArTransactionAction,
	filters?: any
): ArTransactionDrawerSettings => {
	try {
		let drawerSettings: ArTransactionDrawerSettings = {
			...defaultArTransactionDrawerSettings,
			visible: true,
			type: action,
		};
		const isDeleteTransaction =
			transaction.isCancelable ||
			transaction.type == FilterTypes.DB;
		switch (action) {
			case ArTransactionAction.CANCELATION:
			case ArTransactionAction.DELETE:
				drawerSettings = {
					...drawerSettings,
					title: i18n.t(
						'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TRANSACTION_DETAILS'
					),
					isNewTransaction: false,
					isDeleteTransaction,
					filters,
				};
				break;
			case ArTransactionAction.CASE_MAPPING:
				drawerSettings = {
					...drawerSettings,
					title: i18n.t(
						'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.CASE_MAPPING'
					),
					width: '80%',
				};
				break;
			case ArTransactionAction.TRANSACTION_MAPPING:
				drawerSettings = {
					...drawerSettings,
					title: i18n.t(
						'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TRANSACTION_MAPPING'
					),
					width: '80%',
				};
				break;
			default:
				break;
		}

		return drawerSettings;
	} catch (error) {
		return defaultArTransactionDrawerSettings;
	}
};
