import collection from 'us.collection/constants';
import followup from "../constants"
import { fetchData, httpAzure } from 'us.helper'
import { httpCollection } from 'us.helper/http/collection';

export const getFollowups = async (params: any) => fetchData(collection.server + followup.endPoints.currentFollowups, 'GET', params);
export const getFollowupDetails = async (params: any) => httpAzure('https://unicornbox.azure-api.net/ARService/v1/follouwup', 'GET', params);

export const follouwup = {
    get: (): Promise<any> => {
        return new Promise<any>(async (resolve, reject) => {
          try {
            const { data } = await httpCollection.getById(
              'ARService',
              'ars/follouwups',
              ''
            );
            resolve(data)
          } catch (error) {
            reject(error)
          }
        });
      }
}

