export const NewAssetViewNames = {

    // names of the tabs
    REAL_ESTATE: 'RealEstate',
    PERSONAL_EFFECTS: 'PersonalEffects',
    SALARY: 'Salary',
    DOCUMENT_OF_TITLE: 'DocumentofTitle',
    VEHICLE: 'Vehicle',
    OTHER: 'Other',

    //postal code area selection names for each  tab
    REAL_ESTATE_POSTAL_CODE: 'RealEstatePostalCode',
    PERSONAL_EFFECTS_POSTAL_CODE: 'PersonalEffectsPostalCode',
    SALARY_POSTAL_CODE: 'SalaryPostalCode',
    OTHER_POSTAL_CODE: 'OtherPostalCode',

    // add postal code area names for each tab
    REAL_ESTATE_ADD_POSTAL_AREA: 'RealEstateAddPostalArea',
    PERSONAL_EFFECTS_ADD_POSTAL_AREA: 'PersonalEffectsAddPostalArea',
    SALARY_ADD_POSTAL_AREA: 'SalaryAddPostalArea',
    OTHER_ADD_POSTAL_AREA: 'OtherAddPostalArea',

    // select municipal for each tab
    REAL_ESTATE_SELECT_MUNICIPAL: 'RealEstateSelectMunicipal',
    PERSONAL_EFFECTS_SELECT_MUNICIPAL: 'PersonalEffectsSelectMuniciapal',
    SALARY_SELECT_MUNICIPAL: 'SalarySelectMunicipal',
    DOCUMENT_OF_TITLE_SELECT_MUNICIPAL: 'DocumentofTitleSelectMunicipal',
    VEHICLE_SELECT_MUNICIPAL: 'VehicleSelectMunicipal',
    OTHER_SELECT_MUNICIPAL: 'OtherSelectMunicipal',

    // postal code selection
    SELECT_POSTAL_CODE: 'SelectPostalCode',

    // postal code addition
    ADD_POSTAL_CODE: 'addPostalArea',

    // municipality selection
    SELECT_MUNICIPALITY: 'selectMinicipality',

    // employee selection
    SALARY_SELECT_EMPLOYEE: 'salarySelectEmployee',
}

// export const PostalORMunicipal = {
//     SELECT_POSTAL:'SELECT_POSTAL',
//     ADD_POSTAL:'ADD_POSTAL',
//     SELECT_MUNICIPAL:'SELECT_MUNICIPAL',
// }

export const NewEnforcementViewNames = {
    ADD_ASSET_FORM: 'ADD_ASSET_FORM',
    SELECT_POSTAL: 'SELECT_POSTAL',
    ADD_POSTAL: 'addPostalArea',
    SELECT_MUNICIPAL: 'selectMinicipality'
}

export const ExtendedFieldType = {
    Transaction: 'Transaction',
    Case: 'Case',
    Entity: 'Entity',
    Address: 'Address'
}