import React, { useEffect, useState, useRef } from 'react';
import { useHistory, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { IntlCurrency, validateNumber } from 'us.common/functions';
import { Formik } from 'formik';
import {
	$Form,
	$Affix,
	$PageHeader,
	$Divider,
	$Button,
	$DatePicker,
	$Switch,
	$Skeleton,
	$Popconfirm,
	$Row,
	$Col,
	$Alert,
	$FormItem,
	$InputAmount,
	$AmountLabel,
	$DateLabel,
} from 'us.common/components';
import { CheckCircleOutlined } from 'us.icons';
import { IBase, IAmount } from 'us.collection/interfaces';
import * as Action from 'us.collection/actions';
import { ValidationSchema } from './Validation';
import './ManageAgreements.scss';
import { dateFormats } from 'us.collection/components/ManageAgreements/Constants';
import {
	PaymentAgreement,
	Interest,
	PartPayment,
} from 'us.collection/repository';
import { IRootState } from 'us.collection/interfaces';
import { AccountSummeryWidgetActions } from 'us.collection.case/actions';
import _ from 'lodash';
import { CaseType } from 'us.helper/types/enums';
import { isSubCaseTransferredToCase } from 'us.common/functions';

const { paymentAgreement } = Action;
const { accountSummery } = AccountSummeryWidgetActions;

const ManageAgreements: React.FC<IBase> = (props) => {
	const { t } = useTranslation();
	const [total, setTotal] = useState<number>(0);
	const [balance, setBalance] = useState<number>(0);
	const inputEl = useRef<HTMLDivElement>();
	const [isUsePartPaymentView, setIsUsePartPaymentView] =
		useState<boolean>(false);

	const {
		currentCurrency,
		currentDateFormat,
		currentLanguage,
		details,
		amounts,
		save,
		interests,
		getInterest,
		deleteAgreement,
		metaData,
		get,
		history,
		location,
		accountSummeryWidgetData,
		getAccountSummeryWidgetData,
	} = props;

	const { caseId, entityType, caseNo } = metaData.data ?? {};

	useEffect(() => {
		const { params }: any = matchPath(props.location.pathname, {
			path: '/:caseType/:caseId/:agreements',
			exact: false,
			strict: false,
		});
		// chaeck view is use part payment
		params['agreements'].toString() === 'part-payments' &&
			setIsUsePartPaymentView(true);
		caseId &&
			get({
				entityType,
				caseNo: caseId,
			});
	}, [caseId]);

	useEffect(() => {
		setBalance(amounts.data?.totalAmount);
		setTotal(details.data?.totalBalance);
	}, [amounts, details, interests]);

	const addAmountFormatter = (data: any) => {
		const {
			interestCostBalance = 0,
			interestMainBalance = 0,
			collectionBalance = 0,
			costBalance = 0,
			courtBalance = 0,
			otherBalance = 0,
			mainBalance = 0,
			minCaseBalance = 0,
			totalAmount = 0,
		} = data;
		return {
			...data,
			interestCostBalance,
			interestMainBalance,
			collectionBalance,
			costBalance,
			courtBalance,
			otherBalance,
			mainBalance,
			minCaseBalance,
			totalAmount,
		};
	};
	const addInterestFormatter = (data: any) => {
		const {
			payDate,
			mainAmount = 0,
			collectionFee = 0,
			costFee = 0,
			courtFee = 0,
			otherFee = 0,
			interestMainBalance = 0,
			interestCostBalance = 0,
			interestDate = 0,
			isSendSMSNotification,
			collectionBalance = 0,
			costBalance = 0,
			courtBalance = 0,
			otherBalance = 0,
			mainBalance = 0,
			minCaseBalance = 0,
			totalBalance = 0,
		} = data;
		let temData = {};
		temData = !_.isEmpty(data) && {
			...data,
			payDate: moment(payDate).toString(),
			mainAmount,
			collectionFee,
			costFee,
			courtFee,
			otherFee,
			interestMainAmount: interestMainBalance,
			interestCostAmount: interestCostBalance,
			interestDate: moment(interestDate).toString(),
			isSendSMSNotification,
			interestCostBalance,
			interestMainBalance,
			collectionBalance,
			costBalance,
			courtBalance,
			otherBalance,
			mainBalance,
			minCaseBalance,
			totalAmount: totalBalance,
		};
		return temData;
	};
	const addDetailFormatter = (data: any) => {
		const {
			collectionFee = 0,
			costFee = 0,
			courtFee = 0,
			interestCostAmount = 0,
			interestMainAmount = 0,
			mainAmount = 0,
			otherFee = 0,
			totalBalance = 0,
		} = data;
		return {
			...data,
			collectionFee,
			costFee,
			courtFee,
			interestCostAmount,
			interestMainAmount,
			mainAmount,
			otherFee,
			total: totalBalance,
		};
	};
	const handleSubmit = (
		data: any,
		{
			setErrors,
			setStatus,
			resetForm,
			isSubmitting,
			isValidating,
			setSubmitting,
		}: any
	) => {
		if (metaData.data) {
			const paymentAgreement = PaymentAgreement.call(
				data,
				total,
				metaData.data,
				currentLanguage
			);
			save(paymentAgreement);
		}
	};

	const handleInputOnBlur = (e: any, rest: any, values: IAmount) => {
		const {
			collectionFee,
			costFee,
			courtFee,
			mainAmount,
			otherFee,
			interestCostAmount,
			interestMainAmount,
		} = values;

		const totalAmount =
			collectionFee +
			costFee +
			courtFee +
			mainAmount +
			otherFee +
			interestCostAmount +
			interestMainAmount;
		setTotal(totalAmount);
		rest.setFieldValue('totalAmount', totalAmount);
	};

	/**----------------------------------------------------- */
	const getNumber = (value: any): number => {
		const returnVal = validateNumber(value, currentLanguage);
		return returnVal && returnVal > 0 ? returnVal : 0;
	};

	const handleInterest = (data: any, rest: any, values: any) => {
		if (metaData.data) {
			data =
				data === null
					? moment().format(dateFormats.req)
					: data;
			rest.setFieldValue('interestDate', data);
			const interest = Interest.call(
				values,
				data,
				metaData.data,
				currentLanguage
			);
			getInterest(interest);
		}
	};
	const handleDelete = (values: any) => {
		deleteAgreement({
			entityType,
			caseNo: caseId,
			id: values.id,
		});
	};
	/**
	 * add payment agreement in add part payment view
	 */
	const addToPartPaymentview = (data: any, action: string) => {
		if (action == 'add' && metaData.data) {
			const partPayment = PartPayment.call(
				data,
				total,
				metaData.data,
				currentLanguage
			);
			props.addWithPartPayment(partPayment);
		} else {
			props.addWithPartPayment(null);
		}
	};
	/**----------------------------------------------------- */
	const minimize = () => {
		getAccountSummeryWidgetData &&
			getAccountSummeryWidgetData({
				EntityType:
					entityType === CaseType.S
						? 'Subcase'
						: 'Case',
				EntityId: caseId,
			});
		history.push({ ...location, pathname: `/case/${caseNo}` });
	};

	/**
	 * check whether case is close or not
	 * @returns true - if case is close ,otherwise return false
	 */
	const isCloseCase = (): boolean => {
		return accountSummeryWidgetData.data?.case?.closedDate
			? moment(accountSummeryWidgetData.data?.case.closedDate)
					.startOf('day')
					.isSameOrBefore(moment().startOf('day'))
			: false;
	};

	/**
	 *Set placeHolder
	 * @returns {string} - place holder according to the language
	 */
	const setPlaceHolder = (): string => {
		return IntlCurrency(0, currentLanguage, currentCurrency);
	};

	return (
		<div className='agreement-wrap '>
			<Formik
				enableReinitialize
				initialValues={{
					...addAmountFormatter(amounts.data),
					...addDetailFormatter(details.data),
					...addInterestFormatter(interests.data),
					isEdit: details.data?.id != 0,
					isInterestError: interests.isError,
				}}
				validationSchema={ValidationSchema}
				validateOnBlur
				validateOnChange
				onSubmit={handleSubmit}>
				{({
					values,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					isValidating,
					resetForm,
					errors,
					...rest
				}) => {
					return (
						<$Form onSubmit={handleSubmit}>
							<div className='space-content manage-agreements'>
								{!isUsePartPaymentView && (
									<$Affix
										offsetTop={
											80
										}>
										<div className='page-header header-border'>
											<div className='d-flex flex-row align-items-center justify-content-between'>
												<div className='d-flex align-items-center'>
													<$PageHeader
														className='px-0'
														onBack={
															minimize
														}
														title={t(
															'US.COLLECTION.CASE:MANAGE_AGREEMENTS.TITLE'
														)}
													/>
													<$Divider
														className='bui-devider'
														type='vertical'
													/>
													<$FormItem>
														{values.isEdit && (
															<$Skeleton
																loading={
																	details.isLoading
																}
																active
																paragraph={{
																	rows: 0,
																}}>
																<$Popconfirm
																	title={t(
																		'US.COLLECTION.CASE:MANAGE_AGREEMENTS.SURE_TO_DELETE'
																	)}
																	okText={t(
																		'US.COMMON:COMMON.YES'
																	)}
																	cancelText={t(
																		'US.COMMON:COMMON.NO'
																	)}
																	onConfirm={() =>
																		handleDelete(
																			values
																		)
																	}
																	placement='right'
																	disabled={isCloseCase()}>
																	<$Button
																		type='primary'
																		danger
																		size='small'
																		className='mr-2 px-4'
																		disabled={
																			isCloseCase() ||
																			(metaData.data &&
																				isSubCaseTransferredToCase(
																					metaData.data
																				))
																		}>
																		{t(
																			'US.COMMON:COMMON.DELETE'
																		)}
																	</$Button>
																</$Popconfirm>
															</$Skeleton>
														)}
														{!values.isEdit && (
															<$Skeleton
																loading={
																	details.isLoading
																}
																active
																paragraph={{
																	rows: 0,
																}}>
																<$Button
																	type='primary'
																	onClick={(
																		e: any
																	) =>
																		handleSubmit(
																			e
																		)
																	}
																	size='small'
																	className='mr-2 px-4'
																	disabled={
																		!rest.isValid ||
																		values.isInterestError ||
																		isSubmitting ||
																		isCloseCase() ||
																		(metaData.data &&
																			isSubCaseTransferredToCase(
																				metaData.data
																			))
																	}
																	loading={
																		isSubmitting ||
																		isValidating
																	}>
																	{t(
																		'US.COMMON:COMMON.SAVE'
																	)}
																</$Button>
															</$Skeleton>
														)}
													</$FormItem>
												</div>
											</div>
										</div>
									</$Affix>
								)}
								<div
									className={
										isUsePartPaymentView
											? ''
											: 'mt-3 px-3'
									}>
									<$Skeleton
										loading={
											details.isLoading
										}>
										<$Row
											gutter={
												16
											}>
											<$Col
												xl={{
													span: isUsePartPaymentView
														? '24'
														: '13',
												}}
												xxl={{
													span: isUsePartPaymentView
														? '24'
														: '11',
												}}>
												<$Row
													gutter={
														16
													}
													className='mb-3'>
													<$Col
														xl={{
															span: 6,
														}}
														xxl={{
															span: 7,
														}}>
														<strong>
															{t(
																'US.COLLECTION.CASE:MANAGE_AGREEMENTS.CURRENT_STATUS'
															)}
														</strong>
													</$Col>
													<$Col
														xl={{
															span: 5,
														}}
														xxl={{
															span: 6,
														}}></$Col>
													<$Col
														xl={{
															span: 13,
														}}
														xxl={{
															span: 11,
														}}>
														<strong>
															{t(
																'US.COLLECTION.CASE:MANAGE_AGREEMENTS.TITLE'
															)}
														</strong>
													</$Col>
												</$Row>

												<$Row
													gutter={
														16
													}>
													<$Col
														xl={{
															span: 6,
														}}
														xxl={{
															span: 7,
														}}>
														<$FormItem>
															<label>
																{t(
																	'US.COLLECTION.CASE:MANAGE_AGREEMENTS.FINAL_PAY_DATE'
																)}
															</label>
														</$FormItem>
													</$Col>
													<$Col
														xl={{
															span: 5,
														}}
														xxl={{
															span: 6,
														}}></$Col>
													<$Col
														xl={{
															span: 13,
														}}
														xxl={{
															span: 11,
														}}>
														<$DatePicker
															name='payDate'
															size='small'
															placeholder={
																currentDateFormat
															}
															format={
																currentDateFormat
															}
															style={{
																width: '100%',
															}}
															disabledDate={(
																d: any
															) =>
																!d ||
																d.isBefore(
																	moment().startOf(
																		'day'
																	)
																) ||
																d.isAfter(
																	moment()
																		.add(
																			values.maximumDurationForPA,
																			'days'
																		)
																		.startOf(
																			'day'
																		)
																)
															}
															allowClear
															disabled={
																values.isEdit
															}
															value={
																moment(
																	values.payDate
																).isValid()
																	? moment(
																			values.payDate
																	  )
																	: moment().add(
																			1,
																			'days'
																	  )
															}
														/>
														<div>
															<p>
																{t(
																	'US.COLLECTION.CASE:MANAGE_AGREEMENTS.HELP_TEXT'
																)}
															</p>
														</div>
													</$Col>
												</$Row>

												<$Row
													gutter={
														16
													}>
													<$Col
														xl={{
															span: 6,
														}}
														xxl={{
															span: 7,
														}}>
														<$FormItem>
															<label>
																{t(
																	'US.COLLECTION.CASE:MANAGE_AGREEMENTS.MAIN_AMOUNT_BALANCE'
																)}
															</label>
														</$FormItem>
													</$Col>
													<$Col
														xl={{
															span: 5,
														}}
														xxl={{
															span: 6,
														}}>
														<div className='text-right pr-4'>
															<$FormItem>
																<strong>
																	<$AmountLabel
																		value={
																			values.mainBalance
																		}
																	/>
																</strong>
															</$FormItem>
														</div>
													</$Col>
													<$Col
														xl={{
															span: 13,
														}}
														xxl={{
															span: 11,
														}}>
														<$InputAmount
															name='mainAmount'
															value={
																values?.mainAmount
															}
															disabled={
																values.isEdit
															}
															size='small'
															currentLanguage={
																currentLanguage
															}
															currentCurrency={
																currentCurrency
															}
															className='text-right w-100'
															onBlur={(
																e: any
															) =>
																handleInputOnBlur(
																	e,
																	rest,
																	values
																)
															}
															placeholder={setPlaceHolder()}
														/>
													</$Col>
												</$Row>

												<$Row
													gutter={
														16
													}>
													<$Col
														xl={{
															span: 6,
														}}
														xxl={{
															span: 7,
														}}>
														<$FormItem>
															<label>
																{t(
																	'US.COLLECTION.CASE:MANAGE_AGREEMENTS.COLLECTION_FEE_BALANCE'
																)}
															</label>
														</$FormItem>
													</$Col>
													<$Col
														xl={{
															span: 5,
														}}
														xxl={{
															span: 6,
														}}>
														<div className='text-right pr-4'>
															<$FormItem>
																<strong>
																	<$AmountLabel
																		value={
																			values.collectionBalance
																		}
																	/>
																</strong>
															</$FormItem>
														</div>
													</$Col>
													<$Col
														xl={{
															span: 13,
														}}
														xxl={{
															span: 11,
														}}>
														<$InputAmount
															name='collectionFee'
															value={
																values?.collectionFee
															}
															disabled={
																values.isEdit
															}
															size='small'
															currentLanguage={
																currentLanguage
															}
															currentCurrency={
																currentCurrency
															}
															className='text-right w-100'
															onBlur={(
																e: any
															) =>
																handleInputOnBlur(
																	e,
																	rest,
																	values
																)
															}
															placeholder={setPlaceHolder()}
														/>
													</$Col>
												</$Row>

												<$Row
													gutter={
														16
													}>
													<$Col
														xl={{
															span: 6,
														}}
														xxl={{
															span: 7,
														}}>
														<$FormItem>
															<label>
																{t(
																	'US.COLLECTION.CASE:MANAGE_AGREEMENTS.COST_FEE_BALANCE'
																)}
															</label>
														</$FormItem>
													</$Col>
													<$Col
														xl={{
															span: 5,
														}}
														xxl={{
															span: 6,
														}}>
														<div className='text-right pr-4'>
															<$FormItem>
																<strong>
																	<$AmountLabel
																		value={
																			values.costBalance
																		}
																	/>
																</strong>
															</$FormItem>
														</div>
													</$Col>
													<$Col
														xl={{
															span: 13,
														}}
														xxl={{
															span: 11,
														}}>
														<$InputAmount
															name='costFee'
															value={
																values?.costFee
															}
															size='small'
															currentLanguage={
																currentLanguage
															}
															currentCurrency={
																currentCurrency
															}
															disabled={
																values.isEdit
															}
															className='text-right w-100'
															onBlur={(
																e: any
															) =>
																handleInputOnBlur(
																	e,
																	rest,
																	values
																)
															}
															placeholder={setPlaceHolder()}
														/>
													</$Col>
												</$Row>

												<$Row
													gutter={
														16
													}>
													<$Col
														xl={{
															span: 6,
														}}
														xxl={{
															span: 7,
														}}>
														<$FormItem>
															<label>
																{t(
																	'US.COLLECTION.CASE:MANAGE_AGREEMENTS.COURT_FEE_BALANCE'
																)}
															</label>
														</$FormItem>
													</$Col>
													<$Col
														xl={{
															span: 5,
														}}
														xxl={{
															span: 6,
														}}>
														<div className='text-right pr-4'>
															<$FormItem>
																<strong>
																	<$AmountLabel
																		value={
																			values.courtBalance
																		}
																	/>
																</strong>
															</$FormItem>
														</div>
													</$Col>
													<$Col
														xl={{
															span: 13,
														}}
														xxl={{
															span: 11,
														}}>
														<$InputAmount
															name='courtFee'
															value={
																values?.courtFee
															}
															disabled={
																values.isEdit
															}
															size='small'
															currentLanguage={
																currentLanguage
															}
															currentCurrency={
																currentCurrency
															}
															className='text-right w-100'
															onBlur={(
																e: any
															) =>
																handleInputOnBlur(
																	e,
																	rest,
																	values
																)
															}
															placeholder={setPlaceHolder()}
														/>
													</$Col>
												</$Row>

												<$Row
													gutter={
														16
													}>
													<$Col
														xl={{
															span: 6,
														}}
														xxl={{
															span: 7,
														}}>
														<$FormItem>
															<label>
																{t(
																	'US.COLLECTION.CASE:MANAGE_AGREEMENTS.OTHER_FEE_BALANCE'
																)}
															</label>
														</$FormItem>
													</$Col>
													<$Col
														xl={{
															span: 5,
														}}
														xxl={{
															span: 6,
														}}>
														<div className='text-right pr-4'>
															<$FormItem>
																<strong>
																	<$AmountLabel
																		value={
																			values.otherBalance
																		}
																	/>
																</strong>
															</$FormItem>
														</div>
													</$Col>
													<$Col
														xl={{
															span: 13,
														}}
														xxl={{
															span: 11,
														}}>
														<$InputAmount
															name='otherFee'
															value={
																values?.otherFee
															}
															size='small'
															currentLanguage={
																currentLanguage
															}
															currentCurrency={
																currentCurrency
															}
															disabled={
																values.isEdit
															}
															className='text-right w-100'
															onBlur={(
																e: any
															) =>
																handleInputOnBlur(
																	e,
																	rest,
																	values
																)
															}
															placeholder={setPlaceHolder()}
														/>
													</$Col>
												</$Row>

												<div className='gray-box mx-n3 mt-2 mb-3'>
													<$Row
														gutter={
															16
														}>
														<$Col
															xl={{
																span: 6,
															}}
															xxl={{
																span: 7,
															}}>
															<$FormItem>
																<label>
																	{t(
																		'US.COLLECTION.CASE:MANAGE_AGREEMENTS.CALCULATE_INTEREST_TILL'
																	)}
																</label>
															</$FormItem>
														</$Col>
														<$Col
															xl={{
																span: 5,
															}}
															xxl={{
																span: 6,
															}}>
															<div className='text-right pr-4'></div>
														</$Col>
														<$Col
															xl={{
																span: 13,
															}}
															xxl={{
																span: 11,
															}}>
															<$FormItem
																help={
																	values.isInterestError &&
																	t(
																		`US.COLLECTION.VALIDATIONS:NOTIFICATION.DATE_SHOULD_BE_GREATER_THAN_DUE_DATE`
																	)
																}
																validateStatus={
																	values.isInterestError &&
																	'error'
																}>
																<$DatePicker
																	name='interestDate'
																	size='small'
																	allowClear={
																		true
																	}
																	placeholder={
																		currentDateFormat
																	}
																	format={
																		currentDateFormat
																	}
																	style={{
																		width: '100%',
																	}}
																	disabledDate={(
																		d: any
																	) =>
																		!d ||
																		d.isBefore(
																			moment().startOf(
																				'day'
																			)
																		)
																	}
																	onChange={(
																		e: any
																	) =>
																		handleInterest(
																			e,
																			rest,
																			values
																		)
																	}
																	disabled={
																		values.isEdit
																	}
																	value={
																		moment(
																			values.interestDate
																		).isValid() &&
																		moment
																			.utc(
																				values.interestDate
																			)
																			.isAfter(
																				moment.utc(
																					'0001-01-01'
																				)
																			)
																			? moment(
																					values.interestDate
																			  )
																			: moment()
																	}
																/>
															</$FormItem>
														</$Col>
													</$Row>

													<$Row
														gutter={
															16
														}>
														<$Col
															xl={{
																span: 6,
															}}
															xxl={{
																span: 7,
															}}>
															<$FormItem>
																<label>
																	{t(
																		'US.COLLECTION.CASE:MANAGE_AGREEMENTS.INTEREST_MAIN_BALANCE'
																	)}
																</label>
															</$FormItem>
														</$Col>
														<$Col
															xl={{
																span: 5,
															}}
															xxl={{
																span: 6,
															}}>
															<div className='text-right pr-4'>
																<$FormItem>
																	<strong>
																		<$AmountLabel
																			value={
																				values.interestMainBalance
																			}
																		/>
																	</strong>
																</$FormItem>
															</div>
														</$Col>
														<$Col
															xl={{
																span: 13,
															}}
															xxl={{
																span: 11,
															}}>
															<$InputAmount
																name='interestMainAmount'
																value={
																	values?.interestMainAmount
																}
																size='small'
																currentLanguage={
																	currentLanguage
																}
																currentCurrency={
																	currentCurrency
																}
																ref={
																	inputEl
																}
																disabled={
																	values.isEdit
																}
																className='text-right w-100'
																onBlur={(
																	e: any
																) =>
																	handleInputOnBlur(
																		e,
																		rest,
																		values
																	)
																}
																placeholder={setPlaceHolder()}
															/>
														</$Col>
													</$Row>

													<$Row
														gutter={
															16
														}>
														<$Col
															xl={{
																span: 6,
															}}
															xxl={{
																span: 7,
															}}>
															<$FormItem>
																<label>
																	{t(
																		'US.COLLECTION.CASE:MANAGE_AGREEMENTS.INTEREST_COSTS_BALANCE'
																	)}
																</label>
															</$FormItem>
														</$Col>
														<$Col
															xl={{
																span: 5,
															}}
															xxl={{
																span: 6,
															}}>
															<div className='text-right pr-4'>
																<$FormItem>
																	<strong>
																		<$AmountLabel
																			value={
																				values.interestCostBalance
																			}
																		/>
																	</strong>
																</$FormItem>
															</div>
														</$Col>
														<$Col
															xl={{
																span: 13,
															}}
															xxl={{
																span: 11,
															}}>
															<$InputAmount
																name='interestCostAmount'
																value={
																	values?.interestCostAmount
																}
																size='small'
																currentLanguage={
																	currentLanguage
																}
																currentCurrency={
																	currentCurrency
																}
																disabled={
																	values.isEdit
																}
																className='text-right w-100'
																onBlur={(
																	e: any
																) =>
																	handleInputOnBlur(
																		e,
																		rest,
																		values
																	)
																}
																placeholder={setPlaceHolder()}
															/>
														</$Col>
													</$Row>
												</div>

												<$Row
													gutter={
														16
													}>
													<$Col
														xl={{
															span: 6,
														}}
														xxl={{
															span: 7,
														}}>
														<$FormItem>
															<h3>
																<strong>
																	{t(
																		'US.COLLECTION.CASE:MANAGE_AGREEMENTS.TOTAL'
																	)}
																</strong>
															</h3>
														</$FormItem>
													</$Col>
													<$Col
														xl={{
															span: 5,
														}}
														xxl={{
															span: 6,
														}}>
														<div className='text-right pr-4'>
															<$FormItem>
																<h3>
																	<strong>
																		<$AmountLabel
																			value={
																				balance
																			}
																		/>
																	</strong>
																</h3>
															</$FormItem>
														</div>
													</$Col>
													<$Col
														xl={{
															span: 13,
														}}
														xxl={{
															span: 11,
														}}>
														<$FormItem name='totalAmount'>
															{/* <h3 className="text-right"><strong>{total}</strong></h3> */}
															<h3
																className={
																	errors.totalAmount
																		? 'text-right pr-2 text-error'
																		: 'text-right pr-2'
																}>
																<strong>
																	<$AmountLabel
																		value={
																			total
																		}
																	/>
																</strong>
															</h3>
														</$FormItem>
													</$Col>
												</$Row>

												{errors.totalAmount && (
													<$Row
														gutter={
															16
														}
														className='mb-3'>
														<$Col
															lg={{
																span: 24,
															}}
															xl={{
																span: 24,
															}}>
															<$FormItem name='totalAmountaa'>
																<$Alert
																	type='error'
																	message={`${t(
																		errors.totalAmount as string
																	)} ${IntlCurrency(
																		values.minCaseBalance,
																		currentLanguage,
																		currentCurrency
																	)}`}
																/>
															</$FormItem>
														</$Col>
													</$Row>
												)}
												{!isUsePartPaymentView && (
													<$Row
														gutter={
															16
														}>
														<$Col
															lg={{
																span: 24,
															}}
															xl={{
																span: 24,
															}}>
															<$FormItem>
																<label>
																	{t(
																		'US.COLLECTION.CASE:MANAGE_AGREEMENTS.SMS_NOTIFICATION'
																	)}
																</label>
																<$Switch
																	className='ml-2'
																	checked={
																		values.isSendSMSNotification
																	}
																	size='small'
																	disabled={
																		values.isEdit
																	}
																	onChange={(
																		val: any
																	) => {
																		rest.setFieldValue(
																			'isSendSMSNotification',
																			val
																		);
																	}}
																	name='isSendSMSNotification'
																/>
															</$FormItem>
														</$Col>
													</$Row>
												)}

												{values.isEdit && (
													<div className='mx-n3 mt-3 debtor-agreed'>
														<div className='d-flex align-items-center justify-content-between'>
															<div className='d-flex align-items-center'>
																<CheckCircleOutlined className='agreed-icon' />
																<strong className='pl-2'>
																	{t(
																		'US.COLLECTION.CASE:MANAGE_AGREEMENTS.DEBTOR_AGREED'
																	)}
																</strong>
															</div>
															<div className='text-right pr-2'>
																<div className='amount'>
																	<strong>
																		<$AmountLabel
																			value={
																				total
																			}
																		/>
																	</strong>
																</div>
																<small>
																	{t(
																		'US.COLLECTION.CASE:MANAGE_AGREEMENTS.ON'
																	)}{' '}
																	<$DateLabel
																		value={
																			values.payDate
																		}
																	/>
																</small>
															</div>
														</div>
													</div>
												)}
											</$Col>
										</$Row>
									</$Skeleton>
								</div>
							</div>
							{isUsePartPaymentView && (
								<div className='drawer-footer-fixed align-content-center justify-content-end'>
									<div>
										<$Button
											type='primary'
											onClick={() =>
												addToPartPaymentview(
													values,
													'add'
												)
											}
											className='mr-2'
											disabled={
												!rest.isValid
											}>
											{t(
												'US.COLLECTION.COMMON:COMMON.SAVE'
											)}
										</$Button>
										<$Popconfirm
											title={t(
												'US.COLLECTION.CASE:MANAGE_AGREEMENTS.CANCEL_ERROR'
											)}
											placement='topLeft'
											onConfirm={() =>
												addToPartPaymentview(
													values,
													'cancel'
												)
											}
											okText={t(
												'US.COLLECTION.COMMON:COMMON.YES'
											)}
											cancelText={t(
												'US.COLLECTION.COMMON:COMMON.NO'
											)}>
											<$Button>
												{t(
													'US.COLLECTION.COMMON:COMMON.CANCEL'
												)}
											</$Button>
										</$Popconfirm>
									</div>
								</div>
							)}
						</$Form>
					);
				}}
			</Formik>
		</div>
	);
};

const mapStateToProps = (state: IRootState) => {
	const { common, paymentAgreement, dashboard, domainView } = state;
	const { currentLanguage, currentDateFormat, currentCurrency } = common;
	const { metaData } = domainView;
	const { accountSummeryWidgetData } = dashboard;
	const { amounts, interests, details } = paymentAgreement;
	return {
		currentLanguage,
		currentCurrency,
		currentDateFormat,
		interests,
		metaData,
		amounts,
		details,
		accountSummeryWidgetData,
	};
};

const mapDispatchToProps = {
	save: paymentAgreement.save.save,
	deleteAgreement: paymentAgreement.delete.delete,
	get: paymentAgreement.amountsAndDetails.get,
	getInterest: paymentAgreement.interests.get,
	getAccountSummeryWidgetData: accountSummery.get,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAgreements);
