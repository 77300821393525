import React from "react";
import { Route } from "react-router-dom";
import { IAsyncRoute } from "us.collection/interfaces";

const BulkOperation = React.lazy(() => import("../containers/BulkOperation"));
const CaseCloseRules = React.lazy(() => import("../containers/CaseCloseRules"));
const CollectionFee = React.lazy(() => import("../containers/CollectionFee"));
const CourtInfo = React.lazy(() => import("../containers/CourtInfo"));
const Creditors = React.lazy(() => import("../containers/Creditors"));
const CustomSettings = React.lazy(() => import("../containers/CustomSettings"));
const DailyCheckups = React.lazy(() => import("../containers/DailyCheckups"));
const DataProtection = React.lazy(() => import("../containers/DataProtection"));
const Departments = React.lazy(() => import("../containers/Departments"));
const FollowupList = React.lazy(() => import("../containers/FollowupList"));
const InvoiceExportPlugins = React.lazy(() => import("../containers/InvoiceExportPlugins"));
const Municipalities = React.lazy(() => import("../containers/Municipalities"));
const PostalAreas = React.lazy(() => import("../containers/PostalAreas"));
const ProvisionProfiles = React.lazy(() => import("../containers/ProvisionProfiles"));
const Routines = React.lazy(() => import("../containers/Routines"));
const SystemCategory = React.lazy(() => import("../containers/SystemCategory"));
const SystemConfiguration = React.lazy(() => import("../containers/SystemConfiguration"));
const Triggers = React.lazy(() => import("../containers/Triggers"));
const WinMidImport = React.lazy(() => import("../containers/WinMidImport"));
const NonProductionDaysHome = React.lazy(() => import("../containers/NonProductionDays/Home"));
const NonProductionDaysCalendar = React.lazy(
  () => import("../containers/NonProductionDays/Calendar")
);

export default [
  <Route exact key="/bulk-operation" path={"/bulk-operation"} render={() => <BulkOperation />} />,
  <Route
    exact
    key="/case-close-rules"
    path={"/case-close-rules"}
    render={() => <CaseCloseRules />}
  />,
  <Route exact key="/collection-fee" path={"/collection-fee"} render={() => <CollectionFee />} />,
  <Route exact key="/court-info" path={"/court-info"} render={() => <CourtInfo />} />,
  <Route exact key="/creditors" path={"/creditors"} render={() => <Creditors />} />,
  <Route
    exact
    key="/custom-settings"
    path={"/custom-settings"}
    render={() => <CustomSettings />}
  />,
  <Route exact key="/daily-checkups" path={"/daily-checkups"} render={() => <DailyCheckups />} />,
  <Route
    exact
    key="/data-protection"
    path={"/data-protection"}
    render={() => <DataProtection />}
  />,
  <Route exact key="/departments" path={"/departments"} render={() => <Departments />} />,
  <Route exact key="/followup-list" path={"/followup-list"} render={() => <FollowupList />} />,
  <Route
    exact
    key="/invoce-export-plugins"
    path={"/invoce-export-plugins"}
    render={() => <InvoiceExportPlugins />}
  />,
  <Route exact key="/municipalities" path={"/municipalities"} render={() => <Municipalities />} />,
  <Route exact key="/postal-areas" path={"/postal-areas"} render={() => <PostalAreas />} />,
  <Route
    exact
    key="/provision-profiles"
    path={"/provision-profiles"}
    render={() => <ProvisionProfiles />}
  />,
  <Route exact key="/routines" path={"/routines"} render={() => <Routines />} />,
  <Route
    exact
    key="/system-category"
    path={"/system-category"}
    render={() => <SystemCategory />}
  />,
  <Route
    exact
    key="/system-configuration"
    path={"/system-configuration"}
    render={() => <SystemConfiguration />}
  />,
  <Route exact key="/triggers" path={"/triggers"} render={() => <Triggers />} />,
  <Route exact key="/win-mid-import" path={"/win-mid-import"} render={() => <WinMidImport />} />,
  <Route
    exact
    key="/non-production-days"
    path={"/non-production-days"}
    render={(props) => <NonProductionDaysHome {...props} />}
  />,
  <Route
    exact
    key="non-production-calendar"
    path={"/non-production-days/calendar/:calendarId?"}
    render={(props) => <NonProductionDaysCalendar {...props} />}
  />,
];

export const AdminContainers: Array<IAsyncRoute> = [
  {
    Container: React.lazy(() => import("us.collection.admin/containers/Activities")),
    Route: "activities",
  },
  {
    Container: React.lazy(() => import("us.collection.admin/containers/ActivityGroups")),
    Route: "activity-groups",
  },
  {
    Container: React.lazy(() => import("us.collection.admin/containers/BulkOperation")),
    Route: "bulk-operation",
  },
  {
    Container: React.lazy(() => import("us.collection/containers/BMD")),
    Route: "bmd",
  },
];

////////////
export const BureauContainers: Array<IAsyncRoute> = [
  {
    Container: React.lazy(() => import("us.collection.admin/containers/CreditorGroup/Home")),
    Route: "manage-creditor-groups",
  },
  {
    Container: React.lazy(
      () => import("us.collection.routines-and-activities/containers/RoutinesAndActivities")
    ),
    Route: "history",
  },
  {
    Container: React.lazy(() => import("us.collection/containers/BMD")),
    Route: "bmd",
  },
  {
    Container: React.lazy(() => import("us.collection/containers/Workflow")),
    Route: "workflows",
  },
  {
    Container: React.lazy(() => import("us.collection/containers/WorkflowTroubleshoot")),
    Route: "workflows-troubleshoot",
  },
  {
    Container: React.lazy(() => import("us.collection/containers/BMD")),
    Route: "",
  }
];
