import { getCaseNumberAndType } from "us.collection.routines-and-activities/components/Note/Functions";
import {
  RegisteredUserType,
  ActivityStatusForPayload,
  ActivityTypesForPayload,
} from "us.collection/constants";

export function UpdateNotePayload(this: any) {
  const { values, noteId, location, metaData } = this ?? {};
  if (metaData.data) {
    const { caseNo, pid, type, eType } = getCaseNumberAndType(location, metaData.data);

    return {
      ...values,
      noteId,
      entityId: pid,
      entityType: type,
      dueDate: "",
      copyToActiveCases: false,
      copyToChildCreditors: false,
      manualDueDate: "",
      noteType: 0,
      activityParams: {
        entityId: caseNo,
        entityType: eType,
        activityType: !location?.pathname?.includes("history")
          ? ActivityTypesForPayload.MESSAGE
          : ActivityTypesForPayload.ALL,
        activityStatus: !location?.pathname?.includes("history")
          ? ActivityStatusForPayload.SUCCESS
          : ActivityStatusForPayload.ALL,
        registeredUserType: RegisteredUserType.ALL,
      },
    };
  } else {
    return {
      ...values,
      noteId,
      entityId: "",
      entityType: "",
      dueDate: "",
      copyToActiveCases: false,
      copyToChildCreditors: false,
      manualDueDate: "",
      noteType: 0,
      activityParams: {
        entityId: "",
        entityType: "",
        activityType: ActivityTypesForPayload.ALL,
        activityStatus: ActivityStatusForPayload.ALL,
        registeredUserType: RegisteredUserType.ALL,
      },
    };
  }
}
