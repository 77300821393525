import Constants from "us.collection.case/constants";
import { IAPIAction } from "us.collection/interfaces";
import { IInterestOccurrences } from 'us.collection.case/actions/CaseInformation/Interface'

const { ACTIONS } = Constants;
const { CaseInformation } = ACTIONS;

export const CaseInformationAction: Readonly<IAPIAction & IInterestOccurrences> = {
  /**
   * @namespace CaseInformation
   */
  CaseInformation: {
    get: (data) => ({
      type: CaseInformation.GET_CASEINFO,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CaseInformation.GET_CASEINFO_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CaseInformation.GET_CASEINFO_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  /**
   * @namespace InterestOccurrences
   */
  InterestOccurrences: {
    get: (data) => ({
      type: CaseInformation.GET_INTEREST_OCCURRENCES_START,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CaseInformation.GET_INTEREST_OCCURRENCES_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CaseInformation.GET_INTEREST_OCCURRENCES_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  /**
   * @namespace AddInterestOccurrences
   */
  AddInterestOccurrences: {
    save: (data) => ({
      type: CaseInformation.ADD_INTEREST_OCCURRENCES_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CaseInformation.ADD_INTEREST_OCCURRENCES_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CaseInformation.ADD_INTEREST_OCCURRENCES_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  /**
   * @namespace CaseInformationSave
   */
  CaseInformationSave: {
    save: (data) => ({
      type: CaseInformation.SAVE_CASEINFO,
      payload: {
        data: data,
        isLoading: true,
      },
    }),

    success: (data) => ({
      type: CaseInformation.SAVE_CASEINFO_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),

    fail: (error) => ({
      type: CaseInformation.SAVE_CASEINFO_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  /**
   * DeleteInterestOccurrences
   */
   DeleteInterestOccurrences: {
    delete: (data) => ({
      type: CaseInformation.DELETE_INTEREST_OCCURRENCES_START,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CaseInformation.DELETE_INTEREST_OCCURRENCES_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CaseInformation.DELETE_INTEREST_OCCURRENCES_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
};
