import { DrawerTypes } from "us.collection.creditor/components/ManageCreditors/Constants";
import { ICreditorData } from "us.collection.creditor/components/ManageCreditors/Components/BasicInformation";

/**
 * @description - Get validation status
 * @param errors - errors object
 * @returns - validation status
 */
export const isValid = (errors: any): boolean => {
  try {
    return Object.keys(errors).length === 0;
  } catch (error) {
    return false;
  }
};

/**
 * @description - Get drawer title
 * @param type - drawer type
 * @returns - title of the drawer
 */
export const getDrawerTitle = (type: string): string => {
  switch (type) {
    case DrawerTypes.GROUP_NO:
      return "US.COLLECTION.CREDITOR:CREDITOR_INFO.SELECT_CREDITOR_GROUP_NO";
    case DrawerTypes.BELONGS_TO:
      return "US.COLLECTION.CREDITOR:CREDITOR_INFO.SELECT_BELONG_CREDITOR";
    case DrawerTypes.POSTAL_CODE:
      return "US.COMMON:COMMON.SELECT_POSTAL_AREA";
    default:
      return "";
  }
};

/**
 * @description - Get existing creditor data
 * @param creditorDetails - current details of editing creditor
 * @returns - existing creditor data
 */
export const getExistingCreditorData = (
  creditorDetails: any
): ICreditorData => {
  let masterCreditor = ''
  if(creditorDetails?.extendedFields?.MasterCreditorInkassoId && creditorDetails?.extendedFields?.MasterCreditorName){
    masterCreditor = creditorDetails?.extendedFields?.MasterCreditorInkassoId+ " - " +creditorDetails?.extendedFields?.MasterCreditorName
  }
  return {
    entRoleId: creditorDetails?.entRoleId,
    entId: creditorDetails?.entId,
    creditorNumber: creditorDetails?.pid,
    creditorName: creditorDetails?.fullName,
    groupNo: creditorDetails?.extendedFields?.creditorGroupId,
    groupName: creditorDetails?.extendedFields?.GroupName,
    belongsTo: masterCreditor,
    belongsToId: creditorDetails?.extendedFields?.MasterCreditorInkassoId,
    nin: creditorDetails?.idNo,
    account: creditorDetails?.bankAccountNo,
    accountNo: creditorDetails?.extendedFields?.remitAccountNo,
    productNo: creditorDetails?.extendedFields?.ProductNo == 0 ? '' : creditorDetails?.extendedFields?.ProductNo,
    caseHandler: creditorDetails?.caseHandler,
    agreement: creditorDetails?.agreement,
    address1: creditorDetails?.address1,
    address2: creditorDetails?.address2,
    address3: creditorDetails?.address3,
    country: creditorDetails?.countryCode,
    postalCode: creditorDetails?.zipCode,
    postalPlace: creditorDetails?.zipName,
    email: creditorDetails?.email,
    direct: creditorDetails?.contacts?.home,
    mobile: creditorDetails?.contacts?.mobile,
    office: creditorDetails?.contacts?.work,
    fax: creditorDetails?.contacts?.fax,
    im1: creditorDetails?.contacts?.sms,
    im2: creditorDetails?.extendedFields?.skype,
    im3: creditorDetails?.extendedFields?.msn,
    AllowMoveToBMD: false,
    GetBelongsToSettings: false
  };
};

/**
 * @description - check visibility of a element
 * @param isDrawer - isDrawer status
 * @param newEntityData - new Creditor data
 * @returns - visibility status
 */
export const isVisibleElement = (
  isDrawer: any,
  newEntityData: any
): boolean => {
  try {
    return (
      (isDrawer != undefined && newEntityData?.entRoleId == undefined)
      // !isDrawer
    );
  } catch (error) {
    return false;
  }
};

/**
 * @description - check visibility of a contact person
 * @param isDrawer - isDrawer status
 * @param newEntityData - new Creditor data
 * @returns - visibility status
 */
export const isVisibleContactPerson = (
  isDrawer: any,
  newEntityData: any
): boolean => {
  try {
    return (
      (isDrawer != undefined && newEntityData?.entRoleId != undefined)
    );
  } catch (error) {
    return false;
  }
};

/**
 * @description - Get validation status
 * @param errors - errors object
 * @returns - validation status
 */
 export const isFormDirty = (values: any, existingData:any): boolean => {
  try {
    return JSON.stringify(values) !==
    JSON.stringify(existingData)
  } catch (error) {
    return false;
  }
};