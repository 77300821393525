import moment from "moment";
import { EntityType, SearchDateTypes } from "us.collection.creditor/components/CreditorCases/Constants";

export enum CreditorCases {
  GET_CREDITOR_CASES = "GET_CREDITOR_CASES",
  GET_CREDITOR_CASES_SUCCESS = "GET_CREDITOR_CASES_SUCCESS",
  GET_CREDITOR_CASES_FAIL = "GET_CREDITOR_CASES_FAIL",
}

export type creditorCasesInitialValues = {
  isCollapsedAll: boolean;
  isCaseOpen: boolean;
  isCaseClosed: boolean;
  caseType: string;
  caseDateRange: Array<any>;
  debtorNameOrNumber: string;
  regDate: any;
  voucherDate: any;
  invoiceRef: string;
  invoiceKid: string;
  mainAmountFrom: string;
  mainAmountTo: string;
  searchDateType: string;
};

export const CREDITOR_CASES_INITIAL_VALUES: creditorCasesInitialValues = {
  isCollapsedAll: false,
  isCaseOpen: true,
  isCaseClosed: false,
  caseType: EntityType.ALL,
  caseDateRange: [moment().subtract(1, "month"), moment()],
  debtorNameOrNumber: "",
  regDate: null,
  voucherDate: null,
  invoiceRef: "",
  invoiceKid: "",
  mainAmountFrom: "",
  mainAmountTo: "",
  searchDateType: SearchDateTypes.REG_DATE
};
