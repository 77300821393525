
const initialState = {
    followupList: [],
    followupDetailList: [],
    activityHistoryList: [],
    activityDataList: [],
    currentStatus: [],
    caseAssets: [],
    transactions: [],
    transactionTypes: [],
    sentencesList: [],
    routingsAndActivitiesList: [],
    notesList: [],
    caseInterest: [],
    casePropDetail: [],
    extendedField: [],
    enforcements: [],
    userAgreements: {},
    caseNo: undefined,
    partPaymentDetails: [],
    error: null,
    caseType: undefined,
    transactionType: 'all'
}


export default (state: any = initialState, action: any) => {
    let newState = state;

    switch (action.type) {
        case 'GET_FOLLOWUPS_SUCCESS':
            newState = {
                ...state,
                followupList: [
                    {
                        "ide": 1,
                        "type": "Ready to send to court",
                        "count": 10
                    },
                    {
                        "ide": 2,
                        "type": "Address Unknown",
                        "count": 120
                    },
                    {
                        "ide": 3,
                        "type": "Objections",
                        "count": 15
                    }
                ],
                error: null,
            };
            break;
        case 'GET_FOLLOWUP_LIST_SUCCESS':
            newState = {
                ...state,
                followupDetailList: action.payload,
                error: null
            }
            break;
        case 'GET_ACTIVITY_HISTORY_SUCCESS':
            newState = {
                ...state,
                activityHistoryList: action.payload,
                error: null
            }
            break;
        case 'GET_ACTIVITY_DATA_SUCCESS':
            newState = {
                ...state,
                activityDataList: action.payload,
                error: null
            }
            break;
        case 'GET_CASE_LOGS_SUCCESS':
            newState = {
                ...state,
                caseLogList: action.payload,
                error: null
            }
            break;
        case 'GET_CURRENT_STATUS_SUCCESS':
            newState = {
                ...state,
                currentStatus: action.payload,
                error: null
            }
            break;
        case 'GET_CASE_ASSETS_SUCCESS':
            newState = {
                ...state,
                caseAssets: action.payload,
                error: null
            }
            break;
        case 'GET_TRANSACTION_LIST_SUCCESS':
            newState = {
                ...state,
                transactions: action.payload,
                error: null
            }
            break;
        case 'GET_TRANSACTION_TYPE_SUCCESS':
            newState = {
                ...state,
                transactionTypes: action.payload,
                error: null
            }
            break;
        case 'GET_SENTENCES_LIST_SUCCESS':
            newState = {
                ...state,
                sentencesList: action.payload,
                error: null
            }
            break;
        case 'GET_ROTINES_AND_ACTIVITIES_SUCCESS':
            newState = {
                ...state,
                routingsAndActivitiesList: action.payload,
                error: null
            }
            break;
        case 'GET_NOTES_LIST_SUCCESS':
            newState = {
                ...state,
                notesList: action.payload,
                error: null
            }
            break;

        case 'GET_CASE_INTEREST_SUCCESS':
            newState = {
                ...state,
                caseInterest: action.payload,
                error: null
            }
            break;

        case 'GET_CASE_PROP_SUCCESS':
            newState = {
                ...state,
                casePropDetail: action.payload,
                error: null
            }
            break;

        // case 'GET_EXTENDED_FIELD_SUCCESS':
        //     newState = {
        //         ...state,
        //         extendedField: action.payload,
        //         error: null
        //     }
        //     break;

        case 'GET_ENFORCEMENT_SUCCESS':
            newState = {
                ...state,
                enforcements: action.payload,
                error: null
            }
            break;

        case 'GET_USER_AGREEMENT_DETAILS_SUCCESS':
            newState = {
                ...state,
                userAgreements: action.payload,
                error: null
            }
            break;

        case 'SET_CURRENT_CASE_NO_ACTION':
            newState = {
                ...state,
                caseNo: action.payload,
                error: null
            }
            break;
        case 'SET_CURRENT_CASE_TYPE_AND_TRANSACTION_TYPE_ACTION':
            newState = {
                ...state,
                caseType: action.caseType,
                transactionType:action.transactionType,
                error: null
            }
            break;

        // case 'SET_CURRENT_CASE_ACTION':
        //     newState = {
        //         ...state,
        //         currentCase: action.payload,
        //         error: null
        //     }
        //     break;

        case 'GET_CASE_PARTPAYMENTS_SUCCESS':
            newState = {
                ...state,
                partPaymentDetails: action.payload,
                error: null
            }
            break;

        default:
            break;
    }
    return newState;

}