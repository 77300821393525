import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { ConnectedProps, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';

import * as Actions from 'us.collection.transactions/actions';
import * as DashboardActions from 'us.collection.case/actions';
import ExcelExporter from 'us.collection/components/ExcelGenerator';
import { IExcleColumn } from 'us.collection/components/ExcelGenerator/Interface';
import Common from 'us.common';
import { PlusOutlined, ReloadOutlined } from 'us.icons';
import { CaseType } from 'us.helper/types/enums';
import {
	Option,
	ITransactionsHome,
} from 'us.collection.transactions/components/Transactions/Interfaces';
import { filterColumnsByDataIndexAndCaseType } from 'us.collection.transactions/components/Transactions/Functions';
import { excelColumns } from 'us.collection.transactions/components/Transactions/Constants';
import { Mapping, TransactionColumns, EditInterest } from './Components';
import { transGroupByTransType } from 'us.collection.transactions/functions';
import { IOnFilter, IOnSort } from 'us.common/components';
import { appInsights } from 'us.helper';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { TransactionDetails } from 'us.collection.transactions/components';
import { RootState } from 'us.helper/types';

const {
	$Tooltip,
	$Select,
	$Button,
	$Divider,
	$PageHeader,
	$Affix,
	$Drawer,
	$Skeleton,
	$TableTree,
} = Common.Components;

/**
 * @description - Transaction page for view list of transaction.
 * @author Mahesh Suranga
 * @since 1/03/2021
 */

const TransactionsHome: React.FC<ITransactionsHome & PropsFromRedux> = (
	props
) => {
	const {
		currentCurrency,
		currentLanguage,
		isFetching,
		visiblePopOver,
		getTransactions,
		transactions,
		caseType,
		selectTransaction,
		transType,
		resetSelectedTransaction,
		manageTransactionDrawer,
		manageMappingTransactionDrawer,
		transactionDrawer,
		mappingTransactionDrawer,
		currentDateFormat,
		metaData,
		managePopOver,
		manageEditInterestDrawer,
		editInterestDrawer,
		getAccountSummeryWidgetData,
		resetInterestApplication,
	} = props;

	const { t } = useTranslation();
	const { caseNo, entityType, caseId } = metaData.data ?? {};

	const location = useLocation();
	const history = useHistory();

	const [filterValue, setFilterValue] = useState<string>('');
	const [filterOption, setFilterOption] = useState<Array<Option>>([
		{ label: t('US.COMMON:COMMON.ALL'), value: 'All' },
	]);
	const [caseDetail, setCaseDetail] = useState<{
		caseId: string | number;
		caseType: string;
	}>({ caseId: '0', caseType: CaseType.S });
	const [transactionList, setTransactionList] = useState<Array<any>>([]);

	useEffect(() => {
		if (caseId && entityType) {
			setCaseDetail({ caseId, caseType: entityType });
			getTransactions(entityType, caseId);
		}
	}, [location, metaData]);

	useEffect(() => {
		const transGroup = transactions
			.map(
				(transaction: any) =>
					transaction.transactionGroupName
			)
			.filter(
				(
					value: any,
					index: any,
					self: string | any[]
				) => self.indexOf(value) === index
			)
			.map((item: any) => {
				return { label: item, value: item };
			});
		setFilterOption([
			{ label: t('US.COMMON:COMMON.ALL'), value: 'All' },
			...transGroup,
		]);
		filterOnTransactionGroup(transGroupByTransType(transType));
	}, [transactions]);

	/**
	 * onChange event of transaction select options
	 * @param filterValue tranaction type select option value
	 */
	const filterOnTransactionGroup = (filterValue: string) => {
		try {
			if (transactions.length > 0 && filterValue != 'All') {
				const filteredTransactions =
					transactions.filter(
						(transaction: any) =>
							transaction.transactionGroupName ===
							filterValue
					);
				filteredTransactions.length > 0
					? setFilterValue(filterValue)
					: setFilterValue('');
				setTransactionList(filteredTransactions);
			} else {
				setTransactionList(transactions);
				setFilterValue('All');
			}
		} catch (error) {
			appInsights.trackException(
				`Transaction Filtering Exception - ${error}`
			);
		}
	};

	const onClose = () => {
		manageTransactionDrawer({
			title: '',
			visible: false,
			isNewTransaction: false,
			isDeleteTransaction: false,
		});
		manageMappingTransactionDrawer({
			title: t(
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TRANSACTION_MAPPING'
			),
			visible: false,
		});
		manageEditInterestDrawer({
			title: t(
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.EDIT_TRANSACTION_INTEREST_BASIS'
			),
			visible: false,
		});
		resetSelectedTransaction({});
	};

	const addNewTransactionHandler = () => {
		resetSelectedTransaction({});
		resetInterestApplication && resetInterestApplication({});
		manageTransactionDrawer({
			title: t(
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REGISTERTRANSACTION'
			),
			visible: true,
			isNewTransaction: true,
			isDeleteTransaction: false,
			filters: caseDetail,
			width: 1000,
		});
	};

	const editTransactionHandler = (record: any) => {
		const { isCancelable, type } = record;
		selectTransaction(record);
		// after role based implemenatation done, need to grant delete permisson to admin user.
		manageTransactionDrawer({
			title: t(
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TRANSACTION_DETAILS'
			),
			visible: true,
			isNewTransaction: false,
			isDeleteTransaction: isCancelable || type == 'DB',
			filters: caseDetail,
			width: 700,
		});
	};

	const handleTransactionMapping = (record: any) => {
		selectTransaction(record);
		manageMappingTransactionDrawer({
			title: t(
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TRANSACTION_MAPPING'
			),
			visible: true,
		});
	};

	const editInterestHandler = (record: any) => {
		selectTransaction(record);
		manageEditInterestDrawer({
			title: t(
				'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.EDIT_TRANSACTION_INTEREST_BASIS'
			),
			visible: true,
		});
	};

	// Get transaction table columns.
	const tableColumns = TransactionColumns(
		transactionList,
		editTransactionHandler,
		handleTransactionMapping,
		editInterestHandler,
		managePopOver,
		visiblePopOver
	);

	const handleSort: IOnSort = (sortData, dataSource) => {
		return sortData(dataSource);
	};

	const handleFilter: IOnFilter = (searchData, dataSource) => {
		return searchData(dataSource);
	};

	const columns = filterColumnsByDataIndexAndCaseType(
		tableColumns,
		['orderDate'],
		caseDetail.caseType
	);

	const excelTableColumns = filterColumnsByDataIndexAndCaseType(
		excelColumns,
		['caseNo', 'orderDate'],
		caseDetail.caseType
	);
	const minimize = () => {
		history.push({ ...location, pathname: `/case/${caseNo}` });
	};

	const refreshButtonHandler = () => {
		if (caseId && entityType) {
			setCaseDetail({ caseId, caseType: entityType });
			getTransactions(entityType, caseId);
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{}}
			onSubmit={(values: any, actions: any) => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				...rest
			}: any) => (
				<div className='space-content'>
					<$Affix offsetTop={80}>
						<div className='page-header header-border mr-0'>
							<div className='d-flex flex-row align-items-center justify-content-between'>
								<div className='d-flex align-items-center'>
									<$PageHeader
										className='px-0'
										title={t(
											'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TRANSACTIONS'
										)}
										onBack={() => {
											getAccountSummeryWidgetData &&
												getAccountSummeryWidgetData(
													{
														EntityType:
															entityType ===
															CaseType.S
																? 'Subcase'
																: 'Case',
														EntityId: caseId,
													}
												);
											minimize();
										}}
									/>
									<$Divider
										className='bui-devider'
										type='vertical'
									/>
									<$Skeleton
										loading={
											isFetching
										}
										active
										paragraph={{
											rows: 0,
										}}>
										<$Button
											onClick={() =>
												addNewTransactionHandler()
											}
											type='dashed'
											size='small'
											icon={
												<PlusOutlined />
											}
											disabled={
												metaData.data &&
												isSubCaseTransferredToCase(
													metaData.data
												)
											}>
											{t(
												'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.NEWTRANSACTION'
											)}
										</$Button>
									</$Skeleton>
								</div>
								<div className='d-flex align-items-center'>
									<$Select
										name='label'
										size='small'
										allOption={
											false
										}
										value={
											filterValue
										}
										style={{
											width: 200,
										}}
										options={
											filterOption
										}
										onChange={
											filterOnTransactionGroup
										}
										optionValue='value'
										optionText='label'
									/>
									<$Divider
										className='bui-devider'
										type='vertical'
									/>
									<ExcelExporter
										dataSet={
											transactionList
										}
										columns={excelTableColumns.map(
											(
												col: IExcleColumn
											) => {
												return {
													...col,
													title: t(
														col.title
													),
												};
											}
										)}
										name={`Transactions_${caseNo}`}
										format={{
											currentDateFormat,
											currentCurrency,
											currentLanguage,
										}}
									/>

									<$Tooltip
										title={t(
											'US.COLLECTION.COMMON:COMMON.REFRESH'
										)}>
										<$Button
											type='dashed'
											size='small'
											onClick={() =>
												refreshButtonHandler()
											}
											icon={
												<ReloadOutlined />
											}
											className='ml-3'
										/>
									</$Tooltip>
								</div>
							</div>
						</div>
					</$Affix>

					<$Affix offsetTop={120}>
						<$Skeleton
							loading={isFetching}
							active
							paragraph={{ rows: 2 }}>
							<$TableTree
								rowKey='arItemNo'
								data={
									transactionList
								}
								size='small'
								className='mt-3 header-custom-tag'
								onSort={
									handleSort
								}
								onFilter={
									handleFilter
								}
								filterOnType={
									true
								}
								resetOnSourceChange={
									true
								}
								bordered
								pagination={{
									defaultPageSize: 100,
								}}
								scroll={{
									x: 1200,
									y: 'calc(100vh - 280px)',
								}}
								//scroll={{ x: 1200 }}
								columns={
									columns
								}
								onRow={(
									record: any,
									rowIndex: any
								) => {
									return {
										onDoubleClick:
											(
												event: any
											) => {
												if (
													metaData.data &&
													!isSubCaseTransferredToCase(
														metaData.data
													)
												) {
													visiblePopOver ==
														-1 &&
														editTransactionHandler(
															record
														);
												}
											},
									};
								}}
							/>
						</$Skeleton>
					</$Affix>
					<$Drawer
						data-testid='transaction-drawer'
						title={transactionDrawer.title}
						width={transactionDrawer.width}
						onClose={onClose}
						visible={
							transactionDrawer.visible
						}
						destroyOnClose>
						<TransactionDetails
							isDeleteTransaction={
								transactionDrawer.isDeleteTransaction
							}
							isNewTransaction={
								transactionDrawer.isNewTransaction
							}
							filters={
								transactionDrawer.filters
							}
							caseDetail={caseDetail}
							onClose={onClose}
						/>
					</$Drawer>

					<$Drawer
						title={
							mappingTransactionDrawer.title
						}
						width='90%'
						onClose={onClose}
						visible={
							mappingTransactionDrawer.visible
						}
						destroyOnClose>
						<Mapping
							onClose={onClose}
							caseType={caseType}
						/>
					</$Drawer>
					<$Drawer
						title={editInterestDrawer.title}
						width='720px'
						onClose={onClose}
						visible={
							editInterestDrawer.visible
						}
						destroyOnClose>
						<EditInterest
							onClose={onClose}
						/>
					</$Drawer>
				</div>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, domainView, transaction } = state;
	const { currentDateFormat, currentCurrency, currentLanguage } = common;
	const {
		isFetching,
		transactionDrawer,
		mappingTransactionDrawer,
		editInterestDrawer,
		transactions,
		popOverMenu,
	} = transaction;
	const { metaData } = domainView;
	const { visiblePopOver } = popOverMenu;

	return {
		currentCurrency,
		currentLanguage,
		isFetching,
		transactions,
		currentDateFormat,
		transType: state.case.transactionType,
		transactionDrawer,
		mappingTransactionDrawer,
		editInterestDrawer,
		visiblePopOver,
		metaData,
	};
};

const { transactions } = Actions;
const {
	getAll,
	getMapping,
	select,
	resetInfo,
	manageDrawer,
	managePopOver,
	manageMappingDrawer,
	manageInterestDrawer,
} = transactions;

const { AccountSummeryWidgetActions } = DashboardActions;
const { accountSummery } = AccountSummeryWidgetActions;
const { transactionInterest } = Actions.interestApplication;

const mapDispatchToProps = {
	managePopOver,
	getTransactions: getAll,
	getMappingTransactionsByArItemNo: getMapping,
	selectTransaction: select,
	resetSelectedTransaction: resetInfo,
	manageTransactionDrawer: manageDrawer,
	manageMappingTransactionDrawer: manageMappingDrawer,
	getAccountSummeryWidgetData: accountSummery.get,
	manageEditInterestDrawer: manageInterestDrawer,
	resetInterestApplication: transactionInterest.reset,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TransactionsHome);
