import React, { useState, useEffect,useRef } from 'react';
import { matchPath } from 'react-router-dom';
import './PartPaymentDetail.scss';
import { connect } from 'react-redux';
import { getCasePartPayments } from 'us.collection.case/actions';
import Common from 'us.common';
import { useTranslation } from 'react-i18next';
import { Formik,FormikProps  } from 'formik';
import ManageAgreements from 'us.collection/components/ManageAgreements/ManageAgreements';
import ViewPayments from './ViewPayments';
import {
	CheckSquareOutlined,
	CreditCardOutlined,
	ExclamationCircleFilled,
} from 'us.icons';
import { AddPartPaymentAction } from 'us.collection.partpayments/actions';
import { AddPartPaymentInitial, IPartPaymentDetail } from './IPartPaymentDetail';
import moment from 'moment';
import {
	$InputAmount,
	$MessageBox,
	$Popover,
	$Radio,
	$AmountLabel,
} from 'us.common/components';
import AddPartPaymentValidation from './Validation';
import { CaseType } from 'us.helper/types/enums';
import { AccountSummeryWidgetActions } from 'us.collection.case/actions';
import { IOnFilter, IOnSort } from 'us.common/components/antd/TableTree';
import { GetPartPaymentBMDs } from 'us.collection.partpayments/repository';
import { appInsights } from 'us.helper';
import { RootState } from 'us.helper/types';
import { PartPaymentcomunicationMethod, PartPaymentGenerateType } from 'us.collection.partpayments/constants';

const { accountSummery } = AccountSummeryWidgetActions;
const {
	$Input,
	$DatePicker,
	$Select,
	$Form,
	$Button,
	$Divider,
	$Col,
	$PageHeader,
	$Drawer,
	$Affix,
	$Row,
	$Skeleton,
	$Switch,
	$FormItem,
	$TextArea,
	$Tooltip,
	$Popconfirm,
	$TableTree,
} = Common.Components;

export interface ISortOrder {
	columnKey: string;
	order: any;
}

const { IntlDate, IntlCurrency } = Common.Functions;

const PartPaymentDetail: React.FC<IPartPaymentDetail> = (props) => {
	const { t } = useTranslation();

	const {
		currentCurrency,
		currentLanguage,
		currentDateFormat,
		AddPartPaymentInitial,
		debtorWidgetData,
		accountSummeryWidgetData,
		getExistingPartPayment,
		ExistingPartPaymentDetails,
		calculatePartPayment,
		getPartPaymentBMD,
		addPaymentAgreementDrawer,
		openDrawer,
		PaymentAgreementSave,
		deletePartPayment,
		resetPartPaymentForm,
		resetGeneratePartPaymentSchema,
		getPaymentsByInstalments,
		updatePartPayment,
		PartPaymentDetailsDuplicate,
		isFetching,
		metaData,
		history,
		location,
		getAccountSummeryWidgetData,
	} = props;

	const { creditorId, pid, caseId, caseNo, isObjected, entityType } =
		metaData.data ?? {};
	const { debtorDetails } = debtorWidgetData.data ?? {};
	const { phoneNumbers, email: debtorMail } = debtorDetails ?? {};

	const [emailAndSMS, setEmailAndSMS] = useState({
		SMS: '',
		Email: '',
	});
	const [isFinalSettlement, setIsFinalSettlement] = useState(false);
	const [remainingbalance, setRemainingbalance] = useState(0);
	const [firstInstalmentDate, setFirstInstalmentDate] = useState(
		moment()
	);
	const [genarateType, setgenarateType] = useState('');
	const [paymentAgreementDetails, setPaymentAgreementDetails] = useState(
		{}
	);
	const [editDueDate, setEditDueDate] = useState({
		isEdit: false,
		editDate: '',
		previousEditDate: '',
	});
	const [isPPGenarate, setIsPPGenarate] = useState(false);
	const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
		columnKey: '',
		order: '',
	});

	const formikRef = useRef<FormikProps<AddPartPaymentInitial>>(null);
	const { noOfInstallmentBMD, noOfInstallment , installmentAmountBMD , installmentAmount} = AddPartPaymentInitial;
	useEffect(() => {
		setEmailAndSMS({
			SMS:
				phoneNumbers?.find((x) => x.type === 'sms')
					?.number ?? '',
			Email: debtorMail ?? '',
		});
	}, [debtorWidgetData.data]);

	useEffect(() => {
		resetPartPaymentForm('');
		if (metaData?.data?.caseId && metaData?.data?.caseId > 0) {
			getExistingPartPayment({
				entityId: getEntityTypeAndCaseId().id,
				entityType: getEntityTypeAndCaseId().caseType,
			});
			getPartPaymentBMD(
				GetPartPaymentBMDs.call({ caseId, pid })
			);
		}
	}, [metaData.data]);

	useEffect(() => {
		if (noOfInstallment > 0 && installmentAmount > 0) {
			validatePartPaymentFactors();
		}
	}, [noOfInstallment, installmentAmount]);

	const onClose = () => {
		setIsFinalSettlement(false);
		openDrawer({
			title: '',
			visible: false,
		});
	};

	const updateAndValidateField = async (field: string, value: any) => {
		if (!formikRef.current) return;

		await formikRef.current.setFieldValue(field, value);
		formikRef.current.setTouched({ [field]: true });

		await formikRef.current.validateField(field);
	};

	/**
	 * @description - Validate the part payment generate factors , after plan is generating 
	 */

	const validatePartPaymentFactors = async () => {
		if (!formikRef.current) return;

		if (noOfInstallmentBMD < noOfInstallment) {
			await updateAndValidateField("noOfInstallment", noOfInstallment);
		}

		if (installmentAmount < installmentAmountBMD) {
			await updateAndValidateField("installmentAmount", installmentAmount);
		}
	};

	const getEntityTypeAndCaseId = () => {
		try {
			const { params }: any = matchPath(location.pathname, {
				path: '/:type/:Id?/:module?',
				exact: false,
				strict: false,
			});
			let id = params['Id'];
			let caseType = params['type'];
			switch (caseType) {
				case 'case':
					id = caseId;
					break;
				case 'creditor':
					id = creditorId;
					break;
			}
			return {
				caseType,
				id,
			};
		} catch (error) {
			appInsights.trackException(
				`Part-payment getEntityTypeAndCaseId Exception -${error}`
			);
			return { caseType: '', id: 0 };
		}
	};

	const navigate = (): void => {
		getAccountSummeryWidgetData &&
			getAccountSummeryWidgetData({
				EntityType:
					entityType === CaseType.S
						? 'Subcase'
						: 'Case',
				EntityId: caseId,
			});
		history.push({ ...location, pathname: `/case/${caseNo}` });
	};

	const handleSMSAndEmailchange = (comType: string, restProps: any) => {
		restProps.setFieldValue('comunicationMethod', comType);
		if (comType == 'SMS') {
			restProps.setFieldValue(
				'emailAddress',
				emailAndSMS.Email
			);
		}
		if (comType == 'Email') {
			restProps.setFieldValue('smsNumber', emailAndSMS.SMS);
		}
	};

	const handlePartPaymentDate = (
		date: any,
		values: any,
		restProps: any
	) => {
		if (date != null) {
			restProps.setFieldValue('partPaymentDate', date);
		} else {
			if (values.firstInstallmentDate != null) {
				restProps.setFieldValue(
					'partPaymentDate',
					moment(values.firstInstallmentDate)
				);
			} else {
				restProps.setFieldValue(
					'partPaymentDate',
					moment()
				);
			}
		}
	};

	const handleFirstInstallmentDate = (
		date: any,
		values: any,
		restProps: any
	) => {
		if (date != null) {
			restProps.setFieldValue('firstInstallmentDate', date);
		} else {
			if (values.partPaymentDate != null) {
				restProps.setFieldValue(
					'firstInstallmentDate',
					moment(values.partPaymentDate)
				);
			} else {
				restProps.setFieldValue(
					'firstInstallmentDate',
					moment()
				);
			}
		}
	};

	const handleSMSandEmailEdit = (
		smsOrEmail: any,
		values: any,
		restProps: any
	) => {
		switch (values.comunicationMethod) {
			case 'SMS':
				if (smsOrEmail?.length == 0) {
					restProps.setFieldValue(
						'smsNumber',
						emailAndSMS.SMS
					);
					restProps.setFieldValue(
						'emailAddress',
						emailAndSMS.Email
					);
				} else {
					restProps.setFieldValue(
						'smsNumber',
						smsOrEmail
					);
					restProps.setFieldValue(
						'emailAddress',
						emailAndSMS.Email
					);
				}
				break;
			case 'Email':
				if (smsOrEmail?.length == 0) {
					restProps.setFieldValue(
						'smsNumber',
						emailAndSMS.SMS
					);
					restProps.setFieldValue(
						'emailAddress',
						emailAndSMS.Email
					);
				} else {
					restProps.setFieldValue(
						'smsNumber',
						emailAndSMS.SMS
					);
					restProps.setFieldValue(
						'emailAddress',
						smsOrEmail
					);
				}
				break;
			default:
				break;
		}
	};


	const handleFinalSettlement = (value: any, restProps: any) => {
		setIsFinalSettlement(value);
		restProps.setFieldValue('finalSettlement', value);

		if (value) {
			openDrawer({
				title: t(
					'US.COLLECTION.PARTPAYMENTS:DETAIL.ADD_PAYMENT_AGREEMENT'
				),
				visible: true,
			});
		} else {
			setRemainingbalance(
				accountSummeryWidgetData.data?.case?.balance ??
					0
			);
			setFirstInstalmentDate(moment());
		}
	};

	const handlePaymentAgreement = (data: any, restProps: any) => {
		setPaymentAgreementDetails({ ...data, agreementType: 'P' });
		if (data != null) {
			setIsFinalSettlement(true);
			restProps.setFieldValue('finalSettlement', true);
			restProps.setFieldValue(
				'remainingBalance',
				IntlCurrency(
					data?.totalAmount,
					currentLanguage,
					currentCurrency
				)
			);
			setRemainingbalance(data?.totalAmount);
			setFirstInstalmentDate(moment(data?.payDate));
			openDrawer({
				title: '',
				visible: false,
			});
		} else {
			onClose();
			restProps.setFieldValue('finalSettlement', false);
		}
	};

	const caseNoteTranslation = (
		data: any,
		isSubmitting: boolean = false
	) => {
		return `${t(
			'US.COLLECTION.PARTPAYMENTS:DETAIL.CASENOTE_REMAININGBALANCE'
		)} ${data?.remainingBalance}, ${t(
			'US.COLLECTION.PARTPAYMENTS:DETAIL.CASENOTE_NOOFINSTALLMENT'
		)} ${
			isSubmitting
				? ExistingPartPaymentDetails?.schemaList?.length
				: data.noOfInstallment
		}, ${t(
			'US.COLLECTION.PARTPAYMENTS:DETAIL.CASENOTE_FIRSTDATE'
		)} ${moment(data.firstInstallmentDate).format(
			'YYYY-MM-DD'
		)}, ${t(
			'US.COLLECTION.PARTPAYMENTS:DETAIL.CASENOTE_FIRSTINSTALLMENT'
		)} ${
			isSubmitting
				? ExistingPartPaymentDetails?.schemaList[0]
						?.installmentAmount
				: data.installmentAmount
		}, ${t(
			'US.COLLECTION.PARTPAYMENTS:DETAIL.CASENOTE_COMMETHOD'
		)} ${data.comunicationMethod}, ${data.caseNote}`;
	};

	const caluculatePartPayment = (
		data: any,
		type: string,
		restProps: any
	) => {
		try {
			if (
				(data.installmentAmount <
					Number(data.installmentAmountBMD) ||
					data.installmentAmount >
						data.remainingBalance) &&
				type == PartPaymentGenerateType.AMOUNT
			) {
				restProps.setFieldTouched(
					'installmentAmount',
					true,
					true
				);
			} else if (
				Number(data.noOfInstallmentDuplicate) >
					Number(data.noOfInstallmentBMD) &&
				type == PartPaymentGenerateType.INSTALLMENT
			) {
				restProps.setFieldTouched(
					'noOfInstallment',
					true,
					true
				);
			} else if (
				Number(data.noOfInstallmentDuplicate) == 0 &&
				type == PartPaymentGenerateType.INSTALLMENT
			) {
				restProps.setFieldTouched(
					'noOfInstallment',
					true,
					true
				);
			} else if (
				data.comunicationMethod == 'SMS' &&
				(data.smsNumber == '' ||
					data.smsNumber == undefined)
			) {
				restProps.setFieldTouched(
					'smsNumber',
					true,
					true
				);
			} else if (
				data.comunicationMethod == 'Email' &&
				(data.emailAddress == '' ||
					data.emailAddress == undefined)
			) {
				restProps.setFieldTouched(
					'emailAddress',
					true,
					true
				);
			} else {
				setIsPPGenarate(true);
				const queryBody = {
					caseId,
					installmentAmount:
						data.installmentAmount,
					partPaymentDate: moment(
						data.partPaymentDate
					).format('YYYY-MM-DD'),
					partPaymentType:
						type == PartPaymentGenerateType.AMOUNT
							? 'InstallmentAmount'
							: 'NoOfInstallment',
					numberOfInstallment:
						type == PartPaymentGenerateType.AMOUNT
						? 0
						: data.noOfInstallment,
					email:
						data.comunicationMethod ==
						'Email'
							? data.emailAddress
							: '',
					smsNumber:
						data.comunicationMethod == 'SMS'
							? data.smsNumber
							: '',
					InstallmentInterval: data.selectPeriod,
					forwardCharge:
						data.forwardChargOrCosts ===
							'costs' ||
						data.minInstallmentsForCostBMD >
							Number(
								data.noOfInstallment
							)
							? 0
							: Number(data.forwardCharge),
					hasPaymentAgreement: isFinalSettlement,
					remainingBalance: data.remainingBalance,
					isCaseCostAdded:
						data.minInstallmentsForCostBMD <
							Number(
								data.noOfInstallment
							) &&
						data.forwardChargOrCosts ===
							'costs',
					communicationMethod:
						data.comunicationMethod,
					firstInstallmentDate: moment(
						data.firstInstallmentDate
					).format('YYYY-MM-DD'),
					installmentCount: 0,
					caseNote: caseNoteTranslation(data),
					minInstallmentsForCostBMD: data.minInstallmentsForCostBMD,
					forwardChargOrCosts: data.forwardChargOrCosts,
					installmentAmountBMD:data.installmentAmountBMD,
					noOfInstallmentBMD:data.noOfInstallmentBMD,
					writeToDB: 0,
				};
				calculatePartPayment({
					requestBody: queryBody,
					initialValues: data,
				});
				setgenarateType(type);
				setEmailAndSMS({
					SMS: data.smsNumber,
					Email: data.emailAddress,
				});
			}
		} catch (error) {
			appInsights.trackException(
				`Part-payment caluculatePartPayment Exception -${error}`
			);
		}
	};

	const caluculateNoOfInstalment = (
		data: any,
		type: string,
		restProps: any
	) => {
		try {
			if (
				(data.installmentAmount <
					Number(data.installmentAmountBMD) ||
					data.installmentAmount >
						data.remainingBalance) &&
				type == PartPaymentGenerateType.AMOUNT
			) {
				restProps.setFieldTouched(
					'installmentAmount',
					true,
					true
				);
			} else {
				setIsPPGenarate(false);
				const queryBody = {
					caseId,
					installmentAmount:
						data.installmentAmount,
					partPaymentDate: moment(
						data.partPaymentDate
					).format('YYYY-MM-DD'),
					partPaymentType: 'InstallmentAmount',
					numberOfInstallment: 0,
					email: emailAndSMS.Email,
					smsNumber: emailAndSMS.SMS,
					InstallmentInterval: data.selectPeriod,
					forwardCharge: 0,
					hasPaymentAgreement: isFinalSettlement,
					remainingBalance: data.remainingBalance,
					isCaseCostAdded: false,
					communicationMethod: 'Giro',
					firstInstallmentDate: moment(
						data.firstInstallmentDate
					).format('YYYY-MM-DD'),
					installmentCount: 0,
					caseNote: '',
					writeToDB: 0,
				};
				calculatePartPayment({
					requestBody: queryBody,
					initialValues: data,
					calculateType: 'calculateInstalment',
				});
				setgenarateType(type);
				setEmailAndSMS({
					SMS: data.smsNumber,
					Email: data.emailAddress,
				});
			}
		} catch (error) {
			appInsights.trackException(
				`Part-payment caluculateNoOfInstalment Exception -${error}`
			);
		}
	};

	const handleAddPartpaymentSubmit = (data: any) => {
		try {
			const queryBody = {
				caseId,
				installmentAmount:
					ExistingPartPaymentDetails
						?.schemaList[0]
						?.installmentAmount,
				partPaymentDate: moment(
					data.partPaymentDate
				).format('YYYY-MM-DD'),
				partPaymentType:
					genarateType == PartPaymentGenerateType.AMOUNT
						? 'InstallmentAmount'
						: 'NoOfInstallment',
				numberOfInstallment:
					ExistingPartPaymentDetails?.schemaList
						?.length,
				email:
					data.comunicationMethod == 'Email'
						? data.emailAddress
						: debtorMail ?? '',
				smsNumber:
					data.comunicationMethod == 'SMS'
						? data.smsNumber
						: phoneNumbers?.find(
								(x) =>
									x.type ===
									'sms'
						  )?.number ?? '',
				InstallmentInterval: data.selectPeriod,
				forwardCharge:
					data.forwardChargOrCosts === 'costs' ||
					data.minInstallmentsForCostBMD >
						Number(data.noOfInstallment)
						? 0
						: Number(data.forwardCharge),
				hasPaymentAgreement: isFinalSettlement,
				remainingBalance: data.remainingBalance,
				isCaseCostAdded:
					data.minInstallmentsForCostBMD <
						Number(data.noOfInstallment) &&
					data.forwardChargOrCosts === 'costs',
				communicationMethod: data.comunicationMethod,
				firstInstallmentDate: moment(
					data.firstInstallmentDate
				).format('YYYY-MM-DD'),
				installmentCount: 0,
				caseNote: caseNoteTranslation(data, true),
				writeToDB: 1,
			};
			if (isFinalSettlement) {
				PaymentAgreementSave(paymentAgreementDetails);
			}
			setIsPPGenarate(false);
			calculatePartPayment({
				requestBody: queryBody,
				initialValues: data,
			});
			setIsFinalSettlement(false);
			setgenarateType('');
			setEmailAndSMS({
				SMS:
					data.comunicationMethod == 'SMS'
						? data.smsNumber
						: phoneNumbers?.find(
								(x) =>
									x.type ===
									'sms'
						  )?.number ?? '',
				Email:
					data.comunicationMethod == 'Email'
						? data.emailAddress
						: debtorMail ?? '',
			});
		} catch (error) {
			appInsights.trackException(
				`Part-payment AddPartpaymentSubmit Exception -${error}`
			);
		}
	};

	const handlePartPaymentReset = (values: any, restProps: any) => {
		try {
			restProps.resetForm();
			setgenarateType('');
			resetPartPaymentForm('reset');
			setIsPPGenarate(false);
			if (isFinalSettlement) {
				setIsFinalSettlement(false);
			}
			$MessageBox(
				'success',
				'US.COLLECTION.PARTPAYMENTS:DETAIL.RESET_SUCCESSFULLY',
				'',
				''
			);
		} catch (error) {
			appInsights.trackException(
				`Part-payment reset Exception -${error}`
			);
		}
	};

	const handlePartPaymentRemove = () => {
		try {
			deletePartPayment({
				caseId,
				agreementId:
					ExistingPartPaymentDetails?.paymentAgreementId,
			});
		} catch (error) {
			appInsights.trackException(
				`Part-payment remove Exception -${error}`
			);
		}
	};

	const handleViewPayment = (rowData: any) => {
		try {
			getPaymentsByInstalments({
				caseid: caseId,
				installmentid: rowData.installmentId,
			});
		} catch (error) {
			appInsights.trackException(
				`Part-payment Get Payement By Instalment Exception -${error}`
			);
		}
	};

	const handleDueDateChange = (date: any) => {
		if (moment(date).isValid()) {
			setEditDueDate({
				isEdit: true,
				editDate: date,
				previousEditDate: editDueDate.editDate,
			});
		}
	};
	const dueDatePopOverConfirmYes = () => {
		try {
			setEditDueDate({
				...editDueDate,
				isEdit: false,
			});
			const requestBody = {
				caseId,
				installmentId:
					ExistingPartPaymentDetails
						?.schemaList[0]?.installmentId,
				dueDate: moment(editDueDate.editDate).format(
					'YYYY-MM-DD'
				),
			};
			updatePartPayment(requestBody);
		} catch (error) {
			appInsights.trackException(
				`Update part-payement Exception -${error}`
			);
		}
	};
	const dueDatePopOverConfirmNo = () => {
		setEditDueDate({
			isEdit: false,
			editDate: editDueDate.previousEditDate,
			previousEditDate: '',
		});
	};

	const handleCaseCost = (rest: any, values: any) => {
		if (
			values.remainingBalance >
			values.minRemainingBalanceForCost
		) {
			rest.setFieldValue('forwardChargOrCosts', 'costs');
		} else {
			rest.setFieldValue(
				'forwardChargOrCosts',
				'forwardCharge'
			);
		}
	};

	const resetExistingPlan = (values: any) => {
		if (ExistingPartPaymentDetails?.schemaList?.length > 0) {
			resetGeneratePartPaymentSchema(values);
		}
	};
	

	const handlePartPayementFactors = (
		ExistingPartPayments: any,
		inputValue: any,
		values: any,
		restProps: any,
		isNoOfInstallmentChange: Boolean 
	  ) => {
		const { firstInstallmentAmount, schemaList } = ExistingPartPayments ?? {};
		const schemaListLength = schemaList?.length;
	
		if (isNoOfInstallmentChange) {
		  restProps.setFieldValue("noOfInstallmentDuplicate", inputValue);
		  restProps.setFieldValue("noOfInstallment", inputValue);
	
		  if (
			schemaList &&
			schemaListLength > 0 &&
			parseInt(inputValue, 10) !== schemaListLength
		  ) {
			resetExistingPlan(values);
		  }
		} else {
		  restProps.setFieldValue("installmentAmount", inputValue);
		  if (
			schemaList &&
			schemaListLength > 0 &&
			firstInstallmentAmount.toString() !== inputValue.toString()
		  ) {
			resetExistingPlan(values);
		  }
		}
	  };

	  
	  const handlePartPaymentGeneratePlan = async (
		values: any,
		validateForm: any,
		restProps: any,
		isNoOfInstallmentChange: Boolean
	  ) => {
		restProps.setTouched({
		  emailAddress: true,
		  smsNumber: true,
		});
		const formErrors = await validateForm();
  
		const partPaymenetGenerateType = isNoOfInstallmentChange
		  ? PartPaymentGenerateType.INSTALLMENT
		  : PartPaymentGenerateType.AMOUNT;
		const isComunicationMethodValid =
		  values.comunicationMethod.toLowerCase() ===
		  PartPaymentcomunicationMethod.SMS
			? formErrors.smsNumber
			: formErrors.emailAddress;
  
		if (!isComunicationMethodValid) {
		  caluculatePartPayment(values, partPaymenetGenerateType, restProps);
		}
	  };


	const handleTableChange = (
		pagination: any,
		filters: any,
		sorter: any
	) => {
		setSortedInfo(sorter);
	};

	const columns: any = [
		{
			title: t(
				'US.COLLECTION.PARTPAYMENTS:DETAIL.PARTPAYMENTNO'
			),
			dataIndex: 'partPaymentNo',
			key: 'partPaymentNo',
			className: 'text-nowrap',
			width: 160,
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
		},
		{
			title: t('US.COLLECTION.PARTPAYMENTS:DETAIL.DUEDATE'),
			dataIndex: 'dueDate',
			key: 'dueDate',
			className: 'text-nowrap',
			width: 130,
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (
				text: any,
				record: any,
				index: any
			) => {
				return (
					<$Popover
						placement='bottomLeft'
						content={
							<div
								style={{
									maxWidth: '300px',
								}}>
								<div className='ant-popover-message'>
									<ExclamationCircleFilled />
									<div className='ant-popover-message-title'>
										<div
											style={{
												paddingBottom:
													'0.3rem',
											}}>
											{t(
												'US.COLLECTION.PARTPAYMENTS:DETAIL.DUEDATECONFORM'
											)}
										</div>
									</div>
								</div>
								<div className='ant-popover-buttons'>
									<$Button
										size='small'
										onClick={() =>
											dueDatePopOverConfirmNo()
										}>
										{t(
											'US.COLLECTION.COMMON:COMMON.NO'
										)}
									</$Button>
									<$Button
										size='small'
										type='primary'
										onClick={() =>
											dueDatePopOverConfirmYes()
										}>
										{t(
											'US.COLLECTION.COMMON:COMMON.YES'
										)}
									</$Button>
								</div>
							</div>
						}
						trigger='click'
						visible={
							editDueDate.isEdit &&
							index == 0
						}>
						<$DatePicker
							formitem
							allowClear
							name='text'
							size='small'
							style={{
								width: '100%',
							}}
							format={
								currentDateFormat
							}
							onChange={(e: any) =>
								handleDueDateChange(
									e
								)
							}
							defaultValue={moment(
								text
							)}
							value={
								index == 0 &&
								editDueDate.editDate !=
									''
									? editDueDate.editDate
									: moment(
											text
									  )
							}
							disabled={
								index > 0 ||
								moment(
									ExistingPartPaymentDetails
										?.schemaList[1]
										?.dueDate
								) < moment() ||
								(index === 0 &&
									ExistingPartPaymentDetails
										?.schemaList[0]
										?.isPaid)
							}
							disabledDate={(
								d: any
							) =>
								index == 0
									? d.isBefore(
											moment().startOf(
												'day'
											)
									  ) ||
									  d.isAfter(
											moment(
												ExistingPartPaymentDetails
													?.schemaList[1]
													?.dueDate
											)
												.subtract(
													1,
													'days'
												)
												.startOf(
													'day'
												)
									  )
									: null
							}
						/>
					</$Popover>
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.PARTPAYMENTS:DETAIL.CAPITAL_AMOUNT'
			),
			dataIndex: 'partPayment',
			key: 'partPayment',
			width: 160,
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return (
					<$AmountLabel
						value={record.partPayment}
					/>
				);
			},
		},
		{
			title: t('US.COLLECTION.PARTPAYMENTS:DETAIL.INTEREST'),
			dataIndex: 'interest',
			key: 'interest',
			width: 130,
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return <$AmountLabel value={record.interest} />;
			},
		},
		{
			title: t(
				'US.COLLECTION.PARTPAYMENTS:DETAIL.INSTALLMENT_FEE'
			),
			dataIndex: 'installmentFee',
			key: 'installmentFee',
			width: 180,
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return (
					<$AmountLabel
						value={record.installmentFee}
					/>
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.PARTPAYMENTS:DETAIL.INSTALLMENT_AMOUNT'
			),
			dataIndex: 'installmentAmount',
			key: 'installmentAmount',
			width: 200,
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return (
					<$AmountLabel
						value={record.installmentAmount}
					/>
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.PARTPAYMENTS:DETAIL.REMAINING_BALANCE'
			),
			dataIndex: 'remainingBalance',
			key: 'remainingBalance',
			width: 200,
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return (
					<$AmountLabel
						value={record.remainingBalance}
					/>
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.PARTPAYMENTS:DETAIL.INSTALLMENT_OUTSTANDING'
			),
			dataIndex: 'installmentOutstanding',
			key: 'installmentOutstanding',
			width: 220,
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return (
					<$AmountLabel
						value={
							record.installmentOutstanding
						}
					/>
				);
			},
		},
	];

	const handleSort: IOnSort = (sortData, dataSource) => {
		return sortData(dataSource);
	};

	const handleFilter: IOnFilter = (searchData, dataSource) => {
		return searchData(dataSource);
	};

	return (
    <Formik
      enableReinitialize
	  innerRef={formikRef}
      initialValues={{
        ...AddPartPaymentInitial,
        remainingBalance:
          remainingbalance == 0 || !isFinalSettlement
            ? AddPartPaymentInitial?.remainingBalance === 0
              ? accountSummeryWidgetData.data?.case?.balance ?? 0
              : AddPartPaymentInitial?.remainingBalance
            : remainingbalance,
        caseRemainingBalance: accountSummeryWidgetData.data?.case?.balance,
        partPaymentDate:
          AddPartPaymentInitial?.partPaymentDate == ""
            ? moment()
            : AddPartPaymentInitial?.partPaymentDate,
        firstInstallmentDate:
          firstInstalmentDate == moment() || !isFinalSettlement
            ? AddPartPaymentInitial?.firstInstallmentDate == ""
              ? moment()
              : AddPartPaymentInitial?.firstInstallmentDate
            : firstInstalmentDate,
        forwardChargOrCosts: isPPGenarate
          ? AddPartPaymentInitial.forwardChargOrCosts
          : isFinalSettlement || remainingbalance > 0
          ? remainingbalance > AddPartPaymentInitial?.minRemainingBalanceForCost
            ? "costs"
            : "forwardCharge"
          : (AddPartPaymentInitial?.remainingBalance === 0
              ? accountSummeryWidgetData.data?.case?.balance ?? 0
              : AddPartPaymentInitial?.remainingBalance) >
            AddPartPaymentInitial?.minRemainingBalanceForCost
          ? "costs"
          : "forwardCharge",
        smsNo: emailAndSMS.SMS,
        email: emailAndSMS.Email,
        smsNumber: emailAndSMS.SMS,
        emailAddress: emailAndSMS.Email,
      }}
      validationSchema={AddPartPaymentValidation}
      validateOnBlur
      validateOnMount
      validateOnChange
      onSubmit={(values, action) => handleAddPartpaymentSubmit(values)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        validateForm,
        ...restProps
      }: any) => (
        <$Form>
          <div className="space-content pp-detail">
            <$Affix offsetTop={80}>
              <div className="page-header header-border">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <$PageHeader
                      className="px-0"
                      title={t("US.COLLECTION.PARTPAYMENTS:DETAIL.PARTPAYMENT")}
                      onBack={navigate}
                    />
                    <$Divider className="bui-devider" type="vertical" />
                    <$Skeleton
                      loading={false}
                      active
                      paragraph={{
                        rows: 0,
                      }}
                    >
                      <$Popconfirm
                        title={t(
                          "US.COLLECTION.PARTPAYMENTS:DETAIL.RESET_CONFIRM"
                        )}
                        placement="topLeft"
                        onConfirm={() =>
                          handlePartPaymentReset(values, restProps)
                        }
                        okText={t("US.COLLECTION.COMMON:COMMON.YES")}
                        cancelText={t("US.COLLECTION.COMMON:COMMON.NO")}
                        disabled={!restProps.dirty && genarateType == ""}
                      >
                        <$Button
                          type="dashed"
                          size="small"
                          className="mr-2"
                          disabled={!restProps.dirty && genarateType == ""}
                          style={{
                            width: "100px",
                          }}
                        >
                          {t("US.COLLECTION.COMMON:COMMON.RESET")}
                        </$Button>
                      </$Popconfirm>
                    </$Skeleton>
                    <$Skeleton
                      loading={false}
                      active
                      paragraph={{
                        rows: 0,
                      }}
                    >
                      <$Popconfirm
                        title={t(
                          "US.COLLECTION.PARTPAYMENTS:DETAIL.REMOVE_CONFIRM"
                        )}
                        placement="topLeft"
                        onConfirm={() => handlePartPaymentRemove()}
                        okText={t("US.COLLECTION.COMMON:COMMON.YES")}
                        cancelText={t("US.COLLECTION.COMMON:COMMON.NO")}
                        disabled={
                          !(PartPaymentDetailsDuplicate?.schemaList?.length > 0)
                        }
                      >
                        <$Button
                          type="primary"
                          danger
                          size="small"
                          className="mr-2"
                          disabled={
                            !(
                              PartPaymentDetailsDuplicate?.schemaList?.length >
                              0
                            )
                          }
                          style={{
                            width: "100px",
                          }}
                        >
                          {t("US.COLLECTION.COMMON:COMMON.REMOVE")}
                        </$Button>
                      </$Popconfirm>
                    </$Skeleton>
                    <$Skeleton
                      loading={false}
                      active
                      paragraph={{
                        rows: 0,
                      }}
                    >
                      <$Button
                        type="primary"
                        size="small"
                        className=""
                        htmlType="submit"
                        onClick={handleSubmit}
                        disabled={
                          !(
                            (genarateType == "amount" ||
                              genarateType == "installment") &&
                            Number(values.installmentAmountBMD) <=
                              values.installmentAmount &&
                            Number(values.noOfInstallmentBMD) >=
                              Number(values.noOfInstallmentDuplicate)
                          ) ||
                          PartPaymentDetailsDuplicate?.schemaList?.length > 0 ||
                          !ExistingPartPaymentDetails?.schemaList?.length ||
                          isObjected
                        }
                        style={{
                          width: "100px",
                        }}
                      >
                        {t("US.COLLECTION.COMMON:COMMON.SAVE")}
                      </$Button>
                    </$Skeleton>
                  </div>
                </div>
              </div>
            </$Affix>

            <div className="mt-3">
              <$Row gutter={16}>
                <$Col
                  span={16}
                  xl={{
                    span: 16,
                    offset: 0,
                  }}
                  xxl={{
                    span: 16,
                    offset: 0,
                  }}
                >
                  <div className="">
                    <div className="mb-3 d-flex flex-1 align-items-top">
                      <div className="mr-2">
                        <$Switch
                          {...restProps}
                          name="finalSettlement"
                          onChange={(e: any) =>
                            handleFinalSettlement(e, restProps)
                          }
                          checked={isFinalSettlement}
                          defaultChecked={values.finalSettlement}
                        />
                      </div>
                      <div className="d-flex flex-column">
                        <span>
                          {isFinalSettlement ? (
                            <a
                              onClick={(e: any) =>
                                handleFinalSettlement(e, restProps)
                              }
                            >
                              {t(
                                "US.COLLECTION.PARTPAYMENTS:DETAIL.APPLY_PAYMENT_AGREEMENT"
                              )}
                            </a>
                          ) : (
                            t(
                              "US.COLLECTION.PARTPAYMENTS:DETAIL.APPLY_PAYMENT_AGREEMENT"
                            )
                          )}
                        </span>
                      </div>
                    </div>
                    <$Row gutter={16} className="mb-1">
                      <$Col span={8}>
                        <div>
                          <$InputAmount
                            size="small"
                            className="w-100"
                            label={t(
                              "US.COLLECTION.PARTPAYMENTS:DETAIL.REMAINING_BALANCE"
                            )}
                            name="remainingBalance"
                            onBlur={() => handleCaseCost(restProps, values)}
                            disabled={isFinalSettlement}
                            value={values?.remainingBalance}
                            currentLanguage={currentLanguage}
                            currentCurrency={currentCurrency}
                          />
                        </div>
                        <div>
                          <$DatePicker
                            formitem
                            allowClear
                            label={t(
                              "US.COLLECTION.PARTPAYMENTS:DETAIL.PART_PAYMENT_DATE"
                            )}
                            name="partPaymentDate"
                            size="small"
                            style={{
                              width: "100%",
                            }}
                            defaultValue={values.partPaymentDate}
                            value={values.partPaymentDate}
                            format={currentDateFormat}
                            disabledDate={(d: any) =>
                              d.isBefore(moment().startOf("day"))
                            }
                            onChange={(e: any) =>
                              handlePartPaymentDate(e, values, restProps)
                            }
                          />
                        </div>
                        <div>
                          <$DatePicker
                            formitem
                            allowClear
                            label={t(
                              "US.COLLECTION.PARTPAYMENTS:DETAIL.FIRST_INSTALLMENT_DATE"
                            )}
                            name="firstInstallmentDate"
                            size="small"
                            style={{
                              width: "100%",
                            }}
                            format={currentDateFormat}
                            defaultValue={values.firstInstallmentDate}
                            value={values.firstInstallmentDate}
                            disabledDate={(d: any) =>
                              d.isBefore(moment().startOf("day"))
                            }
                            onChange={(e: any) =>
                              handleFirstInstallmentDate(e, values, restProps)
                            }
                          />
                        </div>
                        <div>
                          <$Select
                            name="selectPeriod"
                            formitem={{
                              label: t(
                                "US.COLLECTION.PARTPAYMENTS:DETAIL.SELECT_PERIOD"
                              ),
                            }}
                            size="small"
                            allOption={false}
                            options={[
                              {
                                label: t(
                                  "US.COLLECTION.PARTPAYMENTS:DETAIL.WEEK"
                                ),
                                value: "Week",
                              },
                              {
                                label: t(
                                  "US.COLLECTION.PARTPAYMENTS:DETAIL.BIWEEKLY"
                                ),
                                value: "14Days",
                              },
                              {
                                label: t(
                                  "US.COLLECTION.PARTPAYMENTS:DETAIL.MONTH"
                                ),
                                value: "Month",
                              },
                              {
                                label: t(
                                  "US.COLLECTION.PARTPAYMENTS:DETAIL.QUARTER"
                                ),
                                value: "Quarter",
                              },
                              {
                                label: t(
                                  "US.COLLECTION.PARTPAYMENTS:DETAIL.YEAR"
                                ),
                                value: "Year",
                              },
                            ]}
                            defaultValue={""}
                          />
                        </div>
                      </$Col>
                      <$Col span={16}>
                        <div className="pp-generate p-3 mt-n3">
                          <$Row gutter={16}>
                            <$Col span={12}>
                              <$FormItem
                                label={t(
                                  "US.COLLECTION.PARTPAYMENTS:DETAIL.INSTALLMENT_AMOUNT"
                                )}
                                extra={`${t(
                                  "US.COLLECTION.PARTPAYMENTS:DETAIL.MINIMUM _TERM"
                                )} ${values.installmentAmountBMD}`}
                              >
                                <div className="pp-generate-input">
                                  <$InputAmount
                                    size="small"
                                    name="installmentAmount"
                                    value={values?.installmentAmount}
                                    currentLanguage={currentLanguage}
                                    currentCurrency={currentCurrency}
                                    onChange={(amount: any) => {
                                      handlePartPayementFactors(
                                        ExistingPartPaymentDetails,
                                        amount,
                                        values,
                                        restProps,
                                        false
                                      );
                                    }}
                                  />
                                  <div className="pp-generate-input-addon">
                                    <$Tooltip
                                      placement="top"
                                      title={t(
                                        "US.COLLECTION.COMMON:COMMON.GENERATE"
                                      )}
                                    >
                                      <$Button
                                        type="primary"
                                        size="small"
                                        onClick={() => {
                                          handlePartPaymentGeneratePlan(
                                            values,
                                            validateForm,
                                            restProps,
                                            false
                                          );
                                        }}
                                        icon={<CheckSquareOutlined />}
                                      />
                                    </$Tooltip>
                                  </div>
                                </div>
                              </$FormItem>
                            </$Col>
                            <$Col span={12}>
                              <$FormItem
                                label={t(
                                  "US.COLLECTION.PARTPAYMENTS:DETAIL.NUMBER_OF_INSTALLMENTS"
                                )}
                                extra={`${t(
                                  "US.COLLECTION.PARTPAYMENTS:DETAIL.MAXIMUM"
                                )} ${values.noOfInstallmentBMD}`}
                              >
                                <$Input
                                  size="small"
                                  name="noOfInstallment"					
                                  onBlur={(e: any) => {
                                    handlePartPayementFactors(
                                      ExistingPartPaymentDetails,
                                      e.target.value,
                                      values,
                                      restProps,
                                      true
                                    );
                                  }}
                                  onChange={(e: any) => {
                                    handlePartPayementFactors(
                                      ExistingPartPaymentDetails,
                                      e.target.value,
                                      values,
                                      restProps,
                                      true
                                    );
                                  }}
                                  addonAfter={
                                    <>
                                      <$Tooltip
                                        placement="top"
                                        title={t(
                                          "US.COLLECTION.COMMON:COMMON.GENERATE"
                                        )}
                                      >
                                        <$Button
                                          type="primary"
                                          size="small"
                                          className="mx-n3"
                                          name="installmentGenarate"
                                          onClick={() => {
                                            handlePartPaymentGeneratePlan(
                                              values,
                                              validateForm,
                                              restProps,
                                              true
                                            );
                                          }}
                                          icon={<CheckSquareOutlined />}
                                        />
                                      </$Tooltip>
                                    </>
                                  }
                                />
                              </$FormItem>
                            </$Col>
                          </$Row>
                          <$Row gutter={16} className="mt-2">
                            {values.noOfInstallment >=
                            values.minInstallmentsForCostBMD ? (
                              <$Col span={12}>
                                <div className="pp-radio-group">
                                  <$Radio
                                    name="forwardChargOrCosts"
                                    onChange={() => resetExistingPlan(values)}
                                    options={[
                                      {
                                        value: "forwardCharge",
                                        label: t(
                                          "US.COLLECTION.PARTPAYMENTS:DETAIL.FORWARD_CHARGE"
                                        ),
                                      },
                                      {
                                        value: "costs",
                                        label: t(
                                          "US.COLLECTION.PARTPAYMENTS:DETAIL.COSTS"
                                        ),
                                      },
                                    ]}
                                    optionValue="value"
                                    optionText="label"
                                  />
                                </div>
                                {values.forwardChargOrCosts ===
                                  "forwardCharge" && (
                                  <div>
                                    <$InputAmount
                                      name="forwardCharge"
                                      size="small"
                                      value={values?.forwardCharge}
                                      currentLanguage={currentLanguage}
                                      currentCurrency={currentCurrency}
                                    />
                                  </div>
                                )}
                              </$Col>
                            ) : null}
                          </$Row>
                        </div>

                        <div
                          className="pp-radio-group px-3"
                          style={{
                            marginTop: "1.4rem",
                          }}
                        >
                          <$Radio
                            onChange={(e: any) => {
                              handleSMSAndEmailchange(
                                e.target.value,
                                restProps
                              );
                            }}
                            name="comunicationMethod"
                            options={[
                              {
                                value: "SMS",
                                label: t(
                                  "US.COLLECTION.PARTPAYMENTS:DETAIL.SMSNO"
                                ),
                              },
                              {
                                value: "Email",
                                label: t(
                                  "US.COLLECTION.PARTPAYMENTS:DETAIL.EMAIL"
                                ),
                              },
                              {
                                value: "Giro",
                                label: t(
                                  "US.COLLECTION.PARTPAYMENTS:DETAIL.GIRO"
                                ),
                              },
                              {
                                value: "NonGiro",
                                label: t(
                                  "US.COLLECTION.PARTPAYMENTS:DETAIL.NONGIRO"
                                ),
                              },
                            ]}
                            optionValue="value"
                            optionText="label"
                          />
                        </div>
                        {values.comunicationMethod == "SMS" && (
                          <div className="px-3">
                            <$Input
                              formitem
                              size="small"
                              name="smsNumber"
                              style={{
                                marginTop: "4px",
                              }}
                              onBlur={(e: any) =>
                                handleSMSandEmailEdit(
                                  e.target.value,
                                  values,
                                  restProps
                                )
                              }
                            />
                          </div>
                        )}
                        {values.comunicationMethod == "Email" && (
                          <div className="px-3">
                            <$Input
                              formitem
                              size="small"
                              name="emailAddress"
                              style={{
                                marginTop: "4px",
                              }}
                              onBlur={(e: any) =>
                                handleSMSandEmailEdit(
                                  e.target.value,
                                  values,
                                  restProps
                                )
                              }
                            />
                          </div>
                        )}
                      </$Col>
                    </$Row>
                    <$Row gutter={16}>
                      <$Col span={24}>
                        <div className="pr-3">
                          <$TextArea
                            label={t(
                              "US.COLLECTION.PARTPAYMENTS:DETAIL.CASE_NOTE"
                            )}
                            name="caseNote"
                            autoSize={{
                              minRows: 4,
                            }}
                          />
                        </div>
                      </$Col>
                    </$Row>
                  </div>
                </$Col>
                <$Col
                  span={4}
                  xl={{
                    span: 6,
                    offset: 2,
                  }}
                  xxl={{
                    span: 4,
                    offset: 4,
                  }}
                >
                  <div>
                    <div className="d-flex pp-detail-row-border pb-2 mb-2">
                      <div className="flex-grow-1">
                        {t("US.COLLECTION.PARTPAYMENTS:DETAIL.TOTAL_AMOUNT")}
                      </div>
                      <div className="text-right">
                        <strong>
                          {IntlCurrency(
                            ExistingPartPaymentDetails?.totalAmount,
                            currentLanguage,
                            currentCurrency
                          )}
                        </strong>
                      </div>
                    </div>
                    <div className="d-flex pp-detail-row-border pb-2 mb-2">
                      <div className="flex-grow-1">
                        {t("US.COLLECTION.PARTPAYMENTS:DETAIL.CASE_COST")}
                      </div>
                      <div className="text-right">
                        <strong>
                          {IntlCurrency(
                            ExistingPartPaymentDetails?.caseCost,
                            currentLanguage,
                            currentCurrency
                          )}
                        </strong>
                      </div>
                    </div>
                    <div className="d-flex pp-detail-row-border pb-2 mb-2">
                      <div className="flex-grow-1">
                        {t(
                          "US.COLLECTION.PARTPAYMENTS:DETAIL.FIRST_INSTALLMENT_DATE"
                        )}
                      </div>
                      <div className="text-right">
                        <strong>
                          {IntlDate(
                            ExistingPartPaymentDetails?.firstInstallmentDate,
                            currentLanguage,
                            currentDateFormat
                          )}
                        </strong>
                      </div>
                    </div>
                    <div className="d-flex pp-detail-row-border pb-2 mb-2">
                      <div className="flex-grow-1">
                        {t(
                          "US.COLLECTION.PARTPAYMENTS:DETAIL.LAST_INSTALLMENT_DATE"
                        )}
                      </div>
                      <div className="text-right">
                        <strong>
                          {IntlDate(
                            ExistingPartPaymentDetails?.lastInstallmentDate,
                            currentLanguage,
                            currentDateFormat
                          )}
                        </strong>
                      </div>
                    </div>
                    <div className="d-flex pp-detail-row-border pb-2 mb-2">
                      <div className="flex-grow-1">
                        {t(
                          "US.COLLECTION.PARTPAYMENTS:DETAIL.FIRST_INSTALLMENT"
                        )}
                      </div>
                      <div className="text-right">
                        <strong>
                          {IntlCurrency(
                            ExistingPartPaymentDetails?.firstInstallmentAmount,
                            currentLanguage,
                            currentCurrency
                          )}
                        </strong>
                      </div>
                    </div>
                    <div className="d-flex pp-detail-row-border pb-2 mb-2">
                      <div className="flex-grow-1">
                        {t(
                          "US.COLLECTION.PARTPAYMENTS:DETAIL.LAST_INSTALLMENT"
                        )}
                      </div>
                      <div className="text-right">
                        <strong>
                          {IntlCurrency(
                            ExistingPartPaymentDetails?.lastInstallmentAmount,
                            currentLanguage,
                            currentCurrency
                          )}
                        </strong>
                      </div>
                    </div>
                    {ExistingPartPaymentDetails?.createdUser ? (
                      <div className="d-flex pp-detail-row-border pb-2 mb-2">
                        <div className="flex-grow-1">
                          {t("US.COLLECTION.PARTPAYMENTS:DETAIL.CREATED_USER")}
                        </div>
                        <div className="text-right">
                          <strong>
                            {ExistingPartPaymentDetails?.createdUser}
                          </strong>
                        </div>
                      </div>
                    ) : null}
                    {ExistingPartPaymentDetails?.createdDate ? (
                      <div className="d-flex pp-detail-row-border pb-2 mb-2">
                        <div className="flex-grow-1">
                          {t("US.COLLECTION.PARTPAYMENTS:DETAIL.CREATED_DATE")}
                        </div>
                        <div className="text-right">
                          <strong>
                            {IntlDate(
                              ExistingPartPaymentDetails?.createdDate,
                              currentLanguage,
                              currentDateFormat
                            )}
                          </strong>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </$Col>
              </$Row>
            </div>

            <div className="mt-3">
              <$Skeleton
                loading={isFetching}
                active
                paragraph={{
                  rows: 2,
                }}
              >
                <$TableTree
                  onChange={handleTableChange}
                  rowKey="partPaymentNo"
                  data={ExistingPartPaymentDetails?.schemaList}
                  size="small"
                  className="mt-3 header-custom-tag"
                  onSort={handleSort}
                  onFilter={handleFilter}
                  filterOnType={true}
                  resetOnSourceChange={true}
                  bordered
                  pagination={{
                    defaultPageSize: 15,
                  }}
                  scroll={{
                    x: 1200,
                    y: "calc(100vh - 270px)",
                  }}
                  columns={columns}
                  expandable={{
                    expandedRowRender: (record) => (
                      <div
                        style={{
                          paddingLeft: "2rem",
                        }}
                      >
                        <$Row gutter={16}>
                          <$Col
                            span={12}
                            xl={{
                              span: 10,
                              offset: 0,
                            }}
                            xxl={{
                              span: 8,
                              offset: 0,
                            }}
                          >
                            <ViewPayments
                              paymentDetails={record.paymentsDetails}
                            />
                          </$Col>
                        </$Row>
                      </div>
                    ),
                    expandIcon: ({ expanded, onExpand, record }) =>
                      expanded ? (
                        <$Tooltip
                          placement="topLeft"
                          title={t(
                            "US.COLLECTION.PARTPAYMENTS:DETAIL.VIEW_PAYMENTS"
                          )}
                        >
                          <$Button
                            type="primary"
                            icon={<CreditCardOutlined />}
                            size="small"
                            onClick={(e: any) => onExpand(record, e)}
                          />
                        </$Tooltip>
                      ) : (
                        <$Tooltip
                          placement="topLeft"
                          title={t(
                            "US.COLLECTION.PARTPAYMENTS:DETAIL.VIEW_PAYMENTS"
                          )}
                        >
                          <$Button
                            icon={<CreditCardOutlined />}
                            size="small"
                            onClick={(e: any) => {
                              onExpand(record, e);
                              handleViewPayment(record);
                            }}
                            disabled={!record.isPaid}
                          />
                        </$Tooltip>
                      ),
                    rowExpandable: (record) => record.name !== "Not Expandable",
                  }}
                  firstColSkipFilterProps={-1}
                />
              </$Skeleton>
            </div>

            <$Drawer
              title={addPaymentAgreementDrawer.title}
              width={800}
              visible={addPaymentAgreementDrawer.visible}
              onClose={onClose}
              destroyOnClose={!isFinalSettlement}
            >
              <ManageAgreements
                addWithPartPayment={(e: any) =>
                  handlePaymentAgreement(e, {
                    ...restProps,
                  })
                }
                onMinimize={onClose}
                {...props}
              />
            </$Drawer>
          </div>
        </$Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
	const { dashboard, common, partPayment, domainView } = state;
	const { currentCurrency, currentLanguage, currentDateFormat } = common;
	const { metaData } = domainView;
	const {
		accountSummeryWidgetData,
		caseInfoWidgetData,
		debtorWidgetData,
	} = dashboard;
	const {
		AddPartPaymentInitial,
		ExistingPartPaymentDetails,
		addPaymentAgreementDrawer,
		PartPaymentDetailsDuplicate,
		isFetching,
	} = partPayment;
	return {
		currentCurrency,
		currentLanguage,
		currentDateFormat,
		metaData,
		accountSummeryWidgetData,
		caseInfoWidgetData,
		debtorWidgetData,
		AddPartPaymentInitial,
		ExistingPartPaymentDetails,
		addPaymentAgreementDrawer,
		PartPaymentDetailsDuplicate,
		isFetching,
	};
};

const mapDispatchToProps = {
	getCasePartPayments,
	getExistingPartPayment: AddPartPaymentAction.GetExistingPartPayment.get,
	calculatePartPayment: AddPartPaymentAction.CalculatePartPayment.save,
	getPartPaymentBMD: AddPartPaymentAction.PartPaymentBMD.get,
	openDrawer: AddPartPaymentAction.CalculatePartPayment.openDrawer,
	PaymentAgreementSave: AddPartPaymentAction.PaymentAgreement.save,
	deletePartPayment: AddPartPaymentAction.DeletePartPayment.delete,
	resetPartPaymentForm: AddPartPaymentAction.CalculatePartPayment.reset,
	resetGeneratePartPaymentSchema:
		AddPartPaymentAction.GetExistingPartPayment.reset,
	getPaymentsByInstalments:
		AddPartPaymentAction.PaymentsByInstallment.get,
	updatePartPayment: AddPartPaymentAction.EditPartPayment.update,
	getAccountSummeryWidgetData: accountSummery.get,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartPaymentDetail);
