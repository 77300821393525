import { MessageAndNotes } from 'us.collection.case/constants/Actions';
import { IAPIAction } from 'us.collection/interfaces';

export const MessageAndNoteAction: Readonly<IAPIAction> = {
	/**
	 * @namespace note
	 */
	note: {
		save: (data) => ({
			type: MessageAndNotes.SAVE_NOTE_DETAIL_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MessageAndNotes.SAVE_NOTE_DETAIL_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MessageAndNotes.SAVE_NOTE_DETAIL_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},

	/**
	 * @namespace template
	 */
	template: {
		get: (data) => ({
			type: MessageAndNotes.GET_TEMPLATE_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MessageAndNotes.GET_TEMPLATE_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MessageAndNotes.GET_TEMPLATE_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},

	/**
	 * @namespace formattedTemplate
	 */
	formattedTemplate: {
		get: (data) => ({
			type: MessageAndNotes.GET_FORMATTED_TEMPLATE_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MessageAndNotes.GET_FORMATTED_TEMPLATE_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MessageAndNotes.GET_FORMATTED_TEMPLATE_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		openDrawer: (data) => ({
			type: MessageAndNotes.FORMATTED_TEMPLATE_DRAWER,
			payload: {
				data,
				isLoading: true,
			},
		}),
		reset: (data) => ({
			type: MessageAndNotes.DOMAIN_MESSAGE_INITIAL_VALUE_RESET,
			payload: {
				data,
				isLoading: true,
			},
		}),
	},

	/**
	 * @namespace basicDetail
	 */
	basicDetail: {
		get: (data) => ({
			type: MessageAndNotes.GET_DOMAIN_MESSAGE_BASIC_INFO_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MessageAndNotes.GET_DOMAIN_MESSAGE_BASIC_INFO_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MessageAndNotes.GET_DOMAIN_MESSAGE_BASIC_INFO_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},

	/**
	 * @namespace domainMessage
	 */
	domainMessage: {
		save: (data) => ({
			type: MessageAndNotes.SEND_DOMAIN_MESSAGE_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MessageAndNotes.SEND_DOMAIN_MESSAGE_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MessageAndNotes.SEND_DOMAIN_MESSAGE_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},

	/**
	 * @namespace emailAttachment
	 */
	emailAttachment: {
		get: (data) => ({
			type: MessageAndNotes.ADD_ATTACHMENT_EMAIL_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MessageAndNotes.ADD_ATTACHMENT_EMAIL_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MessageAndNotes.ADD_ATTACHMENT_EMAIL_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		openDrawer: (data) => ({
			type: MessageAndNotes.SHOW_EMAIL_ATTACHMENT,
			payload: {
				data,
				isLoading: true,
			},
		}),
	},

	/**
	 * @namespace printedDocument
	 */
	printedDocument: {
		get: (data) => ({
			type: MessageAndNotes.GET_PRINTED_DOCUMENT_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MessageAndNotes.GET_PRINTED_DOCUMENT_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MessageAndNotes.GET_PRINTED_DOCUMENT_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},

	/**
	 * @namespace uploadDocument
	 */
	uploadDocument: {
		get: (data) => ({
			type: MessageAndNotes.GET_UPLOAD_DOCUMENT_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MessageAndNotes.GET_UPLOAD_DOCUMENT_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MessageAndNotes.GET_UPLOAD_DOCUMENT_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},

	/**
	 * @namespace emailAttachmentContent
	 */
	emailAttachmentContent: {
		get: (data) => ({
			type: MessageAndNotes.GET_EMAIL_ATTACHMENT_CONTENT_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MessageAndNotes.GET_EMAIL_ATTACHMENT_CONTENT_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MessageAndNotes.GET_EMAIL_ATTACHMENT_CONTENT_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	messageText: {
		get: (data) => ({
			type: MessageAndNotes.GET_MESSAGE_TEXT,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MessageAndNotes.GET_MESSAGE_TEXT_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MessageAndNotes.GET_MESSAGE_TEXT_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	messageContent: {
		get: (data) => ({
			type: MessageAndNotes.GET_MESSAGE_CONTENT,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MessageAndNotes.GET_MESSAGE_CONTENT_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MessageAndNotes.GET_MESSAGE_CONTENT_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		update: (data) => ({
			type: MessageAndNotes.GET_EDITABLE_IFRAME,
			payload: {
				data,
				isLoading: true,
			},
		}),
	},
	/**
	 * @namespace dashboardMessage
	 */
	 dashboardMessage: {
		save: (data) => ({
			type: MessageAndNotes.SEND_DASHBOARD_MESSAGE_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MessageAndNotes.SEND_DASHBOARD_MESSAGE_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MessageAndNotes.SEND_DASHBOARD_MESSAGE_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	/**
	 * @namespace creditorPortalNote
	 */
	creditorPortalNote: {
		save: (data) => ({
			type: MessageAndNotes.SEND_CREDITOR_PORTAL_MESSAGE_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MessageAndNotes.SEND_CREDITOR_PORTAL_MESSAGE_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MessageAndNotes.SEND_CREDITOR_PORTAL_MESSAGE_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
};
