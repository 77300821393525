import React from 'react';
import './PropertiesInfo.scss';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Common from 'us.common';
import { IRootState } from 'us.collection/interfaces';
import { $DateLabel } from 'us.common/components';
import { DataTypes } from './Constants';
import { IPropertiesInfo } from './interfaces';
import { appInsights } from 'us.helper';
import { sortWidgetDataByDate } from './Functions';
import { RootState } from 'us.helper/types';

const { $Divider, $Skeleton, $Typography, $Tooltip } = Common.Components;
const { Paragraph } = $Typography;

const PropertiesInfo: React.FC<IPropertiesInfo> = (props) => {
	const { t } = useTranslation();

	const { onMaximize, caseInfoWidgetData, metaData } = props;
	const { data: widgetData, isLoading } = caseInfoWidgetData;
	const { entityType } = metaData.data ?? {};

	const handleDateType = (
		dateType: string,
		value: string,
		iconType: string
	): JSX.Element => {
		try {
			if (!value) {
				return <></>;
			}
			switch (dateType) {
				case DataTypes.LINK:
					return (
						<$Tooltip
							placement='top'
							title={value}>
							<a
								onClick={() =>
									window.open(
										value
									)
								}>
								{value}
							</a>
						</$Tooltip>
					);
				case DataTypes.DATE:
					return (
						<span
							className={`cas-prp-date status-${iconType}`}>
							<$DateLabel
								value={value}
							/>
						</span>
					);
				default:
					return (
						<$Tooltip
							placement='top'
							title={value}>
							{value}
						</$Tooltip>
					);
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard Case Information Exeception- ${error}`
			);
			return <></>;
		}
	};

	const handleTranslation = (
		doTranslate: boolean,
		displayName: string
	): string => {
		return doTranslate
			? t(
					`US.COLLECTION.CASE:CASEINFORMATION.${displayName.toUpperCase()}`
			  )
			: displayName;
	};

	return (
		<div className='widget widget-properties-info'>
			<div
				className={
					entityType === 'C'
						? `widget-header widget-link-header d-flex align-items-center px-0`
						: `widget-header  d-flex align-items-center px-0`
				}
				onClick={() => onMaximize()}>
				<span className='widget-title'>
					{t(
						'US.COLLECTION.CASE:CASEINFORMATION.CASE_INFORMATION'
					)}
				</span>
			</div>
			<$Skeleton
				loading={isLoading}
				active
				paragraph={{ rows: 2 }}>
				<div className='widget-body px-0'>
					{widgetData &&
						sortWidgetDataByDate(
							widgetData
						).map(
							({
								displayName,
								value,
								dataType,
								doTranslate,
								iconType,
							}) => (
								<div
									key={
										displayName
									}
									className='wpi-item'>
									<div className='wpi-item-label'>
										{handleTranslation(
											doTranslate,
											displayName
										)}
									</div>
									<div className='wpi-item-value'>
										{handleDateType(
											dataType,
											value,
											iconType
										)}
									</div>
								</div>
							)
						)}
				</div>
				<$Divider className='my-2' />
			</$Skeleton>
		</div>
	);
};

const mapStateToProps = (state: RootState) => {
	const { dashboard, domainView } = state;
	const { caseInfoWidgetData } = dashboard;
	const { metaData } = domainView;
	return {
		caseInfoWidgetData,
		metaData,
	};
};

export default connect(mapStateToProps)(PropertiesInfo);
