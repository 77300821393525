import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Common from 'us.common';
import {
	General,
	Contact,
	OtherContact,
	Settings,
	Creditors,
} from 'us.collection.creditor/components';
import './Home.scss';

const { $Row, $Col } = Common.Components;

interface ICnt_CreditorDashBoard extends RouteComponentProps {}
const cnt_CreditorDashboard: React.FC<ICnt_CreditorDashBoard> = (props) => {
	return (
		<div className='creditor-dashboard'>
			<$Row gutter={16}>
				{/* Creditor Basic Info */}
				<$Col
					span={6}
					xxl={{ span: 6 }}
					xl={{ span: 5 }}
					className='gutter-row'>
					<div className='gutter-box pr-xxl-3'>
						<General {...props} />
					</div>
				</$Col>

				{/* Creditor Contact Info */}
				<$Col
					span={6}
					xxl={{ span: 6 }}
					xl={{ span: 7 }}
					className='gutter-row'>
					<div className='gutter-box pr-xxl-3'>
						<Contact {...props} />
						<OtherContact {...props} />
					</div>
				</$Col>

				{/* Creditor Settings */}
				<$Col
					span={6}
					xxl={{ span: 6 }}
					xl={{ span: 6 }}
					className='gutter-row'>
					<div className='gutter-box pr-xxl-3'>
						<Settings {...props} />
					</div>
				</$Col>

				{/* Creditors List */}
				<$Col
					span={6}
					xxl={{ span: 6 }}
					xl={{ span: 6 }}
					className='gutter-row'>
					<div className='gutter-box pr-xxl-3'>
						<Creditors {...props} />
					</div>
				</$Col>
			</$Row>
		</div>
	);
};

export default withRouter(cnt_CreditorDashboard);
