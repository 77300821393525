export enum MappingTableColumn {
	CASE_NO = 'caseNo',
	SELECTION = 'selection',
	TRANSACTION_ID = 'transactionId',
	TYPE = 'typeCode',
	REF_NO = 'ref',
	VOUCHER_DATE = 'voucherDate',
	DUE_DATE = 'dueDate',
	REG_DATE = 'regDate',
	AMOUNT = 'amount',
	BALANCE = 'balance',
	NEW_BALANCE = 'newBalance',
	DESCRIPTION = 'description',
}

export const MAPPING_TABLE_COLUMNS = [
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.CASE_NO',
		key: MappingTableColumn.CASE_NO,
		filterType: false,
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TRANSACTION_ID',
		key: MappingTableColumn.TRANSACTION_ID,
		filterType: true,
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TYPE',
		key: MappingTableColumn.TYPE,
		filterType: true,
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REF_NO',
		key: MappingTableColumn.REF_NO,
		filterType: true,
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.VOUCHERDATE',
		key: MappingTableColumn.VOUCHER_DATE,
		filterType: 'date',
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.DUEDATE',
		key: MappingTableColumn.DUE_DATE,
		filterType: 'date',
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REGDATE',
		key: MappingTableColumn.REG_DATE,
		filterType: 'date',
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.AMOUNT',
		key: MappingTableColumn.AMOUNT,
		filterType: 'amount',
		align: 'right',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.BALANCE',
		key: MappingTableColumn.BALANCE,
		filterType: 'amount',
		align: 'right',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.DESCRIPTION',
		key: MappingTableColumn.DESCRIPTION,
		filterType: true,
		align: 'left',
	},
];

export const RE_MAPPING_TABLE_COLUMNS = [
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.CASE_NO',
		key: MappingTableColumn.CASE_NO,
		filterType: false,
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TRANSACTION_ID',
		key: MappingTableColumn.TRANSACTION_ID,
		filterType: true,
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TYPE',
		key: MappingTableColumn.TYPE,
		filterType: true,
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REF_NO',
		key: MappingTableColumn.REF_NO,
		filterType: true,
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.VOUCHERDATE',
		key: MappingTableColumn.VOUCHER_DATE,
		filterType: 'date',
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.DUEDATE',
		key: MappingTableColumn.DUE_DATE,
		filterType: 'date',
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REGDATE',
		key: MappingTableColumn.REG_DATE,
		filterType: 'date',
		align: 'left',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.AMOUNT',
		key: MappingTableColumn.AMOUNT,
		filterType: 'amount',
		align: 'right',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.BALANCE',
		key: MappingTableColumn.BALANCE,
		filterType: 'amount',
		align: 'right',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.NEW_BALANCE',
		key: MappingTableColumn.NEW_BALANCE,
		filterType: 'amount',
		align: 'right',
	},
	{
		title: 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.DESCRIPTION',
		key: MappingTableColumn.DESCRIPTION,
		filterType: true,
		align: 'left',
	},
];
