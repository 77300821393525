import { IClaimOfPayment } from 'us.collection.case/reducers/Dashboard/Interfaces'

export const ClaimOfPayment: IClaimOfPayment = {
    caseId: 0,
    paidTo: "",
    paidDate: "",
    amountPaid: "",
    payerName: "",
    paymentMethod: "Cash",
    cardNumber: "",
    paidFromAccount: "",
    paidToAccount: "",
    kidNumber: "",
    communicationMethod: "",
    smsNumber: "",
    smsInitial: "",
    caseNote: "",
    emailAddress: "",
    emailInitial: "",
    fileUpload: "",
    invoicetable: [],
    isSelected: false,
    creditorName: "",
    invoiceNumber: 0,
    kidNumberList: []
}