export enum SalaryDeductionSchedules {
	WEEKLY = 'Weekly',
	BIWEEKLY = 'BiWeekly',
	MONTHLY = 'Monthly',
}

export enum DateFormats {
	REQ = 'YYYY-MM-DD',
	MIN_DATE = '0001-01-01',
}

export enum EnforcementScreens {
	NEW_ENFORCEMENT = 'NEW_ENFORCEMENT',
	SELECT_ASSETS = 'SELECT_ASSETS',
	SALARY_DEDUCTION = 'SALARY_DEDUCTION',
	UPDATE_CASE_BALANCE = 'UPDATE_CASE_BALANCE',
}

export enum EntityTypes {
	SUB_CASE = 'SubCase',
	SUBCASE = 'Subcase',
	CASE = 'Case',
	AR = 'AR',
	CREDITOR = 'Creditor',
	PAYMENT = 'Payment',
	BUREAU = 'Bureau',
	DEBTOR = 'DEB',
}
