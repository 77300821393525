import * as Yup from 'yup';

interface DomainMessageSMS extends Yup.MixedSchema {
	isPaymentInformation: boolean;
	documentType: string;
	smsNumber: string;
	dueDate: string;
	template: string;
	caseNote: string;
	templateNote: string;
}

export default () => {
	return Yup.object<DomainMessageSMS>().shape({
		isPaymentInformation: Yup.boolean(),
		documentType: Yup.string().required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
		),
		smsNumber: Yup.number()
			.required(
				'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
			)
			.typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD')
			.nullable(),
	});
};
