/**
 * @description - Get selected transaction type details by type name
 * @param {string} type - Selected transaction type
 * @param {any[]} transactionTypes - Transaction types
 */
export const selectedTransactionTypeDetails = (
	type: string,
	transactionTypes: any[]
): {
	description: string;
	isInterestApplicable: boolean;
	isPayment: boolean;
} => {
	try {
		const transactionType = transactionTypes.find(
			(transType: any) => transType.typeName === type
		);
		return transactionType;
	} catch (error) {
		return { description: '', isInterestApplicable: false, isPayment: false };
	}
};

/**
 * @description - Check whether the given transaction type can able to change interest
 * @param {string} type - Selected transaction type
 * @param {any[]} transactionTypes - Transaction types
 */
export const isEnableInterestApplication = (
	type: string,
	transactionTypes: any[]
) => {
	try {
		const transactionTypeDetails = selectedTransactionTypeDetails(
			type,
			transactionTypes
		);
		if (
			transactionTypeDetails &&
			transactionTypeDetails.isInterestApplicable &&
			!transactionTypeDetails.isPayment
		) {
			return true;
		} else {
			return false;
		}
	} catch (error) {
		return false;
	}
};
