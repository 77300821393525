import { IAPIAction } from "us.collection/interfaces";
import { CreditorCases } from "us.collection.creditor/constants";

const {
  GET_CREDITOR_CASES,
  GET_CREDITOR_CASES_SUCCESS,
  GET_CREDITOR_CASES_FAIL,
} = CreditorCases;

export const creditorCases: Readonly<IAPIAction> = {
  caseList: {
    get: (data) => ({
      type: GET_CREDITOR_CASES,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_CREDITOR_CASES_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_CREDITOR_CASES_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
};
