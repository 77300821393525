import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { IPreview } from "us.collection.case/components/MessageTemplates/Interface";
import Common from "us.common";
import { useTranslation } from "react-i18next";
import { Pagination } from "us.common/components";

const { $Skeleton, $Button, $Empty } = Common.Components;

const MessageTemplateDetail: React.FC<IPreview> = (props) => {
  const { t } = useTranslation();

  const { template, onClose } = props;

  const [currentPage, setCurrentPage] = useState<number>(0);

  /**
   * Page Navigation
   */
  const onNavigation = (toNext: boolean) => {
    const scrollWrapper = document.getElementById('previewId') as any;
    scrollWrapper.scrollTop = 0;
    toNext ? setCurrentPage(currentPage + 1) : setCurrentPage(currentPage - 1);
  };

  return (
    <div className={template.isLoading ? "p-4" : "ant-drawer-body-outer"}>
      <$Skeleton loading={template.isLoading} active paragraph={{ rows: 2 }}>
        {template.content.length > 0 && (
          <>
            <div className="ant-drawer-body-inner" id="previewId">
              {ReactHtmlParser(template.content[currentPage])}
            </div>
            <div className="drawer-footer-fixed align-content-center justify-content-between">
              <Pagination
                dataLength={template.content.length}
                currentPage={currentPage}
                onNavigation={onNavigation}
              />
              <div>
                <$Button onClick={onClose}>
                  {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
                </$Button>
              </div>
            </div>
          </>
        )}
        {template.content.length === 0 && (
          <div className="d-flex justify-content-center">
            <div className="d-flex mt-4">
              <$Empty
                image={$Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>
                    {t(
                      "US.COLLECTION.CASE:MASSAGETEMPLATES.THERE_IS_NO_TEMPLATE_AVAILABLE"
                    )}
                  </span>
                }
              />
            </div>
          </div>
        )}
      </$Skeleton>
    </div>
  );
};

export default MessageTemplateDetail;
