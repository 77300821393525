import { FormikErrors, setNestedObjectValues } from "formik";
import { IAddBMDProperty } from "../Interfaces";
import * as Azure from "us.helper/azure";
import { getHistoryItemId, getISODateString } from ".";
import { appInsights } from "us.helper";

/**
 * @function
 * @description - To get a synthetic form validation on adding new change history value
 * @param {(values?: IAddBMDProperty) => Promise<FormikErrors<IAddBMDProperty>>} validateForm - formik function to validate form
 * @param {IAddBMDProperty} values - the form values
 * @param {*} setValues - formik function to set values
 * @param {(fields: { [field: string]: boolean }, shouldValidate?: boolean) => void} setTouched - formik funciton to get the fields touched
 */
export const handleAddValueToHisotryButton = (
  validateForm: (values?: IAddBMDProperty) => Promise<FormikErrors<IAddBMDProperty>>,
  values: IAddBMDProperty,
  setValues: any,
  setTouched: (fields: { [field: string]: boolean }, shouldValidate?: boolean) => void
): void => {
  try {
    const currentUserObje: { [key: string]: string } = (
      window._ENV.REACT_APP_AZURE_AD_SETUP
        ? new Azure.ADAuth()
        : new Azure.B2CAuth()
    ).currentUser();
    validateForm({ ...values, isAddButtonClicked: true }).then((validationErrors) => {
      if (Object.keys(validationErrors).length === 0) {
        const historyValues = [...(values.changedHistory ?? [])];
        historyValues?.unshift({
          comment: values.comment,
          dateTime: getISODateString(values.fromDate ?? undefined) ?? "",
          isEffective: false,
          isNew: true,
          user: currentUserObje?.unique_name,
          id: getHistoryItemId(values.changedHistory ?? []),
          value: values.value,
        });
        setValues(
          (preValues: IAddBMDProperty) => ({
            ...preValues,
            changedHistory: historyValues,
            comment: "",
            fromDate: undefined,
            value: undefined,
          }),
          false
        );
      } else {
        setTouched(setNestedObjectValues(validationErrors, true), false);
      }
    });
  } catch (error) {
    appInsights.trackException(
      `Error in add BMD handleAddValueToHisotryButton function. - ${error}`
    );
  }
};
