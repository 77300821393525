import { takeLatest, put, call, select, take } from "redux-saga/effects";
import * as API from "us.collection.transactions/services";
import * as Actions from "us.collection.transactions/actions";
import { ApplicableCourt } from "us.collection.transactions/constants/Actions";
import { $Message } from "us.common/components";

const { applicableCourts } = Actions;

const ApplicableCourtsSagas = {
  courts: {
    get: function* (action: any):any {
      const params = action?.payload.data ?? {};
      try {
        const data = yield call(API.applicableCourts.courts.get, params);
        if (
          !Array.isArray(data) &&
          (data?.code == 400 || data?.code == 404 || data?.errors?.length != 0)
        ) {
          yield put(applicableCourts.courts.fail(data));
          $Message.error(data?.errors[0]);
        } else {
          yield put(applicableCourts.courts.success(data));
        }
      } catch (error) {
        yield put(applicableCourts.courts.fail(error));
      }
    },
  },
};

export default [
  takeLatest(
    ApplicableCourt.GET_APPLICABLE_COURT,
    ApplicableCourtsSagas.courts.get
  )
];
