import { Formik } from 'formik';
import React, { useEffect, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import moment from 'moment';

import * as Actions from 'us.collection.transactions/actions';
import Common from 'us.common';
import { RootState } from 'us.helper/types';
import { InterestApplication } from 'us.collection.transactions/components/InterestApplication';
import { $Popconfirm, $Tooltip } from 'us.common/components';
import './EditInterest.scss';
import {
	DateFormats,
	InterestType,
} from 'us.collection.transactions/constants';
import { UpdateTransactionInterest } from './Repository';
import { IEditInterest } from './Interfaces';
import { getInterestTypeName } from 'us.collection.transactions/functions';
import { ValidationSchema } from './Validations';

const { $Button, $Row, $Col, $Form, $DateLabel, $AmountLabel, $Alert } =
	Common.Components;

/**
 * @description - Edit Transaction Interest Basis component to change transaction interest
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3272310785/Edit+Transaction+Interest+UI+Design+Implementations
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 03/04/2023
 */
const EditInterest: React.FC<IEditInterest & PropsFromRedux> = memo((props) => {
	const { t } = useTranslation();
	const {
		transactionDetails,
		standardInterest,
		interestRateUpdate,
		metaData,
		interestRateBMD,
		currentDateFormat,
		onClose,
		updateTransactionInterest,
		getStandardInterestRate,
	} = props;

	const { entityType, caseId } = metaData.data ?? {};

	const initialInterestRate = useMemo(() => {
		const { interestType } = transactionDetails ?? {};
		if (interestType == InterestType.STANDARD_INTEREST) {
			return standardInterest.data.interestRate;
		} else if (interestType == InterestType.FIXED_INTEREST) {
			return interestRateBMD.value;
		} else {
			return null;
		}
	}, [transactionDetails, standardInterest, interestRateBMD]);

	useEffect(() => {
		getStandardInterestRate &&
			getStandardInterestRate({
				calculationdate: moment().format(
					DateFormats.REQ
				),
			});
	}, []);

	/**
	 * @description - Handle submit
	 * @param {any} values
	 */
	const handleTransactionSubmit = (values: any) => {
		const paramsUpdateTransaction = UpdateTransactionInterest.call({
			...transactionDetails,
			...values,
			caseId,
		});
		const paramsGetTransaction = {
			entityType,
			entityId: caseId,
		};

		updateTransactionInterest &&
			updateTransactionInterest({
				paramsUpdateTransaction,
				paramsGetTransaction,
			});
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				interestType:
					transactionDetails.interestType ??
					InterestType.NO_INTEREST,
				interestFromDate:
					transactionDetails.interestFromDate ??
					moment(
						transactionDetails.dueDate,
						currentDateFormat
					).add(1, 'd'),
				interestRate: initialInterestRate,
			}}
			validateOnChange
			validateOnBlur
			validateOnMount
			validationSchema={ValidationSchema}
			onSubmit={handleTransactionSubmit}>
			{({
				values,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				dirty,
				isValid,
				...rest
			}: any) => (
				<>
					<div>
						<$Form onFinish={handleSubmit}>
							<div className='tei-summery'>
								<$Row
									gutter={
										16
									}
									className='tei-summery-item'>
									<$Col
										span={
											6
										}>
										<div className='tei-summery-item-inner'>
											<div className='tei-summery-item-value'>
												{transactionDetails?.type && (
													<$Tooltip
														placement='topLeft'
														title={
															transactionDetails?.type
														}>
														<span>
															{
																transactionDetails?.type
															}
														</span>
													</$Tooltip>
												)}
												{!transactionDetails?.type && (
													<span>
														---
													</span>
												)}
											</div>
											<div className='tei-summery-item-title'>
												{t(
													'US.COLLECTION.TRANSACTIONS:REGISTER.TRANSACTIONTYPE'
												)}
											</div>
										</div>
									</$Col>
									<$Col
										span={
											18
										}>
										<div className='tei-summery-item-inner'>
											<div className='tei-summery-item-value'>
												{transactionDetails?.description && (
													<$Tooltip
														placement='topLeft'
														title={
															transactionDetails?.description
														}>
														<span>
															{
																transactionDetails?.description
															}
														</span>
													</$Tooltip>
												)}
												{!transactionDetails?.description && (
													<span>
														---
													</span>
												)}
											</div>
											<div className='tei-summery-item-title'>
												{t(
													'US.COLLECTION.TRANSACTIONS:REGISTER.DESCRIPTION'
												)}
											</div>
										</div>
									</$Col>
								</$Row>
								<$Row
									gutter={
										16
									}
									className='tei-summery-item mt-3'>
									<$Col
										span={
											6
										}>
										<div className='tei-summery-item-inner'>
											<div className='tei-summery-item-value'>
												{transactionDetails?.voucherID >
													0 && (
													<$Tooltip
														placement='topLeft'
														title={
															transactionDetails?.voucherID
														}>
														<span>
															{
																transactionDetails?.voucherID
															}
														</span>
													</$Tooltip>
												)}
												{transactionDetails?.voucherID <=
													0 && (
													<span>
														---
													</span>
												)}
											</div>
											<div className='tei-summery-item-title'>
												{t(
													'US.COLLECTION.TRANSACTIONS:REGISTER.VOUCHERNO'
												)}
											</div>
										</div>
									</$Col>
									<$Col
										span={
											6
										}>
										<div className='tei-summery-item-inner'>
											<div className='tei-summery-item-value'>
												{transactionDetails?.voucherDate && (
													<$DateLabel
														value={
															transactionDetails?.voucherDate
														}
													/>
												)}
												{!transactionDetails?.voucherDate && (
													<span>
														---
													</span>
												)}
											</div>
											<div className='tei-summery-item-title'>
												{t(
													'US.COLLECTION.TRANSACTIONS:REGISTER.VOUCHERDATE'
												)}
											</div>
										</div>
									</$Col>
									<$Col
										span={
											6
										}>
										<div className='tei-summery-item-inner'>
											<div className='tei-summery-item-value'>
												{transactionDetails?.dueDate && (
													<$DateLabel
														value={
															transactionDetails?.dueDate
														}
													/>
												)}
												{!transactionDetails?.dueDate && (
													<span>
														---
													</span>
												)}
											</div>
											<div className='tei-summery-item-title'>
												{t(
													'US.COLLECTION.TRANSACTIONS:REGISTER.DUEDATE'
												)}
											</div>
										</div>
									</$Col>
									<$Col
										span={
											6
										}>
										<div className='tei-summery-item-inner'>
											<div className='tei-summery-item-value'>
												{transactionDetails?.type && (
													<$AmountLabel
														value={
															transactionDetails?.amount
														}
													/>
												)}
												{!transactionDetails?.type && (
													<span>
														---
													</span>
												)}
											</div>
											<div className='tei-summery-item-title'>
												{t(
													'US.COLLECTION.TRANSACTIONS:REGISTER.AMOUNT'
												)}
											</div>
										</div>
									</$Col>
								</$Row>
							</div>

							<div className='mt-5'>
								<strong>
									{t(
										'US.COLLECTION.TRANSACTIONS:REGISTER.CURRENT_INTEREST_DETAILS'
									)}
								</strong>

								<div className='tei-summery mt-3'>
									<$Row
										gutter={
											16
										}
										className='tei-summery-item'>
										<$Col
											span={
												6
											}>
											<div className='tei-summery-item-inner'>
												<div className='tei-summery-item-value'>
													{transactionDetails?.interestType && (
														<$Tooltip
															placement='topLeft'
															title={t(
																getInterestTypeName(
																	transactionDetails?.interestType
																)
															)}>
															<span>
																{t(
																	getInterestTypeName(
																		transactionDetails?.interestType
																	)
																)}
															</span>
														</$Tooltip>
													)}
													{!transactionDetails?.interestType && (
														<span>
															---
														</span>
													)}
												</div>
												<div className='tei-summery-item-title'>
													{t(
														'US.COLLECTION.TRANSACTIONS:REGISTER.INTEREST_TYPE'
													)}
												</div>
											</div>
										</$Col>

										<$Col
											span={
												6
											}>
											<div className='tei-summery-item-inner'>
												<div className='tei-summery-item-value'>
													{transactionDetails?.interestFromDate && (
														<$DateLabel
															value={
																transactionDetails?.interestFromDate
															}
														/>
													)}
													{!transactionDetails?.interestFromDate && (
														<span>
															---
														</span>
													)}
												</div>
												<div className='tei-summery-item-title'>
													{t(
														'US.COLLECTION.TRANSACTIONS:REGISTER.INTEREST_FROM_DATE'
													)}
												</div>
											</div>
										</$Col>
										<$Col
											span={
												6
											}>
											<div className='tei-summery-item-inner'>
												<div className='tei-summery-item-value'>
													{transactionDetails?.interestRate >
														0 &&
														transactionDetails.interestType !=
															InterestType.STANDARD_INTEREST && (
															<span>
																{
																	transactionDetails?.interestRate
																}
															</span>
														)}
													{(!transactionDetails?.interestRate ||
														transactionDetails.interestType ==
															InterestType.STANDARD_INTEREST) && (
														<span>
															---
														</span>
													)}
												</div>
												<div className='tei-summery-item-title'>
													{t(
														'US.COLLECTION.TRANSACTIONS:REGISTER.INTEREST_RATE'
													)}
												</div>
											</div>
										</$Col>
										<$Col
											span={
												6
											}>
											<div className='tei-summery-item-inner'>
												<div className='tei-summery-item-value'>
													{transactionDetails?.interestAmount >=
														0 && (
														<$AmountLabel
															value={
																transactionDetails?.interestAmount
															}
														/>
													)}
													{(transactionDetails?.interestAmount ==
														null ||
														typeof transactionDetails?.interestAmount ==
															'undefined') && (
														<span>
															---
														</span>
													)}
												</div>
												<div className='tei-summery-item-title'>
													{t(
														'US.COLLECTION.TRANSACTIONS:REGISTER.INTEREST'
													)}
												</div>
											</div>
										</$Col>
									</$Row>
								</div>
							</div>
							<div className='mt-5'>
								<InterestApplication />
							</div>
						</$Form>
					</div>

					<div className='drawer-footer-fixed align-content-center justify-content-end'>
						<$Popconfirm
							title={t(
								'US.COMMON:COMMON.DO_YOU_WISH_TO_CONTINUE_?'
							)}
							placement='topLeft'
							disabled={
								!dirty ||
								!isValid
							}
							onConfirm={handleSubmit}
							okText={t(
								'US.COMMON:COMMON.YES'
							)}
							cancelText={t(
								'US.COMMON:COMMON.NO'
							)}>
							<$Button
								className='mr-2'
								type='primary'
								loading={
									interestRateUpdate.isLoading
								}
								tabIndex={13}
								disabled={
									!dirty ||
									!isValid
								}>
								{t(
									'US.COMMON:COMMON.SAVE'
								)}
							</$Button>
						</$Popconfirm>

						<$Button
							onClick={() =>
								onClose()
							}
							tabIndex={14}>
							{t(
								'US.COMMON:COMMON.CANCEL'
							)}
						</$Button>
					</div>
				</>
			)}
		</Formik>
	);
});

const mapStateToProps = (state: RootState) => {
	const { common, transaction, domainView } = state;
	const { currentDateFormat, currentCurrency, currentLanguage } = common;
	const { metaData } = domainView;
	const { transactionDetails, interestApplication, interestRateBMD } =
		transaction;
	const { standardInterest, interestRateUpdate } = interestApplication;
	return {
		currentCurrency,
		currentLanguage,
		currentDateFormat,
		transactionDetails,
		standardInterest,
		interestRateUpdate,
		metaData,
		interestRateBMD,
	};
};

const { transactionInterest, standardInterestRate } =
	Actions.interestApplication;

const mapDispatchToProps = {
	getStandardInterestRate: standardInterestRate.get,
	updateTransactionInterest: transactionInterest.update,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditInterest);
