import { IManageCaseForm } from "../Interfaces";

/**
 * @constant
 * @description - The initial form values for manage case form
 * @type {Partial<IManageCaseForm>} - part of form values
 */
export const INITIAL_VALUES: IManageCaseForm = {
    selectedCases:[],
  destinationCase: "",
  newCase: true,
};
