import { EntityFollowUpPanel } from "us.collection.case/constants";

export enum EntityFollowUpTabs {
  FOLLOW_UP = "FOLLOW_UP",
  FOLLOW_UP_HISTORY = "FOLLOW_UP_HISTORY",
}

export type EntityFollowUpTabsInterface =
  | EntityFollowUpTabs.FOLLOW_UP
  | EntityFollowUpTabs.FOLLOW_UP_HISTORY

export const initialEntityDrawer = {
  visible: false,
  selectedTab: EntityFollowUpTabs.FOLLOW_UP,
  panel: EntityFollowUpPanel.DUE,
  filters: {
    due: {},
    upcoming: {},
    other: {
      followUpName: "",
      createdDate: "",
      assignedTo: "",
    },
  },
  manualFollowUp: {
    isNewManualFollowUp: false,
    panel: [],
  },
};
