import { DrawerType } from "us.collection.transactions/constants";
import { ICase } from "us.collection.transactions/interfaces";
import { IManageCaseForm } from "../Interfaces";
import { IsSourceCaseAllowAsDestinationCase } from './index';

/**
 * @function
 * @description - Handle the row selection of the table
 * @param {(field: string, value: any, shouldValidate?: boolean) => void} setFieldValue - formik setFieldValue function
 * @param {IManageCaseForm} values - form value
 * @param {DrawerType} values - drawer type
 * @param {ICase[]} selectedCases - list of cases selected in the Home view
 */
export const rowSelection = (
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  values: IManageCaseForm,
  drawerType: DrawerType,
  selectedCases: ICase[]
) => ({
  onChange: (selectedRowKeys: any, selectedRows: ICase[]) => {
    const { caseId } = selectedRows[0] ?? {};
    setFieldValue(
      'destinationCase',
      selectedRowKeys.length > 0 ? caseId : '',
      false
    ),
      IsSourceCaseAllowAsDestinationCase(
        selectedRowKeys.length > 0 ? caseId : '',
        selectedCases,
        setFieldValue
      );
  },
  getCheckboxProps: (record: ICase) => ({
    disabled: drawerType === DrawerType.CREATE_CASES && values.newCase, // Column configuration not to be checked
  }),
  hideDefaultSelections: true,
});
