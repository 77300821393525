import { FollowupFooter } from 'us.collection/constants';
import { IAPIAction } from 'us.collection/interfaces';

export const followupFooterActions: Readonly<IAPIAction> = {
    followups: {
        get: (data: any) => ({
            type: FollowupFooter.GET_FOOTER_FOLLOWUPS_START,
            payload: {
                data,
                isLoading: true,
            },
        }),
        success: (data: any) => ({
            type: FollowupFooter.GET_FOOTER_FOLLOWUPS_SUCCESS,
            payload: {
                data,
                isLoading: false,
            },
        }),
        fail: (error: any) => ({
            type: FollowupFooter.GET_FOOTER_FOLLOWUPS_FAIL,
            payload: {
                error,
                isLoading: false,
            },
        }),
    },
    nextResult: {
        get: (data: any) => ({
            type: FollowupFooter.GET_FOOTER_FOLLOWUP_NEXT_RESULT_START,
            payload: {
                data,
                isLoading: true,
            },
        }),
        success: (data: any) => ({
            type: FollowupFooter.GET_FOOTER_FOLLOWUP_NEXT_RESULT_SUCCESS,
            payload: {
                data,
                isLoading: false,
            },
        }),
        fail: (error: any) => ({
            type: FollowupFooter.GET_FOOTER_FOLLOWUP_NEXT_RESULT_FAIL,
            payload: {
                error,
                isLoading: false,
            },
        }),
    },
    previousResult: {
        set: (data: any) => ({
            type: FollowupFooter.SET_FOOTER_FOLLOWUP_PREVIOUS_RESULT_START,
            payload: {
                data,
                isLoading: true,
            },
        }),
        success: (data: any) => ({
            type: FollowupFooter.SET_FOOTER_FOLLOWUP_PREVIOUS_RESULT_SUCCESS,
            payload: {
                data,
                isLoading: false,
            },
        }),
        fail: (error: any) => ({
            type: FollowupFooter.SET_FOOTER_FOLLOWUP_PREVIOUS_RESULT_FAIL,
            payload: {
                error,
                isLoading: false,
            },
        }),
    },
    followupBackButtonVisibility: {
        set: (data: any) => ({
            type: FollowupFooter.SET_FOLLOWUP_CLOSE_BUTTON_VISIBILITY_START,
            payload: {
                data,
                isLoading: true,
            },
        }),
        success: (data: any) => ({
            type: FollowupFooter.SET_FOLLOWUP_CLOSE_BUTTON_VISIBILITY_SUCCESS,
            payload: {
                data,
                isLoading: false,
            },
        }),
        fail: (error: any) => ({
            type: FollowupFooter.SET_FOLLOWUP_CLOSE_BUTTON_VISIBILITY_FAIL,
            payload: {
                error,
                isLoading: false,
            },
        }),
    }
};