import { call, put, takeLatest } from 'redux-saga/effects';
import * as API from 'us.collection/services';
import { ObsoletedateAction } from 'us.collection/actions';
import { ObsoleteDate } from 'us.collection/constants/ObsoleteDateActions';
import { $MessageBox } from 'us.common/components';
import * as Actions from 'us.collection/actions';

const { domainViewAction } = Actions;

const ObsoleteDateSaga = {
	save: function* (action: any) {
		const { caseId, dateType, selectedDate, metaData } =
			action.payload.data;
		try {
			const data: { message: string; isError: boolean } =
				yield call(
					API.Obsoletedate.AddObsoleteDate.save,
					{
						caseId,
						selectedDate,
						dateType,
					}
				);
			yield put(
				ObsoletedateAction.AddObsoletedate.success(data)
			);
			if (!data?.isError) {
				$MessageBox(
					'success',
					'US.COLLECTION.COMMON:COMMON.SAVED_SUCCESSFULLY',
					'',
					''
				);
				yield put(
					domainViewAction.metaData.success(
						metaData
					)
				);
			}
		} catch (error) {
			$MessageBox(
				'error',
				'US.COLLECTION.COMMON:COMMON.UPDATE_FAILED',
				'',
				''
			);
			yield put(
				ObsoletedateAction.AddObsoletedate.fail(
					error as any
				)
			);
		}
	},
};

export default [
	takeLatest(ObsoleteDate.ADD_OBSOLETE_DATE_STRAT, ObsoleteDateSaga.save),
];
