import moment from "moment";
import {IInitialState} from "us.collection.admin/reducers/NonProductionDays/Interfaces"
import { NonProductionDays } from "us.collection.admin/functions";
import { smsTime } from "us.collection.admin/functions";

const selectedYear = moment().year();
export const initialState: IInitialState = {
  calendar: {
    data: {
      calendarId: -1,
      calendarName: "",
      selectedYear: selectedYear,
      startDate: moment.utc(`${selectedYear}-01-01`),
      endDate: moment.utc(`${selectedYear}-12-31`),
      selectedCriteria: "NonBanking",
      previousCalendars: [],
      isImported: false,
    },
    isFetching: false,
  },
  calendars: {
    data: [],
    isFetching: false,
  },
  redDays: {
    data: {
      nonProductionDays: NonProductionDays.call(selectedYear),
      selectedNonProductionDate: null,
    },
    isFetching: false,
  },
  smsSchedule: {
    data: smsTime.call(selectedYear),
    isFetching: false,
  },
};
