import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import Common from "us.common";
import { RootState } from "us.helper/types";
import { workflowAction } from "us.collection/actions";
import {
  PlusOutlined,
  MoreOutlined,
} from "us.icons";
import { StateDelete, WorkflowGroup } from "us.collection/repository";
import { getEntityNumberAndType } from "us.collection/components/BMD/Functions";
import { matchPath, useLocation } from "react-router-dom";
import { IEvents } from "us.collection/reducers/Workflow/Interfaces";
import { NewEvent, ItemMenu } from "./Components";
import {
  eventDrawerInitial,
  ItemMenuActions,
  WORKFLOW_TOOL_DOWNLOAD_URL,
} from "us.collection/components/Workflow/Constants";
import { searchEvent } from "us.collection/components/Workflow/Functions";
import { useFormikContext } from "formik";
import { WorkflowTag } from "us.collection/components/Workflow/Components";
import { openWorkflowTool } from "us.collection/functions";
import "us.collection/components/Workflow/Home.scss";
import _ from "lodash";

const {
  $Button,
  $Col,
  $Divider,
  $Drawer,
  $Popover,
  $Row,
  $Skeleton,
} = Common.Components;

/**
 * @description - The component used in view of events
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3116466232/Manage+Workflow+Events+-+UI
 * @param {PropsFromRedux} props
 * @return {JSX.Element}
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 17/11/2022
 */
const Event: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();

  const {
    getEvents,
    workflowEvents,
    eventDrawer,
    openDrawer,
    deleteEvent,
    isDeleting,
    metaData
  } = props;

  const { caseId } = metaData.data ?? {};

  const [popoverOpenId, setPopoverOpenId] = useState(-1);
  const [isDownloadPopupVisible, setDownloadPopupVisible] =
    useState<boolean>(false);

  const { values } = useFormikContext<any>();
  const searchFilters = _.omit(values, "statesAndEvent");

  const { pathname } = useLocation();
  const { params }: any = matchPath(pathname, {
    path: "/:type/:id?/:module?",
    exact: false,
    strict: false,
  });

  const entityData = WorkflowGroup.call(
    getEntityNumberAndType(
      params["type"],
      params["id"],
      caseId?.toString() ?? ""
    )
  );

  useEffect(() => {
    if (params["type"] && params["id"]) {
      workflowEvents?.data?.length == 0 &&
        getEvents &&
        getEvents(
          WorkflowGroup.call(
            getEntityNumberAndType(params["type"], params["id"], "")
          )
        );
    }
  }, []);

  /**
   * Delete event
   * @param id Event id
   */
  const deleteWorkflowEvent = (id: number, categoryId: number) => {
    deleteEvent &&
      deleteEvent({
        ...StateDelete.call({ id, categoryId }),
        stateRequest: WorkflowGroup.call(
          getEntityNumberAndType(params["type"], params["id"], "")
        ),
        searchFilters,
      });
    setPopoverOpenId(-1);
  };

  /**
   * @description handle events menu actions
   * @param action - action of clicked menu icon
   * @param record - selected record for action
   */
  const handleEventAction = async (action: ItemMenuActions, record: any) => {
    const {
      id,
      stateGroupId,
      workflowGroupStateId
    } = record ?? {};

    switch (action) {
      case ItemMenuActions.OPEN_WORKFLOW:
        const socketStatus = await openWorkflowTool({
          ...record,
          ...entityData,
        });
        setDownloadPopupVisible(socketStatus);
        break;
      case ItemMenuActions.DOWNLOAD:
        window.open(WORKFLOW_TOOL_DOWNLOAD_URL, "_self");
        setPopoverOpenId(-1);
        break;
      case ItemMenuActions.EDIT:
        openDrawer &&
          openDrawer({
            title: "US.COLLECTION.CASE:WORKFLOWS.EDIT_EVENT",
            isVisible: true,
            isEdit: true,
            eventId: id,
            workflowGroupStateId
          });
        setPopoverOpenId(-1);
        break;
      case ItemMenuActions.DELETE:
        deleteWorkflowEvent(id, stateGroupId)
        break;
      default:
        break;
    }
  };

  return (
    <>
      <$Skeleton
        loading={workflowEvents?.isLoading}
        active
        paragraph={{ rows: 2 }}
        className={workflowEvents?.isLoading ? "p-3" : ""}
      >
        <div className="workflows-events-layout">
          <div className="d-flex align-items-center">
            <h3 className="mb-0">{t("US.COLLECTION.CASE:WORKFLOWS.EVENTS")}</h3>
            <$Divider className="bui-devider" type="vertical" />
            <$Button
              type="default"
              size="small"
              icon={<PlusOutlined />}
              disabled={isDeleting}
              onClick={() =>
                openDrawer &&
                openDrawer({
                  ...eventDrawerInitial,
                  title: "US.COLLECTION.CASE:WORKFLOWS.ADD_NEW_EVENT",
                  isVisible: true,
                })
              }
            >
              {t("US.COLLECTION.CASE:WORKFLOWS.NEW_EVENT")}
            </$Button>
          </div>
          <div className="we-wrap mt-4">
            <$Row gutter={16}>
              {searchEvent(workflowEvents?.data, searchFilters)?.map(
                (event: IEvents) => {
                  const {
                    id,
                    displayName,
                    adapterName,
                    isDomainViewActive,
                    workflowType,
                  } = event ?? {};
                  return (
                    <$Col xl={{ span: 6 }} xxl={{ span: 4 }} key={id}>
                      <div className="we-item">
                        <div className="d-flex flex-grow-1 align-items-start">
                          <WorkflowTag
                            isEvent={true}
                            workflowType={workflowType}
                            activeStatus={isDomainViewActive}
                          />
                          <div className="we-item-content">
                            <div className="we-item-name">{displayName}</div>
                            <small className="we-item-da">{adapterName}</small>
                          </div>
                        </div>
                        <div>
                          <$Popover
                            placement="rightTop"
                            content={
                              <ItemMenu
                                isDownloadPopupVisible={isDownloadPopupVisible}
                                onCallAction={(action: ItemMenuActions) =>
                                  handleEventAction(action, event)
                                }
                                onCancelChildPopup={() => {
                                  setPopoverOpenId(-1);
                                  setDownloadPopupVisible(false);
                                }}
                              />
                            }
                            destroyTooltipOnHide
                            trigger="click"
                            visible={id == popoverOpenId}
                            onVisibleChange={(visible: boolean) => {
                              setPopoverOpenId(visible ? id : -1);
                              setDownloadPopupVisible(false);
                            }}
                          >
                            <$Button
                              type="link"
                              icon={<MoreOutlined />}
                              size="small"
                              onClick={() => setPopoverOpenId(id)}
                            />
                          </$Popover>
                        </div>
                      </div>
                    </$Col>
                  );
                }
              )}
            </$Row>
          </div>
        </div>
        <$Drawer
          title={t(eventDrawer.title)}
          width={500}
          visible={eventDrawer.isVisible}
          onClose={() => openDrawer && openDrawer(eventDrawerInitial)}
          destroyOnClose
        >
          <NewEvent searchFilters={searchFilters} />
        </$Drawer>
      </$Skeleton>
    </>
  );
};

const { eventsByAccessLevel, event, eventsDelete } = workflowAction;

const mapStateToProps = (state: RootState) => {
  const { Workflow, domainView } = state;
  const { workflowEvents, eventDrawer, isDeleting } = Workflow;
  const { metaData } = domainView;

  return {
    workflowEvents,
    eventDrawer,
    isDeleting,
    metaData
  };
};

const mapDispatchToProps = {
  getEvents: eventsByAccessLevel.get,
  openDrawer: event.openDrawer,
  deleteEvent: eventsDelete.delete,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Event);
