import * as Yup from 'yup';

interface IObsoleteInterest extends Yup.MixedSchema {
	OCCURENCEDATE: string;
	OCCURENCENAME: string;
}

export default () => {
	return Yup.object<IObsoleteInterest>().shape({
		OCCURENCEDATE: Yup.string().required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
		),
		OCCURENCENAME: Yup.string().required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
		),
	});
};
