import { IAccountSummary } from "us.common/components/CaseBalanceUpdate/Interfaces";

export const accountSummary: IAccountSummary = {
  mainAmount: 0,
  caseCost: 0,
  collectionFee: 0,
  courtFee: 0,
  otherCost: 0,
  mainAmountInterest: 0,
  interestOfCost: 0,
};
