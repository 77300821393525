import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import * as Actions from 'us.common/actions';
import * as API from 'us.common/services';
import { User, Notifications } from 'us.common/constants';
import { $MessageBox } from 'us.common/components';
import {} from 'us.common/constants';
import * as NotificationActions from 'us.common/actions';
import _ from 'lodash';
import { userSummary } from 'us.common/reducers/UserManagement/State';
import { setSaveUserErrorMessage } from 'us.common/functions';
import { ICreditorDetail } from 'us.common/interfaces';
import { i18n } from 'us.helper';
import { changeLanguage } from "us.common/actions";
import { updateNotificationMessage } from 'us.common/functions/notification';

const { userManagementActions } = Actions;
const {
	users,
	aDUsers,
	user,
	b2CUser,
	aDUser,
	creditorGroups,
	creditorCompanies,
	creditors: creditorsActions,
	authorizationLevel,
	init,
	preferredLanguage,
} = userManagementActions;

const userManagementSagas = {
	users: {
		get: function* (action: any): any {
			try {
				const data = yield call(
					API.UserService.users.get
				);
				if (Array.isArray(data)) {
					yield put(users.success(data));
				} else {
					yield put(users.fail([]));
				}
			} catch (error) {
				yield put(users.fail([]));
			}
		},
	},

	user: {
		init: function* (action: any): any {
			/**
			 * get AD users
			 */
			try {
				const data = yield call(
					API.UserService.aDUsers.get
				);
				if (Array.isArray(data)) {
					yield put(aDUsers.success(data));
				} else {
					yield put(aDUsers.fail([]));
				}
			} catch (error) {
				yield put(aDUsers.fail([]));
			}

			/**
			 * get Creditor groups
			 */
			try {
				const data = yield call(
					API.UserService.creditorGroups.get
				);
				if (Array.isArray(data)) {
					yield put(creditorGroups.success(data));
				} else {
					yield put(creditorGroups.fail(data));
				}
			} catch (error) {
				yield put(creditorGroups.fail({}));
			}

			/**
			 * get authorizationLevel
			 */
			try {
				const { data, status } = yield call(
					API.getAuthorityLevel,
					{}
				);
				if (status == 200 && data?.length) {
					yield put(
						authorizationLevel.success(data)
					);
				}
			} catch (error) {
				yield put(authorizationLevel.fail({ error }));
			}

			/**
			 * get Roles for users
			 */
			try {
				const data = yield call(
					API.RoleService.roles.get
				);
				if (Array.isArray(data)) {
					yield put(init.success(data));
				} else {
					yield put(init.fail([]));
				}
			} catch (error) {
				yield put(init.fail([]));
			}
		},
		save: function* (action: any): any {
			const params = action.payload.data;
			try {
				const data = yield call(
					API.UserService.user.save,
					params
				);
				if (data?.userId > 0) {
					if (user.saveSuccess) {
						yield put(
							user.saveSuccess(data)
						);
					}
					$MessageBox(
						'success',
						`US.COMMON:MESSAGES.USER_CREATED_SUCCESSFULLY`,
						'',
						''
					);
					if (users.get) {
						yield put(users.get({}));
					}
					if (user.openDrawer) {
						yield put(
							user.openDrawer({
								title: '',
								isNew: true,
								visible: false,
								selectedUser:
									userSummary,
							})
						);
					}
					if (user.reset) {
						yield put(user.reset({}));
					}
				} else {
					$MessageBox(
						'error',
						`US.COMMON:MESSAGES.USER_CAN_NOT_BE_CREATED`,
						'',
						''
					);
				}
			} catch (error) {
				if (user.saveFail) {
					yield put(user.saveFail(error as any));
				}
				$MessageBox(
					'error',
					`US.COMMON:MESSAGES.${setSaveUserErrorMessage(
						error
					)}`,
					'',
					''
				);
			}
		},
		delete: function* (action: any): any {
			const params = action.payload.data;
			try {
				const data = yield call(
					API.UserService.user.delete,
					params
				);
				if (_.isEmpty(data)) {
					$MessageBox(
						'success',
						`US.COMMON:MESSAGES.USER_IS_DELETED`,
						'',
						''
					);
					if (user.deleteSuccess) {
						yield put(
							user.deleteSuccess(
								params?.id
							)
						);
					}
				} else {
					$MessageBox(
						'error',
						`US.COMMON:MESSAGES.USER_CAN_NOT_BE_DELETED`,
						'',
						''
					);
				}
			} catch (error) {
				$MessageBox(
					'error',
					`US.COMMON:MESSAGES.USER_CAN_NOT_BE_DELETED`,
					'',
					''
				);
				if (user.deleteFail) {
					yield put(
						user.deleteFail(error as any)
					);
				}
			}
		},
		update: function* (action: any): any {
			const params = action.payload.data?.request;
			const history = action.payload.data?.historyRequest;
			const languageDetails = action.payload.data?.languageDetails
			try {
				const data = yield call(
					API.UserService.user.update,
					params
				);
				if (data?.userId > 0) {
					$MessageBox(
						'success',
						`US.COMMON:MESSAGES.USER_UPDATED_SUCCESSFULLY`,
						'',
						''
					);
					if (user.updateSuccess) {
						yield put(
							user.updateSuccess(data)
						);
					}
					if (users.get) {
						yield put(users.get({}));
					}
					if (user.saveHistory) {
						yield put(
							user.saveHistory(
								history
							)
						);
					}
					if (user.openDrawer) {
						yield put(
							user.openDrawer({
								title: '',
								isNew: true,
								visible: false,
								selectedUser:
									userSummary,
							})
						);
					}
					if (user.reset) {
						yield put(user.reset({}));
					}
					if(params.email ===  languageDetails?.modifiedUser){
						const { language, currency, dateFormat, languageCode } = languageDetails ?? {}
						i18n.changeLanguage(languageCode);
						yield put(changeLanguage(language, currency, dateFormat));						
					}
				} else {
					$MessageBox(
						'error',
						`US.COMMON:MESSAGES.USER_CAN_NOT_BE_UPDATED`,
						'',
						''
					);
				}
			} catch (error) {
				$MessageBox(
					'error',
					`US.COMMON:MESSAGES.USER_CAN_NOT_BE_UPDATED`,
					'',
					''
				);
			}
		},
		getById: function* (action: any): any {
			const params = action.payload.data;
			try {
				const data = yield call(
					API.UserService.user.getById,
					params
				);
				const { creditors = [] } = data;
				let newUserData = {
					...data,
					creditors: [],
					creditorGroups: [],
					creditorCompanies: [],
				};

				if (creditors.length != 0) {
					const creditorIds: Array<
						string | number
					> = [];
					const creditorGroupIds: Array<
						string | number
					> = [];
					const creditorCompanyIds: Array<
						string | number
					> = [];
					creditors.map(
						(
							creditorDetail: ICreditorDetail
						) => {
							const {
								creditorId,
								creditorCompanyId,
								creditorGroupId,
							} = creditorDetail;
							creditorIds.push(
								creditorId
							);
							creditorCompanyIds.push(
								creditorCompanyId
							);
							creditorGroupIds.push(
								creditorGroupId
							);
						}
					);
					if (creditorCompanies.get) {
						yield put(
							creditorCompanies.get(
								creditorGroupIds
							)
						);
					}
					if (creditorsActions.get) {
						yield put(
							creditorsActions.get({
								group: creditorGroupIds,
								company: creditorCompanyIds,
							})
						);
					}
					newUserData = {
						...data,
						creditors: creditorIds.map(
							String
						),
						creditorGroups:
							creditorGroupIds,
						creditorCompanies:
							creditorCompanyIds.map(
								String
							),
					};
				}
				yield put(user.success(newUserData));
			} catch (error) {
				yield put(user.fail({}));
			}
		},
		getHistory: function* (action: any): any {
			const params = action.payload.data;
			try {
				const data = yield call(
					API.UserService.user.getHistory,
					params
				);
				if (
					user.getHistorySuccess &&
					Array.isArray(data)
				) {
					yield put(user.getHistorySuccess(data));
				} else if (user.getHistoryFail) {
					yield put(user.getHistoryFail(data));
				}
			} catch (error) {
				if (user.getHistoryFail) {
					yield put(user.getHistoryFail([]));
				}
			}
		},
		saveHistory: function* (action: any): any {
			const params = action.payload.data;
			try {
				const data = yield call(
					API.UserService.user.saveHistory,
					params
				);
				if (user.saveHistorySuccess) {
					yield put(
						user.saveHistorySuccess(data)
					);
				}
			} catch (error) {
				if (user.saveHistoryFail) {
					yield put(user.saveHistoryFail([]));
				}
			}
		},
		stateChange: function* (action: any): any {
			const params = action.payload.data;
			try {
				const data = yield call(
					API.UserService.user.patch,
					params
				);
				if (data?.userId > 0) {
					const message = params?.body[0]?.value
						? 'USER_IS_ACTIVATED'
						: 'USER_IS_DEACTIVATED';
					$MessageBox(
						'success',
						`US.COMMON:MESSAGES.${message}`,
						'',
						''
					);

					if (user.activateOrDeactivateSuccess) {
						yield put(
							user.activateOrDeactivateSuccess(
								{
									userId: data?.userId,
									activeState:
										params
											?.body[0]
											?.value,
								}
							)
						);
					}
				} else {
					$MessageBox(
						'success',
						`US.COMMON:MESSAGES.CAN_NOT_BE_CHANGED_ACTIVE_STATE`,
						'',
						''
					);
				}
			} catch (error) {
				$MessageBox(
					'error',
					`US.COMMON:MESSAGES.CAN_NOT_BE_CHANGED_ACTIVE_STATE`,
					'',
					''
				);
			}
		},
	},
	aDUsers: {
		get: function* (action: any): any {
			try {
				const data = yield call(
					API.UserService.aDUsers.get
				);
				if (Array.isArray(data)) {
					yield put(aDUsers.success(data));
				} else {
					yield put(aDUsers.fail([]));
				}
			} catch (error) {
				yield put(aDUsers.fail([]));
			}
		},
	},
	aDUser: {
		getById: function* (action: any): any {
			const params = action.payload.data;
			try {
				const data = yield call(
					API.UserService.aDUser.getById,
					params
				);
				yield put(aDUser.success(data));
			} catch (error) {
				yield put(aDUser.fail({}));
			}
		},
	},
	b2CUser: {
		save: function* (action: any): any {
			const params = action.payload.data;
			try {
				const data = yield call(
					API.UserService.b2CUser.save,
					params
				);
				if (data?.email) {
					yield put(b2CUser.success(data));
					$MessageBox(
						'success',
						`US.COMMON:MESSAGES.USER_CREATED_SUCCESSFULLY`,
						'',
						''
					);
					// if (users.get) {
					//     yield put(users.get({}))
					// }
				}
			} catch (error) {
				yield put(b2CUser.fail(error as any));
				$MessageBox(
					'error',
					`US.COMMON:MESSAGES.USER_CAN_NOT_BE_CREATED`,
					'',
					''
				);
			}
		},
		delete: function* (action: any): any {
			const params = action.payload.data;
			try {
				const data = yield call(
					API.UserService.b2CUser.delete,
					params
				);
				if (_.isEmpty(data)) {
					$MessageBox(
						'success',
						`US.COMMON:MESSAGES.USER_IS_DELETED`,
						'',
						''
					);
					if (user.deleteSuccess) {
						yield put(
							user.deleteSuccess(
								params?.userId
							)
						);
					}
				} else {
					$MessageBox(
						'error',
						`US.COMMON:MESSAGES.USER_CAN_NOT_BE_DELETED`,
						'',
						''
					);
				}
			} catch (error) {
				$MessageBox(
					'error',
					`US.COMMON:MESSAGES.USER_CAN_NOT_BE_DELETED`,
					'',
					''
				);
				if (user.deleteFail) {
					yield put(
						user.deleteFail(error as any)
					);
				}
			}
		},
	},
	creditorGroups: {
		get: function* (action: any): any {
			try {
				const data = yield call(
					API.UserService.creditorGroups.get
				);
				if (Array.isArray(data)) {
					yield put(creditorGroups.success(data));
				} else {
					yield put(creditorGroups.fail(data));
				}
			} catch (error) {
				yield put(creditorGroups.fail({}));
			}
		},
	},
	creditorCompanies: {
		get: function* (action: any): any {
			const params = action.payload.data;
			try {
				const data = yield call(
					API.UserService.creditorCompanies.get,
					params
				);
				if (Array.isArray(data)) {
					yield put(
						creditorCompanies.success(data)
					);
				} else {
					yield put(creditorCompanies.fail(data));
				}
			} catch (error) {
				yield put(creditorCompanies.fail({}));
			}
		},
	},
	creditors: {
		get: function* (action: any): any {
			const params = action.payload.data;
			try {
				const data = yield call(
					API.UserService.creditors.get,
					params
				);
				if (Array.isArray(data)) {
					yield put(
						creditorsActions.success(data)
					);
				} else {
					yield put(creditorsActions.fail(data));
				}
			} catch (error) {
				yield put(creditorsActions.fail({}));
			}
		},
	},
	notification: {
		get: function* (action: any): any {
			const { id, offset } = action.payload.data;
			try {
				const data = yield call(API.UserService.notification.get, {
				  id,
				  offset,
				});
				if (Array.isArray(data?.notifications)) {
				  data.notifications = updateNotificationMessage(data?.notifications);
				  yield put(NotificationActions.notification.user.success(data));
				} else {
				  yield put(NotificationActions.notification.user.fail({}));
				}
			} catch (error) {
				yield put(
					NotificationActions.notification.user.fail(
						{ error }
					)
				);
			}
		},
		update: function* (action: any): any {
			const { notificationId, userId, type } =
				action.payload.data ?? {};
			try {
				const data = yield call(
					API.UserService.notification.update,
					{
						userId,
						notificationId,
						type,
					}
				);
				yield put(
					NotificationActions.notification.update.success(
						{
							...data,
							notificationId,
							type,
						}
					)
				);
			} catch (error) {
				yield put(
					NotificationActions.notification.update.fail(
						{ error }
					)
				);
			}
		},
		retry: function* (action: any) {
			const params = action.payload.data ?? {};
			try {
				const { data, status } = yield call(
					API.UserService.notification.retry,
					params
				);

				if (status === 200) {
					$MessageBox(
						'success',
						`US.COMMON:MESSAGES.RETRY_REQUEST_SUCCESS`,
						'',
						''
					);
					yield put(
						NotificationActions.notification.action.success(
							data
						)
					);
				} else {
					$MessageBox(
						'error',
						`US.COMMON:MESSAGES.RETRY_REQUEST_FAILED`,
						'',
						''
					);
					yield put(
						NotificationActions.notification.action.fail(
							new Error()
						)
					);
				}
			} catch (error) {
				$MessageBox(
					'error',
					`US.COMMON:MESSAGES.RETRY_REQUEST_FAILED`,
					'',
					''
				);
				yield put(
					NotificationActions.notification.action.fail(
						{ error }
					)
				);
			}
		},
	},
	authorizationLevel: {
		get: function* (action: any) {
			try {
				const { data, status } = yield call(
					API.getAuthorityLevel,
					{}
				);
				if (status == 200 && data?.length) {
					yield put(
						authorizationLevel.success(data)
					);
				}
			} catch (error) {
				yield put(authorizationLevel.fail({ error }));
			}
		},
	},
	preferredLanguage: {
		update: function* (action: any) {
			try {
				const params = action.payload.data ?? {};
				const { data, status } = yield call(
					API.UserService.preferredLanguage
						.update,
					params
				);
				if (status == 200) {
					yield put(
						preferredLanguage.success(data)
					);
				} else {
					yield put(preferredLanguage.fail(data));
				}
			} catch (error) {
				yield put(preferredLanguage.fail({ error }));
			}
		},
	},
};
export default [
	takeLatest(User.GET_USERS_START, userManagementSagas.users.get),
	takeLatest(
		User.GET_AZURE_AD_USERS_START,
		userManagementSagas.aDUsers.get
	),
	takeLatest(User.SAVE_NEW_USER_START, userManagementSagas.user.save),
	takeLatest(
		User.SAVE_NEW_B2C_USER_START,
		userManagementSagas.b2CUser.save
	),
	takeLatest(User.DELETE_USER_START, userManagementSagas.user.delete),
	takeLatest(User.UPDATE_USER_START, userManagementSagas.user.update),
	takeLatest(User.GET_USER_BY_ID_START, userManagementSagas.user.getById),
	takeLatest(
		User.GET_AZURE_AD_USER_BY_ID_START,
		userManagementSagas.aDUser.getById
	),
	takeLatest(
		User.ADD_USER_HISTORY_START,
		userManagementSagas.user.saveHistory
	),
	takeLatest(
		User.GET_USER_HISTORY_BY_ID_START,
		userManagementSagas.user.getHistory
	),
	takeLatest(
		User.GET_CREDITOR_GROUP_START,
		userManagementSagas.creditorGroups.get
	),
	takeLatest(
		User.GET_CREDITOR_COMPANY_START,
		userManagementSagas.creditorCompanies.get
	),
	takeLatest(
		User.GET_CREDITOR_BY_COMPANY_ID_START,
		userManagementSagas.creditors.get
	),
	takeLatest(
		User.USER_STATE_CHANGE_START,
		userManagementSagas.user.stateChange
	),
	takeLatest(
		User.DELETE_B2C_USER_START,
		userManagementSagas.b2CUser.delete
	),
	takeLatest(
		Notifications.GET_NOTIFICATIONS,
		userManagementSagas.notification.get
	),
	takeEvery(
		Notifications.UPDATE_NOTIFICATIONS,
		userManagementSagas.notification.update
	),
	takeEvery(
		Notifications.RETRY_NOTIFICATION_ACTION,
		userManagementSagas.notification.retry
	),
	takeEvery(
		User.GET_AUTHORIZATION_LEVEL_START,
		userManagementSagas.authorizationLevel.get
	),
	takeLatest(User.GET_INIT_USER_START, userManagementSagas.user.init),
	takeLatest(
		User.UPDATE_PREFERRED_LANGUAGE,
		userManagementSagas.preferredLanguage.update
	),
];
