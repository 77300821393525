import { initialState } from "us.collection.admin/reducers/NonProductionDays/State";
import { NonProductionDay } from "us.collection.admin/constants/Actions";
import moment from "moment";
import {
  NonProductionDays,
  smsTime,
} from "us.collection.admin/functions";

const {
  SAVE_CALENDAR,
  SAVE_CALENDAR_SUCCESS,
  SAVE_CALENDAR_FAIL,
  RESET_NON_PRODUCTION_DAYS,
  CHANGE_SELECTED_YEAR,
  GET_CALENDARS,
  GET_CALENDARS_SUCCESS,
  GET_CALENDARS_FAIL,
  GET_NON_PRODUCTION_DAYS,
  GET_NON_PRODUCTION_DAYS_SUCCESS,
  GET_NON_PRODUCTION_DAYS_FAIL,
  GET_DEFAULT_NON_PRODUCTION_DAYS,
  GET_SMS_SCHEDULES,
  GET_SMS_SCHEDULES_SUCCESS,
  GET_SMS_SCHEDULES_FAIL,
  GET_NON_PRODUCTION_DAYS_WITH_SMS_SCHEDULE,
  GET_NON_PRODUCTION_DAYS_WITH_SMS_SCHEDULE_SUCCESS,
  GET_NON_PRODUCTION_DAYS_WITH_SMS_SCHEDULE_FAIL,
  GET_PREVIOUS_CALENDAR,
  GET_PREVIOUS_CALENDAR_SUCCESS,
  GET_PREVIOUS_CALENDAR_FAIL,
  CANCEL_SELECTED_CALENDAR,
  SELECT_NON_PRODUCTION_DAY,
  SELECT_CRITERIA,
  ADD_NEW_NON_PRODUCTION_DAY,
  IMPORT_CALENDAR_SELECT,
} = NonProductionDay;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_CALENDARS:
      return Object.assign({}, state, {
        calendars: {
          data: state.calendars.data,
          isFetching: true,
        },
      });

    case GET_CALENDARS_SUCCESS:
      return Object.assign({}, state, {
        calendars: {
          data: payload?.data,
          isFetching: false,
        },
      });

    case GET_CALENDARS_FAIL:
      return Object.assign({}, state, {
        calendars: {
          data: [],
          isFetching: false,
        },
      });

    case SAVE_CALENDAR_SUCCESS:
      return Object.assign({}, { ...initialState });

    case CHANGE_SELECTED_YEAR:
      const isExistingCal = payload?.isExistingCal;
      return Object.assign({}, state, {
        calendar: {
          data: {
            ...state.calendar.data,
            selectedYear: payload?.data,
            startDate: moment.utc(`${payload?.data}-01-01`),
            endDate: moment.utc(`${payload?.data}-12-31`),
            previousCalendars: [],
          },
          isFetching: isExistingCal ? true : false,
        },
        redDays: {
          data: {
            nonProductionDays: NonProductionDays.call(payload?.data),
            selectedNonProductionDate: null,
          },
          isFetching: isExistingCal ? true : false,
        },
        smsSchedule: {
          data: smsTime.call(payload?.data),
          isFetching: isExistingCal ? true : false,
        },
      });

    case RESET_NON_PRODUCTION_DAYS:
      return Object.assign({}, { ...initialState });

    case GET_NON_PRODUCTION_DAYS_SUCCESS:
      const nonProdDays = payload?.data?.map((nonProdDay: any, index: any) => {
        return { ...nonProdDay, key: index };
      });
      return Object.assign({}, state, {
        redDays: {
          data: {
            nonProductionDays: nonProdDays,
            selectedNonProductionDate: null,
          },
          isFetching: false,
        },
      });
    case GET_NON_PRODUCTION_DAYS_FAIL:
      return Object.assign({}, state, {
        redDays: {
          data: {
            nonProductionDays: [],
            selectedNonProductionDate: null,
          },
          isFetching: false,
        },
      });
    case GET_NON_PRODUCTION_DAYS_WITH_SMS_SCHEDULE:
      return Object.assign({}, state, {
        calendar: {
          ...state.calendar,
          isFetching: true,
        },
        redDays: {
          ...state.redDays,
          isFetching: true,
        },
        smsSchedule: {
          ...state.smsSchedule,
          isFetching: true,
        },
      });
    case GET_NON_PRODUCTION_DAYS_WITH_SMS_SCHEDULE_FAIL:
      return Object.assign({}, state, {
        calendar: {
          ...state.calendar,
          isFetching: false,
        },
        redDays: {
          data: {
            nonProductionDays: [],
            selectedNonProductionDate: null,
          },
          isFetching: false,
        },
        smsSchedule: {
          data: smsTime.call(state.calendar.data.selectedYear),
          isFetching: false,
        },
      });

    case GET_SMS_SCHEDULES_SUCCESS:
      return Object.assign({}, state, {
        smsSchedule: {
          data: state.smsSchedule.data.map((day: any) => {
            const newDay = payload?.data?.find(
              (res: any) => res.dayId === day.dayId
            );
            if (newDay != undefined) {
              return {
                ...newDay,
                isEnable: true,
                timeSlots: newDay.timeSlots.map((slot: any) => {
                  return {
                    ...slot,
                    startTime: moment(slot.startTime),
                    endTime: moment(slot.endTime),
                  };
                }),
              };
            } else {
              return { ...day };
            }
          }),
          isFetching: false,
        },
      });
    case GET_SMS_SCHEDULES_FAIL:
      return Object.assign({}, state, {
        smsSchedule: {
          data: smsTime.call(state.calendar.data.selectedYear),
          isFetching: false,
        },
      });

    case GET_DEFAULT_NON_PRODUCTION_DAYS:
      const initNonProdDays = [...initialState.redDays.data.nonProductionDays];
      return Object.assign({}, state, {
        redDays: {
          data: {
            nonProductionDays: initNonProdDays.map(
              (day: {
                id: number;
                date: moment.Moment;
                reason: string;
                isFixedDate: boolean;
                criteria: Array<any>;
              }) => {
                return {
                  ...day,
                  date: moment(day?.date).year(
                    state.calendar.data.selectedYear
                  ),
                };
              }
            ),
            selectedNonProductionDate: null,
          },
          isFetching: false,
        },
        smsSchedule: {
          data: smsTime.call(state.calendar.data.selectedYear),
          isFetching: false,
        },
        calendar: {
          data: {
            ...state.calendar.data,
            isImported: true,
          },
          isFetching: false,
        },
      });

    case CANCEL_SELECTED_CALENDAR:
      return Object.assign({}, state, {
        redDays: {
          data: {
            nonProductionDays: [],
            selectedNonProductionDate: null,
          },
          isFetching: false,
        },
        calendar: {
          data: {
            ...state.calendar.data,
            isImported: false,
          },
          isFetching: false,
        },
        smsSchedule: {
          data: smsTime.call(state.calendar.data.selectedYear),
          isFetching: false,
        },
      });

    case SELECT_NON_PRODUCTION_DAY:
      const { index, ...newData } = payload?.data;
      if (payload?.data?.date.isValid()) {
        state.redDays.data.nonProductionDays.splice(
          payload?.data?.index,
          1,
          newData
        );
      }

      return Object.assign({}, state, {
        redDays: {
          data: {
            ...state.redDays.data,
            selectedNonProductionDate: { ...payload?.data },
          },
          isFetching: false,
        },
        NonBankingSwitch: payload?.data?.criteria.find(
          (criteria: any) =>
            criteria.type === "NonBanking" && criteria.value === 1
        )
          ? true
          : false,
        NonSmsSwitch: payload?.data?.criteria.find(
          (criteria: any) => criteria.type === "NonSms" && criteria.value === 1
        )
          ? true
          : false,
        NonPrintingSwitch: payload?.data?.criteria.find(
          (criteria: any) =>
            criteria.type === "NonPrinting" && criteria.value === 1
        )
          ? true
          : false,
        NonWorkflowSwitch: payload?.data?.criteria.find(
          (criteria: any) =>
            criteria.type === "NonWorkflow" && criteria.value === 1
        )
          ? true
          : false,
      });

    case SELECT_CRITERIA:
      return Object.assign({}, state, {
        calendar: {
          data: {
            ...state.calendar.data,
            selectedCriteria: payload?.data,
          },
          isFetching: false,
        },
      });

    case ADD_NEW_NON_PRODUCTION_DAY:
      return Object.assign({}, state, {
        redDays: {
          data: {
            ...state.redDays.data,
            nonProductionDays: [
              ...state.redDays.data.nonProductionDays,
              { ...payload?.data },
            ],
          },
          isFetching: false,
        },
      });

    case GET_PREVIOUS_CALENDAR_SUCCESS:
      return Object.assign({}, state, {
        calendar: {
          data: {
            ...state.calendar.data,
            previousCalendars: [
              {
                year: payload?.selectedYear - 1,
                days: payload?.data?.filter((day: any) =>
                  day.date.includes(payload?.selectedYear - 1)
                ),
              },
              {
                year: payload?.selectedYear - 2,
                days: payload?.data?.filter((day: any) =>
                  day.date.includes(payload?.selectedYear - 2)
                ),
              },
              {
                year: payload?.selectedYear - 3,
                days: payload?.data?.filter((day: any) =>
                  day.date.includes(payload?.selectedYear - 3)
                ),
              },
            ],
          },
          isFetching: false,
        },
      });

    case IMPORT_CALENDAR_SELECT:
      const nonProductionDays: { year: number; days: Array<any> } =
        state.calendar.data.previousCalendars[payload?.data];
      return Object.assign({}, state, {
        redDays: {
          data: {
            nonProductionDays: nonProductionDays["days"].map(
              (
                day: {
                  id: number;
                  date: string;
                  reason: string;
                  isFixedDate: boolean;
                  criteria: Array<any>;
                },
                index: number
              ) => {
                return {
                  ...day,
                  id: -1,
                  date: moment(day?.date).year(
                    state.calendar.data.selectedYear
                  ),
                  key: index,
                };
              }
            ),
            selectedNonProductionDate: null,
          },
          isFetching: false,
        },
        smsSchedule: {
          data: state.smsSchedule?.data?.map((day: any) => {
            const timeSlots = day?.timeSlots?.filter(
              (slot: any) =>
                slot.startTime.year() == nonProductionDays.year &&
                slot.endTime.year() == nonProductionDays.year
            );
            return {
              ...day,
              isEnable: timeSlots.length == 0 ? false : day.isEnable,
              timeSlots:
                timeSlots.length == 0
                  ? [
                      {
                        id: -1,
                        startTime: moment()
                          .year(state.calendar.data.selectedYear)
                          .set({ hour: 8, minute: 0 }),
                        endTime: moment()
                          .year(state.calendar.data.selectedYear)
                          .set({ hour: 20, minute: 0 }),
                      },
                    ]
                  : timeSlots.map((slot: any) => {
                      return {
                        ...slot,
                        startTime: slot?.startTime?.year(
                          state.calendar.data.selectedYear
                        ),
                        endTime: slot?.endTime?.year(
                          state.calendar.data.selectedYear
                        ),
                      };
                    }),
            };
          }),
          isFetching: false,
        },
        calendar: {
          data: {
            ...state.calendar.data,
            isImported: true,
          },
          isFetching: false,
        },
      });

    default:
      return state;
  }
};
