import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ContentV1, ContentV2 } from './Components';
import { RootState } from 'us.helper/types';
import { ConfigurationContext } from 'us.common/ConfigurationContext/ConfigurationProvider';

const NotesAndMessages: React.FC = () => {
	const { authorizationProfile } = useSelector(
		(state: RootState) => state.AuthorizationProfile
	);

	const context = useContext(ConfigurationContext);
	const notePermissions = useMemo(() => {
		return (
			context?.componentPermission &&
			context?.componentPermission['case'].components[
				'messageAndNotes'
			].components
		);
	}, [context?.componentPermission]);

	const getContent = () => {
		try {
			if (
				authorizationProfile?.domainMessageVersion ==
				'V2'
			) {
				return (
					<ContentV2
						notePermissions={
							notePermissions
						}
					/>
				);
			} else {
				return (
					<ContentV1
						notePermissions={
							notePermissions
						}
					/>
				);
			}
		} catch (error) {
			return <ContentV1 notePermissions={notePermissions} />;
		}
	};
	return <>{getContent()}</>;
};

export default NotesAndMessages;
