import React, { useEffect, useState } from 'react';
import Form from 'antd/lib/form';
import { connect } from 'react-redux';
import { Select, Breadcrumb, Row, Col, Input, DatePicker, Button, AutoComplete } from 'antd';

import { getDebtorContactCategory } from 'us.collection.debtor/actions';
import { AddPostalArea, SelectPostalArea, SelectMunicipal } from 'us.common/components';
// import { getPostalAreas } from 'us.common/actions';
import NewDebtorCategory from './NewDebtorCategory';
import DEBTOR_CONSTANTS from 'us.collection.debtor/constants';
import { SelectValue } from 'antd/lib/select';
import { postalCodeValidator } from 'us.common/functions';
import { PostalAreaDetails } from 'us.common/components/EntitySelection/Types';
import { useTranslation } from 'react-i18next';
import { IRootState } from "us.collection/interfaces";

const { viewNames } = DEBTOR_CONSTANTS

interface IDebtorContactInfoForm {
    debtorContactInfo: any;
    saveClick(values: any): void;
    cancelClick(result: any): void;
}

interface IBase extends IDebtorContactInfoForm {
    contactCategories: any;
    getDebtorContactCategory: any;
    postalAreas: any[];
    //getPostalAreas: () => void;
    currentDateFormat: string;
}

const DebtorContactInfoForm: React.FC<IBase> = (props) => {

    const { t } = useTranslation();
    const {
        contactCategories,
        getDebtorContactCategory,
        postalAreas,
       // getPostalAreas,
        currentDateFormat
    } = props;


    let newDebtorContactInfo = {
        FirstName: '',
        LastName: '',
        BirthDate: new Date(),
        HomePhoneNumber: '',
        WorkfPhoneNumber: '',
        MobileNumber: '',
        Email: '',
        Skype: '',
        Msn: '',
        SmsNumber: '',
        PostalCode: '',
        City: '',
        MunicipalityCode: '',
        MunicipalityName: '',
        PostalAddress1: '',
        PostalAddress2: '',
        PostalAddress3: '',
        DebtorCategory: ''
    }

    if (props.debtorContactInfo) {
        newDebtorContactInfo = props.debtorContactInfo;
    }
    const [categoryListUI, setCategoryListUI] = useState([]);
    const [drawerContent, setDrawerContent] = useState<any>({ viewName: viewNames.debtContactInfo, debtorInfo: null, setp: undefined });
    const [optinos, setOptions] = useState<any>();

    useEffect(() => {


        /**
         * arranging the data for contact category list
         */
        let tempCategoryListUI = [];
        if (contactCategories.length === 0) {
            getDebtorContactCategory();
        } else {
            tempCategoryListUI = contactCategories.map((item: any) =>
                <Select.Option key={item.id} value={item.id}>{item.Name}</Select.Option>
            );
            if (contactCategories) { /**for a new member */
                newDebtorContactInfo.DebtorCategory = contactCategories[0].id;
            }
            setCategoryListUI(tempCategoryListUI);
        }

        /**
         * arranging data for postal code list
         */
        if (postalAreas?.length === 0) {
          //  getPostalAreas();
        }
        if (!optinos && postalAreas.length > 0) {
            setOptions(postalAreas
                .map((group: any) => (
                    <AutoComplete.Option key={group.PostalCode} value={group.PostalCode}>
                        <div className="d-flex justify-content-between">
                            <span>{group.PostalCode}</span>
                            <small className="ml-5"><strong>{group.PostalPlace}</strong></small>
                        </div>
                    </AutoComplete.Option>
                )
                ));
        }

    }, [contactCategories, getDebtorContactCategory,newDebtorContactInfo.DebtorCategory, optinos, postalAreas]);

    /** 
     * this is used to load different content to the Drawer(Debtor contact info) 
     * new items are the values for the debtor from (this page) from the other pages.
    */
    const setModelVisibility = (postalDetails: PostalAreaDetails) => {


    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
       
    };

    const handleChange = (value: string) => {}



    function hasErrors(fieldsError: Record<string, string[] | undefined>): boolean {
        if (fieldsError)
            return Object.keys(fieldsError).some((field: any) => fieldsError[field]);
        else
            return false;
    }

    const cancelClickHandler = () => {
    }


    const postCodeInputChange = (inputValue: SelectValue) => {

        const inputNumber = inputValue && Number.parseInt(inputValue.toString());
        if (inputNumber && postalAreas?.length > 0) {
            const matched = postalAreas.filter((po: any) => Number.parseInt(po.PostalCode) === inputNumber);

        
        }
    }

    return (
        <>
            {(function () {
                switch (drawerContent.viewName) {

                    case viewNames.addNewDebtor:
                        return (
                            <>
                                <Breadcrumb>
                                    <Breadcrumb.Item onClick={() => setModelVisibility({ currentStep: viewNames.debtContactInfo, updatedInfo: undefined })}><a>{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.DEBTORCONTACTINFO')}</a></Breadcrumb.Item>
                                    <Breadcrumb.Item >{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.NEWDEBTORCATEGORY')}</Breadcrumb.Item>
                                </Breadcrumb>
                                <NewDebtorCategory
                                    currentStep={viewNames.debtContactInfo}
                                    cancelClick={(values: any) => {}}
                                    drawerContentChangeClick={setModelVisibility}
                                />
                            </>
                        );

                    case viewNames.addPostalArea:
                        return (
                            <>
                                <Breadcrumb className="mb-2">
                                    <Breadcrumb.Item onClick={() => setModelVisibility({ currentStep: viewNames.debtContactInfo, updatedInfo: undefined })}><a>{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.DEBTORCONTACTINFO')}</a></Breadcrumb.Item>
                                    <Breadcrumb.Item onClick={() => setModelVisibility({ currentStep: viewNames.selectPostalArea, updatedInfo: undefined })}><a>{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.SELECTPOSTALAREA')}</a></Breadcrumb.Item>
                                    <Breadcrumb.Item >{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.ADDPOSTALAREA')}</Breadcrumb.Item>
                                </Breadcrumb>
                                {/* <AddPostalArea
                                    currentStep={viewNames.selectPostalArea}
                                    drawerContentChangeClick={setModelVisibility
                                    municipal={{ MunicipalityCode: drawerContent.debtorInfo?.MunicipalityCode, MunicipalityName: drawerContent.debtorInfo?.MunicipalityName }} /> */}

                            </>
                        );

                    case viewNames.selectPostalArea:
                        return (
                            <>
                                <Breadcrumb className="mb-2">
                                    <Breadcrumb.Item onClick={() => setModelVisibility({ currentStep: viewNames.debtContactInfo, updatedInfo: undefined })}><a>{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.DEBTORCONTACTINFO')}</a></Breadcrumb.Item>
                                    <Breadcrumb.Item>{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.SELECTPOSTALAREA')}</Breadcrumb.Item>
                                </Breadcrumb>
                                {/* <SelectPostalArea
                                    currentStep={viewNames.debtContactInfo}
                                    drawerContentChangeClick={setModelVisibility}
                                    currentInfo='test'
                                /> */}

                            </>
                        );

                    case viewNames.selectMinicipality:

                        return (
                            <>
                                {
                                    drawerContent.setp === 1 ?
                                        <>
                                            <Breadcrumb className="mb-2">
                                                <Breadcrumb.Item onClick={() => setModelVisibility({ currentStep: viewNames.debtContactInfo, updatedInfo: undefined })}><a>{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.DEBTORCONTACTINFO')}</a></Breadcrumb.Item>
                                                <Breadcrumb.Item>{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.SELECTMUNICIPALITYNAME')}</Breadcrumb.Item>
                                            </Breadcrumb>
                                            {/* <SelectMunicipal
                                                currentStep={viewNames.debtContactInfo}
                                                drawerContentChangeClick={setModelVisibility}
                                             /> */}

                                        </>
                                        :
                                        <>
                                            <Breadcrumb>
                                                <Breadcrumb.Item onClick={() => setModelVisibility({ currentStep: viewNames.debtContactInfo, updatedInfo: undefined })}><a>{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.DEBTORCONTACTINFO')}</a></Breadcrumb.Item>
                                                <Breadcrumb.Item onClick={() => setModelVisibility({ currentStep: viewNames.selectPostalArea, updatedInfo: undefined })}><a>{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.SELECTPOSTALAREA')}</a></Breadcrumb.Item>
                                                <Breadcrumb.Item onClick={() => setModelVisibility({ currentStep: viewNames.addPostalArea, updatedInfo: undefined })}><a>{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.ADDPOSTALAREA')}</a></Breadcrumb.Item>
                                                <Breadcrumb.Item >{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.SELECTMUNICIPALITYNAME')}</Breadcrumb.Item>
                                            </Breadcrumb>
                                            {/* <SelectMunicipal
                                                currentStep={viewNames.addPostalArea}
                                                drawerContentChangeClick={setModelVisibility}
                                             /> */}
                                        </>
                                }

                            </>
                        );

                    case viewNames.debtContactInfo:
                        if (drawerContent.debtorInfo) {

                         
                        }

                        return (
                            <div>
                                <Form name='newDebtorInfo' >
                                    <h3>{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.GENERAL')}</h3>
                                    <Row gutter={16}>
                                        <Col span={9}>
                                        <Form.Item label={t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.FIRSTNAME')}>
                                               <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item label={t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.LASTNAME')}>
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label={t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.BIRTHDATE')}>
                                                <DatePicker placeholder={currentDateFormat} format={currentDateFormat} size="small" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {/* //////////////////////////////// END OF GENERAL SECTION///////////////////////////////////////////////// */}
                                    <Row gutter={16}>
                                        <Col span={9}>
                                            <h3>{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.CONTACT')}</h3>
                                            <Form.Item label={t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.HOME')}>
                                                <Input size="small" />
                                            </Form.Item>
                                            <Form.Item label={t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.WORK')}>
                                                <Input size="small" />
                                            </Form.Item>
                                            <Form.Item label={t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.MOBILE')}>
                                                <Input size="small" />
                                            </Form.Item>
                                            <Form.Item label={t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.EMAIL')}>
                                                <Input size="small" />
                                            </Form.Item>
                                            <Form.Item label={t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.SKYPE')}>
                                               <Input size="small" />
                                            </Form.Item>
                                            <Form.Item label={t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.MSN')}>
                                                <Input size="small" />
                                            </Form.Item>
                                            <Form.Item label={t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.SMS')}>
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={15} >
                                            <h3>{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.ADDRESS')}</h3>

                                            <Button type="link" onClick={() => setModelVisibility({ currentStep: viewNames.selectPostalArea, updatedInfo: undefined })}><strong>{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.POSTAL')}</strong></Button>
                                            <Input.Group className="d-flex py-2">
                                                <Form.Item className="flex-fill mr-1">
                                                        <AutoComplete
                                                            className="post-code"
                                                            dropdownClassName="post-code-dropdown"
                                                            dropdownMatchSelectWidth={false}
                                                            dropdownStyle={{ minWidth: '100%' }}
                                                            size="small"
                                                            style={{ width: '100%' }}
                                                            placeholder={t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.INPUTHERE')}
                                                            onChange={(value: SelectValue) => postCodeInputChange(value)}
                                                        >
                                                            {optinos}
                                                        </AutoComplete>
                                                </Form.Item>
                                                <Form.Item className="flex-fill">
                                                   <Input size="small" placeholder={t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.AREA')} disabled={true} />
                                                </Form.Item>
                                            </Input.Group>

                                            <Button type="link" onClick={() => setModelVisibility({ currentStep: viewNames.selectMinicipality, updatedInfo: undefined, step: 1 })}><strong>{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.MUNICIPALITY')}</strong></Button>
                                            <Input.Group className="d-flex py-2">
                                                <Form.Item  className="flex-fill mr-1">
                                                   <Input size="small" placeholder={t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.NUMBER')} />
                                                </Form.Item>
                                                <Form.Item className="flex-fill">
                                                    <Input size="small" placeholder={t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.NAME')} disabled={true} />
                                                </Form.Item>
                                            </Input.Group>

                                            {/* end of postal and municipal nested rows */}

                                            <Form.Item label={t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.POSTADDRESS1')}>
                                                <Input size="small" />
                                            </Form.Item>

                                            <Form.Item label={t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.POSTADDRESS2')}>
                                                <Input size="small" />
                                            </Form.Item>

                                            <Form.Item label={t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.POSTADDRESS3')}>
                                                <Input size="small" />
                                            </Form.Item>

                                            <Row gutter={8}>
                                                <Col span={20} >
                                                    <Form.Item label={t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.CONTACTCATEGORY')} >
                                                            <Select style={{ width: 120 }} onChange={handleChange}>
                                                                {categoryListUI}
                                                            </Select>
                                                    </Form.Item>

                                                </Col>
                                                <Col span={4} >
                                                    <Button type="primary" onClick={() => setModelVisibility({ currentStep: viewNames.addNewDebtor, updatedInfo: undefined })}>{t('US.COLLECTION.DEBTOR:CONTACTINFOFORM.ADDNEW')}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <div className="d-flex justify-content-end">
                                        <Button className="mr-2" type="primary" htmlType="submit" >{t('US.COLLECTION.COMMON:COMMON.SAVE')}</Button>
                                        <Button onClick={() => cancelClickHandler()}>{t('US.COLLECTION.COMMON:COMMON.CANCEL')}</Button>
                                    </div>
                                </Form>

                            </div>
                        );

                    default:
                        return null;

                }
            })()}

        </>
    )
}


const mapStateToProps = (state: IRootState) => {
    const {common, entity, debtor} = state
    return {
        contactCategories: debtor.contactCategories,
        postalAreas: entity.postalAreas,
        currentDateFormat: common.currentDateFormat
    };
};

const mapDispatchToProps = {
    getDebtorContactCategory,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DebtorContactInfoForm);