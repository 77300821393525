export enum FollowUpEntityType {
  CASE = "Case",
  INVOICE = "Invoice",
  CREDITOR = "Creditor",
  DEBTOR = "Debtor",
  PAYMENT = "Payment",
  SUBCASE = "SubCase"
}

export enum FollowUpEntityTypeBadge {
  CASE = "Ca",
  INVOICE = "In",
  CREDITOR = "Cr",
  DEBTOR = "De",
  PAYMENT = "Pa",
}

export enum UrlModule {
  CASE = "case",
  CREDITOR = "creditor",
  AR = "ar",
  PAYMENT = "payment",
}

export enum FollowUpUniqueCol {
  FOLLOWUP_ID = "Followupid",
  EX_CASE_NO = "Excaseno",
  CASE_NO = "Caseno",
  CREDITOR_NO = "Creditorno",
  AR_NO = "Arno",
  PAYMENT_ID = "Paymentid",
  PAYMENT_NO = "Paymentno",
  WORKFLOW_STATE = "Workflowstate",
  FOLLOWUP_CASE_STATE = "Followupcasestate",
  CASE_TYPE = "Casetype",
  CASE_HANDLER = "Casehandler",
  COUNTRY_ID = "countryid",
  REASON = "reason",
  COMMENT = "comment",
  NOTE = "note",
  BALANCE = "balance",
  AMOUNT = "amount",
  MORE = "more",
  INFO = "info",
  TYPE = "type",
  FINAL_COL = "finalCol",
  LAST_ACTION_NAME = "Lastactionname",
  LAST_ACTION_STATUS = 'Lastactionstatus',
  LAST_ACTION_DATE = 'Lastactiondate',
  LAST_ACTION_USER = 'Lastactionuser'
}

export type AllFollowUpEntityTypeInterface =
  | FollowUpEntityType.CASE
  | FollowUpEntityType.INVOICE
  | FollowUpEntityType.CREDITOR
  | FollowUpEntityType.DEBTOR
  | FollowUpEntityType.PAYMENT
  | FollowUpEntityType.SUBCASE
  | "";

export type FollowUpEntityTypeInterface =
  | FollowUpEntityType.CASE
  | FollowUpEntityType.INVOICE
  | "";

export type FollowUpEntityTypeBadgeInterface =
  | FollowUpEntityTypeBadge.CASE
  | FollowUpEntityTypeBadge.INVOICE
  | FollowUpEntityTypeBadge.DEBTOR
  | FollowUpEntityTypeBadge.CREDITOR
  | FollowUpEntityTypeBadge.PAYMENT
  | "";

export type UrlModuleInterface =
  | UrlModule.CASE
  | UrlModule.AR
  | UrlModule.CREDITOR
  | UrlModule.PAYMENT
  | "";

export type FollowUpUniqueColInterface =
  | FollowUpUniqueCol.CASE_NO
  | FollowUpUniqueCol.AR_NO
  | FollowUpUniqueCol.CREDITOR_NO
  | FollowUpUniqueCol.PAYMENT_ID
  | "";
