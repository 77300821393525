import { appInsights } from 'us.helper';
import { TransactionNavigate } from 'us.collection.case/constants';
import {
	PopOverDataType,
	DependencyTypes,
	Conditions,
} from 'us.common/components/DynamicPopOver/Constants';
import {
	TransactionFilterTypes,
	PopOverMenuTypes,
} from 'us.collection.transactions/components/Transactions/Constants';
import _ from 'lodash';
import {
	IMetaData,
	IParameter,
} from 'us.common/components/DynamicPopOver/Interface';
import { MappingTransaction } from 'us.collection.transactions/components/ArTransactions/Interfaces';
import { CaseType } from 'us.helper/types/enums';
import { InterestType } from 'us.collection.transactions/constants';

export const transGroupByTransType = (type: string = 'all') => {
	let transGroup: string = 'All';
	switch (type) {
		case TransactionNavigate.IN:
			transGroup = TransactionFilterTypes.INVOICE;
			break;
		case TransactionNavigate.CF:
			transGroup = TransactionFilterTypes.COLLECTION;
			break;
		case TransactionNavigate.COURT_FEE:
			transGroup = TransactionFilterTypes.COURT;
			break;
		case TransactionNavigate.CASE_COST:
			transGroup = TransactionFilterTypes.COST;
			break;
		case TransactionNavigate.OTHER_COST:
			transGroup = TransactionFilterTypes.OTHER;
			break;
		case TransactionNavigate.ALL:
			transGroup = 'All';
			break;
		default:
			transGroup = 'All';
			break;
	}
	return transGroup;
};

export const getSum = (list: Array<any>, field: string) => {
	try {
		if (list.length != 0) {
			return list.reduce((acc, item) => acc + item[field], 0);
		} else {
			return 0;
		}
	} catch (error) {
		appInsights.trackException(
			`Calculate Sum of column ${field} out of Transactions Exception - ${error}`
		);
		return 0;
	}
};

/**
 * @method
 * @description Update values for the dynamic pop over component
 * @param menuItems - Existing menu item array.
 * @param values - Array that contain values.
 * @returns Updated pop over content data.
 */
export const mapPopOverValues = (
	menuItems: any[],
	values: any[],
	activityCode: PopOverMenuTypes
): Array<any> => {
	try {
		const menuItem: IMetaData = _.find(menuItems, { activityCode });
		if (Array.isArray(menuItem?.parameters)) {
			const parameters = menuItem.parameters.map((param: IParameter) => {
				const { paramName, paramType } = param;
				const property = _.find(values, {
					propertyName: `@${paramName}`,
				});
				if (property) {
					if (property?.propertyName == `@${paramName}`) {
						return {
							...param,
							paramValue:
								paramType == PopOverDataType.BIT
									? property.propertyValue == 'true'
										? true
										: false
									: property.propertyValue,
							dependency: !property.isEnabled
								? [
										{
											paramName: [paramName],
											dependencyType: DependencyTypes.DISABLE,
											condition: Conditions.FALSE,
										},
								  ]
								: param?.dependency,
						};
					} else {
						return { ...param };
					}
				} else {
					return { ...param };
				}
			});

			const newMenuItems = menuItems.map((item: IMetaData) => {
				if (item?.activityCode == activityCode) {
					return { ...item, parameters };
				} else {
					return { ...item };
				}
			});
			return newMenuItems;
		} else {
			return menuItems;
		}
	} catch (e) {
		return menuItems;
	}
};

/**
 * It takes an array of objects, and returns the same array of objects with an additional property
 * added to each object.
 * @param {MappingTransaction[]} mappingTransactions - MappingTransaction[]
 * @returns An array of objects with the newBalance property added to each object.
 */
export const prepareTransactionsForMapping = (
	mappingTransactions: MappingTransaction[]
): MappingTransaction[] => {
	try {
		return mappingTransactions.map((transaction: MappingTransaction) => {
			return Object.assign(transaction, {
				newBalance: transaction.balance,
			});
		});
	} catch (error) {
		return mappingTransactions;
	}
};

/**
 * @description - Get transaction interest type name by interest type
 * @param {InterestType} interestType - interest type
 * @returns {string}
 */
export const getInterestTypeName = (interestType: InterestType): string => {
	switch (interestType) {
		case InterestType.STANDARD_INTEREST:
			return 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.STANDARD';
		case InterestType.FIXED_INTEREST:
			return 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.FIXED_INTEREST';
		case InterestType.NO_INTEREST:
			return 'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.NO_INTEREST';
		default:
			return '';
	}
};
