
import { IOtherInformation } from './../../../Interfaces/OtherInformation';
import moment from 'moment';

export const otherInformation: IOtherInformation = {
    branch: '',
    collectionOpen: '',
    countryCode: '',
    countryName: '',
    debtorNo: 0,
    email: '',
    fullName: '',
    firstName: '',
    lastName: '',
    homePhoneNumber: '',
    income: '',
    lastContactDate: moment('0001-01-01T00:00:00'),
    lastLoginPortal: '',
    lastPaymentDate: moment('0001-01-01T00:00:00'),
    mobileNumber: '',
    msn: '',
    personNo: 0,
    payment: '',
    postalAddress1: '',
    postalAddress2: '',
    postalAddress3: '',
    score: '',
    skype: '',
    smsNumber: '',
    usPaymentOpen: '',
    visitAddress: '',
    workfPhoneNumber: '',
    birthDate: moment(),
    debtorEntNo: 0,
    debtorEntRoleId: 0,
    addressNo: '',
    fax: '',
    postalCode: 0,
    postalName: '',
    municipalityCode: '',
    municipalityName: '',
    title: '',
    debtorType: '',
    isAddressknown: true,
    isBadDebtor: false,
    badDebtReason: ''
  
}