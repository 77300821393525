import { IAPIAction } from "us.collection/interfaces";
import { MessageTemplate } from "us.collection.case/constants";
import { IMessageTemplateAction } from "./Interface";
const {
  GET_MESSAGE_TEMPLATES_START,
  GET_MESSAGE_TEMPLATES_FAIL,
  GET_MESSAGE_TEMPLATES_SUCCESS,
  DELETE_MESSAGE_TEMPLATE_START,
  DELETE_MESSAGE_TEMPLATE_FAIL,
  DELETE_MESSAGE_TEMPLATE_SUCCESS,
  GET_MESSAGE_TEMPLATE_BY_ID_START,
  GET_MESSAGE_TEMPLATE_BY_ID_SUCCESS,
  GET_MESSAGE_TEMPLATE_BY_ID_FAIL,
  MANAGE_PREVIEW_EDIT_DRAWER,
  EXECUTE_MESSAGE_TEMPLATE_FAIL,
  EXECUTE_MESSAGE_TEMPLATE_SUCCESS,
  EXECUTE_MESSAGE_TEMPLATE_START,
  SEND_EMAIL_START,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAIL,
  MANAGE_SEND_EMAIL_DRAWER,
  UPDATE_IFRAME_CONTENT,
  PIN_UNPIN_MESSAGE_TEMPLATE,
  PIN_UNPIN_MESSAGE_TEMPLATE_SUCCESS,
  PIN_UNPIN_MESSAGE_TEMPLATE_FAIL,
} = MessageTemplate;

export const messageTemplateAction: Readonly<
  IAPIAction & IMessageTemplateAction
> = {
  /**
   * @namespace MessageTempaltes
   */
  messageTemplates: {
    /**
     * Get all message templates start action
     */
    get: (data) => ({
      type: GET_MESSAGE_TEMPLATES_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    /**
     * Get all message templates success action
     */
    success: (data) => ({
      type: GET_MESSAGE_TEMPLATES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    /**
     * Get all message templates fail action
     */
    fail: (error) => ({
      type: GET_MESSAGE_TEMPLATES_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  /**
   * Delete message template
   */
  deleteMessageTempale: {
    delete: (data) => ({
      type: DELETE_MESSAGE_TEMPLATE_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: DELETE_MESSAGE_TEMPLATE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: DELETE_MESSAGE_TEMPLATE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  /**
   * Get message template by id
   */
  messageTemplate: {
    get: (data) => ({
      type: GET_MESSAGE_TEMPLATE_BY_ID_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_MESSAGE_TEMPLATE_BY_ID_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_MESSAGE_TEMPLATE_BY_ID_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    previewEditDrawer: (data) => ({
      type: MANAGE_PREVIEW_EDIT_DRAWER,
      payload: {
        data,
        isLoading: true,
      },
    }),
    updateContent: (data) => ({
      type: UPDATE_IFRAME_CONTENT,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  /**
   * Execute activity
   */
  messageTemplateActivity: {
    execute: (data) => ({
      type: EXECUTE_MESSAGE_TEMPLATE_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: EXECUTE_MESSAGE_TEMPLATE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: EXECUTE_MESSAGE_TEMPLATE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  /**
   * Email send
   */
  email: {
    send: (data) => ({
      type: SEND_EMAIL_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SEND_EMAIL_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SEND_EMAIL_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    openDrawer: (data) => ({
      type: MANAGE_SEND_EMAIL_DRAWER,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },

  pinUnpinTemplate: {
    send: (data) => ({
      type: PIN_UNPIN_MESSAGE_TEMPLATE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: PIN_UNPIN_MESSAGE_TEMPLATE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: PIN_UNPIN_MESSAGE_TEMPLATE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
