import { versionedHttpCollection } from 'us.helper/http/collection';
import ServiceConfig from 'service.config.js';

export default {
	parameters: {
		get: <
			P extends {
				activityId: string;
				state: string;
			}
		>(
			params: P
		): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data } =
						await versionedHttpCollection.get(
							'activityservice',
							`activities/${params.activityId}/${params.state}/settings`,
							{},
							ServiceConfig()[
								`activityservice`
							]['activities.settings']
						);
					resolve(data);
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	activity: {
		execute: (params: any): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data } =
						await versionedHttpCollection.post(
							'activityservice',
							'activities',
							params,
							ServiceConfig()[
								`activityservice`
							]['activities']
						);
					resolve(data);
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	activities: {
		get: <
			P extends {
				entitytype: string;
				entitystate: string;
				canExecute: boolean;
			}
		>(
			params: P
		): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'activityservice',
							`activities`,
							params,
							ServiceConfig()[
								`activityservice`
							]['activities']
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	pendingActivities: {
		execute: <
			P extends {
				entityId: number | string;
				entityType: string;
			}
		>(
			params: P
		): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data } =
						await versionedHttpCollection.post(
							'activityservice',
							'pendingactivities',
							params,
							ServiceConfig()[
								`activityservice`
							]['pendingactivities']
						);
					resolve(data);
				} catch (error) {
					reject(error);
				}
			});
		},
	},
};
