import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from "service.config.js";
import { ICaseInformation } from "us.collection.case/reducers/CaseInformation/Interfaces";

export default {
  Information: {
    get: async (caseId: number): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.getById(
            "arservice",
            "ars",
            `${caseId}/information`,
            ServiceConfig()[`arservice`]["ars.caseInformation"]
          );
          if (status === 200 || status === 404) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },

    save: async (caseInfo: Partial<ICaseInformation>): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.post(
            "arservice",
            `ars/${caseInfo.caseId}/information`,
            caseInfo,
            ServiceConfig()[`arservice`]["ars.caseInformation"]
          );
          if (status === 201) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },

  ObsoleteInterest: {
    get: async (caseId: number): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.getById(
            "arservice",
            "ars",
            `${caseId}/interestoccurrences`,
            ServiceConfig()[`arservice`]["interestoccurrences.get"]
          );
          if (status === 200 || status === 404) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    save: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.post(
            "arservice",
            `ars/${params.caseId}/interestoccurrences`,
            params,
            ServiceConfig()[`arservice`]["interestoccurrences.save"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    delete: async (occurrenceId: number): Promise<any> => {
            
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.delete(
            "arservice",
            `ars`,
            `${occurrenceId}/interestoccurrences`,
            {},
            ServiceConfig()[`arservice`]["interestoccurrences.delete"]
          );
          if (status === 202 || status === 404) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
