import * as Yup from "yup";
import { WorkflowDrawerActionType } from "us.collection/constants";

interface IStateValidation extends Yup.MixedSchema {
  stateGroupName: string;
  name: string;
  displayName: string;
  workflowType: string;
  dueInDays: number;
  adapterName: string;
  stateType: string;
  activeStatus: boolean;
  description: string;
}
const StateValidation = Yup.object<IStateValidation>().shape({
  stateGroupName: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
  ),
  name: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
  ),
  displayName: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
  ),
  dueInDays: Yup.number()
    .integer("US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_AN_INTEGER_VALUE")
    .nullable()
    .required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
    ),
  adapterName: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
  ),
  workflowType: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
  ),
});

export default StateValidation;
