import { CaseType } from "us.helper/types/enums";

export function AuthorityComment(this: any) {
  return {
    entityId: this.caseNo,
    entityType: this.caseType === CaseType.C ? "Case" : "SubCase",
    applicationName: "UnicornBOX4.0",
    userEvent: "CaseDashBoard",
    comment: this.data.comment.trim(),
    isCommentEnable: this.isEnable,
  };
}
