export enum FollowUpAction {
  POSTPONE = "POSTPONE",
  REMOVE = "REMOVE",
  DONE = "DONE",
  UNDO = "UNDO"
}

export enum ReduxAction {
  START,
  SUCCESS,
  FAIL
}