import Constants from "us.collection.transactions/constants";
import { IAPIAction } from "us.collection/interfaces";

const { ACTIONS } = Constants;
const { Apportionment } = ACTIONS;

export const ApportionmentAction: Readonly<IAPIAction> = {
  Apportionments: {
    get: (data) => ({
      type: Apportionment.GET_APPORTIONMENTS,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Apportionment.GET_APPORTIONMENTS_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Apportionment.GET_APPORTIONMENTS_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
};
