export enum AssetTypes {
  NO_ASSET = "No Asset",
  REAL_ESTATE = "Real Estate",
  CERTIFICATE_OF_DEBT = "Certificate of debt",
  PERSONAL_EFFECT = "Personal Effect",
  EMPLOYER = "Employer",
  VEHICLE = "Vehicle",
  DOCUMENT_OF_TITLE = "Document of Title",
  FINANCIAL = "Financial",
}

export enum EntityType {
  DEBTOR = "DEB",
  CREDITOR = "CRE"
}
