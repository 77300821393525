export const initialData = {
    entRoleId: -1,
    otherpartyType: "P",
    emailAddress: "",
    personNo: "",
    organizationNo: "",
    otherpartyRoleType: null,
    newOtherPartyRoleType: "",
    birthDate: "",
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    address3: "",
    telephone: "",
    reference: "",
    gender: "",
    zipCode: "",
    zipName: "",
    countyId: "",
    country: "NO",
    smsNo: "",
    isDefault: true,
    isAddressKnown: false,
    nin:"",
    isCreditorManagement: false
  };

  export enum MainTab {
    SUBCASE = "S",
    CASE = "C",
    AR = "A",
    CREDITOR = "Creditor"
  }