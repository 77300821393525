import React from "react";
import { IAsyncRoute } from "us.collection/interfaces";

export const Containers: Array<IAsyncRoute> = [
  {
    Container: React.lazy(() => import("../containers/Transactions")),
    Route: "transactions",
  },
];

export const TransactionContainers: Array<IAsyncRoute> = [
  {
    Container: React.lazy(() => import("../containers/PaymentDistrubution")),
    Route: "distribution",
  },
  {
    Container: React.lazy(
      () => import("us.collection.routines-and-activities/containers/RoutinesAndActivities")
    ),
    Route: "history",
  },
  {
    Container: React.lazy(() => import("us.collection.case/containers/Assets")),
    Route: "assets",
  },
  {
    Container: React.lazy(() => import("../containers/PaymentDistrubution")),
    Route: "",
  },
];

export const ArContainers: Array<IAsyncRoute> = [
  {
    Container: React.lazy(() => import("../containers/ArTransactions/Home")),
    Route: "transactions",
  },
  {
    Container: React.lazy(
      () => import("us.collection.routines-and-activities/containers/RoutinesAndActivities")
    ),
    Route: "history",
  },
  {
    Container: React.lazy(() => import("us.collection.case/containers/MessageTemplates")),
    Route: "message-templates",
  },
  {
    Container: React.lazy(() => import("../containers/CaseDocuments/Home")),
    Route: "documents",
  },
  {
    Container: React.lazy(() => import("../containers/ApplicableCourts/Home")),
    Route: "applicable-courts",
  },
  {
    Container: React.lazy(() => import("../containers/CasesAndSubcases")),
    Route: "cases-and-subcases",
  },
  {
    Container: React.lazy(() => import("us.collection.case/containers/OtherParty")),
    Route: "other-party",
  },
  {
    Container: React.lazy(() => import("us.collection.case/containers/Assets")),
    Route: "assets",
  },
  {
    Container: React.lazy(() => import("../containers/ArNotes/ArNotes")),
    Route: "notes",
  },
  {
    Container: React.lazy(() => import("us.collection/containers/BMD")),
    Route: "bmd",
  },
  {
    Container: React.lazy(() => import("../containers/CasesAndSubcases")),
    Route: "",
  },
];
