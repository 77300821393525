import { IMetaData } from 'us.collection/interfaces';
import { isEnableInterestApplication } from 'us.collection.transactions/components/TransactionDetails/Functions';
import { CaseType } from 'us.helper/types/enums';
import {
	InterestType,
	TransactionType,
} from 'us.collection.transactions/constants';

export function Transaction(
	this: any,
	transactionTypes: any,
	other?: IMetaData
) {
	const { entityType, caseId, arId, cid, pid } = other ?? {};
	const { type } = this ?? {};
	const enabledInterestApplication = isEnableInterestApplication(
		type,
		transactionTypes
	);

	return {
		caseNo:
			this.caseNo != ''
				? this.caseNo
				: entityType === CaseType.C
				? caseId
				: 0,
		subCaseNo:
			this.subCaseNo != 0
				? this.subCaseNo
				: entityType === CaseType.S
				? caseId
				: 0,
		arNo: arId,
		arState: '0',
		transType:
			this.cancellationType != 0
				? this.cancellationType
				: type,
		transText: this.text,
		cid: cid,
		pid: pid,
		voucherDate: this.voucherDate,
		dueDate: this.dueDate,
		amount: this.amount,
		invoiceNo: this.invoiceNumber,
		apportionStatus: '0',
		voucherNo: this.voucherID,
		interestFromDate: Object.values(TransactionType).includes(type)
			? this.interestFromDate
			: null,
		interestRate: enabledInterestApplication
			? this.interestRate
			: null,
		interestType: enabledInterestApplication
			? this.interestType
			: InterestType.NO_INTEREST,
	};
}

export function InterestRate(this: number) {
	return [
		{
			entityId: this,
			entityType: 'CA',
			categoryName: 'InterestData',
			bmdName: 'InterestProperties',
			displayKeyName: 'InterestRate',
		},
	];
}
