import { ApplicableCourt } from "us.collection.transactions/constants/Actions";
import { IAPIAction } from "us.collection/interfaces";

export const applicableCourts: Readonly<IAPIAction> = {
  courts: {
    get: (data) => ({
      type: ApplicableCourt.GET_APPLICABLE_COURT,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ApplicableCourt.GET_APPLICABLE_COURT_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ApplicableCourt.GET_APPLICABLE_COURT_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
};
