import { put, call, takeLatest, select } from "redux-saga/effects";
import * as API from "us.collection.case/services";
import { Enforcement } from "us.collection.case/constants/Actions";
import * as Actions from "us.collection.case/actions";
import { AssetsAction } from "us.collection.case/actions";
import { $MessageBox } from "us.common/components";
import { IRootState } from "us.collection/interfaces";
import _ from "lodash";
import { AssetTypes } from "us.collection.case/constants";
import { appInsights } from "us.helper";

const { enforcements } = Actions;

const getOriginalEnforcedItems = (state: IRootState) =>
  state.enforcement.enforcementDetail?.data?.enforcedItems;
const getSalaryDeductionPlan = (state: IRootState) =>
  state.enforcement.salaryDeductionPlan?.data;
const getDeletedSalaryDeductionPlan = (state: IRootState) =>
  state.enforcement.deletedSalaryDeductionPlan?.data;

const enforcementSagas = {
  enforcements: {
    get: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.enforcements.enforcements.get, params);
        yield put(enforcements.enforcements.success(data));
        // if (data?.statusCode === 200) {
        //     yield put(EnforcementAction.enforcements.success(data))
        // } else {
        //     yield put(EnforcementAction.enforcements.fail({}))
        // }
      } catch (error) {
        appInsights.trackException(
          `Get Enforcement list Saga Exception - ${error}`
        );
        yield put(enforcements.enforcements.fail(error as any));
      }
    },
  },
  enforcement: {
    save: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        if (params.enforcementId != -1) {
          const initialEnforcedItems = yield select(getOriginalEnforcedItems);
          const deletedEnforcementItems = _.filter(
            initialEnforcedItems,
            (item: any) => {
              return (
                item?.enforcementItemId !=
                _.find(params?.assets, {
                  enforcementItemId: item?.enforcementItemId,
                })?.enforcementItemId
              );
            }
          ).map((deletedItem: any) => {
            return { ...deletedItem, isDeleted: true };
          });

          params.assets = [...params?.assets, ...deletedEnforcementItems];
          if (
            _.find(
              deletedEnforcementItems,
              (deletedEnforcementItem: any) =>
                deletedEnforcementItem.assetType == AssetTypes.EMPLOYER &&
                deletedEnforcementItem.enforcementItemId != -1
            )
          ) {
            const deletedSalaryDeductionPlan = yield select(
              getDeletedSalaryDeductionPlan
            );
            const data = yield call(
              API.enforcements.salaryDeductionPlan.delete,
              deletedSalaryDeductionPlan
            );
          }
        }
        const data = yield call(API.enforcements.enforcement.save, params);
        if (data?.hasOwnProperty("enforcementId") && !data?.isError) {
          yield put(enforcements.enforcement.success(data));
          $MessageBox(
            "success",
            params?.enforcementId == -1
              ? `US.COLLECTION.CASE:ENFORCEMENTS.SUCCESSFULLY_SAVED`
              : `US.COLLECTION.CASE:ENFORCEMENTS.SUCCESSFULLY_EDITED`,
            "",
            ""
          );
          const salaryDeductionPlan = yield select(getSalaryDeductionPlan);
          if (salaryDeductionPlan.caseId > 0) {
            const { salaryDeductionSchema } = yield call(
              API.enforcements.salaryDeductionPlan.save,
              {
                ...salaryDeductionPlan,
                enforcementId: data?.enforcementId,
                saveToDB: true,
              }
            );
            if (salaryDeductionSchema) {
              if (enforcements.salaryDeductionPlan.saveSuccess) {
                yield put(
                  enforcements.salaryDeductionPlan.saveSuccess(
                    salaryDeductionSchema
                  )
                );
              }
            } else {
              if (enforcements.salaryDeductionPlan.saveFail) {
                yield put(enforcements.salaryDeductionPlan.saveFail({}));
              }
            }
          }
          if (enforcements.enforcement.manageDrawer) {
            yield put(
              enforcements.enforcement.manageDrawer({
                title: "",
                visible: false,
                isNewEnforcement: true,
              })
            );
          }
          if (enforcements.enforcements.get) {
            yield put(
              enforcements.enforcements.get({ caseId: params?.caseId })
            );
          }
        } else {
          yield put(enforcements.enforcement.fail({}));
          $MessageBox(
            "error",
            `US.COLLECTION.CASE:ENFORCEMENTS.SAVING_FAILED`,
            "",
            ""
          );
        }
      } catch (error) {
        appInsights.trackException(
          `Save Enforcement Saga Exception - ${error}`
        );
        if (enforcements.enforcement.deleteFail) {
          yield put(enforcements.enforcement.deleteFail(error as any));
        }
      }
    },
    delete: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.enforcements.enforcement.delete, params);
        if (data?.hasOwnProperty("enforcementId") && !data?.isError) {
          if (enforcements.enforcement.deleteSuccess) {
            yield put(enforcements.enforcement.deleteSuccess(params));
          }
          $MessageBox(
            "success",
            `US.COLLECTION.CASE:ENFORCEMENTS.SUCCESSFULLY_DELETED`,
            "",
            ""
          );
        } else {
          if (enforcements.enforcement.deleteFail) {
            yield put(enforcements.enforcement.deleteFail({}));
          }
          $MessageBox(
            "error",
            `US.COLLECTION.CASE:ENFORCEMENTS.DELETION_FAILED`,
            "",
            ""
          );
        }
      } catch (error) {
        appInsights.trackException(
          `Delete Enforcement Saga Exception - ${error}`
        );
        if (enforcements.enforcement.deleteFail) {
          yield put(enforcements.enforcement.deleteFail(error as any));
        }
      }
    },
  },
  types: {
    getWithAssets: function* (action: any): any {
      const params = action.payload.data;
      try {
        const { enforcementTypes, assets } = yield call(
          API.enforcements.types.getWithAssets,
          params
        );

        if (Array.isArray(assets)) {
          yield put(AssetsAction.Assets.success(assets));
        }
        if (enforcementTypes) {
          yield put(enforcements.types.success(enforcementTypes));
        }
      } catch (error) {
        appInsights.trackException(
          `Get Enforcement Types with assets Saga Exception - ${error}`
        );
        console.error(error);
      }
    },
  },
  salaryDeductionPlan: {
    save: function* (action: any): any {
      const params = action.payload.data;
      try {
        const { salaryDeductionSchema } = yield call(
          API.enforcements.salaryDeductionPlan.save,
          params
        );

        if (salaryDeductionSchema) {
          if (enforcements.salaryDeductionPlan.saveSuccess) {
            yield put(
              enforcements.salaryDeductionPlan.saveSuccess(
                salaryDeductionSchema
              )
            );
          }
        } else {
          if (enforcements.salaryDeductionPlan.saveFail) {
            yield put(enforcements.salaryDeductionPlan.saveFail({}));
          }
        }
      } catch (error) {
        appInsights.trackException(
          `Save Salary Deduction Plan Saga Exception - ${error}`
        );
        if (enforcements.salaryDeductionPlan.saveFail) {
          yield put(enforcements.salaryDeductionPlan.saveFail(error as any));
        }
      }
    },
    get: function* (action: any): any {
      const params = action.payload.data;
      try {
        const { salaryDeductionPlan } = yield call(
          API.enforcements.salaryDeductionPlan.get,
          params
        );

        if (salaryDeductionPlan) {
          yield put(
            enforcements.salaryDeductionPlan.success(salaryDeductionPlan)
          );
        } else {
          yield put(enforcements.salaryDeductionPlan.fail({}));
        }
      } catch (error) {
        appInsights.trackException(
          `Get Salary Deduction Plan Saga Exception - ${error}`
        );
        yield put(enforcements.salaryDeductionPlan.fail(error as any));
      }
    },
  },
  accountSummary: {
    get: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.enforcements.accountSummary.get, params);
        yield put(enforcements.accountSummary.success(data));
      } catch (error) {
        appInsights.trackException(
          `Enforcement account summary Saga Exception - ${error}`
        );
        yield put(enforcements.accountSummary.fail(error as any));
      }
    },
  },
};
export default [
  takeLatest(
    Enforcement.GET_ENFORCEMENTS_START,
    enforcementSagas.enforcements.get
  ),
  takeLatest(Enforcement.SAVE_ENFORCEMENT, enforcementSagas.enforcement.save),
  takeLatest(
    Enforcement.DELETE_ENFORCEMENTS_START,
    enforcementSagas.enforcement.delete
  ),
  takeLatest(
    Enforcement.GET_ENFORCEMENT_TYPES,
    enforcementSagas.types.getWithAssets
  ),
  takeLatest(
    Enforcement.SAVE_SALARY_DEDUCTION_PLAN,
    enforcementSagas.salaryDeductionPlan.save
  ),
  takeLatest(
    Enforcement.GET_SALARY_DEDUCTION_PLAN,
    enforcementSagas.salaryDeductionPlan.get
  ),
  takeLatest(
    Enforcement.GET_ENFORCEMENT_ACCOUNT_SUMMARY,
    enforcementSagas.accountSummary.get
  ),
];
