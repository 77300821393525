export function SaveCloseCase(this: any) {
  const { reason, comment } = this.values;
  return [
    {
      op: "add",
      path: "/closeState",
      value: {
        closeReasonId: reason,
        name: this.reasonData?.name ?? "",
        comment: comment,
      },
    },
  ];
}
