import moment from "moment";

export function SaveCalendar(this: any) {
  return {
    calendarId: this.calendarId ?? -1,
    calendarName: this.calendarName,
    nonProductionDays: this.nonProductionDays.map((nonProdDay:any) => {
      return {...nonProdDay, date: moment(nonProdDay?.date).utcOffset(0, true)}
    }),
    smsSchedule: this.smsAllowedTime?.filter((day: any) => day.isEnable == true).map((day:any) => {
      return {...day, timeSlots: day.timeSlots.map((slot: any) => {
        return {...slot, startTime: moment(slot.startTime).utcOffset(0, true), endTime: moment(slot.endTime).utcOffset(0, true)}
      })}
    })
  };
}
