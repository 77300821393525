import React from "react";
import { ToggleClass } from "us.helper";
import { CaseDocumentsHome } from "us.collection.documents/components";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";

interface ICnt_CaseDocuments extends RouteComponentProps {
  caseNo: number;
}

const Cnt_CaseDocuments: React.FC<ICnt_CaseDocuments> = (props) => {
  ToggleClass("change-body-color-dom");

  return <CaseDocumentsHome {...props}></CaseDocumentsHome>;
};

export default withRouter(Cnt_CaseDocuments);
