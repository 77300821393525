import { ISplitCaseRequestObject } from "../Interfaces";

/**
 * @function
 * @description - repository to get the API request object for spliting case
 * @param {*} this - any type object
 * @return {*} - API calling object
 */
export function GetSplitCaseRequestObject(this: any): ISplitCaseRequestObject {
  return {
    arNo: this.arId,
    caseNo: this.parentId,
    creditorId: this.creditorId,
    debtorId: this.debtorId,
    subcases: [this.caseId],
  };
}
