import { IAPIAction } from "us.collection/interfaces";
import { IManageCreditors } from "us.collection.creditor/interfaces";
import { ManageCreditor } from "us.collection.creditor/constants/Actions/ManageCreditors";

export const manageCreditors: Readonly<IAPIAction & IManageCreditors> = {
  postalAreas: {
    get: (data) => ({
      type: ManageCreditor.GET_POSTAL_AREAS_BY_CREDITOR,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManageCreditor.GET_POSTAL_AREAS_BY_CREDITOR_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManageCreditor.GET_POSTAL_AREAS_BY_CREDITOR_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  belongsToCreditor: {
    get: (data) => ({
      type: ManageCreditor.GET_BELONGS_TO_CREDITOR,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManageCreditor.GET_BELONGS_TO_CREDITOR_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManageCreditor.GET_BELONGS_TO_CREDITOR_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
    restWithoutParams: () => ({
      type: ManageCreditor.RESET_BELONGS_TO_CREDITOR,
      payload: {
        isLoading: true,
      },
    }),
  },
  newCreditor: {
    save: (data) => ({
      type: ManageCreditor.ADD_NEW_CREDITOR,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManageCreditor.ADD_NEW_CREDITOR_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManageCreditor.ADD_NEW_CREDITOR_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  nextCreditorNo: {
    getWithoutParams: () => ({
      type: ManageCreditor.GET_NEXT_CREDITOR_NO,
      payload: {
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManageCreditor.GET_NEXT_CREDITOR_NO_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManageCreditor.GET_NEXT_CREDITOR_NO_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  creditorNoValidateStatus: {
    getById: (data) => ({
      type: ManageCreditor.GET_CREDITOR_NO_VALIDATION_STATUS,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManageCreditor.GET_CREDITOR_NO_VALIDATION_STATUS_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManageCreditor.GET_CREDITOR_NO_VALIDATION_STATUS_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  caseHandlers: {
    getWithoutParams: () => ({
      type: ManageCreditor.GET_CASE_HANDLERS,
      payload: {
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManageCreditor.GET_CASE_HANDLERS_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManageCreditor.GET_CASE_HANDLERS_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  existingCreditor: {
    update: (data) => ({
      type: ManageCreditor.UPDATE_NEW_CREDITOR,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManageCreditor.UPDATE_NEW_CREDITOR_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManageCreditor.UPDATE_NEW_CREDITOR_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
};
