import { ObsoleteDate } from 'us.collection/constants/ObsoleteDateActions';
import { IInitialState } from './Interfaces';
import { initialState } from './State';

const {
	ADD_OBSOLETE_DATE_STRAT,
	ADD_OBSOLETE_DATE_SUCCESS,
	ADD_OBSOLETE_DATE_FAIL,
} = ObsoleteDate;

export default (state: IInitialState = initialState, action: any) => {
	switch (action.type) {
		case ADD_OBSOLETE_DATE_STRAT:
			return Object.assign({}, state, {
				data: undefined,
				isError: false,
				isLoading: true,
			});

		case ADD_OBSOLETE_DATE_SUCCESS:
			return Object.assign({}, state, {
				data: action.payload.data,
				isError: false,
				isLoading: false,
			});
		case ADD_OBSOLETE_DATE_FAIL:
			return Object.assign({}, state, {
				data: undefined,
				isError: true,
				isLoading: false,
			});
		default:
			return state;
	}
};
