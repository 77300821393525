import caseSagas from './case';
import {
	dashboard,
	messageAndNotes,
	objectionAndAgreements,
} from './Dashboard';
import { otherPartySagas } from './OtherParty';
import { interestSagas } from './Interest';
import { ExtendedFieldSagas } from './ExtendedField';
import { CaseInformationSagas } from './CaseInformation';
import { AssetsSagas } from './Assets';
import { enforcementSagas } from './Enforcement';
import { messageTemplateSagas } from './MessageTemplate';
import { CourtSentenceSagas } from './CourtSentence';
import { manualFollowUpSagas } from './ManualFollowUps';

export default [
	...caseSagas,
	...dashboard,
	...otherPartySagas,
	...messageAndNotes,
	...interestSagas,
	...objectionAndAgreements,
	...ExtendedFieldSagas,
	...CaseInformationSagas,
	...AssetsSagas,
	...enforcementSagas,
	...messageTemplateSagas,
	...CourtSentenceSagas,
	...manualFollowUpSagas,
];
