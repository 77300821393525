import { IArDetails } from "us.collection.transactions/interfaces";
import moment from "moment";

export const ArDetails: IArDetails = {
    arId:0,
    pid: 0, 
    cid:0, 
    debtorId: 0,
    creditorId: 0,
    debtorName: "", 
    creditorName:"", 
    creditorGroupId: 0, 
    balance: 0,
    lastPaymentDate: null
};
