import { ISentence } from "us.collection.case/interfaces";
import { isValidDate } from ".";

/**
 * To get the sentence details arranged befoe storing in the sentence details redux state
 * @param sentence any sentence value returned form the API
 * @returns valid sentence
 */
export const arrangeSentenceDetails = (sentence: any): ISentence => {
  return sentence && sentence.hasOwnProperty("caseId")
    ? {
        ...sentence,
        collectionFeeFromDate: isValidDate(sentence.collectionFeeFromDate),
        caseCostFromDate: isValidDate(sentence.caseCostFromDate),
        declarationDate: isValidDate(sentence.declarationDate),
        dueDate: isValidDate(sentence.dueDate),
        interestCollectionFeeTo: isValidDate(sentence.interestCollectionFeeTo),
        limitDate: isValidDate(sentence.limitDate),
        mainAmountFromDate: isValidDate(sentence.mainAmountFromDate),
        mainAmountInterestToDate: isValidDate(sentence.mainAmountInterestToDate),
        otherCostsFromDate: isValidDate(sentence.otherCostsFromDate),
        regDate: isValidDate(sentence.regDate),
        sentenceDate: isValidDate(sentence.sentenceDate),
      }
    : null;
};
