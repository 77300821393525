import { httpCollection } from "us.helper/http/collection";

export default {
  creditorInfo: {
    getBelongsToCreditor: (params: number): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const {data} = await httpCollection.get(
            "searchservice",
            "entitysearch",
            {
              roleType: 'CRE',
              searchCriteria: "Number",
              keyword: params
            },
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    addNewCreditor: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const newCreditorData = await httpCollection.post(
            "entityservice",
            "entity",
            params.payload
          );
          let creditors = {}
          if(newCreditorData.status == 200 || newCreditorData.status == 201){
            creditors = await httpCollection.get(
              "entityservice",
              `creditorgroups/${params.groupId}?withcreditors=${true}`,
              {}
            );
          }
          resolve({newCreditorData,creditors});
        } catch (error) {
          reject(error);
        }
      });
    },
    getNextCreditorNo: (): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const nextCreditorNoData = await httpCollection.get(
            "entityservice",
            "creditor/nextcreditorno",
            {}
          );
          const caseHandlerData = await httpCollection.get(
            "userservice",
            "users",
            {}
          );
          resolve({nextCreditorNoData,caseHandlerData});
        } catch (error) {
          reject(error);
        }
      });
    },
    validateCreditorNo: (creditorNo: number): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const data = await httpCollection.get(
            "entityservice",
            `creditor/${creditorNo}/validate`,
            {}
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    getCaseHandlerList: (): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const caseHandlerData = await httpCollection.get(
            "userservice",
            "users",
            {}
          );
          resolve(caseHandlerData);
        } catch (error) {
          reject(error);
        }
      });
    },
    updateCreditor: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const data = await httpCollection.put(
            "entityservice",
            "entity",
            params
          );
            const creditorData = await httpCollection.get(
              "entityservice",
              `entities/${params?.entRoleId}`,
              {}
            );
          resolve({data,creditorData});
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
