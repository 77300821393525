import { put, call, takeLatest } from 'redux-saga/effects';
import * as API from 'us.collection.case/services';
import {
	AccountSummeryWidget,
	DebtorWidget,
	CaseInfoWidget,
} from 'us.collection.case/constants/Actions';
import * as Actions from 'us.collection.case/actions';
import {
	ICaseDetails,
	IMainSearchHistoryRequest,
} from 'us.collection.case/interfaces';
import { HandlerAction } from 'us.collection/actions';

const {
	DebtorWidgetActions,
	CaseInfoWidgetActions,
	AccountSummeryWidgetActions,
} = Actions;
const { handler } = HandlerAction;
const { debtorWidget: debtorActions } = DebtorWidgetActions;
const { caseInformation: caseInfoActions } = CaseInfoWidgetActions;
const { accountSummery: acSummeryActions } = AccountSummeryWidgetActions;

const dashboardInfoSagas = {
	/**
	 * Get caseId,creditorId,debtorId from metaData response and make entity detail service call
	 * @param {string} otherPartyBelongsType - otherparty belongs type, it can be C,S.
	 * @param {number} otherPartyEntityId - case number of particular case
	 * @param {number} creditorEntNumber - creditor number of particular case
	 * @param {number} debtorEntRoleId -debtor number of particular case
	 * @param {number} caseId - case number of particular case
	 */
	debtorWidgetData: {
		get: function* (action: {
			type: string;
			payload: {
				data: IMainSearchHistoryRequest;
				isLoading: boolean;
			};
		}): any {
			const {
				otherPartyEntityId,
				otherPartyBelongsType,
				creditorEntNo,
				debtorEntRoleId,
				caseId,
			} = action.payload.data;
			try {
				const debtorWidgetData = yield call(
					API.dashboard.getEntities,
					{
						otherPartyEntityId,
						otherPartyBelongsType,
						creditorEntNo,
						debtorEntRoleId,
						caseId,
					}
				);
				if (
					debtorWidgetData.hasOwnProperty(
						'debtorDetails'
					)
				) {
					yield put(
						debtorActions.success(
							debtorWidgetData
						)
					);
				} else {
					yield put(debtorActions.fail({}));
				}
			} catch (error) {
				yield put(debtorActions.fail(error as Object));
			}
		},
	},

	/**
	 * Get caseId from metaData response and make Case Information service call
	 * @param {string} EntityType - type of case. it can be either subcase or case
	 * @param {number} EntityId - case number of particular case
	 */
	accountSummeryWidgetData: {
		get: function* (action: {
			type: string;
			payload: {
				data: ICaseDetails;
				isLoading: boolean;
			};
		}): any {
			try {
				const accountSummeryData = yield call(
					API.dashboard.getAccountSummeryDetails,
					action.payload.data
				);
				if (accountSummeryData.hasOwnProperty('case')) {
					yield put(
						acSummeryActions.success(
							accountSummeryData
						)
					);
					yield put(
						handler.success({
							caseHandler:
								accountSummeryData
									.case
									.caseHandler,
							courtHandler:
								accountSummeryData
									.case
									.courtHandler,
							dataType: 'handlerNames',
						})
					);
				} else {
					yield put(acSummeryActions.fail({}));
				}
			} catch (error) {
				yield put(
					acSummeryActions.fail(error as Object)
				);
			}
		},
	},

	/**
	 * Case Information service call
	 * @param caseId - case number of particular case
	 */
	CaseInfoWidgetData: {
		get: function* (action: {
			type: string;
			payload: {
				data: { caseId: number };
				isLoading: boolean;
			};
		}): any {
			try {
				const caseInfoData = yield call(
					API.dashboard.getDevCaseProperties,
					action.payload.data.caseId
				);
				if (Array.isArray(caseInfoData)) {
					yield put(
						caseInfoActions.success(
							caseInfoData
						)
					);
				} else {
					yield put(caseInfoActions.fail([]));
				}
			} catch (error) {
				yield put(caseInfoActions.fail(error as any));
			}
		},
	},
};

export default [
	takeLatest(
		DebtorWidget.GET_DEBTOR_WIDGET_DATA,
		dashboardInfoSagas.debtorWidgetData.get
	),
	takeLatest(
		AccountSummeryWidget.GET_ACCOUNT_SUMMERY_WIDGET_DATA,
		dashboardInfoSagas.accountSummeryWidgetData.get
	),
	takeLatest(
		CaseInfoWidget.GET_CASE_INFO_WIDGET_DATA,
		dashboardInfoSagas.CaseInfoWidgetData.get
	),
];
