import { IAPIAction, IAPIActionBase } from "us.common/interfaces";
import { BMDActionsConst } from "us.collection/constants";
import { IBMDAction } from "./Interfaces";

const {
  GET_BMD_LIST,
  GET_BMD_LIST_SUCCESS,
  GET_BMD_LIST_FAIL,

  ADD_BMD_PROPERTY,
  ADD_BMD_PROPERTY_SUCCESS,
  ADD_BMD_PROPERTY_FAIL,

  GET_BMD_DATA_TYPES,
  GET_BMD_DATA_TYPES_SUCCESS,
  GET_BMD_DATA_TYPES_FAIL,

  GET_BMD_DATA_TYPE_VALUES,
  GET_BMD_DATA_TYPE_VALUES_SUCCESS,
  GET_BMD_DATA_TYPE_VALUES_FAIL,

  GET_BMD_PROPERTY_DETAILS,
  GET_BMD_PROPERTY_DETAILS_SUCCESS,
  GET_BMD_PROPERTY_DETAILS_FAIL,

  CHANGE_BMD_PROPERTY_DETAILS,
  CHANGE_BMD_PROPERTY_DETAILS_SUCCESS,
  CHANGE_BMD_PROPERTY_DETAILS_FAIL,

  DELETE_BMD_PROPERTY_HISTORY_DETAILS,
  DELETE_BMD_PROPERTY_HISTORY_DETAILS_SUCCESS,
  DELETE_BMD_PROPERTY_HISTORY_DETAILS_FAIL,

  CHANGE_BMD_DRAWER_STATUS: CHANGE_DRAWER_STATUS,

  ADD_BMD_CATEGORY,
  ADD_BMD_CATEGORY_SUCCESS,
  ADD_BMD_CATEGORY_FAIL,

  ADD_BMD_GROUP,
  ADD_BMD_GROUP_SUCCESS,
  ADD_BMD_GROUP_FAIL,

  ADD_BMD_GROUP_DRAWER,

  UPDATE_BMD_GROUP,
  UPDATE_BMD_GROUP_SUCCESS,
  UPDATE_BMD_GROUP_FAIL,

  GET_BMD_COUNT_LIST,
  GET_BMD_COUNT_LIST_SUCCESS,
  GET_BMD_COUNT_LIST_FAIL,
  
  SEARCH_LINKED_CREDITORS,
  SEARCH_LINKED_CREDITORS_SUCCESS,
  SEARCH_LINKED_CREDITORS_FAIL,

  ADD_DATA_TYPE,
  EDIT_DATA_TYPE,
  ADD_EDIT_DATA_TYPE_SUCCESS,
  ADD_EDIT_DATA_TYPE_FAIL,

  GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES,
  GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES_SUCCESS,
  GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES_FAIL,
} = BMDActionsConst;

export const BMDActions: Readonly<IAPIActionBase & IBMDAction> = {
  bmdList: {
    get: (data) => ({
      type: GET_BMD_LIST,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_BMD_LIST_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_BMD_LIST_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  bmdProperty: {
    save: (data) => ({
      type: ADD_BMD_PROPERTY,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ADD_BMD_PROPERTY_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ADD_BMD_PROPERTY_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  bmdDataTypes: {
    get: (data) => ({
      type: GET_BMD_DATA_TYPES,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_BMD_DATA_TYPES_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_BMD_DATA_TYPES_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
    openDrawer: (data) => ({
      type: CHANGE_DRAWER_STATUS,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
  },
  bmdDataTypeValues: {
    get: (data) => ({
      type: GET_BMD_DATA_TYPE_VALUES,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_BMD_DATA_TYPE_VALUES_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_BMD_DATA_TYPE_VALUES_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  bmdPropertyDetails: {
    get: (data) => ({
      type: GET_BMD_PROPERTY_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_BMD_PROPERTY_DETAILS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_BMD_PROPERTY_DETAILS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  changeBMDPropertyDetails: {
    update: (data) => ({
      type: CHANGE_BMD_PROPERTY_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CHANGE_BMD_PROPERTY_DETAILS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CHANGE_BMD_PROPERTY_DETAILS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  bmdPropertyHistory: {
    delete: (data) => ({
      type: DELETE_BMD_PROPERTY_HISTORY_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: DELETE_BMD_PROPERTY_HISTORY_DETAILS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: DELETE_BMD_PROPERTY_HISTORY_DETAILS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  bmdCategory: {
    save: (data) => ({
      type: ADD_BMD_CATEGORY,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ADD_BMD_CATEGORY_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ADD_BMD_CATEGORY_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  bmdGroup: {
    save: (data) => ({
      type: ADD_BMD_GROUP,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    update: (data) => ({
      type: UPDATE_BMD_GROUP,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ADD_BMD_GROUP_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ADD_BMD_GROUP_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
    openDrawer: (data) => ({
      type: ADD_BMD_GROUP_DRAWER,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
  },
  linkedCreditors: {
    search: (data) => ({
      type: SEARCH_LINKED_CREDITORS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SEARCH_LINKED_CREDITORS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SEARCH_LINKED_CREDITORS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  bmdCountList: {
    get: (data) => ({
      type: GET_BMD_COUNT_LIST,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_BMD_COUNT_LIST_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_BMD_COUNT_LIST_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    })
  },
  bmdDataType: {
    save: (data) => ({
      type: ADD_DATA_TYPE,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    update:(data) => ({
      type: EDIT_DATA_TYPE,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ADD_EDIT_DATA_TYPE_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ADD_EDIT_DATA_TYPE_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    })
  },
  bmdDataTypeValueRetrievingSP: {
    get: (data) => ({
      type: GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_DATA_TYPE_VALUE_RETRIEVING_SP_NAMES_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    })
  },
};
