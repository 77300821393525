import React, { useEffect } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './Style.scss';
import Common from 'us.common';
import { Formik, FormikProps } from 'formik';
import moment, { Moment } from 'moment';
const {
	$Button,
	$Select,
	$Switch,
	$PageHeader,
	$Affix,
	$Divider,
	$Skeleton,
	$Drawer,
	$Form,
	$TextArea,
	$Popover,
	$DatePicker,
	$TableTree,
} = Common.Components;
import { BoxIcons, IconTypes } from 'us.icons';
import ExcelExporter from 'us.collection/components/ExcelGenerator';
import { CasesAndSubCasesAction } from '../../actions';
import { INavigationData, IRootState } from 'us.collection/interfaces';
import { DueDateValidationSchema } from 'us.collection.transactions/components/CasesAndSubcases/Validations';
import { ISubCase, ICase } from 'us.collection.transactions/interfaces';
import {
	CaseStateOptions,
	CaseTypeOptions,
	CASE_COLUMNS,
	CASE_STATE_DROP_DOWN,
	CASE_TYPE_DROP_DOWN,
	INITIAL_VALUES,
	EXCEL_COLUMNS,
} from './Constants';
import { DrawerType } from 'us.collection.transactions/constants';
import {
	drawerStatusHandler,
	filterByCaseState,
	getDataSetToExport,
	getTableRenderItems,
	handleExpandedRows,
	handleSort,
	isCreateCaseButtonDisabled,
	isMergeCaseButtonDisabled,
} from './Functions';
import {
	GetCasesAndSubCasesRequestObject,
	GetSplitCaseRequestObject,
	GetUpdateDueDateRequestObject,
} from './Repository';
import { ICasesAndSubcases, ICaseSubCasesFrom } from './Interfaces';
import { ManageCase } from './Components';
import { CaseType } from 'us.helper/types/enums';
import { RootState } from 'us.helper/types';

/**
 * @description - The component used in home view of case and sub cases
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2754347013/Cases+and+Sub+Cases+UI+Implementation
 * @param {ICasesAndSubcases & PropsFromRedux} props
 * @return {JSX.Element}
 * @author Darshana Waasala <waasalajb@unicorn-solutions.com>
 * @since 14/03/2022
 */
const CasesAndSubcases: React.FC<ICasesAndSubcases & PropsFromRedux> = (
	props
) => {
	const { t } = useTranslation();
	const { push, location } = useHistory();
	const { search } = location;
	const { state: locationState } = useLocation();
	const state = locationState as INavigationData;
	const { refreshCount = 0 } = state ?? {};

	const {
		currentCurrency,
		currentLanguage,
		currentDateFormat,
		caseList: casesAndSubCases,
		drawerStatus,
		metaData,
		isARDetailsLoading,
		getCasesAndSubCases,
		updateDueDate,
		splitCase,
		openDrawer,
		setSelectedCaseAndSubCase,
	} = props;
	const { creditorId, debtorId, arId } = metaData.data ?? {};

	const formRef = React.useRef<FormikProps<ICaseSubCasesFrom>>(null);

	useEffect(() => {
		if (!metaData.isLoading && creditorId && debtorId && arId) {
			getCasesAndSubCases &&
				getCasesAndSubCases(
					GetCasesAndSubCasesRequestObject.call({
						creditorId,
						debtorId,
						arId,
					})
				);
			formRef.current?.resetForm(INITIAL_VALUES as any);
		}
	}, [metaData, refreshCount]);

	useEffect(() => {
		setSelectedCaseAndSubCase &&
			setSelectedCaseAndSubCase({
				data: [],
				filterType: CaseTypeOptions.ALL,
			});
	}, []);

	const { params }: any = matchPath(window.location.pathname, {
		path: '/:type/:id?/:module?',
		exact: false,
		strict: false,
	});

	/**
	 * @function
	 * @description - To handle the API call for handling case splitting
	 * @param {ISubCase} subCase - form data
	 * @returns - void
	 */
	const handleCaseSplit = (subCase: ISubCase): void => {
		splitCase &&
			splitCase(
				GetSplitCaseRequestObject.call({
					...subCase,
					arId,
					creditorId,
					debtorId,
				})
			);
	};

	/**
	 * @function
	 * @description - form submittion
	 * @param {ICaseSubCasesFrom} data - form data
	 * @param {*} { resetForm } - formikBag function
	 */
	const handleSubmit = (data: ICaseSubCasesFrom, { resetForm }: any) => {
		if (updateDueDate) {
			data.selectedCases.forEach(({ caseId }) => {
				updateDueDate(
					GetUpdateDueDateRequestObject.call({
						...data,
						arId,
						caseId,
					})
				);
			});
		}
		resetForm(INITIAL_VALUES);
	};

	/**
	 * @function
	 * @description - Handles the table tree row selection
	 * @param {(field: string, value: any, shouldValidate?: boolean) => void} setFieldValue - formik setFieldValue
	 * @returns rowSelection object for TableTree row selection
	 */
	const rowSelection = (
		setFieldValue: (
			field: string,
			value: any,
			shouldValidate?: boolean
		) => void,
		values: any
	) => ({
		onChange: (
			selectedRowKeys: any,
			selectedRows: Array<ICase | ISubCase>
		) => {
			const { caseType } = values;
			setFieldValue('selectedCases', selectedRows, false);
			setSelectedCaseAndSubCase &&
				setSelectedCaseAndSubCase({
					data: selectedRows,
					filterType: caseType,
				});
		},
		getCheckboxProps: (record: ICase) => ({
			disabled:
				record.parentId === 0 &&
				record.caseType === CaseType.C, // Column configuration not to be checked
		}),
	});

	const columns = CASE_COLUMNS.map((column) => ({
		...column,
		...getTableRenderItems(
			column.dataIndex,
			handleCaseSplit,
			state
		),
		title: t(
			`US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.${column.title}`
		),
		key: column.dataIndex,
	}));

	return (
		<Formik<ICaseSubCasesFrom>
			innerRef={formRef}
			enableReinitialize
			initialValues={INITIAL_VALUES}
			validationSchema={DueDateValidationSchema}
			onSubmit={handleSubmit}>
			{({
				values,
				handleSubmit,
				isSubmitting,
				isValidating,
				setFieldValue,
				setValues,
				...rest
			}: {
				values: ICaseSubCasesFrom;
				handleSubmit: any;
				isSubmitting: boolean;
				isValidating: boolean;
				setFieldValue: (
					field: string,
					value: any,
					shouldValidate?: boolean
				) => void;
				setValues: (
					fields: React.SetStateAction<{
						[field: string]: any;
					}>,
					shouldValidate?: boolean
				) => void;
			}) => (
				<>
					<div className='space-content cases-and-subcases'>
						<$Affix offsetTop={80}>
							<div className='page-header header-border'>
								<div className='d-flex flex-row align-items-center justify-content-between'>
									<div className='d-flex align-items-center'>
										{params[
											'module'
										] ===
											'cases-and-subcases' && (
											<$PageHeader
												className='px-0'
												title={t(
													'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.MANAGE_CASES_AND_SUBCASES'
												)}
												onBack={() =>
													push(
														{
															pathname: `/ar/${arId}`,
															search,
														}
													)
												}
											/>
										)}
										{!params[
											'module'
										] && (
											<$PageHeader
												className='px-0'
												title={t(
													'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.MANAGE_CASES_AND_SUBCASES'
												)}
											/>
										)}
										<$Divider
											className='bui-devider'
											type='vertical'
										/>
										<$Button
											className='mr-2'
											onClick={() =>
												drawerStatusHandler(
													DrawerType.CREATE_CASES,
													true,
													openDrawer
												)
											}
											type='dashed'
											size='small'
											disabled={isCreateCaseButtonDisabled(
												values
											)}>
											<BoxIcons
												className='mr-1'
												type={
													IconTypes.BOX_ICON
												}
												name='create-case'
											/>
											{t(
												'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.CREATE_CASE'
											)}
										</$Button>
										<$Button
											className='mr-2'
											onClick={() =>
												drawerStatusHandler(
													DrawerType.MERGE_CASES,
													true,
													openDrawer
												)
											}
											type='dashed'
											size='small'
											disabled={isMergeCaseButtonDisabled(
												values
											)}>
											<BoxIcons
												className='mr-1'
												type={
													IconTypes.BOX_ICON
												}
												name='merge-case'
											/>
											{t(
												'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.MERGE_CASES'
											)}
										</$Button>
										<$Popover
											placement='bottomLeft'
											content={
												<$Form>
													<div
														style={{
															maxWidth: '250px',
															minWidth: '250px',
														}}>
														<div className='ant-popover-message'>
															<$DatePicker
																formitem
																label={t(
																	'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.MANUAL_DUE_DATE'
																)}
																className='w-100'
																name='dueDate'
																size='small'
																placeholder={
																	currentDateFormat
																}
																format={
																	currentDateFormat
																}
																value={
																	values.dueDate
																}
																defaultPickerValue={
																	values.dueDate
																}
																disabledDate={(
																	day: Moment
																) =>
																	day.isBefore(
																		moment().startOf(
																			'day'
																		)
																	)
																}
																data-testid='caseDueDate'
																required
															/>
															<$TextArea
																name='caseNote'
																autoSize={{
																	minRows: 6,
																	maxRows: 6,
																}}
																size='small'
																defaultValue={
																	values.caseNote
																}
																label={t(
																	'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.NOTE'
																)}
																required
															/>
														</div>
														<div className='ant-popover-buttons'>
															<$Button
																size='small'
																loading={
																	casesAndSubCases.isLoading ||
																	isSubmitting ||
																	isValidating
																}
																onClick={() =>
																	setFieldValue(
																		'updateDueDateVisible',
																		false,
																		false
																	)
																}>
																{t(
																	'US.COMMON:COMMON.CANCEL'
																)}
															</$Button>
															<$Button
																size='small'
																type='primary'
																onClick={
																	handleSubmit
																}>
																{t(
																	'US.COMMON:COMMON.SAVE'
																)}
															</$Button>
														</div>
													</div>
												</$Form>
											}
											destroyTooltipOnHide
											trigger='click'
											visible={
												values.updateDueDateVisible
											}
											onVisibleChange={(
												visible: boolean
											) =>
												setFieldValue(
													'updateDueDateVisible',
													visible,
													false
												)
											}>
											<$Button
												className='mr-2'
												onClick={() =>
													setFieldValue(
														'updateDueDateVisible',
														true,
														false
													)
												}
												type='dashed'
												size='small'
												disabled={
													values
														.selectedCases
														.length ==
													0
												}>
												<BoxIcons
													className='mr-1'
													type={
														IconTypes.BOX_ICON
													}
													name='update-date'
												/>
												{t(
													'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.MANUAL_DUE_DATE'
												)}
											</$Button>
										</$Popover>
									</div>
									<div className='ml-3 d-flex align-items-center'>
										<$Select
											name='caseType'
											size='small'
											allOption={
												false
											}
											style={{
												width: 110,
											}}
											options={CASE_TYPE_DROP_DOWN.map(
												(
													item
												) => ({
													...item,
													label: t(
														`US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.${item.label}`
													),
												})
											)}
											defaultValue={
												CaseTypeOptions.ALL
											}
											optionValue='value'
											optionText='label'
											className='mr-2'
											data-testid='case-type-select-options'
										/>
										<$Select
											name='caseState'
											size='small'
											allOption={
												false
											}
											style={{
												width: 110,
											}}
											options={CASE_STATE_DROP_DOWN.map(
												(
													item
												) => ({
													...item,
													label: t(
														`US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.${item.label}`
													),
												})
											)}
											defaultValue={
												CaseStateOptions.ALL
											}
											optionValue='value'
											optionText='label'
											className='mr-3'
											data-testid='case-state-select-options'
										/>
										<div className='d-flex align-items-center'>
											<div
												className='mr-2'
												style={{
													lineHeight: '1',
												}}
												data-testid='case-list-switch-expand-all'>
												<$Switch
													dataIndex
													name='expandRows'
													checked={
														values.expandRows
													}
													onChange={(
														switchValue: boolean
													) => {
														handleExpandedRows(
															casesAndSubCases.data,
															0,
															false,
															values,
															setValues,
															switchValue
														);
														setFieldValue(
															'expandRows',
															switchValue,
															false
														);
													}}
												/>
											</div>
											<div className='text-nowrap'>
												{t(
													'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.EXPAND_ALL'
												)}
											</div>
										</div>
										<$Divider
											className='bui-devider'
											type='vertical'
										/>
										<ExcelExporter
											dataSet={getDataSetToExport(
												casesAndSubCases.data
											)}
											columns={EXCEL_COLUMNS.map(
												(
													item
												) => ({
													...item,
													title: t(
														`US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.${item.title}`
													),
												})
											)}
											name={
												'CasesAndSubCaseList_' +
												arId +
												'_' +
												new Date()
											}
											format={{
												currentDateFormat,
												currentCurrency,
												currentLanguage,
											}}
										/>
									</div>
								</div>
							</div>
						</$Affix>
						<div className='mt-3'>
							<$Skeleton
								loading={
									casesAndSubCases.isLoading ||
									isARDetailsLoading
								}
								active
								paragraph={{
									rows: 2,
								}}>
								<$TableTree
									rowKey={(
										record
									) =>
										record.key
									}
									data={filterByCaseState(
										values.caseState,
										values.caseType,
										casesAndSubCases.data
									)}
									columns={
										columns
									}
									onSort={
										handleSort
									}
									onFilter={(
										searchData,
										dataSource
									) =>
										searchData(
											dataSource
										)
									}
									bordered
									className='cases-subcases mt-3'
									filterOnType={
										true
									}
									scroll={{
										x: 1400,
										y: 'calc(100vh - 280px)',
									}}
									size='small'
									resetOnSourceChange={
										true
									}
									rowSelection={{
										type: 'checkbox',
										...rowSelection(
											setFieldValue,
											values
										),
									}}
									firstColSkipFilterProps={
										-1
									}
									expandedRowKeys={[
										...values.expandedRows,
									]}
									onExpand={(
										isExpand: boolean,
										record: any
									) => {
										handleExpandedRows(
											casesAndSubCases.data,
											record.key,
											isExpand,
											values,
											setValues
										);
										!isExpand &&
											values.expandRows &&
											setFieldValue(
												'expandRows',
												false,
												false
											);
									}}
								/>
							</$Skeleton>
						</div>
					</div>

					<$Drawer
						title={t(drawerStatus.title)}
						width={1200}
						onClose={() =>
							drawerStatusHandler(
								DrawerType.CREATE_CASES,
								false,
								openDrawer
							)
						}
						visible={drawerStatus.isOpen}
						destroyOnClose
						className='cases-and-subcases-drawer'>
						<ManageCase />
					</$Drawer>
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { router, common, casesAndSubCases, domainView, transaction } =
		state;
	const { location } = router;
	const { pathname } = location;
	const { currentCurrency, currentLanguage, currentDateFormat } = common;
	const { metaData } = domainView;
	const { caseList, drawerStatus } = casesAndSubCases;
	return {
		locationState: pathname,
		currentCurrency,
		currentLanguage,
		currentDateFormat,
		metaData,
		caseList,
		drawerStatus,
		isARDetailsLoading: transaction.arDetails ? false : true,
	};
};

const { caseList, dueDate, splitSubCases } = CasesAndSubCasesAction;
const mapDispatchToProps = {
	getCasesAndSubCases: caseList.get,
	updateDueDate: dueDate.update,
	splitCase: splitSubCases.update,
	openDrawer: caseList.openDrawer,
	setSelectedCaseAndSubCase: caseList.set,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CasesAndSubcases);
