import {
    ICategory,
    IActivityTableData,
} from "us.collection/components/ActivityTable/Interfaces";
import {
    IActivity,
    IAcvitityBulkRequest,
    IDefaultData,
    ISetting,
    ISettingRequest,
} from "us.collection.followup/interfaces";
import { appInsights } from "us.helper/AppInsights";
import {
    ActivityType,
    ActivityTypeId,
    ActvityGroupProperty,
    DefaultField,
    FollowUpEntityType,
    FollowUpUniqueCol,
    ParameterType,
    InitialField,
} from "us.collection.followup/constants";
import _ from "lodash";
import moment from "moment";

/**
 * @function
 * @description separate activities according to category
 * @param {Array<IActivity>} activities -followup activities
 * @returns {handling: Array<IActivity>;others: Array<IActivity>} activities according to category
 */
export const getCategoryActivities = (
    activities: Array<IActivity>
): {
    handling: Array<IActivity>;
    other: Array<IActivity>;
} => {
    try {
        return {
            handling: activities.filter(
                (activity: IActivity) => activity.isHandlingActivity
            ),
            other: activities.filter(
                (activity: IActivity) => !activity.isHandlingActivity
            ),
        };
    } catch (error) {
        appInsights.trackException(
            `FollowUp-Activity getCategoryActivities Exception - ${error}`
        );
        return {
            handling: [],
            other: [],
        };
    }
};

/**
 * @function
 * @description get category list for ActivityTable
 * @returns {Array<ICategory>} category list
 */
export const getCategories = (): Array<ICategory> => {
    try {
        return [
            {
                categoryId: ActivityTypeId.All,
                categoryName: ActivityType.All,
            },
            {
                categoryId: ActivityTypeId.Handling,
                categoryName: ActivityType.Handling,
            },
            {
                categoryId: ActivityTypeId.Other,
                categoryName: ActivityType.Other,
            },
        ];
    } catch (error) {
        appInsights.trackException(
            `FollowUp-Activity getCategories Exception - ${error}`
        );
        return [];
    }
};


/**
 * @function
 * @description filter activity by acivity name or code
 * @param {Array<IActivity>} activities activities
 * @param {string} searchText -search value
 * @returns  {Array<IActivity>} filtered activities
 */
export const filterActivityByCodeOrName = (
    activities: Array<IActivity>,
    searchText: string
): Array<IActivity> => {
    try {
        return activities.filter((activity: IActivity) => {
            const { activityCode, displayName } = activity;
            return (
                activityCode.toLowerCase().includes(searchText.toLowerCase()) ||
                displayName.toLowerCase().includes(searchText.toLowerCase())
            );
        });
    } catch (error) {
        appInsights.trackException(
            `FollowUp-Activity filterActivityByCodeOrName Exeception -${error}`
        );
        return [];
    }
};

/**
 * @function
 * @description get select activity data
 * @param {Array<IActivity>} activities activities
 * @param {string} activityName select activity name
 * @returns {IActivity} select activity data
 */
export const getSelectedActivity = (
    activities: Array<IActivity>,
    activityName: string
): IActivity | undefined => {
    try {
        return activities.find((activity: IActivity) => {
            const { displayName } = activity;
            return displayName.toLowerCase() === activityName.toLowerCase();
        });
    } catch (error) {
        appInsights.trackException(
            `FollowUp-Activity getSelectedActivity Exeception -${error}`
        );
    }
};

/**
 * @function
 * @description check activity parameter field is default or nor
 * @param {string} fieldName field name
 * @param {string} entityType entityType
 * @returns {boolean}
 */
const isDefaultField = (fieldName: string, entityType: string): boolean => {
    try {
        switch (entityType) {
            case FollowUpEntityType.CASE:
            case FollowUpEntityType.INVOICE:
            case FollowUpEntityType.SUBCASE:
                switch (fieldName.toLowerCase()) {
                    case DefaultField.EntityNo:
                    case DefaultField.EntityId:
                    case DefaultField.Id:
                    case DefaultField.CaseId:
                    case DefaultField.CaseNo:
                    case DefaultField.User:
                    case DefaultField.UserName:
                        return true;
                    default:
                        return false;
                }
            case FollowUpEntityType.DEBTOR:
                switch (fieldName.toLowerCase()) {
                    case DefaultField.EntityNo:
                    case DefaultField.EntityId:
                    case DefaultField.ArNo:
                    case DefaultField.ArId:
                    case DefaultField.User:
                    case DefaultField.UserName:
                        return true;
                    default:
                        return false;
                }
            case FollowUpEntityType.CREDITOR:
                switch (fieldName.toLowerCase()) {
                    case DefaultField.EntityNo:
                    case DefaultField.EntityId:
                    case DefaultField.CreditorNo:
                    case DefaultField.CreditorId:
                    case DefaultField.User:
                    case DefaultField.UserName:
                        return true;
                    default:
                        return false;
                }
            case FollowUpEntityType.PAYMENT:
                switch (fieldName.toLowerCase()) {
                    case DefaultField.EntityNo:
                    case DefaultField.EntityId:
                    case DefaultField.PaymentId:
                    case DefaultField.PaymentNo:
                    case DefaultField.User:
                    case DefaultField.UserName:
                        return true;
                    default:
                        return false;
                }
            default:
                return false;
        }
    } catch (error) {
        appInsights.trackException(
            `FollowUp-Activity isDefaultField Exeception -${error}`
        );
        return false;
    }
};

/**
 * @function
 * @description get activity parameter table data
 * @param {Array<ISetting>} settings activity parameters
 * @param {string} entityType entityType
 * @returns {Array<ISetting>} table data
 */
export const getParameterTableData = (
    settings: Array<ISetting>,
    entityType: string
): Array<ISetting> => {
    try {
        return settings.filter(
            (setting: ISetting) => !isDefaultField(setting.name, entityType)
        );
    } catch (error) {
        appInsights.trackException(
            `FollowUp-Activity getParameterTableData Exeception -${error}`
        );
        return [];
    }
};

/**
 * @function
 * @description get parameter fields for initial value
 * @param {Array<ISetting>} settings activity parameters
 * @param {string} entityType entityType
 * @returns {object}
 */
export const getFieldName = (
    settings: Array<ISetting>,
    entityType: string
): object => {
    let fileds: any = {};
    try {
        settings
            .filter((setting: ISetting) => !isDefaultField(setting.name, entityType))
            .map((setting: ISetting) => {
                fileds[setting.name] = "";
            });
    } catch (error) {
        appInsights.trackException(
            `FollowUp-Activity getFieldName Exeception -${error}`
        );
    }
    return fileds;
};

/**
 * @function
 * @description set parameterList property values for execution request
 * @param {any} values -activity form data
 * @param {Array<ISetting>} settings - activity parameters
 * @param {IDefaultData} defaultData - dafualt data about activity
 * @param {Object} followUp -selected followup  
 * @param {number | string} entityId - entityId
 * @returns 
 */
export const getParameterList = (
    values: any,
    settings: Array<ISetting>,
    defaultData: IDefaultData,
    followUp: Object,
    entityId: number | string
): Array<ISettingRequest> => {
    try {
        const { userName, entityType } = defaultData;
        const settingData = _.omit(values, [
            InitialField.Activity,
            InitialField.ExecutingDateTime,
            InitialField.ExecutingDateTimeWithParameter,
        ]);
        const commonSettingData: Array<ISettingRequest> = [];
        !_.isEmpty(settingData) &&
            settings
                .filter((setting: ISetting) => !isDefaultField(setting.name, entityType))
                .map((setting: ISetting) => {
                    commonSettingData.push({
                        Name: setting.name,
                        DataType: setting.parameterType,
                        value: settingData[setting.name],
                    });
                });
        commonSettingData.unshift({
            Name: DefaultField.UserName,
            DataType: ParameterType.VARCHAR,
            value: userName,
        });

        !_.isEmpty(getDefaultFieldName(settings, defaultData.entityType)) && commonSettingData.unshift({
            Name: Object.keys(getDefaultFieldName(settings, defaultData.entityType))[0],
            DataType: ParameterType.INT,
            value: entityId,
        })
        return commonSettingData
    } catch (error) {
        return []
    }
};
/**
 * @function
 * @description set activities property values for execution request
 * @param {any} values -activity form data
 * @param {Array<ISetting>} settings - activity parameters
 * @param {IDefaultData} defaultData - dafualt data about activity
 * @param {Array<string>} entityIds - selected entityIds
 * @param {Array<Object>} followUps - followup result
 * @param {boolean} isEnableParameter - check activity has non default parameters
 * @returns {Array<IAcvitityBulkRequest>} activities data for execute request
 */
export const getActivitiesRequst = (
    values: any,
    settings: Array<ISetting>,
    defaultData: IDefaultData,
    entityIds: Array<string>,
    followUps: Array<Object>,
    isEnableParameter: boolean
): Array<IAcvitityBulkRequest> => {
    try {
        const actvitiesRequest: Array<IAcvitityBulkRequest> = [];

        entityIds.map((entityId: number | string) => {
            actvitiesRequest.push({
                entityId,
                executingDateTime: setExecuteDateTime(values, isEnableParameter),
                parameterList: getParameterList(values, settings, defaultData, followUps[0], entityId)
            });
        });
        return actvitiesRequest;
    } catch (error) {
        appInsights.trackException(
            `FollowUp-Activity getParameterRequst Exeception -${error}`
        );
        return [];
    }
};

/**
 * @function
 * @description get entityIds according to selected followupIds
 * @param {Array<Object>} data followup result
 * @param {Array<string | number>} followUpIds selected followupIds
 * @param defaultData
 * @returns {Array<string>}  entityIds
 */
export const getEntityIds = (
    data: Array<Object>,
    followUpIds: Array<string | number>,
    defaultData: IDefaultData
): Array<string> => {
    try {
        return data
            .filter((followUp: any) =>
                followUpIds.includes(followUp[FollowUpUniqueCol.FOLLOWUP_ID])
            )
            .map(
                (followUp: any) =>
                    followUp[getEntityFieldName(followUp, defaultData.entityType)]
            );
    } catch (error) {
        appInsights.trackException(
            `FollowUp-Activity getFieldName Exeception -${error}`
        );
        return [];
    }
};

/**
 * @function
 * @description get main entity field name
 * @param {Object} rawData a raw data of followUo result table
 * @param {string} entityType entity type
 * @returns {string} entity field name
 */
export const getEntityFieldName = (
    rawData: Object,
    entityType: string
): string => {
    let fieldName: string | undefined;
    try {
        switch (entityType) {
            case FollowUpEntityType.CASE:
            case FollowUpEntityType.INVOICE:
                fieldName = Object.keys(rawData).find(
                    (colum: string) => colum === FollowUpUniqueCol.CASE_NO
                );
                break;
            case FollowUpEntityType.DEBTOR:
                fieldName = Object.keys(rawData).find(
                    (colum: string) => colum === FollowUpUniqueCol.AR_NO
                );
                break;
            case FollowUpEntityType.CREDITOR:
                fieldName = Object.keys(rawData).find(
                    (colum: string) => colum === FollowUpUniqueCol.CREDITOR_NO
                );
                break;
            case FollowUpEntityType.PAYMENT:
                fieldName = Object.keys(rawData).find(
                    (colum: string) =>
                        colum === FollowUpUniqueCol.PAYMENT_NO ||
                        colum === FollowUpUniqueCol.PAYMENT_ID
                );
                break;
            default:
                fieldName = undefined;
        }
    } catch (error) {
        appInsights.trackException(
            `FollowUp-Activity getEntityFieldName Exeception -${error}`
        );
    }
    return typeof fieldName === "undefined"
        ? FollowUpUniqueCol.CASE_NO
        : fieldName;
};

/**
 * @function
 * @description set execution dateTime
 * @param {any} values activity form data
 * @param {boolean} isEnableParameter - check activity has non default parameters
 * @returns {string} execution dateTime
 */
const setExecuteDateTime = (
    values: any,
    isEnableParameter: boolean
): string => {
    try {
        if (isEnableParameter) {
            return moment(
                values[InitialField.ExecutingDateTimeWithParameter]
            ).isValid()
                ? moment(values[InitialField.ExecutingDateTimeWithParameter]).format()
                : moment().format();
        } else {
            return moment(values[InitialField.ExecutingDateTime]).isValid()
                ? moment(values[InitialField.ExecutingDateTime]).format()
                : moment().format();
        }
    } catch (error) {
        appInsights.trackException(
            `FollowUp-Activity setExecuteDateTime Exeception -${error}`
        );
        return moment().format();
    }
};
/**
 * @function
 * @description get default parameter fields 
 * @param {Array<ISetting>} settings activity parameters
 * @param {string} entityType entityType
 * @returns {object}
 */
export const getDefaultFieldName = (
    settings: Array<ISetting>,
    entityType: string
): object => {
    let fileds: any = {};
    try {
        settings
            .filter((setting: ISetting) => isDefaultField(setting.name, entityType))
            .map((setting: ISetting) => {
                fileds[setting.name] = "";
            });
    } catch (error) {
        appInsights.trackException(
            `FollowUp-Activity getFieldName Exeception -${error}`
        );
    }
    return _.omit(fileds, [DefaultField.User, DefaultField.UserName]);
};