import React from "react";
import { ToggleClass } from "us.helper";
import { RouteComponentProps, withRouter, useParams } from "react-router-dom";
import Transactions from "us.collection.transactions";
import { CaseType } from 'us.helper/types/enums';

interface ICntSelectedCreditorsHome extends RouteComponentProps {
  caseType: CaseType
}

const Cnt_TransactionsHome: React.FC<ICntSelectedCreditorsHome> = (
  props
) => {
  const { arNumber } = useParams<{ arNumber: string }>();

  ToggleClass("change-body-color-dom");

  const onMinimize = () => {
    props.history.push(`/ar/${arNumber}`);
  };

  return (
    <>
      <Transactions.Components.ArTransactions
        onMinimize={onMinimize}
        {...props}
      />
    </>
  );
};

export default withRouter(Cnt_TransactionsHome);
