import { EntityTypeShortForm } from 'us.common/constants';

export function SaveSearchHistory(this: any) {
	let caseType = '';
	if (this.entityType?.toUpperCase() == EntityTypeShortForm.CASE) {
		caseType = 'Case';
	} else if (
		this.entityType?.toUpperCase() == EntityTypeShortForm.SUB_CASE
	) {
		caseType = 'Subcase';
	}
	return {
		CaseNo: this.caseNo,
		DebtorEntNo: this.entId,
		DebtorEntRoleId: this.entRoleId,
		DebtorName: `${this.firstName} ${this.lastName}`,
		CaseType: caseType,
		CaseId: this.caseId,
	};
}
