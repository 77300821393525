export enum InterestApplication {
	GET_STANDARD_INTEREST_RATE_BY_DATE = 'GET_STANDARD_INTEREST_RATE_BY_DATE',
	GET_STANDARD_INTEREST_RATE_BY_DATE_SUCCESS = 'GET_STANDARD_INTEREST_RATE_BY_DATE_SUCCESS',
	GET_STANDARD_INTEREST_RATE_BY_DATE_FAIL = 'GET_STANDARD_INTEREST_RATE_BY_DATE_FAIL',

	GET_CALCULATED_INTEREST = 'GET_CALCULATED_INTEREST',
	GET_CALCULATED_INTEREST_SUCCESS = 'GET_CALCULATED_INTEREST_SUCCESS',
	GET_CALCULATED_INTEREST_FAIL = 'GET_CALCULATED_INTEREST_FAIL',

	UPDATE_TRANSACTION_INTEREST_RATE = 'UPDATE_TRANSACTION_INTEREST_RATE',
	UPDATE_TRANSACTION_INTEREST_RATE_SUCCESS = 'UPDATE_TRANSACTION_INTEREST_RATE_SUCCESS',
	UPDATE_TRANSACTION_INTEREST_RATE_FAIL = 'UPDATE_TRANSACTION_INTEREST_RATE_FAIL',
}
