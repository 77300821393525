import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import {
	$Checkbox,
	$Radio,
	$TableTree,
	$Tooltip,
	$Skeleton,
	$Empty,
	$Switch,
	$Drawer,
	$Select,
	$Button,
	ActivityExecution,
	$Dropdown,
	$Input,
	IOnFilter,
	IOnSort,
} from 'us.common/components';
import {
	ImportOutlined,
	ReloadOutlined,
	UpOutlined,
	DownOutlined,
} from 'us.icons';
import { useParams } from 'react-router-dom';
import { IFollowUpResult } from 'us.collection.followup/components/FollowUps/Components/FollowUpResult/Interfaces';
import { followUps } from 'us.collection.followup/actions';
import {
	FollowUpEntityType,
	FollowUpUniqueCol,
	FollowUpAction,
	FollowUpType,
	FilterType,
	LastAction,
	BMDData,
} from 'us.collection.followup/constants';
import { FollowUpActionHistory } from 'us.collection.followup/components/FollowUps/Components';
import { ActionButton } from 'us.collection.followup/components';
import { useSignalRTrigger } from 'us.common/SignalRProvider';
import { NotificationTriggerType } from 'us.common/constants/Component/Notification';
import { ExecutionSource, Mode } from 'us.common/constants';
import { setPendingFollowups } from 'us.collection.followup/functions';
import { useFormikContext } from 'formik';
import { VList } from 'virtuallist-antd';
import { RootState } from 'us.helper/types';

const {
	result,
	filters,
	historyDrawer,
	reImport,
	lastActions,
	activity,
	action,
	bmd,
	columns,
	tableFilters,
	tableSorters,
} = followUps;

const FollowUpResult: React.FC<PropsFromRedux & IFollowUpResult> = (props) => {
	const { t } = useTranslation();

	const [selectedRowKeys, setSelectedRowKeys] = useState<Array<any>>([]);
	const [executeAction, setExecuteAction] = useState<string>('');
	const { followUpId } = useParams<{ followUpId?: string }>();
	const { setTrigger } = useSignalRTrigger();
	const [isVisibleColumnsSelect, setIsVisibleColumnsSelect] =
		useState<boolean>(false);
	const scDropdown = useRef<any>(null);
	const { setFieldValue } = useFormikContext<any>();

	const {
		result,
		columns,
		maxFollowUpscount,
		isFollowupReImporting,
		filters,
		historyDrawer,
		followUpNavigation,
		tableFilters,
		tableSorters,
		tableDataWithTableFilters,
		setFilters,
		manageFollowUpActionHistoryDrawer,
		getFollowUpResult,
		reImportFollowUp,
		updateLastAction,
		updateFollowupsLastActions,
		actionExecute,
		getActivityBMD,
		setTableColumns,
		setTableFilters,
		setTableSorters,
		reimportingFollowupId
	} = props;

	const { data, dataSource, isFetching } = result;
	const { entityType, followupData, followupType } = data;
	const {
		caseStates,
		entityType: entityTypeInFilter,
		userOwnedClaims,
		actionStatus,
	} = filters;
	const { open, sentence, nextDueDate, court, close } = caseStates;
	const { isBack } = followUpNavigation;
	const { data: tableColumnData, tableColumnWithStates } = columns;

	const triggers = [
		{
			name: NotificationTriggerType.ON_ACTIVITY_EXECUTION,
			callBack: (content: any) => {
				const { batchId } = JSON.parse(content);
				batchId &&
					updateLastAction &&
					updateLastAction({ batchId });
			},
		},
	];

	useEffect(() => {
		if (!isBack) {
			setTrigger(triggers);
			getActivityBMD &&
				getActivityBMD([
					{
						entityType: BMDData.EntityType,
						categoryName:
							BMDData.CategoryName,
						bmdName: BMDData.BMDName,
						displayKeyName:
							BMDData.DisplayKeyName,
					},
				]);
		}
	}, []);

	useEffect(() => {
		setSelectedRowKeys([]);
	}, [dataSource]);

	useEffect(() => {
		if (!isBack) {
			followUpId;
			getFollowUpResult && getFollowUpResult({ followUpId });
		}
	}, [followUpId]);

	const handleSort: IOnSort = (sortData, dataSource, sorters) => {
		const dataWithSorters = sortData(dataSource);
		setTableSorters &&
			setTableSorters({ sorters, dataWithSorters });
		return dataWithSorters;
	};

	const handleFilter: IOnFilter = (searchData, dataSource, filters) => {
		const dataWithFilters = searchData(dataSource);
		setTableFilters &&
			setTableFilters({ filters, dataWithFilters });
		return dataWithFilters;
	};

	const followupActionHistoryClose = () => {
		manageFollowUpActionHistoryDrawer &&
			manageFollowUpActionHistoryDrawer({ isAction: false });
	};

	const onSelectChange = (selectedRowKeys: any, selectedRows: any[]) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		onChange: onSelectChange,
		hideDefaultSelections: true,
	};

	const vComponents = useMemo(() => {
		return VList({
			resetTopWhenDataChange: false,
			height: 'calc(100vh - 320px)', // same value for scroll-y
		});
	}, [dataSource]);

	const handleCaseStateFilters = (
		filter: any,
		value: string | boolean
	) => {
		switch (filter) {
			case FilterType.Open:
			case FilterType.Sentence:
			case FilterType.NextDueDate:
			case FilterType.Court:
			case FilterType.Close:
				setFilters &&
					setFilters({
						...filters,
						caseStates: {
							...caseStates,
							[`${filter}`]: value,
						},
					});
				break;
			case FilterType.SearchText:
			case FilterType.UserOwnedClaims:
				(entityType == FollowUpEntityType.CASE ||
					entityType ==
						FollowUpEntityType.INVOICE) &&
					setFilters &&
					setFilters({
						...filters,
						[`${filter}`]: value,
					});
				break;
			case FilterType.EntityType:
			case FilterType.ActionStatus:
				setFilters &&
					setFilters({
						...filters,
						[`${filter}`]: value,
					});
			default:
				setTableColumns &&
					setTableColumns({
						filter,
						value,
					});
				break;
		}
	};

	const overLayElement: HTMLElement = document.getElementById(
		'column-dropdown'
	) as HTMLElement;
	useEffect(() => {
		document.addEventListener('mousedown', closeOpenDropdown);
	}, []);
	const closeOpenDropdown = (e: any) => {
		if (scDropdown.current?.contains(e.target)) {
			return;
		}
		setIsVisibleColumnsSelect(false);
	};

	return (
		<>
			{followupData.length === 0 && !isFetching && (
				<div className='fl-container'>
					<div className='flc-body'>
						<div className='followup-dashboard-msg'>
							<$Empty
								className='pt-5'
								description={`${t(
									'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.NO_DATA'
								)}`}
							/>
							{followupType !=
								FollowUpType.STATIC_FOLLOW_UP && (
								<div className='mt-4 text-center'>
									<$Button
										type='dashed'
										size='small'
										icon={
											<ImportOutlined />
										}
										loading={
											isFollowupReImporting && reimportingFollowupId == followUpId
										}
										onClick={() =>
											reImportFollowUp &&
											reImportFollowUp(
												{
													followUpId,
												}
											)
										}
										className='mr-1 ml-3'>
										{t(
											'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.RE_IMPORT_FOLLOWUP'
										)}
									</$Button>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
			{(followupData.length > 0 || isFetching) && (
				<div className='fl-container'>
					{!isFetching && (
						<div className='flc-header'>
							<div className='flex-grow-1 d-flex align-items-center'>
								<$Input
									name='searchText'
									size='small'
									allowClear={
										true
									}
									placeholder={t(
										'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.SEARCH_IN_LIST'
									)}
									onChange={(
										textObj: any
									) => {
										setFieldValue(
											'searchText',
											textObj
												.target
												?.value
										);
										handleCaseStateFilters(
											'searchText',
											textObj
												.target
												?.value
										);
									}}
									style={{
										width: 200,
										position: 'relative',
										top: '-3px',
									}}
									data-testid='searchText-input'
								/>
								{!isFetching &&
									[
										FollowUpEntityType.CASE,
										FollowUpEntityType.INVOICE,
									].includes(
										entityType
									) && (
										<div className='ml-2'>
											<$Tooltip
												title={t(
													'US.COLLECTION.COMMON:COMMON.OPEN'
												)}
												placement='top'>
												<span>
													<$Checkbox
														name='open'
														checked={
															open
														}
														className='ml-0 mr-0 checkbox-case-open'
														onChange={(
															e: any
														) =>
															handleCaseStateFilters(
																'open',
																e
																	.target
																	.checked
															)
														}
														data-testid='searchFilter-caseOpen'
													/>
												</span>
											</$Tooltip>
											<$Tooltip
												title={t(
													'US.COLLECTION.COMMON:COMMON.SENTENCE'
												)}>
												<span>
													<$Checkbox
														name='sentence'
														checked={
															sentence
														}
														className='ml-1 mr-0 checkbox-case-sentence'
														onChange={(
															e: any
														) =>
															handleCaseStateFilters(
																'sentence',
																e
																	.target
																	.checked
															)
														}
														data-testid='searchFilter-caseSentence'
													/>
												</span>
											</$Tooltip>
											<$Tooltip
												title={t(
													'US.COLLECTION.COMMON:COMMON.NEXT_DUE_DATE'
												)}>
												<span>
													<$Checkbox
														name='nextDueDate'
														checked={
															nextDueDate
														}
														className='ml-1 mr-0 checkbox-case-nextduedate'
														onChange={(
															e: any
														) =>
															handleCaseStateFilters(
																'nextDueDate',
																e
																	.target
																	.checked
															)
														}
														data-testid='searchFilter-nextDueDate'
													/>
												</span>
											</$Tooltip>
											<$Tooltip
												title={t(
													'US.COLLECTION.COMMON:COMMON.COURT'
												)}>
												<span>
													<$Checkbox
														name='court'
														checked={
															court
														}
														className='ml-1 mr-0 checkbox-case-court'
														onChange={(
															e: any
														) =>
															handleCaseStateFilters(
																'court',
																e
																	.target
																	.checked
															)
														}
														data-testid='searchFilter-caseCourt'
													/>
												</span>
											</$Tooltip>
											<$Tooltip
												title={t(
													'US.COLLECTION.COMMON:COMMON.CLOSE'
												)}>
												<span>
													<$Checkbox
														name='close'
														checked={
															close
														}
														className='ml-1 mr-0 checkbox-case-close'
														onChange={(
															e: any
														) =>
															handleCaseStateFilters(
																'close',
																e
																	.target
																	.checked
															)
														}
														data-testid='searchFilter-caseClose'
													/>
												</span>
											</$Tooltip>
										</div>
									)}

								<$Radio
									name='entityType'
									size='small'
									className='ml-2'
									options={
										entityType ==
										FollowUpEntityType.CASE
											? [
													{
														label: t(
															`US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.CASE`
														),
														value: FollowUpEntityType.CASE,
														dataTestId: `entityType-${FollowUpEntityType.CASE}-radio`,
													},
													{
														label: t(
															`US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.INVOICE`
														),
														value: FollowUpEntityType.INVOICE,
														dataTestId: `entityType-${FollowUpEntityType.INVOICE}-radio`,
													},
											  ]
											: [
													{
														label: t(
															`US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.${entityType.toUpperCase()}`
														),
														value: entityType,
														dataTestId: `entityType-${entityType}-radio`,
													},
											  ]
									}
									radioButton='true'
									optionValue='value'
									optionContent={(
										item: any,
										index: number
									) =>
										item.label
									}
									value={
										entityTypeInFilter
									}
									buttonStyle='solid'
									onChange={(
										e: any
									) =>
										entityTypeInFilter !=
											e
												.target
												.value &&
										handleCaseStateFilters(
											'entityType',
											e
												.target
												.value
										)
									}
									style={{
										marginBottom: 7,
									}}
								/>
								<$Tooltip
									title={t(
										'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.STATUS'
									)}>
									<$Select
										name='actionStatus'
										className='ml-2'
										style={{
											width: 180,
										}}
										optionText='label'
										optionValue='value'
										defaultValue='all'
										value={
											actionStatus
										}
										allOption={
											true
										}
										placeholder={t(
											'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.LAST_ACTIVITY_EXECUTION'
										)}
										options={[
											{
												label: t(
													'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.SUCCEED'
												),
												value: LastAction.EXECUTED,
											},
											{
												label: t(
													'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.PENDING'
												),
												value: LastAction.PENDING,
											},
											{
												label: t(
													'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.FAILED'
												),
												value: LastAction.FAILED,
											},
											{
												label: t(
													'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.OTHER'
												),
												value: LastAction.OTHER,
											},
										]}
										onChange={(
											status: string
										) => {
											handleCaseStateFilters(
												'actionStatus',
												status
											);
										}}
									/>
								</$Tooltip>
								{!isFetching &&
									[
										FollowUpEntityType.CASE,
										FollowUpEntityType.INVOICE,
									].includes(
										entityType
									) && (
										<div className='d-flex align-items-start ml-4'>
											<div
												style={{
													marginTop: -3,
												}}>
												<$Switch
													size='small'
													name='userOwnedClaims'
													onChange={(
														isChecked: boolean
													) =>
														handleCaseStateFilters(
															'userOwnedClaims',
															isChecked
														)
													}
													checked={
														userOwnedClaims
													}
												/>
											</div>

											<div className='pl-2'>
												<div>
													{t(
														'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.MY_CLAIMS'
													)}
												</div>
											</div>
										</div>
									)}
							</div>
							<div>
								{followupType !=
									FollowUpType.STATIC_FOLLOW_UP && (
									<$Tooltip
										title={t(
											'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.RE_IMPORT_FOLLOWUP'
										)}>
										<$Button
											type='dashed'
											size='small'
											icon={
												<ImportOutlined />
											}
											loading={
												isFollowupReImporting && reimportingFollowupId == followUpId
											}
											onClick={() =>
												reImportFollowUp &&
												reImportFollowUp(
													{
														followUpId,
													}
												)
											}
											className='mr-1 ml-3'
										/>
									</$Tooltip>
								)}
								<$Tooltip
									title={t(
										'COMMON.REFRESH'
									)}>
									<$Button
										type='dashed'
										size='small'
										icon={
											<ReloadOutlined />
										}
										onClick={() => {
											followUpId &&
												getFollowUpResult &&
												getFollowUpResult(
													{
														followUpId,
													}
												);
										}}
									/>
								</$Tooltip>
							</div>
						</div>
					)}
					<div className='flc-body'>
						{!isFetching && (
							<div
								className='d-flex align-items-center justify-content-between'
								data-testid='selectedFollowUpCount-div'>
								<div className='flex-grow-1 d-flex align-items-center'>
									<div className='flc-count mr-5'>
										<span>
											{
												selectedRowKeys.length
											}{' '}
											{t(
												'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.SELECTED'
											)}
										</span>
										<span className='bui-label mx-2'>
											/
										</span>
										<span className='bui-label'>
											{
												dataSource?.length
											}{' '}
											{t(
												'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.TOTAL'
											)}
										</span>
									</div>
									<ActivityExecution
										mode={
											Mode.Initial
										}
										isHideTable={
											false
										}
										isDisabled={
											selectedRowKeys.length ===
											0
										}
										isBulkExecutionEnabled={
											true
										}
										isGroupActivityOptions={
											true
										}
										isActivityOptionWithCode={
											true
										}
										isHideDatePicker={
											false
										}
										activityAPIRequest={{
											endPoint: `activitiesforfollowup/${followUpId}/${false}`,
										}}
										executeActivityEndPoint='bulkactivity'
										groupingProperty={{
											groupingLabel:
												'activityType',
											groupingValue:
												'activityType',
											tableGrouping:
												'type',
											optionsGrouping:
												'activityType',
										}}
										isPopConfirmEnabled={
											true
										}
										isPopConfirmEnabledDropDown={
											true
										}
										propConfirmDetail={{
											title: 'DO_YOU_WANT_TO_REMOVE_RESULTS_FROM_THE_FOLLOWUP_AFTER_SUCCESSFUL_EXECUTION',
											isOnCancelSubmit:
												true,
										}}
										filter={
											entityTypeInFilter
										}
										reduxActivities={[
											{
												action: action.execute,
											},
											{
												action: updateFollowupsLastActions,
												method: setPendingFollowups,
											},
										]}
										isActionExecution={
											true
										}
										selectedEntities={
											selectedRowKeys
										}
										externalData={
											dataSource
										}
										maxEntityCountExecuteAtOnce={
											maxFollowUpscount
										}
										bulkExecutionSource={
											ExecutionSource.FollowUpBulkExecution
										}
									/>
								</div>
								<div
									className='d-flex align-items-center'
									id='column-dropdown'
									ref={
										scDropdown
									}>
									<$Dropdown
										overlay={
											<div className='ant-dropdown-menu'>
												<div className='p-2'>
													{tableColumnWithStates &&
														tableColumnWithStates.map(
															(
																header: {
																	key: boolean;
																},
																index: number
															) => {
																const [
																	key,
																	value,
																] =
																	Object.entries(
																		header
																	)[0];
																return (
																	key !=
																		FollowUpUniqueCol.FOLLOWUP_ID &&
																	key !=
																		FollowUpUniqueCol.FOLLOWUP_CASE_STATE &&
																	key !=
																		FollowUpUniqueCol.CASE_TYPE && (
																		<div className='mb-1'>
																			<$Checkbox
																				name={
																					key
																				}
																				onChange={(
																					column: any
																				) =>
																					handleCaseStateFilters(
																						key,
																						column
																							.target
																							.checked
																					)
																				}
																				checked={
																					value
																						? value
																						: false
																				}>
																				{key ==
																				FollowUpUniqueCol.CASE_NO
																					? 'Caseid'
																					: key ==
																					  FollowUpUniqueCol.EX_CASE_NO
																					? 'Caseno'
																					: key}
																			</$Checkbox>
																		</div>
																	)
																);
															}
														)}
												</div>
											</div>
										}
										getPopupContainer={() =>
											overLayElement
										}
										placement='bottomRight'
										trigger={[
											'click',
										]}
										arrow
										overlayClassName=''
										visible={
											isVisibleColumnsSelect
										}>
										<$Button
											size='small'
											onClick={() =>
												setIsVisibleColumnsSelect(
													!isVisibleColumnsSelect
												)
											}
											className='flc-select-columns'>
											{t(
												'US.COLLECTION.COMMON:COMMON.SELECT_COLUMNS'
											)}
											<small className='ml-2'>
												{isVisibleColumnsSelect ? (
													<UpOutlined />
												) : (
													<DownOutlined />
												)}
											</small>
										</$Button>
									</$Dropdown>
								</div>
							</div>
						)}

						<div>
							<$Skeleton
								loading={
									isFetching
								}
								active
								paragraph={{
									rows: 2,
								}}>
								<$TableTree
									rowKey={(
										record: any
									) => {
										return record.hasOwnProperty(
											'children'
										)
											? record.key
											: record.Followupid;
									}}
									data={
										dataSource
									}
									size='small'
									className='mt-3'
									onSort={
										handleSort
									}
									onFilter={
										handleFilter
									}
									filterOnType={
										true
									}
									resetOnSourceChange={
										true
									}
									bordered
									pagination={
										false
									}
									scroll={{
										x: 600,
										y: `calc(100vh - ${
											followupType !=
											FollowUpType.DYNAMIC_FOLLOW_UP
												? '340px'
												: '292px'
										})`,
									}}
									columns={
										tableColumnData
									}
									firstColSkipFilterProps={
										-1
									}
									rowSelection={{
										type: 'checkbox',
										...rowSelection,
									}}
									onRow={(
										record: any,
										rowIndex: any
									) => {
										return {
											onDoubleClick:
												(
													event: any
												) => {},
										};
									}}
									data-testid='followUpResult-table'
									defaultFilters={
										tableFilters?.data
									}
									defaultSorters={
										tableSorters?.data
									}
									defaultData={
										tableDataWithTableFilters?.data
									}
									components={
										vComponents
									}
								/>
							</$Skeleton>
						</div>
					</div>

					{followupType !=
						FollowUpType.DYNAMIC_FOLLOW_UP && (
						<div className='flc-footer'>
							<div>
								<ActionButton
									followUpId={
										followUpId
									}
									followupdataIds={
										selectedRowKeys
									}
									visible={
										FollowUpAction.POSTPONE ===
										executeAction
									}
									type={
										FollowUpAction.POSTPONE
									}
									onVisibleChange={(
										visible: boolean
									) => {
										setExecuteAction(
											visible
												? FollowUpAction.POSTPONE
												: ''
										);
									}}
								/>
								<ActionButton
									followUpId={
										followUpId
									}
									followupdataIds={
										selectedRowKeys
									}
									visible={
										FollowUpAction.REMOVE ===
										executeAction
									}
									type={
										FollowUpAction.REMOVE
									}
									onVisibleChange={(
										visible: boolean
									) => {
										setExecuteAction(
											visible
												? FollowUpAction.REMOVE
												: ''
										);
									}}
								/>
								<ActionButton
									followUpId={
										followUpId
									}
									followupdataIds={
										selectedRowKeys
									}
									visible={
										FollowUpAction.DONE ===
										executeAction
									}
									type={
										FollowUpAction.DONE
									}
									onVisibleChange={(
										visible: boolean
									) => {
										setExecuteAction(
											visible
												? FollowUpAction.DONE
												: ''
										);
									}}
								/>
							</div>
						</div>
					)}
				</div>
			)}
			<$Drawer
				title={t(
					'US.COLLECTION.FOLLOWUP:FOLLOWUP_HOME.ACTION_HISTORY'
				)}
				width={'90%'}
				visible={historyDrawer.isAction}
				onClose={followupActionHistoryClose}
				destroyOnClose>
				<FollowUpActionHistory
					onClose={followupActionHistoryClose}
				/>
			</$Drawer>
		</>
	);
};

const mapStateToProps = (state: RootState) => {
	const { followUps, common } = state;
	const { currentDateFormat } = common;
	const {
		list,
		result,
		columns,
		filters,
		historyDrawer,
		maxFollowUpscount,
		isFollowupReImporting,
		followUpNavigation,
		tableFilters,
		tableSorters,
		tableDataWithTableFilters,
		reimportingFollowupId
	} = followUps;
	return {
		list,
		result,
		columns,
		filters: filters.data,
		historyDrawer,
		currentDateFormat,
		maxFollowUpscount,
		isFollowupReImporting,
		followUpNavigation,
		tableFilters,
		tableSorters,
		tableDataWithTableFilters,
		reimportingFollowupId
	};
};

const mapDispatchToProps = {
	getFollowUpResult: result.get,
	setFilters: filters.set,
	manageFollowUpActionHistoryDrawer: historyDrawer.manage,
	reImportFollowUp: reImport.execute,
	updateLastAction: lastActions.get,
	setActivityExecutionAction: activity.set,
	actionExecute: action.execute,
	updateFollowupsLastActions: result.setLastActions,
	getActivityBMD: bmd.get,
	setTableColumns: columns.set,
	setTableFilters: tableFilters.set,
	setTableSorters: tableSorters.set,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FollowUpResult);
