import * as Actions from "./actions";
import * as API from "./services";
import * as Components from "./components";
import * as Containers from "./containers";
import { default as Constants } from "./constants";
import * as Reducers from "./reducers";
import * as Routes from "./routes";
import { default as Sagas } from "./sagas";

export default {
  Actions,
  API,
  Components,
  Constants,
  Containers,
  Reducers,
  Routes,
  Sagas,
};
