import React from 'react';
import { ITransaction } from 'us.collection.transactions/interfaces';
import Common from 'us.common';

import { CaseType } from 'us.helper/types/enums';
import { TileView } from './Components';

const { $Row, $Col } = Common.Components;

const TransactionSummaryHeader: React.FC<{
	transaction: ITransaction;
	caseType: CaseType;
}> = ({ transaction, caseType }) => {
	const {
		invoiceNumber,
		kid,
		type,
		voucherID,
		voucherDate,
		dueDate,
		regDate,
		paid,
		paymentID,
		balance,
		amount,
		description,
		createdBy,
		isPayment,
	} = transaction ?? {};
	return (
		<div className='tm-summery'>
			<$Row gutter={16} className='tm-summery-item'>
				<$Col span={4}>
					<TileView
						value={invoiceNumber}
						label='US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REF_NO'
						type='string'
						kid={caseType === CaseType.S ? kid : undefined}
					/>
				</$Col>

				<$Col span={4}>
					<TileView
						value={type}
						label='US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TYP'
						type='string'
					/>
				</$Col>

				<$Col span={4}>
					<TileView
						value={voucherID > 0 ? voucherID : undefined}
						label='US.COLLECTION.TRANSACTIONS:TRANSACTIONS.VOUCHERNO'
						type='string'
					/>
				</$Col>

				<$Col span={3}>
					<TileView
						value={voucherDate}
						label='US.COLLECTION.TRANSACTIONS:TRANSACTIONS.VOUCHERDATE'
						type='date'
					/>
				</$Col>

				<$Col span={3}>
					<TileView
						value={dueDate}
						label='US.COLLECTION.TRANSACTIONS:TRANSACTIONS.DUEDATE'
						type='date'
					/>
				</$Col>

				<$Col span={3}>
					<TileView
						value={regDate}
						label='US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REGDATE'
						type='date'
					/>
				</$Col>

				<$Col span={3}>
					<TileView
						value={paymentID > 0 ? paymentID : undefined}
						label='US.COLLECTION.TRANSACTIONS:TRANSACTIONS.PAYMENTID'
						type='string'
					/>
				</$Col>
			</$Row>

			<$Row gutter={16} className='tm-summery-item mt-3'>
				<$Col span={4}>
					<TileView
						value={isPayment ? paid : amount}
						label='US.COLLECTION.TRANSACTIONS:TRANSACTIONS.AMOUNT'
						type='amount'
					/>
				</$Col>

				<$Col span={4}>
					<TileView
						value={balance}
						label='US.COLLECTION.TRANSACTIONS:TRANSACTIONS.BALANCE'
						type='amount'
					/>
				</$Col>

				<$Col span={4}>
					<TileView
						value={createdBy}
						label='US.COLLECTION.TRANSACTIONS:TRANSACTIONS.CREATEDBY'
						type='string'
					/>
				</$Col>

				<$Col span={12}>
					<TileView
						value={description}
						label='US.COLLECTION.TRANSACTIONS:TRANSACTIONS.DESCRIPTION'
						type='string'
					/>
				</$Col>
			</$Row>
		</div>
	);
};

export default TransactionSummaryHeader;
