import { initialState } from "us.collection.transactions/reducers/Transactions/State";
import {
  Transaction,
  TransactionType,
  InterestApplication,
} from "us.collection.transactions/constants/Actions";
import moment from "moment";
import { mapPopOverValues } from "us.collection.transactions/functions";
import { getDefaultExpandedRowKeys } from "us.collection.transactions/components/ArTransactions/Functions";
import { IInitialState } from "./Interfaces";

const {
  SAVE_TRANSACTIONS,
  SAVE_TRANSACTIONS_SUCCESS,
  SAVE_TRANSACTIONS_FAIL,
  GET_TRANSACTIONS_BY_ENTITY_NUMBER_AND_ENTITY_TYPE_STRAT,
  GET_TRANSACTIONS_BY_ENTITY_NUMBER_AND_ENTITY_TYPE_SUCCESS,
  GET_TRANSACTIONS_BY_ENTITY_NUMBER_AND_ENTITY_TYPE_FAIL,
  GET_MAPPING_TRANSACTIONS_BY_TRANSACTIONID_STRAT,
  GET_MAPPING_TRANSACTIONS_BY_TRANSACTIONID_SUCCESS,
  GET_MAPPING_TRANSACTIONS_BY_TRANSACTIONID_FAIL,
  MANAGE_TRANSACION_DRAWER,
  MANAGE_MAPPING_TRANSACION_DRAWER,
  MANAGE_EDIT_INTEREST_DRAWER,
  SELECT_TRANSACTION,
  RESET_TRANSACTION_INFO,
  SET_CURRENT_USER,
  GET_AR_DETAILS,
  GET_AR_DETAILS_SUCCESS,
  GET_AR_DETAILS_FAIL,
  GET_POP_OVER_ITEMS,
  GET_POP_OVER_ITEMS_FAIL,
  GET_POP_OVER_ITEMS_SUCCESS,
  MANAGE_DYNAMIC_POP_OVER,
  MANAGE_POP_OVER,
  SAVE_DYNAMIC_POP_OVER,
  SAVE_DYNAMIC_POP_OVER_FAIL,
  SAVE_DYNAMIC_POP_OVER_SUCCESS,
  GET_POP_OVER_FORM_VALUES,
  GET_POP_OVER_FORM_VALUES_FAIL,
  GET_POP_OVER_FORM_VALUES_SUCCESS,

  GET_MAPPING_TRANSACTIONS,
  GET_MAPPING_TRANSACTIONS_SUCCESS,
  GET_MAPPING_TRANSACTIONS_FAIL,

  UPDATE_MAPPING_TRANSACTIONS,
  UPDATE_MAPPING_TRANSACTIONS_SUCCESS,
  UPDATE_MAPPING_TRANSACTIONS_FAIL,

  UPDATE_DRAWER_SETTINGS,

  GET_MAPPING_CASES,
  GET_MAPPING_CASES_SUCCESS,
  GET_MAPPING_CASES_FAIL,

  GET_INTEREST_BMD_VALUE,
  GET_INTEREST_BMD_VALUE_SUCCESS,
  GET_INTEREST_BMD_VALUE_FAIL,

  RESET_INTEREST_PREDICTION,
} = Transaction;

const {
  GET_TRANSACTION_TYPES,
  GET_TRANSACTION_TYPES_SUCCESS,
  GET_TRANSACTION_TYPES_FAIL,
} = TransactionType;

const {
  GET_CALCULATED_INTEREST,
  GET_CALCULATED_INTEREST_FAIL,
  GET_CALCULATED_INTEREST_SUCCESS,

  GET_STANDARD_INTEREST_RATE_BY_DATE,
  GET_STANDARD_INTEREST_RATE_BY_DATE_FAIL,
  GET_STANDARD_INTEREST_RATE_BY_DATE_SUCCESS,

  UPDATE_TRANSACTION_INTEREST_RATE,
  UPDATE_TRANSACTION_INTEREST_RATE_FAIL,
  UPDATE_TRANSACTION_INTEREST_RATE_SUCCESS,
} = InterestApplication;

export default (
  state: IInitialState = initialState,
  { payload, type }: any
) => {
  switch (type) {
    case GET_TRANSACTIONS_BY_ENTITY_NUMBER_AND_ENTITY_TYPE_STRAT:
      return Object.assign({}, state, {
        isFetching: true,
      });

    case GET_TRANSACTIONS_BY_ENTITY_NUMBER_AND_ENTITY_TYPE_SUCCESS:
      return Object.assign({}, state, {
        transactions: payload?.data?.map((trans: any) => {
          return {
            ...trans,
            amount: Number(trans?.amount?.toFixed(2)),
            interestAmount: Number(trans?.interestAmount?.toFixed(2)),
            totalAmount: Number(trans?.totalAmount?.toFixed(2)),
            balance: Number(trans?.balance?.toFixed(2)),
            paid: Number(trans?.paid?.toFixed(2)),
          };
        }),
        isFetching: false,
      });

    case GET_TRANSACTIONS_BY_ENTITY_NUMBER_AND_ENTITY_TYPE_FAIL:
      return Object.assign({}, state, {
        transactions: [...initialState.transactions],
        isFetching: false,
      });

    case GET_TRANSACTION_TYPES:
      return Object.assign({}, state, {
        isTransTypeFetching: true,
      });

    case GET_TRANSACTION_TYPES_SUCCESS:
      return Object.assign({}, state, {
        transactionTypes: payload?.data,
        isTransTypeFetching: false,
      });

    case GET_TRANSACTION_TYPES_FAIL:
      return Object.assign({}, state, {
        isTransTypeFetching: false,
      });

    case GET_MAPPING_TRANSACTIONS_BY_TRANSACTIONID_STRAT:
      return Object.assign({}, state, {
        isMappingTransFetching: true,
      });

    case GET_MAPPING_TRANSACTIONS_BY_TRANSACTIONID_SUCCESS:
      return Object.assign({}, state, {
        mappingTransactions: payload?.data,
        isMappingTransFetching: false,
      });

    case GET_MAPPING_TRANSACTIONS_BY_TRANSACTIONID_FAIL:
      return Object.assign({}, state, {
        mappingTransactions: [...initialState.mappingTransactions],
        isMappingTransFetching: false,
      });

    case MANAGE_TRANSACION_DRAWER:
      return Object.assign({}, state, {
        transactionDrawer: payload?.data,
      });

    case MANAGE_MAPPING_TRANSACION_DRAWER:
      return Object.assign({}, state, {
        mappingTransactionDrawer: payload?.data,
      });
    case MANAGE_EDIT_INTEREST_DRAWER:
      return Object.assign({}, state, {
        editInterestDrawer: payload?.data,
        interestApplication: initialState.interestApplication,
      });

    case SELECT_TRANSACTION:
      return Object.assign({}, state, {
        transactionDetails: {
          ...payload?.data,
          voucherDate: moment(payload?.data?.voucherDate),
          dueDate: moment(payload?.data?.dueDate),
        },
      });

    case RESET_TRANSACTION_INFO:
      return Object.assign({}, state, {
        transactionDetails: {
          ...initialState.transactionDetails,
        },
        interestApplication: initialState.interestApplication,
        mappingTransactions: [],
      });

    case SET_CURRENT_USER:
      return Object.assign({}, state, {
        user: payload?.data,
      });

    case GET_AR_DETAILS:
      return Object.assign({}, state, {
        arDetails: { ...initialState.arDetails },
        isArDetailsFetching: true,
      });

    case GET_AR_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        arDetails: payload?.data,
        isArDetailsFetching: false,
      });

    case GET_AR_DETAILS_FAIL:
      return Object.assign({}, state, {
        arDetails: { ...initialState.arDetails },
        isArDetailsFetching: false,
      });

    case SAVE_TRANSACTIONS:
      return Object.assign({}, state, {
        ...state,
        isSaving: true,
      });

    case SAVE_TRANSACTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isSaving: false,
      });

    case SAVE_TRANSACTIONS_FAIL:
      return Object.assign({}, state, {
        ...state,
        isSaving: false,
      });
    case GET_POP_OVER_ITEMS:
      return Object.assign({}, state, {
        popOverMenu: {
          ...initialState.popOverMenu,
          visiblePopOver: state.popOverMenu.visiblePopOver,
          isLoading: true,
        },
      });
    case GET_POP_OVER_ITEMS_SUCCESS:
      return Object.assign({}, state, {
        popOverMenu: {
          ...initialState.popOverMenu,
          visiblePopOver: state.popOverMenu.visiblePopOver,
          items: [...initialState.popOverMenu.items, ...payload.data],
          isLoading: false,
        },
      });
    case GET_POP_OVER_ITEMS_FAIL:
      return Object.assign({}, state, {
        popOverMenu: {
          ...initialState.popOverMenu,
          isLoading: false,
        },
      });
    case GET_POP_OVER_FORM_VALUES:
      return Object.assign({}, state, {
        popOverMenu: {
          ...state.popOverMenu,
          isValuesLoading: true,
        },
      });
    case GET_POP_OVER_FORM_VALUES_SUCCESS:
      return Object.assign({}, state, {
        popOverMenu: {
          ...state.popOverMenu,
          items: mapPopOverValues(
            state.popOverMenu.items,
            payload.data.properties,
            payload.data.activityCode
          ),
          isValuesLoading: false,
        },
      });
    case GET_POP_OVER_FORM_VALUES_FAIL:
      return Object.assign({}, state, {
        popOverMenu: {
          ...initialState.popOverMenu,
          isValuesLoading: false,
        },
      });
    case MANAGE_DYNAMIC_POP_OVER:
      return Object.assign({}, state, {
        popOverMenu: {
          ...state.popOverMenu,
          visibleDynamicPopOver: payload.data,
        },
      });
    case MANAGE_POP_OVER:
      return Object.assign({}, state, {
        popOverMenu: {
          ...state.popOverMenu,
          visiblePopOver: payload.data,
        },
      });
    case SAVE_DYNAMIC_POP_OVER:
      return Object.assign({}, state, {
        popOverMenu: {
          ...state.popOverMenu,
          isPopOverSaving: true,
        },
      });
    case SAVE_DYNAMIC_POP_OVER_SUCCESS:
      return Object.assign({}, state, {
        popOverMenu: {
          ...state.popOverMenu,
          visibleDynamicPopOver: -1,
          visiblePopOver: -1,
          isPopOverSaving: false,
        },
      });
    case SAVE_DYNAMIC_POP_OVER_FAIL:
      return Object.assign({}, state, {
        popOverMenu: {
          ...state.popOverMenu,
          visibleDynamicPopOver: -1,
          isPopOverSaving: false,
        },
      });
    case GET_MAPPING_TRANSACTIONS:
      return Object.assign({}, state, {
        mapping: {
          ...state.mapping,
          defaultExpandedRowKeys: [],
          transactions: {
            ...state.mapping.transactions,
            isLoading: true,
          },
        },
      });
    case GET_MAPPING_TRANSACTIONS_SUCCESS:
      return Object.assign({}, state, {
        mapping: {
          ...state.mapping,
          defaultExpandedRowKeys: getDefaultExpandedRowKeys(payload.data),
          transactions: payload,
        },
      });
    case GET_MAPPING_TRANSACTIONS_FAIL:
      return Object.assign({}, state, {
        mapping: {
          ...state.mapping,
          transactions: payload,
        },
      });
    case UPDATE_MAPPING_TRANSACTIONS:
      return Object.assign({}, state, {
        mapping: {
          ...state.mapping,
          updatedTransactions: {
            ...state.mapping.updatedTransactions,
            isLoading: true,
          },
        },
      });
    case UPDATE_MAPPING_TRANSACTIONS_SUCCESS:
      return Object.assign({}, state, {
        mapping: {
          ...state.mapping,
          updatedTransactions: payload,
          defaultExpandedRowKeys: [],
          transactions: {
            isLoading: true,
            data: [],
          },
        },
        drawerSettings: initialState.drawerSettings,
      });
    case UPDATE_MAPPING_TRANSACTIONS_FAIL:
      return Object.assign({}, state, {
        mapping: {
          ...state.mapping,
          updatedTransactions: payload,
        },
      });
    case UPDATE_DRAWER_SETTINGS:
      return Object.assign({}, state, {
        drawerSettings: {
          ...state.drawerSettings,
          ...payload.data,
        },
      });
    case GET_MAPPING_CASES:
      return Object.assign({}, state, {
        mapping: {
          ...state.mapping,
          defaultExpandedRowKeys: [],
          cases: {
            data: [],
            isLoading: true,
          },
        },
      });
    case GET_MAPPING_CASES_SUCCESS:
      return Object.assign({}, state, {
        mapping: {
          ...state.mapping,
          defaultExpandedRowKeys: getDefaultExpandedRowKeys(payload.data, true),
          cases: payload,
        },
      });
    case GET_MAPPING_CASES_FAIL:
      return Object.assign({}, state, {
        mapping: {
          ...state.mapping,
          cases: payload,
        },
      });
    case GET_INTEREST_BMD_VALUE:
      return Object.assign({}, state, {
        interestRateBMD: {
          value: 0,
          isLoading: true,
        },
      });
    case GET_INTEREST_BMD_VALUE_SUCCESS:
      return Object.assign({}, state, {
        interestRateBMD: {
          value: payload?.data[0]?.value
            ? parseFloat(payload?.data[0]?.value)
            : 0,
          isLoading: false,
        },
      });
    case GET_INTEREST_BMD_VALUE_FAIL:
      return Object.assign({}, state, {
        interestRateBMD: { value: 0, isLoading: false },
      });
    case GET_CALCULATED_INTEREST:
      return Object.assign({}, state, {
        interestApplication: {
          ...state.interestApplication,
          interests: {
            data: [],
            isLoading: true,
          },
        },
      });
    case GET_CALCULATED_INTEREST_SUCCESS:
      return Object.assign({}, state, {
        interestApplication: {
          ...state.interestApplication,
          interests: {
            data: payload.data.interests,
            isLoading: false,
          },
        },
      });
    case GET_CALCULATED_INTEREST_FAIL:
      return Object.assign({}, state, {
        interestApplication: {
          ...state.interestApplication,
          interests: {
            data: [],
            isLoading: false,
          },
        },
      });
    case GET_STANDARD_INTEREST_RATE_BY_DATE:
      return Object.assign({}, state, {
        interestApplication: {
          ...state.interestApplication,
          standardInterest: {
            data: {},
            isLoading: true,
          },
        },
      });
    case GET_STANDARD_INTEREST_RATE_BY_DATE_SUCCESS:
      return Object.assign({}, state, {
        interestApplication: {
          ...state.interestApplication,
          standardInterest: {
            data: payload.data,
            isLoading: false,
          },
        },
      });
    case GET_STANDARD_INTEREST_RATE_BY_DATE_FAIL:
      return Object.assign({}, state, {
        interestApplication: {
          ...state.interestApplication,
          standardInterest: {
            data: {},
            isLoading: false,
          },
        },
      });
    case UPDATE_TRANSACTION_INTEREST_RATE:
      return Object.assign({}, state, {
        interestApplication: {
          ...state.interestApplication,
          interestRateUpdate: {
            data: {},
            isLoading: true,
          },
        },
      });
    case UPDATE_TRANSACTION_INTEREST_RATE_SUCCESS:
      return Object.assign({}, state, {
        interestApplication: {
          ...state.interestApplication,
          interestRateUpdate: {
            data: payload.data,
            isLoading: false,
          },
        },
        editInterestDrawer: initialState.editInterestDrawer,
      });
    case UPDATE_TRANSACTION_INTEREST_RATE_FAIL:
      return Object.assign({}, state, {
        interestApplication: {
          ...state.interestApplication,
          interestRateUpdate: {
            data: payload.data,
            isLoading: false,
          },
        },
      });
    case RESET_INTEREST_PREDICTION:
      return Object.assign({}, state, {
        interestApplication: {
          ...state.interestApplication,
          interests: {
            data: [],
            isLoading: false,
          },
        },
      });
    default:
      return state;
  }
};
