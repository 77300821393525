import { DrawerTypes } from "us.collection/constants";
import { IGetBMDPropertyDetailsObject } from "../Interfaces";

/**
 * @function
 * @description - to get the bmd property deteail API request object
 * @param {*} this - property object
 * @return {*}  {IGetBMDPropertyDetailsObject} - obejct for the api call
 */
export function GetBMDPropertyDetailsObject(this: any): IGetBMDPropertyDetailsObject {
  return {
    entityId: this.drawerType === DrawerTypes.BMD_COUNT ? this.dataIndexIdForCount : parseInt(this.entityId) <= 0 ? 1 : parseInt(this.entityId),
    entityType: this.drawerType === DrawerTypes.BMD_COUNT ? this.dataIndex :  this.entityType,
    propertyId: this.id,
    overrideLevel: this.drawerType === DrawerTypes.BMD_COUNT ? this.dataIndex : this.overrideLevel,
    bmdGroupId: this.drawerType === DrawerTypes.BMD_COUNT ? this.groupIdForCount : this.bmdGroupId,
  };
}
