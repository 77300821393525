import Admin from 'us.collection.admin/sagas';
import Case from 'us.collection.case/sagas';
import Creditor from 'us.collection.creditor/sagas';
import Debtor from 'us.collection.debtor/sagas';
import Documents from 'us.collection.documents/sagas';
import Followup from 'us.collection.followup/sagas';
import Partpayments from 'us.collection.partpayments/sagas';
import RoutinesAndActivities from 'us.collection.routines-and-activities/sagas';
import Transactios from 'us.collection.transactions/sagas';
import { PaymentAgreementSagas } from './PaymentAgreement';
import { ObsoleteDateSagas } from './ObsoleteDate';
import { TransTypesSagas } from './Transactions';
import { ActivitySagas } from './ActivityExecution';
import { WorkflowExecutionSagas } from './Workflow';
import { HandlerSagas } from './Handler';
import { ARNoteSagas } from './ARNotes';
import { CloseCaseSagas } from './CloseCase';
import { BMDSagas } from './BMD';
import { FollowupFooterSagas } from './FollowupFooter';
import { DomainViewSagas } from './DomainView';

export default [
	...Admin,
	...Case,
	...Creditor,
	...Debtor,
	...Documents,
	...Followup,
	...Partpayments,
	...RoutinesAndActivities,
	...Transactios,
	...PaymentAgreementSagas,
	...ObsoleteDateSagas,
	...TransTypesSagas,
	...ActivitySagas,
	...WorkflowExecutionSagas,
	...HandlerSagas,
	...ARNoteSagas,
	...CloseCaseSagas,
	...BMDSagas,
	...FollowupFooterSagas,
	...DomainViewSagas,
];
