import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import Common from 'us.common';
import './Home.scss';
import { CreditorInformation } from './Components';
import { IEditCreditorInformation } from 'us.collection.creditor/components/ManageCreditors/Interfaces';
import { AddressHistory, EntityHistory } from 'us.collection.debtor/components';
import { ManageCreditorValidationSchema } from './Validations';
import { isValid, isVisibleContactPerson } from './Functions';
import {
	initialDrawerInfo,
	DrawerTypes,
	initialCreditorData,
} from 'us.collection.creditor/components/ManageCreditors/Constants';
import {
	IDrawerInfo,
	ICreditorData,
} from 'us.collection.creditor/components/ManageCreditors/Components/BasicInformation';
import { getExistingCreditorData } from 'us.collection.creditor/components/ManageCreditors/Functions';
import * as Actions from 'us.collection.creditor/actions';
import { SaveNewCreditor } from 'us.collection.creditor/components/ManageCreditors/Repository';
import { useHistory } from 'react-router-dom';
import { INavigationData } from 'us.collection/interfaces';
import { handleGoBack } from 'us.helper';
import { RootState } from 'us.helper/types';

const {
	$Button,
	$Divider,
	$PageHeader,
	$Affix,
	$Form,
	$Drawer,
	$Popconfirm,
	$Message,
} = Common.Components;

/**
 * @description - Manage Creditor home view component to manage creditor add / edit
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2532114555/Manage+Creditors
 * @author Kaushalya Sandamali <kaushalyas@unicorn-solutions.com>
 * @since 23/03/2022
 */
const ManageCreditorInformation: React.FC<
	PropsFromRedux & IEditCreditorInformation
> = (props) => {
	const { t } = useTranslation([
		'US.COLLECTION.CREDITOR',
		'US.COLLECTION.COMMON',
	]);

	const {
		isDrawer,
		creditorGroupData,
		isGroupDisabled,
		creditorDetails,
		contactPersonsData,
		nextCreditorNo,
		newEntityData,
		isAddingCreditor,
		isUpdatingCreditor,
		addNewCreditor,
		onClose,
		getNextCreditorNo,
		getCaseHandlers,
		updateCreditor,
	} = props;

	const history = useHistory();
	const { location } = history;
	const state = location.state as INavigationData;

	const [historyDrawerInfo, setHistoryDrawerInfo] =
		useState<IDrawerInfo>(initialDrawerInfo);

	const [creditorData, setCData] =
		useState<ICreditorData>(initialCreditorData);

	useEffect(() => {
		if (!isDrawer) {
			if (creditorDetails) {
				setCData(
					getExistingCreditorData(creditorDetails)
				);
			}
		} else {
			setCData({
				...creditorData,
				creditorNumber: nextCreditorNo,
				groupNo: creditorGroupData?.id,
				groupName: creditorGroupData?.groupName,
			});
		}
	}, [creditorDetails, nextCreditorNo]);

	useEffect(() => {
		if (isDrawer) {
			getNextCreditorNo && getNextCreditorNo();
		} else {
			getCaseHandlers && getCaseHandlers();
		}
	}, []);

	/**
	 * @description - minimize the window
	 */
	const minimize = () => {
		handleGoBack(history);
	};

	/**
	 * @description - handle the history view drawer data
	 * @param type - drawer type `DrawerTypes`
	 */
	const historyDrawerHandler = (type: string) => {
		setHistoryDrawerInfo({
			title:
				type === DrawerTypes.ADDRESS_HISTORY
					? t(
							'US.COLLECTION.DEBTOR:INFO.ADDRESSHISTORY'
					  )
					: t(
							'US.COLLECTION.DEBTOR:INFO.ENTITY_HISTORY'
					  ),
			visibility: true,
			type,
		});
	};

	/**
	 * @description - close history drawer
	 */
	const historyDrawerClose = () => {
		setHistoryDrawerInfo(initialDrawerInfo);
	};

	const handleBMDNavigation = () => {
		history.push({
			pathname: `/creditor/${newEntityData?.entRoleId}/bmd`,
			state: {
				...state,
				currentTab: 'creditor',
				creditorId: newEntityData?.entRoleId,
			},
		});
	};

	const handleBMDSettings = (
		updateStatus: boolean,
		values: any,
		restProps: any
	) => {
		if (
			creditorData?.groupNo != values.groupNo &&
			creditorData?.belongsToId != values.belongsToId
		) {
			restProps.setFieldValue('AllowMoveToBMD', updateStatus);
			restProps.setFieldValue(
				'GetBelongsToSettings',
				updateStatus
			);
		} else if (creditorData?.groupNo != values.groupNo) {
			restProps.setFieldValue('AllowMoveToBMD', updateStatus);
		} else if (creditorData?.belongsToId != values.belongsToId) {
			restProps.setFieldValue(
				'GetBelongsToSettings',
				updateStatus
			);
		}
	};

	return (
		<>
			<Formik
				initialValues={{ ...creditorData }}
				enableReinitialize
				validateOnChange
				validateOnBlur
				validateOnMount
				validationSchema={
					ManageCreditorValidationSchema
				}
				onSubmit={(values: any, actions: any) => {
					actions.setSubmitting(true);
					let entRoleIdsOfContactPersons =
						[] as any;
					contactPersonsData?.map((ent: any) => {
						entRoleIdsOfContactPersons.push(
							ent?.entRoleId
						);
					});
					const payload = SaveNewCreditor.call({
						...values,
						entRoleIdsOfContactPersons:
							isDrawer
								? []
								: entRoleIdsOfContactPersons,
					});
					if (values.accountNo == '') {
						$Message.warning({
							content: t(
								'US.COLLECTION.CREDITOR:CREDITOR_INFO.CREDITORS_WITHOUT_A_REMIT_ACCOUNT_NUMBER_ARE_NOT_CONSIDERED_FOR_REMITTING'
							),
						});
					}
					if (isDrawer) {
						addNewCreditor &&
							addNewCreditor({
								payload,
								groupId: values.groupNo,
							});
					} else {
						updateCreditor &&
							updateCreditor(payload);
					}
				}}>
				{({
					values,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					isValidating,
					resetForm,
					...restProps
				}: any) => (
					<>
						<div>
							<$Form
								onSubmit={
									handleSubmit
								}
								autoComplete={
									'off'
								}>
								<$Affix
									offsetTop={
										80
									}>
									<div>
										{!isDrawer && (
											<div className='page-header header-border'>
												<div className='d-flex align-items-center'>
													<$PageHeader
														className='px-0'
														onBack={
															minimize
														}
														title={t(
															'US.COLLECTION.CREDITOR:CREDITOR_INFO.CREDITOR_INFORMATION'
														)}
													/>
													<div>
														<$Divider
															className='bui-devider'
															type='vertical'
														/>
														{(creditorData?.groupNo !=
															values.groupNo ||
															creditorData?.belongsToId !=
																values.belongsToId) && (
															<$Popconfirm
																title={
																	creditorData?.groupNo !=
																		values.groupNo &&
																	creditorData?.belongsToId !=
																		values.belongsToId
																		? 'US.COLLECTION.CREDITOR:CREDITOR_INFO.DO_YOU_NEED_TO_UPDATE_BMD_SETTING_?'
																		: creditorData?.groupNo !=
																		  values.groupNo
																		? t(
																				'US.COLLECTION.CREDITOR:CREDITOR_INFO.THIS_CREDITOR_OBTAINED_BMD_SETTINGS_,_DO_YOU_NEED_TO_SHIFT_TO_THE_NEW_CREDITOR_GROUP_?'
																		  )
																		: creditorData?.belongsToId !=
																		  values.belongsToId
																		? t(
																				'US.COLLECTION.CREDITOR:CREDITOR_INFO.DO_YOU_NEED_TO_GET_BMD_SETTINGS_FROM_BELONGS_TO_CREDITOR_?'
																		  )
																		: ''
																}
																placement='topLeft'
																onConfirm={() => {
																	handleBMDSettings(
																		true,
																		values,
																		restProps
																	);
																	handleSubmit();
																}}
																onCancel={() => {
																	handleBMDSettings(
																		false,
																		values,
																		restProps
																	);
																	handleSubmit();
																}}
																okText={t(
																	'US.COLLECTION.COMMON:COMMON.YES'
																)}
																cancelText={t(
																	'US.COLLECTION.COMMON:COMMON.NO'
																)}
																disabled={
																	JSON.stringify(
																		values
																	) ==
																	JSON.stringify(
																		creditorData
																	)
																}>
																<$Button
																	type='primary'
																	size='small'
																	className='mr-2'
																	loading={
																		isUpdatingCreditor
																	}
																	disabled={
																		JSON.stringify(
																			values
																		) ==
																		JSON.stringify(
																			creditorData
																		)
																	}
																	data-testid='save-btn'>
																	{t(
																		'US.COLLECTION.COMMON:COMMON.SAVE'
																	)}
																</$Button>
															</$Popconfirm>
														)}
														{creditorData?.groupNo ==
															values.groupNo &&
															creditorData?.belongsToId ==
																values.belongsToId && (
																<$Button
																	type='primary'
																	size='small'
																	className='mr-2'
																	loading={
																		isUpdatingCreditor
																	}
																	disabled={
																		JSON.stringify(
																			values
																		) ==
																		JSON.stringify(
																			creditorData
																		)
																	}
																	onClick={() =>
																		handleSubmit()
																	}
																	data-testid='save-btn'>
																	{t(
																		'US.COLLECTION.COMMON:COMMON.SAVE'
																	)}
																</$Button>
															)}
													</div>
													<$Button
														type='default'
														size='small'
														className='mr-1'
														onClick={() =>
															historyDrawerHandler(
																DrawerTypes.ENTITY_HISTORY
															)
														}>
														{t(
															'US.COLLECTION.CREDITOR:CREDITOR_INFO.ENTITY_HISTORY'
														)}
													</$Button>
													<$Button
														type='default'
														size='small'
														onClick={() =>
															historyDrawerHandler(
																DrawerTypes.ADDRESS_HISTORY
															)
														}>
														{t(
															'US.COLLECTION.CREDITOR:CREDITOR_INFO.ADDRESS_HISTORY'
														)}
													</$Button>
												</div>
											</div>
										)}
									</div>
								</$Affix>
								<div className='editcreditor-info'>
									<CreditorInformation
										{...restProps}
										isCreditorManagement={
											true
										}
										isDrawer={
											isDrawer
										}
										isGroupDisabled={
											isGroupDisabled
										}
									/>
								</div>
							</$Form>
							{isDrawer !=
								undefined &&
								newEntityData?.entRoleId ==
									undefined && (
									<div className='drawer-footer-fixed align-content-center justify-content-end'>
										<div>
											{values?.belongsTo !=
												'' && (
												<$Popconfirm
													title={
														'US.COLLECTION.CREDITOR:CREDITOR_INFO.DO_YOU_NEED_TO_GET_BMD_SETTINGS_FROM_BELONGS_TO_CREDITOR_?'
													}
													placement='topLeft'
													onConfirm={() => {
														handleBMDSettings(
															true,
															values,
															restProps
														);
														handleSubmit();
													}}
													onCancel={() => {
														handleBMDSettings(
															false,
															values,
															restProps
														);
														handleSubmit();
													}}
													okText={t(
														'US.COLLECTION.COMMON:COMMON.YES'
													)}
													cancelText={t(
														'US.COLLECTION.COMMON:COMMON.NO'
													)}
													disabled={
														JSON.stringify(
															values
														) ==
														JSON.stringify(
															creditorData
														)
													}>
													<$Button
														className='ml-3 mr-2'
														type='primary'
														loading={
															isAddingCreditor
														}
														disabled={
															!isValid(
																restProps.errors
															) ||
															values.postalPlace ==
																''
														}
														data-testid='save-btn-drawer'>
														{t(
															'US.COLLECTION.COMMON:COMMON.SAVE'
														)}
													</$Button>
												</$Popconfirm>
											)}
											{values?.belongsTo ==
												'' && (
												<$Button
													className='ml-3 mr-2'
													type='primary'
													loading={
														isAddingCreditor
													}
													disabled={
														!isValid(
															restProps.errors
														) ||
														values.postalPlace ==
															''
													}
													onClick={() =>
														handleSubmit()
													}
													data-testid='save-btn-drawer'>
													{t(
														'US.COLLECTION.COMMON:COMMON.SAVE'
													)}
												</$Button>
											)}
											<$Button
												onClick={
													onClose
												}>
												{t(
													'US.COLLECTION.COMMON:COMMON.CANCEL'
												)}
											</$Button>
										</div>
									</div>
								)}
							{isVisibleContactPerson(
								isDrawer,
								newEntityData
							) && (
								<div className='drawer-footer-fixed align-content-center justify-content-end'>
									<div>
										<$Button
											className='ml-3 mr-2'
											type='primary'
											onClick={
												handleBMDNavigation
											}>
											{t(
												'US.COLLECTION.COMMON:COMMON.UPDATE_BMD'
											)}
										</$Button>
										<$Button
											onClick={
												onClose
											}>
											{t(
												'US.COLLECTION.COMMON:COMMON.CANCEL'
											)}
										</$Button>
									</div>
								</div>
							)}
						</div>
						<$Drawer
							title={
								historyDrawerInfo.title
							}
							width={'90%'}
							visible={
								historyDrawerInfo.visibility
							}
							onClose={
								historyDrawerClose
							}
							destroyOnClose>
							{historyDrawerInfo.type ===
								DrawerTypes.ADDRESS_HISTORY && (
								<AddressHistory
									onClose={
										historyDrawerClose
									}
									isCreditor={
										true
									}
								/>
							)}
							{historyDrawerInfo.type ===
								DrawerTypes.ENTITY_HISTORY && (
								<EntityHistory
									onClose={
										historyDrawerClose
									}
									isCreditor={
										true
									}
									debtorInformation={
										creditorDetails
									}
								/>
							)}
						</$Drawer>
					</>
				)}
			</Formik>
		</>
	);
};

const mapStateToProps = (state: RootState) => {
	const { creditor, otherParty, manageCreditors } = state;
	const { otherParties } = otherParty;
	const { general } = creditor;
	const { data } = general;
	const {
		nextCreditorNo,
		newEntityData,
		isValidBelongsToCreditor,
		isAddingCreditor,
		isUpdatingCreditor,
		isValidPostalCodes,
		belongsToCreditor,
	} = manageCreditors;

	return {
		creditorDetails: data,
		contactPersonsData: otherParties.data,
		nextCreditorNo,
		newEntityData,
		isValidBelongsToCreditor,
		isAddingCreditor,
		isUpdatingCreditor,
		isValidPostalCodes,
		belongsToCreditor,
	};
};

const { manageCreditors } = Actions;
const { newCreditor, nextCreditorNo, caseHandlers, existingCreditor } =
	manageCreditors;

const mapDispatchToProps = {
	addNewCreditor: newCreditor.save,
	getNextCreditorNo: nextCreditorNo.getWithoutParams,
	getCaseHandlers: caseHandlers.getWithoutParams,
	updateCreditor: existingCreditor.update,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ManageCreditorInformation);
