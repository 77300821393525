import { AddPartPayment } from 'us.collection.partpayments/constants/Actions'
import { IAPIAction } from "us.collection/interfaces";
import { IDeletePartPayment } from './Interfaces';


export const AddPartPaymentAction: Readonly<IAPIAction & IDeletePartPayment> = {
    /**
   * @namespace GetExistingPartPayment
   */
    GetExistingPartPayment: {
        get: (data) => ({
            type: AddPartPayment.GET_EXISTING_PART_PAYMENT_START ,
            payload: {
                data: data,
                isLoading: true,
            },
        }),
        success: (data) => ({
            type: AddPartPayment.GET_EXISTING_PART_PAYMENT_SUCCESS,
            payload: {
                data: data,
                isLoading: false,
            },
        }),
        fail: (error) => ({
            type: AddPartPayment.GET_EXISTING_PART_PAYMENT_FAIL,
            payload: {
                error: error,
                isLoading: false,
            },
        }),
        reset :(data) => ({
            type: AddPartPayment.RESET_ADD_PART_PAYMENT_CHANGE_COST,
            payload: {
                data: data,
                isLoading: true,
            },
        }), 
    },

    /**
   * @namespace CalculatePartPayment
   */
    CalculatePartPayment: {
        save: (data) => ({
            type: AddPartPayment.CALCULATE_PART_PAYMENT_START ,
            payload: {
                data: data,
                isLoading: true,
            },
        }),
        success: (data) => ({
            type: AddPartPayment.CALCULATE_PART_PAYMENT_SUCCESS,
            payload: {
                data: data,
                isLoading: false,
            },
        }),
        fail: (error) => ({
            type: AddPartPayment.CALCULATE_PART_PAYMENT_FAIL,
            payload: {
                error: error,
                isLoading: false,
            },
        }),
        openDrawer: (data) => ({
            type: AddPartPayment.ADD_PAYMENT_AGREEMENT_DRAWER,
            payload: {
              data,
              isLoading: true,
            },
        }),
        reset: (data) => ({
            type: AddPartPayment.RESET_ADD_PART_PAYMENT,
            payload: {
                data: data,
                isLoading: true,
            },
        }), 
    },

    /**
   * @namespace PartPaymentBMD
   */
    PartPaymentBMD: {
        get: (data) => ({
            type: AddPartPayment.GET_PART_PAYMENT_BMDS_START ,
            payload: {
                data: data,
                isLoading: true,
            },
        }),
        success: (data) => ({
            type: AddPartPayment.GET_PART_PAYMENT_BMDS_SUCCESS,
            payload: {
                data: data,
                isLoading: false,
            },
        }),
        fail: (error) => ({
            type: AddPartPayment.GET_PART_PAYMENT_BMDS_FAIL,
            payload: {
                error: error,
                isLoading: false,
            },
        }),
    },

    /**
   * @namespace PaymentAgreement
   */
    PaymentAgreement: {
        save: (data) => ({
            type: AddPartPayment.ADD_PAYMENT_AGREEMENT_START ,
            payload: {
                data: data,
                isLoading: true,
            },
        }),
        success: (data) => ({
            type: AddPartPayment.ADD_PAYMENT_AGREEMENT_SUCCESS,
            payload: {
                data: data,
                isLoading: false,
            },
        }),
        fail: (error) => ({
            type: AddPartPayment.ADD_PAYMENT_AGREEMENT_FAIL,
            payload: {
                error: error,
                isLoading: false,
            },
        }),
    },

    /**
   * @namespace DeletePartPayment
   */
    DeletePartPayment: {
        delete: (data) => ({
            type: AddPartPayment.DELETE_PART_PAYMENT_START ,
            payload: {
                data: data,
                isLoading: true,
            },
        }),
        success: (data) => ({
            type: AddPartPayment.DELETE_PART_PAYMENT_SUCCESS,
            payload: {
                data: data,
                isLoading: false,
            },
        }),
        fail: (error) => ({
            type: AddPartPayment.DELETE_PART_PAYMENT_FAIL,
            payload: {
                error: error,
                isLoading: false,
            },
        }),
    },

    /**
   * @namespace PaymentsByInstallment
   */
    PaymentsByInstallment: {
        get: (data) => ({
            type: AddPartPayment.GET_PAYMENT_BY_INSTALLMENT_START ,
            payload: {
                data: data,
                isLoading: true,
            },
        }),
        success: (data) => ({
            type: AddPartPayment.GET_PAYMENT_BY_INSTALLMENT_SUCCESS,
            payload: {
                data: data,
                isLoading: false,
            },
        }),
        fail: (error) => ({
            type: AddPartPayment.GET_PAYMENT_BY_INSTALLMENT_FAIL,
            payload: {
                error: error,
                isLoading: false,
            },
        }),
    },

    /**
   * @namespace EditPartPayment
   */
     EditPartPayment: {
        update: (data) => ({
            type: AddPartPayment.EDIT_PART_PAYMENT_START ,
            payload: {
                data: data,
                isLoading: true,
            },
        }),
        success: (data) => ({
            type: AddPartPayment.EDIT_PART_PAYMENT_SUCCESS,
            payload: {
                data: data,
                isLoading: false,
            },
        }),
        fail: (error) => ({
            type: AddPartPayment.EDIT_PART_PAYMENT_FAIL,
            payload: {
                error: error,
                isLoading: false,
            },
        }),
    },
}