import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { Formik } from 'formik';
import XMLViewer from 'react-xml-viewer';
import moment from 'moment';
import { matchPath } from 'react-router-dom';

import Common from 'us.common';
import {
	$Drawer,
	$Skeleton,
	$Tag,
	$TextEditor,
	$Spin,
	$Empty,
	$DateLabel,
} from 'us.common/components';
import {
	SendOutlined,
	BoxIcons,
	IconTypes,
	FolderOpenFilled,
	FolderFilled,
	FileAddOutlined,
	LoadingOutlined,
	FileExclamationOutlined,
} from 'us.icons';
import { MessageAndNoteAction } from 'us.collection.case/actions';
import { IRootState } from 'us.collection/interfaces';
import {
	IEmail,
	IAttachmentFiles,
} from 'us.collection.case/components/Interfaces/NotesAndMessage';
import {
	FormatedTemplate,
	PrintedDocumentContent,
	PrintedDocumentsForAttachment,
	SendDomainMassage,
	UploadDocumentContent,
	UploadDocumentsForAttachment,
} from 'us.collection.case/repository';
import {
	getMIMETypeByFileFormat,
	getBoxIconByFileFormat,
	base64toStr,
} from 'us.collection.documents/functions';
import { DomainMessageType } from 'us.collection.case/constants/NotesAndMessage';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { appInsights } from 'us.helper';
import '../../ContentV1.scss';
import { RootState } from 'us.helper/types';
import EmailValidation from '../../../ContentV2/Validations/EmailValidation';

const {
	$Form,
	$Input,
	$Select,
	$Button,
	$DatePicker,
	$TextArea,
	$Tooltip,
	$AutoComplete,
	$Row,
	$Col,
} = Common.Components;

const {
	formattedTemplate,
	emailAttachment,
	printedDocument,
	uploadDocument,
	emailAttachmentContent,
	domainMessage,
} = MessageAndNoteAction;

const Email: React.FC<IEmail & PropsFromRedux> = (props) => {
	const { t } = useTranslation();

	const attachmentFiles: IAttachmentFiles = {
		fileNames: [],
		filePaths: [],
		isSelected: false,
	};
	const [isPrintedDoc, setIsPrintedDoc] = useState<boolean>(true);
	const [selectAttachment, setSelectAttachment] =
		useState(attachmentFiles);

	const {
		currentDateFormat,
		domainMessageTemplate,
		domainMessageFormattedTemplate,
		openTemplateDrawer,
		domainMessageBasicdetail,
		metaData,
		domainMessageInitial,
		setNoteView,
		openAttachmentDrawer,
		printedDocuments,
		uploadedDocuments,
		emailAttachmentContent,
		location,
		sendDomainMessage,
		getFormattedTemplate,
		openDrawer,
		reset,
		send,
		showAttachment,
		getAttachmentContent,
		getPrintedDocument,
		getUploadedDocument,
	} = props;

	const { entityType } = metaData.data ?? {};

	const showAttachmentFile = emailAttachmentContent?.data[0];
	const unableToDisplayFormats = ['doc', 'docx', 'csv', 'xls', 'xlsx'];

	useEffect(() => {
		if (metaData.data) {
			// Get printed documents
			const queryParam_ = PrintedDocumentsForAttachment(
				metaData.data
			);
			getPrintedDocument && getPrintedDocument(queryParam_);

			// Get uploaded documents
			const queryParam = UploadDocumentsForAttachment(
				metaData.data
			);
			getUploadedDocument && getUploadedDocument(queryParam);
		}
	}, [metaData]);

	useEffect(() => {
		if (sendDomainMessage?.data?.isSuccess) {
			reset && reset({});
			setNoteView(DomainMessageType.EMAIL);
		}
	}, [sendDomainMessage?.data?.isSuccess]);

	const showDrawer = () => {
		openDrawer &&
			openDrawer({
				title: t(
					'US.COLLECTION.CASE:NOTESANDMESSAGES.SELECTATTACHMENT'
				),
				isSelectAttachment: true,
				visible: true,
			});
	};
	const closeDrawer = () => {
		setSelectAttachment((state) => ({
			...state,
			isSelected: false,
		}));
		openDrawer &&
			openDrawer({
				title: '',
				isSelectAttachment: false,
				visible: false,
			});
	};
	/**
	 * Get formatted template
	 * @param value - Select template name
	 * @param rest - props
	 * @param values - form values
	 */
	const handleTemplateDrawer = (
		value: string,
		rest: any,
		values: any
	) => {
		try {
			if (metaData.data) {
				rest.setFieldValue('template', value);
				const template = domainMessageTemplate?.find(
					(template: any) =>
						template.TextName == value
				);
				const queryObject = FormatedTemplate.call(
					template.Text,
					value,
					values,
					metaData.data
				);
				getFormattedTemplate &&
					getFormattedTemplate(queryObject);
				openDrawer &&
					openDrawer({
						title: t(
							'US.COLLECTION.CASE:NOTESANDMESSAGES.EDIT_DOCUMENT'
						),
						isTemplateEditor: true,
						visible: true,
					});
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard NotesAndMessage-Email Drawer Handling Exeception - ${error}`
			);
		}
	};
	/**
	 * Close formatted template drawer
	 * @param rest - props
	 */
	const closeTemplateDrawer = (rest: any) => {
		reset && reset({});
		rest.setFieldValue('template', '');
		rest.setFieldValue('templateNote', '');
		openDrawer &&
			openDrawer({
				title: '',
				isTemplateEditor: false,
				visible: false,
			});
	};

	/**
	 * Send email
	 * @param data - The form data
	 * @param param1
	 */
	const handleSubmit1 = (data: any) => {
		try {
			const attachmentPath = selectAttachment.filePaths?.map(
				(file: any) => {
					return file.path;
				}
			);
			if (metaData.data) {
				const requestBody = SendDomainMassage.call(
					data,
					'Email',
					metaData.data,
					domainMessageFormattedTemplate?.data,
					attachmentPath
				);
				send && send(requestBody);
				openDrawer &&
					openDrawer({
						title: '',
						isTemplateEditor: true,
						visible: false,
					});
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard NotesAndMessage-SendEmail Exeception - ${error}`
			);
		}
	};

	/**
	 * Add select attchment to list
	 * * @param file - file name
	 */
	const handleAttachment = (file: string, filePath: string) => {
		try {
			const tempselectAttachment = [
				...selectAttachment.fileNames,
			];
			const tempFilePaths = [...selectAttachment.filePaths];
			if (
				!selectAttachment.fileNames?.some(
					(fileName: any) => fileName === file
				)
			) {
				tempselectAttachment.push(file);
				tempFilePaths.push({
					fileName: file,
					path: filePath,
				});
				setSelectAttachment({
					fileNames: [...tempselectAttachment],
					filePaths: [...tempFilePaths],
					isSelected: false,
				});
				closeDrawer();
			} else {
				setSelectAttachment({
					fileNames: [...tempselectAttachment],
					filePaths: [...tempFilePaths],
					isSelected: true,
				});
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard NotesAndMessage-Add Select Attachment to List Exeception - ${error}`
			);
		}
	};

	/**
	 * Remove selected attachment
	 * @param file - file name
	 */
	const removeAttachment = (file: string) => {
		try {
			const tempSelectedData = [
				...selectAttachment.fileNames,
			];

			if (
				selectAttachment.fileNames.some(
					(fileName: any) => fileName === file
				)
			) {
				const index = tempSelectedData.indexOf(file);
				if (index !== -1)
					tempSelectedData.splice(index, 1);
				const tempFilePaths =
					selectAttachment.filePaths.filter(
						(item: any) =>
							item.fileName !== file
					);

				setSelectAttachment({
					fileNames: tempSelectedData,
					filePaths: [...tempFilePaths],
					isSelected: false,
				});
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard NotesAndMessage- Remove Selected Attachment from the List Exeception - ${error}`
			);
		}
	};

	const printedDocument = printedDocuments?.data?.map((document: any) => {
		const { outputInfo, messageType, activityName, executedTime } =
			document;
		return (
			<div
				className='msg-template-item msg-template-item-selected'
				onClick={() =>
					handleAttachment(
						activityName,
						outputInfo
					)
				}>
				<div className='msg-template-item-content'>
					<div className='msg-temp-column'>
						<div className='msg-temp-icon'>
							<BoxIcons
								type={
									IconTypes.BOX_ICON
								}
								name={getBoxIconByFileFormat(
									'.' +
										messageType
								)}
							/>
						</div>
						<div className='msg-temp-name'>
							{activityName} -{' '}
							<$DateLabel
								value={
									executedTime
								}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	});
	const uploadDocument = uploadedDocuments?.data?.map((document: any) => {
		const { documentName, documentPath, documentType } = document;
		return (
			<div
				className='msg-template-item msg-template-item-selected'
				onClick={() =>
					handleAttachment(
						documentName,
						documentPath
					)
				}>
				<div className='msg-template-item-content'>
					<div className='msg-temp-column'>
						<div className='msg-temp-icon'>
							<BoxIcons
								type={
									IconTypes.BOX_ICON
								}
								name={getBoxIconByFileFormat(
									documentType
								)}
							/>
						</div>
						<div className='msg-temp-name'>
							{documentName}
						</div>
					</div>
				</div>
			</div>
		);
	});

	/**
	 * Change document type (Debtor,Creditor,Other Parties with name)
	 * @param receiver
	 * @param rest
	 */
	const changeDocumentType = (receiver: string, rest: any) => {
		const entityDetails =
			domainMessageBasicdetail.entityDetails?.find(
				(entity: any) => entity.receiver === receiver
			);
		rest.setFieldValue('emailAddress', entityDetails?.emailAddress);
		rest.setFieldValue('receiverEntityId', entityDetails?.entityId);
	};

	const handleChangeTemplateText = (content: any, rest: any) => {
		rest.setFieldValue('templateNote', content);
	};

	/**
	 * Get select file content
	 * @param value - The selected label
	 */
	const handleAttachmentFileShow = (value: any) => {
		try {
			let comJobId = 0;
			let documentId = 0;
			let activityName = '';
			printedDocuments?.data?.map((item: any) => {
				if (item.activityName === value) {
					comJobId = item.communicationJobId;
					activityName = item.activityName;
				}
			});
			uploadedDocuments?.data?.map((item: any) => {
				if (item.documentName === value) {
					documentId = item.documentId;
				}
			});
			const { params }: any = matchPath(location.pathname, {
				path: '/:caseType/:caseId',
				exact: true,
				strict: false,
			});
			const caseType = params['caseType'];
			let queryParamObject = {};
			// Printed document content
			if (entityType) {
				if (comJobId > 0) {
					queryParamObject =
						PrintedDocumentContent.call(
							{
								activityName,
								comJobId,
								caseType,
							},
							entityType
						);
				} else {
					// Uploaded document content
					queryParamObject =
						UploadDocumentContent.call(
							{
								documentId,
								caseType,
							},
							entityType
						);
				}
			}
			getAttachmentContent &&
				getAttachmentContent(queryParamObject);
			showAttachment &&
				showAttachment({ title: value, visible: true });
		} catch (error) {
			appInsights.trackException(
				`Dashboard NotesAndMessage- Get select file content Exeception - ${error}`
			);
		}
	};

	const closeAttachmentFileShow = () => {
		showAttachment && showAttachment({ title: '', visible: false });
	};

	const tagRender = (props: any) => {
		const { label, value, closable, onClose } = props;
		return (
			<$Tooltip placement='topLeft' title={label}>
				<$Tag
					onClick={() =>
						handleAttachmentFileShow(value)
					}
					closable={closable}
					onClose={() => {
						onClose(),
							removeAttachment(value);
					}}
					className='attchment-selected'
					icon={
						<BoxIcons
							type={
								IconTypes.BOX_ICON
							}
							name={value
								.split('.')
								.pop()}
						/>
					}>
					{value}
				</$Tag>
			</$Tooltip>
		);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={domainMessageInitial}
			validationSchema={EmailValidation}
			onSubmit={handleSubmit1}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...rest
			}: any) => (
				<div className='py-3'>
					<$Form>
						<$Row
							gutter={16}
							className='mt-0'>
							<$Col
								span={10}
								xxl={{
									span: 10,
								}}
								xl={{
									span: 12,
								}}>
								<div
									style={{
										marginTop: 0,
									}}
									data-testid='document-type-label'>
									{t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.DOCUMENTTYPE'
									)}
								</div>
							</$Col>
							<$Col
								span={14}
								xxl={{
									span: 14,
								}}
								xl={{
									span: 12,
								}}
								data-testid='select-document-type'>
								<$Select
									name='documentType'
									placeholder='Status'
									size='small'
									className='w-100'
									data-testid='document-type'
									allOption={
										false
									}
									options={
										domainMessageInitial.documentTypeList
									}
									onChange={(
										type: any
									) =>
										changeDocumentType(
											type,
											rest
										)
									}
									defaultValue={
										values.documentType
									}
									disabled={
										sendDomainMessage?.isProgress ||
										(metaData.data &&
											isSubCaseTransferredToCase(
												metaData.data
											))
									}
								/>
							</$Col>
						</$Row>

						<$Row
							gutter={16}
							className='mt-2'>
							<$Col
								span={10}
								xxl={{
									span: 10,
								}}
								xl={{
									span: 12,
								}}>
								<div
									style={{
										marginTop: 5,
									}}
									data-testid='email-label'>
									{t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.EMAILADDRESS'
									)}
								</div>
							</$Col>
							<$Col
								span={14}
								xxl={{
									span: 14,
								}}
								xl={{
									span: 12,
								}}
								data-testid='email-address'>
								<$Input
									name='emailAddress'
									size='small'
									defaultValue={
										values.emailAddress
									}
									disabled={
										sendDomainMessage?.isProgress ||
										(metaData.data &&
											isSubCaseTransferredToCase(
												metaData.data
											))
									}
								/>
							</$Col>
						</$Row>

						<$Row
							gutter={16}
							className='mt-1'>
							<$Col
								span={10}
								xxl={{
									span: 10,
								}}
								xl={{
									span: 12,
								}}>
								<div
									style={{
										marginTop: 0,
									}}
									data-testid='attachment-label'>
									{t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.ATTACHMENT'
									)}
								</div>
							</$Col>
							<$Col
								span={14}
								xxl={{
									span: 14,
								}}
								xl={{
									span: 12,
								}}>
								<div className='d-flex flex-column'>
									<div
										className='d-flex'
										data-testid='email-attachment'>
										<$Select
											className='attchment flex-grow-1'
											name='attachment'
											mode='multiple'
											placeholder={t(
												'US.COLLECTION.CASE:NOTESANDMESSAGES.ADD_AN_ATTACHMENT'
											)}
											size='small'
											allOption={
												false
											}
											tagRender={
												tagRender
											}
											value={
												selectAttachment?.fileNames
											}
											options={
												values.attachmentOptions
											}
											disabled={
												sendDomainMessage?.isProgress ||
												(metaData.data &&
													isSubCaseTransferredToCase(
														metaData.data
													))
											}
											data-testid='select-attachments'
										/>
										<$Button
											disabled={
												sendDomainMessage?.isProgress
											}
											type='default'
											size='small'
											onClick={
												showDrawer
											}
											data-testid='add-attachment-button'>
											<FileAddOutlined />
										</$Button>
									</div>
								</div>
							</$Col>
						</$Row>

						<$Row
							gutter={16}
							className='mt-2'>
							<$Col
								span={10}
								xxl={{
									span: 10,
								}}
								xl={{
									span: 12,
								}}>
								<div
									style={{
										marginTop: 5,
									}}
									data-testid='due-date-label'>
									{t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.MANUALDUEDATE'
									)}
								</div>
							</$Col>
							<$Col
								span={14}
								xxl={{
									span: 14,
								}}
								xl={{
									span: 12,
								}}>
								<$DatePicker
									formitem
									formitemlabel={t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.DUEDATE'
									)}
									className='w-100'
									name='dueDate'
									size='small'
									placeholder={
										currentDateFormat
									}
									format={
										currentDateFormat
									}
									allowClear
									value={
										values.dueDate
									}
									defaultPickerValue={
										values.dueDate
									}
									disabledDate={(
										day: any
									) =>
										day.isBefore(
											moment().startOf(
												'day'
											)
										)
									}
									disabled={
										sendDomainMessage?.isProgress ||
										(metaData.data &&
											isSubCaseTransferredToCase(
												metaData.data
											))
									}
									data-testid='manual-duedate'
								/>
							</$Col>
						</$Row>

						<$Row
							gutter={16}
							className='mt-0'>
							<$Col
								span={10}
								xxl={{
									span: 10,
								}}
								xl={{
									span: 12,
								}}>
								<div
									style={{
										marginTop: 5,
									}}
									data-testid='template-label'>
									{t(
										'US.COLLECTION.CASE:NOTESANDMESSAGES.TEMPLATE'
									)}
								</div>
							</$Col>
							<$Col
								span={14}
								xxl={{
									span: 14,
								}}
								xl={{
									span: 12,
								}}
								data-testid='domain-templates'>
								<$AutoComplete
									{...rest}
									size='small'
									name='template'
									data-testid='select-domain-templates-options'
									options={
										domainMessageInitial.templateList
									}
									defaultValue={
										values.Template
									}
									onSelect={(
										templateValue: string
									) =>
										handleTemplateDrawer(
											templateValue,
											rest,
											values
										)
									}
									disabled={
										sendDomainMessage?.isProgress ||
										(metaData.data &&
											isSubCaseTransferredToCase(
												metaData.data
											)) ||
										(rest
											.touched
											.emailAddress
											? rest
													.errors
													.emailAddress
											: !values.emailAddress)
									}
								/>
							</$Col>
						</$Row>

						{!(
							openTemplateDrawer.title ==
							'Template Editor'
						) && (
							<$Row
								gutter={16}
								className='mt-2'>
								<$Col
									span={
										24
									}
									xxl={{
										span: 24,
									}}
									xl={{
										span: 24,
									}}>
									<$TextArea
										name='caseNote'
										className='note-message'
										data-testid='case-note'
										placeholder={t(
											'US.COLLECTION.CASE:NOTESANDMESSAGES.TYPEANOTEHERE'
										)}
										defaultValue={
											values.caseNote
										}
										disabled={
											sendDomainMessage?.isProgress ||
											(metaData.data &&
												isSubCaseTransferredToCase(
													metaData.data
												))
										}
									/>
								</$Col>
							</$Row>
						)}

						<div className='d-flex align-items-center justify-content-between mt-2'>
							<div>
								{sendDomainMessage?.isProgress && (
									<small className='d-block'>
										<$Spin
											size='small'
											indicator={
												<LoadingOutlined
													style={{
														fontSize: 20,
													}}
													spin
												/>
											}
										/>
										<span className='ml-2'>
											{t(
												'US.COLLECTION.CASE:NOTESANDMESSAGES.SEND_EMAIL_IN_PROGRESS'
											)}
										</span>
									</small>
								)}
							</div>
							<$Button
								type='default'
								htmlType='submit'
								onClick={
									handleSubmit
								}
								icon={
									<SendOutlined />
								}
								disabled={
									(metaData.data &&
										isSubCaseTransferredToCase(
											metaData.data
										)) ||
									sendDomainMessage?.isProgress
								}
								data-testid='send-btn'>
								{t(
									'US.COLLECTION.COMMON:COMMON.SEND'
								)}
							</$Button>
						</div>

						<$Drawer
							width='800'
							title={
								openTemplateDrawer.title
							}
							placement='right'
							closable={false}
							onClose={() =>
								closeTemplateDrawer(
									rest
								)
							}
							visible={
								openTemplateDrawer.isTemplateEditor &&
								openTemplateDrawer.visible
							}
							data-testid='edit-template'>
							<$Skeleton
								loading={
									domainMessageFormattedTemplate?.isLoading
								}
								active
								paragraph={{
									rows: 2,
								}}>
								<div>
									<$TextEditor
										value={
											values.templateNote
										}
										handleChange={(
											content: any
										) =>
											handleChangeTemplateText(
												content,
												rest
											)
										}
										name='templateNote'
									/>
								</div>
							</$Skeleton>
							<div className='drawer-footer-fixed align-content-center justify-content-end'>
								<div>
									<$Button
										className='mr-2'
										type='primary'
										htmlType='submit'
										onClick={
											handleSubmit
										}>
										{t(
											'US.COLLECTION.COMMON:COMMON.SEND'
										)}
									</$Button>
									<$Button
										data-testid='drawer-cancel-btn'
										onClick={() =>
											closeTemplateDrawer(
												rest
											)
										}>
										{t(
											'US.COLLECTION.COMMON:COMMON.CANCEL'
										)}
									</$Button>
								</div>
							</div>
						</$Drawer>
					</$Form>
					<$Drawer
						title={openTemplateDrawer.title}
						width='1200px'
						placement='right'
						onClose={closeDrawer}
						className='select-attchment'
						destroyOnClose
						visible={
							openTemplateDrawer.isSelectAttachment &&
							openTemplateDrawer.visible
						}
						data-testid='attachment-drawer'>
						<div className='d-flex select-attchment-body'>
							<div className='folders'>
								<p>
									<strong>
										{t(
											'US.COLLECTION.CASE:NOTESANDMESSAGES.DOCUMENT_FOLDER'
										)}
									</strong>
								</p>
								<div
									className={
										isPrintedDoc
											? 'd-flex align-items-start folder-item folder-item-selected'
											: 'd-flex align-items-start folder-item'
									}
									onClick={() => {
										setIsPrintedDoc(
											true
										);
										setSelectAttachment(
											(
												state
											) => ({
												...state,
												isSelected: isPrintedDoc
													? state.isSelected
													: false,
											})
										);
									}}>
									<span>
										{isPrintedDoc ? (
											<FolderOpenFilled />
										) : (
											<FolderFilled />
										)}
									</span>
									<span className='ml-2'>
										{t(
											'US.COLLECTION.CASE:NOTESANDMESSAGES.PRINTED_DOCUMENTS'
										)}
									</span>
								</div>
								<div
									className={
										!isPrintedDoc
											? 'd-flex align-items-start folder-item folder-item-selected'
											: 'd-flex align-items-start folder-item'
									}
									onClick={() => {
										setIsPrintedDoc(
											false
										);
										setSelectAttachment(
											(
												state
											) => ({
												...state,
												isSelected: isPrintedDoc
													? false
													: state.isSelected,
											})
										);
									}}>
									<span>
										{isPrintedDoc ? (
											<FolderFilled />
										) : (
											<FolderOpenFilled />
										)}
									</span>
									<span className='ml-2'>
										{t(
											'US.COLLECTION.CASE:NOTESANDMESSAGES.UPLOADED_DOCUMENTS'
										)}
									</span>
								</div>
							</div>
							<div className='flex-grow-1 documents'>
								{((isPrintedDoc &&
									printedDocument.length <
										1) ||
									(!isPrintedDoc &&
										uploadDocument.length <
											1)) && (
									<$Empty
										image={
											$Empty.PRESENTED_IMAGE_SIMPLE
										}
										description={t(
											'COMMON.NO_DATA'
										)}
									/>
								)}
								<div
									className='documents-wrap'
									data-testid='printed-documents'>
									{isPrintedDoc
										? printedDocument
										: uploadDocument}
								</div>
								{selectAttachment?.isSelected && (
									<div className='text-error'>
										{t(
											'US.COLLECTION.CASE:NOTESANDMESSAGES.FILE_ALREDY_SELECTED'
										)}
									</div>
								)}
							</div>
						</div>
					</$Drawer>
					<$Drawer
						title={
							openAttachmentDrawer?.title
						}
						width='75%'
						placement='left'
						onClose={
							closeAttachmentFileShow
						}
						className='case-doc-viwer case-doc-preview'
						visible={
							openAttachmentDrawer?.visible
						}
						destroyOnClose>
						<$Skeleton
							loading={
								emailAttachmentContent?.isLoading
							}>
							{(!showAttachmentFile ||
								unableToDisplayFormats.includes(
									showAttachmentFile?.documentType
								)) && (
								<div className='unable-to-display'>
									<$Empty
										image={
											<FileExclamationOutlined />
										}
										description={
											<span>
												{t(
													'US.COLLECTION.DOCUMENTS:CASEDOCUMENTS.PREVIEW_IS_NOT_AVAILABLE'
												)}
											</span>
										}></$Empty>
								</div>
							)}
							{!unableToDisplayFormats.includes(
								showAttachmentFile?.documentType
							) &&
								showAttachmentFile?.documentType ==
									'xml' && (
									<XMLViewer
										xml={base64toStr(
											showAttachmentFile?.content
										)}
										theme={{
											overflowBreak:
												true,
										}}
										indentSize={
											5
										}
										collapsible={
											true
										}
									/>
								)}
							{!unableToDisplayFormats.includes(
								showAttachmentFile?.documentType
							) &&
								showAttachmentFile?.documentType !=
									'xml' && (
									<div className='case-doc-viwer-inner'>
										<object
											className={
												showAttachmentFile?.documentType ===
												'pdf'
													? 'case-doc-frame frame-pdf'
													: 'case-doc-frame frame-html'
											}
											type={getMIMETypeByFileFormat(
												showAttachmentFile?.documentType
											)}
											data={`data:${getMIMETypeByFileFormat(
												showAttachmentFile?.documentType
											)};base64,${
												showAttachmentFile?.content
											}`}
										/>
									</div>
								)}
							<div className='drawer-footer-fixed align-content-center justify-content-end'>
								<div>
									<$Button
										onClick={() =>
											closeAttachmentFileShow()
										}>
										{t(
											'US.COLLECTION.COMMON:COMMON.CANCEL'
										)}
									</$Button>
								</div>
							</div>
						</$Skeleton>
					</$Drawer>
				</div>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, domainView, messageAndNotes, router } = state;
	const { currentDateFormat } = common;
	const { location } = router;
	const { metaData } = domainView;
	const {
		domainMessageTemplate,
		domainMessageFormattedTemplate,
		openTemplateDrawer,
		domainMessageBasicdetail,
		domainMessageInitial,
		openAttachmentDrawer,
		printedDocuments,
		uploadedDocuments,
		emailAttachmentContent,
		sendDomainMessage,
	} = messageAndNotes;
	return {
		currentDateFormat,
		domainMessageTemplate,
		domainMessageFormattedTemplate,
		openTemplateDrawer,
		domainMessageBasicdetail,
		metaData,
		domainMessageInitial,
		openAttachmentDrawer,
		printedDocuments,
		uploadedDocuments,
		emailAttachmentContent,
		location,
		sendDomainMessage,
	};
};

const mapDispatchToProps = {
	getFormattedTemplate: formattedTemplate.get,
	openDrawer: formattedTemplate.openDrawer,
	reset: formattedTemplate.reset,
	send: domainMessage.save,
	showAttachment: emailAttachment.openDrawer,
	getPrintedDocument: printedDocument.get,
	getUploadedDocument: uploadDocument.get,
	getAttachmentContent: emailAttachmentContent.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Email);
