import moment from "moment";
import { DATE_FORMAT_TO_COMPARE } from "us.collection/constants";
import { IUpdateDueDateRequestObject } from "../Interfaces";

/**
 * @function
 * @description - repository to get the API request object for updating the due date
 * @param {*} this - any type object
 * @return {*} - API calling object
 */
export function GetUpdateDueDateRequestObject(this: any): IUpdateDueDateRequestObject {
  return {
    arNo: this.arId,
    caseNo: this.caseId,
    duedate: moment(this.dueDate).isAfter(moment().startOf("day"))
      ? moment(this.dueDate).format(DATE_FORMAT_TO_COMPARE)
      : "",
    caseNote: this.caseNote,
  };
}
