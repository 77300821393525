export const newEnforcedItems = (enforcedItems: Array<any>) => {
    return [
        ...enforcedItems,
        {
          enforcementItemId: -1,
          enforcementType: "",
          assetId: "",
          assetType: "",
          faceValue: 0,
          valuation: 0,
        },
      ];
}