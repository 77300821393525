import { IPersonalEffectsAssets } from "./interfaces";
import moment from "moment";

export function UpdatePersonalEffectsAssets(this: IPersonalEffectsAssets) {
  return {
    assetId: this.assetId,
    entityId: this.entityId,
    entityType: this.entityType,
    estimatedValue: this.estimatedValue,
    valuationAmount: this.valuationAmount,
    valuationDate:
      this.valuationDate && moment(this.valuationDate).isValid()
        ? moment(this.valuationDate).format("YYYY-MM-DD")
        : null,
    description: this.description,
    address1: this.address1,
    address2: this.address2,
    postalCode: this.postalCode,
    municipalityCode: this.municipalityCode,
    comment: this.comment,
  };
}
