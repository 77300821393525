import { ICaseSubCasesFrom } from "../Interfaces";
import { CaseStateOptions } from "./CaseStateOptions";
import { CaseTypeOptions } from "./CaseTypeOptions";

/**
 * @constant
 * @description - Initial Values for the case and sub-case form
 * @type {ICaseSubCasesFrom} - case and sub-case form values
 */
export const INITIAL_VALUES: ICaseSubCasesFrom = {
  dueDate: null,
  caseNote: "",
  updateDueDateVisible: false,
  expandRows: false,
  expandedRows: [],
  caseType: CaseTypeOptions.ALL,
  caseState: CaseStateOptions.ALL,
  selectedCases: [],
};
