import { ISentenceModel, ISentenceSummery } from "us.collection.case/interfaces";
import { getISODateString } from "../Functions";

export function AddCourtSentenceRepository(
  this: any,
  sentenceSummery: ISentenceModel<ISentenceSummery[]>
) {
  return {
    sentenceObject: {
      caseId: this.caseId,
      collectionFee: this.collectionFee,
      collectionFeeFromDate: getISODateString(this.collectionFeeFromDate),
      collectionFeeRate: this.collectionFeeRate,
      continueType: this.continueType,
      caseCost: this.caseCost,
      caseCostFromDate: getISODateString(this.caseCostFromDate),
      caseCostRate: this.caseCostRate,
      courtFee: this.courtFee,
      courtRef: this.courtRef,
      declarationDate: getISODateString(this.declarationDate),
      dueDate: getISODateString(this.dueDate),
      interestCollectionFee: this.interestCollectionFee,
      interestCollectionFeeRate: this.interestCollectionFeeRate,
      interestCollectionFeeTo: getISODateString(this.interestCollectionFeeTo),
      limitDate: getISODateString(this.limitDate),
      mainAmount: this.mainAmount,
      mainAmountFromDate: getISODateString(this.mainAmountFromDate),
      mainAmountRate: this.mainAmountRate,
      mainAmountInterest: this.mainAmountInterest,
      mainAmountInterestRate: this.mainAmountInterestRate,
      mainAmountInterestToDate: getISODateString(this.mainAmountInterestToDate),
      modifiedUser: this.modifiedUser,
      otherCosts: this.otherCosts,
      otherCostsFromDate: getISODateString(this.otherCostsFromDate),
      otherCostsRate: this.otherCostsRate,
      sentenceDate: getISODateString(this.sentenceDate),
      sentenceNote: this.sentenceNote,
      sentenceTypeId: this.sentenceTypeId,
    },
    sentenceSummery: sentenceSummery,
  };
}
