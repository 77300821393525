import * as ActionConsts from './History';

export default {
    endPoints: {
        activityHistoryList: '/activityHistory',
        activitiesData: '/activitiesData',
        routingsAndActivitiesList: '/routinesAndActivities'
    },
    ACTIONS: ActionConsts
}
export * from './RoutineAndActivity'