import { Notifications } from "us.common/constants";
import {
  getNotificationPayload,
  removeNotification,
  updateNotificationMessage,
  updatePendingEvents,
} from "us.common/functions/notification";
import { NotificationUpdateType } from "us.common/constants/Component/Notification";

import { initialState } from "./State";
const {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  UPDATE_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS_FAIL,
  UPDATE_NOTIFICATIONS_SUCCESS,
  RETRY_NOTIFICATION_ACTION,
  RETRY_NOTIFICATION_ACTION_SUCCESS,
  RETRY_NOTIFICATION_ACTION_FAIL,
  UPDATE_PENDING_NOTIFICATION,
} = Notifications;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_NOTIFICATIONS:
      const { scroll } = payload?.data;
      return Object.assign({}, state, {
        data: scroll ? state.data : [],
        unReadCount: scroll ? state.unReadCount : 0,
        scroll: scroll,
        isLoading: true,
      });
    case GET_NOTIFICATIONS_SUCCESS:
      const { unRead, notifications } = payload.data;
      return Object.assign({}, state, {
        data: getNotificationPayload(state, notifications),
        unReadCount: !isNaN(unRead) ? Number(unRead) : 0,
        isLoading: false,
      });
    case GET_NOTIFICATIONS_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
      });

    case UPDATE_NOTIFICATIONS:
      const { notificationId } = payload.data ?? {};
      return payload.data?.type == NotificationUpdateType.DELETE
        ? Object.assign({}, state, {
            notificationUpdateId: notificationId,
          })
        : Object.assign({}, state, { ...state });
    case UPDATE_NOTIFICATIONS_SUCCESS:
      return Object.assign({}, state, {
        ...removeNotification(state, payload.data),
        notificationUpdateId: 0,
      });
    case UPDATE_NOTIFICATIONS_FAIL:
      return Object.assign({}, state, {
        notificationUpdateId: 0,
      });

    case RETRY_NOTIFICATION_ACTION:
      return Object.assign({}, state, {
        retry: {
          ...initialState.retry,
          notificationId: payload.data.notificationId,
        },
      });
    case RETRY_NOTIFICATION_ACTION_SUCCESS:
      return Object.assign({}, state, {
        retry: {
          ...state.retry,
          data: payload.data,
          notificationId: 0,
        },
      });
    case RETRY_NOTIFICATION_ACTION_FAIL:
      return Object.assign({}, state, {
        retry: {
          ...initialState.retry,
        },
      });
    case UPDATE_PENDING_NOTIFICATION:
      const { eventId, isInProgress } = payload.data ?? {};
      return Object.assign({}, state, {
        pendingEvents: updatePendingEvents(
          state.pendingEvents,
          eventId,
          isInProgress
        ),
      });
    default:
      return state;
  }
};
