import {
	EntityFollowUpPanel,
	ManualFollowUp,
} from 'us.collection.case/constants';
import { initialState } from './State';
import {
	getDueAndUpcomingManualFollowup,
	appendNewReason,
} from 'us.collection.case/functions';
import { initialValue } from './State';
const {
	GET_MANUAL_FOLLOW_UP_USERS_START,
	GET_MANUAL_FOLLOW_UP_USERS_SUCCESS,
	GET_MANUAL_FOLLOW_UP_USERS_FAIL,
	GET_MANUAL_FOLLOW_UP_REASONS_START,
	GET_MANUAL_FOLLOW_UP_REASONS_SUCCESS,
	GET_MANUAL_FOLLOW_UP_REASONS_FAIL,
	GET_MANUAL_FOLLOW_UP_CATEGORIES_START,
	GET_MANUAL_FOLLOW_UP_CATEGORIES_SUCCESS,
	GET_MANUAL_FOLLOW_UP_CATEGORIES_FAIL,
	SAVE_MANUAL_FOLLOW_UP_START,
	SAVE_MANUAL_FOLLOW_UP_SUCCESS,
	SAVE_MANUAL_FOLLOW_UP_FAIL,
	GET_MANUAL_FOLLOW_UPS_START,
	GET_MANUAL_FOLLOW_UPS_SUCCESS,
	GET_MANUAL_FOLLOW_UPS_FAIL,
	SAVE_MANUAL_FOLLOW_UP_REASONS_START,
	SAVE_MANUAL_FOLLOW_UP_REASONS_SUCCESS,
	SAVE_MANUAL_FOLLOW_UP_REASONS_FAIL,
	UPDATE_REASON_FORM_START,
	RESET_REASON_FORM,
	GET_FOLLOW_UP_SUMMARY,
	GET_FOLLOW_UP_SUMMARY_SUCCESS,
	GET_FOLLOW_UP_SUMMARY_FAIL,
} = ManualFollowUp;

export default (state = initialState, { payload, type }: any) => {
	switch (type) {
		case GET_MANUAL_FOLLOW_UPS_START:
			return Object.assign({}, state, {
				list: {
					due: [],
					upcoming: [],
					data: [],
					isLoading: payload.isLoading,
				},
			});
		case GET_MANUAL_FOLLOW_UPS_SUCCESS:
			const { due, upcoming } =
				getDueAndUpcomingManualFollowup(payload.data);
			return Object.assign({}, state, {
				list: {
					due,
					upcoming,
					data: payload.data,
					isLoading: payload.isLoading,
				},
			});
		case GET_MANUAL_FOLLOW_UPS_FAIL:
			return Object.assign({}, state, {
				list: {
					due: [],
					upcoming: [],
					data: [],
					isLoading: payload.isLoading,
				},
			});
		case GET_MANUAL_FOLLOW_UP_CATEGORIES_START:
			return Object.assign({}, state, {
				categories: [],
			});
		case GET_MANUAL_FOLLOW_UP_CATEGORIES_SUCCESS:
			return Object.assign({}, state, {
				categories: payload.data,
			});
		case GET_MANUAL_FOLLOW_UP_CATEGORIES_FAIL:
			return Object.assign({}, state, {
				categories: [],
			});
		case UPDATE_REASON_FORM_START:
			return Object.assign({}, state, {
				initialValue: payload.data.values,
			});
		case GET_MANUAL_FOLLOW_UP_USERS_START:
			return Object.assign({}, state, {
				assigners: [],
			});
		case GET_MANUAL_FOLLOW_UP_USERS_SUCCESS:
			return Object.assign({}, state, {
				assigners: payload.data,
			});
		case GET_MANUAL_FOLLOW_UP_USERS_FAIL:
			return Object.assign({}, state, {
				assigners: [],
			});
		case GET_MANUAL_FOLLOW_UP_REASONS_START:
			return Object.assign({}, state, {
				reasons: {
					data: [],
					isFetching: true,
				},
			});
		case GET_MANUAL_FOLLOW_UP_REASONS_SUCCESS:
			return Object.assign({}, state, {
				reasons: {
					data: payload.data,
					isFetching: false,
				},
			});
		case GET_MANUAL_FOLLOW_UP_REASONS_FAIL:
			return Object.assign({}, state, {
				reasons: {
					data: [],
					isFetching: false,
				},
			});
		case RESET_REASON_FORM:
			return Object.assign({}, state, {
				reasons: {
					data: [],
					isFetching: false,
				},
				initialValue: initialValue,
			});
		case SAVE_MANUAL_FOLLOW_UP_START:
			return Object.assign({}, state, {
				isManualFollowUpSaving: true,
			});
		case SAVE_MANUAL_FOLLOW_UP_SUCCESS:
			return Object.assign({}, state, {
				isManualFollowUpSaving: false,
			});
		case SAVE_MANUAL_FOLLOW_UP_FAIL:
			return Object.assign({}, state, {
				isManualFollowUpSaving: false,
			});
		case SAVE_MANUAL_FOLLOW_UP_REASONS_START:
			return Object.assign({}, state, {
				isReasonSaving: true,
			});
		case SAVE_MANUAL_FOLLOW_UP_REASONS_SUCCESS:
			const { reasonId } = payload.data;
			return Object.assign({}, state, {
				isReasonSaving: false,
				reasons: {
					data: appendNewReason(
						state.reasons.data,
						payload.data
					),
					isFetching: false,
				},
				initialValue: {
					...state.initialValue,
					reason: reasonId,
				},
			});
		case SAVE_MANUAL_FOLLOW_UP_REASONS_FAIL:
			return Object.assign({}, state, {
				isReasonSaving: false,
			});
		case GET_FOLLOW_UP_SUMMARY:
			return Object.assign({}, state, {
				followUpSummary: {
					data: {
						due: 0,
						upcoming: 0,
					},
					isLoading: true,
				},
			});
		case GET_FOLLOW_UP_SUMMARY_SUCCESS:
			return Object.assign({}, state, {
				followUpSummary: {
					data: payload.data,
					isLoading: false,
				},
			});
		case GET_FOLLOW_UP_SUMMARY_FAIL:
			return Object.assign({}, state, {
				followUpSummary: {
					...state.followUpSummary,
					isLoading: false,
				},
			});
		default:
			return state;
	}
};
