import { ITransactionTypesAction } from 'us.collection.transactions/interfaces';
import { TransactionType } from 'us.collection.transactions/constants/Actions';

/**
 * Transaction Types actions
 */

export const transactionTypes: ITransactionTypesAction = {
	save: (data) => ({
		type: TransactionType.SAVE_TRANSACTION_TYPE,
		payload: {
			data,
			isLoading: true,
		},
	}),
	success: (data) => ({
		type: TransactionType.SAVE_TRANSACTION_TYPE_SUCCESS,
		payload: {
			data,
			isLoading: false,
		},
	}),
	fail: (error) => ({
		type: TransactionType.SAVE_TRANSACTION_TYPE_FAIL,
		payload: {
			error,
			isLoading: false,
		},
	}),
	getAll: (filterType = 'All', itemType?) => ({
		type: TransactionType.GET_TRANSACTION_TYPES,
		payload: {
			filterType,
			itemType,
			isLoading: true,
		},
	}),
	getAllSuccess: (data) => ({
		type: TransactionType.GET_TRANSACTION_TYPES_SUCCESS,
		payload: {
			data,
			isLoading: false,
		},
	}),
	getAllFail: (error) => ({
		type: TransactionType.GET_TRANSACTION_TYPES_FAIL,
		payload: {
			error,
			isLoading: false,
		},
	}),
};
