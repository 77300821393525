import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import Common from "us.common";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import { debtor } from "us.collection.debtor/actions";
import { ExperianProvider, BisnodeProvider } from "./Components";
import { $Empty } from "us.common/components";
const { $Form } = Common.Components;

interface ICreditInformation {
  debtorCreditInfo: any;
  getDebtorCreditInfo: any;
  entRoleId: any;
}

const validationSchema = Yup.object({});

const CreditInformation: React.FC<ICreditInformation> = memo((props) => {
  const { t } = useTranslation();
  const { debtorCreditInfo, getDebtorCreditInfo, entRoleId } = props;

  const { data, isFetching } = debtorCreditInfo;
  const { washProvider } = data ?? {};

  useEffect(() => {
    getDebtorCreditInfo(entRoleId);
  }, [entRoleId]);

  return (
    <Formik initialValues={{}} validationSchema={validationSchema} onSubmit={() => {}}>
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <$Form onSubmit={handleSubmit}>
          <div className="credit-information">
            {/* Experian ****************************************************************************************************************/}
            {washProvider == "Experian" && (
              <ExperianProvider debtorCreditInfo={data} isFetching={isFetching} />
            )}

            {/* Bisnode ****************************************************************************************************************/}
            {washProvider == "Bisnode" && (
              <BisnodeProvider debtorCreditInfo={data} isFetching={isFetching} />
            )}
            {!washProvider && (
              <div className="d-flex justify-content-center">
                <div className="d-flex mt-4">
                  <$Empty
                    image={$Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      <span>
                        {t(
                          "US.COLLECTION.DEBTOR:CREDITINFO.NO_DATA"
                        )}
                      </span>
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </$Form>
      )}
    </Formik>
  );
});

const mapStateToProps = (state: IRootState) => {
  const { debtorDetail } = state;
  const { credit } = debtorDetail;
  return {
    debtorCreditInfo: credit,
  };
};

const mapDispatchToProps = {
  getDebtorCreditInfo: debtor.credit.getByDebtorNo,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditInformation);
