import React from 'react';
import { ToggleClass } from 'us.helper';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PartPayments from 'us.collection.partpayments';

interface ICnt_PartPayments extends RouteComponentProps {
	caseNo: number;
}

const Cnt_PartPayments: React.FC<ICnt_PartPayments> = (props) => {
	ToggleClass('change-body-color-dom');

	const onMinimize = () => {
		props.history.push(`/case/${props.caseNo}`);
	};

	return (
		<PartPayments.Components.PartPaymentDetail
			{...props}
			minimize={() => {
				onMinimize();
			}}
		/>
	);
};

export default withRouter(Cnt_PartPayments);
