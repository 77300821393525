import React, { useState, useEffect, memo } from "react";
import { Formik } from "formik";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { BoxIcons, FilterOutlined, IconTypes, SearchOutlined } from "us.icons";
import Common from "us.common";
import { IRootState } from "us.collection/interfaces";
import { IOnFilter, IOnSort } from "us.common/components/antd/TableTree";

const {
  $Input,
  $Button,
  $Breadcrumb,
  $Table,
  $PageHeader,
  $Affix,
  $TableTree,
} = Common.Components;

interface ITransactionTypes {
  onSelectTransactionType(x: any): void;
  transactionTypes: any;
  enableHeader?: boolean;
}

interface ISortOrder {
  columnKey: string;
  order: any;
}

const TransactionTypes: React.FC<ITransactionTypes> = memo((props) => {
  const { t } = useTranslation(["US.COLLECTION.TRANSACTIONS"]);
  const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
    columnKey: "",
    order: "",
  });
  const { transactionTypes, onSelectTransactionType, enableHeader } = props;

  const selectTransactionType = (type: any) => {
    onSelectTransactionType(type);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter);
  };

  const columns: any = [
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.PAYMENT"),
      dataIndex: "isPayment",
      key: "isPayment",
      width: 100,
      className: "text-nowrap",
      customRenderChild: (text: any, record: any) => {
        return (
          <>
            {record.isPayment ? (
              <BoxIcons
                type={IconTypes.BOX_ICON}
                name="payment"
                className="table-icon-size"
              />
            ) : null}
          </>
        );
      },
    },
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.GROUP"),
      dataIndex: "transactionGroup",
      key: "transactionGroup",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TYPE"),
      dataIndex: "typeName",
      key: "typeName",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.DESCRIPTION"),
      dataIndex: "description",
      key: "description",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
  ];

  const handleSort: IOnSort = (sortData, dataSource) => {
    return sortData(dataSource);
  };

  const handleFilter: IOnFilter = (searchData, dataSource) => {
    return searchData(dataSource);
  };

  return (
    <Formik initialValues={{}} onSubmit={(values: any, actions: any) => {}}>
      <div>
        {enableHeader && (
          <div className="">
            {/* sticky-drawer-header mt-n5 */}
            <$Breadcrumb className="mb-0">
              <$Breadcrumb.Item onClick={() => selectTransactionType(null)}>
                <a>
                  {t(
                    "US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REGISTERTRANSACTION"
                  )}
                </a>
              </$Breadcrumb.Item>
              <$Breadcrumb.Item>
                {t("US.COLLECTION.TRANSACTIONS:REGISTER.TRANSACTION_TYPES")}
              </$Breadcrumb.Item>
            </$Breadcrumb>

            <$PageHeader
              className="p-0 mb-4"
              onBack={() => selectTransactionType(null)}
              title={t("US.COLLECTION.TRANSACTIONS:REGISTER.TRANSACTION_TYPES")}
              // subTitle="double-click to select a type"
            />
          </div>
        )}

        <$TableTree
          onChange={handleTableChange}
          rowKey="arItemNo"
          data={transactionTypes}
          size="small"
          className=""
          onSort={handleSort}
          onFilter={handleFilter}
          filterOnType={true}
          resetOnSourceChange={true}
          bordered
          pagination={{ defaultPageSize: 15 }}
          scroll={{ x: 900 }}
          columns={columns}
          onRow={(record: any, rowIndex: any) => {
            return {
              onDoubleClick: (event: any) => {
                selectTransactionType(record);
              },
            };
          }}
        />

        <div className="drawer-footer-fixed align-content-center">
          <div className="ml-auto">
            <$Button onClick={() => selectTransactionType(null)}>
              {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
            </$Button>
          </div>
        </div>
      </div>
    </Formik>
  );
});

const mapStateToProps = (state: IRootState) => {
  const { transaction } = state;
  const { transactionTypes } = transaction;
  return {
    transactionTypes,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionTypes);
