import moment from "moment";
import { Moment } from "moment";
import { DATE_FORMAT_TO_COMPARE } from "us.collection/constants";

/**
 * @function
 * @description - to determine if the date parameter passed is as same as the date today
 * @param {(Moment | undefined)} pickedDate - the date to be compared
 * @return {*}  {boolean} - the decision if the date is the same
 */
export const isSameDate = (pickedDate: Moment | undefined):boolean =>
moment(moment(pickedDate).format(DATE_FORMAT_TO_COMPARE)).isSame(
  moment().startOf("day").format(DATE_FORMAT_TO_COMPARE)
);