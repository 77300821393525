import { DrawerTypes } from "us.collection/constants";
import {
  IBMDDataType,
  IBMDListModel,
  IBMDPropertyDetails,
  IDrawerStatus,
} from "us.collection/interfaces";
import { appInsights } from "us.helper";
import { INITIAL_VALUES } from "../Constants";
import { IAddBMDProperty } from "../Interfaces";

/**
 * @function
 * @description - To get the list of initial values
 * @param {IDrawerStatus} drawerStatus - The drawer values passed from the redux state
 * @param {IBMDListModel<IBMDPropertyDetails>} bmdPropertyDetails - BMD property details
 * @param {IBMDListModel<IBMDDataType[]>} bmdDataTypes - The list of possible data taypes form the API
 * @return {*}  {IAddBMDProperty} - The initial Values for the BMD add/change form
 */
export const getInitialValues = (
  drawerStatus: IDrawerStatus,
  bmdPropertyDetails: IBMDListModel<IBMDPropertyDetails>,
  bmdDataTypes: IBMDListModel<IBMDDataType[]>
): IAddBMDProperty => {
  try {
    if (
      drawerStatus.drawerType === DrawerTypes.ADD_PROPERTY ||
      bmdPropertyDetails.isLoading ||
      bmdDataTypes.isLoading
    ) {
      return INITIAL_VALUES;
    } else if (drawerStatus.drawerType === DrawerTypes.EDIT_BMD || drawerStatus.drawerType === DrawerTypes.BMD_COUNT) {
      const { data } = bmdPropertyDetails ?? {};
      const {
        keyName,
        dataTypeId,
        displayName,
        cgVisibility,
        crVisibility,
        arVisibility,
        caVisibility,
        changedHistory,
        description,
      } = data ?? {};
      return {
        keyName,
        dataTypeId,
        displayName,
        description,
        fromDate: null,
        comment: "",
        value: "",
        cgVisibility,
        crVisibility,
        arVisibility,
        caVisibility,
        isSaveNewClicked: false,
        selectedBMDDataType: bmdDataTypes.data?.find((dataType) => dataType.id == dataTypeId),
        changedHistory,
        drawerType: DrawerTypes.EDIT_BMD,
        isAddButtonClicked: false,
        shouldShowPopup: false,
        selectedCreditorIds: [],
        updateLowerLevel: false,
        showCreditorView: false,
      };
    } else {
      return INITIAL_VALUES;
    }
  } catch (error) {
    appInsights.trackException(`Error in AddProperty getInitialValues function. - ${error}`);
    return INITIAL_VALUES;
  }
};
