import { WebSocketConfigs } from "us.collection/constants";
import * as Azure from "us.helper/azure";
import { $MessageBox, $Message } from "us.common/components";
import { i18n } from "us.helper";

const { websocketServer, port } = WebSocketConfigs;

/**
 * Send workflow state data to workflow designer tool via web socket.
 * @param stateData Selected state data.
 * @returns true if workflow tool is not running, false otherwise. 
 */
export const openWorkflowTool = async (
  stateData: any
): Promise<boolean> => {
  try {
    return new Promise<boolean>((resolve) => {
      const socket = new WebSocket(websocketServer);
      $Message.loading(
        i18n.t("US.COLLECTION.CASE:WORKFLOWS.OPENING_WORKFLOW"),
        0
      );

      socket.onopen = async () => {
        return sendSocketRequest(stateData, socket)
          .then(() => {
            $Message.destroy();
            $MessageBox(
              "success",
              "US.COLLECTION.CASE:WORKFLOWS.SUCCESSFULLY_OPENED_WORKFLOW",
              "",
              ""
            );
            resolve(false);
          })
          .catch((error) => {
            console.error(error);
            $Message.destroy();
            $MessageBox(
              "error",
              "US.COLLECTION.CASE:WORKFLOWS.FAILED_TO_OPEN_WORKFLOW",
              "",
              ""
            );
            resolve(false);
          });
      };

      socket.onerror = () => {
        console.error("WebSocket connection error. Server may not be running.");
        $Message.destroy();
        resolve(true);
      };
    });
  } catch (e) {
    console.error(e);
    $Message.destroy();
    $MessageBox(
      "error",
      "US.COLLECTION.CASE:WORKFLOWS.FAILED_TO_OPEN_WORKFLOW",
      "",
      ""
    );
    return false;
  }
};

/**
 * Get the status of workflow designer tool.
 * @return true if workflow tool is not running, false otherwise.
 */
export const getWorkflowToolStatus = async (): Promise<boolean> => {
  try {
    return new Promise<boolean>((resolve) => {
      $Message.loading(
        i18n.t("US.COLLECTION.CASE:WORKFLOWS.CHECKING_WORKFLOW_TOOL_STATUS"),
        0
      );

      const socket = new WebSocket(websocketServer);

      socket.onopen = async () => {
        $Message.destroy();
        resolve(false);
      };

      socket.onerror = () => {
        $Message.destroy();
        console.error("WebSocket connection error. Server may not be running.");
        resolve(true);
      };
    });
  } catch (e) {
    $Message.destroy();
    console.error(e);
    return false;
  }
};

/**
 * Format and send socket message to the server.
 * @param stateData Selected state data.
 * @param socket Socket instance.
 */
const sendSocketRequest = async (stateData: any, socket: any) => {
  try {
    if (stateData) {
      const { id, workflowGroupStateId, name, accessLevel, entityId } =
        stateData ?? {};

      const isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
      let authentication: Azure.ADAuth | Azure.B2CAuth;
      authentication = isAzureAD ? new Azure.ADAuth() : new Azure.B2CAuth();
      const currentUser: any = authentication?.currentUser();
      const accessToken: string = authentication?.getToken();

      const serverName = `${window._ENV.REACT_APP_COLLECTION_API_MANAGER}-${window._ENV.REACT_APP_COLLECTION_API_ENV}.${window._ENV.REACT_APP_COLLECTION_API_BASE_URL}`;

      const params =
        "create;" +
        workflowGroupStateId +
        ";" +
        id +
        ";" +
        `https://${serverName}` +
        ";" +
        window._ENV.REACT_APP_AZURE_SUBSCRIPTION_KEY +
        ";" +
        currentUser.upn +
        ";" +
        "admin" +
        ";" +
        name +
        ";" +
        accessLevel +
        ";" +
        entityId +
        ";" +
        accessToken +
        ";" +
        serverName +
        ";" +
        port;
      return await socket.send(params);
    } else {
      return;
    }
  } catch (e) {
    return;
  }
};
