import { TransactionType } from 'us.collection.transactions/constants';
import { ICommonMapping } from 'us.collection.transactions/interfaces';

export function CommonMapping(this: any): ICommonMapping {
	return {
		type: TransactionType.CASE,
		referenceNo: this.caseNo,
		transactionId: this.arItemNo,
	};
}
