import * as API from 'us.collection.case/services';
import { put, call, takeLatest } from 'redux-saga/effects';
import { CourtSentence } from 'us.collection.case/constants/Actions';
import { CourtSentenceActions } from 'us.collection.case/actions';
import { ISentenceSummery } from 'us.collection.case/interfaces';
import { $MessageBox } from 'us.common/components';
import { ICourtSentenceDrawer } from 'us.collection.case/interfaces/CourtSentence/CourtSentenceDrawer';
import { SentneceDrawerStatus } from 'us.collection.case/constants';
import {
	arrangeSentenceDetails,
	arrangeSummeryData,
} from 'us.collection.case/functions';

const {
	summery,
	sentenceDelete,
	sentenceDetails,
	caseAmounts,
	addSentence,
	editSentence,
} = CourtSentenceActions;
const courtSentenceSagas = {
	/**
	 * Get caseId from metaData response and make setence  service call
	 * @param {string} EntityType - type of case. it can be either subcase or case
	 * @param {number} EntityId - case number of particular case
	 */
	sentenceSummery: {
		get: function* (action: {
			type: string;
			payload: {
				data: { caseId: number };
				isLoading: boolean;
			};
		}): any {
			try {
				const accountSummeryData = yield call(
					API.CourtSentence.getSummery,
					action.payload.data
				);
				yield put(
					summery.success(
						arrangeSummeryData(
							accountSummeryData
						)
					)
				);
			} catch (error) {
				yield put(summery.fail(error as Object));
			}
		},
	},

	deleteSentence: {
		patch: function* (action: {
			type: string;
			payload: {
				data: {
					sentenceIdToDelete: number;
					allSentences: ISentenceSummery[];
				};
				isLoading: boolean;
			};
		}): any {
			try {
				const { sentenceIdToDelete } =
					action.payload.data;
				const deletedAck = yield call(
					API.CourtSentence.deleteSentence,
					sentenceIdToDelete
				);
				if (
					deletedAck.hasOwnProperty('isError') &&
					!deletedAck.isError
				) {
					$MessageBox(
						'success',
						'US.COLLECTION.CASE:SENTENCE.SENTENCE_DELETED_SUCCESFULLY',
						'',
						''
					);
					yield put(
						sentenceDelete.success({
							...action.payload.data,
							deletedAck,
						})
					);
				} else {
					$MessageBox(
						'error',
						'US.COLLECTION.CASE:SENTENCE.SENTENCE_DELETE_FAILED',
						'',
						''
					);
					yield put(sentenceDelete.fail({}));
				}
			} catch (error) {
				$MessageBox(
					'error',
					'US.COLLECTION.CASE:SENTENCE.SENTENCE_DELETE_FAILED',
					'',
					''
				);
				yield put(sentenceDelete.fail(error as Object));
			}
		},
	},

	sentenceDetails: {
		get: function* (action: {
			type: string;
			payload: {
				data: { sentenceId: number };
				isLoading: boolean;
			};
		}): any {
			try {
				const { sentenceId } = action.payload.data;
				const sentenceData = yield call(
					API.CourtSentence.getSentence,
					sentenceId
				);
				yield put(
					sentenceDetails.success(
						arrangeSentenceDetails(
							sentenceData
						)
					)
				);
			} catch (error) {
				$MessageBox(
					'error',
					'US.COLLECTION.CASE:SENTENCE.SENTENCE_RETRIEVAL_FAILED',
					'',
					''
				);
				yield put(
					sentenceDetails.fail(error as Object)
				);
			}
		},
	},

	caseAmounts: {
		get: function* (action: {
			type: string;
			payload: {
				data: { entityId: number };
				isLoading: boolean;
			};
		}): any {
			try {
				const { entityId } = action.payload.data;
				const sentenceData = yield call(
					API.CourtSentence.getCaseAmounts,
					entityId
				);
				yield put(caseAmounts.success(sentenceData));
			} catch (error) {
				$MessageBox(
					'error',
					'US.COLLECTION.CASE:SENTENCE.CASE_AMOUT_RETRIEVAL_FAILED',
					'',
					''
				);
				yield put(
					CourtSentenceActions.CaseAmounts.fail(
						error as Object
					)
				);
			}
		},
	},

	addSentence: {
		post: function* (action: {
			type: string;
			payload: {
				data: {
					sentenceObject: any;
					sentenceSummery: ISentenceSummery[];
				};
				isLoading: boolean;
			};
		}): any {
			try {
				const { sentenceObject, sentenceSummery = [] } =
					action.payload.data;
				const newSentence = yield call(
					API.CourtSentence.addSentence,
					sentenceObject
				);
				if (
					newSentence.hasOwnProperty('isError') &&
					!newSentence.isError
				) {
					yield put(
						addSentence.success(newSentence)
					);

					const drawerStatusParam: ICourtSentenceDrawer =
						{
							isVisible: false,
							caseNumber: 0,
							name: '',
							requestCaseAmounts:
								false,
							sentenceId: 0,
							status: SentneceDrawerStatus.ADD_SENTENCE_CLOSE,
						};
					// close drawer
					if (summery.openDrawer) {
						yield put(
							summery.openDrawer(
								drawerStatusParam
							)
						);
					}
					$MessageBox(
						'success',
						'US.COLLECTION.CASE:SENTENCE.SENTENCE_ADDED_SUCCESFULLY',
						'',
						''
					);

					if (summery.get) {
						yield put(
							summery.get({
								caseId: sentenceObject.caseId,
							})
						);
					}
				}
			} catch (error) {
				$MessageBox(
					'error',
					'US.COLLECTION.CASE:SENTENCE.SENTENCE_ADDITION_FAILED',
					'',
					''
				);
				yield put(addSentence.fail(error as Object));
			}
		},
	},

	editSentence: {
		put: function* (action: {
			type: string;
			payload: {
				data: {
					sentenceObject: any;
					sentenceId: number;
				};
				isLoading: boolean;
			};
		}): any {
			try {
				const { sentenceObject, sentenceId } =
					action.payload.data;
				const updatedSentence = yield call(
					API.CourtSentence.editSentence,
					sentenceObject,
					sentenceId
				);
				if (
					updatedSentence.hasOwnProperty(
						'isError'
					) &&
					!updatedSentence.isError
				) {
					yield put(
						editSentence.success(
							updatedSentence
						)
					);

					const drawerStatusParam: ICourtSentenceDrawer =
						{
							isVisible: false,
							caseNumber: 0,
							name: '',
							requestCaseAmounts:
								false,
							sentenceId: 0,
							status: SentneceDrawerStatus.EDIT_SENTNECE_CLOSE,
						};
					// close drawer
					if (summery.openDrawer) {
						yield put(
							summery.openDrawer(
								drawerStatusParam
							)
						);
					}
					$MessageBox(
						'success',
						'US.COLLECTION.CASE:SENTENCE.SENTENCE_UPDATED_SUCCESFULLY',
						'',
						''
					);

					if (summery.get) {
						yield put(
							summery.get({
								caseId: sentenceObject.caseId,
							})
						);
					}
				} else {
					yield put(
						editSentence.fail(
							updatedSentence
						)
					);
				}
			} catch (error) {
				$MessageBox(
					'error',
					'US.COLLECTION.CASE:SENTENCE.SENTENCE_UPDATE_FAILED',
					'',
					''
				);
				yield put(editSentence.fail(error as Object));
			}
		},
	},
};

export default [
	takeLatest(
		CourtSentence.GET_SENTENCE_SUMMERY_START,
		courtSentenceSagas.sentenceSummery.get
	),
	takeLatest(
		CourtSentence.DELETE_SENTENCE_START,
		courtSentenceSagas.deleteSentence.patch
	),
	takeLatest(
		CourtSentence.GET_SENTENCE_START,
		courtSentenceSagas.sentenceDetails.get
	),
	takeLatest(
		CourtSentence.GET_SENTENCE_CASE_AMOUNTS_START,
		courtSentenceSagas.caseAmounts.get
	),
	takeLatest(
		CourtSentence.SAVE_SENTENCE_START,
		courtSentenceSagas.addSentence.post
	),
	takeLatest(
		CourtSentence.UPDATE_SENTENCE_START,
		courtSentenceSagas.editSentence.put
	),
];
