export enum Debtor {
    GET_ADDRESS_INFO_START = "GET_ADDRESS_INFO_START",
    GET_ADDRESS_INFO_SUCCESS = "GET_ADDRESS_INFO_SUCCESS",
    GET_ADDRESS_INFO_FAIL = "GET_ADDRESS_INFO_FAIL",

    GET_CREDITOR_INFO_START = "GET_CREDITOR_INFO_START",
    GET_CREDITOR_INFO_SUCCESS = "GET_CREDITOR_INFO_SUCCESS",
    GET_CREDITOR_INFO_FAIL = "GET_CREDITOR_INFO_FAIL",

    GET_DEBTOR_HISTORY_START = "GET_DEBTOR_HISTORY_START",
    GET_DEBTOR_HISTORY_SUCCESS = "GET_DEBTOR_HISTORY_SUCCESS",
    GET_DEBTOR_HISTORY_FAIL = "GET_DEBTOR_HISTORY_FAIL",

    GET_DEBTOR_OTHER_INFORMATION_START = "GET_DEBTOR_OTHER_INFORMATION_START",
    GET_DEBTOR_OTHER_INFORMATION_SUCCESS = "GET_DEBTOR_OTHER_INFORMATION_SUCCESS",
    GET_DEBTOR_OTHER_INFORMATION_FAIL = "GET_DEBTOR_OTHER_INFORMATION_FAIL",

    SAVE_DEBTOR_DETAIL_START = "SAVE_DEBTOR_DETAIL_START",
    SAVE_DEBTOR_DETAIL_SUCCESS = "SAVE_DEBTOR_DETAIL_SUCCESS",
    SAVE_DEBTOR_DETAIL_FAIL = "SAVE_DEBTOR_DETAIL_FAIL",

    GET_DEBTOR_CASE_INFORMATION_START = "GET_DEBTOR_CASE_INFORMATION_START",
    GET_DEBTOR_CASE_INFORMATION_SUCCESS = "GET_DEBTOR_CASE_INFORMATION_SUCCESS",
    GET_DEBTOR_CASE_INFORMATION_FAIL = "GET_DEBTOR_CASE_INFORMATION_FAIL",

    GET_DEBTOR_BADDEBT_INFO = 'GET_DEBTOR_BADDEBT_INFO',
    GET_DEBTOR_BADDEBT_INFO_SUCCESS = 'GET_DEBTOR_BADDEBT_INFO_SUCCESS',
    GET_DEBTOR_BADDEBT_INFO_FAIL = 'GET_DEBTOR_BADDEBT_INFO_FAIL',

    GET_IF_DEBTOR_IS_BAD = 'GET_IF_DEBTOR_IS_BAD',
    GET_IF_DEBTOR_IS_BAD_SUCCESS = 'GET_IF_DEBTOR_IS_BAD_SUCCESS',
    GET_IF_DEBTOR_IS_BAD_FAIL = 'GET_IF_DEBTOR_IS_BAD_FAIL',

    GET_DEBTOR_CREDIT_INFO = 'GET_DEBTOR_CREDIT_INFO',
    GET_DEBTOR_CREDIT_INFO_SUCCESS = 'GET_DEBTOR_CREDIT_INFO_SUCCESS',
    GET_DEBTOR_CREDIT_INFO_FAIL = 'GET_DEBTOR_CREDIT_INFO_FAIL',

    GET_DEBTOR_POSTALCODE_START = "GET_DEBTOR_POSTALCODE_START",
    GET_DEBTOR_POSTALCODE_SUCCESS = "GET_DEBTOR_POSTALCODE_SUCCESS",
    GET_DEBTOR_POSTALCODE_FAIL = "GET_DEBTOR_POSTALCODE_FAIL",

    GET_DEBTOR_MUNICIPALITY_START = "GET_DEBTOR_MUNICIPALITY_START",
    GET_DEBTOR_MUNICIPALITY_SUCCESS = "GET_DEBTOR_MUNICIPALITY_SUCCESS",
    GET_DEBTOR_MUNICIPALITY_FAIL = "GET_DEBTOR_MUNICIPALITY_FAIL",

    CLEAR_POSTAL_DETAILS = 'CLEAR_POSTAL_DETAILS',

    UPDATE_DEBTOR_FORM_DETAIL = "UPDATE_DEBTOR_FORM_DETAIL"

}