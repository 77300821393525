import { MessageAndNotes } from 'us.collection.case/constants/Actions';
import { initialState } from 'us.collection.case/reducers/Dashboard/State';
import {
	IDomainMessageFormattedTemplate,
	IEmailAttachment,
	IPrintedDocument,
	IUploadedDocuments,
	ISendDomainMessage,
} from './Interfaces';

import {
	updateDomainMessageInitialData,
	resetDomainMessageInitialData,
	getMessageTemplateList,
	setDebtorInfo,
	generateMessageTemplateOptions,
} from 'us.collection.case/functions';

const {
	GET_TEMPLATE_START,
	GET_TEMPLATE_SUCCESS,
	GET_TEMPLATE_FAIL,
	GET_FORMATTED_TEMPLATE_START,
	GET_FORMATTED_TEMPLATE_SUCCESS,
	GET_FORMATTED_TEMPLATE_FAIL,
	FORMATTED_TEMPLATE_DRAWER,
	GET_DOMAIN_MESSAGE_BASIC_INFO_START,
	GET_DOMAIN_MESSAGE_BASIC_INFO_SUCCESS,
	GET_DOMAIN_MESSAGE_BASIC_INFO_FAIL,
	DOMAIN_MESSAGE_INITIAL_VALUE_RESET,
	SEND_DOMAIN_MESSAGE_START,
	SEND_DOMAIN_MESSAGE_SUCCESS,
	SEND_DOMAIN_MESSAGE_FAIL,
	SEND_CREDITOR_PORTAL_MESSAGE_START,
	SEND_CREDITOR_PORTAL_MESSAGE_SUCCESS,
	SEND_CREDITOR_PORTAL_MESSAGE_FAIL,
	SHOW_EMAIL_ATTACHMENT,
	GET_PRINTED_DOCUMENT_START,
	GET_PRINTED_DOCUMENT_SUCCESS,
	GET_PRINTED_DOCUMENT_FAIL,
	GET_UPLOAD_DOCUMENT_START,
	GET_UPLOAD_DOCUMENT_SUCCESS,
	GET_UPLOAD_DOCUMENT_FAIL,
	GET_EMAIL_ATTACHMENT_CONTENT_START,
	GET_EMAIL_ATTACHMENT_CONTENT_SUCCESS,
	GET_EMAIL_ATTACHMENT_CONTENT_FAIL,
	SAVE_NOTE_DETAIL_START,
	SAVE_NOTE_DETAIL_SUCCESS,
	SAVE_NOTE_DETAIL_FAIL,

	GET_MESSAGE_TEXT,
	GET_MESSAGE_TEXT_SUCCESS,
	GET_MESSAGE_TEXT_FAIL,
	GET_MESSAGE_CONTENT,
	GET_MESSAGE_CONTENT_SUCCESS,
	GET_MESSAGE_CONTENT_FAIL,
	SEND_DASHBOARD_MESSAGE_START,
	SEND_DASHBOARD_MESSAGE_SUCCESS,
	SEND_DASHBOARD_MESSAGE_FAIL,
	GET_EDITABLE_IFRAME
} = MessageAndNotes;

export default (state = initialState, { payload, type }: any) => {
	switch (type) {
		case GET_TEMPLATE_START:
		case GET_MESSAGE_TEXT:
			return Object.assign({}, state, {
				isFetching: true,
			});

		case GET_TEMPLATE_SUCCESS:
			const templateList = getMessageTemplateList(payload?.data);
			return Object.assign({}, state, {
				domainMessageTemplate: payload?.data,
				domainMessageInitial: {
					...state.domainMessageInitial,
					templateList,
				},
			});
		case GET_MESSAGE_TEXT_SUCCESS:
			const messageTextOptions = generateMessageTemplateOptions(payload?.data);
			return Object.assign({}, state, {
				domainMessageTemplate: payload?.data,
				domainMessageInitial: {
					...state.domainMessageInitial,
					templateList: messageTextOptions,
				},
			});

		case GET_TEMPLATE_FAIL:
		case GET_MESSAGE_TEXT_FAIL:
			return Object.assign({}, state, {
				isFetching: false,
			});

		case GET_FORMATTED_TEMPLATE_START:
			return Object.assign({}, state, {
				domainMessageInitial: payload?.data.values,
				domainMessageFormattedTemplate: <IDomainMessageFormattedTemplate>{
					data: { communicationJodId: '', formattedText: '' },
					isLoading: true,
				},
			});
		case GET_MESSAGE_CONTENT:
			return Object.assign({}, state, {
				formattedMessageContentDetails:{
					data: {},
					isLoading: true
				}
			});
		case GET_FORMATTED_TEMPLATE_SUCCESS:
			return Object.assign({}, state, {
				domainMessageInitial: {
					...state.domainMessageInitial,
					templateNote: payload?.data?.formattedText,
				},
				domainMessageFormattedTemplate: <IDomainMessageFormattedTemplate>{
					data: payload?.data,
					isLoading: false,
				},
			});
		case GET_MESSAGE_CONTENT_SUCCESS:
			return Object.assign({}, state, {
				formattedMessageContentDetails:{
					data: payload?.data,
					isLoading: false
				}
			});

		case GET_FORMATTED_TEMPLATE_FAIL:
			return Object.assign({}, state, {
				domainMessageInitial: {
					...state.domainMessageInitial,
					templateNote: '',
				},
				domainMessageFormattedTemplate: <IDomainMessageFormattedTemplate>{
					data: { communicationJodId: '', formattedText: '' },
					isLoading: false,
				},
				openTemplateDrawer: { title: '', visible: false },
			});
		case GET_MESSAGE_CONTENT_FAIL:
			return Object.assign({}, state, {
				formattedMessageContentDetails:{
					data: state.formattedMessageContentDetails?.data,
					isLoading: false
				}
			});
		case GET_EDITABLE_IFRAME:
			return Object.assign({}, state, {
				formattedMessageContentDetails:{
					data: {...state.formattedMessageContentDetails?.data, messages: payload?.data},
					isLoading: false
				}
			});
			
		case FORMATTED_TEMPLATE_DRAWER:
			return Object.assign({}, state, {
				openTemplateDrawer: payload?.data,
			});

		case GET_DOMAIN_MESSAGE_BASIC_INFO_START:
			return Object.assign({}, state, {
				isFetching: true,
			});

		case GET_DOMAIN_MESSAGE_BASIC_INFO_SUCCESS:
			const {
				documentTypeList,
				smsNumber,
				emailAddress,
				receiverEntityId,
				documentType,
			} = setDebtorInfo(payload.data?.entityDetails);
			return Object.assign({}, state, {
				domainMessageBasicdetail: payload?.data,
				domainMessageInitial: {
					...state.domainMessageInitial,
					documentTypeList,
					smsNumber,
					emailAddress,
					receiverEntityId,
					documentType,
				},
			});

		case GET_DOMAIN_MESSAGE_BASIC_INFO_FAIL:
			return Object.assign({}, state, {
				isFetching: false,
			});

		case SEND_DOMAIN_MESSAGE_START:
			return Object.assign({}, state, {
				sendDomainMessage: <ISendDomainMessage>{
					data: undefined,
					isProgress: true,
				},
				domainMessageInitial: updateDomainMessageInitialData(payload?.data),
			});
		case SEND_DASHBOARD_MESSAGE_START:
			return Object.assign({}, state, {
				sendDomainMessage: <ISendDomainMessage>{
					data: undefined,
					isProgress: true,
				},
			});

		case SEND_DOMAIN_MESSAGE_SUCCESS:
			return Object.assign({}, state, {
				sendDomainMessage: <ISendDomainMessage>{
					data: payload?.data,
					isProgress: false,
				},
				updateActivityLog: !state.updateActivityLog,
			});
		case SEND_DASHBOARD_MESSAGE_SUCCESS:
			return Object.assign({}, state, {
				sendDomainMessage: <ISendDomainMessage>{
					data: payload?.data,
					isProgress: false,
				},
				updateActivityLog: !state.updateActivityLog,
			});

		case SEND_DOMAIN_MESSAGE_FAIL:
			return Object.assign({}, state, {
				sendDomainMessage: <ISendDomainMessage>{
					data: payload?.data,
					isProgress: false,
				},
			});
		case SEND_DASHBOARD_MESSAGE_FAIL:
			return Object.assign({}, state, {
				sendDomainMessage: <ISendDomainMessage>{
					data: payload?.data,
					isProgress: false,
				},
			});

		case DOMAIN_MESSAGE_INITIAL_VALUE_RESET:
			const debtor = setDebtorInfo(
				state.domainMessageBasicdetail?.entityDetails
			);

			const templateList_ = generateMessageTemplateOptions(
				state?.domainMessageTemplate
			);
			return Object.assign({}, state, {
				domainMessageInitial: resetDomainMessageInitialData(
					debtor.documentTypeList,
					templateList_,
					debtor.smsNumber,
					debtor.emailAddress,
					debtor.receiverEntityId
				),
				sendDomainMessage: <ISendDomainMessage>{
					data: undefined,
					isProgress: false,
				},
			});
		case SHOW_EMAIL_ATTACHMENT:
			return Object.assign({}, state, {
				openAttachmentDrawer: payload?.data,
			});
		case GET_PRINTED_DOCUMENT_START:
			return Object.assign({}, state, {
				printedDocuments: <IPrintedDocument>{
					data: [],
					isLoading: true,
					error: '',
				},
			});

		case GET_PRINTED_DOCUMENT_SUCCESS:
			const document = <any>[];
			payload?.data?.map((doc: any) => {
				if (doc?.messageType != null && doc?.outputInfo?.length > 0) {
					document.push(doc);
				}
			});
			return Object.assign({}, state, {
				printedDocuments: <IPrintedDocument>{
					data: document,
					isLoading: false,
					error: '',
				},
			});

		case GET_PRINTED_DOCUMENT_FAIL:
			return Object.assign({}, state, {
				printedDocuments: <IPrintedDocument>{
					data: [],
					isLoading: false,
					error: payload?.data,
				},
			});
		case GET_UPLOAD_DOCUMENT_START:
			return Object.assign({}, state, {
				uploadedDocuments: <IUploadedDocuments>{
					data: [],
					isLoading: true,
					error: '',
				},
			});

		case GET_UPLOAD_DOCUMENT_SUCCESS:
			return Object.assign({}, state, {
				uploadedDocuments: <IUploadedDocuments>{
					data: payload?.data,
					isLoading: false,
					error: '',
				},
			});

		case GET_UPLOAD_DOCUMENT_FAIL:
			return Object.assign({}, state, {
				uploadedDocuments: <IUploadedDocuments>{
					data: [],
					isLoading: false,
					error: payload?.data,
				},
			});
		case GET_EMAIL_ATTACHMENT_CONTENT_START:
			return Object.assign({}, state, {
				emailAttachmentContent: <IEmailAttachment>{
					data: [],
					isLoading: true,
					error: '',
				},
			});

		case GET_EMAIL_ATTACHMENT_CONTENT_SUCCESS:
			return Object.assign({}, state, {
				emailAttachmentContent: <IEmailAttachment>{
					data: payload?.data,
					isLoading: false,
					error: '',
				},
			});

		case GET_EMAIL_ATTACHMENT_CONTENT_FAIL:
			return Object.assign({}, state, {
				emailAttachmentContent: <IEmailAttachment>{
					data: [],
					isLoading: false,
					error: payload?.data,
				},
			});

		case SAVE_NOTE_DETAIL_START:
			return Object.assign({}, state, {
				isFetching: false,
			});

		case SAVE_NOTE_DETAIL_SUCCESS:
			return Object.assign({}, state, {
				updateActivityLog: !state.updateActivityLog,
			});

		case SAVE_NOTE_DETAIL_FAIL:
			return Object.assign({}, state, {
				isFetching: false,
			});

		case SEND_CREDITOR_PORTAL_MESSAGE_START:
			return Object.assign({}, state, {
				creditorPortalNote: {
					initFormData: payload?.data,
					isSending: true,
				}
			});
		case SEND_CREDITOR_PORTAL_MESSAGE_SUCCESS:
			return Object.assign({}, state, {
				updateActivityLog: !state.updateActivityLog,
				creditorPortalNote: {
					...initialState.creditorPortalNote,
					isSending: false,
				}
			});
		case SEND_CREDITOR_PORTAL_MESSAGE_FAIL:
			return Object.assign({}, state, {
				creditorPortalNote: {
					...initialState.creditorPortalNote,
					isSending: false,
				}
			});
		default:
			return state;
	}
};
