import {  put, call, takeLatest } from 'redux-saga/effects';
import * as API from 'us.collection.creditor/services';
import {  CreditorCases } from 'us.collection.creditor/constants'
import * as Actions from 'us.collection.creditor/actions'

const { creditorCases } = Actions;

const creditorCaseSagas = {
        getCaseList: function* (action: any) {
            const params = action.payload.data.params;
            try {
                const {data, status} = yield call(API.creditorCases.searchCreditorCases, params);
                if (status == 200 || status == 204) {
                  yield put(creditorCases.caseList.success(data))
                } else {
                  yield put(creditorCases.caseList.fail(new Error()));
                }

            } catch (error) {
                yield put(creditorCases.caseList.fail(error))
            }
        }

}

export default [
    takeLatest(CreditorCases.GET_CREDITOR_CASES, creditorCaseSagas.getCaseList),
];