import { eventDrawerInitial, troubleshootDrawerInitial, workflowDrawerInitial, SearchFiltersDefault } from "us.collection/components";
import { IWorkflowStateInitial, IRetryWorkflowState } from "../Interfaces";

const RetryWorkflowInitial: IRetryWorkflowState = {
  data: undefined,
  isError: false,
  isLoading: false,
};

export const initialState: IWorkflowStateInitial = {
  retryWorkflow: RetryWorkflowInitial,
  workflowStates: {
    data: [],
    isLoading: false
  },
  workflowDrawer: workflowDrawerInitial,
  isCategorySaveing: false,
  dataAdapterList: {
    data: [],
    isLoading: false
  },
  isStateSaving: false,
  duplicatingId: undefined,
  isDeleting: false,
  workflowEvents: {
    data: [],
    isLoading: false
  },
  eventDrawer: eventDrawerInitial,
  isEventSaveing: false,
  errorCount: {
    data: [],
    isLoading: false
  },
  monitorList: {
    data: [],
    isLoading: false
  },
  troubleshootDrawer: troubleshootDrawerInitial,
  isStateChanging: false,
  troubleshootCurrentOffset: 0,
  statusHistory: {
    data: [],
    isLoading: false
  },
  statusProperties: {
    data: [],
    isLoading: false
  },
  workflowVersions: {
    data: [],
    isLoading: false
  },
  initWorkflowVersion: -1,
  searchFilters: SearchFiltersDefault,
  isWorkflowPopupVisible: false
};
