import React from "react";
import { IOptionLabel } from "./Interfaces";
import { useTranslation } from "react-i18next";

const OptionLabel: React.FC<IOptionLabel> = (props) => {
  const { code, name } = props;
  const { t } = useTranslation();
  return (
    <div className="py-1">
      <span className="d-block">{`${t(
        "US.COLLECTION.COMMON:ACTIVITYEXECUTION.CODE"
      )} : ${code}`}</span>
      <span className="d-block">{`${t(
        "US.COLLECTION.COMMON:ACTIVITYEXECUTION.NAME"
      )} : ${name}`}</span>
    </div>
  );
};
export default OptionLabel;
