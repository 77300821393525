/**
 * @function
 * @description Add bmd group repository
 * @param this Add bmd group form values
 * @returns AddBMDGroup request object
 */
export function AddBMDGroup(this: any) {
  return {
    groupName: this.name,
    categoryId: this.categoryId,
    description: this.description,
  };
}

/**
 * @function
 * @description Update bmd group repository
 * @param this Update bmd group form values
 * @returns UpdateBMDGroup request object
 */
export function UpdateBMDGroup(this: any) {
  return {
    groupId: this.groupId,
    groupName: this.name,
    categoryId: this.categoryId,
    description: this.description,
  };
}

/**
 * @function
 * @description Add bmd category repository
 * @param this Add bmd category form values
 * @returns AddBMDCategory request object
 */
export function AddBMDCategory(this: any) {
  return {
    category: this.newCategoryName
  };
}
