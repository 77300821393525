import { $MessageBox, $Message } from "us.common/components";
import { workflowAction } from "us.collection/actions";
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as API from "us.collection/services";
import { Workflow } from "us.collection/constants";
import * as DashboardActions from "us.collection.case/actions";
import { IRootState } from "us.collection/interfaces";
import { openWorkflowTool, getRecentWorkflowVersions, getWorkflowToolStatus, getWorkflowStatusProperties } from "us.collection/functions"
import { i18n } from "us.helper";

const {
  RetryWorkflowExecution,
  WorkflowState,
  WorkflowCategory,
  DataAdapter,
  Event,
  ErrorCount,
  MonitorList,
  TroubleshootWorkflow,
  WorkflowActivity,
  WorkflowStatusHistory,
  WorkflowVersions,
} = API.WorkflowExecution;

const {
  retryWorkflow,
  workflowStates,
  category,
  workflowState,
  dataAdapters,
  categoryDeletion,
  stateDeletion,
  eventsByAccessLevel,
  event,
  eventsDelete,
  errorCount,
  monitoringList,
  troubleshootWorkflowRetry,
  troubleshootActivity,
  stateChange,
  statusHistoryInit,
  statusProperties,
  versions,
} = workflowAction;

const { AccountSummeryWidgetActions } = DashboardActions;
const { accountSummery } = AccountSummeryWidgetActions;

const WorkflowExecution = {
  retryWorkflow: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { data, status } = yield call(RetryWorkflowExecution.get, params);
      yield put(retryWorkflow.success(data));
      if (status === 200 && !data?.isError) {
        if (data?.workflowStatus) {
          yield put(
            accountSummery.success({
              ...params.accountSummeryWidgetData.data,
              case: {
                ...params.accountSummeryWidgetData.data?.case,
                ...data?.workflowStatus,
              },
            })
          );
        }
        $MessageBox(
          "success",
          "US.COLLECTION.LAYOUT:CASE.SUCCESSFULLY_EXECUTED",
          "",
          ""
        );
      } else {
        $MessageBox("error", "US.COLLECTION.LAYOUT:CASE.EXECUTED_FAIL", "", "");
      }
    } catch (error) {
      yield put(retryWorkflow.fail(error as any));
    }
  },
  getState: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { stateList, stateStatus } = yield call(WorkflowState.get, params);
      if (stateStatus === 200) {
        yield put(workflowStates.success(stateList));
      } else {
        yield put(workflowStates.fail(stateList));
      }
    } catch (error) {
      yield put(workflowStates.fail(error as any));
    }
  },
  saveCategory: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { data, status } = yield call(
        WorkflowCategory.save,
        params.requestObject
      );
      if (status === 200) {
        const { stateList, stateStatus } = yield call(
          WorkflowState.get,
          params.stateRequest
        );
        yield put(workflowStates.success(stateList));
        yield put(category.success(data));
        $MessageBox(
          "success",
          "US.COLLECTION.CASE:WORKFLOWS.SUCCESSFULLY_CREATED",
          "",
          ""
        );
      } else {
        $MessageBox("error", "US.COLLECTION.CASE:WORKFLOWS.SAVE_FAIL", "", "");
        yield put(category.fail(data));
      }
    } catch (error) {
      yield put(category.fail(error as any));
    }
  },
  updateCategory: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { data, status } = yield call(
        WorkflowCategory.update,
        params.requestObject
      );
      if (status === 200) {
        const { stateList, stateStatus } = yield call(
          WorkflowState.get,
          params.stateRequest
        );
        yield put(workflowStates.success(stateList));
        yield put(category.success(data));
        $MessageBox(
          "success",
          "US.COLLECTION.CASE:WORKFLOWS.UPDATED_SUCCESSFULLY",
          "",
          ""
        );
      } else {
        $MessageBox(
          "error",
          "US.COLLECTION.CASE:WORKFLOWS.UPDATE_FAIL",
          "",
          ""
        );
        yield put(category.fail(data));
      }
    } catch (error) {
      yield put(category.fail(error as any));
    }
  },
  saveState: function* (action: any) {
    const params = action.payload?.data;
    const { isOpenWorkflow, isCopy, request } = params ?? {};
    const { accessLevel, entityId } = request ?? {};
    try {
      const { data, status } = yield call(WorkflowState.save, params?.request);
      const { stateId, workflowGroupStateId } = data ?? {};
      if (status === 200) {
        const { stateList } = yield call(WorkflowState.get, {
          accessLevel,
          entityId,
        });
        yield put(workflowStates.success(stateList));
        $MessageBox(
          "success",
          `US.COLLECTION.CASE:WORKFLOWS.${
            isCopy
              ? "THE_COPY_IS_SUCCESSFULLY_SAVED_AS_A_NEW_STATE"
              : "SUCCESSFULLY_CREATED"
          }`,
          "",
          ""
        );
        
        if(isOpenWorkflow){
          const workflowToolStatus:boolean = yield call(getWorkflowToolStatus);

          if(workflowToolStatus){
           if(workflowState.manageWorkflowPopover)
            yield put(workflowState.manageWorkflowPopover({}));
          }else{
            openWorkflowTool({...params?.request, id: stateId, workflowGroupStateId })
            yield put(workflowState.success(data));
          }
        }else{
          yield put(workflowState.success(data));
        }
      } else if (status === 409) {
        yield put(workflowState.fail({ duplicatingId: stateId }));
      } else {
        $MessageBox("error", "US.COLLECTION.CASE:WORKFLOWS.SAVE_FAIL", "", "");
        yield put(workflowState.fail(data));
      }
    } catch (error) {
      yield put(workflowState.fail(error as any));
    }
  },
  updateState: function* (action: any) {
    const params = action.payload?.data;
    const { accessLevel, entityId } = params?.request ?? {};
    try {
      const { data, status } = yield call(
        WorkflowState.update,
        params?.request
      );
      if (status === 200) {
        const { stateList } = yield call(WorkflowState.get, {
          accessLevel,
          entityId,
        });
        yield put(workflowStates.success(stateList));

        $MessageBox(
          "success",
          "US.COLLECTION.CASE:WORKFLOWS.UPDATED_SUCCESSFULLY",
          "",
          ""
        );
        if(params?.isOpenWorkflow){
          const workflowToolStatus:boolean = yield call(getWorkflowToolStatus);

          if(workflowToolStatus){
           if(workflowState.manageWorkflowPopover)
            yield put(workflowState.manageWorkflowPopover({}));
          }else{
            openWorkflowTool(params?.request)
            yield put(workflowState.success(data));
          }
        }else{
          yield put(workflowState.success(data));
        }
      } else {
        $MessageBox(
          "error",
          "US.COLLECTION.CASE:WORKFLOWS.UPDATE_FAIL",
          "",
          ""
        );
        yield put(workflowState.fail(data));
      }
    } catch (error) {
      yield put(workflowState.fail(error as any));
    }
  },
  getDataAdapters: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { data, status } = yield call(DataAdapter.get, params);
      yield put(dataAdapters.success(data));
    } catch (error) {
      yield put(dataAdapters.fail(error as any));
    }
  },
  getWorkflowVersions: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { data, status } = yield call(WorkflowVersions.get, params);
      if (status == 200) {
        yield put(versions.success(getRecentWorkflowVersions(data)));
      } else {
        yield put(versions.fail(new Error()));
      }
    } catch (error) {
      yield put(versions.fail(error as any));
    }
  },
  deleteCategory: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { data, status } = yield call(WorkflowCategory.delete, params);
      if (status === 200) {
        const { stateList, stateStatus } = yield call(
          WorkflowState.get,
          params.stateRequest
        );
        yield put(workflowStates.success(stateList));
        yield put(categoryDeletion.success(data));
        $MessageBox(
          "success",
          "US.COLLECTION.CASE:WORKFLOWS.SUCCESSFULLY_DELETED",
          "",
          ""
        );
      } else if (
        status === 409 &&
        data?.message?.includes(
          "Provided state category has active workflow states"
        )
      ) {
        yield put(categoryDeletion.fail(data));
        $MessageBox(
          "error",
          "US.COLLECTION.CASE:WORKFLOWS.CANNOT_DELETE_AS_STATES_EXIST_IN_OTHER_LEVELS",
          "",
          ""
        );
      } else {
        yield put(categoryDeletion.fail(data));
        $MessageBox(
          "error",
          "US.COLLECTION.CASE:WORKFLOWS.DELETE_FAIL",
          "",
          ""
        );
      }
    } catch (error) {
      yield put(categoryDeletion.fail(error as any));
    }
  },
  deleteState: function* (action: any) {
    const params = action.payload?.data;
    const { isReActivate, isOpenWorkflow, workflowGroupStateId } = params ?? {};
    if (!isReActivate) $Message.loading(i18n.t("COMMON.DELETING"), 0);
    try {
      const { data, status } = yield call(WorkflowState.delete, params);
      $Message.destroy();
      if (status === 200) {
        const { stateList } = yield call(
          WorkflowState.get,
          params.stateRequest
        );
        yield put(workflowStates.success(stateList));
        
        if (!isReActivate) {
          yield put(stateDeletion.success(data));
          $MessageBox(
            "success",
            "US.COLLECTION.CASE:WORKFLOWS.SUCCESSFULLY_DELETED",
            "",
            ""
          );
        } else {
          $MessageBox(
            "success",
            "US.COLLECTION.CASE:WORKFLOWS.SUCCESSFULLY_RESTORED",
            "",
            ""
          );
          if(isOpenWorkflow){
            const workflowToolStatus:boolean = yield call(getWorkflowToolStatus);
  
            if(workflowToolStatus){
             if(workflowState.manageWorkflowPopover)
              yield put(workflowState.manageWorkflowPopover({}));
            }else{
              openWorkflowTool({...params?.request, id: params?.id, workflowGroupStateId })
              yield put(stateDeletion.success(data));
            }
          }else{
            yield put(stateDeletion.success(data));
          }
        }
      } else {
        yield put(stateDeletion.fail(data));
        if (!isReActivate) {
          $MessageBox(
            "error",
            "US.COLLECTION.CASE:WORKFLOWS.DELETE_FAIL",
            "",
            ""
          );
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.CASE:WORKFLOWS.RESTORE_FAIL",
            "",
            ""
          );
        }
      }
    } catch (error) {
      $Message.destroy();
      yield put(stateDeletion.fail(error as any));
    }
  },
  getEvents: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { events, eventStatus } = yield call(Event.get, params);
      yield put(eventsByAccessLevel.success(events));
    } catch (error) {
      yield put(eventsByAccessLevel.fail(error as any));
    }
  },
  saveEvent: function* (action: any) {
    const params = action.payload?.data;
    const { request } = params ?? {}
    const { accessLevel, entityId } = request ?? {};
    try {
      const { data, status } = yield call(Event.save, request);
      if (status === 200) {
        const { events, eventStatus } = yield call(Event.get, {
          accessLevel,
          entityId,
        });
        yield put(eventsByAccessLevel.success(events));
        yield put(event.success(data));
        $MessageBox(
          "success",
          "US.COLLECTION.CASE:WORKFLOWS.SUCCESSFULLY_CREATED",
          "",
          ""
        );
      } else if (status === 409) {
        yield put(event.fail({ duplicatingId: data?.id }));
      } else {
        $MessageBox("error", "US.COLLECTION.CASE:WORKFLOWS.SAVE_FAIL", "", "");
        yield put(event.fail(data));
      }
    } catch (error) {
      yield put(event.fail(error as any));
    }
  },
  updateEvent: function* (action: any) {
    const params = action.payload?.data;
    const { isOpenWorkflow, request } = params ?? {}
    const { accessLevel, entityId } = request ?? {};
    try {
      const { data, status } = yield call(Event.update, request);
      if (status === 200) {
        const { events, eventStatus } = yield call(Event.get, {
          accessLevel,
          entityId,
        });
        yield put(eventsByAccessLevel.success(events));
        $MessageBox(
          "success",
          "US.COLLECTION.CASE:WORKFLOWS.SUCCESSFULLY_UPDATED",
          "",
          ""
        );

        if(isOpenWorkflow){
          const workflowToolStatus:boolean = yield call(getWorkflowToolStatus);

          if(workflowToolStatus){
           if(workflowState.manageWorkflowPopover)
            yield put(workflowState.manageWorkflowPopover({}));
          }else{
            openWorkflowTool({...request, id: request?.eventId})
            yield put(event.success(data));
          }
        }else{
          yield put(event.success(data));
        }

      } else {
        $MessageBox(
          "error",
          "US.COLLECTION.CASE:WORKFLOWS.UPDATE_FAIL",
          "",
          ""
        );
        yield put(event.fail(data));
      }
    } catch (error) {
      yield put(event.fail(error as any));
    }
  },
  deleteEvent: function* (action: any) {
    const params = action.payload?.data;
    if (!params?.isReActivate) $Message.loading(i18n.t("COMMON.DELETING"), 0);
    try {
      const { data, status } = yield call(WorkflowState.delete, params);
      $Message.destroy();
      if (status === 200) {
        const { events, eventStatus } = yield call(
          Event.get,
          params.stateRequest
        );
        yield put(eventsByAccessLevel.success(events));
        yield put(eventsDelete.success(data));
        if (!params?.isReActivate) {
          $MessageBox(
            "success",
            "US.COLLECTION.CASE:WORKFLOWS.SUCCESSFULLY_DELETED",
            "",
            ""
          );
        } else {
          $MessageBox(
            "success",
            "US.COLLECTION.CASE:WORKFLOWS.SUCCESSFULLY_RESTORED",
            "",
            ""
          );
        }
      } else {
        $Message.destroy();
        yield put(eventsDelete.fail(data));
        if (!params?.isReActivate) {
          $MessageBox(
            "error",
            "US.COLLECTION.CASE:WORKFLOWS.DELETE_FAIL",
            "",
            ""
          );
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.CASE:WORKFLOWS.RESTORE_FAIL",
            "",
            ""
          );
        }
      }
    } catch (error) {
      $Message.destroy();
      yield put(eventsDelete.fail(error as any));
    }
  },
  getErrorCount: function* (action: any) {
    try {
      const { count, countStatus } = yield call(ErrorCount.get, {});
      yield put(errorCount.success(count));
    } catch (error) {
      yield put(errorCount.fail(error as any));
    }
  },
  getMonitorList: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { errorList, errorStatus } = yield call(MonitorList.get, params);
      if (errorStatus == 200) {
        yield put(monitoringList.success(errorList));
      } else {
        yield put(monitoringList.fail(new Error()));
      }
    } catch (error) {
      yield put(monitoringList.fail(error as any));
    }
  },
  reExecuteWorkflow: function* (action: any): any {
    const params = action.payload?.data;
    const { searchText, searchKey, workflowErrorType } =
      params?.searchFilters ?? {};
    $Message.loading(i18n.t("COMMON.EXECUTING"), 0);
    try {
      const { data, status } = yield call(TroubleshootWorkflow.retry, {
        caseId: params?.caseId,
      });
      let offset = yield select(
        (state: IRootState) => state.Workflow.troubleshootCurrentOffset
      );

      if (status === 200) {
        // Get Error Count
        const { count, countStatus } = yield call(ErrorCount.get, {});
        yield put(errorCount.success(count));
        // Get New Error List
        const { errorList, errorStatus } = yield call(MonitorList.get, {
          limit: 100,
          offset,
          searchText,
          searchKey,
          category: workflowErrorType,
        });
        $Message.destroy();
        yield put(monitoringList.success(errorList));
        yield put(troubleshootWorkflowRetry.success(data));
        $MessageBox(
          "success",
          "US.COLLECTION.CASE:WORKFLOWS.SUCCESSFULLY_EXECUTED",
          "",
          ""
        );
      } else if (status === 400) {
        $Message.destroy();
        yield put(troubleshootWorkflowRetry.fail(data));
        $MessageBox(
          "error",
          "US.COLLECTION.CASE:WORKFLOWS.CANNOT_RETRY_CASE_DUE_TO_EXCEPTIONAL_CONDITIONS_PLEASE_CONTACT_SUPPORT_TEAM",
          "",
          ""
        );
      } else {
        $Message.destroy();
        yield put(troubleshootWorkflowRetry.fail(data));
        $MessageBox(
          "error",
          "US.COLLECTION.CASE:WORKFLOWS.EXECUTED_FAIL",
          "",
          ""
        );
      }
    } catch (error) {
      $Message.destroy();
      yield put(troubleshootWorkflowRetry.fail(error as any));
    }
  },
  reExecuteActivity: function* (action: any): any {
    const params = action.payload?.data;
    const { searchText, searchKey, workflowErrorType } =
      params?.searchFilters ?? {};
    $Message.loading(i18n.t("COMMON.EXECUTING"), 0);
    try {
      const { data, status } = yield call(
        WorkflowActivity.retry,
        params?.requestObject
      );
      let offset = yield select(
        (state: IRootState) => state.Workflow.troubleshootCurrentOffset
      );

      if (status === 200) {
        // Get Error Count
        const { count, countStatus } = yield call(ErrorCount.get, {});
        yield put(errorCount.success(count));
        // Get New Error List
        const { errorList, errorStatus } = yield call(MonitorList.get, {
          limit: 100,
          offset,
          searchText,
          searchKey,
          category: workflowErrorType,
        });
        $Message.destroy();
        yield put(monitoringList.success(errorList));
        yield put(troubleshootActivity.success(data));
        $MessageBox(
          "success",
          "US.COLLECTION.CASE:WORKFLOWS.SUCCESSFULLY_EXECUTED",
          "",
          ""
        );
      } else {
        $Message.destroy();
        yield put(troubleshootActivity.fail(data));
        $MessageBox(
          "error",
          "US.COLLECTION.CASE:WORKFLOWS.EXECUTED_FAIL",
          "",
          ""
        );
      }
    } catch (error) {
      $Message.destroy();
      yield put(troubleshootActivity.fail(error as any));
    }
  },
  ignoreActivity: function* (action: any): any {
    const params = action.payload?.data;
    const { searchText, searchKey, workflowErrorType } =
      params?.searchFilters ?? {};
    $Message.loading(i18n.t("COMMON.EXECUTING"), 0);
    try {
      const { data, status } = yield call(
        WorkflowActivity.retry,
        params?.requestObject
      );
      let offset = yield select(
        (state: IRootState) => state.Workflow.troubleshootCurrentOffset
      );

      if (status === 200) {
        // Get Error Count
        const { count, countStatus } = yield call(ErrorCount.get, {});
        yield put(errorCount.success(count));
        // Get New Error List
        const { errorList, errorStatus } = yield call(MonitorList.get, {
          limit: 100,
          offset,
          searchText,
          searchKey,
          category: workflowErrorType,
        });
        $Message.destroy();
        yield put(monitoringList.success(errorList));
        yield put(troubleshootActivity.success(data));
        $MessageBox(
          "success",
          "US.COLLECTION.CASE:WORKFLOWS.SUCCESSFULLY_EXECUTED",
          "",
          ""
        );
      } else {
        $Message.destroy();
        yield put(troubleshootActivity.fail(data));
        $MessageBox(
          "error",
          "US.COLLECTION.CASE:WORKFLOWS.EXECUTED_FAIL",
          "",
          ""
        );
      }
    } catch (error) {
      $Message.destroy();
      yield put(troubleshootActivity.fail(error as any));
    }
  },
  deleteActivity: function* (action: any): any {
    const params = action.payload?.data;
    const { searchText, searchKey, workflowErrorType } =
      params?.searchFilters ?? {};
    $Message.loading(i18n.t("COMMON.DELETING"), 0);
    try {
      const { data, status } = yield call(
        WorkflowActivity.delete,
        params?.pendingexecutionid
      );
      let offset = yield select(
        (state: IRootState) => state.Workflow.troubleshootCurrentOffset
      );

      if (status === 200) {
        // Get Error Count
        const { count, countStatus } = yield call(ErrorCount.get, {});
        yield put(errorCount.success(count));
        // Get New Error List
        const { errorList, errorStatus } = yield call(MonitorList.get, {
          limit: 100,
          offset,
          searchText,
          searchKey,
          category: workflowErrorType,
        });
        $Message.destroy();
        yield put(monitoringList.success(errorList));
        yield put(troubleshootActivity.success(data));
        $MessageBox(
          "success",
          "US.COLLECTION.CASE:WORKFLOWS.SUCCESSFULLY_DELETED",
          "",
          ""
        );
      } else {
        $Message.destroy();
        yield put(troubleshootActivity.fail(data));
        $MessageBox(
          "error",
          "US.COLLECTION.CASE:WORKFLOWS.DELETE_FAIL",
          "",
          ""
        );
      }
    } catch (error) {
      yield put(troubleshootActivity.fail(error as any));
    }
  },
  changeState: function* (action: any): any {
    const params = action.payload?.data;
    const { searchText, searchKey, workflowErrorType } =
      params?.searchFilters ?? {};
    try {
      const { data, status } = yield call(WorkflowState.change, params);

      let offset = yield select(
        (state: IRootState) => state.Workflow.troubleshootCurrentOffset
      );

      if (status === 200) {
        // Get Error Count
        const { count, countStatus } = yield call(ErrorCount.get, {});
        yield put(errorCount.success(count));
        // Get New Error List
        const { errorList, errorStatus } = yield call(MonitorList.get, {
          limit: 100,
          offset,
          searchText,
          searchKey,
          category: workflowErrorType,
        });
        yield put(monitoringList.success(errorList));
        yield put(stateChange.success(data));
        $MessageBox(
          "success",
          "US.COLLECTION.CASE:WORKFLOWS.SUCCESSFULLY_CHANGED",
          "",
          ""
        );
      } else {
        yield put(stateChange.fail(data));
        $MessageBox(
          "error",
          "US.COLLECTION.CASE:WORKFLOWS.STATE_CHANGE_FAIL",
          "",
          ""
        );
      }
    } catch (error) {
      yield put(stateChange.fail(error as any));
    }
  },
  getStatusHistory: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { historyData, historyStatus, propertiesData } = yield call(
        WorkflowStatusHistory.get,
        params
      );
      if (historyStatus == 200 && Array.isArray(historyData)) {
        yield put(
          statusHistoryInit.success({
            statusHistory: historyData,
            statusProperties: getWorkflowStatusProperties(propertiesData),
          })
        );
      } else {
        yield put(statusHistoryInit.fail(new Error()));
      }
    } catch (error) {
      yield put(statusHistoryInit.fail(error as any));
    }
  },
  getStatusProperties: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { data, status } = yield call(
        WorkflowStatusHistory.getProperties,
        params
      );
      if (status == 200) {
        yield put(statusProperties.success(getWorkflowStatusProperties(data)));
      } else {
        yield put(statusProperties.fail(new Error()));
      }
    } catch (error) {
      yield put(statusProperties.fail(error as any));
    }
  },
};

export default [
  takeLatest(Workflow.RETRY_WORKFLOW_START, WorkflowExecution.retryWorkflow),
  takeLatest(Workflow.GET_WORKFLOW_STATES_START, WorkflowExecution.getState),
  takeLatest(
    Workflow.ADD_WORKFLOW_CATEGORY_START,
    WorkflowExecution.saveCategory
  ),
  takeLatest(Workflow.ADD_WORKFLOW_STATE_START, WorkflowExecution.saveState),
  takeLatest(
    Workflow.GET_DATA_ADAPTERS_START,
    WorkflowExecution.getDataAdapters
  ),
  takeLatest(
    Workflow.UPDATE_WORKFLOW_CATEGORY_START,
    WorkflowExecution.updateCategory
  ),
  takeLatest(
    Workflow.DELETE_WORKFLOW_CATEGORY_START,
    WorkflowExecution.deleteCategory
  ),
  takeLatest(
    Workflow.UPDATE_WORKFLOW_STATE_START,
    WorkflowExecution.updateState
  ),
  takeLatest(
    Workflow.DELETE_WORKFLOW_STATE_START,
    WorkflowExecution.deleteState
  ),
  takeLatest(Workflow.GET_EVENTS_START, WorkflowExecution.getEvents),
  takeLatest(Workflow.ADD_WORKFLOW_EVENT_START, WorkflowExecution.saveEvent),
  takeLatest(
    Workflow.UPDATE_WORKFLOW_EVENT_START,
    WorkflowExecution.updateEvent
  ),
  takeLatest(
    Workflow.DELETE_WORKFLOW_EVENT_START,
    WorkflowExecution.deleteEvent
  ),
  takeLatest(Workflow.GET_ERROR_COUNT_START, WorkflowExecution.getErrorCount),
  takeLatest(
    Workflow.GET_MONITORING_LIST_START,
    WorkflowExecution.getMonitorList
  ),
  takeLatest(
    Workflow.RETRY_TROUBLESHOOT_WORKFLOW_START,
    WorkflowExecution.reExecuteWorkflow
  ),
  takeLatest(
    Workflow.RETRY_TROUBLESHOOT_ACTIVITY_START,
    WorkflowExecution.reExecuteActivity
  ),
  takeLatest(
    Workflow.IGNORE_TROUBLESHOOT_ACTIVITY_START,
    WorkflowExecution.ignoreActivity
  ),
  takeLatest(
    Workflow.DELETE_TROUBLESHOOT_ACTIVITY_START,
    WorkflowExecution.deleteActivity
  ),
  takeLatest(
    Workflow.CHANGE_WORKFLOW_STATE_START,
    WorkflowExecution.changeState
  ),
  takeLatest(
    Workflow.GET_WORKFLOW_HISTORY_INIT_START,
    WorkflowExecution.getStatusHistory
  ),
  takeLatest(
    Workflow.GET_WORKFLOW_STATUS_PROPERTIES_START,
    WorkflowExecution.getStatusProperties
  ),
  takeLatest(
    Workflow.GET_WORKFLOW_VERSIONS_START,
    WorkflowExecution.getWorkflowVersions
  ),
];