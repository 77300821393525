import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {PaymentDistribution} from 'us.collection.transactions/components'
import { ToggleClass } from 'us.helper';

interface ICnt_CaseDetailView extends RouteComponentProps {
  caseNo: number;
}

const Cnt_PaymentDistribution: React.FC<ICnt_CaseDetailView> = (props) => {

  ToggleClass('change-body-color-dom');

  const onMinimize = () => {
    props.history.push(`/case/${props.caseNo}`);
  };

  return (
    <>
      {/* <p>Payment distribution UI</p>
      <button onClick={onMinimize}>Go back</button> */}
      <PaymentDistribution />
    </>
  );
};

export default withRouter(Cnt_PaymentDistribution);
