import {
  INoteDrawer,
  IARNoteInitialState,
  IArNoteSummery,
} from "../Interfaces";

const arNoteSummery: IArNoteSummery = {
  data: [],
  isLoading: false,
};

export const noteDrawerInitial: INoteDrawer = {
  visible: false,
  title: "",
  isEdit: false,
};

export const initialState: IARNoteInitialState = {
  summery: arNoteSummery,
  noteDrawer : noteDrawerInitial,
  isNoteSaving: false
};
