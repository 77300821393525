import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IParameterTable } from "../Interfaces";
import {
  $Table,
  $Input,
  $DatePicker,
  $Switch,
  $Select,
} from "us.common/components";
import { IRootState } from "us.collection/interfaces";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ParameterType, InitialField } from "us.collection.followup/constants";
import { appInsights } from "us.helper";
import { Execution } from "../Execution";
import { followUps } from "us.collection.followup/actions";

const { activity } = followUps;

const ParameterTable: React.FC<PropsFromRedux & IParameterTable> = (props) => {
  const { t } = useTranslation();

  const {
    currentDateFormat,
    parameters,
    manageDropDown,
    setFieldValue,
    values,
    handleSubmit,
    isActivityExecuting,
    isValidToExecute,
    isSingleFollowUp,
  } = props;

  const { dataSource, isEnableDropDown } = parameters;
  const { executingDateTimeWithParameter } = values;

  useEffect(() => {
    if (dataSource.length > 0) {
      window.setTimeout(function () {
        document.querySelector(`[name=${dataSource[0]?.name}]` as any)?.focus();
      }, 200);
    }
  }, [dataSource]);

  /**
   * @function
   * @description manage parameter dropdwon
   */
  const onClose = () => {
    manageDropDown && manageDropDown({});
  };

  /**
   * @function
   * @description set schedule date
   * @param {moment.Moment} date selected date
   * @param setFieldValue -formik setFieldValue prop
   */
  const onSelectDate = (date: moment.Moment, setFieldValue: any) => {
    setFieldValue(InitialField.ExecutingDateTimeWithParameter, date);
  };
  /**
   * @function
   * @description get field for each parameter
   * @param record a table data record
   * @returns relevant compenent
   */
  const getParameterField = (record: any, index: number): JSX.Element => {
    try {
      const { valueType, parameterType, name, value } = record;
      const tabIndex: number = index + 1;
      if (ParameterType.LIST === valueType) {
        return (
          <div>
            <$Select
              name={name}
              allOption={false}
              optionText="value"
              optionValue="id"
              style={{ width: "100%", margin: "4px 0 7px 0" }}
              options={value}
              onSearchBy={["value"]}
              dropdownMatchSelectWidth={false}
              tabIndex={tabIndex}
            />
          </div>
        );
      } else {
        switch (parameterType) {
          case ParameterType.INT:
          case ParameterType.STRING:
            return (
              <div>
                <$Input
                  name={name}
                  size="small"
                  style={{ width: "100%", marginBottom: "-5px" }}
                  tabIndex={tabIndex}
                />
              </div>
            );
          case ParameterType.DATETIME:
          case ParameterType.DATE:
            return (
              <div>
                <$DatePicker
                  name={name}
                  allowClear={true}
                  size="small"
                  placeholder={currentDateFormat}
                  format={currentDateFormat}
                  defaultValue={value}
                  value={values[name]}
                  style={{ width: "100%", marginBottom: "-5px" }}
                  tabIndex={tabIndex}
                />
              </div>
            );
          case ParameterType.BOOLEAN:
            return (
              <div className="d-flex align-items-center mb-1">
                <$Switch name={name} tabIndex={tabIndex} />
              </div>
            );
          default:
            return (
              <div>
                <$Input
                  name={name}
                  size="small"
                  style={{ width: "100%", marginBottom: "-5px" }}
                  tabIndex={tabIndex}
                />
              </div>
            );
        }
      }
    } catch (error) {
      appInsights.trackException(
        `FollowUp-ParameterTable getParameterField Exception - ${error}`
      );
      return <></>;
    }
  };

  const parametersColumns: any = [
    {
      title: t("US.COLLECTION.COMMON:ACTIVITYEXECUTION.PARAMETER_NAME"),
      dataIndex: "name",
      key: "name",
      width: 150,
      className: "text-nowrap",
      ellipsis: true,
    },
    {
      title: t("US.COLLECTION.COMMON:ACTIVITYEXECUTION.PARAMETER_DATA_TYPE"),
      dataIndex: "parameterType",
      key: "parameterType",
      width: 150,
      className: "text-nowrap",
      ellipsis: true,
    },
    {
      title: t("US.COLLECTION.COMMON:ACTIVITYEXECUTION.VALUE"),
      dataIndex: "value",
      key: "value",
      className: "parameters-value",
      render: (text: any, record: any, index: number) => {
        return <>{getParameterField(record, index)}</>;
      },
    },
  ];

  return (
    <div className="parameters-dropdown" style={{ width: 585 }}>
      <$Table
        rowKey={(record) => record.name}
        columns={parametersColumns}
        dataSource={dataSource}
        className="parameters mt-1 mb-3"
        size="small"
        pagination={false}
        bordered
        data-testid="settings-table"
      />
      <div className="d-flex justify-content-end mt-2">
        <Execution
          name="executingDateTimeWithParameter"
          currentDateFormat={currentDateFormat}
          isEnableParameter={true}
          isdisable={!isEnableDropDown}
          isExecuting={isActivityExecuting}
          onClose={onClose}
          setFieldValue={setFieldValue}
          onDateSelect={onSelectDate}
          date={executingDateTimeWithParameter}
          onExecute={handleSubmit}
          values={values}
          isValidToExecute={isValidToExecute}
          isSingleFollowUp={isSingleFollowUp}
          tabStartedIndex={dataSource.length}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common, followUps } = state;
  const { parameters, isActivityExecuting } = followUps;
  const { currentCurrency, currentDateFormat } = common;
  return {
    currentCurrency,
    currentDateFormat,
    parameters,
    isActivityExecuting,
  };
};

const mapDispatchToProps = {
  manageDropDown: activity.mangeParameterDropDown,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ParameterTable);
