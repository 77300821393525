import React, { useCallback, useContext, useMemo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { DebtorBasicInfo } from 'us.collection.debtor/components/DebtorBasicInfo';
import { Log } from 'us.collection.routines-and-activities/components';
import {
	PropertiesInfo,
	NotesAndMessages,
	ObjectionAndAgreement,
} from '../components/Detail';
import { connect } from 'react-redux';
import Common from 'us.common';
import { CaseType } from 'us.helper/types/enums';
import { IDataModel } from 'us.collection.case/reducers/Dashboard/Interfaces';
import { ConfigurationContext } from 'us.common/ConfigurationContext/ConfigurationProvider';
import { WorkflowState } from 'us.collection.case/constants';
import { IMetaData } from 'us.collection/interfaces';
import { Home } from 'us.collection.case/components/Detail/AccountSummery';
import { ManualFollowUpsHome } from 'us.collection.case/components/ManualFollowUps';
import { RootState } from 'us.helper/types';

interface ICnt_CaseDetailView extends RouteComponentProps {
	caseType: CaseType;
	metaData: IDataModel<IMetaData>;
	workflow: any;
}

const { $Row, $Col, $Divider } = Common.Components;
const closeStates: Array<string> = [
	WorkflowState.CLOSE,
	WorkflowState.CLOSED,
	WorkflowState.SUV_CLOSE_CASE,
];

const Cnt_CaseDetailView: React.FC<ICnt_CaseDetailView> = (props) => {
	const { metaData, workflow, history } = props;
	const { caseNo, entityType } = metaData.data ?? {};
	const { workflowDescription = '' } = workflow.data ?? {};
	const context = useContext(ConfigurationContext);
	const dashboardPermission = useMemo(() => {
		if (context.componentPermission) {
			return context.componentPermission['case'];
		}
	}, [context]);

	const { location } = history;

	/**
	 * the function that handle the maximization of the summerized case item
	 * @param url the directing URL
	 */
	const maximize = (url: string) => {
		caseNo &&
			history.push({
				...location,
				pathname: `/case/${caseNo}/${url}`,
			});
	};

	const isWidgetAvailable = useCallback(
		(widgetName: string) => {
			return dashboardPermission?.components[widgetName]
				?.isEnabled;
		},
		[dashboardPermission]
	);

	return (
		<div className='space-content'>
			<$Row gutter={16} className='mt-3'>
				<$Col className='gutter-row' span={12}>
					<$Row gutter={16}>
						<$Col
							className='gutter-row'
							span={8}>
							<div className='gutter-box pr-xxl-1'>
								{isWidgetAvailable(
									'debtor'
								) && (
									<DebtorBasicInfo
										onMaximize={() =>
											maximize(
												`debtor-information`
											)
										}
										onOtherParty={() =>
											maximize(
												`other-party`
											)
										}
										{...props}
										debtorPermission={
											dashboardPermission
												?.components[
												'debtor'
											]
												?.components
										}
									/>
								)}
								{isWidgetAvailable(
									'caseInformation'
								) && (
									<PropertiesInfo
										onMaximize={() =>
											entityType ===
											'C'
												? maximize(
														`case-information`
												  )
												: undefined
										}
									/>
								)}
							</div>
						</$Col>
						<$Col
							className='gutter-row'
							span={16}>
							<div className='gutter-box pr-xxl-3'>
								{isWidgetAvailable(
									'accountSummary'
								) && (
									<Home
										{...props}
									/>
								)}
							</div>
						</$Col>
					</$Row>
				</$Col>

				<$Col className='gutter-row' span={12}>
					<$Row gutter={16}>
						<$Col
							className='gutter-row'
							span={12}>
							<div className='gutter-box pr-xxl-3'>
								{isWidgetAvailable(
									'messageAndNotes'
								) && (
									<NotesAndMessages />
								)}
								{isWidgetAvailable(
									'objection'
								) &&
									!closeStates.includes(
										workflowDescription
									) && (
										<ObjectionAndAgreement
											objectionAndAgreementPermission={
												dashboardPermission
													?.components[
													'objection'
												]
													?.component
											}
										/>
									)}
							</div>
						</$Col>

						<$Col
							className='gutter-row'
							span={12}>
							<div className='gutter-box'>
								<ManualFollowUpsHome />
								<$Divider className='my-3' />
								{isWidgetAvailable(
									'history'
								) && (
									<Log
										onMaximize={() =>
											maximize(
												`history`
											)
										}
										{...props}
									/>
								)}
							</div>
						</$Col>
					</$Row>
				</$Col>
			</$Row>
		</div>
	);
};

const mapStateToProps = (state: RootState) => {
	const { domainView } = state;
	const { metaData, workflow } = domainView;
	return {
		metaData,
		workflow,
	};
};

const mapDispatchToProps = {};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Cnt_CaseDetailView));
