import { IColumn } from "./Interfaces";

export const enum FollowUpActionHistoryDataIndex {
  CREATED_DATE = "createdDate",
  USER_NAME = "userName",
  ACTION = "action",
}

export const FOLLOW_UP_ACTION_HISTORY_COLUMNS: Array<IColumn> = [
  {
    dataIndex: FollowUpActionHistoryDataIndex.CREATED_DATE,
    title: "CREATED_DATE_AND_TIME",
    width: 200,
  },
  {
    dataIndex: FollowUpActionHistoryDataIndex.USER_NAME,
    title: "CREATED_BY",
    ellipsis: true,
  },
  {
    dataIndex: FollowUpActionHistoryDataIndex.ACTION,
    title: "ACTION",
    ellipsis: true,
  },
];
