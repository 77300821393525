import { takeEvery, put, call, all, takeLatest } from 'redux-saga/effects';
import * as API from 'us.collection.creditor/services';
import { Creditor } from 'us.collection.creditor/constants'
import * as Actions from 'us.collection.creditor/actions'

const { creditor } = Actions;

const creditorSagas = {
    general: {
        getById: function* (action: any) {
            const params = action.payload.data;
            try {
                const data = yield call(API.creditor.general.getById, params);
                yield put(creditor.general.success(data))
            } catch (error) {
                yield put(creditor.general.fail(error))
            }
        }
    },
    otherContact: {
        getById: function* (action: any) {
            const params = action.payload.data;
            try {
                const data = yield call(API.creditor.otherContact.getById, params);
                yield put(creditor.otherContact.success(data))
            } catch (error) {
                yield put(creditor.otherContact.fail(error))
            }
        }
    },
    creditors: {
        getById: function* (action: any) {
            const params = action.payload.data;
            try {
                const data = yield call(API.creditor.creditors.getById, params);
                yield put(creditor.creditors.success(data))
            } catch (error) {
                yield put(creditor.creditors.fail(error))
            }
        }
    },
    creditorBMDs: {
        getById: function* (action: any) {
            const params = action.payload.data;
            try {
                const data = yield call(API.creditor.settings.getById, params);
                yield put(creditor.creditorBMDs.success(data))
            } catch (error) {
                yield put(creditor.creditorBMDs.fail(error))
            }
        }
    }

}

export default [
    takeLatest(Creditor.GET_GENERAL_AND_ADDRESS_DATA_START, creditorSagas.general.getById),
    takeLatest(Creditor.GET_OTHER_CONTACT_DATA_START, creditorSagas.otherContact.getById),
    takeLatest(Creditor.GET_CREDITORS_DATA_START, creditorSagas.creditors.getById),
    takeLatest(Creditor.GET_CREDITORS_BMD_DATA_START, creditorSagas.creditorBMDs.getById)
];