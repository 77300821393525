import { IAPIAction } from 'us.collection/interfaces';
import { FollowUps } from 'us.collection.followup/constants/Actions';
import { IFollowUpsAction } from 'us.collection.followup/interfaces/Actions';

export const followUps: Readonly<IFollowUpsAction & IAPIAction> = {
	/**
	 * A namespace
	 * @namespace categories
	 */
	categories: {
		/**
		 * Get all categories start action
		 */
		get: (data) => ({
			type: FollowUps.GET_FOLLOW_UP_CATEGORIES,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Get all categories success action
		 */
		success: (data) => ({
			type: FollowUps.GET_FOLLOW_UP_CATEGORIES_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Get all categories fail action
		 */
		fail: (error) => ({
			type: FollowUps.GET_FOLLOW_UP_CATEGORIES_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},

	/**
	 * A namespace
	 * @namespace list
	 */
	list: {
		/**
		 * Get all followUps list start action
		 */
		get: (data) => ({
			type: FollowUps.GET_FOLLOW_UP_LIST,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Get all followUps list success action
		 */
		success: (data) => ({
			type: FollowUps.GET_FOLLOW_UP_LIST_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Get all followUps list fail action
		 */
		fail: (error) => ({
			type: FollowUps.GET_FOLLOW_UP_LIST_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	/**
	 * A namespace
	 * @namespace item
	 */
	item: {
		/**
		 * unPin followUp item start action
		 */
		unPin: (data) => ({
			type: FollowUps.UNPIN_FOLLOW_UP_ITEM,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Pin followUp item success action
		 */
		success: (data) => ({
			type: FollowUps.UNPIN_FOLLOW_UP_ITEM_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Pin followUp item fail action
		 */
		fail: (error) => ({
			type: FollowUps.UNPIN_FOLLOW_UP_ITEM_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	/**
	 * A namespace
	 * @namespace unpinnedItems
	 */
	unpinnedItems: {
		/**
		 * unPin followUp item start action
		 */
		set: (data) => ({
			type: FollowUps.SET_UNPINNED_EXPANDED,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Pin followUp item success action
		 */
		success: (data) => ({
			type: FollowUps.SET_UNPINNED_EXPANDED_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Pin followUp item fail action
		 */
		fail: (error) => ({
			type: FollowUps.SET_UNPINNED_EXPANDED_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	/**
	 * A namespace
	 * @namespace result
	 */
	result: {
		/**
		 * Get followUp result start action
		 */
		get: (data) => ({
			type: FollowUps.GET_FOLLOW_UP_RESULT,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Get followUp result success action
		 */
		success: (data) => ({
			type: FollowUps.GET_FOLLOW_UP_RESULT_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Get followUp result fail action
		 */
		fail: (error) => ({
			type: FollowUps.GET_FOLLOW_UP_RESULT_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		/**
		 * Assign followUp to user action
		 */
		assignUser: (data, history, other) => ({
			type: FollowUps.ASSIGN_FOLLOW_UP_TO_USER,
			payload: {
				data,
				history,
				other,
				isLoading: true,
			},
		}),
		/**
		 * Set last action to followup result
		 */
		setLastActions: (data) => ({
			type: FollowUps.SET_LAST_ACTION_TO_FOLLOWUP_RESULT,
			payload: {
				data,
				isLoading: true,
			},
		}),
	},
	/**
	 * A namespace
	 * @namespace filters
	 */
	filters: {
		/**
		 * Set followUp filters start action
		 */
		set: (data) => ({
			type: FollowUps.SET_FOLLOW_UP_FILTERS,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Set followUp filters success action
		 */
		success: (data) => ({
			type: FollowUps.SET_FOLLOW_UP_FILTERS_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Set followUp filters fail action
		 */
		fail: (error) => ({
			type: FollowUps.SET_FOLLOW_UP_FILTERS_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	/**
	 * A namespace
	 * @namespace categoryFilters
	 */
	categoryFilters: {
		/**
		 * Set followUp filters start action
		 */
		set: (data) => ({
			type: FollowUps.SET_FOLLOW_UP_CATEGORY_FILTERS,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Set followUp filters success action
		 */
		success: (data) => ({
			type: FollowUps.SET_FOLLOW_UP_CATEGORY_FILTERS_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Set followUp filters fail action
		 */
		fail: (error) => ({
			type: FollowUps.SET_FOLLOW_UP_CATEGORY_FILTERS_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	/**
	 * A namespace
	 * @namespace historyForEntity
	 */
	historyForEntity: {
		/**
		 * Set followUp history for entity start action
		 */
		get: (data) => ({
			type: FollowUps.GET_FOLLOW_UP_HISTORY_FOR_ENTITY,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Set followUp history for entity success action
		 */
		success: (data) => ({
			type: FollowUps.GET_FOLLOW_UP_HISTORY_FOR_ENTITY_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Set followUp history for entity fail action
		 */
		fail: (error) => ({
			type: FollowUps.GET_FOLLOW_UP_HISTORY_FOR_ENTITY_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	/**
	 * A namespace
	 * @namespace historyForItem
	 */
	historyForItem: {
		/**
		 * Get followUp history for item start action
		 */
		get: (data) => ({
			type: FollowUps.GET_FOLLOW_UP_HISTORY_FOR_ITEM,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Get followUp history for item success action
		 */
		success: (data) => ({
			type: FollowUps.GET_FOLLOW_UP_HISTORY_FOR_ITEM_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Get followUp history for item fail action
		 */
		fail: (error) => ({
			type: FollowUps.GET_FOLLOW_UP_HISTORY_FOR_ITEM_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	/**
	 * A namespace
	 * @namespace historyForAction
	 */
	historyForAction: {
		/**
		 * Get followUp history for action start action
		 */
		get: (data) => ({
			type: FollowUps.GET_FOLLOW_UP_HISTORY_FOR_ACTION,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Get followUp history for action success action
		 */
		success: (data) => ({
			type: FollowUps.GET_FOLLOW_UP_HISTORY_FOR_ACTION_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Get followUp history for action fail action
		 */
		fail: (error) => ({
			type: FollowUps.GET_FOLLOW_UP_HISTORY_FOR_ACTION_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	/**
	 * A namespace
	 * @namespace historyDrawer
	 */
	historyDrawer: {
		/**
		 * Manage history drawer action start action
		 */
		manage: (data) => ({
			type: FollowUps.MANAGE_FOLLOW_UP_HISTORY_DRAWER,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Manage history drawer action success action
		 */
		success: (data) => ({
			type: FollowUps.MANAGE_FOLLOW_UP_HISTORY_DRAWER_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Manage history drawer for action fail action
		 */
		fail: (error) => ({
			type: FollowUps.MANAGE_FOLLOW_UP_HISTORY_DRAWER_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},

	/**
	 * A namespace
	 * @namespace activeFollowUps
	 */
	activeFollowUps: {
		/**
		 * Get activeFollowUps action start action
		 */
		get: (data) => ({
			type: FollowUps.GET_ACTIVE_FOLLOW_UP,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Get activeFollowUps success action
		 */
		success: (data) => ({
			type: FollowUps.GET_ACTIVE_FOLLOW_UP_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Get activeFollowUps fail action
		 */
		fail: (error) => ({
			type: FollowUps.GET_ACTIVE_FOLLOW_UP_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},

	/**
	 * A namespace
	 * @namespace activities
	 */
	activities: {
		/**
		 * Get followUp activities for action start action
		 */
		get: (data) => ({
			type: FollowUps.GET_FOLLOW_UP_ACTIVITIES_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Get followUp activities for action success action
		 */
		success: (data) => ({
			type: FollowUps.GET_FOLLOW_UP_ACTIVITIES_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Get followUp activities for action fail action
		 */
		fail: (error) => ({
			type: FollowUps.GET_FOLLOW_UP_ACTIVITIES_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	/**
	 * A namespace
	 * @namespace activity
	 */
	activity: {
		/**
		 * Execute followUp activity for action start action
		 */
		execute: (data, other) => ({
			type: FollowUps.EXECUTE_FOLLOW_UP_ACTIVITY_STRAT,
			payload: {
				data,
				other,
				isLoading: true,
			},
		}),
		/**
		 * Execute followUp activity for action success action
		 */
		success: (data) => ({
			type: FollowUps.EXECUTE_FOLLOW_UP_ACTIVITY_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Execute followUp activity for action fail action
		 */
		fail: (error) => ({
			type: FollowUps.EXECUTE_FOLLOW_UP_ACTIVITY_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),

		mangeParameterDropDown: (data) => ({
			type: FollowUps.MANAGE_PARAMATER_DROP_DOWN,
			payload: {
				data,
				isLoading: true,
			},
		}),
		updateDefaultData: (data) => ({
			type: FollowUps.UPDATE_ACTIVITY_PARAMETERS_DEFAULT_DATA,
			payload: {
				data,
				isLoading: true,
			},
		}),
		updateInitialValues: (data) => ({
			type: FollowUps.UPDATE_ACTIVITY_FORM_DATA,
			payload: {
				data,
				isLoading: true,
			},
		}),

		/**
		 * Execute single followUp activity for action start action
		 */
		executeSingle: (data, other) => ({
			type: FollowUps.EXECUTE_SINGLE_FOLLOW_UP_ACTIVITY,
			payload: {
				data,
				other,
				isLoading: true,
			},
		}),
		executeSingleSuccess: (data) => ({
			type: FollowUps.EXECUTE_SINGLE_FOLLOW_UP_ACTIVITY_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		executeSingleFail: (error) => ({
			type: FollowUps.EXECUTE_SINGLE_FOLLOW_UP_ACTIVITY_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		reset: (data: any) => ({
			type: FollowUps.RESET_ACTIVITY_FROM,
			payload: {
				data,
				isLoading: true,
			},
		}),
		set: (data: any) => ({
			type: FollowUps.SET_ACTIVITY_EXECUTION_ACTION,
			payload: {
				data,
				isLoading: true,
			},
		}),
	},
	/**
	 * A namespace
	 * @namespace parameters
	 */
	parameters: {
		/**
		 * Get followUp activity parameters for action start action
		 */
		get: (data) => ({
			type: FollowUps.GET_FOLLOW_UP_ACTIVITY_PARAMETERS_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Get followUp activity parameters for action success action
		 */
		success: (data) => ({
			type: FollowUps.GET_FOLLOW_UP_ACTIVITY_PARAMETERS_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Get followUp activity parameters for action fail action
		 */
		fail: (error) => ({
			type: FollowUps.GET_FOLLOW_UP_ACTIVITY_PARAMETERS_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	/**
	 * A namespace
	 * @namespace BMD
	 */
	bmd: {
		/**
		 * Get followUp activity BMD for action start action
		 */
		get: (data) => ({
			type: FollowUps.GET_ACTIVITY_BMD_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Get followUp activity BMD for action success action
		 */
		success: (data) => ({
			type: FollowUps.GET_ACTIVITY_BMD_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Get followUp activity BMD for action fail action
		 */
		fail: (error) => ({
			type: FollowUps.GET_ACTIVITY_BMD_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	/**
	 * A namespace
	 * @namespace action
	 */
	action: {
		/**
		 * Execute followUp action for action start action
		 */
		execute: (data, other) => ({
			type: FollowUps.EXECUTE_FOLLOW_UP_ACTION,
			payload: {
				data,
				other,
				isLoading: true,
			},
		}),
		/**
		 * Execute Followup-action success action
		 */
		success: (data) => ({
			type: FollowUps.EXECUTE_FOLLOW_UP_ACTION_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Execute Followup-action fail action
		 */
		fail: (error) => ({
			type: FollowUps.EXECUTE_FOLLOW_UP_ACTION_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	/**
	 * A namespace
	 * @namespace reImport
	 */
	reImport: {
		/**
		 * Re-import follow-up start action
		 */
		execute: (data) => ({
			type: FollowUps.FOLLOW_UP_RE_IMPORT,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Re-import follow-up success action
		 */
		success: (data) => ({
			type: FollowUps.FOLLOW_UP_RE_IMPORT_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Re-import follow-up fail action
		 */
		fail: (error) => ({
			type: FollowUps.FOLLOW_UP_RE_IMPORT_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		/**
		 * Re-import follow-up reset action
		 */
		reset: (data) => ({
			type: FollowUps.FOLLOW_UP_RE_IMPORT_RESET,
			payload: {
				data,
				isLoading: true,
			},
		}),
	},
	lastActions: {
		/**
		 * Get updated last actions for action start action
		 */
		get: (data) => ({
			type: FollowUps.GET_UPDATED_LAST_ACTION_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Get updated last actions for action success action
		 */
		success: (data) => ({
			type: FollowUps.GET_UPDATED_LAST_ACTION_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Get updated last actions for action fail action
		 */
		fail: (error) => ({
			type: FollowUps.GET_UPDATED_LAST_ACTION_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	/**
	 * A namespace
	 * @namespace category
	 */
	categoryId: {
		/**
		 * Get category start action
		 */
		get: (data) => ({
			type: FollowUps.GET_CATEGORY_ID_FOR_SELECTED_FOLLOW_UP_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Get category success action
		 */
		success: (data) => ({
			type: FollowUps.GET_CATEGORY_ID_FOR_SELECTED_FOLLOW_UP_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Get category fail action
		 */
		fail: (error) => ({
			type: FollowUps.GET_CATEGORY_ID_FOR_SELECTED_FOLLOW_UP_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	followUpNavigation: {
		/**
		 * Update followup navigation action
		 */
		set: (data) => ({
			type: FollowUps.SET_FOLLOW_UP_NAVIGATION,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Get followup navigation success action
		 */
		success: (data) => ({
			type: FollowUps.SET_FOLLOW_UP_NAVIGATION_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Get followup navigation fail action
		 */
		fail: (error) => ({
			type: FollowUps.SET_FOLLOW_UP_NAVIGATION_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	/**
	 * A namespace
	 * @namespace columns
	 */
	columns: {
		/**
		 * Set followUp filters start action
		 */
		set: (data) => ({
			type: FollowUps.SET_FOLLOW_UP_RESULT_COLUMNS,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Set followUp COLUMNS success action
		 */
		success: (data) => ({
			type: FollowUps.SET_FOLLOW_UP_RESULT_COLUMNS_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Set followUp COLUMNS fail action
		 */
		fail: (error) => ({
			type: FollowUps.SET_FOLLOW_UP_RESULT_COLUMNS_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	/**
	 * A namespace
	 * @namespace tableFilters
	 */
	tableFilters: {
		/**
		 * Set followUp filters start action
		 */
		set: (data) => ({
			type: FollowUps.SET_FOLLOW_UP_TABLE_FILTERS,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Set followUp filters success action
		 */
		success: (data) => ({
			type: FollowUps.SET_FOLLOW_UP_TABLE_FILTERS_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Set followUp filters fail action
		 */
		fail: (error) => ({
			type: FollowUps.SET_FOLLOW_UP_TABLE_FILTERS_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	/**
	 * A namespace
	 * @namespace sorters
	 */
	tableSorters: {
		/**
		 * Set followUp filters start action
		 */
		set: (data) => ({
			type: FollowUps.SET_FOLLOW_UP_SORTERS,
			payload: {
				data,
				isLoading: true,
			},
		}),
		/**
		 * Set followUp filters success action
		 */
		success: (data) => ({
			type: FollowUps.SET_FOLLOW_UP_SORTERS_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		/**
		 * Set followUp filters fail action
		 */
		fail: (error) => ({
			type: FollowUps.SET_FOLLOW_UP_SORTERS_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
};
