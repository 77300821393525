import { IDebtorObjection } from 'us.collection.case/reducers/Dashboard/Interfaces'

export const DebtorObjection: IDebtorObjection = {
    caseId: 0,
    debtorName:"",
    communicationMethod: "",
    smsNumber: "",
    caseNote: "",
    emailAddress: "",
    smsInitial: "",
    emailInitial: "",
    invoicetable: [],
    reasonObjectionId: "",
    isSelected: false,
    invoiceNumber: "",
    objectionReasonList: []
}