import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';

import { PaymentAction } from 'us.collection.transactions/actions';
import { INavigationData } from 'us.collection/interfaces';
import { $Skeleton, $Divider, ActivityExecution } from 'us.common/components';
import {
	getEntityDetail,
	getEntityId,
	IntlCurrency,
	IntlDate,
} from 'us.common/functions';
import { getPaymentId } from 'us.collection.transactions/components/PaymentDistribution/Function';
import {
	PendingActivityExecution,
	AddToFollowUpButton,
} from 'us.collection/components';
import { EntityType, EntityTypeShortForm, Mode } from 'us.common/constants';
import { RootState } from 'us.helper/types';

export const PaymentHeader: React.FC<RouteComponentProps & PropsFromRedux> = (
	props
) => {
	const {
		payment,
		currentDateFormat,
		currentLanguage,
		currentCurrency,
		locationState,
		location,
		metaData,
		getPayments,
	} = props;
	const { t } = useTranslation();
	const { push } = useHistory();

	const state = location.state as INavigationData;
	const { refreshCount = 0, currentTab } = state ?? {};

	const entityDetail = useMemo(() => {
		return getEntityDetail(location.pathname, metaData.data);
	}, [location.pathname, metaData.data]);

	const { entityId } = entityDetail ?? {};

	useEffect(() => {
		if (getPayments && state && currentTab === 'payment') {
			const paymentId = getPaymentId(locationState);
			paymentId && getPayments({ paymentId });
		}
	}, [refreshCount]);

	const GetContent = (params: any) => {
		if (!params.state.error) {
			return params.children;
		} else {
			return (
				<div className='space-content'>
					<h3>
						{t(
							'US.COMMON:COMMON.OOOPS_SOMETHING_IS_NOT_RIGHT'
						)}
					</h3>
				</div>
			);
		}
	};

	/**
	 * @function
	 * @description handle navigation after execute activity
	 */
	const navigateAfterExecution = () => {
		push(`/payment/${entityId}/history`, state);
	};

	return (
		<GetContent state={payment}>
			<div className='dom-payment-header'>
				<$Skeleton
					loading={!payment.data && true}
					active
					paragraph={{ rows: 0, width: 100 }}>
					<div className='d-flex flex-wrap align-items-center'>
						<div className='my-1'>
							<span className='bui-label mr-2'>
								{t(
									'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.PAYMENTID'
								)}
							</span>
							<strong>
								{
									payment
										.data
										.paymentId
								}
							</strong>
							<$Divider
								type='vertical'
								className='bui-devider'
							/>
						</div>
						<div className='my-1'>
							<span className='bui-label mr-2'>
								{t(
									'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.PAYMENTTYPE'
								)}
							</span>
							<strong>
								{
									payment
										.data
										.transType
								}
							</strong>
							<$Divider
								type='vertical'
								className='bui-devider'
							/>
						</div>

						<div className='my-1'>
							<span className='bui-label mr-2'>
								{t(
									'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.PAYMENTDATE'
								)}
							</span>
							<strong>
								{payment.data
									.paymentId &&
								payment.data
									.paymentId >
									0
									? IntlDate(
											payment
												.data
												.paymentDate,
											currentLanguage,
											currentDateFormat
									  )
									: ''}
							</strong>
							<$Divider
								type='vertical'
								className='bui-devider'
							/>
						</div>

						<div className='my-1'>
							<span className='bui-label mr-2'>
								{t(
									'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.INVOICENO'
								)}
							</span>
							<strong>
								{payment.data
									.invoiceNo
									? payment
											.data
											.invoiceNo
									: '--'}
							</strong>
							<$Divider
								type='vertical'
								className='bui-devider'
							/>
						</div>

						<div className='my-1'>
							<span className='bui-label mr-2'>
								{t(
									'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.KID'
								)}
							</span>
							<strong>
								{payment.data
									.kid
									? payment
											.data
											.kid
									: '--'}
							</strong>
							<$Divider
								type='vertical'
								className='bui-devider'
							/>
						</div>

						<div className='my-1'>
							<span className='bui-label mr-2'>
								{t(
									'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.FILENAME'
								)}
							</span>
							<strong>
								{payment.data
									.filename
									? payment
											.data
											.filename
									: '--'}
							</strong>
							<$Divider
								type='vertical'
								className='bui-devider'
							/>
						</div>

						<div className='my-1'>
							<span className='bui-label mr-2'>
								{t(
									'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.BANKACCOUNTNO'
								)}
							</span>
							<strong>
								{payment.data
									.sourceAccountNo
									? payment
											.data
											.sourceAccountNo
									: '--'}
							</strong>
							<$Divider
								type='vertical'
								className='bui-devider'
							/>
						</div>

						<div className='my-1'>
							<span className='bui-label mr-2'>
								{t(
									'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.CREDITORACCOUNTNO'
								)}
							</span>
							<strong>
								{payment.data
									.bankAccountNo
									? payment
											.data
											.bankAccountNo
									: '--'}
							</strong>
							<$Divider
								type='vertical'
								className='bui-devider'
							/>
						</div>

						<div className='my-1'>
							<span className='bui-label mr-2'>
								{t(
									'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.AMOUNT'
								)}
							</span>
							<strong>
								{payment.data
									.amount
									? IntlCurrency(
											payment
												.data
												.amount,
											currentLanguage,
											currentCurrency
									  )
									: '--'}
							</strong>
							<$Divider
								type='vertical'
								className='bui-devider'
							/>
						</div>
					</div>
					<div className='d-flex align-items-center mt-2'>
						<ActivityExecution
							mode={Mode.Initial}
							isHideTable={false}
							isDisabled={false}
							isBulkExecutionEnabled={
								false
							}
							isGroupActivityOptions={
								false
							}
							isActivityOptionWithCode={
								true
							}
							isHideDatePicker={false}
							activityAPIRequest={{
								endPoint: `activities`,
								parameters: {
									entitytype: EntityTypeShortForm.PAYMENT,
									entitystate:
										'',
									canExecute: true,
								},
							}}
							executeActivityEndPoint='activities'
							groupingProperty={{
								groupingLabel:
									'activityGroup',
								groupingValue:
									'activityGroupId',
								tableGrouping:
									'type',
							}}
							entityId={getEntityId(
								entityDetail
							)}
							entityType={
								EntityType.PAYMENT
							}
							isPopConfirmEnabled={
								true
							}
							propConfirmDetail={{
								title: 'ARE_YOU_SURE_YOU_WANT_TO_EXECUTE_THIS_ACTIVITY',
							}}
							navigateAfterExecution={
								navigateAfterExecution
							}
							isPopConfirmEnabledDropDown={
								false
							}
						/>

						<div className='ml-auto d-flex'>
							<AddToFollowUpButton />
							<PendingActivityExecution
								{...props}
							/>
						</div>
					</div>
				</$Skeleton>
			</div>
		</GetContent>
	);
};

const mapStateToProps = (state: RootState) => {
	const { router, common, paymentDistrubution, domainView } = state;
	const { location } = router;
	const { pathname } = location;
	const { currentCurrency, currentLanguage, currentDateFormat } = common;
	const { payment } = paymentDistrubution;
	const { metaData } = domainView;
	return {
		payment,
		locationState: pathname,
		currentCurrency,
		currentLanguage,
		currentDateFormat,
		metaData,
	};
};

const mapDispatchToProps = {
	getPayments: PaymentAction.Payments.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(PaymentHeader));
