import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Common from 'us.common';
import { IHome, IFilter, IEmailFormData } from './Interface';
import {
	messageTemplateAction,
	MessageAndNoteAction,
} from 'us.collection.case/actions';
import { LevelType, EntityType } from 'us.collection/constants';
import { IMessageTemplateItem } from 'us.collection.case/interfaces';
import {
	MessageTemplate,
	Editor,
	Email,
	MessageTemplateDetail,
} from './Components';
import { filterMessageTemplates, getEntityTypeForPayload } from './Function';
import { getCaseNumberAndType } from "us.collection/functions";
import {
	DocumentType,
	DrawerType,
	ReceiverType,
	FilterptionsForTemplate,
} from 'us.collection.case/constants';
import { messageTemplateInitial } from 'us.collection.case/reducers/MassageTemplates/State';
import './Home.scss';
import { ReloadOutlined } from 'us.icons';
import { appInsights } from 'us.helper';
import { RootState } from 'us.helper/types';

const {
	messageTemplates,
	deleteMessageTempale,
	messageTemplate,
	messageTemplateActivity,
	email,
	pinUnpinTemplate,
} = messageTemplateAction;

const { basicDetail } = MessageAndNoteAction;
const {
	$Input,
	$Radio,
	$PageHeader,
	$Affix,
	$Skeleton,
	$Empty,
	$Drawer,
	$Button,
	$Tooltip,
} = Common.Components;

const MessageTemplates: React.FC<IHome> = (props) => {
	const { t } = useTranslation();
	const {
		messageTemplates,
		getMessageTemplates,
		metaData,
		history,
		location,
		deleteMessageTemplate,
		previewEditDrawerData,
		previewEditDrawer,
		getMessageTemplateById,
		template,
		ExecuteActivity,
		sendEmail,
		emailDrawerData,
		emailDrawer,
		email,
		getBasicData,
		domainMessageBasicData,
		generateMessageData,
		updateTemplateContent,
		tempalateUpdatedContent,
		pinUnpinTemplate,
	} = props;

	const { caseNo, caseId, arId } = metaData.data ?? {};
	const { type: entityType, url } = getCaseNumberAndType(location, metaData.data) ?? {};

	const [filter, setFilter] = useState<IFilter>({
		type: DocumentType.ALL,
		name: '',
	});

	const [content, setContent] = useState<Array<string>>([]);

	useEffect(() => {
		if (
			getMessageTemplates &&
			(entityType === EntityType.SUBCASE ||
				entityType === EntityType.CASE || 
				entityType === EntityType.AR)
		) {
			getMessageTemplates({
				entityType: getEntityTypeForPayload(entityType)
			});
		}
	}, [entityType]);

	/**
	 * Back navigation
	 */
	const minimize = () => {
		(caseNo || arId) &&
			history.push({
				...location,
				pathname: `/${url}/${entityType === EntityType.AR ? arId : caseNo}`,
			});	
	};

	/**
	 * Delete message template
	 * @param template
	 */
	const onDeleteTemplate = (template: IMessageTemplateItem) => {
		try {
			const { id } = template;
			deleteMessageTemplate &&
				deleteMessageTemplate({ templateId: id });
		} catch (error) {
			appInsights.trackException(
				`MessageTemplate Delete Exeception - ${error}`
			);
		}
	};
	/**
	 * Pin or Unpin message template
	 * @param template
	 */
	const onPinUnpinTemplate = (template: IMessageTemplateItem) => {
		const { id, isPin } = template;

		pinUnpinTemplate &&
			pinUnpinTemplate({
				templateId: id,
				isPin: isPin,
				entityType: getEntityTypeForPayload(entityType),
			});
	};

	/**
	 * Open drawer and get message template content
	 * @param isPreview
	 * @param messageTemplate
	 */
	const onPreviewEditDrawer = (
		isPreview: boolean,
		messageTemplate: IMessageTemplateItem
	) => {
		try {
			const { templateCategory, templateName, plugin } =
				messageTemplate;
			previewEditDrawer &&
				previewEditDrawer({
					title: isPreview
						? templateName
						: t(
								'US.COLLECTION.CASE:MASSAGETEMPLATES.TEMPLATE_EDITOR'
						  ),
					visible: true,
					isPreview,
					messageTemplate,
				});

			getMessageTemplateById &&
				getMessageTemplateById({
					entityID: entityType ==
					EntityType.AR ? arId : caseId,
					entityType: getEntityTypeForPayload(entityType),
					templateName,
					templateCategory,
					pluginName:
						plugin.toLowerCase() ===
						DocumentType.EMAIL
							? DocumentType.EMAIL
							: plugin.toLowerCase(),
					action: isPreview
						? DrawerType.PREVIEW
						: DrawerType.PREVIEW,
				});

			!isPreview &&
				getBasicData &&
				getBasicData({
					entityId: entityType ==
					EntityType.AR ? arId : caseId,
					entityType: getEntityTypeForPayload(entityType),
				});
		} catch (error) {
			appInsights.trackException(
				`MessageTemplate onPreviewEditDrawer Exeception - ${error}`
			);
		}
	};

	/**
	 * Drawer closer
	 */
	const onPreviewEditDrawerClose = () => {
		try {
			previewEditDrawer &&
				previewEditDrawer({
					title: '',
					visible: false,
					isPreview: false,
					messageTemplate: messageTemplateInitial,
				});
			onEmaiDrawerClose();
		} catch (error) {
			appInsights.trackException(
				`MessageTemplate onPreviewEditDrawerClose Exeception - ${error}`
			);
		}
	};

	/**
	 * Encode unicode text
	 * @param {string} str
	 * @returns {string} encoded string
	 */
	function toBinary(str: string) {
		let result = '';
		try {
			const codeUnits = new Uint16Array(str.length);
			for (let i = 0; i < codeUnits.length; i++) {
				codeUnits[i] = str.charCodeAt(i);
			}
			const charCodes = new Uint8Array(codeUnits.buffer);
			for (let i = 0; i < charCodes.byteLength; i++) {
				result += String.fromCharCode(charCodes[i]);
			}
			return result;
		} catch (error) {
			appInsights.trackException(
				`MessageTemplate toBinary Exeception - ${error}`
			);
			return result;
		}
	}

	/**
	 * Encode htmlContent with binary converion
	 * @returns {string} encoded string
	 */
	const contentEncodeWithBinary = (): Array<string> => {
		if (content.length > 0) {
			return content.map((item: any) => btoa(toBinary(item)));
		} else {
			return template.content.map((item: any) =>
				btoa(toBinary(item))
			);
		}
	};

	/**
	 * Encode htmlContent without  binary converion
	 * @returns {string} encoded string
	 */
	const contentEncodeWithoutBinary = (): Array<string> => {
		if (content.length > 0) {
			return content.map((item: any) => btoa(item));
		} else {
			return template.content.map((item: any) => btoa(item));
		}
	};

	/**
	 * Set HTMLContent to Request
	 * @param {string} pluginName -Template plugin type
	 * @returns {Array<string>} - Encoded data array
	 */
	const setHTMLContent = (pluginName: string): Array<string> => {
		try {
			switch (pluginName.toLowerCase()) {
				case DocumentType.PDF:
					return contentEncodeWithBinary();
				default:
					return contentEncodeWithoutBinary();
			}
		} catch (error) {
			appInsights.trackException(
				`MessageTemplate setHTMLContent Exeception - ${error}`
			);
			return contentEncodeWithoutBinary();
		}
	};

	/**
	 * Execute activity
	 */
	const onExecute = () => {
		try {
			const { templateName, templateCategory, plugin } =
				previewEditDrawerData.messageTemplate;
			const { communicationJobId } = template;
			ExecuteActivity &&
				ExecuteActivity({
					communicationJobId,
					entityId: entityType ==
					EntityType.AR ? arId : caseId,
					entityType: getEntityTypeForPayload(entityType),
					templateName,
					templateCategory,
					PluginName: plugin,
					HtmlContent: setHTMLContent(plugin),
				});
		} catch (error) {
			appInsights.trackException(
				`MessageTemplate onExecute Exeception - ${error}`
			);
		}
	};

	/**
	 *Send email
	 * @param content
	 */
	const onSendEmail = (data: IEmailFormData) => {
		try {
			const { to, subject, body } = data;
			const { templateName, templateCategory, plugin } =
				previewEditDrawerData.messageTemplate;
			const { communicationJobId } = template;

			sendEmail &&
				sendEmail({
					communicationJobId,
					entityId: entityType ==
					EntityType.AR ? arId : caseId,
					entityType: getEntityTypeForPayload(entityType),
					mesageContent: body,
					emailOrTelNo: to,
					isPaymentInfoSMS: false,
					isGiroDocument: false,
					subject,
					dueDate: '',
					senderType: '',
					messgeType: 'Email',
					attachmentPaths: [],
					templateName,
					templateCategory,
					PluginName: plugin,
					HtmlContent: setHTMLContent(plugin),
					documentType: 'Debtor',
				});
		} catch (error) {
			appInsights.trackException(
				`MessageTemplate onSendEmail Exeception - ${error}`
			);
		}
	};

	/**
	 * Email Drawer open
	 */
	const onEmailDrawer = () => {
		emailDrawer &&
			emailDrawer({
				title: '',
				visible: true,
			});
	};

	/**
	 * Email drawer closer
	 */
	const onEmaiDrawerClose = () => {
		emailDrawer &&
			emailDrawer({
				title: '',
				visible: false,
			});
	};

	/**
	 * Refresh message templates
	 */
	const onRefresh = () => {
		try {
			getMessageTemplates &&
				getMessageTemplates({
					entityType: getEntityTypeForPayload(entityType)
				});
		} catch (error) {
			appInsights.trackException(
				`MessageTemplate onRefresh Exeception - ${error}`
			);
		}
	};

	const getContent = (content: Array<string>): void => {
		try {
			setContent(content);
		} catch (error) {
			setContent(template.content);
			appInsights.trackException(
				`MessageTemplate getContent Exeception - ${error}`
			);
		}
	};

	return (
		<Formik initialValues={{}} onSubmit={() => {}}>
			{() => (
				<>
					<div className='msg-template'>
						<$Affix offsetTop={80}>
							<div className='page-header header-border'>
								<div className='d-flex flex-row align-items-center justify-content-between'>
									<div className='d-flex align-items-center'>
										<$PageHeader
											className='px-0'
											title={t(
												'US.COLLECTION.CASE:MASSAGETEMPLATES.TITLE'
											)}
											onBack={
												minimize
											}
										/>
									</div>

									<div className='ml-5 d-flex align-items-center'>
										<$Tooltip
											placement='top'
											title={t(
												'US.COLLECTION.CASE:MASSAGETEMPLATES.REFRESH'
											)}>
											<$Button
												className='mr-3'
												icon={
													<ReloadOutlined />
												}
												type='dashed'
												size='small'
												onClick={
													onRefresh
												}
											/>
										</$Tooltip>
										<$Radio
											size='small'
											name='documentType'
											radioButton='true'
											optionText='label'
											optionValue='value'
											defaultValue={
												'all'
											}
											style={{
												marginTop: 5,
											}}
											className='d-flex'
											optionStyle='flex-fill text-center'
											data-testid='messageTemplate-documentType'
											options={FilterptionsForTemplate.map(
												(option: {
													key: string;
													value: string;
												}) => {
													return {
														label: t(
															`US.COLLECTION.CASE:MASSAGETEMPLATES.${option.key}`
														),
														value: option.value,
													};
												}
											)}
											onChange={(
												e: any
											) => {
												setFilter(
													{
														...filter,
														type: e
															.target
															.value,
													}
												);
											}}
										/>
										<$Input
											name='filter'
											size='small'
											className='ml-2'
											allowClear
											style={{
												width: 200,
												marginTop: 5,
											}}
											placeholder={t(
												'US.COLLECTION.COMMON:COMMON.NAME'
											)}
											onChange={(
												e: any
											) => {
												setFilter(
													{
														...filter,
														name: e
															.target
															.value,
													}
												);
											}}
										/>
									</div>
								</div>
							</div>
						</$Affix>
						<div className='msg-template-view'>
							<$Skeleton
								loading={
									messageTemplates.isLoading
								}
								active
								paragraph={{
									rows: 2,
								}}>
								<div>
									{filterMessageTemplates(
										messageTemplates.data,
										filter
									)
										.length >
										0 && (
										<>
											<div className='msg-template-wrap'>
												<div className='msg-template-inner'>
													{filterMessageTemplates(
														messageTemplates.data,
														filter
													).map(
														(
															messageTemplate: IMessageTemplateItem,
															index
														) => {
															const {
																isPin,
															} =
																messageTemplate;
															if (
																isPin
															) {
																return (
																	<>
																		<MessageTemplate
																			key={
																				index
																			}
																			messageTemplate={
																				messageTemplate
																			}
																			onPreviewEditDrawer={
																				onPreviewEditDrawer
																			}
																			onDelete={() =>
																				onDeleteTemplate(
																					messageTemplate
																				)
																			}
																			onPinUnpinTemplate={() =>
																				onPinUnpinTemplate(
																					messageTemplate
																				)
																			}
																			metaData={
																				metaData.data
																			}
																		/>
																	</>
																);
															}
														}
													)}
												</div>
											</div>

											<div>
												<label className='d-flex align-items-center mt-3 bold'>
													<span data-testid='UnPinTemplate'>
														{t(
															'US.COLLECTION.CASE:MASSAGETEMPLATES.UNPINNED'
														)}
													</span>
												</label>
											</div>

											<div className='msg-template-wrap'>
												<div className='msg-template-inner'>
													{filterMessageTemplates(
														messageTemplates.data,
														filter
													).map(
														(
															messageTemplate: IMessageTemplateItem,
															index
														) => {
															const {
																isPin,
															} =
																messageTemplate;
															if (
																!isPin
															) {
																return (
																	<>
																		<MessageTemplate
																			key={
																				index
																			}
																			messageTemplate={
																				messageTemplate
																			}
																			onPreviewEditDrawer={
																				onPreviewEditDrawer
																			}
																			onDelete={() =>
																				onDeleteTemplate(
																					messageTemplate
																				)
																			}
																			onPinUnpinTemplate={() =>
																				onPinUnpinTemplate(
																					messageTemplate
																				)
																			}
																			metaData={
																				metaData.data
																			}
																		/>
																	</>
																);
															}
														}
													)}
												</div>
											</div>
										</>
									)}

									{filterMessageTemplates(
										messageTemplates.data,
										filter
									)
										.length ===
										0 && (
										<div className='d-flex justify-content-center'>
											<div className='d-flex mt-4'>
												<$Empty
													image={
														$Empty.PRESENTED_IMAGE_SIMPLE
													}
													description={
														<span>
															{t(
																'US.COLLECTION.CASE:MASSAGETEMPLATES.NO_DATA'
															)}
														</span>
													}
												/>
											</div>
										</div>
									)}
								</div>
							</$Skeleton>
						</div>
					</div>

					<$Drawer
						title={
							previewEditDrawerData.title
						}
						width={'900px'}
						visible={
							previewEditDrawerData.visible
						}
						onClose={
							onPreviewEditDrawerClose
						}
						destroyOnClose
						className={
							previewEditDrawerData.isPreview &&
							!emailDrawerData.visible
								? 'msg-template-html-parser'
								: ''
						}>
						{!previewEditDrawerData.isPreview &&
							previewEditDrawerData.visible &&
							!emailDrawerData.visible && (
								<Editor
									item={
										previewEditDrawerData.messageTemplate
									}
									template={
										template
									}
									onClose={
										onPreviewEditDrawerClose
									}
									onExecute={
										onExecute
									}
									onOpenEmail={
										onEmailDrawer
									}
									isExecute={
										generateMessageData.isLoading
									}
									getContent={
										getContent
									}
									updateTemplateContent={
										updateTemplateContent
									}
									tempalateUpdatedContent={
										tempalateUpdatedContent
									}
								/>
							)}
						{previewEditDrawerData.isPreview &&
							previewEditDrawerData.visible &&
							!emailDrawerData.visible && (
								<MessageTemplateDetail
									template={
										template
									}
									onClose={
										onPreviewEditDrawerClose
									}
								/>
							)}
						{emailDrawerData.visible && (
							<Email
								to={
									domainMessageBasicData.entityDetails.find(
										(
											entity: any
										) =>
											entity.receiver ===
											ReceiverType.DEBTOR
									)
										?.emailAddress ??
									''
								}
								item={
									previewEditDrawerData.messageTemplate
								}
								onSendEmail={
									onSendEmail
								}
								onClose={
									onEmaiDrawerClose
								}
								isSend={
									email.isLoading
								}
							/>
						)}
					</$Drawer>
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { messageTemplate, domainView, messageAndNotes } = state;
	const {
		messageTemplates,
		previewEditDrawerData,
		template,
		emailDrawerData,
		generateMessageData,
		email,
		tempalateUpdatedContent,
	} = messageTemplate;
	const { metaData } = domainView;
	const { domainMessageBasicdetail } = messageAndNotes;
	return {
		messageTemplates,
		metaData,
		previewEditDrawerData,
		template,
		emailDrawerData,
		generateMessageData,
		email,
		domainMessageBasicData: domainMessageBasicdetail,
		tempalateUpdatedContent,
	};
};

const mapDispatchToProps = {
	getMessageTemplates: messageTemplates.get,
	deleteMessageTemplate: deleteMessageTempale.delete,
	previewEditDrawer: messageTemplate.previewEditDrawer,
	getMessageTemplateById: messageTemplate.get,
	ExecuteActivity: messageTemplateActivity.execute,
	sendEmail: email.send,
	emailDrawer: email.openDrawer,
	getBasicData: basicDetail.get,
	updateTemplateContent: messageTemplate.updateContent,
	pinUnpinTemplate: pinUnpinTemplate.send,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageTemplates);
