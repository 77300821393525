import { IColumn } from "../../../Interfaces";

/**
 * @constant
 * @description - The list of columns in manage case list view
 * @type {IColumn[]} - basic colum type
 */
export const MERGE_CASE_COLUMNS: IColumn[] = [
  {
    title: "CASE_NO",
    dataIndex: "caseNumber",
    width: 120,
    className: "text-nowrap",
    customFilter: true,
  },
  {
    title: "STATE",
    dataIndex: "workflowState",
    width: 150,
    className: "text-nowrap",
    customFilter: true,
  },
  {
    title: "MAIN_AMOUNT",
    dataIndex: "mainAmount",
    width: 150,
    className: "right-has-sort right-has-filter text-nowrap",
    customFilter: true,
    align: "right",
  },
  {
    title: "OTHER_AMOUNTS",
    dataIndex: "otherCost",
    width: 170,
    className: "right-has-sort right-has-filter text-nowrap",
    customFilter: true,
    align: "right",
  },
  {
    title: "PAID",
    dataIndex: "paidAmount",
    width: 120,
    className: "right-has-sort right-has-filter text-nowrap",
    customFilter: true,
    align: "right",
  },
  {
    title: "BALANCE",
    dataIndex: "balance",
    width: 120,
    className: "right-has-sort right-has-filter text-nowrap",
    customFilter: true,
    align: "right",
  },
];
