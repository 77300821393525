import React, { useEffect, useMemo, useState } from "react";
import { $TableTree } from "us.common/components";
import { VList } from "virtuallist-antd";
import {
  getTreeData,
  getAllTableParentKeys,
  handleTableSort,
  handleTableFilter,
} from "us.collection/components/BMD/Functions";
import { IBmdTable } from "us.collection/components/BMD/Interfaces";
import { useField } from "formik";

/**
 * @description - Virtualized table component for bmd list
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2855272453/UI+BMD+-+List+Summery+View
 * @since 04/07/2023
 */
const BmdTable: React.FC<IBmdTable> = (props) => {
  const { filteredCategories, columns, onDoubleClick } = props;
  const [expandKeys, setExpandKeys] = useState<Array<string>>([]);
  const [isScrollbarVisible, setScrollbarVisible] = useState<boolean>(true);

  const selectedCategory = useField("selectedCategory")[0].value;
  const selectedBmdGroup = useField("bmdGroup")[0].value;

  useEffect(() => {
    if (filteredCategories && filteredCategories.length > 0) {
      setExpandKeys(getAllTableParentKeys(filteredCategories));
    }
  }, [filteredCategories]);

  const treeData = useMemo(() => {
    return getTreeData(
      filteredCategories,
      selectedCategory?.id,
      selectedBmdGroup
    );
  }, [filteredCategories, selectedBmdGroup, selectedCategory]);

  const vComponents = useMemo(() => {
    return VList({
      resetTopWhenDataChange: false,
      height: "calc(100vh - 320px)", // same value for scroll-y
    });
  }, [treeData, isScrollbarVisible]);

  /**
   * Table row expand
   * @param isExpand Row expand or not
   * @param record Row data
   */
  const onTableRowExpand = (isExpand: any, record: any) => {
    setScrollBarStatus();
    if (isExpand) {
      setExpandKeys([...expandKeys, record.key]);
    } else {
      setExpandKeys(expandKeys.filter((item: any) => item !== record.key));
    }
  };

  // Refresh the table when scroll bar is not visible. Workaround for not collapsing issue.
  const setScrollBarStatus = () => {
    try {
      setTimeout(() => {
        const tableElement: any = document?.getElementById("bmd-table-tree");
        const scrollElement: any = tableElement?.querySelector(
          "[class=ant-table-body]"
        );
        setScrollbarVisible(
          scrollElement?.scrollHeight > scrollElement?.clientHeight
        );
      }, 1);
    } catch (e) {
      setScrollbarVisible(false);
    }
  };

  return (
    <div className="v-table-tree p-1">
      <$TableTree
        id="bmd-table-tree"
        data={treeData}
        size="small"
        className="mb-3"
        rowKey={(record: any) => {
          return record.key;
        }}
        defaultExpandAllRows={true}
        resetOnSourceChange={true}
        onSort={handleTableSort}
        onFilter={handleTableFilter}
        filterOnType={true}
        scroll={{ y: "calc(100vh - 320px)", x: "1200px" }}
        bordered
        pagination={false}
        columns={columns}
        expandable={{
          defaultExpandAllRows: true,
          onExpand: (isExpand, record) => onTableRowExpand(isExpand, record),
          childrenColumnName: "children",
          expandedRowKeys: expandKeys,
        }}
        //data-testid={`bmd-search-filtered-table-${groupIndex}`}
        onRow={(record) => {
          return {
            onDoubleClick: () => {
              !record?.hasOwnProperty("children") &&
              onDoubleClick(record);
            },
          };
        }}
        renderCellParent={(data: any) => {
          return <div className="bmd-parent-row mb-2">{data?.row?.name}</div>;
        }}
        components={vComponents}
      />
    </div>
  );
};

export default BmdTable;
