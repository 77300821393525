import { Formik } from 'formik';
import React, { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';
import { Link, matchPath, useLocation } from 'react-router-dom';

import Common from 'us.common';
import { IMapping } from './Interfaces/interfaces';
import * as Actions from 'us.collection.transactions/actions';
import { INavigationData, IRootState } from 'us.collection/interfaces';
import { ITableTreeColumns } from 'us.common/components';
import { invalidDate } from 'us.collection.transactions/components/Transactions/Constants';
import './TransactionMapping.scss';
import {
	MappingTableColumn,
	MAPPING_TABLE_COLUMNS,
	RE_MAPPING_TABLE_COLUMNS,
} from './Constants';
import {
	GetMappingTransactions,
	UpdateMappingTransactions,
} from '../../Repository';
import { getRouteUrl } from 'us.helper';
import { MappingTransaction } from '../../Interfaces';
import { FilterTypes } from '../../Constants';
import { URLType } from 'us.collection/constants';
import { TransactionSummaryHeader } from '../TransactionSummaryHeader';
import { isApportionmentMapping, updateSelectedRowKeys } from '../../Functions';
import {
	ableToReMap,
	getItemsToMap,
	getTotalMappedAmount,
	groupByCaseNo,
	mappingTransactionsWithNewBalance,
	canReMap,
	isFullyMapped,
} from './Functions';
const {
	$Button,
	$Divider,
	$TableTree,
	$Skeleton,
	$Tooltip,
	$Popconfirm,
	$AmountLabel,
	$Checkbox,
	$DateLabel,
} = Common.Components;

/**
 * @description -  AR Transaction mapping drawer
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3029336065/Item+by+Item+Mapping+in+AR+UI+Implementation
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 13/05/2022
 */
const TransactionMapping: React.FC<IMapping & PropsFromRedux> = memo(
	(props) => {
		const { t } = useTranslation(['US.COLLECTION.TRANSACTIONS']);
		const {
			transactionDetails,
			caseType,
			transactions,
			updatedTransactions,
			defaultExpandedRowKeys,
			getMappingTransactions,
			updateMappingTransactions,
			onClose,
		} = props;
		const { state, pathname } = useLocation();
		const [enabledReMap, setEnabledReMap] =
			useState<boolean>(false);

		const routeDetails: any = matchPath(pathname, {
			path: '/ar/:arNo/transactions',
			exact: false,
			strict: false,
		});

		// define the available balance for mapping
		const availableBalanceToMap =
			(transactionDetails?.isPayment
				? transactionDetails?.paid
				: transactionDetails?.amount) ?? 0;

		// get initial mapping transactions
		useEffect(() => {
			const params = GetMappingTransactions.call({
				...transactionDetails,
			});
			getMappingTransactions(params);
		}, []);

		/**
		 * @description - Get row style class name to highlight the row
		 * @param {RemitRecord} record - Table row record
		 * @returns {string} class
		 */
		const getRowClass = ({
			isMappedTransaction,
			isMapped,
		}: MappingTransaction): string => {
			try {
				if (isMappedTransaction && !isMapped) {
					return 'success-table-row-bg';
				} else if (!isMappedTransaction && isMapped) {
					return 'success-table-row-bg';
				} else {
					return '';
				}
			} catch (error) {
				return '';
			}
		};

		/**
		 * If the transaction is an apportionment, navigate to the economy module apportionment view to map the
		 * payment. Otherwise, enable re-mapping in the AR transaction mapping
		 */
		const handleReMapping = () => {
			// decide the re-mapping view for the transaction
			if (isApportionmentMapping(transactionDetails)) {
				// navigate to the economy module apportionment view to map the payment
				getRouteUrl.mapPayment(
					transactionDetails.paymentID,
					'apportionment'
				);
			} else {
				// enable re-mapping in the AR transaction mapping
				setEnabledReMap(true);
			}
		};

		/**
		 * @description handle submit the re-mapping item
		 * @param {any} values - form values
		 */
		const submit = (values: any) => {
			const mappings = getItemsToMap(
				_.cloneDeep(values.transactions),
				values.selectedRowKeys,
				availableBalanceToMap
			);
			const mappingParams = UpdateMappingTransactions.call({
				...transactionDetails,
				mappings,
			});
			updateMappingTransactions({
				mappingParams,
				searchParams: {
					entityType: FilterTypes.AR,
					entityId: routeDetails?.params?.arNo,
				},
			});
		};

		/**
		 * "When a user selects a row, update the selectedRowKeys and transactions form fields."
		 *
		 * @description The function is called from the following component:
		 * @param {boolean} checked - boolean - whether the checkbox is checked or not
		 * @param {number} selectedId - the id of the row that was selected
		 * @param {any} restProps - this is the props that are passed to the component from the formik
		 * form.
		 * @param {any} values - this is the formik values object
		 */
		const onSelectItemToMap = (
			checked: boolean,
			selectedId: number,
			restProps: any,
			values: any
		) => {
			// get updated row key list
			const selectedRowKeys = updateSelectedRowKeys(
				checked,
				selectedId,
				values.selectedRowKeys
			);
			// update the form field
			restProps.setFieldValue(
				'selectedRowKeys',
				selectedRowKeys
			);
			if (selectedRowKeys.length > 0) {
				// get updated transactions list
				const updatedTransactions =
					mappingTransactionsWithNewBalance(
						_.cloneDeep(
							values.transactions
						),
						selectedRowKeys,
						availableBalanceToMap
					);
				// update the form field
				restProps.setFieldValue(
					'transactions',
					updatedTransactions
				);
			} else {
				resetMapping(restProps);
			}
		};

		/**
		 * @description Reset the selected row keys and mapped transactions list .
		 * @param {any} restProps - any - this is the props that are passed to the component from the formik
		 * form.
		 */
		const resetMapping = (restProps: any) => {
			// reset the selected row keys
			restProps.setFieldValue('selectedRowKeys', []);
			// reset the mapped transactions list
			restProps.setFieldValue(
				'transactions',
				transactions.data
			);
		};

		/**
		 * @description If the row is expanded, add the caseNo to the expandedRowKeys array, otherwise remove it
		 * @param {boolean} expanded - boolean - whether the row is expanded or not
		 * @param {any} record - The row that was expanded/collapsed
		 * @param {any} restProps - This is the props that are passed to the component.
		 * @param {string[]} prevExpandedRowKeys - The array of expanded row keys.
		 */
		const handleExpandedRows = (
			expanded: boolean,
			record: any,
			restProps: any,
			prevExpandedRowKeys: string[]
		) => {
			const { caseNo } = record ?? {};
			let updatedRowKeys;
			if (expanded) {
				updatedRowKeys = [
					...prevExpandedRowKeys,
					caseNo,
				];
			} else {
				updatedRowKeys = prevExpandedRowKeys?.filter(
					(expandedRow: string) =>
						expandedRow != caseNo
				);
			}
			restProps.setFieldValue(
				'expandedRowKeys',
				updatedRowKeys
			);
		};

		/**
		 * If the user has enabled the re-map feature, reset the mapping and disable the re-map feature.
		 * Otherwise, close the modal
		 * @param {any} restProps - This is the rest of the props that are passed to the component.
		 */
		const onCancelMapping = (restProps: any) => {
			if (enabledReMap) {
				resetMapping(restProps);
				setEnabledReMap(false);
			} else {
				onClose();
			}
		};

		/**
		 * @description Generate table columns
		 * @returns {ITableTreeColumns}
		 */
		const getTableColumns = (
			restProps: any,
			values: any
		): ITableTreeColumns => {
			const tableColumns: ITableTreeColumns = [];

			// get columns
			// if enable remapping adding new balance
			const columns: Array<any> = enabledReMap
				? RE_MAPPING_TABLE_COLUMNS
				: MAPPING_TABLE_COLUMNS;

			columns.map(
				({
					key,
					title,
					filterType,
					align,
				}: {
					key: MappingTableColumn;
					title: string;
					filterType: boolean | string;
					align: string;
				}) => {
					if (
						key ===
						MappingTableColumn.CASE_NO
					) {
						const column: any = {
							key,
							align,
							dataIndex: key,
							title: title
								? t(title)
								: undefined,
							className: 'text-nowrap',
							customFilter:
								filterType,
							customRenderParent: (
								_text: any,
								{
									caseNo,
									caseId,
								}: MappingTransaction,
								_index: number
							) => {
								return {
									children: (
										<span className='mr-2 font-weight-bold'>
											{`${t(
												'US.COLLECTION.TRANSACTIONS:ARHOME.CASE_NO'
											)} : `}
											<Link
												to={{
													pathname: `/case/${caseNo}`,
													state: {
														...(state as INavigationData),
														currentTab:
															caseId !==
															-1
																? URLType.CASE
																: URLType.SUBCASE,
													},
												}}>
												{
													caseNo
												}
											</Link>
										</span>
									),
									key: caseNo,
									props: {
										colSpan: 11,
									},
								};
							},
							customRenderChild: (
								_text: any,
								record: MappingTransaction
							) => {
								const {
									transactionId,
									isMapped,
								} = record;
								return (
									enabledReMap && (
										<$Checkbox
											defaultChecked={
												false
											}
											disabled={
												!canReMap(
													record,
													availableBalanceToMap
												) ||
												(availableBalanceToMap -
													getTotalMappedAmount(
														values.transactions,
														availableBalanceToMap
													) ===
													0 &&
													!isMapped)
											}
											checked={values.selectedRowKeys.includes(
												transactionId
											)}
											onChange={(
												e
											) => {
												onSelectItemToMap(
													e
														.target
														.checked,
													transactionId,
													restProps,
													values
												);
											}}
										/>
									)
								);
							},
						};
						tableColumns.push(column);
					} else {
						const column: any = {
							key,
							dataIndex: key,
							title: title
								? t(title)
								: undefined,
							className: 'text-nowrap',
							customFilter:
								filterType,
							align,
							customSorter: (
								a: any,
								b: any
							) => {
								if (
									[
										MappingTableColumn.TYPE,
										MappingTableColumn.VOUCHER_DATE,
										MappingTableColumn.DUE_DATE,
										MappingTableColumn.REG_DATE,
										MappingTableColumn.DESCRIPTION,
									].includes(
										key
									)
								) {
									return a.localeCompare(
										b
									);
								} else {
									return (
										a -
										b
									);
								}
							},
							customRenderChild: (
								text: any,
								record: any
							) => (
								<>
									{[
										MappingTableColumn.AMOUNT,
										MappingTableColumn.BALANCE,
										MappingTableColumn.NEW_BALANCE,
									].includes(
										key
									) && (
										<$AmountLabel
											value={
												record[
													key
												]
											}
										/>
									)}
									{[
										MappingTableColumn.DUE_DATE,
										MappingTableColumn.REG_DATE,
										MappingTableColumn.VOUCHER_DATE,
									].includes(
										key
									) &&
										record[
											key
										] !=
											invalidDate && (
											<$DateLabel
												value={
													record[
														key
													]
												}
											/>
										)}
									{key ===
										MappingTableColumn.TRANSACTION_ID && (
										<span>
											{
												record[
													key
												]
											}
										</span>
									)}

									{[
										MappingTableColumn.REF_NO,
									].includes(
										key
									) && (
										<div>
											{record[
												key
											] >
											0
												? record[
														key
												  ]
												: ''}
										</div>
									)}

									{[
										MappingTableColumn.DESCRIPTION,
										MappingTableColumn.TYPE,
									].includes(
										key
									) && (
										<div>
											{record[
												key
											] ??
												''}
										</div>
									)}
								</>
							),
						};
						tableColumns.push(column);
					}
				}
			);
			return tableColumns;
		};

		return (
			<Formik
				enableReinitialize
				initialValues={{
					selectedRowKeys: [],
					transactions: transactions.data ?? [],
					expandedRowKeys: defaultExpandedRowKeys,
				}}
				onSubmit={submit}>
				{({
					values,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					isValidating,
					resetForm,
					...restProps
				}: any) => {
					const totalMappedAmount =
						getTotalMappedAmount(
							values.transactions,
							availableBalanceToMap
						);
					return (
						<div className='transaction-mapping'>
							<TransactionSummaryHeader
								transaction={
									transactionDetails
								}
								caseType={
									caseType
								}
							/>
							<$Divider />

							<div className='mt-4 d-flex align-items-center'>
								<div>
									<h3>
										{t(
											'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.MAPPING_DETAILS'
										)}
									</h3>
								</div>

								{!enabledReMap &&
									Array.isArray(
										values.transactions
									) && (
										<div>
											<$Divider
												className='bui-devider'
												type='vertical'
											/>
											{isApportionmentMapping(
												transactionDetails
											) && (
												<$Tooltip
													placement='top'
													defaultVisible={
														false
													}
													title={t(
														'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.YOU_WILL_NAVIGATE_TO_THE_APPORTIONMENT_VIEW_TO_MAP_THIS_TRANSACTION'
													)}>
													<$Button
														className='ml-3 px-4'
														size='small'
														type='default'
														onClick={
															handleReMapping
														}>
														{t(
															'US.COLLECTION.COMMON:COMMON.RE_MAP'
														)}
													</$Button>
												</$Tooltip>
											)}
											{!isApportionmentMapping(
												transactionDetails
											) &&
												values
													.transactions
													.length >
													0 && (
													<$Button
														className='ml-3 px-4'
														size='small'
														type='default'
														onClick={
															handleReMapping
														}>
														{t(
															'US.COLLECTION.COMMON:COMMON.RE_MAP'
														)}
													</$Button>
												)}
										</div>
									)}
							</div>
							<div>
								<$Skeleton
									loading={
										transactions.isLoading
									}
									active
									paragraph={{
										rows: 2,
									}}>
									<$TableTree
										rowKey={
											'id'
										}
										data={groupByCaseNo(
											values.transactions
										)}
										size='small'
										className='mt-3 header-custom-tag'
										onSort={(
											sortData,
											dataSource
										) => {
											return sortData(
												dataSource
											);
										}}
										rowClassName={
											getRowClass
										}
										onFilter={(
											searchData,
											dataSource
										) => {
											return searchData(
												dataSource
											);
										}}
										filterOnType
										resetOnSourceChange
										bordered
										expandable={{
											expandedRowKeys:
												values.expandedRowKeys,
											onExpand: (
												expanded: boolean,
												record: any
											) =>
												handleExpandedRows(
													expanded,
													record,
													restProps,
													values.expandedRowKeys
												),
										}}
										pagination={{
											defaultPageSize: 15,
										}}
										scroll={{
											x: 1200,
										}}
										columns={getTableColumns(
											restProps,
											values
										)}
										firstColSkipFilterProps={
											0
										}
									/>
								</$Skeleton>
							</div>
							<div className='drawer-footer-fixed align-content-center'>
								{enabledReMap &&
									values
										.selectedRowKeys
										.length >
										0 &&
									availableBalanceToMap !==
										totalMappedAmount && (
										<h3>
											{`${t(
												'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.REMAINING_AMOUNT'
											)}
								: `}
											<$AmountLabel
												value={
													availableBalanceToMap -
													totalMappedAmount
												}
											/>
										</h3>
									)}
								<div className='ml-auto'>
									{enabledReMap && (
										<$Button
											className='mx-2'
											onClick={() => {
												resetMapping(
													restProps
												);
											}}
											disabled={
												totalMappedAmount ===
												0
											}
											loading={
												updatedTransactions.isLoading
											}>
											{t(
												'US.COMMON:COMMON.RESET'
											)}
										</$Button>
									)}
									{enabledReMap && (
										<$Popconfirm
											title={t(
												'US.COLLECTION.TRANSACTIONS:TRANSACTIONS.ARE_YOU_SURE_YOU_WANT_TO_RE_MAP_?'
											)}
											className='mx-2'
											placement='topLeft'
											onConfirm={
												handleSubmit
											}
											okText={t(
												'US.COLLECTION.COMMON:COMMON.YES'
											)}
											cancelText={t(
												'US.COLLECTION.COMMON:COMMON.NO'
											)}>
											<$Button
												disabled={
													updatedTransactions.isLoading ||
													!ableToReMap(
														values,
														availableBalanceToMap
													)
												}
												loading={
													updatedTransactions.isLoading
												}
												type='primary'>
												{t(
													'US.COLLECTION.COMMON:COMMON.RE_MAP'
												)}
											</$Button>
										</$Popconfirm>
									)}
									<$Button
										className='ml-2'
										onClick={() =>
											onCancelMapping(
												restProps
											)
										}>
										{t(
											'US.COLLECTION.COMMON:COMMON.CANCEL'
										)}
									</$Button>
								</div>
							</div>
						</div>
					);
				}}
			</Formik>
		);
	}
);

const mapStateToProps = (state: IRootState) => {
	const { common, transaction } = state;
	const { currentDateFormat, currentCurrency, currentLanguage } = common;
	const {
		transactionDetails,
		isFetching,
		isMappingTransFetching,
		mapping,
	} = transaction;
	const { transactions, updatedTransactions, defaultExpandedRowKeys } =
		mapping;
	return {
		isFetching,
		isMappingTransFetching,
		currentCurrency,
		currentLanguage,
		currentDateFormat,
		transactionDetails,
		transactions,
		updatedTransactions,
		defaultExpandedRowKeys,
	};
};
const { getMappingTransactions, updateMappingTransactions, getMapping } =
	Actions.transactions;

const mapDispatchToProps = {
	getMappingTransactions,
	updateMappingTransactions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TransactionMapping);
