import { Handlers } from 'us.collection/constants';
import { IHandler } from 'us.collection/reducers/Handler/Interfaces';
import Common from 'us.common';
/**
 * Get case handler or court handler id
 * @param handlerDetails case/court handler list
 * @param type handler type
 * @param handlerName selected handler name
 * @returns number
 */
export const getHandlerId = (
	handlerDetails: IHandler,
	type: string,
	handlerName: string
): number => {
	try {
		const selectCaseHandler = handlerDetails?.list.find((Handler) =>
			type === Handlers.CASE
				? Handler.caseHandlerName === handlerName
				: Handler.courtHandlerName === handlerName
		);
		if (selectCaseHandler) {
			switch (type) {
				case Handlers.CASE:
					return selectCaseHandler.caseHandlerId;
				case Handlers.COURT:
					return selectCaseHandler.courtHandlerId;
				default:
					return 0;
			}
		}
		return 0;
	} catch (error) {
		console.log(error);
		return 0;
	}
};

/**
 * Filter the Case Close Reason
 * @param caseCloseReasonList
 * @param searchText
 * @param currentLanguage
 * @returns return the match result
 */
export const filterCloseState = (
	caseCloseReasonList: Array<any>,
	searchText: any,
	currentLanguage: string
): Array<any> => {
	try {
		return caseCloseReasonList.filter((reason: any) =>
			(currentLanguage === Common.Constants.languageCodes.enGB
				? reason.closeReason
				: reason.displayName
			)
				.toUpperCase()
				.includes(searchText.reasonSearch.toUpperCase())
		);
	} catch (error) {
		return [];
	}
};
