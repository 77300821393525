import React from "react";
import { $Typography } from "us.common/components";

const { Text } = $Typography;

interface IDisplayLabel {
  name : string;
}

export const DisplayLabel: React.FC<IDisplayLabel> = (props) => {
  
  const { name  } = props;

  return (
    <small className="d-block">
        <Text type="secondary">
            <strong>
                {name}
            </strong>
        </Text>
    </small>
  );
};
