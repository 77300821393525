import React from 'react';
import { Link } from 'react-router-dom';
import { ICase, ISubCase } from 'us.collection.transactions/interfaces';
import {
	$AmountLabel,
	$Button,
	$Popconfirm,
	$Tag,
	$Tooltip,
} from 'us.common/components';
import { CaseType } from 'us.helper/types/enums';
import { appInsights, i18n } from 'us.helper';
import { BoxIcons, IconTypes } from 'us.icons';
import { CaseState } from 'us.collection.transactions/constants';
import { ITableCustomComponents } from '../Interfaces';
import { INavigationData } from 'us.collection/interfaces';
import { URLType } from 'us.collection/constants';

/**
 * @function
 * @description - Get the table render items for the table columns
 * @param {keyof ICase} dataIndex - The data index of ICase interface
 * @param {(record: ISubCase) => void} handleCaseSplit - Case split handlig function
 * @return {*}  {ITableCustomComponents} - Custom table components to be rendered
 */
export const getTableRenderItems = (
	dataIndex: keyof ICase,
	handleCaseSplit: (record: ISubCase) => void,
	state: INavigationData,
	search?: string
): ITableCustomComponents => {
	try {
		const t = i18n.t.bind(i18n);
		switch (dataIndex) {
			case 'caseNumber':
				return {
					customSorter: (a: any, b: any) =>
						a?.localeCompare(b),
					customRenderParent: (
						text: any,
						{
							caseNumber,
							caseId,
							caseType,
						}: ICase,
						index: number
					) => {
						return {
							children: (
								<>
									{text >
										0 && (
										<$Tooltip
											placement='topLeft'
											title={`${caseNumber} ${' '} ${`(${t(
												'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.CASE_ID'
											)} : ${caseId})`}`}>
											<div className='text-truncate'>
												<Link
													to={{
														pathname: `/case/${caseNumber}`,
														state: {
															...state,
															currentTab:
																caseType ==
																CaseType.C
																	? URLType.CASE
																	: URLType.SUBCASE,
															caseNo: caseNumber,
															caseType: caseType,
															id: caseId,
														},
														search,
													}}>
													<strong>
														{
															text
														}
													</strong>
												</Link>
												<span>
													{' '}
													{`(${t(
														'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.CASE_ID'
													)} : ${caseId})`}
												</span>
											</div>
										</$Tooltip>
									)}
									{text ==
										0 && (
										<strong>
											{t(
												'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.OTHER_SUBCASES'
											)}

											:{' '}
										</strong>
									)}
								</>
							),
							key: index,
							props: {
								colSpan: 1,
							},
						};
					},
					customRenderChild: (
						text: any,
						record: ISubCase,
						index: number
					) => {
						const {
							caseType,
							caseNumber,
							isSplitEnabled,
							parentId,
							caseId,
						} = record;
						return {
							children: (
								<div className='text-truncate'>
									{caseType ==
										CaseType.S &&
										!Number.isNaN(
											parentId
										) &&
										isSplitEnabled && (
											<$Popconfirm
												title={t(
													'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.SPLIT_CONFIRM_MSG'
												)}
												placement='topLeft'
												onConfirm={() =>
													handleCaseSplit(
														record
													)
												}
												okText={t(
													'US.COLLECTION.COMMON:COMMON.YES'
												)}
												cancelText={t(
													'US.COLLECTION.COMMON:COMMON.NO'
												)}>
												<$Tooltip
													placement='topLeft'
													title={t(
														'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.SPLIT_SUBCASES_FROM_CASE'
													)}>
													<$Button className='bui-btn-tool-icon mr-3'>
														<BoxIcons
															type={
																IconTypes.BOX_ICON
															}
															name='split-case'
														/>
													</$Button>
												</$Tooltip>
											</$Popconfirm>
										)}
									{caseNumber && (
										<$Tooltip
											placement='topLeft'
											title={`${caseNumber} ${' '} ${`(${t(
												'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.CASE_ID'
											)} : ${caseId})`}`}>
											<Link
												to={{
													pathname: `/case/${caseNumber}`,
													state: {
														...state,
														currentTab:
															caseType ==
															CaseType.C
																? URLType.CASE
																: URLType.SUBCASE,
														caseNo: caseNumber,
														caseType: caseType,
														id: caseId,
													},
													search,
												}}>
												{
													caseNumber
												}
											</Link>
											<span>
												{' '}
												{`(${t(
													'US.COLLECTION.TRANSACTIONS:CASES_AND_SUBCASES.CASE_ID'
												)} : ${caseId})`}
											</span>
										</$Tooltip>
									)}
								</div>
							),
							key: index,
							props: {
								colSpan: 1,
							},
						};
					},
				};

			case 'workflowState':
				return {
					customSorter: (a: any, b: any) =>
						a?.localeCompare(b),
					customRenderParent: (
						text: any,
						{
							workflowState,
							caseState,
							closeReason,
							parentId,
						}: ICase,
						index: number
					) => {
						return {
							children: (
								<>
									{workflowState && (
										<$Tooltip
											placement='topLeft'
											title={`${workflowState} ${
												caseState ===
													CaseState.CLOSE &&
												closeReason
													? ' - ' +
													  closeReason
													: ''
											}`}>
											<$Tag
												className={`case-state tag-status-${
													caseState ===
													CaseState.OPEN
														? 'active'
														: 'close'
												}`}>
												{
													workflowState
												}{' '}
												{caseState ===
													CaseState.CLOSE &&
													closeReason &&
													' - ' +
														closeReason}
											</$Tag>
										</$Tooltip>
									)}
								</>
							),
							key: index,
							props: {
								colSpan: 1,
							},
						};
					},
					customRenderChild: (
						text: any,
						{
							workflowState,
							closeReason,
							caseState,
						}: ISubCase,
						index: number
					) => {
						return {
							children: (
								<>
									{workflowState && (
										<$Tooltip
											placement='topLeft'
											title={`${workflowState} ${
												caseState ===
													CaseState.CLOSE &&
												closeReason
													? ' - ' +
													  closeReason
													: ''
											}`}>
											<$Tag
												className={
													caseState ===
													CaseState.OPEN
														? 'case-state tag-status-active'
														: 'case-state tag-status-close'
												}>
												{
													workflowState
												}{' '}
												{caseState ===
													CaseState.CLOSE &&
													closeReason &&
													' - ' +
														closeReason}
											</$Tag>
										</$Tooltip>
									)}
								</>
							),
							key: index,
							props: {
								colSpan: 1,
							},
						};
					},
				};

			case 'mainAmount':
			case 'collectionFee':
			case 'courtFee':
			case 'otherCost':
			case 'interestAmount':
			case 'caseCost':
			case 'paidAmount':
			case 'balance':
				return {
					customSorter: (a: any, b: any) => a - b,
					customRenderParent: (
						text: any,
						record: ICase,
						index: number
					) => {
						return {
							children: (
								<strong>
									<$AmountLabel
										value={
											record[
												dataIndex
											]
										}
									/>
								</strong>
							),
							key: index,
							props: {
								colSpan: 1,
							},
						};
					},
					customRenderChild: (
						text: any,
						record: ISubCase,
						index: number
					) => {
						return {
							children: (
								<$AmountLabel
									value={
										record[
											dataIndex
										]
									}
								/>
							),
							key: index,
							props: { colSpan: 1 },
						};
					},
				};
			default:
				return {
					customSorter: (a: any, b: any) => a - b,
					customRenderParent: (
						text: any,
						record: ICase,
						index: number
					) => {
						return {
							children: (
								<strong>
									<$AmountLabel
										value={parseInt(
											record.caseId
										)}
									/>
								</strong>
							),
							key: index,
							props: {
								colSpan: 1,
							},
						};
					},
					customRenderChild: (
						text: any,
						record: ISubCase,
						index: number
					) => {
						return {
							children: (
								<$AmountLabel
									value={parseInt(
										record.caseId
									)}
								/>
							),
							key: index,
							props: { colSpan: 1 },
						};
					},
				};
		}
	} catch (error) {
		appInsights.trackException(
			`Error in case and subcases getTableRenderItems function. - ${error}`
		);
		return {
			customSorter: (a: any, b: any) => a - b,
			customRenderParent: (
				text: any,
				record: ICase,
				index: number
			) => {
				return {
					children: <></>,
					key: index,
					props: {
						colSpan: 1,
					},
				};
			},
			customRenderChild: (
				text: any,
				record: ISubCase,
				index: number
			) => {
				return {
					children: <></>,
					key: index,
					props: { colSpan: 1 },
				};
			},
		};
	}
};
