import * as Yup from "yup";

interface IAddBMDGroup {
    name: string,
    description: string,
    categoryId: number
}

/**
 * @description Validation object used in add bmd group form
 */

export default Yup.object<IAddBMDGroup>().shape({
  name: Yup.string()
    .required("US.COLLECTION.VALIDATIONS:REQUIRED.SUB_CATEGORY_IS_REQUIRED")
    .typeError("US.COLLECTION.VALIDATIONS:REQUIRED.SUB_CATEGORY_IS_REQUIRED"),
  categoryId: Yup.number()
    .required("US.COLLECTION.VALIDATIONS:REQUIRED.MAIN_CATEGORY_IS_REQUIRED"),
});
