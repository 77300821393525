import React from "react";
import { useTranslation } from "react-i18next";
import { $Button, $Divider, $Skeleton } from "us.common/components";

interface IControllerButtons {
  onSaveClick: (values: any) => void;
  isSaving: boolean;
  setValues: any;
  handleReset: any;
  values: any;
  reduxIsEditing:boolean;
}

export const ControllerButtons: React.FC<IControllerButtons> = (props) => {
  const { t } = useTranslation(["US.COLLECTION.COMMON"], {
    useSuspense: true,
  });

  const { handleReset, onSaveClick, setValues, values, isSaving,reduxIsEditing } = props;

  return (
    <$Skeleton loading={values.isLoading} active paragraph={{ rows: 0 }}>
      {(values.isEditing || reduxIsEditing) && (
        <>
          <$Divider className="bui-devider" type="vertical" />
          <$Button
            onClick={() => {
              setValues((preVals: any) => ({ ...preVals, isEditing: false }));
              onSaveClick(values);
            }}
            loading={isSaving}
            type="primary"
            size="small"
            style={{ width: 100 }}
          >
            {t("US.COLLECTION.COMMON:COMMON.SAVE")}
          </$Button>
          <$Button
            className="ml-2"
            onClick={handleReset}
            size="small"
            style={{ width: 100 }}
          >
            {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
          </$Button>
        </>
      )}
      {!values.isEditing && !reduxIsEditing && (
        <>
          <$Divider className="bui-devider" type="vertical" />
          <$Button
            onClick={() =>
              setValues((preVals: any) => ({ ...preVals, isEditing: true }))
            }
            type="primary"
            size="small"
            style={{ width: 100 }}
            disabled={values.closeDate}
          >
            {t("US.COLLECTION.COMMON:COMMON.EDIT")}
          </$Button>
        </>
      )}
    </$Skeleton>
  );
};
