import { versionedHttpCollection } from 'us.helper/http/collection';
import ServiceConfig from 'service.config.js';

export default {
	objection: {
		cancel: async (caseId: number): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data } =
						await versionedHttpCollection.delete(
							'arservice',
							'ars/cases',
							`${caseId}/objection`,
							{},
							ServiceConfig()[
								`paymentagreementservice`
							][
								'paymentAgreement.amount'
							]
						);
					resolve(data);
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	cop: {
		save: (params: any): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.post(
							'arservice',
							'ars/claimofpayment',
							params,
							ServiceConfig()[
								`arservice`
							]['objectionsCOP.save']
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	invoices: {
		getInvoices: <
			P extends {
				caseNo: string;
			}
		>(
			params: P
		): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data } =
						await versionedHttpCollection.get(
							'arservice',
							`ars/cases/${params}/subcases`,
							{},
							ServiceConfig()[
								`uscservice`
							][
								'domainMessage.template'
							]
						);
					resolve(data);
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	debtorObjection: {
		save: (params: any): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.post(
							'arservice',
							'ars/debtorobjection',
							params,
							ServiceConfig()[
								`arservice`
							]['objectionsDO.save']
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	objectionProfile: {
		get: <
			P extends {
				caseNo: string;
			}
		>(
			params: P
		): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data } =
						await versionedHttpCollection.get(
							'arservice',
							`ars/cases/${params}/objectionprofile`,
							{},
							ServiceConfig()[
								`arservice`
							]['objection.profile']
						);
					resolve(data);
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	kidListByCaseNo: {
		get: <
			P extends {
				caseNo: string;
			}
		>(
			params: P
		): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'arservice',
							`ars/cases/${params}/kids`,
							{},
							ServiceConfig()[
								`arservice`
							]['cases.kids']
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
	},
};
