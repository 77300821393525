import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HtmlParser, {
  domToReact,
  HTMLReactParserOptions,
} from "html-react-parser";
import {
  $Button,
  $Collapse,
  $Drawer,
  $Skeleton,
  Pagination,
  $TextArea,
} from "us.common/components";
import { CheckCircleFilled } from "us.icons";
import "./TemplateEditor.scss";
import { DomainMessageType } from "us.collection.case/constants/NotesAndMessage";
import { ITemplateEditor } from "./Interfaces";
import {
  getEditableContent,
  getMainContentForNavigatePage,
  getMessageContent,
  getTockenDataValue,
  setHTMLCode,
  setIframeContent,
} from "../../Functions";
import ReactDOMServer from "react-dom/server";
import { SendDashboardMessage } from "us.collection.case/repository";

/**
 * @description - Manage domain message template view and add/edit custom message
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3292627347/Domain+message+-+Template+editor+UI+design
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 10/07/2023
 */

const TemplateEditor: React.FC<ITemplateEditor> = (props) => {
  const { t } = useTranslation();
  const {
    closeTemplateDrawer,
    openTemplateDrawer,
    domainMessageFormattedTemplate,
    domainMessageInitial,
    documentType,
    messageContentDetails,
    send,
    metaData,
    selectAttachment
  } = props;

  const { caseId, entityType } = metaData.data ?? {};
  const { messages: templateHtmlContent, dataItems } = messageContentDetails?.data ?? {};

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [selectedCustomTemplate, setSelectedCustomTemplate] = useState<
    string | string[]
  >("");
  const [editableMessageContent, setEditableMessageContent] = useState({
    editContentId: 0,
    content: "",
  });
  const [customText, setCustomText] = useState<string>("");

  useEffect(() => {
    if (templateHtmlContent?.length) {
      if (document.getElementById("editor")) {
        setHTMLCode(templateHtmlContent[0], "");
      }
      setEditableMessageContent(
        getEditableContent(templateHtmlContent)
      );
    }
  }, [messageContentDetails.data]);

  /**
   * Actual data set to template params
   */
  const options: HTMLReactParserOptions = {
    replace: (domNode: any) => {
      if (domNode?.attribs && domNode.name === "params") {
        return (
          <>
            {domToReact(domNode.children, {
              replace: (domNode) => {
                return (
                  <span>
                    {getTockenDataValue(
                      dataItems,
                      domNode
                    )}
                  </span>
                );
              },
            })}
          </>
        );
      }
    },
  };

  /**
   * @function
   * @description Replace editable template content with user selection
   * @param htmlStr - USC template
   * @param templateList - Custom selection text
   * @param {*} templateValue - Selected custom text value
   * @returns
   */
  const setTemplateContent = (
    htmlStr: string,
    templateList: any,
    templateValue: any
  ) => {
    try {
      if (
        currentPage + 1 == editableMessageContent.editContentId ||
        currentPage - 1 == editableMessageContent.editContentId
      ) {
        const editorId = document.getElementById("editor") as any;
        setIframeContent(editorId, htmlStr);
      } else {
        const template =
          templateList
            .find((record: any) => record.value === templateValue)
            ?.text.toString() ?? customText;
        const htmlString = ReactDOMServer.renderToString(
          <div>{HtmlParser(template.toString(), options)}</div>
        );
        if (htmlString && htmlStr) setHTMLCode(htmlStr.toString(), htmlString);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * @function
   * @description Replace editable template content with user text
   * @param {*} customText - User message
   * @returns
   */
  const setCustormMessage = (customText: string) => {
    try {
      if (customText?.length >= 0) {
        setHTMLCode(editableMessageContent.content, customText);
      }

      setSelectedCustomTemplate("");
      setCustomText(customText);
      setCurrentPage(editableMessageContent.editContentId);

      if (currentPage == editableMessageContent.editContentId) {
        const editorId = document.getElementById("editor") as any;
        const innerHTML =
          editorId.contentWindow.document.documentElement.innerHTML;
        setEditableMessageContent({
          ...editableMessageContent,
          content: innerHTML,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Page navigation
   * @param toNext - Is next page or not
   */
  const onNavigation = (toNext: boolean) => {
    try {
      const scrollWrapper = document.getElementById("mteWrapper") as any;
      scrollWrapper.scrollTop = 0;

      if (currentPage == editableMessageContent.editContentId) {
        const editorId = document.getElementById("editor") as any;
        const innerHTML =
          editorId.contentWindow.document.documentElement.outerHTML;
        setEditableMessageContent({
          ...editableMessageContent,
          content: innerHTML,
        });
      }

      if (toNext) {
        setTemplateContent(
          getMainContentForNavigatePage(
            currentPage,
            editableMessageContent,
            templateHtmlContent,
            true
          ),
          domainMessageInitial.templateList,
          selectedCustomTemplate
        );
        setCurrentPage(currentPage + 1);
      } else {
        setTemplateContent(
          getMainContentForNavigatePage(
            currentPage,
            editableMessageContent,
            templateHtmlContent,
            false
          ),
          domainMessageInitial.templateList,
          selectedCustomTemplate
        );
        setCurrentPage(currentPage - 1);
      }
    } catch (error) {}
  };

  /**
   * Send domain message
   */
  const sendDashboardMessage = () => {
      const attachmentList : Array<string> = [];

      if(selectAttachment?.filePaths?.length){
        selectAttachment?.filePaths?.map((file: any) =>{
          attachmentList.push(file.path);
        })
      }
      
    send(
      SendDashboardMessage.call(
        messageContentDetails?.data,
        domainMessageInitial,
        caseId,
        entityType,
        getMessageContent(templateHtmlContent, editableMessageContent),
        attachmentList
      )
    );
    closeTemplateDrawer()
  };

  return (
    <$Drawer
      width="1250"
      title={openTemplateDrawer.title}
      placement="right"
      closable={false}
      onClose={closeTemplateDrawer}
      visible={
        documentType === DomainMessageType.EMAIL
          ? openTemplateDrawer.isTemplateEditor && openTemplateDrawer.visible
          : openTemplateDrawer.visible
      }
      data-testid="edit-template"
      className="edit-html-template"
    >
      <div className="d-flex edit-html-template-body">
        <$Skeleton
          loading={messageContentDetails?.isLoading}
          active
          className="mx-4 my-2"
          paragraph={{ rows: 3 }}
        >
          <div className="edit-html-template-side">
            {!domainMessageFormattedTemplate?.isLoading && (
              <$Collapse
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <CheckCircleFilled />
                  ) : (
                    <span className="not-selected" />
                  )
                }
                ghost
                accordion
                defaultActiveKey={"customtext"}
                onChange={(templateValue: string | string[]) => {
                  if(templateHtmlContent){
                    setTemplateContent(
                      templateHtmlContent[0],
                      domainMessageInitial.templateList,
                      templateValue
                    )
                  }
                    setSelectedCustomTemplate(templateValue),
                    setCurrentPage(0);
                }}
              >
                <$Collapse.Panel header={t("US.COLLECTION.CASE:NOTESANDMESSAGES.CUSTOM_TEXT")} key={"customtext"}>
                  <div className="template-text-block">
                    <$TextArea
                      name="message"
                      placeholder={t(
                        "US.COLLECTION.CASE:NOTESANDMESSAGES.TYPE_A_CUSTOM_TEXT_HERE"
                      )}
                      className="custom-message-textarea"
                      value={customText}
                      onChange={(data: any) =>
                        setCustormMessage(data.target.value)
                      }
                    />
                  </div>
                </$Collapse.Panel>
                {domainMessageInitial.templateList.map(
                  (item: any, index: any) => (
                    <$Collapse.Panel header={item.label} key={item.key}>
                      <div className="template-text-block">
                        {HtmlParser(item.text, options)}
                      </div>
                    </$Collapse.Panel>
                  )
                )}
              </$Collapse>
            )}
          </div>
          <div
            id="mteWrapper"
            className="flex-grow-1 edit-html-template-content"
          >
            <iframe
              className="mte-ieditor-iframe"
              id="editor"
              height="1130px"
              width="100%"
            />
          </div>
        </$Skeleton>
      </div>
      <div className="drawer-footer-fixed align-content-center justify-content-between">
        <Pagination
          dataLength={templateHtmlContent?.length}
          currentPage={currentPage}
          onNavigation={onNavigation}
        />
        <div>
          <$Button
            className="mr-2"
            type="primary"
            htmlType="submit"
            disabled={domainMessageFormattedTemplate?.isLoading}
            onClick={sendDashboardMessage}
          >
            {t("US.COLLECTION.COMMON:COMMON.SEND")}
          </$Button>
          <$Button
            data-testid="drawer-cancel-btn"
            onClick={closeTemplateDrawer}
          >
            {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
          </$Button>
        </div>
      </div>
    </$Drawer>
  );
};

export default TemplateEditor;
