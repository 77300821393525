import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { IntlDate } from 'us.common/functions';
import Common from 'us.common';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import Highlighter from 'react-highlight-words';
import { CaseInformationAction } from 'us.collection.case/actions';
import { IOnFilter, IOnSort } from 'us.common/components/antd/TableTree';

const {
	$Input,
	$Button,
	$DatePicker,
	$Form,
	$Row,
	$Col,
	$Table,
	$Drawer,
	$Popconfirm,
	$Message,
	$DateLabel,
	$TableTree,
} = Common.Components;

import { FilterOutlined, SearchOutlined, DeleteOutlined } from 'us.icons';

import { IRootState } from 'us.collection/interfaces';
import * as Azure from 'us.helper/azure';
import ObsoleteInterestValidation from 'us.collection.case/components/Validations/ObsoleteInterestValidation';
import { IAddObsoleteInterest } from 'us.collection.case/components/CaseInformation/Interfaces';

export interface ISortOrder {
	columnKey: string;
	order: any;
}

const AddObsoleteInterest: React.FC<IAddObsoleteInterest> = (props) => {
	const { t } = useTranslation(
		['US.COLLECTION.CASE', 'US.COLLECTION.COMMON'],
		{
			useSuspense: true,
		}
	);
	const {
		currentDateFormat,
		currentLanguage,
		drawerClose,
		drawerVisible,
		getObsoleteInterest,
		interestOccurrences,
		saveObsoleteInterest,
		metaData,
		deleteObsoleteInterest,
	} = props;

	const { caseId } = metaData.data ?? {};

	const [searchText, setSearchText] = useState<string>('');
	const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
		columnKey: '',
		order: '',
	});
	const [tableDate, setTableData] = useState<any>([]);

	useEffect(() => {
		if (interestOccurrences?.caseID != caseId) {
			getObsoleteInterest(caseId);
		} else {
			let tempTableData: Array<any> = [];
			interestOccurrences?.list?.map((item: any) => {
				tempTableData.push({
					key: item.occurrenceId,
					REG_DATE: IntlDate(
						item.regDate,
						'enGB',
						'DATE_TIME24'
					),
					OCCURENCEDATE: IntlDate(
						item.occurrenceDate,
						'enGB',
						'DATE_TIME24'
					),
					OCCURENCENAME: item.occurrenceName,
					USER: item.userName,
					isNewAdded: false,
				});
			});
			setTableData(tempTableData);
		}
	}, [interestOccurrences, metaData]);

	const getCurrentUser = () => {
		let isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
		let auth;
		if (isAzureAD) {
			auth = new Azure.ADAuth();
		} else {
			auth = new Azure.B2CAuth();
		}
		const authentication = auth;
		const currentUser: any = authentication.currentUser();
		return currentUser;
	};

	const addDataToTable = (values: any, rest: any) => {
		if (
			tableDate.find(
				(x: any) =>
					moment(x.OCCURENCEDATE).format(
						'YYYY-MM-DD'
					) ==
						moment(
							values.OCCURENCEDATE
						).format('YYYY-MM-DD') &&
					x.OCCURENCENAME == values.OCCURENCENAME
			)
		) {
			$Message.error('Occurrences can not be duplicated.');
		} else {
			let userDetails = getCurrentUser();
			let tempTableData = [...tableDate];
			tempTableData.push({
				key:
					moment(values.OCCURENCEDATE).format(
						'YYYY-MM-DD'
					) + values.OCCURENCENAME,
				REG_DATE: Date().toLocaleString(),
				OCCURENCEDATE:
					moment(values.OCCURENCEDATE).format(
						'YYYY-MM-DD'
					) +
					' ' +
					'00:00',
				OCCURENCENAME: values.OCCURENCENAME,
				USER: userDetails?.name,
				isNewAdded: true,
			});
			setTableData([...tempTableData]);
		}
		rest.resetForm();
	};

	const handleDeleteAddItem = (record: any) => {
		let tempTable: Array<any> = [];
		tableDate?.map((item: any) => {
			if (item.key != record.key) {
				tempTable.push(item);
			}
		});
		if (
			interestOccurrences?.list.find(
				(x: any) => x.occurrenceId == record.key
			)
		) {
			deleteObsoleteInterest(record.key);
		} else {
			$Message.success('Occurrence successfully deleted.');
		}
		setTableData(tempTable);
	};

	const handleTableChange = (
		pagination: any,
		filters: any,
		sorter: any
	) => {
		setSortedInfo(sorter);
	};

	const handleReset = (clearFilters: any) => {
		clearFilters();
		setSearchText('');
	};

	const handleSearch = (selectedKeys: any, confirm: any) => {
		confirm();
		setSearchText(selectedKeys[0]);
	};

	const getColumnSearchProps = (dataIndex: string, title: string) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}: any) => (
			<div style={{ padding: 8 }}>
				<$Input
					name='columnSearch'
					placeholder={`${t(
						'US.COLLECTION.COMMON:SEARCH'
					)} ${title}`}
					value={selectedKeys[0]}
					onChange={(e: any) =>
						setSelectedKeys(
							e.target.value
								? [
										e
											.target
											.value,
								  ]
								: []
						)
					}
					onPressEnter={() =>
						handleSearch(
							selectedKeys,
							confirm
						)
					}
					style={{
						width: 188,
						marginBottom: 8,
						display: 'block',
					}}
				/>
				<$Button
					name='searchBtn'
					type='primary'
					onClick={() =>
						handleSearch(
							selectedKeys,
							confirm
						)
					}
					icon={<SearchOutlined />}
					size='small'
					style={{ width: 90, marginRight: 8 }}>
					{t('US.COLLECTION.COMMON:SEARCH')}
				</$Button>
				<$Button
					name='resetBtn'
					onClick={() =>
						handleReset(clearFilters)
					}
					size='small'
					style={{ width: 90 }}>
					{t('US.COLLECTION.COMMON:RESET')}
				</$Button>
			</div>
		),
		filterIcon: (filtered: string) => (
			<FilterOutlined
				style={{
					color: filtered ? '#1890ff' : undefined,
				}}
			/>
		),
		onFilter: (value: string, record: any) =>
			record[dataIndex]
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		render: (text: string) => (
			<Highlighter
				highlightStyle={{
					backgroundColor: '#ffc069',
					padding: 0,
				}}
				searchWords={[searchText]}
				autoEscape
				textToHighlight={text ? text.toString() : ''}
			/>
		),
	});

	const columns: any = [
		{
			title: '',
			key: 'Id',
			width: '50px',
			customRenderChild: (text: any, record: any) => {
				return (
					<span>
						<$Popconfirm
							title={t(
								'US.COLLECTION.CASE:PROPERTIES.DELETE_CONFIRM'
							)}
							onConfirm={() =>
								handleDeleteAddItem(
									record
								)
							}
							placement='rightTop'
							destroyTooltipOnHide>
							<$Button
								icon={
									<DeleteOutlined />
								}
								size='small'
								danger
							/>
						</$Popconfirm>
					</span>
				);
			},
		},
		{
			title: t('US.COLLECTION.CASE:PROPERTIES.OCCURENCEDATE'),
			dataIndex: 'OCCURENCEDATE',
			key: 'OCCURENCEDATE',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (text: any, record: any) => {
				return (
					<span>
						<$DateLabel value={text} />
					</span>
				);
			},
		},
		{
			title: t('US.COLLECTION.CASE:PROPERTIES.OCCURENCENAME'),
			dataIndex: 'OCCURENCENAME',
			key: 'OCCURENCENAME',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
		{
			title: t('US.COLLECTION.CASE:PROPERTIES.REG_DATE'),
			dataIndex: 'REG_DATE',
			key: 'REG_DATE',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (text: any, record: any) => {
				return (
					<span>
						<$DateLabel value={text} />
					</span>
				);
			},
		},
		{
			title: t('US.COLLECTION.CASE:PROPERTIES.USER'),
			dataIndex: 'USER',
			key: 'USER',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
	];

	const handleAddInterestOccurrencesSubmit = () => {
		let occurrenceList: Array<any> = [];
		tableDate?.map((item: any) => {
			if (item.isNewAdded) {
				occurrenceList.push({
					occurrenceDate: moment(
						item.OCCURENCEDATE
					)
						.locale('en')
						.format('YYYY-MM-DDTHH:mm:ss'),
					occurrenceName: item.OCCURENCENAME,
				});
			}
		});
		let queryBody = {
			caseId,
			interestOccurenceList: occurrenceList,
		};
		saveObsoleteInterest(queryBody);
		getObsoleteInterest(caseId);
		drawerClose();
	};

	const handleSort: IOnSort = (sortData, dataSource) => {
		return sortData(dataSource);
	};

	const handleFilter: IOnFilter = (searchData, dataSource) => {
		return searchData(dataSource);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{ OCCURENCEDATE: '', OCCURENCENAME: '' }}
			validationSchema={ObsoleteInterestValidation}
			onSubmit={(values: any, actions: any) => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				...rest
			}: any) => (
				<$Drawer
					title={t(
						'US.COLLECTION.CASE:PROPERTIES.OBSOLETE_INTEREST'
					)}
					width={1200}
					onClose={drawerClose}
					visible={drawerVisible}
					destroyOnClose>
					<$Form>
						<$Row gutter={18}>
							<$Col span='6'>
								<div className='mb-1'>
									<$DatePicker
										formitem
										allowClear
										name='OCCURENCEDATE'
										label={t(
											'US.COLLECTION.CASE:PROPERTIES.OCCURENCEDATE'
										)}
										format={
											currentDateFormat
										}
										defaultValue={moment(
											values.OCCURENCEDATE
										)}
										value={
											values.OCCURENCEDATE
										}
										style={{
											width: '100%',
										}}
										size='small'
									/>
								</div>
							</$Col>
							<$Col span='12'>
								<div className='mb-1'>
									<$Input
										size='small'
										label={t(
											'US.COLLECTION.CASE:PROPERTIES.OCCURENCENAME'
										)}
										name='OCCURENCENAME'
										style={{
											width: '100%',
										}}
									/>
								</div>
							</$Col>
							<$Col span='4'>
								<div
									style={{
										marginTop: '27px',
									}}>
									<$Button
										onClick={() =>
											addDataToTable(
												values,
												rest
											)
										}
										type='dashed'
										size='small'
										disabled={
											!rest.isValid ||
											!rest.dirty ||
											!moment(
												values.OCCURENCEDATE
											).isValid()
										}>
										{t(
											'US.COLLECTION.CASE:PROPERTIES.ADD_TO_LIST'
										)}
									</$Button>
								</div>
							</$Col>
						</$Row>

						<div>
							<$TableTree
								onChange={
									handleTableChange
								}
								rowKey='arItemNo'
								data={tableDate}
								size='small'
								className='mt-3'
								onSort={
									handleSort
								}
								onFilter={
									handleFilter
								}
								filterOnType={
									true
								}
								resetOnSourceChange={
									true
								}
								bordered
								pagination={{
									defaultPageSize: 15,
								}}
								scroll={{
									x: 1000,
								}}
								columns={
									columns
								}
							/>
						</div>

						<div className='drawer-footer-fixed align-content-center justify-content-end'>
							<div>
								<$Button
									className='mr-2'
									disabled={
										!tableDate.find(
											(
												x: any
											) =>
												x.isNewAdded ==
												true
										)
									}
									onClick={
										handleAddInterestOccurrencesSubmit
									}
									type='primary'>
									{t(
										'US.COLLECTION.COMMON:COMMON.SAVE'
									)}
								</$Button>
								{rest.dirty && (
									<$Popconfirm
										title={t(
											'US.COLLECTION.CASE:PROPERTIES.CANCEL_CONFIRM'
										)}
										placement='topLeft'
										onConfirm={() =>
											drawerClose()
										}
										okText={t(
											'US.COLLECTION.COMMON:COMMON.YES'
										)}
										cancelText={t(
											'US.COLLECTION.COMMON:COMMON.NO'
										)}>
										<$Button>
											{t(
												'US.COLLECTION.COMMON:COMMON.CANCEL'
											)}
										</$Button>
									</$Popconfirm>
								)}
								{!rest.dirty && (
									<$Button
										onClick={() =>
											drawerClose()
										}>
										{t(
											'US.COLLECTION.COMMON:COMMON.CANCEL'
										)}
									</$Button>
								)}
							</div>
						</div>
					</$Form>
				</$Drawer>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: IRootState) => {
	const { caseInformation, domainView, common } = state;
	const { interestOccurrences } = caseInformation;
	const { currentCurrency, currentLanguage, currentDateFormat } = common;
	const { metaData } = domainView;
	return {
		interestOccurrences,
		currentCurrency,
		currentLanguage,
		currentDateFormat,
		metaData,
	};
};

const mapDispatchToProps = {
	getObsoleteInterest: CaseInformationAction.InterestOccurrences.get,
	saveObsoleteInterest: CaseInformationAction.AddInterestOccurrences.save,
	deleteObsoleteInterest:
		CaseInformationAction.DeleteInterestOccurrences.delete,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddObsoleteInterest);
