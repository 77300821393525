import { ITransaction } from 'us.collection.transactions/interfaces';
import { IntlCurrency, IntlDate } from 'us.common/functions';
import moment from 'moment';
import _ from 'lodash';
import { appInsights } from 'us.helper';

type filter = {
	caseNo: string;
	regDate: string;
	type: string;
	voucherID: string;
	amount: string;
	invoiceNumber: string;
	kid: string;
	voucherDate: string;
	paymentID: string;
	interestAmount: string;
	totalAmount: string;
	paid: string;
	balance: string;
	description: string;
};

export const filterTransactions = (
	transactions: ITransaction[],
	tableView: string,
	filters: filter,
	currentDateFormat: string,
	currentLanguage: string,
	currentCurrency: string
) => {
	try {
		const beforeTableView = transactions
			.map((trans: ITransaction, index: number) => {
				return { ...trans, id: index };
			})
			.filter((transaction: ITransaction) => {
				let matched = true;
				if (
					filters.caseNo != '' &&
					filters.caseNo != undefined
				) {
					if (
						transaction.caseNo.indexOf(
							filters.caseNo
						) == -1 ||
						transaction.caseNo.indexOf(
							filters.caseNo
						) > 0
					) {
						matched = false;
					}
				}
				if (
					filters.regDate != '' &&
					filters.regDate != undefined
				) {
					if (
						moment(
							transaction.regDate
						).format(currentDateFormat) !=
							moment(
								filters.regDate
							).format(
								currentDateFormat
							) &&
						!moment(transaction.regDate)
							.format(
								currentDateFormat
							)
							.includes(
								filters.regDate
							)
					) {
						matched = false;
					}
				}
				if (
					filters.type != 'All' &&
					filters.type != undefined
				) {
					if (
						transaction.transactionGroupName !=
							filters.type &&
						transaction.type?.toLocaleLowerCase() !=
							filters.type?.toLocaleLowerCase()
					) {
						matched = false;
					}
				}
				if (
					filters.voucherID != '' &&
					filters.voucherID != undefined
				) {
					if (
						transaction.voucherID
							?.toString()
							.indexOf(
								filters.voucherID
							) == -1 ||
						transaction.voucherID
							?.toString()
							.indexOf(
								filters.voucherID
							) > 0
					) {
						matched = false;
					}
				}
				if (
					filters.amount != '' &&
					filters.amount != undefined
				) {
					if (
						transaction.amount
							.toFixed(2)
							.indexOf(
								filters.amount
							) == -1 &&
						IntlCurrency(
							transaction.amount,
							currentLanguage,
							currentCurrency
						)
							.replace(/\s+/g, '')
							.indexOf(
								filters.amount.replace(
									/\s+/g,
									''
								)
							) == -1
					) {
						matched = false;
					}
				}
				if (
					filters.invoiceNumber != '' &&
					filters.invoiceNumber != undefined
				) {
					if (
						transaction.invoiceNumber.indexOf(
							filters.invoiceNumber
						) == -1 ||
						transaction.invoiceNumber.indexOf(
							filters.invoiceNumber
						) > 0
					) {
						matched = false;
					}
				}
				if (
					filters.kid != '' &&
					filters.kid != undefined
				) {
					if (
						transaction.kid?.indexOf(
							filters.kid
						) == -1 ||
						transaction.kid?.indexOf(
							filters.kid
						) > 0 ||
						transaction.kid == null
					) {
						matched = false;
					}
				}
				if (
					filters.voucherDate != '' &&
					filters.voucherDate != undefined
				) {
					if (
						moment(
							transaction.voucherDate
						).format(currentDateFormat) !=
							moment(
								filters.voucherDate
							).format(
								currentDateFormat
							) &&
						!moment(transaction.voucherDate)
							.format(
								currentDateFormat
							)
							.includes(
								filters.voucherDate
							)
					) {
						matched = false;
					}
				}
				if (
					filters.paymentID != '' &&
					filters.paymentID != undefined
				) {
					if (
						transaction.paymentID
							.toString()
							.indexOf(
								filters.paymentID
							) == -1 ||
						transaction.paymentID
							.toString()
							.indexOf(
								filters.paymentID
							) > 0
					) {
						matched = false;
					}
				}
				if (
					filters.interestAmount != '' &&
					filters.interestAmount != undefined
				) {
					if (
						transaction.interestAmount
							?.toFixed(2)
							.indexOf(
								filters.interestAmount
							) == -1 &&
						IntlCurrency(
							transaction.interestAmount,
							currentLanguage,
							currentCurrency
						)
							.replace(/\s+/g, '')
							.indexOf(
								filters.interestAmount.replace(
									/\s+/g,
									''
								)
							) == -1
					) {
						matched = false;
					}
				}
				if (
					filters.totalAmount != '' &&
					filters.totalAmount != undefined
				) {
					if (
						transaction.totalAmount
							?.toFixed(2)
							.indexOf(
								filters.totalAmount
							) == -1 &&
						IntlCurrency(
							transaction.totalAmount,
							currentLanguage,
							currentCurrency
						)
							.replace(/\s+/g, '')
							.indexOf(
								filters.totalAmount.replace(
									/\s+/g,
									''
								)
							) == -1
					) {
						matched = false;
					}
				}
				if (
					filters.paid != '' &&
					filters.paid != undefined
				) {
					if (
						transaction.paid
							?.toFixed(2)
							.indexOf(
								filters.paid
							) == -1 &&
						IntlCurrency(
							transaction.paid,
							currentLanguage,
							currentCurrency
						)
							.replace(/\s+/g, '')
							.indexOf(
								filters.paid.replace(
									/\s+/g,
									''
								)
							) == -1
					) {
						matched = false;
					}
				}
				if (
					filters.balance != '' &&
					filters.balance != undefined
				) {
					if (
						transaction.balance
							?.toFixed(2)
							.indexOf(
								filters.balance
							) == -1 &&
						IntlCurrency(
							transaction.balance,
							currentLanguage,
							currentCurrency
						)
							.replace(/\s+/g, '')
							.indexOf(
								filters.balance.replace(
									/\s+/g,
									''
								)
							) == -1
					) {
						matched = false;
					}
				}
				if (
					filters.description != '' &&
					filters.description != undefined
				) {
					if (
						transaction.description
							?.toLocaleLowerCase()
							.indexOf(
								filters.description?.toLocaleLowerCase()
							) == -1 ||
						transaction.description
							?.toLocaleLowerCase()
							.indexOf(
								filters.description?.toLocaleLowerCase()
							) > 0 ||
						transaction.kid == null
					) {
						matched = false;
					}
				}
				return matched;
			});

		if (tableView == 'group') {
			const finalTable: any = [];
			Object.entries(
				_.groupBy(beforeTableView, 'caseNo')
			).forEach(([caseNo, transArr], index) => {
				const caseTransaction = transArr.find(
					(trans: any) =>
						trans['caseId'] != '-1' &&
						trans['caseId'] != '0'
				);
				const subCaseTransaction = transArr.find(
					(trans: ITransaction) =>
						trans['subCaseNo'] != 0
				);
				const workFlowStateID = transArr.find(
					(trans: ITransaction) =>
						trans['workFlowStateID'] != 0
				);
				const workFlowState = transArr.find(
					(trans: ITransaction) =>
						trans['workFlowState'] != null
				);
				const nextDueDate = transArr.find(
					(trans: ITransaction) =>
						trans['nextDueDate'] != null
				);
				finalTable.push({
					id: beforeTableView.length + index,
					caseNo: caseNo,
					caseId: caseTransaction?.caseId ?? '-1',
					subCaseNo:
						subCaseTransaction?.subCaseNo ??
						0,
					workFlowStateID:
						workFlowStateID?.workFlowStateID ??
						0,
					nextDueDate:
						nextDueDate?.nextDueDate ??
						null,
					workFlowState:
						workFlowState?.workFlowState ??
						'',
					amount: _.sumBy(transArr, 'amount'),
					interestAmount: _.sumBy(
						transArr,
						'interestAmount'
					),
					totalAmount: _.sumBy(
						transArr,
						'totalAmount'
					),
					paid: _.sumBy(transArr, 'paid'),
					balance: _.sumBy(transArr, 'balance'),
					children: _.orderBy(
						transArr.map(
							(trans: ITransaction) =>
								trans
						),
						['regDate'],
						['asc']
					),
				});
			});

			const zeroCaseIndex = finalTable.findIndex(
				(transArr: any) => transArr['caseNo'] == '0'
			);
			if (
				zeroCaseIndex != -1 &&
				zeroCaseIndex != 'undefined'
			) {
				const zeroCase = finalTable[zeroCaseIndex];
				finalTable.splice(zeroCaseIndex, 1);
				finalTable.push(zeroCase);
				return finalTable;
			} else {
				return finalTable;
			}
		} else {
			return _.orderBy(beforeTableView, ['regDate'], ['asc']);
		}
	} catch (error) {
		appInsights.trackException(
			`AR Transaction filtering Exception - ${error}`
		);
		return transactions;
	}
};

/**
 * @description If the response object has a statusCode property and it's value is 201, then return true, otherwise
 * return false.
 * @param {any} response - update mapping transactions API response data
 * @returns {boolean} Returns a boolean whether the response is success or not.
 */
export const isMappingSuccess = (response: any): boolean => {
	try {
		return (
			response &&
			response.hasOwnProperty('statusCode') &&
			response.statusCode == 201
		);
	} catch (error) {
		return false;
	}
};
