import React from "react";
import { useTranslation } from "react-i18next";
import { $Breadcrumb, $Affix, $PageHeader } from "us.common/components";
import { CurrentView } from "../../Constants";
import { IViewHandler } from "../../Interfaces";
import { AddEditDataTypeForm } from "./Components";
import { IAddEditDataType } from "./Interfaces";

export const AddEditDataType: React.FC<IAddEditDataType & IViewHandler> = (props) => {
  const { t } = useTranslation(["US.COLLECTION.CASE", "US.COLLECTION.COMMON"]);
  const { viewchangeEvent, currentViewDetails } = props;
  const { currentView, selectedBMDDataType } = currentViewDetails;

  return (
    <div className="">
      <$Breadcrumb className="mb-0">
        <$Breadcrumb.Item onClick={() => viewchangeEvent(CurrentView.List)}>
          <a>{t("US.COLLECTION.CASE:BMD.MANAGE_TYPES")}</a>
        </$Breadcrumb.Item>
        <$Breadcrumb.Item>
          {t(
            `US.COLLECTION.CASE:BMD.${
              currentView === CurrentView.Add ? "ADD_NEW_TYPE" : "UPDATE_TYPE"
            }`
          )}
        </$Breadcrumb.Item>
      </$Breadcrumb>
      <$Affix offsetTop={80}>
        <div className="page-header">
          <div className="d-flex align-items-center">
            <$PageHeader
              className="px-0 py-0 mr-n3"
              onBack={() => viewchangeEvent(CurrentView.List)}
              title={t(
                `US.COLLECTION.CASE:BMD.${
                  currentView === CurrentView.Add ? "ADD_NEW_TYPE" : "UPDATE_TYPE"
                }`
              )}
            />
          </div>
        </div>
      </$Affix>
      <AddEditDataTypeForm currentView={currentView} selectedBMDDataType={selectedBMDDataType} />
    </div>
  );
};
