import { IDropDown } from "../Interfaces";
import { CaseTypeOptions } from "./CaseTypeOptions";

/**
 * @constant
 * @description - The constants holding the case type dropdown values
 * @type {IDropDown<CaseTypeOptions>[]} - basic drop down option
 */
export const CASE_TYPE_DROP_DOWN: IDropDown<CaseTypeOptions>[] = [
  {
    label: "ALL_CASES",
    value: CaseTypeOptions.ALL,
  },
  {
    label: "SUBCASES",
    value: CaseTypeOptions.SUB_CASE,
  },
  {
    label: "CASES",
    value: CaseTypeOptions.CASE,
  },
];
