import { takeEvery, put, call } from 'redux-saga/effects';
import * as API  from '../services';
import {
  getCurrentStatusSuccess,
  getCaseAssetsSuccess,
  getSentencesListSuccess,
  getNotesListSuccess,
  getCaseInterestSuccess,
  getCaseProptiesDetilSuccess,
  // getExtendedFieldSuccess,
  getEnforcementSuccess,
  getUserAgreementDetailsSuccess,
  setCurrentCaseNoAction,
  getCasePartPaymentsSuccess
} from '../actions';

import { showLoader, hideLoader } from 'us.common/actions';
import { push } from 'connected-react-router'



/**
 * Get agreements on us.box.case
 * @param action 
 */
function* getCurrentStatus(action: any) {
  const params = action.payload;
  try {
    const data = yield call(API.getCurrentStatus, params);
    yield put(getCurrentStatusSuccess(data))
  } catch (error) {
    console.error(error);
  }
}

function* getCaseAssets(action: any) {
  const params = action.payload;
  try {
    const data = yield call(API.getCaseAssets, params);
    yield put(getCaseAssetsSuccess(data))
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get sentences list on us.box.case
 * @param action 
 */
function* getSentencesList(action: any) {
  const params = action.payload;
  try {
    const data = yield call(API.getSentencesList, params);
    yield put(getSentencesListSuccess(data))
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get notes list on us.box.case
 * @param action 
 */
function* getNotesList(action: any) {
  const params = action.payload;
  try {
    const data = yield call(API.getNotesList, params);
    yield put(getNotesListSuccess(data))
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get case interest information.
 * @param action 
 */
function* getCaseInterest(action: any) {
  const params = action.payload;
  try {
    const data = yield call(API.getCaseInterest, params);
    yield put(getCaseInterestSuccess(data))
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get case properties detail for the case detil view.
 * @param action 
 */
function* getCasePropDetail(action: any) {
  const params = action.payload;
  try {
    const data = yield call(API.getCasePropDetail, params);
    yield put(getCaseProptiesDetilSuccess(data))
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get Extended Fields on us.box.case.
 * @param action 
 */
// function* getExtendedFields(action: any) {
//   const params = action.payload;
//   try {
//     const data = yield call(API.getExtendedFields, params);
//     yield put(getExtendedFieldSuccess(data))
//   } catch (error) {
//     console.log(error);
//   }
// }

/**
 * Get Enforcements on us.box.case.
 * @param action 
 */
function* getEnforcements(action: any) {
  const params = action.payload;
  try {
    const data = yield call(API.getEnforcements, params);
    yield put(getEnforcementSuccess(data))
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get the user agreement details for the agreement view 
 * @param action action object defined in actions, passed from the redux.lib
 */
function* getUserAgreementDetails(action: any) {
  const params = action.payload;
  try {
    const data = yield call(API.getUserAgreementDetails, params);
    yield put(getUserAgreementDetailsSuccess(data))
  } catch (error) {
    console.log(error);
  }
}

/**
 * this method will help to load the case from url.
 * @param action the routing data (location,caseNo, etc) and followup cases
 */
function* loadCaseFromURLAction(action: any) {

  const caseRegEx = /\/case\/[0-9]+(\/[a-z]*[A-Z]*)?/;

  // check if the URL is of our consernce
  if (caseRegEx.test(action.location)) {

    // get the caseNo from the URL
    let items = action.location.split('/');
    let caseNoFromURL = items[2];
    const parsedCaseNo: number = Number.parseInt(caseNoFromURL);

    if (!isNaN(parsedCaseNo)) {
      // check if the caseNo is undefined or, what is there is not the latest one.
      if (!action.caseNo || action.caseNo <= 0 || action.caseNo !== parsedCaseNo) {

        /**
        * checking if the CaseNo in the url is in the fllowup list
        */
        if (action.followupDetailList.length > 0) {
          if (action.followupDetailList.find((item: any) => item.subcaseno === Number.parseInt(caseNoFromURL))) {
            try {

              yield put(setCurrentCaseNoAction(parsedCaseNo));
            }
            catch (error) {
              console.error(error);
            }
          }
          else {
            /**
             * if the case number in the url is not matching with the provided list of case numbers 
             * returning to the task list.
             */
            yield put(push('/task-list'));
          }
        }
      }
    }
  }
}

/**
 * Get case part payment details.
 * @param action action object defined in actions, passed from redux.lib
 */
function* getCasePartPayments(action: any) {
  const params = action.payload;
  try {
    const data = yield call(API.getPartPaymentDetail, params);
    yield put(getCasePartPaymentsSuccess(data))
  } catch (error) {
    console.log(error);
  }
}

export default [
  takeEvery('GET_CURRENT_STATUS', getCurrentStatus),
  takeEvery('GET_CASE_ASSETS', getCaseAssets),
  takeEvery('GET_SENTENCES_LIST', getSentencesList),
  takeEvery('GET_NOTES_LIST', getNotesList),
  takeEvery('GET_CASE_INTEREST', getCaseInterest),
  takeEvery('GET_CASE_PROP_DETAIL', getCasePropDetail),
  // takeEvery('GET_EXTENDED_FIELD', getExtendedFields),
  takeEvery('GET_ENFORCEMENT', getEnforcements),
  takeEvery('GET_USER_AGREEMENT_DETAILS', getUserAgreementDetails),
  takeEvery('LOAD_CASE_FROM_URL_ACTION', loadCaseFromURLAction),
  takeEvery('GET_CASE_PARTPAYMENTS', getCasePartPayments)
];