import { ICategory } from "us.collection/interfaces";

/**
 * BMD category list
 * @param categories BMD category and group list
 * @returns BMD category list
 */
export const getBMDCategoryList = (categories: any) : ICategory[] => { //
  try {
    const list : ICategory[] = categories.map((item: any) => {
      return {
        id: item.id,
        categoryName: item.name,
      };
    });
    return list;
  } catch {
    return [];
  }
};

/**
 * Add new category for list
 * @param categoryList Previous category list
 * @param id New category id
 * @param categoryName New category name
 * @returns Category list
 */
export const addBMDCategoryToList = (
  categoryList: ICategory[],
  id: number,
  categoryName: string
) => {
  try {
    return [...categoryList, { id, categoryName }];
  } catch {
    return categoryList;
  }
};

