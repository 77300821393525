export enum Payment {
  GET_PAYMENTS = "GET_PAYMENTS",
  GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS",
  GET_PAYMENTS_FAIL = "GET_PAYMENTS_FAIL",
}

export enum Apportionment {
  GET_APPORTIONMENTS = "GET_APPORTIONMENTS",
  GET_APPORTIONMENTS_SUCCESS = "GET_APPORTIONMENTS_SUCCESS",
  GET_APPORTIONMENTS_FAIL = "GET_APPORTIONMENTS_FAIL",
}
