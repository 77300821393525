import { CaseType } from 'us.helper/types/enums';

export const getCurrentStatus = () => ({
  type: 'GET_CURRENT_STATUS'
});

export const getCurrentStatusSuccess = (data: any) => ({
  type: 'GET_CURRENT_STATUS_SUCCESS',
  payload: data
});

export const getCaseAssets = () => ({
  type: 'GET_CASE_ASSETS'
});

export const getCaseAssetsSuccess = (data: any) => ({
  type: 'GET_CASE_ASSETS_SUCCESS',
  payload: data
});

export const getSentencesList = () => ({
  type: 'GET_SENTENCES_LIST'
});

export const getSentencesListSuccess = (data: any) => ({
  type: 'GET_SENTENCES_LIST_SUCCESS',
  payload: data
});

export const getNotesList = () => ({
  type: 'GET_NOTES_LIST'
});

export const getNotesListSuccess = (data: any) => ({
  type: 'GET_NOTES_LIST_SUCCESS',
  payload: data
});

export const getCaseInterest = () => ({
  type: 'GET_CASE_INTEREST'
});

export const getCaseInterestSuccess = (data: any) => ({
  type: 'GET_CASE_INTEREST_SUCCESS',
  payload: data
});

export const getCaseProptiesDetil = () => ({
  type: 'GET_CASE_PROP_DETAIL'
});

export const getCaseProptiesDetilSuccess = (data: any) => ({
  type: 'GET_CASE_PROP_SUCCESS',
  payload: data
});

export const getEnforcement = () => ({
  type: 'GET_ENFORCEMENT'
});

export const getEnforcementSuccess = (data: any) => ({
  type: 'GET_ENFORCEMENT_SUCCESS',
  payload: data
});

export const getUserAgreementDetails = () => ({
  type: 'GET_USER_AGREEMENT_DETAILS'
});

export const getUserAgreementDetailsSuccess = (data: any) => ({
  type: 'GET_USER_AGREEMENT_DETAILS_SUCCESS',
  payload: data
});

export const setCurrentCaseNoAction = (data: number) => ({
  type: 'SET_CURRENT_CASE_NO_ACTION',
  payload: data
});

export const setCurrentCaseTypeAndTransactionTypeAction = (caseType: CaseType, transactionType: string) => ({
  type: 'SET_CURRENT_CASE_TYPE_AND_TRANSACTION_TYPE_ACTION',
  caseType: caseType,
  transactionType: transactionType
});

export const updateCaseNoAction = (data: number) => ({
  type: 'UPDATE_CASE_NO_ACTION',
  payload: data
});

export const loadCaseFromURLAction = (location: any, history: any, caseNo: number, followupDetailList: any) => ({
  type: 'LOAD_CASE_FROM_URL_ACTION',
  location: location,
  history: history,
  caseNo: caseNo,
  followupDetailList: followupDetailList
});

export const getCasePartPayments = () => ({
  type: 'GET_CASE_PARTPAYMENTS'
});

export const getCasePartPaymentsSuccess = (data: any) => ({
  type: 'GET_CASE_PARTPAYMENTS_SUCCESS',
  payload: data
});

export const setSelectedCaseNo = (selectedCase: any) => ({
  type: 'SET_SELECTED_CASE',
  payload: selectedCase
});
