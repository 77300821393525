import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from "service.config.js";
import { ICase } from "us.collection.transactions/interfaces";

export const CasesAndSubCases = {
  get: async (
    creditorId: number,
    debtorId: number,
    arId: number,
    isSimilarDebtor: boolean,
    returnTransferredSubCases: boolean
  ): Promise<ICase[]> => {
    return new Promise<ICase[]>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.get(
          "arservice",
          `ars/cases/${creditorId}/${debtorId}/${arId}/${isSimilarDebtor}`,
          { returnTransferredSubCases: returnTransferredSubCases },
          ServiceConfig()[`arservice`]["cases.list"]
        );
        if (status === 200) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  merge: async (params: any): Promise<any> => {
    const { arNo, destinationCaseNo, ...rest } = params;
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.patch(
          "arservice",
          `ars/${arNo}/cases/${destinationCaseNo}`,
          [
            {
              op: "add",
              path: "/sourceCases",
              ...rest /* case id list */,
            },
          ],
          ServiceConfig()[`arservice`]["cases.merge"]
        );
        if ([201, 200].includes(status)) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  create: async (params: any): Promise<any> => {
    const { arNo, ...rest } = params;
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.post(
          "arservice",
          `ars/${arNo}/cases`,
          [...rest.sourceCases],
          ServiceConfig()[`arservice`]["cases.create"]
        );
        if (status === 200 || status === 201) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  updateduedate: async (params: any): Promise<any> => {
    const { arNo, caseNo, ...rest } = params;
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.patch(
          "arservice",
          `ars/${arNo}/cases/${caseNo}`,
          [
            {
              op: "add",
              path: "/duedate",
              value: {
                duedate: rest.duedate,
                caseNote: rest.caseNote,
              },
            },
          ],
          ServiceConfig()[`arservice`]["cases.updateDueDate"]
        );
        if (status === 200 || status === 201) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  split: async (params: any): Promise<any> => {
    const { arNo, caseNo, ...rest } = params;
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.patch(
          "arservice",
          `ars/${arNo}/cases/${caseNo}`,
          [
            {
              op: "remove",
              path: "/subcases",
              value: rest.subcases,
            },
          ],
          ServiceConfig()[`arservice`]["cases.split"]
        );
        if ([200, 201, 409].includes(status)) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
};
