import { matchPath } from "react-router-dom";
import { IMetaData } from 'us.collection/interfaces';
import { URLType, URLTypeId } from 'us.collection/constants'
import { FollowUpEntityType, FollowUpUniqueCol } from "../Constants";

/**
 * @function
 * @description get entity detail
 * @param {string} pathName - URL
 * @returns {entity:string; entityId: string}
 */
export const getEntityDataFromURL = (
    pathName: string
): { entity: string; entityId: number } => {
    try {
        const { params }: any = matchPath(pathName, {
            path: "/:entity/:entityId?/:module?",
            exact: false,
            strict: false,
        });
        return {
            entity: params["entity"],
            entityId: Number(params["entityId"]),
        };
    } catch (error) {
        return { entity: "", entityId: 0 };
    }
};

/**
 * @function
 * @description get displayName
 * @param {Array<IFollowup>} followups -followup list
 * @param {string} followupId -followup Id that is in query params
 * @returns {string} followup name
 */
export const getDisplayName = (
    followups: Array<any>,
    followupId: string
): string => {
    try {
        return followups.find(
            (followup: any) => followup.followupId.toString() == followupId
        )?.followupDisplayName ?? "";
    } catch (error) {
        return "";
    }
};

/**
 * @function
 * @description get EntityId 
 * @param {string} pathname - URL 
 * @param {IMetaDta} metaData - metaData
 * @returns {number} entityId
 */
export const getEntityId = (pathname: string, metaData: IMetaData,): number => {
    const { entity, entityId } = getEntityDataFromURL(pathname)
    const {
        caseId,
        arId,
        creditorGroupId,
    } = metaData;

    try {
        switch (entity.toLowerCase()) {
            case URLType.CASE:
                return caseId;
            case URLType.AR:
                return arId;
            case URLType.CREDITOR:
                return entityId;
            case URLType.CREDITOR_GROUP:
                return creditorGroupId;
            case URLType.PAYMENT:
                return entityId;
            case URLType.BUREAU:
                return 0;
            default:
                return 0;
        }

    } catch (error) {
        return 0
    }
}

/**
 * @function
 * @description get entity type
 * @param {string} entityType entity type according to next result response 
 * @returns { entityType: string, metaDataType: string }
 */
export const getEntityType = (entityType: string): { entityType: string, metaDataType: string } => {
    try {
        switch (entityType.toLowerCase()) {
            case URLType.CASE:
            case URLType.INVOICE:    
                return {
                    entityType: URLType.CASE,
                    metaDataType: URLTypeId.EXCASENO
                }
            case URLType.AR:
            case URLType.DEBTOR:
                return {
                    entityType: URLType.AR,
                    metaDataType: URLTypeId.AR
                }
            case URLType.CREDITOR:
                return {
                    entityType: URLType.CREDITOR,
                    metaDataType: URLTypeId.PID
                }
            case URLType.PAYMENT:
                return {
                    entityType: URLType.PAYMENT,
                    metaDataType: ''
                }
            default:
                return {
                    entityType: URLType.PAYMENT,
                    metaDataType: ''
                }
        }

    } catch (error) {
        return {
            entityType: '',
            metaDataType: ''
        }
    }
}

/**
 * @description - Get find linked column with respect to the entityType
 * @param entityType - entityType
 * @returns  final array of possible columns to linked.
 */
export const getLinkedColumnsForEntity = (
    entityType: string
  ): string => {
    switch (entityType) {
      case FollowUpEntityType.CASE:
      case FollowUpEntityType.INVOICE:
        return FollowUpUniqueCol.EX_CASE_NO;
      case FollowUpEntityType.CREDITOR:
        return FollowUpUniqueCol.CREDITOR_NO;
      case FollowUpEntityType.DEBTOR:
        return FollowUpUniqueCol.AR_NO;
      case FollowUpEntityType.PAYMENT:
        return FollowUpUniqueCol.PAYMENT_ID;
      default:
        return "";
    }
  };

  
  /**
   * @function
   * @description get next result
   * @param {any} entityIndex current entity index
   * @param {Array<any>} tableData table data 
   * @param {string} entityType current entity type
   * @returns {number}   
   */
  export const getNextEntity = (entityIndex:any,tableData:Array<any>, entityType:string):number => {
    try{
      if(typeof entityIndex !== 'undefined' && tableData?.length >0){
        const nextEntityIndex = entityIndex == (tableData.length -1) ? 0: entityIndex + 1;
        const linkedEntityName = getLinkedColumnsForEntity(entityType)
        return tableData[nextEntityIndex][linkedEntityName];
      } else{
        return 0
      }
    }catch(error){
       return 0
    }
  };