import { IAPIAction } from "us.collection/interfaces";
import { IInterest } from 'us.collection.case/interfaces'
import { Interest } from 'us.collection.case/constants/Actions';

export const interestAction: Readonly<IInterest & IAPIAction> = {
    interest: {
        get: (data) => ({
            type: Interest.GET_INTEREST_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: Interest.GET_INTEREST_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: Interest.GET_INTEREST_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        setFilter: (data) => ({
            type: Interest.SET_INTEREST_FILTER,
            payload: {
                data,
                isLoading: true
            }
        })
    },
    summaryInterest: {
        update: (data) => ({
            type: Interest.GET_SUMMARY_INTEREST_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: Interest.GET_SUMMARY_INTEREST_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: Interest.GET_SUMMARY_INTEREST_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    }
}