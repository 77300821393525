import { httpCollection, versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from 'service.config.js';

export default {
  extendedFields: {
    get: <P extends { extendedType: string; fieldId: string }>(
      params: P
    ): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "extendeddataservice",
            "extendedfields",
            params,
            ServiceConfig()[`extendeddataservice`]['extendeddata.extendedfields']
          );
          resolve({data, status});
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
