import React, { useEffect } from 'react';
import {
	matchPath,
	RouteComponentProps,
	useHistory,
	withRouter,
} from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Formik } from 'formik';
import Common from 'us.common';
import { Link } from 'react-router-dom';
import { IntlCurrency } from 'us.common/functions';
import { useTranslation } from 'react-i18next';
import { ApportionmentAction } from 'us.collection.transactions/actions';
import _ from 'lodash';
import { handleGoBack } from 'us.helper/utility';

const { $Tooltip, $PageHeader, $Affix, $Skeleton, $TableTree, $AmountLabel } =
	Common.Components;
import { INavigationData } from 'us.collection/interfaces';
import {
	IApportionment,
	IApportionmentModel,
} from 'us.collection.transactions/reducers/PaymentDistrubution/Interface/IApportionment';
import { IFormikInitValue } from './Interface/IFormikInitValue';
import { IApportionmentModelFormik } from './Interface/IApportionmentFormik';
import ExcelExporter from 'us.collection/components/ExcelGenerator';
import { IExcleColumn } from 'us.collection/components/ExcelGenerator/Interface';
import { getPaymentId } from './Function';
import { domainViewAction } from 'us.collection/actions';
import { RootState } from 'us.helper/types';
import { URLTypeId } from 'us.collection/constants';
const { metaData } = domainViewAction;

const PaymentDistribution: React.FC<PropsFromRedux & RouteComponentProps> = (
	props
) => {
	const { t } = useTranslation(['US.COLLECTION.TRANSACTIONS'], {
		useSuspense: true,
	});
	const history = useHistory();
	const { location } = history;
	let formikInitialValues: IFormikInitValue;

	const {
		locationState,
		currentCurrency,
		currentDateFormat,
		currentLanguage,
		apportionments,
		getApportionments,
		getMetaData,
	} = props;

	const state = location.state as INavigationData;
	const { refreshCount = 0, currentTab } = state ?? {};

	const { params }: any = matchPath(window.location.pathname, {
		path: '/:type/:id?/:module?',
		exact: false,
		strict: false,
	});

	useEffect(() => {
		if (getApportionments && state && currentTab === 'payment')
			getApportionments({
				paymentId: getPaymentId(locationState),
			});
	}, [refreshCount]);

	useEffect(() => {
		formikInitialValues = {
			apportionments: GetFormikApportionments({
				...apportionments,
			}),
			filterValues: {},
		};
	}, [currentLanguage, apportionments]);

	const RouteToCase = (record: IApportionment): any => {
		const { caseId } = record;
		getMetaData &&
			getMetaData({
				id: caseId,
				type: URLTypeId.CASE,
			});
	};

	const columns: any = [
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.CASE_NO'
			),
			dataIndex: 'caseNo',
			key: 'caseNo',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
			customRenderChild: (text: any, record: any) => {
				return (
					<Link
						to={{
							pathname: `/case/${record.caseNo}`,
							search: location.search,
						}}
						onClick={() =>
							RouteToCase(record)
						}>
						{text > 0 ? text : ''}
					</Link>
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.TRANS_TYPE'
			),
			dataIndex: 'transType',
			key: 'transType',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: true,
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.INVOICE_NO'
			),
			dataIndex: 'invoiceNo',
			key: 'invoiceNo',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
		},
		{
			title: () => (
				<React.Fragment key='exceededSum'>
					<div>
						{t(
							'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.EXCEED_SUM'
						)}
					</div>
					<div className='header-custom-amount'>
						{GetTotal('exceedAmount')}
					</div>
				</React.Fragment>
			),
			dataIndex: 'exceedAmount',
			key: 'exceedAmount',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return (
					<$AmountLabel
						value={record?.exceedAmount}
					/>
				);
			},
		},
		{
			title: () => (
				<>
					<div>
						{t(
							'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.CREDITOR_SUM'
						)}
					</div>
					<div className='header-custom-amount'>
						{GetTotal('creditorAmount')}
					</div>
				</>
			),
			dataIndex: 'creditorAmount',
			key: 'creditorAmount',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return (
					<$AmountLabel
						value={record?.creditorAmount}
					/>
				);
			},
		},
		{
			title: () => (
				<>
					<div>
						{t(
							'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.BUREAU_SUM'
						)}
					</div>
					<div className='header-custom-amount'>
						{GetTotal('bureauAmount')}
					</div>
				</>
			),
			dataIndex: 'bureauAmount',
			key: 'bureauAmount',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return (
					<$AmountLabel
						value={record?.bureauAmount}
					/>
				);
			},
		},
		{
			title: () => (
				<>
					<div>
						{t(
							'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.DEBTOR_SUM'
						)}
					</div>
					<div className='header-custom-amount'>
						{GetTotal('debtorAmount')}
					</div>
				</>
			),
			dataIndex: 'debtorAmount',
			key: 'debtorAmount',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return (
					<$AmountLabel
						value={record?.debtorAmount}
					/>
				);
			},
		},
		{
			title: () => (
				<>
					<div>
						{t(
							'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.OTHER_SUM'
						)}
					</div>
					<div className='header-custom-amount'>
						{GetTotal('otherAmount')}
					</div>
				</>
			),
			dataIndex: 'otherAmount',
			key: 'otherAmount',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return (
					<$AmountLabel
						value={record?.otherAmount}
					/>
				);
			},
		},
		{
			title: () => (
				<>
					<div>
						{t(
							'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.VAT_BASIS'
						)}
					</div>
					<div className='header-custom-amount'>
						{GetTotal('vatBasis')}
					</div>
				</>
			),
			dataIndex: 'vatBasis',
			key: 'vatBasis',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return (
					<$AmountLabel
						value={record?.vatBasis}
					/>
				);
			},
		},
		{
			title: () => (
				<>
					<div>
						{t(
							'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.VAT_AMOUNT'
						)}
					</div>
					<div className='header-custom-amount'>
						{GetTotal('vatAmount')}
					</div>
				</>
			),
			dataIndex: 'vatAmount',
			key: 'vatAmount',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return (
					<$AmountLabel
						value={record?.vatAmount}
					/>
				);
			},
		},
		{
			title: () => (
				<>
					<div>
						{t(
							'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.VAT_PAID_BY_DEBTOR'
						)}
					</div>
					<div className='header-custom-amount'>
						{GetTotal('vatPaidByDebtor')}
					</div>
				</>
			),
			dataIndex: 'vatPaidByDebtor',
			key: 'vatPaidByDebtor',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return (
					<$AmountLabel
						value={record?.vatPaidByDebtor}
					/>
				);
			},
		},
		{
			title: () => (
				<>
					<div>
						{t(
							'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.VAT_DEDUCTED'
						)}
					</div>
					<div className='header-custom-amount'>
						{GetTotal('vatDeducted')}
					</div>
				</>
			),
			dataIndex: 'vatDeducted',
			key: 'vatDeducted',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return (
					<$AmountLabel
						value={record?.vatDeducted}
					/>
				);
			},
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.VAT_CODE'
			),
			dataIndex: 'vatCode',
			key: 'vatCode',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: true,
		},
		{
			title: () => (
				<>
					<div>
						{t(
							'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.TOTAL_AMOUNT'
						)}
					</div>
					<div className='header-custom-amount'>
						{GetTotal('amount')}
					</div>
				</>
			),
			dataIndex: 'amount',
			key: 'amount',
			align: 'right',
			className: 'right-has-sort right-has-filter text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: 'amount',
			customRenderChild: (text: any, record: any) => {
				return <$AmountLabel value={record?.amount} />;
			},
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.REMIT_VOUCHER_NO'
			),
			dataIndex: 'remitVoucherNo',
			key: 'remitVoucherNo',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.JOURNAL_ID'
			),
			dataIndex: 'journalId',
			key: 'journalId',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
		},
	];

	const GetContent = (params: any) => {
		if (!params.state.error) {
			return params.children;
		} else {
			return (
				<div className='space-content'>
					<h3>
						{t(
							'US.COMMON:COMMON.OOOPS_SOMETHING_IS_NOT_RIGHT'
						)}
					</h3>
				</div>
			);
		}
	};

	const GetFormikApportionments = (
		apportions: IApportionmentModel
	): IApportionmentModelFormik => {
		return {
			isLoading: apportions.isLoading,
			error: apportions.error,
			data: (apportions.data as any[]).map(
				(ops: IApportionment, index: number) => {
					return {
						key: index,
						amount: ops.amount,
						bureauAmount: ops.bureauAmount,
						caseId: ops.caseId,
						caseNo: ops.caseNo,
						creditorAmount:
							ops.creditorAmount,
						debtorAmount: ops.debtorAmount,
						exceedAmount: ops.exceedAmount,
						invoiceNo: ops.invoiceNo,
						journalId: ops.journalId,
						otherAmount: ops.otherAmount,
						remitVoucherNo:
							ops.remitVoucherNo,
						transType: ops.transType,
						caseType: ops.caseType,
						vatAmount: ops.vatAmount,
						vatBasis: ops.vatBasis,
						vatCode:
							ops.vatCode &&
							ops.vatCode > 0
								? ops.vatCode
								: 'N/A',
						vatDeducted: ops.vatDeducted,
						vatPaidByDebtor:
							ops.vatPaidByDebtor,
					};
				}
			),
		} as IApportionmentModelFormik;
	};

	formikInitialValues = {
		apportionments: GetFormikApportionments({ ...apportionments }),
		filterValues: {},
	};

	const GetNumberValue = (value: any): number => {
		const parsedValue = Number.parseFloat(value.toString());
		if (Number.isNaN(parsedValue)) {
			return 0;
		} else {
			return parsedValue;
		}
	};

	const GetTotal = (RowKey: keyof IApportionment): string => {
		const totalValue: number = (apportionments.data as any[])
			.map((x) => {
				if (typeof x[RowKey] === 'number') {
					return x[RowKey] as number;
				} else {
					return 0;
				}
			})
			.reduce(
				(
					accumilator: number,
					currentValue: number
				): number => {
					return (
						GetNumberValue(accumilator) +
						GetNumberValue(currentValue)
					);
				},
				0
			);
		return IntlCurrency(
			totalValue,
			currentLanguage,
			currentCurrency
		);
	};

	const excleColumn: Array<IExcleColumn> = [
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.CASE_NO'
			),
			dataIndex: 'caseNo',
			format: 'Text',
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.TRANS_TYPE'
			),
			dataIndex: 'transType',
			format: 'Text',
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.INVOICE_NO'
			),
			dataIndex: 'invoiceNo',
			format: 'Text',
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.EXCEED_SUM'
			),
			dataIndex: 'exceedAmount',
			format: 'Currency',
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.CREDITOR_SUM'
			),
			dataIndex: 'creditorAmount',
			format: 'Currency',
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.BUREAU_SUM'
			),
			dataIndex: 'bureauAmount',
			format: 'Currency',
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.DEBTOR_SUM'
			),
			dataIndex: 'debtorAmount',
			format: 'Currency',
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.OTHER_SUM'
			),
			dataIndex: 'otherAmount',
			format: 'Currency',
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.VAT_BASIS'
			),
			dataIndex: 'vatBasis',
			format: 'Currency',
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.VAT_AMOUNT'
			),
			dataIndex: 'vatAmount',
			format: 'Currency',
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.VAT_PAID_BY_DEBTOR'
			),
			dataIndex: 'vatPaidByDebtor',
			format: 'Currency',
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.VAT_DEDUCTED'
			),
			dataIndex: 'vatDeducted',
			format: 'Currency',
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.VAT_CODE'
			),
			dataIndex: 'vatCode',
			format: 'Text',
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.TOTAL_AMOUNT'
			),
			dataIndex: 'amount',
			format: 'Currency',
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.REMIT_VOUCHER_NO'
			),
			dataIndex: 'remitVoucherNo',
			format: 'Text',
		},
		{
			title: t(
				'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.JOURNAL_ID'
			),
			dataIndex: 'journalId',
			format: 'Text',
		},
	];

	return (
		<Formik
			enableReinitialize
			initialValues={formikInitialValues}
			onSubmit={(values: any, actions: any) => {}}>
			{({
				values,
				setValues,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				...rest
			}: any) => (
				<>
					<div className='space-content'>
						<$Affix offsetTop={80}>
							<div className='page-header header-border'>
								<div className='d-flex flex-row align-items-center justify-content-between'>
									<div className='d-flex align-items-center'>
										{params[
											'module'
										] ===
											'distribution' && (
											<$PageHeader
												className='px-0'
												title={t(
													'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.PAYMENTDISTRIBUTION'
												)}
												onBack={() =>
													handleGoBack(
														history
													)
												}
											/>
										)}
										{!params[
											'module'
										] && (
											<$PageHeader
												className='px-0'
												title={t(
													'US.COLLECTION.TRANSACTIONS:PAYMENTDISTRIBUTION.PAYMENTDISTRIBUTION'
												)}
											/>
										)}
									</div>
									<div className='ml-auto'>
										<$Tooltip
											placement='topLeft'
											title={t(
												'US.COLLECTION.COMMON:COMMON.EXPORTTOEXCELSHEET'
											)}>
											<ExcelExporter
												dataSet={
													apportionments.data
												}
												columns={
													excleColumn
												}
												name={`PaymentDistribution_${getPaymentId(
													locationState
												)}`}
												format={{
													currentDateFormat,
													currentCurrency,
													currentLanguage,
												}}
											/>
										</$Tooltip>
									</div>
								</div>
							</div>
						</$Affix>

						<GetContent
							state={apportionments}>
							<$Skeleton
								loading={
									values
										.apportionments
										.isLoading
								}
								active
								paragraph={{
									rows: 2,
								}}>
								<$TableTree
									rowKey='key'
									data={
										values
											.apportionments
											.data
									}
									size='small'
									className='mt-3 header-custom-tag'
									onSort={(
										sortData,
										dataSource
									) =>
										sortData(
											dataSource
										)
									}
									onFilter={(
										searchData,
										dataSource
									) =>
										searchData(
											dataSource
										)
									}
									filterOnType={
										true
									}
									resetOnSourceChange={
										true
									}
									bordered
									pagination={{
										defaultPageSize: 15,
									}}
									columns={
										columns
									}
									scroll={{
										x: 1200,
									}}
									firstColSkipFilterProps={
										-1
									}
								/>
							</$Skeleton>
						</GetContent>
					</div>
				</>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { router, common, paymentDistrubution } = state;
	const { location } = router;
	const { pathname } = location;
	const { currentCurrency, currentLanguage, currentDateFormat } = common;
	const { payment, apportionments } = paymentDistrubution;
	return {
		payment,
		apportionments,
		locationState: pathname,
		currentCurrency,
		currentLanguage,
		currentDateFormat,
	};
};

const mapDispatchToProps = {
	getApportionments: ApportionmentAction.Apportionments.get,
	getMetaData: metaData.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(PaymentDistribution));
