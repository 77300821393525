import { ArTransactionAction } from './TransactionType';

export const defaultArTransactionDrawerSettings = {
	visible: false,
	title: '',
	type: ArTransactionAction.NONE,
	width: '60%',
	isNewTransaction: true,
	isDeleteTransaction: false,
	filters: null,
};
