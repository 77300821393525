import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Transactions from "us.collection.transactions";
import { ToggleClass } from 'us.helper';
import { connect } from 'react-redux';

interface ICnt_ApplicableCourts extends RouteComponentProps {
    caseNo: number;
}

const Cnt_ApplicableCourts: React.FC<ICnt_ApplicableCourts> = (props) => {

    ToggleClass('change-body-color-dom');

    const onMinimize = (url: string) => {
        props.history.push(url);
    }

    return (
        <Transactions.Components.ApplicableCourts onMinimize={() => onMinimize(`/case/${props.caseNo}`)} {...props} />

    )
}

// const mapStateToProps = (state: any) => {
//     return {
//         caseNo: state.case.caseNo
//     };
// };

// export default connect<{}, {},any>(mapStateToProps)(withRouter(Cnt_Sentences));
export default withRouter(Cnt_ApplicableCourts);
