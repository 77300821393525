import { initialState } from "us.collection/reducers/Transactions/State";
import {
  TransType
} from "us.collection/constants";
import moment from "moment";


const {
  GET_TRANS_TYPE_STRAT,
  GET_TRANS_TYPE_FAIL,
  GET_TRANS_TYPE_SUCCESS,
} = TransType;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    
    case GET_TRANS_TYPE_STRAT:
      return Object.assign({}, state, {
        transTypes: {
          data: [],
          isLoading: true
        },
      });

    case GET_TRANS_TYPE_SUCCESS:
      return Object.assign({}, state, {
        transTypes: {
          data:payload.data,
          isLoading: false
        },
      });

    case GET_TRANS_TYPE_FAIL:
      return Object.assign({}, state, {
        transTypes: {
          data: [],
          isLoading: false
        },
      });

    default:
      return state;
  }
};
