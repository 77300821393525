import React from "react";
import { useTranslation } from "react-i18next";

const TableColumns = (selectedColumns: Array<string>) => {
  const { t } = useTranslation();
  
  const columns: any = [
    {
      title: t("US.COLLECTION.DEBTOR:SELECT_DEBTOR.DEBTOR"),
      dataIndex: "debtorColoum",
      key: "debtorColoum",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
      width: 250,
      className: "name-column",
      customRenderChild: (text: any, record: any) => {
        return (
          <div className="name-column-wrap">
            <div className="first-row">
              {record.custId}
              {(record.custId && record.debtorName) && " - "}
              {record.debtorName}
            </div>
            <div className="second-row">
              <small>
                {record.address1 && record.address1}
                {record.address1 && ", "}
                {record.address2 && record.address2}
              </small>
            </div>
          </div>
        );
      },
    },
    {
      width: 100,
      title: t("US.COLLECTION.DEBTOR:SELECT_DEBTOR.TYPE"),
      dataIndex: "debtorType",
      key: "debtorType",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
    {
      width: 200,
      title: t("US.COLLECTION.DEBTOR:SELECT_DEBTOR.NIN"),
      dataIndex: "nin",
      key: "nin",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,   
    },
  ];

  const extraColumns = [
    {
      width: 150,
      title: t("US.COLLECTION.COMMON:COMMON.POSTAL_CODE"),
      dataIndex: "zipCode",
      key: "zipCode",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
    {
      width: 150,
      title: t("US.COLLECTION.COMMON:COMMON.POSTAL_AREA"),
      dataIndex: "zipName",
      key: "zipName",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    }
  ].filter(({ dataIndex }: { dataIndex: string }) =>
    selectedColumns.includes(dataIndex)
  );
  return [...columns, ...extraColumns];
};

export default TableColumns;