export default {
	endPoints: {
		info: '/debtor',
		contactCategory: '/DebtorContactCategory',
		addressInfo: '/debtorAddressInfo',
		entHistory: '/debtorEntHistory',
	},
	viewNames: {
		addNewDebtor: 'addNewDebtor',
		selectPostalArea: 'selectPostalArea',
		selectMinicipality: 'selectMinicipality',
		addPostalArea: 'addPostalArea',
		debtContactInfo: 'debtContactInfo',
	},
	debtorFormFields: {
		title: 'title',
		firstName: 'firstName',
		lastName: 'lastName',
		birthDay: 'birthDay',
		companyNumber: 'companyNumber',
		debtorType: 'debtorType',
		email: 'email',
		home: 'home',
		mobile: 'mobile',
		work: 'work',
		sms: 'sms',
		skype: 'skype',
		msn: 'msn',
		postAddress1: 'postAddress1',
		postAddress2: 'postAddress2',
		postAddress3: 'postAddress3',
		postCode: 'postCode',
		postalArea: 'postalArea',
		municipalityCode: 'municipalityCode',
		municipalityName: 'municipalityName',
		country: 'country',
	},
};
