
import { ICreditorCasesSearchRequest } from "us.collection.creditor/interfaces/CreditorCases";
import { httpCollection } from "us.helper/http/collection";

export default {
    searchCreditorCases: (params: ICreditorCasesSearchRequest): Promise<any> => {
        return new Promise<any>(async (resolve, reject) => {
          try {
            const data = await httpCollection.post(
              "searchservice",
              "creditor/cases",
              params
            );
            resolve(data);
          } catch (error) {
            reject(error);
          }
        });
      }
};
