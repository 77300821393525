import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import Common from 'us.common';
import { Note, SMS, Email, Letter, CreditorPortalNote } from './Components';
import { MessageAndNoteAction } from 'us.collection.case/actions';
import { DomainMessageType } from 'us.collection.case/constants/NotesAndMessage';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { RootState } from 'us.helper/types';
import { MESSAGE_FORM_DEFAULT_VALUES } from '../../Constants';
import { CreditorNotesAvailability } from "us.collection/constants";
import './ContentV1.scss';

const { $Tooltip } = Common.Components;

const { template, basicDetail } = MessageAndNoteAction;

interface IContentV1Props {
	notePermissions?: any;
}

/**
 * @description - Dashboard message template component old version(v1)
 * @link Design Document -
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 06/08/2023
 */
const ContentV1: React.FC<IContentV1Props & PropsFromRedux> = (props) => {
	const { t } = useTranslation();

	const { getTemplate, getBasicInfo, notePermissions, metaData, authorizationProfile } = props;

	const { entityType, caseId } = metaData.data ?? {};
	const { enableCreditorPortalNotes } = authorizationProfile ?? {};

	const [messageFormFields, setMessageFormFields] = useState(
		MESSAGE_FORM_DEFAULT_VALUES
	);
	const [radioGroupValue, setRadioGroupValue] = useState(
		DomainMessageType.NOTE
	);



	const onChange = (type: any) => {
		setRadioGroupValue(type);
		if (type != DomainMessageType.NOTE) {
			fetchTemplateData();
		}
		switch (type) {
			case DomainMessageType.NOTE:
				setMessageFormFields({
					isNoteOption: true,
					isCPNoteOption: false,
					isSMSOption: false,
					isEmailOption: false,
					isLetterOption: false,
				});
				break;
			case DomainMessageType.SMS:
				setMessageFormFields({
					isNoteOption: false,
					isSMSOption: true,
					isEmailOption: false,
					isLetterOption: false,
					isCPNoteOption: false
				});
				break;
			case DomainMessageType.EMAIL:
				setMessageFormFields({
					isNoteOption: false,
					isSMSOption: false,
					isEmailOption: true,
					isLetterOption: false,
					isCPNoteOption: false
				});
				break;
			case DomainMessageType.LETTERS:
				setMessageFormFields({
					isNoteOption: false,
					isSMSOption: false,
					isEmailOption: false,
					isLetterOption: true,
					isCPNoteOption: false
				});
				break;
			case DomainMessageType.CP_NOTE:
				setMessageFormFields({
					isNoteOption: false,
					isSMSOption: false,
					isEmailOption: false,
					isLetterOption: false,
					isCPNoteOption: true
				});
				break;
			default:
				break;
		}
	};

	const fetchTemplateData = () => {
		let caseType = '';
		if (entityType == 'S') {
			caseType = 'SubCase';
		} else if (entityType == 'C') {
			caseType = 'Case';
		}
		if (caseId && caseType != '') {
			getTemplate &&
				getTemplate({
					category: 'DomainMessage',
					entityType: caseType,
				});
			getBasicInfo &&
				getBasicInfo({
					entityId: caseId,
					entityType: caseType,
				});
		}
	};

	return (
		<div className='widget notes-and-messages'>
			<div className='widget-header d-flex align-items-center px-0'>
				<span className='widget-title'>
					{t(
						'US.COLLECTION.CASE:NOTESANDMESSAGES.MESSAGE/NOTES'
					)}
				</span>
			</div>
			<div className='widget-body px-0 pb-0'>
				<Common.Components.Radio.Group
					onChange={(e) =>
						onChange(e.target.value)
					}
					defaultValue={DomainMessageType.NOTE}
					optionType='button'
					value={radioGroupValue}
					disabled={
						metaData.data &&
						isSubCaseTransferredToCase(
							metaData.data
						)
					}>
					{notePermissions['note']?.isEnabled && (
						<$Tooltip
							placement='top'
							title={t(
								'US.COLLECTION.CASE:NOTESANDMESSAGES.NOTE'
							)}>
							<Common.Components.Radio.Button
								value={
									DomainMessageType.NOTE
								}
								data-testid='dashboard-notesAndMessages-note'>
								<svg viewBox='0 0 20 20'>
									<path d='M14.6,8.4c0-0.5-0.4-0.9-0.9-0.9H4.5C4,7.6,3.6,8,3.7,8.5c0,0.4,0.4,0.8,0.8,0.8h9.2C14.2,9.3,14.6,8.9,14.6,8.4z M4.5,10.5c-0.5,0-0.9,0.5-0.8,1c0,0.4,0.4,0.8,0.8,0.8h5.6c0.5,0,0.9-0.5,0.8-1c0-0.4-0.4-0.8-0.8-0.8H4.5z M6.6,18h-3c-0.9,0.1-1.7-0.6-1.8-1.5V3.1c0.1-0.9,0.9-1.6,1.8-1.5h11c0.9-0.1,1.7,0.6,1.8,1.5v4.7c0.1,0.5,0.5,0.8,1,0.8c0.4-0.1,0.7-0.4,0.8-0.8V3.1C18,1.2,16.4-0.1,14.6,0h-11C1.8-0.1,0.2,1.2,0,3.1v13.4c0.2,1.8,1.8,3.2,3.6,3.1h3c0.4,0.1,0.8-0.2,0.9-0.6S7.3,18.1,6.8,18C6.7,18,6.7,18,6.6,18L6.6,18z M19.8,9.6c-1.1-1-2.7-1-3.8,0L11,14.5c-0.1,0.1-0.2,0.2-0.2,0.4l-1.1,3.5c-0.1,0.5,0.1,1,0.6,1.1c0.2,0,0.3,0,0.5,0l3.6-1c0.1,0,0.3-0.1,0.4-0.2l4.9-4.9C20.8,12.3,20.8,10.6,19.8,9.6z M13.8,16.9l-1.8,0.5l0.5-1.8l3.3-3.3l1.3,1.3L13.8,16.9z M18.5,12.1l-0.2,0.2L17.1,11l0.2-0.2c0.4-0.3,1-0.3,1.3,0.1C18.8,11.3,18.8,11.8,18.5,12.1L18.5,12.1z M13.7,4.5H4.5C4,4.6,3.6,5,3.7,5.5c0,0.4,0.4,0.8,0.8,0.8h9.2c0.5,0,0.9-0.5,0.8-1C14.5,4.9,14.1,4.6,13.7,4.5z' />
								</svg>
							</Common.Components.Radio.Button>
						</$Tooltip>
					)}
					{notePermissions['sms']?.isEnabled && (
						<$Tooltip
							placement='top'
							title={t(
								'US.COLLECTION.CASE:NOTESANDMESSAGES.SMS'
							)}>
							<Common.Components.Radio.Button
								value={
									DomainMessageType.SMS
								}
								data-testid='dashboard-notesAndMessages-sms'>
								<svg viewBox='0 0 20 20'>
									<path d='M15.8,20l-6.3-5.1H2.2c-1.1,0-2.1-1-2.1-2.1V2.1C0.1,1,1,0,2.2,0H18c1.1,0,2.1,1,2.1,2.1v10.7c0,1.1-0.9,2.1-2.1,2.1h-2.3V20z M2.2,1.2c-0.5,0-0.8,0.4-0.8,0.9v10.7c0,0.5,0.4,0.8,0.8,0.9h7.8l4.6,3.7v-3.7H18c0.5,0,0.8-0.4,0.8-0.9V2.1c0-0.5-0.4-0.8-0.8-0.9L2.2,1.2L2.2,1.2z M3.6,6h2.6v2.6H3.6V6z M8.8,6h2.6v2.6H8.8V6z M13.9,6h2.6v2.6h-2.6V6z' />
								</svg>
							</Common.Components.Radio.Button>
						</$Tooltip>
					)}
					{notePermissions['email']
						?.isEnabled && (
						<$Tooltip
							placement='top'
							title={t(
								'US.COLLECTION.CASE:NOTESANDMESSAGES.EMAIL'
							)}>
							<Common.Components.Radio.Button
								value={
									DomainMessageType.EMAIL
								}
								data-testid='dashboard-notesAndMessages-email'>
								<svg viewBox='0 0 20 20'>
									{' '}
									<path d='M13.371,15.344a9.183,9.183,0,0,1-4.054.8,10.71,10.71,0,0,1-4.123-.768A5.837,5.837,0,0,1,2.35,13a7.215,7.215,0,0,1-.984-3.836A8.4,8.4,0,0,1,2.35,5.177,6.863,6.863,0,0,1,5.12,2.322a7.974,7.974,0,0,1,3.948-1,7.559,7.559,0,0,1,3.458.791,5.6,5.6,0,0,1,2.355,2.109,5.423,5.423,0,0,1,.79,2.836,5.558,5.558,0,0,1-.614,2.522,5.1,5.1,0,0,1-1.667,1.95,2.489,2.489,0,0,1-1.284.554.644.644,0,0,1-.457-.177.559.559,0,0,1-.189-.423q0-.136.277-1.409l1.33-6.1H11.413L11.1,5.39A3.571,3.571,0,0,0,9.844,4.131a3.064,3.064,0,0,0-1.533-.414,3.983,3.983,0,0,0-2.17.727,5.588,5.588,0,0,0-1.87,2.177,6.238,6.238,0,0,0-.753,2.9A4.8,4.8,0,0,0,4,11.672a3.406,3.406,0,0,0,1.26,1.454,3.032,3.032,0,0,0,1.6.473,3.039,3.039,0,0,0,1.491-.414A5.073,5.073,0,0,0,9.7,12.09a2.317,2.317,0,0,0,.175.782,1.237,1.237,0,0,0,.577.546,2.368,2.368,0,0,0,1.03.191,4.938,4.938,0,0,0,3.675-1.836A6.712,6.712,0,0,0,17.037,7.09,6.9,6.9,0,0,0,13.265.923,8.985,8.985,0,0,0,9.151,0,9.23,9.23,0,0,0,4.123,1.309a8.507,8.507,0,0,0-3.2,3.773A10,10,0,0,0,0,9.308a8.908,8.908,0,0,0,.776,3.709,6.913,6.913,0,0,0,1.838,2.4,8.018,8.018,0,0,0,2.95,1.559,13.14,13.14,0,0,0,3.781.518,11.116,11.116,0,0,0,3.8-.577,8.657,8.657,0,0,0,2.724-1.568A6.467,6.467,0,0,0,17.489,13.4H15.818A6.32,6.32,0,0,1,13.371,15.344ZM10.241,9.522a4.751,4.751,0,0,1-.854,1.523,3.9,3.9,0,0,1-1.094.9,2.324,2.324,0,0,1-1.09.3,1.786,1.786,0,0,1-1.376-.691,2.858,2.858,0,0,1-.6-1.936,5.744,5.744,0,0,1,.3-1.718,5.284,5.284,0,0,1,.785-1.591,3.4,3.4,0,0,1,1.011-.95,2.223,2.223,0,0,1,1.113-.3,1.981,1.981,0,0,1,1.519.682,2.74,2.74,0,0,1,.623,1.918A5.426,5.426,0,0,1,10.241,9.522Z' />
								</svg>
							</Common.Components.Radio.Button>
						</$Tooltip>
					)}
					{notePermissions['letter']
						?.isEnabled && (
						<$Tooltip
							placement='top'
							title={t(
								'US.COLLECTION.CASE:NOTESANDMESSAGES.LETTER'
							)}>
							<Common.Components.Radio.Button
								value={
									DomainMessageType.LETTERS
								}
								data-testid='dashboard-notesAndMessages-letter'>
								<svg viewBox='0 0 20 20'>
									<path d='M18.1,14.4H2c-1.1,0-2-0.9-2-2c0,0,0,0,0,0V2c0-1.1,0.9-2,2-2c0,0,0,0,0,0h16c1.1,0,2,0.9,2,2c0,0,0,0,0,0v10.4C20.1,13.5,19.2,14.4,18.1,14.4C18.1,14.4,18.1,14.4,18.1,14.4z M2,1.3C1.7,1.4,1.4,1.7,1.3,2v10.4C1.4,12.7,1.7,13,2,13h16c0.4,0,0.7-0.3,0.7-0.7V2c0-0.4-0.3-0.7-0.7-0.7H2z M10,9.7c-0.2,0-0.3-0.1-0.4-0.2l-9-7.9c-0.3-0.2-0.3-0.7,0-0.9c0.2-0.3,0.7-0.3,0.9,0L10,8.1l8.5-7.5c0.3-0.2,0.7-0.2,0.9,0s0.2,0.7,0,0.9l0,0l-9,7.9C10.4,9.6,10.2,9.7,10,9.7z M0.8,13.8c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.3-0.2-0.7,0-0.9c0,0,0,0,0,0l6.4-6c0.3-0.2,0.7-0.2,0.9,0s0.2,0.7,0,0.9l-6.3,6C1.2,13.7,1,13.8,0.8,13.8z M19.2,13.8c-0.2,0-0.3-0.1-0.4-0.2l-6.4-6c-0.3-0.2-0.3-0.7,0-0.9c0.2-0.3,0.7-0.3,0.9,0l0,0l6.4,6c0.3,0.3,0.3,0.7,0,0.9C19.6,13.7,19.4,13.8,19.2,13.8z' />
								</svg>
							</Common.Components.Radio.Button>
						</$Tooltip>
					)}
					{notePermissions['creditorNote'] && enableCreditorPortalNotes == CreditorNotesAvailability.YES && (
						<$Tooltip
							placement='top'
							title={t(
								'US.COLLECTION.CASE:NOTESANDMESSAGES.CREDITOR_NOTE'
							)}>
							<Common.Components.Radio.Button
								value={
									DomainMessageType.CP_NOTE
								}
								data-testid='dashboard-notesAndMessages-cp-note'>
								<svg viewBox='0 0 20 20'>
									<path d='M7.7,5.3H6V3.6h1.7V5.3z M6,12.9h1.7v-1.7H6V12.9z M7.7,7.3H6V9h1.7V7.3z M12.3,3.6h-1.7v1.7h1.7V3.6z M19.7,10v8.1c0,0.5-0.5,1-1,1h-7.2c-0.5,0-1-0.4-1-1V10c0-0.5,0.5-1,1-1h1.3V8.8c0-0.4,0.3-0.7,0.7-0.7h3.2c0.4,0,0.7,0.3,0.7,0.7V9h1.3C19.2,9,19.7,9.5,19.7,10z M13.7,9.9h2.8V9h-2.8V9.9z M17.8,13.1c0-0.2-0.1-0.4-0.2-0.6L17.1,12c-0.3-0.3-0.8-0.3-1.2,0l-2.7,2.6c-0.1,0.1-0.1,0.1-0.1,0.2l-0.4,1.4c-0.1,0.1,0,0.3,0.1,0.4c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0l1.4-0.4c0.1,0,0.2-0.1,0.2-0.1l2.7-2.6C17.7,13.5,17.8,13.3,17.8,13.1z M10.6,8.3C11.3,8.1,12,8.1,12,8.1c0-0.1,0.1-0.3,0.2-0.4V7.3h-1.7V8.3z M7.7,17.2H4.5V2.7h9.3v3.9v0.9h1.6V6.5V1h-3.3V0h-6V1H2.9v16.2H0.3v1.6h2.6h4.8h1.9c0-0.4,0-1,0-1.6H8.8H7.7z' />
								</svg>
							</Common.Components.Radio.Button>
						</$Tooltip>
					)}
				</Common.Components.Radio.Group>

				<div>
					{messageFormFields.isNoteOption && (
						<Note
							setNoteView={(e: any) =>
								onChange(e)
							}
						/>
					)}
					{messageFormFields.isSMSOption && (
						<SMS
							setNoteView={(e: any) =>
								onChange(e)
							}
						/>
					)}
					{messageFormFields.isEmailOption && (
						<Email
							setNoteView={(e: any) =>
								onChange(e)
							}
						/>
					)}
					{messageFormFields.isLetterOption && (
						<Letter
							setNoteView={(e: any) =>
								onChange(e)
							}
						/>
					)}
					{messageFormFields.isCPNoteOption && (
						<CreditorPortalNote />
					)}
				</div>
			</div>
		</div>
	);
};

const mapState = (state: RootState) => {
	const { common, domainView, AuthorizationProfile } = state;
	const { authorizationProfile } = AuthorizationProfile;
	const { currentDateFormat } = common;
	const { metaData } = domainView;
	return {
		authorizationProfile,
		currentDateFormat,
		metaData,
	};
};

const mapDispatch = {
	getTemplate: template.get,
	getBasicInfo: basicDetail.get,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ContentV1);
