export interface IFollowUpItem {
	followupId: number;
	followupName: string;
	followupDisplayName: string;
	followupType: string;
	caseCount: number;
	pinStatus: boolean;
}

export enum FollowUpPinStatus {
	PIN = 'Pin',
	UNPIN = 'UnPin',
	ALL = 'All',
}

export type Followup = {
	followupId: number;
	followupName: string;
	followupDisplayName: string;
	followupType: string;
	caseCount: number;
	pinStatus: boolean;
	description: string;
	entityType: string;
	customQuery: string;
	createdUser: string;
	createdDate: string;
};
