import React, { useEffect, useContext, useMemo, useCallback } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter, matchPath } from 'react-router-dom';
import {
	AccountSummeryWidgetActions,
	CaseInfoWidgetActions,
	DebtorWidgetActions,
} from 'us.collection.case/actions';
import * as Actions from 'us.collection/actions';
import { MainSearchActions } from 'us.common/actions';
import { ConfigurationContext } from 'us.common/ConfigurationContext/ConfigurationProvider';
import { IDomainVewLayout, INavigationData } from 'us.collection/interfaces';
import {
	URLType,
	URLTypeId,
	initialEntityDrawer,
	DirectForm,
} from 'us.collection/constants';
import './style.scss';
import { $Affix, $Button, $Drawer, $Tooltip, $Empty } from 'us.common/components';
import { appInsights } from 'us.helper';
import { EntityFollowUps, FollowupFooter } from 'us.collection/components';
import { EntityFollowUpPanel, EntityTypes } from 'us.collection.case/constants';
import { parse, stringify } from 'query-string';
import { CloseOutlined } from 'us.icons';
import { RootState } from 'us.helper/types';
import { domainViewAction } from 'us.collection/actions';
import { followUps as followUpActions } from 'us.collection.followup/actions';
import { manualFollowUpActions } from 'us.collection.case/actions';
import { CaseType } from 'us.helper/types/enums';
import { historyAction } from 'us.collection.routines-and-activities/actions';

const { activityAction, followUps, followupFooterActions } = Actions;
const { activity } = activityAction;
const { accountSummery } = AccountSummeryWidgetActions;
const { entity } = followUps;
const { followUpNavigation } = followUpActions;
const { previousResult, followupBackButtonVisibility } = followupFooterActions;

const DomainView: React.FC<IDomainVewLayout & PropsFromRedux> = (props) => {
	const { t } = useTranslation(['US.COLLECTION.LAYOUT']);
	const context = useContext(ConfigurationContext);

	const {
		metaData,
		location,
		history,
		drawer,
		backNavigation,
		caseViewData,
		followUpNavigation,
		isActive,
		authorizationProfile,
		creditorGroupDetails,
		resetActivity,
		getMetaData,
		getAccountSummery,
		manageEntityFollowUpDrawer,
		setNavigation,
		getCaseId,
		getWorkflowStatus,
		setFollowupBackButtonVisibility,
		setPreviousResult,
		updateNavigationDetails,
		getFollowUpsSummary,
		getLogHistory,
		getCaseInformation,
		getDebtorInformation,
	} = props;

	const {
		arId,
		pid,
		creditorGroupId,
		entityType,
		caseNo,
		parentCaseNo,
		creditorId,
		parentCaseId,
	} = metaData.data ?? {};

	const { pathname } = location;
	const state = location.state as INavigationData;
	const { currentTab, subCaseId, caseType } = state ?? {};
	const previousSearchParams = parse(location.search);
	const search = stringify(previousSearchParams);
	const { caseId, caseType: caseViewType } = caseViewData.data ?? {};
	const queryFollowupId = previousSearchParams['id'];
	let timer: any; // timer for single and double click event handler

	const params: { type: string; id: string; module: string } =
		useMemo(() => {
			const { params }: any = matchPath(
				props.location.pathname,
				{
					path: '/:type/:id?/:module?',
					exact: false,
					strict: false,
				}
			);
			return {
				type: params['type'] ?? '',
				id: params['id'] ?? '',
				module: params['module'] ?? '',
			};
		}, [props.location.pathname]);

	useEffect(() => {
		if (state) {
			if (checkURLType(URLType.CASE)) {
				if (caseId > 0) {
					if (metaData.data.caseId > 0) {
						if (
							currentTab ==
								URLType.SUBCASE &&
							caseType ==
								CaseType.C &&
							subCaseId
						) {
							fetchInitialDataForCase(
								`${subCaseId}`,
								URLType.SUBCASE
							);
						} else if (
							currentTab ==
								URLType.CASE &&
							caseType ==
								CaseType.S &&
							parentCaseId
						) {
							fetchInitialDataForCase(
								`${parentCaseId}`,
								URLType.CASE
							);
						} else if (
							(currentTab ==
								URLType.CASE &&
								caseType ==
									CaseType.C &&
								metaData.data
									.caseId !=
									state.id) ||
							(currentTab ==
								URLType.SUBCASE &&
								caseType ==
									CaseType.S &&
								metaData.data
									.caseId !=
									state.id)
						) {
							fetchInitialDataForCase(
								`${state.id}`,
								currentTab
							);
						}
					} else {
						fetchInitialDataForCase(
							`${caseId}`,
							currentTab as URLType
						);
					}
				} else {
					initCase(params.id);
				}
			} else {
				if (arId == 0 || !arId) {
					const { type, id } =
						setMetaDataResponse();
					if (id != 0 && type.length > 0) {
						getMetaData &&
							getMetaData({
								id,
								type,
							});
					}
				}
			}
		} else {
			if (checkURLType(URLType.CASE)) {
				if (
					caseId > 0 &&
					params.id == caseViewData.data?.caseNo
				) {
					fetchInitialDataForCase(
						`${caseId}`,
						caseViewType
					);
				} else {
					initCase(params.id);
				}
			} else {
				initLayout();
			}
		}
	}, [params.id, currentTab, caseId]);

	/**
	 * This will update the current location state according to the metadata response
	 */
	useEffect(() => {
		if (
			metaData.data &&
			entityType?.length > 0 &&
			!metaData.isLoading
		) {
			updateRouteHistory();

			const {
				caseId,
				entityType,
				creditorEntNumber,
				debtorId,
				debtorEntNumber,
			} = metaData.data ?? {};
			if (
				entityType &&
				(entityType == CaseType.C ||
					entityType == CaseType.S) &&
				caseId &&
				caseId > 0
			) {
				getDebtorInformation &&
					getDebtorInformation({
						otherPartyBelongsType:
							entityType,
						otherPartyEntityId: caseId,
						creditorEntNo:
							creditorEntNumber,
						debtorEntRoleId: debtorId,
						caseId: caseId,
						CaseNo: caseNo,
						DebtorEntNo: debtorEntNumber,
						CaseType:
							entityType ===
							CaseType.S
								? 'Subcase'
								: 'Case',
					});
			}
		}
	}, [metaData.data]);

	const initLayout = () => {
		if (checkURLType(URLType.CASE)) {
			initCase(params.id);
		} else {
			if (checkURLType(URLType.PAYMENT)) {
				history.replace(
					{ pathname, search },
					{
						...state,
						currentTab: URLType.PAYMENT,
						originate: URLType.PAYMENT,
						accessTabs: setAccessTabs(
							URLType.PAYMENT
						),
						creditorId: -1,
						refreshCount: 0,
					}
				);
			} else if (checkURLType(URLType.BUREAU)) {
				history.replace(
					{ pathname, search },
					{
						...state,
						currentTab: URLType.BUREAU,
						originate: URLType.BUREAU,
						accessTabs: setAccessTabs(
							URLType.BUREAU
						),
						creditorId: -1,
						refreshCount: 0,
					}
				);
			} else if (checkURLType(URLType.CREDITOR_GROUP)) {
				history.replace(
					{ pathname, search },
					{
						...state,
						currentTab: URLType.CREDITORGROUP,
						originate: URLType.CREDITORGROUP,
						accessTabs: setAccessTabs(
							URLType.CREDITORGROUP
						),
						creditorId,
						refreshCount: 0,
					}
				);
			} else {
				const { type, id } = setMetaDataResponse();
				if (id != 0 && type.length > 0) {
					getMetaData &&
						getMetaData({
							id,
							type,
						});
				}
			}
		}
	};

	/**
	 * @description - The function `updateRouteHistory` updates the route history based on the URL type and sets the
	 * state accordingly.
	 */
	const updateRouteHistory = () => {
		try {
			const { entityType, caseNo, creditorId, caseId } =
				metaData.data ?? {};
			if (checkURLType(URLType.CASE)) {
				if (entityType?.length > 0) {
					if (entityType === CaseType.S) {
						if (parentCaseId) {
							history.replace(
								{
									pathname,
									search,
								},
								{
									...state,
									currentTab: URLType.SUBCASE,
									originate: URLType.SUBCASE,
									accessTabs: setAccessTabs(
										URLType.SUBCASE,
										true
									),
									refreshCount: 0,
									creditorId,
									subCaseId: caseId,
									caseNo,
									caseType: entityType,
									id: caseId,
								}
							);
						} else {
							history.replace(
								{
									pathname,
									search,
								},
								{
									...state,
									currentTab: URLType.SUBCASE,
									originate: URLType.SUBCASE,
									accessTabs: setAccessTabs(
										URLType.SUBCASE,
										false
									),
									refreshCount: 0,
									creditorId,
									caseNo,
									caseType: entityType,
									id: caseId,
								}
							);
						}
					} else {
						history.replace(
							{ pathname, search },
							{
								...state,
								currentTab: URLType.CASE,
								originate: URLType.CASE,
								accessTabs: setAccessTabs(
									URLType.CASE
								),
								creditorId,
								refreshCount: 0,
								caseNo,
								caseType: entityType,
								id: caseId,
							}
						);
					}
				}
			} else if (checkURLType(URLType.AR)) {
				history.replace(
					{ pathname, search },
					{
						...state,
						currentTab: URLType.AR,
						originate: URLType.AR,
						accessTabs: setAccessTabs(
							URLType.AR
						),
						creditorId,
						refreshCount: 0,
					}
				);
			} else if (checkURLType(URLType.CREDITOR_GROUP)) {
				history.replace(
					{ pathname, search },
					{
						...state,
						currentTab: URLType.CREDITORGROUP,
						originate: URLType.CREDITORGROUP,
						accessTabs: setAccessTabs(
							URLType.CREDITORGROUP
						),
						creditorId,
						refreshCount: 0,
					}
				);
			} else if (checkURLType(URLType.CREDITOR)) {
				history.replace(
					{ pathname, search },
					{
						...state,
						currentTab: URLType.CREDITOR,
						originate: URLType.CREDITOR,
						accessTabs: setAccessTabs(
							URLType.CREDITOR
						),
						creditorId,
						refreshCount: 0,
					}
				);
			}
		} catch (error) {
			console.error(
				'Domain View - updateRouteHistory failed => ',
				error
			);
		}
	};

	/**
	 *
	 *@param {string} caseNo
	 */
	const initCase = useCallback(
		(caseNo: string) => {
			if (caseNo) {
				getCaseId &&
					getCaseId({
						exCaseNo: caseNo,
						authorizationProfile,
					});
			}
		},
		[params.id, authorizationProfile]
	);

	/**
	 * @description - This function takes a `caseId` parameter and performs three asynchronous actions using the
       `getFollowUpsSummary`, `getMetaData`, and `getWorkflowStatus` functions. These actions are performed
        with the provided `caseId` and the results are fetched.
	 *@param {string} caseId
	 */
	const fetchInitialDataForCase = useCallback(
		(caseId: string, caseType: URLType) => {
			if (caseId) {
				getMetaData &&
					getMetaData({
						id: caseId,
						type: URLTypeId.CASE,
					});
				getWorkflowStatus &&
					getWorkflowStatus({
						caseId: caseId,
					});
				getAccountSummery &&
					getAccountSummery({
						EntityType:
							caseType == URLType.CASE
								? EntityTypes.CASE
								: EntityTypes.SUBCASE,
						EntityId: caseId,
					});
				getCaseInformation &&
					getCaseInformation({
						caseId: caseId,
					});
				getFollowUpsSummary &&
					getFollowUpsSummary({
						caseId: caseId,
					});
			}
		},
		[caseId, parentCaseId, subCaseId]
	);

	/**
	 * @description Set tabs that user can access.
	 * @param currentTab current tab
	 * @param isMerged whether case is merge or not
	 * @returns tabs array
	 */
	const setAccessTabs = (
		currentTab: string,
		isMerged?: boolean
	): Array<string> => {
		try {
			switch (currentTab) {
				case URLType.SUBCASE:
					if (isMerged) {
						return [
							'case',
							'ar',
							'creditor',
							'creditorgroup',
							'bureau',
						];
					} else {
						return [
							'ar',
							'creditor',
							'creditorgroup',
							'bureau',
						];
					}
				case URLType.CASE:
					return [
						'ar',
						'creditor',
						'creditorgroup',
						'bureau',
					];
				case URLType.AR:
					return [
						'creditor',
						'creditorgroup',
						'bureau',
					];
				case URLType.CREDITOR:
					return ['creditorgroup', 'bureau'];
				case URLType.CREDITORGROUP:
					return ['bureau'];
				case URLType.BUREAU:
					return [];
				default:
					return [];
			}
		} catch (error) {
			appInsights.trackException(
				`MetaData Set Access Tab Execption - ${error}`
			);
			return [];
		}
	};

	/**
	 * @description The function `checkURLType` checks if the `type` parameter in the URL matches the given `URLType`
	 * and returns a boolean value.
	 * @param {URLType} type - The `type` parameter is of type `URLType`. It is used to specify the type of
	 * URL that needs to be checked.
	 * @returns The function `checkURLType` returns a boolean value.
	 */
	const checkURLType = (type: URLType): boolean => {
		try {
			if (params && params.type) {
				return params.type.toLowerCase() == type;
			} else {
				return false;
			}
		} catch (error) {
			return false;
		}
	};

	const setMetaDataResponse = (): {
		id: number | string;
		type: string;
		urlType: string;
	} => {
		try {
			const urlType: string = params?.type.toLowerCase();
			let urlIDAndType: { id: number | string; type: string };
			switch (params?.type.toLowerCase()) {
				case URLType.CASE:
					urlIDAndType = {
						id: caseViewData.data.caseId,
						type: URLTypeId.CASE,
					};
					break;
				case URLType.AR:
					urlIDAndType = {
						id: params.id,
						type: URLTypeId.AR,
					};
					break;
				case URLType.CREDITOR:
					urlIDAndType = {
						id: params.id,
						type: URLTypeId.PID,
					};
					break;
				case URLType.CREDITOR_GROUP:
					urlIDAndType = {
						id: params.id,
						type: '',
					};
					break;
				case URLType.PAYMENT:
					urlIDAndType = {
						id: params.id,
						type: '',
					};
					break;
				default:
					urlIDAndType = { id: 0, type: '' };
					break;
			}
			return { ...urlIDAndType, urlType };
		} catch (error) {
			appInsights.trackException(
				`MetaData Set MetaData Response Execption - ${error}`
			);
			return {
				id: 0,
				type: '',
				urlType: '',
			};
		}
	};

	/**
	 * handle single click event and double click event
	 */
	const handleTabClickEvents = (
		url: string,
		tabName: string,
		event?: any,
		urlParamId?: string | number
	) => {
		try {
			// Prevents React from resetting its properties:
			event?.persist();
			clearTimeout(timer);
			const isTabPermit = tabPermission(tabName);
			if (Number(urlParamId) > 0 && !metaData.isLoading) {
				if (
					event?.detail === 1 &&
					tabName != URLType.PAYMENT
				) {
					timer = setTimeout(() => {
						// handle single click event
						if (
							isTabPermit &&
							metaData.data
						) {
							if (
								tabName ===
								URLType.CASE
							) {
								if (
									parentCaseNo
								) {
									history.push(
										{
											...location,
											pathname: `/case/${parentCaseNo}`,
											state: {
												...state,
												currentTab: tabName,
											},
										}
									);
								} else {
									history.push(
										{
											...location,
											pathname: url,
											state: {
												...state,
												currentTab: tabName,
											},
										}
									);
								}
							} else if (
								tabName ===
								URLType.SUBCASE
							) {
								history.push({
									...location,
									pathname: url,
									state: {
										...state,
										currentTab: tabName,
									},
								});
							} else if (
								tabName ===
								URLType.CREDITOR
							) {
								history.push({
									...location,
									pathname: url,
									state: {
										...state,
										currentTab: tabName,
										creditorId,
									},
								});
							} else {
								history.push({
									...location,
									pathname: url,
									state: {
										...state,
										currentTab: tabName,
									},
								});
							}
							if (
								(currentTab ==
									URLType.SUBCASE &&
									tabName ==
										URLType.SUBCASE) ||
								(currentTab ==
									URLType.CASE &&
									tabName ==
										URLType.CASE)
							) {
								resetActivity &&
									resetActivity(
										{}
									);
								getWorkflowStatus &&
									getWorkflowStatus(
										{
											caseId: metaData
												.data
												.caseId,
										}
									);
								getAccountSummery &&
									getAccountSummery(
										{
											EntityType:
												tabName ==
												URLType.CASE
													? EntityTypes.CASE
													: EntityTypes.SUBCASE,
											EntityId: metaData
												.data
												.caseId,
										}
									);
							}
						}
					}, 400);
				} else if (event?.detail === 2) {
					// handle double click event
					refreshTabs(tabName);
				}
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard tab navigation Execption - ${error}`
			);
		}
	};

	/**
	 * double click action
	 * @param tabName currently click tab name.
	 */
	const refreshTabs = (tabName: string) => {
		try {
			if (tabPermission(tabName) && metaData.data) {
				const { entityType, caseId } =
					metaData.data ?? {};
				if (
					tabName === URLType.CASE ||
					tabName === URLType.SUBCASE
				) {
					if (
						(tabName === URLType.CASE &&
							entityType ===
								CaseType.C) ||
						(tabName === URLType.SUBCASE &&
							entityType ===
								CaseType.S)
					) {
						fetchInitialDataForCase(
							caseId,
							tabName
						);
					} else {
						fetchInitialDataForCase(
							`${subCaseId}`,
							tabName
						);
					}
				} else {
					history.replace(location.pathname, {
						...state,
						refreshCount: Math.random(),
					});
				}
			} else {
				history.replace(location.pathname, {
					...state,
					refreshCount: Math.random(),
				});
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard Refresh Execption - ${error}`
			);
		}
	};

	const tabPermission = (tabName: string): boolean => {
		let isAllowed: boolean = false;
		try {
			if (
				context.componentPermission[tabName]?.tabs[
					tabName
				]?.isEnabled &&
				(state?.accessTabs.includes(tabName) ||
					state?.originate == tabName ||
					state?.currentTab == tabName)
			) {
				isAllowed = true;
			}
		} catch (error) {
			appInsights.trackException(
				`Dashboard Tab Permission Execption - ${error}`
			);
		}
		return isAllowed;
	};

	/**
	 * @description - Handle close button action in the dashboard header to navigate search view
	 */
	const handleGoBackToSearch = () => {
		setNavigation &&
			setNavigation({
				...backNavigation,
				isBack: true,
				canGoBack: false,
			});
		history.push(
			{
				pathname: `/search`,
				search: backNavigation?.searchString,
			},
			{
				...state,
			}
		);
	};

	/**
	 * @function
	 * @description Navigation back to followup result
	 */
	const backToFollowupListNavigation = () => {
		try {
			updateNavigationDetails &&
				updateNavigationDetails({
					...followUpNavigation,
					isBack: true,
					hit: 0,
				});
			setPreviousResult && setPreviousResult([]);
			setFollowupBackButtonVisibility &&
				setFollowupBackButtonVisibility({
					isActive: false,
				});
			queryFollowupId &&
				history.push(`/follow-up/${queryFollowupId}`);
		} catch (error) {}
	};

	return (
		<>
			<$Affix offsetTop={48}>
				<div className='dom-nav'>
					<div
						onClick={(e: any) =>
							handleTabClickEvents(
								`/payment/${
									setMetaDataResponse()
										.id
								}/distribution`,
								URLType.PAYMENT,
								e,
								setMetaDataResponse()
									.id
							)
						}
						className={`dom-nav-item ${
							state?.currentTab ===
							URLType.PAYMENT
								? 'selected'
								: ''
						}`}
						data-testid='payment-tab'>
						{t(
							'US.COLLECTION.LAYOUT:DOMAINVIEW.PAYMENT'
						)}
					</div>
					<div
						onClick={(e: any) =>
							handleTabClickEvents(
								`/case/${caseNo}`,
								URLType.SUBCASE,
								e,
								caseNo
							)
						}
						className={`dom-nav-item ${
							state?.currentTab ===
							URLType.SUBCASE
								? 'selected'
								: ''
						}`}
						data-testid='subcase-tab'>
						{t(
							'US.COLLECTION.LAYOUT:DOMAINVIEW.SUBCASE'
						)}
					</div>
					<div
						onClick={(e: any) =>
							handleTabClickEvents(
								`/case/${caseNo}`,
								URLType.CASE,
								e,
								caseNo
							)
						}
						className={`dom-nav-item ${
							state?.currentTab ===
							URLType.CASE
								? 'selected'
								: ''
						}`}
						data-testid='case-tab'>
						{t(
							'US.COLLECTION.LAYOUT:DOMAINVIEW.CASE'
						)}
					</div>
					<div
						onClick={(e: any) =>
							handleTabClickEvents(
								`/ar/${arId}`,
								URLType.AR,
								e,
								arId
							)
						}
						className={`dom-nav-item ${
							state?.currentTab ===
							URLType.AR
								? 'selected'
								: ''
						}`}
						data-testid='ar-tab'>
						{t(
							'US.COLLECTION.LAYOUT:DOMAINVIEW.AR'
						)}
					</div>
					<div
						onClick={(e: any) =>
							handleTabClickEvents(
								`/creditor/${pid}`,
								URLType.CREDITOR,
								e,
								pid
							)
						}
						className={`dom-nav-item ${
							state?.currentTab ===
							URLType.CREDITOR
								? 'selected'
								: ''
						}`}
						data-testid='creditor-tab'>
						{t(
							'US.COLLECTION.LAYOUT:DOMAINVIEW.CREDITOR'
						)}
					</div>
					<div
						onClick={(e: any) => {
							const { id: cGroupId } =
								creditorGroupDetails.data ??
								{};
							const id =
								creditorGroupId >
								0
									? creditorGroupId
									: cGroupId;
							handleTabClickEvents(
								`/creditor-group/${id}`,
								URLType.CREDITORGROUP,
								e,
								id
							);
						}}
						className={`dom-nav-item ${
							state?.currentTab ===
							URLType.CREDITORGROUP
								? 'selected'
								: ''
						}`}
						data-testid='creditorGroup-tab'>
						{t(
							'US.COLLECTION.LAYOUT:DOMAINVIEW.CREDITORGROUP'
						)}
					</div>
					<div
						onClick={(e: any) =>
							handleTabClickEvents(
								'/bureau',
								URLType.BUREAU,
								e,
								1
							)
						}
						className={`dom-nav-item dom-nav-bureau ${
							state?.currentTab ===
							URLType.BUREAU
								? 'selected'
								: ''
						}`}
						data-testid='bureau-tab'>
						{t(
							'US.COLLECTION.LAYOUT:DOMAINVIEW.BUREAU'
						)}
					</div>
					{backNavigation.canGoBack && (
						<div className='dom-nav-close'>
							<$Tooltip
								title={t(
									'US.COLLECTION.COMMON:COMMON.CLOSE'
								)}
								placement='topLeft'>
								<$Button
									data-testid='dashboard-close-btn'
									className='dom-nav-close-btn'
									icon={
										<CloseOutlined />
									}
									onClick={
										handleGoBackToSearch
									}
								/>
							</$Tooltip>
						</div>
					)}
					{isActive && !backNavigation.canGoBack && (
						<div className='dom-nav-close'>
							<$Tooltip
								title={t(
									'US.COMMON:FOLLOWUP_FOOTER.BACK_TO_LIST'
								)}
								placement='topLeft'>
								<$Button
									data-testid='dashboard-close-btn'
									className='dom-nav-close-btn'
									icon={
										<CloseOutlined />
									}
									onClick={
										backToFollowupListNavigation
									}
								/>
							</$Tooltip>
						</div>
					)}
				</div>
			</$Affix>
			<main className='dom-bottom-space'>
				{!caseViewData.isLoading &&
					checkURLType(URLType.CASE) &&
					!caseId && (
						<div className='d-flex flex-column align-items-center' style={{marginTop:'8%'}}>
							<$Empty
								description={t("US.COLLECTION.LAYOUT:DOMAINVIEW.NO_DATA_AVAILABLE_FOR_THE_CASE")}
								image={$Empty.PRESENTED_IMAGE_DEFAULT}
							/>
						</div>
					)}
				{props.children}
			</main>
			{previousSearchParams['redirectFrom'] ===
				DirectForm.FOLLOW_UP &&
				metaData.data && (
					<FollowupFooter
						metaData={metaData.data}
					/>
				)}
			<$Drawer // Entity FollowUp
				title={t(
					'US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.FOLLOWUP'
				)}
				width={1000}
				visible={drawer.visible}
				onClose={() =>
					manageEntityFollowUpDrawer &&
					manageEntityFollowUpDrawer({
						...initialEntityDrawer,
						selectedTab: '',
						manualFollowUp: {
							isNewManualFollowUp:
								false,
							panel: [
								EntityFollowUpPanel.DUE,
							],
						},
					})
				}
				destroyOnClose
				className='followup-detailview'>
				<EntityFollowUps {...props} />
			</$Drawer>
		</>
	);
};

const mapStateToProps = (state: RootState) => {
	const {
		router,
		entityFollowUps,
		mainSearch,
		domainView,
		followupFooter,
		followUps,
		AuthorizationProfile,
		creditorGroup,
	} = state;
	const { location } = router;
	const { pathname } = location;
	const { drawer } = entityFollowUps;
	const { isActive, nextResult } = followupFooter;
	const { followUpNavigation } = followUps;
	const { creditorGroupDetails } = creditorGroup;
	return {
		metaData: domainView.metaData,
		locationState: pathname,
		drawer,
		backNavigation: mainSearch.backNavigation,
		caseViewData: domainView.case,
		isActive,
		nextResult,
		followUpNavigation,
		authorizationProfile: AuthorizationProfile.authorizationProfile,
		creditorGroupDetails,
	};
};

const { all } = MainSearchActions;
const { followUpsSummary } = manualFollowUpActions;
const { logHistory } = historyAction;
const { caseId, metaData, workflowStatus } = domainViewAction;
const { caseInformation } = CaseInfoWidgetActions;
const { debtorWidget } = DebtorWidgetActions;

const mapDispatchToProps = {
	getMetaData: metaData.get,
	resetMetaData: metaData.reset,
	resetActivity: activity.reset,
	getAccountSummery: accountSummery.get,
	manageEntityFollowUpDrawer: entity.manageDrawer,
	setNavigation: all.set,
	getCaseId: caseId.get,
	getWorkflowStatus: workflowStatus.get,
	updateNavigationDetails: followUpNavigation.set,
	setPreviousResult: previousResult.set,
	setFollowupBackButtonVisibility: followupBackButtonVisibility.set,
	getFollowUpsSummary: followUpsSummary.get,
	getLogHistory: logHistory.get,
	getCaseInformation: caseInformation.get,
	getDebtorInformation: debtorWidget.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(DomainView));