import * as Yup from 'yup';

interface CreditorPortalNote extends Yup.MixedSchema {
	note: string;
}

export default () => {
	return Yup.object<CreditorPortalNote>().shape({
		note: Yup.string().required(
			'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'
		),
	});
};
