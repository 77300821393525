import { put, call, takeLatest } from 'redux-saga/effects';
import * as Actions from 'us.collection/actions';
import * as API from 'us.collection/services';
import { Activity } from 'us.collection/constants';
import { $MessageBox } from 'us.common/components';
import _ from 'lodash';
import { appInsights } from 'us.helper';

const { activityAction, domainViewAction } = Actions;
const { metaData } = domainViewAction;

const activitySagas = {
	parameters: {
		get: function* (action: any): any {
			const params = action.payload.data;
			try {
				const data = yield call(
					API.activityService.parameters.get,
					params
				);
				if (
					(data.hasOwnProperty('code') &&
						data?.code === 500) ||
					data?.code === 404
				) {
					yield put(
						activityAction.parameters.fail(
							{}
						)
					);
				} else {
					yield put(
						activityAction.parameters.success(
							data
						)
					);
				}
			} catch (error) {
				yield put(
					activityAction.parameters.fail(
						error as any
					)
				);
				appInsights.trackException(
					`Get Activity Parameters Saga Exception - ${error}`
				);
			}
		},
	},
	activity: {
		execute: function* (action: any): any {
			const params = action.payload.data;
			try {
				const data = yield call(
					API.activityService.activity.execute,
					_.omit(params, [
						'navigateTo',
						'activityName',
					])
				);
				if (data.hasOwnProperty('message')) {
					if (
						data?.message
							?.toLowerCase()
							?.includes('scheduled')
					) {
						$MessageBox(
							'success',
							`US.COLLECTION.COMMON:ACTIVITYEXECUTION.ACTIVITY_SCHEDULED_SUCCESSFULLY`,
							'',
							''
						);
					} else {
						const {
							entityType,
							caseId,
							activityName,
						} = params ?? {};
						if (
							entityType &&
							entityType.toLowerCase() ===
								'subcase' &&
							metaData.get &&
							activityName ===
								'Overfør til inkasso'
						) {
							yield put(
								metaData.get({
									id: caseId,
									type: 'caseid',
								})
							);
						}
						$MessageBox(
							'success',
							`US.COLLECTION.COMMON:ACTIVITYEXECUTION.ACTIVITY_SUCCESSFULLY_EXECUTED`,
							'',
							''
						);
					}
					yield put(
						activityAction.activity.success(
							data
						)
					);
				} else {
					if (data.hasOwnProperty('errors')) {
						$MessageBox(
							'error',
							`US.COLLECTION.COMMON:ACTIVITYEXECUTION.ACTIVITY_EXECUTION_FAILED`,
							'',
							''
						);
					}

					yield put(
						activityAction.activity.fail({})
					);
				}
				yield call(params?.navigateTo, '/history');
			} catch (error) {
				yield put(activityAction.activity.fail({}));
				appInsights.trackException(
					`Activity Execution Saga Exception - ${error}`
				);
			}
		},
	},
	activities: {
		get: function* (action: any): any {
			const params = action.payload.data;
			try {
				const data = yield call(
					API.activityService.activities.get,
					params
				);
				if (
					_.isEmpty(data) ||
					data?.hasOwnProperty('errors')
				) {
					yield put(
						activityAction.activities.fail(
							{}
						)
					);
				} else {
					yield put(
						activityAction.activities.success(
							data
						)
					);
				}
			} catch (error) {
				yield put(
					activityAction.activities.fail(
						error as any
					)
				);
			}
		},
	},
	pendingActivities: {
		execute: function* (action: any): any {
			const params = action.payload.data;
			try {
				const data = yield call(
					API.activityService.pendingActivities
						.execute,
					params
				);
				if (
					data.hasOwnProperty('isError') &&
					!data?.isError
				) {
					yield put(
						activityAction.pendingActivities.success(
							data
						)
					);
					const { entityType, entityId } =
						params ?? {};
					if (
						metaData.get &&
						entityType === 'S'
					) {
						yield put(
							metaData.get({
								id: entityId,
								type: 'caseid',
							})
						);
					}
				} else {
					yield put(
						activityAction.pendingActivities.fail(
							{}
						)
					);
				}
			} catch (error) {
				yield put(
					activityAction.pendingActivities.fail(
						{}
					)
				);
				appInsights.trackException(
					`Execute Pending Activities Saga Exception - ${error}`
				);
			}
		},
	},
};
export default [
	takeLatest(
		Activity.ALL_PENDING_ACTIVITY_EXCUTE_STRAT,
		activitySagas.pendingActivities.execute
	),
];
