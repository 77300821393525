import React from 'react';
import { ToggleClass } from 'us.helper';
import { CaseDocumentsHome } from '../components';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const Cnt_CaseDocuments: React.FC<RouteComponentProps> = (props) => {
	ToggleClass('change-body-color-dom');

	return <CaseDocumentsHome {...props} />;
};

export default withRouter(Cnt_CaseDocuments);
