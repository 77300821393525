import {
  IDashboardMessageContent,
  IDomainMessage,
} from "us.collection.case/reducers/Dashboard/Interfaces";

export const DomainMessage: IDomainMessage = {
  isPaymentInformation: false,
  documentType: "Debtor",
  smsNumber: "",
  dueDate: "",
  template: "",
  templateNote: "",
  caseNote: "",
  emailAddress: "",
  attachmentOptions: [],
  attachment: [],
  isGiroChecked: false,
  documentTypeList: [],
  templateList: [],
  receiverEntityId: 0,
  attachmentPaths: [],
  isGiroDocument: false,
  isPaymentInfoSMS: false,
  messgeType: "",
};

export const DashboardMessageContent: IDashboardMessageContent = {
  queueId: 0,
  statusCode: 0,
  templateId: 0,
  templateName: "",
  templateCategory: "",
  rowFiles: "",
  message: "",
  errorDescription: "",
  messages: [],
  dataItems: {},
  messageType: "",
  fileName: "",
  settings: {},
  documentDetails: "",
  activityId: 0,
  activityName: "",
};
