import React from 'react';
import { connect } from 'react-redux';
import './Settings.scss';
import Common from 'us.common';
import { useTranslation } from 'react-i18next';
import { ISettings } from 'us.collection.creditor/components/Dashboard/Interfaces';
import * as Actions from 'us.collection.creditor/actions';
import { checkValue } from 'us.collection.creditor/functions';
import { BoxIcons, IconTypes } from 'us.icons';
import { RootState } from 'us.helper/types';

const { $Skeleton, $Empty, $Row, $Col } = Common.Components;

const { creditor } = Actions;
const { creditorBMDs } = creditor;
const { get } = creditorBMDs;

const Home: React.FC<ISettings> = (props) => {
	const { t } = useTranslation(['US.COLLECTION.CREDITOR'], {
		useSuspense: true,
	});

	const { creditorBMDs, metaData } = props;
	const { isLoading } = metaData;

	return (
		<div className='widget creditor-settings'>
			<div className='widget-body py-3 px-4'>
				<strong className='d-block mb-3'>
					{t(
						'US.COLLECTION.CREDITOR:SETTINGS.SETTINGS'
					)}
				</strong>

				<$Skeleton
					loading={
						creditorBMDs.isLoading ||
						isLoading
					}
					active
					paragraph={{ rows: 4 }}>
					{checkValue(
						!creditorBMDs.data?.properties,
						'integrations'
					) ||
						checkValue(
							!creditorBMDs.data
								?.properties,
							'InputIntegration'
						) ||
						checkValue(
							!creditorBMDs.data
								?.properties,
							'OutputIntegration'
						) ||
						(checkValue(
							!creditorBMDs.data
								?.properties,
							'RemitFileFormat'
						) && (
							<$Empty
								className='mb-2'
								description={`${t(
									'US.COLLECTION.CREDITOR:SETTINGS.NO_DATA'
								)}`}
								image={
									$Empty.PRESENTED_IMAGE_SIMPLE
								}
							/>
						))}

					<div>
						{checkValue(
							creditorBMDs.data
								?.properties,
							'integrations'
						) && (
							<$Row
								gutter={16}
								className='mb-2'>
								<$Col
									span={
										12
									}
									xxl={{
										span: 12,
									}}
									xl={{
										span: 12,
									}}>
									{t(
										'US.COLLECTION.CREDITOR:SETTINGS.INTEGRATION'
									)}
								</$Col>
								<$Col
									span={
										12
									}
									xxl={{
										span: 12,
									}}
									xl={{
										span: 12,
									}}>
									<div className='d-flex'>
										<div className='mr-2'>
											<BoxIcons
												className='creditor-integration-icon'
												type={
													IconTypes.BOX_ICON
												}
												name='creditor-integration'
											/>
										</div>
										<strong>
											{
												creditorBMDs
													.data
													?.properties
													?.integrations
											}
										</strong>
									</div>
								</$Col>
							</$Row>
						)}

						{checkValue(
							creditorBMDs.data
								?.properties,
							'InputIntegration'
						) && (
							<$Row
								gutter={16}
								className='mb-2'>
								<$Col
									span={
										12
									}
									xxl={{
										span: 12,
									}}
									xl={{
										span: 12,
									}}>
									{t(
										'US.COLLECTION.CREDITOR:SETTINGS.INPUT_INTEGRATION'
									)}
								</$Col>
								<$Col
									span={
										12
									}
									xxl={{
										span: 12,
									}}
									xl={{
										span: 12,
									}}>
									<div className='d-flex'>
										<div className='mr-2'>
											<BoxIcons
												className='creditor-integration-icon'
												type={
													IconTypes.BOX_ICON
												}
												name='creditor-integration'
											/>
										</div>
										<strong>
											{
												creditorBMDs
													.data
													?.properties
													?.InputIntegration
											}
										</strong>
									</div>
								</$Col>
							</$Row>
						)}

						{checkValue(
							creditorBMDs.data
								?.properties,
							'OutputIntegration'
						) && (
							<$Row
								gutter={16}
								className='mb-2'>
								<$Col
									span={
										12
									}
									xxl={{
										span: 12,
									}}
									xl={{
										span: 12,
									}}>
									{t(
										'US.COLLECTION.CREDITOR:SETTINGS.OUTPUT_INTEGRATION'
									)}
								</$Col>
								<$Col
									span={
										12
									}
									xxl={{
										span: 12,
									}}
									xl={{
										span: 12,
									}}>
									<div className='d-flex'>
										<div className='mr-2'>
											<BoxIcons
												className='creditor-integration-icon'
												type={
													IconTypes.BOX_ICON
												}
												name='creditor-integration'
											/>
										</div>
										<strong>
											{
												creditorBMDs
													.data
													?.properties
													?.OutputIntegration
											}
										</strong>
									</div>
								</$Col>
							</$Row>
						)}

						{checkValue(
							creditorBMDs.data
								?.properties,
							'RemitFileFormat'
						) && (
							<$Row
								gutter={16}
								className='mb-2'>
								<$Col
									span={
										12
									}
									xxl={{
										span: 12,
									}}
									xl={{
										span: 12,
									}}>
									{t(
										'US.COLLECTION.CREDITOR:SETTINGS.REMIT_FILE_FORMAT'
									)}
								</$Col>
								<$Col
									span={
										12
									}
									xxl={{
										span: 12,
									}}
									xl={{
										span: 12,
									}}>
									<div className='d-flex'>
										<div className='mr-2'>
											<BoxIcons
												className='creditor-file-icon'
												type={
													IconTypes.BOX_ICON
												}
												name='creditor-file'
											/>
										</div>
										<strong>
											{
												creditorBMDs
													.data
													?.properties
													?.RemitFileFormat
											}
										</strong>
									</div>
								</$Col>
							</$Row>
						)}
					</div>
				</$Skeleton>
			</div>
		</div>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, creditor, domainView } = state;
	const { currentLanguage, currentDateFormat } = common;
	const { creditorBMDs } = creditor;
	const { metaData } = domainView;
	return {
		currentLanguage,
		currentDateFormat,
		creditorBMDs,
		metaData,
	};
};

const mapDispatchToProps = {
	get,
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
