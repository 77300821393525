import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { INavigationData } from 'us.collection/interfaces';
import { BoxIcons, IconTypes, DownOutlined } from 'us.icons';
import Common from 'us.common';
import { useTranslation } from 'react-i18next';
import { IOtherContact } from 'us.collection.creditor/components/Dashboard/Interfaces';
import * as Actions from 'us.collection.creditor/actions';
import { checkValue } from 'us.collection.creditor/functions';
import './OtherContact.scss';
import { RootState } from 'us.helper/types';

const { $Skeleton, $Collapse, $Row, $Col, $Tooltip } = Common.Components;

const { creditor } = Actions;
const { otherContact } = creditor;
const { get } = otherContact;

const Home: React.FC<IOtherContact> = (props) => {
	const { t } = useTranslation(['US.COLLECTION.CREDITOR'], {
		useSuspense: true,
	});

	const { get, otherContacts, location, metaData } = props;
	const { isLoading } = metaData;

	const state = location.state as INavigationData;
	const { refreshCount = 0, currentTab, creditorId } = state ?? {};

	useEffect(() => {
		if (
			state &&
			currentTab === 'creditor' &&
			!otherContacts.isLoading
		) {
			if (creditorId) {
				get({ creditorId });
			}
		}
	}, [creditorId, refreshCount]);

	return (
		<>
			{otherContacts.data?.length != 0 && (
				<div className='widget mt-4 creditor-other-contact-info'>
					<div className='widget-body py-3 px-3'>
						<strong className='d-block mb-2'>
							{t(
								'US.COLLECTION.CREDITOR:OTHERCONTACTINFO.OTHER_CONTACT'
							)}
						</strong>
						<$Skeleton
							loading={
								otherContacts?.isLoading ||
								isLoading
							}
							active
							paragraph={{ rows: 1 }}>
							<div className='mx-n3'>
								<$Collapse
									ghost
									defaultActiveKey={[]}
									expandIcon={({
										isActive,
									}) => (
										<DownOutlined
											rotate={
												isActive
													? 180
													: 0
											}
										/>
									)}
									expandIconPosition='right'>
									{otherContacts.data?.map(
										(
											item: any,
											index: number
										) => (
											<$Collapse.Panel
												header={
													item.fullName
												}
												key={
													index +
													1
												}>
												<div className='dom-amt-list-table contact-info'>
													{checkValue(
														item,
														'email'
													) && (
														<div className='list-table-row'>
															<div
																className='list-table-col'
																style={{
																	width: '26px',
																}}>
																<$Tooltip
																	placement='top'
																	title={t(
																		'US.COLLECTION.CREDITOR:INFO.EMAIL'
																	)}>
																	<BoxIcons
																		className='creditor-email-icon'
																		type={
																			IconTypes.BOX_ICON
																		}
																		name='email-fill'
																	/>
																</$Tooltip>
															</div>
															<div className='list-table-col'>
																<div>
																	{
																		item.email
																	}
																</div>
															</div>
														</div>
													)}
													{checkValue(
														item,
														'msn'
													) && (
														<div className='list-table-row'>
															<div
																className='list-table-col'
																style={{
																	width: '26px',
																}}>
																<$Tooltip
																	placement='top'
																	title={t(
																		'US.COLLECTION.CREDITOR:INFO.MSN'
																	)}>
																	<BoxIcons
																		className='creditor-msn-icon'
																		type={
																			IconTypes.BOX_ICON
																		}
																		name='msn'
																	/>
																</$Tooltip>
															</div>
															<div className='list-table-col'>
																<div>
																	{
																		item.msn
																	}
																</div>
															</div>
														</div>
													)}
												</div>
												<div className='dom-amt-list-table contact-info'>
													<$Row>
														{checkValue(
															item,
															'homePhoneNumber'
														) && (
															<$Col
																span={
																	12
																}>
																<div className='list-table-row'>
																	<div
																		className='list-table-col'
																		style={{
																			width: '26px',
																		}}>
																		<$Tooltip
																			placement='top'
																			title={t(
																				'US.COLLECTION.CREDITOR:INFO.HOME'
																			)}>
																			<BoxIcons
																				type={
																					IconTypes.BOX_ICON
																				}
																				name='phone-home'
																			/>
																		</$Tooltip>
																	</div>
																	<div className='list-table-col'>
																		<div>
																			{
																				item.homePhoneNumber
																			}
																		</div>
																	</div>
																</div>
															</$Col>
														)}
														{checkValue(
															item,
															'smsNumber'
														) && (
															<$Col
																span={
																	12
																}>
																<div
																	className='list-table-col'
																	style={{
																		width: '26px',
																	}}>
																	<$Tooltip
																		placement='top'
																		title={t(
																			'US.COLLECTION.CREDITOR:INFO.SMS'
																		)}>
																		<BoxIcons
																			type={
																				IconTypes.BOX_ICON
																			}
																			name='phone-sms'
																		/>
																	</$Tooltip>
																</div>
																<div className='list-table-col'>
																	<div>
																		{
																			item.smsNumber
																		}
																	</div>
																</div>
															</$Col>
														)}
														{checkValue(
															item,
															'mobileNumber'
														) && (
															<$Col
																span={
																	12
																}>
																<div
																	className='list-table-col'
																	style={{
																		width: '26px',
																	}}>
																	<$Tooltip
																		placement='top'
																		title={t(
																			'US.COLLECTION.CREDITOR:INFO.MOBILE'
																		)}>
																		<BoxIcons
																			type={
																				IconTypes.BOX_ICON
																			}
																			name='phone-mobile'
																		/>
																	</$Tooltip>
																</div>
																<div className='list-table-col'>
																	<div>
																		{
																			item.mobileNumber
																		}
																	</div>
																</div>
															</$Col>
														)}
														{checkValue(
															item,
															'fax'
														) && (
															<$Col
																span={
																	12
																}>
																<div
																	className='list-table-col'
																	style={{
																		width: '26px',
																	}}>
																	<$Tooltip
																		placement='top'
																		title={t(
																			'US.COLLECTION.CREDITOR:INFO.FAX'
																		)}>
																		<BoxIcons
																			type={
																				IconTypes.BOX_ICON
																			}
																			name='phone-fax'
																		/>
																	</$Tooltip>
																</div>
																<div className='list-table-col'>
																	<div>
																		{
																			item.fax
																		}
																	</div>
																</div>
															</$Col>
														)}
														{checkValue(
															item,
															'workfPhoneNumber'
														) && (
															<$Col
																span={
																	12
																}>
																<div
																	className='list-table-col'
																	style={{
																		width: '26px',
																	}}>
																	<$Tooltip
																		placement='top'
																		title={t(
																			'US.COLLECTION.CREDITOR:INFO.WORK'
																		)}>
																		<BoxIcons
																			type={
																				IconTypes.BOX_ICON
																			}
																			name='phone-work'
																		/>
																	</$Tooltip>
																</div>
																<div className='list-table-col'>
																	<div>
																		{
																			item.workfPhoneNumber
																		}
																	</div>
																</div>
															</$Col>
														)}
														{checkValue(
															item,
															'skype'
														) && (
															<$Col
																span={
																	12
																}>
																<div
																	className='list-table-col'
																	style={{
																		width: '26px',
																	}}>
																	<$Tooltip
																		placement='top'
																		title={t(
																			'US.COLLECTION.CREDITOR:INFO.SKYPE'
																		)}>
																		<BoxIcons
																			className='creditor-skype-icon'
																			type={
																				IconTypes.BOX_ICON
																			}
																			name='skype'
																		/>
																	</$Tooltip>
																</div>
																<div className='list-table-col'>
																	<div>
																		{
																			item.skype
																		}
																	</div>
																</div>
															</$Col>
														)}
													</$Row>
												</div>
											</$Collapse.Panel>
										)
									)}
								</$Collapse>
							</div>
						</$Skeleton>
					</div>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state: RootState) => {
	const { common, creditor, domainView } = state;
	const { currentLanguage, currentDateFormat } = common;
	const { otherContacts } = creditor;
	const { metaData } = domainView;
	return {
		currentLanguage,
		currentDateFormat,
		otherContacts,
		metaData,
	};
};
const mapDispatchToProps = {
	get,
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
