import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
	$Affix,
	$Search,
	$Menu,
	$Tag,
	$Tooltip,
	$Popover,
	$PageHeader,
	$Divider,
	$Button,
} from 'us.common/components';
import { PlusOutlined, MoreOutlined, ReloadOutlined } from 'us.icons';
import './Home.scss';
import { BMDActions } from 'us.collection/actions';
import {
	IBMDCategory,
	INavigationData,
	IProperty,
} from 'us.collection/interfaces';
import { connect, ConnectedProps } from 'react-redux';
import { $Drawer, $Skeleton, ITableTreeColumns } from 'us.common/components';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import {
	isCorrectRoute,
	getEntityNumberAndType,
	getDataFiltered,
} from './Functions';
import { IBMDListFrom } from './Interfaces';
import {
	BMD_TABLE_COLUMNS,
	KEY_NAMES,
	TABLE_ACTIONS,
	DEFAULT_GROUP,
} from './Constants';
import { BMDChangeCount, SearchView, TableAction } from './Components';
import { URLType } from 'us.collection/constants/DomainView';
import { getBMDListInitialValues } from './Functions/GetBMDListInitialValues';
import { AddProperty } from './Components/AddProperty';
import { DrawerTypes } from 'us.collection/constants';
import * as CreditorActions from 'us.collection.creditor/actions';
import { BMDGroup } from './Components/AddBMDGroup';
import { isSubCaseTransferredToCase } from 'us.common/functions';
import { ManageDataType } from './Components/ManageDataType';
import { ALL_CATEGORY } from 'us.collection/constants';
import { RootState } from 'us.helper/types';

/**
 * @description - The component used in home view of BMD List
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2855272453/UI+BMD+-+List+Summery+View
 * @param {PropsFromRedux} props
 * @return {JSX.Element}
 * @author Darshana Waasala <waasalajb@unicorn-solutions.com>
 * @since 16/03/2022
 */
const BmdList: React.FC<PropsFromRedux> = (props) => {
	const { t } = useTranslation([
		'US.COLLECTION.CASE',
		'US.COLLECTION.COMMON',
	]);

	const { push, location } = useHistory();
	const { state, pathname } = useLocation();

	const {
		metaData,
		bmdListData,
		bmdListLoading,
		drawerStatus,
		bmdGroupDrawer,
		bmdGroupInitial,
		bmdGroupData,
		getBMDList,
		changeDrawerStatus,
		openGroupDrawer,
	} = props;

	const { caseId } = metaData.data ?? {};
	const { recordData } = drawerStatus ?? {};
	const {
		bmdGroupId = 0,
		bmdCategoryId = 0,
		bmdSearch = '',
		initialPropertyCheck = false,
	} = recordData ?? {};

	const [editGroupContent, setEditGroupContent] =
		useState(bmdGroupInitial);
	const [filteredCategories, setFilteredCategories] = useState<
		IBMDCategory[]
	>([]);
	const { params }: any = matchPath(pathname, {
		path: '/:type/:id?/:module?',
		exact: false,
		strict: false,
	});

	const locationState = state as INavigationData;
	const { refreshCount = 0 } = locationState ?? {};

	useEffect(() => {
		if (isCorrectRoute(metaData.data?.caseNo, params['type'])) {
			getBMDList &&
				getBMDList(
					getEntityNumberAndType(
						params['type'],
						params['id'],
						caseId?.toString() ?? ''
					)
				);
			changeDrawerStatus &&
				changeDrawerStatus({
					isVisible: false,
					title: '',
					drawerType: DrawerTypes.EDIT_BMD,
					recordData: {},
				});
		}
	}, [caseId, refreshCount]);

	useEffect(() => {
		if (bmdListData?.categories?.length > 0) {
			setFilteredCategories(
				getDataFiltered(
					getBMDListInitialValues(
						bmdListData?.categories ?? [],
						bmdGroupId,
						bmdCategoryId,
						bmdSearch,
						initialPropertyCheck
					),
					bmdListData
				)?.categories
			);
		}
	}, [bmdListData]);

	/**
	 * @function
	 * @description Close Add/Edit bmd group drawer
	 * @returns void
	 */
	const onClose = () => {
		openGroupDrawer &&
			openGroupDrawer({
				title: '',
				isVisible: false,
				isEdit: false,
			});
	};

	/**
	 * @function
	 * @description Add bmd group button click
	 * @returns - void
	 */
	const handleAddBmdGroup = () => {
		openGroupDrawer &&
			openGroupDrawer({
				title: t('US.COLLECTION.CASE:BMD.NEW_CATEGORY'),
				isVisible: true,
				isEdit: false,
			});
	};

	/**
	 * @function
	 * @description Edit bmf group button click
	 * @param values edit group details
	 * @returns Void
	 */
	const handleEditBMDGroup = (values: any) => {
		const { bmdGroups, id: categoryId } = values.selectedCategory;
		const category = bmdGroups.find(
			(item: any) => item.name === values.bmdGroup
		);
		const { id, name, description } = category;
		setEditGroupContent({
			categoryId,
			groupId: id,
			name,
			newCategoryName: '',
			description,
		});
		openGroupDrawer &&
			openGroupDrawer({
				title: t(
					'US.COLLECTION.CASE:BMD.EDIT_CATEGORY'
				),
				isVisible: true,
				isEdit: true,
			});
	};

	const getCorrectDataIndex = (
		dataIndex: keyof IProperty | 'more'
	): string => {
		switch (dataIndex) {
			case 'arCount':
				return 'AR';
			case 'caseCount':
				return 'CA';
			case 'creditorCount':
				return 'CR';
			case 'groupCount':
				return 'CG';
			default:
				return '';
		}
	};

	const handleCountClick = (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		record: IProperty,
		dataIndex: keyof IProperty | 'more',
		{
			selectedCategory,
			bmdSearch,
			initialPropertyCheck,
			bmdGroup,
		}: IBMDListFrom
	) => {
		e.preventDefault();
		changeDrawerStatus &&
			changeDrawerStatus({
				isVisible: true,
				title: 'US.COLLECTION.CASE:BMD.CHANGE_COUNT',
				drawerType: DrawerTypes.BMD_COUNT,
				recordData: {
					...record,
					...getEntityNumberAndType(
						params['type'],
						params['id'],
						caseId?.toString() ?? ''
					),
					dataIndex: getCorrectDataIndex(
						dataIndex
					),
					dataIndexId: record.id,
					bmdCategoryId: selectedCategory?.id,
					bmdSearch,
					initialPropertyCheck,
				},
			});
	};

	const column = (values: IBMDListFrom): ITableTreeColumns =>
		BMD_TABLE_COLUMNS.filter(({ dataIndex, visibility }) =>
			visibility.includes(params['type'])
		).map(({ title, dataIndex, width, ellipsis, className }) => ({
			title: title
				? t(`US.COLLECTION.CASE:BMD.${title}`)
				: '',
			dataIndex: dataIndex,
			key: dataIndex,
			className: className,
			ellipsis,
			...(dataIndex !== 'more'
				? {
						customSorter: (
							a: any,
							b: any
						) => a?.localeCompare(b),
						customFilter: true,
				  }
				: {}),
			...(width ? { width } : {}),
			...(dataIndex === 'displayName' || dataIndex === 'value'
				? {
						customRenderChild: (
							text: any,
							record: IProperty,
							index: number
						) => (
							<$Tooltip
								placement='topLeft'
								title={text}
								key={text}>
								{text}
							</$Tooltip>
						),
				  }
				: {}),
			...(dataIndex === 'more'
				? {
						customRenderChild: (
							text: any,
							record: IProperty,
							index: number
						) => (
							<$Popover
								placement='rightTop'
								content={
									<TableAction
										clickEvent={(
											e
										) => {
											if (
												metaData.data &&
												!isSubCaseTransferredToCase(
													metaData.data
												)
											) {
												changeDrawerStatus &&
													changeDrawerStatus(
														{
															isVisible: true,
															title: 'US.COLLECTION.CASE:BMD.EDIT_BMD',
															drawerType: DrawerTypes.EDIT_BMD,
															recordData: {
																...record,
																bmdGroupId: values.selectedCategory?.bmdGroups.find(
																	(
																		bmdGroup
																	) =>
																		bmdGroup.name ===
																		values.bmdGroup
																)
																	?.id,
																...getEntityNumberAndType(
																	params[
																		'type'
																	],
																	params[
																		'id'
																	],
																	caseId?.toString() ??
																		''
																),
																bmdCategoryId:
																	values
																		.selectedCategory
																		?.id,
																bmdSearch: values.bmdSearch,
																initialPropertyCheck:
																	values.initialPropertyCheck,
															},
														}
													);
											}
										}}
										actions={[
											{
												action: TABLE_ACTIONS.EDIT,
												isPopupDisabled:
													true,
												popupMessage:
													'',
											},
										]}
									/>
								}
								destroyTooltipOnHide
								trigger='hover'>
								<$Button
									icon={
										<MoreOutlined />
									}
									size='small'
									className='ml-n4'
									disabled={
										metaData.data &&
										isSubCaseTransferredToCase(
											metaData.data
										)
									}
								/>
							</$Popover>
						),
				  }
				: {}),
			...(dataIndex === 'keyName'
				? {
						customRenderChild: (
							text: any,
							record: any,
							index: number
						) => (
							<>
								<span>
									{(
										KEY_NAMES.filter(
											(
												keyName
											) =>
												keyName.overrideLevel ===
												record.overrideLevel
										) ??
										[]
									).map(
										({
											toolTip,
											className,
											displayName,
										}) => (
											<$Tooltip
												placement='topLeft'
												title={t(
													`US.COLLECTION.COMMON:COMMON.${toolTip}`
												)}
												key={
													displayName
												}>
												<$Tag
													className={`mr-2 tag-bmd-${className}`}>
													{t(
														`US.COLLECTION.COMMON:COMMON.${displayName}`
													)}
												</$Tag>
											</$Tooltip>
										)
									)}
								</span>
								<$Tooltip
									placement='topLeft'
									title={
										text
									}
									key={
										text
									}>
									{text}
								</$Tooltip>
							</>
						),
				  }
				: {}),
			...(dataIndex === 'description'
				? {
						customRenderChild: (
							text: any,
							record: any,
							index: number
						) => (
							<$Popover
								overlayClassName='history-note-preview'
								placement='left'
								title={t(
									`US.COLLECTION.CASE:BMD.${title}`
								)}
								content={text}
								trigger='hover'>
								{text}
							</$Popover>
						),
				  }
				: {}),
			...([
				'arCount',
				'caseCount',
				'creditorCount',
				'groupCount',
			].includes(dataIndex)
				? {
						customRenderChild: (
							text: any,
							record: IProperty,
							index: number
						) => (
							<>
								{!isNaN(
									parseInt(
										text
									)
								) &&
								parseInt(text) >
									0 ? (
									<a
										onClick={(
											e
										) =>
											handleCountClick(
												e,
												record,
												dataIndex,
												values
											)
										}>
										{
											text
										}
									</a>
								) : (
									<p>
										{
											text
										}
									</p>
								)}
							</>
						),
						customSorter: (
							a: any,
							b: any
						) => a - b,
				  }
				: {}),
		}));

	/**
	 * @function
	 * @description - Refresh the list of cases and subcases
	 * @returns - void
	 */
	const handleRefreshTable = (): void => {
		getBMDList &&
			getBMDList(
				getEntityNumberAndType(
					params['type'],
					params['id'],
					caseId?.toString() ?? ''
				)
			);
	};

	/**
	 * @function
	 * @description - Reset page scroll
	 * @returns - void
	 */
	const resetScroll = () => {
		window?.scrollTo({ top: 0, behavior: 'smooth' });
	};

	return (
		<Formik
			initialValues={getBMDListInitialValues(
				bmdListData?.categories ?? [],
				bmdGroupData?.groupId ?? bmdGroupId,
				bmdGroupData?.categoryId ?? bmdCategoryId,
				bmdSearch,
				initialPropertyCheck
			)}
			enableReinitialize
			onSubmit={() => {}}>
			{({
				values,
				setValues,
				setFieldValue,
				...restProps
			}: {
				values: IBMDListFrom;
				setValues: any;
				setFieldValue: (
					field: string,
					value: any,
					shouldValidate?: boolean
				) => void;
			}) => (
				<>
					<$Affix offsetTop={80}>
						<div className='space-content'>
							<div className='page-header header-border'>
								<div
									style={{
										maxHeight: '47px',
									}}
									className='d-flex flex-row align-items-center justify-content-between'>
									<div className='d-flex align-items-center'>
										{params[
											'id'
										] && (
											<$PageHeader
												className='px-0'
												title={t(
													'US.COLLECTION.CASE:BMD.BMD'
												)}
												onBack={() => {
													if (
														params[
															'module'
														]
													)
														push(
															{
																...location,
																pathname: `/${params['type']}/${params['id']}`,
															}
														);
													else if (
														params[
															'id'
														]
													)
														push(
															{
																...location,
																pathname: `/${params['type']}`,
															}
														);
												}}
											/>
										)}
										{!params[
											'id'
										] && (
											<$PageHeader
												className='px-0'
												title={t(
													'US.COLLECTION.CASE:BMD.BMD'
												)}
											/>
										)}
										<$Divider
											className='bui-devider'
											type='vertical'
										/>

										{params[
											'type'
										] ===
											URLType.BUREAU && (
											<>
												<$Button
													type='default'
													size='small'
													className='mr-2'
													icon={
														<PlusOutlined />
													}
													onClick={() =>
														handleAddBmdGroup()
													}>
													{t(
														'US.COLLECTION.CASE:BMD.NEW_CATEGORY'
													)}
												</$Button>
												<$Button
													type='default'
													size='small'
													icon={
														<PlusOutlined />
													}
													data-testid='btn-bmd-manage-type'
													onClick={() => {
														changeDrawerStatus &&
															changeDrawerStatus(
																{
																	...drawerStatus,
																	isVisible: true,
																	title: t(
																		'US.COLLECTION.CASE:BMD.MANAGE_TYPES'
																	),
																	drawerType: DrawerTypes.MANAGE_DATA_TYPE,
																}
															);
													}}>
													{t(
														'US.COLLECTION.CASE:BMD.MANAGE_TYPE'
													)}
												</$Button>
											</>
										)}
									</div>
									<div className='d-flex flex-fill justify-content-end'>
										<$Tooltip
											placement='right'
											title={t(
												'US.COLLECTION.CASE:BMD.REFRESH_BMD_TABLE'
											)}>
											<$Button
												icon={
													<ReloadOutlined />
												}
												type='dashed'
												size='small'
												className='mr-n2'
												disabled={
													bmdListLoading
												}
												onClick={() => {
													setFieldValue(
														'bmdSearch',
														''
													);
													handleRefreshTable();
												}}
											/>
										</$Tooltip>
									</div>
								</div>
							</div>
						</div>
					</$Affix>
					<$Skeleton
						loading={bmdListLoading}
						active
						paragraph={{ rows: 2 }}
						className={
							bmdListLoading
								? 'px-3'
								: ''
						}>
						<div className='bmd-layout'>
							<$Affix offsetTop={128}>
								<div className='bmd-layout-side'>
									<aside>
										<div
											className='bmdlist-search'
											data-testid='bmdlist-search-input'>
											<$Search
												size='small'
												name='bmdSearch'
												className='w-100'
												allowClear={
													true
												}
												placeholder={t(
													'US.COLLECTION.CASE:BMD.SEARCH'
												)}
												value={
													values.bmdSearch
												}
												onSearch={(
													value
												) => {
													if (
														value
													) {
														const categories =
															getDataFiltered(
																{
																	...values,
																	bmdSearch: value,
																},
																bmdListData
															)?.categories;
														setFilteredCategories(
															categories
														);
														setValues(
															(
																preVals: any
															) => ({
																...preVals,
																selectedCategory:
																	ALL_CATEGORY,
																bmdGroup: DEFAULT_GROUP,
															}),
															false
														);
														resetScroll();
													}
												}}
												onChange={(
													e: any
												) => {
													if (
														!e
															.target
															.value
													) {
														setFilteredCategories(
															getDataFiltered(
																getBMDListInitialValues(
																	bmdListData?.categories ??
																		[],
																	bmdGroupId,
																	bmdCategoryId,
																	bmdSearch,
																	initialPropertyCheck
																),
																bmdListData
															)
																?.categories
														);
														setFieldValue(
															'selectedCategory',
															ALL_CATEGORY,
															false
														);
														setFieldValue(
															'bmdGroup',
															DEFAULT_GROUP,
															false
														);
														resetScroll();
													}
												}}
											/>
											{values?.bmdSearch && (
												<span className='ant-form-item-explain'>
													{t(
														'US.COLLECTION.CASE:BMD.PRESS_ENTER_TO_SEARCH'
													)}
												</span>
											)}
										</div>
										<div>
											<$Menu
												defaultSelectedKeys={[
													'0',
												]}
												selectedKeys={[
													values.selectedCategory?.id.toString() ??
														'0',
												]}
												className='bmd-type-menu'
												data-testid='category-menu'>
												{filteredCategories &&
													[
														ALL_CATEGORY,
														...filteredCategories,
													]?.map(
														(
															category
														) => (
															<$Menu.Item
																key={
																	category.id
																}
																onClick={() => {
																	resetScroll();
																	setValues(
																		(
																			preVals: any
																		) => ({
																			...preVals,
																			selectedCategory:
																				category,
																			bmdGroup: DEFAULT_GROUP,
																		}),
																		false
																	);
																}}>
																{
																	category.name
																}
															</$Menu.Item>
														)
													)}
											</$Menu>
										</div>
									</aside>
								</div>
							</$Affix>
							<div className='bmd-layout-content'>
								<SearchView
									selectedCategory={
										values.selectedCategory
									}
									columns={column(
										values
									)}
									filteredCategories={
										filteredCategories
									}
									entityDetails={getEntityNumberAndType(
										params[
											'type'
										],
										params[
											'id'
										],
										caseId?.toString() ??
											''
									)}
									selectedBmdGroup={values.selectedCategory?.bmdGroups.find(
										(
											bmdGroup
										) =>
											bmdGroup.name ===
											values.bmdGroup
									)}
									editBMDGroup={() =>
										handleEditBMDGroup(
											values
										)
									}
									isBureauRoute={
										params[
											'type'
										] ==
										URLType.BUREAU
									}
								/>
							</div>
						</div>

						<$Drawer
							title={t(
								drawerStatus.title
							)}
							width={'1000'}
							visible={
								drawerStatus.isVisible
							}
							onClose={() =>
								changeDrawerStatus &&
								changeDrawerStatus(
									{
										...drawerStatus,
										isVisible: false,
									}
								)
							}
							data-testid='add-property-drawer'>
							{drawerStatus.isVisible && (
								<>
									{drawerStatus.drawerType ===
										DrawerTypes.MANAGE_DATA_TYPE && (
										<ManageDataType />
									)}
									{drawerStatus.drawerType ===
										DrawerTypes.BMD_COUNT && (
										<BMDChangeCount />
									)}
									{[
										DrawerTypes.ADD_PROPERTY,
										DrawerTypes.EDIT_BMD,
									].includes(
										drawerStatus.drawerType
									) && (
										<AddProperty
											selectedBMDGroupId={
												values.selectedCategory?.bmdGroups.find(
													(
														bmdGroup
													) =>
														bmdGroup.name ===
														values.bmdGroup
												)
													?.id ??
												0
											}
											routeDetails={getEntityNumberAndType(
												params[
													'type'
												],
												params[
													'id'
												],
												caseId?.toString() ??
													''
											)}
											bmdGroups={
												values
													.selectedCategory
													?.bmdGroups ??
												[]
											}
										/>
									)}
								</>
							)}
						</$Drawer>
					</$Skeleton>
					<$Drawer
						title={bmdGroupDrawer.title}
						width={'600'}
						visible={
							bmdGroupDrawer.isVisible
						}
						onClose={() => onClose()}
						destroyOnClose>
						<BMDGroup
							editGroupContent={
								editGroupContent
							}
						/>
					</$Drawer>
				</>
			)}
		</Formik>
	);
};

const { bmdList, bmdDataTypes, bmdGroup } = BMDActions;
const { creditor } = CreditorActions;
const { creditors } = creditor;

const mapStateToProps = (state: RootState) => {
	const { bmd, domainView } = state;
	const {
		bmdList,
		drawerStatus,
		bmdGroupDrawer,
		bmdGroupInitial,
		bmdGroup,
	} = bmd;
	const { metaData } = domainView;
	const { data, isLoading } = bmdList;
	return {
		bmdListData: data,
		bmdListLoading: isLoading,
		metaData,
		drawerStatus,
		bmdGroupDrawer,
		bmdGroupInitial,
		bmdGroupData: bmdGroup.data,
	};
};

const mapDispatchToProps = {
	getCreditors: creditors.get,
	getBMDList: bmdList.get,
	changeDrawerStatus: bmdDataTypes.openDrawer,
	openGroupDrawer: bmdGroup.openDrawer,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(BmdList);
