import React from 'react';
import { Handlers } from 'us.collection/constants';
import { IHandler } from 'us.collection/reducers/Handler/Interfaces';
import { $Menu, $Skeleton } from 'us.common/components';

interface ICaseHandlerList {
	setSelectHandler: any;
	caseHandlerDetails: IHandler;
	isLoading: boolean;
}

export const CaseHandlerList: React.FC<ICaseHandlerList> = (props) => {
	const { setSelectHandler, caseHandlerDetails, isLoading } = props;

	return (
		<>
			{isLoading && (
				<div className='ant-dropdown-menu px-3 pt-3'>
					<$Skeleton
						loading={true}
						active
						title={false}
						paragraph={{ rows: 2 }}
					/>
				</div>
			)}
			{!isLoading && (
				<div
					className='ant-dropdown-menu'
					data-testid={'case-list'}>
					<$Menu>
						{caseHandlerDetails?.list.map(
							({
								caseHandlerName,
								caseHandlerId,
							}: {
								caseHandlerName: string;
								caseHandlerId: number;
							}) => {
								return (
									<$Menu.Item
										key={
											caseHandlerId
										}
										onClick={() =>
											setSelectHandler(
												caseHandlerName,
												Handlers.CASE
											)
										}
										className='ant-dropdown-menu-item my-0'>
										<span>
											{
												caseHandlerName
											}
										</span>
									</$Menu.Item>
								);
							}
						)}
					</$Menu>
				</div>
			)}
		</>
	);
};
