import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IRootState } from "us.collection/interfaces";
import { ActivityTable } from "us.collection/components";
import { useTranslation } from "react-i18next";
import {
  $Button,
  $AutoComplete,
  $MessageBox,
  $Dropdown,
  $Drawer,
} from "us.common/components";
import { followUps } from "us.collection.followup/actions";
import { IActivityExecution, IOption, IOptionItem } from "./Interfaces";
import { Formik } from "formik";
import moment from "moment";
import { BoxIcons, IconTypes } from "us.icons";
import {
  getCategoryActivities,
  filterActivityByCodeOrName,
  getSelectedActivity,
} from "us.collection.followup/functions";
import { IActivity } from "us.collection.followup/interfaces";
import {
  ActivityParametersState,
  InitialField,
  BMDData,
} from "us.collection.followup/constants";
import { useParams } from "react-router-dom";
import { ParameterTable, Execution, OptionLabel } from "./Components";
import * as Azure from "us.helper/azure";
import {
  ExecuteAction,
  ExecuteActivity,
} from "us.collection.followup/repository";
import {
  filterActivityByFollowUpEntityType,
  getActivityTableData,
  isValidActivity,
} from "./Function";
import { SingleActivityExReq } from "us.collection.followup/repository/FollowUps";
import { useMsal } from "@azure/msal-react";

const { bmd, activities, activity, parameters, action } = followUps;

/**
 * @description Activity Execution bar
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2912026690/Follow-up+Activity+Execution+UI+Implementation
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 03/03/2022
 */
const ActivityExecution: React.FC<PropsFromRedux & IActivityExecution> = (
  props
) => {
  const { followUpId } = useParams<{ followUpId?: string }>();
  const { t } = useTranslation();
  const { instance } = useMsal();
  const {
    activites,
    currentCurrency,
    currentDateFormat,
    getParameters,
    getActivities,
    parameters,
    updateDefaultData,
    selectedRowKeys,
    defaultData,
    followUpResult,
    executeActivity,
    updateInitialValues,
    activityForm,
    getActivityBMD,
    maxFollowUpscount,
    isActivityExecuting,
    activityFilter,
    isSingleFollowUp,
    followupId,
    followUpDataId,
    executeSingleActivity,
    entityDetails,
    urlEntityId,
    resetForm,
  } = props;

  const { categories, data, isFetching, isEmpty } = activites;
  const { isEnableDropDown, list } = parameters;
  const { dataSource } = followUpResult;
  const { initialValues } = activityForm;

  const [tableVisible, setTableVisible] = useState<boolean>(false);
  const [searchedData, setSearchData] = useState<Array<IActivity>>(data);

  const currentUser: any = (
    window._ENV.REACT_APP_AZURE_AD_SETUP
      ? new Azure.ADAuth()
      : new Azure.B2CAuth()
  ).currentUser();

  useEffect(() => {
    if (!isSingleFollowUp) {
      followUpId &&
        getActivities &&
        getActivities({ followUpId, isHandelingActivity: false });
      getActivityBMD &&
        getActivityBMD([
          {
            entityType: BMDData.EntityType,
            categoryName: BMDData.CategoryName,
            bmdName: BMDData.BMDName,
            displayKeyName: BMDData.DisplayKeyName,
          },
        ]);
    }
    resetForm && resetForm({});
  }, []);

  useEffect(() => {
    if (isSingleFollowUp && !isFetching) {
      setSearchData(data);
    }
  }, [data]);

  useEffect(() => {
    toggleBodyOverlay(isEnableDropDown);
  }, [isEnableDropDown]);

  const getHandlingActivities = () => {
    if (isSingleFollowUp) {
      getActivities &&
        getActivities({ followUpId: followupId, isHandelingActivity: true });
    }
  };

  /**
   * @function
   * @description  get a option data for autocomplate dropdown
   * @param {string} title activity name
   * @param {string | number} code activity code
   * @returns {IOptionItem} a option data for activities dropdown
   */
  const getOptionItem = (title: string, code: number | string): IOptionItem => {
    try {
      return {
        value: title,
        label: <OptionLabel name={title} code={code} />,
      };
    } catch (error) {
      return {
        value: "",
        label: <></>,
      };
    }
  };

  /**
   * @function
   * @description  get all activities options
   * @param {boolean} isGroup - is dropdown data group or not
   * @returns {Array<IOption | IOptionItem>} all options data for activity dropdown
   */
  const getOptions = (isGroup: boolean): Array<IOption | IOptionItem> => {
    try {
      if (searchedData.length > 0) {
        if (isGroup) {
          const { handling, other } = getCategoryActivities(searchedData);
          return [
            {
              label: t(
                "US.COLLECTION.FOLLOWUP:ACTIVITYEXECUTION.HANDLING_ACTIVITIES"
              ),
              options: filterActivityByFollowUpEntityType(
                handling,
                activityFilter
              ).map((activity: IActivity) =>
                getOptionItem(activity.displayName, activity.activityCode)
              ),
            },
            {
              label: t(
                "US.COLLECTION.FOLLOWUP:ACTIVITYEXECUTION.OTHER_ACTIVITIES"
              ),
              options: filterActivityByFollowUpEntityType(
                other,
                activityFilter
              ).map((activity: IActivity) =>
                getOptionItem(activity.displayName, activity.activityCode)
              ),
            },
          ].filter((option: IOption) => option.options.length > 0);
        } else {
          return searchedData.map((activity: IActivity) => ({
            label: activity.displayName,
            value: activity.displayName,
          }));
        }
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  /**
   * @function
   * @description handle activity search
   * @param {string} searchText - search value
   */
  const onSearchActivity = (searchText: string): void => {
    try {
      if (searchText.trim().length > 0) {
        setSearchData(filterActivityByCodeOrName(data, searchText));
      } else {
        setSearchData(data);
      }
    } catch (error) {
      setSearchData(data);
    }
  };

  /**
   * @function
   * @description handle after select a activity
   * @param {string} selectValue - selected activity name
   */
  const onSelectActivity = (selectValue: string): void => {
    const { activityId, entitytype } =
      getSelectedActivity(data, selectValue) ?? {};

    updateDefaultData &&
      updateDefaultData({
        entityType: entitytype,
        userName: currentUser?.unique_name,
      });

    updateInitialValues &&
      updateInitialValues({
        activity: selectValue,
        executingDateTime: "",
        executingDateTimeWithParameter: "",
      });

    activityId &&
      getParameters &&
      getParameters({
        activityId,
        state: ActivityParametersState.DefaultState,
      });
  };

  /**
   * @function
   * @description set schedule date
   * @param {moment.Moment} date selected date
   * @param setFieldValue -formik setFieldValue prop
   */
  const onSelectDate = (date: moment.Moment, setFieldValue: any) => {
    setFieldValue(InitialField.ExecutingDateTime, date);
  };

  /**
   * @function
   * @description handle activity execution
   * @param formData activity form data
   */
  const onExecute = (formData: any) => {
    const {
      activity,
      executingDateTime,
      executingDateTimeWithParameter,
      action,
    } = formData;

    const followUpIds = isSingleFollowUp
      ? [followUpDataId ?? 0]
      : selectedRowKeys;

    const activityName = data.find(
      (act: IActivity) => act.displayName === activity
    )?.name;

    const actionRequest = ExecuteAction.call(
      { noteText: action },
      followUpIds,
      activityName
    );
    if (
      isValidToExecute(
        activity,
        isEnableDropDown ? executingDateTimeWithParameter : executingDateTime
      )
    ) {
      if (isSingleFollowUp) {
        const req = SingleActivityExReq.call(
          formData,
          list,
          data,
          defaultData,
          entityDetails,
          activityFilter
        );
        executeSingleActivity &&
          executeSingleActivity(req, {
            actionRequest,
            entity: {
              entityId: urlEntityId,
              entityType: entityDetails?.entityType,
            },
          });
      } else {
        const request = ExecuteActivity.call(
          formData,
          list,
          data,
          defaultData,
          dataSource,
          selectedRowKeys,
          isEnableDropDown,
          activityFilter
        );
        executeActivity &&
          executeActivity(request, {
            followUpId,
            actionRequest,
            activity,
            user: instance.getActiveAccount()?.username ?? "",
          });
      }
    }
    removeBodyClass("hidden-overlay-open");
  };

  /**
   * @function
   * @description check whether activity is valid for execute or not and provide notifications
   * @param {string} activityName selected activity name
   * @param {moment.Moment | string} date - schedule date
   * @returns {boolean} true - if activity is valid, otherwise return false
   */
  const isValidToExecute = (
    activityName: string,
    date: moment.Moment | string
  ): boolean => {
    if (activityName.trim().length === 0) {
      $MessageBox(
        "error",
        "US.COLLECTION.COMMON:ACTIVITYEXECUTION.PLEASE_SELECT_AN_ACTIVITY",
        "",
        ""
      );
      return false;
    } else if (!isValidActivity(data, activityName)) {
      $MessageBox(
        "error",
        "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD",
        "",
        ""
      );
      return false;
    } else if (
      selectedRowKeys.length > maxFollowUpscount &&
      !moment(date).isValid()
    ) {
      $MessageBox(
        "error",
        "US.COLLECTION.COMMON:ACTIVITYEXECUTION.PLEASE_USE_SCHEDULER",
        "",
        "",
        { maxFollowUpscount }
      );
      return false;
    }
    return true;
  };

  const overLayElement: HTMLElement = document.getElementById(
    "hiddenOverlay"
  ) as HTMLElement;
  const addBodyClass = (className: any) =>
    document.body.classList.add(className);
  const removeBodyClass = (className: any) =>
    document.body.classList.remove(className);
  const toggleBodyOverlay = (show: any) =>
    show === true
      ? addBodyClass("hidden-overlay-open")
      : removeBodyClass("hidden-overlay-open");

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onExecute}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        setFieldValue,
        ...rest
      }: any) => (
        <div className={`activity-bar ${isSingleFollowUp ? "no-sa-btn" : ""}`}>
          <div className="activity-bar-select">
            {!isSingleFollowUp && (
              <$Button
                data-testid="execution-activityTable"
                type="link"
                className="activity-bar-select-before"
                onClick={() => setTableVisible(true)}
                disabled={selectedRowKeys.length === 0}
              >
                <BoxIcons type={IconTypes.BOX_ICON} name="select-activity" />
              </$Button>
            )}
            <$AutoComplete
              style={{ width: 340 }}
              placeholder={
                isSingleFollowUp && !isFetching && isEmpty
                  ? t(
                      "US.COLLECTION.COMMON:ACTIVITYEXECUTION.NO_HANDLING_ACTIVITIES_TO_EXECUTE"
                    )
                  : t("US.COLLECTION.COMMON:ACTIVITYEXECUTION.RUNACTIVITY")
              }
              name="activity"
              options={getOptions(!isSingleFollowUp)}
              onSelect={(value: string) => onSelectActivity(value)}
              className="activity-bar-select-input mb-0"
              allowClear={true}
              disabled={selectedRowKeys.length === 0}
              onSearch={(value: string) => onSearchActivity(value)}
              onFocus={(e: any) => getHandlingActivities()}
              data-testid="followup-activity"
            />
            <$Dropdown
              getPopupContainer={() => overLayElement}
              overlay={
                <ParameterTable
                  setFieldValue={setFieldValue}
                  values={values}
                  handleSubmit={handleSubmit}
                  activityFilter={activityFilter}
                  isValidToExecute={isValidToExecute}
                  isSingleFollowUp={isSingleFollowUp}
                />
              }
              placement="bottomLeft"
              trigger={["click"]}
              arrow
              visible={isEnableDropDown}
            >
              <span className="parameters-dropdown-btn"></span>
            </$Dropdown>
          </div>
          <Execution
            name="executingDateTime"
            currentDateFormat={currentDateFormat}
            isEnableParameter={false}
            isdisable={isEnableDropDown || selectedRowKeys.length === 0}
            isExecuting={isActivityExecuting}
            setFieldValue={setFieldValue}
            onDateSelect={onSelectDate}
            date={values.executingDateTime}
            onExecute={handleSubmit}
            values={values}
            isValidToExecute={isValidToExecute}
            isSingleFollowUp={isSingleFollowUp}
            tabStartedIndex={1}
            isActivityValid={isValidActivity(data, values.activity)}
          />
          <$Drawer
            title={t("US.COLLECTION.COMMON:ACTIVITYEXECUTION.SELECT_ACTIVITY")}
            width={1100}
            visible={tableVisible}
            onClose={() => setTableVisible(false)}
            destroyOnClose
            className="select-activity"
          >
            <ActivityTable
              onClose={() => setTableVisible(false)}
              data={getActivityTableData(data, activityFilter)}
              handleSelect={onSelectActivity}
              categories={categories}
              currentCurrency={currentCurrency}
              setFieldValue={setFieldValue}
            />
          </$Drawer>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { followUps, common } = state;
  const {
    activites,
    parameters,
    defaultData,
    result: followUpResult,
    activityForm,
    maxFollowUpscount,
    isActivityExecuting,
  } = followUps;
  const { currentCurrency, currentDateFormat } = common;

  return {
    activites,
    currentCurrency,
    currentDateFormat,
    parameters,
    defaultData,
    followUpResult,
    activityForm,
    maxFollowUpscount,
    isActivityExecuting,
  };
};

const mapDispatchToProps = {
  getParameters: parameters.get,
  getActivities: activities.get,
  updateDefaultData: activity.updateDefaultData,
  executeActivity: activity.execute,
  executeSingleActivity: activity.executeSingle,
  updateInitialValues: activity.updateInitialValues,
  getActivityBMD: bmd.get,
  executeAction: action.execute,
  resetForm: activity.reset,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ActivityExecution);
