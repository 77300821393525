export enum FollowUpName {
    ManualFollowupCase = "ManualFollowupCase",
    ManualFollowupSubCase = "ManualFollowupSubCase",
    CommonFollowup = "Common follow-up"
}
export enum FollowUpEntityType {
    SUBCASE = 'S',
    CASE = 'C'
}
export enum CaseType {
    SUBCASE = 'SubCase',
    CASE = 'Case'
}

export enum EntityFollowUpPanel {
    DUE = "DUE",
    UPCOMING = "UPCOMING",
    OTHER = "OTHER"
}

export enum ItemAction {
    DONE = "DONE",
    REMOVE = "REMOVE"
}
export const DateFormat = "YYYY-MM-DD"