import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { BoxIcons, IconTypes } from 'us.icons';
import { $Button, $Tooltip } from 'us.common/components';
import { followUps } from 'us.collection/actions';
import { IAddToFollowUpButton } from './Interfaces';
import { initialEntityDrawer } from 'us.collection/constants';
import { EntityFollowUpPanel } from 'us.collection.case/constants';
import { useLocation } from 'react-router-dom';
import { getEntityTypeAndId } from 'us.collection.followup/functions';
import { FollowUpEntityType } from 'us.collection.followup/constants';
import { RootState } from 'us.helper/types';

const { entity } = followUps;

const AddToFollowUpButton: React.FC<PropsFromRedux & IAddToFollowUpButton> = (
	props
) => {
	const { t } = useTranslation();
	const { pathname } = useLocation();

	const { manageEntityFollowUpDrawer, metaData, selectedTab } = props;

	const { entityType } = getEntityTypeAndId({ pathname }, metaData);

	return (
		<$Tooltip
			placement='topLeft'
			title={t('US.COLLECTION.LAYOUT:CASE.FOLLOWUPS')}>
			<$Button
				className='bui-btn-tool-icon mx-1'
				onClick={() =>
					manageEntityFollowUpDrawer &&
					manageEntityFollowUpDrawer({
						...initialEntityDrawer,
						panel: [
							FollowUpEntityType.CASE,
							FollowUpEntityType.INVOICE,
						].includes(
							entityType as FollowUpEntityType
						)
							? EntityFollowUpPanel.DUE
							: EntityFollowUpPanel.OTHER,
						visible: true,
						selectedTab,
					})
				}>
				<BoxIcons
					type={IconTypes.BOX_ICON}
					name='add-followup'
				/>
			</$Button>
		</$Tooltip>
	);
};

const mapStateToProps = (state: RootState) => {
	const { domainView } = state;
	const { metaData } = domainView;

	return {
		metaData,
	};
};

const mapDispatchToProps = {
	manageEntityFollowUpDrawer: entity.manageDrawer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddToFollowUpButton);
