import { versionedHttpCollection } from 'us.helper/http/collection';
import ServiceConfig from 'service.config.js';

export default {
	manualFollowUps: {
		get: <
			P extends {
				caseType: string;
				caseId: number;
			}
		>(
			params: P
		): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				const { caseId, caseType } = params;
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'followupservice',
							`manualfollowupdataset/${caseId}/${caseType}`,
							{},
							ServiceConfig()[
								`followupservice`
							][
								'manualfollowupdataset'
							]
						);
					if (status === 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	categories: {
		get: (): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'followupservice',
							`manualfollowupcategory`,
							{},
							ServiceConfig()[
								`followupservice`
							][
								'manualfollowupcategory'
							]
						);
					if (status === 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	reasons: {
		get: <
			P extends {
				categoryId: number;
				searchcriteria: string;
			}
		>(
			params: P
		): Promise<any> => {
			const { categoryId, searchcriteria } = params;
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'followupservice',
							`manualfollowupreason`,
							{
								categoryId,
								reasonName: searchcriteria,
							},
							ServiceConfig()[
								`followupservice`
							][
								'manualfollowupreason'
							]
						);
					if (status === 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	reason: {
		save: <P extends { categoryId: number; reasonName: string }>(
			params: P
		): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data } =
						await versionedHttpCollection.post(
							'followupservice',
							`manualfollowupreason`,
							params,
							ServiceConfig()[
								`followupservice`
							][
								'manualfollowupreason'
							]
						);
					resolve(data);
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	users: {
		get: (): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'userservice',
							'users',
							{},
							ServiceConfig()[
								`userservice`
							]['users']
						);
					if (status === 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	manualFollowUp: {
		save: (params: any): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data } =
						await versionedHttpCollection.post(
							'followupservice',
							`followupdataset`,
							params,
							ServiceConfig()[
								`followupservice`
							]['followupdataset']
						);
					resolve(data);
				} catch (error) {
					reject(error);
				}
			});
		},
		doneOrRemove: (params: any): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data } =
						await versionedHttpCollection.post(
							'followupservice',
							`handlemanualfollowupitem`,
							params,
							ServiceConfig()[
								`followupservice`
							][
								'handlemanualfollowupitem'
							]
						);
					resolve(data);
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	followUpsSummary: {
		get: <
			P extends {
				caseId: number;
			}
		>(
			params: P
		): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				const { caseId } = params;
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'followupservice',
							`followupsummary/${caseId}`,
							{},
							ServiceConfig()[
								`followupservice`
							][
								'manualfollowupdataset'
							]
						);
					if (status === 200) {
						resolve({ data, status });
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
};
