import React, { useState, useEffect, memo } from "react";
import { Formik } from "formik";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { BoxIcons, FilterOutlined, IconTypes, SearchOutlined } from "us.icons";
import {
  $Input,
  $Button,
  $Breadcrumb,
  $Table,
  $PageHeader,
  $Affix,
} from "us.common/components";
import { IRootState } from "us.collection/interfaces";
import { ISortOrder, ITransTypes } from "./Interfaces";

const TransTypes: React.FC<ITransTypes> = memo((props) => {
  const { t } = useTranslation(["US.COLLECTION.TRANSACTIONS"]);
  const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
    columnKey: "",
    order: "",
  });
  const [searchText, setSearchText] = useState<string>("");
  const { transTypes, onSelectTransactionType, enableHeader } = props;

  const selectTransactionType = (type: any) => {
    onSelectTransactionType(type);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter);
  };

  const handleSearch = (selectedKeys: any, confirm: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex: string, title: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <$Input
          name="columnSearch"
          placeholder={`${t("US.COLLECTION.COMMON:COMMON.SEARCH")} ${title}`}
          value={selectedKeys[0]}
          onChange={(e: any) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <$Button
          name="searchBtn"
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {t("US.COLLECTION.COMMON:COMMON.SEARCH")}
        </$Button>
        <$Button
          name="resetBtn"
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          {t("US.COLLECTION.COMMON:COMMON.RESET")}
        </$Button>
      </div>
    ),
    filterIcon: (filtered: string) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: string, record: any) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text: string) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ),
  });

  const columns: any = [
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.PAYMENT"),
      dataIndex: "isPayment",
      key: "isPayment",
      width: 100,
      className: "text-nowrap",
      render: (text: string, record: any) =>
        record.isPayment ? (
          <BoxIcons
            type={IconTypes.BOX_ICON}
            name="payment"
            className="table-icon-size"
          />
        ) : null,
    },
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.GROUP"),
      dataIndex: "transactionGroup",
      key: "transactionGroup",
      width: 100,
      className: "text-nowrap",
      sorter: (a: any, b: any) =>
        a.transactionGroup.localeCompare(b.transactionGroup),
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "transactionGroup" && sortedInfo.order
        : null,
      ...getColumnSearchProps("transactionGroup", "Group"),
    },
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.TYPE"),
      dataIndex: "typeName",
      key: "typeName",
      width: 100,
      className: "text-nowrap",
      sorter: (a: any, b: any) => a.typeName.localeCompare(b.typeName),
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "typeName" && sortedInfo.order
        : null,
      ...getColumnSearchProps("typeName", "Type"),
    },
    {
      title: t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.DESCRIPTION"),
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "description" && sortedInfo.order
        : null,
      ...getColumnSearchProps(
        "description",
        t("US.COLLECTION.TRANSACTIONS:TRANSACTIONS.DESCRIPTION")
      ),
    },
  ];

  return (
    <Formik initialValues={{}} onSubmit={(values: any, actions: any) => {}}>
      <div>
        {enableHeader && (
          <div className="">
            {/* sticky-drawer-header mt-n5 */}
            <$Breadcrumb className="mb-0">
              <$Breadcrumb.Item onClick={() => selectTransactionType(null)}>
                <a>{t("US.COLLECTION:TRANSACTIONS.REGISTERTRANSACTION")}</a>
              </$Breadcrumb.Item>
              <$Breadcrumb.Item>
                {t("US.COLLECTION.TRANSACTIONS:REGISTER.TRANSACTION_TYPES")}
              </$Breadcrumb.Item>
            </$Breadcrumb>

            <$PageHeader
              className="p-0 mb-4"
              onBack={() => selectTransactionType(null)}
              title={t("US.COLLECTION:TRANSACTIONS.SELECT_TRANSACTION_TYPES")}
            />
          </div>
        )}

        <$Table
          onChange={handleTableChange}
          className="mt-3 table-striped"
          bordered
          size="small"
          rowKey="typeId"
          columns={columns}
          pagination={{ hideOnSinglePage: true, defaultPageSize: 20 }}
          dataSource={transTypes?.data}
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                selectTransactionType(record);
              },
            };
          }}
        />

        <div className="drawer-footer-fixed align-content-center">
          <div className="ml-auto">
            <$Button onClick={() => selectTransactionType(null)}>
              {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
            </$Button>
          </div>
        </div>
      </div>
    </Formik>
  );
});

const mapStateToProps = (state: IRootState) => {
  const { transType } = state;
  const { transTypes } = transType;
  return {
    transTypes,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TransTypes);
