import { CaseTypeOptions } from "us.collection.transactions/components/CasesAndSubcases/Constants";
import { DrawerType } from "us.collection.transactions/constants";
import { IInitialState } from "us.collection.transactions/interfaces";

export const InitialState: IInitialState = {
  caseList: { data: [], isError: false, isLoading: true },
  drawerStatus: {
    title: "",
    type: DrawerType.CREATE_CASES,
    isOpen: false,
  },
  saveStatus: { data: { errorMessage: "", isError: false }, isError: false, isLoading: false },
  selectedCaseAndSubCase: { data: [], filterType: CaseTypeOptions.ALL }
};
