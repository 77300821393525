import { LabeledValue } from "antd/lib/select";
import { IBMDDataType, IReturnAction } from "us.collection/interfaces";
import { appInsights } from "us.helper";

/**
 * @function
 * @description - To get the data types and call the api
 * @param {(string | number | LabeledValue)} value -  Data type selection value
 * @param {((<T>(data: object | T[]) => IReturnAction<T>) | undefined)} getDataTypeValuesAction - Reduction action to get data type values
 * @param {IBMDDataType[]} bmdDataTypes - data types available
 * @return {*}  {(IBMDDataType | undefined)} - selected data type object
 */
export const getBMDDataTypeValues = (
  value: string | number | LabeledValue,
  getDataTypeValuesAction: (<T>(data: object | T[]) => IReturnAction<T>) | undefined,
  bmdDataTypes: IBMDDataType[]
): IBMDDataType | undefined => {
  try {
    if (bmdDataTypes.length === 0) {
      return undefined;
    }
    const selectedDataType = bmdDataTypes.find((dataType) => dataType.id == value);
    if (selectedDataType && selectedDataType.isPrimitive === 0 && selectedDataType.storedProcedure) {
      getDataTypeValuesAction &&
        getDataTypeValuesAction({
          dataTypeId: selectedDataType.id,
        });
    }
    return selectedDataType;
  } catch (error) {
    appInsights.trackException(`Error in add BMD getBMDDataTypeValues function. - ${error}`);
    return undefined;
  }
};
