import { ICourtSentenceInitialState, ISentenceModel } from "us.collection.case/interfaces";

/**
 * To get sentence object with defined prop name
 * @param propName propoerty name of ICourtSentenceInitialState
 * @param data data from the API
 * @param isLoading if the API call is still on going
 * @param isError if there is any error
 * @returns a valid object with propName defined
 */
export const getSentenceObject = <T>(
  propName: keyof ICourtSentenceInitialState,
  data: T,
  isLoading: boolean,
  isError: boolean
) => ({
  [propName]: <ISentenceModel<T>>{
    isLoading,
    data,
    isError,
  },
});
