import { SideBarMenu } from "us.collection/constants";

export const getSelectedMenuKeyByModule = (moduleName: string) => {
  switch (moduleName) {
    case SideBarMenu.AGREEMENTS:
      return ["1"];
    case SideBarMenu.ASSETS:
      return ["2"];
    case SideBarMenu.DISTRIBUTION:
      return ["3"];
    case SideBarMenu.DOCUMENTS:
      return ["4"];
    case SideBarMenu.INTEREST:
      return ["5"];
    case SideBarMenu.CASE_INFORMATION:
      return ["6"];
    case SideBarMenu.APPLICABLE_COURTS:
      return ["7"];
    case SideBarMenu.DEBTOR_INFORMATION:
      return ["8"];
    case SideBarMenu.ENFORCEMENTS:
      return ["9"];
    case SideBarMenu.EXTENDED_FIELDS:
      return ["10"];
    case SideBarMenu.MANAGE_CREDITOR_GROUPS:
      return ["11"];
    case SideBarMenu.MESSAGE_TEMPLATES:
      return ["12"];
    case SideBarMenu.SELECTED_CREDITORS:
      return ["13"];
    case SideBarMenu.OTHER_PARTY:
      return ["14"];
    case SideBarMenu.PART_PAYMENTS:
      return ["15"];
    case SideBarMenu.HISTORY:
      return ["16"];
    case SideBarMenu.SENTENCES:
      return ["17"];
    case SideBarMenu.TRANSACTIONS:
      return ["18"];
    default:
      return [];
  }
};
