import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { $Tooltip } from 'us.common/components';
import { followUps } from 'us.collection.followup/actions';
import { INavigationColumn } from './Interfaces';
import {
	FollowUpEntityType,
	FollowUpUniqueCol,
} from 'us.collection.followup/constants';
import { getUrlForEntity } from 'us.collection.followup/functions';
import { useHistory } from 'react-router-dom';
import { DirectForm } from 'us.collection/constants';
import { followupFooterActions } from 'us.collection/actions/FollowupFooter';
import { RootState } from 'us.helper/types';
import { getLinkedEntity } from 'us.collection.followup/components/FollowUps/Components/FollowUpResult/Functions';

const { result, followUpNavigation } = followUps;
const { previousResult, followupBackButtonVisibility } = followupFooterActions;

/**
 * @description - Content of navigation column in table row data
 * @param record - individual single row of data
 * @param dataIndex - dataIndex of column of data
 * @param entityType - Entity Type
 * @param isHistory - current follow-up or history data
 * @returns  jsx element of more button popup
 */
const NavigationColumn: React.FC<PropsFromRedux & INavigationColumn> = (
	props
) => {
	const { t } = useTranslation();
	const history = useHistory();
	const {
		record,
		entityType,
		dataIndex,
		isHistory,
		assignFollowUpToUser,
		followupId,
		followUpNavigation,
		tableDataWithTableFilters,
		setFollowUpNavigation,
		setPreviousResult,
		setFollowupBackButtonVisibility,
	} = props;

	const { viewedItems, isBack } = followUpNavigation;

	/**
	 * @description - handle navigation from followup claim
	 * @returns  void
	 */
	const handleNavigation = (event: any) => {
		const columnFilter = getLinkedEntity(entityType);
		const propertyValues = tableDataWithTableFilters.data?.map(
			(dataItem: any) => dataItem[columnFilter]
		);
		const index = propertyValues.findIndex(
			(value: any) => value === record[`${dataIndex}`]
		);
		if (entityType == FollowUpEntityType.DEBTOR) {
			setFollowUpNavigation &&
				setFollowUpNavigation({
					isBack,
					viewedItems: [
						...viewedItems,
						record?.Followupid,
					],
					initialEntityId: record[`${dataIndex}`],
					entityIndex: index,
					hit: 1,
				});
		} else {
			setFollowUpNavigation &&
				setFollowUpNavigation({
					isBack,
					viewedItems: [
						...viewedItems,
						record[`${dataIndex}`],
					],
					initialEntityId: record[`${dataIndex}`],
					entityIndex: index,
					hit: 1,
				});
		}
		setPreviousResult && setPreviousResult([]);
		setFollowupBackButtonVisibility &&
			setFollowupBackButtonVisibility({ isActive: true });
		if (!isHistory) {
			assignFollowUpToUser &&
				assignFollowUpToUser(
					{
						followUpDataId:
							record[
								FollowUpUniqueCol
									.FOLLOWUP_ID
							],
					},
					history,
					{
						entityType,
						record,
						dataIndex,
						followupId,
					}
				);
		}
		if (!(event.ctrlKey || event.metaKey)) {
			event.preventDefault();

			history.push({
				pathname: `/${getUrlForEntity(entityType)}/${
					record[`${dataIndex}`]
				}${
					entityType == FollowUpEntityType.PAYMENT
						? '/distribution'
						: ''
				}`,
				search: `redirectFrom=${DirectForm.FOLLOW_UP}&id=${followupId}`,
			});
		}
	};

	return (
		<$Tooltip title={record[`${dataIndex}`]}>
			<Link
				to={{
					pathname: `/${getUrlForEntity(
						entityType
					)}/${record[`${dataIndex}`]}${
						entityType ==
						FollowUpEntityType.PAYMENT
							? '/distribution'
							: ''
					}`,
				}}
				target='_blank'
				onClick={() => handleNavigation(event)}>
				{record[`${dataIndex}`]}
			</Link>
		</$Tooltip>
	);
};

const mapStateToProps = (state: RootState) => {
	const { followUps } = state;
	const { followUpNavigation, tableDataWithTableFilters } = followUps;
	return { followUpNavigation, tableDataWithTableFilters };
};

const mapDispatchToProps = {
	assignFollowUpToUser: result.assignUser,
	setPreviousResult: previousResult.set,
	setFollowUpNavigation: followUpNavigation.set,
	setFollowupBackButtonVisibility: followupBackButtonVisibility.set,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NavigationColumn);
