import { IBMDChangeHistory } from "us.collection/interfaces";
import { IChangePropertyDetailsRequestObject } from "../Interfaces";

/**
 * @function
 * @description - To get the object required to make the BMD value change API request
 * @param {*} this - input object param
 * @return {*}  {IChangePropertyDetailsRequestObject} - the object for the saga to handle change bmd property request
 */
export function ChangePropertyDetailsRequestObject(this: any): any {
  let entityIdsList = [];
  if (!isNaN(parseInt(this.entityId))) {
    if (parseInt(this.entityId) < 0) {
      entityIdsList.push(1);
    } else {
      entityIdsList.push(parseInt(this.entityId));
    }
  }
  if (this.selectedCreditors && this.selectedCreditors.length > 0) {
    entityIdsList = [...entityIdsList, ...this.selectedCreditors];
  }
  return {
    entityType: this.entityType,
    entityId: [...entityIdsList],
    propertyId: this.propertyId,
    displayName: this.displayName,
    cgVisibility: this.cgVisibility,
    crVisibility: this.crVisibility,
    arVisibility: this.arVisibility,
    caVisibility: this.caVisibility,
    updateLowerLevel: this.updateLowerLevel,
    values: this.changedHistory
      .filter(({ isNew }: IBMDChangeHistory) => isNew)
      .map(({ value, comment, dateTime }: IBMDChangeHistory) => ({
        value,
        comment,
        fromDate: dateTime,
      })),
      
    // ----------------
    realEntityId:parseInt(this.entityId) <= 0 ? 1 : parseInt(this.realEntityId),
    realEntityType:this.realEntityType,
    drawerType:this.drawerType
  };
}
